<template>
    <div class="pt-3">
        <el-form class="form-aloware"
                 ref="triggers_form"
                 v-loading="loading || loading_whitelabel">
            <div class="mb-5">
                <h4>Lead Intake By Email</h4>
                <p>
                    Use this email address to capture leads via email. {{ statics.name }} automatically enrolls contacts <br/>
                    to this sequence using its best guess algorithm on all incoming emails to this address.
                </p>
                <div>
                    <el-button type="success"
                               plain
                               v-if="show_install_email_service"
                               @click="installEmailService">
                        <i class="material-icons loader pull-left"
                           v-show="loading_install_email_service">&#xE863;</i>
                        <i class="fa fa-plus"
                           v-show="!loading_install_email_service"></i>
                        Install Email Service
                    </el-button>
                    <p class="text-md _600 text-dark-greenish"
                       v-if="!show_install_email_service">
                        <input-readonly-with-copy v-model="mailgun_intake_route_string" />
                    </p>
                </div>
            </div>

            <div>
                <div class="mb-4">
                    <h4>Triggers</h4>
                    <p>
                        Here you can define how this sequence is applied to your contacts, based on their disposition status.
                    </p>
                    <el-dropdown trigger="click"
                                 placement="right"
                                 class="mb-4 mr-3">
                        <el-button type="success" plain>
                            <strong><i class="fa fa-plus"></i> Add Trigger</strong>
                        </el-button>
                        <template v-slot:dropdown>
                            <el-dropdown-menu>
                                <el-dropdown-item
                                    v-if="canAddContactDispositionTrigger"
                                    @click.native="addContactDispositionTrigger">
                                    <i class="material-icons mr-1">contact_phone</i>
                                    Add Contact Disposition Trigger
                                </el-dropdown-item>
                                <el-dropdown-item
                                    v-if="canAddCallDispositionTrigger"
                                    @click.native="addCallDispositionTrigger">
                                    <i class="material-icons mr-1">contact_phone</i>
                                    Add Call Disposition Trigger
                                </el-dropdown-item>
                                <el-dropdown-item
                                    v-if="canAddContactTrigger"
                                    @click.native="addAutomationContactTrigger">
                                    <i class="material-icons mr-1">contact_phone</i>
                                    Add Contact Trigger
                                </el-dropdown-item>
                                <el-dropdown-item
                                    v-if="canAddCallTrigger"
                                    @click.native="addAutomationCallTrigger">
                                    <i class="material-icons mr-1">call</i>
                                    Add Call Trigger
                                </el-dropdown-item>
                                <el-dropdown-item
                                    @click.native="addContactDNCTrigger">
                                    <i class="material-icons mr-1">contact_phone</i>
                                    Add Contact DNC Trigger
                                </el-dropdown-item>
                                <el-dropdown-item
                                    v-if="canAddAppointmentTrigger"
                                    @click.native="addAppointmentTrigger">
                                    <i class="material-icons mr-1">contact_phone</i>
                                    Add Appointment Trigger
                                </el-dropdown-item>
                                <el-dropdown-item
                                    @click.native="addContactTaggedTrigger">
                                    <i class="material-icons mr-1">label</i>
                                    Add Contact Tagged Trigger
                                </el-dropdown-item>
                                <el-dropdown-item
                                    @click.native="addContactTagRemovedTrigger">
                                    <i class="material-icons mr-1">label</i>
                                    Add Contact Tag Removed Trigger
                                </el-dropdown-item>
                            </el-dropdown-menu>
                        </template>
                    </el-dropdown>

                    <el-popover
                        v-model="show_copy_trigger_popover"
                        placement="right"
                        width="400"
                        trigger="click">
                        <div class="p-2">
                            <div class="mb-2">
                                <h6 class="mb-0"><i class="fa fa-copy"></i> Copy Triggers</h6>
                                <small>Select the sequence you want to copy the triggers from.</small>
                            </div>
                            <workflow-selector
                                v-model="copy_trigger_workflow_id"
                                @change="copyWorkflowTriggers">
                            </workflow-selector>
                        </div>
                        <el-button slot="reference" plain>
                            <i class="fa fa-copy"></i> Copy from other Sequence
                        </el-button>
                    </el-popover>
                </div>
                <div
                    class="card shadow-sm mb-3"
                    v-for="(trigger, idx) in workflow_triggers"
                    :key="trigger.id">
                    <div class="d-flex p-3">
                        <div>
                            <h6 class="text-md _600"
                                v-if="!['contact', 'disposition', 'call disposition', 'appointment', 'dnc status', 'tag', 'remove_tag'].includes(trigger.type)">
                                #{{ idx + 1 }} - {{ trigger.type | fixCommType }} Trigger
                            </h6>
                            <h6 class="text-md _600"
                                v-else>
                                #{{ idx + 1 }} - {{ getTriggerTypeName(trigger.type) }} Trigger
                            </h6>
                        </div>
                        <div class="ml-auto">
                            <el-button-group>
                                <el-button
                                    size="small"
                                    v-if="(trigger.type === 'contact' && !automation_trigger) || (trigger.type != 'contact' && trigger.type != 'disposition') || (trigger.type == 'disposition' && trigger.disposition_status_id != 0) || (trigger.type == 'call disposition' && trigger.call_disposition_id != 0)"
                                    @click="duplicate(trigger, idx)">
                                    <i class="fa fa-copy"></i> Duplicate
                                </el-button>
                                <el-button
                                    size="small"
                                    class="text-danger"
                                    @click="removeAt(idx)">
                                    <i class="fa fa-trash-o"></i> Delete
                                </el-button>
                            </el-button-group>
                        </div>
                    </div>
                    <div class="px-3 pb-3">
                        <trigger :trigger="trigger"
                                 :automation_trigger="automation_trigger"
                                 :disable_any_contact_disposition="contactDispositionTriggerIndex > 0"
                                 :disable_any_call_disposition="callDispositionTriggerIndex > 0"
                                 :used_contact_disposition_statuses="usedContactDispositionStatuses"
                                 @trigger-validated="updateTrigger"
                                 @valid="updateValidation(true)"
                                 @invalid="updateValidation(false)">
                        </trigger>
                    </div>
                </div>
            </div>
            <div
                class="text-center">
                <el-button type="success"
                           round
                           :disabled="disable_save_trigger"
                           @click="saveTriggers">
                    <strong>
                        <i class="fa fa-save mr-1"></i> Save Triggers
                    </strong>
                </el-button>
            </div>
        </el-form>
    </div>
</template>

<script>
import auth from '../../auth'
import {acl_mixin, validator_mixin, statics_mixin} from '../../mixins'
import { mapState } from 'vuex'
import Trigger from './../trigger'
import * as CommunicationTypes from '../../constants/communication-types'
import * as CommunicationDirections from "../../constants/communication-direction"
import * as CommunicationDispositionStatus from "../../constants/communication-disposition-status"
import InputReadonlyWithCopy from "../input-readonly-with-copy"
import WorkflowSelector from "../workflow-selector"

export default {
    name: 'workflow-triggers',

    mixins: [validator_mixin, acl_mixin, statics_mixin],

    components: {WorkflowSelector, InputReadonlyWithCopy, Trigger},

    props: {
        value: {
            type: Object,
            required: true
        },
        triggers: {
            required: true,
            default: []
        },

        mailgun_intake_route: {
            required: false,
            default: null
        },

        intake_route_list: {
            required: false
        },

        automation_trigger: {
            type: Boolean,
            default: false,
            required: false
        }
    },

    data() {
        return {
            loading: false,
            show_install_email_service: false,
            loading_install_email_service: false,
            disable_save_trigger: true,
            mailgun_intake_route_string: '',
            workflow_triggers: [],
            original_triggers: [],
            auth: auth,
            has_email_intake: false,
            copy_trigger_workflow_id: null,
            show_copy_trigger_popover: false,
            CommunicationDirections,
            CommunicationDispositionStatus
        }
    },

    computed: {
        ...mapState('cache', ['current_company']),

        canAddCallTrigger() {
            if (this.workflow_triggers && Array.isArray(this.workflow_triggers)) {
                return !this.workflow_triggers.some(trigger => trigger.type == CommunicationTypes.CALL)
            }

            return true
        },

        canAddAppointmentTrigger() {
            if (this.workflow_triggers && Array.isArray(this.workflow_triggers)) {
                return !this.workflow_triggers.some(trigger => trigger.type == CommunicationTypes.APPOINTMENT)
            }

            return true
        },

        canAddContactTrigger() {
            if (this.workflow_triggers && Array.isArray(this.workflow_triggers)) {
                return !this.workflow_triggers.some(trigger => trigger.type == 'contact')
            }

            return true
        },

        canAddContactDispositionTrigger() {
            if (this.workflow_triggers && Array.isArray(this.workflow_triggers)) {
                return !this.workflow_triggers.some(trigger => trigger.type == 'disposition' && trigger.disposition_status_id === 0)
            }

            return true
        },

        canAddCallDispositionTrigger() {
            if (this.workflow_triggers && Array.isArray(this.workflow_triggers)) {
                return !this.workflow_triggers.some(trigger => trigger.type == 'call disposition' && trigger.call_disposition_id === 0)
            }

            return true
        },

        usedContactDispositionStatuses() {
            if (this.workflow_triggers && Array.isArray(this.workflow_triggers)) {
                return this.workflow_triggers.map(trigger => (trigger.disposition_status_id) ? trigger.disposition_status_id : null).filter(o => o !== null)
            }

            return []
        },

        contactDispositionTriggerIndex() {
            let count = -1
            for (let index in this.workflow_triggers) {
                if (this.workflow_triggers[index].type === 'disposition') {
                    count++
                }
            }
            return count
        },

        callDispositionTriggerIndex() {
            let count = -1
            for (let index in this.workflow_triggers) {
                if (this.workflow_triggers[index].type === 'call disposition') {
                    count++
                }
            }
            return count
        },
    },

    created() {
        if (this.intake_route_list) {
            this.intake_route_list.forEach((intake_route) => {
                if (intake_route.type == 'email') {
                    this.has_email_intake = true
                }
            })
        }

        if (this.triggers) {
            this.workflow_triggers = [...this.triggers]
            this.original_triggers = this.triggers
        }

        if (!this.triggers) {
            this.workflow_triggers = []
            this.original_triggers = []
        }

        if (this.mailgun_intake_route) {
            this.mailgun_intake_route_string = this.mailgun_intake_route
        }

        if (!this.mailgun_intake_route) {
            this.show_install_email_service = true
        }

        this.getStatics()
    },

    methods: {
        copyWorkflowTriggers(copy_trigger_workflow_id) {
            axios.post(`/api/v1/automations/workflows/${copy_trigger_workflow_id}/copy-triggers-to/${this.value.id}`, {}).then(res => {
                this.workflow_triggers = res.data.triggers

                this.$notify({
                    offset: 75,
                    title: 'Triggers',
                    message: res.data.message,
                    type: 'success',
                    showClose: true,
                    duration: 3000
                })

                this.show_copy_trigger_popover = false
            })
        },

        addCallTrigger() {
            let trigger = {
                id: this.$uuid.v4(),
                type: CommunicationTypes.CALL,
                direction: null,
                disposition_status: null,
                operation: null,
                workflow_ids: [],
                tag_ids: [],
                disposition_status_id: null,
                has_condition: false,
                symbol: null,
                variable: null,
                value: 0,
                keywords: [],
                valid: false
            }
            this.workflow_triggers.push(trigger)

            this.validateChanges()
        },

        addSmsTrigger() {
            let trigger = {
                id: this.$uuid.v4(),
                type: CommunicationTypes.SMS,
                direction: null,
                disposition_status: null,
                operation: null,
                workflow_ids: [],
                tag_ids: [],
                disposition_status_id: null,
                has_condition: false,
                symbol: null,
                variable: null,
                value: 0,
                keywords: [],
                valid: false
            }
            this.workflow_triggers.push(trigger)

            this.validateChanges()
        },

        addContactTrigger() {
            let trigger = {
                id: this.$uuid.v4(),
                type: 'contact',
                direction: null,
                disposition_status: null,
                operation: null,
                workflow_ids: [],
                tag_ids: [],
                disposition_status_id: null,
                has_condition: false,
                symbol: null,
                variable: null,
                value: 0,
                keywords: [],
                valid: false
            }
            this.workflow_triggers.push(trigger)

            this.validateChanges()
        },

        addEmailTrigger() {
            let trigger = {
                id: this.$uuid.v4(),
                type: CommunicationTypes.EMAIL,
                direction: null,
                disposition_status: null,
                operation: null,
                workflow_ids: [],
                tag_ids: [],
                disposition_status_id: null,
                has_condition: false,
                symbol: null,
                variable: null,
                value: 0,
                keywords: [],
                valid: false
            }
            this.workflow_triggers.push(trigger)

            this.validateChanges()
        },

        addAutomationContactTrigger() {
            let trigger = {
                id: this.$uuid.v4(),
                type: 'contact',
                valid: true
            }
            this.workflow_triggers.push(trigger)

            this.validateChanges()
            this.disable_save_trigger = true
        },

        addAutomationCallTrigger() {
            let trigger = {
                id: this.$uuid.v4(),
                type: CommunicationTypes.CALL,
                direction: CommunicationDirections.OUTBOUND,
                disposition_status: CommunicationDispositionStatus.DISPOSITION_STATUS_PLACED_NEW,
                operation: 'disenroll',
                has_condition: true,
                symbol: '>=',
                variable: 'duration',
                value: 0,
                valid: true
            }
            this.workflow_triggers.push(trigger)

            this.validateChanges()
            this.disable_save_trigger = true
        },

        addContactDispositionTrigger() {
            let trigger = {
                id: this.$uuid.v4(),
                type: 'disposition',
                disposition_status_id: null,
                valid: false
            }
            this.workflow_triggers.push(trigger)

            this.validateChanges()
            this.disable_save_trigger = true
        },

        addContactTaggedTrigger() {
            let trigger = {
                id: this.$uuid.v4(),
                type: 'tag',
                tag_option_id: 1,
                enroll_action: 'enroll',
                tag_ids: null,
                valid: false
            }

            this.workflow_triggers.push(trigger)

            this.validateChanges()
            this.disable_save_trigger = true
        },

        addContactTagRemovedTrigger() {
            let trigger = {
                id: this.$uuid.v4(),
                type: 'remove_tag',
                tag_option_id: 1,
                enroll_action: 'enroll',
                tag_ids: null,
                valid: false
            }

            this.workflow_triggers.push(trigger)

            this.validateChanges()
            this.disable_save_trigger = true
        },

        addCallDispositionTrigger() {
            let trigger = {
                id: this.$uuid.v4(),
                type: 'call disposition',
                call_disposition_id: null,
                valid: false
            }

            if (!this.automation_trigger) {
                trigger.workflow_ids = []
            }

            this.workflow_triggers.push(trigger)

            this.validateChanges()
            this.disable_save_trigger = true
        },

        addContactDNCTrigger() {
            let trigger = {
                id: this.$uuid.v4(),
                type: 'dnc status',
                trigger_option: null,
                enroll_action: 'enroll',
                valid: false
            }

            this.workflow_triggers.push(trigger)

            this.validateChanges()
            this.disable_save_trigger = true
        },

        addAppointmentTrigger() {
            let trigger = {
                id: this.$uuid.v4(),
                type: 'appointment',
                appointment_status_id: null,
                disposition_status_id: null,
                valid: false
            }

            this.workflow_triggers.push(trigger)

            this.validateChanges()
            this.disable_save_trigger = true
        },

        removeAt(idx) {
            this.workflow_triggers.splice(idx, 1)
            this.validateChanges()
        },

        duplicate(trigger, idx) {
            let duplicated_trigger
            if (!this.automation_trigger) {
                duplicated_trigger = {
                    id: this.$uuid.v4(),
                    type: trigger.type,
                    direction: trigger.direction,
                    disposition_status: trigger.disposition_status,
                    operation: null,
                    workflow_ids: [],
                    tag_ids: [],
                    has_condition: trigger.has_condition,
                    symbol: trigger.symbol,
                    variable: trigger.variable,
                    value: trigger.value,
                    keywords: [],
                    valid: false
                }
            } else {
                duplicated_trigger = {
                    id: this.$uuid.v4(),
                    type: trigger.type,
                    valid: false
                }
                if (trigger.type === 'call disposition') {
                    duplicated_trigger.call_disposition_id = null
                }
                if (trigger.type === 'disposition') {
                    duplicated_trigger.disposition_status_id = null
                }
            }

            this.workflow_triggers.splice(idx + 1, 0, duplicated_trigger)
            this.validateChanges()
        },

        validateChanges() {
            setTimeout(() => {
                if (this.workflow_triggers !== this.original_triggers && this.validTriggers()) {
                    this.disable_save_trigger = false
                }

                if (this.workflow_triggers === this.original_triggers) {
                    this.disable_save_trigger = true
                }
            }, 100)
        },

        saveTriggers() {
            this.loading = true
            axios.put(`/api/v1/automations/workflows/` + this.$route.params.workflow_id + `/triggers`, {
                triggers: this.workflow_triggers
            }).then(res => {
                this.$notify({
                    offset: 95,
                    title: 'Sequences',
                    message: res.data.message,
                    type: 'success',
                    duration: 6000,
                })
                this.loading = false
                this.disable_save_trigger = true
            }).catch(err => {
                this.loading = false
                this.$root.handleErrors(err.response)
            })
        },

        installEmailService() {
            this.loading_install_email_service = true
            axios.post('/api/v1/automations/workflows/' + this.$route.params.workflow_id + '/email-service')
                .then(res => {
                    this.mailgun_intake_route_string = res.data.workflow.mailgun_intake_route
                    this.loading_install_email_service = false
                    this.show_install_email_service = false

                    this.$notify({
                        offset: 75,
                        title: 'Lead Intake by Email',
                        message: res.data.message,
                        type: 'success',
                        showClose: true,
                        duration: 3000
                    })
                })
                .catch(err => {
                    this.$notify({
                        offset: 75,
                        title: 'Lead Intake by Email',
                        message: err.response.data.message,
                        type: 'error',
                        showClose: true,
                        duration: 3000
                    })

                    this.loading_install_email_service = false
                })
        },

        validTriggers() {
            return !this.workflow_triggers.find(trigger => !trigger.valid)
        },

        updateTrigger(trigger) {
            let index = this.workflow_triggers.findIndex(trig => {
                    return trig.id == trigger.id
            })

            this.$set(this.workflow_triggers, index, trigger)

            this.validateChanges()
        },

        updateValidation(valid) {
            this.disable_save_trigger = !valid
        },


        getTriggerTypeName(trigger_type) {
            if (trigger_type === 'disposition') {
                trigger_type = 'Contact ' + trigger_type
            }

            if (trigger_type === 'appointment') {
                trigger_type = 'Appointment'
            }

            if (trigger_type === 'dnc status') {
                trigger_type = 'DNC Status'
            }

            if (trigger_type === 'tag') {
                trigger_type = 'Add Contact Tagged'
            }

            if (trigger_type === 'remove_tag') {
                trigger_type = 'Add Contact Tag Removed'
            }

            return _.startCase(trigger_type)
        },
    }
}
</script>
