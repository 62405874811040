<template>
    <div class="row no-gutter"
         :class="[ no_padding ? '' : 'pt-2 pl-2 pr-2' ]">
        <div class="col-md-3 text-center"
             v-if="hubspotLink">
            <a class="md-btn md-raised white hubspot-btn"
               data-testid="crm-hubspot-link"
               :href="hubspotLink"
               target="_blank">
            </a>
            <span class="d-block text-xs text-muted">HubSpot</span>
        </div>
        <div class="col-md-3 text-center"
             v-if="pipedriveLink">
            <a class="md-btn md-raised white pipedrive-btn"
               :href="pipedriveLink"
               data-testid="crm-pipedrive-link"
               target="_blank">
            </a>
            <span class="d-block text-xs text-muted">Pipedrive</span>
        </div>
        <div class="col-md-3 text-center"
             v-if="stripeLink">
            <a class="md-btn md-raised white stripe-btn"
               :href="stripeLink"
               data-testid="crm-stripe-link"
               target="_blank">
            </a>
            <span class="d-block text-xs text-muted">Stripe</span>
        </div>
        <div class="col-md-3 text-center"
             v-if="batsLink">
            <a class="md-btn md-raised blue bats-btn"
               :href="batsLink"
               data-testid="crm-bats-link"
               target="_blank">
            </a>
            <span class="d-block text-xs text-muted">BATS</span>
        </div>
        <div class="col-md-3 text-center"
             v-if="zohoContactLink">
            <a class="md-btn md-raised white zoho-btn"
               :href="zohoContactLink"
               data-testid="crm-zoho-contact-link"
               target="_blank">
            </a>
            <span class="d-block text-xs text-muted">Zoho Contact</span>
        </div>
        <div class="col-md-3 text-center"
             v-if="zohoLeadLink">
            <a class="md-btn md-raised white zoho-btn"
               :href="zohoLeadLink"
               data-testid="crm-zoho-lead-link"
               target="_blank">
            </a>
            <span class="d-block text-xs text-muted">Zoho Lead</span>
        </div>
        <div class="col-md-3 text-center"
             v-if="helpscoutLink">
            <a class="md-btn md-raised white helpscout-btn"
               :href="helpscoutLink"
               data-testid="crm-help-scout-link"
               target="_blank">
            </a>
            <span class="d-block text-xs text-muted">Help Scout</span>
        </div>
        <div class="col-md-3 text-center"
             v-if="guestyLink">
            <a class="md-btn md-raised white guesty-btn"
               :href="guestyLink"
               data-testid="crm-guesty-link"
               target="_blank">
            </a>
            <span class="d-block text-xs text-muted">Guesty</span>
        </div>
        <div class="col-md-3 text-center"
             v-if="goHighLevelLink">
            <a class="md-btn md-raised white gohighlevel-btn"
               :href="goHighLevelLink"
               data-testid="crm-go-high-level-link"
               target="_blank">
            </a>
            <span class="d-block text-xs text-muted">GoHighLevel</span>
        </div>
        <div class="col-md-3 text-center"
             v-if="customLink">
            <a class="md-btn md-raised white custom-link-builder-btn"
               :href="customLink"
               data-testid="crm-customn-link"
               target="_blank">
            </a>
            <span class="d-block text-xs text-muted">Custom Link</span>
        </div>
    </div>
</template>

<script>
    import auth from '../auth'
    import {mapState} from 'vuex'

    export default {
        props: {
            contact: {
                required: true
            },

            no_padding: {
                type: Boolean,
                required: false,
                default: false
            },
        },

        data() {
            return {
                auth: auth,
            }
        },

        computed: {
            ...mapState('cache', ['current_company']),

            hubspotLink() {
                if (this.current_company &&
                    this.current_company.hubspot_integration_enabled &&
                    this.contact &&
                    this.contact.integration_data &&
                    this.contact.integration_data.hubspot &&
                    this.contact.integration_data.hubspot.contact_id &&
                    this.current_company.hubspot_marketing_portal_id) {
                        const ui_domain = this.current_company.hubspot_company_ui_domain ? this.current_company.hubspot_company_ui_domain : 'app.hubspot.com'
                        return `https://${ui_domain}/contacts/${this.current_company.hubspot_marketing_portal_id}/contact/${this.contact.integration_data.hubspot.contact_id}`
                }

                return false
            },

            pipedriveLink() {
                if (this.current_company &&
                    this.current_company.pipedrive_integration_enabled &&
                    this.contact &&
                    this.contact.integration_data &&
                    this.contact.integration_data.pipedrive &&
                    this.contact.integration_data.pipedrive.person_id &&
                    this.current_company.pipedrive_company_domain) {
                    return `https://${this.current_company.pipedrive_company_domain}.pipedrive.com/person/${this.contact.integration_data.pipedrive.person_id}`
                }

                return false
            },

            batsLink() {
                // @custom for Direct Auto
                if (this.current_company &&
                    this.current_company.id == 460 &&
                    this.contact &&
                    this.contact.phone_number) {
                    let phone_number = this.contact.phone_number
                    phone_number = phone_number.replace('+1', '')
                    return `https://www.batscrm.com/search/phone/` + phone_number
                }

                return false
            },

            stripeLink() {
                if (this.current_company &&
                    this.current_company.stripe_integration_enabled &&
                    this.contact &&
                    this.contact.first_name) {
                    return `https://dashboard.stripe.com/test/search?query=${this.contact.first_name}`
                }

                return false
            },

            zohoContactLink() {
                if (this.current_company &&
                    this.current_company.zoho_integration_enabled &&
                    this.contact &&
                    this.contact.integration_data &&
                    this.contact.integration_data.zoho &&
                    this.contact.integration_data.zoho.contact_id &&
                    this.contact.integration_data.zoho.contact_link) {
                    return this.contact.integration_data.zoho.contact_link
                }

                return false
            },

            zohoLeadLink() {
                if (this.current_company &&
                    this.current_company.zoho_integration_enabled &&
                    this.contact &&
                    this.contact.integration_data &&
                    this.contact.integration_data.zoho &&
                    this.contact.integration_data.zoho.lead_id &&
                    this.contact.integration_data.zoho.lead_link) {
                    return this.contact.integration_data.zoho.lead_link
                }

                return false
            },

            helpscoutLink() {
                if (this.current_company &&
                    this.current_company.helpscout_integration_enabled &&
                    this.contact &&
                    this.contact.integration_data &&
                    this.contact.integration_data.helpscout &&
                    this.contact.integration_data.helpscout.customer_id &&
                    this.current_company.helpscout_mailbox_id) {
                    return `https://secure.helpscout.net/customer/${this.contact.integration_data.helpscout.customer_id}/${this.current_company.helpscout_mailbox_id}`
                }

                return false
            },

            guestyLink() {
                if (this.current_company &&
                    this.current_company.guesty_integration_enabled &&
                    this.contact &&
                    this.contact.integration_data &&
                    this.contact.integration_data.guesty &&
                    this.contact.integration_data.guesty.guest_id) {
                    return `https://app.guesty.com/people/contact/${this.contact.integration_data.guesty.guest_id}/profile`
                }

                return false
            },

            goHighLevelLink() {
                if (this.current_company &&
                    this.current_company.gohighlevel_integration_enabled &&
                    this.contact &&
                    this.contact.integration_data &&
                    this.contact.integration_data.gohighlevel &&
                    this.contact.integration_data.gohighlevel.contact_id) {
                    const ghl_contact_link = this.current_company.gohighlevel_contact_link ? this.current_company.gohighlevel_contact_link : ''
                    const ghl_contact_id = this.contact.integration_data.gohighlevel.contact_id
                    return ghl_contact_link + ghl_contact_id
                }

                return false
            },

            customLink() {
                if (this.current_company?.custom_link_builder_integration_enabled &&
                    this.contact?.custom_link) {
                    return this.contact.custom_link
                }

                return false
            }
        },
    }
</script>
