<template>
    <div>
        <div class="col-12">
            <p class="font-weight-bold side-panel-title">Appointment Panel</p>
        </div>

        <div class="col-12 mt-2">
            <p class="mb-0 font-weight-bold">Type:</p>
            <el-radio-group v-model="nodeData.type">
                <el-radio-button v-for="type in APPOINTMENT_TYPES"
                                 :key="type[0]"
                                 :label="type[0]">
                    {{ type[1].label }}
                </el-radio-button>
            </el-radio-group>
        </div>

        <div class="col-12 mt-2">
            <p class="mb-0 font-weight-bold">Note:</p>
            <el-input
                type="textarea"
                placeholder="Type appointment note here."
                :rows="3"
                v-model="nodeData.note">
            </el-input>
        </div>

        <div class="col-12 text-right">
            <el-tooltip
                content="Add variable"
                placement="top">
                <variables @variable-selected="noteVariableSelected"/>
            </el-tooltip>
        </div>

        <div class="col-12 mt-2">
            <p class="mb-0 font-weight-bold">Date Message:</p>
            <el-input
                type="textarea"
                placeholder="Type the date message here."
                :rows="3"
                v-model="nodeData.date_message">
            </el-input>
        </div>

        <div class="col-12 text-right">
            <el-tooltip
                content="Add variable"
                placement="top">
                <variables @variable-selected="dateVariableSelected"/>
            </el-tooltip>
        </div>

        <div class="col-12">
            <p class="mb-0 font-weight-bold">Number of date suggestion:</p>
            <el-select
                v-model="nodeData.date_suggestion_number"
                placeholder="Number">
                <el-option
                    v-for="number in date_numbers"
                    :key="number"
                    :label="number"
                    :value="number"/>
            </el-select>
        </div>

        <div class="col-12  mt-2">
            <p class="mb-0 font-weight-bold">Time Message:</p>
            <el-input
                type="textarea"
                placeholder="Type the time message here."
                :rows="3"
                v-model="nodeData.time_message">
            </el-input>
        </div>

        <div class="col-12 text-right">
            <el-tooltip
                content="Add variable"
                placement="top">
                <variables @variable-selected="timeVariableSelected"/>
            </el-tooltip>
        </div>

        <div class="col-12">
            <p class="mb-0 font-weight-bold">Number of time suggestion:</p>
            <el-select
                v-model="nodeData.time_suggestion_number"
                default-first-option
                placeholder="Numbers">
                <el-option
                    v-for="number in time_numbers"
                    :key="number"
                    :label="number"
                    :value="number"/>
            </el-select>
        </div>

        <div class="col-12  mt-2">
            <p class="mb-0 font-weight-bold">Service:</p>
            <el-select
                v-model="nodeData.service_id"
                placeholder="Select Appointment Service">
                <el-option
                    v-for="appointment_service in appointment_services"
                    v-bind:key="appointment_service.id"
                    :label="`${appointment_service.name} (${appointment_service.duration} min.)`"
                    :value="appointment_service.id"/>
            </el-select>
        </div>
        <hr>

        <el-checkbox class="col-12 mt-2"
                     v-model="nodeData.reminder_enabled">
            SMS reminder
        </el-checkbox>

        <div v-if="nodeData.reminder_enabled">
            <div class="col-12 mt-2">
                <p class="mb-0 font-weight-bold">Reminder message:</p>
                <el-input
                    type="textarea"
                    placeholder="Reminder message"
                    :rows="3"
                    v-model="nodeData.reminder_message">
                </el-input>
            </div>

            <div class="col-12 text-right">
                <el-tooltip
                    content="Add variable"
                    placement="top">
                    <Variables @variable-selected="reminderVariableSelected"/>
                </el-tooltip>
            </div>

            <div class="col-12 mt-2">
                <p class="mb-0 font-weight-bold">Time:</p>
                <el-select
                    v-model="nodeData.reminder_time"
                    placeholder="Reminder Time">
                    <el-option
                        v-for="time in reminder_times"
                        :key="time"
                        :label="`${time} minutes before`"
                        :value="time"/>
                </el-select>
            </div>

            <div class="col-12 mt-2">
                <p class="mb-0 font-weight-bold">Select a Workflow:</p>
                <el-select
                    v-model="nodeData.reminder_workflow_id"
                    filterable
                    placeholder="Sequence">

                    <el-option
                        v-for="workflow in workflows"
                        v-bind:key="workflow.id"
                        :label="workflow.name"
                        :value="workflow.id"/>
                </el-select>
            </div>
        </div>

        <div class="col-12 mt-4">
            <Fallback
                ref="fallback"
                :fallbacks.sync="fallbacks"
                @change="updateFallback"
                :date-time="true"/>
        </div>
    </div>
</template>

<script>
import {alohabot_side_panel_mixin} from '../../../mixins'
import CONSTANTS from '../contants'
import {mapState} from 'vuex'
import Fallback from './panel-fallback-component.vue'
import Variables from '../../messenger/variables'

export default {
    name: 'custom-panel',
    components: {
        Fallback,
        Variables
    },
    props: {
        appointment_services: {
            required: true,
            default: []
        }
    },
    mixins: [
        alohabot_side_panel_mixin
    ],
    data() {
        return {
            date_numbers: Array.from(Array(15).keys()).map(number => number + 1),
            time_numbers: Array.from(Array(20).keys()).map(number => number + 1),
            reminder_times: [
                5,
                10,
                15,
                20,
                30,
                45,
                60
            ],
            CONSTANTS
        }
    },
    computed: {
        APPOINTMENT_TYPES() {
            return Object.entries(this.CONSTANTS.APPOINTMENT_TYPES)
        },
        ...mapState(['workflows']),
    },
    watch: {
        fallbacks(value) {
            let copy = JSON.parse(JSON.stringify(this.nodeData))
            copy.fallback = value
            this.nodeData = copy
        }
    },
    methods: {
        /**
         * Handles saving the panel.
         *
         * @param {object} payload
         *
         * @return {void}
         */
        handleSaveState(payload) {
            this.$refs['fallback'].handleSaveState()
        },

        /**
         * Set selected date variable
         *
         * @param {string} variable
         *
         * @return {void}
         */
        dateVariableSelected(variable) {
            this.nodeData.date_message = (this.nodeData.date_message ?? '') + ' ' + variable
        },

        /**
         * Set selected note variable
         *
         * @param {string} variable
         *
         * @return {void}
         */
        noteVariableSelected(variable) {
            this.nodeData.note = (this.nodeData.note ?? '') + ' ' + variable
        },

        /**
         * Set selected reminder message variable
         *
         * @param {string} variable
         *
         * @return {void}
         */
        reminderVariableSelected(variable) {
            this.nodeData.reminder_message = (this.nodeData.reminder_message ?? '') + ' ' + variable
        },

        /**
         * Set selected time variable
         *
         * @param {string} variable
         *
         * @return {void}
         */
        timeVariableSelected(variable) {
            this.nodeData.time_message = (this.nodeData.time_message ?? '') + ' ' + variable
        },
    }
}
</script>
