var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "app-body pb-0", attrs: { id: "view" } },
    [
      _vm.shouldShowCalendar && !_vm.is_loading
        ? [
            _c("div", { staticClass: "pl-3 pr-3" }, [_c("calendar")], 1),
            _vm._v(" "),
            _c("el-backtop", {
              ref: "backtop",
              attrs: { target: "html", bottom: 20, right: 20 },
            }),
          ]
        : _vm._e(),
      _vm._v(" "),
      !_vm.shouldShowCalendar && _vm.shouldShowUpgradeNow
        ? _c("upgrade-now-page", {
            staticClass: "mt-5",
            attrs: {
              "image-link": "/assets/images/Calendar.svg",
              text: "Simplify your appointment scheduling and receive timely reminders with Calendar",
              "extra-text": "Upgrade today to unlock this feature",
              "title-text": "Calendar",
              "kb-link":
                "https://support.aloware.com/en/articles/9034189-the-aloware-talk-calendar",
            },
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }