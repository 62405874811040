var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.contact &&
    _vm.contact.broadcasts &&
    _vm.contact.broadcasts.length > 0 &&
    _vm.hasPermissionTo("list broadcast")
    ? _c(
        "div",
        { staticClass: "row no-gutter" },
        _vm._l(_vm.contact.broadcasts, function (broadcast) {
          return _c(
            "div",
            { staticClass: "text-center mr-2 mb-2" },
            [
              _c("router-link", { attrs: { to: { name: "Broadcast" } } }, [
                _c(
                  "a",
                  {
                    staticClass: "btn btn-xs bluish",
                    attrs: { "data-testid": "contact-broadcasts-link" },
                  },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s(broadcast.name) +
                        "\n            "
                    ),
                  ]
                ),
              ]),
            ],
            1
          )
        }),
        0
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }