<template>
    <el-form
        v-loading="loading"
        :model="useCaseRegistrationForm"
        :rules="rules"
        autocomplete="off"
        label-position="top"
        ref="useCaseRegistrationForm"
    >
        <div class="row">
            <div class="col-md-12">
                <h5>A2P Use Case Registration</h5>
                <div class="dropdown-divider" />

                <el-alert
                    v-if="!selected_messaging_service?.a2p_use_case"
                    title="Important"
                    class="mb-2"
                    description="Purposefully misleading your campaign will result in violations and possible termination."
                    type="info"
                    show-icon
                />

                <el-alert
                    v-if="selected_messaging_service?.a2p_use_case?.prevetting_status === ComplianceStatuses.STATUS_REJECTED"
                    :description="selected_messaging_service?.a2p_use_case?.failure_reason"
                    title="A2P Campaign Pre-Vetting Rejected"
                    class="mb-2"
                    type="error"
                    show-icon
                    :closable="false"
                >
                    <p class="mb-0">
                        <span class="text-dark">
                            {{ selected_messaging_service?.a2p_use_case?.failure_reason }}
                        </span>
                    </p>
                </el-alert>

                <el-alert
                    v-for="error in selected_messaging_service?.a2p_use_case?.errors"
                    :key="error.error_code"
                    :description="`Error Code ${error.error_code}: ${error.description}`"
                    :title="fixToUcFirst(error.fields[0])"
                    class="mb-2"
                    type="error"
                    show-icon
                    :closable="false"
                />

                <el-form-item label="Use Case" prop="use_case">
                    <span class="page-anchor" />
                    <p v-if="!isSimpSocial" class="line-height-normal">Read our <a class="font-weight-bold text-blue" target="_blank" href="https://support.aloware.com/en/articles/9031988-a2p-10dlc-compliance-campaign-registration">Easy Guide</a> on what Use Case to choose.</p>

                    <div class="switch-label">
                        <el-radio-group
                            v-model="useCaseRegistrationForm.use_case"
                            :disabled="shouldDisableForm"
                            @change="useCaseChanged()"
                        >
                            <el-radio
                                v-for="use_case in selectableUseCases"
                                :key="use_case.value"
                                :label="use_case.value"
                                class="d-flex"
                            >
                                <div>
                                    <span class="font-weight-bold">{{ use_case.title }}</span>: {{ use_case.description }}
                                </div>
                            </el-radio>
                        </el-radio-group>
                    </div>
                </el-form-item>

                <el-form-item label="Unregistered Lines">
                    <span class="page-anchor" />
                    <p class="line-height-normal">You can register up to 400 numbers on a single campaign.</p>
                    <campaign-selector
                        multiple
                        :collapsable="shouldCollapseLines"
                        clearable
                        only_10_dlc
                        :value="line_ids"
                        :is_a2p_campaign_wizard="true"
                        :show_paused="true"
                        :unregistered_lines_only="true"
                        :disabled="shouldUnregisteredLines || shouldDisableForm"
                        @change="addLine"
                        @input="addLine"
                    />
                </el-form-item>

                <el-form-item label="Campaign Description" prop="description">
                    <span class="page-anchor" />
                    <p class="line-height-normal">Describe what you will be using this campaign for including the campaign's purpose, audience and sender. <a v-if="!isSimpSocial" class="font-weight-bold text-blue" target="_blank" href="https://support.aloware.com/en/articles/9031981-a2p-10dlc-compliance-brand-registration-guide">Learn more</a></p>
                    
                    <el-input
                        v-model="useCaseRegistrationForm.description"
                        :disabled="shouldDisableForm"
                        @input="preValidateForm('useCaseRegistrationForm')"
                    />
                    <p class="line-height-normal mb-0"><b>* Example</b>: <span class="font-italic">Provide assistance to our current customers, mainly conversational communication.</span></p>
                </el-form-item>

                <el-form-item prop="message_flow" label="How do end-users consent to receive messages? (40-2048 characters)">
                    <span class="page-anchor" />
                    <p class="line-height-normal">Describe how users opt-in to your campaign, therefore giving consent to the sender to receive their messages.</p>

                    <el-input
                        type="textarea"
                        v-model="useCaseRegistrationForm.message_flow"
                        :disabled="shouldDisableForm"
                        @input="preValidateForm('useCaseRegistrationForm')"
                    />
                    <p class="line-height-normal mb-0"><b>* Example</b>: <span class="font-italic">End users opt-in by visiting www.examplewebsite.com and adding their number. They then check a box agreeing to receive text messages from Example Brand. Additionally, end users can also opt-in by texting START to (111) 222-3333 to opt-in.</span><span v-popover:popover class="font-weight-bold text-blue ml-1">Learn more</span></p>

                    <el-popover
                        ref="popover"
                        placement="top-start"
                        title="How do end-users consent to receive messages?"
                        width="800"
                        trigger="hover">
                        <p class="line-height-normal">This field should describe how and when consumers opt-in to the campaign, therefore giving consent to the sender to receive their messages. The call-to-action must be explicitly clear and inform consumers of the nature of the program. If multiple opt-in methods can be used for the same, list them all.</p>
                        <ul>
                            <li>Provide website where opt-in occurs</li>
                            <li>List all opt-in methods</li>
                            <li>Include an opt-in message if users can text</li>
                            <li>If a website is provided, it must be accessible with privacy policy and terms</li>
                        </ul>
                        <p class="line-height-normal"><span class="font-weight-bold">Successful Example:</span>
                        "Customers provide opt-in specifically when enrolling into our website, or in person by requesting SMS capabilities within the store. Opt-in during website is a self-service process and occurs at acme.com/signup. By submitting, you authorize ACME to send text messages with offers and other information. Message/data rates apply. Consent is not a condition of purchase. [Link to terms and conditions]."</p>
                        <p class="line-height-normal"><span class="font-weight-bold">Failure Example:</span>
                        "Customers sign up"
                        (Where and how the customer provides opt-in is unclear, campaign will be rejected.)</p>
                    </el-popover>
                </el-form-item>

                <el-form-item prop="sample_message_1">
                    <template slot="label">
                        Sample Message #1
                        <el-tooltip
                            class="item"
                            effect="dark"
                            content="Click to pick a random message from your Outbound SMS"
                            placement="right"
                        >
                            <i class="material-icons hover-lower-opacity-primary text-primary" @click="randomizeCommunicationSamples('sample_message_1')">sync</i>
                        </el-tooltip>
                    </template>
                    <span class="page-anchor" />
                    <p class="line-height-normal">Make sure to include compliant opt-out language in your sample messages, such as <i>Text STOP to unsubscribe</i>. <b class="text-primary">Failure to include opt-out language will result in failed registration.</b></p>

                    <el-input
                        type="textarea"
                        v-model="useCaseRegistrationForm.sample_message_1"
                        :disabled="shouldDisableForm"
                        @input="preValidateForm('useCaseRegistrationForm')"
                    />
                    <p class="line-height-normal mb-0"><b>* Example</b>: <span class="font-italic">Hi {Customer Name}, this is {Agent Name} from {Company Name}. Thank you for reaching out. Reply with your question or call us at {Phone Number}. For account updates, visit {Company Website}. Reply STOP to opt-out.</span></p>
                </el-form-item>

                <el-form-item prop="sample_message_2">
                    <template slot="label">
                        Sample Message #2
                        <el-tooltip
                            class="item"
                            effect="dark"
                            content="Click to pick a random message from your Outbound SMS"
                            placement="right"
                        >
                            <i class="material-icons hover-lower-opacity-primary text-primary" @click="randomizeCommunicationSamples('sample_message_2')">sync</i>
                        </el-tooltip>
                    </template>
                    <span class="page-anchor" />
                    <p class="line-height-normal">Please provide another example of the message that you intend to send within this campaign.</p>

                    <el-input
                        type="textarea"
                        v-model="useCaseRegistrationForm.sample_message_2"
                        :disabled="shouldDisableForm"
                        @input="preValidateForm('useCaseRegistrationForm')"
                    />
                    <p class="line-height-normal mb-0"><b>* Example</b>: <span class="font-italic">🌟 Exclusive Offer from {Company Name}! Unlock 20% off your next purchase with code WELCOME20. Shop now at {Company Website}. Reply STOP to opt-out.</span></p>
                </el-form-item>

                <el-form-item prop="message_contents" label="Message Contents">
                    <span class="page-anchor" />

                    <el-checkbox v-model="useCaseRegistrationForm.has_embedded_links" :disabled="shouldDisableForm">
                        Messages will include embedded links.
                    </el-checkbox>
                    <el-checkbox v-model="useCaseRegistrationForm.has_embedded_phone" :disabled="shouldDisableForm">
                        Messages will include phone numbers.
                    </el-checkbox>
                </el-form-item>

                <el-form-item prop="message_restrictions" label="Message Restrictions">
                    <span class="page-anchor" />
                    <el-checkbox v-model="useCaseRegistrationForm.direct_lending" :disabled="shouldDisableForm">
                        Message will include content related to Direct Lending or other Loan Arrangement.
                    </el-checkbox>
                    <br/>
                    <small class="text-muted">High Risk Loans, Indirect Loans and Debt Collection are prohibited under A2P 10DLC Guidelines</small>
                    <br/>
                    <el-checkbox v-model="useCaseRegistrationForm.age_gated" :disabled="shouldDisableForm">
                        Messages will include age-gated content.
                    </el-checkbox>
                    <br/>
                    <small class="text-muted">Sex, Hate, Alcohol, Firearms, Tobacco (SHAFT) and Cannabis content are prohibited under A2P 10DLC and <a class="font-weight-bold text-blue" target="_blank" href="https://www.ctia.org/the-wireless-industry/industry-commitments/messaging-interoperability-sms-mms">CTIA Guidelines</a></small>
                    <br/>
                </el-form-item>

                <el-form-item
                    prop="accepted_campaign_fee"
                    class="mt-4"
                    label="One-time Per Campaign Fee"
                >
                    <p class="form-label mb-0">
                        A non-refundable one-time fee of $15 is charged to manually vet each A2P Campaign. The manual vetting process is an industry standard to ensure the quality of messages through A2P 10DLC registered channels.
                    </p>
                    <el-checkbox
                        label="I agree to a one-time non-refundable $15 fee."
                        v-model="useCaseRegistrationForm.accepted_campaign_fee"
                        :disabled="shouldDisableForm"
                        @change="preValidateForm('useCaseRegistrationForm')"
                    />
                </el-form-item>

                <el-form-item
                    prop="agree_terms_and_conditions"
                    label="Agree Terms and Conditions"
                >
                    <span class="page-anchor" />
                    <el-checkbox
                        label="I agree to the terms and conditions and monthly recurring $10 carrier fee."
                        v-model="useCaseRegistrationForm.agree_terms_and_conditions"
                        :disabled="shouldDisableForm"
                        @change="preValidateForm('useCaseRegistrationForm')"
                    />

                    <el-popover
                        ref="agree_terms_and_conditions_learn_more"
                        placement="right-start"
                        title="Do you want to add an additional campaign?"
                        width="400"
                        trigger="hover"
                        content="The allowed message volume depends on the trust score of your business. Ranges can start from 2k - 10k per day. Your messages will be capped once you reach your daily limit. Limits may be increased as your brand score improves.">
                    </el-popover>
                    <span
                        class="form-hover-help"
                        v-popover:agree_terms_and_conditions_learn_more
                    >
                        Learn more
                    </span>
                </el-form-item>

                <div class="row w-full">
                    <div class="col-md-8 mb-3 mb-md-0 flex-3">
                        <alert-message
                            :alert-message="alertMessage"
                            @alert="submitAlert"
                        />
                    </div>
                    <div class="w-full flex-1">
                        <el-button
                            type="success"
                            @click="openUseCaseRegistrationConfirmation"
                            class="w-full"
                            :loading="loading"
                            :disabled="!validated || shouldDisableForm"
                        >
                            Finish <i class="el-icon-arrow-right el-icon-right"></i>
                        </el-button>
                    </div>
                </div>
            </div>
        </div>
    </el-form>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import {
    compliance_mixin,
    form_validation_mixin,
    campaign_mixin,
} from '../../../../mixins'
import AlertMessage from './alert-message.vue'
import CampaignSelector from '../../../campaign-selector.vue'
import {
    USE_CASES as UseCases,
    USE_CASE_CUSTOMER_CARE,
    USE_CASE_MARKETING,
    USE_CASE_MIXED,
} from '../../../../constants/compliance/a2p-use-cases'
import * as ComplianceStatuses from '../../../../constants/compliance-bundle-statuses'

export default {
    name: 'UseCaseRegistration',
    mixins: [
        compliance_mixin,
        form_validation_mixin,
        campaign_mixin,
    ],
    components: { AlertMessage, CampaignSelector },
    props: {
        selected_lines: {
            type: Array,
            required: true,
        },
        selected_messaging_service: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            UseCases,
            ComplianceStatuses,
            line_ids: [],
            useCaseRegistrationForm: {
                line_ids: [],
                accepted_campaign_fee: false,
                use_case: '',
                description: '',
                sample_message_1: '',
                sample_message_2: '',
                has_embedded_links: false,
                has_embedded_phone: false,
                age_gated: false,
                direct_lending: false,
                agree_terms_and_conditions: false,
                message_flow: '',
            },
            rules: {
                line_ids: [{
                    validator: (rule, value, callback) => {
                        /** @const {array} selected_lines */
                        const selected_lines = this.campaigns.filter(line => this.useCaseRegistrationForm.line_ids.includes(line.id))

                        // Sanity check
                        if (selected_lines.length === 0) {
                            callback(new Error('Please select at least one line.'))
                            return
                        }

                        /** @const {boolean} has_local_presence */
                        const has_local_presence = selected_lines.some(line => line.has_local_presence)

                        /** @const {boolean} has_local_presence */
                        const has_regular_lines = selected_lines.some(line => !line.has_local_presence)

                        // If the selected lines have both local presence and regular lines, throw an error
                        if (has_local_presence && has_regular_lines && this.isCompanyNetNew) {
                            callback(new Error('You cannot attach both Local Presence and Regular Lines to the same A2P Messaging Service.'))
                            return
                        }

                        /** @const {int} total_incoming_numbers - Only count 10 DLC Numbers from the selected lines */
                        const total_incoming_numbers = selected_lines.reduce((total, line) => {
                            return total + line.incoming_10_dlc_numbers.length
                        }, 0)

                        // If the total incoming numbers is greater than the current pool size, throw an error
                        if (total_incoming_numbers > this.current_pool_size) {
                            callback(new Error('Selected lines have more than 400 numbers and it will exceed the pool size limit.'))
                        } else {
                            callback()
                        }
                    },
                    type: 'array',
                    trigger: 'change'
                }],
                accepted_campaign_fee: [{
                    validator: (rule, value, callback) => {
                        if (value === true) {
                            callback()
                        } else {
                            callback(new Error('Please agree to a one-time $15 per campaign fee.'))
                        }
                    },
                    trigger: 'change',
                }],
                message_flow: [
                    {required: true, message: 'Please provide a message flow.', trigger: 'blur'},
                    {
                        min: 40,
                        message: 'Message flow is too short'
                    },
                    {
                        max: 2048,
                        message: 'Message flow is too long'
                    }
                ],
                use_case: [
                    {required: true, message: 'Please provide a use case.', trigger: 'blur'},
                ],
                description: [
                    {required: true, message: 'Please provide a description.', trigger: 'blur'},
                ],
                sample_message_1: [
                    {required: true, message: 'Please provide a sample message (#1).', trigger: 'blur'},
                ],
                sample_message_2: [
                    {required: true, message: 'Please provide a sample message (#2).', trigger: 'blur'},
                ],
                has_embedded_links: [
                    {type: 'boolean'},
                ],
                has_embedded_phone: [
                    {type: 'boolean'},
                ],
                age_gated: [
                    {type: 'boolean'},
                ],
                direct_lending: [
                    {type: 'boolean'},
                ],
                agree_terms_and_conditions: [{
                    validator: (rule, value, callback) => {
                        if (value === true) {
                            callback()
                        } else {
                            callback(new Error('Please agree terms and conditions.'))
                        }
                    },
                    trigger: 'change',
                }],
            },
            currentPoolSize: 400,
            communications_messages: []
        }
    },
    mounted() {
        if (this.selected_messaging_service?.a2p_use_case) {
            // Pre-populate the rest o the fields
            this.useCaseRegistrationForm.use_case = this.selected_messaging_service?.a2p_use_case?.use_case
            this.useCaseRegistrationForm.description = this.selected_messaging_service?.a2p_use_case?.description
            this.useCaseRegistrationForm.message_flow = this.selected_messaging_service?.a2p_use_case?.message_flow
            this.useCaseRegistrationForm.sample_message_1 = this.selected_messaging_service?.a2p_use_case?.message_samples[0]
            this.useCaseRegistrationForm.sample_message_2 = this.selected_messaging_service?.a2p_use_case?.message_samples[1]
            this.useCaseRegistrationForm.has_embedded_links = this.selected_messaging_service?.a2p_use_case?.has_embedded_links
            this.useCaseRegistrationForm.has_embedded_phone = this.selected_messaging_service?.a2p_use_case?.has_embedded_phone
            this.useCaseRegistrationForm.age_gated = this.selected_messaging_service?.a2p_use_case?.age_gated
            this.useCaseRegistrationForm.direct_lending = this.selected_messaging_service?.a2p_use_case?.direct_lending
            this.useCaseRegistrationForm.accepted_campaign_fee = this.selected_messaging_service?.a2p_use_case?.accepted_campaign_fee
            this.useCaseRegistrationForm.agree_terms_and_conditions = this.selected_messaging_service?.a2p_use_case?.agree_terms_and_conditions
        }

        VueEvent.listen('randomize_communication_outbound_messages', this.getRandomizedOutboundCommunicationSamples())

        this.preValidateForm('useCaseRegistrationForm')
    },
    computed: {
        ...mapState({
            campaigns: state => state.campaigns
        }),
        ...mapState(['easy_wizard', 'unregistered_lines']),
        ...mapGetters('cache', ['isSimpSocial']),

        selectableUseCases() {
            if (this.easy_wizard) {
                let useCasesToFilter = [
                    USE_CASE_CUSTOMER_CARE,
                    USE_CASE_MARKETING,
                    USE_CASE_MIXED
                ]
                return this.filterUseCases(useCasesToFilter)
            }
            return UseCases
        },

        shouldCollapseLines() {
            if (this.useCaseRegistrationForm.line_ids.length < 6) {
                return false
            }
            return true
        },

        shouldCollapseLines() {
            if (this.useCaseRegistrationForm.line_ids.length < 6) {
                return false
            }
            return true
        },

        shouldDisableForm() {
            // Disable if Pre-vetting is pending
            if (this.selected_messaging_service?.a2p_use_case?.prevetting_status === ComplianceStatuses.STATUS_PENDING) {
                return true
            }

            // Disable if A2P Campaign is in review or approved by Twilio using .includes()
            if (
                [
                    ComplianceStatuses.STATUS_IN_REVIEW,
                    ComplianceStatuses.STATUS_APPROVED
                ].includes(this.selected_messaging_service?.a2p_use_case?.status)
            ) {
                return true
            }

            return false
        },

        shouldUnregisteredLines() {
            return !!this.selected_messaging_service?.a2p_use_case
        },
    },
    methods: {
        addLine(line_ids) {
            this.preValidateForm('useCaseRegistrationForm')

            // Sanity check
            if (!line_ids) {
                return
            }

            this.useCaseRegistrationForm.line_ids = [...line_ids]
        },
        openUseCaseRegistrationConfirmation() {
            this.$confirm(`Are you sure you want to register this A2P Use Case?`, 'Warning', {
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                customClass: 'w-25 fixed',
                confirmButtonClass: 'el-button--success',
                type: 'warning'
            }).then(() => {
                this.submitUseCaseRegistrationForm()
            }).catch(() => {
                // User dismissed Messaging Service registration.
            })
        },
        submitUseCaseRegistrationForm() {
            this.preValidateForm('useCaseRegistrationForm')

            if (!this.validateForm('useCaseRegistrationForm')) {
                return
            }

            this.loading = true

            axios.post(`/api/v1/compliance/messaging-services/${this.selected_messaging_service?.id}/a2p-use-case`, this.useCaseRegistrationForm)
                .then(response => {
                    this.loading = false
                    this.$message({
                        showClose: true,
                        message: 'A2P Campaign Registration form has been submitted successfully. We will notify you about its status.',
                        type: 'success',
                    })

                    this.$emit('next', {a2p_use_case: response.data})
                    this.submitAlert()
                }).catch(({response}) => {
                    this.loading = false
                    this.submitAlert(response)
                })
        },
        filterUseCases(useCasesToFilter) {
            return UseCases.filter(useCase => useCasesToFilter.includes(useCase['value']));
        },
        useCaseChanged() {
            if (this.easy_wizard) {
                switch (this.useCaseRegistrationForm.use_case) {
                    case USE_CASE_CUSTOMER_CARE:
                        // Pre-select lines that have a user_id or only have one incoming number
                        const customer_care_line_ids = this.unregistered_lines
                            .filter(line => !line.has_local_presence && (line.user_id || line.incoming_numbers.length === 1))
                            .map(line => line.id);

                        this.addLine(customer_care_line_ids)
                        this.line_ids = [...customer_care_line_ids]
                        break;
                    case USE_CASE_MARKETING:
                        // Pre-select all lines containing 10DLC Numbers
                        const marketing_line_ids = this.unregistered_lines
                            .filter(line => !line.has_local_presence && line.incoming_numbers.length > 0)
                            .map(line => line.id);

                        this.addLine(marketing_line_ids)
                        this.line_ids = [...marketing_line_ids]
                        break;
                    case USE_CASE_MIXED:
                        // Pre-select lines that have a user_id or only have one incoming number or are has 10DLC Numbers
                        let mixed_line_ids = this.unregistered_lines
                            .filter(line => !line.has_local_presence && (line.user_id || line.incoming_numbers.length === 1 || line.is_10_dlc === true))
                            .map(line => line.id)

                        this.addLine(mixed_line_ids)
                        this.line_ids = [...mixed_line_ids]
                    default:
                        break;
                }
            }
            this.preValidateForm('useCaseRegistrationForm')
        },
        getRandomizedOutboundCommunicationSamples() {
            // Sanity check
            if (this.unregistered_lines.length === 0) {
                return
            }

            let unregistered_line_ids = this.unregistered_lines.map(line => line.id)
            axios.post('/api/v1/compliance/marketing-samples', { unregistered_line_ids }).then((res) => {
                this.communications_messages = res.data
                if (this.communications_messages.length !== 0) {
                    this.randomizeCommunicationSamples('sample_message_1')
                    this.randomizeCommunicationSamples('sample_message_2')
                }
            }).catch(({error}) => {
                console.log(error)
            })
        },
        randomizeCommunicationSamples(field, tries = 0) {
            // Sanity check
            if (!this.communications_messages || this.communications_messages.length === 0) {
                console.log('No sample messages to show.')
                return;
            }

            // Get out of recursiveness if all messages have the same body.
            if (tries > this.communications_messages.length) {
                console.log('All sample messages have the same body.')
                return
            }

            // Get a random body from the communications messages
            let random_index = Math.floor(Math.random() * this.communications_messages.length);
            let random_body = this.communications_messages[random_index];

            // Check if previous body has the same text
            if (this.useCaseRegistrationForm[field] === random_body) {
                // Try to fetch a different body each time it's refreshed
                this.randomizeCommunicationSamples(field, tries + 1)
                return
            }

            this.useCaseRegistrationForm[field] = random_body;
        }
    },
    watch: {
        selected_lines: {
            handler: function (lines) {
                this.line_ids = lines
                if (lines.length === 0) {
                    this.useCaseChanged()
                }
            },
            immediate: true,
        },
        line_ids: {
            handler: function (campaignIds) {
                this.useCaseRegistrationForm.line_ids = campaignIds
            },
        },
    },
}
</script>
