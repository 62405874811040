var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.hasPermissionTo(["list contact", "view contact"])
    ? _c(
        "div",
        {
          staticClass: "app-body conversync max-height",
          style: { height: "calc(100% - " + (_vm.top_offset + 1) + "px)" },
          attrs: { id: "view" },
        },
        [
          _c(
            "el-dialog",
            {
              attrs: {
                visible: _vm.show_help,
                title: "What are contacts?",
                top: "10vh",
                "data-testid": "contacts-help-dialog",
                "append-to-body": "",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.show_help = $event
                },
              },
            },
            [
              _c("p", { staticClass: "text-md _600 break-word" }, [
                _vm._v(
                  "We automatically add each person you communicate with as a Contact (or\n            lead). Each Contact has a small CRM-style profile. You can send messages or call a contact here."
                ),
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "text-md _600 break-word" }, [
                _vm._v(
                  "To send a message to someone, or read previous conversations, search\n            their name or their number and click on the Contact. Contacts can be tagged (for audience segmentation)\n            or enrolled in Sequences."
                ),
              ]),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "full-height contacts-page",
              style: { height: "calc(100% - 48px)" },
            },
            [
              _c(
                "div",
                {
                  staticClass: "fixed-header padding pt-0 pb-0",
                  style: { top: _vm.top_offset + 50 + "px" },
                },
                [
                  _c("div", { staticClass: "row mb-0 mt-0" }, [
                    _c(
                      "div",
                      { staticClass: "d-flex flex-fill flex-wrap p-2" },
                      [
                        _c(
                          "div",
                          { staticClass: "d-flex flex-wrap flex-fill" },
                          [
                            _c("contact-advanced-filters-wrapper", {
                              key: _vm.prop_counter,
                              attrs: {
                                tag_id: _vm.tag_id,
                                disposition_status_id:
                                  _vm.disposition_status_id,
                                lead_source_id: _vm.lead_source_id,
                                default_date_range: _vm.default_date_range,
                                has_sort_communications: true,
                              },
                              on: { changed: _vm.updateFilterChanged },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "d-flex flex-wrap wrap-auto-margin" },
                          [
                            _vm.hasPermissionTo("create contact")
                              ? _c("div", [_c("contact-creator")], 1)
                              : _vm._e(),
                            _vm._v(" "),
                            !_vm.enabledToImportContacts()
                              ? _c(
                                  "el-popover",
                                  {
                                    ref: "import-contacts",
                                    staticClass: "custom-popover",
                                    attrs: {
                                      placement: "top",
                                      width: "200",
                                      "popper-class": "btn-primary",
                                      trigger: "hover",
                                    },
                                  },
                                  [
                                    _c("custom-message-display", {
                                      attrs: {
                                        config:
                                          _vm.customMessage("contacts.import"),
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.hasPermissionTo("create contact") &&
                            _vm.isLargeEnough
                              ? _c("div", [
                                  _c(
                                    "button",
                                    {
                                      directives: [
                                        {
                                          name: "popover",
                                          rawName: "v-popover:import-contacts",
                                          arg: "import-contacts",
                                        },
                                      ],
                                      staticClass: "btn blue",
                                      attrs: {
                                        disabled: !_vm.canImportContacts,
                                        "data-testid": "import-contacts-button",
                                      },
                                      on: {
                                        click: function ($event) {
                                          $event.preventDefault()
                                          return _vm.startImportWizard()
                                        },
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "fas fa-file-import",
                                      }),
                                      _vm._v(
                                        "\n                                Import Contacts\n                            "
                                      ),
                                    ]
                                  ),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _c("div", [
                              _c(
                                "i",
                                {
                                  staticClass:
                                    "material-icons text-lg cursor-pointer d-flex flex-column justify-content-center text-blue",
                                  attrs: {
                                    "data-testid": "contacts-help-info-outline",
                                  },
                                  on: { click: _vm.toggleHelp },
                                },
                                [
                                  _vm._v(
                                    "\n                                info_outline\n                            "
                                  ),
                                ]
                              ),
                            ]),
                          ],
                          1
                        ),
                      ]
                    ),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "row-col pl-3 pr-3 pt-3 pb-0 mt-5" },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "row m-0 b-l b-r b-t whitish text-blackish px-3 pt-3",
                    },
                    [
                      _c("contact-textual-filters"),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-12 px-0" }, [
                        _c(
                          "div",
                          { staticClass: "input-group py-2" },
                          [
                            _c(
                              "el-input",
                              {
                                ref: "search_box",
                                staticClass:
                                  "text-blackish form-control no-border p-0 input-contact-shortcut mt-1 mx-1",
                                attrs: {
                                  placeholder:
                                    "Press ENTER to search contacts...",
                                  autofocus: "",
                                  clearable: "",
                                  "data-testid": "search-contacts-input",
                                },
                                on: { change: _vm.searchContactChange },
                                model: {
                                  value: _vm.search_text,
                                  callback: function ($$v) {
                                    _vm.search_text = $$v
                                  },
                                  expression: "search_text",
                                },
                              },
                              [
                                _c("i", {
                                  staticClass: "el-input__icon el-icon-search",
                                  attrs: { slot: "prefix" },
                                  slot: "prefix",
                                }),
                              ]
                            ),
                            _vm._v(" "),
                            _vm.current_company &&
                            _vm.current_company.reseller_id === 357
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "ss-messenger-frame",
                                    attrs: {
                                      id: "ss-messenger-iframe-wrapper",
                                    },
                                  },
                                  [
                                    _c("div", {
                                      staticClass:
                                        "ss-messenger-iframe-wrapper-click",
                                      on: { click: _vm.redirectToDMSEquity },
                                    }),
                                    _vm._v(" "),
                                    _c("iframe", {
                                      attrs: {
                                        id: "ss-messenger-button",
                                        src:
                                          "https://dealer.simpsocial.com/" +
                                          _vm.current_company.id +
                                          "/messenger/unread/count",
                                        frameborder: "0",
                                      },
                                    }),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "mt-2 mt-md-0" },
                              [
                                _c("contacts-shortcuts", {
                                  ref: "contacts-shortcuts",
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _vm.isLargeEnough
                          ? _c("div", { staticClass: "row mt-2" }, [
                              _vm._m(0),
                              _vm._v(" "),
                              _vm._m(1),
                              _vm._v(" "),
                              _vm._m(2),
                              _vm._v(" "),
                              _vm._m(3),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "col-md-1" },
                                [
                                  _c(
                                    "el-tooltip",
                                    {
                                      attrs: {
                                        content: "Unread Missed Calls",
                                        placement: "top",
                                      },
                                    },
                                    [_c("strong", [_vm._v("Missed Calls")])]
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "col-md-1" },
                                [
                                  _c(
                                    "el-tooltip",
                                    {
                                      attrs: {
                                        content: "Unread Messages",
                                        placement: "top",
                                      },
                                    },
                                    [_c("strong", [_vm._v("Unread Texts")])]
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "col-md-1" },
                                [
                                  _c(
                                    "el-tooltip",
                                    {
                                      attrs: {
                                        content: "Unread Voicemails",
                                        placement: "top",
                                      },
                                    },
                                    [_c("strong", [_vm._v("Voicemails")])]
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _vm.hasPermissionTo("update contact")
                                ? _c("div", { staticClass: "col-md-1" }, [
                                    _c("strong", [_vm._v("Ops")]),
                                  ])
                                : _vm._e(),
                            ])
                          : _vm._e(),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("contacts-list", {
                    attrs: { is_filtered: _vm.is_filtered },
                  }),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _c("import-wizard", { ref: "importWizard" }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-md-2" }, [
      _c("strong", [_vm._v("Name")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-md-2" }, [
      _c("strong", [_vm._v("Info")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-md-2" }, [
      _c("strong", [_vm._v("Recent Engagement")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-md-2" }, [
      _c("strong", [_vm._v("Tags")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }