<template>
  <div>

    <input class="md-input"
       v-model="formattedDate"
       v-show="!contactAttribute.edit_mode"
       data-testid="contact-attributes-input"
       :disabled="true"
    />

    <el-date-picker
        v-model="defaultDate"
        v-show="contactAttribute.edit_mode"
        type="date"
        placeholder="Pick a Date"
        :format="datePickerDateFormat"
        :value-format="datePickerDateFormat"
        @blur="$emit('input-set-editable', contactAttribute, false, AttributeDictionary.TYPE_DATE_PICKER)"
        @change="saveDateValue"
    />

  </div>
</template>

<script>
import moment from "moment";
import * as AttributeDictionary from "../../../constants/attribute-dictionary";

export default {
  name: "attribute-type-date-picker",

  props: {
    contactAttribute: {
      required: true,
      type: Object,
    },
    timezone: {
      required: true,
      type: String,
    },
    momentDateFormat: {
      required: false,
      default: 'MM/DD/YYYY',
      type: String,
    },
    datePickerDateFormat: {
      required: false,
      default: 'MM/dd/yyyy',
      type: String,
    },
  },

  data() {
    return {
      AttributeDictionary,
      defaultDate: this.contactAttribute.value
          ? moment(parseInt(this.contactAttribute.value)).utc().format(this.momentDateFormat)
          : null,
    }
  },

  methods: {
    saveDateValue(date) {
      this.contactAttribute.value = date !== null
          ? moment(date, this.momentDateFormat).utc().startOf('day').format('x') // timestamp
          : null

      this.$emit('input-set-editable', this.contactAttribute, false, AttributeDictionary.TYPE_DATE_PICKER)
      this.$emit('input-save-contact-attribute', this.contactAttribute)
    }
  },

  computed: {

    formattedDate() {
      const date = moment(parseInt(this.contactAttribute.value))

      return date.isValid() === true
          ? date.utc().format(this.momentDateFormat)
          : ''
    }
  },

}
</script>
