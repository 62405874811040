var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.contact
    ? _c(
        "div",
        { staticClass: "pl-3 pr-3" },
        [
          _vm.hasPermissionTo("update contact")
            ? _c("add-contact-phone-number", {
                staticClass: "mb-2",
                attrs: { contact: _vm.contact },
                on: { success: _vm.addPhoneNumber },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm._l(_vm.phone_numbers, function (phone_number) {
            return _c(
              "div",
              {
                key: phone_number.id,
                staticClass:
                  "d-flex flex-row justify-content-between align-items-center mb-3",
              },
              [
                _c(
                  "div",
                  { staticClass: "d-flex flex-column align-items-start" },
                  [
                    phone_number.phone_number
                      ? _c("div", { staticClass: "md-form-group pb-2" }, [
                          _c("input", {
                            staticClass: "md-input",
                            attrs: {
                              disabled: true,
                              "data-testid": "phone-number-input",
                            },
                            domProps: {
                              value: _vm._f("fixPhone")(
                                phone_number.phone_number
                              ),
                            },
                          }),
                          _vm._v(" "),
                          _c("label", [
                            _vm._v(
                              _vm._s(_vm._f("fixName")(phone_number.title))
                            ),
                          ]),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("div", { staticClass: "d-flex flex-row" }, [
                      phone_number.is_primary
                        ? _c(
                            "span",
                            {
                              staticClass: "label p-1 text-xxs success mr-2",
                              attrs: { "data-testid": "primary-phone-number" },
                            },
                            [
                              _vm._v(
                                "\n                    Primary\n                "
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      phone_number.lrn_type !== undefined
                        ? _c(
                            "span",
                            {
                              staticClass: "label p-1 text-xxs light-blue mr-2",
                              attrs: { "data-testid": "lrn-type-phone-number" },
                            },
                            [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(
                                    _vm._f("fixLrnType")(phone_number.lrn_type)
                                  ) +
                                  "\n                "
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      phone_number.is_wrong_number
                        ? _c(
                            "span",
                            {
                              staticClass: "label p-1 text-xxs red-A400 mr-2",
                              attrs: { "data-testid": "wrong-phone-number" },
                            },
                            [
                              _vm._v(
                                "\n                    Wrong Number\n                "
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      phone_number.is_invalid
                        ? _c(
                            "span",
                            {
                              staticClass: "label p-1 text-xxs red-A400",
                              attrs: { "data-testid": "invalid-phone-number" },
                            },
                            [
                              _vm._v(
                                "\n                    Invalid Number\n                "
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      phone_number.is_opted_out
                        ? _c(
                            "span",
                            {
                              staticClass: "label p-1 text-xxs red-A400",
                              attrs: { "data-testid": "opt-out-phone-number" },
                            },
                            [
                              _vm._v(
                                "\n                    Opt-Out\n                "
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      phone_number?.conflicted_contacts?.length > 0
                        ? _c(
                            "span",
                            {
                              staticClass:
                                "label p-1 text-xxs has_conflict_color_background",
                              attrs: {
                                "data-testid": "has-conflicts-phone-number",
                              },
                            },
                            [
                              _vm._v(
                                "\n                    Has Conflicts\n                "
                              ),
                            ]
                          )
                        : _vm._e(),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "d-flex position-absolute right-0" },
                  [
                    phone_number?.conflicted_contacts?.length > 0
                      ? _c("contact-phone-number-duplicates", {
                          attrs: { phone_number: phone_number },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.hasPermissionTo("update contact")
                      ? _c("edit-contact-phone-number", {
                          attrs: {
                            "data-testid": "edit-contact-phone-number",
                            contact: _vm.contact,
                            phone_number: phone_number,
                          },
                          on: { success: _vm.updatePhoneNumber },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "el-popover",
                      {
                        attrs: {
                          placement: "top",
                          width: "300",
                          trigger: "hover",
                          "popper-class": "p-2",
                          disabled: !_vm.isNumberInvalidOrWrong(phone_number),
                        },
                      },
                      [
                        _c("span", { staticClass: "text-black-dk" }, [
                          _vm._v(
                            "\n                    " +
                              _vm._s(_vm.getTooltipMessage(phone_number)) +
                              "\n                "
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "span",
                          { attrs: { slot: "reference" }, slot: "reference" },
                          [
                            _vm.hasPermissionTo("update contact")
                              ? _c(
                                  "button",
                                  {
                                    staticClass:
                                      "btn btn-sm btn-icon btn-outline-success w-30 d-flex justify-content-center align-items-center ml-1",
                                    attrs: {
                                      "data-testid": "make-a-call-button",
                                      disabled:
                                        _vm.call_loading === phone_number.id ||
                                        _vm.contact.is_dnc ||
                                        _vm.isNumberInvalidOrWrong(
                                          phone_number
                                        ),
                                    },
                                    on: {
                                      click: function ($event) {
                                        $event.preventDefault()
                                        return _vm.callContact(
                                          $event,
                                          _vm.contact,
                                          phone_number.phone_number
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _vm.call_loading === phone_number.id
                                      ? _c("i", {
                                          staticClass: "fa fa-spinner fa-spin",
                                        })
                                      : _vm._e(),
                                    _vm._v(" "),
                                    !_vm.call_loading ||
                                    _vm.call_loading !== phone_number.id
                                      ? _c("i", { staticClass: "fa fa-phone" })
                                      : _vm._e(),
                                  ]
                                )
                              : _vm._e(),
                          ]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-popover",
                      {
                        attrs: {
                          placement: "top",
                          width: "300",
                          trigger: "hover",
                          "popper-class": "p-2",
                          disabled:
                            !_vm.isNumberInvalidOrWrong(phone_number) &&
                            !_vm.isOptedOut(phone_number),
                        },
                      },
                      [
                        _c("span", { staticClass: "text-black-dk" }, [
                          _vm._v(
                            "\n                    " +
                              _vm._s(_vm.getTooltipMessage(phone_number)) +
                              "\n                "
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "span",
                          { attrs: { slot: "reference" }, slot: "reference" },
                          [
                            _vm.hasPermissionTo("update contact")
                              ? _c(
                                  "button",
                                  {
                                    staticClass:
                                      "btn btn-sm btn-icon btn-outline-info w-30 d-flex justify-content-center align-items-center ml-1",
                                    attrs: {
                                      "data-testid":
                                        "update-contact-comment-button",
                                      disabled:
                                        _vm.contact.is_dnc ||
                                        _vm.isNumberInvalidOrWrong(
                                          phone_number
                                        ) ||
                                        _vm.isOptedOut(phone_number),
                                    },
                                    on: {
                                      click: function ($event) {
                                        $event.preventDefault()
                                        return _vm.gotoMessages(
                                          $event,
                                          phone_number.phone_number
                                        )
                                      },
                                    },
                                  },
                                  [_c("i", { staticClass: "fa fa-comment" })]
                                )
                              : _vm._e(),
                          ]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _vm.hasPermissionTo("archive contact") &&
                    _vm.contact.phone_number != phone_number.phone_number
                      ? _c(
                          "button",
                          {
                            staticClass:
                              "btn btn-sm btn-icon btn-outline-danger w-30 d-flex justify-content-center align-items-center ml-1",
                            attrs: {
                              "data-testid": "archive-contact-action-button",
                            },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.deletePhoneNumber(
                                  $event,
                                  _vm.contact,
                                  phone_number
                                )
                              },
                            },
                          },
                          [
                            _vm.loading_delete &&
                            _vm.loading_delete == phone_number.id
                              ? _c("i", {
                                  staticClass: "fa fa-spinner fa-spin",
                                })
                              : _c("i", { staticClass: "fa fa-trash" }),
                          ]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ]
            )
          }),
          _vm._v(" "),
          _vm._l(_vm.conflicted_phone_numbers, function (phone_number) {
            return _c(
              "div",
              {
                key: `${phone_number.id}-conflicted`,
                staticClass:
                  "d-flex flex-row justify-content-between align-items-center mb-3",
              },
              [
                _c(
                  "div",
                  { staticClass: "d-flex flex-column align-items-start" },
                  [
                    phone_number.phone_number
                      ? _c("div", { staticClass: "md-form-group pb-2" }, [
                          _c("input", {
                            staticClass: "md-input",
                            attrs: {
                              disabled: true,
                              "data-testid": "phone-number-input",
                            },
                            domProps: {
                              value: _vm._f("fixPhone")(
                                phone_number.phone_number
                              ),
                            },
                          }),
                          _vm._v(" "),
                          _c("label", [
                            _vm._v(
                              _vm._s(_vm._f("fixName")(phone_number.title))
                            ),
                          ]),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("div", { staticClass: "d-flex flex-row" }, [
                      phone_number.lrn_type !== undefined
                        ? _c(
                            "span",
                            {
                              staticClass: "label p-1 text-xxs light-blue mr-2",
                              attrs: { "data-testid": "lrn-type-phone-number" },
                            },
                            [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(
                                    _vm._f("fixLrnType")(phone_number.lrn_type)
                                  ) +
                                  "\n                "
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      phone_number.is_wrong_number
                        ? _c(
                            "span",
                            {
                              staticClass: "label p-1 text-xxs red-A400 mr-2",
                              attrs: { "data-testid": "wrong-phone-number" },
                            },
                            [
                              _vm._v(
                                "\n                    Wrong Number\n                "
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      phone_number.is_invalid
                        ? _c(
                            "span",
                            {
                              staticClass: "label p-1 text-xxs red-A400",
                              attrs: { "data-testid": "invalid-phone-number" },
                            },
                            [
                              _vm._v(
                                "\n                    Invalid Number\n                "
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      phone_number.is_opted_out
                        ? _c(
                            "span",
                            {
                              staticClass: "label p-1 text-xxs red-A400",
                              attrs: { "data-testid": "opt-out-phone-number" },
                            },
                            [
                              _vm._v(
                                "\n                    Opt-Out\n                "
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          staticClass:
                            "label p-1 text-xxs has_conflict_color_background",
                          attrs: {
                            "data-testid": "has-conflicts-phone-number",
                          },
                        },
                        [
                          _vm._v(
                            "\n                    Conflicted\n                "
                          ),
                        ]
                      ),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "d-flex position-absolute right-0" },
                  [
                    _c("contact-phone-number-duplicates", {
                      attrs: { phone_number: phone_number },
                    }),
                  ],
                  1
                ),
              ]
            )
          }),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }