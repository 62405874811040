var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm._m(0),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "col-12 mt-2" },
        [
          _c("p", { staticClass: "mb-0 font-weight-bold" }, [_vm._v("Type:")]),
          _vm._v(" "),
          _c(
            "el-radio-group",
            {
              model: {
                value: _vm.nodeData.type,
                callback: function ($$v) {
                  _vm.$set(_vm.nodeData, "type", $$v)
                },
                expression: "nodeData.type",
              },
            },
            _vm._l(_vm.APPOINTMENT_TYPES, function (type) {
              return _c(
                "el-radio-button",
                { key: type[0], attrs: { label: type[0] } },
                [
                  _vm._v(
                    "\n                " +
                      _vm._s(type[1].label) +
                      "\n            "
                  ),
                ]
              )
            }),
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "col-12 mt-2" },
        [
          _c("p", { staticClass: "mb-0 font-weight-bold" }, [_vm._v("Note:")]),
          _vm._v(" "),
          _c("el-input", {
            attrs: {
              type: "textarea",
              placeholder: "Type appointment note here.",
              rows: 3,
            },
            model: {
              value: _vm.nodeData.note,
              callback: function ($$v) {
                _vm.$set(_vm.nodeData, "note", $$v)
              },
              expression: "nodeData.note",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "col-12 text-right" },
        [
          _c(
            "el-tooltip",
            { attrs: { content: "Add variable", placement: "top" } },
            [
              _c("variables", {
                on: { "variable-selected": _vm.noteVariableSelected },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "col-12 mt-2" },
        [
          _c("p", { staticClass: "mb-0 font-weight-bold" }, [
            _vm._v("Date Message:"),
          ]),
          _vm._v(" "),
          _c("el-input", {
            attrs: {
              type: "textarea",
              placeholder: "Type the date message here.",
              rows: 3,
            },
            model: {
              value: _vm.nodeData.date_message,
              callback: function ($$v) {
                _vm.$set(_vm.nodeData, "date_message", $$v)
              },
              expression: "nodeData.date_message",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "col-12 text-right" },
        [
          _c(
            "el-tooltip",
            { attrs: { content: "Add variable", placement: "top" } },
            [
              _c("variables", {
                on: { "variable-selected": _vm.dateVariableSelected },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "col-12" },
        [
          _c("p", { staticClass: "mb-0 font-weight-bold" }, [
            _vm._v("Number of date suggestion:"),
          ]),
          _vm._v(" "),
          _c(
            "el-select",
            {
              attrs: { placeholder: "Number" },
              model: {
                value: _vm.nodeData.date_suggestion_number,
                callback: function ($$v) {
                  _vm.$set(_vm.nodeData, "date_suggestion_number", $$v)
                },
                expression: "nodeData.date_suggestion_number",
              },
            },
            _vm._l(_vm.date_numbers, function (number) {
              return _c("el-option", {
                key: number,
                attrs: { label: number, value: number },
              })
            }),
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "col-12 mt-2" },
        [
          _c("p", { staticClass: "mb-0 font-weight-bold" }, [
            _vm._v("Time Message:"),
          ]),
          _vm._v(" "),
          _c("el-input", {
            attrs: {
              type: "textarea",
              placeholder: "Type the time message here.",
              rows: 3,
            },
            model: {
              value: _vm.nodeData.time_message,
              callback: function ($$v) {
                _vm.$set(_vm.nodeData, "time_message", $$v)
              },
              expression: "nodeData.time_message",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "col-12 text-right" },
        [
          _c(
            "el-tooltip",
            { attrs: { content: "Add variable", placement: "top" } },
            [
              _c("variables", {
                on: { "variable-selected": _vm.timeVariableSelected },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "col-12" },
        [
          _c("p", { staticClass: "mb-0 font-weight-bold" }, [
            _vm._v("Number of time suggestion:"),
          ]),
          _vm._v(" "),
          _c(
            "el-select",
            {
              attrs: { "default-first-option": "", placeholder: "Numbers" },
              model: {
                value: _vm.nodeData.time_suggestion_number,
                callback: function ($$v) {
                  _vm.$set(_vm.nodeData, "time_suggestion_number", $$v)
                },
                expression: "nodeData.time_suggestion_number",
              },
            },
            _vm._l(_vm.time_numbers, function (number) {
              return _c("el-option", {
                key: number,
                attrs: { label: number, value: number },
              })
            }),
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "col-12 mt-2" },
        [
          _c("p", { staticClass: "mb-0 font-weight-bold" }, [
            _vm._v("Service:"),
          ]),
          _vm._v(" "),
          _c(
            "el-select",
            {
              attrs: { placeholder: "Select Appointment Service" },
              model: {
                value: _vm.nodeData.service_id,
                callback: function ($$v) {
                  _vm.$set(_vm.nodeData, "service_id", $$v)
                },
                expression: "nodeData.service_id",
              },
            },
            _vm._l(_vm.appointment_services, function (appointment_service) {
              return _c("el-option", {
                key: appointment_service.id,
                attrs: {
                  label: `${appointment_service.name} (${appointment_service.duration} min.)`,
                  value: appointment_service.id,
                },
              })
            }),
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("hr"),
      _vm._v(" "),
      _c(
        "el-checkbox",
        {
          staticClass: "col-12 mt-2",
          model: {
            value: _vm.nodeData.reminder_enabled,
            callback: function ($$v) {
              _vm.$set(_vm.nodeData, "reminder_enabled", $$v)
            },
            expression: "nodeData.reminder_enabled",
          },
        },
        [_vm._v("\n        SMS reminder\n    ")]
      ),
      _vm._v(" "),
      _vm.nodeData.reminder_enabled
        ? _c("div", [
            _c(
              "div",
              { staticClass: "col-12 mt-2" },
              [
                _c("p", { staticClass: "mb-0 font-weight-bold" }, [
                  _vm._v("Reminder message:"),
                ]),
                _vm._v(" "),
                _c("el-input", {
                  attrs: {
                    type: "textarea",
                    placeholder: "Reminder message",
                    rows: 3,
                  },
                  model: {
                    value: _vm.nodeData.reminder_message,
                    callback: function ($$v) {
                      _vm.$set(_vm.nodeData, "reminder_message", $$v)
                    },
                    expression: "nodeData.reminder_message",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-12 text-right" },
              [
                _c(
                  "el-tooltip",
                  { attrs: { content: "Add variable", placement: "top" } },
                  [
                    _c("Variables", {
                      on: { "variable-selected": _vm.reminderVariableSelected },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-12 mt-2" },
              [
                _c("p", { staticClass: "mb-0 font-weight-bold" }, [
                  _vm._v("Time:"),
                ]),
                _vm._v(" "),
                _c(
                  "el-select",
                  {
                    attrs: { placeholder: "Reminder Time" },
                    model: {
                      value: _vm.nodeData.reminder_time,
                      callback: function ($$v) {
                        _vm.$set(_vm.nodeData, "reminder_time", $$v)
                      },
                      expression: "nodeData.reminder_time",
                    },
                  },
                  _vm._l(_vm.reminder_times, function (time) {
                    return _c("el-option", {
                      key: time,
                      attrs: { label: `${time} minutes before`, value: time },
                    })
                  }),
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-12 mt-2" },
              [
                _c("p", { staticClass: "mb-0 font-weight-bold" }, [
                  _vm._v("Select a Workflow:"),
                ]),
                _vm._v(" "),
                _c(
                  "el-select",
                  {
                    attrs: { filterable: "", placeholder: "Sequence" },
                    model: {
                      value: _vm.nodeData.reminder_workflow_id,
                      callback: function ($$v) {
                        _vm.$set(_vm.nodeData, "reminder_workflow_id", $$v)
                      },
                      expression: "nodeData.reminder_workflow_id",
                    },
                  },
                  _vm._l(_vm.workflows, function (workflow) {
                    return _c("el-option", {
                      key: workflow.id,
                      attrs: { label: workflow.name, value: workflow.id },
                    })
                  }),
                  1
                ),
              ],
              1
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "col-12 mt-4" },
        [
          _c("Fallback", {
            ref: "fallback",
            attrs: { fallbacks: _vm.fallbacks, "date-time": true },
            on: {
              "update:fallbacks": function ($event) {
                _vm.fallbacks = $event
              },
              change: _vm.updateFallback,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-12" }, [
      _c("p", { staticClass: "font-weight-bold side-panel-title" }, [
        _vm._v("Appointment Panel"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }