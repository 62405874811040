<template>
    <div data-testid="amc-wizard-wrapper">
        <div class="container-fluid">
            <el-steps :active="current_step" align-center finish-status="success" class="mb-3">
                <el-step title="Information" data-testid="info-step"></el-step>
                <el-step title="Confirmation" data-testid="confirmation-step"></el-step>
            </el-steps>
            <div v-if="current_step === 0" class="step-1 row">
                <div class="col-12">
                    <p class="form-head font-weight-bold mb-3 description text-wrap"><b>Do you need hassle-free A2P compliance and fast phone number onboarding with expert support?</b></p>
                    <div class="dropdown-divider"></div>
                    <p class="description text-wrap">
                        <b>Aloware Managed Compliance</b> is a premium white-glove service that handles your A2P brand and campaign registration, along with seamless phone number onboarding.
                    </p>
                    <p class="description text-wrap">
                        We will also manage any issues or complaints raised by carriers throughout the registration process.
                    </p>
                    <p class="description text-wrap">
                        While approval from carriers or regulators is not guaranteed, our goal is to complete the brand and campaign registration within 5 business days and submission of your phone numbers for same-day onboarding.
                    </p>
                </div>
                <div class="col-12 text-right mt-4">
                    <el-button type="success" @click="nextStep">Next</el-button>
                </div>
            </div>
            <div v-if="current_step === 1" class="step-2 row">
                <div class="col-12">
                    <p class="form-head font-weight-bold mb-3"><b>Aloware Managed Compliance - Terms of Service</b></p>
                    <div class="dropdown-divider"></div>
                    <p class="text-wrap">
                        <b>Business Requirements:</b>
                        <ul>
                            <li>You are required to provide accurate and complete information, including the necessary business documents (eg. EIN, CBN, ASIC, etc.) for the registration process.</li>
                        </ul>
                    </p>
                    <p class="text-wrap">
                        <b>Service Overview:</b>
                        <ul>
                            <li>Aloware will handle the registration of your A2P brand and campaign, and the submission of your phone numbers for same-day onboarding.</li>
                            <li>We will address any issues raised by carriers during the process.</li>
                        </ul>
                    </p>
                    <p class="text-wrap">
                        <b>Turnaround Time and No Guarantee of Approval:</b>
                        <ul>
                            <li>A2P Brand and Campaign registration typically takes up to 5 business days.</li>
                            <li>Phone numbers will be submitted for same-day onboarding.</li>
                            <li>While we aim for timely completion, Aloware only facilitates the registration but cannot guarantee acceptance by carriers or regulators.</li>
                        </ul>
                    </p>
                    <p class="text-wrap">
                        <b>Cost of Service:</b>
                        <ul>
                            <li>The Aloware Managed Compliance service is currently offered at no cost.</li>
                        </ul>
                    </p>
                    <p class="text-wrap">
                        By clicking <b>"Accept and Submit"</b>, you agree to these terms and allow Aloware to proceed with your registration.
                    </p>
                </div>
                <div class="col-12 d-flex justify-content-between mt-4">
                    <el-button type="info" @click="previousStep">Back</el-button>
                    <el-button type="success" @click="confirmAndSubmit">Accept and Submit</el-button>
                </div>
            </div>
            <div v-if="current_step === 2" class="step-3 text-center row">
                <div class="col-12">
                    <p class="text-wrap"><b>Your order for Aloware Managed Compliance has been successfully submitted!</b></p>
                    <p class="text-wrap">Please make sure your business information and required documents <br> have been provided via the registration form.</p>
                    <p class="text-wrap">Our team will reach out to you within 1-2 business days. Thank you!</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['onWizardComplete'],
    data() {
        return {
            current_step: 0,
            completed: false,
        };
    },
    methods: {
        nextStep() {
            this.current_step++
        },
        previousStep() {
            if (this.current_step > 0) {
                this.current_step--
            }
        },
        confirmAndSubmit() {
            this.loading = true
            axios.post('/api/v1/compliance/managed-compliance')
                .then(() => {
                    this.completed = true
                    this.onWizardComplete()
                    this.current_step++
                })
                .catch(() => {
                    this.$message.error('There was an issue submitting your request. Please try again.')
                })
                .finally(() => {
                    this.loading = false
                });
        },
        resetWizard() {
            if (!this.completed) {
                // We only reset the wizard if it wasn't completed
                this.current_step = 0;
            }
        }
    },
};
</script>

<style scoped>
.text-wrap {
    white-space: normal;
    word-wrap: break-word;
    word-break: keep-all;
    overflow-wrap: break-word;
}
</style>
