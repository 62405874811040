var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-popover",
    {
      attrs: {
        placement: _vm.small ? "bottom-end" : "right-start",
        width: "255",
      },
      on: { hide: _vm.resetAddContact },
      model: {
        value: _vm.add_contact_popover_visibility,
        callback: function ($$v) {
          _vm.add_contact_popover_visibility = $$v
        },
        expression: "add_contact_popover_visibility",
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "add_contact",
          attrs: {
            "label-width": "100px",
            "label-position": "top",
            model: _vm.add_contact,
            rules: _vm.rules_test,
          },
          nativeOn: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.addContact.apply(null, arguments)
            },
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "Contact", prop: "phone_number" } },
            [
              _c(
                "el-select",
                {
                  staticClass: "no-select w-full",
                  attrs: {
                    placeholder: "Type to search ...",
                    filterable: "",
                    remote: "",
                    "remote-method": _vm.fetchContact,
                    loading: _vm.loading_fetching_contact,
                    clearable: "",
                  },
                  on: { change: _vm.changeTargetContact },
                  model: {
                    value: _vm.add_contact.phone_number,
                    callback: function ($$v) {
                      _vm.$set(_vm.add_contact, "phone_number", $$v)
                    },
                    expression: "add_contact.phone_number",
                  },
                },
                _vm._l(_vm.contactsAlphabeticalOrder, function (contact) {
                  return _c(
                    "el-option",
                    {
                      key: contact.id,
                      staticClass: "p-0",
                      attrs: {
                        label: contact.name,
                        value: contact.phone_number,
                      },
                    },
                    [
                      _c("div", { staticClass: "media" }, [
                        _c("div", { staticClass: "media-body" }, [
                          _c("label", [_vm._v(_vm._s(contact.name))]),
                          _vm._v(" "),
                          _c("small", [
                            _vm._v(
                              _vm._s(_vm._f("fixPhone")(contact.phone_number))
                            ),
                          ]),
                        ]),
                      ]),
                    ]
                  )
                }),
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "row no-gutter mt-2 centered-content" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-block greenish",
                attrs: { disabled: _vm.disable_add_contact },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.addContact.apply(null, arguments)
                  },
                },
              },
              [_vm._v("\n                Add\n            ")]
            ),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _vm.referrer == "btn"
        ? _c(
            "button",
            {
              staticClass: "md-btn md-flat text-whitish search-btn p-1",
              attrs: { slot: "reference" },
              slot: "reference",
            },
            [_c("i", { staticClass: "fa fa-search" })]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.referrer == "input"
        ? _c("el-input", {
            attrs: { slot: "reference", type: "text", readonly: "" },
            slot: "reference",
            model: {
              value: _vm.add_contact.name,
              callback: function ($$v) {
                _vm.$set(_vm.add_contact, "name", $$v)
              },
              expression: "add_contact.name",
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }