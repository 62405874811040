var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.contact && _vm.contact.id
    ? _c(
        "div",
        {
          staticClass: "row no-gutter",
          class: [_vm.no_padding ? "" : "pl-3 pr-3"],
        },
        [
          _vm.guestyEnabled
            ? _c(
                "div",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading_integration_data,
                      expression: "loading_integration_data",
                    },
                  ],
                  staticClass: "col-12",
                  staticStyle: { "min-height": "60px" },
                },
                _vm._l(_vm.messages.messages, function (message, key) {
                  return !_vm.isEmpty(_vm.messages)
                    ? _c(
                        "el-card",
                        {
                          key: key,
                          staticClass: "small-paddings",
                          staticStyle: { "margin-top": "10px" },
                          attrs: { shadow: "never" },
                        },
                        [
                          _c("table", { staticClass: "mb-2 w-100" }, [
                            _c("tr", [
                              _c("td", { staticClass: "text-muted" }, [
                                _vm._v("Module:"),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("tr", [
                              _c("td", { staticClass: "_400" }, [
                                _c("span", [_vm._v(_vm._s(message.module))]),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("tr", [
                              _c("td", { staticClass: "text-muted" }, [
                                _vm._v("Direction:"),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("tr", [
                              _c("td", { staticClass: "_400" }, [
                                _c("span", [_vm._v(_vm._s(message.direction))]),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("tr", [
                              _c("td", { staticClass: "text-muted" }, [
                                _vm._v("Message:"),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("tr", [
                              _c("td", { staticClass: "_400" }, [
                                _c("span", [_vm._v(_vm._s(message.body))]),
                              ]),
                            ]),
                          ]),
                        ]
                      )
                    : _vm._e()
                }),
                1
              )
            : _vm._e(),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }