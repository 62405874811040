<template>
    <div v-if="contact">
        <div class="row no-gutter text-center m-t">

            <div class="col p-0"
                 v-if="!hasReporterAccess">
                <el-badge class="item"
                          :value="unreadCallCount"
                          :max="99"
                          :min="0"
                          :hidden="unreadCallCount < 1">
                    <el-popover
                        v-if="!enabledToCallNumber(activePhoneNumber)"
                        placement="top"
                        width="300"
                        trigger="hover"
                        popper-class="p-2 btn-primary">
                        <custom-message-display :config="customMessage('call')" />
                        <span slot="reference">
                            <button
                                slot="reference"
                                disabled
                                class="md-btn md-fab md-sm m-b-sm greenish">
                                <i class="material-icons md-24">call</i>
                            </button>
                        </span>
                    </el-popover>
                    <el-popover
                        v-if="enabledToCallNumber(activePhoneNumber)"
                        placement="top"
                        width="300"
                        trigger="hover"
                        popper-class="p-2"
                        :disabled="!isNumberInvalidOrWrong(activePhoneNumber)">
                        <span class="text-black-dk">
                            {{ getTooltipMessage(activePhoneNumber) }}
                        </span>
                        <span slot="reference">
                            <button
                                :disabled="isNumberInvalidOrWrong(activePhoneNumber)"
                                class="md-btn md-fab md-sm m-b-sm greenish"
                                data-testid="call-contact-button"
                                @click="callContact">
                                <i class="material-icons md-24">call</i>
                            </button>
                        </span>
                    </el-popover>
                </el-badge>
                <span class="d-block text-xs text-muted">Call</span>
            </div>
            <div class="col p-0">
                <el-badge class="item"
                          :value="contact.unread_count"
                          :max="99"
                          :min="0"
                          :hidden="contact.unread_count < 1">
                    <el-popover
                        v-if="!enabledToTextNumber(activePhoneNumber)"
                        placement="top"
                        width="300"
                        trigger="hover"
                        popper-class="p-2 btn-primary">
                        <custom-message-display :config="customMessage('text')" />
                        <span slot="reference">
                            <button
                                slot="reference"
                                disabled
                                class="md-btn md-fab md-sm m-b-sm greenish"
                                data-testid="forum-contact-popover-button">
                                <i class="material-icons md-24">forum</i>
                            </button>
                        </span>
                    </el-popover>
                    <el-popover
                        v-if="enabledToTextNumber(activePhoneNumber)"
                        placement="top"
                        width="300"
                        trigger="hover"
                        popper-class="p-2"
                        :disabled="(!isPrimaryNumberErroneous || !isNumberInvalidOrWrong(activePhoneNumber)) && !isOptedOut(activePhoneNumber) && canSendNonTCPAContacts">
                        <span v-html="getTooltipMessage(activePhoneNumber, !canSendNonTCPAContacts)">
                        </span>
                        <span slot="reference">
                            <button
                                data-testid="forum-text-contact-button"
                                @click="changeSelectedPhoneNumber(contact.phone_number)"
                                :disabled="contact.is_dnc || (isPrimaryNumberErroneous && isNumberInvalidOrWrong(activePhoneNumber)) || isOptedOut(activePhoneNumber) || !canSendNonTCPAContacts"
                                class="md-btn md-fab md-sm m-b-sm bluish">
                                <i class="material-icons md-24">forum</i>
                            </button>
                        </span>
                    </el-popover>
                </el-badge>
                <span class="d-block text-xs text-muted">Text</span>
            </div>
            <div class="col p-0"
                 v-if="hasPermissionTo('toggle block contact')">
                <button class="md-btn md-fab md-sm m-b-sm orange text-whitish"
                        v-if="!contact.is_blocked"
                        data-testid="block-contact-button"
                        @click="blockContact">
                    <i v-show="!loading_block_contact"
                       class="material-icons md-24">lock_outline</i>
                    <i v-show="loading_block_contact"
                       class="material-icons md-24 loader">&#xE863;</i>
                </button>
                <button class="md-btn md-fab md-sm m-b-sm blue"
                        data-testid="unblock-contact-button"
                        @click="unblockContact"
                        v-else>
                    <i v-show="!loading_block_contact"
                       class="material-icons md-24">lock_open</i>
                    <i v-show="loading_block_contact"
                       class="material-icons md-24 loader">&#xE863;</i>
                </button>
                <span class="d-block text-xs text-muted"
                      v-if="!contact.is_blocked">
                    Block
                </span>
                <span class="d-block text-xs text-muted"
                      v-else>
                    Unblock
                </span>
            </div>
            <div class="col p-0"
                 v-if="hasPermissionTo('toggle block contact') && !contact.is_dnc">
                <button class="md-btn md-fab md-sm m-b-sm red"
                        data-testid="dnc-contact-button"
                        @click="dncContact">
                    <i v-show="!loading_dnc_contact"
                       class="material-icons md-24">do_not_disturb_on</i>
                    <i v-show="loading_dnc_contact"
                       class="material-icons md-24 loader">&#xE863;</i>
                </button>
                <span class="d-block text-xs text-muted"
                      v-if="!contact.is_dnc">
                    DNC
                </span>

            </div>
            <div class="col p-0"
                 v-if="hasRole('Company Admin') && contact.is_dnc && current_company && current_company.undnc_enabled">
                <button class="md-btn md-fab md-sm m-b-sm red"
                        data-testid="undnc-contact-button"
                        @click="showUnDncDialog">
                    <i v-show="!undnc.updating_dnc_status"
                       class="material-icons md-24">do_not_disturb_on</i>
                    <i v-show="undnc.updating_dnc_status"
                       class="material-icons md-24 loader">&#xE863;</i>
                </button>
                <span class="d-block text-xs text-muted"
                      v-if="contact.is_dnc">
                    UN-DNC
                </span>
            </div>
        </div>
        <div class="row no-gutter text-center m-t">
            <div class="col p-0">
                <button class="md-btn md-fab md-sm m-b-sm yellow"
                        slot="reference"
                        :disabled="contact.is_dnc"
                        data-testid="add-appointment-button"
                        @click="addAppointment">
                    <i class="material-icons md-24">event_available</i>
                </button>
                <span class="d-block text-xs text-muted">Appt</span>
            </div>
            <div class="col p-0">
                <el-popover ref="noteForm"
                            placement="right"
                            title="Add Note"
                            width="250"
                            trigger="manual"
                            v-model="add_note_form_visibility"
                            v-closable="{
                                handler: 'onAddNoteFormClose'
                            }"
                            data-testid="add-note-popover"
                            @hide="resetAddEngagementForm">
                    <el-form ref="add_note_form"
                             class="h-full"
                             data-testid="add-note-form"
                             :model="add_engagement">
                        <el-form-item>
                            <app-mentionable
                                :items="mention_items"
                                searchKey="full_name"
                                v-model="add_engagement.body"
                                data-testid="add-mention-items-mentionable"
                                @input="validateAddNoteEngagementForm">
                            </app-mentionable>
                        </el-form-item>
                        <el-button type="success"
                                   class="d-block mt-2 btn-block"
                                   :loading="loading_add_engagement"
                                   :disabled="!add_engagement_validated"
                                   data-testid="add-note-button"
                                   @click.prevent="addEngagement(CommunicationTypes.NOTE)">
                            Add Note
                        </el-button>
                    </el-form>
                    <button class="md-btn md-fab md-sm m-b-sm accent"
                            slot="reference"
                            data-testid="add-note-form-visibility-button"
                            @click="add_note_form_visibility = true">
                        <i class="material-icons md-24">event_note</i>
                    </button>
                </el-popover>
                <span class="d-block text-xs text-muted">Note</span>
            </div>
            <div class="col p-0">
                <el-popover ref="reminderForm"
                            placement="right"
                            title="Add Reminder"
                            width="250"
                            trigger="click"
                            @hide="resetAddEngagementForm">
                    <el-form ref="add_note_form"
                             class="h-full"
                             data-testid="add-reminder-form"
                             :model="add_engagement">
                        <el-form-item>
                            <el-date-picker
                                    v-model="add_engagement.date"
                                    format="MM/dd/yyyy"
                                    value-format="MM/dd/yyyy"
                                    type="date"
                                    placeholder="Select date"
                                    :picker-options="pickerOptions"
                                    data-testid="reminder-date-picker"
                                    @change="validateAddAppointmentOrReminderEngagementForm"
                                    class="w-100">
                            </el-date-picker>
                        </el-form-item>
                        <el-form-item>
                            <el-select
                                    v-model="add_engagement.time"
                                    placeholder="Select time"
                                    filterable
                                    data-testid="reminder-time-picker"
                                    @change="validateAddAppointmentOrReminderEngagementForm"
                                    class="w-100">
                                <el-option
                                        v-for="time in times"
                                        :key="time.value"
                                        :label="time.label"
                                        :value="time.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item>
                            <el-select v-model="add_engagement.timezone"
                                       placeholder="Select Timezone"
                                       class="w-100"
                                       data-testid="reminder-timezone-picker"
                                       filterable>
                                <el-option v-for="timezone in filteredTimezones"
                                           :key="timezone.value"
                                           :label="timezone.name"
                                           :value="timezone.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item>
                            <el-input
                                    v-model="add_engagement.body"
                                    type="textarea"
                                    :autosize="{ minRows: 2, maxRows: 4}"
                                    data-testid="reminder-notes-input"
                                    placeholder="Reminder notes">
                            </el-input>
                        </el-form-item>
                        <el-button type="success"
                                   class="d-block mt-2 btn-block"
                                   :loading="loading_add_engagement"
                                   :disabled="!add_engagement_validated"
                                   data-testid="add-reminder-button"
                                   @click.prevent="addEngagement(CommunicationTypes.REMINDER)">
                            Add Reminder
                        </el-button>
                    </el-form>
                    <button class="md-btn md-fab md-sm m-b-sm greyish"
                            :disabled="contact.is_dnc"
                            data-testid="access-alarms-button"
                            slot="reference">
                        <i class="material-icons md-24">access_alarms</i>
                    </button>
                </el-popover>
                <span class="d-block text-xs text-muted">Reminder</span>
            </div>
            <div class="col p-0">
                <el-popover
                    placement="top"
                    width="300"
                    trigger="hover"
                    popper-class="p-2"
                    data-testid="power-dialer-popover"
                    :disabled="!isPrimaryNumberErroneous || !isNumberInvalidOrWrong(activePhoneNumber) || isPowerDialerDepreciated">
                    <span class="text-black-dk">
                        {{ getTooltipMessage(activePhoneNumber) }}
                    </span>
                    <span slot="reference">
                        <button
                            data-testid="phone-forwarded-button"
                            @click="addToPowerDialer"
                            v-loading="loading_add_to_power_dialer"
                            :disabled="loading_add_to_power_dialer || contact.is_dnc || (isPrimaryNumberErroneous && isNumberInvalidOrWrong(activePhoneNumber)) || (isPowerDialerDepreciated && forceTalk)"
                            class="md-btn md-fab md-sm m-b-sm new-purple">
                            <i class="material-icons md-24">phone_forwarded</i>
                        </button>
                    </span>
                </el-popover>
                <span class="d-block text-xs text-muted">Power Dialer</span>
            </div>
        </div>
        <div class="row no-gutter text-center m-t">
            <div class="col-3 p-0"
                 v-if="current_company && current_company.reseller_id !== 357">
                <template>
                    <el-popover
                        placement="top"
                        width="300"
                        trigger="hover"
                        popper-class="p-2"
                        data-testid="send-fax-popover"
                        :disabled="!isNumberInvalidOrWrong(activePhoneNumber) && !disable_send_fax_button">
                        <span class="text-black-dk">
                            <template v-if="isNumberInvalidOrWrong(activePhoneNumber)">
                                {{ getTooltipMessage(activePhoneNumber) }}
                            </template>
                            <template v-else-if="disable_send_fax_button">
                                Selected line is not capable of sending faxes
                            </template>
                        </span>
                        <span slot="reference">
                            <button
                                class="md-btn md-fab md-sm m-b-sm text-white bg-teal"
                                data-testid="send-fax-button"
                                @click="openSendFaxDialog"
                                :disabled="isNumberInvalidOrWrong(activePhoneNumber) || disable_send_fax_button">
                                <i class="material-icons md-24">print</i>
                            </button>
                        </span>
                    </el-popover>
                    <span class="d-block text-xs text-muted">Send Fax</span>
                </template>
            </div>
            <div class="col-3 p-0"
                 v-if="current_company && current_company.reseller_id !== 357">
                <template>
                    <button class="md-btn md-fab md-sm m-b-sm text-white bg-blue"
                            :disabled="!contact.email"
                            data-testid="send-email-button"
                            @click="openSendEmailDialog">
                        <i class="material-icons md-24">email</i>
                    </button>
                    <span class="d-block text-xs text-muted">Send Email</span>
                </template>
            </div>
            <div class="col-3 p-0"
                 v-if="hasRole('Company Admin') && ((current_company && current_company.reseller_id === 357) || !hasCrmLink) && !getCrmLink(contact)">
                <template>
                    <button
                        class="md-btn md-fab md-sm m-b-sm text-white bg-brown"
                        data-testid="call-merge-contact-button"
                        @click="openMergeContactDialog">
                        <i class="material-icons md-24 fa-rotate-90">call_merge</i>
                    </button>
                    <span class="d-block text-xs text-muted">Merge</span>
                </template>
            </div>
            <div class="col-3 p-0"
                 v-if="isSimpSocial">
                 <router-link class="md-btn md-fab md-sm m-b-sm red"
                              tag="button"
                              data-testid="simp-social-email-button"
                              :to="{ name: 'Email Blast', params: { contact_id: contact_id } }"
                              v-if="current_company && current_company.simpsocial_integration_enabled && auth.user.profile && isSimpSocial">
                    <i v-show="isSimpSocial"
                       class="material-icons md-24">email</i>
                </router-link>
                <span class="d-block text-xs text-muted">
                    Email
                </span>
            </div>
            <div class="col-3 p-0"
                 v-if="isSimpSocial">
                <button class="md-btn md-fab md-sm m-b-sm text-white bg-white p-0"
                        v-loading="isVideoConferenceLinkSending"
                        data-testid="video-conference-button"
                        @click="sendVideoConferenceLink()">
                    <img src="/assets/images/video_conference_btn.png"
                         class="md-24 w-100"/>
                </button>
                <span class="d-block text-xs text-muted">Video Conference</span>
            </div>
        </div>

        <!-- Un-DNC dialog-->

        <el-dialog
            title="Confirm"
            :visible.sync="undnc.visible"
            append-to-body
            width="30%">
            <div>
                <p>{{ undnc.confirm_message }}</p>
                <div>
                    <span>Please enter a reason:</span>
                </div>
                <el-input v-model="undnc.reason"></el-input>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button data-testid="cancel-undnc-button" 
                           @click="resetUnDncFields">
                            Cancel
                </el-button>
                <el-button type="success"
                           data-testid="confirm-undnc-button"
                           @click="unDncContact"
                           :disabled="!undnc.reason || undnc.updating_dnc_status">
                    Confirm
                </el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
    import auth from '../auth'
    import {mapState, mapGetters} from "vuex"
    import {
        acl_mixin,
        crm_link_mixin,
        date_mixin,
        contact_phone_number_mixin,
        kyc_mixin
    } from '../mixins'
    import * as CommunicationTypes from '../constants/communication-types'
    import AppMentionable from './app-mentionable'
    import CustomMessageDisplay from './kyc/custom-message-display'

    export default {
        mixins: [
            acl_mixin,
            crm_link_mixin,
            date_mixin,
            contact_phone_number_mixin,
            kyc_mixin
        ],

        components: {
            AppMentionable,
            CustomMessageDisplay
        },

        props: {
            contact: {
                required: true
            },

            contact_phone_numbers: {
                type: Array,
                required: true
            },

            selected_phone_number: {
                required: true
            },

            contact_id: {
                required: false
            },

            campaign_id: {
                required: false,
            },

            mention_items: {
                type: Array,
                required: true
            },

            disable_send_fax_button: {
                type: Boolean,
                required: true,
            }
        },

        data() {
            return {
                auth: auth,
                loading_block_contact: false,
                is_contact_blocked: false,
                loading_dnc_contact: false,
                loading_add_to_power_dialer: false,
                add_engagement: {
                    time: null,
                    date: null,
                    timezone: auth.user.profile.timezone,
                    body: '',
                },
                add_engagement_validated: false,
                loading_add_engagement: false,
                // Element UI Datepicker Options
                pickerOptions: {},
                time_picker_options: {
                    start: '06:00',
                    step: '00:15',
                    end: '23:45'
                },
                CommunicationTypes,
                items: [],
                add_note_form_visibility: false,
                undnc: {
                    confirm_message: '',
                    reason: '',
                    updating_dnc_status: false,
                    visible: false
                },
                simpsocialEmailModalOpen: false,
                isVideoConferenceLinkSending: false
            }
        },

        computed: {
            ...mapState('cache', ['current_company']),
            ...mapState(['on_call']),
            ...mapGetters('cache', ['isSimpSocial']),

            unreadCallCount() {
                return this.contact.unread_voicemail_count + this.contact.unread_missed_call_count
            },

            times() {
                const times = []
                for (let hour = 6; hour < 24; hour++) {
                    times.push({
                        label: moment({hour}).format('h:mm A'),
                        value: moment({hour}).format('HH:mm')
                    })
                    times.push({
                            label: moment({hour, minute: 15}).format('h:mm A'),
                            value: moment({hour, minute: 15}).format('HH:mm')
                        }
                    )
                    times.push({
                            label: moment({hour, minute: 30}).format('h:mm A'),
                            value: moment({hour, minute: 30}).format('HH:mm')
                        }
                    )
                    times.push({
                            label: moment({hour, minute: 45}).format('h:mm A'),
                            value: moment({hour, minute: 45}).format('HH:mm')
                        }
                    )
                }
                return times
            },

            activePhoneNumber() {
                let numbers = this.contact_phone_numbers

                // search the phone number data from the collection of contact's phone number based on the phone number
                if (this.selected_phone_number && numbers && numbers.length) {
                    return numbers.find(num => num.phone_number === this.selected_phone_number)
                }

                return null
            },

            isPrimaryNumberErroneous() {
                let numbers = this.contact_phone_numbers

                if (numbers && numbers.length) {
                    let number = numbers.find(num => num.phone_number === this.contact.phone_number)

                    return number && (number.is_invalid || number.is_wrong_number)
                }

                return true
            },

            canSendNonTCPAContacts() {
                return !(this.current_company.enforce_tcpa && !this.contact.text_authorized)
            },
        },

        methods: {
            showUnDncDialog() {
                let name = this.contact.name !== '' ? this.contact.name : this.contact.phone_number
                this.undnc.confirm_message = `Are you sure you want to Un-DNC ${ name }?`
                this.undnc.visible = true;
            },

            unDncContact() {
                if (!this.undnc.reason) {
                    return this.$notify.error({
                        title: 'Error',
                        message: 'Please enter a reason'
                    })
                }

                this.undnc.updating_dnc_status = true
                this.loading_dnc_contact = true

                axios.patch(`/api/v1/contact/${this.contact.id}/undnc`, {
                    reason: this.undnc.reason
                }).then(response => {
                    // State update
                    this.contact.is_dnc = false
                    this.loading_dnc_contact = false
                    // UI notif
                    this.$notify.success({
                        title: 'Success',
                        message: `Un-DNC contact success!`
                    })
                    // Reset un-dnc fields
                    this.resetUnDncFields()
                }).catch(err => {
                    console.log(err)
                    this.$root.handleErrors(err.response)
                    this.resetUnDncFields()
                })
            },

            resetUnDncFields() {
                this.undnc = {
                    confirm_message: '',
                    reason: '',
                    updating_dnc_status: false,
                    visible: false
                }
            },

            addAppointment() {
                let date = moment().set({hour:0,minute:0,second:0,millisecond:0})
                this.$emit('add-appointment', date)
            },

            resetAddEngagementForm(type = null, delete_content = false) {
                if (delete_content) {
                    this.add_engagement.body = null
                }
                this.add_engagement.date = null
                this.add_engagement.time = null

                this.add_engagement_validated = false
                switch (type) {
                    case CommunicationTypes.APPOINTMENT:
                        this.$refs.appointmentForm.doClose()
                        break
                    case CommunicationTypes.NOTE:
                        this.$refs.noteForm.doClose()
                        break
                    case CommunicationTypes.REMINDER:
                        this.$refs.reminderForm.doClose()
                        break
                }
            },

            resetAddEngagementFormTimezone() {
                this.add_engagement.timezone = (this.contact) ? this.contact.timezone : this.current_company.timezone
                // @custom Omid
                if (this.current_company && this.current_company.reseller_id === 357) {
                    this.add_engagement.timezone = window.timezone
                }
            },

            addEngagement(type) {
                if (!this.contact_id) {
                    return false
                }

                const params = _.assign({type}, this.add_engagement)

                this.loading_add_engagement = true

                let endpoint = `/api/v1/calendar/events/contact/${this.contact_id}/create`
                // if communication type is note, we just store it in communications table
                if (type == CommunicationTypes.NOTE) {
                    endpoint = `/api/v1/contact/${this.contact_id}/add-engagement`
                }

                axios.post(endpoint, params).then(res => {
                    this.loading_add_engagement = false
                    // check data loaded
                    if (this.contact && this.contact.communications) {
                        // check new communication exists in the old list
                        let found = this.contact.communications.find(communication => communication.id === res.data.id)
                        if (!found) {
                            // push new data to top of array
                            this.contact.communications.push(res.data)
                        }
                    }
                    this.$notify({
                        offset: 95,
                        title: 'Contact',
                        message: 'Engagement added.',
                        type: 'success',
                        showClose: true
                    })
                    this.resetAddEngagementForm(type, true)
                }).catch(err => {
                    this.loading_add_engagement = false
                    this.$root.handleErrors(err.response)
                })
            },

            validateAddAppointmentOrReminderEngagementForm() {
                this.add_engagement_validated = !!this.add_engagement.date && !!this.add_engagement.time
            },

            validateAddNoteEngagementForm(body) {
                this.add_engagement.body = body
                this.add_engagement_validated = this.add_engagement.body !== ''
            },

            blockContact: function () {
                this.loading_block_contact = true
                let params = {is_blocked: 1}
                axios.patch('/api/v1/contact/' + this.contact.id, params)
                    .then(res => {
                        this.loading_block_contact = false
                        this.is_contact_blocked = true
                        this.contact.is_blocked = true
                    })
            },

            unblockContact: function () {
                this.loading_block_contact = true
                let params = {is_blocked: 0}
                axios.patch('/api/v1/contact/' + this.contact.id, params)
                    .then(res => {
                        this.loading_block_contact = false
                        this.is_contact_blocked = false
                        this.contact.is_blocked = false
                    })
            },

            callContact() {
                // don't allow simultaneous calls
                if (this.on_call || sessionStorage.getItem('on_call') == 'true') {
                    this.$notify({
                        duration: 2000,
                        title: 'Phone',
                        message: 'Sorry, your phone looks busy. Please disconnect your ongoing call, or put yourself back to available.',
                        type: 'error',
                        showClose: true,
                        position: 'bottom-right'
                    })

                    return
                }

                // check contact has timezone or not
                if (this.contact.timezone) {
                    // if have timezone check is it day time?
                    const start_day = moment().tz(this.contact.timezone).hour(8).minute(0).second(0)
                    const end_day = moment().tz(this.contact.timezone).hour(18).minute(0).second(0)
                    const contact_local_time = moment().tz(this.contact.timezone)
                    if (!contact_local_time.isBetween(start_day, end_day)) {
                        return this.$confirm(`This is outside the lead's day time. Do you want to make a call? It's ${contact_local_time.format('hh:mm A')} for ${this.contact.name}.`, 'Call Lead', {
                            confirmButtonText: 'OK',
                            cancelButtonText: 'Cancel',
                            customClass: 'width-500 fixed',
                            type: 'warning'
                        }).then(() => {
                            this.makeCall()
                        }).catch(() => {
                        })
                    }
                }
                this.makeCall()
            },

            makeCall() {
                if (this.auth.user.profile.enabled_two_legged_outbound) {
                    let message = 'We will call your secondary phone'
                    message += ' on ' + this.auth.user.profile.secondary_phone_number
                    message += ` and connect you with ${this.contact.name}. Proceed?`
                    return this.$confirm(message, 'Going old school?', {
                        confirmButtonText: 'OK',
                        cancelButtonText: 'Cancel',
                        customClass: 'width-500 fixed',
                        type: 'warning'
                    }).then(() => {
                        this.makeTwoLeggedCall()
                    }).catch(() => {
                    })
                }
                VueEvent.fire('make_new_call', {
                    phone_number: this.contact.phone_number
                })
            },

            makeTwoLeggedCall() {
                axios.post('/api/v1/contact/' + this.contact.id + '/make-two-legged-call', {
                    phone_number: this.contact.phone_number
                }).then(res => {
                    this.$notify({
                        offset: 95,
                        title: 'Call Lead',
                        message: `We are calling your phone to connect you to ${this.contact.name}`,
                        type: 'success',
                        showClose: true
                    })
                }).catch(err => {
                    this.$root.handleErrors(err.response)
                })
            },

            dncContact() {
                this.$confirm('DNC will disable all communications to a contact and is irreversible. Continue?', 'Warning', {
                    confirmButtonText: 'OK',
                    cancelButtonText: 'Cancel',
                    customClass: 'width-500 fixed',
                    type: 'warning'
                }).then(() => {
                    this.loading_dnc_contact = true
                    let params = {
                        is_dnc: 1
                    }
                    axios.patch('/api/v1/contact/' + this.contact.id, params)
                        .then(res => {
                            this.loading_dnc_contact = false
                            this.contact.is_dnc = true
                        })
                }).catch(() => {
                })
            },

            addToPowerDialer() {
                if (!this.contact_id) {
                    return false
                }
                this.loading_add_to_power_dialer = true
                axios.post(`/api/v1/auto-dialer/add/${this.contact_id}`, {
                    allow_international_phone_numbers: true,
                }).then(() => {
                    this.loading_add_to_power_dialer = false
                    this.$notify({
                        offset: 95,
                        title: 'Contact',
                        message: 'Added to power dialer.',
                        type: 'success',
                        showClose: true
                    })
                }).catch(err => {
                    this.loading_add_to_power_dialer = false
                    this.$root.handleErrors(err.response)
                })
            },

            changeSelectedPhoneNumber(phone_number) {
                this.$emit('changeSelectedPhoneNumber', phone_number)
            },

            onAddNoteFormClose() {
                if (this.add_engagement.body) {
                    this.$confirm('Closing this note will delete its contents. Proceed anyway?', 'Warning', {
                        confirmButtonText: 'OK',
                        cancelButtonText: 'Cancel',
                        cancelButtonClass: 'cancel-transfer-button',
                        confirmButtonClass: 'confirm-transfer-button',
                        customClass: 'width-500 fixed',
                        type: 'warning'
                    }).then(res => {
                        this.resetAddEngagementForm(null, true)
                        this.add_note_form_visibility = false
                    }).catch(() => {

                    })
                } else {
                    this.add_note_form_visibility = false
                }
            },

            openSendEmailDialog() {
                VueEvent.fire('open-send-email-dialog')
            },

            openSendFaxDialog() {
                VueEvent.fire('open-send-fax-dialog')
            },

            openMergeContactDialog() {
                VueEvent.fire('open-contact-merge-dialog')
            },

            openSimpsocialEmailModal () {
                this.simpsocialEmailModalOpen = !this.simpsocialEmailModalOpen
            },

            sendVideoConferenceLink() {
                if (this.isVideoConferenceLinkSending) {
                    return
                }

                this.isVideoConferenceLinkSending = true
                axios.post(`/integrations/simpsocial/video-conference-to-contact/${this.contact.id}`, {
                    campaign_id: this.campaign_id
                }).then(res => {
                    this.isVideoConferenceLinkSending = false
                }).catch(err => {
                    this.isVideoConferenceLinkSending = false
                })
            },
        },

        beforeDestroy() {
            this.resetAddEngagementForm(null, true)
        }
    }
</script>
