<template>
  <div :key="key">
    <div v-for="contact_attribute in contact_attributes"
         :key="contact_attribute.attribute_id"
         class="md-form-group pb-2">

      <!-- if date picker -->
      <attribute-type-date-picker
          v-if="contact_attribute.type === AttributeDictionary.TYPE_DATE_PICKER"
          :contact-attribute="contact_attribute"
          :timezone="contact.timezone"
          @input-set-editable="setEditable"
          @input-save-contact-attribute="saveContactAttribute"
      />

      <!-- if any other type -->
      <attribute-type-text
          v-else
          :contact-attribute="contact_attribute"
          @input-set-editable="setEditable"
          @input-save-contact-attribute="saveContactAttribute"
          :ref="AttributeDictionary.TYPE_TEXT"
      />

      <div class="edit-field-button active">
                <span class="text-md text-greenish pointer no-select"
                      data-testid="saving-icon"
                      v-show="contact_attribute.saving">
                    <i class="material-icons loader">&#xE863;</i>
                </span>
        <span class="text-md text-greenish pointer btn-edit"
              data-testid="edit-contact-attributes-icon"
              v-if="!contact_attribute.saving && !contact_attribute.edit_mode"
              @click="setEditable(contact_attribute, true, contact_attribute.type)">
            <i class="material-icons">edit</i>
        </span>
      </div>

      <label>{{ contact_attribute.name }}</label>
    </div>
  </div>
</template>

<script>
import {acl_mixin} from "../../mixins"
import auth from '../../auth'
import AttributeTypeText from "./attribute-types/attribute-type-text";
import AttributeTypeDatePicker from "./attribute-types/attribute-type-date-picker";
import * as AttributeDictionary from "../../constants/attribute-dictionary";

export default {
    name: "contact-attributes",

    components: {
      AttributeTypeText,
      AttributeTypeDatePicker,
    },

    mixins: [acl_mixin],

    props: {
        contact: {
            required: true,
            type: Object,
            default: function() {
                return null
            }
        }
    },

    data() {
        return {
            auth: auth,
            contact_attributes: [],
            key: 1,
            AttributeDictionary,
        }
    },

    mounted() {
        if (this.contact.id) {
            this.fetchContactAttributes(this.contact)
        }
    },

    methods: {
        fetchContactAttributes(contact) {
            axios.get(`/api/v1/contact-attributes/${contact.id}`).then(res => {
                this.contact_attributes = res.data
            })
        },

        saveContactAttribute(contact_attribute) {
            this.debounceSaveContactAttribute(this, contact_attribute, this.contact)
        },

        setEditable(contact_attribute, value, attribute_type) {
          this.key++
          contact_attribute.edit_mode = value

          try {
            if (value) {
              this.$nextTick(() => {
                if (this.isFocusable(contact_attribute)) {
                  const refsFound = this.$refs[attribute_type].filter((component) => {
                    return component.$refs[contact_attribute.slug] || false
                  })

                  if (refsFound.length > 0 && (refsFound[0].$refs[contact_attribute.slug] ?? false)) {
                    refsFound[0].$refs[contact_attribute.slug].click()
                    refsFound[0].$refs[contact_attribute.slug].focus()
                  }
                }
              })
            }
          } catch (e) {
            console.error(e)
          }
        },

        debounceSaveContactAttribute: _.debounce((instance, contact_attribute, contact) => {
          instance.key++
          contact_attribute.saving = true

          axios.post(`/api/v1/contact-attributes/${contact_attribute.attribute_id}/${contact.id}`, {
              value: contact_attribute.value
          }).then(res => {
              instance.key++
              contact_attribute.saving = false
              instance.$notify({
                  offset: 95,
                  title: 'Custom Field',
                  message: res.data.message,
                  type: 'success',
                  duration: 6000,
              });
          })
        }, 1000),

        isFocusable(contact_attribute) {
          return contact_attribute.type === AttributeDictionary.TYPE_TEXT
        }
    },

    watch: {
        contact: function(n, o) {
            // hit the API only if contact was changed
            if (JSON.stringify(n) !== JSON.stringify(o)) {
                this.fetchContactAttributes(this.contact)
            }
        }
    }
}
</script>
