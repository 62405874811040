var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-form",
    {
      ref: "companySettingsForm",
      staticClass: "form-aloware",
      attrs: { model: _vm.report_setting, rules: _vm.rules },
      nativeOn: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.submitForm("companySettingsForm")
        },
      },
    },
    [
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-md-7" },
          [
            _c(
              "el-form-item",
              { attrs: { prop: "default_report_period" } },
              [
                _c("span", {
                  staticClass: "page-anchor",
                  attrs: { id: "default_report_period" },
                }),
                _vm._v(" "),
                _c("div", { staticClass: "form-label" }, [
                  _c("h5", [_vm._v("Default Report Period")]),
                  _vm._v(" "),
                  _c("small", [
                    _vm._v(
                      "Configure how the default timeframe for the dashboard and its main graph."
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "el-select",
                  {
                    staticClass: "w-full",
                    on: {
                      change: function ($event) {
                        return _vm.updateParent("default_report_period")
                      },
                    },
                    model: {
                      value: _vm.report_setting.default_report_period,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.report_setting,
                          "default_report_period",
                          $$v
                        )
                      },
                      expression: "report_setting.default_report_period",
                    },
                  },
                  [
                    _c("el-option", { attrs: { value: "day" } }),
                    _vm._v(" "),
                    _c("el-option", { attrs: { value: "week" } }),
                    _vm._v(" "),
                    _c("el-option", { attrs: { value: "month" } }),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c("el-form-item", [
              _c("span", {
                staticClass: "page-anchor",
                attrs: { id: "daily_activity_reports_enabled" },
              }),
              _vm._v(" "),
              _c("div", { staticClass: "form-label" }, [
                _c("h5", [_vm._v("Daily Activity Reports")]),
                _vm._v(" "),
                _c("small", [
                  _vm._v(
                    "\n                        Send daily reports of calls and texts, new lead dispositions,\n                        and agents activities by email. This report is emailed to\n                        account admins and includes unreturned statistics. "
                  ),
                  _c("br"),
                  _vm._v(
                    "\n                        An unreturned conversation is any contact that has an unread communication or is without a response."
                  ),
                  _c("br"),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      staticClass: "is-link",
                      attrs: {
                        href: "https://support.aloware.com/en/articles/9034116-aloware-s-unreturned-conversations-your-key-to-unmissed-opportunities",
                        target: "_blank",
                      },
                    },
                    [
                      _c("i", { staticClass: "material-icons text-lg" }, [
                        _vm._v("help"),
                      ]),
                      _vm._v(
                        "\n                           Click here to learn more\n                        "
                      ),
                    ]
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "switch-label" },
                [
                  _c("el-switch", {
                    attrs: { "active-color": "#00a344" },
                    on: {
                      change: function ($event) {
                        return _vm.updateParent(
                          "daily_activity_reports_enabled"
                        )
                      },
                    },
                    model: {
                      value: _vm.report_setting.daily_activity_reports_enabled,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.report_setting,
                          "daily_activity_reports_enabled",
                          $$v
                        )
                      },
                      expression:
                        "report_setting.daily_activity_reports_enabled",
                    },
                  }),
                  _vm._v(" "),
                  _c("label", [_vm._v("Enable daily activity reports")]),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "form-label mt-2" }, [
                _c("small", [
                  _vm._v(
                    "\n                        By selecting this box, all phone extension metrics will be included in the report.\n                    "
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "div",
                [
                  _c(
                    "el-checkbox",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            _vm.report_setting.daily_activity_reports_enabled,
                          expression:
                            "report_setting.daily_activity_reports_enabled",
                        },
                      ],
                      staticClass: "mr-2 mt-2",
                      attrs: { border: "" },
                      on: {
                        change: function ($event) {
                          return _vm.updateParent(
                            "send_daily_activity_reports_with_user_extensions"
                          )
                        },
                      },
                      model: {
                        value:
                          _vm.report_setting
                            .send_daily_activity_reports_with_user_extensions,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.report_setting,
                            "send_daily_activity_reports_with_user_extensions",
                            $$v
                          )
                        },
                        expression:
                          "report_setting.send_daily_activity_reports_with_user_extensions",
                      },
                    },
                    [_c("strong", [_vm._v("Include extensions")])]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "form-label mt-2" }, [
                _c("small", [
                  _vm._v(
                    "\n                        By selecting this box, only users with activity will be included in the report.\n                    "
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "div",
                [
                  _c(
                    "el-checkbox",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            _vm.report_setting.daily_activity_reports_enabled,
                          expression:
                            "report_setting.daily_activity_reports_enabled",
                        },
                      ],
                      staticClass: "mr-2 mt-2",
                      attrs: { border: "" },
                      on: {
                        change: function ($event) {
                          return _vm.updateParent(
                            "send_daily_activity_reports_hiding_users_without_activity"
                          )
                        },
                      },
                      model: {
                        value:
                          _vm.report_setting
                            .send_daily_activity_reports_hiding_users_without_activity,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.report_setting,
                            "send_daily_activity_reports_hiding_users_without_activity",
                            $$v
                          )
                        },
                        expression:
                          "report_setting.send_daily_activity_reports_hiding_users_without_activity",
                      },
                    },
                    [_c("strong", [_vm._v("Hide Users Without Activity")])]
                  ),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c(
              "el-form-item",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.report_setting.daily_activity_reports_enabled,
                    expression: "report_setting.daily_activity_reports_enabled",
                  },
                ],
                attrs: { prop: "send_daily_activity_reports_on" },
              },
              [
                _c("span", {
                  staticClass: "page-anchor",
                  attrs: { id: "send_daily_activity_reports_on" },
                }),
                _vm._v(" "),
                _c("div", { staticClass: "form-label" }, [
                  _c("h5", [_vm._v("Send Activity Reports On")]),
                  _vm._v(" "),
                  _c("small", [
                    _vm._v(
                      "When to send the report, in your company timezone."
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "el-select",
                  {
                    staticClass: "w-full",
                    attrs: { filterable: "", placeholder: "Select time" },
                    on: {
                      change: function ($event) {
                        return _vm.updateParent(
                          "send_daily_activity_reports_on"
                        )
                      },
                    },
                    model: {
                      value: _vm.report_setting.send_daily_activity_reports_on,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.report_setting,
                          "send_daily_activity_reports_on",
                          $$v
                        )
                      },
                      expression:
                        "report_setting.send_daily_activity_reports_on",
                    },
                  },
                  _vm._l(_vm.everyThirtyMinutes, function (time) {
                    return _c("el-option", {
                      key: time.value,
                      attrs: { label: time.label, value: time.value },
                    })
                  }),
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.report_setting.daily_activity_reports_enabled,
                    expression: "report_setting.daily_activity_reports_enabled",
                  },
                ],
              },
              [
                _c("span", {
                  staticClass: "page-anchor",
                  attrs: { id: "send_daily_activity_reports_for" },
                }),
                _vm._v(" "),
                _c("div", { staticClass: "form-label" }, [
                  _c("h5", [_vm._v("Send Activity Reports For")]),
                  _vm._v(" "),
                  _c("small", [
                    _vm._v(
                      "Receive report from the beginning of the day (00:00 am) or the previous cut off."
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "el-radio-group",
                  {
                    on: {
                      change: function ($event) {
                        return _vm.updateParent(
                          "send_daily_activity_reports_for"
                        )
                      },
                    },
                    model: {
                      value: _vm.report_setting.send_daily_activity_reports_for,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.report_setting,
                          "send_daily_activity_reports_for",
                          $$v
                        )
                      },
                      expression:
                        "report_setting.send_daily_activity_reports_for",
                    },
                  },
                  [
                    _c("el-radio-button", { attrs: { label: 1 } }, [
                      _vm._v("Today"),
                    ]),
                    _vm._v(" "),
                    _c("el-radio-button", { attrs: { label: 2 } }, [
                      _vm._v("Last 24 hours"),
                    ]),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  [
                    _c(
                      "el-button",
                      {
                        staticClass: "bg-deep-orange mt-2",
                        attrs: {
                          type: "warning",
                          size: "small",
                          loading: _vm.test_activity_report.loading,
                        },
                        on: { click: _vm.sendActivityReports },
                      },
                      [
                        _vm._v(
                          "\n                        Test daily activity reports\n                    "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              { attrs: { prop: "daily_sms_report" } },
              [
                _c("span", {
                  staticClass: "page-anchor",
                  attrs: { id: "daily_sms_report" },
                }),
                _vm._v(" "),
                _c("div", { staticClass: "form-label" }, [
                  _c("h5", [_vm._v("Daily Inbound SMS Report")]),
                  _vm._v(" "),
                  _c("small", [
                    _vm._v(
                      "\n                        Receive a daily report of your inbound SMS messages.\n                        Useful for accounts with heavy SMS marketing.\n                    "
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "el-select",
                  {
                    staticClass: "w-full",
                    attrs: { filterable: "", placeholder: "Pick an option" },
                    on: {
                      change: function ($event) {
                        return _vm.updateParent("daily_sms_report")
                      },
                    },
                    model: {
                      value: _vm.report_setting.daily_sms_report,
                      callback: function ($$v) {
                        _vm.$set(_vm.report_setting, "daily_sms_report", $$v)
                      },
                      expression: "report_setting.daily_sms_report",
                    },
                  },
                  _vm._l(_vm.dailySmsReportPeriods, function (time) {
                    return _c(
                      "el-option",
                      {
                        key: time.value,
                        attrs: { label: time.label, value: time.value },
                      },
                      [
                        _c("div", { staticClass: "media" }, [
                          _c("div", { staticClass: "media-body" }, [
                            _c("label", [_vm._v(_vm._s(time.label))]),
                            _vm._v(" "),
                            _c("small", [_vm._v(_vm._s(time.description))]),
                          ]),
                        ]),
                      ]
                    )
                  }),
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              { attrs: { prop: "daily_outbound_sms_report" } },
              [
                _c("span", {
                  staticClass: "page-anchor",
                  attrs: { id: "daily_outbound_sms_report" },
                }),
                _vm._v(" "),
                _c("div", { staticClass: "form-label" }, [
                  _c("h5", [_vm._v("Daily Outbound SMS Report")]),
                  _vm._v(" "),
                  _c("small", [
                    _vm._v(
                      "\n                        Receive a daily report of your outbound SMS messages.\n                        Useful for accounts with heavy SMS marketing.\n                    "
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "el-select",
                  {
                    staticClass: "w-full",
                    attrs: { filterable: "", placeholder: "Pick an option" },
                    on: {
                      change: function ($event) {
                        return _vm.updateParent("daily_outbound_sms_report")
                      },
                    },
                    model: {
                      value: _vm.report_setting.daily_outbound_sms_report,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.report_setting,
                          "daily_outbound_sms_report",
                          $$v
                        )
                      },
                      expression: "report_setting.daily_outbound_sms_report",
                    },
                  },
                  _vm._l(_vm.dailySmsReportPeriods, function (time) {
                    return _c(
                      "el-option",
                      {
                        key: time.value,
                        staticClass: "p-0",
                        attrs: { label: time.label, value: time.value },
                      },
                      [
                        _c("div", { staticClass: "media" }, [
                          _c("div", { staticClass: "media-body" }, [
                            _c("label", [_vm._v(_vm._s(time.label))]),
                            _vm._v(" "),
                            _c("small", [_vm._v(_vm._s(time.description))]),
                          ]),
                        ]),
                      ]
                    )
                  }),
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _vm.report_setting &&
            !_vm.report_setting.is_high_volume &&
            (!_vm.report_setting.reseller_id ||
              _vm.report_setting.reseller_id != 357)
              ? _c(
                  "el-form-item",
                  { attrs: { prop: "daily_spend_report" } },
                  [
                    _c("span", {
                      staticClass: "page-anchor",
                      attrs: { id: "daily_spend_report" },
                    }),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-label" }, [
                      _c("h5", [_vm._v("Daily Spending Report")]),
                      _vm._v(" "),
                      _c("small", [
                        _vm._v(
                          "Receive a detailed report of your spending on our platform."
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c(
                      "el-select",
                      {
                        staticClass: "w-full",
                        attrs: {
                          filterable: "",
                          placeholder: "Pick an option",
                        },
                        on: {
                          change: function ($event) {
                            return _vm.updateParent("daily_spend_report")
                          },
                        },
                        model: {
                          value: _vm.report_setting.daily_spend_report,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.report_setting,
                              "daily_spend_report",
                              $$v
                            )
                          },
                          expression: "report_setting.daily_spend_report",
                        },
                      },
                      _vm._l(_vm.dailySpendingReportPeriods, function (time) {
                        return _c("el-option", {
                          key: time.value,
                          attrs: { label: time.label, value: time.value },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "el-form-item",
              {
                staticClass: "border-0 pb-0",
                attrs: { prop: "daily_dnc_report_enabled" },
              },
              [
                _c("span", {
                  staticClass: "page-anchor",
                  attrs: { id: "daily_dnc_report_enabled" },
                }),
                _vm._v(" "),
                _c("div", { staticClass: "form-label" }, [
                  _c("h5", [_vm._v("Daily DNC Report")]),
                  _vm._v(" "),
                  _c("small", [
                    _vm._v(
                      "\n                        Receive a daily report of contacts set to DNC.\n                    "
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "switch-label" },
                  [
                    _c("el-switch", {
                      attrs: { "active-color": "#00a344" },
                      on: {
                        change: function ($event) {
                          return _vm.updateParent("daily_dnc_report_enabled")
                        },
                      },
                      model: {
                        value: _vm.report_setting.daily_dnc_report_enabled,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.report_setting,
                            "daily_dnc_report_enabled",
                            $$v
                          )
                        },
                        expression: "report_setting.daily_dnc_report_enabled",
                      },
                    }),
                    _vm._v(" "),
                    _c("label", [_vm._v("Enable daily DNC report")]),
                  ],
                  1
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              { staticClass: "no-border pb-3" },
              [
                _c(
                  "el-popover",
                  {
                    attrs: {
                      placement: "right",
                      width: "320",
                      trigger: "hover",
                    },
                  },
                  [
                    _c("span", [
                      _vm._v(
                        "Go to User Settings > Notification Settings > Account Level Notifications > Account Reports"
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        staticClass: "border-bottom-dashed cursor-pointer",
                        attrs: { slot: "reference" },
                        slot: "reference",
                      },
                      [
                        _vm._v(
                          "\n                        To enable these settings for a specific User\n                    "
                        ),
                      ]
                    ),
                  ]
                ),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "col-md-2 d-none d-md-block ml-auto" }, [
          _c(
            "div",
            {
              staticClass:
                "list-group list-group-alo list-group-flush sticky-top stick-top-with-header top-70",
            },
            [
              _c("h6", { staticClass: "list-group-item" }, [
                _vm._v("\n                    On This Page\n                "),
              ]),
              _vm._v(" "),
              _c(
                "a",
                {
                  staticClass: "list-group-item list-group-item-action",
                  attrs: { href: "#default_report_period" },
                  on: {
                    click: function ($event) {
                      return _vm.shine("default_report_period")
                    },
                  },
                },
                [
                  _vm._v(
                    "\n                    Default Report Period\n                "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "a",
                {
                  staticClass: "list-group-item list-group-item-action",
                  attrs: { href: "#daily_activity_reports_enabled" },
                  on: {
                    click: function ($event) {
                      return _vm.shine("daily_activity_reports_enabled")
                    },
                  },
                },
                [
                  _vm._v(
                    "\n                    Daily Activity Reports\n                "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "a",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.report_setting.daily_activity_reports_enabled,
                      expression:
                        "report_setting.daily_activity_reports_enabled",
                    },
                  ],
                  staticClass: "list-group-item list-group-item-action",
                  attrs: { href: "#send_daily_activity_reports_on" },
                  on: {
                    click: function ($event) {
                      return _vm.shine("send_daily_activity_reports_on")
                    },
                  },
                },
                [
                  _vm._v(
                    "\n                    Send Activity Reports On\n                "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "a",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.report_setting.daily_activity_reports_enabled,
                      expression:
                        "report_setting.daily_activity_reports_enabled",
                    },
                  ],
                  staticClass: "list-group-item list-group-item-action",
                  attrs: { href: "#send_daily_activity_reports_for" },
                  on: {
                    click: function ($event) {
                      return _vm.shine("send_daily_activity_reports_for")
                    },
                  },
                },
                [
                  _vm._v(
                    "\n                    Send Activity Reports For\n                "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "a",
                {
                  staticClass: "list-group-item list-group-item-action",
                  attrs: { href: "#daily_sms_report" },
                  on: {
                    click: function ($event) {
                      return _vm.shine("daily_sms_report")
                    },
                  },
                },
                [
                  _vm._v(
                    "\n                    Daily Inbound SMS Report\n                "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "a",
                {
                  staticClass: "list-group-item list-group-item-action",
                  attrs: { href: "#daily_outbound_sms_report" },
                  on: {
                    click: function ($event) {
                      return _vm.shine("daily_outbound_sms_report")
                    },
                  },
                },
                [
                  _vm._v(
                    "\n                    Daily Outbound SMS Report\n                "
                  ),
                ]
              ),
              _vm._v(" "),
              _vm.report_setting &&
              !_vm.report_setting.is_high_volume &&
              (!_vm.report_setting.reseller_id ||
                _vm.report_setting.reseller_id != 357)
                ? _c(
                    "a",
                    {
                      staticClass: "list-group-item list-group-item-action",
                      attrs: { href: "#daily_spend_report" },
                      on: {
                        click: function ($event) {
                          return _vm.shine("daily_spend_report")
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n                    Daily Spending Report\n                "
                      ),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "a",
                {
                  staticClass: "list-group-item list-group-item-action",
                  attrs: { href: "#daily_dnc_report_enabled" },
                  on: {
                    click: function ($event) {
                      return _vm.shine("daily_dnc_report_enabled")
                    },
                  },
                },
                [
                  _vm._v(
                    "\n                    Daily DNC Report\n                "
                  ),
                ]
              ),
            ]
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }