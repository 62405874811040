<template>
    <div ref="mentionableContainer"
         class="position-relative"
         @click="handleMentionClick">
        <div v-click-outside="handleSuggestionsClickOutside">
            <ul ref="mentionableListContainer"
                class="mentionable-list shadow bg-white position-absolute w-100 mb-0 list-unstyled"
                :style="{bottom: `${offsetY}px`, left: `${offsetX}px`}"
                v-show="isMentioning">
                <li ref="mentionableListItem"
                    v-for="(mutableItem, i) in mutableItems"
                    v-bind:key="i"
                    class="mentionable-list-item p-1"
                    :class="i == 0 ? 'selected' : ''"
                    @click="handleClick($event, mutableItem)"
                    @mouseenter="handleMouseEnter(i)"
                    @mouseleave="handleMouseLeave(i)">
                    <slot name="item"
                          :item="mutableItem">
                        {{ searchKey ? mutableItem[searchKey] : mutableItem }}
                    </slot>
                </li>
            </ul>
        </div>

        <contenteditable :contenteditable="!disabled"
                         class="editor p-1"
                         ref="editor"
                         tag="div"
                         v-model="message"
                         :noHTML="false"
                         :noNL="true"
                         :placeholder="placeholder"
                         :disabled="disabled"
                         @input="handleInput"
                         @keydown="handleKeyDown"
                         @mousedown="handleMouseDown"
                         @mouseup="handleMouseUp"
                         @mousemove="handleMouseMove"
                         @click="handleEditorClick">
        </contenteditable>
    </div>
</template>

<script>
export default {
    name: 'app-mentionable',

    props: {
        items: {
            type: Array,
            required: true,
        },

        searchKey: {
            type: String,
            required: false,
        },

        value: {
            type: String,
            required: false,
        },

        disabled: {
            type: Boolean,
            required: false,
            default: false
        },

        placeholder: {
            type: String,
            required: false,
            default: 'Type @ to mention someone'
        }
    },

    data() {
        return {
            text: '',
            mutableItems: [...this.items],
            searchTerm: '',
            isMentioning: false,
            message: this.value,
            KEYBOARD_EVENT_CODES: {
                BACKSPACE: 'Backspace',
                KEY_A: 'KeyA',
                ARROW_UP: 'ArrowUp',
                ARROW_DOWN: 'ArrowDown',
                ENTER: 'Enter'
            },
            trigger: '@',
            hasAllSelected: false,
            hasTriggerSelected: false,
            mouseMoved: false,
            selectedText: '',
            offsetX: 0,
            offsetY: 0,
            savedSelection: {},
            enterPressed: false
        }
    },

    watch: {
        value(v) {
            this.message = v
        },
    },

    methods: {
        handleMouseDown() {
            this.mouseMoved = false
        },

        handleMouseUp(e) {
            this.selectedText = window.getSelection().toString()
        },

        handleMouseMove() {
            this.mouseMoved = true
        },

        handleKeyDown(e) {
            switch (e.code) {
                case this.KEYBOARD_EVENT_CODES.BACKSPACE:
                    if (
                        this.message.slice(-1) == this.trigger ||
                        this.hasAllSelected ||
                        this.selectedText
                    ) {
                        if (this.selectedText) {
                            // when there are still other text not selected
                            if (this.selectedText != this.message) {
                                let index = this.message.indexOf(this.selectedText)

                                // disregard if index is 0 or -1
                                if (index) {
                                    let remainingText = this.message.substr(0, index),
                                        lastChar = remainingText.slice(-1),
                                        lastWord = remainingText.split(' ').pop()

                                    // last word contains '@'
                                    // last char is not a space
                                    if (lastWord.includes(this.trigger) && lastChar.trim()) {
                                        this.isMentioning = true
                                    } else {
                                        this.isMentioning = false
                                    }
                                } else {
                                    this.isMentioning = false
                                }
                            } else {
                                this.isMentioning = false
                            }
                        } else {
                            this.isMentioning = false
                        }
                    }

                    break
                case this.KEYBOARD_EVENT_CODES.KEY_A:
                    // user pressed the 'ctrl' + 'a' on windows or 'cmd' + 'a' on mac keys which means the action was an intent to 'select all'
                    if (e.metaKey) {
                        this.hasAllSelected = true
                    } else {
                        this.hasAllSelected = false
                    }

                    break
                case this.KEYBOARD_EVENT_CODES.ARROW_UP:
                case this.KEYBOARD_EVENT_CODES.ARROW_DOWN:
                    if (this.isMentioning) {
                        e.preventDefault()

                        let container = this.$refs.mentionableListContainer,
                            items = this.$refs.mentionableListItem,
                            index = items.findIndex(item => {
                                // this is necessary because the 'classList' property is an instance of DOMTokenList which contains named keys and not a simple array
                                // we need to "flatten" it first
                                let length = item.classList.length,
                                    classes = []

                                for (let i = 0; i < length; i++) {
                                    classes.push(item.classList[i])
                                }

                                return classes.includes('selected')
                            }),
                            nextIndex = 0,
                            conditionsPass = false

                        if (e.code == this.KEYBOARD_EVENT_CODES.ARROW_UP) {
                            nextIndex = index - 1
                        }

                        if (e.code == this.KEYBOARD_EVENT_CODES.ARROW_DOWN) {
                            nextIndex = index + 1
                        }

                        // conditions:
                        // index should not return a -1
                        // the next index should not be less than 0
                        // there should more than one item in the list
                        // the next index should not be more than the number of items in the list
                        conditionsPass = index > -1 && !(nextIndex < 0) && items.length > 1 && !(nextIndex >= items.length)

                        if (conditionsPass) {
                            items[index].classList.remove('selected')
                            items[nextIndex].classList.add('selected')

                            if (e.code == this.KEYBOARD_EVENT_CODES.ARROW_DOWN && !this.isElementInView(items[nextIndex])) {
                                this.scrollDownTo(items[nextIndex])
                            }

                            if (e.code == this.KEYBOARD_EVENT_CODES.ARROW_UP && !this.isElementInView(items[nextIndex])) {
                                this.scrollUpTo(items[nextIndex])
                            }
                        }
                    }
                    break
                case this.KEYBOARD_EVENT_CODES.ENTER:
                    if (this.isMentioning) {
                        let items = this.$refs.mentionableListItem,
                            index = items.findIndex(item => {
                                // this is necessary because the 'classList' property is an instance of DOMTokenList which contains named keys and not a simple array
                                // we need to "flatten" it first
                                let length = item.classList.length,
                                    classes = []

                                for (let i = 0; i < length; i++) {
                                    classes.push(item.classList[i])
                                }

                                return classes.includes('selected')
                            }),
                            selectedItem = null

                        if (index > -1) {
                            selectedItem = items[index]
                        }

                        if (selectedItem) {
                            this.enterPressed = true
                            selectedItem.click()
                        }
                    }
                    break
            }

            this.selectedText = ''
        },

        handleInput(value) {
            let i = this.getCaretCharacterOffsetWithin(this.$refs.editor.$el) - 1,
                stripped = this.stripSpanTags(value),
                keyPressed = stripped.charAt(i)

            // if key pressed is the trigger
            // default is '@'
            if (keyPressed == this.trigger) {
                let prevKeyPressed = stripped.charAt(i - 1)

                if (!prevKeyPressed.trim() && !this.isMentioning) {
                    this.isMentioning = true

                    // position popover accordingly
                    const {x, y} = this.getCaretCoordinates()
                    const {left, bottom} = this.$refs.mentionableContainer.getBoundingClientRect()

                    this.offsetX = x - left
                    this.offsetY = bottom - y
                }
            }

            if (this.isMentioning) {
                // reverse loop to find nearest trigger (default '@') to the left
                for (let index = i; index >= 0; index--) {
                    if (stripped[index] == this.trigger) {
                        this.searchTerm = this.replaceNbsps(stripped.substring(index + 1, i + 1))
                        break
                    }
                }

                this.filterItems()
            } else {
                this.searchTerm = ''
            }

            this.$emit('input', this.message)
        },

        handleClick(event, mutableItem) {
            let editor = this.$refs.editor.$el

            if (!this.enterPressed) {
                this.$refs.editor.$el.focus()
                this.restoreSelection(editor)
            }

            let text = mutableItem[this.searchKey ?? 'name'],
                id = mutableItem[this.primaryKey ?? 'id'],
                node = document.createTextNode(text)

            this.insertNodeAtCaret(node)

            this.message = editor.innerHTML
            let index = this.getCaretPosition(node),
                key = this.generateKey(32)

            // reverse loop to find nearest trigger (default '@') of the most recent inserted mention
            for (let i = (index + text.length) - 1; i >= 0; i--) {
                if (editor.innerHTML[i] == this.trigger) {
                    let el = `<span class="mention" data-key="${key}" data-id="${id}">${this.trigger + text}</span>`
                    this.message = this.message.substring(0, i) + el + this.message.substring(i + this.searchTerm.length + text.length + 1)
                    break
                }
            }

            // set caret position at end of inserted mention (span tag)
            this.$nextTick(() => {
                let range = document.createRange(),
                    selection = window.getSelection(),
                    mention = document.querySelector(`.mention[data-key="${key}"]`)

                mention.contentEditable = false
                range.setStartAfter(mention)
                range.setEndAfter(mention)
                selection.removeAllRanges()
                selection.addRange(range)
                this.searchTerm = ''
                this.mutableItems = this.items
                this.isMentioning = false
                this.enterPressed = false

                this.$refs.mentionableListItem[0].classList.add('selected')
                this.$refs.mentionableListContainer.scrollTop = 0
            });
        },

        handleMouseEnter(i) {
            this.$refs.mentionableListItem[i].classList.add('selected')
            this.saveSelection(this.$refs.editor.$el)
        },

        handleMouseLeave(i) {
            this.$refs.mentionableListItem[i].classList.remove('selected')
        },

        handleMentionClick(e) {
            if (e.target.matches('.mention')) {
                // TODO: move caret to end of clicked mention
            }
        },

        insertNodeAtCaret(node) {
            let selection = window.getSelection(),
                range = selection.getRangeAt(0)

            range.insertNode(node)

            range.setStartAfter(node)
            range.setEndAfter(node)
            selection.removeAllRanges()
            selection.addRange(range)
        },

        getCaretPosition(node) {
            if (window.getSelection && window.getSelection().getRangeAt) {
                let range = window.getSelection().getRangeAt(0),
                    selectedObj = window.getSelection(),
                    rangeCount = 0,
                    editor = this.$refs.editor.$el,
                    childNodes = editor.childNodes
                // childNodes = selectedObj.anchorNode.parentNode.childNodes

                for (let i = 0; i < childNodes.length; i++) {
                    if (childNodes[i] == node) {
                        break
                    }
                    if (childNodes[i].outerHTML)
                        rangeCount += childNodes[i].outerHTML.length
                    else if (childNodes[i].nodeType == 3) {
                        rangeCount += childNodes[i].textContent.length
                    }
                }

                return range.startOffset + rangeCount
            }

            return -1
        },

        stripSpanTags(html) {
            let tmp = document.createElement('div')
            tmp.innerHTML = html
            return tmp.textContent || tmp.innerText || ''
        },

        isElementInView(element, fullyInView = true) {
            let container = this.$refs.mentionableListContainer,
                pageTop = container.scrollTop,
                pageBottom = pageTop + container.offsetHeight,
                elementTop = element.offsetTop,
                elementBottom = elementTop + element.offsetHeight

            if (fullyInView === true) {
                return ((pageTop < elementTop) && (pageBottom > elementBottom))
            } else {
                return ((elementTop <= pageBottom) && (elementBottom >= pageTop))
            }
        },

        scrollDownTo(element) {
            let container = this.$refs.mentionableListContainer

            container.scrollTop += (element.offsetTop + element.offsetHeight) - (container.offsetHeight + container.scrollTop)
        },

        scrollUpTo(element) {
            let container = this.$refs.mentionableListContainer

            container.scrollTop -= container.scrollTop - element.offsetTop
        },

        getCaretCharacterOffsetWithin(element) {
            let caretOffset = 0
            let doc = element.ownerDocument || element.document
            let win = doc.defaultView || doc.parentWindow
            let sel
            if (typeof win.getSelection != "undefined") {
                sel = win.getSelection()
                if (sel.rangeCount > 0) {
                    let range = win.getSelection().getRangeAt(0)
                    let preCaretRange = range.cloneRange()
                    preCaretRange.selectNodeContents(element)
                    preCaretRange.setEnd(range.endContainer, range.endOffset)
                    caretOffset = preCaretRange.toString().length
                }
            } else if ((sel = doc.selection) && sel.type != "Control") {
                let textRange = sel.createRange()
                let preCaretTextRange = doc.body.createTextRange()
                preCaretTextRange.moveToElementText(element)
                preCaretTextRange.setEndPoint("EndToEnd", textRange)
                caretOffset = preCaretTextRange.text.length
            }
            return caretOffset
        },

        generateKey(length) {
            let result = ''
            let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
            let charactersLength = characters.length
            for (let i = 0; i < length; i++) {
                result += characters.charAt(Math.floor(Math.random() * charactersLength))
            }
            return result
        },

        getCaretCoordinates() {
            const selection = window.getSelection()

            if (selection.rangeCount !== 0) {
                const range = selection.getRangeAt(0).cloneRange()
                range.collapse(true)
                const rect = range.getClientRects()[0]
                return rect
            }

            return null
        },

        saveSelection(containerEl) {
            let range = window.getSelection().getRangeAt(0)
            let preSelectionRange = range.cloneRange()
            preSelectionRange.selectNodeContents(containerEl)
            preSelectionRange.setEnd(range.startContainer, range.startOffset)
            let start = preSelectionRange.toString().length

            this.savedSelection = {
                start: start,
                end: start + range.toString().length
            }
        },

        restoreSelection(containerEl) {
            let charIndex = 0,
                range = document.createRange()

            const {savedSelection: savedSel} = this

            range.setStart(containerEl, 0)
            range.collapse(true)
            let nodeStack = [containerEl], node, foundStart = false, stop = false

            while (!stop && (node = nodeStack.pop())) {
                if (node.nodeType == 3) {
                    let nextCharIndex = charIndex + node.length
                    if (!foundStart && savedSel.start >= charIndex && savedSel.start <= nextCharIndex) {
                        range.setStart(node, savedSel.start - charIndex)
                        foundStart = true
                    }
                    if (foundStart && savedSel.end >= charIndex && savedSel.end <= nextCharIndex) {
                        range.setEnd(node, savedSel.end - charIndex)
                        stop = true
                    }
                    charIndex = nextCharIndex
                } else {
                    let i = node.childNodes.length
                    while (i--) {
                        nodeStack.push(node.childNodes[i])
                    }
                }
            }

            let sel = window.getSelection()
            sel.removeAllRanges()
            sel.addRange(range)
        },

        filterItems() {
            if (this.searchTerm) {
                this.mutableItems = this.items.filter(item => {
                    if (this.searchKey) {
                        item = item[this.searchKey]
                    }

                    return item.toLowerCase().includes(this.searchTerm.toLowerCase())
                })
            } else {
                this.mutableItems = this.items
            }

            this.$nextTick(() => {
                let items = this.$refs.mentionableListItem

                if (items) {
                    for (let i = 0; i < items.length; i++) {
                        if (i == 0) {
                            items[i].classList.add('selected')
                        } else {
                            items[i].classList.remove('selected')
                        }
                    }
                }
            })
        },

        replaceNbsps(str) {
            return str.replace(/&nbsp;/g, ' ');
        },

        handleSuggestionsClickOutside() {
            this.isMentioning = false
        },

        handleEditorClick() {
            let i = this.getCaretCharacterOffsetWithin(this.$refs.editor.$el) - 1,
                stripped = this.stripSpanTags(this.message),
                charAtCaretPos = stripped.charAt(i)

            if (charAtCaretPos != this.trigger) {
                this.isMentioning = false
            }
        }
    }
}
</script>

<style>
.mentionable-list {
    z-index: 9999;
    max-height: 180px;
    overflow-y: auto;
    cursor: pointer;
}

.mentionable-list-item.selected {
    background: lightgrey;
}

.editor .mention {
    background: rgba(0, 191, 80, .1);
    color: rgba(0, 191, 80, 1);
}

.editor .mention:hover {
    background: rgba(0, 191, 80, .125);
}

.editor {
    border: 1px solid #DCDFE6;
    min-height: calc(34px * 2);
    transition: border-color .2s cubic-bezier(.645, .045, .355, 1);
    line-height: 22.5px;
}

.editor:hover {
    border-color: #868E96;
}

.editor:focus {
    border-color: #00BF50 !important;
}

.editor:empty:before {
    content: attr(placeholder);
    pointer-events: none;
    display: block; /* For Firefox */
}

.editor[contenteditable="false"] {
    background-color: #FBFCFD;
    border-color: #E4E7ED;
    color: #868E96;
    cursor: not-allowed;
}
</style>
