var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm._m(0),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "col-12 mt-4" },
      [
        _c("p", { staticClass: "mb-0 font-weight-bold" }, [_vm._v("Message:")]),
        _vm._v(" "),
        _c("el-input", {
          attrs: {
            type: "textarea",
            placeholder: "Type bot message here.",
            rows: 3,
          },
          model: {
            value: _vm.nodeData.message,
            callback: function ($$v) {
              _vm.$set(_vm.nodeData, "message", $$v)
            },
            expression: "nodeData.message",
          },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "col-12 text-right" },
      [
        _c(
          "el-tooltip",
          { attrs: { content: "Add variable", placement: "top" } },
          [
            _c("Variables", {
              on: { "variable-selected": _vm.variableSelected },
            }),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-12" }, [
      _c("p", { staticClass: "font-weight-bold side-panel-title" }, [
        _vm._v("Send Message Panel"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }