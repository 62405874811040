var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.hasPermissionTo("list ring group")
    ? _c(
        "div",
        [
          _vm.label
            ? _c("label", { staticClass: "p-0 m-0 _500" }, [
                _vm._v("\n        " + _vm._s(_vm.label) + "\n    "),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-select",
            {
              staticClass: "w-full",
              attrs: {
                "default-first-option": _vm.default_first,
                multiple: _vm.multiple,
                placeholder: _vm.placeholder,
                size: _vm.size,
                autocomplete: "off",
                clearable: "",
                filterable: "",
                "collapse-tags": _vm.collapsable,
                "data-testid": "ring-group-selector",
              },
              on: { clear: _vm.ringGroupChange, change: _vm.ringGroupChange },
              model: {
                value: _vm.ring_group_id,
                callback: function ($$v) {
                  _vm.ring_group_id = $$v
                },
                expression: "ring_group_id",
              },
            },
            _vm._l(_vm.ring_group_list, function (ring_group) {
              return _c(
                "el-option",
                {
                  key: ring_group.id,
                  attrs: { label: ring_group.name, value: ring_group.id },
                },
                [_c("span", [_vm._v(_vm._s(ring_group.name))])]
              )
            }),
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }