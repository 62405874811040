<template>
    <el-dialog class="dialog-blank alo-default-app-selector-dialog"
               width="700px"
               :visible.sync="visible"
               :show-close="true">
        <div class="alo-default-app-selector-body">
            <div class="break-word">
                <p>Hello there!</p>
                <p>We have news to share. As we move closer to the launch of our new Aloware Talk product (yay!), we will be sunsetting this Power Dialer feature in {{ alowareClassic }} shortly, and we will transition all of your existing contacts to the new Power Dialer when we make the switch.</p>
                <p>In the meantime, we are glad to let you know that Aloware Talk's Power Dialer offers a step up for users with a more intuitive, seamless experience.</p>
                <p>Go ahead and go check it out for yourself! Just click the button below. Happy talking!</p>
            </div>

            <el-divider></el-divider>

            <a :href="talk2PowerDialerUrl">
                <el-button size="large"
                           type="primary"
                           class="w-100 d-flex justify-content-center align-items-center">
                    Try Aloware Talk Power Dialer
                </el-button>
            </a>
        </div>
    </el-dialog>
</template>

<script>
    import auth from "../auth"
    import { acl_mixin, helper_mixin } from '../mixins'

    export default {
        name: 'power-dialer-depreciation-dialog',

        mixins: [
            acl_mixin,
            helper_mixin
        ],

        data: () => ({
            auth,
            visible: false
        }),

        mounted() {
            this.visible = this.isPowerDialerDepreciated
        },

        computed: {
            talk2PowerDialerUrl () {
                return localStorage.getItem('talk_url') + '/power-dialer'
            }
        }
    }
</script>
