var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "col-12 h-100 mh-100" }, [
    _c("div", { staticClass: "sidebar-wrapper px-2 py-3 h-100" }, [
      _c("div", { staticClass: "body pb-4" }, [
        _vm.panel === _vm.GENERAL_PANEL
          ? _c("div", [_c("GeneralPanel")], 1)
          : _vm._e(),
        _vm._v(" "),
        _vm.panel === _vm.START_PANEL
          ? _c(
              "div",
              [
                _c("StartPanel", {
                  attrs: { transitions: _vm.state.transitions },
                  on: {
                    "transition-changed": function ($event) {
                      _vm.selected_transition = $event
                    },
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.panel === _vm.MESSAGE_PANEL
          ? _c(
              "div",
              [
                _c("MessagePanel", {
                  attrs: { data: _vm.data, node_id: _vm.node_id },
                  on: { "data-updated": _vm.dataUpdated },
                }),
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.panel === _vm.GET_INPUT_PANEL
          ? _c(
              "div",
              [
                _c("GetInputPanel", {
                  attrs: {
                    data: _vm.data,
                    node_id: _vm.node_id,
                    intents: _vm.intents,
                  },
                  on: { "data-updated": _vm.dataUpdated },
                }),
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.panel === _vm.STORE_INPUT_PANEL
          ? _c(
              "div",
              [
                _c("StoreInputPanel", {
                  attrs: { data: _vm.data, node_id: _vm.node_id },
                  on: { "data-updated": _vm.dataUpdated },
                }),
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.panel === _vm.ESCALATION_PANEL
          ? _c(
              "div",
              [
                _c("EscalationPanel", {
                  attrs: { data: _vm.data, node_id: _vm.node_id },
                  on: { "data-updated": _vm.dataUpdated },
                }),
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.panel === _vm.CUSTOM_PANEL
          ? _c(
              "div",
              [
                _c("CustomPanel", {
                  attrs: { data: _vm.data, node_id: _vm.node_id },
                  on: { "data-updated": _vm.dataUpdated },
                }),
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.panel === _vm.APPOINTMENT_PANEL
          ? _c(
              "div",
              [
                _c("AppointmentPanel", {
                  attrs: {
                    data: _vm.data,
                    node_id: _vm.node_id,
                    appointment_services: _vm.appointment_services,
                  },
                  on: { "data-updated": _vm.dataUpdated },
                }),
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.panel === _vm.CONDITION_PANEL
          ? _c(
              "div",
              [
                _c("ConditionPanel", {
                  attrs: { data: _vm.data, node_id: _vm.node_id },
                  on: { "data-updated": _vm.dataUpdated },
                }),
              ],
              1
            )
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "container-fluid mt-2" }, [
        _c(
          "div",
          { staticClass: "row" },
          [
            _vm.node_id
              ? _c(
                  "el-button",
                  {
                    staticClass: "ml-auto",
                    attrs: { type: "success", loading: _vm.saving },
                    on: {
                      click: function ($event) {
                        return _vm.saveState()
                      },
                    },
                  },
                  [_vm._v("\n                    Close\n                ")]
                )
              : _vm._e(),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }