var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.shouldShowAlohabots
        ? _c(
            "div",
            { staticClass: "container-listing" },
            [
              _c("div", { staticClass: "container-header d-flex" }, [
                _c("h3", { staticClass: "listing-heading" }, [
                  _vm._v("AlohaBot"),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "ml-auto" }, [
                  _c(
                    "div",
                    { staticClass: "d-flex" },
                    [
                      !_vm.canAddAlohabot
                        ? _c(
                            "el-popover",
                            {
                              ref: "import-alohabot-popover",
                              staticClass: "custom-popover",
                              attrs: {
                                placement: "top",
                                width: "200",
                                "popper-class": "btn-primary",
                                trigger: "hover",
                              },
                            },
                            [
                              _c("custom-message-display", {
                                attrs: {
                                  config: _vm.customMessage("alohabot"),
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          directives: [
                            {
                              name: "popover",
                              rawName: "v-popover:import-alohabot-popover",
                              arg: "import-alohabot-popover",
                            },
                          ],
                          staticClass: "mr-3",
                          attrs: {
                            type: "info",
                            round: "",
                            size: "medium",
                            disabled: !_vm.canAddAlohabot,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.openImportChatbotForm()
                            },
                          },
                        },
                        [
                          _c("i", {
                            staticClass: "fa fa-download mr-1",
                            attrs: { "aria-hidden": "true" },
                          }),
                          _vm._v(" "),
                          _c("strong", [_vm._v("Import Chatbot")]),
                        ]
                      ),
                      _vm._v(" "),
                      !_vm.canAddAlohabot
                        ? _c(
                            "el-popover",
                            {
                              ref: "add-alohabot-popover",
                              staticClass: "custom-popover",
                              attrs: {
                                placement: "top",
                                width: "200",
                                "popper-class": "btn-primary",
                                trigger: "hover",
                              },
                            },
                            [
                              _c("custom-message-display", {
                                attrs: {
                                  config: _vm.customMessage("alohabot"),
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          directives: [
                            {
                              name: "popover",
                              rawName: "v-popover:add-alohabot-popover",
                              arg: "add-alohabot-popover",
                            },
                          ],
                          staticClass: "mr-3",
                          attrs: {
                            type: "success",
                            round: "",
                            size: "medium",
                            disabled: !_vm.canAddAlohabot,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.openNewChatbotForm()
                            },
                          },
                        },
                        [
                          _c("i", { staticClass: "fa fa-plus mr-1" }),
                          _vm._v(" "),
                          _c("strong", [_vm._v("New Chatbot")]),
                        ]
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "el-tabs",
                {
                  staticClass: "pt-2 h-100",
                  staticStyle: { "max-height": "calc(100% - 4rem)" },
                  on: { "tab-click": _vm.onTabClick },
                  model: {
                    value: _vm.active_tab,
                    callback: function ($$v) {
                      _vm.active_tab = $$v
                    },
                    expression: "active_tab",
                  },
                },
                [
                  _c(
                    "el-tab-pane",
                    { attrs: { name: "chatbots", label: "Chatbots" } },
                    [
                      _c("ChatbotsTable", {
                        attrs: { chatbots: _vm.chatbots },
                        on: {
                          "chatbot-update": _vm.onChatbotUpdate,
                          "chatbot-delete": _vm.onChatbotDelete,
                          "chatbot-export": _vm.onChatbotExport,
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-tab-pane",
                    { attrs: { name: "templates", label: "Templates" } },
                    [
                      _c("TemplatesTable", {
                        attrs: { templates: _vm.templates },
                        on: {
                          "template-update": _vm.onTemplateUpdate,
                          "template-delete": _vm.onTemplateDelete,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("NewChatbotForm", {
                attrs: {
                  form_visible: _vm.new_chatbot_form_visible,
                  lines: _vm.lines,
                },
                on: {
                  "bot-created": _vm.onChatbotCreated,
                  "bot-discarded": _vm.closeNewChatbotForm,
                },
              }),
              _vm._v(" "),
              _c("ImportTemplateForm", {
                attrs: {
                  form_visible: _vm.import_chatbot_form_visible,
                  lines: _vm.lines,
                  templates: _vm.templates,
                },
                on: {
                  "bot-imported": _vm.onChatbotImported,
                  "bot-discarded": _vm.closeImportChatbotForm,
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.shouldShowAlohabots && _vm.shouldShowUpgradeNow
        ? _c("upgrade-now-page", {
            staticClass: "mt-5",
            attrs: {
              "image-link": "/assets/images/alohabot-advertising.svg",
              text: "Engage customers and prospects round the clock with the power of our AI-powered conversational SMS bot",
              "extra-text":
                "Contact your CSM today for early beta access to this feature.",
              "title-text": "AlohaBot",
              "button-text": "Sign Up for Early Access",
              "kb-link":
                "https://support.aloware.com/en/articles/9037495-alohabot",
              disabled: _vm.hasRequestedAlohabot,
              "default-click": false,
            },
            on: {
              click: function ($event) {
                return _vm.requestAlohabot()
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }