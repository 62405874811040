<template>
    <div class="row-row whitish text-blackish contacts-list">
        <div class="row-body hover b-a">
            <div class="row-inner">
                <div class="list inset">
                    <div v-for="contact in uniqueContacts"
                         :key="contact.id"
                         class="pointer b-b">
                        <router-link :to="{ name: 'Contact', params: { contact_id: contact.id }}"
                                     data-testid="go-to-contact-router-link"
                                     class="list-item">
                            <div class="row d-flex align-items-center"
                                 v-if="isLargeEnough">
                                <div class="col-md-2">
                                    <div class="d-flex flex-row">
                                        <el-tooltip class="item"
                                                    effect="dark"
                                                    :disabled="!avatarTooltip(contact)"
                                                    :content="avatarTooltip(contact)"
                                                    placement="top">
                                            <span class="w-40 avatar grey-300 d-flex align-items-center justify-content-center flex-shrink-0"
                                                  :style="avatarStyle(contact)">
                                                <span>{{ contact.name | fixContactName | initials }}</span>
                                            </span>
                                        </el-tooltip>
                                        <div class="ml-2 d-flex flex-column flex-grow-1 text-ellipsis">
                                            <span class="d-block font-weight-bold text-ellipsis">{{ contact.name | fixContactName }}</span>
                                            <span class="d-block">{{ contact.phone_number | fixPhone }}</span>
                                            <span class="label p-1 text-xxs danger width-fit-content"
                                                  v-if="contact.is_dnc">
                                                DNC
                                            </span>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-2">
                                    <div class="list-item pl-0 pr-0">
                                        <span class="d-block text-bluish"
                                              v-if="contact.user_id">
                                            <i class="material-icons">person</i>
                                            {{ getUserName(contact.user_id) }}
                                        </span>
                                        <span class="d-block text-red-500"
                                              v-if="contact.disposition_status_id">
                                            <i class="material-icons">folder</i>
                                            {{ getDispositionStatusName(contact.disposition_status_id) }}
                                        </span>
                                        <span class="d-block text-purple-500"
                                              v-if="getCampaign(contact.initial_campaign_id)">
                                            <i class="material-icons">power</i>
                                            {{ getCampaign(contact.initial_campaign_id).name }}
                                        </span>
                                        <div class="d-block text-pink-500"
                                             v-if="contact.company_name">
                                            <i class="material-icons">business_center</i>
                                            {{ contact.company_name }}
                                        </div>
                                        <span class="d-block text-new-purple"
                                              v-if="contact.cnam_city || contact.cnam_state">
                                            <i class="material-icons">location_on</i>
                                            <span v-if="contact.cnam_city">{{ contact.cnam_city }}, </span>
                                            <span v-if="contact.cnam_state">{{ contact.cnam_state }}</span>
                                        </span>
                                        <div class="d-block text-dark-greenish"
                                             v-if="contact.lead_source">
                                            <i class="fa fa-filter"></i>
                                            {{ contact.lead_source }}
                                        </div>
                                        <div class="d-block text-deep-orange"
                                             v-if="contact.timezone">
                                            <i class="material-icons">public</i>
                                            {{ contact.timezone | fixTimezone }}
                                        </div>
                                    </div>
                                </div>

<!--                                <div class="col-md-1">-->
<!--                                    <relative-datetime :datetime="contact.created_at"></relative-datetime>-->
<!--                                </div>-->

                                <div class="col-md-2">
                                    <div class="pl-2 pr-2"
                                         v-if="contact.last_engagement_at || contact.last_engagement_text">
                                        <span v-if="contact.last_engagement_text"
                                              class="d-block last-message">
                                            {{ contact.last_engagement_text | truncate(100) }}
                                        </span>
                                        <span v-if="contact.last_engagement_at"
                                              class="d-block last-message b-t">
                                            {{ contact.last_engagement_at | fixDateTime }}
                                        </span>
                                    </div>
                                </div>

                                <div class="col-md-2">
                                    <contact-tags :show_only="true"
                                                  :summary="true"
                                                  :contact="contact">
                                    </contact-tags>
                                </div>

                                <div class="col-md-1 text-center">
                                    <span class="label full-font-size danger mt-2 pointer mr-2"
                                          v-if="contact.unread_missed_call_count !== 0"
                                          @click="markAllAsRead($event, contact.id, ContactUnreadCountTypes.UNREAD_TYPE_MISSED_CALLS)">
                                          <i v-if="loading_unread_count === contact.id"
                                             class="fa fa-spinner fa-spin">
                                          </i>
                                          <template v-else>
                                              {{ contact.unread_missed_call_count }}
                                          </template>
                                    </span>
                                </div>

                                <div class="text-center col-md-1">
                                    <span class="label full-font-size danger mt-2 pointer"
                                          v-if="contact.unread_count !== 0"
                                          @click="markAllAsRead($event, contact.id, ContactUnreadCountTypes.UNREAD_TYPE_TEXTS)">
                                        <i v-if="loading_unread_count === contact.id"
                                           class="fa fa-spinner fa-spin"></i>
                                        <template v-else>
                                            {{ contact.unread_count }}
                                        </template>
                                    </span>
                                </div>

                                <div class="col-md-1 text-center">
                                    <span class="label full-font-size danger mt-2 pointer"
                                          v-if="contact.unread_voicemail_count !== 0"
                                          @click="markAllAsRead($event, contact.id, ContactUnreadCountTypes.UNREAD_TYPE_VOICEMAILS)">
                                        <i v-if="loading_unread_count === contact.id"
                                           class="fa fa-spinner fa-spin"></i>
                                        <template v-else>
                                            {{ contact.unread_voicemail_count }}
                                        </template>
                                    </span>
                                </div>

                                

                                

                                <div class="col-md-1 column"
                                     v-if="hasPermissionTo('update contact')">
                                    <div class="row justify-content-center mb-1">
                                        <el-popover v-if="!enabledToCallNumber()"
                                                    :ref="'call-popover-'+contact.id"
                                                    placement="left"
                                                    width="200"
                                                    class="custom-popover"
                                                    popper-class="btn-primary"
                                                    trigger="hover">
                                            <custom-message-display :config="customMessage('call')" />
                                            <button class="btn btn-sm btn-icon btn-outline-success"
                                                    slot="reference"
                                                    data-testid="disabled-call-button"
                                                    disabled>
                                                <i v-if="call_loading === contact.id" class="fa fa-spinner fa-spin"></i>
                                                <i v-if="!call_loading || call_loading !== contact.id"
                                                class="fa fa-phone"></i>
                                            </button>
                                        </el-popover>
                                        <button v-if="enabledToCallNumber()"
                                                class="btn btn-sm btn-icon btn-outline-success"
                                                data-popover="call"
                                                data-testid="enabled-call-button"
                                                :disabled="call_loading === contact.id"
                                                @click.prevent="callContact(contact)">
                                            <i v-if="call_loading === contact.id" class="fa fa-spinner fa-spin"></i>
                                            <i v-if="!call_loading || call_loading !== contact.id"
                                               class="fa fa-phone"></i>
                                        </button>
                                    </div>
                                    <div class="row justify-content-center mb-1">
                                        <el-popover v-if="!enabledToTextNumber()"
                                                    :ref="'text-popover-'+contact.id"
                                                    placement="left"
                                                    width="200"
                                                    class="custom-popover"
                                                    popper-class="btn-primary"
                                                    trigger="hover">
                                            <custom-message-display :config="customMessage('text')" />
                                            <button class="btn btn-sm btn-icon btn-outline-info"
                                                    slot="reference"
                                                    data-testid="disabled-text-button"
                                                    disabled>
                                                <i class="fa fa-comment"></i>
                                            </button>
                                        </el-popover>
                                        <button v-if="enabledToTextNumber()"
                                                class="btn btn-sm btn-icon btn-outline-info"
                                                data-popover="text"
                                                data-testid="enabled-text-button"
                                                @click.prevent="gotoMessages(contact.id)">
                                            <i class="fa fa-comment"></i>
                                        </button>
                                    </div>
                                    <div class="row justify-content-center">
                                        <button class="btn btn-sm btn-icon btn-outline-danger"
                                                data-testid="delete-contact-list-item"
                                                v-if="hasPermissionTo('archive contact') && current_company && current_company.reseller_id !== 357"
                                                @click.prevent="deleteContactById(contact.id)">
                                            <i v-if="loading_delete && loading_delete == contact.id"
                                               class="fa fa-spinner fa-spin"></i>
                                            <i v-else
                                               class="fa fa-trash"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="row-col"
                                 v-else>
                                <span class="w-40 avatar grey-300 pull-left"
                                      v-bind:style="avatarStyle(contact)"
                                      :class="[getCampaign(contact.initial_campaign_id) ? 'mt-2' : '']">
                                    <span>{{ contact.name | fixContactName | initials }}</span>
                                </span>

                                <div class="ml-2 pull-left width-120">
                                    <span class="d-block font-weight-bold">{{ contact.name | fixContactName }}</span>
                                    <span class="d-block">{{ contact.phone_number | fixPhone }}</span>
                                    <span class="d-block text-dark-greenish font-weight-bold"
                                          v-if="getCampaign(contact.initial_campaign_id)">
                                        {{ getCampaign(contact.initial_campaign_id).name }}
                                    </span>
                                </div>

                                <span class="label full-font-size danger mt-2 pointer pull-right"
                                      v-if="contact.unread_count !== 0"
                                      @click="markAllAsRead($event, contact.id)">
                                    <i v-if="loading_unread_count === contact.id"
                                       class="fa fa-spinner fa-spin"></i>
                                    <template v-else>
                                        {{ contact.unread_count }}
                                    </template>
                                </span>
                            </div>
                        </router-link>
                    </div>
                </div>

                <div v-if="contacts.length == 0 && !loading"
                     class="text-center pos-abt w-full"
                     style="top:50%;">
                    No Contacts
                </div>

                <div v-if="contacts.length == 0 && loading"
                     class="text-center pos-abt w-full"
                     style="top:50%;">
                    Loading Contacts
                </div>

                <infinite-loading v-if="contacts.length > 0"
                                  @infinite="loadMore"
                                  spinner="waveDots">
                    <span slot="no-more"></span>
                    <span slot="no-results"></span>
                </infinite-loading>
            </div>
        </div>
    </div>
</template>

<script>
import auth from '../auth'
import {mapActions, mapGetters, mapState} from 'vuex'
import {
    acl_mixin,
    avatar_mixin,
    contact_filters_mixin,
    contact_unread_messages_mixin,
    contacts_live_updates_mixin,
    contact_counts_mixin,
    kyc_mixin
} from '../mixins'
import InfiniteLoading from 'vue-infinite-loading'
import * as AnswerTypes from '../constants/answer-types'
import * as DefaultContactDateFilter from '../constants/company_default_contact_date_filter'
import * as ContactAccessTypes from '../constants/contact-access-types'
import * as ContactUnreadCountTypes from '../constants/contact-unread-counts'
import * as FilterDncOptions from '../constants/filter-dnc-options'
import RelativeDatetime from "./misc/relative-datetime"
import RelativeTime from "./relative-time"
import CustomMessageDisplay from './kyc/custom-message-display'

export default {
    mixins: [
        acl_mixin,
        avatar_mixin,
        contact_unread_messages_mixin,
        contact_filters_mixin,
        contacts_live_updates_mixin,
        contact_counts_mixin,
        kyc_mixin
    ],

    components: {
        RelativeTime,
        RelativeDatetime,
        InfiniteLoading,
        CustomMessageDisplay
    },

    props: {
        is_filtered: {
            default: false,
            type: Boolean,
            required: false
        },
    },

    data() {
        return {
            auth: auth,
            env: null,
            loading: false,
            loading_delete: null,
            call_loading: null,
            pagination_loading: false,
            loading_unread_count: null,
            is_filtered_new_leads: false,
            is_filtered_unanswered_messages: false,
            is_unfiltered_new_leads_unanswered_messages: false,
            source: null,
            AnswerTypes,
            DefaultContactDateFilter,
            ContactUnreadCountTypes
        }
    },

    computed: {
        ...mapGetters({
            pagination: 'getContactsPagination',
            contacts: 'getContacts',
            contactsCount: 'getContactsCount',
            newLeadsCount: 'getNewLeadsCount',
            unreadsCount: 'getUnreadsCount'
        }),

        ...mapState('cache', ['current_company', 'live_update_contacts']),
        ...mapState(['users', 'disposition_statuses', 'campaigns', 'on_call']),

        isLargeEnough() {
            let valid_sizes = ['xxl', 'xl', 'lg', 'md']
            if (valid_sizes.includes(this.$mq)) {
                return true
            }
            return false
        },

        uniqueContacts() {
            return _.uniqBy(this.contacts, 'id')
        }
    },

    created() {
        this.CancelToken = axios.CancelToken
        this.source = this.CancelToken.source()

        // Stop initializing event listeners if contact live updated is disabled
        if (!this.live_update_contacts) {
            return
        }

        VueEvent.listen('contact_unanswered_leads_cleared', (scope) => {
            this.contacts.forEach(contact => {
                // filter and only update the contact visibility limit based on scope
                let should_clear = true

                // if an admin and the selected scope is "user", only mark all their owned contacts as read
                // do not clear unread counter badges of contacts not owned by them
                if (this.hasRole('Company Admin') && scope == 'user') {
                    // if true, the value should be negated for the update logic to not go through
                    should_clear = !(contact.user_id != this.auth.user.profile.id)
                }

                if (should_clear) {
                    // set all unread properties to 0
                    contact.unread_count = 0
                    contact.unread_missed_call_count = 0
                    contact.unread_voicemail_count = 0
                }
            })
        })

        VueEvent.listen('update_unanswered_leads_count_and_filter', async (data) => {
            // return to default filtered contacts
            if (data.unreadCount < 1) {
                this.filter.has_unread = 0;
                this.filter.changed = !this.hasActiveNonDefaultFilters() ? false : this.filter.changed
                VueEvent.fire('unfiltered_unanswered_messages')
            } else {
                VueEvent.fire('filtered_unanswered_messages')
            }

            // nonetheless, update unanswered leads count badge (override redshift count delay)
            // wait a bit
            setTimeout(() => {
                this.setUnreadsCount(data.unreadCount)
                VueEvent.fire('contact_unanswered_leads_cleared', data.scope)
            }, 2000);
        })

        VueEvent.listen('contact_created', (data) => {
            VueEvent.fire('filtered_contact_created', data)

            // Disable live updates for now
            if (this.filter.is_new_lead == 0 || !this.allowLiveUpdates()) {
                return
            }

            let found = this.contacts.find(contact => contact.id === data.id)
            let indexFound = this.contacts.findIndex(contact => contact.id === data.id)
            if (found) {
                // do not push up the contact for BP Dental
                // or event do not push up if contact has no new engagement
                // don't push DNC contacts to the top of list if filter is off
                if (found.last_engagement_at != data.last_engagement_at && this.current_company.id != 376) {
                    if (this.isNotDNC(this.filter.dnc_option) && data.is_dnc == true) {
                        this.contacts.splice(indexFound, 1)
                        return
                    }

                    this.contacts.splice(indexFound, 1)
                    this.contacts.unshift(found)
                    this.setContacts(this.contacts)
                }
            } else {
                // prevent contact's update event bringing in new contacts when there is an active search
                // or when default sort is changed
                if (this.filter.search_text || this.filter.comm_sort_by !== null) {
                    return
                }

                // fix focus mode
                if (this.auth.user && this.auth.user.profile && this.auth.user.profile.focus_mode && (!data.user_id || (data.user_id && data.user_id != this.auth.user.profile.id))) {
                    return
                }

                // ring group only access
                if (this.auth.user && this.auth.user.profile && this.auth.user.profile.contacts_visibility === ContactAccessTypes.CONTACTS_ACCESS_RING_GROUP) {
                    // if user does not have unassigned access
                    if (!data.user_id && !this.auth.user.profile.can_view_unassigned_contacts) {
                        return
                    }

                    // @todo for ring group only access (UI doesn't know that contact relationship with ring groups at this stage)
                }

                // owned only access
                if (this.auth.user && this.auth.user.profile && this.auth.user.profile.contacts_visibility === ContactAccessTypes.CONTACTS_ACCESS_OWNED_ONLY) {
                    // if user does not have unassigned access
                    if (!data.user_id && !this.auth.user.profile.can_view_unassigned_contacts) {
                        return
                    }

                    // checks if communication's contact is owned by the user
                    if (data.user_id && data.user_id !== this.auth.user.profile.id) {
                        return
                    }
                }

                // don't push DNC contacts to list if filter is off
                if (this.isNotDNC(this.filter.dnc_option) && data.is_dnc == true) {
                    return
                }

                this.contacts.unshift(data)
                this.setContacts(this.contacts)
            }
        })

        // live updates: contact updated
        VueEvent.listen('contact_updated', (data) => {
            let found = this.contacts.find(contact => contact.id === data.id)
            let indexFound = this.contacts.findIndex(contact => contact.id === data.id)

            // allow live updates only if:
            // - new leads filter is active and all other filters are in their default value
            // - has unread messages filter is active and all other filters are in their default value
            // - filters are in their default values
            if (!this.allowLiveUpdates()) {
                // update contact if found
                if (found) {
                    this.updateContact(found, data)
                }
                return
            }

            if (found) {
                let should_push = true
                let should_remove = false
                // do not push up the contact for BP Dental
                if (this.current_company.id != 376) {
                    // do not push contact to top if contact has no new engagement
                    if (found.last_engagement_at != data.last_engagement_at) {
                        should_push = false
                        should_remove = false
                    }
                    // prevent contact's update event bringing in new contacts when filter is set but it's not unanswered lead or new lead
                    if (this.filter.has_unread == 0 && this.filter.is_new_lead == 0 && this.filter.changed) {
                        should_push = false
                        should_remove = false
                    }
                    // prevent contact's update event bringing in new contacts when is new lead filter is on and contact is already engaged (not new)
                    if (this.filter.is_new_lead == 1 && data.last_engagement_at !== null) {
                        should_push = false
                        should_remove = true
                    }
                    // prevent contact's update event bringing in new contacts when is has unread filter is on and contact is unread count is zero
                    if (this.filter.has_unread == 1 && this.filterUnreadScope()) {
                        should_push = false
                        should_remove = true
                    }
                    // don't push DNC'd contact and remove
                    if ((this.isNotDNC(this.filter.dnc_option) && data.is_dnc == true) || (this.filter.dnc_option == FilterDncOptions.CONTACTS_WITH_DNC && data.is_dnc == false)) {
                        should_push = false
                        should_remove = true
                    }
                    // remove contact if it doesn't belong to this user on focus mode
                    if (this.auth.user && this.auth.user.profile && this.auth.user.profile.focus_mode && (!data.user_id || (data.user_id && data.user_id != this.auth.user.profile.id))) {
                        should_push = false
                        should_remove = true
                    }

                    let old_unread_count = found.unread_count + found.unread_voicemail_count + found.unread_missed_call_count
                    let new_unread_count = data.unread_count + data.unread_voicemail_count + data.unread_missed_call_count
                    // we should not modify the order or the contact list if the action is coming from marking all comms as read
                    if (old_unread_count !== new_unread_count && this.filter.has_unread === 1) {
                        should_push = false
                    }

                    // should we push contact to top?
                    if (should_push) {
                        this.contacts.splice(indexFound, 1)
                        this.contacts.unshift(found)
                    }
                    // should we remove the contact?
                    if (should_remove) {
                        this.contacts.splice(indexFound, 1)
                        // don't update contact
                        return
                    }
                }
                // update contact
                this.updateContact(found, data)
            } else {
                // prevent contact's update event bringing in new contacts when there is an active search
                // or when default sort is changed
                if (this.filter.search_text || this.filter.comm_sort_by !== null) {
                    return
                }

                // prevent contact's update event bringing in new contacts when is new lead filter is on and contact is already engaged (not new)
                if (this.filter.is_new_lead == 1 && data.last_engagement_at !== null) {
                    return
                }

                // prevent contact's update event bringing in new contacts when is has unread filter is on and contact is unread count is zero
                if (this.filter.has_unread == 1 && data.unread_count == 0) {
                    return
                }

                // add the updated contact in list if filtered as dnc or unanswered or new leads
                if (this.filter.has_unread == 0 && this.filter.is_new_lead == 0 && this.isNotDNC(this.filter.dnc_option) && this.filter.changed) {
                    return
                }
                // prevent contact's update event bringing in new contacts when contact doesn't belong to this user on focus mode
                if (this.auth.user && this.auth.user.profile && this.auth.user.profile.focus_mode && (!data.user_id || (data.user_id && data.user_id != this.auth.user.profile.id))) {
                    return
                }

                // fix focus mode
                if (this.auth.user && this.auth.user.profile && this.auth.user.profile.focus_mode && (!data.user_id || (data.user_id && data.user_id != this.auth.user.profile.id))) {
                    return
                }

                // ring group only access
                if (this.auth.user && this.auth.user.profile && this.auth.user.profile.contacts_visibility === ContactAccessTypes.CONTACTS_ACCESS_RING_GROUP) {
                    // if user does not have unassigned access
                    if (!data.user_id && !this.auth.user.profile.can_view_unassigned_contacts) {
                        return
                    }

                    // @todo for ring group only access (UI doesn't know that contact relationship with ring groups at this stage)
                }

                // owned only access
                if (this.auth.user && this.auth.user.profile && this.auth.user.profile.contacts_visibility === ContactAccessTypes.CONTACTS_ACCESS_OWNED_ONLY) {
                    // if user does not have unassigned access
                    if (!data.user_id && !this.auth.user.profile.can_view_unassigned_contacts) {
                        return
                    }

                    // checks if communication's contact is owned by the user
                    if (data.user_id && data.user_id !== this.auth.user.profile.id) {
                        return
                    }
                }

                this.contacts.unshift(data)
            }
        })

        // live updates: contact deleted
        VueEvent.listen('contact_deleted', (data) => {
            this.deleteContact(data)
        })

        // live updates: DNC detection
        VueEvent.listen('contact_audit_created', (data) => {
            let found = this.contacts.find(contact => contact.id === data.contact_id)
            if (!found) {
                return
            }
            if (!this.filter.has_unread) {
                return
            }
            // remove contact in unanswered leads list
            if (data.property === 'is_dnc') {
                this.deleteContact(found)
            }
        })

        VueEvent.listen('unfiltered_new_leads_unanswered_messages', () => {
            this.applyCountsFilter('unfiltered_count', true);
        })

        VueEvent.listen('filtered_new_leads', () => {
            this.applyCountsFilter('new_leads');
        })

        VueEvent.listen('filtered_unanswered_messages', () => {
            this.applyCountsFilter('unanswered_messages');
        })
    },

    mounted() {
        this.filter.page = 1

        if (!['Contacts', 'Contact'].includes(localStorage.getItem('PREVIOUS_ROUTE_NAME')) || this.contacts.length === 0) {
            this.resetFilters()
            this.assignDefaultDateField()
            this.setContacts([])
            this.initLeadSource()
            this.initFromToDates()

            this.getContacts().catch(err => {
                console.log(err)
            })
        }

        VueEvent.listen('contact_selected', (data) => {
            const selected_contact = this.uniqueContacts.find((contact) => contact.id === data.id)
            const selected_contact_index = this.uniqueContacts.indexOf(selected_contact)
            if (this.uniqueContacts.length === selected_contact_index + 1) {
                // fire an alarm to hide next contact button
                this.$emit('last_contact_selected')
                return
            }
            // otherwise let it be
            this.$emit('has_more_contact')
        })
    },

    methods: {
        markAllAsRead($event, contact_id, unread_type = null) {
            $event.preventDefault()
            $event.stopPropagation()
            return this.$confirm('Mark communications as read?', 'Contact', {
                confirmButtonText: 'OK',
                cancelButtonText: 'Cancel',
                customClass: 'width-500 fixed',
                type: 'warning'
            }).then(() => {
                this.loading_unread_count = contact_id
                axios.post('/api/v1/contact/' + contact_id + '/mark-as-read', {
                    unread_type: unread_type
                }).then(res => {
                    VueEvent.fire('contact_updated', res.data)
                    this.loading_unread_count = null
                }).catch(err => {
                    this.$root.handleErrors(err.response)
                    this.loading_unread_count = null
                })
            }).catch(() => {
            })
        },

        applyCountsFilter(filter, refresh_counts = false) {
            if (refresh_counts) {
                this.getContactsCount()
                return
            }
            switch (filter) {
                case 'unfiltered_count':
                    this.is_unfiltered_new_leads_unanswered_messages = true
                    this.is_filtered_new_leads = false
                    this.is_filtered_unanswered_messages = false
                    break
                case 'new_leads':
                    this.is_filtered_new_leads = true
                    this.is_unfiltered_new_leads_unanswered_messages = false
                    this.is_filtered_unanswered_messages = false
                    break
                case 'unanswered_messages':
                    this.is_filtered_unanswered_messages = true
                    this.is_unfiltered_new_leads_unanswered_messages = false
                    this.is_filtered_new_leads = false
                    break
            }
        },

        clearCountsFilter() {
            this.is_unfiltered_new_leads_unanswered_messages = false
            this.is_filtered_new_leads = false
            this.is_filtered_unanswered_messages = false
        },

        assignDefaultDateField() {
            // override default date_field from company.default_contact_date_filter
            let date_field = {
                [DefaultContactDateFilter.DEFAULT_CONTACT_DATE_FILTER_ENGAGEMENT]: 'last_engagement_at',
                [DefaultContactDateFilter.DEFAULT_CONTACT_DATE_FILTER_CREATED_AT]: 'created_at'
            }[this.auth.user.profile.company.default_contact_date_filter]
            // also set the date field of default and custom filter variables for comparison
            // and tracking of changes
            this.filter.date_field = date_field
        },

        getContacts() {
            this.source.cancel('getContacts canceled by the user.')
            this.source = this.CancelToken.source()
            this.loading = true
            this.pagination_loading = true
            this.filter.search_fields = this.search_fields
            let params = this.filter
            params.per_page = 20

            return axios.get('/api/v1/contact', {
                params: params,
                cancelToken: this.source.token
            }).then(res => {
                this.setContacts(_.union(this.contacts, res.data.data))
                this.setContactsPagination(res.data)
                this.pagination_loading = false
                this.loading = false

                return Promise.resolve(res)
            }).catch(err => {
                this.$root.handleErrors(err.response)
                this.pagination_loading = false
                if (axios.isCancel(err)) {
                    console.log('Request canceled', err.message)
                    // return Promise.reject(err)
                } else {
                    console.log(err)
                    this.loading = false
                    return Promise.reject(err)
                }
            })
        },

        updateContact(old_contact, new_contact) {
            for (let index in new_contact) {
                this.$set(this.contacts[this.contacts.indexOf(old_contact)], index, new_contact[index])
            }
        },

        loadMore($state) {
            if (this.pagination && this.pagination.to && this.filter.page <= this.pagination.to) {
                this.filter.page += 1
                this.getContacts()
                    .then(res => {
                        $state.loaded()
                    })
                    .catch(err => {
                        $state.loaded()
                    })
            } else {
                $state.complete()
            }
        },

        getUserName(userId) {
            if (!userId) {
                return ''
            }

            let user = this.users.find(o => o.id === userId)

            if (user) {
                return user.name
            }

            return ''
        },

        getDispositionStatusName(dispositionStatusId) {
            if (!dispositionStatusId) {
                return ''
            }

            let dispositionStatus = this.disposition_statuses.find(o => o.id === dispositionStatusId)

            if (dispositionStatus) {
                return dispositionStatus.name
            }

            return ''
        },

        deleteContactById(contact_id) {
            let originalContact = this.contacts.find(contact => contact.id === contact_id)
            this.$confirm('Heads Up! Deleting a contact will permanently remove all their calls, messages and data. Proceed?', 'Warning', {
                confirmButtonText: 'OK',
                cancelButtonText: 'Cancel',
                customClass: 'width-500 fixed',
                type: 'warning'
            }).then(() => {
                this.loading_delete = contact_id
                axios.delete('/api/v1/contact/' + contact_id)
                    .then(res => {
                        VueEvent.fire('contact_deleted', originalContact)
                        this.$notify({
                            offset: 95,
                            title: 'Contact',
                            message: 'Contact has been deleted successfully.',
                            type: 'success',
                            showClose: true
                        })
                        this.loading_delete = null
                    })
                    .catch(err => {
                        this.$root.handleErrors(err.response)
                        this.loading_delete = null
                    })
            }).catch(() => {
            })
        },

        callContact(contact) {
            // don't allow simultaneous calls
            if (this.on_call || sessionStorage.getItem('on_call') == 'true') {
                this.$notify({
                    duration: 2000,
                    title: 'Phone',
                    message: 'Sorry, your phone looks busy. Please disconnect your ongoing call, or put yourself back to available.',
                    type: 'error',
                    showClose: true,
                    position: 'bottom-right'
                })

                return
            }

            // check contact has timezone or not
            if (contact.timezone) {
                // if have timezone check is it day time?
                const start_day = moment().tz(contact.timezone).hour(8).minute(0).second(0)
                const end_day = moment().tz(contact.timezone).hour(18).minute(0).second(0)
                const contact_local_time = moment().tz(contact.timezone)
                if (!contact_local_time.isBetween(start_day, end_day)) {
                    return this.$confirm(`It's ${contact_local_time.format('hh:mm A')} for ${contact.name}. Do you still want to make this call?`, 'Call Lead', {
                        confirmButtonText: 'OK',
                        cancelButtonText: 'Cancel',
                        customClass: 'width-500 fixed',
                        type: 'warning'
                    }).then(() => {
                        this.makeCall(contact)
                    }).catch(() => {
                    })
                }
            }

            this.makeCall(contact)
        },

        makeCall(contact) {
            if (this.auth.user.profile.enabled_two_legged_outbound) {
                let message = 'We will call your secondary phone'
                message += ' on ' + this.auth.user.profile.secondary_phone_number
                message += ` and connect you with ${contact.name}. Proceed?`
                return this.$confirm(message, 'Going old school?', {
                    confirmButtonText: 'OK',
                    cancelButtonText: 'Cancel',
                    customClass: 'width-500 fixed',
                    type: 'warning'
                }).then(() => {
                    this.makeTwoLeggedCall(contact)
                }).catch(() => {
                })
            }

            VueEvent.fire('make_new_call', {
                phone_number: contact.phone_number
            })
        },

        makeTwoLeggedCall(contact) {
            this.call_loading = contact.id
            axios.post('/api/v1/contact/' + contact.id + '/make-two-legged-call', {
                phone_number: contact.phone_number
            }).then(res => {
                this.$notify({
                    offset: 95,
                    title: 'Call Lead',
                    message: `We are calling your phone to connect you to ${contact.name}`,
                    type: 'success',
                    showClose: true
                })
                this.call_loading = null
            }).catch(err => {
                this.$root.handleErrors(err.response)
                this.call_loading = null
            })
        },

        gotoMessages(contact_id) {
            this.$router.push({
                name: 'Contact',
                params: {contact_id: contact_id}
            }).catch(err => {
            })
        },

        applyContactsFilters(filter_field) {
            this.filter.page = 1
            this.setContacts([])
            this.getContacts().catch(err => {
                console.log(err)
            })
            // we allow the fetching of contact counts when:
            // 1. When searching for contacts through search field or when clearing the search.
            // 2. When user searched for contact(s) and toggled active the new leads
            //    or unanswered leads counts and then clears the search.
            // 3. When the user toggled inactive the new leads or unanswered leads counts
            //    and that the total contacts count is equal to either the new leads
            //    or unanswered leads count.
            if (filter_field === 'search_text' ||
                ((this.filter.search_text.length ||
                        (this.filter.search_text.length == 0 &&
                            (this.contactsCount == this.newLeadsCount ||
                                this.contactsCount == this.unreadsCount))
                    ) &&
                    ['is_new_lead', 'has_unread'].includes(filter_field) &&
                    this.is_unfiltered_new_leads_unanswered_messages) ||
                (!this.is_filtered_new_leads &&
                    !this.is_filtered_unanswered_messages &&
                    !this.is_unfiltered_new_leads_unanswered_messages)) {
                this.getContactsCount().catch(err => {
                    console.log(err)
                })
            }
        },

        getCampaign(id) {
            if (!id) {
                return null
            }
            let found = this.campaigns.find(campaign => campaign.id === id)
            if (found) {
                return found
            }

            return null
        },

        getTotalUnreadCount(contact) {
            if (!contact) {
                return 0
            }

            return contact.unread_count + contact.unread_missed_call_count + contact.unread_voicemail_count
        },

        filterUnreadScope() {
            // For standard company
            return this.filter.unread_count == 0 && this.filter.unread_missed_call_count == 0 && this.filter.unread_voicemail_count == 0
        },

        isNotDNC(dnc_option) {
            return [FilterDncOptions.CONTACTS_WITHOUT_DNC_WITHOUT_OPTOUT, FilterDncOptions.CONTACTS_WITHOUT_DNC_WITH_OPTOUT].includes(dnc_option)
        },

        ...mapActions(['resetFilters', 'setContacts', 'deleteContact', 'setContactsPagination'])
    },

    watch: {
        'filter.search_text': _.debounce(function () {
            if (!this.filter.search_text ||
                (this.filter.search_text && (this.filter.search_text.length >= 3 || this.filter.search_text.length == 0))) {
                this.applyContactsFilters('search_text')
            }
        }, 500),

        'filter.from_date': function () {
            this.clearCountsFilter()
            this.applyContactsFilters()
        },

        'filter.to_date': function () {
            this.clearCountsFilter()
            this.applyContactsFilters()
        },

        'filter.date_field': function () {
            this.clearCountsFilter()
            this.applyContactsFilters()
        },

        'filter.lrn_types': function () {
            this.clearCountsFilter()
            this.applyContactsFilters()
        },

        'filter.has_appointments': function () {
            this.clearCountsFilter()
            this.applyContactsFilters()
        },

        'filter.has_reminders': function () {
            this.clearCountsFilter()
            this.applyContactsFilters()
        },

        'filter.event_range': function () {
            this.clearCountsFilter()
            this.applyContactsFilters()
        },

        'filter.intake_source': function () {
            this.clearCountsFilter()
            this.applyContactsFilters()
        },

        'filter.lead_source': function () {
            this.clearCountsFilter()
            this.applyContactsFilters()
        },

        'filter.tags': function () {
            this.clearCountsFilter()
            this.applyContactsFilters()
        },

        'filter.doesnt_have_tags': function () {
            this.clearCountsFilter()
            this.applyContactsFilters()
        },

        'filter.disposition_statuses': function () {
            this.clearCountsFilter()
            this.applyContactsFilters()
        },

        'filter.no_disposition_statuses': function () {
            this.clearCountsFilter()
            this.applyContactsFilters()
        },

        'filter.untagged_only': function () {
            this.clearCountsFilter()
            this.applyContactsFilters()
        },

        'filter.has_untagged_call': function () {
            this.clearCountsFilter()
            this.applyContactsFilters()
        },

        'filter.campaigns': function () {
            this.clearCountsFilter()
            this.applyContactsFilters()
        },

        'filter.users': function () {
            this.clearCountsFilter()
            this.applyContactsFilters()
        },

        'filter.not_disposed': function () {
            this.clearCountsFilter()
            this.applyContactsFilters()
        },

        'filter.is_blocked': function () {
            this.clearCountsFilter()
            this.applyContactsFilters()
        },

        'filter.dnc_option': function () {
            this.clearCountsFilter()
            this.applyContactsFilters()
        },

        'filter.has_scheduled_messages': function () {
            this.clearCountsFilter()
            this.applyContactsFilters()
        },

        'filter.no_scheduled_messages': function () {
            this.clearCountsFilter()
            this.applyContactsFilters()
        },

        'filter.enrolled_in_sequence': function () {
            this.clearCountsFilter()
            this.applyContactsFilters()
        },

        'filter.not_enrolled_in_sequence': function () {
            this.clearCountsFilter()
            this.applyContactsFilters()
        },

        'filter.has_unread': function () {
            this.applyContactsFilters('has_unread')
        },

        'filter.is_new_lead': function () {
            this.applyContactsFilters('is_new_lead')
        },

        'filter.should_follow_the_sun': function () {
            this.clearCountsFilter()
            this.applyContactsFilters()
        },

        'filter.unassigned_leads': function () {
            this.clearCountsFilter()
            this.applyContactsFilters()
        },

        'filter.not_responded': function () {
            this.clearCountsFilter()
            this.applyContactsFilters()
        },

        'filter.not_responded_hours': function () {
            this.clearCountsFilter()
            this.applyContactsFilters()
        },

        'filter.responded': function () {
            this.clearCountsFilter()
            this.applyContactsFilters()
        },

        'filter.responded_hours': function () {
            this.clearCountsFilter()
            this.applyContactsFilters()
        },

        'filter.not_contacted': function () {
            this.clearCountsFilter()
            this.applyContactsFilters()
        },

        'filter.not_contacted_hours': function () {
            this.clearCountsFilter()
            this.applyContactsFilters()
        },

        'filter.text_authorized': function () {
            this.clearCountsFilter()
            this.applyContactsFilters()
        },

        'filter.states_limit.us': function () {
            this.clearCountsFilter()
            this.applyContactsFilters()
        },

        'filter.states_limit.ca': function () {
            this.clearCountsFilter()
            this.applyContactsFilters()
        },

        'filter.initial_line_only': function () {
            this.clearCountsFilter()
            this.applyContactsFilters()
        },

        'filter.contact_country': function () {
            this.clearCountsFilter()
            this.applyContactsFilters()
        },

        'filter.contact_timezone': function () {
            this.clearCountsFilter()
            this.applyContactsFilters()
        },

        'filter.comm_sort_by': function () {
            this.clearCountsFilter()
            this.applyContactsFilters()
        },

        'filter.outbound_call_count_start': function () {
            this.clearCountsFilter()
            this.applyContactsFilters()
        },

        'filter.outbound_call_count_end': function () {
            this.clearCountsFilter()
            this.applyContactsFilters()
        },
    },

    beforeCreate() {
        axios.get('/build-info.json')
            .then(res => {
                this.env = res.data.env
            })
            .catch(err => {
                console.log(err)
            })
    },
}
</script>
