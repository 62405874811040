var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm._m(0),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "col-12 mt-2" },
      [
        _c("p", { staticClass: "mb-0 font-weight-bold" }, [_vm._v("Field:")]),
        _vm._v(" "),
        _c(
          "el-select",
          {
            attrs: {
              filterable: "",
              placeholder: "Select custom field",
              rows: 3,
            },
            model: {
              value: _vm.nodeData.key,
              callback: function ($$v) {
                _vm.$set(_vm.nodeData, "key", $$v)
              },
              expression: "nodeData.key",
            },
          },
          _vm._l(_vm.attribute_dictionaries, function (attribute) {
            return _c("el-option", {
              key: attribute.id,
              attrs: { label: attribute.slug, value: attribute.slug },
            })
          }),
          1
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "col-12 mt-4" },
      [
        _c("p", { staticClass: "mb-0 font-weight-bold" }, [_vm._v("Value:")]),
        _vm._v(" "),
        _c("el-input", {
          attrs: { placeholder: "Type the value here.", rows: 3 },
          model: {
            value: _vm.nodeData.value,
            callback: function ($$v) {
              _vm.$set(_vm.nodeData, "value", $$v)
            },
            expression: "nodeData.value",
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-12" }, [
      _c("p", { staticClass: "font-weight-bold side-panel-title" }, [
        _vm._v("Custom Field Panel"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }