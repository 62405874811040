<template>
    <el-popover placement="top-start"
                class="h-100"
                width="520">
        <div style="height:450px">
            <search-giphy @selected="gifSelected" v-if="show_search_giphy"></search-giphy>
        </div>
        <el-button id="gif-popover-trigger"
                   slot="reference"
                   class="border-0 bg-transparent"
                   style="padding:3px 5px"
                   size="mini"
                   circle
                   @click="mountSearchGiphy"
                   :disabled="disabled">
            <i class="material-icons" style="font-size: 2rem">gif</i>
        </el-button>
    </el-popover>
</template>

<script>
import SearchGiphy from "../search-giphy";

export default {
    name: "giphy",

    components: {SearchGiphy},

    props: {
        disabled: {
            required: false,
            default: false
        }
    },

    data() {
        return {
            show_search_giphy: false
        }
    },

    mounted() {
    },

    methods: {
        gifSelected(gif) {
            this.$emit('gif-selected', gif)
            this.$el.querySelector('#gif-popover-trigger').click()
        },

        mountSearchGiphy() {
            setTimeout(() => this.show_search_giphy = true, 500)
        }
    }
}

</script>

<style scoped>

</style>
