var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-table",
        { staticStyle: { width: "100%" }, attrs: { data: _vm.templatesData } },
        [
          _c("el-table-column", {
            attrs: { align: "left", label: "Workflow", "min-width": 15 },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("div", [
                      _c("p", { staticClass: "mb-0 font-weight-bold" }, [
                        _vm._v(
                          "\n                        " +
                            _vm._s(scope.row.workflow) +
                            "\n                    "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("span", [_vm._v("#" + _vm._s(scope.row.id))]),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "left", label: "Created by", "min-width": 10 },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.user
                      ? _c(
                          "div",
                          [
                            !scope.row.user.deleted_at
                              ? _c(
                                  "router-link",
                                  {
                                    attrs: {
                                      to: {
                                        name: "User Dialog",
                                        params: { user_id: scope.row.user.id },
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "hover-lower-opacity" },
                                      [
                                        _c("p", { staticClass: "mb-0" }, [
                                          _c(
                                            "span",
                                            { staticClass: "font-weight-bold" },
                                            [
                                              _vm._v(
                                                _vm._s(scope.row.user.name)
                                              ),
                                            ]
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _c("span", [
                                          _vm._v(
                                            "#" + _vm._s(scope.row.user.id)
                                          ),
                                        ]),
                                      ]
                                    ),
                                  ]
                                )
                              : _c("div", [
                                  _c(
                                    "div",
                                    { staticClass: "hover-lower-opacity" },
                                    [
                                      _c("p", { staticClass: "mb-0" }, [
                                        _c(
                                          "span",
                                          { staticClass: "font-weight-bold" },
                                          [_vm._v(_vm._s(scope.row.user.name))]
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("span", [
                                        _vm._v("#" + _vm._s(scope.row.user.id)),
                                      ]),
                                    ]
                                  ),
                                ]),
                          ],
                          1
                        )
                      : _c("div", [_c("span", [_vm._v("-")])]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "Version", "min-width": 10 },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("b", [_vm._v(_vm._s(scope.row.version))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "Notes", "min-width": 10 },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("TemplateNotes", { attrs: { template: scope.row } }),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", "min-width": 10 },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "div",
                      {
                        staticClass: "hover-lower-opacity-danger ml-auto",
                        attrs: {
                          disabled: _vm.shouldDisableDeleteButton(scope.row),
                        },
                        on: {
                          click: function ($event) {
                            return _vm.handleDelete(scope.row)
                          },
                        },
                      },
                      [
                        _c("i", {
                          staticClass: "fa fa-trash-o mr-1",
                          staticStyle: { "font-size": "17px" },
                          attrs: { "aria-hidden": "true" },
                        }),
                        _vm._v(
                          "\n                    Delete\n                "
                        ),
                      ]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("DeleteTemplate", {
        attrs: {
          form_visible: _vm.delete_template_visible,
          template: _vm.template,
        },
        on: { submit: _vm.onDeleteSubmit, discard: _vm.closeDeleteTemplate },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }