var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.hasPermissionTo("list user")
    ? _c(
        "div",
        { staticClass: "ordered-user-team-selector" },
        [
          _vm._m(0),
          _vm._v(" "),
          _c(
            "draggable",
            {
              staticClass: "list-ring-group list-unstyled",
              attrs: { tag: "ul", handle: ".handle", list: _vm.list },
              on: { change: _vm.changed },
            },
            _vm._l(_vm.list, function (element, idx) {
              return _c("li", { key: idx }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "list-ring-group-item d-flex align-items-center",
                  },
                  [
                    _c(
                      "el-tooltip",
                      {
                        staticClass: "item",
                        attrs: {
                          effect: "dark",
                          content:
                            "Drag up or down to change the order of the ring group layers",
                          placement: "top",
                        },
                      },
                      [
                        _c(
                          "el-button",
                          {
                            staticClass: "handle mr-3 draggable",
                            attrs: { size: "small" },
                          },
                          [_c("i", { staticClass: "fas fa-arrows-alt-v" })]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "layer-label" }, [
                      _vm._v("Layer " + _vm._s(idx + 1)),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "w-100" }, [
                      _c(
                        "div",
                        { staticClass: "d-flex align-items-center" },
                        [
                          _c("div", { staticClass: "select-label" }, [
                            _vm._v("Users:"),
                          ]),
                          _vm._v(" "),
                          _c(
                            "el-tooltip",
                            {
                              staticClass: "item",
                              attrs: {
                                effect: "dark",
                                content:
                                  "Select one or more users for this layer.",
                                placement: "top",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticClass: "flex-grow-1 w-100",
                                  attrs: {
                                    placeholder: "Add one or more users",
                                    multiple: "",
                                    filterable: "",
                                    "default-first-option": "",
                                    disabled: _vm.loading,
                                    "filter-method": _vm.filterOptions,
                                  },
                                  on: {
                                    change: _vm.changed,
                                    "visible-change": _vm.resetFilteredText,
                                  },
                                  model: {
                                    value: _vm.list[idx].user_ids,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.list[idx], "user_ids", $$v)
                                    },
                                    expression: "list[idx].user_ids",
                                  },
                                },
                                [
                                  _vm.normalUsers && _vm.normalUsers.length > 0
                                    ? _c(
                                        "el-option-group",
                                        {
                                          key: "Users",
                                          attrs: { label: "Users" },
                                        },
                                        _vm._l(
                                          _vm.normalUsers,
                                          function (user) {
                                            return _c(
                                              "el-option",
                                              {
                                                key: `user-${user.id}`,
                                                staticClass: "p-0",
                                                attrs: {
                                                  label: user.name,
                                                  value: user.id,
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "media" },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "media-body",
                                                      },
                                                      [
                                                        _c("label", [
                                                          _vm._v(
                                                            _vm._s(user.name)
                                                          ),
                                                        ]),
                                                        _vm._v(" "),
                                                        _c("small", [
                                                          _vm._v(
                                                            _vm._s(user.email) +
                                                              " - " +
                                                              _vm._s(
                                                                _vm.getLabel(
                                                                  user
                                                                )
                                                              )
                                                          ),
                                                        ]),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            )
                                          }
                                        ),
                                        1
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.extensionUsers &&
                                  _vm.extensionUsers.length > 0
                                    ? _c(
                                        "el-option-group",
                                        {
                                          key: "Extensions",
                                          attrs: { label: "Extensions" },
                                        },
                                        _vm._l(
                                          _vm.extensionUsers,
                                          function (user) {
                                            return _c(
                                              "el-option",
                                              {
                                                key: `user-${user.id}`,
                                                staticClass: "p-0",
                                                attrs: {
                                                  label: user.name,
                                                  value: user.id,
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "media" },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "media-body",
                                                      },
                                                      [
                                                        _c("label", [
                                                          _vm._v(
                                                            _vm._s(user.name)
                                                          ),
                                                        ]),
                                                        _vm._v(" "),
                                                        _c("small", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.getLabel(user)
                                                            )
                                                          ),
                                                        ]),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            )
                                          }
                                        ),
                                        1
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.pausedUsers.length
                                    ? _c(
                                        "el-option-group",
                                        { attrs: { label: "Paused Users" } },
                                        _vm._l(
                                          _vm.pausedUsers,
                                          function (user) {
                                            return _c(
                                              "el-option",
                                              {
                                                key: `user-${user.id}`,
                                                staticClass: "p-0",
                                                attrs: {
                                                  label: user.name,
                                                  value: user.id,
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "media" },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "media-body text-danger",
                                                      },
                                                      [
                                                        _c("label", [
                                                          _vm._v(
                                                            _vm._s(user.name)
                                                          ),
                                                        ]),
                                                        _vm._v(" "),
                                                        _c("small", [
                                                          _vm._v(
                                                            _vm._s(user.email) +
                                                              " - " +
                                                              _vm._s(
                                                                _vm.getLabel(
                                                                  user
                                                                )
                                                              )
                                                          ),
                                                        ]),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            )
                                          }
                                        ),
                                        1
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.pausedExtensions.length
                                    ? _c(
                                        "el-option-group",
                                        {
                                          attrs: { label: "Paused Extensions" },
                                        },
                                        _vm._l(
                                          _vm.pausedExtensions,
                                          function (user) {
                                            return _c(
                                              "el-option",
                                              {
                                                key: user.id,
                                                staticClass: "p-0",
                                                attrs: {
                                                  label: user.name,
                                                  value: user.id,
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "media" },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "media-body text-danger",
                                                      },
                                                      [
                                                        _c("label", [
                                                          _vm._v(
                                                            _vm._s(user.name)
                                                          ),
                                                        ]),
                                                        _vm._v(" "),
                                                        _c("small", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.getLabel(user)
                                                            )
                                                          ),
                                                        ]),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            )
                                          }
                                        ),
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "d-flex align-items-center mt-3" },
                        [
                          _c("div", { staticClass: "select-label" }, [
                            _vm._v("Teams:"),
                          ]),
                          _vm._v(" "),
                          _c(
                            "el-tooltip",
                            {
                              staticClass: "item",
                              attrs: {
                                effect: "dark",
                                content:
                                  "Select one or more teams for this layer.",
                                placement: "top",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticClass: "teams-select flex-grow-1 w-100",
                                  attrs: {
                                    placeholder: "Add one or more teams",
                                    multiple: "",
                                    filterable: "",
                                    "default-first-option": "",
                                    "popper-class": "teams-select-popper",
                                    disabled: _vm.loading,
                                    "filter-method": _vm.filterOptions,
                                  },
                                  on: {
                                    change: _vm.changed,
                                    "visible-change": _vm.resetFilteredText,
                                  },
                                  model: {
                                    value: _vm.list[idx].team_ids,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.list[idx], "team_ids", $$v)
                                    },
                                    expression: "list[idx].team_ids",
                                  },
                                },
                                [
                                  _vm.filteredTeams &&
                                  _vm.filteredTeams.length > 0
                                    ? _c(
                                        "el-option-group",
                                        { attrs: { label: "Teams" } },
                                        _vm._l(
                                          _vm.filteredTeams,
                                          function (team) {
                                            return _c(
                                              "el-option",
                                              {
                                                key: team.id,
                                                staticClass: "p-0",
                                                attrs: {
                                                  label: team.name,
                                                  value: team.id,
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "media" },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "media-body",
                                                      },
                                                      [
                                                        _c("label", [
                                                          _vm._v(
                                                            _vm._s(team.name)
                                                          ),
                                                        ]),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            )
                                          }
                                        ),
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "el-tooltip",
                      {
                        staticClass: "item",
                        attrs: {
                          effect: "dark",
                          content: "Remove this layer",
                          placement: "top",
                        },
                      },
                      [
                        _c("i", {
                          directives: [
                            {
                              name: "visible",
                              rawName: "v-visible",
                              value: idx > 0,
                              expression: "idx > 0",
                            },
                          ],
                          staticClass: "fa fa-times close text-danger ml-3",
                          on: {
                            click: function ($event) {
                              return _vm.removeAt(idx)
                            },
                          },
                        }),
                      ]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "text-center" }, [
                  _c("i", {
                    staticClass: "fas fa-arrow-down",
                    staticStyle: { "font-size": "30px", color: "#acacac" },
                  }),
                ]),
              ])
            }),
            0
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "text-center" },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "success", plain: "" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.add.apply(null, arguments)
                    },
                  },
                },
                [
                  _c("i", { staticClass: "fa fa-plus" }),
                  _vm._v("\n            Add Another Layer\n        "),
                ]
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "text-center" }, [
      _c("i", {
        staticClass: "fas fa-arrow-down",
        staticStyle: { "font-size": "30px", color: "#acacac" },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }