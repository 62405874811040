<template>
    <div class="row no-gutter"
         :class="[ no_padding ? '' : 'pl-3 pr-3' ]"
         v-if="contact && contact.id">

        <div class="col-12"
             v-loading="loading_integration_data"
             style="min-height: 60px;"
             v-if="guestyEnabled">
            <el-card shadow="never"
                     class="small-paddings"
                     v-if="!isEmpty(messages)"
                     v-for="(message, key) in messages.messages"
                     :key="key"
                     style="margin-top: 10px;">
                <!-- Start main info -->
                <table class="mb-2 w-100">
                    <tr>
                        <td class="text-muted">Module:</td>
                    </tr>
                    <tr>
                        <td class="_400">
                            <span>{{ message.module }}</span>
                        </td>
                    </tr>
                    <tr>
                        <td class="text-muted">Direction:</td>
                    </tr>
                    <tr>
                        <td class="_400">
                            <span>{{ message.direction }}</span>
                        </td>
                    </tr>
                    <tr>
                        <td class="text-muted">Message:</td>
                    </tr>
                    <tr>
                        <td class="_400">
                            <span>{{ message.body }}</span>
                        </td>
                    </tr>
                </table>
                <!-- End main info -->
            </el-card>
        </div>
    </div>
</template>

<script>

import {mapState} from 'vuex'

export default {
    props: {
        contact: {
            required: true
        },

        no_padding: {
            type: Boolean,
            required: false,
            default: false
        },
    },

    data() {
        return {
            loading_integration_data: false,
            messages: [],
        }
    },

    computed: {
        ...mapState('cache', ['current_company']),

        guestyEnabled() {
            if (this.current_company &&
                this.current_company.guesty_integration_enabled) {
                return true
            }

            return false
        }
    },

    created() {
        this.fetchReservationsMessagesData()
    },

    methods: {
        fetchContactReservationsMessages(contact_id) {
            this.loading_integration_data = true
            axios.get('/api/v1/contact/' + contact_id + '/reservations/messages').then(res => {
                this.loading_integration_data = false
                this.messages = res.data
            }).catch(err => {
                this.loading_integration_data = false
            })
        },

        isEmpty(obj) {
            return Object.keys(obj).length === 0 && obj.constructor === Object
        },

        fetchReservationsMessagesData(force = false) {
            if (this.contact && this.contact.id) {
                if (this.guestyEnabled) {
                    this.fetchContactReservationsMessages(this.contact.id)
                }
            }
        },
    },

    watch: {
        contact() {
            if (this.contact) {
                this.fetchReservationsMessagesData()
            }
        }
    }
}
</script>
