var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { staticClass: "d-flex align-items-baseline" },
      [
        _c("div", { staticClass: "flex-shrink-0" }, [
          _vm._v("\n            Impersonate contact\n        "),
        ]),
        _vm._v(" "),
        _c(
          "el-select",
          {
            staticClass: "w-full flex-grow-1",
            attrs: {
              placeholder: _vm.label,
              "remote-method": _vm.fetchContacts,
              loading: _vm.loading,
              filterable: "",
              remote: "",
              clearable: "",
            },
            on: { change: _vm.selectContacts, clear: _vm.blur, blur: _vm.blur },
            model: {
              value: _vm.selected_contact,
              callback: function ($$v) {
                _vm.selected_contact = $$v
              },
              expression: "selected_contact",
            },
          },
          _vm._l(_vm.contacts, function (contact) {
            return _c(
              "el-option",
              {
                key: contact.id,
                staticClass: "p-0",
                attrs: {
                  label: contact.name || contact.phone_number,
                  value: contact.id,
                },
              },
              [
                _c("div", { staticClass: "media" }, [
                  _c("div", { staticClass: "media-body" }, [
                    _c("label", [_vm._v(_vm._s(contact.name))]),
                    _vm._v(" "),
                    _c("small", [
                      _vm._v(_vm._s(_vm._f("fixPhone")(contact.phone_number))),
                    ]),
                  ]),
                ]),
              ]
            )
          }),
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }