var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm._m(0),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "col-12 mt-2" },
      [
        _c("p", { staticClass: "font-weight-bold" }, [_vm._v("Intents:")]),
        _vm._v(" "),
        _c("IntentsSelector", {
          attrs: {
            chatbot_intents: _vm.intents,
            node_intents: _vm.nodeData.intents,
          },
          on: { "intent-changed": _vm.intentChangedListener },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "col-12 mt-4" },
      [
        _c("Fallback", {
          ref: "fallback",
          attrs: { fallbacks: _vm.fallbacks },
          on: { change: _vm.updateFallback },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-12 mb-3" }, [
      _c("p", { staticClass: "font-weight-bold side-panel-title" }, [
        _vm._v("Get Input Panel"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }