var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-tooltip",
    {
      staticClass: "item",
      attrs: {
        effect: "dark",
        content: _vm.data.note,
        disabled: !_vm.data.note || _vm.shouldHideTooltip,
        placement: "bottom",
      },
    },
    [
      _c(
        "node-template",
        {
          attrs: { id: _vm.nodeId },
          scopedSlots: _vm._u([
            {
              key: "header",
              fn: function () {
                return [_vm._v("\n            Appointment\n        ")]
              },
              proxy: true,
            },
          ]),
        },
        [
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "row-inline mt-2" },
            [
              _c("div", { staticClass: "col-12" }, [
                _vm.data.note
                  ? _c("p", {
                      domProps: { textContent: _vm._s(_vm.data.note) },
                    })
                  : _c("p", [
                      _vm._v(
                        "\n                    Enter appointment note.\n                "
                      ),
                    ]),
              ]),
              _vm._v(" "),
              _c("hr"),
              _vm._v(" "),
              _c("div", { staticClass: "col-12 text-center" }, [
                _c("span", [
                  _c("b", [_vm._v("Date Message:")]),
                  _vm._v(" " + _vm._s(_vm.data.date_message)),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-12 text-center" }, [
                _c("span", [
                  _c("b", [_vm._v("Time Message:")]),
                  _vm._v(" " + _vm._s(_vm.data.time_message)),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-12 text-center" }, [
                _c("b", [_vm._v("Service:")]),
                _vm._v(" "),
                _vm.appointment_service
                  ? _c("span", [
                      _vm._v(
                        _vm._s(
                          `${_vm.appointment_service.name} (${_vm.appointment_service.duration} min.)`
                        )
                      ),
                    ])
                  : _vm._e(),
              ]),
              _vm._v(" "),
              _c("hr"),
              _vm._v(" "),
              _c("Fallbacks", {
                staticClass: "mt-1",
                attrs: { fallbacks: _vm.data.fallback, showSuccess: true },
              }),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }