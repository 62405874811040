import store from './store'
import { SELF_SERVE_CX_CSM_ID } from './constants';

// used to reduce the number of calls to API
let lastFetch = {
    time: 0,
    token: '',
    data: {}
}

function resetLastFetch() {
    lastFetch = {
        time: 0,
        token: '',
        data: {}
    }
}

export default {
    user: {
        authenticated: false,
        profile: null,
        adminImpersonated: false
    },

    check(prevent_logout = false) {
        if (localStorage.getItem('api_token') === null) {
            this.user.authenticated = false
            this.user.profile = null

            return Promise.reject({
                response: {
                    data: {
                        error: 'User is not authenticated'
                    }
                }
            })
        } else {
            window.axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('api_token')

            // Only fetch authenticated user every 1 minute (60000 milliseconds)
            if (Date.now() - lastFetch.time <= 60 * 1000 && Object.keys(lastFetch.data).length !== 0 && lastFetch.token === localStorage.getItem('api_token')) {
                return Promise.resolve(lastFetch.data)
            }

            return axios.post('/get-auth-user').then((res) => {
                // success
                window.axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('api_token')
                this.user.adminImpersonated = localStorage.getItem('impersonate') === 'true' && localStorage.getItem('is_admin_impersonating') === 'true'
                this.user.authenticated = true
                this.user.profile = res.data.user

                store.commit('SET_USAGE', res.data.user.usage)
                store.commit('SET_USER_STATUS', res.data.user.enabled)
                store.commit('SET_CURRENT_TIMEZONE', res.data.user.company.timezone)

                let isSuspendedPage = window.location.href.indexOf('/suspended') >= 0
                let isCanceledPage = window.location.href.indexOf('/canceled') >= 0

                if (
                    !res.data.user.enabled &&
                    !isSuspendedPage &&
                    !this.user.adminImpersonated
                ) {
                    window.location.href = '/suspended'
                } else if (
                    res.data.user.company_subscription_status === 'cancelled' &&
                    !res.data.user.company.is_trial &&
                    !isCanceledPage &&
                    // Verify if it's suspended to avoid infinite looping
                    !isSuspendedPage &&
                    !this.user.adminImpersonated
                ) {
                    window.location.href = '/canceled'
                }

                lastFetch.time = Date.now()
                lastFetch.data = res
                lastFetch.token = localStorage.getItem('api_token')

                return Promise.resolve(res)
            }).catch((err) => {
                if (!prevent_logout) {
                    // error
                    this.user.authenticated = false
                    this.user.profile = null
                }

                return Promise.reject(err)
            })
        }

    },

    login(email, password, remember_me, recaptcha_response, requested_from = null) {
        const config = {}
        const params = {
            email: email,
            password: password,
            remember_me: remember_me,
            recaptcha_response: recaptcha_response
        }

        if (requested_from) {
            config.headers = {
                'requested-from': requested_from
            }
        }

        return axios.post('/login', params, config).then((res) => {
            // success
            localStorage.setItem('api_token', res.data.meta.token)
            window.axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('api_token')
            store.commit('SET_FIRST_LOGIN', res.data.data.first_login)

            return Promise.resolve(res)
        }).catch((err) => {
            // error
            return Promise.reject(err)
        })
    },

    logout() {
        return axios.post('/logout').then((res) => {
            // success
            this.clear()

            resetLastFetch()

            return Promise.resolve(res)
        }).catch((err) => {
            // error
            return Promise.reject(err)
        })
    },

    clear() {
        localStorage.removeItem('api_token')
        localStorage.removeItem('impersonate')
        localStorage.removeItem('company_id')
        localStorage.removeItem('is_admin_impersonating')
        localStorage.removeItem('hashed_token')
        localStorage.removeItem('current_user_id')
        window.axios.defaults.headers = null
    },

    register(first_name, last_name, email, password, company_name, phone_number, timezone, agreed_to_terms) {
        return axios.post('/register', {
            first_name: first_name,
            last_name: last_name,
            email: email,
            password: password,
            company_name: company_name,
            phone_number: phone_number,
            timezone: timezone,
            agreed_to_terms: agreed_to_terms,
        }).then((res) => {
            // success
            return Promise.resolve(res)
        }).catch((err) => {
            // error
            return Promise.reject(err)
        })
    },

    forgotPass(email, recaptcha_response) {
        return axios.post('/forgot', {
            email: email,
            recaptcha_response: recaptcha_response
        }).then((res) => {
            // success
            return Promise.resolve(res)
        }).catch((err) => {
            // error
            return Promise.reject(err)
        })
    },

    resetPass(email, password, password_confirmation, token, recaptcha_response) {
        return axios.post('/reset', {
            email: email,
            password: password,
            password_confirmation: password_confirmation,
            token: token,
            recaptcha_response: recaptcha_response
        }).then((res) => {
            // success
            return Promise.resolve(res)
        }).catch((err) => {
            // error
            return Promise.reject(err)
        })
    },

    impersonate(user_id, user_access = false) {
        let is_impersonating = true
        let previous_user_id = this.user?.profile?.id
        localStorage.removeItem('previous_user_id')
        localStorage.removeItem('impersonate')
        localStorage.removeItem('company_id')
        localStorage.removeItem('current_user_id')

        if (user_access) {
            is_impersonating = false
        }

        return axios.post('/api/v1/user/' + user_id + '/impersonate', {
            is_impersonated: is_impersonating
        }).then((res) => {
            // success
            localStorage.setItem('api_token', res.data.api_token)
            window.axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('api_token')
            if (is_impersonating) {
                localStorage.setItem('impersonate', true)
                localStorage.setItem('previous_user_id', previous_user_id)
            }

            localStorage.setItem('company_id', res.data.company_id)
            localStorage.setItem('current_user_id', res.data.user_id)
        }).catch((err) => {
            // error
            return Promise.reject(err)
        })
    },

    /* WAT-653: accounts where there is a CSM (CSM is not null, CSM != 196 self serve CX) CSM can be read from  aloware.db.companies.csm_rep_admin_id */
    hasCsm() {
        // csm_rep_admin_id is not null, csm_rep_admin_id != self serve CX
        return this.user.profile && 
               this.user.profile.csm_rep && 
               this.user.profile.csm_rep.id && 
               this.user.profile.csm_rep.id !== SELF_SERVE_CX_CSM_ID
    },

    hasSalesRep() {
        return this.user.profile && 
               this.user.profile.sales_rep && 
               this.user.profile.sales_rep.id
    },

    /* WAT-653: case CSM field = 196 (self-serve CX) */
    isSelfServedAccount() {
        const IS_SELF_SERVE_ACCOUNT = this.user.profile?.csm_rep?.id === SELF_SERVE_CX_CSM_ID
        return IS_SELF_SERVE_ACCOUNT 
    }
}
