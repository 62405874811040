<template>
    <div>
        <el-card v-for="(trigger, idx) in triggers"
                 class="box-card mb-3"
                 :key="trigger.id">
            <div slot="header"
                 class="clearfix">
                <span class="text-md _600"
                      v-if="!['contact', 'disposition', 'call disposition'].includes(trigger.type)">
                    #{{ idx + 1 }} - {{ trigger.type | fixCommType }} Trigger
                </span>
                <span class="text-md _600"
                      v-else>
                    #{{ idx + 1 }} - {{ trigger.type | fixName }} Trigger
                </span>
                <div class="pull-right">
                    <el-dropdown trigger="click">
                        <el-button
                            size="medium"
                            class="px-3 py-2 border-0">
                            <i class="fa fa-ellipsis-h"></i>
                        </el-button>
                        <template v-slot:dropdown>
                            <el-dropdown-menu>
                                <div class="d-flex flex-column px-3">
                                    <el-button class="text-blue text-left"
                                               type="text"
                                               v-if="(trigger.type == 'contact' && !automation_trigger && !power_dialer_trigger) || (trigger.type != 'contact' && trigger.type != 'disposition' && !power_dialer_trigger && !automation_trigger) || (trigger.type == 'disposition' && trigger.disposition_status_id != 0) || (trigger.type == 'call disposition' && trigger.call_disposition_id != 0)"
                                               @click="duplicate(trigger, idx)">
                                        <i class="material-icons">content_copy</i>
                                        Duplicate
                                    </el-button>
                                    <template v-if="trigger.valid && triggerCanBeCopied(trigger)">
                                        <el-popover :ref="'copy_form_' + trigger.id"
                                                    placement="left-start"
                                                    width="250"
                                                    trigger="click"
                                        >
                                            <div v-loading="copy_trigger_loading">
                                                <campaign-selector v-model="copy_trigger_to_campaign"
                                                                   @change="updateCopyTriggerToCampaign">
                                                </campaign-selector>
                                                <el-button class="btn btn-success mt-2 w-full"
                                                           type="success"
                                                           @click="copyTriggerToLine(trigger)"
                                                           :disabled="!copy_trigger_to_campaign">
                                                    Copy
                                                </el-button>
                                            </div>
                                            <el-button class="text-success ml-0 text-green text-left"
                                                       slot="reference"
                                                       type="text">
                                                <i class="material-icons">phone</i>
                                                Copy to line
                                            </el-button>
                                        </el-popover>
                                    </template>
                                    <el-button class="text-danger ml-0 text-red text-left"
                                               type="text"
                                               @click="removeAt(idx)">
                                        <i class="material-icons">delete</i>
                                        Delete
                                    </el-button>
                                </div>
                            </el-dropdown-menu>
                        </template>
                    </el-dropdown>
                </div>
            </div>
            <trigger :trigger="trigger"
                     :automation_trigger="automation_trigger"
                     :power_dialer_trigger="power_dialer_trigger"
                     :disable_any_contact_disposition="contactDispositionTriggerIndex > 0"
                     :disable_any_call_disposition="callDispositionTriggerIndex > 0"
                     :used_contact_disposition_statuses="usedContactDispositionStatuses"
                     @valid="validateTrigger(true)"
                     @invalid="validateTrigger(false)">
            </trigger>
        </el-card>
        <div class="mt-3">
            <template v-if="!automation_trigger && !power_dialer_trigger">
                <el-button type="success"
                           size="medium"
                           plain
                           @click="addCallTrigger">
                    <i class="material-icons mr-1">call</i>
                    <span class="_700 pointer">Add Call Trigger</span>
                </el-button>
                <el-button type="success"
                           size="medium"
                           plain
                           @click="addSmsTrigger">
                    <i class="material-icons mr-1">sms</i>
                    <span class="_700 pointer">Add SMS Trigger</span>
                </el-button>
                <el-button type="success"
                           size="medium"
                           plain
                           v-if="has_email_intake"
                           @click="addEmailTrigger">
                    <i class="material-icons mr-1">email</i>
                    <span class="_700 pointer">Add Email Trigger</span>
                </el-button>
                <el-button type="success"
                           size="medium"
                           plain
                           v-if="canAddCallDispositionTrigger"
                           @click="addCallDispositionTrigger">
                    <i class="material-icons mr-1">contact_phone</i>
                    <span class="_700 pointer">Add Call Disposition Trigger</span>
                </el-button>
                <el-button type="success"
                           size="medium"
                           plain
                           v-if="canAddContactTrigger"
                           @click="addContactTrigger">
                    <i class="material-icons mr-1">contact_phone</i>
                    <span class="_700 pointer">Add Contact Trigger</span>
                </el-button>
            </template>
            <template v-if="automation_trigger">
                <el-button type="success"
                           size="medium"
                           plain
                           v-if="canAddContactDispositionTrigger"
                           @click="addContactDispositionTrigger">
                    <i class="material-icons mr-1">contact_phone</i>
                    <span class="_700 pointer">Add Contact Disposition Trigger</span>
                </el-button>
                <el-button type="success"
                           size="medium"
                           plain
                           v-if="canAddCallDispositionTrigger"
                           @click="addCallDispositionTrigger">
                    <i class="material-icons mr-1">contact_phone</i>
                    <span class="_700 pointer">Add Call Disposition Trigger</span>
                </el-button>
                <el-button type="success"
                           size="medium"
                           plain
                           v-if="canAddContactTrigger"
                           @click="addAutomationContactTrigger">
                    <i class="material-icons mr-1">contact_phone</i>
                    <span class="_700 pointer">Add Contact Trigger</span>
                </el-button>
                <el-button type="success"
                           size="medium"
                           plain
                           v-if="canAddCallTrigger"
                           @click="addAutomationCallTrigger">
                    <i class="material-icons mr-1">call</i>
                    <span class="_700 pointer">Add Call Trigger</span>
                </el-button>
            </template>
            <template v-if="power_dialer_trigger">
                <div class="pull-left">
                    <el-button type="success"
                               size="medium"
                               plain
                               v-if="canAddCallTrigger"
                               @click="addPowerDialerCallTrigger">
                        <i class="material-icons mr-1">call</i>
                        <span class="_700 pointer">Add Call Trigger</span>
                    </el-button>
                    <el-button type="success"
                               size="medium"
                               plain
                               v-if="canAddCallDispositionTrigger"
                               @click="addCallDispositionTrigger">
                        <i class="material-icons mr-1">contact_phone</i>
                        <span class="_700 pointer">Add Call Disposition Trigger</span>
                    </el-button>
                </div>
                <div class="pull-right">
                    <el-button type="success"
                               size="medium"
                               @click="saveTriggers">
                        <i class="material-icons loader"
                           v-show="loading_btn">&#xE863;</i>
                        <i class="material-icons"
                           v-show="!loading_btn">
                            save
                        </i>
                        <span class="_700 pointer">Save Triggers</span>
                    </el-button>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import auth from '../auth'
import {acl_mixin, validator_mixin} from '../mixins'
import {mapState} from 'vuex'
import Trigger from './trigger'
import * as CommunicationTypes from '../constants/communication-types'
import * as CommunicationDirections from "../constants/communication-direction"
import * as CommunicationDispositionStatus from "../constants/communication-disposition-status"

export default {
    mixins: [validator_mixin, acl_mixin],

    components: {
        Trigger
    },

    props: {
        triggers: {
            required: true,
            default: []
        },

        intake_route_list: {
            required: false
        },

        automation_trigger: {
            type: Boolean,
            default: false,
            required: false
        },

        power_dialer_trigger: {
            type: Boolean,
            default: false,
            required: false
        }
    },

    data() {
        return {
            auth: auth,
            has_email_intake: false,
            loading_btn: false,
            copy_trigger_to_campaign: null,
            copy_trigger_loading: false,
            copy_form_current: null,
            CommunicationDirections,
            CommunicationDispositionStatus
        }
    },

    computed: {
        ...mapState('cache', ['current_company']),

        canAddCallTrigger() {
            if (this.triggers && Array.isArray(this.triggers)) {
                return !this.triggers.some(trigger => trigger.type == CommunicationTypes.CALL)
            }

            return true
        },

        canAddContactTrigger() {
            if (this.triggers && Array.isArray(this.triggers)) {
                return !this.triggers.some(trigger => trigger.type == 'contact')
            }

            return true
        },

        canAddContactDispositionTrigger() {
            if (this.triggers && Array.isArray(this.triggers)) {
                return !this.triggers.some(trigger => trigger.type == 'disposition' && trigger.disposition_status_id === 0)
            }

            return true
        },

        canAddCallDispositionTrigger() {
            if (this.triggers && Array.isArray(this.triggers)) {
                return !this.triggers.some(trigger => trigger.type == 'call disposition' && trigger.call_disposition_id === 0)
            }

            return true
        },

        usedContactDispositionStatuses() {
            if (this.triggers && Array.isArray(this.triggers)) {
                return this.triggers.map(trigger => (trigger.disposition_status_id) ? trigger.disposition_status_id : null).filter(o => o !== null)
            }

            return []
        },

        contactDispositionTriggerIndex() {
            let count = -1
            for (let index in this.triggers) {
                if (this.triggers[index].type === 'disposition') {
                    count++
                }
            }
            return count
        },

        callDispositionTriggerIndex() {
            let count = -1
            for (let index in this.triggers) {
                if (this.triggers[index].type === 'call disposition') {
                    count++
                }
            }
            return count
        }
    },

    created() {
        if (this.intake_route_list) {
            this.intake_route_list.forEach((intake_route) => {
                if (intake_route.type == 'email') {
                    this.has_email_intake = true
                }
            })
        }
    },

    methods: {
        addCallTrigger() {
            let trigger = {
                id: this.$uuid.v4(),
                type: CommunicationTypes.CALL,
                direction: null,
                disposition_status: null,
                operation: null,
                workflow_ids: [],
                tag_ids: [],
                ticket_name: '',
                pipeline_id: null,
                stage_id: null,
                ticket_description: '',
                disposition_status_id: null,
                has_condition: false,
                symbol: null,
                variable: null,
                value: 0,
                keywords: [],
                valid: false
            }
            this.triggers.push(trigger)
        },

        addSmsTrigger() {
            let trigger = {
                id: this.$uuid.v4(),
                type: CommunicationTypes.SMS,
                direction: null,
                disposition_status: null,
                operation: null,
                workflow_ids: [],
                tag_ids: [],
                ticket_name: '',
                pipeline_id: null,
                stage_id: null,
                ticket_description: '',
                disposition_status_id: null,
                has_condition: false,
                symbol: null,
                variable: null,
                value: 0,
                keywords: [],
                valid: false
            }
            this.triggers.push(trigger)
        },

        addContactTrigger() {
            let trigger = {
                id: this.$uuid.v4(),
                type: 'contact',
                direction: null,
                disposition_status: null,
                operation: null,
                workflow_ids: [],
                tag_ids: [],
                ticket_name: '',
                pipeline_id: null,
                stage_id: null,
                ticket_description: '',
                disposition_status_id: null,
                has_condition: false,
                symbol: null,
                variable: null,
                value: 0,
                keywords: [],
                valid: false
            }
            this.triggers.push(trigger)
        },

        addEmailTrigger() {
            let trigger = {
                id: this.$uuid.v4(),
                type: CommunicationTypes.EMAIL,
                direction: null,
                disposition_status: null,
                operation: null,
                workflow_ids: [],
                tag_ids: [],
                ticket_name: '',
                pipeline_id: null,
                stage_id: null,
                ticket_description: '',
                disposition_status_id: null,
                has_condition: false,
                symbol: null,
                variable: null,
                value: 0,
                keywords: [],
                valid: false
            }
            this.triggers.push(trigger)
        },

        addAutomationContactTrigger() {
            let trigger = {
                id: this.$uuid.v4(),
                type: 'contact',
                disposition_status_id: null,
                valid: false
            }
            this.triggers.push(trigger)
        },

        addAutomationCallTrigger() {
            let trigger = {
                id: this.$uuid.v4(),
                type: CommunicationTypes.CALL,
                direction: CommunicationDirections.OUTBOUND,
                disposition_status: CommunicationDispositionStatus.DISPOSITION_STATUS_PLACED_NEW,
                operation: 'disenroll',
                has_condition: true,
                symbol: '>=',
                variable: 'duration',
                value: 0,
                valid: false
            }
            this.triggers.push(trigger)
        },

        addPowerDialerCallTrigger() {
            let trigger = {
                id: this.$uuid.v4(),
                type: CommunicationTypes.CALL,
                direction: CommunicationDirections.OUTBOUND,
                disposition_status: CommunicationDispositionStatus.DISPOSITION_STATUS_PLACED_NEW,
                operation: null,
                workflow_ids: [],
                tag_ids: [],
                disposition_status_id: null,
                has_condition: true,
                symbol: '>=',
                variable: 'duration',
                value: 0,
                valid: false
            }
            this.triggers.push(trigger)
        },

        addContactDispositionTrigger() {
            let trigger = {
                id: this.$uuid.v4(),
                type: 'disposition',
                disposition_status_id: null,
                valid: false
            }

            if (!this.automation_trigger) {
                trigger.workflow_ids = []
            }

            if (this.power_dialer_trigger) {
                trigger.tag_ids = []
            }

            this.triggers.push(trigger)
        },

        addCallDispositionTrigger() {
            let trigger = {
                id: this.$uuid.v4(),
                type: 'call disposition',
                call_disposition_id: null,
                valid: false
            }

            if (!this.automation_trigger) {
                trigger.workflow_ids = []
            }

            if (this.power_dialer_trigger) {
                trigger.tag_ids = []
            }

            this.triggers.push(trigger)
        },

        removeAt(idx) {
            this.triggers.splice(idx, 1)
        },

        copyTriggerToLine(trigger_to_copy) {
            this.copy_trigger_loading = true
            axios.post('/api/v1/campaign/' + this.copy_trigger_to_campaign + '/add-trigger', {
                trigger: trigger_to_copy
            })
            .then(res => {
                this.copy_trigger_loading = false
                this.$notify({
                    offset: 95,
                    title: 'Campaign',
                    message: 'Trigger copied!',
                    type: 'success',
                    showClose: true
                })
                document.querySelector('body').click()
            })
            .catch(err => {
                this.copy_trigger_loading = false
                this.$root.handleErrors(err.response)
                document.querySelector('body').click()
            })
        },

        duplicate(trigger, idx) {
            let duplicated_trigger
            if (!this.automation_trigger && !this.power_dialer_trigger) {
                duplicated_trigger = {
                    id: this.$uuid.v4(),
                    type: trigger.type,
                    direction: trigger.direction,
                    disposition_status: trigger.disposition_status,
                    operation: null,
                    workflow_ids: [],
                    tag_ids: [],
                    ticket_name: '',
                    pipeline_id: null,
                    stage_id: null,
                    ticket_description: '',
                    has_condition: trigger.has_condition,
                    symbol: trigger.symbol,
                    variable: trigger.variable,
                    value: trigger.value,
                    keywords: [],
                    valid: false
                }
            }

            if (this.automation_trigger) {
                duplicated_trigger = {
                    id: this.$uuid.v4(),
                    type: trigger.type,
                    valid: false
                }
                if (trigger.type === 'call disposition') {
                    duplicated_trigger.call_disposition_id = null
                }
                if (trigger.type === 'disposition') {
                    duplicated_trigger.disposition_status_id = null
                }
            }

            if (this.power_dialer_trigger && trigger.type !== CommunicationTypes.CALL) {
                duplicated_trigger = {
                    id: this.$uuid.v4(),
                    type: trigger.type,
                    valid: false,
                    workflow_ids: [],
                    tag_ids: [],
                }
                if (trigger.type === 'call disposition') {
                    duplicated_trigger.call_disposition_id = null
                }
            }

            if (this.power_dialer_trigger && trigger.type === CommunicationTypes.CALL) {
                duplicated_trigger = {
                    id: this.$uuid.v4(),
                    type: trigger.type,
                    direction: trigger.direction,
                    disposition_status: trigger.disposition_status,
                    operation: null,
                    has_condition: true,
                    symbol: '>=',
                    variable: 'duration',
                    value: 0,
                    valid: false
                }
            }

            this.triggers.splice(idx + 1, 0, duplicated_trigger)
        },

        saveTriggers($event) {
            this.loading_btn = true
            $event.target.blur()
            this.$emit('save')
            setTimeout(() => {
                this.loading_btn = false
            }, 1000)
        },

        validateTrigger(valid) {
            this.$emit('validateTrigger', valid)
        },

        updateCopyTriggerToCampaign(val) {
            this.copy_trigger_to_campaign = val
        },

        triggerCanBeCopied(trigger) {
            return !this.power_dialer_trigger
                && !this.automation_trigger
                &&
                (
                    trigger.type != 'contact'
                    ||
                    (trigger.type == 'disposition' && trigger.disposition_status_id != 0)
                    ||
                    (trigger.type == 'call disposition' && trigger.call_disposition_id != 0)
                )
        },
    }
}
</script>
