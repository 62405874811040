var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-tooltip",
    {
      staticClass: "item",
      attrs: {
        effect: "dark",
        content: _vm.data.message,
        disabled: !_vm.data.message || _vm.shouldHideTooltip,
        placement: "bottom",
      },
    },
    [
      _c(
        "node-template",
        {
          attrs: { id: _vm.nodeId },
          scopedSlots: _vm._u([
            {
              key: "header",
              fn: function () {
                return [_c("span", [_vm._v("Store Input")])]
              },
              proxy: true,
            },
          ]),
        },
        [
          _vm._v(" "),
          _c("div", { staticClass: "row mt-2" }, [
            _c("div", { staticClass: "col-12 text-center" }, [
              _c("p", [_vm._v(_vm._s(_vm.data.message))]),
            ]),
          ]),
          _vm._v(" "),
          _c("hr"),
          _vm._v(" "),
          _c("div", { staticClass: "row-inline mt-2" }, [
            _c("div", { staticClass: "col-12 text-center" }, [
              _c("span", [
                _c("b", [_vm._v("Name:")]),
                _vm._v(" " + _vm._s(_vm.data.name)),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "row-inline mt-2" }, [
            _c("div", { staticClass: "col-12 text-center" }, [
              _c("span", [
                _c("b", [_vm._v("Type:")]),
                _vm._v(" " + _vm._s(_vm.data.type)),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "row-inline mt-2" }, [
            _c("div", { staticClass: "col-12 text-center" }, [
              _c("span", [
                _c("b", [_vm._v("Custom Property:")]),
                _vm._v(" " + _vm._s(_vm.data.aloware_name)),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("hr"),
          _vm._v(" "),
          _c("Fallbacks", {
            staticClass: "mt-1",
            attrs: { fallbacks: _vm.data.fallback, showSuccess: true },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }