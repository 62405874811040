<template>
    <router-link :to="{ name: 'Contacts' }"
                 active-class="active green-gradient rounded"
                 exact-active-class="active green-gradient rounded"
                 tag="li"
                 data-testid="contacts-sidebar"
                 v-if="hasPermissionTo('list contact') && !forceTalk">
        <a>
            <span class="nav-icon">
                <img src="/assets/images/app-icons/icon-contacts-grey.svg" 
                     data-testid="icon-contacts-grey-img">
            </span>
            <span class="nav-icon active">
                <img src="/assets/images/app-icons/icon-contacts-white.svg" 
                     data-testid="icon-contacts-white-img">
            </span>
            <span class="nav-text">Contacts</span>
        </a>
    </router-link>
</template>

<script>
    import auth from '../../auth'
    import {acl_mixin, webrtc_mixin} from '../../mixins'

    export default {
        mixins: [acl_mixin],

        data() {
            return {
                auth: auth
            }
        }
    }
</script>
