<template>
    <div>
        <el-dialog class="dialog-blank"
                   width="60vw"
                   top="5vh"
                   append-to-body
                   :show-close="false"
                   data-testid="schedule-messages-dialog"
                   :visible.sync="scheduledMessagesDialogVisible">
            <div class="container-listing">
                <div class="container-header d-flex">
                    <h5 class="listing-heading">Scheduled Messages for {{ contactFullName }}</h5>
                    <div class="ml-auto">
                        <el-button type="text"
                                   class="btn-dialog-close"
                                   size="large"
                                   data-testid="schedule-messages-close-dialog-button"
                                   @click="closeDialog">
                            <i class="fa fa-remove"></i>
                        </el-button>
                    </div>
                </div>

                <div class="container-body">
                    <div class="container-listing-tools row mb-1">
                        <div id="toolbox-scheduled-msg">
                            <el-select placeholder="Select"
                                       class="mr-2"
                                       size="small"
                                       v-model="filters.sort"
                                       data-testid="schedule-messages-select"
                                       @change="handleSelectChange">
                                <el-option default-first-option
                                           :key="item.value"
                                           :label="item.label"
                                           :value="item.value"
                                           v-for="item in options">
                                </el-option>
                            </el-select>
                            <el-button size="small"
                                       data-testid="schdule-messages-refresh-list-button"
                                       @click="fetchScheduledMessages(1)">
                                <i class="fa fa-refresh"></i> Refresh List
                            </el-button>
                        </div>

                        <div class="ml-auto d-flex">
                            <div class="mr-2 align-self-center">
                                <span class="text-grey">Total Scheduled Messages:</span> <strong>{{
                                    pagination.total | numFormat
                                }}</strong>
                            </div>
                        </div>
                    </div>

                    <div style="color: initial;font-size: 0.95rem"
                         v-loading="is_scheduled_messages_loading">
                        <table class="table table-hover" 
                               data-testid="schedule-messages-table">
                            <thead data-testid="schedule-messages-head-table">
                                <tr data-testid="schedule-messages-head-row">
                                    <th data-testid="schedule-message-head-column" 
                                        width="40%">
                                        Message
                                    </th>
                                    <th data-testid="schedule-media-head-column">Media</th>
                                    <th data-testid="schedule-for-head-column" 
                                        width="20%">
                                        Scheduled For
                                    </th>
                                    <th data-testid="schedule-by-head-column" 
                                        width="15%">
                                        Scheduled By
                                    </th>
                                    <th data-testid="schedule-last-column-head-column" 
                                        width="5%">
                                    </th>
                                </tr>
                            </thead>
                            <tbody data-testid="schedule-messages-body-table">
                                <tr :key="message.id"
                                    data-testid="schedule-messages-body-row"
                                    v-for="(message, index) in pagination.data">
                                    <td class="align-middle" 
                                        data-testid="schedule-messages-body-column">
                                        <span v-if="message.message.length < 100">
                                            {{ message.message }}
                                        </span>
                                        <el-popover placement="bottom-end"
                                                    width="400"
                                                    trigger="hover"
                                                    v-else>
                                            <div id="popover-scheduled-msg"
                                                 class="p-1 handle-whitespace">
                                                {{ message.message }}
                                            </div>
                                            <div slot="reference">{{ message.message | strLimit(100) }}</div>
                                        </el-popover>
                                    </td>
                                    <td class="align-middle" 
                                        data-testid="schedule-messages-body-column">
                                        <div class="d-inline px-1"
                                             v-if="!message.gif_url && message.uploaded_files.length < 1">
                                            <span>---</span>
                                        </div>
                                        <div class="d-flex align-items-center">
                                            <div class="px-0">
                                                <div class="d-inline px-1"
                                                     v-if="message.gif_url">
                                                    <img width="20%"
                                                         data-testid="gif-url-img"
                                                         :src="message.gif_url">
                                                </div>

                                                <div class="d-inline px-1"
                                                     :key="file.id"
                                                     v-for="file in message.uploaded_files">
                                                    <img width="20%"
                                                         :src="getFileUrl(file.uuid, file.extension)"
                                                         data-testid="uploaded-files-img"
                                                         v-if="file.mimetype && isAttachmentImage(file.mimetype)"
                                                         v-show="file.show">

                                                    <a target="_blank"
                                                       :href="getFileUrl(file.uuid, file.extension)"
                                                       data-testid="uploaded-files-links"
                                                        v-if="file.mimetype && (isAttachmentText(file.mimetype) || isAttachmentApplication(file.mimetype))"
                                                        v-show="file.show">
                                                        <div class="d-inline">
                                                            <img width="15%"
                                                                 data-testid="svg-file-img"
                                                                 src="/assets/images/app-icons/file.svg">
                                                            <span class="text-xxs">{{ file.original_file }}</span>
                                                        </div>
                                                    </a>

                                                    <div v-if="file.mimetype && isAttachmentVideo(file.mimetype)"
                                                         v-show="file.show">
                                                        <video width="200"
                                                               class="rounded"
                                                               data-testid="uploaded-files-video"
                                                               controls>
                                                            <source :src="getFileUrl(file.uuid, file.extension)"
                                                                    :type="file.mimetype">
                                                            Your browser does not support the video tag.
                                                        </video>
                                                    </div>

                                                    <div v-if="file.mimetype && isAttachmentAudio(file.mimetype)"
                                                         v-show="file.show">
                                                        <audio class="mt-2"
                                                               style="height: 25px;width: 200px;"
                                                               data-testid="uploaded-files-audio"
                                                               controls>
                                                            <source :src="getFileUrl(file.uuid, file.extension)"
                                                                    :type="file.mimetype">
                                                            Your browser does not support the audio element.
                                                        </audio>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="px-1"
                                                 v-if="message.uploaded_files.length > 1">
                                                <el-tooltip class="item"
                                                            effect="dark"
                                                            placement="top"
                                                            :content="getMediaDisplayTooltipContent(message.uploaded_files[1].show)">
                                                    <el-button size="small"
                                                               plain
                                                               round
                                                               data-testid="toggle-message-files-button"
                                                               @click="toggleMessageFilesDisplay(index)">
                                                        <span class="font-weight-bold">+{{ message.uploaded_files.length - 1 }} more </span>
                                                        <span class="align-middle">
                                                            <i class="fa"
                                                               :class="{
                                                                    'fa-angle-up': message.uploaded_files[1].show,
                                                                    'fa-angle-down': !message.uploaded_files[1].show
                                                                }"></i>
                                                        </span>
                                                    </el-button>
                                                </el-tooltip>
                                            </div>
                                        </div>
                                    </td>
                                    <td class="align-middle">
                                        <span class="font-weight-normal">{{ message.scheduled_at | momentFormat('MMM D, YYYY h:mma z', true)}}</span>
                                    </td>
                                    <td class="align-middle">
                                        <span v-if="message.user">{{ message.user.full_name }}</span>
                                    </td>
                                    <td class="align-middle text-right">
                                        <el-button
                                            class="text-danger"
                                            size="small"
                                            data-testid="delete-scheduled-message"
                                            @click="deleteScheduledMessage(message.id, index)">
                                            <i class="fa fa-trash-o"></i>
                                        </el-button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <div class="container-footer d-flex">
                    <div class="ml-auto">
                        <el-pagination layout="sizes, prev, pager, next"
                                       :current-page.sync="pagination.current_page"
                                       :page-count.sync="pagination.per_page"
                                       :page-size.sync="filters.size"
                                       :pager-count="5"
                                       :total="pagination.total"
                                       :hide-on-single-page="true"
                                       data-testid="schedule-messages-pagination"
                                       @size-change="fetchScheduledMessages(1)"
                                       @current-change="fetchScheduledMessages">
                        </el-pagination>
                    </div>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import {mapState} from 'vuex'
import VueLoadImage from 'vue-load-image'

export default {
    components: {
        'vue-load-image': VueLoadImage,
    },

    props: {
        contact: {
          required: true
        },
        contact_id: {
            required: true
        }
    },

    data() {
        return {
            scheduledMessagesDialogVisible: false,
            scheduled_messages: {},
            is_scheduled_messages_loading: false,
            pagination: {},
            filters: {
                size: 10,
                sort: 'latest'
            },
            options: [{
              value: 'latest',
              label: 'Created Date'
            }, {
              value: 'schedule',
              label: 'Chronological'
            }]
        }
    },

    computed: {
        ...mapState('cache', ['current_company']),

        contactFullName() {
            return this.$options.filters.fixContactName(this.contact.name)
        }
    },

    created() {
        this.fetchScheduledMessages();
    },

    mounted() {
        VueEvent.listen('load_scheduled_messages_list', () => {
            this.filters.size = 10
            this.filters.sort = 'latest'
            this.fetchScheduledMessages(1)
            this.scheduledMessagesDialogVisible = true
        })
    },

    methods: {
        getFileUrl(uuid, extension) {
            return `/static/uploaded_file/${uuid}.${extension}`
        },

        handleSelectChange(value) {
            this.filters.sort = value
            this.fetchScheduledMessages()
        },

        async fetchScheduledMessages(page = 1) {
            this.is_scheduled_messages_loading = true
            this.filters.page = page

            axios.get(`/api/v1/scheduled-messages/${this.contact_id}`, {
                params: this.filters
            }).then(res => {
                this.pagination = res.data

                // modify display state of message files (show/not)
                this.pagination.data.forEach((data, index) => {
                    if (data.uploaded_files.length) {
                        data.uploaded_files.forEach((file, key) => {
                            this.pagination.data[index].uploaded_files[key].show = !key
                        })
                    }
                })

                this.is_scheduled_messages_loading = false
            }).catch(err => {
                console.log(err);
            })
        },

        async deleteScheduledMessage(message_id, index) {
            this.$confirm('Are you sure to delete the scheduled message?', 'Warning', {
                    confirmButtonText: "Yes",
                    cancelButtonText: "No",
                    type: 'warning',
                    customClass: 'width-500 fixed'
                })
                .then(async () => {
                    await axios.delete(`/api/v1/scheduled-messages/${message_id}`)

                    this.fetchScheduledMessages(this.pagination.current_page)
                })
                .catch(err => {
                    console.warn(err)
                })
        },

        isAttachmentImage(mime_type) {
            return mime_type.includes('image/')
        },

        isAttachmentVideo(mime_type) {
            return mime_type.includes('video/')
        },

        isAttachmentAudio(mime_type) {
            return mime_type.includes('audio/')
        },

        isAttachmentText(mime_type) {
            return mime_type.includes('text/')
        },

        isAttachmentApplication(mime_type) {
            return mime_type.includes('application/')
        },

        toggleMessageFilesDisplay(index)
        {
            let updatedRow = this.pagination.data[index]

            updatedRow.uploaded_files.forEach((file, key) => {
                // only toggle those that are hidden; first file is always visible
                if (key) {
                    updatedRow.uploaded_files[key].show = !updatedRow.uploaded_files[key].show
                }
            })

            this.pagination.data.splice(index, 1, updatedRow)
        },

        getMediaDisplayTooltipContent(state) {
            return state ? 'Hide most media attachments' : 'Show all media attachments'
        },

        closeDialog() {
            this.scheduledMessagesDialogVisible = false
        }
    }
}
</script>
