<template>
    <div>
        <p class="mb-1"><b>Responses to: </b>{{ fallback.type }}</p>
        <div class="container-fluid fallback-messages">
            <div
                v-for="(message, id) of fallback.messages"
                v-bind:key="id"
                @click="edit_id = id"
                class="px-2 py-1 mb-1 row">

                <el-input
                    v-if="edit_id == id"
                    :ref="'fallback-' + id"
                    v-model="messages[id]"
                    @keyup.native.prevent="handleKeyup"
                    @blur.prevent="edit_id = null"
                    @hook:mounted="handleMounted"
                    :autofocus="true"/>

                <template v-else>
                    <span> {{ message }} </span>
                    <span class="ml-auto material-icons pt-1"
                        @click="removePhrase(id)">close</span>
                </template>
            </div>
        </div>

        <el-input
            v-if="messages.length <= 5"
            v-model="newMessage"
            placeholder="New Message"
            @keyup.native.prevent="handleNewMessageKeyup"/>
    </div>
</template>

<script>
export default {
    props: {
        fallback: {
            required: true
        }
    },
    data () {
        return {
            edit_id: null,
            messages: [],
            newMessage: ''
        }
    },
    mounted () {
        this.messages = this.fallback.messages
    },
    watch: {
        messages (value, oldValue) {
            let changedFallback = {
                ...this.fallback
            }
            changedFallback.messages = value

            // Should osly have max 6 lines
            if (changedFallback.messages.length > 6) {
                this.messages = oldValue
                return
            }

            this.$emit('change', changedFallback)
        }
    },
    methods: {
        /**
         * Handle enter and escape keyword events.
         *
         * @param {object} event 
         *
         * @return {void}
         */
        handleKeyup(event) {
            let { key } = event
            switch (key) {
                case 'Escape':
                case 'Enter':
                    this.edit_id = null
            }
        },

        /**
         * Helps to add new message to current stack.
         *
         * @return {void}
         */
        handleNewMessageKeyup(event) {
            let { key } = event
            switch (key) {
                case 'Enter':
                    this.messages.push(this.newMessage)
                    this.newMessage = ''
                    break
                case 'Escape':
                    this.newMessage = ''
            }
        },

        handleMounted() {
            this.$refs['fallback-' + this.edit_id][0].focus()
        },

        /**
         * Remove fallback phrase.
         *
         * @param {integer} id
         * @return {void}
         */
        removePhrase(id) {
            this.messages.splice(id, 1)
        }
    }
}
</script>