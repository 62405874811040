var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.contact
    ? _c(
        "div",
        [
          _c("el-input", {
            ref: "contact_notes",
            attrs: {
              type: "textarea",
              placeholder: "write your contact notes here...",
              "data-testid": "contact-note-input",
              autosize: { minRows: 3, maxRows: 5 },
              disabled:
                !_vm.hasPermissionTo("update contact") || _vm.loading_btn,
            },
            on: { input: _vm.changeNote },
            model: {
              value: _vm.note,
              callback: function ($$v) {
                _vm.note = $$v
              },
              expression: "note",
            },
          }),
          _vm._v(" "),
          _c("div", { staticClass: "pull-right mt-2" }, [
            _c(
              "span",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.loading_btn,
                    expression: "loading_btn",
                  },
                ],
                staticClass: "text-muted",
                attrs: { "data-testid": "contact-note-saving-input" },
              },
              [
                _c(
                  "i",
                  { staticClass: "material-icons loader text-dark-greenish" },
                  [_vm._v("")]
                ),
                _vm._v("\n            saving\n        "),
              ]
            ),
            _vm._v(" "),
            _c(
              "span",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.loading_btn && _vm.loading,
                    expression: "!loading_btn && loading",
                  },
                ],
                staticClass: "text-muted",
                attrs: { "data-testid": "contact-note-autosaved-input" },
              },
              [_vm._v("\n            autosaved\n        ")]
            ),
          ]),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }