var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "digital-clock" } }, [
    _c("span", { staticClass: "time text-md _600" }, [
      _vm._v(_vm._s(_vm.time)),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }