var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.formattedDate,
            expression: "formattedDate",
          },
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.contactAttribute.edit_mode,
            expression: "!contactAttribute.edit_mode",
          },
        ],
        staticClass: "md-input",
        attrs: { "data-testid": "contact-attributes-input", disabled: true },
        domProps: { value: _vm.formattedDate },
        on: {
          input: function ($event) {
            if ($event.target.composing) return
            _vm.formattedDate = $event.target.value
          },
        },
      }),
      _vm._v(" "),
      _c("el-date-picker", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.contactAttribute.edit_mode,
            expression: "contactAttribute.edit_mode",
          },
        ],
        attrs: {
          type: "date",
          placeholder: "Pick a Date",
          format: _vm.datePickerDateFormat,
          "value-format": _vm.datePickerDateFormat,
        },
        on: {
          blur: function ($event) {
            return _vm.$emit(
              "input-set-editable",
              _vm.contactAttribute,
              false,
              _vm.AttributeDictionary.TYPE_DATE_PICKER
            )
          },
          change: _vm.saveDateValue,
        },
        model: {
          value: _vm.defaultDate,
          callback: function ($$v) {
            _vm.defaultDate = $$v
          },
          expression: "defaultDate",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }