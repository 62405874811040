var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { "data-testid": "amc-wizard-wrapper" } }, [
    _c(
      "div",
      { staticClass: "container-fluid" },
      [
        _c(
          "el-steps",
          {
            staticClass: "mb-3",
            attrs: {
              active: _vm.current_step,
              "align-center": "",
              "finish-status": "success",
            },
          },
          [
            _c("el-step", {
              attrs: { title: "Information", "data-testid": "info-step" },
            }),
            _vm._v(" "),
            _c("el-step", {
              attrs: {
                title: "Confirmation",
                "data-testid": "confirmation-step",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _vm.current_step === 0
          ? _c("div", { staticClass: "step-1 row" }, [
              _vm._m(0),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col-12 text-right mt-4" },
                [
                  _c(
                    "el-button",
                    { attrs: { type: "success" }, on: { click: _vm.nextStep } },
                    [_vm._v("Next")]
                  ),
                ],
                1
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.current_step === 1
          ? _c("div", { staticClass: "step-2 row" }, [
              _vm._m(1),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col-12 d-flex justify-content-between mt-4" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "info" },
                      on: { click: _vm.previousStep },
                    },
                    [_vm._v("Back")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "success" },
                      on: { click: _vm.confirmAndSubmit },
                    },
                    [_vm._v("Accept and Submit")]
                  ),
                ],
                1
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.current_step === 2
          ? _c("div", { staticClass: "step-3 text-center row" }, [_vm._m(2)])
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-12" }, [
      _c(
        "p",
        {
          staticClass: "form-head font-weight-bold mb-3 description text-wrap",
        },
        [
          _c("b", [
            _vm._v(
              "Do you need hassle-free A2P compliance and fast phone number onboarding with expert support?"
            ),
          ]),
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "dropdown-divider" }),
      _vm._v(" "),
      _c("p", { staticClass: "description text-wrap" }, [
        _c("b", [_vm._v("Aloware Managed Compliance")]),
        _vm._v(
          " is a premium white-glove service that handles your A2P brand and campaign registration, along with seamless phone number onboarding.\n                "
        ),
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "description text-wrap" }, [
        _vm._v(
          "\n                    We will also manage any issues or complaints raised by carriers throughout the registration process.\n                "
        ),
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "description text-wrap" }, [
        _vm._v(
          "\n                    While approval from carriers or regulators is not guaranteed, our goal is to complete the brand and campaign registration within 5 business days and submission of your phone numbers for same-day onboarding.\n                "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-12" }, [
      _c("p", { staticClass: "form-head font-weight-bold mb-3" }, [
        _c("b", [_vm._v("Aloware Managed Compliance - Terms of Service")]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "dropdown-divider" }),
      _vm._v(" "),
      _c("p", { staticClass: "text-wrap" }, [
        _c("b", [_vm._v("Business Requirements:")]),
        _vm._v(" "),
        _c("ul", [
          _c("li", [
            _vm._v(
              "You are required to provide accurate and complete information, including the necessary business documents (eg. EIN, CBN, ASIC, etc.) for the registration process."
            ),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "text-wrap" }, [
        _c("b", [_vm._v("Service Overview:")]),
        _vm._v(" "),
        _c("ul", [
          _c("li", [
            _vm._v(
              "Aloware will handle the registration of your A2P brand and campaign, and the submission of your phone numbers for same-day onboarding."
            ),
          ]),
          _vm._v(" "),
          _c("li", [
            _vm._v(
              "We will address any issues raised by carriers during the process."
            ),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "text-wrap" }, [
        _c("b", [_vm._v("Turnaround Time and No Guarantee of Approval:")]),
        _vm._v(" "),
        _c("ul", [
          _c("li", [
            _vm._v(
              "A2P Brand and Campaign registration typically takes up to 5 business days."
            ),
          ]),
          _vm._v(" "),
          _c("li", [
            _vm._v("Phone numbers will be submitted for same-day onboarding."),
          ]),
          _vm._v(" "),
          _c("li", [
            _vm._v(
              "While we aim for timely completion, Aloware only facilitates the registration but cannot guarantee acceptance by carriers or regulators."
            ),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "text-wrap" }, [
        _c("b", [_vm._v("Cost of Service:")]),
        _vm._v(" "),
        _c("ul", [
          _c("li", [
            _vm._v(
              "The Aloware Managed Compliance service is currently offered at no cost."
            ),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "text-wrap" }, [
        _vm._v("\n                    By clicking "),
        _c("b", [_vm._v('"Accept and Submit"')]),
        _vm._v(
          ", you agree to these terms and allow Aloware to proceed with your registration.\n                "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-12" }, [
      _c("p", { staticClass: "text-wrap" }, [
        _c("b", [
          _vm._v(
            "Your order for Aloware Managed Compliance has been successfully submitted!"
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "text-wrap" }, [
        _vm._v(
          "Please make sure your business information and required documents "
        ),
        _c("br"),
        _vm._v(" have been provided via the registration form."),
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "text-wrap" }, [
        _vm._v(
          "Our team will reach out to you within 1-2 business days. Thank you!"
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }