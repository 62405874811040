var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm._m(0),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "col-12 mt-2" },
      [
        _c("p", { staticClass: "mb-0 font-weight-bold" }, [_vm._v("Name:")]),
        _vm._v(" "),
        _c("el-input", {
          attrs: { placeholder: "Type name here.", rows: 3 },
          model: {
            value: _vm.nodeData.name,
            callback: function ($$v) {
              _vm.$set(_vm.nodeData, "name", $$v)
            },
            expression: "nodeData.name",
          },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "col-12 mt-4" },
      [
        _c("p", { staticClass: "mb-0 font-weight-bold" }, [
          _vm._v("Custom Property:"),
        ]),
        _vm._v(" "),
        _c("el-input", {
          attrs: { rows: 3 },
          model: {
            value: _vm.nodeData.aloware_name,
            callback: function ($$v) {
              _vm.$set(_vm.nodeData, "aloware_name", $$v)
            },
            expression: "nodeData.aloware_name",
          },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "col-12 mt-4" },
      [
        _c("p", { staticClass: "mb-0 font-weight-bold" }, [_vm._v("Type:")]),
        _vm._v(" "),
        _c(
          "el-select",
          {
            attrs: { placeholder: "Select data type" },
            model: {
              value: _vm.nodeData.type,
              callback: function ($$v) {
                _vm.$set(_vm.nodeData, "type", $$v)
              },
              expression: "nodeData.type",
            },
          },
          _vm._l(_vm.data_types, function (data_type) {
            return _c("el-option", {
              key: data_type,
              attrs: { label: data_type, value: data_type },
            })
          }),
          1
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "col-12 mt-4" },
      [
        _c("p", { staticClass: "mb-0 font-weight-bold" }, [_vm._v("Message:")]),
        _vm._v(" "),
        _c("el-input", {
          attrs: {
            type: "textarea",
            placeholder: "Type the escalation message here.",
            rows: 3,
          },
          model: {
            value: _vm.nodeData.message,
            callback: function ($$v) {
              _vm.$set(_vm.nodeData, "message", $$v)
            },
            expression: "nodeData.message",
          },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "col-12 text-right" },
      [
        _c(
          "el-tooltip",
          { attrs: { content: "Add variable", placement: "top" } },
          [
            _c("Variables", {
              on: { "variable-selected": _vm.variableSelected },
            }),
          ],
          1
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "col-12 mt-2" },
      [
        _c("Fallback", {
          ref: "fallback",
          attrs: { fallbacks: _vm.fallbacks },
          on: {
            "update:fallbacks": function ($event) {
              _vm.fallbacks = $event
            },
            change: _vm.updateFallback,
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-12" }, [
      _c("p", { staticClass: "font-weight-bold side-panel-title" }, [
        _vm._v("Store Input Panel"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }