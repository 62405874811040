<template>
    <el-tooltip
        class="item"
        effect="dark"
        :content="data.parameter ?? 'parameter'"
        :append-to="'#' + nodeId"
        :disabled="shouldHideTooltip"
        placement="bottom">

        <node-template :id="nodeId">
            <template v-slot:header>
                <span>Condition</span>
            </template>

            <div class="row-inline mt-2">
                <div class="col-12 text-center">
                    <span><b>Field:</b> {{ data.parameter }}</span>
                </div>
            </div>

            <div class="row-inline mt-2">
                <div class="col-12 text-center">
                    <span><b>Operator:</b> {{ CONDITION_OPERATOR_TYPES[data.operator_type] ? CONDITION_OPERATOR_TYPES[data.operator_type].label : null }}</span>
                </div>
            </div>

            <div class="row-inline mt-2">
                <div class="col-12 text-center">
                    <span><b>Value:</b> {{ data.value }}</span>
                </div>
            </div>
            <hr>

            <div class="multiple">
                <div class="panel">
                    True
                    <div :class="`link output_${start_id} output_success condition_true`"></div>
                </div>
                <div class="panel">
                    False
                    <div :class="`link output_${start_id + 1} output_fallback condition_false`"></div>
                </div>
            </div>

        </node-template>

    </el-tooltip>
</template>

<script>
import { alohabot_node_mixin  } from '../../../mixins'
import _ from 'lodash'
import CONSTANTS from '../contants'

export default {
    name: 'condition-node',
    mixins: [
        alohabot_node_mixin
    ],
    props:{
        start_id: {
            required: false,
            default: 1
        }
    },
    computed: {

    },
    data () {
        let { CONDITION_OPERATOR_TYPES } = CONSTANTS
        return {
            CONDITION_OPERATOR_TYPES
        }
    },
}
</script>

<style scoped>
.el-tooltip {
    max-width: 300px;
}

.el-tooltip span {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    line-clamp: 2;
    -webkit-box-orient: vertical;
}
</style>

<style>
.el-tooltip__popper {
    max-width: 250px;
}
</style>
