<template>
    <div id="container-user-notifications"
         ref="userNotificationsContainer"
         :style="'top: ' + total_offset + 'px'">
        <!-- Announcement-->
        <header-notification type="info"
                             v-if="shouldShowAnnouncementNotification"
                             :visible="visible"
                             @afterClose="afterCloseAndSaveCookie">
            <div class="d-flex"
                 v-if="announcement && announcement.enabled">
                <div class="align-self-center text-center text-md-left">
                    <strong>
                        <i class="fa fa-bullhorn"></i>
                        {{ announcement.value.message }}
                    </strong>

                    <div>
                        <a :href="announcement.value.cta_url"
                           class="d-md-none"
                           target="_blank">
                            <el-button type="primary"
                                       size="small"
                                       class="mr-2 px-4 mt-2"
                                       round>
                                <strong>{{ announcement.value.cta_text }}</strong>
                            </el-button>
                        </a>
                    </div>
                </div>
                <div class="ml-auto align-self-center d-none d-md-block"
                     v-if="announcement.value.cta_url != null && announcement.value.cta_text != null">
                    <a :href="announcement.value.cta_url"
                       target="_blank">
                        <el-button type="primary"
                                   size="small"
                                   class="mr-2 px-4"
                                   round>
                            <strong>{{ announcement.value.cta_text }}</strong>
                        </el-button>
                    </a>
                </div>
            </div>
        </header-notification>

        <!-- Diagnosis-->
        <header-notification :type="is10DlcError || isTollFreeError ? '10dlc-warning' : 'danger'"
                             v-if="shouldShowDiagnosisNotification"
                             :visible="show_diagnosis"
                             @afterClose="afterClose">
            <div class="d-flex"
                 v-if="diagnosis.length > 0">
                <div class="align-self-center flex-grow-1">
                    <strong>
                        <i class="fa fa-exclamation-triangle"></i>
                        {{ firstDiagnosis }}
                        <template v-if="secondDiagnosis">
                            <br>
                            {{ secondDiagnosis }}
                        </template>
                        <template v-if="thirdDiagnosis">
                            <br>
                            {{ thirdDiagnosis }}
                        </template>
                    </strong>
                    <a v-if="link && link.learn_more_href"
                       target="_blank"
                       class="text-u-l"
                       @click="finishRegistration(link.learn_more_href)">
                        {{ link.learn_more_title ?? 'Learn more' }}
                    </a>
                </div>
                <div class="ml-auto align-self-center"
                     v-if="!loading_whitelabel && !statics.whitelabel">
                    <div v-if="!link && issue_codes.includes(CompanyIssues.ISSUE_ACTIVE_DUNNING) && hasPermissionTo('see chargebee portal')">
                        <el-button type="danger"
                                   size="small"
                                   class="mr-2"
                                   round
                                   @click="chargebeePortal">
                            <strong>Manage Payment</strong>
                        </el-button>
                    </div>

                    <router-link v-if="link && !link.external"
                                 :to="link.href">
                        <el-button
                                type="primary"
                                size="mini"
                                class="default-app-button">
                            {{ link.title }}
                        </el-button>
                    </router-link>

                    <a v-if="link && link.external"
                       target="_blank"
                       @click="finishRegistration(link.href)">
                        <el-button
                                type="primary"
                                size="mini"
                                class="default-app-button">
                            {{ link.title }}
                        </el-button>
                    </a>
                </div>
            </div>
        </header-notification>

        <!-- Trial-->
        <header-notification :visible="isTrialNotificationVisible"
                             type="info"
                             @afterClose="afterClose">
            <div class="d-flex">
                <div class="align-self-center text-center text-md-left flex-grow-1">
                    <strong v-if="current_company && isTrialStatusActive">
                        Welcome {{ auth.user.profile.first_name }}, you have {{ trialRemainingDays }}
                        day{{ trialRemainingDays === 1 ? '' : 's' }} left until your {{ current_company.trial_days }}-day
                        trial account expires.
                    </strong>
                    <strong v-if="isTrialExpired">
                        <i class="fa fa-exclamation-triangle"></i> {{ auth.user.profile.first_name }}, your {{ current_company.trial_days }}-day trial period has ended.
                    </strong>
                </div>

                <div class="ml-auto d-none d-md-block">
                    <div class="mr-2 d-none d-lg-flex align-items-center"
                                     v-if="!isWhitelabelAccount">
                        <video-modal ref="videoModal"
                                     title="📞 Welcome to Aloware Talk!"
                                     cookie-name="welcome"
                                     notes="🔥 Ignite your communication game with <strong>Aloware Talk!</strong> </br></br> 📞 Dive into seamless conversations, build stronger connections, and make every word count. </br></br> Amplify your talk experience now! 💥🔊"
                                     videoUrl="https://www.youtube.com/embed/6CAkvtjJMBQ?si=_4uvXnXGqEj7Ntxr"
                                     learnMoreLink="https://support.aloware.com/en/articles/9034164-logging-in-to-aloware-talk-a-step-by-step-guide-for-agents"
                                     :should-show-in-first-visit="true"
                                     :has-activator-button="true"
                                     :use-custom-activator-attr="true"
                                     :custom-activator-attr="show_welcome_video"
                                     v-if="!show_cancelled_account && !show_trial_expired && !show_is_risky_user && !show_needs_phone_verification"
                                     @close-modal="closeWatchGuideVideo">
                            <template v-slot:activator>
                                <div class="button-index q-mr-lg demo--button"
                                     @click="openWatchGuideVideo">
                                    <img src="/assets/images/icons/film.svg"/>
                                    <a target="_blank"
                                       @click="openWatchGuideVideo">
                                        Watch Guide Video
                                    </a>
                                </div>
                            </template>
                        </video-modal>
                        <div class="button-index q-mr-lg demo--button"
                             v-if="!isResold"
                             @click="bookDemo">
                            <img src="/assets/images/icons/calendar.svg"/>
                            <a target="_blank"
                                @click="bookDemo">
                                Book a Demo
                            </a>
                        </div>

                        <el-button type="primary"
                                   size="small"
                                   class="mr-2 px-4 d-none d-md-inline-block"
                                   round
                                    v-if="shouldShowUnlockTrialExperienceButton"
                                   @click="onOpenFinishRegistration">
                            <strong>Unlock trial experience</strong>
                        </el-button>

                        <el-button type="primary"
                                    size="small"
                                    class="mr-2 px-4 d-none d-md-inline-block"
                                    round
                                    v-else-if="shouldShowRegistrationReviewButton"
                                    @click="onOpenRegistrationInReview">
                            <strong>Registration in Review</strong>
                        </el-button>

                        <el-popover v-if="!enabledToSkipTrialAndSubscribe()"
                                    ref="trial-skip-popover"
                                    placement="top"
                                    width="200"
                                    class="custom-popover"
                                    popper-class="btn-primary"
                                    trigger="hover">
                            <custom-message-display :config="customMessage('billing.subscribe')" />
                        </el-popover>
                        <el-button type="primary"
                                   size="small"
                                   class="mr-2 px-4 d-none d-md-inline-block"
                                   v-if="isIndependentOrNotResold && hasPermissionTo('see chargebee portal')"
                                   :disabled="isChargeBeeSessionOrSubscriptionDisabled"
                                   v-popover:trial-skip-popover
                                   round
                                   @click="chargebeePaymentSources">
                            <i class="material-icons">credit_card_outline</i>
                            <strong v-if="!cardAdded">Add Payment Method</strong>
                            <strong v-else>Manage Payment Methods</strong>
                        </el-button>

                    </div>
                </div>
            </div>
        </header-notification>

        <!-- Simpsocial Migration -->
        <header-notification type="info"
                             :visible="showSimpsocialMigrationNotification"
                             @afterClose="afterCloseShowSimpsocialMigrationNotification">
            <div class="d-flex">
                <div class="align-self-center text-center text-md-left flex-grow-1">
                    <strong>
                        Over the coming weeks, PowerDialer, Calendar, Contacts, Broadcast, and the Phone will move to the new 'Talk' page. For questions, contact <a class="modal-link" href="mailto:training@simpsocial.com">training@simpsocial.com</a>
                    </strong>
                </div>
            </div>
        </header-notification>

        <el-dialog title=""
                   width="40%"
                   :visible.sync="show_is_risky_user"
                   :modal-append-to-body="true"
                   :append-to-body="true"
                   :show-close="isAdminImpersonating"
                   :before-close="beforeDialogClose"
                   v-if="show_is_risky_user && !app_loading">
            <div class="container-trial-modal">
                <p>
                    {{ user.first_name }}, it looks like there is something wrong with your account and we flagged you as a
                    risky user because of the following reasons:
                </p>

                <div class="alert alert-warning alert-aloware">
                    <strong>{{ user.risky_reason }}</strong>
                </div>

                <p>To resolve these issues, please add your credit card information.</p>
                <el-button type="success"
                           size="large"
                           class="px-5 mb-4"
                           round
                           @click="chargebeeUpdatePaymentMethod">
                    <strong>Add Credit Card</strong>
                </el-button>
            </div>
        </el-dialog>

        <el-dialog title=""
                   width="40%"
                   :visible.sync="show_trial_expired"
                   :modal-append-to-body="true"
                   :append-to-body="true"
                   :show-close="isAdminImpersonating"
                   :before-close="beforeDialogClose"
                   v-else-if="show_trial_expired && !app_loading">
            <div class="container-trial-modal">
                <h2>Your trial account has expired</h2>
                <i class="fas fa-user-clock"></i>
                <p>
                    {{ auth.user.profile.first_name }}, your trial period has ended, and what an incredible journey it's been! We trust you've discovered the wealth of features and benefits aimed at streamlining your connection with prospects and customers, making every interaction more impactful.
                </p>
                <p>
                    Though your trial has concluded, your path to success doesn't need to end. Dive into our variety of plans and flexible billing options, and upgrade today. Ensure you never miss a lead and your customers have uninterrupted access to you! Should you have any inquiries or require additional support, remember, our team is just a message away.
                </p>

                <el-button v-if="current_company.sales_rep"
                           type="success"
                           size="large"
                           class="px-5 mb-4"
                           round
                           @click="emailSalesRep">
                    <strong>Upgrade Now</strong>
                </el-button>
                <el-button v-if="!current_company.sales_rep"
                           type="success"
                           size="large"
                           class="px-5 mb-4"
                           round
                           @click="openDemo">
                    <strong>Upgrade Now</strong>
                </el-button>
                <el-button type="danger"
                           size="large"
                           class="px-5 mb-4"
                           round
                           @click="logout">
                    <strong>{{ is_impersonated ? 'Stop impersonating' : 'Sign out' }}</strong>
                </el-button>

                <div v-if="current_company.sales_rep">
                    <p class="mb-1" v-if="current_company.sales_rep">Sales Contact:
                        <strong class="mb-1 text-dark">
                            {{ this.current_company.sales_rep.name }}
                        </strong>
                    </p>
                    <p class="mb-1" v-if="current_company.sales_rep">Email address:
                        <strong class="mb-1 text-dark">
                            <a :href="'mailto:' + this.current_company.sales_rep.email"
                               target="_blank">
                                {{ this.current_company.sales_rep.email }}
                            </a>
                        </strong>
                    </p>
                </div>

                <p class="mb-0">Contact Support:</p>
                <p class="mb-1">
                    <strong class="text-success">
                        <a class="tel:(855) 256-2001">
                            <i class="fa fa-phone-alt"></i>
                            (855) 256-2001
                        </a>
                        <span class="mx-2 text-dark">|</span>
                        <a href="https://aloware.com"
                           target="_blank">
                            <i class="fa fa-globe"></i>
                            Visit Website
                        </a>
                    </strong>
                </p>
            </div>
        </el-dialog>

        <el-dialog title=""
                   width="40%"
                   :visible.sync="show_cancelled_account"
                   :modal-append-to-body="true"
                   :append-to-body="true"
                   :show-close="isAdminImpersonating"
                   :before-close="beforeDialogClose"
                   v-else-if="show_cancelled_account && !app_loading">
            <div class="container-trial-modal">
                <h2>Your subscription has been cancelled.</h2>
                <p>
                    {{ auth.user.profile.first_name }}, even though you have canceled your subscription, that doesn't mean this has to be the end of our journey together. If you'd like to renew your subscription and continue enjoying Aloware, we've made the process easy for you.
                </p>
                <p>
                    If there's anything we can assist with or if you have feedback that could help us improve, we're all ears. You can reach out to your CSM, {{ current_company.csm_rep  ? current_company.csm_rep.name + ',' : '' }} directly through {{ current_company.csm_rep?.email ?? 'sales@aloware.com' }}.
                </p>
                <p>
                    We value you as a customer and would love to serve you again!
                </p>

                <el-button type="success"
                           size="large"
                           class="px-5 mb-4"
                           round
                           @click="emailCsmRep">
                    <strong>Restore Account</strong>
                </el-button>
                <el-button type="danger"
                           size="large"
                           class="px-5 mb-4"
                           round
                           @click="logout">
                    <strong>{{ is_impersonated ? 'Stop impersonating' : 'Sign out' }}</strong>
                </el-button>
            </div>
        </el-dialog>

        <el-dialog title=""
                   width="40%"
                   :visible.sync="show_needs_phone_verification"
                   :modal-append-to-body="true"
                   :append-to-body="true"
                   :show-close="isAdminImpersonating"
                   :before-close="beforeDialogClose"
                   v-else-if="show_needs_phone_verification && !app_loading">
            <div class="container-trial-modal">
                <h2>Verify Phone</h2>
                <p>
                    {{ auth.user.profile.first_name }}, we sent you a phone verification code to {{ auth.user.profile.phone_number | fixPhone }}. Please input the code below
                </p>
                <div class="mb-5">
                    <security-code v-model="token"
                                   ref="security_code"
                                   class="mb-2"
                                   @completed="verifyPhone">
                    </security-code>
                    <small v-if="phone_verification_message.length > 0"
                           :class="'text-' + phone_verification_message_type">
                        {{ phone_verification_message }}
                    </small>
                </div>

                <p class="mb-1">If you did not receive the code. You can resend a new one</p>
                <el-button @click="requestPhoneVerification"
                           v-loading="resending_phone_validation"
                           :disabled="phone_verification_request_sent">
                    Request Verification Code
                </el-button>
            </div>
        </el-dialog>
        <kyc-fill-dialog :show="shouldShowKycFillDialog"/>
        <kyc-reload-dialog :show="shouldShowKycReloadDialog" />
    </div>
</template>

<script>
import auth from '../auth'
import HeaderNotification from '../components/notifications/header-notifications'
import {mapActions, mapState, mapGetters} from 'vuex'
import {
    acl_mixin,
    chargebee_mixin,
    styling_mixin,
    kyc_mixin,
    announce_kit_mixin
} from "../mixins"
import * as TrialStatus from "../constants/trial-account-status"
import * as CompanyIssues from "../constants/company-issues"
import VideoModal from "../components/video-modal.vue"
import kycFillDialog from '../components/kyc-fill-dialog.vue'
import KycReloadDialog from '../components/kyc-reload-dialog.vue'
import CustomMessageDisplay from './kyc/custom-message-display.vue'
import VueCookies from 'vue-cookies'

export default {
    mixins: [
        styling_mixin,
        chargebee_mixin,
        acl_mixin,
        kyc_mixin,
        announce_kit_mixin
    ],

    components: {
        HeaderNotification,
        VideoModal,
        kycFillDialog,
        KycReloadDialog,
        CustomMessageDisplay
    },

    data() {
        return {
            auth: auth,
            visible: false,
            diagnosis: [],
            issue_codes: [],
            link: null,
            show_diagnosis: false,
            current_route_name: this.$route.name,
            announcement: {
                enabled: false,
                is_fully_enabled: false
            },
            user: auth.user.profile,
            show: true,
            token: '',
            resending_phone_validation: false,
            phone_verification_message_type: 'success',
            phone_verification_message: 'Input the validation code',
            phone_verification_request_sent: false,
            statics: {
                logo: null,
                logo_inverse: null,
                logo_square: null,
                logo_square_inverse: null,
                host: null,
                referer: null,
                name: null,
                domain: null,
                whitelabel: false,
                path: null
            },
            loading_whitelabel: true,
            is_impersonated: localStorage.getItem('impersonate'),
            is_admin_impersonating: localStorage.getItem('is_admin_impersonating'),
            show_is_risky_user: false,
            show_trial_expired: false,
            show_cancelled_account: false,
            show_needs_phone_verification: false,
            show_welcome_video: false,
            TrialStatus,
            CompanyIssues,
            cookieName: 'simpsocial-migration',
        }
    },

    computed: {
        ...mapState([
            'app_loading',
            'showedKycDialog',
            'showedKycReloadDialog',
            'isIntroVideoVisible',
        ]),

        ...mapState('cache', ['current_company', 'is_simpsocial_migration_banner_visible']),

        ...mapGetters('cache', ['isNotSimpSocial', 'isModGen', 'isSimpSocial']),

        shouldShow() {
            if (this.current_route_name) {
                if (['Dialer Widget (API)', 'Dialer Widget', 'Hubspot Call Extension'].includes(this.current_route_name)) {
                    return false
                }
            }

            // Don't show announcement on resold accounts
            return (this.current_company && !this.current_company.reseller_id)
        },

        shouldShowDiagnosis() {
            if (this.current_company && this.current_company.reseller_id == 357) {
                if (this.current_route_name && ['Broadcasts', 'Bulk Message', 'Bulk RVM'].includes(this.current_route_name)) {
                    return true
                }

                return false
            }

            return true
        },

        firstDiagnosis() {
            if (this.diagnosis.length > 0) {
                return this.diagnosis[0]
            }
            return 'We are having issues with your account and calls might not route at this time. Please contact support as soon as possible.'
        },

        secondDiagnosis() {
            if (this.diagnosis.length > 1) {
                return this.diagnosis[1]
            }
            return ''
        },

        thirdDiagnosis() {
            if (this.diagnosis.length > 2) {
                return this.diagnosis[2]
            }
            return ''
        },

        isTollFreeError() {
            return this.issue_codes.includes(CompanyIssues.ISSUE_TOLL_FREE_SMS)
        },

        is10DlcError() {
            return this.issue_codes.includes(CompanyIssues.ISSUE_A2P_10DLC_NOT_REGISTERED) ||
                this.issue_codes.includes(CompanyIssues.ISSUE_A2P_10DLC_INCOMPLETE) ||
                this.issue_codes.includes(CompanyIssues.ISSUE_BRAND_REGISTRATION_FAILED) ||
                this.issue_codes.includes(CompanyIssues.ISSUE_BRAND_NOT_REGISTERED) ||
                this.issue_codes.includes(CompanyIssues.ISSUE_BRAND_REGISTRATION_FAILED_TCR_ZERO)
        },

        needsPhoneVerification() {
            return this.current_company && this.current_company.is_trial && auth.user.profile.phone_number_verified_at === null
        },

        isTrialExpired() {
            return [TrialStatus.TRIAL_STATUS_EXPIRED, TrialStatus.TRIAL_STATUS_PURGE_ELIGIBLE].includes(this.current_company?.trial_status)
        },

        isCancelledAccount() {
            return this.current_company?.subscription?.status === 'cancelled'
        },

        isRiskyUser() {
            return (this.current_company && this.current_company.is_trial && this.current_company.trial_status === TrialStatus.TRIAL_STATUS_ACTIVE && this.user && this.user.is_risky) ? true : false
        },

        isAdminImpersonating() {
            return this.is_impersonated === 'true' && this.is_admin_impersonating === 'true'
        },

        trialRemainingDays() {
            const remaining_days = moment(this.current_company.trial_ends_at).diff(moment(), 'days')

            return this.current_company.trial_remaining_days || remaining_days
        },

        isTrialStatusActive() {
            const remaining_days = moment(this.current_company.trial_ends_at).diff(moment(), 'days')

            if (!this.current_company.trial_status && remaining_days > 0) {
                return true
            }

            const isStatusActive = this.current_company && this.current_company.trial_status === TrialStatus.TRIAL_STATUS_ACTIVE

            return isStatusActive
        },

        shouldShowKycFillDialog () {
            return !this.showedKycDialog &&
                this.isIntroVideoVisible === null &&
                !this.isKYCFilled &&
                !this.app_loading &&
                !this.isTrialExpired &&
                !this.isCancelledAccount &&
                !this.isComplianceTabOpen
        },

        shouldShowUnlockTrialExperienceButton () {
            return this.user?.company?.is_trial &&
                !this.isKYCFilled &&
                !this.isTrialExpired &&
                !this.isCancelledAccount
        },

        isComplianceTabOpen () {
            return this.$route.query.tab === 'compliance'
        },

        shouldShowKycReloadDialog () {
            return !this.app_loading &&
                    this.showedKycReloadDialog &&
                    this.user?.company?.is_trial
        },

        mailToCsmRep () {
            return 'mailto:' + (this.current_company.csm_rep?.email || 'sales@aloware.com');
        },

        shouldShowRegistrationReviewButton() {
            return this.isCompanyKYC && this.isKYCFilledNotReviewed
        },

        parsedCookieName () {
            return `${this.cookieName}-${this.auth.user.profile.id}`
        },

        shouldShowAnnouncementNotification () {
            return !this.is_simpsocial_migration_banner_visible && this.shouldShow
        },

        shouldShowDiagnosisNotification () {
            return !this.is_simpsocial_migration_banner_visible && this.diagnosis.length > 0 && this.shouldShowDiagnosis
        },

        isTrialNotificationVisible () {
            return !this.is_simpsocial_migration_banner_visible && this.current_company && this.current_company.is_trial && this.current_company.trial_status !== TrialStatus.TRIAL_STATUS_SUCCESS
        },

        showSimpsocialMigrationNotification () {
            this.$cookies = VueCookies
            let show = true

            if (!this.isSimpSocial || (this.isSimpSocial && this.$cookies.get(this.parsedCookieName) !== null && this.$cookies.get(this.parsedCookieName) !== 'null' )) {
                show = false
                this.setIsSimpsocialMigrationBannerVisible(false)
            }

            return show
        },
    },

    created() {
        this.getStatics()
        this.prepareChargeBee()
        this.show_is_risky_user = this.isRiskyUser
        this.show_needs_phone_verification = this.needsPhoneVerification

        VueEvent.listen('kyc_status_updated', company => {
            if (this.isTrialKYC && this.isNotSimpSocial && !this.isModGen) {
                this.setShowedKycReloadDialog(true)
            }
        })
    },

    mounted() {
        this.runDiagnosis()
        this.show_cancelled_account = this.isCancelledAccount && !this.isWhitelabelAccount && !this.isTrialExpired
        this.show_trial_expired = this.isTrialExpired
    },

    methods: {
        ...mapActions('cache', ['setIsSimpsocialMigrationBannerVisible']),

        openDemo() {
            window.open("https://meetings.hubspot.com/alwr/aloware-demo", '_blank')
        },

        emailSalesRep() {
            window.open("mailto:" + this.current_company.sales_rep.email, '_blank')
        },

        emailCsmRep () {
            window.open(this.mailToCsmRep, '_blank')
        },

        beforeDialogClose(done) {
            if (this.isAdminImpersonating) {
                done()
            }
        },

        getStatics() {
            this.loading_whitelabel = true
            axios.get('/get-statics').then(res => {
                this.statics = res.data
                this.loading_whitelabel = false
            }).catch(err => {
                console.log(err)
                this.$root.handleErrors(err.response)
                this.loading_whitelabel = false
            })
        },

        runDiagnosis() {
            axios.get(`/api/v1/status/diagnosis`).then(res => {
                this.issue_codes = res.data.issue_codes
                this.diagnosis = res.data.issues
                this.link = res.data.link
                this.show_diagnosis = res.data.has_issues
                // if account has reached max negative balance and auto recharge is not set
                if (this.issue_codes.length && this.issue_codes.includes(CompanyIssues.ISSUE_MAX_NEGATIVE_BALANCE) && !this.auth.user.profile.usage.auto_recharge && !this.isAdminImpersonating) {
                    this.$router.push({name: 'Account', query: {tab: 'billing'}}).catch(err => {
                    })
                }
            }).then(() => {
                this.adjustOffset()
            })
        },

        /**
         * +--------------------------------------------------------------------------------------------------------
         * | Trial Header Functions
         * +--------------------------------------------------------------------------------------------------------
         * | The code below is responsible for trial header button functions
         */

        watchVideo() {
            this.$root.$refs.appHeader.showTutorial()
        },

        bookDemo() {
            window.open('https://meetings.hubspot.com/alwr/aloware-demo', '_blank')
        },

        finishRegistration(link) {
            if (this.isCompanyKYC) {
                return this.onOpenFinishRegistration()
            }

            return window.open(link, '_blank')
        },

        onOpenRegistrationInReview () {
            this.$router.push({name: 'Account', query: {tab: 'compliance'}}).catch(err => {})
        },

        /**
         * +--------------------------------------------------------------------------------------------------------
         * | Phone Verification Code
         * +--------------------------------------------------------------------------------------------------------
         * | The code below is responsible for verifying and resend phone verification codes
         */

        verifyPhone() {
            axios.post(`/api/v1/phone-verification/verify/${this.token}`).then(res => {
                this.auth.user.profile.phone_number_verified_at = moment()
                this.$notify({
                    offset: 95,
                    title: 'Successful Verification',
                    message: 'Your phone number has been verified.',
                    type: 'success'
                })
            }).catch(err => {
                this.phone_verification_message = err.response.data.message
                this.phone_verification_message_type = 'danger'
            })
        },

        requestPhoneVerification() {
            this.resending_phone_validation = true
            axios.post(`/api/v1/phone-verification/`).then(res => {
                this.phone_verification_message = res.data.message
                this.phone_verification_message_type = 'success'
                this.resending_phone_validation = false
                this.phone_verification_request_sent = true
            }).catch(err => {

            })
        },

        adjustOffset() {
            setTimeout(function () {
                window.dispatchEvent(new Event('customResize'))
            }, 1000)
        },

        afterClose() {
            this.adjustOffset()
        },

        afterCloseShowSimpsocialMigrationNotification() {
            this.$cookies.set(this.parsedCookieName, 'viewed', 365)
            this.setIsSimpsocialMigrationBannerVisible(false)
        },

        afterCloseAndSaveCookie() {
            this.setCookie('closed_announcement', this.announcement.starts_at, 365)
            this.adjustOffset()
        },

        ...mapActions(['setTopOffset', 'setShowedKycReloadDialog']),

        setCookie(cname, cvalue, exdays) {
            let d = new Date()
            d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000))
            let expires = "expires=" + d.toUTCString()
            document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/"
        },

        getCookie(cname) {
            let name = cname + "="
            let ca = document.cookie.split(';')
            for (let i = 0; i < ca.length; i++) {
                let c = ca[i]
                while (c.charAt(0) == ' ') {
                    c = c.substring(1)
                }
                if (c.indexOf(name) == 0) {
                    return c.substring(name.length, c.length)
                }
            }
            return ""
        },

        verifyIfShouldShowDialogs() {
            this.show_cancelled_account = this.isCancelledAccount && !this.isWhitelabelAccount && !this.isTrialExpired
            this.show_trial_expired = this.isTrialExpired
        },

        openWatchGuideVideo () {
            this.show_welcome_video = true
        },

        closeWatchGuideVideo () {
            this.show_welcome_video = false
        }
    },

    watch: {
        '$route'(to, from) {
            this.current_route_name = to.name
            // if account has reached max negative balance and auto recharge is not set
            if (this.issue_codes.includes(CompanyIssues.ISSUE_MAX_NEGATIVE_BALANCE) && !this.auth.user.profile.usage.auto_recharge && this.current_route_name != 'Account' && !this.isAdminImpersonating) {
                this.$router.push({name: 'Account', query: {tab: 'billing'}}).catch(err => {
                })
            }
        },

        'isRiskyUser': function () {
            this.show_is_risky_user = this.isRiskyUser
        },

        'needsPhoneVerification': function () {
            this.show_needs_phone_verification = this.needsPhoneVerification
        },

        isTrialExpired: function () {
            this.verifyIfShouldShowDialogs()
        },

        isCancelledAccount: function () {
            this.verifyIfShouldShowDialogs()
        }
    }
}
</script>
