<template>
    <div>
        <span
            v-for="(phrase, id) in phrases"
            :key="id"
            class="mb-2">
            <el-tag
                class="mr-2 mb-2 font-weight-bold"
                style="font-size:14px!important"
                :type="id == editPhrase ? 'warning' : ''"
                :closable="phrases.length > 1 && !disabled"
                :disable-transitions="false"
                :disabled="disabled"
                @close="handlePhraseDelete(id)"
                @click="handleClickTag(id)">
                {{ phrase }}
            </el-tag>
        </span>
        <br/>
        <el-input
            v-if="(addPhrase || editPhrase != null) && !disabled"
            v-model="input"
            class="ml-1 mt-2"
            placeholder="Type the phrase and press enter to submit"
            ref="phrase-input"
            size="small"
            :disabled="disabled"
            @hook:mounted="focusInput"
            @keyup.native.prevent="event => handleKeyup(event, editPhrase)"
            @blur="handleInputCancel"/>

        <el-button
            v-else-if="!disabled"
            size="small"
            round
            class="mt-2"
            :disabled="disabled"
            @click="addPhrase = true">
            + Add Training Phrase
        </el-button>
    </div>
</template>

<script>
export default {
    props: {
        phrases: {
            type: Array,
            default: () => ([])
        },
        disabled: {
            required: false,
            default: false
        }
    },
    data () {
        return {
            editPhrase: null,
            input: '',
            editInput: '',
            shouldBlur: true,
            addPhrase: false
        }
    },
    methods: {
        /**
         * Handles adding a new Training Phrase.
         *
         * @return {void}
         */
        handleInputConfirm(index = null) {
            // Sanity check.
            if (this.input === '') {
                return
            }

            // If it was editing a phrase.
            if(index !== null) {
                this.phrases[index] = this.input
                this.$emit('updated')
            } else {
                this.phrases.push(this.input)
                this.$emit('created')
            }
            this.clearInput()
        },

        /**
         * Handles cancel adding a new Training Phrase.
         *
         * @return {void}
         */
        handleInputCancel() {
            this.clearInput()
        },

        /**
         * Clears the input.
         *
         * @return {void}
         */
        clearInput() {
            this.editInput = ''
            this.input = ''
            this.editPhrase = null
            this.addPhrase = false
        },

        /**
         * Removes a phrase when clicking on the x button
         *
         * @return {void}
         */
        handlePhraseDelete(index) {
            this.phrases = this.phrases.splice(index, 1)

            this.$emit('deleted')
        },

        /**
         * Sets the phrase to edit.
         *
         * @return {void}
         */
        handleClickTag(index) {
            // Don't do anything is it's disabled.
            if (this.disabled) {
                console.log('its disabled')
                return
            }
            this.input = '' + this.phrases[index]
            this.editPhrase = index
        },

        /**
         * Determines which key was released and adds or edits a phrase.
         *
         * @return {void}
         */
        handleKeyup(event, index = null) {
            let { key, target } = event
            switch (key) {
                case 'Escape':
                    this.addPhrase = false
                    this.handleInputCancel()
                    break
                case 'Enter':
                    this.addPhrase = false
                    this.handleInputConfirm(index)
                    break
            }
        },

        /**
         * Focus the phrase input whenever needed.
         *
         * @return {void}
         */
        focusInput() {
            setTimeout(() => {
                this.$refs['phrase-input'].focus()
            }, 200)
        }
    },
    watch: {
        /**
         * Takes the index of the phrase to edit.
         *
         * @param {integer} value
         *
         * @return {void}
         */
        editPhrase(value) {
            if (value) {
                this.focusInput()
            }
        },

        /**
         * Determines which key was released and adds or edits a phrase.
         *
         * @param {boolean} value
         *
         * @return {void}
         */
        addPhrase(value) {
            if (value) {
                this.focusInput()
            }
        }
    }
}
</script>