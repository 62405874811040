<template>
  <div class="container-fluid lato-regular">
    <div class="d-flex flex-wrap align-items-stretch justify-content-between text-center">
      <div class="section-margin col-md-12" v-if="showMainHeader">
        <h1 class="">SMS Segment Calculator</h1>
        <p>Calculate the length of the message segment and the character count of your text message.</p>
      </div>
      <div class="flex-fill text-center section-margin col-md-6">
        <div class="subtitle-text subtitle-text-margin lato-bold pull-left">
          <label for="message">Original Message</label>
        </div>
        <textarea v-model="message.body" class="form-control text-monospace p-4 message-container"
                  style="border-radius: 0.625rem;" id="message"></textarea>
        <div class="pull-right">{{ message.bodyLength }}</div>
      </div>
      <div class="flex-fill text-center section-margin col-md-6">
        <div style="padding-bottom: 1.25rem">
          <div class="subtitle-text subtitle-text-margin lato-bold pull-left">
            <label for="message">Message Detail</label>
          </div>
          <div class="table-responsive card details-table-border" style="border-radius: 0.625rem;">
            <table class="table overflow-hidden bg-blue-16" style="margin-bottom: 0px;">
              <thead class="">
              <tr class="">
                <th class="mobile-table-header text-white text-center align-middle details-table-border">
                  Segments
                </th>
                <th class="mobile-table-header text-white text-center align-middle details-table-border">
                  Message Length
                </th>
                <th class="mobile-table-header text-white text-center align-middle  details-table-border">
                  Max Segment Length
                </th>
                <th class="mobile-table-header text-white text-center align-middle details-table-border">
                  Encoding Type
                </th>
              </tr>
              </thead>
              <tbody class="">
              <tr class="bg-white">
                <td class="font-weight-bold text-center align-middle details-table-border border rounded-bottom-left">
                  {{ numberOfSegments }}
                </td>
                <td class="font-weight-bold text-center align-middle details-table-border border"
                    style="border-left-width: 2px !important;">
                  {{ numberOfSegments * limitOfCharactersPerSegment }}
                </td>
                <td class="font-weight-bold text-center align-middle details-table-border border "
                    style="border-left-width: 2px !important;">
                  {{ limitOfCharactersPerSegment }}
                </td>
                <td class="font-weight-bold text-center align-middle details-table-border border rounded-bottom-right"
                    style="border-left-width: 2px !important;">
                  {{ message.smsEncodingType }}
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div style="padding-top: 1.25rem; padding-bottom: 1.25rem">
          <div class="subtitle-text subtitle-text-margin lato-bold pull-left">
            <label for="message">Price Detail</label>
          </div>
          <div class="table-responsive card details-table-border" style="border-radius: 0.625rem;">
            <table class="table overflow-hidden bg-blue-16" style="margin-bottom: 0px;">
              <thead class="">
              <tr class="">
                <th class="mobile-table-header text-white text-center align-middle details-table-border">
                  iPro
                </th>
                <th class="mobile-table-header text-white text-center align-middle details-table-border">
                  uPro
                </th>
                <th class="mobile-table-header text-white text-center align-middle details-table-border">
                  xPro
                </th>
              </tr>
              </thead>
              <tbody class="">
              <tr class="bg-white">
                <td class="font-weight-bold text-center align-middle details-table-border border rounded-bottom-left">
                 {{ iProTotalPrice}}
                </td>
                <td class="font-weight-bold text-center align-middle details-table-border border"
                    style="border-left-width: 2px !important;">
                 {{ uProTotalPrice}}
                </td>
                <td class="font-weight-bold text-center align-middle details-table-border border rounded-bottom-right"
                    style="border-left-width: 2px !important;">
                 {{ xProTotalPrice}}
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <div class="footnote">
            *For toll-free, short code, and international numbers, charges are being calculated accordingly.
          </div>
        </div>
        <div style="padding-top: 1.25rem; padding-bottom: 1.25rem; flex-direction: column; justify-content: flex-start; align-items: center; display: inline-flex">
          <a :href="startTrialButtonUrl" target="_blank" style="text-decoration: none;">
            <div style="padding-top: 0.03125rem; padding-left: 2.5625rem; padding-right: 2.5625rem; background: #0CA855; box-shadow: 0px 0.25rem 0.5625rem rgba(0, 0, 0, 0.05); border-radius: 0.5rem; overflow: hidden; justify-content: flex-start; align-items: center; gap: 0.1875rem; display: inline-flex">
              <div style="text-align: center; color: white; font-size: 1rem; line-height: 3.25rem; word-wrap: break-word">
                Start free trial
              </div>
              <div style="width: 6.25rem; height: 6.25rem; position: relative"></div>
              <div style="justify-content: center; align-items: flex-start; display: flex">
                <div style="justify-content: flex-start; align-items: flex-start; display: flex">
                  <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M10.0837 13.2756C10.0317 13.2756 9.98218 13.2626 9.9353 13.2366C9.88843 13.2105 9.84937 13.1871 9.81812 13.1663C9.7452 13.0829 9.70874 12.984 9.70874 12.8694C9.70874 12.7548 9.7452 12.6558 9.81812 12.5725L13.615 8.79126L9.81812 5.01001C9.7452 4.92668 9.70874 4.82772 9.70874 4.71313C9.70874 4.59855 9.7452 4.49959 9.81812 4.41626C9.90145 4.33293 10.0004 4.29126 10.115 4.29126C10.2296 4.29126 10.3285 4.33293 10.4119 4.41626L14.4587 8.46313C14.49 8.49438 14.516 8.53345 14.5369 8.58032C14.5577 8.6272 14.5681 8.68188 14.5681 8.74438C14.5681 8.79647 14.5577 8.84595 14.5369 8.89282C14.516 8.9397 14.49 8.97876 14.4587 9.01001L10.4119 13.0569C10.3598 13.1611 10.3077 13.2236 10.2556 13.2444C10.2035 13.2652 10.1462 13.2756 10.0837 13.2756ZM14.0369 9.27563H2.83374C2.72957 9.27563 2.63322 9.23657 2.54468 9.15845C2.45614 9.08032 2.41187 8.97355 2.41187 8.83813C2.41187 8.71313 2.44832 8.61157 2.52124 8.53345C2.59416 8.45532 2.69832 8.41626 2.83374 8.41626H14.0369C14.141 8.41626 14.2374 8.45532 14.3259 8.53345C14.4145 8.61157 14.4587 8.71313 14.4587 8.83813C14.4587 8.97355 14.4145 9.08032 14.3259 9.15845C14.2374 9.23657 14.141 9.27563 14.0369 9.27563Z"
                        fill="white"/>
                  </svg>
                </div>
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
    <div class="flex-fill section-margin col-md-12" v-if="showMessageOutput">
      <div class="flex-1">
        <div class="">
          <div class="subtitle-text lato-bold"><label for="message">Encoded Message and Segments</label></div>
          <div class="subtitle-description lato-regular">Each segment will be displayed with a different background
            color.
          </div>
        </div>
        <div class="card message-container overflow-auto">
          <div
              :class="['card-body p-4', message.bodyLength === 0 && 'd-flex align-items-center justify-content-center'  ]">
            <p class="text-monospace text-md text-left" v-if="message.bodyLength">
              <template v-for="(character) in message.characters"><span
                  v-if="shouldPrintConcatenationHeaders(character)"
                  :class="['font-weight-bold character-block', getCharacterBackgroundClass(character,true)]">HEADER</span><span
                  :class="['character-block', getCharacterBackgroundClass(character)]">{{
                  character.visibleChar === '\n' || character.visibleChar === '\r\n' ? '&nbsp' : character.visibleChar
                }}</span></template>
            </p>
            <p class="card-text" v-else>
              No message entered
            </p>
          </div>
        </div>
        <div class="pull-right">{{ message.encodedLength }}</div>
      </div>
    </div>
    <div class="flex-fill section-margin col-md-12" v-if="showWarnings && message.hasLineBreaks">
      <div class="flex-fill">
        <span class="text-xl">Warnings</span>
        <p>
          If the message has line breaks, the web page utility only supports LF style.
          If you insert a CRLF it will be converted to LF.
        </p>
      </div>
    </div>
  </div>
</template>

<script>

import {sms_mixin} from "../../mixins";
import * as SMS from "../../constants/sms";

export default {
  name: 'sms-segment-calculator',

  mixins: [sms_mixin],

  data() {
    return {
      SMS: SMS,
      showMessageOutput: true,
      showMessageOutputHeaders: true,
      showWarnings: true,
      showMainHeader: false,
      selectedPosition: null,
      startTrialButtonUrl: 'https://app.aloware.com/signup',
      iProSegmentPrice: 0.02,
      uProSegmentPrice: 0.015,
      xProSegmentPrice: 0.01,
    }
  },
  watch: {
    'message.body': function (message) {
      this.$_sms_mixin_inspectMessage(message)
    }
  },
  computed: {
    iProTotalPrice() {
      return this.calculateTotalPrice(this.iProSegmentPrice);
    },
    uProTotalPrice() {
      return this.calculateTotalPrice(this.uProSegmentPrice);
    },
    xProTotalPrice() {
      return this.calculateTotalPrice(this.xProSegmentPrice);
    }
  },
  methods: {
    /**
     * Calculates the total price based on the segment price and the number of segments.
     *
     * @param {number} rateSegmentPrice - The price per segment.
     * @returns {string} - The total price formatted as a string with a dollar sign and exactly three decimal places.
     *                     If the number of segments is 0, returns '$0'.
     */
    calculateTotalPrice(rateSegmentPrice) {
      if (this.numberOfSegments === 0) {
        return '$' + 0;
      }
      return '$' + (this.numberOfSegments * rateSegmentPrice).toFixed(3);
    },
    /**
     * Returns the background color class based on the position of the character
     * @returns {string}
     * @param character
     * @param isHeader
     */
    getCharacterBackgroundClass(character, isHeader = false) {

      // Define the color classes
      const colorClasses = ['bg-purple', 'bg-yellow', 'bg-green', 'bg-orange', 'bg-blue'];

      // Return the color class based on the segment number
      const colorClass = colorClasses[character.segmentNumber % 5];

      if (isHeader) {
        return `${colorClass}-3`;
      }
      // Return the color class based on the selected position. If the position is hovered, return the darker color class.
      return [SMS.SMS_ENCODING_TYPE_GSM_7_EXTENSION, SMS.SMS_ENCODING_TYPE_UCS_2].includes(character.smsEncodingType) ? `${colorClass}-7` : `${colorClass}-3`;
    },
    shouldPrintConcatenationHeaders(character) {
      if (!this.showMessageOutputHeaders) {
        return false;
      }

      if (this.numberOfSegments <= 1) {
        return false
      }

      return character.decodedPosition === 0 || character.decodedPosition % this.limitOfCharactersPerSegment === 0;
    }
  },
  beforeMount() {
    // checks showMainHeader query parameter
    if (this.$route.query.ShowMainHeader) {
      this.showMainHeader = this.$route.query.ShowMainHeader === 'true';
    }

    // checks ShowMessageOutput query parameter
    if (this.$route.query.ShowMessageOutput) {
      this.showMessageOutput = this.$route.query.ShowMessageOutput === 'true';
    }

    // checks ShowMessageOutputHeaders query parameter
    if (this.$route.query.ShowMessageOutputHeaders) {
      this.showMessageOutputHeaders = this.$route.query.ShowMessageOutputHeaders === 'true';
    }

    // checks ShowWarnings query parameter
    if (this.$route.query.ShowWarnings) {
      this.showWarnings = this.$route.query.ShowWarnings === 'true';
    }

  },

}
</script>
<style scoped>
/* import local file to be able to use Lato fonts: api-core/resources/assets/fonts/Lato/fonts.css */
@import url('../../../../assets/fonts/Lato/font.css');

/* Defines the Message output character block */
.character-block {
  align-items: center;
  color: black;
  font-family: monospace;
  justify-content: center;
  text-align: center;
  vertical-align: middle;
}

.section-margin {
  @media screen and (max-width: 767px) {
    margin-bottom: 40px;
  }
  @media screen and (min-width: 768px) {
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 767px) {
  .mobile-table-header {
    font-size: 3vw;
  }
}

td, th {
  padding: .75rem;
}

.message-container {
  height: 15rem;
}

.overflow-auto {
  overflow: auto;
}

.overflow-hidden {
  overflow: hidden; /* this is because of the rounded corners. If a background color is used, the corners will be visible.*/
}

.details-table-border {
  border-color: #101840 !important;
}

.rounded-bottom-right {
  border-bottom-right-radius: 0.625rem !important;
}

.rounded-bottom-left {
  border-bottom-left-radius: 0.625rem !important;
}

.subtitle-text {
  color: black;
  font-size: 1.0625rem; /* 17px / 16 = 1.0625rem */
}

@media screen and (min-width: 768px) {
  .subtitle-text-margin {
    margin-bottom: 1.5625rem
  }
}

.subtitle-description {
  color: black;
  font-size: 1rem; /* 16px */
}

@media screen and (min-width: 768px) {
  .subtitle-description {
    margin-bottom: 1.5625rem
  }
}


.lato-regular {
  font-family: "Lato", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.lato-bold {
  font-family: "Lato", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.footnote {
  font-size: 0.75rem;
  color: #6c757d; /* Bootstrap's text-muted color */
  text-align: left;
}

@media screen and (min-width: 768px) {
  .message-container {
    min-height: 313px;
  }
}
</style>
