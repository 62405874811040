var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.hasPermissionTo("change integration settings company") &&
    !_vm.loading_whitelabel
    ? _c(
        "div",
        { staticClass: "app-body", attrs: { id: "view" } },
        [
          _c("div", { staticClass: "padding" }, [
            _c("div", { staticClass: "row box" }, [
              _c("div", { staticClass: "col-12" }, [
                _c("div", { staticClass: "no-effect" }, [
                  _c(
                    "div",
                    { staticClass: "box-body" },
                    [
                      _vm.isCompanyKYC
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "d-flex align-items-start justify-content-between pb-4 pt-2",
                            },
                            [
                              _c("integrations-banner"),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "d-flex text-right" },
                                [
                                  _vm.isTrial && _vm.isNotSimpSocial
                                    ? _c("video-modal", {
                                        staticClass: "pl-4",
                                        attrs: {
                                          title:
                                            "Integrate Aloware with your CRM 🔗️",
                                          "cookie-name": "integrations",
                                          notes:
                                            "🔥 Unlock limitless potential with <strong>Integrations</strong>!</br></br>🔄 Dive into a world of seamless connections, bridging gaps and boosting productivity.</br></br>Step into the nexus of ultimate compatibility and synergy now! 💥🔗",
                                          "video-url":
                                            "https://www.youtube.com/embed/mYPcj4sj43c?si=-25jSawvJhMgHFCN",
                                          "learn-more-link":
                                            "https://support.aloware.com/aloware-integrations",
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-flex align-items-center border-bottom pb-4 pt-2",
                        },
                        [
                          _c("div", { staticClass: "h3 flex-grow-1 m-0" }, [
                            _vm._v("Integrations"),
                          ]),
                          _vm._v(" "),
                          _vm.isTrial &&
                          _vm.isNotSimpSocial &&
                          !_vm.isCompanyKYC
                            ? _c("video-modal", {
                                staticClass: "pl-4",
                                attrs: {
                                  title: "Integrate Aloware with your CRM 🔗️",
                                  "cookie-name": "integrations",
                                  notes:
                                    "🔥 Unlock limitless potential with <strong>Integrations</strong>!</br></br>🔄 Dive into a world of seamless connections, bridging gaps and boosting productivity.</br></br>Step into the nexus of ultimate compatibility and synergy now! 💥🔗",
                                  "video-url":
                                    "https://www.youtube.com/embed/mYPcj4sj43c?si=-25jSawvJhMgHFCN",
                                  "learn-more-link":
                                    "https://support.aloware.com/aloware-integrations",
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-tabs",
                        {
                          ref: "tabs",
                          staticClass: "mt-4 integration-tabs",
                          attrs: { type: "card" },
                          on: { "tab-click": _vm.handleClick },
                          model: {
                            value: _vm.activeName,
                            callback: function ($$v) {
                              _vm.activeName = $$v
                            },
                            expression: "activeName",
                          },
                        },
                        [
                          !_vm.whitelabel
                            ? _c(
                                "el-tab-pane",
                                {
                                  attrs: {
                                    label: "Integrations",
                                    name: "integrations",
                                  },
                                },
                                [
                                  _c("integrations-list", {
                                    attrs: {
                                      links: _vm.links,
                                      link_without_settings:
                                        _vm.link_without_settings,
                                    },
                                    on: { selected: _vm.selectedItem },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "el-tab-pane",
                            {
                              attrs: {
                                label: "Form Connect",
                                name: "form_connect",
                              },
                            },
                            [_c("integrations-form-connect")],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-tab-pane",
                            { attrs: { label: "Lead API", name: "lead_api" } },
                            [_c("integrations-lead-api")],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-tab-pane",
                            {
                              attrs: {
                                label: "Contact Lookup API",
                                name: "contact_lookup_api",
                              },
                            },
                            [_c("contact-lookup-api")],
                            1
                          ),
                          _vm._v(" "),
                          _vm.shouldShowVoipApi
                            ? _c(
                                "el-tab-pane",
                                {
                                  attrs: {
                                    label: "Sequences API",
                                    name: "sequence_enroll",
                                  },
                                },
                                [_c("integrations-sequence-enroll-api")],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.shouldShowVoipApi
                            ? _c(
                                "el-tab-pane",
                                {
                                  attrs: {
                                    label: "Number Lookup API",
                                    name: "number_lookup_api",
                                  },
                                },
                                [_c("integrations-number-lookup")],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.shouldShowVoipApi
                            ? _c(
                                "el-tab-pane",
                                {
                                  attrs: { label: "SMS API", name: "sms_api" },
                                },
                                [_c("integrations-sms-api")],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.shouldShowVoipApi
                            ? _c(
                                "el-tab-pane",
                                {
                                  attrs: {
                                    label: "RVM API",
                                    name: "rvm_api",
                                    disabled: !_vm.canBroadcastRvm,
                                  },
                                },
                                [_c("integrations-rvm-api")],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.shouldShowVoipApi
                            ? _c(
                                "el-tab-pane",
                                {
                                  attrs: {
                                    label: "Two-Legged Call API",
                                    name: "two_legged_call_api",
                                  },
                                },
                                [_c("integrations-two-legged-call-api")],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "el-tab-pane",
                            {
                              attrs: {
                                label: "Power Dialer API",
                                name: "pd_api",
                              },
                            },
                            [_c("integrations-power-dialer-api")],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-tab-pane",
                            {
                              attrs: { label: "Users API", name: "users_api" },
                            },
                            [_c("integrations-users-api")],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _vm.selected_item
            ? _c(
                "el-drawer",
                {
                  staticClass: "integration-settings-drawer",
                  attrs: {
                    visible: _vm.drawer,
                    direction: _vm.direction,
                    size: _vm.drawer_size,
                    "destroy-on-close": true,
                    "before-close": _vm.handleClose,
                  },
                  on: {
                    "update:visible": function ($event) {
                      _vm.drawer = $event
                    },
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "title",
                        fn: function () {
                          return [
                            _c("span", { attrs: { role: "heading" } }, [
                              _vm.selected_item === "custom_link_builder"
                                ? _c("h3", [
                                    _vm._v("Custom Integration Link Builder"),
                                  ])
                                : _vm.selected_item === "gohighlevel"
                                ? _c("h3", [
                                    _vm._v("GoHighLevel Integration Settings"),
                                  ])
                                : _c("h3", [
                                    _vm._v(
                                      "\n                    " +
                                        _vm._s(
                                          _vm._f("fixIntegrationName")(
                                            _vm._f("ucwords")(
                                              _vm._f("prettifyCamelCase")(
                                                _vm.selected_item
                                              )
                                            )
                                          )
                                        ) +
                                        " Integration Settings\n                "
                                    ),
                                  ]),
                              _vm._v(" "),
                              _vm.aloware_links[_vm.selected_item]
                                ? _c("small", { staticClass: "mr-2" }, [
                                    _c(
                                      "a",
                                      {
                                        staticClass: "text-bluish _600",
                                        attrs: {
                                          href: _vm.aloware_links[
                                            _vm.selected_item
                                          ],
                                          target: "_blank",
                                        },
                                      },
                                      [_vm._v("Click here")]
                                    ),
                                    _vm._v(
                                      "\n                    to learn more information about " +
                                        _vm._s(
                                          _vm._f("fixIntegrationName")(
                                            _vm._f("ucwords")(
                                              _vm._f("prettifyCamelCase")(
                                                _vm.selected_item
                                              )
                                            )
                                          )
                                        ) +
                                        " integration.\n                "
                                    ),
                                  ])
                                : _vm._e(),
                            ]),
                          ]
                        },
                        proxy: true,
                      },
                    ],
                    null,
                    false,
                    901491599
                  ),
                },
                [
                  _vm._v(" "),
                  _c("integration-settings-drawer", {
                    attrs: { cardname: _vm.selected_item },
                    on: { back: _vm.handleClose },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c("el-backtop", {
            ref: "backtop",
            attrs: { target: "html", bottom: 20, right: 20 },
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }