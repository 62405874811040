<template>
    <div class="col-12 p-a-xs"
         data-testid="contact-textual-filters">
        <span v-loading="contactsCount == null"
              element-loading-custom-class="medium-v-loading">
            <span>
                <strong>{{ filteredContactCount | numFormat }}</strong>
                Contacts
            </span>
        </span>
        <div class="py-2"
             v-if="notEmptyEnabledFilters">
            <small>Active Filters:</small>
            <div class="col-12 p-0">
                <span v-for="filter in enabled_filters_text"
                      v-if="(typeof filter.value !== 'object' && filter.value) || ((typeof filter.value === 'object' && filter.value && filter.value.length) || (typeof filter.date_range === 'object' && filter.date_range && filter.date_range.length))"
                      class="btn btn-xs indigo-A200 mr-1 mb-1 text-left white-space-normal"
                      style="max-width: 100%;">
                    <strong>{{ filter.name | ucwords }}</strong>
                    <b v-if="!flag_filters.includes(filter.name) && !filter.date_range && filter.value.length"
                       v-for="value in filter.value"
                       class="white-space-initial d-inline label label-sm rounded success m-l-xs">
                        <span v-if="typeof value == 'string'">{{ value | capitalize }}</span>
                    </b>
                    <span v-if="!flag_filters.includes(filter.name) && filter.date_range"
                          class="white-space-initial d-inline">
                        <b class="label rounded success m-l-xs">
                            <span>{{ filter.date_range[0] }}</span>
                        </b>
                        <span class="m-l-xs">to</span>
                        <b class="label rounded success m-l-xs">
                            <span>{{ filter.date_range[1] }}</span>
                        </b>
                    </span>
                </span>
            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters} from "vuex"

export default {
    data() {
        return {
            enabled_filters_text: [],
            flag_filters: [
                'Has untagged calls',
                'Untagged contacts',
                'Not disposed',
                'Blocked contacts',
                'DNC contacts',
                'Initial line only',
                'Has unread messages',
                'New leads',
                'Follow the sun',
                'Unassigned leads',
                'TCPA approved',
                'Has Scheduled Messages',
                'Does Not Have Scheduled Messages',
                'Is Enrolled in Sequence',
                'Not Enrolled in Sequence'
            ],
            is_new_lead_filtered: false,
            is_unanswered_lead_filtered: false
        }
    },

    computed: {
        ...mapGetters({
            filter: 'getFilter',
            contactsCount: 'getContactsCount',
            newLeadsCount: 'getNewLeadsCount',
            unreadsCount: 'getUnreadsCount'
        }),

        notEmptyEnabledFilters() {
            for (let index in this.enabled_filters_text) {
                if ((typeof this.enabled_filters_text[index].value !== 'object' && this.enabled_filters_text[index].value) ||
                    ((typeof this.enabled_filters_text[index].value === 'object' && this.enabled_filters_text[index].value && this.enabled_filters_text[index].value.length) ||
                        (typeof this.enabled_filters_text[index].date_range === 'object' && this.enabled_filters_text[index].date_range && this.enabled_filters_text[index].date_range.length))) {
                    return true
                }
                return false
            }
        },

        filteredContactCount() {
            if (this.is_new_lead_filtered || this.filter.is_new_lead) {
                return this.newLeadsCount
            } else if (this.is_unanswered_lead_filtered || this.filter.has_unread) {
                return this.unreadsCount
            } else {
                return this.contactsCount
            }
        }
    },

    created() {
        VueEvent.listen('update_contact_filter_text', (data) => {
            this.enabled_filters_text = data
        })
        VueEvent.listen('filtered_new_leads', () => {
            this.is_new_lead_filtered = true
            this.is_unanswered_lead_filtered = false
        })
        VueEvent.listen('filtered_unanswered_messages', () => {
            this.is_unanswered_lead_filtered = true
            this.is_new_lead_filtered = false
        })
        VueEvent.listen('unfiltered_new_leads_unanswered_messages', () => {
            this.is_unanswered_lead_filtered = false
            this.is_new_lead_filtered = false
        })
        VueEvent.listen('unfiltered_unanswered_messages', () => {
            this.is_unanswered_lead_filtered = false
        })
    }
}
</script>
