<template>
    <div class="app-body conversync max-height"
         :style="{height: 'calc(100% - ' + (top_offset  + 1) + 'px)'}"
         id="view"
         v-if="hasPermissionTo(['list contact', 'view contact'])">

        <!--contacts help dialog-->
        <el-dialog :visible.sync="show_help"
                   title="What are contacts?"
                   top="10vh"
                   data-testid="contacts-help-dialog"
                   append-to-body>
            <p class="text-md _600 break-word">We automatically add each person you communicate with as a Contact (or
                lead). Each Contact has a small CRM-style profile. You can send messages or call a contact here.</p>
            <p class="text-md _600 break-word">To send a message to someone, or read previous conversations, search
                their name or their number and click on the Contact. Contacts can be tagged (for audience segmentation)
                or enrolled in Sequences.</p>
        </el-dialog>

        <div class="full-height contacts-page"
             :style="{height: 'calc(100% - 48px)'}">
            <div class="fixed-header padding pt-0 pb-0" :style="{top: (top_offset+50) + 'px'}">
                <div class="row mb-0 mt-0">
                    <div class="d-flex flex-fill flex-wrap p-2">
                        <div class="d-flex flex-wrap flex-fill">
                            <contact-advanced-filters-wrapper :key="prop_counter"
                                                              :tag_id="tag_id"
                                                              :disposition_status_id="disposition_status_id"
                                                              :lead_source_id="lead_source_id"
                                                              :default_date_range="default_date_range"
                                                              :has_sort_communications="true"
                                                              @changed="updateFilterChanged">
                            </contact-advanced-filters-wrapper>
                        </div>

                        <div class="d-flex flex-wrap wrap-auto-margin">
                            <div v-if="hasPermissionTo('create contact')">
                                <contact-creator></contact-creator>
                            </div>

                            <el-popover v-if="!enabledToImportContacts()"
                                        ref="import-contacts"
                                        placement="top"
                                        width="200"
                                        class="custom-popover"
                                        popper-class="btn-primary"
                                        trigger="hover">
                                <custom-message-display :config="customMessage('contacts.import')" />
                            </el-popover>

                            <div v-if="hasPermissionTo('create contact') && isLargeEnough">
                                <button class="btn blue"
                                        :disabled="!canImportContacts"
                                        v-popover:import-contacts
                                        data-testid="import-contacts-button"
                                        @click.prevent="startImportWizard()">
                                    <i class="fas fa-file-import"></i>
                                    Import Contacts
                                </button>
                            </div>

                            <div>
                                <i class="material-icons text-lg cursor-pointer d-flex flex-column justify-content-center text-blue"
                                   data-testid="contacts-help-info-outline"
                                   @click="toggleHelp">
                                    info_outline
                                </i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!--contact list-->
            <div class="row-col pl-3 pr-3 pt-3 pb-0 mt-5">
                <div class="row m-0 b-l b-r b-t whitish text-blackish px-3 pt-3">
                    <contact-textual-filters></contact-textual-filters>
                    <div class="col-12 px-0">
                        <div class="input-group py-2">
                            <el-input placeholder="Press ENTER to search contacts..."
                                      class="text-blackish form-control no-border p-0 input-contact-shortcut mt-1 mx-1"
                                      v-model="search_text"
                                      ref="search_box"
                                      autofocus
                                      clearable
                                      data-testid="search-contacts-input"
                                      @change="searchContactChange">
                                <i slot="prefix" class="el-input__icon el-icon-search"></i>
                            </el-input>
                            <!-- For SimpSocial Button Frame-->
                            <div id="ss-messenger-iframe-wrapper" class="ss-messenger-frame"
                                 v-if="current_company && current_company.reseller_id === 357">
                                <div class="ss-messenger-iframe-wrapper-click" @click="redirectToDMSEquity">
                                </div>
                                <iframe id="ss-messenger-button" :src="'https://dealer.simpsocial.com/' + current_company.id + '/messenger/unread/count'" frameborder="0" style=""></iframe>
                            </div>
                            <div class="mt-2 mt-md-0">
                                <contacts-shortcuts ref="contacts-shortcuts"></contacts-shortcuts>
                            </div>
                        </div>
                        <div v-if="isLargeEnough" class="row mt-2">
                            <div class="col-md-2">
                                <strong>Name</strong>
                            </div>
                            <div class="col-md-2">
                                <strong>Info</strong>
                            </div>
                            <div class="col-md-2">
                                <strong>Recent Engagement</strong>
                            </div>
                            <div class="col-md-2">
                                <strong>Tags</strong>
                            </div>
                            <div class="col-md-1">
                                <el-tooltip content="Unread Missed Calls"
                                            placement="top">
                                    <strong>Missed Calls</strong>
                                </el-tooltip>
                            </div>
                            <div class="col-md-1">
                                <el-tooltip content="Unread Messages"
                                            placement="top">
                                    <strong>Unread Texts</strong>
                                </el-tooltip>
                            </div>
                            <div class="col-md-1">
                                <el-tooltip content="Unread Voicemails"
                                            placement="top">
                                    <strong>Voicemails</strong>
                                </el-tooltip>
                            </div>
                            <div class="col-md-1"
                                 v-if="hasPermissionTo('update contact')">
                                <strong>Ops</strong>
                            </div>
                        </div>
                    </div>
                </div>
                <contacts-list :is_filtered="is_filtered"></contacts-list>
            </div>
        </div>
        <import-wizard ref="importWizard"></import-wizard>
    </div>
</template>

<script>
import auth from '../../auth'
import {mapActions, mapGetters, mapState} from 'vuex'
import {
    acl_mixin,
    avatar_mixin,
    styling_mixin,
    validator_mixin,
    kyc_mixin,
    supervisor_mixin
} from '../../mixins'
import * as LrnTypes from '../../constants/lrn-types'
import * as TagTypes from '../../constants/tag-types'
import TagCreator from '../../components/tag-creator'
import ContactCreator from '../../components/contact-creator'
import VariableDialog from '../../components/variable-dialog'
import ImportWizard from '../../components/import-wizard/import-wizard'
import ContactsShortcuts from '../../components/contacts-shortcuts'
import CustomMessageDisplay from '../../components/kyc/custom-message-display'

export default {
    mixins: [
        styling_mixin,
        validator_mixin,
        acl_mixin,
        avatar_mixin,
        kyc_mixin,
        supervisor_mixin
    ],

    components: {
        ContactCreator,
        TagCreator,
        VariableDialog,
        ImportWizard,
        ContactsShortcuts,
        CustomMessageDisplay
    },

    data() {
        return {
            auth: auth,
            env: null,
            statics: {
                logo: null,
                logo_inverse: null,
                logo_square: null,
                logo_square_inverse: null,
                host: null,
                referer: null,
                name: null,
                domain: null,
                whitelabel: false,
                path: null
            },
            search_text: '',
            tag_id: null,
            disposition_status_id: null,
            lead_source_id: null,
            filter_by: null,
            show_help: false,
            is_filtered: false,
            prop_counter: 0,
            default_date_range: 'All Time - nochange',
            contacts_shortcuts_key: 0,
            LrnTypes,
            TagTypes,
        }
    },

    computed: {
        ...mapGetters({
            filter: 'getFilter'
        }),

        ...mapState('cache', ['current_company']),

        isLargeEnough() {
            let valid_sizes = ['xxl', 'xl', 'lg', 'md']
            return valid_sizes.includes(this.$mq)
        },

        canImportContacts () {
            return this.enabledToImportContacts()
        },
    },

    created() {
        if (this.$route.query.tag_id) {
            this.tag_id = parseInt(this.$route.query.tag_id)
        }

        if (this.$route.query.disposition_status_id) {
            this.disposition_status_id = parseInt(this.$route.query.disposition_status_id)
        }

        if (this.$route.query.lead_source_id) {
            this.lead_source_id = parseInt(this.$route.query.lead_source_id)
        }

        this.getStatics()

        if (!['Contacts', 'Contact'].includes(localStorage.getItem('PREVIOUS_ROUTE_NAME'))) {
            this.resetContactFilters()
        }
    },

    mounted() {
        if (!this.hasPermissionTo('list contact')) {
            this.goBack()
        }

        if (!this.checkSize()) {
            this.is_center = false
        }
        if (['Contacts', 'Contact'].includes(localStorage.getItem('PREVIOUS_ROUTE_NAME'))) {
            this.search_text = this.filter.search_text
        } else {
            this.search_text = ''
            this.filter.search_text = ''
        }
        this.filter.search_fields = []

        if (this.$route.query.tag_id) {
            this.changeTag(this.$route.query.tag_id)
        }

        if (this.$refs.search_box) {
            this.setSearchFocus()
        }
    },

    methods: {
        redirectToDMSEquity() {
            this.$router.push({name: 'Messenger'}).catch((err) => {
            })
        },

        setSearchFocus() {
            this.$nextTick(() => {
                if (this.$refs.search_box) {
                    this.$refs.search_box.focus()
                }
            })
        },

        avatarStyle(contact) {
            if (!contact.name) {
                return
            }

            let bg = this.intToRGB(this.hashCode(contact.name))

            return {
                backgroundColor: bg,
                color: this.overlayColor(bg)
            }
        },

        getStatics() {
            axios.get('/get-statics').then(res => {
                this.statics = res.data
                this.setPageTitle('Contacts - ' + this.statics.name)
            }).catch(err => {
                this.setPageTitle('Contacts - Aloware')
                console.log(err)
                this.$root.handleErrors(err.response)
            })
        },

        checkSize() {
            return this.isLargeEnough
        },

        changeTag(tag_id) {
            this.tag_id = tag_id
        },

        toggleHelp() {
            this.show_help = !this.show_help
        },

        updateFilterChanged(changed) {
            this.is_filtered = changed
            VueEvent.fire('filters_changed', changed)
        },

        startImportWizard: function () {
            this.$refs.importWizard.start()
        },

        searchContactChange(event) {
            if (!this.search_text.includes(this.filter_by)) {
                this.filter_by = null
            }
            if (this.search_text.length < 3 && this.search_text.length != 0) {
                return
            }
            this.filter.search_text = this.search_text
        },

        ...mapActions(['resetContactFilters'])
    },

    watch: {
        'filter.search_text': function (new_value) {
            if (new_value && new_value.length === 0) {
                this.search_text = ''
            }
        },

        $route({params, query}) {
            if (this.$route.query.tag_id) {
                this.changeTag(this.$route.query.tag_id)
            }
        }
    },

    beforeRouteEnter(to, from, next) {
        auth.check()
            .then((res) => {
                if (res.data.user.is_reseller) {
                    // redirect to account management portal if the company is a reseller
                    next({name: 'Account Management Portal'})
                } else {
                    next()
                }
            })
            .catch((err) => {
                next({name: 'Login', query: {redirect: to.fullPath}})
            })
    },

    beforeCreate() {
        axios.get('/build-info.json')
            .then(res => {
                this.env = res.data.env
            })
            .catch(err => {
                console.log(err)
            })
    }
}
</script>
