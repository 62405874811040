<template>
    <node-template
        :id="nodeId"
        :onlyHeader="true">
        <template v-slot:header>
            <span>End</span>
        </template>
    </node-template>
</template>

<script>
import { alohabot_node_mixin  } from '../../../mixins'

export default {
    name: 'end-node',
    mixins: [
        alohabot_node_mixin
    ]
}
</script>
