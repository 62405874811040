<template>
    <div class="container-listing">
        <div class="container-header d-flex mb-0">
            <div class="mr-auto">
                <div class="d-flex">
                    <el-button
                        type="info"
                        round
                        size="medium"
                        @click="$router.push({ name: 'Chatbots' })">
                        <i class="fa fa-chevron-left mr-1" aria-hidden="true"/>
                        <strong>Back</strong>
                    </el-button>
                </div>
            </div>

            <!-- Save state icons -->
            <div v-if="chatbot" class="ml-auto vertical-center mr-3">
                <el-tooltip
                    v-if="chatbot.build_status === BUILD_IN_PROGRESS"
                    effect="dark"
                    content="Saving changes..."
                    placement="top">
                    <i class="material-icons hover-lower-opacity-primary text-primary">
                        cloud_upload
                    </i>
                </el-tooltip>
                <div v-else-if="chatbot.build_status !== BUILD_SUCCESS || edit_icon_visible">
                    <small>Unsaved changes</small>
                    <i class="material-icons">
                        edit_square
                    </i>
                </div>
                <el-tooltip
                    v-else
                    effect="dark"
                    content="Your changes are saved"
                    placement="top">
                    <i class="material-icons hover-lower-opacity text-success">
                        cloud_done
                    </i>
                </el-tooltip>
            </div>

            <!-- Chatbot state icons -->
            <div v-if="chatbot" class="vertical-center mr-3">
                <el-tooltip
                    v-if="!chatbot.enabled"
                    effect="dark"
                    content="This chatbot is paused"
                    placement="top">
                    <i class="material-icons hover-lower-opacity-warning text-warning">
                        pause_circle_filled
                    </i>
                </el-tooltip>
                <el-tooltip
                    v-else
                    effect="dark"
                    content="This chabot is active and running"
                    placement="top">
                    <i class="material-icons hover-lower-opacity text-success">
                        play_circle_filled
                    </i>
                </el-tooltip>
            </div>

            <!-- Chatbot build status icons -->
            <div v-if="chatbot" class="vertical-center mr-3">
                <el-tooltip
                    v-if="chatbot.build_status === BUILD_SUCCESS"
                    effect="dark"
                    content="Successfully Built"
                    placement="top">
                    <i class="material-icons hover-lower-opacity text-success">
                        check_box
                    </i>
                </el-tooltip>
                <el-tooltip
                    v-else-if="chatbot.build_status === BUILD_IN_PROGRESS"
                    effect="dark"
                    content="Building..."
                    placement="top">
                    <i class="material-icons fa-spin hover-lower-opacity-primary text-primary">
                        sync
                    </i>
                </el-tooltip>
                <el-tooltip
                    v-else-if="chatbot.build_status === BUILD_FAILED"
                    effect="dark"
                    content="Failed to Build"
                    placement="top">
                    <i class="material-icons hover-lower-opacity-danger text-danger">
                        report
                    </i>
                </el-tooltip>
                <el-tooltip
                    v-else
                    effect="dark"
                    content="Never Built"
                    placement="top">
                    <i class="material-icons text-dark">
                        more_horiz
                    </i>
                </el-tooltip>
            </div>

            <div v-if="chatbot" class="vertical-center">
                <!-- Build Button -->
                <el-button
                    type="primary"
                    :disabled="shouldDisableBuild"
                    round
                    class="mr-3"
                    size="medium"
                    @click="onClickBuild">
                    <i class="fa fa-wrench mr-1" aria-hidden="true"/>
                    <strong>Build</strong>
                </el-button>
            </div>
        </div>

        <div class="d-flex">
            <div class="ml-auto mr-3" v-if="chatbot">
                    <p v-if="chatbot.build_status === NOT_BUILD"
                        class="mb-0">
                        <b>{{ chatbot.name }}</b> has <b>never</b> been built
                    </p>
                    <p v-else
                        class="mb-0">
                        <b>{{ chatbot.name }}</b> <b class="text-success">successfully</b> built on <b>{{ this.chatbot.updated_at | fixDateTime }}</b> by <b>{{ chatbot.builder_user?.name }}</b>
                    </p>
            </div>
        </div>

        <div id="chatbot-builder" class="h-foot">
            <el-tabs
                v-model="active_tab"
                class="pt-2 h-100"
                style="max-height: calc(100% - 4rem)"
                @tab-click="onTabClick">

                <el-tab-pane
                    v-if="chatbot"
                    name="settings"
                    label="Chatbot Settings">
                    <ChatbotSettings
                        :chatbot="chatbot"
                        :lines="campaigns"
                        @bot-update="fetchChatbot"/>
                </el-tab-pane>

                <el-tab-pane
                    v-if="chatbot"
                    name="builder"
                    label="Flow Builder"
                    class="w-100 h-100">
                    <FlowBuilder
                        :chatbot="chatbot"
                        :intents="intents"
                        :active_tab="active_tab"
                        @ready-states="onChatbotStatesReady"
                        @set-save-icon="onSetSaveIcon"
                        @set-edit-icon="onSetEditIcon"
                        @enable-build-button="onEnableBuildButton"
                        @disable-build-button="onDisableBuildButton"/>
                </el-tab-pane>

                <el-tab-pane
                    v-if="chatbot"
                    name="intent-editor"
                    label="Intent Manager">
                    <IntentManager
                        :chatbot="chatbot"
                        :intents="intents"/>
                </el-tab-pane>
            </el-tabs>
        </div>
    </div>
</template>

<script>
import ChatbotSettings from './chatbot-settings.vue'
import FlowBuilder from './flow-builder.vue'
import IntentManager from './chatbot-intent-manager.vue'

const NOT_BUILD = 1
const BUILD_SUCCESS = 2
const BUILD_IN_PROGRESS = 3
const BUILD_FAILED = 4

export default {
    components: {
        ChatbotSettings,
        FlowBuilder,
        IntentManager
    },
    props: {
        chatbot_id: {
            required: true,
            default: null
        }
    },
    data() {
        return {
            NOT_BUILD,
            BUILD_SUCCESS,
            BUILD_IN_PROGRESS,
            BUILD_FAILED,
            chatbot: null,
            intents: [],
            active_tab: 'settings',
            campaigns: [],
            building: false,
            enable_build_button: false,
            edit_icon_visible: false
        }
    },
    created () {
        // Change tabs based on URL query
        if (this.$route.query.tab) {
            this.active_tab = this.$route.query.tab
        } else {
            this.$route.query.tab = this.active_tab
        }
    },
    mounted() {
        this.fetchChatbot()
        this.fetchIntents()
        this.fetchLines()
    },
    methods: {
        /**
         * Fetches the current Chatbot
         *
         * @return {void}
         */
        fetchChatbot() {
            axios.get(`/api/v1/bots/builder/bot/${this.chatbot_id}`)
                .then(res => {
                    this.chatbot = res.data
    
                    if(this.chatbot.build_status === BUILD_IN_PROGRESS) {
                        this.setBuilding(true)
                        this.enableBuildButton(false)
                        this.checkBotStatus()
                    }
                })
                .catch(err => {
                    this.setBuilding(false)
                    this.enableBuildButton(false)
                    this.$notify.error({
                        title: 'Chatbots',
                        offset: 95,
                        message: 'Something went wrong while fetching your chatbot.'
                    })
                    console.error(err)
                })
        },

        /**
         * Fetches chatbot intents
         *
         * @return {void}
         */
        fetchIntents() {
            axios.get(`/api/v1/bots/builder/${this.chatbot_id}/intents`)
                .then(res => {
                    this.intents = res.data.data
                })
                .catch(err => {
                    console.log(err)
                    this.$notify.error({
                        title: 'Chatbots',
                        offset: 95,
                        message: 'Something went wrong while fetching your chatbot intents.'
                    })
                })
        },

        /**
         * Checks bot status when it's building.
         *
         * @return {void}
         */
        async checkBotStatus() {
            while(this.building) {
                // Check the bot status every 10 seconds.
                await this.sleep(10000).then(res => {
                    axios.get(`/api/v1/bots/builder/bot/${this.chatbot_id}`)
                        .then(res => {
                            this.chatbot = res.data
                            this.isBuildFinished()
                        })
                        .catch(err => {
                            this.$notify.error({
                                offset: 95,
                                title: 'Chatbot Builder',
                                message: 'We are having trouble fetching the the status of your bot.',
                            })
                            this.setBuilding(false)
                            console.log(err)
                        })
                })
            }
        },

        /**
         * Once we receive the Bot Status, will fire notifications depending on the current chatbot build status
         *
         * @return
         */
        isBuildFinished() {
            switch (this.chatbot.build_status) {
                default:
                    this.$notify.error({
                        offset: 95,
                        title: 'Chatbot Builder',
                        message: 'Unknown bot status',
                    })
                    this.$root.$emit('chatbot-build-finished')
                    this.setBuilding(false)
                    break
                case NOT_BUILD:
                    if (this.building) {
                        this.$notify.info({
                            offset: 95,
                            title: 'Chatbot Builder',
                            message: 'Your bot was not built',
                        })
                        this.$root.$emit('chatbot-build-finished')
                        this.setBuilding(false)
                    }
                    break
                case BUILD_SUCCESS:
                    if (this.building) {
                        this.$notify.success({
                            offset: 95,
                            title: 'Chatbot Builder',
                            message: 'Your chatbot is ready! Give it a try in the Chatbot Settings tab :)',
                        })
                        this.$root.$emit('chatbot-build-finished')
                        this.$root.$emit('cleanup-states-buffer')
                        this.setBuilding(false)
                    }
                    break
                case BUILD_IN_PROGRESS:
                    if (!this.is_build_notified) {
                        this.$notify.info({
                            offset: 95,
                            title: 'Chatbot Builder',
                            message: 'We are still working...',
                        })
                        this.is_build_notified = true
                    }
                    break
                case BUILD_FAILED:
                    if (this.building) {
                        this.$notify.error({
                            offset: 95,
                            title: 'Chatbot Builder',
                            message: 'There was a problem building the bot. Make sure your changes are correct and try again.',
                        })
                        this.$root.$emit('chatbot-build-finished')
                        this.setBuilding(false)
                    }
                    break
            }
        },

        /**
         * Listens when Build button is clicked.
         *
         * @return {void}
         */
        onClickBuild() {
            this.enableBuildButton(false)
            this.setBuilding(true)
            // Forces icons and other dependencies of the build status to change at click time.
            this.chatbot.build_status = BUILD_IN_PROGRESS
            // Skip 'We are still working...' notification when the user clicks the build button.
            // We only show it when the page is reloaded.
            this.is_build_notified = true
            // This event tells the Flow Builder to prepare and retrieve the states
            this.$root.$emit('prepare-chatbot-states')
        },

        /**
         * This function decides what to do when the states are ready to be sent to the backend.
         *
         * @param {object[]} states Transformed states from the Flow Builder to fit the API request needs.
         *
         * @return {void}
         */
        onChatbotStatesReady(states) {
            // Once the states are prepared, we send a single build request.
            this.buildChatbot(states)
        },

        /**
         * Send build request of the current Chatbot.
         *
         * @param {object[]} states
         *
         * @return {void}
         */
        buildChatbot(states) {
            axios.post(`/api/v1/bots/builder/${this.chatbot.id}/states/build`, { states })
                .then(res => {
                    this.$notify.info({
                        offset: 95,
                        title: 'Bot Builder',
                        message: "Your chatbot is under construction. We'll let you know when it's ready!",
                    })
                    this.checkBotStatus()
                })
                .catch(err => {
                    this.$notify.error({
                        offset: 95,
                        title: 'Bot Builder',
                        message: "We're having issues building you Chatbot!",
                    })
                    this.setBuilding(false)
                    this.enableBuildButton(false)
                    // Forces icons and other dependencies of the build status to change at click time.
                    this.chatbot.build_status = BUILD_FAILED
                    this.$root.$emit('chatbot-build-finished')
                })
        },

        /**
         * Sets the building state
         *
         * @param {boolean} state 
         *
         * @return {void}
         */
        setBuilding(state) {
            this.building = state
        },

        /**
         * Listens when any components asks for enabling the build button.
         *
         * @return {void}
         */
        onEnableBuildButton() {
            this.enableBuildButton(true)
        },

        /**
         * Listens when any components asks for disabling the build button.
         *
         * @return {void}
         */
        onDisableBuildButton() {
            this.enableBuildButton(false)
        },

        /**
         * Listens when any the save icon must be shown.
         *
         * @return {void}
         */
        onSetSaveIcon() {
            this.edit_icon_visible = false
        },

        /**
         * Listens when any the edit icon must be shown.
         *
         * @return {void}
         */
        onSetEditIcon() {
            this.edit_icon_visible = true
        },

        /**
         * Enables or disables the Build button.
         * @param {boolean} enabled 
         */
        enableBuildButton(enabled) {
            // Prevent if it's already on the requested state.
            if(this.enable_build_button === enabled) {
                return
            }
            this.enable_build_button = enabled
        },

        /**
         * Waits x number of milliseconds.
         * @param {integer} ms 
         */
        sleep(ms) {
            return new Promise(resolve => setTimeout(resolve, ms))
        },

        /**
         * Fetch campaigns from the backend.
         *
         * @return {void}
         */
        fetchLines() {
            axios.get('/api/v1/campaign').then(res => {
                this.campaigns = res.data
            }).catch(err => {
                console.log(err)
            })
        },

        /**
         * Handles tab changing
         *
         * @param {string} tab 
         *
         * @return {void}
         */
        onTabClick (tab) {
            this.$router.replace({
                query: {
                    tab: tab.name
                }
            }).then(route => {})
            .catch(err => {
                console.log(err)
            })
        }
    },
    computed: {
        /**
         * This function decided if the Build button should be disabled or not.
         *
         * @return {boolean}
         */
        shouldDisableBuild() {
            return this.building || !this.enable_build_button
        }
    }
}
</script>
