<template>
    <div v-if="hasPermissionTo('create contact') && contact && phone_number">
        <!--create new phone number-->
        <el-dialog title="Update phone number"
                   class="dialog-padding auto"
                   :width="isLargeEnough ? '450px' : '100%'"
                   :visible.sync="hide_edit"
                   data-testid="edit-contact-phone-number-dialog"
                   append-to-body>
            <el-form ref="edit_phone_number"
                     class="p-0"
                     label-position="top"
                     :rules="rules_phone_number"
                     :model="edit_phone_number"
                     data-testid="edit-contact-phone-number-form"
                     @submit.prevent.native="savePhoneNumber">
                <el-form-item prop="title"
                              label="Title">
                    <div class="width-400">
                        <el-input v-model="edit_phone_number.title"
                                  data-testid="edit-phone-title-input"
                                  @input="preValidateForm('edit_phone_number')">
                        </el-input>
                    </div>
                </el-form-item>
                <el-form-item prop="phone_number"
                              label="Phone Number">
                    <div class="width-400">
                        <el-tooltip
                            class="item mb-1"
                            effect="light"
                            content="It's not possible to edit phone number which came from integration."
                            data-testid="can-not-edit-phone-number-tooltip"
                            placement="top"
                            :disabled="canEdit">
                            <el-input v-model="edit_phone_number.phone_number"
                                      :disabled="!canEdit"
                                      data-testid="edit-phone-number-input"
                                      @input="preValidateForm('edit_phone_number')">
                            </el-input>
                        </el-tooltip>
                    </div>
                </el-form-item>
                <el-form-item prop="is_primary"
                              v-if="contact.phone_number !== this.phone_number.phone_number">
                    <el-checkbox v-model="edit_phone_number.is_primary"
                                 label="Primary phone number"
                                 data-testid="primary-number-checkbox"
                                 @change="preValidateForm('edit_phone_number')">
                    </el-checkbox>
                </el-form-item>
            </el-form>

            <el-checkbox
                style="margin-right: 0;"
                data-testid="wrong-number-checkbox"
                v-model="edit_phone_number.is_wrong_number">
                Wrong Number
            </el-checkbox>

            <el-tooltip
                class="item mb-1"
                effect="light"
                content="Select this box if number isn't associated with this contact."
                data-testid="is-not-associated-tooltip"
                placement="top">
                <i class="fas fa-info-circle"></i>
            </el-tooltip>

            <br />

            <el-checkbox
                :disabled="this.phone_number.is_opted_out"
                data-testid="sms-opt-out-checkbox"
                v-model="edit_phone_number.is_opted_out">
                SMS Opt-Out
            </el-checkbox>

            <el-tooltip
                class="item ml-2 mb-1"
                effect="light"
                content="Once the phone number is opted out, you can't uncheck it."
                placement="top">
                <i class="fas fa-info-circle"></i>
            </el-tooltip>

            <div class="d-flex align-items-center pb-3">

                <div class="ml-auto">
                    <el-button
                        type="success"
                        :disabled="loading_btn"
                        class="pull-right ml-2"
                        data-testid="edit-phone-number-save-button"
                        @click="savePhoneNumber">
                        <i class="material-icons loader"
                           v-show="loading_btn">&#xE863;</i>
                        <i class="material-icons"
                           v-show="!loading_btn">
                            save
                        </i>
                        <span>Save</span>
                    </el-button>

                    <el-button
                        type="info"
                        class="pull-right"
                        data-testid="edit-phone-number-close-button"
                        @click="closeModal">
                        <span class="fa fa-close"></span>
                        <span>Cancel</span>
                    </el-button>
                </div>
            </div>
        </el-dialog>

        <slot name="trigger">
            <button slot="reference"
                    class="btn btn-sm btn-outline-warning w-30 d-flex justify-content-center align-items-center"
                    data-testid="edit-phone-number-slot-button"
                    @click.prevent="hide_edit = true">
                <i class="fa fa-edit"></i>
            </button>
        </slot>
    </div>
</template>


<script>
    import auth from '../auth'
    import {mapState} from 'vuex'
    import {
        acl_mixin,
        validator_mixin,
        form_validation_mixin
    } from '../mixins'

    export default {
        mixins: [
            acl_mixin,
            validator_mixin,
            form_validation_mixin
        ],

        props: {
            contact: {
                required: true
            },

            phone_number: {
                required: true
            }
        },

        data() {
            return {
                auth: auth,
                loading_btn: false,
                hide_edit: false,
                edit_phone_number: {
                    title: null,
                    phone_number: null,
                    is_primary: false,
                    is_wrong_number: false,
                    is_opted_out: false
                },
                rules_phone_number: {
                    phone_number: [
                        {
                            required: true,
                            message: 'Please provide a phone number',
                            trigger: 'blur'
                        },
                        {
                            required: true,
                            validator: this.phoneValidator,
                            trigger: 'blur'
                        },
                    ]
                }
            }
        },

        computed: {
            ...mapState('cache', ['current_company']),

            isLargeEnough() {
                let valid_sizes = ['xxl', 'xl', 'lg', 'md']
                if (valid_sizes.includes(this.$mq)) {
                    return true
                }
                return false
            },
            canEdit() {
                return this.current_company.activate_multi_entity ? !this.phone_number.integration_data || this.phone_number.integration_data.length === 0 : true
            }
        },

        methods: {
            savePhoneNumber() {
                if (this.validateForm('edit_phone_number')) {
                    this.loading_btn = true

                    axios.post('/api/v1/contact/' + this.contact.id + '/phone-number/' + this.phone_number.id, this.edit_phone_number).then(res => {
                        this.loading_btn = false
                        this.closeModal()
                        this.$emit('success', res.data)
                    }).catch(err => {
                        this.$root.handleErrors(err.response)
                        this.loading_btn = false
                    })
                }
            },

            closeModal() {
                this.hide_edit = false
            },

            initPhoneNumber() {
                if (this.phone_number && this.contact) {
                    this.edit_phone_number.title = this.phone_number.title
                    this.edit_phone_number.phone_number = this.phone_number.phone_number
                    this.edit_phone_number.is_primary = (this.contact.phone_number === this.phone_number.phone_number)
                    this.edit_phone_number.is_wrong_number = this.phone_number.is_wrong_number
                }
            }
        },

        watch: {
            phone_number() {
                this.initPhoneNumber()
            },

            hide_edit() {
                if (this.hide_edit) {
                    this.initPhoneNumber()
                    this.$emit('hide')
                } else {
                    this.resetForm('edit_phone_number')
                    this.$emit('show')
                }
            }
        }
    }
</script>
