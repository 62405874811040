<template>
    <el-tooltip
        class="item"
        effect="dark"
        :content="data.value"
        :disabled="!data.value || shouldHideTooltip"
        placement="bottom">

        <node-template :id="nodeId">

            <template v-slot:header>
                <span>Custom Field</span>
            </template>

            <div class="row-inline mt-2">
                <div class="col-12 text-center">
                    <span><b>Field:</b> {{ data.key }}</span>
                </div>
            </div>

            <div class="row-inline mt-2">
                <div class="col-12 text-center">
                    <span><b>Value:</b> {{ data.value }}</span>
                </div>
            </div>
        </node-template>
    </el-tooltip>
</template>

<script>
import { alohabot_node_mixin  } from '../../../mixins'

export default {
    name: 'custom-node',
    mixins: [
        alohabot_node_mixin
    ]
}
</script>
