<template>
    <el-popover placement="right-start"
                width="350"
                trigger="manual"
                v-model="popover_visible"
                @show="resetForms"
                v-closable="{
                    exclude: ['popover_button'],
                    handler: 'onClose'
                }">
            <h6 class="mb-2 text-black">Tranfer this phone number to</h6>
                <el-tabs v-model="tab_name"
                         @before-leave="preValidateBothForms">
                    <el-tab-pane label="Existing Line"
                                 name="existing_line">
                        <p class="lh-1p4 mb-2 text-dark">Lines</p>
                        <el-form
                            :model="existing_line_form"
                            ref="existing_line_form"
                            :rules="existing_line_form_rules">
                            <el-form-item
                                class="mb-3"
                                prop="selected_campaign">
                                <el-select v-model="existing_line_form.selected_campaign"
                                           class="w-full"
                                           placeholder="Select Line"
                                           :size="'small'"
                                           collapse-tags
                                           filterable
                                           @change="customPreValidateForm('existing_line_form')"
                                           @visible-change="customPreValidateForm('existing_line_form')">
                                    <el-option
                                        v-for="campaign in filteredCampaigns"
                                        :disabled="current_campaign.id  === campaign.id"
                                        :key="campaign.id"
                                        :label="campaign.name"
                                        :value="campaign.id">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-form>
                    </el-tab-pane>

                    <el-tab-pane label="New Line"
                                 name="new_line"
                                 :disabled="current_campaign.incoming_numbers.length === 1">
                        <p class="lh-1p4 mb-2 text-dark">Name</p>
                        <el-form
                            :model="new_line_form"
                            ref="new_line_form"
                            :rules="new_line_form_rules">
                            <el-form-item prop="name">
                                <el-input
                                    v-model="new_line_form.name"
                                    @input="customPreValidateForm('new_line_form')"></el-input>
                            </el-form-item>
                        </el-form>
                    </el-tab-pane>
                </el-tabs>

            <div>
                <el-button type="success"
                        size="small"
                        class="w-full"
                        :disabled="shouldDisableConfirmButton"
                        @click.prevent="submit()">
                    Confirm
                </el-button>
            </div>

        <el-tooltip content="Unable to transfer because this is part of the Local Presence package"
                    placement="left-start"
                    :disabled="!current_campaign.has_local_presence"
                    slot="reference">
            <span>
                <button class="btn btn-sm btn-block light-blue-500"
                        ref="popover_button"
                        :disabled="current_campaign.has_local_presence"
                        @click.prevent="popover_visible = !popover_visible">
                    <i class="fa fa-exchange-alt pull-left"></i>
                    Transfer
                </button>
            </span>
        </el-tooltip>
    </el-popover>
</template>

<script>
import { EventBus } from '../event-bus';
import { form_validation_mixin } from '../mixins'
import _ from "lodash";

export default {
    mixins: [form_validation_mixin],

    props: {
        current_campaign: {
            type: Object,
            required: true
        },
        campaigns: {
            type: Array,
            required: true
        },
        incoming_number: {
            type: Object,
            required: true
        },
    },

    name: 'transfer-phone-number-popover',

    data: () => ({
        tab_name: 'existing_line',
        loading: false,
        existing_line_form: {
            selected_campaign: null,
        },
        existing_line_form_rules: {
            selected_campaign: [
                {
                    required: true,
                    message: 'Line is required',
                    trigger: 'change'
                },
            ],
        },
        existing_line_form_validated: false,
        new_line_form: {
            name: null,
        },
        new_line_form_rules: {
            name: [
                {
                    required: true,
                    message: 'Please enter a Line name',
                    trigger: 'blur'
                },
            ],
        },
        new_line_form_validated: false,
        popover_visible: false,
    }),

    computed: {
        filteredCampaigns() {
            return this.campaigns.filter(campaign => campaign.is_hosted_sms === this.current_campaign.is_hosted_sms)
        },

        shouldDisableConfirmButton: function() {
            switch (this.tab_name) {
                case 'existing_line':
                    return !this.existing_line_form_validated
                case 'new_line':
                    return !this.new_line_form_validated
            }
        }
    },

    methods: {
        submit() {
            const { tab_name: transfer_type } = this;

            switch (transfer_type) {
                case 'existing_line':
                    this.transferNumber()
                    break
                case 'new_line':
                    this.transferNumberToNewLine()
                    break
            }
        },

        resetForms() {
            this.new_line_form.name = this.incoming_number.phone_number
            this.tab_name = 'new_line'
            this.customPreValidateForm('new_line_form') // this is needed to remove the error message from the new line name field

            this.existing_line_form.selected_campaign = null
            this.tab_name = 'existing_line'
            this.existing_line_form_validated = false
        },

        transferNumber() {
            const { incoming_number, existing_line_form } = this
            const { id, phone_number } = incoming_number
            const { selected_campaign } = existing_line_form

            this.$confirm('Are you sure you want to transfer this phone number?', 'Warning', {
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                customClass: 'width-500 fixed',
                cancelButtonClass: 'cancel-transfer-button',
                confirmButtonClass: 'confirm-transfer-button',
                type: 'warning',
            }).then(() => {
                this.loading = true
                this.popover_visible = false
                axios.put(`/api/v1/campaign/${this.current_campaign.id}/incoming-number/${id}`, {
                    transfer: true,
                    campaign_id: selected_campaign
                }).then(res => {
                    EventBus.$emit('transfer-complete', {
                        transferred_incoming_number: {
                            id,
                            phone_number
                        },
                        transfer_type: 'existing_line'
                    });
                }).catch(err => {
                    this.$root.handleErrors(err.response)
                }).finally(() => this.loading = false)
            }).catch(() => {
            })
        },

        transferNumberToNewLine() {
            const { incoming_number, new_line_form } = this
            const { id, phone_number } = incoming_number
            const { name: new_line_name } = new_line_form

            this.$confirm('Are you sure you want to transfer this phone number to a new line?', 'Warning', {
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                customClass: 'width-500 fixed',
                cancelButtonClass: 'cancel-transfer-button',
                confirmButtonClass: 'confirm-transfer-button',
                type: 'warning'
            }).then(() => {
                this.loading = true
                this.popover_visible = false
                axios.post(`/api/v1/campaign/new/incoming-number/${id}`, {
                    name: new_line_name,
                }).then(res => {
                    const { id: new_line_id } = res.data;

                    EventBus.$emit('transfer-complete', {
                        transferred_incoming_number: {
                            id,
                            phone_number,
                        },
                        transfer_type: 'new_line'
                    });

                    this.$router.push({ path: `/lines/${new_line_id}/settings` });
                }).catch(err => {
                    this.$root.handleErrors(err.response)
                }).finally(() => this.loading = false)
            }).catch(() => {
            })
        },

        onClose() {
            this.popover_visible = false;
        },

        customPreValidateForm: _.debounce( function(form_name) {
            let form_element = _.get(this.$refs, form_name, null)

            if (!form_element) {
                return
            }

            let fields = form_element.fields
            if (fields.find((f) => f.validateState === 'validating')) {
                setTimeout(() => {
                    this.customPreValidate(form_name)
                }, 100)

                return
            }

            if (this.validateForm(form_name)) {
                this.validated = true
                this[`${form_name}_validated`] = this.validated
                return
            }

            this.validated = false
            this[`${form_name}_validated`] = this.validated
        }, 100),

        preValidateBothForms() {
            this.customPreValidateForm('existing_line_form')
            this.customPreValidateForm('new_line_form')
        }
    }
}
</script>
