<template>
    <div>
        <p>{{ direction === CommunicationDirections.INBOUND ? 'Received' : 'Sent' }} too many messages {{ direction === CommunicationDirections.INBOUND ? 'from' : 'to' }} a contact.</p>
        <p>Name: {{ contact.name }}</p>
        <p v-if="campaignName">Line: {{ campaignName }}</p>
        <p>Incoming Number: {{ incomingNumber.phone_number | fixPhone }}</p>

        <div>
            <button class="btn rounded greenish btn-contact btn-sm mt-2 pull-right">
                Go To Contact
            </button>
        </div>
    </div>
</template>

<script>
    import auth from '../auth'
    import {acl_mixin, html_mixin} from '../mixins'
    import {mapState} from 'vuex'
    import * as CommunicationDirections from '../constants/communication-direction'

    export default {
        mixins: [acl_mixin, html_mixin],

        props: ['contact', 'incomingNumber', 'direction'],

        data() {
            return {
                auth: auth,
                CommunicationDirections
            }
        },

        computed: {
            ...mapState(['campaigns']),
            ...mapState('cache', ['current_company']),

            campaignName() {
                const campaign = this.campaigns.find(c => c.id === this.contact.campaign_id)
                return campaign ? campaign.name : ''
            }
        }
    }
</script>
