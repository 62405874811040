<template>
    <div>
        <div class="col-12">
            <p class="font-weight-bold side-panel-title">Condition Panel</p>
        </div>

        <div class="d-flex">
            <div class="col-4">
                <p class="mb-0 font-weight-bold">Field:</p>
                <el-input placeholder="Parameter"
                    v-model="nodeData.parameter">
                </el-input>
            </div>

            <div class="col-3">
                <p class="mb-0 font-weight-bold">Operator:</p>
                <el-select
                    v-model="nodeData.operator_type"
                    placeholder="Operator Type">
                    <el-option
                        v-for="operator in OPERATOR_TYPES"
                        v-bind:key="operator[0]"
                        :label="operator[1].label"
                        :value="operator[0]"/>
                </el-select>
            </div>

            <div class="col-5">
                <p class="mb-0 font-weight-bold">Value:</p>
                <el-input placeholder="Value"
                    v-model="nodeData.value">
                </el-input>
            </div>
        </div>

    </div>
</template>

<script>
import {alohabot_side_panel_mixin} from '../../../mixins'
import CONSTANTS from '../contants'

export default {
    name: 'condition-panel',
    mixins: [
        alohabot_side_panel_mixin
    ],
    methods: {

    },
    data() {
        return {
            CONSTANTS
        }
    },
    computed: {
        OPERATOR_TYPES () {
            return Object.entries(this.CONSTANTS.CONDITION_OPERATOR_TYPES)
        }
    },
}
</script>
