var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.hasPermissionTo("list sms template")
    ? _c(
        "el-popover",
        { attrs: { title: "Templates", width: "500" } },
        [
          _c(
            "div",
            {
              staticClass: "dialog-padding auto overflow-y-scroll",
              staticStyle: { "max-height": "500px" },
            },
            [
              _c("template-list", {
                attrs: { template_scope: "user", mode: "return" },
                on: { "template-selected": _vm.emitSelectedTemplate },
              }),
              _vm._v(" "),
              _c("template-list", {
                attrs: { template_scope: "company", mode: "return" },
                on: { "template-selected": _vm.emitSelectedTemplate },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticClass: "border-0 bg-transparent",
              attrs: {
                slot: "reference",
                id: "template-selector-trigger",
                size: "mini",
                circle: "",
                disabled: _vm.disabled,
              },
              slot: "reference",
            },
            [_c("i", { staticClass: "fa fa-file-code-o" })]
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }