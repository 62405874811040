<template>
    <el-dialog
        class="dialog-blank"
        width="35%"
        append-to-body
        :show-close="false"
        :before-close="handleClose"
        :visible.sync="form_visible">

        <div class="container-listing rounded">
            <div class="container-header d-flex">
                <h4 class="listing-heading">New Chatbot</h4>
                <div class="ml-auto">
                    <el-button type="text"
                               class="btn-dialog-close"
                               size="large"
                               @click="handleClose">
                        <i class="fa fa-remove"></i>
                    </el-button>
                </div>
            </div>
            <div class="container-body">
                <el-form :model="chatbot">

                    <el-form-item>
                        <div class="form-label">
                            <h5 class="text-dark">Name (*)</h5>
                            <small>
                                Specify a name for this chatbot.
                            </small>
                        </div>
                        <el-input v-model="chatbot.name"/>
                    </el-form-item>

                    <el-form-item>
                        <div class="form-label">
                            <h5 class="text-dark">Workflow (*)</h5>
                            <small>
                                Specify a workflow for this chatbot.
                            </small>
                        </div>
                        <el-input v-model="chatbot.workflow"/>
                    </el-form-item>

                    <el-form-item>
                        <div class="form-label">
                            <h5 class="text-dark">Lines (*)</h5>
                            <small>
                                Select 1 or multiple lines to connect to this chatbot.
                            </small>
                        </div>
                        <el-select
                            class="w-100"
                            v-model="chatbot.campaign_ids"
                            multiple
                            filterable
                            placeholder="Select lines">

                            <el-option
                                v-for="line in lines"
                                :key="line.id"
                                :label="line.name"
                                :value="line.id"/>

                        </el-select>
                    </el-form-item>

                    <el-form-item>
                        <div class="form-label">
                            <h5 class="text-dark">Notes</h5>
                            <small>
                                Write any notes about this chatbot.
                            </small>
                        </div>
                        <el-input
                            type="textarea"
                            :rows="2"
                            v-model="chatbot.notes"/>
                    </el-form-item>

                    <el-form-item>
                        <div class="row">
                            <div class="col-12">
                                <div class="d-flex">
                                    <el-button
                                        class="ml-auto mb-3"
                                        type="success"
                                        @click="createChatbot()"
                                        :disabled="creating_bot">
                                        <i v-if="creating_bot" class="fa fa-spinner fa-spin"/>
                                        <strong v-if="creating_bot"> Creating...</strong>
                                        <strong v-else> Create</strong>
                                    </el-button>
                                </div>
                            </div>
                        </div>
                    </el-form-item>

                </el-form>
            </div>
        </div>
    </el-dialog>
</template>

<script>
import _ from 'lodash'

export default {
    name: 'new-chatbot-form',
    props: {
        form_visible: {
            required: true,
            default: false
        },
        lines: {
            required: false,
            default: []
        }
    },
    data() {
        return {
            chatbot: {
                name: '',
                workflow: '',
                campaign_ids: [],
                notes: ''
            },
            creating_bot: false
        }
    },
    methods: {
        /**
         * Creates a new chatbot.
         *
         * @return {void}
         */
        createChatbot() {
            // Sanity check
            if(
                !this.chatbot.name ||
                !this.chatbot.workflow ||
                _.isEmpty(this.chatbot.campaign_ids)
            ) {
                this.$notify.info({
                    offset: 95,
                    title: 'Chatbots',
                    message: 'You must fill the required (*) fields.'
                })

                return
            }

            this.startLoading()

            axios.post('/api/v1/bots/builder/bot', { ...this.chatbot })
                .then(res => {
                    this.$emit('bot-created')
                    this.stopLoading()
                    this.resetForm()
                    this.$notify.success({
                        offset: 95,
                        title: 'Chatbots',
                        message: 'Your chatbot was created successfully.'
                    })
                }).catch(err => {
                    this.stopLoading()
                    this.$notify.error({
                        offset: 95,
                        title: 'Chatbots',
                        message: 'Your chatbot was not created.'
                    })
                    console.log(err)
                })
        },

        /**
         * Set all the components to loading state
         *
         * @return {void}
         */
        startLoading() {
            this.creating_bot = true
        },

        /**
         * Stop all components loading state
         *
         * @return {void}
         */
        stopLoading() {
            this.creating_bot = false
        },

        /**
         * Resets all the field used to create a chatbot.
         *
         * @return {void}
         */
        resetForm() {
            this.chatbot = {
                name: '',
                workflow: '',
                notes: '',
                campaign_ids: []
            }
            this.creating_bot = false
        },

        /**
         * Adds any additional logic before or after closing this dialog.
         *
         * @return {void}
         */
        handleClose() {
            // Show a confirmation dialog before closing the chatbot creation form
            this.$confirm('Are you sure you want to discard your new Chatbot?')
                .then(_ => {
                    this.$emit('bot-discarded')
                    this.resetForm()
                    // Close the dialog
                    done()
                })
                .catch(_ => {
                    // Do nothing if the user cancels the action
                })
        }
    }
}
</script>
