var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "chatbot-intents-selector" }, [
    _c("div", { staticClass: "col-12" }, [
      _c(
        "div",
        { staticClass: "intent-list" },
        _vm._l(_vm.node_intents, function (intent) {
          return _c(
            "div",
            { key: intent.name, staticClass: "mb-1 row px-2 py-2" },
            [
              _c(
                "span",
                {
                  staticClass: "mx-1",
                  attrs: {
                    closable: _vm.node_intents.length > 1,
                    "disable-transitions": false,
                  },
                },
                [
                  _vm._v(
                    "\n                    " +
                      _vm._s(intent.name) +
                      "\n                "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "span",
                {
                  staticClass: "ml-auto material-icons pt-1",
                  on: {
                    click: function ($event) {
                      return _vm.handleIntentClose(intent)
                    },
                  },
                },
                [_vm._v("\n                    close\n                ")]
              ),
            ]
          )
        }),
        0
      ),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "col-12 px-0" },
      [
        _c(
          "el-select",
          {
            staticClass: "inline-input w-100 mt-2",
            attrs: {
              filterable: "",
              placeholder: "Select a new intent",
              clearable: "",
            },
            on: { change: _vm.handleInputConfirm },
            model: {
              value: _vm.selected_intent,
              callback: function ($$v) {
                _vm.selected_intent = $$v
              },
              expression: "selected_intent",
            },
          },
          _vm._l(_vm.selectableIntents, function (intent) {
            return _c("el-option", {
              key: intent.id,
              attrs: { label: intent.name, value: intent },
            })
          }),
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }