<template>
    <div class="app-body messenger-container"
         :style="{height: '100vh'}"
         id="view">
        <iframe frameborder="0"
                :src="source">
        </iframe>
    </div>
</template>

<script>
    import {chargebee_mixin, scroll_mixin} from '../../mixins'
    import auth from "../../auth";
    export default {
        mixins: [chargebee_mixin, scroll_mixin],
        data() {
            return {
                source : ''
            }
        },
        mounted() {
            axios.get('/integrations/simpsocial/messenger-source').then(res => {
                this.source = res.data.source
            }).catch(err => {
                console.log(err)
            })
        },
        beforeRouteEnter(to, from, next) {
            auth.check()
                .then((res) => {
                    if (res.data.user.is_reseller) {
                        // redirect to account management portal if the company is a reseller
                        next({name: 'Account Management Portal'})
                    } else {
                        next()
                    }
                })
                .catch((err) => {
                    next({name: 'Login', query: {redirect: to.fullPath}})
                })
        }
    }
</script>
