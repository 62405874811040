<template>
    <node-template
        :id="nodeId"
        :onlyHeader="true"
        :hide_edit_button="true"
        :hide_close_button="true">

        <template v-slot:header>
            <span>Start</span>
        </template>

    </node-template>
</template>

<script>
import { alohabot_node_mixin  } from '../../../mixins'

export default {
    name: 'start-node',
    mixins: [
        alohabot_node_mixin
    ]
}
</script>
