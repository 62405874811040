var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.user
    ? _c("div", [
        _c("p", [_vm._v("You have a new message")]),
        _vm._v(" "),
        _c("p", [_vm._v("Message: " + _vm._s(_vm.message))]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }