var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-form",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading_whitelabel,
          expression: "loading_whitelabel",
        },
      ],
      ref: "companySettingsForm",
      staticClass: "form-aloware",
      attrs: { model: _vm.contact_settings, rules: _vm.rules },
      nativeOn: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.submitForm("companySettingsForm")
        },
      },
    },
    [
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-md-7" },
          [
            _c(
              "el-form-item",
              { attrs: { prop: "appointment_adp_recipients" } },
              [
                _c("span", {
                  staticClass: "page-anchor",
                  attrs: { id: "appointment_adp_recipients" },
                }),
                _vm._v(" "),
                _c("div", { staticClass: "form-label" }, [
                  _c("h5", [_vm._v("Appointment Recipients")]),
                  _vm._v(" "),
                  _c("small", [
                    _vm._v(
                      "\n                        You can copy some emails (or smart calendars!) to receive a copy\n                        of your appointments set on the Calendar.\n                    "
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "el-select",
                  {
                    staticClass: "w-full",
                    attrs: {
                      "allow-create": "",
                      "default-first-option": "",
                      filterable: "",
                      multiple: "",
                      clearable: "",
                      placeholder: "Select or create a new recipient.",
                    },
                    on: { change: _vm.updateParent },
                    model: {
                      value: _vm.contact_settings.appointment_adp_recipients,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.contact_settings,
                          "appointment_adp_recipients",
                          $$v
                        )
                      },
                      expression: "contact_settings.appointment_adp_recipients",
                    },
                  },
                  _vm._l(_vm.users, function (user) {
                    return _c(
                      "el-option",
                      {
                        key: user.id,
                        attrs: { label: user.email, value: user.email },
                      },
                      [
                        _c("div", { staticClass: "media" }, [
                          _c("div", { staticClass: "media-body" }, [
                            _c("label", [_vm._v(_vm._s(user.name))]),
                            _vm._v(" "),
                            _c("small", [_vm._v(_vm._s(user.email))]),
                          ]),
                        ]),
                      ]
                    )
                  }),
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              { attrs: { prop: "lead_recipients" } },
              [
                _c("span", {
                  staticClass: "page-anchor",
                  attrs: { id: "lead_recipients" },
                }),
                _vm._v(" "),
                _c("div", { staticClass: "form-label" }, [
                  _c("h5", [_vm._v("Lead Recipients")]),
                  _vm._v(" "),
                  _c("small", [
                    _vm._v(
                      "\n                        We can forward a lead we receive through our API. email\n                        processor, or other mechanisms to an email.\n                    "
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "el-select",
                  {
                    staticClass: "w-full",
                    attrs: {
                      "allow-create": "",
                      "default-first-option": "",
                      filterable: "",
                      multiple: "",
                      clearable: "",
                      placeholder: "Select or create a new recipient.",
                    },
                    on: { change: _vm.updateParent },
                    model: {
                      value: _vm.contact_settings.lead_recipients,
                      callback: function ($$v) {
                        _vm.$set(_vm.contact_settings, "lead_recipients", $$v)
                      },
                      expression: "contact_settings.lead_recipients",
                    },
                  },
                  _vm._l(_vm.users, function (user) {
                    return _c(
                      "el-option",
                      {
                        key: user.id,
                        attrs: { label: user.email, value: user.email },
                      },
                      [
                        _c("div", { staticClass: "media" }, [
                          _c("div", { staticClass: "media-body" }, [
                            _c("label", [_vm._v(_vm._s(user.name))]),
                            _vm._v(" "),
                            _c("small", [_vm._v(_vm._s(user.email))]),
                          ]),
                        ]),
                      ]
                    )
                  }),
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              { attrs: { prop: "default_contact_date_filter" } },
              [
                _c("span", {
                  staticClass: "page-anchor",
                  attrs: { id: "default_contact_date_filter" },
                }),
                _vm._v(" "),
                _c("div", { staticClass: "form-label" }, [
                  _c("h5", [_vm._v("Default Contact Date Filter")]),
                  _vm._v(" "),
                  _c("small", [
                    _vm._v(
                      "\n                        How the Contacts page should be sorted.\n                    "
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "el-select",
                  {
                    staticClass: "w-full",
                    attrs: { placeholder: "Select" },
                    on: { change: _vm.updateParent },
                    model: {
                      value: _vm.contact_settings.default_contact_date_filter,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.contact_settings,
                          "default_contact_date_filter",
                          $$v
                        )
                      },
                      expression:
                        "contact_settings.default_contact_date_filter",
                    },
                  },
                  [
                    _c("el-option", {
                      attrs: {
                        label: "Engagement",
                        value:
                          _vm.DefaultContactDateFilter
                            .DEFAULT_CONTACT_DATE_FILTER_ENGAGEMENT,
                      },
                    }),
                    _vm._v(" "),
                    _c("el-option", {
                      attrs: {
                        label: "Created At",
                        value:
                          _vm.DefaultContactDateFilter
                            .DEFAULT_CONTACT_DATE_FILTER_CREATED_AT,
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              { attrs: { prop: "auto_assign_contacts_enabled" } },
              [
                _c("span", {
                  staticClass: "page-anchor",
                  attrs: { id: "auto_assign_contacts_enabled" },
                }),
                _vm._v(" "),
                _c("div", { staticClass: "form-label" }, [
                  _c("h5", [_vm._v("Auto Assign Contact")]),
                  _vm._v(" "),
                  _c("small", [
                    _vm._v(
                      "With Auto Assign, any new contact added in Aloware will be assigned to the user who makes the first communication with the contact."
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("el-switch", {
                  attrs: { "active-color": "#00a344" },
                  on: { change: _vm.handleAutoAssignContactChange },
                  model: {
                    value: _vm.contact_settings.auto_assign_contacts_enabled,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.contact_settings,
                        "auto_assign_contacts_enabled",
                        $$v
                      )
                    },
                    expression: "contact_settings.auto_assign_contacts_enabled",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            !_vm.contact_settings.auto_assign_contacts_enabled
              ? _c(
                  "el-form-item",
                  { attrs: { prop: "default_contact_owner" } },
                  [
                    _c("span", {
                      staticClass: "page-anchor",
                      attrs: { id: "default_contact_owner" },
                    }),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-label" }, [
                      _c("h5", [_vm._v("Default Contact Owner")]),
                      _vm._v(" "),
                      _c("small", [
                        _vm._v(
                          "You can choose the Default owner of new contacts here."
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c(
                      "el-select",
                      {
                        staticClass: "w-full",
                        attrs: {
                          "allow-create": "",
                          "default-first-option": "",
                          filterable: "",
                          clearable: "",
                          placeholder: "Select the default contact owner",
                        },
                        on: { change: _vm.handleAutoAssignContactChange },
                        model: {
                          value: _vm.contact_settings.default_contact_owner,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.contact_settings,
                              "default_contact_owner",
                              $$v
                            )
                          },
                          expression: "contact_settings.default_contact_owner",
                        },
                      },
                      _vm._l(_vm.users, function (user) {
                        return _c(
                          "el-option",
                          {
                            key: user.id,
                            attrs: { label: user.email, value: user.id },
                          },
                          [
                            _c("div", { staticClass: "media" }, [
                              _c("div", { staticClass: "media-body" }, [
                                _c("label", [_vm._v(_vm._s(user.name))]),
                                _vm._v(" "),
                                _c("small", [_vm._v(_vm._s(user.email))]),
                              ]),
                            ]),
                          ]
                        )
                      }),
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "el-form-item",
              { attrs: { prop: "sms_reminder_enabled" } },
              [
                _c("div", { staticClass: "form-label" }, [
                  _c("span", {
                    staticClass: "page-anchor",
                    attrs: { id: "sms_reminder_enabled" },
                  }),
                  _vm._v(" "),
                  _c("h5", [_vm._v("SMS reminder enabled")]),
                ]),
                _vm._v(" "),
                _c("el-switch", {
                  attrs: { "active-color": "#00a344" },
                  on: { change: _vm.updateParent },
                  model: {
                    value: _vm.contact_settings.sms_reminder_enabled,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.contact_settings,
                        "sms_reminder_enabled",
                        $$v
                      )
                    },
                    expression: "contact_settings.sms_reminder_enabled",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              { attrs: { prop: "sms_reminder_default_text" } },
              [
                _c("div", { staticClass: "form-label" }, [
                  _c("span", {
                    staticClass: "page-anchor",
                    attrs: { id: "sms_reminder_default_text" },
                  }),
                  _vm._v(" "),
                  _c("h5", [_vm._v("SMS reminder default text")]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "pb-2 line-he" },
                    _vm._l(
                      _vm.sms_reminder_template_variables,
                      function (item) {
                        return _c(
                          "span",
                          {
                            key: item,
                            staticClass:
                              "text-danger sms-reminder-template-variables",
                            on: {
                              click: function ($event) {
                                return _vm.addTemplateVariableToSmsReminderText(
                                  item
                                )
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n                            " +
                                _vm._s(item) +
                                "\n                        "
                            ),
                          ]
                        )
                      }
                    ),
                    0
                  ),
                ]),
                _vm._v(" "),
                _c("el-input", {
                  ref: "smsReminderDefaultTxt",
                  attrs: {
                    type: "textarea",
                    maxlength: "255",
                    "show-word-limit": "",
                  },
                  on: { blur: _vm.updateSmsReminderText },
                  model: {
                    value: _vm.contact_settings.sms_reminder_default_text,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.contact_settings,
                        "sms_reminder_default_text",
                        $$v
                      )
                    },
                    expression: "contact_settings.sms_reminder_default_text",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              { attrs: { prop: "sms_reminder_default_campaign_id" } },
              [
                _c("div", { staticClass: "form-label" }, [
                  _c("h5", [_vm._v("SMS reminder use personal line")]),
                  _vm._v(" "),
                  _c("small", [
                    _vm._v(
                      "\n                        Use User's Personal Line as default line\n                    "
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("el-switch", {
                  attrs: { "active-color": "#00a344" },
                  on: { change: _vm.updateParent },
                  model: {
                    value: _vm.contact_settings.sms_reminder_use_personal_line,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.contact_settings,
                        "sms_reminder_use_personal_line",
                        $$v
                      )
                    },
                    expression:
                      "contact_settings.sms_reminder_use_personal_line",
                  },
                }),
                _vm._v(" "),
                !_vm.contact_settings.sms_reminder_use_personal_line
                  ? _c(
                      "div",
                      [
                        _c("div", { staticClass: "form-label mt-4" }, [
                          _c("span", {
                            staticClass: "page-anchor",
                            attrs: { id: "sms_reminder_default_campaign_id" },
                          }),
                          _vm._v(" "),
                          _c("h5", [_vm._v("SMS reminder default line")]),
                        ]),
                        _vm._v(" "),
                        _c("campaign-selector", {
                          attrs: {
                            class_prop: "w-100",
                            clearable: true,
                            show_paused: false,
                          },
                          on: { change: _vm.updateSmsReminderCampaign },
                          model: {
                            value:
                              _vm.contact_settings
                                .sms_reminder_default_campaign_id,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.contact_settings,
                                "sms_reminder_default_campaign_id",
                                $$v
                              )
                            },
                            expression:
                              "contact_settings.sms_reminder_default_campaign_id",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              { attrs: { prop: "sms_reminder_default_time" } },
              [
                _c("div", { staticClass: "form-label" }, [
                  _c("span", {
                    staticClass: "page-anchor",
                    attrs: { id: "sms_reminder_default_time" },
                  }),
                  _vm._v(" "),
                  _c("h5", [_vm._v("SMS reminder default time")]),
                ]),
                _vm._v(" "),
                _c(
                  "el-select",
                  {
                    staticClass: "w-100",
                    attrs: {
                      placeholder: "Select time",
                      filterable: "",
                      clearable: "",
                    },
                    on: { change: _vm.updateParent },
                    model: {
                      value: _vm.contact_settings.sms_reminder_default_time,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.contact_settings,
                          "sms_reminder_default_time",
                          $$v
                        )
                      },
                      expression: "contact_settings.sms_reminder_default_time",
                    },
                  },
                  _vm._l(_vm.times, function (time) {
                    return _c("el-option", {
                      key: time.value,
                      attrs: { label: time.label, value: time.value },
                    })
                  }),
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              { attrs: { prop: "sms_reminder_default_send_before_days" } },
              [
                _c("div", { staticClass: "form-label" }, [
                  _c("span", {
                    staticClass: "page-anchor",
                    attrs: { id: "sms_reminder_default_send_before_days" },
                  }),
                  _vm._v(" "),
                  _c("h5", [_vm._v("SMS reminder default send before days")]),
                ]),
                _vm._v(" "),
                _c(
                  "el-select",
                  {
                    staticClass: "w-full",
                    attrs: { filterable: "", multiple: "" },
                    on: { change: _vm.updateSmsReminderSendBeforeDays },
                    model: {
                      value: _vm.sms_reminder_default_send_before_days,
                      callback: function ($$v) {
                        _vm.sms_reminder_default_send_before_days = $$v
                      },
                      expression: "sms_reminder_default_send_before_days",
                    },
                  },
                  [
                    _c("el-option", {
                      attrs: { value: "0", label: "On appointment day" },
                    }),
                    _vm._v(" "),
                    _c("el-option", {
                      attrs: { value: "1", label: "1 day before" },
                    }),
                    _vm._v(" "),
                    _c("el-option", {
                      attrs: { value: "2", label: "2 days before" },
                    }),
                    _vm._v(" "),
                    _c("el-option", {
                      attrs: { value: "3", label: "3 days before" },
                    }),
                    _vm._v(" "),
                    _c("el-option", {
                      attrs: { value: "4", label: "4 days before" },
                    }),
                    _vm._v(" "),
                    _c("el-option", {
                      attrs: { value: "5", label: "5 days before" },
                    }),
                    _vm._v(" "),
                    _c("el-option", {
                      attrs: { value: "6", label: "6 days before" },
                    }),
                    _vm._v(" "),
                    _c("el-option", {
                      attrs: { value: "7", label: "7 days before" },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              {
                staticClass: "pb-1",
                attrs: { prop: "returning_lead_as_new_lead" },
              },
              [
                _c("span", {
                  staticClass: "page-anchor",
                  attrs: { id: "returning_lead_as_new_lead" },
                }),
                _vm._v(" "),
                _c("div", { staticClass: "form-label" }, [
                  _c("h5", [_vm._v("New Lead Settings")]),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "switch-label" },
                  [
                    _c(
                      "el-radio-group",
                      {
                        on: { change: _vm.updateParent },
                        model: {
                          value:
                            _vm.contact_settings.returning_lead_as_new_lead,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.contact_settings,
                              "returning_lead_as_new_lead",
                              $$v
                            )
                          },
                          expression:
                            "contact_settings.returning_lead_as_new_lead",
                        },
                      },
                      [
                        _c(
                          "el-radio",
                          { staticClass: "d-flex", attrs: { label: false } },
                          [
                            _c("div", [
                              _c("span", [
                                _vm._v(
                                  "\n                                    Default\n                                "
                                ),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "font-weight-light" }, [
                                _vm._v(
                                  "\n                                    Existing contacts re-added to the platform will not be treated as new leads. Contacts will be ignored or updated (if they comes from an intake API)\n                                "
                                ),
                              ]),
                            ]),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-radio",
                          { staticClass: "d-flex", attrs: { label: true } },
                          [
                            _c("div", [
                              _c("span", [
                                _vm._v(
                                  "\n                                    Treat existing contacts re-added to platform as new leads\n                                "
                                ),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "font-weight-light" }, [
                                _vm._v(
                                  "\n                                    If any existing contacts are re-added to " +
                                    _vm._s(_vm.statics.name) +
                                    " through one of the following methods, they will be considered new leads:\n                                    "
                                ),
                                _c("ul", { staticClass: "ml-2" }, [
                                  _c("li", [_vm._v("Zapier")]),
                                  _vm._v(" "),
                                  _c("li", [_vm._v("Webhook (API)")]),
                                  _vm._v(" "),
                                  _c("li", [_vm._v("ADF/XML (email)")]),
                                  _vm._v(" "),
                                  _c("li", [_vm._v("Webform (API)")]),
                                  _vm._v(" "),
                                  _c("li", [_vm._v("Facebook leadgen form")]),
                                ]),
                              ]),
                            ]),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.isSimpSocial
                  ? _c(
                      "div",
                      { staticClass: "switch-label" },
                      [
                        _c("el-switch", {
                          attrs: { "active-color": "#00a344" },
                          on: { change: _vm.updateParent },
                          model: {
                            value: _vm.contact_settings.undnc_readded_contacts,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.contact_settings,
                                "undnc_readded_contacts",
                                $$v
                              )
                            },
                            expression:
                              "contact_settings.undnc_readded_contacts",
                          },
                        }),
                        _vm._v(" "),
                        _c("label", [
                          _vm._v(
                            "Automatically unDNC a contact when they resubmit a form"
                          ),
                        ]),
                        _vm._v(" "),
                        _c("br"),
                        _vm._v(" "),
                        _c(
                          "a",
                          {
                            staticClass:
                              "text-decoration-underline el-link el-link--primary mt-1",
                            attrs: {
                              href: "https://simpsocial.s3.us-east-2.amazonaws.com/assets/DNC-article.pdf",
                              target: "_blank",
                            },
                          },
                          [
                            _vm._v(
                              "\n                        See More Details\n                    "
                            ),
                          ]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ]
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              {
                attrs: { prop: "archive_communications_from_blocked_contacts" },
              },
              [
                _c("span", {
                  staticClass: "page-anchor",
                  attrs: { id: "archive_communications_from_blocked_contacts" },
                }),
                _vm._v(" "),
                _c("div", { staticClass: "form-label" }, [
                  _c("h5", [
                    _vm._v(
                      "Reject & Archive Communications From Blocked Contacts"
                    ),
                  ]),
                  _vm._v(" "),
                  _c("ul", { staticClass: "mt-3" }, [
                    _c("li", [
                      _vm._v(
                        "\n                            By default, the application doesn't route communications from blocked contacts; but they still show up in logs and reports.\n                        "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("li", [
                      _vm._v(
                        "\n                            By enabling this setting, the application rejects and archives communications from blocked contacts on the platform. These communications won’t appear in call logs or reports.\n                        "
                      ),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "switch-label" },
                  [
                    _c("el-switch", {
                      attrs: { "active-color": "#00a344" },
                      on: { change: _vm.updateParent },
                      model: {
                        value:
                          _vm.contact_settings
                            .archive_communications_from_blocked_contacts,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.contact_settings,
                            "archive_communications_from_blocked_contacts",
                            $$v
                          )
                        },
                        expression:
                          "contact_settings.archive_communications_from_blocked_contacts",
                      },
                    }),
                    _vm._v(" "),
                    _c("label", [
                      _vm._v(
                        "Enable reject & archive communications from blocked contacts"
                      ),
                    ]),
                  ],
                  1
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              {
                staticClass: "pb-1",
                attrs: { prop: "mark_all_as_read_enabled" },
              },
              [
                _c("span", {
                  staticClass: "page-anchor",
                  attrs: { id: "mark_all_as_read_enabled" },
                }),
                _vm._v(" "),
                _c("div", { staticClass: "form-label" }, [
                  _c("h5", [_vm._v("'Mark All Contacts As Read' Option")]),
                  _vm._v(" "),
                  _c("small", [
                    _vm._v(
                      "\n                        Give any Agent or Admin the ability to 'Mark All Contacts as Read' on the Contacts Page\n                    "
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "switch-label" },
                  [
                    _c("el-switch", {
                      attrs: { "active-color": "#00a344" },
                      on: { change: _vm.updateParent },
                      model: {
                        value: _vm.contact_settings.mark_all_as_read_enabled,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.contact_settings,
                            "mark_all_as_read_enabled",
                            $$v
                          )
                        },
                        expression: "contact_settings.mark_all_as_read_enabled",
                      },
                    }),
                    _vm._v(" "),
                    _c("label", [
                      _vm._v("Enable 'Mark All Contacts As Read' Option"),
                    ]),
                  ],
                  1
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              { staticClass: "pb-1", attrs: { prop: "enforce_tcpa" } },
              [
                _c("span", {
                  staticClass: "page-anchor",
                  attrs: { id: "enforce_tcpa" },
                }),
                _vm._v(" "),
                _c("div", { staticClass: "form-label" }, [
                  _c("h5", [
                    _vm._v(
                      "'Prevent Messaging to Non TCPA Approved Contacts' Option"
                    ),
                  ]),
                  _vm._v(" "),
                  _c("small", [
                    _vm._v(
                      "\n                        Prevents sending message to the contact that is not TCPA Approved\n                    "
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "switch-label" },
                  [
                    _c("el-switch", {
                      attrs: { "active-color": "#00a344" },
                      on: { change: _vm.updateParent },
                      model: {
                        value: _vm.contact_settings.enforce_tcpa,
                        callback: function ($$v) {
                          _vm.$set(_vm.contact_settings, "enforce_tcpa", $$v)
                        },
                        expression: "contact_settings.enforce_tcpa",
                      },
                    }),
                    _vm._v(" "),
                    _c("label", [
                      _vm._v(
                        "Enable 'Prevent Messaging to Non TCPA Approved Contacts' Option"
                      ),
                    ]),
                  ],
                  1
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              {
                staticClass: "pb-1",
                attrs: { prop: "automatically_tag_imported_contacts" },
              },
              [
                _c("span", {
                  staticClass: "page-anchor",
                  attrs: { id: "automatically_tag_imported_contacts" },
                }),
                _vm._v(" "),
                _c("div", { staticClass: "form-label" }, [
                  _c("h5", [_vm._v("Automatically tag imported contacts")]),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "switch-label" },
                  [
                    _c("el-switch", {
                      attrs: { "active-color": "#00a344" },
                      on: { change: _vm.updateParent },
                      model: {
                        value:
                          _vm.contact_settings
                            .automatically_tag_imported_contacts,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.contact_settings,
                            "automatically_tag_imported_contacts",
                            $$v
                          )
                        },
                        expression:
                          "contact_settings.automatically_tag_imported_contacts",
                      },
                    }),
                    _vm._v(" "),
                    _c("label", [
                      _vm._v("Automatically tag imported contacts"),
                    ]),
                  ],
                  1
                ),
              ]
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "col-md-2 d-none d-md-block ml-auto" }, [
          _c(
            "div",
            {
              staticClass:
                "list-group list-group-alo list-group-flush sticky-top stick-top-with-header top-70",
            },
            [
              _c("h6", { staticClass: "list-group-item" }, [
                _vm._v("\n                    On This Page\n                "),
              ]),
              _vm._v(" "),
              _c(
                "a",
                {
                  staticClass: "list-group-item list-group-item-action",
                  attrs: { href: "#appointment_adp_recipients" },
                  on: {
                    click: function ($event) {
                      return _vm.shine("appointment_adp_recipients")
                    },
                  },
                },
                [
                  _vm._v(
                    "\n                    Appointment Recipients\n                "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "a",
                {
                  staticClass: "list-group-item list-group-item-action",
                  attrs: { href: "#lead_recipients" },
                  on: {
                    click: function ($event) {
                      return _vm.shine("lead_recipients")
                    },
                  },
                },
                [
                  _vm._v(
                    "\n                    Lead Recipients\n                "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "a",
                {
                  staticClass: "list-group-item list-group-item-action",
                  attrs: { href: "#default_contact_date_filter" },
                  on: {
                    click: function ($event) {
                      return _vm.shine("default_contact_date_filter")
                    },
                  },
                },
                [
                  _vm._v(
                    "\n                    Default Contact Date Filter\n                "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "a",
                {
                  staticClass: "list-group-item list-group-item-action",
                  attrs: { href: "#auto_assign_contacts_enabled" },
                  on: {
                    click: function ($event) {
                      return _vm.shine("auto_assign_contacts_enabled")
                    },
                  },
                },
                [
                  _vm._v(
                    "\n                    Auto Assign Contact\n                "
                  ),
                ]
              ),
              _vm._v(" "),
              !_vm.contact_settings.auto_assign_contacts_enabled
                ? _c(
                    "a",
                    {
                      staticClass: "list-group-item list-group-item-action",
                      attrs: { href: "#default_contact_owner" },
                      on: {
                        click: function ($event) {
                          return _vm.shine("default_contact_owner")
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n                    Default Contact Owner\n                "
                      ),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "a",
                {
                  staticClass: "list-group-item list-group-item-action",
                  attrs: { href: "#sms_reminder_enabled" },
                  on: {
                    click: function ($event) {
                      return _vm.shine("sms_reminder_enabled")
                    },
                  },
                },
                [_vm._v("\n                    SMS Reminder\n                ")]
              ),
              _vm._v(" "),
              _c(
                "a",
                {
                  staticClass: "list-group-item list-group-item-action",
                  attrs: { href: "#sms_reminder_default_text" },
                  on: {
                    click: function ($event) {
                      return _vm.shine("sms_reminder_default_text")
                    },
                  },
                },
                [
                  _vm._v(
                    "\n                    SMS Reminder Default Text\n                "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "a",
                {
                  staticClass: "list-group-item list-group-item-action",
                  attrs: { href: "#sms_reminder_default_campaign_id" },
                  on: {
                    click: function ($event) {
                      return _vm.shine("sms_reminder_default_campaign_id")
                    },
                  },
                },
                [
                  _vm._v(
                    "\n                    SMS Reminder Default Line\n                "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "a",
                {
                  staticClass: "list-group-item list-group-item-action",
                  attrs: { href: "#sms_reminder_default_time" },
                  on: {
                    click: function ($event) {
                      return _vm.shine("sms_reminder_default_time")
                    },
                  },
                },
                [
                  _vm._v(
                    "\n                    SMS Reminder Default Time\n                "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "a",
                {
                  staticClass: "list-group-item list-group-item-action",
                  attrs: { href: "#sms_reminder_default_send_before_days" },
                  on: {
                    click: function ($event) {
                      return _vm.shine("sms_reminder_default_send_before_days")
                    },
                  },
                },
                [
                  _vm._v(
                    "\n                    SMS Reminder Default Send Before Days\n                "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "a",
                {
                  staticClass: "list-group-item list-group-item-action",
                  attrs: { href: "#returning_lead_as_new_lead" },
                  on: {
                    click: function ($event) {
                      return _vm.shine("returning_lead_as_new_lead")
                    },
                  },
                },
                [
                  _vm._v(
                    "\n                    New Lead Settings\n                "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "a",
                {
                  staticClass: "list-group-item list-group-item-action",
                  attrs: {
                    href: "#archive_communications_from_blocked_contacts",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.shine(
                        "archive_communications_from_blocked_contacts"
                      )
                    },
                  },
                },
                [
                  _vm._v(
                    "\n                    Reject & Archive Communications From Blocked Contacts\n                "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "a",
                {
                  staticClass: "list-group-item list-group-item-action",
                  attrs: { href: "#mark_all_as_read_enabled" },
                  on: {
                    click: function ($event) {
                      return _vm.shine("mark_all_as_read_enabled")
                    },
                  },
                },
                [
                  _vm._v(
                    "\n                    'Mark All Contacts As Read' Option\n                "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "a",
                {
                  staticClass: "list-group-item list-group-item-action",
                  attrs: { href: "#enforce_tcpa" },
                  on: {
                    click: function ($event) {
                      return _vm.shine("enforce_tcpa")
                    },
                  },
                },
                [
                  _vm._v(
                    "\n                    'Prevent Messaging to Non TCPA Approved Contacts' Option\n                "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "a",
                {
                  staticClass: "list-group-item list-group-item-action",
                  attrs: { href: "#automatically_tag_imported_contacts" },
                  on: {
                    click: function ($event) {
                      return _vm.shine("automatically_tag_imported_contacts")
                    },
                  },
                },
                [
                  _vm._v(
                    "\n                   'Automatically tag imported contacts' Option\n                "
                  ),
                ]
              ),
            ]
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }