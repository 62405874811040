<template>
    <el-popover placement="right-start"
                width="350"
                trigger="click"
                @show="resetForm">
        <el-form>
            <el-form-item class="mb-3">
                <div scope="label"
                     class="form-label text-dark">
                    <h5 class="mb-2 text-black">Add tasks to user's PowerDialer</h5>
                    <p class="lh-1p4">
                        This tool will respect user's visibility limits
                    </p>
                </div>

                <p class="lh-1p4 mb-2 text-dark">Add tasks to this user's PowerDialer:</p>
                <user-selector ref="userSelector"
                               class="mb-2"
                               v-model="user_id"
                               :hide_extensions="true"
                               clearable
                               @change="changeUserId">
                </user-selector>

                <el-form-item class="mb-2">
                    <div scope="label"
                         class="form-label mb-1">
                        <label>Push contacts to</label>
                    </div>
                    <div class="row">
                        <div class="col">
                            <el-radio v-model="direction"
                                      :label="PowerDialer.DIRECTION_BOTTOM"
                                      :value="PowerDialer.DIRECTION_BOTTOM"
                                      class="w-full"
                                      border
                                      size="medium">
                                Bottom
                            </el-radio>
                        </div>
                        <div class="col">
                            <el-radio v-model="direction"
                                      :label="PowerDialer.DIRECTION_TOP"
                                      :value="PowerDialer.DIRECTION_TOP"
                                      class="w-full"
                                      border
                                      size="medium">
                                Top
                            </el-radio>
                        </div>
                    </div>
                </el-form-item>
                <el-form-item class="mb-2">
                    <div scope="label"
                         class="form-label">
                        <el-checkbox v-model="multiple_phone_numbers"
                                     label="Add multiple phone numbers?"
                                     class="w-full">
                        </el-checkbox>
                    </div>
                </el-form-item>
                <el-form-item>
                    <div scope="label"
                         class="form-label mb-1">
                        <el-checkbox v-model="prevent_duplicates"
                                     label="Prevent duplicate task creation"
                                     class="w-full">
                        </el-checkbox>
                    </div>
                </el-form-item>
            </el-form-item>
            <div>
                <el-button type="success"
                           size="small"
                           class="w-full"
                           :disabled="!user_id"
                           @click="addTasks">
                    Add Tasks
                </el-button>
            </div>
        </el-form>
        <button class="btn btn-block deep-purple btn-sm"
                slot="reference">
            <i class="fa fa-phone pull-left"></i>
            <slot>Add To PowerDialer</slot>
        </button>
    </el-popover>
</template>

<script>
import auth from "../auth"
import * as PowerDialer from '../constants/power-dialer'

export default {
    name: "add-tasks-to-user-power-dialer",

    props: {
        tag: {
            type: Object,
            required: true
        }
    },

    data() {
        return {
            auth: auth,
            user_id: null,
            multiple_phone_numbers: false,
            prevent_duplicates: true,
            direction: PowerDialer.DIRECTION_BOTTOM,
            PowerDialer
        }
    },

    methods: {
        addTasks() {
            this.$confirm(`Are you sure you want to add the contacts under this tag to to this user's PowerDialer?`, 'Event', {
                confirmButtonText: "Yes",
                cancelButtonText: "No",
                type: 'warning',
                customClass: 'width-500 fixed'
            }).then(() => {
                let params = {
                    user_id: this.user_id,
                    direction: this.direction,
                    multiple_phone_numbers: this.multiple_phone_numbers,
                    prevent_duplicates: this.prevent_duplicates,
                }
                axios.post(`/api/v1/tags/${this.tag.id}/add-to-user-power-dialer`, params).then(res => {
                    this.$notify({
                        offset: 95,
                        title: 'Add To PowerDialer',
                        message: res.data.message,
                        type: 'success',
                        showClose: true,
                    })
                    this.resetForm()
                }).catch(err => {
                    this.$root.handleErrors(err.response)
                    console.log(err)
                })
            })
        },

        changeUserId(user_id) {
            this.user_id = user_id
        },

        resetForm() {
            this.user_id = null
            this.multiple_phone_numbers = false
            this.prevent_duplicates = true
            this.direction = PowerDialer.DIRECTION_BOTTOM
        }
    },
}
</script>
