var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "message" }, [
    _c(
      "div",
      {
        staticClass: "d-flex flex-column",
        class: [
          _vm.communication.direction === _vm.CommunicationDirection.INBOUND
            ? "align-items-start"
            : "align-items-end text-right",
        ],
      },
      [
        _c(
          "el-badge",
          {
            staticClass: "item px-2 d-flex flex-column",
            class: [
              _vm.communication.direction == _vm.CommunicationDirection.INBOUND
                ? "align-items-start"
                : "align-items-end",
            ],
          },
          [
            _vm.communication.body
              ? _c(
                  "div",
                  {
                    staticClass: "inline r-2x effect7 mt-1",
                    class: _vm.getCommunicationClass,
                  },
                  [
                    _c("span", {
                      staticClass: "arrow pull-top",
                      class: [
                        _vm.communication.direction ===
                        _vm.CommunicationDirection.INBOUND
                          ? "arrow-dker left"
                          : "arrow-dker right",
                      ],
                    }),
                    _vm._v(" "),
                    _c("div", { staticClass: "p-a p-y-sm handle-whitespace" }, [
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "linkified",
                              rawName: "v-linkified:options",
                              value: { target: "_blank" },
                              expression: "{ target: '_blank' }",
                              arg: "options",
                            },
                          ],
                        },
                        [_vm._v(_vm._s(_vm.communication.body))]
                      ),
                    ]),
                  ]
                )
              : _vm._e(),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }