var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-tooltip",
    {
      staticClass: "item",
      attrs: {
        effect: "dark",
        content: _vm.data.message,
        disabled: _vm.emptyMessage || _vm.shouldHideTooltip,
        "append-to": "#" + _vm.nodeId,
        placement: "bottom",
      },
    },
    [
      _c(
        "node-template",
        {
          attrs: { id: _vm.nodeId },
          scopedSlots: _vm._u([
            {
              key: "header",
              fn: function () {
                return [_c("span", [_vm._v("Send Message")])]
              },
              proxy: true,
            },
          ]),
        },
        [
          _vm._v(" "),
          !_vm.emptyMessage
            ? _c("span", {
                domProps: { textContent: _vm._s(_vm.data.message) },
              })
            : _c("span", [_vm._v("Enter bot's message.")]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }