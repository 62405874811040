var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.hasPermissionTo("tag contact") && !_vm.show_only && _vm.contact.tag_ids
      ? _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-12" },
            [
              _c(
                "el-popover",
                {
                  ref: "popover_tags",
                  attrs: {
                    title: "Tags:",
                    placement: "left",
                    width: "400",
                    "data-testid": "contact-tags-popover",
                    trigge: "click",
                  },
                  model: {
                    value: _vm.is_open,
                    callback: function ($$v) {
                      _vm.is_open = $$v
                    },
                    expression: "is_open",
                  },
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        slot: "reference",
                        "data-testid": "contact-tags-button",
                        type: "text text-dark-greenish text-sm p-0",
                      },
                      slot: "reference",
                    },
                    [
                      _c("i", { staticClass: "el-icon-circle-plus-outline" }),
                      _vm._v(" "),
                      _vm.contact.tag_ids.length === 0
                        ? _c("span", [_vm._v("Add Tags")])
                        : _c("span", [_vm._v("Modify Tags")]),
                    ]
                  ),
                  _vm._v(" "),
                  _c("tag-creator", {
                    ref: "addTag",
                    attrs: {
                      category_prop: _vm.category,
                      "data-testid": "contact-tags-creator",
                    },
                    on: {
                      success: _vm.addTagToContact,
                      show: _vm.showAdd,
                      hide: _vm.hideAdd,
                    },
                  }),
                  _vm._v(" "),
                  _vm.hasPermissionTo(["list tag", "view tag"]) && _vm.is_open
                    ? _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: !_vm.hide_add,
                              expression: "!hide_add",
                            },
                          ],
                          staticClass: "row",
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "col-12" },
                            [
                              _c("tag-selector", {
                                attrs: {
                                  category_prop: _vm.category,
                                  custom_tags: _vm.contact.tags,
                                  multiple: true,
                                  "no-collapse": _vm.noCollapse,
                                  "data-testid":
                                    "contact-tags-category-selector",
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.changeTags($event, _vm.contact)
                                  },
                                },
                                model: {
                                  value: _vm.contact.tag_ids,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.contact, "tag_ids", $$v)
                                  },
                                  expression: "contact.tag_ids",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.contact.tags && _vm.contact.tags.length > 0
      ? _c(
          "div",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.loading_tag,
                expression: "loading_tag",
              },
            ],
            staticClass: "row",
          },
          [
            _c(
              "div",
              { staticClass: "col-12" },
              [
                _vm.summary
                  ? [
                      _vm._l(
                        _vm.$options.filters.lastTags(_vm.contact.tags, 3),
                        function (tag) {
                          return _c(
                            "div",
                            { key: tag.id, staticClass: "text-xs width-300" },
                            [
                              _c("i", {
                                staticClass: "fa fa-circle",
                                style: { color: tag.color },
                              }),
                              _vm._v(" "),
                              _c(
                                "span",
                                { staticClass: "ml-1 text-grey-900" },
                                [_vm._v(_vm._s(tag.name))]
                              ),
                            ]
                          )
                        }
                      ),
                      _vm._v(" "),
                      _vm.contact.tags.length > 3
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "d-flex align-items-center justify-content-center text-muted text-sm width-300",
                            },
                            [
                              _vm._v(
                                "\n                    ...\n                "
                              ),
                            ]
                          )
                        : _vm._e(),
                    ]
                  : _vm._l(
                      _vm.$options.filters.lastTags(_vm.contact.tags),
                      function (tag) {
                        return _c(
                          "div",
                          { key: tag.id, staticClass: "text-xs width-300" },
                          [
                            _c("i", {
                              staticClass: "fa fa-circle",
                              style: { color: tag.color },
                            }),
                            _vm._v(" "),
                            _c("span", { staticClass: "ml-1 text-grey-900" }, [
                              _vm._v(_vm._s(tag.name)),
                            ]),
                          ]
                        )
                      }
                    ),
              ],
              2
            ),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }