<template>
    <div class="row flex-grow-1">
        <div class="col-5">
            <el-form
                @submit.prevent
                :model="chatbot">

                <el-form-item class="mb-0" prop="enabled">
                    <div class="d-flex">
                        <div class="mr-auto">
                            <div class="form-label">
                                <h5 class="text-dark">Enabled</h5>
                                <small>
                                    This means your chatbot is active and running.
                                </small>
                            </div>
                        </div>
                        <div class="ml-auto my-auto">
                            <el-switch
                                v-model="chatbot.enabled"
                                class="pull-right"
                                active-color="#00a344"/>
                        </div>
                    </div>
                    <hr/>
                </el-form-item>

                <el-form-item class="mb-0" prop="name">
                    <div class="form-label">
                        <h5 class="text-dark">Name</h5>
                    </div>
                    <el-input v-model="chatbot.name"/>
                    <hr/>
                </el-form-item>

                <el-form-item class="mb-0" prop="workflow_name">
                    <div class="form-label">
                        <h5 class="text-dark">Workflow</h5>
                        <small>
                            This is how this template is identified.
                        </small>
                    </div>
                    <el-input v-model="chatbot.workflow"/>
                    <hr/>
                </el-form-item>

                <el-form-item class="mb-0" prop="campaign_ids">
                    <div class="form-label">
                        <h5 class="text-dark">Lines</h5>
                        <small>
                            These lines are connected to this chatbot.
                        </small>
                    </div>
                    <el-select
                        class="w-100"
                        v-model="campaign_ids"
                        multiple
                        filterable
                        placeholder="Select any line">
    
                        <el-option
                            v-for="line in lines"
                            :key="line.id"
                            :label="formatedLine(line)"
                            :value="line.id">
                            <span class="font-weight-bold">{{ line.name }}</span>
                            <br/>
                            <!-- One phone number -->
                            <span v-if="line.incoming_numbers.length === 1">
                                {{ line.incoming_numbers[0].phone_number | fixPhone }}
                            </span>
                            <!-- Multiple phone numbers -->
                            <span v-else>
                                {{ line.incoming_numbers.length }} Numbers
                            </span>
                        </el-option>
    
                    </el-select>
                    <hr/>
                </el-form-item>

                <el-form-item class="mb-0" prop="time_format_24h">
                    <div class="d-flex">
                        <div class="mr-auto">
                            <div class="form-label">
                                <h5 class="text-dark">Time Format</h5>
                                <small>
                                    When this feature is on, your Chatbot will use 24-hour time format.
                                </small>
                            </div>
                        </div>
                        <div class="ml-auto my-auto">
                            <el-switch
                                v-model="chatbot.time_format_24h"
                                class="pull-right"
                                active-color="#00a344"/>
                        </div>
                    </div>
                    <hr/>
                </el-form-item>

                <el-form-item class="mb-0 pb-4" prop="notes">
                    <div class="form-label">
                        <h5 class="text-dark">Notes</h5>
                    </div>
                    <el-input
                        v-model="chatbot.notes"
                        type="textarea"
                        :rows="4"/>
                </el-form-item>

                <el-button
                    type="success"
                    round
                    size="medium"
                    :disabled="disable_save_button"
                    @click="updateBot">
                    <i class="fa fa-floppy-o mr-1" aria-hidden="true"/>
                    <strong> Save</strong>
                </el-button>
                <el-button
                    type="danger"
                    round
                    size="medium"
                    @click="openDeleteChatbotForm">
                    <i class="fa fa-trash-o mr-1" aria-hidden="true"/>
                    <strong> Delete</strong>
                </el-button>
            </el-form>
        </div>
        <div class="col-7">
            <div class="d-flex">
                <div>
                    <el-divider
                        direction="vertical"
                        class="h-100"/>
                </div>
                <div class="d-flex flex-column flex-grow-1">
                    <Chatbox
                        id="alohabot-chatbox"
                        :bot="chatbot"/>
                </div>
            </div>
        </div>
        <DeleteChatbot
            :form_visible="delete_chatbot_form_visible"
            :chatbot="chatbot"
            @submit="deleteBot"
            @discard="closeDeleteChatbotForm"/>
    </div>
</template>

<script>
import Chatbox from './chatbox.vue'

import DeleteChatbot from './delete-chatbot.vue'

export default {
    components: {
        Chatbox,
        DeleteChatbot
    },
    props: {
        chatbot: {
            required: true,
        },
        lines: {
            required: true,
            default: []
        }
    },
    data() {
        return {
            campaign_ids: [],
            delete_chatbot_form_visible: false,
            disable_save_button: false
        }
    },
    mounted() {
        let campaign_ids = []
        if (this.chatbot.campaigns) {
            this.chatbot.campaigns.map(campaign => campaign_ids.push(campaign.id))
        }
        this.campaign_ids = campaign_ids
    },
    methods: {
        /**
         * Opens Dialog to Delete Chatbot
         *
         * @return {void}
         */
        openDeleteChatbotForm() {
            this.delete_chatbot_form_visible = true
        },

        /**
         * Closes Dialog to Delete Chatbot
         *
         * @return {void}
         */
        closeDeleteChatbotForm() {
            this.delete_chatbot_form_visible = false
        },

        /**
         * Listens when dialog fires delete event (Work in progress...)
         * @todo Finish UX implementation
         *
         * @return {void}
         */
        onDelete() {
            this.$confirm(`Are you sure you want to <b>delete</b> your Chatbot?`)
                .then(_ => {
                    done()
                })
                .catch(_ => {})
        },

        /**
         * Updates any Chatbot Setting
         *
         * @return {void}
         */
        updateBot() {
            this.disable_save_button = true
            axios.patch(`/api/v1/bots/builder/bot/${this.chatbot.id}`, {
                notes: this.chatbot.notes,
                name: this.chatbot.name,
                workflow: this.chatbot.workflow,
                time_format_24h: this.chatbot.time_format_24h,
                enabled: this.chatbot.enabled,
                campaign_ids: this.campaign_ids
            }).then(res => {
                this.$notify.success({
                    offset: 95,
                    title: 'Chatbots',
                    message: 'Your Chatbot was updated successfully.',
                })
            }).catch(err => {
                console.log(err)
                this.$notify.error({
                    offset: 95,
                    title: 'Chatbots',
                    message: 'There was an issue while updating your Chatbot.',
                })
            }).finally(res => {
                this.disable_save_button = false
            })
        },

        /**
         * Deletes the Chatbot
         *
         * @return {void}
         */
        deleteBot() {
            axios.delete(`/api/v1/bots/builder/bot/${this.chatbot.id}`).then(res => {
                this.$notify.success({
                    offset: 95,
                    title: 'Chatbots',
                    message: 'Bot deleted successfully.',
                })
                this.$router.push({ name: 'Chatbots' })
            }).catch(err => {
                console.log(err)
                this.$notify.error({
                    offset: 95,
                    title: 'Chatbots',
                    message: 'There was an issue while deleting your Chatbot.',
                })
            })
        },

        /**
         * Formats the line displayed on the selector
         *
         * @param {object} line
         *
         * @return {string}
         */
        formatedLine(line) {
            if (line.incoming_numbers && line.incoming_numbers.length === 1) {
                var line_tag = ` - ${this.$options.filters.fixPhone(line.incoming_numbers[0].phone_number)}`
            } else {
                var line_tag = ` - (${line.incoming_numbers.length} Numbers)`
            }

            return `${line.name + line_tag}`
        }
    }
}
</script>