<template>
    <div class="message">
        <el-tooltip class="item"
                    effect="dark"
                    placement="top"
                    v-if="communication.type !== undefined && communication.type !== CommunicationTypes.SYSNOTE"
                    :disabled="!avatarTooltip(contact, communication)"
                    :content="avatarTooltip(contact, communication)">
            <span class="w-40 avatar grey-300 m-b"
                  :class="[ communication.direction === CommunicationDirection.INBOUND ? 'pull-left' : 'pull-right' ]"
                  v-bind:style="avatarStyle(contact, communication)">
                <span v-if="communication.direction === CommunicationDirection.OUTBOUND && current_company">{{ current_company.name | fixContactName | initials }}</span>
                <span v-else>{{ contact.name | fixContactName | initials }}</span>
            </span>
        </el-tooltip>

        <div class="clear"
             v-if="communication.type === CommunicationTypes.SYSNOTE && communication.body">
            <div class="pt-3 pb-3 m-b">
                <el-divider content-position="center">
                    <span>{{ communication.body }}</span>
                    <span v-if="communication.user_id && getUser(communication.user_id).name.length">
                        by {{ getUser(communication.user_id).name }}
                    </span>
                    <span>-</span>
                    <relative-datetime :datetime="communication.created_at"></relative-datetime>
                </el-divider>
            </div>
        </div>

        <div class="clear"
             v-if="communication.property !== undefined && !excluded_audits.includes(communication.property) && (generalAuditsConditions(communication) || customAuditsConditions(communication) || hasAuditNotes(communication))">
            <div class="pt-3 pb-3 m-b">
                <el-divider content-position="center">
                    <span v-if="hasAuditNotes(communication)">
                        {{ communication.notes }}
                    </span>
                    <span v-if="generalAuditsConditions(communication)"
                          v-html="generalAuditMessages(communication)"/>

                    <span v-if="customAuditsConditions(communication)"
                        v-html="generateCustomAuditMessageWithNotes(communication)">
                    </span>

                    <span v-if="communication.user_id && getUser(communication.user_id).name.length && showAuthor(communication)">
                        by {{ getUser(communication.user_id).name }}
                    </span>
                    <span v-else-if="showAuthor(communication)">
                        by System
                    </span>
                    <span>-</span>
                    <relative-datetime :datetime="communication.created_at"
                                       v-if="communication.property">
                    </relative-datetime>
                </el-divider>
            </div>
        </div>
        <div class="clear d-flex flex-column"
             :class="[ communication.direction === CommunicationDirection.INBOUND ? 'align-items-start' : 'align-items-end text-right' ]"
             v-else>
            <el-badge class="item px-2 d-flex flex-column"
                      :class="[communication.direction == CommunicationDirection.INBOUND ? 'align-items-start' : 'align-items-end']"
                      v-if="(communication.type == CommunicationTypes.SMS || (communication.type == CommunicationTypes.NOTE && communication.direction === CommunicationDirection.INBOUND)) && (communication.body || communication.attachments)"
                      :is-dot="!communication.is_read">
                <div class="px-2"
                     v-if="communication.attachments && communication.attachments.length > 0"
                     v-for="(attachment, index) in communication.attachments"
                     :key="index">
                    <vue-load-image v-if="(attachment.mime_type && isAttachmentImage(attachment.mime_type)) || !attachment.mime_type">
                        <img slot="image"
                             class="img-fluid d-block r-2x width-400"
                             :class="index > 0 ? 'mb-1' : ''"
                             :src="attachment.url"/>
                        <img slot="preloader"
                             src="/assets/images/loading.svg"/>
                        <div slot="error">Error on loading the image attachment</div>
                    </vue-load-image>
                    <template v-if="attachment.mime_type">
                        <div v-if="isAttachmentAudio(attachment.mime_type)">
                            <audio class="audio-player"
                                   controls>
                                <source :src="attachment.url"
                                        :type="attachment.mime_type">
                                Your browser does not support the audio element.
                            </audio>
                        </div>

                        <div v-if="isAttachmentVideo(attachment.mime_type)">
                            <video width="320"
                                   class="rounded"
                                   controls>
                                <source :src="attachment.url"
                                        :type="attachment.mime_type">
                                Your browser does not support the video tag.
                            </video>
                        </div>

                        <a :href="attachment.url"
                           v-if="(isAttachmentText(attachment.mime_type) || isAttachmentApplication(attachment.mime_type))"
                           target="_blank">
                            <div class="p-2 text-center">
                                <figure>
                                    <img height="100"
                                         width="100"
                                         src="/assets/images/app-icons/file.svg">
                                    <figcaption>{{ attachment.name ? attachment.name : 'Click Here To Download' }}</figcaption>
                                </figure>
                            </div>
                        </a>
                    </template>
                </div>

                <div class="inline r-2x message-body effect7 mt-1"
                     :class="getCommunicationClass"
                     v-if="communication.body">
                    <span class="arrow pull-top"
                          :class="[ communication.direction === CommunicationDirection.INBOUND ? 'arrow-dker left' : 'arrow-dker right' ]">
                    </span>
                    <div class="p-a p-y-sm handle-whitespace">
                        <span v-linkified:options="{ target: '_blank' }">{{ communication.body }}</span>
                    </div>
                </div>
            </el-badge>

            <el-badge class="item width-400"
                      v-if="communication.type !== undefined && ![CommunicationTypes.SMS, CommunicationTypes.SYSNOTE].includes(communication.type) && ((communication.direction === CommunicationDirection.INBOUND && communication.type !== CommunicationTypes.NOTE) || communication.direction !== CommunicationDirection.INBOUND)">
                <div class="inline r-2x message-body text-xs effect7 mt-1"
                     :class="[ communication.direction === CommunicationDirection.INBOUND ? 'white' : 'white text-left' ]">
                    <span class="arrow pull-top"
                          :class="[ communication.direction === CommunicationDirection.INBOUND ? 'arrow-dker left' : 'arrow-dker right' ]">
                    </span>

                    <div class="p-y-sm">
                        <communication-info :communication="communication"
                                            :contact="contact"
                                            :activity_mode="true"
                                            :campaign_id="campaign_id">
                        </communication-info>
                    </div>
                </div>
            </el-badge>

            <el-button type="text"
                       class="pl-2 p-y-sm inline text-blue mark-read _400"
                       v-if="markable(communication) && !communication.is_read"
                       @click="markAsRead">
                Mark as read
            </el-button>

            <el-button type="text"
                       class="pl-2 p-y-sm inline text-blue mark-read _400"
                       v-if="markable(communication) && communication.is_read"
                       @click="markAsUnread">
                Mark as unread
            </el-button>

            <div class="text-xxs m-t-xs width-600 m-b"
                 v-if="communication.type !== undefined && communication.type !== CommunicationTypes.SYSNOTE"
                 :class="[ communication.direction === CommunicationDirection.INBOUND ? 'ml-2' : 'mr-2' ]">
                <template v-if="communication.direction === CommunicationDirection.INBOUND">
                    <router-link :to="{ name: 'Communication', params: {communication_id: communication.id }}">
                        <i class="material-icons help text-blue">info</i>
                    </router-link>
                </template>
                <relative-datetime :datetime="communication.created_at"></relative-datetime>
                <span class="text-muted"
                      v-if="communication.direction === CommunicationDirection.INBOUND">
                    from {{ communication.lead_number | fixPhone }}
                </span>
                <span class="text-muted"
                      v-if="communication.direction === CommunicationDirection.INBOUND && communication.campaign_id && getCampaign(communication.campaign_id)">
                    to {{ getCampaign(communication.campaign_id).name }}
                </span>
                <span class="text-muted"
                      v-if="communication.direction === CommunicationDirection.OUTBOUND && communication.campaign_id && getCampaign(communication.campaign_id)">
                    from {{ getCampaign(communication.campaign_id).name }}
                </span>
                <span class="text-muted"
                      v-if="communication.direction === CommunicationDirection.OUTBOUND">
                    to {{ communication.lead_number | fixPhone }}
                </span>
                <span class="text-muted"
                      v-if="communication.direction === CommunicationDirection.OUTBOUND && communication.workflow_id && getWorkflow(communication.workflow_id)">
                    sent by {{ getWorkflow(communication.workflow_id).name }} sequence
                </span>
                <span class="text-muted"
                      v-else-if="communication.direction === CommunicationDirection.OUTBOUND && communication.broadcast_id && getBroadcast(communication.broadcast_id)">
                    sent by {{ getBroadcast(communication.broadcast_id).name }} broadcast
                </span>
                <span class="text-muted"
                      v-else-if="communication.direction === CommunicationDirection.OUTBOUND && communication.user_id && getUser(communication.user_id).name.length">
                    sent by {{ getUser(communication.user_id).name }}
                    <template v-if="communication.creator_type && communication.creator_type != CreatorTypes.CREATOR_TYPE_MANUAL">
                        - {{ communication.creator_type | translateCreatorTypeText }}
                    </template>
                </span>
                <template v-if="communication.direction === CommunicationDirection.OUTBOUND">
                    <router-link :to="{ name: 'Communication', params: {communication_id: communication.id }}">
                        <template
                            v-if="[CommunicationDispositionStatus.DISPOSITION_STATUS_FAILED_NEW, CommunicationDispositionStatus.DISPOSITION_STATUS_INVALID_NEW].includes(communication.disposition_status2)"
                        >
                            <i class="material-icons help text-red-500"
                               v-if="communication.current_status2 !== CommunicationCurrentStatus.CURRENT_STATUS_COMPLETED_NEW"
                               :title="communication.current_status2 | translateCurrentStatusText | fixName">
                                error
                            </i>
                            <i class="material-icons help text-red-500"
                               v-else
                               :title="communication.disposition_status2 | translateDispositionStatusText | fixName">
                                error
                            </i>
                        </template>
                        <template v-else>
                            <template
                                v-if="[CommunicationCurrentStatus.CURRENT_STATUS_SMS_RECEIVED_NEW, CommunicationCurrentStatus.CURRENT_STATUS_SMS_DELIVERED_NEW].includes(communication.current_status2)">
                                <i class="material-icons help text-bluish"
                                   :title="communication.current_status2 | translateCurrentStatusText | fixName">done_all</i>
                            </template>

                            <i class="material-icons help text-bluish"
                               :title="communication.current_status2 | translateCurrentStatusText | fixName"
                               v-if="[CommunicationCurrentStatus.CURRENT_STATUS_SMS_SENT_NEW, CommunicationCurrentStatus.CURRENT_STATUS_SMS_ACCEPTED_NEW, CommunicationCurrentStatus.CURRENT_STATUS_INPROGRESS_NEW].includes(communication.current_status2)">done</i>

                            <i class="material-icons help text-blue"
                               :title="communication.current_status2 | translateCurrentStatusText | fixName"
                               v-if="[CommunicationCurrentStatus.CURRENT_STATUS_COMPLETED_NEW, CommunicationCurrentStatus.CURRENT_STATUS_SMS_QUEUED_NEW, CommunicationCurrentStatus.CURRENT_STATUS_SMS_SENDING_NEW, CommunicationCurrentStatus.CURRENT_STATUS_SMS_RECEIVING_NEW].includes(communication.current_status2)">done</i>

                            <i class="material-icons help text-red-500"
                               :title="communication.current_status2 | translateCurrentStatusText | fixName"
                               v-if="[CommunicationCurrentStatus.CURRENT_STATUS_SMS_UNDELIVERED_NEW, CommunicationCurrentStatus.CURRENT_STATUS_SMS_FAILED_NEW].includes(communication.current_status2)">error</i>
                        </template>
                    </router-link>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
import auth from '../auth'
import {
    acl_mixin,
    avatar_mixin,
    communication_info_mixin,
    contact_unread_messages_mixin,
    user_info_mixin
} from '../mixins'
import {mapState} from 'vuex'
import VueLoadImage from 'vue-load-image'
import RelativeDatetime from "./misc/relative-datetime"
import * as CommunicationDirection from '../constants/communication-direction'
import * as CommunicationDispositionStatus from '../constants/communication-disposition-status'
import * as CommunicationCurrentStatus from '../constants/communication-current-status'
import * as CommunicationTypes from '../constants/communication-types'
import * as CommunicationRejectionReasons from '../constants/communication-rejection-reasons'
import * as CreatorTypes from '../constants/creator-types'

export default {
    mixins: [
        acl_mixin,
        avatar_mixin,
        contact_unread_messages_mixin,
        communication_info_mixin,
        user_info_mixin
    ],

    components: {
        RelativeDatetime,
        'vue-load-image': VueLoadImage,
    },

    props: {
        communication: {
            required: true
        },
        contact: {
            required: true
        },
        is_widget: {
            default: false,
            type: Boolean,
            required: false
        },
        campaign_id: {
            required: false
        }
    },

    data() {
        return {
            auth: auth,
            excluded_audits: [
                'email',
                'first_name',
                'last_name',
                'company_name',
                'address',
                'website',
                'lead_source',
                'date_of_birth',
                'cnam_country',
                'cnam_state',
                'cnam_city'
            ],
            general_audit_properties: [
                'disposition_status_id',
                'user_id',
                'lead_source_id',
                'workflow_id',
                'phone_number',
                'email',
                'first_name',
                'last_name',
                'company_name',
                'address',
                'website',
                'lead_source',
                'date_of_birth',
                'cnam_country',
                'cnam_state',
                'cnam_city'
            ],
            custom_audit_messages: {
                'is_dnc': [
                    "Contact has been removed from the DNC list",
                    "Contact has been set to DNC."
                ],
                'is_blocked': [
                    "Contact has been unblocked",
                    "Contact has been blocked"
                ]
            },
            CommunicationDirection,
            CommunicationDispositionStatus,
            CommunicationCurrentStatus,
            CommunicationTypes,
            CommunicationRejectionReasons,
            CreatorTypes
        }
    },

    computed: {
        ...mapState(['campaigns', 'workflows', 'broadcasts', 'disposition_statuses', 'lead_sources', 'call_dispositions']),
        ...mapState('cache', ['current_company']),

        getCommunicationClass() {
            if (this.communication.direction === CommunicationDirection.INBOUND) {
                return 'dker'
            }

            if (this.communication.direction === CommunicationDirection.OUTBOUND && ![CommunicationDispositionStatus.DISPOSITION_STATUS_FAILED_NEW, CommunicationDispositionStatus.DISPOSITION_STATUS_INVALID_NEW].includes(this.communication.disposition_status2)) {
                return 'blue-800 text-left'
            }

            if (this.communication.direction === CommunicationDirection.OUTBOUND && [CommunicationDispositionStatus.DISPOSITION_STATUS_FAILED_NEW, CommunicationDispositionStatus.DISPOSITION_STATUS_INVALID_NEW].includes(this.communication.disposition_status2)) {
                return 'blue-800 text-left'
            }
        },
    },

    methods: {
        markable(communication) {
            // Markable if communication is SMS and the comm direction is INBOUND
            let sms_rule = communication.type === CommunicationTypes.SMS
                && communication.direction === CommunicationDirection.INBOUND
            // Markable if communication is a CALL and disposition_status2 is VOICEMAIL_NEW or MISSED_NEW
            let call_rule = communication.type === CommunicationTypes.CALL
                && [CommunicationDispositionStatus.DISPOSITION_STATUS_MISSED_NEW, CommunicationDispositionStatus.DISPOSITION_STATUS_VOICEMAIL_NEW].includes(communication.disposition_status2)
                && CommunicationDirection.INBOUND === communication.direction

            return sms_rule || call_rule
        },

        generalAuditsConditions(data) {
            // skip if not a property of contact audits
            if (!this.general_audit_properties.includes(data.property)) {
                return false
            }
            return (!data.from && data.to && data.property !== 'phone_number') ||
                (data.from && data.to && data.property !== 'workflow_id') ||
                (data.from && !data.to && data.property !== 'phone_number')
        },

        customAuditsConditions(data) {
            let allowed_data = null
            let property = data.property
            let property_value = data.to !== null ? parseInt(data.to) : data.to
            switch (property) {
                case 'is_dnc':
                case 'is_blocked':
                    allowed_data = [0, 1]
                    return allowed_data.includes(property_value)
                default:
                    return false
            }
        },

        hasAuditNotes(data) {
            let allowed = [
                'tag_ids',
            ]
            return data.notes &&
                !this.generalAuditsConditions(data) &&
                (allowed.includes(data.property) || (!data.from && !data.to))
        },

        generalAuditMessages(data) {
            let general_message = 'Contact' + (data.property !== 'disposition_status_id' ? "'s " : ' ')
            let property_readable_name = data.property.replace('user_id', 'owner').replace('_id', '').replace('_', ' ')
            property_readable_name = property_readable_name === 'phone number' ? 'primary ' + property_readable_name : property_readable_name
            general_message += ' ' + property_readable_name
            let workflow_message = [
                'Enrolled contact into "' + this.getWorkflow(data.to).name + '" sequence.',
                'Contact finished all "' + this.getWorkflow(data.from).name + '" sequence steps.'
            ]

            if (data.from && !data.to) {
                workflow_message[1] = 'Contact was disenrolled from "' + this.getWorkflow(data.from).name + '" sequence.'
            }

            if (data.notes && data.notes.length) {
                workflow_message[0] += ' Reason: ' + data.notes
                workflow_message[1] += ' Reason: ' + data.notes
            }

            let from_value = ''
            let to_value = ''
            if (data.from) {
                from_value = this.getValue(data.property, data.from)
            }
            if (data.to) {
                to_value = this.getValue(data.property, data.to)
            }

            if (!data.from && data.to) {
                switch (data.property) {
                    case 'disposition_status_id':
                    case 'lead_source_id':
                    case 'user_id':
                        return general_message += ' has been set to "' + to_value + '"' + `. Reason: ${data.notes}`
                    case 'workflow_id':
                        return workflow_message[0]
                }
            }
            if (data.from && data.to && data.property !== 'workflow_id') {
                const hs_contact_audit_properties = ['user_id', 'disposition_status_id']
                general_message += ' has been changed from "' + from_value + '" to "' + to_value + '"'

                // Add reason for hubspot property change
                if (data.notes && data.notes !== '' && hs_contact_audit_properties.includes(data.property)) {
                    general_message += '. Reason: ' + data.notes
                }

                return general_message
            }
            if (data.from && !data.to) {
                switch (data.property) {
                    case 'disposition_status_id':
                    case 'lead_source_id':
                    case 'user_id':
                        return general_message += ' has been removed from "' + from_value + '"' + `. Reason: ${data.notes}`
                    case 'workflow_id':
                        return workflow_message[1]
                }
            }
            return ''
        },

        getValue(property, data) {
            let value = ''

            switch (property) {
                case 'disposition_status_id':
                    value = this.getContactDisposition(data).name
                    break
                case 'lead_source_id':
                    value = this.getLeadSource(data).name
                    break
                case 'user_id':
                    value = this.getUser(data).name
                    break
                default:
                    value = data
                    break
            }

            return value
        },

        generateCustomAuditMessage(communication) {
            return this.custom_audit_messages[communication.property][communication.to]
        },

        generateCustomAuditMessageWithNotes(communication) {
            const notes = communication.notes ? ` (Reason: ${communication.notes.replace(/\\"/g, '"')})` : ''
            return this.generateCustomAuditMessage(communication) + notes
        },

        getCampaign(id) {
            if (!id) {
                return null
            }
            id = parseInt(id)
            let found = this.campaigns.find(campaign => campaign.id === id)
            if (found) {
                return found
            }

            return null
        },

        markAsRead() {
            axios.patch('/api/v1/communication/' + this.communication.id, {
                is_read: true
            }).then(res => {
                VueEvent.fire('contact_updated', res.data.contact)
                this.communication.is_read = true
            }).catch(err => {
                this.$root.handleErrors(err.response)
            })
        },

        markAsUnread() {
            axios.patch('/api/v1/communication/' + this.communication.id, {
                is_read: false
            }).then(res => {
                VueEvent.fire('contact_updated', res.data.contact)
                this.communication.is_read = false
            }).catch(err => {
                this.$root.handleErrors(err.response)
            })
        },

        stateToClass: function (state) {
            if (state == CommunicationDispositionStatus.DISPOSITION_STATUS_INPROGRESS_NEW) {
                return 'b-indigo-500'
            } else if (state == CommunicationDispositionStatus.DISPOSITION_STATUS_COMPLETED_NEW) {
                return 'b-green-500'
            } else if (state == CommunicationDispositionStatus.DISPOSITION_STATUS_ABANDONED_NEW) {
                return 'b-purple-500'
            } else if (state == CommunicationDispositionStatus.DISPOSITION_STATUS_MISSED_NEW) {
                return 'b-red-500'
            } else if (state == CommunicationDispositionStatus.DISPOSITION_STATUS_FAILED_NEW) {
                return 'b-red-500'
            } else if (state == CommunicationDispositionStatus.DISPOSITION_STATUS_DEADEND_NEW) {
                return 'b-orange-500'
            } else if (state == CommunicationDispositionStatus.DISPOSITION_STATUS_PLACED_NEW) {
                return 'b-lime-500'
            }
        },

        getUser(id) {
            let user = {name: ''}
            // Sanity check - user
            if (!id) {
                return user
            }
            // Sanity check - users
            if (this.users) {
                id = parseInt(id)
                return this.users.find(user => user.id == id) ?? user
            }

            return user
        },

        getWorkflow(id) {
            if (!id) {
                return {name: ''}
            }

            id = parseInt(id)
            let found = this.workflows.find(workflow => workflow.id === id)

            if (found) {
                return found
            }

            return {name: ''}
        },

        getBroadcast(id) {
            if (!id) {
                return {name: ''}
            }
            id = parseInt(id)
            let found = this.broadcasts.find(broadcast => broadcast.id === id)
            if (found) {
                return found
            }

            return {name: ''}
        },

        getContactDisposition(contact_disposition_id) {
            if (!contact_disposition_id) {
                return {name: ''}
            }
            contact_disposition_id = parseInt(contact_disposition_id)
            let found = this.disposition_statuses.find(contact_disposition => contact_disposition.id === contact_disposition_id)
            if (found) {
                return found
            }

            return {name: ''}
        },

        getLeadSource(lead_source_id) {
            if (!lead_source_id) {
                return {name: ''}
            }
            lead_source_id = parseInt(lead_source_id)
            let found = this.lead_sources.find(lead_source => lead_source.id === lead_source_id)
            if (found) {
                return found
            }

            return {name: ''}
        },

        showAuthor(audit) {
            return !['text_authorized', 'is_opted_out'].includes(audit.property)
        }
    }
}
</script>
