<template>
    <div class="app-body"
         id="view"
         v-if="hasPermissionTo('change integration settings company') && !loading_whitelabel">
        <div class="padding">
            <div class="row box">
                <div class="col-12">
                    <div class="no-effect">
                        <div class="box-body">
                            <div class="d-flex align-items-start justify-content-between pb-4 pt-2"
                                 v-if="isCompanyKYC">
                                <integrations-banner />
                                <div class="d-flex text-right">
                                    <video-modal class="pl-4"
                                                 title="Integrate Aloware with your CRM 🔗️"
                                                 cookie-name="integrations"
                                                 notes="🔥 Unlock limitless potential with <strong>Integrations</strong>!</br></br>🔄 Dive into a world of seamless connections, bridging gaps and boosting productivity.</br></br>Step into the nexus of ultimate compatibility and synergy now! 💥🔗"
                                                 video-url="https://www.youtube.com/embed/mYPcj4sj43c?si=-25jSawvJhMgHFCN"
                                                 learn-more-link="https://support.aloware.com/aloware-integrations"
                                                 v-if="isTrial && isNotSimpSocial"/>
                                </div>
                            </div>
                            <div class="d-flex align-items-center border-bottom pb-4 pt-2">
                                <div class="h3 flex-grow-1 m-0">Integrations</div>
                                <video-modal class="pl-4"
                                             title="Integrate Aloware with your CRM 🔗️"
                                             cookie-name="integrations"
                                             notes="🔥 Unlock limitless potential with <strong>Integrations</strong>!</br></br>🔄 Dive into a world of seamless connections, bridging gaps and boosting productivity.</br></br>Step into the nexus of ultimate compatibility and synergy now! 💥🔗"
                                             video-url="https://www.youtube.com/embed/mYPcj4sj43c?si=-25jSawvJhMgHFCN"
                                             learn-more-link="https://support.aloware.com/aloware-integrations"
                                             v-if="isTrial && isNotSimpSocial && !isCompanyKYC"/>
                            </div>
                            <el-tabs class="mt-4 integration-tabs"
                                     type="card"
                                     ref="tabs"
                                     v-model="activeName"
                                     @tab-click="handleClick">
                                <el-tab-pane label="Integrations"
                                             name="integrations"
                                             v-if="!whitelabel">
                                    <integrations-list @selected="selectedItem"
                                                       :links="links"
                                                       :link_without_settings="link_without_settings">
                                    </integrations-list>
                                </el-tab-pane>
                                <el-tab-pane label="Form Connect"
                                             name="form_connect">
                                    <integrations-form-connect></integrations-form-connect>
                                </el-tab-pane>
                                <el-tab-pane label="Lead API"
                                             name="lead_api">
                                    <integrations-lead-api></integrations-lead-api>
                                </el-tab-pane>
                                <el-tab-pane label="Contact Lookup API"
                                             name="contact_lookup_api">
                                    <contact-lookup-api></contact-lookup-api>
                                </el-tab-pane>
                                <el-tab-pane label="Sequences API"
                                             name="sequence_enroll"
                                             v-if="shouldShowVoipApi">
                                    <integrations-sequence-enroll-api></integrations-sequence-enroll-api>
                                </el-tab-pane>
                                <el-tab-pane label="Number Lookup API"
                                             name="number_lookup_api"
                                             v-if="shouldShowVoipApi">
                                    <integrations-number-lookup></integrations-number-lookup>
                                </el-tab-pane>
                                <el-tab-pane label="SMS API"
                                             name="sms_api"
                                             v-if="shouldShowVoipApi">
                                    <integrations-sms-api></integrations-sms-api>
                                </el-tab-pane>
                                <el-tab-pane label="RVM API"
                                             name="rvm_api"
                                             v-if="shouldShowVoipApi"
                                             :disabled="!canBroadcastRvm">
                                    <integrations-rvm-api></integrations-rvm-api>
                                </el-tab-pane>
                                <el-tab-pane label="Two-Legged Call API"
                                             name="two_legged_call_api"
                                             v-if="shouldShowVoipApi">
                                    <integrations-two-legged-call-api></integrations-two-legged-call-api>
                                </el-tab-pane>
                                <el-tab-pane label="Power Dialer API"
                                             name="pd_api">
                                    <integrations-power-dialer-api></integrations-power-dialer-api>
                                </el-tab-pane>
                                <el-tab-pane label="Users API"
                                             name="users_api">
                                    <integrations-users-api></integrations-users-api>
                                </el-tab-pane>
                            </el-tabs>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <el-drawer v-if="selected_item"
                   class="integration-settings-drawer"
                   :visible.sync="drawer"
                   :direction="direction"
                   :size="drawer_size"
                   :destroy-on-close="true"
                   :before-close="handleClose">
            <template v-slot:title>
                <span role="heading">
                    <h3 v-if="selected_item === 'custom_link_builder'">Custom Integration Link Builder</h3>
                    <h3 v-else-if="selected_item === 'gohighlevel'">GoHighLevel Integration Settings</h3>
                    <h3 v-else>
                        {{ selected_item | prettifyCamelCase | ucwords | fixIntegrationName }} Integration Settings
                    </h3>
                    <small v-if="aloware_links[selected_item]"
                           class="mr-2">
                        <a :href="aloware_links[selected_item]"
                           class="text-bluish _600"
                           target="_blank">Click here</a>
                        to learn more information about {{ selected_item | prettifyCamelCase | ucwords | fixIntegrationName }} integration.
                    </small>
                </span>
            </template>
            <integration-settings-drawer :cardname="selected_item"
                                         @back="handleClose">
            </integration-settings-drawer>
        </el-drawer>
        <el-backtop target="html"
                    ref="backtop"
                    :bottom="20"
                    :right="20">
        </el-backtop>
    </div>
</template>

<script>
import auth from '../auth'
import {mapState, mapGetters} from 'vuex'
import {acl_mixin, scroll_mixin, kyc_mixin} from '../mixins'
import IntegrationListCrmTester from '../components/integration-list-crm-tester'
import IntegrationsRvmApi from '../components/integrations/integrations-rvm-api'
import IntegrationsTwoLeggedCallApi from '../components/integrations/integrations-two-legged-call-api'
import IntegrationSettingsDrawer from '../components/integration-settings-drawer'
import UpgradeNowLite from "../components/upgrade-now-lite.vue"
import _ from "lodash"
import VideoModal from '../components/video-modal.vue'
import IntegrationsBanner from '../components/integrations/integrations-banner.vue'
import IntegrationsPowerDialerApi from '../components/integrations-power-dialer-api.vue'

export default {
    components: {
        UpgradeNowLite,
        IntegrationsTwoLeggedCallApi,
        IntegrationsRvmApi,
        IntegrationListCrmTester,
        IntegrationSettingsDrawer,
        VideoModal,
        IntegrationsBanner,
        IntegrationsPowerDialerApi
    },

    mixins: [acl_mixin, scroll_mixin, kyc_mixin],

    data() {
        return {
            auth: auth,
            activeName: 'integrations',
            whitelabel: false,
            loading_whitelabel: true,
            drawer: false,
            direction: 'rtl',
            drawer_size: '60%',
            selected_item: null,
            aloware_links: {
                hubspot: 'https://aloware.com/integrations/hubspot',
                pipedrive: 'https://aloware.com/integrations/pipedrive',
                zoho: 'https://aloware.com/integrations/zoho',
                guesty: 'https://aloware.com/integrations/guesty',
                google: 'https://aloware.com/integrations/google',
                gohighlevel: 'https://aloware.com/integrations/gohighlevel',
            },
            links: [
                {
                    'image': '/assets/images/hubspot.jpg',
                    'name': 'hubspot',
                    'status': false,
                    'disabled': false,
                    'loading': true,
                    'is_required_fields_set': false,
                    'connection_status': null,
                    'category': 'CRM & Helpdesk Software',
                    'show': true,
                    'is_beta': false,
                },
                {
                    'image': '/assets/images/pipedrive.jpg',
                    'name': 'pipedrive',
                    'status': false,
                    'disabled': false,
                    'loading': true,
                    'is_required_fields_set': false,
                    'connection_status': null,
                    'category': 'CRM & Helpdesk Software',
                    'show': true,
                    'is_beta': false,
                },
                {
                    'image': '/assets/images/zoho.png',
                    'name': 'zoho',
                    'status': false,
                    'disabled': false,
                    'loading': true,
                    'is_required_fields_set': false,
                    'connection_status': null,
                    'category': 'CRM & Helpdesk Software',
                    'show': true,
                    'is_beta': false,
                },
                {
                    'image': '/assets/images/guesty.png',
                    'name': 'guesty',
                    'status': false,
                    'disabled': false,
                    'loading': true,
                    'is_required_fields_set': false,
                    'connection_status': null,
                    'category': 'CRM & Helpdesk Software',
                    'show': true,
                    'is_beta': false,
                },
                {
                    'image': '/assets/images/helpscout.png',
                    'name': 'helpscout',
                    'status': false,
                    'disabled': false,
                    'loading': true,
                    'is_required_fields_set': false,
                    'connection_status': null,
                    'category': 'CRM & Helpdesk Software',
                    'show': true,
                    'is_beta': false,
                },
                {
                    'image': '/assets/images/ghl.png',
                    'name': 'gohighlevel',
                    'status': false,
                    'disabled': false,
                    'loading': true,
                    'is_required_fields_set': false,
                    'connection_status': null,
                    'category': 'CRM & Helpdesk Software',
                    'show': true,
                    'is_beta': true,
                },
                {
                  'image': '/assets/images/integrations/Salesforce-logo.jpg',
                  'name': 'salesforce',
                  'status': false,
                  'disabled': false,
                  'loading': true,
                  'is_required_fields_set': false,
                  'connection_status': null,
                  'category': 'CRM & Helpdesk Software',
                  'show': true,
                  'is_beta': true
                },
                {
                    'image': '/assets/images/sendgrid.png',
                    'name': 'sendgrid',
                    'status': false,
                    'disabled': false,
                    'loading': true,
                    'is_required_fields_set': false,
                    'connection_status': null,
                    'category': 'email',
                    'show': true,
                    'is_beta': false,
                },
                {
                    'image': '/assets/images/mailgun.png',
                    'name': 'mailgun',
                    'status': false,
                    'disabled': false,
                    'loading': true,
                    'is_required_fields_set': false,
                    'connection_status': null,
                    'category': 'email',
                    'show': true,
                    'is_beta': false,
                },
                {
                    'image': '/assets/images/smtp.png',
                    'name': 'SMTP',
                    'status': false,
                    'disabled': false,
                    'loading': true,
                    'is_required_fields_set': false,
                    'connection_status': null,
                    'category': 'email',
                    'show': true,
                    'is_beta': false,
                },
                {
                    'image': '/assets/images/gong.png',
                    'name': 'gong',
                    'status': false,
                    'disabled': false,
                    'loading': true,
                    'is_required_fields_set': false,
                    'connection_status': null,
                    'category': 'call analytics',
                    'show': true,
                    'is_beta': false,
                },
                {
                    'image': '/assets/images/execvision-logo.png',
                    'name': 'execvision',
                    'status': false,
                    'disabled': false,
                    'loading': false,
                    'is_required_fields_set': false,
                    'connection_status': null,
                    'category': 'call analytics',
                    'show': true,
                    'is_beta': false,
                },
                {
                    'image': '/assets/images/facebook.png',
                    'name': 'facebook',
                    'status': false,
                    'disabled': false,
                    'loading': true,
                    'is_required_fields_set': false,
                    'connection_status': null,
                    'category': 'other',
                    'show': true,
                    'is_beta': false,
                },
                {
                    'image': '/assets/images/slack.png',
                    'name': 'slack',
                    'status': false,
                    'disabled': false,
                    'loading': true,
                    'is_required_fields_set': false,
                    'connection_status': null,
                    'category': 'other',
                    'show': true,
                    'is_beta': false,
                },
                {
                    'image': '/assets/images/stripe.png',
                    'name': 'stripe',
                    'status': false,
                    'disabled': false,
                    'loading': true,
                    'is_required_fields_set': false,
                    'connection_status': null,
                    'category': 'other',
                    'show': true,
                    'is_beta': false,
                },
                {
                    'image': '/assets/images/webhook.png',
                    'name': 'webhook',
                    'status': false,
                    'disabled': false,
                    'loading': true,
                    'is_required_fields_set': false,
                    'connection_status': null,
                    'category': 'other',
                    'show': true,
                    'is_beta': false,
                },
                {
                    'image': '/assets/images/googleanalytics.png',
                    'name': 'google-analytics',
                    'status': false,
                    'disabled': false,
                    'loading': true,
                    'is_required_fields_set': false,
                    'connection_status': null,
                    'category': 'other',
                    'show': true,
                    'is_beta': false,
                },
                {
                    'image': '/assets/images/google-calendar.png',
                    'name': 'google-calendar',
                    'status': false,
                    'disabled': false,
                    'loading': true,
                    'is_required_fields_set': true,
                    'connection_status': null,
                    'category': 'other',
                    'show': true,
                    'is_beta': false,
                },
                {
                    'image': '/assets/images/integrations/link-solid.svg',
                    'name': 'custom_link_builder',
                    'status': false,
                    'disabled': false,
                    'loading': true,
                    'is_required_fields_set': false,
                    'connection_status': null,
                    'category': 'other',
                    'show': true,
                    'is_beta': false,
                },
                {
                    'image': '/assets/images/zapier.svg',
                    'name': 'zapier',
                    'status': false,
                    'disabled': false,
                    'loading': false,
                    'is_required_fields_set': false,
                    'connection_status': null,
                    'category': 'other',
                    'show': true,
                    'is_beta': false,
                },
                {
                    'image': '/assets/images/execvision-logo.png',
                    'name': 'aloware_click_to_call',
                    'status': false,
                    'disabled': false,
                    'loading': false,
                    'is_required_fields_set': false,
                    'connection_status': null,
                    'category': 'other',
                    'show': true,
                    'is_beta': false,
                },
                {
                    'image': null,
                    'name': 'missing_integration',
                    'status': false,
                    'disabled': false,
                    'loading': false,
                    'is_required_fields_set': false,
                    'connection_status': null,
                    'category': 'other',
                    'show': true,
                    'is_beta': false,
                },
            ],
            link_without_settings: [
                'execvision',
                'contact_center_compliance',
                'zapier',
                'aloware_click_to_call',
                'missing_integration',
            ],
        }
    },

    computed: {
        ...mapState('cache', ['current_company']),
        ...mapGetters('cache', [
            'isTrial',
            'isNotSimpSocial'
        ]),

        canBroadcastRvm() {
            if (this.auth && _.has(this.auth, 'user.profile.bulk_rvm_enabled')) {
                return this.auth.user.profile.bulk_rvm_enabled && this.hasPermissionTo('create broadcast rvm')
            }

            return false
        }
    },

    created() {
        if (!this.hasPermissionTo('change integration settings company')) {
            this.goBack()
        }

        if (!this.$route.query.tab) {
            if (this.auth.user.profile && (!this.auth.user.profile.created_by_reseller || (this.auth.user.profile.created_by_reseller && this.is_impersonated))) {
                this.$router.push({name: 'Integrations', query: {tab: this.activeName}}).catch(err => {
                })
            } else {
                this.activeName = 'lead_api'
                this.$router.push({name: 'Integrations', query: {tab: this.activeName}}).catch(err => {
                })
            }
        }

        const integration_name = this.$route.query.name
        const valid_integration_name = this.links.filter(value => value.name === integration_name).length

        // Making sure that param value is valid based from links array
        if (integration_name && valid_integration_name && !this.link_without_settings.includes(integration_name)) {
            // Remove name param in query string once setting has been rendered
            this.selected_item = integration_name
            this.drawer = true
        }
    },

    mounted() {
        window.addEventListener('resize', this.onResize)
        this.setTitle()

        if (this.$route.query.tab) {
            this.activeName = this.$route.query.tab
        }

        if (this.$refs.tabs) {
            this.$refs.tabs.$forceUpdate()
        }
    },

    beforeDestroy() {
        window.removeEventListener('resize', this.onResize)
    },

    methods: {
        setTitle() {
            axios.get('/get-statics')
                .then(res => {
                    this.whitelabel = res.data.whitelabel
                    this.loading_whitelabel = false
                    let name = res.data.name
                    this.setPageTitle('Integrations - ' + name)
                })
                .catch(err => {
                    this.setPageTitle('Integrations - Aloware')
                    this.loading_whitelabel = false
                    console.log(err)
                    this.$root.handleErrors(err.response)
                })
        },

        handleClick(tab, event) {
            this.$router.push({name: 'Integrations', query: {tab: tab.name}}).catch(err => {
            })
        },

        openInNewTab(url) {
            let win = window.open(url, '_blank')
            win.focus()
        },
        onResize() {
            this.drawer_size = '60%'

            // Change drawer size on xs device
            if (window.innerWidth < 768) {
                this.drawer_size = '90%'
            }
        },
        removePropertyFromQuery(property_name) {
            let query = Object.assign({}, this.$route.query)
            delete query[property_name]
            this.$router.replace({query})
        },
        handleClose() {
            this.removePropertyFromQuery('name')
            this.drawer = false
        },
        selectedItem(link_name) {
            this.selected_item = link_name
            this.drawer = true
        }
    },

    watch: {
        $route({params, query}) {
            if (query.tab) {
                this.activeName = query.tab
            }
        }
    },

    beforeRouteEnter(to, from, next) {
        auth.check()
            .then((res) => {
                if (res.data.user.is_reseller) {
                    // redirect to account management portal if the company is a reseller
                    next({name: 'Account Management Portal'})
                } else {
                    next()
                }
            })
            .catch((err) => {
                next({name: 'Login', query: {redirect: to.fullPath}})
            })
    }
}
</script>
