<template>
    <div v-if="contact">
        Appointment with {{contact.name}}<span v-if="time_diff != 0"> in {{time_diff}} {{unit}}</span>.
        <br>
        <span v-html="$options.filters.nl2br(engagement.body)"></span>
        <br>
        <p>Phone number: {{ contact.phone_number | fixPhone }}</p>
        <div>
            <button class="btn rounded greenish btn-appointment btn-sm mt-2 pull-right">
                Go To Contact
            </button>
        </div>
    </div>
</template>

<script>
    import {html_mixin} from '../mixins'

    export default {
        mixins: [html_mixin],

        props: ['engagement', 'contact', 'time_diff', 'unit']
    }
</script>
