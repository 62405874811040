<template>
  <div>

    <input class="md-input"
       v-model="contactAttribute.value"
       v-show="!contactAttribute.edit_mode"
       data-testid="contact-attributes-input"
       :disabled="true"
    />

    <input class="md-input"
       :ref="contactAttribute.slug"
       v-show="contactAttribute.edit_mode"
       v-model="contactAttribute.value"
       data-testid="edit-contact-attributes-input"
       @blur="$emit('input-set-editable', contactAttribute, false, AttributeDictionary.TYPE_TEXT)"
       @keyup="$emit('input-save-contact-attribute', contactAttribute)"
    />

  </div>
</template>

<script>
import * as AttributeDictionary from "../../../constants/attribute-dictionary";

export default {
  name: "attribute-type-text",

  data() {
    return {
      AttributeDictionary,
    }
  },

  props: {
    contactAttribute: {
      required: true,
      type: Object,
    }
  },

}
</script>
