<template>
    <div>
        <!--send a new email-->
        <el-dialog title="Send an Email"
                   class="dialog-padding auto"
                   :width="isLargeEnough ? '500px' : '100%'"
                   :visible.sync="hide_add"
                   :before-close="beforeCloseModal"
                   append-to-body>
            <el-form ref="send_email"
                     class="p-0"
                     label-position="top"
                     :rules="rules_send_email"
                     :model="send_email"
                     data-testid="send-email-form"
                     @submit.prevent.native="sendEmail(contact_id)">
                <el-form-item prop="subject"
                              label="Subject">
                    <el-input 
                        ref="subject"
                        v-model="send_email.subject"
                        data-testid="subject-email-input"
                        @input="preValidateForm('send_email')">
                    </el-input>
                </el-form-item>
                <el-form-item prop="message"
                              label="Message Body">
                    <el-input v-model="send_email.message"
                              type="textarea"
                              rows="4"
                              data-testid="message-email-input"
                              @input="preValidateForm('send_email')">
                    </el-input>
                </el-form-item>
            </el-form>

            <div class="row pb-3">
                <div class="col-8">
                    <div class="row">
                        <div class="col-6">
                            <variable-dialog :in_input_group="false"></variable-dialog>
                        </div>
                        <div class="col-6">
                            <template-list-dialog/>
                        </div>
                    </div>
                </div>
                <div class="col-4">
                    <el-button type="success"
                               :disabled="loading_btn || !validated"
                               class="pull-right ml-2"
                               data-testid="send-email-button"
                               @click="sendEmail(contact_id)">
                        <i class="material-icons loader"
                           v-show="loading_btn">&#xE863;</i>
                        <i class="material-icons"
                           v-show="!loading_btn">
                            send
                        </i>
                        <span>Send</span>
                    </el-button>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import auth from '../auth'
    import {
        acl_mixin,
        form_validation_mixin
    } from '../mixins'
    import {mapState} from 'vuex'
    import VariableDialog from "./variable-dialog"
    import TemplateListDialog from "./template-list-dialog"

    const _send_email_default = () => {
        return {
            campaign_id: null,
            subject: '',
            message: '',
            from_name: auth.user.profile.name
        }
    }

    export default {
        components: {TemplateListDialog, VariableDialog},

        mixins: [
            acl_mixin,
            form_validation_mixin
        ],

        props: ['contact_id', 'selected_campaign_id'],
        data() {
            return {
                auth: auth,
                loading_btn: false,
                loading_send: false,
                hide_add: false,
                send_email: _send_email_default(),
                rules_send_email: {
                    subject: [
                        {
                            required: true,
                            message: 'Please provide subject',
                            trigger: 'blur'
                        }
                    ],
                    message: [
                        {
                            required: true,
                            message: 'Please provide message body',
                            trigger: 'blur'
                        }
                    ],
                }
            }
        },
        computed: {
            ...mapState('cache', ['current_company']),

            isLargeEnough() {
                let valid_sizes = ['xxl', 'xl', 'lg', 'md']
                if (valid_sizes.includes(this.$mq)) {
                    return true
                }
                return false
            }
        },
        mounted() {
            this.send_email.campaign_id = this.selected_campaign_id
        },
        methods: {
            changeCampaignId(val) {
                this.send_email.campaign_id = val
                this.preValidateForm('send_email')
            },

            sendEmail(contact_id) {
                if (this.validateForm('send_email')) {
                    this.loading_btn = true
                    let url = `/api/v1/contact/${contact_id}/send-email`;
                    axios.post(url, this.send_email).then(res => {
                            this.hide_add = false
                            this.$emit('success', res.data)
                            this.resetSendEmail()
                        }).catch(err => {
                            this.$notify({
                                offset: 95,
                                title: 'Send Email',
                                message: err.response.data.message,
                                type: 'error',
                                showClose: true
                            })
                        }).then(_=> {
                            this.loading_btn = false
                    })
                }
            },

            resetSendEmail() {
                this.send_email = _send_email_default()
                let form_element = _.get(this.$refs, 'send_email', null)

                if (form_element) {
                    form_element.clearValidate()
                }

                this.send_email.campaign_id = this.selected_campaign_id
            },

            beforeCloseModal(done) {
                if (this.send_email.subject || this.send_email.message) {
                    this.$confirm('Are you sure you want to leave?', 'Warning', {
                        confirmButtonText: 'Yes, Leave',
                        cancelButtonText: 'No, Stay',
                        customClass: 'width-500 fixed',
                        type: 'warning'
                    }).then(res => {
                        this.resetSendEmail()
                        done()
                    }).catch(() => {

                    })
                } else {
                    this.resetSendEmail()
                    done()
                }
            }
        },
        watch: {
            hide_add() {
                if (this.hide_add) {
                    this.$emit('hide')
                } else {
                    this.$emit('show')
                }
            }
        }
    }
</script>
