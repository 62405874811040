<template>
    <el-select ref="contact-lists-selector"
               v-model="contact_list_id"
               v-loading="loading"
               class="w-full"
               :placeholder="placeholder"
               :multiple="multiple"
               :clearable="clearable"
               :collapse-tags="!no_collapse"
               :size="size"
               filterable
               default-first-option
               @change="selectContactList">
        <el-option v-for="contact_list in contactListOrdered"
                   :key="contact_list.id"
                   :label="contact_list.name"
                   :value="contact_list.id">
            <span class="ml-2">{{ contact_list.name }}</span>
        </el-option>
  </el-select>
</template>

<script>
import auth from '../auth'
import * as ContactListModuleTypes from '../constants/contact-list-module-types'
import { acl_mixin } from '../mixins'
import { mapState } from 'vuex'
import { clone } from 'lodash'

export default {
    mixins: [
        acl_mixin
    ],

    data() {
        return {
            auth: auth,
            contact_list_id: this.value,
            loading: false,
            contact_lists: [],
            params: {
                page: 1,
                size: 100,
                user_id: this.user_id,
                include_default_queue: true
            }
        }
    },

    props: {
        value: {
            required: false,
            type: [Number, String]
        },

        user_id: {
            required: true,
            type: Number,
        },

        module_type: {
            required: false,
            default: ContactListModuleTypes.CONTACT_LIST,
            validator (value) {
                return [ContactListModuleTypes.CONTACT_LIST, ContactListModuleTypes.POWER_DIALER_LIST].includes(value)
            }
        },

        multiple: {
            type: Boolean,
            required: false,
            default: false
        },

        no_collapse: {
            type: Boolean,
            required: false,
            default: false
        },

        clearable: {
            type: Boolean,
            required: false,
            default: false
        },

        size: {
            type: String,
            default: '',
            required: false
        }
    },

    computed: {
        ...mapState('cache', [
            'current_company'
        ]),

        placeholder() {
            return this.multiple ? 'Select Contact Lists' : 'Select Contact List'
        },

        endpoint() {
            switch (this.module_type) {
                case ContactListModuleTypes.CONTACT_LIST:
                    return '/api/v2/contacts-list'
                case ContactListModuleTypes.POWER_DIALER_LIST:
                    return '/api/v2/power-dialer-lists'
            }
        },

        contactListOrdered() {
            let contact_lists = clone(this.contact_lists)

            return contact_lists.sort((a, b) => {
                // "My Queue" lists should come first
                if (a.type === 0 && b.type !== 0) {
                    return -1
                }
                if (a.type !== 0 && b.type === 0) {
                    return 1
                }

                let textA = a.name.toUpperCase()
                let textB = b.name.toUpperCase()
                return (textA < textB) ? -1 : (textA > textB) ? 1 : 0
            })
        }
    },

    mounted() {
        this.getContactLists()
    },

    methods: {
        selectContactList(list) {
            this.contact_list_id = list
            this.$emit('change', list)
        },

        async getContactLists() {
            if (!this.hasPermissionTo('list contact list item')) {
                return
            }

            this.loading = true
            let res = null

            do {
                res = await axios.get(this.endpoint, { params: this.params })
                this.contact_lists.push(...res.data.data)
                this.params.page++
            } while (res.data.next_page_url)

            this.loading = false

             // After fetching the contact lists, set the first one(My Queue) if none is selected
            if (!this.contact_list_id && this.contactListOrdered.length > 0 && this.contactListOrdered[0]?.id) {
                this.selectContactList(this.contactListOrdered[0].id)
            }
        }
    },

    watch: {
        value() {
            this.contact_list_id = this.value
        },

        user_id(value) {
            this.params.page = 1
            this.params.user_id = value
            this.$set(this, 'contact_lists', [])
            this.getContactLists()
        }
  }
}
</script>
