<template>
    <div v-if="contact">
        <el-input type="textarea"
                  placeholder="write your contact notes here..."
                  ref="contact_notes"
                  v-model="note"
                  data-testid="contact-note-input"
                  :autosize="{ minRows: 3, maxRows: 5}"
                  :disabled="!hasPermissionTo('update contact') || loading_btn"
                  @input="changeNote">
        </el-input>

        <div class="pull-right mt-2">
            <span class="text-muted"
                  data-testid="contact-note-saving-input"
                  v-show="loading_btn">
                <i class="material-icons loader text-dark-greenish">&#xE863;</i>
                saving
            </span>
            <span class="text-muted"
                  data-testid="contact-note-autosaved-input"
                  v-show="!loading_btn && loading">
                autosaved
            </span>
        </div>
    </div>
</template>

<script>
    import auth from '../auth'
    import {acl_mixin} from '../mixins'
    import {mapState} from 'vuex'

    export default {
        mixins: [acl_mixin],

        props: ['contact'],

        data() {
            return {
                auth: auth,
                loading: false,
                loading_btn: false,
                note: null,
            }
        },

        computed: {
            ...mapState('cache', ['current_company'])
        },

        mounted() {
            if (this.contact) {
                this.showNote()
            }
        },

        methods: {
            showNote() {
                this.note = this.contact.notes
            },

            changeNote: _.debounce(function () {
                if (this.hasPermissionTo('update contact')) {
                    this.saveNote()
                }
            }, 2000),

            saveNote() {
                this.loading_btn = true
                axios.patch('/api/v1/contact/' + this.contact.id, {
                    notes: this.note
                }).then(res => {
                    this.contact.notes = this.note
                    this.loading_btn = false
                    this.loading = true
                    setTimeout(() => {
                        this.loading = false
                    }, 1000)
                }).catch(err => {
                    this.note = null
                    this.$root.handleErrors(err.response)
                    this.loading_btn = false
                }).then(() => {
                    if (this.$refs.contact_notes) {
                        this.$refs.contact_notes.focus()
                    }
                })
            },
        },

        watch: {
            'contact.notes': function () {
                this.showNote()
            }
        }
    }
</script>
