<template>
    <div>
        <div :class="{ blink: loading }"
             class="placeholder text-center d-flex align-items-center justify-content-center"
             style="height: 450px"
             v-if="!data_loaded || loading">

            <i class="material-icons loader text-greenish text-4x">
                &#xE863;
            </i>
        </div>
        <div class="force-scrollbar-view"
             style="height: 450px"
             v-else>
            <graph-data-switch :report_type="'activity_reporting'"
                               :series="options.series"
                               :table_label="graph_label">
                <highcharts :class="{'overflow-y-scroll': (chart_type == 'bar' && data.length > 25)}"
                            :options="graphOptions"
                            :style="{height: '450px'}"
                            ref="highchart"
                            v-bind:id="graph_id"
                            v-show="is_done && options.series.length > 0">
                </highcharts>
                <div class="el-table__empty-block"
                     v-show="is_done && !options.series.length">
                    <span class="el-table__empty-text"
                          style="color: #606266;">
                        No Data
                    </span>
                </div>
            </graph-data-switch>
        </div>
    </div>
</template>

<script>
    import auth from '../auth'
    import {mapGetters} from 'vuex'

    export default {
        props: {
            data: {
                required: true,
                default: []
            },
            type: {
                required: true,
                default: 'all_calls'
            },
            data_loaded: {
                required: true,
                default: false
            },
            chart_type: {
                required: false,
                default: 'bar'
            },
            metric_time_mode: {
                required: false,
                default: 'minutes'
            }
        },

        data() {
            return {
                auth: auth,
                is_done: false,
                loading: true,
                axisLabels: [],
                graph_id: this.type.replace(/_/g, ' ') + '_graph',
                options: {
                    chart: {
                        type: this.chart_type,
                        inverted: true
                    },
                    plotOptions: {},
                    title: {
                        text: this.$options.filters.capitalize(this.type.replace(/_/g, ' ')),
                        style: {
                            color: 'transparent'
                        }
                    },
                    xAxis: {},
                    yAxis: {
                        minTickInterval: 1,
                        title: {
                            text: ''
                        },
                        labels: {
                            enabled: true,
                            formatter() {
                                return Math.floor(this.value)
                            }
                        }
                    },
                    tooltip: {
                        headerFormat: "",
                        pointFormatter: function () {
                            let label = ''
                            let title = this.series.chart.title.textStr
                            let new_value = this.y.toLocaleString()

                            switch (title) {
                                case 'All calls':
                                    label = 'Calls'
                                    break;
                                case 'All qualified calls':
                                    label = 'All Qualified calls'
                                    break;
                                case 'Inbound calls':
                                    label = 'Inbound calls'
                                    break;
                                case 'Qualified inbound calls':
                                    label = 'Qualified Inbound calls'
                                    break;
                                case 'All missed calls':
                                    label = 'Missed calls'
                                    break;
                                case 'Missed call percent':
                                    label = 'Missed call'
                                    break;
                                case 'Outbound calls':
                                    label = 'Outbound calls'
                                    break;
                                case 'Qualified outbound calls':
                                    label = 'Qualified Outbound calls'
                                    break;
                                case 'Qualified outbound calls percent':
                                    label = 'Qualified Outbound calls'
                                    break;
                                case 'Average duration':
                                    label = 'Average duration'
                                    break;
                                case 'Total duration':
                                    label = 'Total duration'
                                    break;
                                case 'Average talk time':
                                    label = 'Average Talk Time'
                                    break;
                                case 'Total talk time':
                                    label = 'Total Talk Time'
                                    break;
                                case 'Average wait time':
                                    label = 'Average Wait Time'
                                    break;
                                case 'Total wait time':
                                    label = 'Total Wait Time'
                                    break;
                                case 'Text messages':
                                    label = 'Text Messages'
                                    break;
                                case 'Ringless voicemails':
                                    label = 'Ringless voicemails'
                                    break;
                                default:
                                    label = 'Activities'
                            }

                            // for time data
                            if (label.includes('Average') || label.includes('Total')) {
                                let decimalTime = parseFloat(this.y * 60)
                                let hours = Math.floor((decimalTime / (60 * 60)))
                                decimalTime = decimalTime - (hours * 60 * 60)
                                let minutes = Math.floor((decimalTime / 60))
                                decimalTime = decimalTime - (minutes * 60)
                                let seconds = Math.floor(decimalTime)

                                if (hours < 10) {
                                    hours = "0" + hours
                                }

                                if (minutes < 10) {
                                    minutes = "0" + minutes
                                }

                                if (seconds < 10) {
                                    seconds = "0" + seconds
                                }

                                // convert float hour to hh:mm
                                new_value = hours + ":" + minutes + ":" + seconds

                                // if more than 0 minutes, show in minutes value only for bar chart type
                                if (this.y > 0 && this.series.chart.options.chart.type === 'bar' && this.metric_time_mode === 'minutes') {
                                    const in_minutes = Math.floor(this.y * 100) / 100
                                    new_value += ' <b>(' + in_minutes.toLocaleString(undefined, {
                                        minimumFractionDigits: 2, maximumFractionDigits: 2
                                    }) + 'm)</b>'
                                }
                            }
                            let toReturn = ((this.name != 'All') ? '<b>' + this.name + '</b><br>has' : 'All have')
                            toReturn += ' <b>' + new_value + (title.includes('percent') ? '%' : '') + '</b> ' + label

                            return toReturn
                        },
                        split: false,
                        enabled: true
                    },
                    legend: {
                        enabled: false
                    },
                },
                graph_label: 'All Calls',
            }
        },

        computed: {
            ...mapGetters({
                filter: 'getFilter'
            }),

            graphOptions() {
                return this.options
            },
        },

        mounted() {
            this.graph_label = this.changeXAxisLabel()
            this.generateGraphData()
        },

        methods: {
            generateGraphData: function () {
                this.updateChartHeight()
                this.loading = true
                this.is_done = false

                let sorted_data = _.clone(this.data)

                // fix percentage
                for (let index in sorted_data) {
                    let value = sorted_data[index][this.type]
                    value = typeof value == 'string' ? parseInt(value.replace('%')) : value
                    sorted_data[index][this.type] = value
                }
                // now we can sort the data
                sorted_data.sort((a, b) => b[this.type] - a[this.type])
                // setup the data attrs
                let data_series = []

                //get distinct chart series values
                for (let index in sorted_data) {
                    this.axisLabels.push(sorted_data[index].name)
                    let value = sorted_data[index][this.type]
                    value = typeof value == 'string' ? parseInt(value.replace('%')) : value

                    // only divide the value by 60 for durations and time-related metrics
                    if (this.type.includes('average') || this.type.includes('total')) {
                        switch (this.metric_time_mode) {
                            case 'minutes':
                                value = value / 60
                                break
                            case 'hours':
                                value = (value / 60) / 60
                                break
                        }
                    }

                    data_series.push({
                        name: sorted_data[index].name,
                        y: value
                    })
                }

                // set the y-axis labels
                this.options.xAxis.categories = this.axisLabels

                //no data flag
                if (data_series.length) {
                    this.options.series = [{
                        color: '#7bafe2',
                        data: data_series
                    }]
                    if (this.chart_type === 'bar') {
                        this.options.series[0].dataLabels = {
                            inside: false,
                            enabled: true,
                            style: {
                                color: 'black'
                            },
                            formatter: function () {
                                if (this.y == 0) {
                                    return ''
                                }

                                const metric = this.series.chart.title.textStr

                                // show decimal points only for durations and time-related metrics
                                if (metric.includes('Average') || metric.includes('Total')) {
                                    const in_minutes = Math.floor(this.y * 100) / 100
                                    return in_minutes.toLocaleString(undefined, {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2
                                    })
                                }

                                return this.y.toLocaleString()
                            }
                        }
                    }
                } else {
                    this.options.series = []
                }

                this.is_done = true
                this.loading = false
            },

            changeXAxisLabel() {
                switch (this.type) {
                    case 'all_activity':
                        return 'Number of Activities'
                    case 'all_calls':
                        return 'Number of Calls'
                    case 'all_qualified_calls':
                        return 'Number of Qualified Calls'
                    case 'inbound_calls':
                        return 'Number of Inbound Calls'
                    case 'qualified_inbound_calls':
                        return 'Number of Qualified Inbound Calls'
                    case 'all_missed_calls':
                        return 'Number of Missed Calls'
                    case 'missed_calls_percent':
                        return 'Missed Calls (percentage)'
                    case 'outbound_calls':
                        return 'Number of Outbound Calls'
                    case 'qualified_outbound_calls':
                        return 'Number of Qualified Outbound Calls'
                    case 'qualified_outbound_calls_percent':
                        return 'Qualified Outbound Calls (percentage)'
                    case 'average_duration':
                        return 'Average duration (in seconds)'
                    case 'total_duration':
                        return 'Total duration (in seconds)'
                    case 'average_talk_time':
                        return 'Average Talk Time (in seconds)'
                    case 'total_talk_time':
                        return 'Total Talk Time (in seconds)'
                    case 'average_wait_time':
                        return 'Average Wait Time (in seconds)'
                    case 'total_wait_time':
                        return 'Total Wait Time (in seconds)'
                    case 'all_text_messages':
                        return 'Number of Text Messages'
                    case 'inbound_text_messages':
                        return 'Number of Inbound Text Messages'
                    case 'outbound_text_messages':
                        return 'Number of Outbound Text Messages'
                    case 'all_emails':
                        return 'Number of Emails'
                    case 'inbound_emails':
                        return 'Number of Inbound Emails'
                    case 'outbound_emails':
                        return 'Number of Outbound Emails'
                    case 'all_faxes':
                        return 'Number of Faxes'
                    case 'inbound_faxes':
                        return 'Number of Inbound Faxes'
                    case 'outbound_faxes':
                        return 'Number of Outbound Faxes'
                    case 'text_messages':
                        return 'Number of Text Messages'
                    case 'failed':
                        return 'Number of Failed Messages'
                    case 'ringless_voicemails':
                        return 'Number of Ringless Voicemails'
                }
            },

            updateChartHeight() {
                if (this.chart_type == 'bar' && this.data.length > 25) {
                    this.options.chart.height = 20 * this.data.length
                }
            },
        },

        watch: {
            data: {
                deep: true,
                handler: function () {
                    this.generateGraphData()
                }
            },
            'type': function () {
                this.graph_label = this.changeXAxisLabel()
                this.generateGraphData()
            }
        },

        destroyed() {
            this.loading = true
            this.is_done = false
        }
    }
</script>
