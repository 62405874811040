<template>
    <a style="vertical-align: -2px;"
       v-if="hasRole('Billing Admin') || hasRole('Company Admin')"
       :href="talkUrl"
       data-testid="talk-bridge-a">
        <el-button
            type="primary"
            size="mini"
            class="default-app-button talk-button btn btn-xs"
            data-testid="talk-bridge-el-button">
            Talk
        </el-button>
    </a>
    <a style="vertical-align: -2px;"
       v-else
       data-testid="talk-bridge-a"
       :href="talkUrl"
       @click.prevent>
        <el-dropdown
            @click="navigateToTalk"
            split-button
            type="primary"
            size="mini"
            trigger="click"
            class="default-app-dropdown talk-button btn btn-xs">
            Talk
            <template v-slot:dropdown>
                <el-dropdown-menu class="default-app-dropdown" 
                                  data-testid="dropdown-menu">
                    <el-dropdown-item
                        disabled
                        class="mb-2"
                        data-testid="dropdown-menu-item">
                        <span class="label-text">Open on Login:</span>
                    </el-dropdown-item>
                    <el-dropdown-item data-testid="dropdown-menu-item">
                        <el-radio
                            data-testid="talk-bridge-radio"
                            v-model="auth.user.profile.default_app"
                            :label="AppTypes.APP_ALOWARE_TALK"
                            @change="updateDefaultApp">
                            {{ alowareTalk }}
                        </el-radio>
                    </el-dropdown-item>
                    <el-dropdown-item data-testid="dropdown-menu-item">
                        <el-radio
                            data-testid="talk-bridge-radio"
                            v-model="auth.user.profile.default_app"
                            :label="AppTypes.APP_ALOWARE_CLASSIC"
                            @change="updateDefaultApp">
                            {{ alowareClassic }}
                        </el-radio>
                    </el-dropdown-item>
                </el-dropdown-menu>
            </template>
        </el-dropdown>
    </a>
</template>

<script>
import auth from '../auth'
import {acl_mixin, helper_mixin} from "../mixins"
import * as AppTypes from '../constants/aloware-app-types'

export default {
    name: "talk-bridge",

    mixins: [acl_mixin, helper_mixin],

    data() {
        return {
            auth: auth,
            loading: false,
            talk_url: localStorage.getItem('talk_url'),
            AppTypes,
        }
    },

    computed: {
        talkUrl() {
            if (this.isSimpSocial) {
                const simpsocial_url = this.talk_url.replace('aloware', 'simpsocial')

                return `${simpsocial_url}?from_classic=1` + (localStorage.getItem('impersonate') === 'true' ? '&impersonating=1' : '')
            }

            return `${this.talk_url}?from_classic=1` + (localStorage.getItem('impersonate') === 'true' ? '&impersonating=1' : '')
        }
    },

    methods: {
        updateDefaultApp(value) {
            this.loading = true

            axios.patch(`/api/v1/user/${this.auth.user.profile.id}/set-default-app`, {
                default_app: value
            }).then(res => {
                this.loading = false

                this.$notify.success({
                    title: 'Default App',
                    message: 'Default app successfully updated.',
                    offset: 95,
                    duration: 5000
                })
            }).catch(err => {
                this.loading = false
                console.log(err)
            })
        },

        navigateToTalk() {
            if (this.isSimpSocial) {
                const simpsocial_url = this.talk_url.replace('aloware', 'simpsocial')
                return window.location.href = simpsocial_url
            }

            window.location.href = this.talkUrl
        },
    }
}
</script>
