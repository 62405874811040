var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "node-template",
    {
      attrs: { id: _vm.nodeId },
      scopedSlots: _vm._u([
        {
          key: "header",
          fn: function () {
            return [_c("span", [_vm._v("Get Input")])]
          },
          proxy: true,
        },
      ]),
    },
    [
      _vm._v(" "),
      _c("Intents", { attrs: { data: _vm.data } }),
      _vm._v(" "),
      _c("Fallbacks", {
        staticClass: "mt-1",
        attrs: { fallbacks: _vm.data.fallback, start_id: _vm.startId },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }