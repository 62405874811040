<template>
    <span>
        <el-popover
            :placement="position"
            :width="width"
            :trigger="trigger"
            v-model="is_open"
            @show="nameEditorOnShow">
            <el-form class="edit-contact"
                     ref="edit_contact_info_form"
                     label-width="auto"
                     label-position="top"
                     :model="selected_contact"
                     :rules="rules"
                     @submit.prevent.native="preventSubmit">
                <div style="text-align: right; margin: 0">
                    <div class="row">
                        <div class="col-12 mb-2">
                            <el-form-item class="mb-0 first-name"
                                          prop="first_name">
                                <div class="md-form-group pb-0">
                                    <label>First Name</label>
                                    <el-input class="md-input"
                                              ref="first_name"
                                              v-model="selected_contact.first_name"
                                              data-testid="edit-contact-first-name-input"
                                              @input="preValidateForm('edit_contact_info_form')">
                                    </el-input>
                                </div>
                            </el-form-item>
                        </div>
                        <div class="col-12">
                            <el-form-item class="mb-0 last-name"
                                          prop="last_name">
                                <div class="md-form-group pb-0">
                                    <label>Last Name</label>
                                    <el-input class="md-input"
                                              ref="last_name"
                                              v-model="selected_contact.last_name"
                                              data-testid="edit-contact-last-name-input"
                                              @input="preValidateForm('edit_contact_info_form')">
                                    </el-input>
                                </div>
                            </el-form-item>
                        </div>
                        <div class="col-12 mt-4">
                            <button class="btn btn-xs _400 greenish pull-right"
                                    :disabled="validated === false"
                                    data-testid="edit-contact-save-button"
                                    @click="quickUpdateContact">
                                <i class="material-icons loader"
                                   v-show="contact_saving">&#xE863;</i>
                                <span>Save</span>
                            </button>
                            <button class="btn btn-xs _400 btn-secondary pull-right mr-2"
                                    data-testid="edit-contact-cancel-button"
                                    :disabled="contact_saving"
                                    @click="hideForm">
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            </el-form>
            <div slot="reference">
                <slot name="ref_trigger"></slot>
            </div>
        </el-popover>
    </span>
</template>

<script>
import auth from '../auth'
import {form_validation_mixin} from '../mixins'

export default {
    mixins: [form_validation_mixin],

    props: {
        contact: {
            required: true,
            type: Object,
            default() {
                return {
                    first_name: '',
                    last_name: ''
                }
            },
        },
        position: {
            required: false,
            type: String,
            default: 'left'
        },
        width: {
            required: false,
            type: Number,
            default: 250
        },
        trigger: {
            required: false,
            type: String,
            default: 'click'
        }
    },

    data() {
        return {
            auth: auth,
            is_open: false,
            contact_saving: false,
            selected_contact: {
                id: null,
                first_name: null,
                last_name: null
            },
            rules: {
                first_name: [
                    {
                        required: true,
                        message: 'Please provide first name',
                        trigger: 'blur'
                    }
                ],
                last_name: [
                    {
                        required: true,
                        message: 'Please provide last name',
                        trigger: 'blur'
                    }
                ],
            }
        }
    },

    mounted() {
        if (typeof this.contact.id != 'undefined' && this.contact.id) {
            this.selected_contact = this.contact
        }
    },

    methods: {
        nameEditorOnShow() {
            this.validated = false
            this.$nextTick(() => {
                this.$refs.first_name.focus()
            })
        },
        quickUpdateContact() {
            if (typeof this.contact.id == 'undefined') {
                return
            }
            // sanity check
            this.preValidateForm('edit_contact_info_form')
            if (!this.validated) {
                return
            }
            let params = {
                first_name: this.selected_contact.first_name,
                last_name: this.selected_contact.last_name,
            }
            this.contact_saving = true
            // send an axios patch for all fields
            axios.patch('/api/v1/contact/' + this.contact.id, params)
                .then(res => {
                    VueEvent.fire('contact_updated', res.data)
                    this.contact_saving = false
                    this.$emit('update', res.data)
                    this.hideForm()
                })
                .catch(err => {
                    this.$root.handleErrors(err.response)
                    this.contact_saving = false
                    this.hideForm()
                })
        },

        hideForm() {
            this.is_open = !this.is_open
        },

        preventSubmit() {
            return false
        }
    },

    watch: {
        'contact': function () {
            this.selected_contact = this.contact
        }
    }
}
</script>
