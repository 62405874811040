<template>
    <div class="waveform-container"
         v-loading="!ready">
        <button class="play-button"
                :disabled="!ready"
                data-testid="waveform-play-button"
                @click.prevent="handlePlay">
            <i class="fa fa-pause"
               v-show="playing"></i>
            <i class="fa fa-play"
               v-show="!playing"></i>
        </button>
        <div id="waveform"></div>
        <div class="waveform-timeline">
            <span class="text-xxs">{{ current_time | fixDuration }} / {{ duration | fixDuration }}</span>
        </div>
        <vue-wave-surfer ref="surf"
                         data-testid="waveform-surfer"
                         :src="remote_url"
                         :options="options">
        </vue-wave-surfer>
    </div>
</template>

<script>
import auth from '../auth'
import {acl_mixin} from '../mixins'

export default {
    mixins: [acl_mixin],

    props: {
        remote_url: {
            required: true
        },
    },

    data() {
        return {
            auth: auth,
            options: {
                barRadius: 2,
                barWidth: 2,
                barGap: null,
                cursorWidth: 1,
                container: '#waveform',
                hideScrollbar: true,
                backend: 'WebAudio',
                height: 40,
                progressColor: '#2D5BFF',
                responsive: true,
                waveColor: '#EFEFEF',
                cursorColor: '#2D5BFF',
                splitChannels: false,
                splitChannelsOptions: {
                    overlay: false,
                    channelColors: [
                        {
                            waveColor: '#2D5BFF',
                            progressColor: '#0056b3'
                        },
                        {
                            waveColor: '#6c757d',
                            progressColor: '#495057'
                        }
                    ]
                }
            },
            playing: false,
            ready: false,
            duration: 0,
            current_time: 0
        }
    },

    computed: {
        player() {
            return this.$refs.surf.waveSurfer
        }
    },

    mounted() {
        this.player.on('ready', () => {
            this.ready = true
            this.duration = this.player.getDuration()
        })

        this.player.on('finish', () => {
            this.playing = false
        })

        this.player.on('audioprocess', () => {
            this.current_time = this.player.getCurrentTime()
        })
    },

    methods: {
        handlePlay() {
            this.playing = !this.playing
            this.player.playPause()
        }
    }
}
</script>
