var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "app-body conversync max-height no-footer",
      class: _vm.getClass,
      style: {
        height: "calc(100% - " + (_vm.top_offset + 1) + "px) !important",
      },
      attrs: { id: "view" },
    },
    [
      _vm.hasPermissionTo("update contact")
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: "Send GIF",
                top: "10vh",
                "append-to-body": "",
                "custom-class": "wizard-dialog dialog-no-border gif-dialog",
                visible: _vm.giphyMediaDialogVisible,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.giphyMediaDialogVisible = $event
                },
              },
            },
            [
              _vm.giphyMediaDialogVisible
                ? _c("search-giphy", { on: { selected: _vm.sendGifMessage } })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.hasPermissionTo("update contact")
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: "Send Media",
                visible: _vm.sendMediaDialogVisible,
                top: "10vh",
                "append-to-body": "",
                "custom-class": "wizard-dialog dialog-no-border",
                "before-close": _vm.beforeCloseSendMediaModal,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.sendMediaDialogVisible = $event
                },
                close: _vm.closeSendMediaModal,
              },
            },
            [
              _c(
                "el-form",
                {
                  ref: "media",
                  attrs: {
                    "label-width": "200px",
                    "label-position": "top",
                    model: _vm.media,
                    rules: _vm.rules_media,
                  },
                  nativeOn: {
                    submit: function ($event) {
                      $event.preventDefault()
                      return _vm.sendMedia.apply(null, arguments)
                    },
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "input-group mb-3" },
                    [
                      _c("variable-dialog", {
                        attrs: { is_append: false, short: false },
                      }),
                      _vm._v(" "),
                      _c("textarea", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.media.body,
                            expression: "media.body",
                          },
                        ],
                        staticClass: "form-control r-2x",
                        attrs: { rows: "1", placeholder: _vm.getPlaceholder },
                        domProps: { value: _vm.media.body },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(_vm.media, "body", $event.target.value)
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("p", { staticClass: "form-head-message text-left mb-2" }, [
                    _vm._v("Upload the media file:"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "no-bottom-margin" },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { prop: "file_name" } },
                        [
                          _c(
                            "el-upload",
                            {
                              attrs: {
                                drag: "",
                                action:
                                  "/api/v1/campaign/" +
                                  _vm.selected_campaign_id +
                                  "/upload/media",
                                headers: _vm.headers,
                                "on-success": _vm.onSuccessSendMedia,
                                "on-error": _vm.onFailedSendMedia,
                                "on-progress": _vm.progressUploadSendMedia,
                                "before-upload": _vm.beforeUploadSendMedia,
                                "file-list": _vm.media.files,
                                "on-change": _vm.onChangeFileList,
                                "on-remove": _vm.onChangeFileList,
                                multiple: "",
                              },
                            },
                            [
                              _c("i", { staticClass: "el-icon-upload" }),
                              _vm._v(" "),
                              _c("div", { staticClass: "el-upload__text" }, [
                                _vm._v(
                                  "\n                            Drop file here or "
                                ),
                                _c("em", [_vm._v("click to upload")]),
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "el-upload__tip p-3 break-word",
                                  attrs: { slot: "tip" },
                                  slot: "tip",
                                },
                                [
                                  _vm._v(
                                    "\n                            We support various media file types.\n                            The file size limit for images is 5MB, and for the other file types, it should be below 600KB for carriers to accept it.\n                        "
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _vm.media.file_name === null
                            ? _c("el-progress", {
                                attrs: {
                                  "text-inside": true,
                                  "stroke-width": 18,
                                  percentage: _vm.uploadPercentage.upload,
                                  status: _vm.uploadStatus.upload,
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          _vm.sendMediaDialogVisible = false
                        },
                      },
                    },
                    [_vm._v("Cancel")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: "success",
                        loading: _vm.loading_send_media_btn,
                        disabled: !_vm.validated,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.sendMedia()
                        },
                      },
                    },
                    [_vm._v("\n                Send\n            ")]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.hasPermissionTo("update contact")
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: "Send Fax",
                visible: _vm.sendFaxDialogVisible,
                top: "10vh",
                "append-to-body": "",
                "custom-class": "wizard-dialog dialog-no-border",
                "before-close": _vm.beforeCloseSendFaxModal,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.sendFaxDialogVisible = $event
                },
                close: _vm.closeSendFaxModal,
              },
            },
            [
              _c(
                "el-form",
                {
                  ref: "fax",
                  attrs: {
                    "label-width": "200px",
                    "label-position": "top",
                    model: _vm.fax,
                    rules: _vm.rules_fax,
                  },
                  nativeOn: {
                    submit: function ($event) {
                      $event.preventDefault()
                      return _vm.sendFax.apply(null, arguments)
                    },
                  },
                },
                [
                  _c("p", { staticClass: "form-head-message text-left mb-2" }, [
                    _vm._v("Upload a PDF file:"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "no-bottom-margin" },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { prop: "file_name" } },
                        [
                          _c(
                            "el-upload",
                            {
                              attrs: {
                                drag: "",
                                action:
                                  "/api/v1/campaign/" +
                                  _vm.selected_campaign_id +
                                  "/upload/fax",
                                headers: _vm.headers,
                                "on-success": _vm.onSuccessSendFax,
                                "on-error": _vm.onFailedSendFax,
                                "on-progress": _vm.progressUploadSendFax,
                                "before-upload": _vm.beforeUploadSendFax,
                                "file-list": _vm.uploadFileList.upload,
                                multiple: false,
                              },
                            },
                            [
                              _c("i", { staticClass: "el-icon-upload" }),
                              _vm._v(" "),
                              _c("div", { staticClass: "el-upload__text" }, [
                                _vm._v(
                                  "\n                            Drop file here or "
                                ),
                                _c("em", [_vm._v("click to upload")]),
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "el-upload__tip",
                                  attrs: { slot: "tip" },
                                  slot: "tip",
                                },
                                [
                                  _vm._v(
                                    "\n                            PDF file (up to 32MB)\n                        "
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _vm.fax.file_name === null
                            ? _c("el-progress", {
                                attrs: {
                                  "text-inside": true,
                                  "stroke-width": 18,
                                  percentage: _vm.uploadPercentage.upload,
                                  status: _vm.uploadStatus.upload,
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          _vm.sendFaxDialogVisible = false
                        },
                      },
                    },
                    [_vm._v("Cancel")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: "success",
                        loading: _vm.loading_send_fax_btn,
                        disabled: !_vm.validated,
                      },
                      on: { click: _vm.sendFax },
                    },
                    [_vm._v("\n                Send\n            ")]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.hasPermissionTo("update contact")
        ? _c("new-email", {
            ref: "newEmailMessage",
            attrs: {
              contact_id: _vm.contact_id,
              selected_campaign_id: _vm.selected_campaign_id,
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.hasPermissionTo("update contact")
        ? _c("new-car", {
            key: _vm.prop_counter,
            ref: "newCarMessage",
            attrs: {
              contact_id: _vm.contact_id,
              selected_campaign_id: _vm.selected_campaign_id,
            },
            on: { hide: _vm.newCarFormClosed },
          })
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "pt-3 pl-3 pr-3 full-height" }, [
        !_vm.isLargeEnough &&
        _vm.contact_id &&
        !_vm.$root.loading &&
        _vm.hasPermissionTo("view contact")
          ? _c(
              "div",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.loading_contact,
                    expression: "loading_contact",
                  },
                ],
                staticClass: "full-height",
              },
              [!_vm.statics.whitelabel ? _c("div", [_vm._m(0)]) : _vm._e()]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.isLargeEnough &&
        _vm.contact_id &&
        _vm.hasPermissionTo("view contact")
          ? _c(
              "div",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.loading_contact,
                    expression: "loading_contact",
                  },
                ],
                staticClass: "full-height test",
              },
              [
                _c("div", { staticClass: "row flex-nowrap full-height" }, [
                  _c(
                    "div",
                    {
                      staticClass: "col sidebar-full-height scroll-y pl-2 pr-2",
                    },
                    [
                      _c("div", { staticClass: "box b-a" }, [
                        _c(
                          "div",
                          {
                            staticClass: "d-flex justify-content-between mt-3",
                          },
                          [
                            !_vm.embedded
                              ? _c(
                                  "el-button",
                                  {
                                    staticClass: "no-radius",
                                    attrs: {
                                      type: "info",
                                      size: "small",
                                      plain: "",
                                      "data-testid": "back-to-contacts-button",
                                    },
                                    on: { click: _vm.backToContacts },
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "el-icon-arrow-left",
                                    }),
                                    _vm._v(
                                      "\n                                Contacts\n                            "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.selected_contact && !_vm.embedded
                              ? _c(
                                  "div",
                                  { staticClass: "d-flex" },
                                  [
                                    _c(
                                      "el-button-group",
                                      [
                                        _c(
                                          "el-button",
                                          {
                                            ref: "previousContact",
                                            staticClass: "no-radius ml-0",
                                            attrs: {
                                              type: "info",
                                              size: "small",
                                              icon: "el-icon-arrow-left",
                                              disabled:
                                                _vm.contact_list_pagination_loading,
                                              "data-testid":
                                                "previous-contact-button",
                                              plain: "",
                                            },
                                            on: {
                                              click: _vm.goToPreviousContact,
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                                        Prev\n                                        "
                                            ),
                                            _vm.contact_list_pagination_loading
                                              ? _c("i", {
                                                  staticClass:
                                                    "fa fa-spinner fa-spin",
                                                })
                                              : _vm._e(),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-button",
                                          {
                                            ref: "nextContact",
                                            staticClass: "no-radius ml-0",
                                            attrs: {
                                              type: "info",
                                              size: "small",
                                              disabled:
                                                _vm.contact_list_pagination_loading,
                                              "data-testid":
                                                "next-contact-button",
                                              plain: "",
                                            },
                                            on: { click: _vm.goToNextContact },
                                          },
                                          [
                                            _vm._v(
                                              "\n                                        Next\n                                        "
                                            ),
                                            _vm.contact_list_pagination_loading
                                              ? _c("i", {
                                                  staticClass:
                                                    "fa fa-spinner fa-spin",
                                                })
                                              : _c("i", {
                                                  staticClass:
                                                    "el-icon-arrow-right",
                                                }),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _vm.selected_contact
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "d-flex flex-row pt-3 pb-3 pl-3 pr-3 b-b",
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "d-flex flex-column align-items-start w-100",
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-flex flex-row align-items-start justify-content-between w-100",
                                      },
                                      [
                                        _c("div", { staticClass: "d-flex" }, [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "_600 text-md fixed d-flex align-self-start mr-2",
                                              staticStyle: {
                                                "max-width": "116px",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                                            " +
                                                  _vm._s(
                                                    _vm._f("fixContactName")(
                                                      _vm.selected_contact.name
                                                    )
                                                  ) +
                                                  "\n                                        "
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _vm.hasPermissionTo("update contact")
                                            ? _c(
                                                "div",
                                                { staticClass: "pos-rlt" },
                                                [
                                                  _c(
                                                    "contact-info-form",
                                                    {
                                                      attrs: {
                                                        contact:
                                                          _vm.contactBasic,
                                                        position: "right-start",
                                                        "data-testid":
                                                          "contact-info-form",
                                                      },
                                                      on: {
                                                        update:
                                                          _vm.updateContactInCommunication,
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "no-select",
                                                          attrs: {
                                                            slot: "ref_trigger",
                                                          },
                                                          slot: "ref_trigger",
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "text-md text-greenish pointer no-select",
                                                            },
                                                            [
                                                              _c(
                                                                "i",
                                                                {
                                                                  staticClass:
                                                                    "material-icons",
                                                                },
                                                                [_vm._v("edit")]
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ]),
                                        _vm._v(" "),
                                        _c("digital-clock", {
                                          staticClass: "d-flex ml-3",
                                          attrs: {
                                            timezone: _vm.getTimeZone(
                                              _vm.selected_contact.timezone
                                            ),
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c("span", { staticClass: "text-md" }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("fixPhone")(
                                            _vm.selected_contact.phone_number
                                          )
                                        )
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-flex flex-row mt-2 align-items-center",
                                      },
                                      [
                                        _vm.selected_contact.phone_number
                                          ? _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "label p-1 text-xxs success",
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                        Primary\n                                    "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.selected_contact.lrn_type != null
                                          ? _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "label p-1 text-xxs light-blue ml-2",
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                        " +
                                                    _vm._s(
                                                      _vm._f("fixLrnType")(
                                                        _vm.selected_contact
                                                          .lrn_type
                                                      )
                                                    ) +
                                                    "\n                                    "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.selected_contact.is_dnc
                                          ? _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "label p-1 text-xxs danger ml-2",
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                        DNC\n                                    "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.primaryNumber &&
                                        _vm.primaryNumber.is_wrong_number
                                          ? _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "label p-1 text-xxs red-A400 ml-2",
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                        Wrong Number\n                                    "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.primaryNumber &&
                                        _vm.primaryNumber.is_invalid
                                          ? _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "label p-1 text-xxs red-A400 ml-2",
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                        Invalid Number\n                                    "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.hasPermissionTo("update contact")
                          ? _c(
                              "div",
                              { staticClass: "text-center contact-info" },
                              [
                                _c("contact-buttons", {
                                  attrs: {
                                    contact: _vm.selected_contact,
                                    contact_phone_numbers:
                                      _vm.contact_phone_numbers,
                                    selected_phone_number:
                                      _vm.selected_phone_number,
                                    contact_id: _vm.contact_id,
                                    campaign_id: _vm.selected_campaign_id,
                                    mention_items: _vm.mention_items,
                                    disable_send_fax_button:
                                      _vm.disableSendFaxButton,
                                  },
                                  on: {
                                    "update:disable_send_fax_button": function (
                                      $event
                                    ) {
                                      _vm.disableSendFaxButton = $event
                                    },
                                    changeSelectedPhoneNumber:
                                      _vm.changeSelectedPhoneNumber,
                                    "add-appointment": _vm.addAppointment,
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.selected_contact &&
                        !_vm.selected_contact.is_dnc &&
                        _vm.hasPermissionTo("update contact")
                          ? _c(
                              "div",
                              { staticClass: "row no-gutter b-t mt-2" },
                              [
                                _c("contact-sequence", {
                                  attrs: {
                                    contact_phone_numbers:
                                      _vm.contact_phone_numbers,
                                  },
                                  model: {
                                    value: _vm.selected_contact,
                                    callback: function ($$v) {
                                      _vm.selected_contact = $$v
                                    },
                                    expression: "selected_contact",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "contact-details" },
                          [
                            _c(
                              "el-collapse",
                              {
                                model: {
                                  value: _vm.activeNames,
                                  callback: function ($$v) {
                                    _vm.activeNames = $$v
                                  },
                                  expression: "activeNames",
                                },
                              },
                              [
                                _vm.hubspotEnabled ||
                                _vm.pipedriveEnabled ||
                                _vm.goHighLevelEnabled
                                  ? _c(
                                      "el-collapse-item",
                                      {
                                        attrs: {
                                          title: "Integration Cards",
                                          name: "integration-cards",
                                          "data-testid":
                                            "integration-card-contact-item",
                                        },
                                      },
                                      [
                                        _c("integration-cards", {
                                          attrs: {
                                            contact: _vm.selected_contact,
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.guestyEnabled && _vm.showGuestyReservations
                                  ? _c(
                                      "el-collapse-item",
                                      {
                                        attrs: {
                                          title: "Guesty Reservations",
                                          name: "guesty-reservations",
                                          "data-testid":
                                            "guesty-reservation-contact-item",
                                        },
                                      },
                                      [
                                        _c("guesty-reservations", {
                                          attrs: {
                                            contact: _vm.selected_contact,
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.guestyEnabled && _vm.showGuestyReservations
                                  ? _c(
                                      "el-collapse-item",
                                      {
                                        attrs: {
                                          title: "Guesty Messages",
                                          name: "guesty-messages",
                                          "data-testid":
                                            "guesty-messages-contact-item",
                                        },
                                      },
                                      [
                                        _c("guesty-messages", {
                                          attrs: {
                                            contact: _vm.selected_contact,
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _c(
                                  "el-collapse-item",
                                  {
                                    attrs: {
                                      title: "Integrations",
                                      name: "integrations",
                                      "data-testid":
                                        "integrations-contact-item",
                                    },
                                  },
                                  [
                                    _c("contact-crm-links", {
                                      attrs: { contact: _vm.selected_contact },
                                    }),
                                    _vm._v(" "),
                                    _vm.integrationsDisabled
                                      ? _c(
                                          "div",
                                          { staticClass: "pl-3 pr-3" },
                                          [
                                            _c(
                                              "strong",
                                              { staticClass: "text-muted" },
                                              [
                                                _vm._v(
                                                  "\n                                            Enable your favorite CRM integration and push contacts from the\n                                            integration settings page to see links to your CRM contacts.\n                                        "
                                                ),
                                              ]
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-collapse-item",
                                  {
                                    attrs: {
                                      title: "Scheduled Messages",
                                      name: "scheduled-messages",
                                      "data-testid": "scheduled-messages-item",
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "container text-center my-2 px-4",
                                      },
                                      [
                                        _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              plain: "",
                                              size: "small",
                                              "data-testid":
                                                "open-schedule-messages-dialog",
                                            },
                                            on: {
                                              click:
                                                _vm.openScheduledMessagesDialog,
                                            },
                                          },
                                          [
                                            _c("i", {
                                              staticClass: "fa fa-list",
                                            }),
                                            _vm._v(
                                              " Show Scheduled Messages\n                                        "
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _vm.isPushContactToCrmEnabled
                                  ? _c(
                                      "el-collapse-item",
                                      {
                                        attrs: {
                                          title: "Push Contact to CRM",
                                          name: "push-to-crm",
                                          "data-testid":
                                            "push-contact-to-crm-item",
                                        },
                                      },
                                      [
                                        _c("contact-push-to-crm", {
                                          attrs: {
                                            contact: _vm.selected_contact,
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _c(
                                  "el-collapse-item",
                                  {
                                    attrs: {
                                      title: "Phone Numbers",
                                      name: "phone_numbers",
                                      "data-testid": "phone-number-item",
                                    },
                                  },
                                  [
                                    _c("contact-phone-numbers", {
                                      ref: "contactPhoneNumbers",
                                      attrs: { contact: _vm.selected_contact },
                                      on: {
                                        message: _vm.changeSelectedPhoneNumber,
                                        updated: _vm.setContactPhoneNumbers,
                                        "primary-updated":
                                          _vm.updateContactPhoneNumber,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-collapse-item",
                                  {
                                    attrs: {
                                      title: "About this contact",
                                      name: "about",
                                      "data-testid": "about-this-contact-item",
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "pl-3 pr-3" },
                                      [
                                        _c("contact-details", {
                                          attrs: {
                                            contact: _vm.selected_contact,
                                            first_outbound_call:
                                              _vm.communications_summary
                                                .first_outbound_call,
                                          },
                                          on: {
                                            contactUpdated:
                                              _vm.selectedContactUpdate,
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-collapse-item",
                                  {
                                    attrs: {
                                      title: "Communications Summary",
                                      name: "communications",
                                      "data-testid":
                                        "communitactions-summary-item",
                                    },
                                  },
                                  [
                                    _c("contact-communications-summary", {
                                      attrs: {
                                        summary:
                                          _vm.communications_summary.summaries,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-collapse-item",
                                  {
                                    attrs: {
                                      title: "Contact Lines",
                                      name: "lines",
                                      "data-testid": "contact-lines-item",
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "pl-3 pr-3" },
                                      [
                                        _c("contact-lines", {
                                          attrs: {
                                            contact: _vm.selected_contact,
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-collapse-item",
                                  {
                                    attrs: {
                                      title: "Contact Ring Groups",
                                      name: "ring-groups",
                                      "data-testid": "contact-ring-groups",
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "pl-3 pr-3" },
                                      [
                                        _c("contact-ring-groups", {
                                          attrs: {
                                            contact: _vm.selected_contact,
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-collapse-item",
                                  {
                                    attrs: {
                                      title: "Broadcasts",
                                      name: "broadcasts",
                                      "data-testid": "broadcast-item",
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "pl-3 pr-3" },
                                      [
                                        _c("contact-broadcasts", {
                                          attrs: {
                                            contact: _vm.selected_contact,
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _vm.selected_contact
                    ? _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "loading",
                              rawName: "v-loading",
                              value: _vm.loading_contact_communications,
                              expression: "loading_contact_communications",
                            },
                          ],
                          staticClass: "col pl-2 pr-2",
                        },
                        [
                          _c(
                            "el-tabs",
                            {
                              staticClass: "full-height no-header",
                              attrs: {
                                "data-testid": "contact-communication-card",
                                type: "card",
                              },
                              model: {
                                value: _vm.activeTab,
                                callback: function ($$v) {
                                  _vm.activeTab = $$v
                                },
                                expression: "activeTab",
                              },
                            },
                            [
                              _vm.hasPermissionTo("list communication")
                                ? _c(
                                    "el-tab-pane",
                                    {
                                      staticClass: "full-height bg-white b-a",
                                      attrs: {
                                        name: "activities",
                                        "data-testid":
                                          "contact-communication-pane",
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          attrs: { slot: "label" },
                                          slot: "label",
                                        },
                                        [
                                          _c(
                                            "el-badge",
                                            {
                                              staticClass: "tab-badge",
                                              attrs: {
                                                value: _vm.totalUnreadCount,
                                                max: 99,
                                                "data-testid":
                                                  "contact-activities-badge",
                                                hidden:
                                                  _vm.totalUnreadCount === 0,
                                              },
                                            },
                                            [_c("span", [_vm._v("Activities")])]
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "box row-col no-shadow",
                                          staticStyle: { "min-height": "100%" },
                                        },
                                        [
                                          _vm.hasPermissionTo("list campaign")
                                            ? _c(
                                                "div",
                                                { staticClass: "row p-2" },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "col-12" },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "pull-left no-select",
                                                        },
                                                        [
                                                          _vm.totalUnreadCount !==
                                                          0
                                                            ? _c(
                                                                "button",
                                                                {
                                                                  staticClass:
                                                                    "btn btn-sm blue text-sm",
                                                                  attrs: {
                                                                    disabled:
                                                                      _vm.loading_mark_as_read,
                                                                    "data-testid":
                                                                      "communication-mark-all-as-read-button",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      _vm.markAllAsRead,
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "i",
                                                                    {
                                                                      directives:
                                                                        [
                                                                          {
                                                                            name: "show",
                                                                            rawName:
                                                                              "v-show",
                                                                            value:
                                                                              _vm.loading_mark_as_read,
                                                                            expression:
                                                                              "loading_mark_as_read",
                                                                          },
                                                                        ],
                                                                      staticClass:
                                                                        "material-icons loader",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "\n                                                    \n                                                "
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _vm._v(
                                                                    "\n                                                Mark All As Read\n                                            "
                                                                  ),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "pull-right no-select",
                                                        },
                                                        [
                                                          _c(
                                                            "strong",
                                                            {
                                                              staticClass:
                                                                "text-xs",
                                                            },
                                                            [
                                                              _vm._v(
                                                                "Phone Number:"
                                                              ),
                                                            ]
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "el-select",
                                                            {
                                                              staticClass:
                                                                "no-select",
                                                              staticStyle: {
                                                                width: "150px",
                                                              },
                                                              attrs: {
                                                                placeholder:
                                                                  "Select a phone number",
                                                                size: "mini",
                                                                "data-testid":
                                                                  "communication-phone-number-select",
                                                              },
                                                              on: {
                                                                change:
                                                                  _vm.changeSelectedPhoneNumber,
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.selected_phone_number,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.selected_phone_number =
                                                                      $$v
                                                                  },
                                                                expression:
                                                                  "selected_phone_number",
                                                              },
                                                            },
                                                            _vm._l(
                                                              _vm.contact_phone_numbers,
                                                              function (
                                                                phone_number
                                                              ) {
                                                                return _c(
                                                                  "el-option",
                                                                  {
                                                                    key: phone_number.id,
                                                                    attrs: {
                                                                      label:
                                                                        phone_number.phone_number,
                                                                      value:
                                                                        phone_number.phone_number,
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "span",
                                                                      {
                                                                        staticClass:
                                                                          "pull-left",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            _vm._f(
                                                                              "fixPhone"
                                                                            )(
                                                                              phone_number.phone_number
                                                                            )
                                                                          )
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                )
                                                              }
                                                            ),
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            { staticClass: "row-row" },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "row-body" },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      ref: "activitiesWrap",
                                                      staticClass: "row-inner",
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass: "p-a-sm",
                                                        },
                                                        [
                                                          _c(
                                                            "ul",
                                                            {
                                                              staticClass:
                                                                "timeline timeline-center",
                                                            },
                                                            [
                                                              _vm.has_more_communications
                                                                ? _c(
                                                                    "li",
                                                                    {
                                                                      staticClass:
                                                                        "tl-header mb-3",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "el-button",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              type: "primary",
                                                                              size: "medium",
                                                                              loading:
                                                                                _vm.is_loading_previous_activities,
                                                                              plain:
                                                                                "",
                                                                              "data-testid":
                                                                                "load-more-previous-activities-button",
                                                                            },
                                                                          on: {
                                                                            click:
                                                                              _vm.loadMorePreviousActivities,
                                                                          },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "\n                                                            Previous Activities\n                                                        "
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ],
                                                                    1
                                                                  )
                                                                : _vm._e(),
                                                            ]
                                                          ),
                                                          _vm._v(" "),
                                                          _vm._l(
                                                            _vm.filteredCommunications,
                                                            function (
                                                              communication,
                                                              index
                                                            ) {
                                                              return _c(
                                                                "activity",
                                                                {
                                                                  key:
                                                                    communication.id +
                                                                    "-comm-" +
                                                                    index,
                                                                  ref:
                                                                    (communication.type !==
                                                                    undefined
                                                                      ? "communication-"
                                                                      : "contact-audit-") +
                                                                    communication.id,
                                                                  refInFor: true,
                                                                  attrs: {
                                                                    communication:
                                                                      communication,
                                                                    contact:
                                                                      _vm.selected_contact,
                                                                    campaign_id:
                                                                      _vm.selected_campaign_id,
                                                                  },
                                                                }
                                                              )
                                                            }
                                                          ),
                                                          _vm._v(" "),
                                                          _vm.is_typing
                                                            ? [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "m-b message",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "span",
                                                                      {
                                                                        staticClass:
                                                                          "w-40 avatar grey-300 pull-right",
                                                                        style:
                                                                          _vm.avatarStyle(
                                                                            _vm.selected_contact
                                                                          ),
                                                                      },
                                                                      [
                                                                        _vm.current_company
                                                                          ? _c(
                                                                              "span",
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    _vm._f(
                                                                                      "initials"
                                                                                    )(
                                                                                      _vm._f(
                                                                                        "fixContactName"
                                                                                      )(
                                                                                        _vm
                                                                                          .current_company
                                                                                          .name
                                                                                      )
                                                                                    )
                                                                                  )
                                                                                ),
                                                                              ]
                                                                            )
                                                                          : _vm._e(),
                                                                      ]
                                                                    ),
                                                                    _vm._v(" "),
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "clear d-flex flex-column align-items-end text-right",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "inline r-2x message-body effect7 mt-1 greenish text-left",
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "span",
                                                                              {
                                                                                staticClass:
                                                                                  "arrow pull-top arrow-greenish right",
                                                                              }
                                                                            ),
                                                                            _vm._v(
                                                                              " "
                                                                            ),
                                                                            _c(
                                                                              "div",
                                                                              {
                                                                                staticClass:
                                                                                  "p-a p-y-sm handle-whitespace",
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "span",
                                                                                  [
                                                                                    _vm._v(
                                                                                      _vm._s(
                                                                                        _vm
                                                                                          .is_typing
                                                                                          .message
                                                                                      )
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _vm._v(
                                                                          " "
                                                                        ),
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "text-xs m-t-xs width-500 mr-2",
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "span",
                                                                              {
                                                                                staticClass:
                                                                                  "text-muted",
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  "\n                                                                    from " +
                                                                                    _vm._s(
                                                                                      _vm
                                                                                        .is_typing
                                                                                        .campaign_name
                                                                                    ) +
                                                                                    "\n                                                                "
                                                                                ),
                                                                              ]
                                                                            ),
                                                                            _vm._v(
                                                                              " "
                                                                            ),
                                                                            _c(
                                                                              "span",
                                                                              {
                                                                                staticClass:
                                                                                  "text-muted",
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  "\n                                                                    to " +
                                                                                    _vm._s(
                                                                                      _vm._f(
                                                                                        "fixPhone"
                                                                                      )(
                                                                                        _vm
                                                                                          .is_typing
                                                                                          .phone_number
                                                                                      )
                                                                                    ) +
                                                                                    "\n                                                                "
                                                                                ),
                                                                              ]
                                                                            ),
                                                                            _vm._v(
                                                                              " "
                                                                            ),
                                                                            _c(
                                                                              "span",
                                                                              {
                                                                                staticClass:
                                                                                  "text-muted",
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  "\n                                                                    " +
                                                                                    _vm._s(
                                                                                      _vm
                                                                                        .is_typing
                                                                                        .user_name
                                                                                    ) +
                                                                                    " is typing ...\n                                                                "
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            : _vm._e(),
                                                          _vm._v(" "),
                                                          _vm
                                                            .filteredCommunications
                                                            .length == 0
                                                            ? _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "el-table__empty-block",
                                                                },
                                                                [
                                                                  _c(
                                                                    "span",
                                                                    {
                                                                      staticClass:
                                                                        "el-table__empty-text",
                                                                      staticStyle:
                                                                        {
                                                                          color:
                                                                            "#606266",
                                                                        },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "\n                                                        No Data\n                                                    "
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                        ],
                                                        2
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _vm.contact_loaded
                                            ? _c("message-composer", {
                                                ref: "message_composer",
                                                staticClass: "border-top",
                                                attrs: {
                                                  phone_numbers:
                                                    _vm.contact_phone_numbers,
                                                  "force-disabled": _vm.hasRole(
                                                    "Company Reporter Access"
                                                  ),
                                                },
                                                on: {
                                                  "open-car-form":
                                                    _vm.openCarForm,
                                                  "send-credit-application-link":
                                                    _vm.sendCreditApplicationLink,
                                                  "send-message":
                                                    _vm.sendNewMessage,
                                                  "create-scheduled-message":
                                                    _vm.createScheduledMessage,
                                                  "changed-selected-campaign":
                                                    _vm.changeSelectedCampaign,
                                                },
                                                model: {
                                                  value: _vm.message_object,
                                                  callback: function ($$v) {
                                                    _vm.message_object = $$v
                                                  },
                                                  expression: "message_object",
                                                },
                                              })
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ]),
              ]
            )
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c("appointment-reminder-manager", {
        ref: "manager",
        attrs: {
          called_from: "contact",
          "data-testid": "contact-appointment-reminder-manager",
          contact: _vm.selected_contact,
        },
      }),
      _vm._v(" "),
      _c("contact-scheduled-messages-list", {
        attrs: {
          contact: _vm.contact,
          "data-testid": "contact-scheduled-messages-list",
          contact_id: _vm.contact_id,
        },
      }),
      _vm._v(" "),
      _c("contact-merge-dialog", {
        attrs: {
          "data-testid": "contact-merge-dialog",
          contact: _vm.selected_contact,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "p-a notif-card el-card" }, [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-md-12" }, [
          _c(
            "a",
            {
              staticClass: "text-md text-bluish",
              attrs: {
                href: "https://aloware.com/apps",
                "data-testid": "aloware-app-link",
              },
            },
            [
              _vm._v(
                "\n                                Please download Aloware app on your smartphone 📱\n                            "
              ),
            ]
          ),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }