var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "app-body", attrs: { id: "view" } }, [
    _c("div", { staticClass: "row mb-0 mt-0" }, [
      _c("div", { staticClass: "d-flex flex-fill flex-wrap p-2" }, [
        _c(
          "div",
          { staticClass: "d-flex flex-wrap flex-xl-nowrap flex-fill mr-auto" },
          [
            _c(
              "div",
              {
                staticClass: "mr-sm-2 flex-fill flex-xl-grow-0 search-toolbar",
              },
              [
                _c("el-input", {
                  ref: "userSearch",
                  attrs: {
                    placeholder: "Search",
                    "prefix-icon": "el-icon-search",
                    clearable: "",
                    autofocus: "",
                    "data-testid": "user-search-input",
                  },
                  model: {
                    value: _vm.search_text,
                    callback: function ($$v) {
                      _vm.search_text = $$v
                    },
                    expression: "search_text",
                  },
                }),
              ],
              1
            ),
          ]
        ),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "padding" }, [
      _c("div", { staticClass: "row box" }, [
        _c("div", { staticClass: "col-12" }, [
          _c("div", { staticClass: "no-effect" }, [
            _c("div", { staticClass: "box-header pb-0" }, [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-3" }, [
                  _vm.filteredUsers
                    ? _c("span", { staticClass: "pull-left font-120" }, [
                        _c("strong", [
                          _vm._v(_vm._s(_vm.filteredUsers.length || 0)),
                        ]),
                        _vm._v(
                          "\n                                    " +
                            _vm._s(
                              _vm.filteredUsers.length > 1 ? "users" : "user"
                            ) +
                            "\n                                "
                        ),
                      ])
                    : _vm._e(),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "box-body pt-0" }, [
              _c(
                "div",
                { on: { mouseleave: _vm.tableLeave } },
                [
                  _c(
                    "el-table",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.loading,
                          expression: "loading",
                        },
                      ],
                      staticClass: "w-full",
                      attrs: {
                        data: _vm.paginatedData,
                        "default-sort": { prop: "id", order: "ascending" },
                        fit: "",
                        stripe: "",
                      },
                      on: { "cell-mouse-enter": _vm.cellEnter },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          "data-testid": "users-name-column",
                          prop: "name",
                          label: "Name",
                          "min-width": 200,
                          sortable: "",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  "\n                                        " +
                                    _vm._s(scope.row.name || "-") +
                                    "\n                                    "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          "data-testid": "users-name-column",
                          prop: "summarization_enabled",
                          label: "Summarization Status",
                          "min-width": 100,
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("el-switch", {
                                  staticClass: "mr-4 align-self-center",
                                  attrs: {
                                    "active-color": "#00BF50",
                                    disabled: !scope.row.transcription_enabled,
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.handleSummarizationStatusChange(
                                        scope.row,
                                        scope.row.summarization_enabled
                                      )
                                    },
                                  },
                                  model: {
                                    value: scope.row.summarization_enabled,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        scope.row,
                                        "summarization_enabled",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "scope.row.summarization_enabled",
                                  },
                                }),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c(
                        "template",
                        {
                          staticClass: "text-center text-muted",
                          attrs: { "data-testid": "no-data-users-template" },
                          slot: "empty",
                        },
                        [
                          !_vm.loading
                            ? _c("span", [_vm._v("No Data")])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.loading ? _c("span") : _vm._e(),
                        ]
                      ),
                    ],
                    2
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm.pageCount >= 1
                ? _c(
                    "div",
                    { staticClass: "mt-3" },
                    [
                      _c("el-pagination", {
                        staticClass: "pull-right",
                        attrs: {
                          background: "",
                          layout: "sizes, prev, pager, next",
                          "page-sizes": [10, 20, 50],
                          "page-size": _vm.pagination.per_page,
                          "current-page": _vm.pagination.current_page,
                          total: _vm.dataCount,
                          "data-testid": "users-list-pagination",
                        },
                        on: {
                          "update:currentPage": function ($event) {
                            return _vm.$set(
                              _vm.pagination,
                              "current_page",
                              $event
                            )
                          },
                          "update:current-page": function ($event) {
                            return _vm.$set(
                              _vm.pagination,
                              "current_page",
                              $event
                            )
                          },
                          "current-change": _vm.changePage,
                          "size-change": _vm.handleSizeChange,
                        },
                      }),
                      _vm._v(" "),
                      _c("div", { staticClass: "clearfix" }),
                    ],
                    1
                  )
                : _vm._e(),
            ]),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }