<template>
    <div>
        <div class="col-12">
            <p class="font-weight-bold side-panel-title">Store Input Panel</p>
        </div>

        <div class="col-12 mt-2">
            <p class="mb-0 font-weight-bold">Name:</p>
            <el-input
                placeholder="Type name here."
                :rows="3"
                v-model="nodeData.name">
            </el-input>
        </div>

        <div class="col-12 mt-4">
            <p class="mb-0 font-weight-bold">Custom Property:</p>
            <el-input
                :rows="3"
                v-model="nodeData.aloware_name">
            </el-input>
        </div>

        <div class="col-12 mt-4">
            <p class="mb-0 font-weight-bold">Type:</p>
            <el-select
                v-model="nodeData.type"
                placeholder="Select data type">

                <el-option
                    v-for="data_type in data_types"
                    :key="data_type"
                    :label="data_type"
                    :value="data_type"/>

            </el-select>
        </div>

        <div class="col-12 mt-4">
            <p class="mb-0 font-weight-bold">Message:</p>
            <el-input
                type="textarea"
                placeholder="Type the escalation message here."
                :rows="3"
                v-model="nodeData.message">
            </el-input>
        </div>

        <div class="col-12 text-right">
            <el-tooltip
                content="Add variable"
                placement="top">
                <Variables @variable-selected="variableSelected"/>
            </el-tooltip>
        </div>

        <div class="col-12 mt-2">
            <Fallback
                ref="fallback"
                :fallbacks.sync="fallbacks"
                @change="updateFallback"/>
        </div>
    </div>
</template>

<script>
import { alohabot_side_panel_mixin } from '../../../mixins'
import Fallback from './panel-fallback-component.vue'
import Variables from '../../messenger/variables'

export default {
    name: 'store-input-panel',
    mixins: [
        alohabot_side_panel_mixin
    ],
    components: {
        Fallback,
        Variables
    },
    data() {
        return {
            data_types: [
                'date',
                'time',
                'any',
                'number',
                'duration'
            ]
        }
    },
    watch: {
        fallbacks (value) {
            let copy = JSON.parse(JSON.stringify(this.nodeData))
            copy.fallback = value
            this.nodeData = copy
        }
    },
    methods: {
        handleSaveState (payload) {
            this.$refs['fallback'].handleSaveState()
        },

        variableSelected(variable) {
            this.nodeData.message = (this.nodeData.message ?? '') + ' ' + variable
        },
    }
}
</script>
