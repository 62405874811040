<template>
    <div class="app-body sold-report-container"
         id="view">
        <iframe :src="source"></iframe>
    </div>
</template>

<script>
import auth from '../../auth'
import {mapState} from 'vuex'
export default {
    data() {
        return {
            auth: auth,
            source: ''
        }
    },

    mounted() {
        axios.get('/integrations/simpsocial/dms-equity-source').then(res => {
            this.source = res.data.source
        }).catch(err => {
            console.log(err)
        })
    },

    created() {
        this.getStatics()
    },

    methods: {
        getStatics() {
            axios.get('/get-statics')
                .then(res => {
                    this.statics = res.data
                    this.setPageTitle('DMS Equity Campaigns - ' + this.statics.name)
                })
                .catch(err => {
                    this.setPageTitle('DMS Equity Campaigns - Aloware')
                    console.log(err)
                    this.$root.handleErrors(err.response)
                })
        },
    },

    beforeRouteEnter(to, from, next) {
        auth.check()
            .then((res) => {
                if (res.data.user.is_reseller) {
                    // redirect to account management portal if the company is a reseller
                    next({name: 'Account Management Portal'})
                } else {
                    next()
                }
            })
            .catch((err) => {
                next({name: 'Login', query: {redirect: to.fullPath}})
            })
    }
}
</script>
