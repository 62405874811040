<template>
    <div class="account-registration row">
        <div class="loading-overlay"
            v-if="isLoading">
            <div>
                <img class="loading-icon"
                    alt="Loading"
                    src="/assets/images/loading.svg"/>
                <p class="loading-text">{{ loadingText }}</p>
            </div>
        </div>

        <div class="px-10 bg-white"
             :class="[shouldShowBanner ? 'col-8' : 'col-12']"
             v-if="statics.name" >
            <div class="logo absolute-top pt-xl px-xl">
                <img :src="statics.logo_square" style="width: 60px;"
                    alt="Logo" />
            </div>
            <div class="stepper__container pt-20">
                <step-header description="We are thrilled for you to better communicate with your customers today. Set your password and create your account"
                             :company_name="statics.name"
                             :is-mobile="isMobile"/>

                <div class="stepper__content">
                    <div class="min-w-90 w-90"
                         v-if="shouldShowBanner || isTablet">
                        <input-group>
                            <template v-slot:content>
                                <input-field label="First Name"
                                             placeholder="Type your first name"
                                             ref="first_name-input"
                                             :paddingClasses="isLargeScreen ? 'pr-4' : ''"
                                             :col-span="twoColumnsOrThreeColumnsForm"
                                             :rules="[validateFieldError('first_name')]"
                                             :disabled="checkIfFieldIsPreFilled('first_name')"
                                             v-model="form.first_name"
                                             @input="cleanFieldError('first_name')">
                                    <template v-slot:hint
                                            v-if="fieldErrors.first_name">
                                    {{ fieldErrors.first_name[0] }}
                                    </template>
                                </input-field>

                                <input-field label="Last Name"
                                            placeholder="Type your last name"
                                            ref="last_name-input"
                                            :paddingClasses="isLargeScreen ? 'pl-4' : ''"
                                            :col-span="twoColumnsOrThreeColumnsForm"
                                            :rules="[validateFieldError('last_name')]"
                                            :disabled="checkIfFieldIsPreFilled('last_name')"
                                            v-model="form.last_name"
                                            @input="cleanFieldError('last_name')">
                                    <template v-slot:hint
                                            v-if="fieldErrors.last_name">
                                    <span>{{ fieldErrors.last_name[0] }}</span>
                                    </template>
                                </input-field>
                            </template>
                        </input-group>

                        <input-group>
                            <template v-slot:content>
                                <input-field label="Email Address"
                                             placeholder="youremail@domain.com"
                                             type="email"
                                             ref="email-input"
                                             :paddingClasses="isLargeScreen ? 'pr-4' : ''"
                                             :col-span="twoColumnsOrThreeColumnsForm"
                                             :rules="[validateEmail, validateFieldError('email')]"
                                             :disabled="checkIfFieldIsPreFilled('email')"
                                             v-model="form.email"
                                             @input="cleanFieldError('email')" />

                                <phone-number-field v-if="isCompanyRegistration"
                                                    label="Phone Number"
                                                    placeholder="222 333 4444"
                                                    ref="phone_number-input"
                                                    :rules="[validatePhoneNumber, validateFieldError('phone_number')]"
                                                    :paddingClasses="isLargeScreen ? 'pl-4' : ''"
                                                    :col-span="twoColumnsOrThreeColumnsForm"
                                                    :disabled="checkIfFieldIsPreFilled('phone_number')"
                                                    :country-code="countryCode"
                                                    v-model="phoneNumberValue"
                                                    @input="cleanFieldError('phone_number')" />
                            </template>
                        </input-group>

                        <input-group v-if="isCompanyRegistration">
                            <template v-slot:content>
                                <select-field label="Country"
                                               placeholder="Ex: United States"
                                               option-label="name"
                                               option-value="id"
                                               ref="country-input"
                                               :rules="[validateFieldError('country')]"
                                               :paddingClasses="isLargeScreen ? 'pl-4' : ''"
                                               :col-span="twoColumnsOrThreeColumnsForm"
                                               :options="countries"
                                               :disabled="checkIfFieldIsPreFilled('country')"
                                               v-model="form.country" />

                                <input-field label="Business Name"
                                             placeholder="Ex. Aloware Inc."
                                             ref="company_name-input"
                                             :paddingClasses="isLargeScreen ? 'pr-4' : ''"
                                             :col-span="twoColumnsOrThreeColumnsForm"
                                             :rules="[validateFieldError('company_name')]"
                                             :disabled="checkIfFieldIsPreFilled('company_name')"
                                             v-model="form.company_name"
                                             @input="cleanFieldError('company_name')" />
                            </template>
                        </input-group>

                        <input-group>
                            <template v-slot:content>
                                <password-input :col-span="twoColumnsOrThreeColumnsForm"
                                                v-model="form.password"
                                                @valid="updatePasswordValid($event)" />

                                <password-confirmation-input :col-span="twoColumnsOrThreeColumnsForm"
                                                             :main-password="form.password"
                                                             v-model="form.password_confirmation"
                                                             @match="updatePasswordsMatch($event)" />
                            </template>
                        </input-group>

                        <div class="accept-box">
                            <div>
                                <div>
                                    <el-checkbox class="mb-3 q-pr-xs"
                                                 color="primary"
                                                 dense
                                                 v-model="form.agreed_to_terms">
                                        <template slot="default">
                                            I agree to
                                            <a class="text-bluish _600"
                                               :href="statics.link_terms_conditions"
                                               target="_blank">
                                                Terms and Conditions
                                            </a>
                                            and
                                            <a class="text-bluish _600"
                                               :href="statics.acceptable_use_policy"
                                               target="_blank">
                                                Acceptable Use Policy.
                                            </a>
                                        </template>
                                    </el-checkbox>
                                </div>

                                <vue-recaptcha ref="recaptcha"
                                               class="g-recaptcha pt-3"
                                               :sitekey="site_key"
                                               v-if="site_key"
                                               @verify="onVerifyRecaptcha"
                                               @expired="onExpiredRecaptcha">
                                </vue-recaptcha>
                            </div>
                        </div>
                    </div>

                    <div class="min-w-90 w-90"
                         v-else-if="isMobile">
                        <input-group>
                            <template v-slot:content>
                                <input-field label="First Name"
                                             placeholder="Type your first name"
                                             ref="first_name-input"
                                             :paddingClasses="isLargeScreen ? 'pr-4' : ''"
                                             :col-span="twoColumnsOrThreeColumnsForm"
                                             :rules="[validateFieldError('first_name')]"
                                             :disabled="checkIfFieldIsPreFilled('first_name')"
                                             v-model="form.first_name"
                                             @input="cleanFieldError('first_name')">
                                    <template v-slot:hint
                                            v-if="fieldErrors.first_name">
                                    {{ fieldErrors.first_name[0] }}
                                    </template>
                                </input-field>
                            </template>
                        </input-group>

                        <input-group>
                            <template v-slot:content>
                                <input-field label="Last Name"
                                            placeholder="Type your last name"
                                            ref="last_name-input"
                                            :paddingClasses="isLargeScreen ? 'pl-4' : ''"
                                             :col-span="twoColumnsOrThreeColumnsForm"
                                            :rules="[validateFieldError('last_name')]"
                                            :disabled="checkIfFieldIsPreFilled('last_name')"
                                            v-model="form.last_name"
                                            @input="cleanFieldError('last_name')">
                                    <template v-slot:hint
                                            v-if="fieldErrors.last_name">
                                    <span>{{ fieldErrors.last_name[0] }}</span>
                                    </template>
                                </input-field>
                            </template>
                        </input-group>

                        <input-group>
                            <template v-slot:content>
                                <input-field label="Email Address"
                                             placeholder="youremail@domain.com"
                                             type="email"
                                             ref="email-input"
                                             :paddingClasses="isLargeScreen ? 'pr-4' : ''"
                                             :col-span="twoColumnsOrThreeColumnsForm"
                                             :rules="[validateEmail, validateFieldError('email')]"
                                             :disabled="checkIfFieldIsPreFilled('email')"
                                             v-model="form.email"
                                             @input="cleanFieldError('email')" />
                            </template>
                        </input-group>

                        <input-group v-if="isCompanyRegistration">
                            <template v-slot:content>
                                <phone-number-field label="Phone Number"
                                                    placeholder="222 333 4444"
                                                    ref="phone_number-input"
                                                    :rules="[validatePhoneNumber, validateFieldError('phone_number')]"
                                                    :paddingClasses="isLargeScreen ? 'pl-4' : ''"
                                                    :col-span="twoColumnsOrThreeColumnsForm"
                                                    :disabled="checkIfFieldIsPreFilled('phone_number')"
                                                    :country-code="countryCode"
                                                    v-model="phoneNumberValue"
                                                    @input="cleanFieldError('phone_number')" />
                            </template>
                        </input-group>

                        <input-group v-if="isCompanyRegistration">
                            <template v-slot:content>
                                <select-field label="Country"
                                               placeholder="Ex: United States"
                                               option-label="name"
                                               option-value="id"
                                               ref="country-input"
                                               :rules="[validateFieldError('country')]"
                                               :paddingClasses="isLargeScreen ? 'pl-4' : ''"
                                               :col-span="twoColumnsOrThreeColumnsForm"
                                               :options="countries"
                                               :disabled="checkIfFieldIsPreFilled('country')"
                                               v-model="form.country" />
                            </template>
                        </input-group>

                        <input-group v-if="isCompanyRegistration">
                            <template v-slot:content>
                                <input-field label="Business Name"
                                             placeholder="Ex. Aloware Inc."
                                             ref="company_name-input"
                                             :paddingClasses="isLargeScreen ? 'pr-4' : ''"
                                             :col-span="twoColumnsOrThreeColumnsForm"
                                             :rules="[validateFieldError('company_name')]"
                                             :disabled="checkIfFieldIsPreFilled('company_name')"
                                             v-model="form.company_name"
                                             @input="cleanFieldError('company_name')" />
                            </template>
                        </input-group>

                        <input-group>
                            <template v-slot:content>
                                <password-input :col-span="twoColumnsOrThreeColumnsForm"
                                                v-model="form.password"
                                                @valid="updatePasswordValid($event)" />
                            </template>
                        </input-group>

                        <input-group>
                            <template v-slot:content>
                                <password-confirmation-input :col-span="twoColumnsOrThreeColumnsForm"
                                                             :main-password="form.password"
                                                             v-model="form.password_confirmation"
                                                             @match="updatePasswordsMatch($event)" />
                            </template>
                        </input-group>

                        <div class="accept-box">
                            <div>
                                <div>
                                    <el-checkbox class="mb-3 q-pr-xs"
                                                 color="primary"
                                                 dense
                                                 v-model="form.agreed_to_terms">
                                        <template slot="default">
                                            I agree to
                                            <a class="text-bluish _600"
                                               :href="statics.link_terms_conditions"
                                               target="_blank">
                                                Terms and Conditions
                                            </a>
                                            and
                                            <a class="text-bluish _600"
                                               :href="statics.acceptable_use_policy"
                                               target="_blank">
                                                Acceptable Use Policy.
                                            </a>
                                        </template>
                                    </el-checkbox>
                                </div>

                                <vue-recaptcha ref="recaptcha"
                                               class="g-recaptcha pt-3"
                                               :sitekey="site_key"
                                               v-if="site_key"
                                               @verify="onVerifyRecaptcha"
                                               @expired="onExpiredRecaptcha">
                                </vue-recaptcha>
                            </div>
                        </div>
                    </div>

                    <div class="min-w-90 w-90"
                         v-else>
                        <input-group>
                            <template v-slot:content>
                                <input-field label="First Name"
                                             placeholder="Type your first name"
                                             ref="first_name-input"
                                             :paddingClasses="isLargeScreen ? 'pr-4' : ''"
                                             :col-span="twoColumnsOrThreeColumnsForm"
                                             :rules="[validateFieldError('first_name')]"
                                             :disabled="checkIfFieldIsPreFilled('first_name')"
                                             v-model="form.first_name"
                                             @input="cleanFieldError('first_name')">
                                    <template v-slot:hint
                                            v-if="fieldErrors.first_name">
                                    {{ fieldErrors.first_name[0] }}
                                    </template>
                                </input-field>

                                <input-field label="Last Name"
                                            placeholder="Type your last name"
                                            ref="last_name-input"
                                            :paddingClasses="isLargeScreen ? 'pl-4' : ''"
                                             :col-span="twoColumnsOrThreeColumnsForm"
                                            :rules="[validateFieldError('last_name')]"
                                            :disabled="checkIfFieldIsPreFilled('last_name')"
                                            v-model="form.last_name"
                                            @input="cleanFieldError('last_name')">
                                    <template v-slot:hint
                                            v-if="fieldErrors.last_name">
                                    <span>{{ fieldErrors.last_name[0] }}</span>
                                    </template>
                                </input-field>

                                <input-field label="Email Address"
                                             placeholder="youremail@domain.com"
                                             type="email"
                                             ref="email-input"
                                             :paddingClasses="isLargeScreen ? 'pr-4' : ''"
                                             :col-span="twoColumnsOrThreeColumnsForm"
                                             :rules="[validateEmail, validateFieldError('email')]"
                                             :disabled="checkIfFieldIsPreFilled('email')"
                                             v-model="form.email"
                                             @input="cleanFieldError('email')" />
                            </template>
                        </input-group>

                        <input-group v-if="isCompanyRegistration">
                            <template v-slot:content>
                                <phone-number-field label="Phone Number"
                                                    placeholder="222 333 4444"
                                                    ref="phone_number-input"
                                                    :rules="[validatePhoneNumber, validateFieldError('phone_number')]"
                                                    :paddingClasses="isLargeScreen ? 'pl-4' : ''"
                                                    :col-span="twoColumnsOrThreeColumnsForm"
                                                    :disabled="checkIfFieldIsPreFilled('phone_number')"
                                                    :country-code="countryCode"
                                                    v-model="phoneNumberValue"
                                                    @input="cleanFieldError('phone_number')" />
                                <select-field label="Country"
                                               placeholder="Ex: United States"
                                               option-label="name"
                                               option-value="id"
                                               ref="country-input"
                                               :rules="[validateFieldError('country')]"
                                               :paddingClasses="isLargeScreen ? 'pl-4' : ''"
                                               :col-span="twoColumnsOrThreeColumnsForm"
                                               :options="countries"
                                               :disabled="checkIfFieldIsPreFilled('country')"
                                               v-model="form.country" />
                                <input-field label="Business Name"
                                             placeholder="Ex. Aloware Inc."
                                             ref="company_name-input"
                                             :paddingClasses="isLargeScreen ? 'pr-4' : ''"
                                             :col-span="twoColumnsOrThreeColumnsForm"
                                             :rules="[validateFieldError('company_name')]"
                                             :disabled="checkIfFieldIsPreFilled('company_name')"
                                             v-model="form.company_name"
                                             @input="cleanFieldError('company_name')" />
                            </template>
                        </input-group>

                        <input-group>
                            <template v-slot:content>
                                <password-input :col-span="twoColumnsOrThreeColumnsForm"
                                                v-model="form.password"
                                                @valid="updatePasswordValid($event)" />

                                <password-confirmation-input :col-span="twoColumnsOrThreeColumnsForm"
                                                             :main-password="form.password"
                                                             v-model="form.password_confirmation"
                                                             @match="updatePasswordsMatch($event)" />
                            </template>
                        </input-group>

                        <div class="accept-box">
                            <div>
                                <div>
                                    <el-checkbox class="mb-3 q-pr-xs"
                                                 color="primary"
                                                 dense
                                                 v-model="form.agreed_to_terms">
                                        <template slot="default">
                                            I agree to
                                            <a class="text-bluish _600"
                                               :href="statics.link_terms_conditions"
                                               target="_blank">
                                                Terms and Conditions
                                            </a>
                                            and
                                            <a class="text-bluish _600"
                                               :href="statics.acceptable_use_policy"
                                               target="_blank">
                                                Acceptable Use Policy.
                                            </a>
                                        </template>
                                    </el-checkbox>
                                </div>

                                <vue-recaptcha ref="recaptcha"
                                               class="g-recaptcha pt-3"
                                               :sitekey="site_key"
                                               v-if="site_key"
                                               @verify="onVerifyRecaptcha"
                                               @expired="onExpiredRecaptcha">
                                </vue-recaptcha>
                            </div>
                        </div>

                    </div>
                </div>

                <div class="form-actions mx-lg">
                    <div class="mx-xl-0">
                        <el-button class="btn-block mt-3 signup-submit__button"
                                   color="primary"
                                   size="md"
                                   label="Create my Account"
                                   rounded
                                   dense
                                   no-caps
                                   unelevated
                                   :disabled="isNextButtonDisabled"
                                   @click="onSubmit">
                            <i class="material-icons loader pull-right"
                               v-show="isLoading">
                                &#xE863;
                            </i>
                            Create my Account
                            <i class="el-icon-arrow-right el-icon-right pull-right"
                               show="!isLoading"/>
                        </el-button>
                    </div>
                </div>
            </div>
        </div>
        <banner :company_name="statics.name"
                :current-step="step"
                v-if="statics.name && shouldShowBanner" />
    </div>
  </template>

<script>
import auth from '../auth'
import { mapActions, mapState, mapGetters } from 'vuex'

// Mixins
import { acl_mixin, statics_mixin, phone_masker_mixin, user_mixin } from '../mixins'
import businessTypes from '../constants/account-registration-business-types'
import businessIdTypes from '../constants/account-registration-business-registration-identifiers'
import regionsOfOperations from '../constants/account-registration-business-regions-of-operations'
import businessIndustries from '../constants/account-registration-business-industries'
// Components
import StepHeader from '../components/account-registration/step-header.vue'
import InputGroup from '../components/account-registration/input-group.vue'
import InputField from '../components/account-registration/input-field.vue'
import SelectField from '../components/account-registration/select-field.vue'
import PhoneNumberField from '../components/account-registration/phone-number-field.vue'
import Banner from '../components/account-registration/banner.vue'
import {VueRecaptcha} from "vue-recaptcha"
import PasswordInput from '../components/password-input'
import PasswordConfirmationInput from '../components/password-confirmation-input'

export default {

    components: {
        StepHeader,
        InputGroup,
        InputField,
        SelectField,
        PhoneNumberField,
        Banner,
        VueRecaptcha,
        PasswordInput,
        PasswordConfirmationInput,
    },

    mixins: [
        acl_mixin,
        phone_masker_mixin,
        statics_mixin,
        user_mixin,
    ],

    data () {
        return {
            isCompanyRegistration: true,
            passwordValid: false,
            passwordsMatch: false,
            statics: {
                logo: null,
                logo_inverse: null,
                logo_square: null,
                logo_square_inverse: null,
                host: null,
                referer: null,
                name: null,
                domain: null,
                whitelabel: false,
                path: null,
                link_terms_conditions: null,
                acceptable_use_policy: null,
            },
            loading_whitelabel: true,
            site_key: localStorage.getItem('recaptcha_site_key'),
            step: 0,
            isSubmitted: false,
            isLoading: true,
            businessTypes,
            businessIdTypes,
            regionsOfOperations,
            businessIndustries,
            preFilledData: {},
            shouldRedirectToLogin: false,
            loadingText: 'Please wait while we are creating your account...',
            countryCode: '+1',
            recaptcha_response: null,
            disabledSubmit: localStorage.getItem('recaptcha_site_key') !== '',
            shouldShowBanner: true,
            isMobile: false,
            isTablet: false,
        }
    },

    computed: {
        ...mapState('accountRegistration', [
            'form',
            'fieldErrors'
        ]),

        ...mapGetters('accountRegistration', ['getBusinessInformationFieldsValue']),

        isNextButtonDisabled () {
            return !this.validateFirstStepFieldsFilled() || this.isLoading
        },

        isLargeScreen () {
            return window.innerWidth > 767
        },

        twoColumnsOrThreeColumnsForm () {
            if (this.isMobile) {
                return 24
            }

            if (this.isTablet) {
                return 12
            }

            return this.shouldShowBanner ? 12 : 8
        },

        countries () {
            const countries = window.countriesAndTimezones.getAllCountries()

            // first exclude US & CA, and then extract only id, name fields of the countries
            const countriesArr = Object.values(countries)
            .filter(({ id }) => !['US', 'CA'].includes(id))
            .map(({ id, name }) => {
                return { id, name }
            })

            // add US & CA to the top and return
            return [
                { id: 'US', name: 'United States' },
                { id: 'CA', name: 'Canada' },
                ...countriesArr
            ]
        },

        phoneNumberValue: {
            get () {
                if (this.checkIfFieldIsPreFilled('phone_number')) {
                    return this.countryCode + '//' + this.form.phone_national || this.form.phone_number
                }

                return this.countryCode + '//' + this.form.phone_number
            },

            set (val) {
                this.form.phone_number = val
            }
        }
    },

    methods: {
        ...mapActions('accountRegistration', [
            'setFieldErrors',
            'cleanFieldError',
            'setPreFilledData',
            'setShouldRedirectToLogin'
        ]),

        ...mapActions('auth', ['login']),

        ...mapActions('cache', [
            'setCurrentCompany'
        ]),

        ...mapActions([
            'resetVuex',
            'setUsage'
        ]),

        checkWindowSize() {
            this.isMobile = window.innerWidth <= 767
            this.isTablet = !this.isMobile && window.innerWidth <= 1024
            this.shouldShowBanner = window.innerWidth >= 1440 && window.innerHeight >= 780  && !this.isMobile
        },

        verifyFieldErrors () {
            this.$nextTick(() => {
                Object.keys(this.fieldErrors).forEach(field => {
                    const fieldRef = `${field}-input`

                    if (this.$refs[fieldRef]) {
                        this.$refs[fieldRef].validate()
                    }
                })
            })
        },

        validateFieldError (fieldName) {
            return () => {
                if (this.fieldErrors && this.fieldErrors[fieldName]) {
                    return this.fieldErrors[fieldName][0]
                }
                return true
            }
        },

        moveToStepWithFieldErrors () {
            const stepFieldsReference = {
                first_name: 1,
                last_name: 1,
                email: 1,
                phone_number: 1,
                company_name: 1,
                job_title: 1,
                password: 1,
                password_confirmation: 1,
                legal_name: 2,
                business_type: 2,
                business_registration_identifier: 2,
                business_registration_number: 2,
                business_regions_of_operation: 2,
                business_industry: 2,
                website_url: 2,
                street: 2,
                region: 2,
                city: 2,
                legal_country: 2,
                postal_code: 2,
                auth_rep_first_name: 2,
                auth_rep_last_name: 2,
                auth_rep_email: 2,
                auth_rep_phone_number: 2,
                auth_rep_business_title: 2,
                auth_rep_job_position: 2
            }
            const step = stepFieldsReference[Object.keys(this.fieldErrors)[0]]
            this.$refs.stepper.goTo(step)
        },

        validateEmail (email) {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
            return emailRegex.test(email) || 'Invalid email address'
        },

        validatePhoneNumber (phone) {
            const cleanedPhone = this.getCleanedPhoneNumber(this.preFilledData?.phone_number || phone)
            const formattedPhone = this.$options.filters.fixPhone(cleanedPhone, 'E164', true)

            // if the phone number is empty or invalid
            if (!formattedPhone || formattedPhone === cleanedPhone || formattedPhone === '-') {
                return 'Invalid phone number'
            }
            return true
        },

        getCleanedPhoneNumber (phone) {
            return phone.replace(/[^\d]/g, '')
        },

        validateFirstStepFieldsFilled () {
            const companyValidations = this.isCompanyRegistration
                ? (
                    this.validatePhoneNumber(this.form.phone_number) === true &&
                    this.form.country &&
                    this.form.company_name?.length
                )
                : true

            return (
                this.form.first_name?.length &&
                this.form.last_name?.length &&
                this.validateEmail(this.form.email) === true &&
                companyValidations &&
                this.form.password?.length &&
                this.form.password_confirmation?.length &&
                this.passwordValid &&
                this.passwordsMatch &&
                this.form.agreed_to_terms &&
                !this.disabledSubmit // recaptcha
            )
        },

        validateSecondStepFieldsFilled () {
            return (
                this.form.legal_name?.length &&
                this.form.business_type &&
                this.form.business_registration_identifier &&
                this.form.business_registration_number?.length &&
                this.form.business_regions_of_operation &&
                this.form.business_industry &&
                this.form.website_url?.length &&
                this.form.street?.length &&
                this.form.region?.length &&
                this.form.city?.length &&
                this.form.legal_country &&
                this.validateZipCodeByCountryId(this.form.postal_code, this.form?.legal_country?.id) &&
                this.form.auth_rep_first_name?.length &&
                this.form.auth_rep_last_name?.length &&
                this.validateEmail(this.form.auth_rep_email) === true &&
                this.validatePhoneNumber(this.form.auth_rep_phone_number) === true &&
                this.form.auth_rep_business_title &&
                this.form.auth_rep_job_position
            )
        },

        getPasswordRuleClass (rule) {
            return rule ? 'text-green' : 'text-red'
        },

        setTimezone () {
            const timezone = 'Intl' in window ? new Intl.DateTimeFormat().resolvedOptions().timeZone : ''

            this.form.timezone = timezone
        },

        selectCountryBasedInPreFilledData () {
            const countries = this.countries
            const matchedCountry = Object.values(countries).find(({ id }) => id === this.form.country)

            if (matchedCountry) {
                this.form.country = {
                    id: matchedCountry.id,
                    name: matchedCountry.name
                }
                this.countryCode = this.getCountryCodeByCountryId(matchedCountry.id)
            }
        },

        selectCountryBasedInTimezone () {
            if (this.form.country) {
                return this.selectCountryBasedInPreFilledData()
            }

            const timezone = this.form.timezone
            const countries = this.countries
            const country = Object.values(countries).find(({ timezones }) => timezones?.includes(timezone))

            if (country) {
                this.form.country = {
                    id: country.id,
                    name: country.name
                }
            }
        },

        formatPhoneNumber () {
            if (!this.form?.country?.id) {
                return
            }

            this.form.phone_number = this.$options.filters.fixPhone(this.form.phone_number, 'E164', true, false, this.form.country.id)
        },

        nextStep () {
            this.$refs.stepper.next()
        },

        skipForNow () {
            this.$refs.stepper.next()
        },

        cleanLoadingState () {
            this.isLoading = false
            this.loadingText = ''
        },

        getPreFilledDetails () {
            this.isLoading = true
            this.loadingText = 'Please wait while we are loading your information...'

            const prefillDetailsURL = this.isCompanyRegistration
                ? 'company-registration/pre-signup-prefill'
                : 'user-registration'

            axios.get(`/api/v1/${prefillDetailsURL}/${this.$route.params.verification_token}`)
            .then((res) => {
                if (res.headers['content-type'] !== 'application/json') {
                    return this.$router.push({ name: 'Login' })
                }

                this.setPreFilledData(res.data)
                this.setPreFilledFieldsDisabled()
                if (this.isCompanyRegistration) {
                    this.selectCountryBasedInTimezone()
                    this.formatPhoneNumber()
                }

                // Redirect to login page if PreSignup record has been already processed
                if (this.isCompanyRegistration && res.data?.company_id) {
                    this.setShouldRedirectToLogin(true)
                    this.$router.push({ name: 'Login' })
                }
            })
            .catch((err) => {
                this.$root.handleErrors(err.response)
                this.$router.push({ name: 'Login' })
            })
            .finally(() => {
                this.cleanLoadingState()
            })
        },

        setPreFilledFieldsDisabled () {
            Object.keys(this.form).forEach(field => {
                if (this.form[field]) {
                    this.preFilledData[field] = this.form[field]
                }
            })
        },

        checkIfFieldIsPreFilled (field) {
            return Boolean(this.preFilledData[field])
        },

        openLink (link) {
            window.open(link, '_blank')
        },

        onVerifyRecaptcha(response) {
            this.disabledSubmit = false
            this.recaptcha_response = response
        },

        onExpiredRecaptcha() {
            this.disabledSubmit = true
            this.recaptcha_response = null
        },

        async onLoginSuccess ({ data: { data } }) {
            const { usage, company } = data
            if (this.isCompanyRegistration) {
                await this.verifyCompanyIsReadyToLogin(company.id)
            }

            this.loadingText = 'Almost done...'
            this.resetVuex(['all'])
            this.setCurrentCompany(company)
            this.setUsage(usage)

            localStorage.setItem('company_id', company.id)

            this.loading = false

            const reseller_id = company.reseller_id

            if(!reseller_id || reseller_id !== 357) { // Not Simpsocial accounts go to Talk
                const talk2Url = localStorage.getItem('talk_url')
                window.location.href = `${talk2Url}?from_classic=1`;
            } else {
                const redirectPath = String(this.$route.query.redirect || '/')
                await this.$router.push(redirectPath)
            }
        },

        async verifyCompanyIsReadyToLogin (companyId) {
            let companySetupComplete = false
            this.loadingText = 'Please wait while we are logging you in...'

            while (!companySetupComplete) {
                const companyInfo = await axios.get(`/api/v1/company/${companyId}`)

                if (companyInfo.data?.is_setup) {
                    companySetupComplete = true
                } else {
                    await new Promise(resolve => setTimeout(resolve, 3000))
                }
            }

            return companySetupComplete
        },

        onSubmit () {
            this.isLoading = true
            this.loadingText = 'Please wait while we are sending your information...'

            const phone_number = this.isCompanyRegistration
                ? this.$options.filters.fixPhone(
                    this.getCleanedPhoneNumber(this.preFilledData?.phone_number || this.form.phone_number),
                    'E164',
                    true
                )
                : null

            const auth_rep_phone_number = this.isCompanyRegistration
                ? this.$options.filters.fixPhone(this.getCleanedPhoneNumber(this.form.auth_rep_phone_number), 'E164', true)
                : null

            const businessInformation = this.isCompanyRegistration
                ? this.getBusinessInformationFieldsValue
                : {}

            const payload = {
                ...this.form,
                verification_token: this.$route.params.verification_token,
                phone_number: phone_number,
                auth_rep_phone_number: auth_rep_phone_number,
                ...businessInformation
            }

            const registrationURL = this.isCompanyRegistration
                ? 'company-registration'
                : 'user-registration'

            axios.post(`/api/v1/${registrationURL}`, payload)
            .then(async (res) => {
                this.isSubmitted = true
            })
            .catch((err) => {
                if (err.response && err.response.data && err.response.data.errors) {
                    this.setFieldErrors(err.response.data.errors)
                    this.verifyFieldErrors()
                }
                this.$root.handleErrors(err.response)
            })
            .finally(async () => {
                // Automaically login the recently created customer
                if (this.isSubmitted) {
                    this.loadingText = 'Your account is being created...'

                    // Login the recently created user
                    const response = await auth.login(this.form.email, this.form.password, false, this.recaptcha_response, 'bypass')
                    await this.onLoginSuccess(response)
                }

                this.cleanLoadingState()
            })
        },

        updatePasswordValid ($event) {
            this.passwordValid = $event
        },

        updatePasswordsMatch($event) {
            this.passwordsMatch = $event
        },
    },

    created () {
        this.isCompanyRegistration = this.$route.meta.isCompanyRegistration

        this.setTimezone()
        this.selectCountryBasedInTimezone()
        this.getStatics()
        this.checkWindowSize()

        window.addEventListener('resize', this.checkWindowSize)
    },

    async mounted () {
        this.verifyFieldErrors()
        await this.getPreFilledDetails()
    },

    beforeDestroy () {
        window.removeEventListener('resize', this.checkWindowSize)
    }
}
</script>
