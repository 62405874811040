var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("p", [
      _vm._v(
        _vm._s(
          _vm.direction === _vm.CommunicationDirections.INBOUND
            ? "Received"
            : "Sent"
        ) +
          " too many messages " +
          _vm._s(
            _vm.direction === _vm.CommunicationDirections.INBOUND
              ? "from"
              : "to"
          ) +
          " a contact."
      ),
    ]),
    _vm._v(" "),
    _c("p", [_vm._v("Name: " + _vm._s(_vm.contact.name))]),
    _vm._v(" "),
    _vm.campaignName
      ? _c("p", [_vm._v("Line: " + _vm._s(_vm.campaignName))])
      : _vm._e(),
    _vm._v(" "),
    _c("p", [
      _vm._v(
        "Incoming Number: " +
          _vm._s(_vm._f("fixPhone")(_vm.incomingNumber.phone_number))
      ),
    ]),
    _vm._v(" "),
    _vm._m(0),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c(
        "button",
        {
          staticClass:
            "btn rounded greenish btn-contact btn-sm mt-2 pull-right",
        },
        [_vm._v("\n            Go To Contact\n        ")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }