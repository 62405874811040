<template>
    <div>
        <p class="font-weight-bold side-panel-title mb-0">Fallback</p>
        <el-button
            v-if="!hasFallbacks"
            @click.prevent="addFallback">
            Add fallback
        </el-button>
        
        <div v-for="(fallback, id) in fallbacks_"
             v-bind:key="id"
             class="pt-3">
            <FallbackTexts
                :fallback.sync="fallback"
                @change="updateFallback"/>
        </div>
    </div>
</template>

<script>
import FallbackTexts from './panel-fallback-text-component.vue'

import _ from 'lodash'

export default {
    components: {
        FallbackTexts
    },
    props: {
        fallbacks: {
            required: true
        },
        dateTime: {
            required: false,
            type: Boolean,
            default: false
        }
    },
    created () {
        if (!this.fallbacks_) {
            this.fallbacks_ = []
        }
    },
    computed: {
        fallbacks_: {
            get () {
                return JSON.parse(JSON.stringify(this.fallbacks || []))
            },
            set (value) {
                this.$emit('change', value)
            }   
        },
        hasFallbacks () {
            return this.fallbacks_.length > 0
        },
        selectedFallbacks: {
            // Only get all the values already in the fallback
            get () {
                return this.fallbacks_.map(fallback => fallback.type)
            },
            // Sync values at the fallback
            set (value) {
                let currentFallbacks = JSON.parse(JSON.stringify(this.fallbacks_))
                let currentTypes = currentFallbacks.map(fallback => fallback.type)
                let removed = _.difference(currentTypes, value)
                let added = _.difference(value, currentTypes)

                for (let fallback of removed) {
                    let result = currentFallbacks.splice(currentTypes.indexOf(fallback), 1)
                    currentTypes = currentFallbacks.map(fallback => fallback.type)
                }

                for (let fallback of added) {
                    currentFallbacks.push({
                        type: fallback,
                        messages: []
                    })
                }

                this.fallbacks_ = currentFallbacks
            }
        }
    },
    methods: {
        /**
         * Adds fallback to stack if not exists.
         *
         * @return {void}
         */
        addFallback () {
            if (this.dateTime) {
                this.fallbacks_ = [
                    {type: 'date', messages: []},
                    {type: 'time', messages: []}
                ]
            } else {
                this.fallbacks_ = [
                    {type: 'state', messages: []}
                ]
            }
        },

        /**
         * Updates fallback to stack.
         *
         * @return {void}
         */
        updateFallback (fallback) {
            let currentFallbacks = JSON.parse(JSON.stringify(this.fallbacks_))
            currentFallbacks = currentFallbacks.map(curFallback => fallback.type == curFallback.type ? fallback : curFallback)
            this.fallbacks_ = currentFallbacks
        },

        /**
         * Handles saving the panel.
         *
         * @param {object} payload
         *
         * @return {void}
         */
        handleSaveState () {
            // Sanity check
            if (!this.fallbacks) {
                return
            }

            let ocupied = this.fallbacks.reduce((prev, curr) => {
                if (curr.messages.length == 0) {
                    return prev
                }
                if (curr.messages.length == 1) {
                    return prev || curr.messages[0] != ''
                }

                return true
            }, false)

            if (!ocupied) {
                this.fallbacks_ = []
                return
            }

            this.fallbacks_ = this.fallbacks
        }
    }
}
</script>