<template>
    <div class="app-body">
        <div class="text-center pos-rlt p-y-md"
             style="overflow: hidden;">
            <div class="row">
                <div class="col-12">
                    <!-- brand -->
                    <a class="mx-auto d-block">
                        <img :src="statics.logo"
                             height="70px"
                             alt="logo"
                             v-if="!loading_whitelabel">
                    </a>
                    <!-- / brand -->
                </div>
            </div>
            <div class="row mt-4">
                <div class="col-md-4"></div>
                <div class="col-md-4">
                    <p class="h5 mt-5 font-bold text-left"
                       style="line-height: 30px;">Unfortunately, your subscription has been suspended due to a problem with your payment.
                    </p>
                    <p class="h5 mb-5 mt-4 font-bold text-left"
                       style="line-height: 30px;">
                        Please update your payment information on your invoice, or contact us. Thank you!
                    </p>

                    <template v-if="auth.user.profile && (!auth.user.profile.created_by_reseller || (auth.user.profile.created_by_reseller && auth.user.profile.independent_billing))">
                        <el-popover v-if="!enabledToSkipTrialAndSubscribe()"
                                    ref="trial-skip-popover"
                                    placement="top"
                                    width="200"
                                    class="custom-popover"
                                    popper-class="btn-primary"
                                    trigger="hover">
                            <custom-message-display :config="customMessage('billing.subscribe')" />
                        </el-popover>
                        <button class="btn btn-sm bluish pr-2 pl-2"
                                @click="chargebeePaymentSources"
                                :disabled="isChargeBeeSessionOrSubscriptionDisabled"
                                v-popover:trial-skip-popover
                                v-if="hasPermissionTo('see chargebee portal')">
                            <i class="material-icons">credit_card_outline</i>
                            <span v-if="!cardAdded">Add Payment Method</span>
                            <span v-else>Manage Payment Methods</span>
                        </button>

                        <button class="btn btn-sm white pr-2 pl-2 ml-2"
                                @click="billingHistory"
                                :disabled="!hasChargeBeeSession"
                                v-if="hasPermissionTo('see chargebee portal')">
                            <i class="material-icons">history</i>
                            Payment History
                        </button>
                    </template>

                    <div class="mt-3">
                        <a href="mailto:support@aloware.com?subject=Suspended Account - Failed Payment"
                           class="btn greenish p-x-md"
                           v-if="current_company && current_company.reseller_id != 357">
                            Send an Email
                        </a>
                        <a href="mailto:support@simpsocial.com?subject=Suspended Account - Failed Payment"
                           class="btn greenish p-x-md"
                           v-if="current_company && current_company.reseller_id == 357">
                            Send an Email
                        </a>
                        <a href="tel:+18552562001"
                           class="btn new-blue p-x-md ml-2"
                           v-if="current_company && current_company.reseller_id != 357">
                            Call us
                        </a>
                        <a href="tel:+18888291110"
                           class="btn new-blue p-x-md ml-2"
                           v-if="current_company && current_company.reseller_id == 357">
                            Call us
                        </a>
                    </div>
                </div>
                <div class="col-md-4"></div>
            </div>
        </div>
    </div>
</template>

<script>
import auth from '../../auth'
import {mapState} from 'vuex'
import {chargebee_mixin, kyc_mixin, statics_mixin} from '../../mixins'
import CustomMessageDisplay from '../../components/kyc/custom-message-display'

export default {
    components: {
        CustomMessageDisplay
    },

    mixins: [
        chargebee_mixin,
        statics_mixin,
        kyc_mixin
    ],

    data() {
        return {
            auth: auth,
            statics: {
                logo: null,
                logo_inverse: null,
                logo_square: null,
                logo_square_inverse: null,
                host: null,
                referer: null,
                name: null,
                domain: null,
                whitelabel: false,
                path: null
            },
            loading_whitelabel: true
        }
    },

    computed: {
        ...mapState('cache', ['current_company']),
    },

    created() {
        this.getStatics()
        this.prepareChargeBee()
    },

    beforeRouteEnter(to, from, next) {
        auth.check()
            .then((res) => {
                if (auth.user.profile.company_subscription_status !== 'cancelled' || auth.user.company.is_trial) {
                    next({name: 'Dashboard'})
                } else {
                    next()
                }
            })
            .catch((err) => {
                next({name: 'Login', query: {redirect: to.fullPath}})
            })
    },

    beforeRouteLeave(to, from, next) {
        auth.check().then((res) => {
            if (auth.user.profile.company_subscription_status !== 'cancelled' || auth.user.company.is_trial) {
                next()
            } else {
                next(false)
            }
        })
    }
}
</script>
