var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "app-body", attrs: { id: "view" } },
    [
      _c("image-assets-preloader"),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.show_help,
            top: "10vh",
            width: "600px",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.show_help = $event
            },
          },
        },
        [
          _c("div", { attrs: { slot: "title" }, slot: "title" }, [
            _c("h5", { staticClass: "mb-0" }, [
              _vm._v("\n                The Dashboard\n            "),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "article",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.loading_whitelabel,
                  expression: "loading_whitelabel",
                },
              ],
            },
            [
              _c("p", {}, [
                _vm._v(
                  "\n                The dashboard is a central place to view your agent's activity, historical\n                call records and a list of all calls and messages that you have received, sent or made.\n            "
                ),
              ]),
              _vm._v(" "),
              _c("p", {}, [
                _vm._v(
                  "\n                You can track the progress of a call or historical calling and messaging\n                records right on the dashboard.\n            "
                ),
              ]),
              _vm._v(" "),
              !_vm.statics.whitelabel
                ? _c("p", [
                    _vm._v(
                      "\n                To learn more about the summary report, open our knowledge base.\n            "
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              !_vm.statics.whitelabel
                ? _c(
                    "a",
                    {
                      staticClass: "btn btn-success",
                      attrs: {
                        href: "https://support.aloware.com/en/articles/9033811-dashboard-communication-activities",
                        target: "_blank",
                      },
                    },
                    [
                      _vm._v(
                        "\n                Go to Knowledge Base\n            "
                      ),
                    ]
                  )
                : _vm._e(),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _c("welcome-to-aloware"),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "fixed-header padding pt-0 pb-0",
          style: { top: _vm.total_offset + 50 + "px" },
        },
        [
          _c("div", { staticClass: "row mb-0 mt-0" }, [
            _c("div", { staticClass: "col-12 p-2" }, [
              _c("div", { staticClass: "d-flex" }, [
                _c(
                  "div",
                  { staticClass: "d-flex flex-fill" },
                  [
                    _c("comm-advanced-filters-wrapper", {
                      attrs: {
                        tag_id: _vm.tag_id,
                        call_disposition_id: _vm.call_disposition_id,
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "ml-auto" }, [
                  false
                    ? _c(
                        "button",
                        {
                          staticClass: "btn btn-md ml-2 text-bluish",
                          staticStyle: { height: "40px" },
                          on: { click: _vm.toggleHelp },
                        },
                        [
                          _c("i", { staticClass: "material-icons text-lg" }, [
                            _vm._v("help"),
                          ]),
                          _vm._v(
                            "\n                            Help\n                        "
                          ),
                        ]
                      )
                    : _vm._e(),
                ]),
              ]),
            ]),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.section === "Activities",
              expression: "section === 'Activities'",
            },
          ],
        },
        [
          _vm.hasPermissionTo("list report")
            ? _c("div", { staticClass: "padding mt-5 pt-3" }, [
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col-12 p-0" },
                    [_c("new-summary-report")],
                    1
                  ),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.hasPermissionTo("list report") && _vm.show_graph
            ? _c("div", { staticClass: "padding" }, [
                _c("div", { staticClass: "row box" }, [
                  _c("div", { staticClass: "col-12" }, [
                    _c("div", { staticClass: "no-effect" }, [
                      _c(
                        "div",
                        { staticClass: "box-body" },
                        [
                          _c("activity-graph", {
                            attrs: {
                              base: "campaign",
                              is_first_load: _vm.reports_first_load,
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.hasPermissionTo("list communication")
            ? _c("div", { staticClass: "padding" }, [
                _c("div", { staticClass: "row box" }, [
                  _c("div", { staticClass: "col-12" }, [
                    _c("div", { staticClass: "no-effect" }, [
                      _c(
                        "div",
                        { staticClass: "box-body" },
                        [_c("comm-log-table")],
                        1
                      ),
                    ]),
                  ]),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("v-tour", {
            attrs: {
              name: "onboardingTour",
              options: _vm.tourOptions,
              steps: _vm.tourSteps,
              callbacks: _vm.tourCallbacks,
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (tour) {
                  return [
                    _c(
                      "transition",
                      { attrs: { name: "el-fade-in-linear" } },
                      _vm._l(tour.steps, function (step, index) {
                        return tour.currentStep === index
                          ? _c(
                              "v-step",
                              {
                                key: index,
                                attrs: {
                                  step: step,
                                  "previous-step": tour.previousStep,
                                  "next-step": tour.nextStep,
                                  stop: tour.stop,
                                  "is-first": tour.isFirst,
                                  "is-last": tour.isLast,
                                  labels: tour.labels,
                                  highlight: tour.highlight,
                                },
                              },
                              [
                                tour.isFirst
                                  ? [
                                      _c(
                                        "div",
                                        {
                                          attrs: { slot: "actions" },
                                          slot: "actions",
                                        },
                                        [
                                          _c(
                                            "button",
                                            {
                                              staticClass:
                                                "btn btn-sm btn-warning mr-2",
                                              on: { click: tour.stop },
                                            },
                                            [
                                              _vm._v(
                                                "\n                                    Skip\n                                "
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "button",
                                            {
                                              staticClass:
                                                "btn btn-sm btn-success",
                                              on: { click: tour.nextStep },
                                            },
                                            [
                                              _vm._v(
                                                "\n                                    Next\n                                "
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  : tour.isLast
                                  ? [
                                      _c(
                                        "div",
                                        {
                                          attrs: { slot: "actions" },
                                          slot: "actions",
                                        },
                                        [
                                          _c(
                                            "button",
                                            {
                                              staticClass:
                                                "btn btn-sm btn-primary mr-2",
                                              on: { click: tour.previousStep },
                                            },
                                            [
                                              _vm._v(
                                                "\n                                    Previous\n                                "
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "button",
                                            {
                                              staticClass:
                                                "btn btn-sm btn-success",
                                              on: { click: tour.stop },
                                            },
                                            [
                                              _vm._v(
                                                "\n                                    Finish\n                                "
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  : [
                                      _c(
                                        "div",
                                        {
                                          attrs: { slot: "actions" },
                                          slot: "actions",
                                        },
                                        [
                                          _c(
                                            "button",
                                            {
                                              staticClass:
                                                "btn btn-sm btn-warning mr-2",
                                              on: { click: tour.stop },
                                            },
                                            [
                                              _vm._v(
                                                "\n                                    Skip\n                                "
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "button",
                                            {
                                              staticClass:
                                                "btn btn-sm btn-primary mr-2",
                                              on: { click: tour.previousStep },
                                            },
                                            [
                                              _vm._v(
                                                "\n                                    Previous\n                                "
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "button",
                                            {
                                              staticClass:
                                                "btn btn-sm btn-success",
                                              on: { click: tour.nextStep },
                                            },
                                            [
                                              _vm._v(
                                                "\n                                    Next\n                                "
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ],
                              ],
                              2
                            )
                          : _vm._e()
                      }),
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _vm.section === "Transcriptions" && _vm.shouldDisplayTranscriptionSections
        ? _c("div", [
            _c(
              "div",
              { staticClass: "padding mt-5 pt-3 mb-0 pb-0" },
              [_c("transcription-reporting-panel")],
              1
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("el-backtop", {
        ref: "backtop",
        attrs: { target: "html", bottom: 20, right: 20 },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }