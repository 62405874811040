var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "span",
    [
      _c(
        "el-popover",
        {
          attrs: {
            placement: _vm.position,
            width: _vm.width,
            trigger: _vm.trigger,
          },
          on: { show: _vm.nameEditorOnShow },
          model: {
            value: _vm.is_open,
            callback: function ($$v) {
              _vm.is_open = $$v
            },
            expression: "is_open",
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "edit_contact_info_form",
              staticClass: "edit-contact",
              attrs: {
                "label-width": "auto",
                "label-position": "top",
                model: _vm.selected_contact,
                rules: _vm.rules,
              },
              nativeOn: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.preventSubmit.apply(null, arguments)
                },
              },
            },
            [
              _c(
                "div",
                { staticStyle: { "text-align": "right", margin: "0" } },
                [
                  _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      { staticClass: "col-12 mb-2" },
                      [
                        _c(
                          "el-form-item",
                          {
                            staticClass: "mb-0 first-name",
                            attrs: { prop: "first_name" },
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "md-form-group pb-0" },
                              [
                                _c("label", [_vm._v("First Name")]),
                                _vm._v(" "),
                                _c("el-input", {
                                  ref: "first_name",
                                  staticClass: "md-input",
                                  attrs: {
                                    "data-testid":
                                      "edit-contact-first-name-input",
                                  },
                                  on: {
                                    input: function ($event) {
                                      return _vm.preValidateForm(
                                        "edit_contact_info_form"
                                      )
                                    },
                                  },
                                  model: {
                                    value: _vm.selected_contact.first_name,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.selected_contact,
                                        "first_name",
                                        $$v
                                      )
                                    },
                                    expression: "selected_contact.first_name",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-12" },
                      [
                        _c(
                          "el-form-item",
                          {
                            staticClass: "mb-0 last-name",
                            attrs: { prop: "last_name" },
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "md-form-group pb-0" },
                              [
                                _c("label", [_vm._v("Last Name")]),
                                _vm._v(" "),
                                _c("el-input", {
                                  ref: "last_name",
                                  staticClass: "md-input",
                                  attrs: {
                                    "data-testid":
                                      "edit-contact-last-name-input",
                                  },
                                  on: {
                                    input: function ($event) {
                                      return _vm.preValidateForm(
                                        "edit_contact_info_form"
                                      )
                                    },
                                  },
                                  model: {
                                    value: _vm.selected_contact.last_name,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.selected_contact,
                                        "last_name",
                                        $$v
                                      )
                                    },
                                    expression: "selected_contact.last_name",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-12 mt-4" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-xs _400 greenish pull-right",
                          attrs: {
                            disabled: _vm.validated === false,
                            "data-testid": "edit-contact-save-button",
                          },
                          on: { click: _vm.quickUpdateContact },
                        },
                        [
                          _c(
                            "i",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.contact_saving,
                                  expression: "contact_saving",
                                },
                              ],
                              staticClass: "material-icons loader",
                            },
                            [_vm._v("")]
                          ),
                          _vm._v(" "),
                          _c("span", [_vm._v("Save")]),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass:
                            "btn btn-xs _400 btn-secondary pull-right mr-2",
                          attrs: {
                            "data-testid": "edit-contact-cancel-button",
                            disabled: _vm.contact_saving,
                          },
                          on: { click: _vm.hideForm },
                        },
                        [
                          _vm._v(
                            "\n                            Cancel\n                        "
                          ),
                        ]
                      ),
                    ]),
                  ]),
                ]
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { attrs: { slot: "reference" }, slot: "reference" },
            [_vm._t("ref_trigger")],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }