var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.hasPermissionTo("create contact") && _vm.contact
    ? _c(
        "div",
        [
          _c(
            "el-dialog",
            {
              staticClass: "dialog-padding auto",
              attrs: {
                title: "Create a new phone number",
                width: _vm.isLargeEnough ? "400px" : "100%",
                visible: _vm.hide_add,
                "before-close": _vm.beforeCloseModal,
                "data-testid": "add-contact-phone-number-dialog",
                "append-to-body": "",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.hide_add = $event
                },
              },
            },
            [
              _c(
                "el-form",
                {
                  ref: "add_phone_number",
                  staticClass: "p-0",
                  attrs: {
                    "label-position": "top",
                    rules: _vm.rules_phone_number,
                    model: _vm.phone_number,
                    "data-testid": "add-contact-phone-number-form",
                  },
                  nativeOn: {
                    submit: function ($event) {
                      $event.preventDefault()
                      return _vm.addPhoneNumber.apply(null, arguments)
                    },
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { prop: "title", label: "Title" } },
                    [
                      _c(
                        "div",
                        { staticClass: "width-400" },
                        [
                          _c("el-input", {
                            attrs: { "data-testid": "add-phone-title-input" },
                            on: {
                              input: function ($event) {
                                return _vm.preValidateForm("add_phone_number")
                              },
                            },
                            model: {
                              value: _vm.phone_number.title,
                              callback: function ($$v) {
                                _vm.$set(_vm.phone_number, "title", $$v)
                              },
                              expression: "phone_number.title",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "phone_number", label: "Phone Number" } },
                    [
                      _c(
                        "div",
                        { staticClass: "width-400" },
                        [
                          _c("el-input", {
                            attrs: { "data-testid": "add-phone-number-input" },
                            on: {
                              input: function ($event) {
                                return _vm.preValidateForm("add_phone_number")
                              },
                            },
                            model: {
                              value: _vm.phone_number.phone_number,
                              callback: function ($$v) {
                                _vm.$set(_vm.phone_number, "phone_number", $$v)
                              },
                              expression: "phone_number.phone_number",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "row pb-3" }, [
                _c(
                  "div",
                  { staticClass: "col-12" },
                  [
                    _c(
                      "el-button",
                      {
                        staticClass: "pull-right ml-2",
                        attrs: {
                          type: "success",
                          disabled: _vm.loading_btn,
                          "data-testid": "add-phone-number-button",
                        },
                        on: { click: _vm.addPhoneNumber },
                      },
                      [
                        _c(
                          "i",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.loading_btn,
                                expression: "loading_btn",
                              },
                            ],
                            staticClass: "material-icons loader",
                          },
                          [_vm._v("")]
                        ),
                        _vm._v(" "),
                        _c("i", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: !_vm.loading_btn,
                              expression: "!loading_btn",
                            },
                          ],
                          staticClass: "fa fa-plus",
                        }),
                        _vm._v(" "),
                        _c("span", [_vm._v("Add")]),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        staticClass: "pull-right",
                        attrs: {
                          type: "info",
                          "data-testid": "add-phone-close-dialog-button",
                        },
                        on: { click: _vm.closeDialog },
                      },
                      [
                        _c("span", { staticClass: "fa fa-close" }),
                        _vm._v(" "),
                        _c("span", [_vm._v("Cancel")]),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _vm._t("trigger", function () {
            return [
              _c(
                "el-button",
                {
                  attrs: {
                    slot: "reference",
                    type: "text text-sm text-dark-greenish p-0",
                    "data-testid": "add-phone-number-slot-button",
                  },
                  on: {
                    click: function ($event) {
                      _vm.hide_add = true
                    },
                  },
                  slot: "reference",
                },
                [
                  _c("i", { staticClass: "el-icon-circle-plus-outline" }),
                  _vm._v("\n            Add a phone number\n        "),
                ]
              ),
            ]
          }),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }