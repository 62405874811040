<template>
    <div class="messenger-wrapper container-fluid px-3 py-2 mb-1"
         v-if="message_object && message_object.contact"
         v-loading="loading">
        <el-dialog title="Schedule Message"
                   width="300px"
                   class="schedule-dialog"
                   data-testid="message-composer-dialog"
                   :visible="show_schedule_dialog"
                   :before-close="closeSchedulePicker"
                   :close-on-click-modal="false"
                   append-to-body>
            <div class="d-flex flex-column">
                <v-date-picker v-model="schedule_date"
                               :min-date='new Date()'
                               mode="dateTime"
                               data-testid="message-composer-schedule-date-picker"
                               color="green">
                </v-date-picker>
            </div>
            <template v-slot:footer>
                <div class="d-flex flex-row justify-content-between mt-2 mb-1">
                    <el-button size="small"
                               data-testid="message-composer-reset-buton"
                               @click="resetScheduleDateTime">
                        Reset
                    </el-button>
                    <el-button size="small"
                               type="success"
                               :disabled="!is_schedule_deliverable"
                               data-testid="message-composer-schedule-button"
                               @click="setScheduleDateTime">
                        <i class="material-icons mr-2">schedule</i> <span>Schedule Send</span>
                    </el-button>
                </div>
                <div
                    v-if="!is_schedule_deliverable"
                    class="text-right text-danger"
                    style="font-size: 12px">
                    <i class="fa fa-exclamation-triangle text-danger"></i> Schedule must be in the future
                </div>
            </template>
        </el-dialog>
        <div class="messenger-info d-flex flex-wrap wrap-auto-margin mb-2"
             v-if="showDetails">
            <span v-if="name">
                <strong>Contact:</strong>
                <a href="#"
                   title="Add to message"
                   class="_600 text-dark-greenish"
                   data-testid="message-composer-contact-link"
                   @click.prevent="appendToMessageBody(name)">
                    {{ name }}
                </a>
            </span>
            <span v-if="auth.user && auth.user.profile">
                <strong>User:</strong>
                <a href="#"
                   title="Add to message"
                   class="_600 text-dark-greenish"
                   @click.prevent="appendToMessageBody(auth.user.profile.name)">
                    {{ auth.user.profile.name }}
                </a>
            </span>
            <span v-if="selectedCampaign">
                <strong>Line:</strong>
                <a href="#"
                   title="Add to message"
                   class="_600 text-dark-greenish"
                   data-testid="message-composer-line-link"
                   @click.prevent="appendToMessageBody(selectedCampaign.name)">
                    {{ selectedCampaign.name }}
                </a>
            </span>
            <span v-if="contact_incoming_number && contact_incoming_number.phone_number">
                <strong>Line Number:</strong>
                <a href="#"
                   title="Add to message"
                   class="_600 text-dark-greenish"
                   data-testid="message-composer-line-number-link"
                   @click.prevent="appendToMessageBody(contact_incoming_number.phone_number)">
                    {{ contact_incoming_number.phone_number | fixPhone }}
                </a>
            </span>
            <span>
                <strong>Phone Number:</strong>
                <a href="#"
                   title="Add to message"
                   class="_600 text-dark-greenish"
                   data-testid="message-composer-phone-number-link"
                   @click.prevent="appendToMessageBody(message_object.phone_number)">
                    {{ message_object.phone_number | fixPhone }}
                </a>
            </span>
            <span class="ml-auto"
                  :class="[reachedMessageLimit || highlightMessageLimitInfo ? 'text-red font-weight-bold': '']">
                <span class="font-weight-normal mr-2"
                      v-if="reachedMessageLimit || highlightMessageLimitInfo">
                    The message body has reached the allowed character limit.
                </span>
                {{message_object.body ? smartEncodedMessageLength : 0}} / {{message_body_limit}}
            </span>
        </div>
        <div style="border:solid 1px #ddd; border-radius: 5px">
            <div class="messenger-input d-flex flex-row mb-2">
            <textarea id="message-input"
                      ref="message_composer_text"
                      name="message"
                      class="message-input w-100 px-3 py-2"
                      :class="disabled ? 'opacity-5' : ''"
                      placeholder="Write a message"
                      :maxlength="message_body_limit"
                      v-model="message_object.body"
                      :disabled="disabled || loading"
                      data-testid="message-composer-message-textarea"
                      @keydown="handleEnterPress"
                      @change="reHeightMessageInput"
                      @focus="highlightBottomBar"
                      @blur="onBlurMessageInput"
                      @keyup="reHeightMessageInput"
                      @input="imposeMessageCharLimit($event)"
                      v-loading="generatingUrls" />
            </div>
            <div class="attachment-previews d-flex flex-row px-3 py-2"
                 style="overflow-x: auto"
                 v-if="message_object.attachments.length > 0 || message_object.gif">
                <div class="attachment-preview"
                     style="height: 5rem;width: 5rem;"
                     v-if="message_object.gif">
                    <img :src="message_object.gif" 
                         data-testid="message-composer-gif">
                    <el-button class="attachment-preview-close"
                               size="mini"
                               type="danger"
                               circle
                               data-testid="message-composer-remove-gif"
                               @click="removeGif">
                        <i class="fa fa-times"></i>
                    </el-button>
                </div>
                <template v-for="attachment of message_object.attachments">
                    <div class="attachment-preview"
                         style="height: 5rem;width: 5rem;"
                         v-if="attachment.mimetype.includes('image')">
                        <img :src="getImagePreviewLink(attachment.uuid)" 
                             data-testid="message-composer-img">
                        <el-button class="attachment-preview-close"
                                   size="mini"
                                   type="danger"
                                   circle
                                   data-testid="message-composer-remove-attachment"
                                   @click="removeAttachment(attachment)">
                            <i class="fa fa-times"></i>
                        </el-button>
                    </div>
                    <div class="attachment-preview d-flex flex-row align-items-center"
                         style="height: 5rem;padding:1rem;font-size: 1.2rem"
                         v-else>
                        <div style="height: max-content">
                            <i class="fa fa-file-o mr-2"
                               style="font-size: 1.5rem;">
                            </i>
                        </div>
                        <div style="height: max-content">
                            <span>
                                {{ attachment.original_file }}
                            </span>
                        </div>
                        <el-button class="attachment-preview-close"
                                   size="mini"
                                   type="danger"
                                   circle
                                   data-testid="message-composer-remove-attachment-1"
                                   @click="removeAttachment(attachment)">
                            <i class="fa fa-times"></i>
                        </el-button>
                    </div>
                </template>
            </div>
            <div class="message-actions d-flex flex-wrap justify-content-end justify-sm-content-center align-items-center py-1">
                <span class="action-item px-2"
                      v-if="contact_incoming_number && contact_incoming_number.is_mms_capable">
                    <el-tooltip content="Attach file"
                                placement="top">
                        <attachments :campaign_id="selected_campaign_id"
                                     :disabled="disabled"
                                     data-testid="meesage-composer-attachments"
                                     @file-uploaded="fileUploaded">
                        </attachments>
                    </el-tooltip>
                </span>
                <span class="action-item px-2"
                      v-if="contact_incoming_number && contact_incoming_number.is_mms_capable">
                    <el-tooltip content="Add gif"
                                data-testid="message-composer-add-gif-tooltip"
                                placement="top">
                         <gifs :disabled="disabled"
                               data-testid="message-composer-gifs"
                               @gif-selected="gifSelected">
                         </gifs>
                    </el-tooltip>
                </span>
                <span class="action-item px-2">
                    <el-tooltip content="Add template"
                                data-testid="message-composer-add-template-tooltip"
                                placement="top">
                        <templates :disabled="disabled"
                                   @template-selected="templateSelected">
                        </templates>
                    </el-tooltip>
                </span>
                <span class="action-item px-2">
                    <el-tooltip content="Add variable"
                                data-testid="message-composer-add-variable-tooltip"
                                placement="top">
                        <variables @variable-selected="variableSelected"
                                   :disabled="disabled">
                        </variables>
                    </el-tooltip>
                </span>
                <span class="action-item px-2"
                      v-if="current_company && current_company.simpsocial_integration_enabled">
                    <el-tooltip content="Inventory"
                                data-testid="message-composer-inventory-tooltip"
                                placement="top">
                        <el-button id="inventory-popover-trigger"
                                   class="border-0 bg-transparent"
                                   size="mini"
                                   :disabled="disabled"
                                   circle
                                   data-testid="message-composer-inventory-button"
                                   @click="openCarForm">
                            <i class="fa fa-car"></i>
                        </el-button>
                    </el-tooltip>
                </span>
                <span class="action-item px-2"
                      v-if="current_company && current_company.simpsocial_integration_enabled">
                    <el-tooltip content="Credit Application"
                                data-testid="message-composer-credit-application-tooltip"
                                placement="top">
                        <el-button id="credit-application-popover-trigger"
                                   class="border-0 bg-transparent"
                                   size="mini"
                                   :disabled="disabled"
                                   circle
                                   data-testid="message-composer-credit-application-button"
                                   @click="sendCreditApplicationLink">
                            <i class="fa fa-edit"></i>
                        </el-button>
                    </el-tooltip>
                </span>

                <div class="ml-md-auto no-select pr-2 pr-xl-0">
                    <strong class="mr-2">Line:</strong>
                    <el-select v-model="selected_campaign_id"
                               size="small"
                               placeholder="Select a line"
                               style="width: 200px"
                               class="no-select"
                               :disabled="loading || disabled"
                               filterable
                               data-testid="message-composer-select-a-line-select"
                               @change="changeSelectedCampaign">
                        <el-option-group key="Contact Lines"
                                         label="Contact Lines"
                                         v-if="contactCampaigns.length !== 0">
                            <el-option
                                v-for="(campaign, index) in contactCampaigns"
                                :key="campaign.id + '-comm-' + index"
                                :label="campaign.name"
                                :value="campaign.id">
                                <el-tooltip
                                    :disabled="!isMessagingBlocked(campaign, true)"
                                    content="To send messages to the US, A2P 10DLC Brand and Campaign are required."
                                    placement="left"
                                >
                                    <span class="pull-left" :class="{'not-registered': isMessagingBlocked(campaign, true)}">{{ campaign.name }}</span>
                                </el-tooltip>
                            </el-option>
                        </el-option-group>

                        <el-option-group key="Other Lines"
                                         label="Other Lines"
                                         v-if="otherContactCampaigns.length !== 0">
                            <el-option
                                v-for="(campaign, index) in otherContactCampaigns"
                                :key="campaign.id + '-comm-' + index"
                                :label="campaign.name"
                                :value="campaign.id">
                                <span class="pull-left" :class="{'not-registered': isMessagingBlocked(campaign)}">{{ campaign.name }}</span>
                            </el-option>
                        </el-option-group>
                    </el-select>
                </div>
                <template v-if="sendEnabled && current_company.sms_enabled && canSendNonTCPAContacts && enabledToTextNumber(activePhoneNumber)">
                    <el-dropdown class="schedule-message-action-buttons ml-xl-2 mr-2"
                                 type="success"
                                 size="small"
                                 split-button
                                 trigger="click"
                                 data-testid="message-composer-send-dropdown"
                                 @command="showScheduleDialog"
                                 @click="send">
                        <template v-if="mode === 'schedule'">
                            Save
                        </template>
                        <template v-else>
                            Send
                        </template>
                        <template v-slot:dropdown>
                            <el-dropdown-menu>
                                <el-dropdown-item command="schedule-message" 
                                                  data-testid="schedule-dropdown-schedule-send">
                                    Schedule Send
                                </el-dropdown-item>
                            </el-dropdown-menu>
                        </template>
                    </el-dropdown>
                </template>
                <template v-else-if="!enabledToTextNumber(activePhoneNumber)">
                    <el-popover
                        placement="top"
                        width="300"
                        ref="text-disabled"
                        trigger="hover"
                        popper-class="p-2 btn-primary">
                        <custom-message-display :config="customMessage('text')" />
                        <span slot="reference">
                            <el-button
                                class="ml-xl-2 mr-2"
                                type="success"
                                size="small"
                                data-testid="message-composer-disable-text-number-send-button"
                                disabled>
                                Send
                            </el-button>
                        </span>
                    </el-popover>
                </template>
                <template v-else>
                    <el-popover
                        placement="top"
                        width="300"
                        ref="text-invalid"
                        trigger="hover"
                        popper-class="p-2"
                        :disabled="!isNumberInvalidOrWrong(activePhoneNumber) && !isOptedOut(activePhoneNumber) && canSendNonTCPAContacts && !isMessagingBlocked">
                        <span v-html="getTooltipMessage(activePhoneNumber, !canSendNonTCPAContacts, blockedMessage)">
                        </span>
                        <span slot="reference">
                            <el-button
                                class="ml-xl-2 mr-2"
                                type="success"
                                size="small"
                                data-testid="message-composer-enable-text-number-send-button"
                                disabled>
                                Send
                            </el-button>
                        </span>
                    </el-popover>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
import {mapState} from "vuex"
import cloneDeep from "lodash/cloneDeep"
import {
    contact_activities_mixin,
    contact_phone_number_mixin,
    url_shortener_mixin,
    kyc_mixin,
    sms_mixin
} from "../../mixins"
import auth from '../../auth'
import Variables from "./variables"
import Templates from "./templates"
import Gifs from "./gifs"
import Attachments from "./attachments"
import isEmpty from "lodash/isEmpty"
import CustomMessageDisplay from '../kyc/custom-message-display'
import * as CommunicationTypes from '../../constants/communication-types'

export default {
    name: "message-composer",

    components: {Attachments, Templates, Variables, Gifs, CustomMessageDisplay},

    mixins: [
        contact_activities_mixin,
        contact_phone_number_mixin,
        url_shortener_mixin,
        kyc_mixin,
        sms_mixin
    ],

    props: {
        value: {
            required: true,
            type: Object,
            default: {}
        },

        action: {
            required: false,
            type: String,
            default: 'send'
        },

        phone_numbers: {
            required: false,
            type: Array
        },

        forceDisabled: {
            required: false,
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            auth: auth,
            mode: 'default',
            loading: false,
            show_schedule_dialog: false,
            message_object: {
                body: this.value.message || this.value.body || '',
                attachments: this.value.attachments || [],
                contact: this.value.contact,
                campaign_id: this.value.campaign,
                gif: this.value.gif,
                schedule_date: (this.value.schedule_date) ? moment(this.value.schedule_date).toDate() : moment().subtract(1, 'minutes').toDate(),
                schedule_id: this.value.schedule_id,
                phone_number: this.value.phone_number
            },
            schedule_date: (this.value.schedule_date) ? moment(this.value.schedule_date).toDate() : moment().subtract(1, 'minutes').toDate(),
            calendar_attributes: [
                {
                    key: 'today',
                    highlight: true,
                    dates: new Date()
                }
            ],
            message_body_limit: 1600
        }
    },

    computed: {
        ...mapState('cache', ['current_company']),

        blockedMessage() {
            if (!this.isMessagingBlocked) {
                return ''
            }

            if (isEmpty(this.selectedCampaign)) {
                return ''
            }

            /**
             * Bypass until we have better logic to block SMS
             * @todo
             */
            if (this.selectedCampaign.blocked_messaging_information['bypassed']) {
                return ''
            }

            return this.selectedCampaign.blocked_messaging_information['blocked'] ? this.selectedCampaign.blocked_messaging_information['reason'] : ''
        },

        sendEnabled() {
            // If there is no selected campaign, then disable the send button
            if (!this.selected_campaign_id) {
                return false
            }

            if (this.forceDisabled || !this.hasSomeMessage) {
                return false
            }

            if (this.isMessagingBlocked(this.selectedCampaign)) {
                return false
            }

            if (this.activePhoneNumber) {
                return !this.isNumberInvalidOrWrong(this.activePhoneNumber) && !this.isOptedOut(this.activePhoneNumber)
            }

            return !!(!this.disabled && !this.disableSend && !this.reachedMessageLimit && this.contact_incoming_number && this.contact_incoming_number.is_sms_capable);
        },

        disabled() {
            if (!this.activePhoneNumber || this.forceDisabled) {
                return true
            }

            return !this.current_company.sms_enabled || this.activePhoneNumber.is_opted_out || !this.canSendNonTCPAContacts
        },

        disableSend() {
            if (!this.selected_campaign_id) {
                return true
            }

            if (!this.contact_incoming_number || (this.contact_incoming_number && !this.contact_incoming_number.phone_number)) {
                return true
            }

            if (!this.message_object.gif && this.message_object.attachments.length == 0 && (!this.message_object.body || !this.message_object.body.trim())) {
                return true
            }

            return false
        },

        canSendNonTCPAContacts() {
            return !(this.current_company.enforce_tcpa && !this.message_object?.contact?.text_authorized)
        },

        name() {
            return this.$options.filters.fixContactName(this.message_object.contact.name)
        },

        is_schedule_deliverable() {
            if (!this.schedule_date) {
                return false
            }

            let schedule_date = moment(this.schedule_date)
            let today = moment()

            return schedule_date.isAfter(today)
        },

        contactCampaigns() {
            if (this.contactCampaignsFromCommunications) {
                return this.contactCampaignsFromCommunications.filter(campaign => campaign.active == true)
            }

            return []
        },

        otherContactCampaigns() {
            if (this.otherCampaignsFromCommunications) {
                return this.otherCampaignsFromCommunications.filter(campaign => campaign.active == true)
            }

            return []
        },

        reachedMessageLimit() {
            // Limit the message length to 1600 characters
            return !!(this.message_object.body && this.message_object.body.length > this.message_body_limit);
        },

        hasSomeMessage() {
            if (this.message_object.body.trim() !== '' || this.message_object.attachments.length > 0 || this.message_object.gif) {
                return true
            }

            return false
        },

        highlightMessageLimitInfo() {
            // show message char limit reached but sending not disabled
            return !!(this.message_object.body && this.message_object.body.length >= this.message_body_limit);
        },

        activePhoneNumber() {
            // search the phone number data from the collection of contact's phone number based on the phone number
            if (this.selected_phone_number && this.phone_numbers && this.phone_numbers.length) {
                return this.phone_numbers.find(num => num.phone_number === this.selected_phone_number)
            }

            return null
        },
    },

    mounted() {
        this.auth = auth

        // react to the updated mixin values after promise
        VueEvent.listen('contact_communications_loaded', (data) => {
            if (+data.contact_id === +this.value.contact.id) {
                // gets called first, because "communications_and_audits" is reset in the method
                this.fetchedContactInfo(this.value.contact)

                // update the referenced property values
                this.communications_and_audits = data.communications_and_audits

                if (!this.selected_campaign_id && this.communications_and_audits.length) {
                    // Get the latest communication that is either SMS or CALL
                    const latestCommunication = _.find(_.orderBy(this.communications_and_audits, item => item.created_at, ['desc']), item => {
                        return item.type === CommunicationTypes.SMS || item.type === CommunicationTypes.CALL
                    })

                    // Set the selected campaign to the latest communication's campaign_id
                    if (latestCommunication) {
                        this.selected_campaign_id = latestCommunication.campaign_id
                    }
                } else {
                    this.selected_campaign_id = data.selected_campaign_id
                }

                // setup messenger details accordingly
                this.showContactInfo(this.value.contact.id)
                this.updateMessageComposer()
            }
        })
    },

    methods: {
        showDetails() {
            return this.value.contact
        },

        closeSchedulePicker() {
            this.show_schedule_dialog = false
            this.resetStaticFields(false)
            this.mode = 'default'
        },

        highlightBottomBar(highlight = true) {
            if (highlight) {
                this.$el.querySelector('.message-actions').style.background = '#94949414';
                this.$el.querySelector('.message-actions').style.borderTop = 'solid 1px #ddd';
            } else {
                this.$el.querySelector('.message-actions').style.background = 'none';
                this.$el.querySelector('.message-actions').style.borderTop = 'solid 1px transparent';
            }
        },

        reHeightMessageInput() {
            let message_input_dom = document.getElementById('message-input')
            message_input_dom.value = this.message_object.body
            if (this.message_object.body && this.message_object.body.trim()) {
                message_input_dom.style.height = '38px'
            } else {
                message_input_dom.style.height = '0'
            }

            message_input_dom.style.height = message_input_dom.scrollHeight + 'px'
        },

        appendToMessageBody(text) {
            if (!this.disabled) {
                this.message_object.body = (this.message_object.body ?? '') + ' ' + text
                this.reHeightMessageInput()
            }
        },

        variableSelected(variable) {
            this.message_object.body = (this.message_object.body ?? '') + ' ' + variable
            this.reHeightMessageInput()
        },

        templateSelected(template) {
            this.message_object.body = (this.message_object.body ?? '') + ' ' + template
            this.reHeightMessageInput()
        },

        gifSelected(gif) {
            this.message_object.gif = gif
        },

        fileUploaded(files) {
            this.message_object.attachments.push(files)
        },

        getImagePreviewLink(uuid) {
            return '/static/uploaded_file/' + uuid
        },

        removeGif() {
            this.message_object.gif = ''
        },

        removeAttachment(attachment) {
            this.message_object.attachments = this.message_object.attachments.filter(a => {
                return a.id !== attachment.id
            })
        },

        showScheduleDialog() {
            this.show_schedule_dialog = true
            this.mode = 'schedule'
        },

        resetScheduleDateTime() {
            this.schedule_date = moment().subtract(1, 'minutes').toDate()
            this.message_object.schedule_date = (this.value?.schedule_date) ? moment(this.value.schedule_date).toDate() : this.schedule_date
            this.show_schedule_dialog = false
            this.mode = 'default'
        },

        setScheduleDateTime() {
            this.message_object.schedule_date = this.schedule_date
            this.show_schedule_dialog = false

            let final_message_object = this.getFinalMessageObject()
            this.$emit('create-scheduled-message', final_message_object)
        },

        resetStaticFields(reset_body = true) {
            this.schedule_date = moment().subtract(1, 'minutes').toDate()
            this.message_object.schedule_date = (this.value?.schedule_date) ? moment(this.value.schedule_date).toDate() : this.schedule_date

            if (reset_body) {
                this.message_object.body = ''
                this.message_object.attachments = []
                this.message_object.gif = null
            }

            this.reHeightMessageInput()
        },

        isLoading(loading = true) {
            this.loading = loading
            setTimeout(() => {
                if (this.$refs.message_composer_text) {
                    this.$refs.message_composer_text.focus()
                }
            }, 100)
        },

        handleEnterPress(event) {
            if (event.keyCode === 13 && event.shiftKey) {
                event.preventDefault()
                this.message_object.body = this.message_object.body + "\n"
            } else if (event.keyCode === 13) {
                event.preventDefault()
                this.send()
            }
        },

        setPhoneNumber(phone_number) {
            this.message_object.phone_number = phone_number
            this.selected_phone_number = cloneDeep(phone_number)
        },

        getFinalMessageObject() {
            let attachments = this.message_object.attachments
            let attachments_uuids = Array.isArray(attachments) ? attachments.map(a => a.uuid) : []

            return {
                body: this.message_object.body,
                phone_number: this.message_object.phone_number,
                attachments: attachments_uuids,
                contact_id: this.message_object.contact.id,
                campaign_id: this.selected_campaign_id,
                gif: this.message_object.gif,
                schedule_date: this.message_object.schedule_date,
                schedule_id: this.message_object.schedule_id
            }
        },

        send() {
            if (!this.sendEnabled || !this.canSendNonTCPAContacts) {
                return false
            }

            let final_message_object = this.getFinalMessageObject()

            if (this.mode === 'default') {
                this.$emit('send-message', final_message_object)
            }

            if (this.mode === 'schedule' && final_message_object.schedule_id) {
                this.$emit('update-scheduled-message', final_message_object)
            }

            if (this.mode === 'schedule' && !final_message_object.schedule_id) {
                this.$emit('create-scheduled-message', final_message_object)
            }

            this.mode = 'default'
        },

        openCarForm() {
            this.$emit('open-car-form')
        },

        sendCreditApplicationLink() {
            this.$emit('send-credit-application-link')
        },

        filterPausedLine() {
            let campaign = this.campaigns.find(c => c.id == this.selected_campaign_id)

            // if campaign is not found, then reset the selected_campaign_id
            if (!campaign) {
                this.selected_campaign_id = null
                return
            }

            const hasCampaignIncomingNumber = campaign.incoming_numbers.some(number => number.campaign_id === this.selected_campaign_id)

            // if campaign is not active or does not have incoming number, then reset the selected_campaign_id
            if (!campaign.active || !hasCampaignIncomingNumber) {
                this.selected_campaign_id = null
            }
        },

        imposeMessageCharLimit(event) {
            const element = event.currentTarget

            // force reinstatement of maxlength (if bypassed)
            if (!element.hasAttribute('maxlength') ||
                !element.getAttribute('maxlength') ||
                parseInt(element.getAttribute('maxlength')) > this.message_body_limit) {
                element.setAttribute('maxlength', this.message_body_limit)
            }

            // prune message characters to allowed limit
            this.message_object.body = this.message_object.body.substr(0, this.message_body_limit - this.smartEncodingExtraChars)
            this.messageLength(this.message_object.body)
        },

        changeSelectedCampaign(campaign_id) {
            this.$emit('changed-selected-campaign', campaign_id)
        },

        async detectLongUrls() {
            this.message_object.body = await this.shortenUrls(this.message_object.body)
        },

        onBlurMessageInput() {
            this.highlightBottomBar(false)
            this.detectLongUrls()
        },

        isMessagingBlocked(campaign = null, is_from_selector = false) {
            if (campaign === null) {
                campaign = this.selectedCampaign
            }

            if (isEmpty(campaign)) {
                return false
            }

            /**
             * Bypass until we have better logic to block SMS
             * For the selector, we keep showing the (!) redmark
             * @todo
             */
            if (campaign.blocked_messaging_information['bypassed'] && !is_from_selector) {
                return false
            }

            return campaign.blocked_messaging_information['blocked'] && !campaign.blocked_messaging_information['automated_only']
        },
    },

    watch: {
        message_object: {
            handler(value) {
                this.$emit('input', value)
            },
            deep: true
        },

        'message_object.contact': function (n, o) {
            const has_changed = JSON.stringify(n) !== JSON.stringify(o)

            // hit the API only if contact is present and it was changed
            if (this.message_object && this.message_object.contact && this.message_object.contact.id && has_changed) {
                this.fetchContactInfo(this.message_object.contact.id).then(res => {
                    if (res && res.data) {
                        this.fetchedContactInfo(res.data)
                        this.updateMessageComposer()
                    }
                })
            }
        },

        selected_campaign_id() {
            this.filterPausedLine()
        },

        contactCampaigns() {
            this.filterPausedLine()
        },

        otherContactCampaigns() {
            this.filterPausedLine()
        }
    }
}
</script>

<style scoped>
.not-registered::after {
    content: "i";
    position: absolute;
    right: 15px;
    display: inline-block;
    width: 20px;
    height: 20px;
    line-height: 20px;
    border-radius: 10px;
    text-align: center;
    top: 0;
    bottom: 0;
    margin: auto 0;
    background: #0071e2;
    color: #fff;
    z-index: 999999999;
}

.not-registered-desc {
    position: relative;
    width: 100%;
    background: #BD1A1A;
    border-radius: 10px;
    padding: 5px;
    margin-top: 10px;
}

</style>
