var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm._m(0),
    _vm._v(" "),
    _c("div", { staticClass: "d-flex" }, [
      _c(
        "div",
        { staticClass: "col-4" },
        [
          _c("p", { staticClass: "mb-0 font-weight-bold" }, [_vm._v("Field:")]),
          _vm._v(" "),
          _c("el-input", {
            attrs: { placeholder: "Parameter" },
            model: {
              value: _vm.nodeData.parameter,
              callback: function ($$v) {
                _vm.$set(_vm.nodeData, "parameter", $$v)
              },
              expression: "nodeData.parameter",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "col-3" },
        [
          _c("p", { staticClass: "mb-0 font-weight-bold" }, [
            _vm._v("Operator:"),
          ]),
          _vm._v(" "),
          _c(
            "el-select",
            {
              attrs: { placeholder: "Operator Type" },
              model: {
                value: _vm.nodeData.operator_type,
                callback: function ($$v) {
                  _vm.$set(_vm.nodeData, "operator_type", $$v)
                },
                expression: "nodeData.operator_type",
              },
            },
            _vm._l(_vm.OPERATOR_TYPES, function (operator) {
              return _c("el-option", {
                key: operator[0],
                attrs: { label: operator[1].label, value: operator[0] },
              })
            }),
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "col-5" },
        [
          _c("p", { staticClass: "mb-0 font-weight-bold" }, [_vm._v("Value:")]),
          _vm._v(" "),
          _c("el-input", {
            attrs: { placeholder: "Value" },
            model: {
              value: _vm.nodeData.value,
              callback: function ($$v) {
                _vm.$set(_vm.nodeData, "value", $$v)
              },
              expression: "nodeData.value",
            },
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-12" }, [
      _c("p", { staticClass: "font-weight-bold side-panel-title" }, [
        _vm._v("Condition Panel"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }