var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "app-header",
      style: { top: _vm.total_offset + "px" },
      attrs: { "data-testid": "app-header-component" },
    },
    [
      !_vm.auth.user.profile.created_by_reseller || _vm.isSimpSocial
        ? _c("terms-and-conditions", {
            attrs: { "data-testid": "terms-and-conditions" },
          })
        : _vm._e(),
      _vm._v(" "),
      _c("workflow-creator", { ref: "workflow_creator" }),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "navbar navbar-toggleable-sm flex-row align-items-center justify-content-center bg-white",
        },
        [
          _vm._m(0),
          _vm._v(" "),
          _vm.auth.user &&
          _vm.auth.user.profile &&
          !_vm.auth.user.profile.is_reseller
            ? _c(
                "a",
                {
                  staticClass: "d-none d-lg-block mr-2 text-lg text-blackish",
                  attrs: {
                    "data-testid": "toggle-sidebar",
                    title: _vm.sidebar_folded
                      ? "Normal Layout"
                      : "Expanded Layout",
                  },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.toggleSidebar.apply(null, arguments)
                    },
                  },
                },
                [_c("i", { staticClass: "fa fa-bars" })]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "mb-0 h5 no-wrap",
              attrs: { id: "page-title", "data-testid": "page-title" },
            },
            [
              _vm.isLargeEnough
                ? [
                    _vm._l(_vm.validRoutes, function (route, idx) {
                      return [
                        idx != 1
                          ? [
                              _c(
                                "router-link",
                                { attrs: { to: { name: route.name } } },
                                [
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s(
                                        _vm.applyCustomPageTitle(
                                          route.name.capitalize()
                                        )
                                      ) +
                                      "\n                        "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              idx < _vm.validRoutes.length - 1 &&
                              _vm.currentRouteName.length > 0 &&
                              idx != 0
                                ? _c(
                                    "span",
                                    { staticClass: "ml-1 mr-1 _100" },
                                    [
                                      _vm._v(
                                        "\n                            >\n                        "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]
                          : _vm._e(),
                      ]
                    }),
                    _vm._v(" "),
                    _vm.pageTitle
                      ? [
                          _c("span", { staticClass: "ml-1 mr-1 _100" }, [
                            _vm._v(
                              "\n                        >\n                    "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("span", [
                            _vm._v(
                              "\n                        " +
                                _vm._s(_vm.pageTitle) +
                                "\n                    "
                            ),
                          ]),
                        ]
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.contact_name
                      ? [
                          _c("span", { staticClass: "ml-1 mr-1 _100" }, [
                            _vm._v(
                              "\n                        >\n                    "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("span", [
                            _vm._v(
                              "\n                        " +
                                _vm._s(_vm.contact_name) +
                                "\n                    "
                            ),
                          ]),
                        ]
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.currentSelectedTeamName
                      ? [
                          _c("span", { staticClass: "ml-1 mr-1 _100" }, [
                            _vm._v(
                              "\n                        >\n                    "
                            ),
                          ]),
                          _vm._v(" "),
                          _c(
                            "el-tooltip",
                            {
                              attrs: {
                                effect: "dark",
                                placement: "bottom-start",
                                content: _vm.currentSelectedTeamName,
                              },
                            },
                            [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("truncateText")(
                                      _vm.currentSelectedTeamName
                                    )
                                  )
                                ),
                              ]),
                            ]
                          ),
                        ]
                      : _vm._e(),
                  ]
                : [
                    _vm.lastRoute && _vm.lastRoute.name && !_vm.contact_name
                      ? _c(
                          "router-link",
                          { attrs: { to: { name: _vm.lastRoute.name } } },
                          [
                            _vm._v(
                              "\n                    " +
                                _vm._s(
                                  _vm.applyCustomPageTitle(
                                    _vm.lastRoute.name.capitalize()
                                  )
                                ) +
                                "\n                "
                            ),
                          ]
                        )
                      : [
                          _c("span", [
                            _vm._v(
                              "\n                        " +
                                _vm._s(_vm.contact_name) +
                                "\n                    "
                            ),
                          ]),
                        ],
                  ],
            ],
            2
          ),
          _vm._v(" "),
          _c("div", {
            staticClass: "collapse navbar-collapse",
            attrs: { "data-testid": "navabar-collapse", id: "collapse" },
          }),
          _vm._v(" "),
          _c(
            "ul",
            {
              class: {
                nav: true,
                "navbar-nav": true,
                "ml-auto": true,
                "flex-row": true,
                "no-select": true,
              },
              attrs: { "data-testid": "navbar-right" },
            },
            [
              _vm.auth.user.profile.focus_mode
                ? _c(
                    "li",
                    {
                      staticClass: "nav-item mr-2",
                      attrs: { "data-testid": "navabar-right-li" },
                    },
                    [_vm._m(1)]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.talkEnabled && _vm.isLargeEnough
                ? _c(
                    "li",
                    {
                      staticClass: "nav-item d-flex align-items-center mr-2",
                      attrs: { "data-testid": "navabar-right-li" },
                    },
                    [
                      _c(
                        "span",
                        { staticClass: "nav-link" },
                        [
                          _vm.auth.user.profile
                            ? _c("talk-bridge", {
                                attrs: { "data-testid": "li-talk-bridge" },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.talkEnabled &&
              !_vm.forceTalk &&
              _vm.isLargeEnough &&
              !_vm.isSimpSocial
                ? _c(
                    "li",
                    {
                      staticClass:
                        "nav-item d-flex align-items-center alo-talk-info-container",
                      attrs: { "data-testid": "navabar-right-li" },
                    },
                    [
                      _c(
                        "span",
                        { staticClass: "nav-link" },
                        [
                          _c(
                            "el-popover",
                            {
                              ref: "aloInfo",
                              style: { verticalAlign: "-2px !important" },
                              attrs: {
                                placement: "bottom-end",
                                width: "550",
                                trigger: "hover",
                                "popper-class": "alo-popover",
                                "data-testid": "li-popover",
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "alo-default-app-selector-dialog",
                                },
                                [
                                  _c("alo-talk-info", {
                                    attrs: {
                                      "data-testid": "li-alo-talk-info",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "button",
                                {
                                  staticClass:
                                    "btn btn-xs btn-outline btn-default text-white pl-2 pr-2 alo-talk-info-button",
                                  attrs: {
                                    slot: "reference",
                                    "data-testid": "new-btn",
                                  },
                                  slot: "reference",
                                },
                                [
                                  _c("span", [
                                    _vm._v(
                                      "\n                                New\n                                "
                                    ),
                                    _c("img", {
                                      attrs: {
                                        src: "/assets/images/app-icons/info-icon-outline.png",
                                        alt: "info-icon",
                                        "data-testid": "new-btn-img",
                                      },
                                    }),
                                  ]),
                                ]
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.usage &&
              _vm.isLargeEnough &&
              !_vm.isMobileSize &&
              _vm.hasRole("Billing Admin") &&
              !_vm.isTrialKYC &&
              _vm.auth.user.profile &&
              (!_vm.auth.user.profile.created_by_reseller ||
                (_vm.auth.user.profile.created_by_reseller &&
                  _vm.auth.user.profile.independent_billing) ||
                (_vm.auth.user.profile.created_by_reseller &&
                  _vm.is_impersonated &&
                  _vm.current_company &&
                  _vm.current_company.reseller_id != 357))
                ? _c("li", { staticClass: "nav-item mr-2" }, [
                    _c(
                      "span",
                      {
                        staticClass: "nav-link",
                        attrs: { "data-testid": "navabar-right-li" },
                      },
                      [
                        _c(
                          "router-link",
                          {
                            staticStyle: { "vertical-align": "-2px" },
                            attrs: {
                              to: {
                                name: "Account",
                                query: { tab: "billing" },
                              },
                            },
                          },
                          [
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-xs btn-outline _400",
                                style: {
                                  color: "#ffffff",
                                  "background-color": _vm.creditColor,
                                },
                              },
                              [
                                _c("span", [
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(
                                        _vm._f("toCurrency")(
                                          _vm._f("fixRounding")(
                                            _vm.usage.credits
                                          )
                                        )
                                      ) +
                                      "\n                            "
                                  ),
                                ]),
                              ]
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.isLargeEnough &&
              !_vm.isMobileSize &&
              _vm.hasAccess &&
              _vm.auth.user.profile &&
              !_vm.auth.user.profile.is_reseller
                ? _c(
                    "li",
                    {
                      staticClass: "nav-item dropdown mr-2",
                      attrs: { "data-testid": "navabar-right-li" },
                    },
                    [
                      _c(
                        "a",
                        {
                          ref: "addMenu",
                          staticClass: "nav-link",
                          attrs: {
                            href: "",
                            "data-tour-step": "1",
                            "data-testid": "green-plus-btn",
                            "data-toggle": "dropdown",
                          },
                        },
                        [
                          _c(
                            "i",
                            {
                              staticClass: "material-icons text-greenish",
                              staticStyle: {
                                "font-size": "2.6em",
                                "vertical-align": "-18px",
                              },
                              attrs: { "data-testid": "li-i" },
                            },
                            [_vm._v("add_box")]
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "dropdown-menu w-xxl hover-menu text-color pull-right p-0 dropdown-menu-right dropdown-menu-overlay dropdown-menu-scale",
                          attrs: { "data-testid": "li-div" },
                        },
                        [
                          _c("div", { staticClass: "row no-gutter" }, [
                            _vm.hasPermissionTo("create campaign")
                              ? _c(
                                  "div",
                                  { staticClass: "col-4 b-a" },
                                  [
                                    !_vm.isCreateLineAllowed
                                      ? _c(
                                          "el-popover",
                                          {
                                            ref: "line-popover",
                                            staticClass: "custom-popover",
                                            attrs: {
                                              placement: "left",
                                              width: "200",
                                              "popper-class": "btn-primary",
                                              trigger: "hover",
                                              "data-testid": "li-popover",
                                            },
                                          },
                                          [
                                            _c("custom-message-display", {
                                              attrs: {
                                                "data-testid":
                                                  "custom-message-display",
                                                config:
                                                  _vm.customMessage(
                                                    "line.create"
                                                  ),
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c("line-creator", {
                                      ref: "addCampaignContactCenter",
                                      attrs: { "data-testid": "line-creator" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "trigger",
                                            fn: function () {
                                              return [
                                                _vm.isCreateLineAllowed
                                                  ? _c(
                                                      "a",
                                                      {
                                                        staticClass:
                                                          "p-1 block text-center",
                                                        attrs: {
                                                          "data-testid":
                                                            "line-creator-a",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.$refs.addCampaignContactCenter.openModal()
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "icon mt-2",
                                                          },
                                                          [
                                                            _c("img", {
                                                              attrs: {
                                                                "data-testid":
                                                                  "icon-lines-grey-img",
                                                                src: "/assets/images/app-icons/icon-lines-grey.svg",
                                                              },
                                                            }),
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "icon active mt-2",
                                                          },
                                                          [
                                                            _c("img", {
                                                              attrs: {
                                                                "data-testid":
                                                                  "icon-lines-white-img",
                                                                src: "/assets/images/app-icons/icon-lines-white.svg",
                                                              },
                                                            }),
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "block mt-2",
                                                            attrs: {
                                                              "data-testid":
                                                                "line-creator-line",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                                            Line\n                                        "
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                !_vm.isCreateLineAllowed
                                                  ? _c(
                                                      "a",
                                                      {
                                                        directives: [
                                                          {
                                                            name: "popover",
                                                            rawName:
                                                              "v-popover:line-popover",
                                                            arg: "line-popover",
                                                          },
                                                        ],
                                                        staticClass:
                                                          "p-1 block text-center cursor-default",
                                                        attrs: {
                                                          "data-testid":
                                                            "line-creator-a",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "icon mt-2",
                                                          },
                                                          [
                                                            _c("img", {
                                                              attrs: {
                                                                "data-testid":
                                                                  "icon-lines-grey-img",
                                                                src: "/assets/images/app-icons/icon-lines-grey.svg",
                                                              },
                                                            }),
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "icon active mt-2",
                                                          },
                                                          [
                                                            _c("img", {
                                                              attrs: {
                                                                "data-testid":
                                                                  "icon-lines-white-img",
                                                                src: "/assets/images/app-icons/icon-lines-white.svg",
                                                              },
                                                            }),
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "block mt-2",
                                                            attrs: {
                                                              "data-testid":
                                                                "line-creator-line",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                                            Line\n                                        "
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                              ]
                                            },
                                            proxy: true,
                                          },
                                        ],
                                        null,
                                        false,
                                        2594680927
                                      ),
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.hasPermissionTo("create ring group")
                              ? _c(
                                  "div",
                                  { staticClass: "col-4 b-a" },
                                  [
                                    _c(
                                      "router-link",
                                      {
                                        attrs: {
                                          to: { name: "Ring Group Dialog" },
                                        },
                                      },
                                      [
                                        _c(
                                          "a",
                                          {
                                            staticClass:
                                              "p-1 block text-center",
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "icon mt-2" },
                                              [
                                                _c("img", {
                                                  attrs: {
                                                    "data-testid":
                                                      "icon-lines-group-grey-img",
                                                    src: "/assets/images/app-icons/icon-ring-groups-grey.svg",
                                                  },
                                                }),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass: "icon active mt-2",
                                              },
                                              [
                                                _c("img", {
                                                  attrs: {
                                                    "data-testid":
                                                      "icon-lines-groups-white-img",
                                                    src: "/assets/images/app-icons/icon-ring-groups-white.svg",
                                                  },
                                                }),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "span",
                                              { staticClass: "block mt-2" },
                                              [_vm._v("Ring Group")]
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.hasPermissionTo("create user")
                              ? _c(
                                  "div",
                                  { staticClass: "col-4 b-a" },
                                  [
                                    _c(
                                      "router-link",
                                      {
                                        attrs: { to: { name: "User Dialog" } },
                                      },
                                      [
                                        _c(
                                          "a",
                                          {
                                            staticClass:
                                              "p-1 block text-center",
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "icon mt-2" },
                                              [
                                                _c(
                                                  "i",
                                                  {
                                                    staticClass:
                                                      "material-icons md-24",
                                                    attrs: {
                                                      "data-testid":
                                                        "people-outline-i",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                                           people_outline\n                                        "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass: "icon active mt-2",
                                              },
                                              [
                                                _c(
                                                  "i",
                                                  {
                                                    staticClass:
                                                      "material-icons md-24",
                                                    attrs: {
                                                      "data-testid":
                                                        "people-outline-i",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                                           people_outline\n                                        "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "span",
                                              {
                                                staticClass: "block mt-2",
                                                attrs: {
                                                  "data-testid": "user-span",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                        User\n                                    "
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.hasPermissionTo("create contact")
                              ? _c(
                                  "div",
                                  { staticClass: "col-4 b-a" },
                                  [
                                    !_vm.enabledToCreateContacts()
                                      ? _c(
                                          "el-popover",
                                          {
                                            ref: "create-contacts-dialog",
                                            staticClass: "custom-popover",
                                            attrs: {
                                              placement: "left",
                                              width: "200",
                                              "popper-class": "btn-primary",
                                              trigger: "hover",
                                              "data-testid":
                                                "contact-create-popover",
                                            },
                                          },
                                          [
                                            _c("custom-message-display", {
                                              attrs: {
                                                "data-testid":
                                                  "custom-message-display",
                                                config:
                                                  _vm.customMessage(
                                                    "contacts.create"
                                                  ),
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.enabledToCreateContacts()
                                      ? _c("contact-creator", {
                                          ref: "addContact",
                                          attrs: {
                                            "data-testid": "contact-creator",
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "trigger",
                                                fn: function () {
                                                  return [
                                                    _c(
                                                      "a",
                                                      {
                                                        staticClass:
                                                          "p-1 block text-center",
                                                        attrs: {
                                                          "data-testid":
                                                            "contact-creator-open-modal",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.$refs.addContact.openModal()
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "icon mt-2",
                                                          },
                                                          [
                                                            _c("img", {
                                                              attrs: {
                                                                "data-testid":
                                                                  "icon-contacts-grey-img",
                                                                src: "/assets/images/app-icons/icon-contacts-grey.svg",
                                                              },
                                                            }),
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "icon active mt-2",
                                                          },
                                                          [
                                                            _c("img", {
                                                              attrs: {
                                                                "data-testid":
                                                                  "icon-contacts-white-img",
                                                                src: "/assets/images/app-icons/icon-contacts-white.svg",
                                                              },
                                                            }),
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "block mt-2",
                                                          },
                                                          [_vm._v("Contact")]
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                },
                                                proxy: true,
                                              },
                                            ],
                                            null,
                                            false,
                                            2247983980
                                          ),
                                        })
                                      : _c(
                                          "a",
                                          {
                                            directives: [
                                              {
                                                name: "popover",
                                                rawName:
                                                  "v-popover:create-contacts-dialog",
                                                arg: "create-contacts-dialog",
                                              },
                                            ],
                                            staticClass:
                                              "p-1 block text-center cursor-default",
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "icon mt-2" },
                                              [
                                                _c("img", {
                                                  attrs: {
                                                    "data-testid":
                                                      "icon-contacts-grey-img",
                                                    src: "/assets/images/app-icons/icon-contacts-grey.svg",
                                                  },
                                                }),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass: "icon active mt-2",
                                              },
                                              [
                                                _c("img", {
                                                  attrs: {
                                                    "data-testid":
                                                      "icon-contacts-white-img",
                                                    src: "/assets/images/app-icons/icon-contacts-white.svg",
                                                  },
                                                }),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "span",
                                              { staticClass: "block mt-2" },
                                              [_vm._v("Contact")]
                                            ),
                                          ]
                                        ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.hasPermissionTo("create tag")
                              ? _c(
                                  "div",
                                  { staticClass: "col-4 b-a" },
                                  [
                                    _c("tag-creator", {
                                      ref: "addTag",
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "trigger",
                                            fn: function () {
                                              return [
                                                _c(
                                                  "a",
                                                  {
                                                    staticClass:
                                                      "p-1 block text-center",
                                                    attrs: {
                                                      "data-testid":
                                                        "tag-creator-open-modal",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        _vm.$refs.addTag.hide_add = true
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "icon mt-2",
                                                      },
                                                      [
                                                        _c("img", {
                                                          attrs: {
                                                            "data-testid":
                                                              "icon-tags-grey-img",
                                                            src: "/assets/images/app-icons/icon-tags-grey.svg",
                                                          },
                                                        }),
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "icon active mt-2",
                                                      },
                                                      [
                                                        _c("img", {
                                                          attrs: {
                                                            "data-testid":
                                                              "icon-tags-white-img",
                                                            src: "/assets/images/app-icons/icon-tags-white.svg",
                                                          },
                                                        }),
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "block mt-2",
                                                      },
                                                      [_vm._v("Tag")]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            },
                                            proxy: true,
                                          },
                                        ],
                                        null,
                                        false,
                                        1868111039
                                      ),
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.hasPermissionTo("create workflow")
                              ? _c(
                                  "div",
                                  { staticClass: "col-4 b-a" },
                                  [
                                    !_vm.enabledToAddSequences()
                                      ? _c(
                                          "el-popover",
                                          {
                                            ref: "secuence-popover",
                                            staticClass: "custom-popover",
                                            attrs: {
                                              placement: "right",
                                              width: "200",
                                              "popper-class": "btn-primary",
                                              "data-testid": "sequence-popover",
                                              trigger: "hover",
                                            },
                                          },
                                          [
                                            _c("custom-message-display", {
                                              attrs: {
                                                config:
                                                  _vm.customMessage(
                                                    "sequences"
                                                  ),
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.enabledToAddSequences() &&
                                    !_vm.sequencesFeatureRestricted
                                      ? _c(
                                          "a",
                                          {
                                            staticClass:
                                              "p-1 block text-center",
                                            attrs: {
                                              "data-testid":
                                                "sequence-creator-a",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.$refs.workflow_creator.addWorkflow()
                                              },
                                            },
                                          },
                                          [
                                            _vm._m(2),
                                            _vm._v(" "),
                                            _vm._m(3),
                                            _vm._v(" "),
                                            _c(
                                              "span",
                                              { staticClass: "block mt-2" },
                                              [_vm._v("Sequence")]
                                            ),
                                          ]
                                        )
                                      : _c(
                                          "a",
                                          {
                                            directives: [
                                              {
                                                name: "popover",
                                                rawName:
                                                  "v-popover:secuence-popover",
                                                arg: "secuence-popover",
                                              },
                                            ],
                                            staticClass:
                                              "p-1 block text-center cursor-default",
                                          },
                                          [
                                            _vm._m(4),
                                            _vm._v(" "),
                                            _vm._m(5),
                                            _vm._v(" "),
                                            _c(
                                              "span",
                                              { staticClass: "block mt-2" },
                                              [_vm._v("Sequence")]
                                            ),
                                          ]
                                        ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.canCommunicate
                              ? _c(
                                  "div",
                                  { staticClass: "col-4 b-a" },
                                  [
                                    !_vm.enabledToTextNumber()
                                      ? _c(
                                          "el-popover",
                                          {
                                            ref: "sms-dialog",
                                            staticClass: "custom-popover",
                                            attrs: {
                                              placement: "left",
                                              width: "200",
                                              "popper-class": "btn-primary",
                                              trigger: "hover",
                                            },
                                          },
                                          [
                                            _c("custom-message-display", {
                                              attrs: {
                                                config:
                                                  _vm.customMessage("text"),
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    !_vm.enabledToTextNumber()
                                      ? _c(
                                          "a",
                                          {
                                            directives: [
                                              {
                                                name: "popover",
                                                rawName: "v-popover:sms-dialog",
                                                arg: "sms-dialog",
                                              },
                                            ],
                                            staticClass:
                                              "p-1 block text-center cursor-default",
                                          },
                                          [
                                            _vm._m(6),
                                            _vm._v(" "),
                                            _vm._m(7),
                                            _vm._v(" "),
                                            _c(
                                              "span",
                                              { staticClass: "block mt-2" },
                                              [_vm._v("SMS")]
                                            ),
                                          ]
                                        )
                                      : _c("new-message", {
                                          ref: "newMessage",
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "trigger",
                                                fn: function () {
                                                  return [
                                                    _c(
                                                      "a",
                                                      {
                                                        staticClass:
                                                          "p-1 block text-center",
                                                        attrs: {
                                                          "data-testid":
                                                            "sms-a",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            _vm.$refs.newMessage.hide_add = true
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "icon mt-2",
                                                          },
                                                          [
                                                            _c("img", {
                                                              attrs: {
                                                                src: "/assets/images/app-icons/icon-bulk-messaging-grey.svg",
                                                                "data-testid":
                                                                  "icon-bulk-messaging-grey-img",
                                                                width: "24",
                                                                height: "24",
                                                              },
                                                            }),
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "icon active mt-2",
                                                          },
                                                          [
                                                            _c("img", {
                                                              attrs: {
                                                                src: "/assets/images/app-icons/icon-bulk-messaging-white.svg",
                                                                "data-testid":
                                                                  "icon-bulk-messaging-white-img",
                                                                width: "24",
                                                                height: "24",
                                                              },
                                                            }),
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "block mt-2",
                                                          },
                                                          [_vm._v("SMS")]
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                },
                                                proxy: true,
                                              },
                                            ],
                                            null,
                                            false,
                                            222821977
                                          ),
                                        }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.canCommunicate
                              ? _c("div", { staticClass: "col-4 b-a" }, [
                                  _c(
                                    "a",
                                    {
                                      staticClass: "p-1 block text-center",
                                      attrs: { "data-testid": "call-a" },
                                      on: {
                                        click: function ($event) {
                                          $event.preventDefault()
                                          return _vm.toggleDialer.apply(
                                            null,
                                            arguments
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _vm._m(8),
                                      _vm._v(" "),
                                      _vm._m(9),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        { staticClass: "block mt-2" },
                                        [_vm._v("Call")]
                                      ),
                                    ]
                                  ),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.canCommunicate
                              ? _c(
                                  "div",
                                  { staticClass: "col-4 b-a" },
                                  [
                                    _c("new-fax", {
                                      ref: "newFax",
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "trigger",
                                            fn: function () {
                                              return [
                                                _c(
                                                  "a",
                                                  {
                                                    staticClass:
                                                      "p-1 block text-center",
                                                    attrs: {
                                                      "data-testid": "fax-a",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        _vm.$refs.newFax.hide_add = true
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "icon mt-2 status-icon",
                                                      },
                                                      [
                                                        _c("i", {
                                                          staticClass:
                                                            "fa fa-fax md-24 text-grey",
                                                          attrs: {
                                                            "aria-hidden":
                                                              "true",
                                                          },
                                                        }),
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "icon active mt-2 status-icon",
                                                      },
                                                      [
                                                        _c("i", {
                                                          staticClass:
                                                            "fa fa-fax md-24 text-white",
                                                          attrs: {
                                                            "aria-hidden":
                                                              "true",
                                                          },
                                                        }),
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "block mt-2",
                                                      },
                                                      [_vm._v("Fax")]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            },
                                            proxy: true,
                                          },
                                        ],
                                        null,
                                        false,
                                        3434725155
                                      ),
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ]),
                        ]
                      ),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              !_vm.whitelabel && !_vm.loading_whitelabel && !_vm.isMobileSize
                ? _c(
                    "li",
                    {
                      staticClass: "nav-item dropdown pos-stc-xs mr-2",
                      attrs: { "data-testid": "navabar-right-li" },
                    },
                    [
                      _c(
                        "a",
                        {
                          ref: "helpMenu",
                          staticClass: "nav-link",
                          attrs: {
                            "data-toggle": "dropdown",
                            "data-tour-step": "2",
                            "data-testid": "question-circle-icon-a",
                          },
                        },
                        [
                          _c("i", {
                            staticClass:
                              "fa fa-question-circle text-2x text-danger",
                            staticStyle: { "vertical-align": "-9px" },
                          }),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "dropdown-menu pull-right w-lg text-color box-shadow-z2 dropdown-menu-right dropdown-menu-overlay dropdown-menu-scale p-3 allow-select",
                        },
                        [
                          _vm.auth.user.profile
                            ? _c(
                                "span",
                                {
                                  staticClass: "text-md",
                                  attrs: { "data-testid": "service-code" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.noClose($event)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                        Service code: "
                                  ),
                                  _c("b", [
                                    _vm._v(
                                      _vm._s(_vm.auth.user.profile.company_id) +
                                        "-" +
                                        _vm._s(_vm.auth.user.profile.id)
                                    ),
                                  ]),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c("br"),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              staticClass: "text-md",
                              attrs: { "data-testid": "role" },
                              on: {
                                click: function ($event) {
                                  return _vm.noClose($event)
                                },
                              },
                            },
                            [
                              _vm._v("\n                        Role: "),
                              _c("b", [
                                _vm._v(_vm._s(_vm.auth.user.profile.role_name)),
                              ]),
                            ]
                          ),
                          _vm._v(" "),
                          !_vm.auth.user.profile.company.reseller_id
                            ? _c(
                                "a",
                                {
                                  staticClass: "btn btn-block btn-success",
                                  attrs: {
                                    "data-testid": "help-menu-a",
                                    href: "https://aloware.com/setup-guide",
                                    target: "_blank",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                        Setup Guide\n                    "
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.env !== "production" ? _c("hr") : _vm._e(),
                          _vm._v(" "),
                          _vm.env !== "production"
                            ? _c("p", { staticClass: "mb-0 mt-2 text-xs" }, [
                                _vm._v(
                                  "\n                        Environment: "
                                ),
                                _c("span", { staticClass: "_600 text-break" }, [
                                  _vm._v(_vm._s(_vm.env)),
                                ]),
                                _vm._v(" "),
                                _c("br"),
                                _vm._v("\n                        Branch: "),
                                _c("span", { staticClass: "_600 text-break" }, [
                                  _vm._v(_vm._s(_vm.branch)),
                                ]),
                                _vm._v(" "),
                                _c("br"),
                                _vm._v(
                                  "\n                        Fake Number Purchase: "
                                ),
                                _c("span", { staticClass: "_600 text-break" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("fixBooleanType")(
                                        _vm.fake_number_purchase
                                      )
                                    )
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("br"),
                                _vm._v(
                                  "\n                        Fake Power Dialer Calls: "
                                ),
                                _c("span", { staticClass: "_600 text-break" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("fixBooleanType")(
                                        _vm.fake_power_dialer_calls
                                      )
                                    )
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("br"),
                                _vm._v(
                                  "\n                        Fake Text Messages: "
                                ),
                                _c("span", { staticClass: "_600 text-break" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("fixBooleanType")(
                                        _vm.fake_text_messages
                                      )
                                    )
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("br"),
                                _vm._v(
                                  "\n                        Fake Toll-Free Verification: "
                                ),
                                _c("span", { staticClass: "_600 text-break" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("fixBooleanType")(
                                        _vm.fake_toll_free_verification
                                      )
                                    )
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("br"),
                                _vm._v(
                                  "\n                        Mock A2P Brands: "
                                ),
                                _c("span", { staticClass: "_600 text-break" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("fixBooleanType")(
                                        _vm.mock_a2p_brand_registration
                                      )
                                    )
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("br"),
                                _vm._v(
                                  "\n                        Mock A2P Campaigns: "
                                ),
                                _c("span", { staticClass: "_600 text-break" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("fixBooleanType")(
                                        _vm.mock_a2p_campaign_registration
                                      )
                                    )
                                  ),
                                ]),
                              ])
                            : _vm._e(),
                        ]
                      ),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.ak_widget_url &&
              !_vm.whitelabel &&
              !_vm.loading_whitelabel &&
              _vm.current_company &&
              _vm.current_company.reseller_id != 357 &&
              !_vm.isMobileSize
                ? _c(
                    "li",
                    {
                      staticClass: "nav-item dropdown pos-stc-xs mr-2",
                      attrs: { "data-testid": "navabar-right-li" },
                    },
                    [
                      _c(
                        "a",
                        {
                          staticClass: "nav-link ak-trigger",
                          attrs: {
                            href: "#",
                            "data-testid": "announcekit-trigger",
                          },
                        },
                        [
                          _c("span", {
                            staticClass:
                              "fa fa-bullhorn changelog-trigger pointer",
                            staticStyle: {
                              "vertical-align": "-4px",
                              "font-size": "1.2rem",
                            },
                          }),
                          _vm._v(" "),
                          _c("AnnounceKit", {
                            attrs: {
                              catchClick: ".ak-trigger",
                              "data-testid": "announcekit",
                              user: _vm.currentUser,
                              widget: _vm.ak_widget_url,
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "li",
                {
                  staticClass: "nav-item dropdown",
                  class: { "bg-dark": _vm.is_impersonated },
                  attrs: { "data-testid": "navabar-right-li" },
                },
                [
                  _c(
                    "a",
                    {
                      ref: "mainMenu",
                      class: {
                        "nav-link": true,
                        "dropdown-toggle": true,
                        "text-ellipsis": true,
                        "text-white": _vm.is_impersonated,
                      },
                      attrs: {
                        "data-toggle": "dropdown",
                        "data-flip": "false",
                        "data-testid": "user-logged-in",
                        "data-tour-step": "3",
                      },
                    },
                    [
                      _vm.auth.user.profile
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "profile-info d-flex align-items-center pl-1",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "d-flex flex-column ml-2",
                                  staticStyle: { "line-height": "1.2rem" },
                                },
                                [
                                  _c(
                                    "small",
                                    {
                                      staticClass: "dropdown-label",
                                      class: {
                                        "text-white": _vm.is_impersonated,
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                                " +
                                          _vm._s(_vm.auth.user.profile.name) +
                                          "\n                            "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _vm.current_company
                                    ? _c(
                                        "span",
                                        {
                                          staticClass: "company-name _600",
                                          class: {
                                            "text-white": _vm.is_impersonated,
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                                " +
                                              _vm._s(_vm.current_company.name) +
                                              "\n                            "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]
                              ),
                              _vm._v(" "),
                              _c("i", { staticClass: "material-icons ml-2" }, [
                                _vm._v("keyboard_arrow_down"),
                              ]),
                            ]
                          )
                        : _vm._e(),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "dropdown-menu dropdown-menu-right dropdown-menu-overlay dropdown-menu-scale pull-right",
                    },
                    [
                      _vm.auth.user.profile
                        ? _c("div", { staticClass: "row" }, [
                            _c(
                              "div",
                              { staticClass: "col-12" },
                              [
                                _c(
                                  "router-link",
                                  {
                                    staticClass: "dropdown-item",
                                    attrs: {
                                      to: {
                                        name: "User Dialog",
                                        params: {
                                          user_id: _vm.auth.user.profile.id,
                                        },
                                      },
                                    },
                                  },
                                  [
                                    _c("i", { staticClass: "material-icons" }, [
                                      _vm._v("person"),
                                    ]),
                                    _vm._v(
                                      "\n                                Profile Settings\n                            "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.hasRole(["Company Agent", "Company Admin"]) &&
                      _vm.auth.user.profile &&
                      !_vm.auth.user.profile.is_reseller
                        ? _c("div", { staticClass: "row nav-padding" }, [
                            !_vm.auth.user.profile.company.force_talk
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "dropdown-item",
                                    attrs: { "data-testid": "focus-mode" },
                                    on: {
                                      click: function ($event) {
                                        $event.preventDefault()
                                      },
                                    },
                                  },
                                  [
                                    _c("i", { staticClass: "material-icons" }, [
                                      _vm._v("adjust"),
                                    ]),
                                    _vm._v(" "),
                                    _c("span", [_vm._v("Focus Mode")]),
                                    _vm._v(" "),
                                    _c("el-switch", {
                                      staticClass: "ml-2 pull-right",
                                      attrs: {
                                        "active-color": "#00a344",
                                        disabled:
                                          _vm.auth.user.profile.company_id ==
                                            522 && _vm.env == "production",
                                      },
                                      on: { change: _vm.changeFocusMode },
                                      model: {
                                        value: _vm.auth.user.profile.focus_mode,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.auth.user.profile,
                                            "focus_mode",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "auth.user.profile.focus_mode",
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "dropdown-item",
                                attrs: { "data-testid": "sleep-mode" },
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                  },
                                },
                              },
                              [
                                _c(
                                  "i",
                                  {
                                    staticClass: "material-icons fa-rotate-180",
                                  },
                                  [_vm._v("brightness_2")]
                                ),
                                _vm._v(" "),
                                _c("span", [_vm._v("Sleep Mode")]),
                                _vm._v(" "),
                                _c("el-switch", {
                                  staticClass: "ml-2 pull-right",
                                  attrs: { "active-color": "#00a344" },
                                  on: { change: _vm.changeSleepMode },
                                  model: {
                                    value: _vm.auth.user.profile.sleep_mode,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.auth.user.profile,
                                        "sleep_mode",
                                        $$v
                                      )
                                    },
                                    expression: "auth.user.profile.sleep_mode",
                                  },
                                }),
                              ],
                              1
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c("div", { staticClass: "dropdown-divider" }),
                      _vm._v(" "),
                      !_vm.loading_whitelabel &&
                      !_vm.whitelabel &&
                      !_vm.isResold
                        ? _c("div", { staticClass: "row" }, [_vm._m(10)])
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.loading_whitelabel && !_vm.whitelabel
                        ? _c("div", { staticClass: "row" }, [_vm._m(11)])
                        : _vm._e(),
                      _vm._v(" "),
                      _c("div", { staticClass: "dropdown-divider" }),
                      _vm._v(" "),
                      !_vm.loading_whitelabel && !_vm.whitelabel
                        ? _c("div", { staticClass: "row" }, [_vm._m(12)])
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.loading_whitelabel && !_vm.whitelabel
                        ? _c("div", { staticClass: "row" }, [_vm._m(13)])
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.loading_whitelabel && !_vm.whitelabel
                        ? _c("div", { staticClass: "row" }, [_vm._m(14)])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.auth.user.profile.has_multiple_access
                        ? _c("div", { staticClass: "dropdown-divider" })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.auth.user.profile.has_multiple_access
                        ? [
                            _c("div", { staticClass: "row" }, [
                              _c(
                                "div",
                                { staticClass: "col-12" },
                                [
                                  _c(
                                    "router-link",
                                    {
                                      staticClass: "dropdown-item",
                                      attrs: {
                                        to: { name: "Account Selector" },
                                      },
                                    },
                                    [
                                      _c("i", { staticClass: "fas fa-list" }),
                                      _vm._v(
                                        "\n                                    Select Account\n                                "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]),
                          ]
                        : _vm._e(),
                      _vm._v(" "),
                      _c("div", { staticClass: "dropdown-divider" }),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "row",
                          attrs: { "data-testid": "logout-row" },
                          on: {
                            click: function ($event) {
                              return _vm.noClose($event)
                            },
                          },
                        },
                        [
                          _c("div", { staticClass: "col-12" }, [
                            _c(
                              "a",
                              {
                                staticClass: "dropdown-item text-danger",
                                attrs: { "data-testid": "logout-a" },
                                on: {
                                  click: function ($event) {
                                    return _vm.proceedToLogout()
                                  },
                                },
                              },
                              [
                                _c("i", { staticClass: "material-icons" }, [
                                  _vm._v("power_settings_new"),
                                ]),
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(
                                      _vm.is_impersonated
                                        ? "Stop impersonating"
                                        : "Sign out"
                                    ) +
                                    "\n                            "
                                ),
                              ]
                            ),
                          ]),
                        ]
                      ),
                    ],
                    2
                  ),
                ]
              ),
              _vm._v(" "),
              _vm.auth.user.profile && !_vm.current_company.force_talk
                ? _c(
                    "li",
                    {
                      staticClass: "nav-item dropdown",
                      attrs: { "data-testid": "navabar-right-li" },
                    },
                    [
                      _c(
                        "a",
                        {
                          ref: "agentMenu",
                          staticClass:
                            "nav-link d-flex align-items-center no-select",
                          class: [
                            _vm.statusDropdownMode
                              ? "disabled pointer-not-allowed"
                              : "",
                          ],
                          attrs: {
                            "data-tour-step": "4",
                            "data-testid": "user-status-a",
                            "data-toggle": "dropdown",
                          },
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass: "w-40 avatar agent-avatar grey-300",
                              style: _vm.avatarStyle(
                                _vm.auth.user.profile.name
                              ),
                            },
                            [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("initials")(
                                      _vm.auth.user.profile.name
                                    )
                                  )
                                ),
                              ]),
                              _vm._v(" "),
                              _c("i", {
                                staticClass: "b-white bottom",
                                class: [
                                  _vm.$options.filters.agentStatusClass(
                                    _vm.auth.user.profile.agent_status
                                  ),
                                ],
                              }),
                            ]
                          ),
                          _vm._v(" "),
                          _c("i", { staticClass: "material-icons" }, [
                            _vm._v("keyboard_arrow_down"),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c("agent-status-dropdown", {
                        ref: "avatar_agent_statuses",
                        staticClass:
                          "dropdown-menu dropdown-menu-right dropdown-menu-overlay dropdown-menu-scale pull-right",
                        attrs: {
                          id: "avatar-agent-statuses",
                          "data-testid": "avatar-agent-statuses",
                          user_id: _vm.auth.user.profile.id,
                          forced_disabled_ui: true,
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "a",
      {
        staticClass: "d-lg-none mr-2",
        attrs: {
          "data-toggle": "modal",
          "data-target": "#aside",
          "data-testid": "open-sidebar",
        },
      },
      [_c("i", { staticClass: "material-icons" }, [_vm._v("")])]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "nav-link" }, [
      _c(
        "button",
        {
          staticClass: "btn btn-xs btn-outline amber-700 text-white _400",
          staticStyle: { "vertical-align": "-2px" },
          attrs: { "data-testid": "li-focus-mode-on" },
        },
        [
          _vm._v(
            "\n                        Focus Mode On\n                    "
          ),
        ]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "icon mt-2" }, [
      _c("img", {
        attrs: {
          "data-testid": "icon-sequence-grey-img",
          src: "/assets/images/app-icons/icon-workflow-grey.svg",
          width: "24",
        },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "icon active mt-2" }, [
      _c("img", {
        attrs: {
          "data-testid": "icon-sequence-white-img",
          src: "/assets/images/app-icons/icon-workflow-white.svg",
          width: "24",
        },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "icon mt-2" }, [
      _c("img", {
        attrs: {
          "data-testid": "icon-sequence-grey-img",
          src: "/assets/images/app-icons/icon-workflow-grey.svg",
          width: "24",
        },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "icon active mt-2" }, [
      _c("img", {
        attrs: {
          "data-testid": "icon-sequence-white-img",
          src: "/assets/images/app-icons/icon-workflow-white.svg",
          width: "24",
        },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "icon mt-2" }, [
      _c("img", {
        attrs: {
          "data-testid": "icon-bulk-messaging-grey",
          src: "/assets/images/app-icons/icon-bulk-messaging-grey.svg",
        },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "icon active mt-2" }, [
      _c("img", {
        attrs: {
          "data-testid": "icon-bulk-messaging-white-img",
          src: "/assets/images/app-icons/icon-bulk-messaging-white.svg",
        },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "icon mt-2" }, [
      _c("i", { staticClass: "material-icons md-24" }, [
        _vm._v("phone_iphone"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "icon active mt-2" }, [
      _c("i", { staticClass: "material-icons md-24" }, [
        _vm._v("phone_iphone"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-12" }, [
      _c(
        "a",
        {
          staticClass: "dropdown-item",
          attrs: {
            href: "https://aloware.com/partnerships/",
            "data-testid": "my-referral-link-a",
            target: "_blank",
          },
        },
        [
          _c("i", { staticClass: "fas fa-link" }),
          _vm._v(
            "\n                                My Referral Link\n                            "
          ),
        ]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-12" }, [
      _c(
        "a",
        {
          staticClass: "dropdown-item",
          attrs: {
            href: "https://meetings.hubspot.com/alwr/aloware-demo",
            "data-testid": "book-a-demo-a",
            target: "_blank",
          },
        },
        [
          _c("i", { staticClass: "material-icons" }, [
            _vm._v("perm_contact_calendar"),
          ]),
          _vm._v(
            "\n                                Book a Demo\n                            "
          ),
        ]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-12" }, [
      _c(
        "a",
        {
          staticClass: "dropdown-item",
          attrs: {
            href: "https://aloware.com/apps",
            "data-testid": "desktop-apps-a",
            target: "_blank",
          },
        },
        [
          _c("i", { staticClass: "material-icons" }, [_vm._v("desktop_mac")]),
          _vm._v(
            "\n                                Desktop Apps\n                            "
          ),
        ]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-12" }, [
      _c(
        "a",
        {
          staticClass: "dropdown-item",
          attrs: {
            href: "https://aloware.com/apps",
            "data-testid": "mobile-apps-a",
            target: "_blank",
          },
        },
        [
          _c("i", { staticClass: "material-icons" }, [_vm._v("devices")]),
          _vm._v(
            "\n                                Mobile Apps\n                            "
          ),
        ]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-12" }, [
      _c(
        "a",
        {
          staticClass: "dropdown-item",
          attrs: {
            href: "https://chrome.google.com/webstore/detail/aloware-click-to-call/kmchiimcbhaaadgpnelddelgiaeahobi?hl=en-US",
            "data-testid": "chrome-ext-a",
            target: "_blank",
          },
        },
        [
          _c("i", { staticClass: "fab fa-chrome" }),
          _vm._v(
            "\n                                Chrome Ext. (CTI)\n                            "
          ),
        ]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }