<template>
    <el-popover id="contacts-filters-popover"
                placement="left-start"
                width="500"
                trigger="manual"
                v-model="popover_visibility"
                @hide="resetForms">

        <div class="power-dialer-filter-close-button">
            <el-button
                @click="resetForms"
                type="text"
                class="p-0 text-black-50">
                <i class="fas fa-times"></i>
            </el-button>
        </div>

        <el-tabs
            v-model="tab_name"
            @tab-click="tabClicked"
            class="mt-2">
            <el-tab-pane label="Bulk"
                         name="bulk">
                <el-form ref="add_auto_dial_tasks"
                         label-width="100px"
                         label-position="top"
                         :model="auto_dial_tasks"
                         :rules="rules_auto_dial_tasks"
                         @submit.prevent.native="preventSubmit">
                    <el-form-item class="mb-3"
                                  prop="target_contacts">
                        <div scope="label"
                             class="form-label mb-1">
                            <label>Select contact group</label>
                        </div>
                        <contact-group-selector
                            v-model="auto_dial_tasks.target_contacts"
                            :allow_all_contacts="false"
                            :tag_category="TagCategories.CAT_CONTACTS"
                            @change="contactGroupChanged"
                            full_width>
                        </contact-group-selector>
                    </el-form-item>
                    <el-form-item class="mb-2">
                        <div scope="label"
                             class="form-label mb-1">
                            <label>Push contacts to</label>
                        </div>
                        <div class="row">
                            <div class="col">
                                <el-radio v-model="direction"
                                          :label="PowerDialer.DIRECTION_BOTTOM"
                                          :value="PowerDialer.DIRECTION_BOTTOM"
                                          class="w-full"
                                          border
                                          size="medium">
                                    Bottom
                                </el-radio>
                            </div>
                            <div class="col">
                                <el-radio v-model="direction"
                                          :label="PowerDialer.DIRECTION_TOP"
                                          :value="PowerDialer.DIRECTION_TOP"
                                          class="w-full"
                                          border
                                          size="medium">
                                    Top
                                </el-radio>
                            </div>
                        </div>
                    </el-form-item>
                    <el-form-item class="mb-2">
                        <div scope="label"
                             class="form-label">
                            <el-checkbox v-model="multiple_phone_numbers"
                                         label="Add multiple phone numbers?"
                                         class="w-full">
                            </el-checkbox>
                        </div>
                    </el-form-item>
                    <el-form-item class="mb-2">
                        <div scope="label"
                             class="form-label">
                            <el-checkbox v-model="prevent_duplicates"
                                         label="Prevent duplicate task creation"
                                         class="w-full">
                            </el-checkbox>
                        </div>
                    </el-form-item>
                    <el-form-item class="mb-2">
                        <div scope="label"
                             class="form-label">
                            <el-checkbox v-model="future_scheduled"
                                         label="Future scheduled task?"
                                         class="w-full">
                            </el-checkbox>
                        </div>
                    </el-form-item>

                    <el-form-item label="Scheduled time:"
                                  v-if="future_scheduled">
                        <v-date-picker v-model="future_scheduled_time"
                                       mode="dateTime"
                                       title-position="left"
                                       color="green"
                                       :min-date="new Date()"
                                       :masks="masks"
                                       :popover="popover_config">
                            <template v-slot="{ inputValue, inputEvents }">
                                <input
                                    class="px-2 py-1 border rounded"
                                    :value="inputValue"
                                    v-on="inputEvents"
                                />
                            </template>
                        </v-date-picker>
                    </el-form-item>

                    <el-form-item class="mb-2">
                        <div scope="label"
                             :class="`form-label ${!hasInternationalAccess ? 'd-inline-flex' : ''}`">
                            <el-checkbox v-model="allow_international_phone_numbers"
                                         :disabled="!hasInternationalAccess"
                                         label="Allow international phone numbers"
                                         class="w-full">
                            </el-checkbox>
                            <el-tooltip v-if="!hasInternationalAccess"
                                        class="item ml-2"
                                        effect="dark"
                                        content="This account has no international access"
                                        placement="top">
                                <i class="fas fa-info-circle"></i>
                            </el-tooltip>
                        </div>
                    </el-form-item>

                    <div class="row no-gutter centered-content">
                        <button class="btn btn-block greenish"
                                :loading="loading_add_auto_dial_tasks"
                                :disabled="loading_add_auto_dial_tasks || disable_add_auto_dial_tasks"
                                @click.prevent="addAutoDialTasks">
                            Add Tasks
                        </button>
                    </div>
                </el-form>
            </el-tab-pane>
            <el-tab-pane label="Filter"
                         name="filter">
                <el-form ref="contact_filter_auto_dial_tasks"
                         label-width="100px"
                         label-position="top"
                         :model="auto_dial_tasks"
                         :rules="rules_auto_dial_contact_filter_tasks"
                         @submit.prevent.native="preventSubmit">
                    <el-form-item class="mb-3"
                                  prop="filters">
                        <div scope="label"
                             class="form-label mb-1">
                            <label>Filters</label>
                        </div>
                        <contact-tags-filters-selector @resetFilters="resetFilters"/>
                    </el-form-item>
                    <el-form-item class="mb-2">
                        <div scope="label"
                             class="form-label mb-1">
                            <label>Push contacts to</label>
                        </div>
                        <div class="row">
                            <div class="col">
                                <el-radio v-model="direction"
                                          :label="PowerDialer.DIRECTION_BOTTOM"
                                          :value="PowerDialer.DIRECTION_BOTTOM"
                                          class="w-full"
                                          border
                                          size="medium">
                                    Bottom
                                </el-radio>
                            </div>
                            <div class="col">
                                <el-radio v-model="direction"
                                          :label="PowerDialer.DIRECTION_TOP"
                                          :value="PowerDialer.DIRECTION_TOP"
                                          class="w-full"
                                          border
                                          size="medium">
                                    Top
                                </el-radio>
                            </div>
                        </div>
                    </el-form-item>
                    <el-form-item class="mb-2">
                        <div scope="label"
                             class="form-label">
                            <el-checkbox v-model="multiple_phone_numbers"
                                         label="Add multiple phone numbers?"
                                         class="w-full">
                            </el-checkbox>
                        </div>
                    </el-form-item>
                    <el-form-item class="mb-2">
                        <div scope="label"
                             class="form-label">
                            <el-checkbox v-model="prevent_duplicates"
                                         label="Prevent duplicate task creation"
                                         class="w-full">
                            </el-checkbox>
                        </div>
                    </el-form-item>
                    <el-form-item class="mb-2">
                        <div scope="label"
                             class="form-label">
                            <el-checkbox v-model="future_scheduled"
                                         label="Future scheduled task?"
                                         class="w-full">
                            </el-checkbox>
                        </div>
                    </el-form-item>

                    <el-form-item label="Scheduled time:"
                                  v-if="future_scheduled">
                        <v-date-picker v-model="future_scheduled_time"
                                       mode="dateTime"
                                       title-position="left"
                                       color="green"
                                       :min-date="new Date()"
                                       :masks="masks"
                                       :popover="popover_config">
                            <template v-slot="{ inputValue, inputEvents }">
                                <input
                                    class="px-2 py-1 border rounded"
                                    :value="inputValue"
                                    v-on="inputEvents"
                                />
                            </template>
                        </v-date-picker>
                    </el-form-item>

                    <el-form-item class="mb-2">
                        <div scope="label"
                             :class="`form-label ${!hasInternationalAccess ? 'd-inline-flex' : ''}`">
                            <el-checkbox v-model="allow_international_phone_numbers"
                                         :disabled="!hasInternationalAccess"
                                         label="Allow international phone numbers"
                                         class="w-full">
                            </el-checkbox>
                            <el-tooltip v-if="!hasInternationalAccess"
                                        class="item ml-2"
                                        effect="dark"
                                        content="This account has no international access"
                                        placement="top">
                                <i class="fas fa-info-circle"></i>
                            </el-tooltip>
                        </div>
                    </el-form-item>

                    <div class="row no-gutter centered-content">
                        <button class="btn btn-block greenish"
                                :loading="loading_contact_filter_auto_dial_tasks"
                                :disabled="loading_contact_filter_auto_dial_tasks || disable_contact_filter_auto_dial_tasks"
                                @click.prevent="contactFilterAutoDialTasks">
                            Add Tasks
                        </button>
                    </div>
                </el-form>
            </el-tab-pane>
            <el-tab-pane label="Test"
                         name="test">
                <el-form ref="add_auto_dial_task"
                         label-width="100px"
                         label-position="top"
                         :model="auto_dial_task"
                         :rules="rules_auto_dial_task"
                         @submit.prevent.native="addAutoDialTask">
                    <el-form-item class="mb-3"
                                  prop="contact_id">
                        <div scope="label"
                             class="form-label mb-1">
                            <label>Select a contact</label>
                        </div>
                        <el-select placeholder="Type to search ..."
                                   v-model="auto_dial_task.contact_id"
                                   class="no-select w-full"
                                   :remote-method="fetchContact"
                                   :loading="loading_fetching_contact"
                                   @change="changeTargetContact"
                                   filterable
                                   remote
                                   clearable>
                            <el-option
                                v-for="contact in contactsAlphabeticalOrder"
                                class="p-0"
                                :key="contact.id"
                                :label="contact.name.length ? contact.name : 'No Name'"
                                :value="contact.id">
                                <div class="media">
                                    <div class="media-body">
                                        <label v-if="contact.name.length">{{ contact.name }}</label>
                                        <label v-else>No Name</label>
                                        <small>{{ contact.phone_number | fixPhone }}</small>
                                    </div>
                                </div>
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item class="mb-2">
                        <div scope="label"
                             class="form-label mb-1">
                            <label>Push contacts to</label>
                        </div>
                        <div class="row">
                            <div class="col">
                                <el-radio v-model="direction"
                                          :label="PowerDialer.DIRECTION_BOTTOM"
                                          :value="PowerDialer.DIRECTION_BOTTOM"
                                          class="w-full"
                                          border
                                          size="medium">
                                    Bottom
                                </el-radio>
                            </div>
                            <div class="col">
                                <el-radio v-model="direction"
                                          :label="PowerDialer.DIRECTION_TOP"
                                          :value="PowerDialer.DIRECTION_TOP"
                                          class="w-full"
                                          border
                                          size="medium">
                                    Top
                                </el-radio>
                            </div>
                        </div>
                    </el-form-item>
                    <el-form-item class="mb-2">
                        <div scope="label"
                             class="form-label">
                            <el-checkbox v-model="multiple_phone_numbers"
                                         label="Add multiple phone numbers?"
                                         class="w-full">
                            </el-checkbox>
                        </div>
                    </el-form-item>
                    <el-form-item class="mb-2">
                        <div scope="label"
                             class="form-label">
                            <el-checkbox v-model="prevent_duplicates"
                                         label="Prevent duplicate task creation"
                                         class="w-full">
                            </el-checkbox>
                        </div>
                    </el-form-item>
                    <el-form-item class="mb-2">
                        <div scope="label"
                             class="form-label">
                            <el-checkbox v-model="future_scheduled"
                                         label="Future scheduled task?"
                                         class="w-full">
                            </el-checkbox>
                        </div>
                    </el-form-item>

                    <el-form-item label="Scheduled time:"
                                  v-if="future_scheduled">
                        <v-date-picker v-model="future_scheduled_time"
                                       mode="dateTime"
                                       title-position="left"
                                       color="green"
                                       :min-date="new Date()"
                                       :masks="masks"
                                       :popover="popover_config">
                            <template v-slot="{ inputValue, inputEvents }">
                                <input
                                    class="px-2 py-1 border rounded"
                                    :value="inputValue"
                                    v-on="inputEvents"
                                />
                            </template>
                        </v-date-picker>
                    </el-form-item>

                    <el-form-item class="mb-2">
                        <div scope="label"
                             :class="`form-label ${!hasInternationalAccess ? 'd-inline-flex' : ''}`">
                            <el-checkbox v-model="allow_international_phone_numbers"
                                         :disabled="!hasInternationalAccess"
                                         label="Allow international phone numbers"
                                         class="w-full">
                            </el-checkbox>
                            <el-tooltip v-if="!hasInternationalAccess"
                                        class="item ml-2"
                                        effect="dark"
                                        content="This account has no international access"
                                        placement="top">
                                <i class="fas fa-info-circle"></i>
                            </el-tooltip>
                        </div>
                    </el-form-item>

                    <div class="row no-gutter centered-content">
                        <button class="btn btn-block greenish"
                                :loading="loading_add_auto_dial_task"
                                :disabled="loading_add_auto_dial_task || disable_auto_dial_task"
                                @click.prevent="addAutoDialTask">
                            Add Task
                        </button>
                    </div>
                </el-form>
            </el-tab-pane>
            <el-tab-pane label="Integrations"
                         name="integrations"
                         v-if="hubspotEnabled || zohoEnabled || pipedriveEnabled">
                <!-- HubSpot -->
                <el-form ref="add_hubspot_list"
                         label-width="100px"
                         label-position="top"
                         :model="hubspot_list"
                         :rules="rules_hubspot_list"
                         v-if="hubspotEnabled"
                         @submit.prevent.native="addHubSpotList">
                    <el-form-item prop="list_id"
                                  label="Select a HubSpot list"
                                  class="mb-3">
                        <hubspot-list-selector v-model="hubspot_list.list_id"
                                               :value="hubspot_list.list_id"
                                               @change="hubspotListChanged">
                        </hubspot-list-selector>
                    </el-form-item>
                    <el-form-item class="mb-2">
                        <div scope="label"
                             class="form-label mb-1">
                            <label>Push contacts to</label>
                        </div>
                        <div class="row">
                            <div class="col">
                                <el-radio v-model="direction"
                                          :label="PowerDialer.DIRECTION_BOTTOM"
                                          :value="PowerDialer.DIRECTION_BOTTOM"
                                          class="w-full"
                                          border
                                          size="medium">
                                    Bottom
                                </el-radio>
                            </div>
                            <div class="col">
                                <el-radio v-model="direction"
                                          :label="PowerDialer.DIRECTION_TOP"
                                          :value="PowerDialer.DIRECTION_TOP"
                                          class="w-full"
                                          border
                                          size="medium">
                                    Top
                                </el-radio>
                            </div>
                        </div>
                    </el-form-item>
                    <el-form-item class="mb-2">
                        <div scope="label"
                             class="form-label">
                            <el-checkbox v-model="multiple_phone_numbers"
                                         label="Add multiple phone numbers?"
                                         class="w-full">
                            </el-checkbox>
                        </div>
                    </el-form-item>
                    <el-form-item class="mb-2">
                        <div scope="label"
                             class="form-label">
                            <el-checkbox v-model="prevent_duplicates"
                                         label="Prevent duplicate task creation"
                                         class="w-full">
                            </el-checkbox>
                        </div>
                    </el-form-item>
                    <el-form-item class="mb-2">
                        <div scope="label"
                             class="form-label">
                            <el-checkbox v-model="own_contacts_only"
                                         label="Add your own contacts?"
                                         class="w-full">
                            </el-checkbox>
                        </div>
                    </el-form-item>
                    <el-form-item class="mb-2">
                        <div scope="label"
                             class="form-label">
                            <el-checkbox v-model="future_scheduled"
                                         label="Future scheduled task?"
                                         class="w-full">
                            </el-checkbox>
                        </div>
                    </el-form-item>

                    <el-form-item label="Scheduled time:"
                                  v-if="future_scheduled">
                        <v-date-picker v-model="future_scheduled_time"
                                       mode="dateTime"
                                       title-position="left"
                                       color="green"
                                       :min-date="new Date()"
                                       :masks="masks"
                                       :popover="popover_config">
                            <template v-slot="{ inputValue, inputEvents }">
                                <input
                                    class="px-2 py-1 border rounded"
                                    :value="inputValue"
                                    v-on="inputEvents"
                                />
                            </template>
                        </v-date-picker>
                    </el-form-item>

                    <el-form-item class="mb-2">
                        <div scope="label"
                             :class="`form-label ${!hasInternationalAccess ? 'd-inline-flex' : ''}`">
                            <el-checkbox v-model="allow_international_phone_numbers"
                                         :disabled="!hasInternationalAccess"
                                         label="Allow international phone numbers"
                                         class="w-full">
                            </el-checkbox>
                            <el-tooltip v-if="!hasInternationalAccess"
                                        class="item ml-2"
                                        effect="dark"
                                        content="This account has no international access"
                                        placement="top">
                                <i class="fas fa-info-circle"></i>
                            </el-tooltip>
                        </div>
                    </el-form-item>

                    <div class="row no-gutter centered-content">
                        <button class="btn btn-block greenish"
                                :loading="loading_add_hubspot_list"
                                :disabled="loading_add_hubspot_list || disable_add_list"
                                @click.prevent="addHubSpotList">
                            <i class="material-icons loader"
                               v-if="loading_add_hubspot_list">&#xE863;</i>
                            Add Contacts
                        </button>
                    </div>
                </el-form>

                <!-- Zoho -->
                <el-form ref="add_zoho_view"
                         label-width="100px"
                         label-position="top"
                         :model="zoho_view"
                         :rules="rules_zoho_view"
                         v-if="zohoEnabled"
                         @submit.prevent.native="addZohoView">
                    <el-form-item prop="view_id"
                                  label="Select a Zoho custom view"
                                  class="mb-3">
                        <zoho-view-selector v-model="zoho_view.view_id"
                                            :value="zoho_view.view_id"
                                            @change="zohoViewChanged">
                        </zoho-view-selector>
                    </el-form-item>
                    <el-form-item class="mb-2">
                        <div scope="label"
                             class="form-label mb-1">
                            <label>Push contacts to</label>
                        </div>
                        <div class="row">
                            <div class="col">
                                <el-radio v-model="direction"
                                          :label="PowerDialer.DIRECTION_BOTTOM"
                                          :value="PowerDialer.DIRECTION_BOTTOM"
                                          class="w-full"
                                          border
                                          size="medium">
                                    Bottom
                                </el-radio>
                            </div>
                            <div class="col">
                                <el-radio v-model="direction"
                                          :label="PowerDialer.DIRECTION_TOP"
                                          :value="PowerDialer.DIRECTION_TOP"
                                          class="w-full"
                                          border
                                          size="medium">
                                    Top
                                </el-radio>
                            </div>
                        </div>
                    </el-form-item>
                    <el-form-item class="mb-2">
                        <div scope="label"
                             class="form-label">
                            <el-checkbox v-model="multiple_phone_numbers"
                                         label="Add multiple phone numbers?"
                                         class="w-full">
                            </el-checkbox>
                        </div>
                    </el-form-item>
                    <el-form-item class="mb-2">
                        <div scope="label"
                             class="form-label">
                            <el-checkbox v-model="prevent_duplicates"
                                         label="Prevent duplicate task creation"
                                         class="w-full">
                            </el-checkbox>
                        </div>
                    </el-form-item>
                    <el-form-item class="mb-2">
                        <div scope="label"
                             class="form-label">
                            <el-checkbox v-model="own_contacts_only"
                                         label="Add your own contacts?"
                                         class="w-full">
                            </el-checkbox>
                        </div>
                    </el-form-item>
                    <el-form-item class="mb-2">
                        <div scope="label"
                             class="form-label">
                            <el-checkbox v-model="future_scheduled"
                                         label="Future scheduled task?"
                                         class="w-full">
                            </el-checkbox>
                        </div>
                    </el-form-item>

                    <el-form-item label="Scheduled time:"
                                  v-if="future_scheduled">
                        <v-date-picker v-model="future_scheduled_time"
                                       mode="dateTime"
                                       title-position="left"
                                       color="green"
                                       :min-date="new Date()"
                                       :masks="masks"
                                       :popover="popover_config">
                            <template v-slot="{ inputValue, inputEvents }">
                                <input
                                    class="px-2 py-1 border rounded"
                                    :value="inputValue"
                                    v-on="inputEvents"
                                />
                            </template>
                        </v-date-picker>
                    </el-form-item>

                    <div class="row no-gutter centered-content">
                        <button class="btn btn-block greenish"
                                :loading="loading_add_zoho_view"
                                :disabled="loading_add_zoho_view || disable_add_view"
                                @click.prevent="addZohoView">
                            <i class="material-icons loader"
                               v-if="loading_add_zoho_view">&#xE863;</i>
                            Add Contacts
                        </button>
                    </div>
                </el-form>

                <!-- Pipedrive -->
                <el-form ref="add_pipedrive_filter"
                         label-width="100px"
                         label-position="top"
                         :model="pipedrive_filter"
                         :rules="rules_pipedrive_filter"
                         v-if="pipedriveEnabled"
                         @submit.prevent.native="addPipedriveFilter">
                  <el-form-item prop="filter_id"
                                label="Select a Pipedrive custom filter"
                                class="mb-3">
                    <pipedrive-filter-selector v-model="pipedrive_filter.filter_id"
                                               :value="pipedrive_filter.filter_id"
                                               @selectedFilter="pipedriveFilterChanged">
                    </pipedrive-filter-selector>
                  </el-form-item>
                  <el-form-item class="mb-2">
                    <div scope="label"
                         class="form-label mb-1">
                      <label>Push contacts to</label>
                    </div>
                    <div class="row">
                      <div class="col">
                        <el-radio v-model="direction"
                                  :label="PowerDialer.DIRECTION_BOTTOM"
                                  :value="PowerDialer.DIRECTION_BOTTOM"
                                  class="w-full"
                                  border
                                  size="medium">
                          Bottom
                        </el-radio>
                      </div>
                      <div class="col">
                        <el-radio v-model="direction"
                                  :label="PowerDialer.DIRECTION_TOP"
                                  :value="PowerDialer.DIRECTION_TOP"
                                  class="w-full"
                                  border
                                  size="medium">
                          Top
                        </el-radio>
                      </div>
                    </div>
                  </el-form-item>
                  <el-form-item class="mb-2">
                    <div scope="label"
                         class="form-label">
                      <el-checkbox v-model="multiple_phone_numbers"
                                   label="Add multiple phone numbers?"
                                   class="w-full">
                      </el-checkbox>
                    </div>
                  </el-form-item>
                  <el-form-item class="mb-2">
                    <div scope="label"
                         class="form-label">
                      <el-checkbox v-model="prevent_duplicates"
                                   label="Prevent duplicate task creation"
                                   class="w-full">
                      </el-checkbox>
                    </div>
                  </el-form-item>
                  <el-form-item class="mb-2">
                    <div scope="label"
                         class="form-label">
                      <el-checkbox v-model="own_contacts_only"
                                   label="Add your own contacts?"
                                   class="w-full">
                      </el-checkbox>
                    </div>
                  </el-form-item>
                  <el-form-item class="mb-2">
                    <div scope="label"
                         class="form-label">
                      <el-checkbox v-model="future_scheduled"
                                   label="Future scheduled task?"
                                   class="w-full">
                      </el-checkbox>
                    </div>
                  </el-form-item>

                  <el-form-item label="Scheduled time:"
                                v-if="future_scheduled">
                    <v-date-picker v-model="future_scheduled_time"
                                   mode="dateTime"
                                   title-position="left"
                                   color="green"
                                   :min-date="new Date()"
                                   :masks="masks"
                                   :popover="popover_config">
                      <template v-slot="{ inputValue, inputEvents }">
                        <input
                            class="px-2 py-1 border rounded"
                            :value="inputValue"
                            v-on="inputEvents"
                        />
                      </template>
                    </v-date-picker>
                  </el-form-item>

                  <el-form-item class="mb-2">
                    <div scope="label"
                         :class="`form-label ${!hasInternationalAccess ? 'd-inline-flex' : ''}`">
                      <el-checkbox v-model="allow_international_phone_numbers"
                                   :disabled="!hasInternationalAccess"
                                   label="Allow international phone numbers"
                                   class="w-full">
                      </el-checkbox>
                      <el-tooltip v-if="!hasInternationalAccess"
                                  class="item ml-2"
                                  effect="dark"
                                  content="This account has no international access"
                                  placement="top">
                        <i class="fas fa-info-circle"></i>
                      </el-tooltip>
                    </div>
                  </el-form-item>

                  <div class="row no-gutter centered-content">
                    <button class="btn btn-block greenish"
                            :loading="loading_add_pipedrive_filter"
                            :disabled="loading_add_pipedrive_filter || disable_add_view"
                            @click.prevent="addPipedriveFilter">
                      <i class="material-icons loader"
                         v-if="loading_add_pipedrive_filter">&#xE863;</i>
                      Add Contacts
                    </button>
                  </div>
                </el-form>

            </el-tab-pane>
        </el-tabs>

        <el-button icon="fa fa-plus"
                   class="mb-2"
                   type="success"
                   slot="reference"
                   :disabled="disabled"
                   :size="small ? 'small' : ''"
                   @click="toggleAddTaskForm">
            <span class="ml-2">Add Tasks</span>
        </el-button>
    </el-popover>
</template>

<script>
import auth from '../auth'
import {
    acl_mixin,
    form_validation_mixin
} from '../mixins'
import {mixin as click_away} from 'vue-clickaway'
import {mapActions, mapGetters, mapState} from 'vuex'
import * as PowerDialer from '../constants/power-dialer'
import * as TagCategories from '../constants/tag-categories'
import * as International from "../constants/international-tier";

export default {
    mixins: [
        acl_mixin,
        form_validation_mixin,
        click_away
    ],

    props: {
        small: {
            default: false,
            type: Boolean,
            required: false
        },

        disabled: {
            default: false,
            type: Boolean,
            required: false
        }
    },

    data() {
        return {
            auth: auth,
            tab_name: 'bulk',
            hubspot_list: {
                list_id: null
            },
            rules_hubspot_list: {
                list_id: [
                    {
                        required: true,
                        message: 'Please select a HubSpot list',
                        trigger: 'change'
                    }
                ]
            },
            loading_add_hubspot_list: false,
            disable_add_list: false,
            zoho_view: {
                view_id: null
            },
            rules_zoho_view: {
                view_id: [
                    {
                        required: true,
                        message: 'Please select a Zoho custom view',
                        trigger: 'change'
                    }
                ]
            },
            loading_add_zoho_view: false,
            disable_add_view: false,
            pipedrive_filter: {
              filter_id: null
            },
            rules_pipedrive_filter: {
              filter_id: [
                {
                  required: true,
                  message: 'Please select a Pipedrive custom filter',
                  trigger: 'change'
                }
              ]
            },
            loading_add_pipedrive_filter: false,
            auto_dial_task: {
                contact_id: null,
            },
            auto_dial_tasks: {
                target_contacts: null,
                filters: null
            },
            rules_auto_dial_tasks: {
                target_contacts: [
                    {
                        required: true,
                        message: 'Please select a group of contacts',
                        trigger: 'change'
                    }
                ]
            },
            rules_auto_dial_contact_filter_tasks: {
                filters: [
                    {
                        required: true,
                        message: 'Please select a group of contacts',
                        trigger: 'change'
                    }
                ]
            },
            loading_add_auto_dial_tasks: false,
            disable_add_auto_dial_tasks: true,
            rules_auto_dial_task: {
                contact_id: [
                    {
                        required: true,
                        message: 'Please select a contact',
                        trigger: 'change'
                    }
                ]
            },
            contacts: [],
            CancelToken: null,
            source: null,
            loading_fetching_contact: false,
            loading_add_auto_dial_task: false,
            disable_auto_dial_task: true,
            popover_visibility: false,
            filter_tags: null,
            is_reset_tags: false,
            is_reset_filters: false,
            is_visible_contact_filter: false,
            loading_contact_filter_auto_dial_tasks: false,
            disable_contact_filter_auto_dial_tasks: true,
            multiple_phone_numbers: false,
            prevent_duplicates: true,
            future_scheduled: false,
            allow_international_phone_numbers: false,
            future_scheduled_time: new Date(),
            masks: {
                input: 'MM/DD/YYYY HH:mm',
            },
            popover_config: {
                placement: 'right'
            },
            own_contacts_only: true,
            direction: PowerDialer.DIRECTION_BOTTOM,
            is_filters_reset: false,
            PowerDialer,
            TagCategories,
        }
    },

    computed: {
        ...mapState(['campaigns', 'filter_options']),
        ...mapState('cache', ['current_company']),

        ...mapGetters({
            filter: 'getFilter'
        }),

        hasInternationalAccess() {
            if (this.current_company && this.current_company.international_tier !== International.TIER_1) {
                return true
            }

            return false
        },

        hubspotEnabled() {
          return !!this.current_company?.hubspot_integration_enabled
        },

        zohoEnabled() {
          return !!this.current_company?.zoho_integration_enabled
        },

        pipedriveEnabled() {
          return !!this.current_company?.pipedrive_integration_enabled
        },

        campaignsAlphabeticalOrder() {
            if (this.campaigns) {
                let campaigns = _.clone(this.campaigns)
                return campaigns.sort((a, b) => {
                    let textA = a.name.toUpperCase()
                    let textB = b.name.toUpperCase()
                    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0
                })
            }

            return []
        },

        contactsAlphabeticalOrder() {
            if (this.contacts.length) {
                let contacts = _.clone(this.contacts)
                return contacts.sort((a, b) => {
                    let textA = a.name.toUpperCase()
                    let textB = b.name.toUpperCase()
                    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0
                })
            }

            return []
        }
    },

    created() {
        this.CancelToken = axios.CancelToken
        this.source = this.CancelToken.source()
        this.initializeFilterOptions()
    },

    methods: {
        toggleAddTaskForm() {
            this.tab_name = 'bulk'
            this.popover_visibility = !this.popover_visibility

            if (!this.popover_visibility) {
                this.hideAddTaskPopover()
            }
        },
        initializeFilterOptions() {
            VueEvent.fire('reset_events')
            this.resetContactFilters()
            this.filter_options.filters_only = true
            this.filter_options.no_export = true
            this.filter_options.no_save_as_tag = true
        },

        contactGroupChanged(val) {
            this.auto_dial_tasks.target_contacts = val
            this.disable_add_auto_dial_tasks = !this.validateForm('add_auto_dial_tasks')
        },

        // executes when there are contact filter changes
        contactFiltersChanged() {
            this.auto_dial_tasks.filters = _.clone(this.filter)
            this.disable_contact_filter_auto_dial_tasks = !this.validateForm('contact_filter_auto_dial_tasks')
        },

        resetAddAutoDialTasks() {
            this.auto_dial_tasks.target_contacts = null
            this.resetForm('add_auto_dial_tasks')
            this.loading_add_auto_dial_tasks = false
            this.disable_add_auto_dial_tasks = true
        },

        resetContactFiltersSelector() {
            this.auto_dial_tasks.filters = null
            this.contacts_count = 0
            this.initializeFilterOptions()
            this.loading_contact_filter_auto_dial_tasks = false
            this.disable_contact_filter_auto_dial_tasks = true
        },

        fetchContact(query) {
            if (query.length >= 3) {
                this.source.cancel('fetchContact canceled by the user.')
                this.source = this.CancelToken.source()
                this.loading_fetching_contact = true
                const params = {
                    search_fields: ['name', 'phone_number', 'email'],
                    search_text: query
                }
                axios.get('/api/v1/contact', {
                    params: params,
                    cancelToken: this.source.token
                }).then((res) => {
                    this.loading_fetching_contact = false
                    this.contacts = res.data.data
                }).catch((err) => {
                    if (axios.isCancel(err)) {
                        console.log('Request canceled', err.message)
                    } else {
                        this.$root.handleErrors(err.response)
                        this.loading_fetching_contact = false
                    }
                })
            } else {
                this.contacts = []
            }
        },

        changeTargetContact() {
            this.preValidateForm('add_auto_dial_task')
            this.disable_auto_dial_task = !this.validateForm('add_auto_dial_task')
        },

        convertTargetContactsToAcceptableParams() {
            if (this.auto_dial_tasks.target_contacts.includes('campaign:')) {
                return {
                    target_campaign_id: this.auto_dial_tasks.target_contacts.replace('campaign:', '')
                }
            }

            if (this.auto_dial_tasks.target_contacts.includes('tag:')) {
                return {
                    target_tag_id: this.auto_dial_tasks.target_contacts.replace('tag:', '')
                }
            }

            if (this.auto_dial_tasks.target_contacts.includes('disposition_status:')) {
                return {
                    target_disposition_status_id: this.auto_dial_tasks.target_contacts.replace('disposition_status:', ''),
                }
            }

            return {}
        },

        resetAddHubspotList() {
            if (this.hubspotEnabled) {
                this.hubspot_list.list_id = null
                this.resetForm('add_hubspot_list')
                this.disable_add_list = true
            }
        },

        resetAddZohoView() {
            if (this.zohoEnabled) {
                this.zoho_view.view_id = null
                this.resetForm('add_zoho_view')
                this.disable_add_view = true
            }
        },

        resetAddPipedriveFilter() {
            if (this.pipedriveEnabled) {
              this.pipedrive_filter.filter_id = null
              this.resetForm('add_pipedrive_filter')
              this.disable_add_view = true
            }
        },

        resetAddAutoDialTask() {
            this.auto_dial_task.contact_id = null
            this.resetForm('add_auto_dial_task')
            this.disable_auto_dial_task = true
        },

        resetForms() {
            this.hideAddTaskPopover()
            this.resetAddAutoDialTask()
            this.resetAddAutoDialTasks()
            this.resetAddHubspotList()
            this.resetAddZohoView()
            this.resetAddPipedriveFilter()
        },

        cleanUpFilter() {
            for (let index in this.auto_dial_tasks.filters) {
                // lodash's isEmpty will check if an object, collection, map or set is empty
                // not on the property it self.
                // https://lodash.com/docs/4.17.15#isEmpty
                // in this case we will only remove the filter keys that is null or has undefined value
                if ([undefined, null].includes(this.auto_dial_tasks.filters[index])) {
                    delete this.auto_dial_tasks.filters[index]
                }
            }
        },

        contactFilterAutoDialTasks() {
            if (this.validateForm('contact_filter_auto_dial_tasks') === true) {
                this.loading_contact_filter_auto_dial_tasks = true
                this.cleanUpFilter()
                let params = {filters: this.auto_dial_tasks.filters}
                params.direction = this.direction
                params.multiple_phone_numbers = this.multiple_phone_numbers
                params.prevent_duplicates = this.prevent_duplicates
                params.allow_international_phone_numbers = this.allow_international_phone_numbers
                if (this.future_scheduled) {
                    params.future_scheduled_time = this.future_scheduled_time
                }
                axios.post('/api/v1/auto-dialer/bulk-add', params).then((res) => {
                    this.resetForms()
                    this.resetContactFiltersSelector()
                    this.$notify({
                        offset: 175,
                        title: 'PowerDialer',
                        message: res.data.message,
                        type: 'success',
                        showClose: true,
                    })
                    this.$emit('success', true)
                }).catch((err) => {
                    this.resetContactFiltersSelector()
                    this.$root.handleErrors(err.response)
                })
            } else {
                return false
            }
        },

        addAutoDialTasks() {
            if (this.validateForm('add_auto_dial_tasks') === true) {
                this.loading_add_auto_dial_tasks = true
                let params = this.convertTargetContactsToAcceptableParams()
                params.direction = this.direction
                params.multiple_phone_numbers = this.multiple_phone_numbers
                params.prevent_duplicates = this.prevent_duplicates
                params.allow_international_phone_numbers = this.allow_international_phone_numbers
                if (this.future_scheduled) {
                    params.future_scheduled_time = this.future_scheduled_time
                }

                axios.post('/api/v1/auto-dialer/bulk-add', params).then((res) => {
                    this.resetForms()
                    this.$notify({
                        offset: 175,
                        title: 'PowerDialer',
                        message: res.data.message,
                        type: 'success',
                        showClose: true,
                    })
                    this.$emit('success', true)
                }).catch((err) => {
                    this.resetAddAutoDialTasks()
                    this.$root.handleErrors(err.response)
                })
            } else {
                return false
            }
        },

        addHubSpotList() {
            if (this.validateForm('add_hubspot_list') === true) {
                this.loading_add_hubspot_list = true
                let params = {}
                params.direction = this.direction
                params.multiple_phone_numbers = this.multiple_phone_numbers
                params.prevent_duplicates = this.prevent_duplicates
                params.own_contacts_only = this.own_contacts_only
                params.allow_international_phone_numbers = this.allow_international_phone_numbers
                if (this.future_scheduled) {
                    params.future_scheduled_time = this.future_scheduled_time
                }
                axios.post(`/api/v1/auto-dialer/add-hubspot-list/${this.hubspot_list.list_id}`, params).then(() => {
                    this.loading_add_hubspot_list = false
                    this.resetForms()
                    this.$notify({
                        offset: 175,
                        title: 'PowerDialer',
                        message: 'HubSpot contact list is now being added to your PowerDialer.',
                        type: 'success',
                        showClose: true,
                    })
                    this.$emit('success', true)
                }).catch((err) => {
                    this.loading_add_hubspot_list = false
                    this.resetAddHubspotList()

                    const res = err.response
                    this.$notify({
                      offset: 175,
                      title: 'PowerDialer',
                      message: res.data?.message,
                      type: 'error',
                      showClose: true,
                    })

                    console.log(err)
                })
            } else {
                return false
            }
        },

        addZohoView() {
            if (this.validateForm('add_zoho_view') === true) {
                this.loading_add_zoho_view = true
                let params = {}
                params.direction = this.direction
                params.multiple_phone_numbers = this.multiple_phone_numbers
                params.prevent_duplicates = this.prevent_duplicates
                params.own_contacts_only = this.own_contacts_only
                if (this.future_scheduled) {
                    params.future_scheduled_time = this.future_scheduled_time
                }
                axios.post(`/api/v1/auto-dialer/add-zoho-view/${this.zoho_view.view_id}`, params).then(() => {
                    this.loading_add_zoho_view = false
                    this.resetForms()
                    this.$notify({
                        offset: 175,
                        title: 'PowerDialer',
                        message: 'Zoho custom view contacts are now being added to your PowerDialer.',
                        type: 'success',
                        showClose: true,
                    })
                    this.$emit('success', true)
                }).catch((err) => {
                    this.loading_add_zoho_view = false
                    this.resetAddZohoView()

                    const res = err.response
                    this.$notify({
                      offset: 175,
                      title: 'PowerDialer',
                      message: res.data?.message,
                      type: 'error',
                      showClose: true,
                    })

                    console.log(err)
                })
            } else {
                return false
            }
        },

        addPipedriveFilter() {
          if (this.validateForm('add_pipedrive_filter') === true) {
            this.loading_add_pipedrive_filter = true
            let params = {}
            params.direction = this.direction
            params.multiple_phone_numbers = this.multiple_phone_numbers
            params.prevent_duplicates = this.prevent_duplicates
            params.own_contacts_only = this.own_contacts_only
            params.allow_international_phone_numbers = this.allow_international_phone_numbers
            if (this.future_scheduled) {
              params.future_scheduled_time = this.future_scheduled_time
            }
            axios.post(`/api/v1/auto-dialer/add-pipedrive-filter/${this.pipedrive_filter.filter_id}`, params).then(() => {
              this.loading_add_pipedrive_filter = false
              this.resetForms()
              this.$notify({
                offset: 175,
                title: 'PowerDialer',
                message: 'Pipedrive custom filter contacts are now being added to your PowerDialer.',
                type: 'success',
                showClose: true,
              })
              this.$emit('success', true)
            }).catch((err) => {
              this.loading_add_pipedrive_filter = false
              this.resetAddPipedriveFilter()

              const res = err.response
              this.$notify({
                offset: 175,
                title: 'PowerDialer',
                message: res.data?.message,
                type: 'error',
                showClose: true,
              })

              console.log(err)
            })
          } else {
            return false
          }
        },


      addAutoDialTask() {
            if (this.validateForm('add_auto_dial_task') === true) {
                this.loading_add_auto_dial_task = true
                let params = {}
                params.direction = this.direction
                params.multiple_phone_numbers = this.multiple_phone_numbers
                params.prevent_duplicates = this.prevent_duplicates
                params.allow_international_phone_numbers = this.allow_international_phone_numbers
                if (this.future_scheduled) {
                    params.future_scheduled_time = this.future_scheduled_time
                }
                axios.post(`/api/v1/auto-dialer/add/${this.auto_dial_task.contact_id}`, params).then(() => {
                    this.loading_add_auto_dial_task = false
                    this.resetForms()
                    this.$notify({
                        offset: 175,
                        title: 'PowerDialer',
                        message: 'Task has been created successfully.',
                        type: 'success',
                        showClose: true,
                    })
                    this.$emit('success', false)
                }).catch((err) => {
                    this.loading_add_auto_dial_task = false
                    this.resetAddAutoDialTask()

                    let response = err.response
                    if (response.status === 422) {
                        this.$notify({
                            offset: 175,
                            title: 'PowerDialer',
                            message: response.data.message,
                            type: 'error',
                            showClose: true,
                        })
                    }
                })
            } else {
                return false
            }
        },

        hideAddTaskPopover() {
            if (!this.is_visible_contact_filter && this.popover_visibility) {
                this.popover_visibility = false
                this.direction = PowerDialer.DIRECTION_BOTTOM
                this.multiple_phone_numbers = false
                this.prevent_duplicates = true
                this.own_contacts_only = true
                this.future_scheduled = false
                this.future_scheduled_time = new Date()
            }
        },

        preventSubmit() {
            return false
        },

        hubspotListChanged(list_id) {
            this.hubspot_list.list_id = list_id
            this.disable_add_list = !this.validateForm('add_hubspot_list')
        },

        zohoViewChanged(view_id) {
            this.zoho_view.view_id = view_id
            this.disable_add_view = !this.validateForm('add_zoho_view')
        },

        pipedriveFilterChanged(filter) {
          this.pipedrive_filter.filter_id = filter.id
          this.disable_add_view = !this.validateForm('add_pipedrive_filter')
        },

        tabClicked(tab) {
            if (tab.name === 'integrations' && this.hubspotEnabled) {
                this.hubspot_list.list_id = null
            }

            if (tab.name === 'integrations' && this.zohoEnabled) {
                this.zoho_view.view_id = null
            }

            if (tab.name === 'integrations' && this.pipedriveEnabled) {
                this.pipedrive_filter.filter_id = null
            }
        },

        resetFilters() {
            this.is_filters_reset = true
            this.disable_contact_filter_auto_dial_tasks = true
        },

        ...mapActions(['resetContactFilters'])
    },

    watch: {
        popover_visibility(val) {
            this.allow_international_phone_numbers = false
        },

        filter: {
            handler() {
                this.contactFiltersChanged()

                this.is_filters_reset = false
            },
            deep: true
        },

        'filter_options.is_filter_visible': function (newValue) {
            setTimeout(() => {
                this.is_visible_contact_filter = newValue
                if (!newValue) {
                    this.popover_visibility = true
                }
            }, 100)
        }
    },
}
</script>
