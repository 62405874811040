<template>
    <div>
        <div class="d-flex align-items-baseline">
            <div class="flex-shrink-0">
                Impersonate contact
            </div>
            <el-select
                v-model="selected_contact"
                class="w-full flex-grow-1"
                :placeholder="label"
                :remote-method="fetchContacts"
                :loading="loading"
                filterable
                remote
                clearable
                @change="selectContacts"
                @clear="blur"
                @blur="blur">
                <el-option
                    v-for="contact in contacts"
                    class="p-0"
                    :key="contact.id"
                    :label="contact.name || contact.phone_number"
                    :value="contact.id">
                    <div class="media">
                        <div class="media-body">
                            <label>{{ contact.name }}</label>
                            <small>{{ contact.phone_number | fixPhone }}</small>
                        </div>
                    </div>
                </el-option>
            </el-select>
        </div>
    </div>
</template>

<script>
    import { acl_mixin, validator_mixin } from '../../mixins'

    export default {
        mixins: [
            acl_mixin,
            validator_mixin
        ],
        props: [
            'label',
            'contact_selected'
        ],
        data() {
            return {
                loading: false,
                contacts: [],
                selected_contact: null,
                source: null
            }
        },
        mounted() {
            // If there is a contact id pre selected, fetch it and set
            if (this.contact_selected) {
                this.$emit('select-contacts', this.contact_selected)
            }
        },
        created() {
            this.CancelToken = axios.CancelToken
            this.source = this.CancelToken.source()
        },
        methods: {
            /**
             * Fetches contacts
             *
             * @param {string} query
             *
             * @return {void}
             */
            fetchContacts(query) {
                this.contacts = []
                if (query.length >= 3) {
                    this.source.cancel('fetchContacts canceled by the user.')
                    this.source = this.CancelToken.source()
                    this.loading = true
                    const params = {
                        search_fields: ['name', 'email', 'phone_number'],
                        search_text: query
                    }
                    axios
                        .get('/api/v1/contact', {
                            params: params,
                            cancelToken: this.source.token
                        })
                        .then(res => {
                            this.loading = false
                            this.contacts = res.data.data
                        })
                        .catch(err => {
                            if (!axios.isCancel(err)) {
                                this.$root.handleErrors(err.response)
                                this.loading = false
                            }
                        })
                }
            },

            /**
             * Select a contact to impersonate in the chatbox.
             *
             * @param {integer} selected_contact_id
             *
             * @return {void}
             */
            selectContacts(selected_contact_id) {
                this.$emit('select-contacts', _.find(this.contacts, {
                    id: selected_contact_id
                }))
            },

            /**
             * Reset impersonated contact.
             *
             * @return {void}
             */
            reset() {
                this.selected_contact = null
            },

            /**
             * Listens blur event on select.
             *
             * @return {void}
             */
            blur() {
                this.$emit('blur')
            }
        },
        watch: {
            contact_selected(value) {
                this.contacts = [value]
                this.selected_contact = value.id
            }
        }
    }

</script>
