var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    _vm._l(_vm.categorized_links, function (links, category) {
      return _c("div", { key: category }, [
        _c("div", { staticClass: "row p-2" }, [
          _c(
            "div",
            { staticClass: "col-12 d-flex flex-row justify-content-between" },
            [
              _c("h5", [_vm._v(_vm._s(_vm._f("ucwords")(category)))]),
              _vm._v(" "),
              category.includes("CRM")
                ? _c(
                    "el-popover",
                    {
                      ref: "aloInfo",
                      refInFor: true,
                      attrs: {
                        placement: "bottom-end",
                        width: "550",
                        trigger: "hover",
                        "popper-class": "alo-popover",
                      },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "alo-default-app-selector-dialog" },
                        [
                          _vm._v(
                            "\n                        We only support one CRM connected at a time. If you have a need for multiple CRMs, please contact us.\n                    "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass:
                            "btn btn-xs btn-outline btn-default text-white pl-2 pr-2 alo-talk-info-button",
                          attrs: { slot: "reference" },
                          slot: "reference",
                        },
                        [
                          _c("span", [
                            _c("img", {
                              attrs: {
                                src: "/assets/images/app-icons/info-icon-outline.png",
                                alt: "info-icon",
                              },
                            }),
                          ]),
                        ]
                      ),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("hr", { staticClass: "no-top-margin" }),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "row p-2" },
          [
            !_vm.isCrmIntegrationsEnabled
              ? _c(
                  "div",
                  {
                    staticClass:
                      "w-100 d-flex justify-content-center align-items-cente my-2",
                  },
                  [
                    _c("i", {
                      staticClass:
                        "fa fa-exclamation text-amber-10 text-2x mr-2",
                    }),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        staticClass: "vertical-center text-lg font-weight-bold",
                      },
                      [
                        _vm._v(
                          "\n                    " +
                            _vm._s(
                              _vm.integrationsNotAllowedMessage(
                                _vm.current_company.created_at
                              )
                            ) +
                            "\n                "
                        ),
                      ]
                    ),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm._l(links, function (link, index) {
              return link.show
                ? _c(
                    "div",
                    {
                      key: index + "-" + link.name,
                      staticClass: "col-xl-4 col-lg-6 mb-2",
                    },
                    [
                      !link.disabled &&
                      !_vm.link_without_settings.includes(link.name)
                        ? _c(
                            "el-card",
                            {
                              directives: [
                                {
                                  name: "loading",
                                  rawName: "v-loading",
                                  value: link.loading,
                                  expression: "link.loading",
                                },
                              ],
                              staticClass: "integration-box",
                              class: { "border-0": !link.name || !link.image },
                              attrs: {
                                shadow:
                                  !link.name || !link.image
                                    ? "never"
                                    : "always",
                              },
                            },
                            [
                              link.name && link.image
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "row d-flex align-items-center",
                                    },
                                    [
                                      link.is_beta
                                        ? _c(
                                            "el-tooltip",
                                            {
                                              staticClass: "item",
                                              attrs: {
                                                effect: "dark",
                                                placement: "top",
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  attrs: { slot: "content" },
                                                  slot: "content",
                                                },
                                                [
                                                  _c("small", [
                                                    _vm._v(
                                                      "\n                                    This feature is in beta phase, so you can expect some changes. Feel free to send us feedback 🤩\n                                "
                                                    ),
                                                  ]),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "nav-label beta-box",
                                                },
                                                [
                                                  _c(
                                                    "b",
                                                    {
                                                      staticClass:
                                                        "label beta-label",
                                                    },
                                                    [_vm._v("Beta")]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "col-6" }, [
                                        link.name === "custom_link_builder"
                                          ? _c(
                                              "h2",
                                              { staticClass: "text-md _600" },
                                              [
                                                _vm._v(
                                                  "\n                                Custom Integration Link Builder\n                            "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        link.name === "custom_link_builder"
                                          ? _c("img", {
                                              staticClass: "integration-boxes",
                                              class: !link.status
                                                ? "deactivated"
                                                : "",
                                              staticStyle: {
                                                "max-width": "100%",
                                                height: "45px",
                                              },
                                              attrs: { src: link.image },
                                            })
                                          : _c("img", {
                                              staticClass: "integration-boxes",
                                              class: !link.status
                                                ? "deactivated"
                                                : "",
                                              attrs: { src: link.image },
                                            }),
                                        _vm._v(" "),
                                        link.name === "SMTP"
                                          ? _c("div", [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "pl-4 font-weight-bold",
                                                },
                                                [_vm._v(_vm._s(link.name))]
                                              ),
                                            ])
                                          : _vm._e(),
                                      ]),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "col-6" }, [
                                        _c("div", { staticClass: "row" }, [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "col-12 text-right",
                                            },
                                            [
                                              !_vm.allowedToEnableIntegrationsPage()
                                                ? _c(
                                                    "el-popover",
                                                    {
                                                      ref:
                                                        "settings-popover" +
                                                        link.name,
                                                      refInFor: true,
                                                      staticClass:
                                                        "custom-popover",
                                                      attrs: {
                                                        placement: "top",
                                                        width: "200",
                                                        "popper-class":
                                                          "btn-primary",
                                                        trigger: "hover",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "custom-message-display",
                                                        {
                                                          attrs: {
                                                            config:
                                                              _vm.customMessage(
                                                                "integrations"
                                                              ),
                                                          },
                                                        }
                                                      ),
                                                      _vm._v(" "),
                                                      link.name !== "webhook"
                                                        ? _c(
                                                            "button",
                                                            {
                                                              staticClass:
                                                                "btn btn-sm ml-2 mb-2",
                                                              class: [
                                                                _vm.allowIntegrationSettingsView(
                                                                  link.name
                                                                )
                                                                  ? "greenish"
                                                                  : "border",
                                                              ],
                                                              attrs: {
                                                                slot: "reference",
                                                                disabled:
                                                                  !_vm.allowedToEnableIntegrationsPage() ||
                                                                  !_vm.allowIntegrationSettingsView(
                                                                    link.name
                                                                  ),
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.checkCrmAccess(
                                                                      link.name
                                                                    )
                                                                  },
                                                              },
                                                              slot: "reference",
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                                            Settings\n                                        "
                                                              ),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                      _vm._v(" "),
                                                      link.name === "webhook"
                                                        ? _c("div", [
                                                            _c(
                                                              "button",
                                                              {
                                                                staticClass:
                                                                  "btn btn-sm greenish ml-2 mb-2",
                                                                attrs: {
                                                                  slot: "reference",
                                                                  disabled: "",
                                                                },
                                                                slot: "reference",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                                                Settings\n                                            "
                                                                ),
                                                              ]
                                                            ),
                                                          ])
                                                        : _vm._e(),
                                                    ],
                                                    1
                                                  )
                                                : _c(
                                                    "div",
                                                    [
                                                      _vm.showSettingsButtonForNonWebhook(
                                                        link
                                                      )
                                                        ? _c(
                                                            "button",
                                                            {
                                                              staticClass:
                                                                "btn btn-sm ml-2 mb-2",
                                                              class: [
                                                                _vm.allowIntegrationSettingsView(
                                                                  link.name
                                                                )
                                                                  ? "greenish"
                                                                  : "border",
                                                              ],
                                                              attrs: {
                                                                slot: "reference",
                                                                disabled:
                                                                  !_vm.allowIntegrationSettingsView(
                                                                    link.name
                                                                  ),
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.checkCrmAccess(
                                                                      link.name
                                                                    )
                                                                  },
                                                              },
                                                              slot: "reference",
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                                            Settings\n                                        "
                                                              ),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                      _vm._v(" "),
                                                      link.name === "webhook"
                                                        ? _c(
                                                            "router-link",
                                                            {
                                                              attrs: {
                                                                to: {
                                                                  name: "Webhooks",
                                                                },
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "button",
                                                                {
                                                                  staticClass:
                                                                    "btn btn-sm greenish ml-2 mb-2",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n                                                Settings\n                                            "
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                    ],
                                                    1
                                                  ),
                                            ],
                                            1
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _c("div", { staticClass: "row" }, [
                                          _vm.showIfNotMultiGuesty(link)
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "col-12 text-right",
                                                },
                                                [
                                                  !_vm.allowedToEnableIntegrationsPage() &&
                                                  !link.status
                                                    ? _c("el-tooltip", {
                                                        staticClass: "item",
                                                        attrs: {
                                                          effect: "light",
                                                          content:
                                                            "To turn this integration on, click settings",
                                                          placement:
                                                            "left-start",
                                                        },
                                                      })
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  _c("el-switch", {
                                                    attrs: {
                                                      disabled:
                                                        !_vm.allowedToEnableIntegrationsPage() ||
                                                        !link.status,
                                                      "active-color": "#00a344",
                                                    },
                                                    on: {
                                                      change: function (
                                                        $event
                                                      ) {
                                                        return _vm.updateStatus(
                                                          link
                                                        )
                                                      },
                                                    },
                                                    model: {
                                                      value: link.status,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          link,
                                                          "status",
                                                          $$v
                                                        )
                                                      },
                                                      expression: "link.status",
                                                    },
                                                  }),
                                                ],
                                                1
                                              )
                                            : _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "col-12 text-right",
                                                },
                                                [
                                                  _c("el-switch", {
                                                    attrs: {
                                                      disabled: "",
                                                      "active-color": "#00a344",
                                                    },
                                                    model: {
                                                      value: _vm.enabled,
                                                      callback: function ($$v) {
                                                        _vm.enabled = $$v
                                                      },
                                                      expression: "enabled",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                        ]),
                                        _vm._v(" "),
                                        link.connection_status == 2
                                          ? _c("div", { staticClass: "row" }, [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "col d-flex justify-content-end pt-2",
                                                },
                                                [
                                                  !_vm.allowedToEnableIntegrationsPage()
                                                    ? _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "text-danger text-right text-xs font-weight-normal mr-1",
                                                        },
                                                        [
                                                          _c("i", {
                                                            staticClass:
                                                              "fa fa-exclamation-triangle",
                                                          }),
                                                          _vm._v(" "),
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "border-bottom border-danger",
                                                            },
                                                            [
                                                              _vm._v(
                                                                "Not Connected"
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  _c(
                                                    "el-popover",
                                                    {
                                                      attrs: {
                                                        placement: "right",
                                                        width: "300",
                                                        trigger: "hover",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "text-dark",
                                                        },
                                                        [
                                                          _vm._v(
                                                            "Try re-authenticating to " +
                                                              _vm._s(
                                                                _vm._f(
                                                                  "fixIntegrationName"
                                                                )(
                                                                  _vm._f(
                                                                    "ucwords"
                                                                  )(
                                                                    _vm._f(
                                                                      "prettifyCamelCase"
                                                                    )(link.name)
                                                                  )
                                                                )
                                                              ) +
                                                              " by clicking "
                                                          ),
                                                          _c("strong", [
                                                            _vm._v(
                                                              "Settings > Re-authenticate"
                                                            ),
                                                          ]),
                                                          _vm._v(" button."),
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "text-primary",
                                                          attrs: {
                                                            slot: "reference",
                                                          },
                                                          slot: "reference",
                                                        },
                                                        [
                                                          _c("i", {
                                                            staticClass:
                                                              "fa fa-question-circle-o",
                                                          }),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ])
                                          : _vm._e(),
                                        _vm._v(" "),
                                        !link.is_required_fields_set &&
                                        link.status &&
                                        link.name !== "webhook"
                                          ? _c(
                                              "p",
                                              {
                                                staticClass:
                                                  "_600 text-danger mt-2 mb-0 text-right",
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                More Info Required\n                            "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ]
                          )
                        : [
                            "execvision",
                            "contact_center_compliance",
                            "zapier",
                          ].includes(link.name)
                        ? _c("el-card", { staticClass: "integration-box" }, [
                            _c(
                              "div",
                              { staticClass: "row d-flex align-items-center" },
                              [
                                _c("div", { staticClass: "col-6" }, [
                                  _c("img", {
                                    staticClass: "integration-boxes",
                                    attrs: { src: link.image },
                                  }),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "col-6" },
                                  [
                                    link.name === "zapier" &&
                                    _vm.allowedToEnableIntegrationsPage()
                                      ? _c(
                                          "a",
                                          {
                                            staticClass:
                                              "btn btn-sm bluish pull-right",
                                            attrs: {
                                              href: "https://support.aloware.com/en/articles/9037436-how-to-integrate-aloware-with-zapier-a-comprehensive-guide",
                                              target: "_blank",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                                Check our tutorial\n                            "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    !_vm.allowedToEnableIntegrationsPage()
                                      ? _c(
                                          "el-popover",
                                          {
                                            ref: "tutorial-popover",
                                            refInFor: true,
                                            staticClass: "custom-popover",
                                            attrs: {
                                              placement: "top",
                                              width: "200",
                                              "popper-class": "btn-primary",
                                              trigger: "hover",
                                            },
                                          },
                                          [
                                            _c("custom-message-display", {
                                              attrs: {
                                                config:
                                                  _vm.customMessage(
                                                    "integrations"
                                                  ),
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    link.name === "zapier" &&
                                    !_vm.allowedToEnableIntegrationsPage()
                                      ? _c(
                                          "button",
                                          {
                                            directives: [
                                              {
                                                name: "popover",
                                                rawName:
                                                  "v-popover:tutorial-popover",
                                                arg: "tutorial-popover",
                                              },
                                            ],
                                            staticClass:
                                              "btn btn-sm bluish pull-right cursor-default",
                                            attrs: { disabled: "" },
                                          },
                                          [
                                            _vm._v(
                                              "\n                                    Check our tutorial\n                            "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    link.name !== "zapier" &&
                                    _vm.allowedToEnableIntegrationsPage()
                                      ? _c(
                                          "a",
                                          {
                                            staticClass:
                                              "btn btn-sm bluish pull-right",
                                            attrs: {
                                              href: "mailto:support@aloware.com",
                                              target: "_blank",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                                Contact Us\n                            "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    !_vm.allowedToEnableIntegrationsPage()
                                      ? _c(
                                          "el-popover",
                                          {
                                            ref: "contact-popover",
                                            refInFor: true,
                                            staticClass: "custom-popover",
                                            attrs: {
                                              placement: "top",
                                              width: "200",
                                              "popper-class": "btn-primary",
                                              trigger: "hover",
                                            },
                                          },
                                          [
                                            _c("custom-message-display", {
                                              attrs: {
                                                config:
                                                  _vm.customMessage(
                                                    "integrations"
                                                  ),
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    link.name !== "zapier" &&
                                    !_vm.allowedToEnableIntegrationsPage()
                                      ? _c(
                                          "button",
                                          {
                                            directives: [
                                              {
                                                name: "popover",
                                                rawName:
                                                  "v-popover:contact-popover",
                                                arg: "contact-popover",
                                              },
                                            ],
                                            staticClass:
                                              "btn btn-sm bluish pull-right cursor-default",
                                            attrs: { disabled: "" },
                                          },
                                          [
                                            _vm._v(
                                              "\n                                Contact Us\n                            "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ]
                            ),
                          ])
                        : link.name === "aloware_click_to_call"
                        ? _c("el-card", { staticClass: "integration-box" }, [
                            _c(
                              "div",
                              { staticClass: "row d-flex align-items-center" },
                              [
                                _c("div", { staticClass: "col-6" }, [
                                  _c("h2", { staticClass: "text-md _600" }, [
                                    _vm._v("Aloware Click-To-Call"),
                                  ]),
                                  _vm._v(" "),
                                  _c("img", {
                                    staticClass: "integration-boxes",
                                    staticStyle: {
                                      "max-width": "100%",
                                      height: "45px",
                                    },
                                    attrs: {
                                      src: "/assets/images/ChromeWebStore_Badge_v2_496x150.png",
                                    },
                                  }),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "col-6" },
                                  [
                                    _vm.allowedToEnableIntegrationsPage()
                                      ? _c(
                                          "a",
                                          {
                                            staticClass:
                                              "btn btn-sm bluish pull-right",
                                            attrs: {
                                              href: "https://chrome.google.com/webstore/detail/kmchiimcbhaaadgpnelddelgiaeahobi",
                                              target: "_blank",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                                Add to Chrome\n                            "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    !_vm.allowedToEnableIntegrationsPage()
                                      ? _c(
                                          "el-popover",
                                          {
                                            ref: "chrome-popover",
                                            refInFor: true,
                                            staticClass: "custom-popover",
                                            attrs: {
                                              placement: "top",
                                              width: "200",
                                              "popper-class": "btn-primary",
                                              trigger: "hover",
                                            },
                                          },
                                          [
                                            _c("custom-message-display", {
                                              attrs: {
                                                config:
                                                  _vm.customMessage(
                                                    "integrations"
                                                  ),
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    !_vm.allowedToEnableIntegrationsPage()
                                      ? _c(
                                          "button",
                                          {
                                            directives: [
                                              {
                                                name: "popover",
                                                rawName:
                                                  "v-popover:chrome-popover",
                                                arg: "chrome-popover",
                                              },
                                            ],
                                            staticClass:
                                              "btn btn-sm bluish pull-right cursor-default",
                                            attrs: { disabled: "" },
                                          },
                                          [
                                            _vm._v(
                                              "\n                                Add to Chrome\n                            "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ]
                            ),
                          ])
                        : link.name === "missing_integration"
                        ? _c("el-card", { staticClass: "integration-box" }, [
                            _c(
                              "div",
                              { staticClass: "row d-flex align-items-center" },
                              [
                                _c("div", { staticClass: "col-12" }, [
                                  _c("h2", { staticClass: "text-md _600" }, [
                                    _vm._v("Missing an integration?"),
                                  ]),
                                  _vm._v(" "),
                                  _c("p", { staticClass: "text-md" }, [
                                    _vm._v("Let us know and we will make it!"),
                                  ]),
                                ]),
                              ]
                            ),
                          ])
                        : _c(
                            "el-card",
                            {
                              staticClass: "integration-box",
                              attrs: {
                                "body-style": { filter: "grayscale(100%)" },
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "row d-flex align-items-center",
                                },
                                [
                                  _c("div", { staticClass: "col-6" }, [
                                    _c("img", {
                                      staticClass: "integration-boxes",
                                      class: !link.status ? "deactivated" : "",
                                      attrs: { src: link.image },
                                    }),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "col-6" },
                                    [
                                      _c("el-switch", {
                                        staticClass: "pull-right",
                                        attrs: {
                                          "active-color": "#00a344",
                                          disabled: "",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ]
                          ),
                    ],
                    1
                  )
                : _vm._e()
            }),
          ],
          2
        ),
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }