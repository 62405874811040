var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "row flex-grow-1" },
    [
      _c(
        "div",
        { staticClass: "col-5" },
        [
          _c(
            "el-form",
            {
              attrs: { model: _vm.chatbot },
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [
              _c(
                "el-form-item",
                { staticClass: "mb-0", attrs: { prop: "enabled" } },
                [
                  _c("div", { staticClass: "d-flex" }, [
                    _c("div", { staticClass: "mr-auto" }, [
                      _c("div", { staticClass: "form-label" }, [
                        _c("h5", { staticClass: "text-dark" }, [
                          _vm._v("Enabled"),
                        ]),
                        _vm._v(" "),
                        _c("small", [
                          _vm._v(
                            "\n                                This means your chatbot is active and running.\n                            "
                          ),
                        ]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "ml-auto my-auto" },
                      [
                        _c("el-switch", {
                          staticClass: "pull-right",
                          attrs: { "active-color": "#00a344" },
                          model: {
                            value: _vm.chatbot.enabled,
                            callback: function ($$v) {
                              _vm.$set(_vm.chatbot, "enabled", $$v)
                            },
                            expression: "chatbot.enabled",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("hr"),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mb-0", attrs: { prop: "name" } },
                [
                  _c("div", { staticClass: "form-label" }, [
                    _c("h5", { staticClass: "text-dark" }, [_vm._v("Name")]),
                  ]),
                  _vm._v(" "),
                  _c("el-input", {
                    model: {
                      value: _vm.chatbot.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.chatbot, "name", $$v)
                      },
                      expression: "chatbot.name",
                    },
                  }),
                  _vm._v(" "),
                  _c("hr"),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mb-0", attrs: { prop: "workflow_name" } },
                [
                  _c("div", { staticClass: "form-label" }, [
                    _c("h5", { staticClass: "text-dark" }, [
                      _vm._v("Workflow"),
                    ]),
                    _vm._v(" "),
                    _c("small", [
                      _vm._v(
                        "\n                        This is how this template is identified.\n                    "
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("el-input", {
                    model: {
                      value: _vm.chatbot.workflow,
                      callback: function ($$v) {
                        _vm.$set(_vm.chatbot, "workflow", $$v)
                      },
                      expression: "chatbot.workflow",
                    },
                  }),
                  _vm._v(" "),
                  _c("hr"),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mb-0", attrs: { prop: "campaign_ids" } },
                [
                  _c("div", { staticClass: "form-label" }, [
                    _c("h5", { staticClass: "text-dark" }, [_vm._v("Lines")]),
                    _vm._v(" "),
                    _c("small", [
                      _vm._v(
                        "\n                        These lines are connected to this chatbot.\n                    "
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-select",
                    {
                      staticClass: "w-100",
                      attrs: {
                        multiple: "",
                        filterable: "",
                        placeholder: "Select any line",
                      },
                      model: {
                        value: _vm.campaign_ids,
                        callback: function ($$v) {
                          _vm.campaign_ids = $$v
                        },
                        expression: "campaign_ids",
                      },
                    },
                    _vm._l(_vm.lines, function (line) {
                      return _c(
                        "el-option",
                        {
                          key: line.id,
                          attrs: {
                            label: _vm.formatedLine(line),
                            value: line.id,
                          },
                        },
                        [
                          _c("span", { staticClass: "font-weight-bold" }, [
                            _vm._v(_vm._s(line.name)),
                          ]),
                          _vm._v(" "),
                          _c("br"),
                          _vm._v(" "),
                          line.incoming_numbers.length === 1
                            ? _c("span", [
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(
                                      _vm._f("fixPhone")(
                                        line.incoming_numbers[0].phone_number
                                      )
                                    ) +
                                    "\n                        "
                                ),
                              ])
                            : _c("span", [
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(line.incoming_numbers.length) +
                                    " Numbers\n                        "
                                ),
                              ]),
                        ]
                      )
                    }),
                    1
                  ),
                  _vm._v(" "),
                  _c("hr"),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mb-0", attrs: { prop: "time_format_24h" } },
                [
                  _c("div", { staticClass: "d-flex" }, [
                    _c("div", { staticClass: "mr-auto" }, [
                      _c("div", { staticClass: "form-label" }, [
                        _c("h5", { staticClass: "text-dark" }, [
                          _vm._v("Time Format"),
                        ]),
                        _vm._v(" "),
                        _c("small", [
                          _vm._v(
                            "\n                                When this feature is on, your Chatbot will use 24-hour time format.\n                            "
                          ),
                        ]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "ml-auto my-auto" },
                      [
                        _c("el-switch", {
                          staticClass: "pull-right",
                          attrs: { "active-color": "#00a344" },
                          model: {
                            value: _vm.chatbot.time_format_24h,
                            callback: function ($$v) {
                              _vm.$set(_vm.chatbot, "time_format_24h", $$v)
                            },
                            expression: "chatbot.time_format_24h",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("hr"),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mb-0 pb-4", attrs: { prop: "notes" } },
                [
                  _c("div", { staticClass: "form-label" }, [
                    _c("h5", { staticClass: "text-dark" }, [_vm._v("Notes")]),
                  ]),
                  _vm._v(" "),
                  _c("el-input", {
                    attrs: { type: "textarea", rows: 4 },
                    model: {
                      value: _vm.chatbot.notes,
                      callback: function ($$v) {
                        _vm.$set(_vm.chatbot, "notes", $$v)
                      },
                      expression: "chatbot.notes",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: {
                    type: "success",
                    round: "",
                    size: "medium",
                    disabled: _vm.disable_save_button,
                  },
                  on: { click: _vm.updateBot },
                },
                [
                  _c("i", {
                    staticClass: "fa fa-floppy-o mr-1",
                    attrs: { "aria-hidden": "true" },
                  }),
                  _vm._v(" "),
                  _c("strong", [_vm._v(" Save")]),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "danger", round: "", size: "medium" },
                  on: { click: _vm.openDeleteChatbotForm },
                },
                [
                  _c("i", {
                    staticClass: "fa fa-trash-o mr-1",
                    attrs: { "aria-hidden": "true" },
                  }),
                  _vm._v(" "),
                  _c("strong", [_vm._v(" Delete")]),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "col-7" }, [
        _c("div", { staticClass: "d-flex" }, [
          _c(
            "div",
            [
              _c("el-divider", {
                staticClass: "h-100",
                attrs: { direction: "vertical" },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "d-flex flex-column flex-grow-1" },
            [
              _c("Chatbox", {
                attrs: { id: "alohabot-chatbox", bot: _vm.chatbot },
              }),
            ],
            1
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("DeleteChatbot", {
        attrs: {
          form_visible: _vm.delete_chatbot_form_visible,
          chatbot: _vm.chatbot,
        },
        on: { submit: _vm.deleteBot, discard: _vm.closeDeleteChatbotForm },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }