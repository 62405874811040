<template>
    <div class="container-listing px-0 py-3">
        <div class="container-header d-flex">
            <h4 class="listing-heading">Enrolled Contacts</h4>
            <div class="ml-auto">
                <div class="d-flex">
                    <el-button type="success"
                               round
                               class="mr-3"
                               size="medium"
                               @click="$refs.workflow_enroller.enrollContacts(workflow)">
                        <i class="fa fa-plus"></i>
                        <strong>Enroll Contacts</strong>
                    </el-button>
                </div>
            </div>
        </div>

        <div class="container-body">
            <div class="container-listing-tools d-flex flex-fill flex-wrap flex-lg-nowrap mb-1">
                <div class="d-flex flex-wrap flex-lg-nowrap mr-auto mr-5">
                    <div class="d-flex mr-2 search-toolbar">
                        <el-input
                            id="search-input"
                            placeholder="Search contact ID, phone number, or name..."
                            v-model="filters.search"
                            clearable
                            @keyup.native="searchSequenceContacts"
                            @change="searchSequenceContacts">
                            <i slot="prefix" class="el-input__icon el-icon-search"></i>
                        </el-input>
                    </div>
                    <div class="d-flex mt-2 mt-sm-0">
                        <el-button
                            size="small"
                            @click="fetchSequenceContacts(1)">
                            <i class="fa fa-refresh"></i> Refresh List
                        </el-button>
                        <el-button
                            v-if="pagination.data.length > 0"
                            class="text-danger ml-2"
                            size="small"
                            @click="clearSequenceContacts">
                            <i class="fa fa-trash-o"></i> Clear List
                        </el-button>
                    </div>
                </div>
                <div class="d-flex mt-2 mt-md-0 flex-wrap flex-lg-nowrap">
                    <div class="mr-2 align-self-center">
                        <span class="text-grey">Total Contacts:</span> <strong>{{
                            pagination.total | numFormat
                        }}</strong>
                    </div>
                    <el-pagination
                        class="align-self-center"
                        layout="prev, pager, next"
                        :current-page.sync="filters.page"
                        :page-size.sync="filters.size"
                        :total="pagination.total"
                        :pager-count="5"
                        @size-change="fetchSequenceContacts(1)"
                        @current-change="fetchSequenceContacts">
                    </el-pagination>
                </div>
            </div>

            <div class="table-responsive"
                 v-loading="is_contacts_loading">
                <table v-if="pagination.data.length > 0" class="table table-sm">
                    <thead>
                    <tr>
                        <th>Contact</th>
                        <th>Step</th>
                        <th>Enrolled At</th>
                        <th>Estimated Schedule</th>
                        <th>Retries</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(sequence_contact, index) in pagination.data">
                        <td>
                            <router-link
                                v-if="sequence_contact.contact"
                                :to="{name: 'Contact', params: {contact_id: sequence_contact.contact_id}}"
                                target="_blank">
                                <div class="hover-lower-opacity">
                                    <h6 class="mb-0">{{ sequence_contact.contact.name }}</h6>
                                    <small class="text-grey">{{ sequence_contact.contact.phone_number | fixPhone }}</small><br/>
                                </div>
                            </router-link>
                        </td>
                        <td>
                            <div class="d-flex">
                                <div></div>
                                <img :src="sequence_contact.sequence.img_src" class="mr-3 "/>
                                <div>
                                    <h6 class="mb-0">{{ sequence_contact.sequence.name }}</h6>
                                    <small class="text-grey">Step #{{ sequence_contact.sequence.order }}</small>
                                </div>
                            </div>
                        </td>
                        <td>
                            {{ sequence_contact.converted_enrolled_at }}
                        </td>
                        <td>
                            {{ sequence_contact.converted_estimated_schedule }}
                        </td>
                        <td>
                            <span v-if="sequence_contact.tries > 0">
                                {{ sequence_contact.tries | numFormat }}
                            </span>
                            <span v-else>
                                -
                            </span>
                        </td>
                        <td class="text-right">
                            <el-button
                                class="text-danger"
                                size="small"
                                @click="removeSequenceContact(sequence_contact)">
                                <i class="fa fa-trash-o"></i>
                            </el-button>
                        </td>
                    </tr>
                    </tbody>
                </table>

                <div v-if="pagination.data.length <= 0"
                     class="text-center mx-auto w-50 py-5">
                    <div class="w-75 mx-auto">
                        <img-empty></img-empty>
                    </div>
                    <p class="lead">
                        <span v-if="filters.search">
                            We could not find an enrolled contact that matches "{{ filters.search }}".
                        </span>
                        <span v-else>There are no enrolled contacts or all contacts has finished the sequence.</span>
                    </p>
                    <el-button type="success"
                               round
                               class="mr-2"
                               size="medium"
                               @click="$refs.workflow_enroller.enrollContacts(workflow)">
                        <i class="fa fa-plus"></i>
                        <strong>Enroll Contacts</strong>
                    </el-button>
                    <el-button
                        round
                        size="medium"
                        @click="fetchSequenceContacts(1)">
                        <i class="fa fa-refresh"></i> Refresh List
                    </el-button>
                </div>
            </div>
        </div>

        <div class="container-footer d-flex">
            <div class="ml-auto">
                <el-pagination
                    layout="sizes, prev, pager, next"
                    :current-page.sync="filters.page"
                    :page-sizes="sizes"
                    :page-size.sync="filters.size"
                    :total="pagination.total"
                    :pager-count="5"
                    :hide-on-single-page="true"
                    @size-change="fetchSequenceContacts(1)"
                    @current-change="fetchSequenceContacts">
                </el-pagination>
            </div>
        </div>

        <workflow-enroller ref="workflow_enroller" @before-close="fetchSequenceContactsDelay(3000)"/>
    </div>
</template>

<script>
import ImgEmpty from "../misc/img-empty";
import WorkflowEnroller from "./workflow-enroller";
import { sleep } from '../../utils/integration-helpers'

export default {
    name: "workflow-contacts",

    components: {WorkflowEnroller, ImgEmpty},

    props: {
        workflow: {
            type: Object,
            required: true
        }
    },

    data() {
        return {
            is_contacts_loading: false,
            pagination: {
                data: []
            },
            filters: {
                size: 10
            },
            sizes: []
        }
    },

    created() {
        this.fetchSequenceContacts()

        for(let i = 10; i <= 100; i += 10) {
            this.sizes.push(i)
        }

        // Listen for Pipedrive app extension completion
        VueEvent.listen('integration_pipedrive_app_extension_sequence_completed', async () => {
          const message = "We're done processing the contacts and <b>we'll refresh your page in a few seconds</b>. Enrolled list <b>will slowly populate until completion</b>"
          this.$notify({
            duration: 7000,
            offset: 175,
            title: 'Sequences',
            message: message,
            type: 'success',
            showClose: true,
            dangerouslyUseHTMLString: true
          })
          if (this.$route.name === 'Sequence Manager' && this.$route.query.tab === 'contacts') {
            await sleep(7500) // TODO: find why fetchSequenceContacts() method is not working when triggered from here
            location.reload()
          }
        })
    },

    methods: {
        fetchSequenceContacts(page = 1) {
            this.is_contacts_loading = true

            this.filters.page = page

            axios.get(`/api/v1/automations/workflows/${this.workflow.id}/sequence-contacts`, {
                params: this.filters
            }).then(res => {
                this.pagination = res.data
                this.is_contacts_loading = false
            })
        },

        fetchSequenceContactsDelay(delay = 1000) {
            setTimeout(() => {
                this.fetchSequenceContacts()
            }, delay)
        },

        removeSequenceContact(sequence_contact) {
            this.$confirm(`Are you sure you want to remove this contact?`, 'Warning', {
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                customClass: 'w-25 fixed',
                confirmButtonClass: 'el-button--success',
                type: 'warning'
            }).then(() => {
                axios.delete(`/api/v1/automations/sequence-contacts/${this.workflow.id}/${sequence_contact.contact_id}`).then(res => {
                    this.pagination.data = _.remove(this.pagination.data, (sc) => {
                        return sc.id != sequence_contact.id
                    })

                    this.$notify({
                        offset: 75,
                        title: 'Sequence',
                        message: res.data.message,
                        type: 'success',
                        showClose: true,
                        duration: 3000
                    })
                })
            }).catch(() => {

            })
        },

        clearSequenceContacts() {
            this.$confirm(`This will delete all the contacts enrolled in the is sequence and the action is irreversible. Are you sure you want to clear the contacts list?`, 'Warning', {
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                customClass: 'w-25 fixed',
                confirmButtonClass: 'el-button--success',
                type: 'warning'
            }).then(() => {
                axios.delete(`/api/v1/automations/workflows/${this.workflow.id}/sequence-contacts`).then(res => {
                    this.pagination = {
                        data: []
                    }

                    this.$notify({
                        offset: 75,
                        title: 'Sequence',
                        message: res.data.message,
                        type: 'success',
                        showClose: true,
                        duration: 3000
                    })
                })
            }).catch(() => {

            })
        },

        searchSequenceContacts: _.debounce(function () {
            this.fetchSequenceContacts()
        }, 400),
    }
}
</script>
