var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.shouldShowAloAis
    ? _c(
        "div",
        { staticClass: "app-body page-chatbot", attrs: { id: "view" } },
        [_c("ChatbotBuilder", { attrs: { chatbot_id: _vm.chatbot_id } })],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }