var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "row no-gutter",
      class: [_vm.no_padding ? "" : "pt-2 pl-2 pr-2"],
    },
    [
      _vm.hubspotLink
        ? _c("div", { staticClass: "col-md-3 text-center" }, [
            _c("a", {
              staticClass: "md-btn md-raised white hubspot-btn",
              attrs: {
                "data-testid": "crm-hubspot-link",
                href: _vm.hubspotLink,
                target: "_blank",
              },
            }),
            _vm._v(" "),
            _c("span", { staticClass: "d-block text-xs text-muted" }, [
              _vm._v("HubSpot"),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.pipedriveLink
        ? _c("div", { staticClass: "col-md-3 text-center" }, [
            _c("a", {
              staticClass: "md-btn md-raised white pipedrive-btn",
              attrs: {
                href: _vm.pipedriveLink,
                "data-testid": "crm-pipedrive-link",
                target: "_blank",
              },
            }),
            _vm._v(" "),
            _c("span", { staticClass: "d-block text-xs text-muted" }, [
              _vm._v("Pipedrive"),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.stripeLink
        ? _c("div", { staticClass: "col-md-3 text-center" }, [
            _c("a", {
              staticClass: "md-btn md-raised white stripe-btn",
              attrs: {
                href: _vm.stripeLink,
                "data-testid": "crm-stripe-link",
                target: "_blank",
              },
            }),
            _vm._v(" "),
            _c("span", { staticClass: "d-block text-xs text-muted" }, [
              _vm._v("Stripe"),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.batsLink
        ? _c("div", { staticClass: "col-md-3 text-center" }, [
            _c("a", {
              staticClass: "md-btn md-raised blue bats-btn",
              attrs: {
                href: _vm.batsLink,
                "data-testid": "crm-bats-link",
                target: "_blank",
              },
            }),
            _vm._v(" "),
            _c("span", { staticClass: "d-block text-xs text-muted" }, [
              _vm._v("BATS"),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.zohoContactLink
        ? _c("div", { staticClass: "col-md-3 text-center" }, [
            _c("a", {
              staticClass: "md-btn md-raised white zoho-btn",
              attrs: {
                href: _vm.zohoContactLink,
                "data-testid": "crm-zoho-contact-link",
                target: "_blank",
              },
            }),
            _vm._v(" "),
            _c("span", { staticClass: "d-block text-xs text-muted" }, [
              _vm._v("Zoho Contact"),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.zohoLeadLink
        ? _c("div", { staticClass: "col-md-3 text-center" }, [
            _c("a", {
              staticClass: "md-btn md-raised white zoho-btn",
              attrs: {
                href: _vm.zohoLeadLink,
                "data-testid": "crm-zoho-lead-link",
                target: "_blank",
              },
            }),
            _vm._v(" "),
            _c("span", { staticClass: "d-block text-xs text-muted" }, [
              _vm._v("Zoho Lead"),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.helpscoutLink
        ? _c("div", { staticClass: "col-md-3 text-center" }, [
            _c("a", {
              staticClass: "md-btn md-raised white helpscout-btn",
              attrs: {
                href: _vm.helpscoutLink,
                "data-testid": "crm-help-scout-link",
                target: "_blank",
              },
            }),
            _vm._v(" "),
            _c("span", { staticClass: "d-block text-xs text-muted" }, [
              _vm._v("Help Scout"),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.guestyLink
        ? _c("div", { staticClass: "col-md-3 text-center" }, [
            _c("a", {
              staticClass: "md-btn md-raised white guesty-btn",
              attrs: {
                href: _vm.guestyLink,
                "data-testid": "crm-guesty-link",
                target: "_blank",
              },
            }),
            _vm._v(" "),
            _c("span", { staticClass: "d-block text-xs text-muted" }, [
              _vm._v("Guesty"),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.goHighLevelLink
        ? _c("div", { staticClass: "col-md-3 text-center" }, [
            _c("a", {
              staticClass: "md-btn md-raised white gohighlevel-btn",
              attrs: {
                href: _vm.goHighLevelLink,
                "data-testid": "crm-go-high-level-link",
                target: "_blank",
              },
            }),
            _vm._v(" "),
            _c("span", { staticClass: "d-block text-xs text-muted" }, [
              _vm._v("GoHighLevel"),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.customLink
        ? _c("div", { staticClass: "col-md-3 text-center" }, [
            _c("a", {
              staticClass: "md-btn md-raised white custom-link-builder-btn",
              attrs: {
                href: _vm.customLink,
                "data-testid": "crm-customn-link",
                target: "_blank",
              },
            }),
            _vm._v(" "),
            _c("span", { staticClass: "d-block text-xs text-muted" }, [
              _vm._v("Custom Link"),
            ]),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }