<template>
    <li class="tl-item"
        :class="[ communication.direction === 2 ? 'tl-right' : 'tl-left' ]"
        v-if="communication.type == filter_type || filter_type == 0">
        <div class="tl-wrap"
             :class="stateToClass(communication.disposition_status2)">
            <span class="tl-date text-muted pl-1"
                  v-html="relative_datetime">
            </span>
            <div class="tl-content box-color text-color w-auto-xs"
                 :class="{ 'w-xl': is_center }">
                <span class="arrow b-white pull-top"
                      :class="[ getClass ]">
                </span>
                <communication-info :communication="communication"
                                    :activity_mode="true">
                </communication-info>
                <div class="row p-l p-r"
                     v-if="![10, 11, 12, 13].includes(communication.type)">
                    <div class="col-12">
                        <router-link
                                :to="{ name: 'Communication', params: {communication_id: communication.id}}">
                            <button class="btn btn-sm greyish text-left">
                                <i class="material-icons mr-2">info</i>
                                More Details
                            </button>
                        </router-link>
                    </div>
                </div>
                <div class="row p-l p-r"
                     v-if="communication.type === 13">
                    <div class="col-12">
                        <el-radio-group v-model="communication.disposition_status2"
                                        size="small"
                                        :disabled="loading_update_engagement"
                                        @change="changeReminderStatus">
                            <el-radio-button :label="CommunicationDispositionStatus.DISPOSITION_STATUS_COMPLETED_NEW">Completed</el-radio-button>
                            <el-radio-button :label="CommunicationDispositionStatus.DISPOSITION_STATUS_PLACED_NEW">Pending</el-radio-button>
                            <el-radio-button :label="CommunicationDispositionStatus.DISPOSITION_STATUS_FAILED_NEW">Failed</el-radio-button>
                        </el-radio-group>
                    </div>
                </div>
            </div>
        </div>
    </li>
</template>

<script>
    import auth from '../auth'
    import {acl_mixin, avatar_mixin} from '../mixins'
    import {mapState} from 'vuex'
    import * as CommunicationDispositionStatus from '../constants/communication-disposition-status'

    export default {
        mixins: [acl_mixin, avatar_mixin],

        props: ['communication', 'is_center', 'filter_type'],

        data() {
            return {
                auth: auth,
                profile: auth.user.profile,
                relative_datetime: null,
                loading_update_engagement: false,
                CommunicationDispositionStatus
            }
        },

        computed: {
            ...mapState('cache', ['current_company']),

            getClass() {
                if (this.communication.direction === 1 && this.is_center) {
                    return 'right'
                } else {
                    return 'left'
                }
            }
        },

        created() {
            this.getRelativeDateTime()
            setInterval(this.getRelativeDateTime, 10000)
        },

        destroyed() {
            clearInterval(this.getRelativeDateTime)
        },

        methods: {
            getRelativeDateTime() {
                this.relative_datetime = this.$options.filters.fixFullDateUTCRelative(this.communication.created_at)
            },

            avatarStyle(name) {
                name = this.communication.direction === 2 && this.current_company ? this.current_company.name : this.contact.name
                if (!name) {
                    return
                }

                let bg = this.intToRGB(this.hashCode(name))
                return {
                    backgroundColor: bg,
                    color: this.overlayColor(bg)
                }
            },

            stateToClass: function (state) {
                if (state == CommunicationDispositionStatus.DISPOSITION_STATUS_INPROGRESS_NEW) {
                    return 'b-indigo-500'
                } else if (state == CommunicationDispositionStatus.DISPOSITION_STATUS_COMPLETED_NEW) {
                    return 'b-green-500'
                } else if (state == CommunicationDispositionStatus.DISPOSITION_STATUS_ABANDONED_NEW) {
                    return 'b-purple-500'
                } else if (state == CommunicationDispositionStatus.DISPOSITION_STATUS_MISSED_NEW) {
                    return 'b-red-500'
                } else if (state == CommunicationDispositionStatus.DISPOSITION_STATUS_FAILED_NEW) {
                    return 'b-red-500'
                } else if (state == CommunicationDispositionStatus.DISPOSITION_STATUS_DEADEND_NEW) {
                    return 'b-orange-500'
                } else if (state == CommunicationDispositionStatus.DISPOSITION_STATUS_PLACED_NEW) {
                    return 'b-lime-500'
                }
            },

            changeReminderStatus(status) {
                const params = {
                    status: status,
                    entity_type : "communication", // it means entity id for communication table
                }

                this.loading_update_engagement = true

                axios.post(`/api/v1/calendar/events/contact/${this.communication.contact_id}/update/${this.communication.id}`, params).then(res => {
                    this.loading_update_engagement = false
                    this.$notify({
                        title: 'Engagement',
                        message: 'Engagement updated.',
                        type: 'success',
                        showClose: true,
                        offset: 95
                    })
                    this.$emit('update', res.data)
                }).catch(err => {
                    this.loading_update_engagement = false
                    this.$root.handleErrors(err.response)
                })
            }
        }
    }
</script>
