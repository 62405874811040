<template>
    <div class="d-flex flex-wrap wrap-auto-margin">
        <div class="el-badge">
            <el-button class="p-x-md"
                       plain
                       :class="[ filter.is_new_lead ? 'greenish' : '' ]"
                       :loading="newLeadsCount == null"
                       data-testid="new-leads-button"
                       @click="toggleNewLeadsFilter">
                New Leads
                <span class="badge badge-danger text-sm"
                      size="tiny">
                    <strong>{{ newLeadsCount | numFormat }}</strong>
                </span>
            </el-button>
        </div>

        <div class="el-badge">
            <el-button-group>
                <el-button :type="filter.has_unread ? 'success' : 'default'"
                           :loading="unreadsCount == null"
                           data-testid="has-unread-communications-button"
                           @click="toggleUnansweredLeadsFilter">
                    Has Unread Communications
                    <span class="badge badge-danger text-sm"
                          :class="[unreadsCount > 0 ? 'flashy-counts' : '']">
                        <strong>{{ unreadsCount | numFormat }}</strong>
                    </span>
                </el-button>
                <el-dropdown trigger="click"
                             v-if="unreadsCount > 0 && hasMarkAllAsReadAccess && current_company && current_company.mark_all_as_read_enabled"
                             @command="markAllAsRead">
                    <el-button class="px-2 btn-contact-shortcut"
                               id="btn-mark-unanswered-read">
                        <i class="fa fa-caret-down"></i>
                    </el-button>
                    <template v-slot:dropdown>
                        <el-dropdown-menu>
                            <el-dropdown-item command="user" 
                                              data-testid="mark-my-contacts-as-read-item">
                                Mark my contacts as read
                            </el-dropdown-item>
                        </el-dropdown-menu>
                    </template>
                </el-dropdown>
            </el-button-group>
        </div>

        <div class="el-badge"
             v-if="current_company && (!current_company.reseller_id || current_company.reseller_id !== 357)">
            <el-button class="p-x-md btn-contact-shortcut"
                       plain
                       :class="[ filter.should_follow_the_sun ? 'greenish' : '' ]"
                       data-testid="follow-the-sun-button"
                       @click="toggleShouldFollowTheSun">
                Follow The Sun
            </el-button>
        </div>
    </div>
</template>

<script>
import auth from '../auth'
import {mapGetters, mapState, mapActions} from 'vuex'
import {acl_mixin, contact_counts_mixin, contact_filters_mixin, contacts_live_updates_mixin} from '../mixins'

    export default {
        mixins: [
            acl_mixin,
            contact_filters_mixin,
            contacts_live_updates_mixin,
            contact_counts_mixin
        ],

        data() {
            return {
                auth: auth,
                loading_assign_tasks: false
            }
        },

        computed: {
            ...mapGetters({
                contactsCount: 'getContactsCount',
                newLeadsCount: 'getNewLeadsCount',
                unreadsCount: 'getUnreadsCount'
            }),

            hasMarkAllAsReadAccess() {
                if (this.current_company && !this.current_company.reseller_id) {
                    return true
                }

                if (this.current_company && this.current_company.reseller_id === 357 && this.hasRole('Billing Admin')) {
                    return true
                }

                return false
            },

            ...mapState('cache', ['current_company']),
        },

        methods: {
            toggleNewLeadsFilter($event) {
                $event.target.blur()
                this.filter.has_unread = 0
                this.filter.should_follow_the_sun = 0
                this.filter.is_new_lead = (this.filter.is_new_lead === 1) ? 0 : 1
                VueEvent.fire('clear_new_leads_count')

                if (this.filter.is_new_lead) {
                    this.filter.changed = true
                    VueEvent.fire('filtered_new_leads')
                } else {
                    // if new leads filter is inactive, we have to check if
                    // there are active non-default filters. If none found,
                    // set filter changed flag to false. otherwise, retain its current value
                    this.filter.changed = !this.hasActiveNonDefaultFilters() ? false : this.filter.changed
                    VueEvent.fire('unfiltered_new_leads_unanswered_messages')
                }

                this.$nextTick(() => {
                    VueEvent.fire('updateTextualContactFilters')
                })
            },

            toggleUnansweredLeadsFilter($event) {
                $event.target.blur()
                this.filter.is_new_lead = 0
                this.filter.should_follow_the_sun = 0
                this.filter.has_unread = (this.filter.has_unread === 1) ? 0 : 1

                if (this.filter.has_unread) {
                    this.filter.changed = true
                    VueEvent.fire('filtered_unanswered_messages')
                    VueEvent.fire('disable_unanswered_leads_sort_options', false)
                } else {
                    // if has unread messages filter is inactive, we have to check if
                    // there are active non-default filters. If none found,
                    // set filter changed flag to false. otherwise, retain its current value
                    this.filter.changed = !this.hasActiveNonDefaultFilters() ? false : this.filter.changed
                    VueEvent.fire('unfiltered_new_leads_unanswered_messages')
                    VueEvent.fire('disable_unanswered_leads_sort_options', true)
                }

                this.$nextTick(() => {
                    VueEvent.fire('updateTextualContactFilters')
                })
            },

            markAllAsRead(command) {
                let warn_msg = 'Are you sure you want to mark all unanswered leads as read?'

                if (command === 'user') {
                    warn_msg = 'Are you sure you want to mark all your unanswered leads as read?'
                }

                this.$confirm(warn_msg, 'Warning', {
                    confirmButtonText: "Yes",
                    cancelButtonText: "No",
                    type: 'warning',
                    customClass: 'width-500 fixed'
                }).then(() => {
                     axios.put(`/api/v1/contact/mark-all-as-read`, {'scope': command})
                        .then(res => {
                            this.$notify({
                                duration: 3000,
                                offset: 150,
                                title: 'Unanswered Leads',
                                message: res.data.message,
                                type: 'success',
                                showClose: true
                            })

                            const updatedUnreadCount = this.unreadsCount - +res.data.updated_contacts_count

                            // update each unanswered contact's unread counter badge and the filter
                            VueEvent.fire('update_unanswered_leads_count_and_filter', {unreadCount: updatedUnreadCount, scope: command})
                        })
                        .catch((err) => console.error(err))
                }).catch(err => {
                    console.log(err)
                })
            },

            toggleShouldFollowTheSun($event) {
                $event.target.blur()
                this.filter.has_unread = 0
                this.filter.is_new_lead = 0
                this.filter.should_follow_the_sun = (this.filter.should_follow_the_sun === 1) ? 0 : 1
                VueEvent.fire('clear_new_leads_count')

                if (this.filter.should_follow_the_sun) {
                    this.filter.changed = true
                } else {
                    // if follow the sun filter is inactive, we have to check if
                    // there are active non-default filters. If none found,
                    // set filter changed flag to false. otherwise, retain its current value
                    this.filter.changed = !this.hasActiveNonDefaultFilters() ? false : this.filter.changed
                }

                VueEvent.fire('unfiltered_new_leads_unanswered_messages')
                this.$nextTick(() => {
                    VueEvent.fire('updateTextualContactFilters')
                })
            },

            ...mapActions(['setUnreadsCount'])
        }
    }
</script>
