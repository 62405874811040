var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("node-template", {
    attrs: { id: _vm.nodeId, hide_edit_button: true, onlyHeader: true },
    scopedSlots: _vm._u([
      {
        key: "header",
        fn: function () {
          return [_c("span", [_vm._v("Disengage")])]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }