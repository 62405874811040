<template>
    <el-tooltip
        class="item"
        effect="dark"
        :content="data.note"
        :disabled="!data.note || shouldHideTooltip"
        placement="bottom">

        <node-template :id="nodeId">
            <template v-slot:header>
                Appointment
            </template>

            <div class="row-inline mt-2">
                <div class="col-12">
                    <p v-if="data.note" v-text="data.note"/>
                    <p v-else>
                        Enter appointment note.
                    </p>
                </div>
                <hr>
                <div class="col-12 text-center">
                    <span><b>Date Message:</b> {{ data.date_message }}</span>
                </div>
                <div class="col-12 text-center">
                    <span><b>Time Message:</b> {{ data.time_message }}</span>
                </div>
                <div class="col-12 text-center">
                    <b>Service:</b> <span v-if="appointment_service">{{ `${appointment_service.name} (${appointment_service.duration} min.)` }}</span>
                </div>
                <hr>

                <Fallbacks
                    class="mt-1"
                    :fallbacks="data.fallback"
                    :showSuccess="true"/>

            </div>
        </node-template>
    </el-tooltip>
</template>

<script>
import { alohabot_node_mixin  } from '../../../mixins'
import Fallbacks from './fallback-component.vue'

export default {
    name: 'appointment-node',
    mixins: [
        alohabot_node_mixin
    ],
    components: {
        Fallbacks
    },
    props: {
        appointment_services: {
            required: false,
            default: () => []
        }
    },
    data() {
        return {
            appointment_service: null
        }
    },
    updated() {
        this.setSelectedAppointmentService()
    },
    methods: {
        /**
         * Get's the appointment service by its id.
         *
         * @param {int} id
         *
         * @return {object}
         */
        getAppointmentServiceById(id) {
            /** @var {object[]} service An array of a single service object. */
            let service = this.appointment_services.filter(service => {
                return service.id === id
            })

            if (service.length === 0) {
                // For future debugging.
                console.error(`Appointment Service ${id} not found.`, this.appointment_services)

                return
            }

            return service[0]
        },

        /**
         * Sets the selected appointment service to be displayed in the node.
         *
         * @return {void}
         */
        setSelectedAppointmentService() {
            this.appointment_service = this.getAppointmentServiceById(parseInt(this.data.service_id))
        }
    }
}
</script>

<style scoped>
.el-tooltip div p {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    /* number of lines to show */
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
}
</style>
