<template>
    <div>
        <el-dialog title="New List Created"
                   width="30%"
                   center
                   :visible.sync="converted_list_dialog"
                   :close-on-click-modal="false"
                   :close-on-press-escape="false"
                   :show-close="false">
            <span>
                The tag has been successfully converted to a contact list.
            </span>
            <span slot="footer"
                  class="dialog-footer">
                <el-button type="primary"
                           v-if="converted_list && converted_list.show_in_public_folder"
                           @click="openConvertedList">
                    Go to list
                </el-button>

                <router-link :to="{ name: 'Lists' }"
                             v-else>
                    <el-button type="primary"
                               icon="el-icon-tickets">
                        Go to lists
                    </el-button>
                </router-link>
            </span>
        </el-dialog>

        <el-table ref="tags_table_index"
                  v-loading="loading_tags"
                  :data="tags"
                  :default-sort="{prop: order_by, order: order}"
                  class="w-full"
                  stripe
                  @sort-change="onSortChange">
            <el-table-column
                prop="id"
                label="ID"
                width="100"
                sortable>
                <template v-slot="scope">
                    <span class="text-greyish">{{ scope.row.id }}</span>
                </template>
            </el-table-column>
            <el-table-column
                prop="updated_at"
                label="Date"
                width="200"
                sortable>
                <template v-slot="scope">
                    <span class="text-greyish">{{ scope.row.updated_at | fixDateTime }}</span>
                </template>
            </el-table-column>
            <el-table-column prop="color"
                             label="Color"
                             width="150"
                             align="center">
                <template v-slot="scope">
                    <i class="fa fa-circle"
                       :style="{ color: scope.row.color }"
                       v-if="!editingTag || editingTag.id != scope.row.id"></i>
                    <el-color-picker v-model="editingTag.color"
                                     class="d-block"
                                     @active-change="changeUpdateTagColor"
                                     v-else>
                    </el-color-picker>
                </template>
            </el-table-column>
            <el-table-column prop="name"
                             label="Name"
                             :min-width="200"
                             sortable>
                <template v-slot="scope">
                    <span class="text-greyish"
                          :title="scope.row.name"
                          v-if="!editingTag || editingTag.id != scope.row.id">
                        {{ scope.row.name }}
                    </span>
                    <el-input v-model="editingTag.name"
                              placeholder="Name"
                              v-else>
                    </el-input>
                </template>
            </el-table-column>
            <el-table-column label="Description"
                             :min-width="200">
                <template v-slot="scope">
                    <span class="text-greyish"
                          v-if="!editingTag || editingTag.id != scope.row.id">
                        {{ scope.row.description || '-' }}
                    </span>
                    <el-input v-model="editingTag.description"
                              placeholder="Description"
                              type="textarea"
                              rows="2"
                              maxlength="190"
                              show-word-limit
                              v-else>
                    </el-input>
                </template>
            </el-table-column>
            <el-table-column prop="communications_count"
                             label="Number Of Communications"
                             :min-width="200"
                             v-if="(!hasReporterAccess && filter === 'communication')"
                             sortable>
                <template v-slot="scope">
                    <span v-if="scope.row.type === 2">-</span>
                    <template v-else>
                        <i class="fa fa-spin fa-spinner pull-left"
                           v-if="loading_extend_tag[scope.row.id]"></i>
                        <el-tooltip class="item"
                                    effect="dark"
                                    content="Click to refresh"
                                    placement="top"
                                    v-else>
                            <b class="label danger rounded m-l-xs pointer"
                               @click="extendTag(scope.row.id)">
                                {{ scope.row.communications_count }}
                            </b>
                        </el-tooltip>
                    </template>
                </template>
            </el-table-column>
            <el-table-column prop="contacts_count"
                             label="Number Of Contacts"
                             :min-width="200"
                             v-if="!hasReporterAccess && (filter === 'contact' || filter === 'import')"
                             sortable>
                <template v-slot="scope">
                    <span v-if="scope.row.type === 2">-</span>
                    <template v-else>
                        <i class="fa fa-spin fa-spinner pull-left"
                           v-if="loading_extend_tag[scope.row.id]"></i>
                        <el-tooltip class="item"
                                    effect="dark"
                                    content="Click to refresh"
                                    placement="top"
                                    v-else>
                            <b class="label danger rounded m-l-xs pointer"
                               @click="extendTag(scope.row.id)">
                                {{ scope.row.contacts_count }}
                            </b>
                        </el-tooltip>
                    </template>
                </template>
            </el-table-column>
            <el-table-column label="Operations"
                             align="center"
                             width="190"
                             fixed="right"
                             v-if="!hasReporterAccess">
                <template v-slot="scope">
                    <div v-if="scope.row.company_id">
                        <div v-if="!editingTag || editingTag.id != scope.row.id">
                            <div class="row mb-2"
                                 v-if="scope.row.communications_count">
                                <div class="col-12">
                                    <router-link
                                        :to="{name: 'Dashboard', query: {tag_id: scope.row.id}}">
                                        <button class="btn btn-sm btn-block purplish">
                                            <i class="fa fa-signal pull-left"></i>
                                            Communications
                                        </button>
                                    </router-link>
                                </div>
                            </div>

                            <div class="row mb-2"
                                 v-if="scope.row.contacts_count">
                                <div class="col-12">
                                    <a :href="talk2Url + '/contacts?tag_id=' + scope.row.id"
                                       target="_blank"
                                       v-if="forceTalk">
                                        <button class="btn btn-sm btn-block light-blue-500">
                                            <i class="fa fa-user pull-left"></i>
                                            Contacts
                                        </button>
                                    </a>
                                    <router-link :to="{name: 'Contacts', query: {tag_id: scope.row.id}}"
                                                 v-else>
                                        <button class="btn btn-sm btn-block light-blue-500">
                                            <i class="fa fa-user pull-left"></i>
                                            Contacts
                                        </button>
                                    </router-link>
                                </div>
                            </div>

                            <template v-if="hasRole('Company Admin')">
                                <div class="row mb-2"
                                     v-if="scope.row.contacts_count > 50">
                                    <div class="col-12">
                                        <tag-splitter
                                            :contacts_count="scope.row.contacts_count"
                                            :tag_id="scope.row.id">
                                        </tag-splitter>
                                    </div>
                                </div>

                                <div class="row mb-2">
                                    <div class="col-12"
                                         v-if="tag_filter == 'contact'">
                                        <convert-to-contacts-list :tag="scope.row"
                                                                  :enabled="scope.row.contacts_count > 0"
                                                                  @list-converted="onListConverted">
                                        </convert-to-contacts-list>
                                    </div>
                                </div>

                                <div class="row mb-2"
                                     v-if="scope.row.contacts_count">
                                    <div class="col-12">
                                        <assign-contacts-by-tag :tag="scope.row"></assign-contacts-by-tag>
                                    </div>
                                </div>

                                <div class="row mb-2"
                                     v-if="scope.row.contacts_count && current_company && current_company.auto_dialer_enabled">
                                    <div class="col-12">
                                        <add-tasks-to-user-power-dialer :tag="scope.row"></add-tasks-to-user-power-dialer>
                                    </div>
                                </div>

                                <div class="row mb-2"
                                     v-if="scope.row.contacts_count && current_company && current_company.automation_enabled">
                                    <div class="col-12">
                                        <workflow-bulk-enroller model="tag"
                                                                :id="scope.row.id">
                                            Enroll Contacts
                                        </workflow-bulk-enroller>
                                    </div>
                                </div>

                                <div class="row mb-2"
                                     v-if="hasPermissionTo('update tag')">
                                    <div class="col-12">
                                        <button class="btn btn-sm btn-block info"
                                                @click="editTag(scope.row)">
                                            <i class="fa fa-pencil pull-left"></i>
                                            Edit
                                        </button>
                                    </div>
                                </div>

                                <div class="row"
                                     v-if="hasPermissionTo('delete tag')">
                                    <div class="col-12">
                                        <button class="btn btn-sm btn-block danger"
                                                @click="toggleDialog(scope.row.id, scope.row.contacts_count)">
                                            <i class="fa fa-trash pull-left"></i>
                                            Delete
                                        </button>
                                    </div>
                                </div>
                            </template>
                        </div>
                        <div v-else>
                            <div class="row">
                                <div class="col-12">
                                    <button class="btn btn-sm btn-block primary"
                                            :disabled="loading_save"
                                            v-if="hasPermissionTo('update tag')"
                                            @click="saveTag">
                                        <i class="material-icons loader"
                                           v-show="loading_save">&#xE863;</i>
                                        <i class="fa fa-save pull-left"
                                           v-show="!loading_save"></i>
                                        Save
                                    </button>
                                </div>
                            </div>

                            <div class="row mt-2">
                                <div class="col-12">
                                    <button class="btn btn-sm btn-block danger"
                                            @click="cancelEdit">
                                        <i class="fa fa-close pull-left"></i>
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div :style="getStyle(scope.row.id)"
                         v-else>
                        <div class="row">
                            <div class="col-12">
                                <router-link
                                    :to="{name: 'Dashboard', query: {tag_id: scope.row.id}}">
                                    <button class="btn btn-sm btn-block purplish">
                                        <i class="fa fa-signal pull-left"></i>
                                        Communications
                                    </button>
                                </router-link>
                            </div>
                        </div>
                    </div>
                </template>
            </el-table-column>
        </el-table>

        <div class="mt-3"
             v-if="last_page >= 1">
            <el-pagination class="pull-right"
                           background
                           layout="sizes, prev, pager, next"
                           :page-sizes="[10, 20, 50, 100, 250]"
                           :page-size="per_page"
                           :total="total"
                           :current-page="current_page"
                           @current-change="onPageChange"
                           @size-change="onSizeChange">
            </el-pagination>
            <div class="clearfix"></div>
        </div>
    </div>
</template>

<script>
import auth from '../../auth'
import {acl_mixin} from '../../mixins'
import {mapState} from 'vuex'
import * as TagTypes from '../../constants/tag-types'
import * as TagCategory from '../../constants/tag-categories'
import TagSplitter from "../../components/tag-splitter"
import AssignContactsByTag from "../../components/assign-contacts-by-tag"
import AddTasksToUserPowerDialer from '../../components/add-tasks-to-user-power-dialer'
import ConvertToContactsList from '../../components/convert-to-contacts-list'
import WorkflowBulkEnroller from '../../components/automations/workflow-bulk-enroller'

export default {
    mixins: [acl_mixin],

    props: {
        tag_filter: {
            type: String,
            required: false,
            default: 'contact'
        },
        search_text: {
            type: String,
            required: false,
            default: ''
        },
    },

    components: {
        TagSplitter,
        AssignContactsByTag,
        AddTasksToUserPowerDialer,
        WorkflowBulkEnroller,
        ConvertToContactsList
    },

    data() {
        return {
            auth,
            tags: [],
            filter: 'contact',
            editingTag: null,
            loading_tags: false,
            loading_save: false,
            loading_extend_tag: [],
            order_by: 'id',
            order: 'descending',
            page: 1,
            current_page: 1,
            per_page: 20,
            total: 0,
            last_page: null,
            converted_list_dialog: false,
            converted_list: null
        }
    },

    computed: {
        ...mapState('cache', ['current_company']),

        talk2Url () {
            return this.getTalkUrl()
        }
    },

    created() {
        VueEvent.listen('tag_created', (tag) => {
            this.addTag(tag)
        })
        VueEvent.listen('tag_updated', (tag) => {
            this.updateTag(tag)
            this.extendTag(tag.id)
        })
        VueEvent.listen('tag_deleting', (tag) => {
            this.deleteTag(tag.id)
        })
    },

    mounted() {
        if ('tag_filter' in this.$route.query) {
            this.filter = this.$route.query.tag_filter.length > 0 ? this.$route.query.tag_filter : this.tag_filter
        }

        this.loadTags()
    },

    methods: {
        extendTag(tag_id) {
            this.$set(this.loading_extend_tag, tag_id, true)
            axios.get('/api/v1/tag/' + tag_id).then(res => {
                this.updateTag(res.data)
                this.$set(this.loading_extend_tag, tag_id, false)
            }).catch(err => {
                this.$root.handleErrors(err.response)
                this.$set(this.loading_extend_tag, tag_id, false)
            })
        },

        onSortChange({column, prop: order_by, order}) {
            if (order) {
                this.order_by = order_by
                this.order = order
                this.loadTags()
            }
        },

        onPageChange(page) {
            this.page = page
            this.loadTags()
        },

        onSizeChange(per_page) {
            this.per_page = per_page
            this.loadTags()
        },

        saveTag() {
            this.loading_save = true
            axios.patch('/api/v1/tag/' + this.editingTag.id, this.editingTag).then(res => {
                this.loading_save = false
                const {data: tag} = res
                this.updateTag(tag)
                this.cancelEdit()
            }).catch(err => {
                this.$root.handleErrors(err.response)
                this.loading_save = false
                this.cancelEdit()
            })
        },

        cancelEdit() {
            this.editingTag = null
        },

        editTag(tag) {
            this.editingTag = {
                id: tag.id,
                color: tag.color,
                name: tag.name,
                description: tag.description,
                category: tag.category
            }
        },

        changeUpdateTagColor(val) {
            this.editingTag.color = val
        },

        toggleDialog(tag_id, tagged_contacts_count) {
            this.$emit('toggle-dialog-triggered', {tag_id, tagged_contacts_count})
        },

        loadTags() {
            if (this.hasPermissionTo('list tag')) {
                this.loading_tags = true
                const {category, type} = this.getCategoryAndType()

                // If the tag type is changed, and the order_by is set to a column that the type
                // doesn't have, switch back to default order_by
                let orderCommunicationByInexistentColumn =
                    this.tag_filter == 'communication' && this.order_by == 'contacts_count'
                let orderContactByInexistentColumn =
                    (this.tag_filter == 'import' || this.tag_filter == 'contact')
                    && this.order_by == 'communications_count'

                if (orderCommunicationByInexistentColumn || orderContactByInexistentColumn) {
                    this.$refs.tags_table_index.clearSort()
                    this.order_by = 'id'
                    this.order = 'descending'
                }

                let params = {
                    category,
                    type,
                    search_text: this.search_text,
                    per_page: this.per_page,
                    order_by: this.order_by,
                    order: this.order,
                    page: this.page
                }

                axios.get('/api/v1/tag-new', {params})
                    .then(res => {
                        this.loading_tags = false
                        this.tags = res.data.data
                        this.current_page = res.data.current_page
                        this.total = res.data.total
                        this.last_page = res.data.last_page
                        this.$emit('tags-count', res.data.total)
                    })
                    .catch(err => {
                        this.loading_tags = false
                        console.log(err)
                    })
            }
        },

        getCategoryAndType() {
            let category, type

            switch (this.filter) {
                case 'contact':
                    category = TagCategory.CAT_CONTACTS
                    break;
                case 'communication':
                    category = TagCategory.CAT_COMMUNICATIONS
                    break;
                case 'import':
                    category = TagCategory.CAT_CONTACTS
                    type = TagTypes.TYPE_IMPORT
                    break;
                default:
                    category = TagCategory.CAT_CONTACTS
            }

            return {category, type}
        },

        resourceExists(arr, resource) {
            return arr.some(item => item.id === resource.id)
        },

        addTag(tag) {
            if (this.resourceExists(this.tags, tag)) {
                return
            }

            if (tag.category == TagCategory.CAT_CONTACTS && this.filter == 'contact') {
                this.tags.push(tag)
            }

            if (tag.category == TagCategory.CAT_COMMUNICATIONS && this.filter == 'communication') {
                this.tags.push(tag)
            }
        },

        updateTag(updated_tag) {
            let index = this.tags.findIndex(tag => tag.id === updated_tag.id)

            if (index > -1) {
                Vue.set(this.tags, index, updated_tag)
            }
        },

        deleteTag(deleted_tag_id) {
            let index = this.tags.findIndex(tag => tag.id === deleted_tag_id)

            if (index > -1) {
                this.tags.splice(index, 1)
            }
        },

        onListConverted (list) {
            this.converted_list_dialog = true
            this.converted_list = list
        },

        openConvertedList() {
            let talk_url = this.talk2Url

            talk_url += `/contacts/list/${this.converted_list.id}?type=public`
            window.location.href = talk_url
        }
    },

    watch: {
        tag_filter(val) {
            this.filter = val
        }
    }
}
</script>
