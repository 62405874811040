var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        width: "35vw",
        top: "10vh",
        "append-to-body": "",
        "before-close": _vm.closeDialog,
        visible: _vm.show_dialog,
      },
      on: {
        "update:visible": function ($event) {
          _vm.show_dialog = $event
        },
      },
    },
    [
      _c("div", { attrs: { slot: "title" }, slot: "title" }, [
        !_vm.active_step
          ? _c("h5", [
              _vm._v("Which contact do you want to merge "),
              _c("b", [_vm._v(_vm._s(_vm.fromContact))]),
              _vm._v(" with ?"),
            ])
          : _c("h5", [
              _vm._v(
                "Merge " +
                  _vm._s(_vm.fromContact) +
                  " with " +
                  _vm._s(_vm.toContact)
              ),
            ]),
      ]),
      _vm._v(" "),
      _c(
        "el-steps",
        {
          attrs: {
            active: _vm.active_step,
            "finish-status": "success",
            "align-center": "",
          },
        },
        [
          _c("el-step", { attrs: { title: "Select Contact" } }),
          _vm._v(" "),
          _c("el-step", { attrs: { title: "Merge Contact" } }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticClass: "mt-3",
        },
        [
          _vm.active_step === 0
            ? _c(
                "el-select",
                {
                  staticClass: "w-100",
                  attrs: {
                    "data-testid": "merge-contact-search",
                    filterable: "",
                    clearable: "",
                    remote: "",
                    "value-key": "id",
                    placeholder: "Search Contact...",
                    "remote-method": _vm.fetchContact,
                    loading: _vm.contact_searching,
                    "no-data-text": _vm.no_data_text,
                  },
                  model: {
                    value: _vm.selected_contact,
                    callback: function ($$v) {
                      _vm.selected_contact = $$v
                    },
                    expression: "selected_contact",
                  },
                },
                _vm._l(_vm.filteredContacts, function (contact) {
                  return _c(
                    "el-option",
                    {
                      key: contact.id,
                      staticClass: "p-0",
                      attrs: { label: contact.name, value: contact },
                    },
                    [
                      _c("div", { staticClass: "media" }, [
                        _c("div", { staticClass: "media-body" }, [
                          _c("label", [
                            _vm._v(
                              _vm._s(_vm._f("fixContactName")(contact.name))
                            ),
                          ]),
                          _vm._v(" "),
                          _c("small", [
                            _vm._v(
                              _vm._s(_vm._f("fixPhone")(contact.phone_number))
                            ),
                          ]),
                        ]),
                      ]),
                    ]
                  )
                }),
                1
              )
            : _c(
                "div",
                [
                  _c("h6", [
                    _vm._v("The following "),
                    _c("span", { staticClass: "text-black" }, [
                      _vm._v(_vm._s(_vm.fromContact)),
                    ]),
                    _vm._v(" info will be added to the "),
                    _c("span", { staticClass: "text-black" }, [
                      _vm._v(_vm._s(_vm.toContact)),
                    ]),
                    _vm._v(" Contact:"),
                  ]),
                  _vm._v(" "),
                  _c("ul", [
                    _c("li", [_vm._v("Phone Number")]),
                    _vm._v(" "),
                    _c("li", [_vm._v("Power Dialer Tasks")]),
                    _vm._v(" "),
                    _c("li", [_vm._v("Communications")]),
                    _vm._v(" "),
                    _c("li", [_vm._v("Tags")]),
                    _vm._v(" "),
                    _c("li", [_vm._v("Future Scheduled Messages")]),
                    _vm._v(" "),
                    _c("li", [_vm._v("Ring Groups")]),
                    _vm._v(" "),
                    _c("li", [
                      _vm._v("Lines "),
                      _c("span", { staticClass: "pull-right" }, [
                        _vm._v("(if doesn't exist)"),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("li", [
                      _vm._v("Initial Line "),
                      _c("span", { staticClass: "pull-right" }, [
                        _vm._v("(if doesn't exist)"),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("li", [
                      _vm._v("Contact Disposition "),
                      _c("span", { staticClass: "pull-right" }, [
                        _vm._v("(if doesn't exist)"),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("li", [
                      _vm._v("Owner "),
                      _c("span", { staticClass: "pull-right" }, [
                        _vm._v("(if doesn't exist)"),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("el-divider"),
                  _vm._v(" "),
                  _c("h6", [
                    _vm._v("The "),
                    _c("span", { staticClass: "text-black" }, [
                      _vm._v(_vm._s(_vm.fromContact)),
                    ]),
                    _vm._v(
                      " contact will be deleted and its following info will be lost:"
                    ),
                  ]),
                  _vm._v(" "),
                  _c("ul", [
                    _c("li", [
                      _vm._v(
                        "Contact Information (e.g. First Name, Last Name, etc.)"
                      ),
                    ]),
                    _vm._v(" "),
                    _c("li", [_vm._v("Contact Audits")]),
                    _vm._v(" "),
                    _c("li", [_vm._v("Active Broadcast or Sequence")]),
                  ]),
                  _vm._v(" "),
                  _c("el-divider"),
                  _vm._v(" "),
                  _c("h6", [_vm._v("Are you sure you want to merge?")]),
                ],
                1
              ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "dialog-footer d-flex",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              staticClass: "flex-grow-1",
              attrs: { "data-testid": "merge-cancel-button" },
              on: {
                click: function ($event) {
                  _vm.active_step ? _vm.backToSearch() : _vm.closeDialog()
                },
              },
            },
            [_vm._v(_vm._s(_vm.active_step ? "Back" : "Cancel"))]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticClass: "flex-grow-1",
              attrs: {
                disabled: !_vm.selected_contact,
                type: "success",
                "data-testid": "merge-review-button",
              },
              on: {
                click: function ($event) {
                  _vm.active_step ? _vm.mergeContact() : _vm.reviewMerge()
                },
              },
            },
            [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.active_step ? "Merge" : "Review") +
                  "\n        "
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }