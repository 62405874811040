<template>
    <div v-if="hasPermissionTo('create contact')" 
         data-testid="contact-creator-wrapper">
        <!--create new contact-->
        <el-dialog title="Create a new contact"
                   class="dialog-padding auto"
                   :width="isLargeEnough ? '700px' : '100%'"
                   :visible.sync="dialogVisible"
                   :before-close="beforeCloseModal"
                   append-to-body
                   data-testid="create-contact-dialog">
            <el-form ref="create_contact"
                     data-testid="create-contact-form"
                     label-width="200px"
                     label-position="top"
                     :model="new_contact"
                     :rules="rules_create_contact"
                     @submit.prevent.native="createContact">
                <div class="row">
                    <div class="col-6">
                        <el-form-item label="First Name (Optional)"
                                      prop="first_name"
                                      data-testid="first-name-form-item">
                            <el-input v-model="new_contact.first_name"
                                      placeholder="John"
                                      data-testid="first-name-input">
                            </el-input>
                        </el-form-item>
                    </div>
                    <div class="col-6">
                        <el-form-item label="Last Name (Optional)"
                                      prop="last_name"
                                      data-testid="last-name-form-item">
                            <el-input v-model="new_contact.last_name"
                                      placeholder="Doe"
                                      data-testid="last-name-input">
                            </el-input>
                        </el-form-item>
                    </div>
                </div>
                <div class="row">
                    <div class="col-6">
                        <el-form-item label="Phone Number"
                                      prop="phone_number"
                                      data-testid="phone-form-item">
                            <el-input v-model="new_contact.phone_number"
                                      placeholder="+18185005050"
                                      data-testid="phone-number-input"
                                      @input="preValidateForm('create_contact')">
                            </el-input>
                        </el-form-item>
                    </div>
                    <div class="col-6">
                        <el-form-item label="Email Address (Optional)"
                                      prop="email"
                                      data-testid="email-form-item">
                            <el-input v-model="new_contact.email"
                                      placeholder="john.doe@email.com"
                                      data-testid="email-input"
                                      @input="preValidateForm('create_contact')">
                            </el-input>
                        </el-form-item>
                    </div>
                </div>
                <div class="row">
                    <div class="col-6">
                        <el-form-item label="Initial Line (Optional)"
                                      prop="initial_campaign_id"
                                      data-testid="line-selector">
                            <el-select placeholder="Select Initial Line"
                                       v-model="new_contact.initial_campaign_id"
                                       class="no-select w-full"
                                       filterable
                                       clearable
                                       data-testid="initial-line-selects"
                                       @change="preValidateForm('create_contact')">
                                <el-option v-for="campaign in campaignsAlphabeticalOrder"
                                           :key="campaign.id"
                                           :label="campaign.name"
                                           :value="campaign.id">
                                    <span>{{ campaign.name }}</span>
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </div>
                    <div class="col-6"
                         v-if="hasPermissionTo('list user')">
                        <el-form-item label="Owner (Optional)"
                                      prop="user_id"
                                      data-testid="owner-form-item">
                            <user-selector v-model="new_contact.user_id"
                                           data-testid="owner-selector"
                                           @change="changeUserId">
                            </user-selector>
                        </el-form-item>
                    </div>
                </div>
                <el-form-item label="Tags (Optional)"
                              prop="tag_ids"
                              data-testid="tags-form-item">
                    <tag-creator ref="addTag"
                                 data-testid="tag-creator"
                                 @success="addTagToNewContact"
                                 @show="showAdd"
                                 @hide="hideAdd">
                    </tag-creator>
                    <div class="row"
                         v-if="hasPermissionTo(['list tag', 'view tag'])"
                         v-show="!hide_add">
                        <div class="col-12">
                            <tag-selector v-model="new_contact.tag_ids"
                                          :multiple="true"
                                          data-testid="tag-selector"
                                          @change="changeTags($event, new_contact)">
                            </tag-selector>
                        </div>
                    </div>
                </el-form-item>
            </el-form>

            <span slot="footer"
                  class="dialog-footer">
                    <el-button 
                          data-testid="create-contact-cancel-button"
                          @click="cancelNewContact">
                             Cancel
                    </el-button>
                    <el-popover v-if="!canCreateContacts"
                                data-testid="create-contacts-popover"
                                ref="create-contacts-dialog"
                                placement="top"
                                width="200"
                                class="custom-popover"
                                popper-class="btn-primary"
                                trigger="hover">
                        <custom-message-display :config="customMessage('contacts.create')" />
                    </el-popover>
                    <el-button type="success"
                               :loading="loading_save_btn"
                               v-popover:create-contacts-dialog
                               :disabled="!validated || !canCreateContacts"
                               data-testid="create-contact-save-button"
                               @click="createContact">
                        Save
                    </el-button>
                </span>
        </el-dialog>

        <slot name="trigger">
            <el-popover v-if="!canCreateContacts"
                        ref="add-contact"
                        placement="top"
                        width="200"
                        class="custom-popover"
                        popper-class="btn-primary"
                        data-testid="add-contact-popover"
                        trigger="hover">
                <custom-message-display data-testid="custom-message-display" 
                                        :config="customMessage('contacts.create')" />
            </el-popover>

            <el-popover v-if="!canCreateContacts"
                        ref="create-contacts-mobile"
                        placement="top"
                        width="200"
                        class="custom-popover"
                        popper-class="btn-primary"
                        trigger="hover"
                        data-testid="add-contact-popover">
                <custom-message-display data-testid="custom-message-display" 
                                        :config="customMessage('contacts.create')" />
            </el-popover>

            <!--add contact (desktop screen)-->
            <button v-if="isLargeEnough"
                    v-popover:add-contact
                    class="btn greenish"
                    :disabled="!canCreateContacts"
                    data-testid="add-contact-button-in-desktop"
                    @click="openModal">
                <i class="material-icons">add</i>
                Add Contact
            </button>

            <!--add contact (mobile screen)-->
            <button v-else
                    v-popover:create-contacts-mobile
                    class="md-btn md-fab m-b-sm greenish abs-bottom abs-right"
                    :disabled="!canCreateContacts"
                    data-testid="add-contact-button-in-mobile"
                    @click="openModal">
                <i class="material-icons md-24">add</i>
            </button>
        </slot>
    </div>
</template>

<script>
    import auth from '../auth'
    import {mapState} from 'vuex'
    import {
        acl_mixin,
        validator_mixin,
        form_validation_mixin,
        kyc_mixin
    } from '../mixins'
    import TagCreator from './tag-creator'
    import BlockTooltip from './kyc/block-tooltip'
    import CustomMessageDisplay from './kyc/custom-message-display'

    export default {
        mixins: [
            acl_mixin,
            validator_mixin,
            form_validation_mixin,
            kyc_mixin
        ],

        components: { TagCreator, BlockTooltip, CustomMessageDisplay },

        data() {
            return {
                auth: auth,
                dialogVisible: false,
                loading_save_btn: false,
                new_contact: {
                    first_name: null,
                    last_name: null,
                    phone_number: null,
                    email: null,
                    initial_campaign_id: null,
                    user_id: null,
                    tag_ids: []
                },
                rules_create_contact: {
                    first_name: [
                        {
                            validator: this.firstNameValidator,
                            trigger: 'blur'
                        }
                    ],
                    last_name: [
                        {
                            validator: this.lastNameValidator,
                            trigger: 'blur'
                        }
                    ],
                    phone_number: [
                        {
                            validator: this.phoneValidator,
                            trigger: 'blur'
                        }
                    ],
                    email: [
                        {
                            required: false,
                            message: 'Please provide an email address',
                            trigger: 'blur'
                        },
                        {
                            type: 'email',
                            message: 'Please provide correct email address',
                            trigger: 'blur'
                        }
                    ],
                },
                hide_add: false
            }
        },

        computed: {
            isLargeEnough() {
                let valid_sizes = ['xxl', 'xl', 'lg', 'md']
                if (valid_sizes.includes(this.$mq)) {
                    return true
                }
                return false
            },

            campaignsAlphabeticalOrder() {
                if (this.campaigns) {
                    let campaigns = _.clone(this.campaigns)
                    return campaigns.sort((a, b) => {
                        let textA = a.name.toUpperCase()
                        let textB = b.name.toUpperCase()
                        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0
                    })
                }

                return []
            },

            canCreateContacts () {
                return this.enabledToCreateContacts()
            },

            ...mapState(['campaigns'])
        },

        methods: {
            showAdd() {
                this.hide_add = false
            },

            hideAdd() {
                this.hide_add = true
            },

            addTagToNewContact(tag) {
                this.new_contact.tag_ids.push(tag.id)
            },

            createContact() {
                if (this.validateForm('create_contact') === true) {
                    this.loading_save_btn = true
                    axios.post('/api/v1/contact', this.new_contact)
                        .then(res => {
                            if (!res.data.is_newly_added) {
                                return this.notifyUserForExistingContact(res.data)
                            }

                            this.resetNewContact()
                            this.dialogVisible = false
                            this.$router.push({name: 'Contact', params: {contact_id: res.data.id}}).catch(err => {})
                        })
                        .catch(err => {
                            console.log(err)
                            this.$root.handleErrors(err.response)
                            this.resetNewContact()
                            this.dialogVisible = false
                        })
                        .finally(_ => {
                            this.loading_save_btn = false
                        })
                } else {
                    return false
                }
            },

            notifyUserForExistingContact(contact) {
                let display = contact.name ? contact.name : contact.phone_number

                this.$confirm(`This contact's phone number already exists and is assigned to an existing contact, <b>${display}</b>. <div class="mt-2">This contact requires a unique phone number.</div>`, 'Phone Number Already Exists', {
                    confirmButtonText: `Remove this contact & Open ${display}`,
                    cancelButtonText: 'Go back to Contact',
                    customClass: 'width-500 fixed contact-merge-confirm',
                    confirmButtonClass: 'merge-confirm-button',
                    cancelButtonClass: 'el-button--primary',
                    dangerouslyUseHTMLString: true,
                    type: 'info'
                }).then(() => {
                    this.resetNewContact()
                    this.dialogVisible = false
                    this.$router.push({name: 'Contact', params: {contact_id: contact.id}}).catch(err => {})
                }).catch(() => {})
            },

            openModal() {
                this.dialogVisible = true
                this.resetNewContact()
            },

            resetNewContact() {
                this.new_contact.first_name = null
                this.new_contact.last_name = null
                this.new_contact.phone_number = null
                this.new_contact.email = null
                this.new_contact.initial_campaign_id = null
                this.new_contact.tag_ids = []
                this.new_contact.user_id = this.auth.user.profile.id
            },

            cancelNewContact() {
                this.dialogVisible = false
                this.resetNewContact()
                this.preValidateForm('create_contact', true)
            },

            beforeCloseModal(done) {
                if (this.new_contact.phone_number || this.new_contact.first_name || this.new_contact.last_name || this.new_contact.email) {
                    this.$confirm('Are you sure you want to leave? Your changes will not be saved.', 'Warning', {
                        confirmButtonText: 'Yes, Leave',
                        cancelButtonText: 'No, Stay',
                        customClass: 'width-500 fixed',
                        type: 'warning'
                    }).then(res => {
                        this.resetNewContact()
                        this.preValidateForm('create_contact', true)
                        done()
                    }).catch(() => {

                    })
                } else {
                    this.resetNewContact()
                    done()
                }
            },

            closeModal() {
                this.dialogVisible = false
            },

            changeTags(event, model) {
                model.tag_ids = event
            },

            changeUserId(user_id) {
                this.new_contact.user_id = user_id
            }
        }
    }
</script>
