<template>
    <div class="d-flex flex-fill flex-wrap wrap-auto-margin"
         v-on-clickaway="closeAllMenus">

        <!-- Save as Tag -->
        <div v-if="hasPermissionTo('create tag') && changed && !filter_options.is_bulk && !filter_options.no_save_as_tag">
            <tag-creator id="add-tag"
                         ref="addTag"
                         :title="'Save as Tag'"
                         :is_filter_tags="true"
                         :filters="custom_filter">
                <template v-slot:trigger>
                    <button class="btn btn-md rounded purple"
                            data-testid="save-as-tag-button"
                            @click="$refs.addTag.hide_add = true">
                        Save as Tag
                    </button>
                </template>
            </tag-creator>
        </div>

        <!-- Communication Sort -->
        <div v-if="has_sort_communications">
            <div class="filter-div white compact no-bottom-margin bold-label no-select width-150">
                <el-form>
                    <el-form-item>
                        <el-select
                            class="filter-select"
                            v-model="comm_sort_by"
                            data-testid="communicate-sort-select"
                            @change="changeCommSortBy">
                            <el-option
                                v-for="option in comm_sort_by_options"
                                :key="option.value"
                                :label="option.label"
                                :value="option.value"
                                :disabled="option.disabled"
                                :title="option.tooltip">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-form>
            </div>
        </div>

        <!-- Communicate Date Sort -->
        <div>
            <div class="filter-div white compact no-bottom-margin bold-label no-select width-160">
                <el-form>
                    <el-form-item>
                        <el-select
                            class="filter-select"
                            popper-class="min-width-240"
                            placeholder="Select Date Field"
                            v-model="date_field"
                            data-testid="communicate-date-sort-select"
                            @change="changeDateField">
                            <el-option
                                v-for="(field, key) in date_field_option"
                                :key="key"
                                :label="field.label"
                                :value="field.value">
                                <div class="d-flex">
                                    {{ field.label }}
                                     <el-popover
                                         placement="right"
                                         width="400"
                                         class="ml-auto"
                                         trigger="hover">
                                         <div v-html="field.info"></div>
                                         <i
                                            :class="`${field.icon} ml-2`"
                                            slot="reference">
                                         </i>
                                      </el-popover>
                                </div>
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-form>
            </div>
        </div>

        <!-- Timeframe (Date Selector) -->
        <div v-if="!filter_options.no_float">
            <date-selector :is_bulk="filter_options.is_bulk"
                           :default_date_range="default_date_range"
                           :disable_default_report_period="true"
                           data-testid="timeframe-date-selector"
                           @updateCustomFilter="updateCustomFilter">
            </date-selector>
        </div>

        <!-- Timeframe (Date Selector) -->
        <div v-if="filter_options.no_float"
             class="pb-2">
            <date-selector :default_date_range="default_date_range"
                           :disable_default_report_period="true"
                           data-testid="timeframe-no-flat-date-selector"
                           @updateCustomFilter="updateCustomFilter">
            </date-selector>
        </div>

        <!-- Filters -->
        <div class="no-select filters-menu">
            <button type="button"
                    :class="{'w-full': filter_options.no_float, 'deep-orange': changed}"
                    class="btn btn-md white"
                    style="height: 40px"
                    data-testid="filter-w-full-button"
                    @click="toggleFiltersMenu"
                    v-if="checkSize()">
                Filters
            </button>

            <button type="button"
                    class="btn btn-md white"
                    :class="[changed ? 'deep-orange' : '']"
                    style="height: 40px"
                    data-testid="filter-md-button"
                    @click="toggleFiltersMenu"
                    v-else>
                <i class="fa fa-filter"></i>
            </button>

            <el-dialog title="Filters"
                       :width="isLargeEnough ? '50%' : '100%'"
                       :visible.sync="filters_menu_visible"
                       :before-close="closeFiltersMenu"
                       top="10vh"
                       append-to-body>
                <div class="row nav-padding lean-bottom-margin bold-label right-align">
                    <div class="col-12"
                         v-if="!only_campaign_selector">
                        <span class="filter-header text-muted">Contact Properties</span>
                        <div class="dropdown-divider"></div>
                        <el-form v-if="!filter_options.is_bulk"
                                 label-position="left"
                                 label-width="150px">
                            <el-form-item label="Line Type">
                                <el-select class="filter-select"
                                           v-model="lrn_types"
                                           placeholder="Select Line Type"
                                           data-testid="contact-property-line-type-select"
                                           @change="changeLrnTypes"
                                           multiple
                                           collapse-tags>
                                    <el-option v-for="lrn_type in LrnTypes"
                                               :key="lrn_type"
                                               :label="lrn_type | fixLrnType"
                                               :value="lrn_type">
                                        <span>{{ lrn_type | fixLrnType }}</span>
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-form>

                        <el-form label-position="left"
                                 label-width="150px">
                            <el-form-item label="Intake Source">
                                <source-selector source_type="Intake Source"
                                                 :value="intake_source"
                                                 :sources="IntakeSources"
                                                 data-testid="contact-property-intake-source-select"
                                                 @change="changeIntakeSource">
                                </source-selector>
                            </el-form-item>
                        </el-form>

                        <el-form label-position="left"
                                 label-width="150px">
                            <el-form-item label="Lead Source">
                                <source-selector source_type="Lead Source"
                                                 :value="lead_source"
                                                 :sources="leadSources"
                                                 data-testid="contact-property-lead-source-select"
                                                 @change="changeLeadSource">
                                </source-selector>
                            </el-form-item>
                        </el-form>

                        <el-form label-position="left"
                                 label-width="150px">
                            <el-form-item label="Tag">
                                <tag-selector :value="filter_tags"
                                              :multiple="true"
                                              :category_prop="TagCategory.CAT_CONTACTS"
                                              class="filter-select"
                                              v-model="filter_tags"
                                              loaded
                                              data-testid="contact-property-filter-tags-select"
                                              @change="changeTags">
                                </tag-selector>
                            </el-form-item>
                        </el-form>

                        <el-form label-position="left"
                                 label-width="150px">
                            <el-form-item label="Does Not Have Tag">
                                <tag-selector :value="filter_doesnt_have_tags"
                                              :multiple="true"
                                              class="filter-select"
                                              v-model="filter_doesnt_have_tags"
                                              loaded
                                              data-testid="contact-property-without-tag-select"
                                              @change="changeDoesntHaveTags">
                                </tag-selector>
                            </el-form-item>
                        </el-form>

                        <el-form label-position="left"
                                 label-width="150px">
                            <el-form-item label="Country">
                                <el-select v-model="filter_contact_country"
                                           placeholder="Select Country"
                                           class="filter-select"
                                           filterable
                                           clearable
                                           data-testid="contact-property-country-select"
                                           @change="changeContactCountry">
                                    <el-option v-for="country in countries"
                                               :key="country.id"
                                               :label="country.name"
                                               :value="country.id">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-form>

                        <el-form ref="states-limit-form"
                                 label-position="left"
                                 label-width="150px">
                            <el-form-item label="States">
                                <states-province-selector class="filter-select"
                                                          :value="states_limit"
                                                          :multiple="true"
                                                          data-testid="contact-property-states-select"
                                                          @change="changeStatesLimitFilter">
                                </states-province-selector>
                            </el-form-item>
                        </el-form>

                        <el-form label-position="left"
                                 label-width="150px">
                            <el-form-item label="Timezone">
                                <el-select v-model="filter_contact_timezone"
                                           placeholder="Select Timezone"
                                           class="filter-select"
                                           filterable
                                           clearable
                                           data-testid="contact-property-timezone-select"
                                           @change="changeContactTimezone">
                                    <el-option v-for="timezone in timezones"
                                               :key="timezone.value"
                                               :label="timezone.name"
                                               :value="timezone.value">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-form>

                        <el-form label-position="left"
                                 label-width="200px">
                            <el-form-item label="Has Untagged Calls">
                                <el-switch v-model="has_untagged_call"
                                           active-color="#00a344"
                                           :active-value="1"
                                           :inactive-value="0"
                                           data-testid="contact-property-has-untagged-calls-switch"
                                           @change="changeHasUntaggedCall">
                                </el-switch>
                            </el-form-item>
                        </el-form>

                        <el-form label-position="left"
                                 label-width="200px">
                            <el-form-item label="Untagged Contacts">
                                <el-switch v-model="untagged_only"
                                           active-color="#00a344"
                                           :active-value="1"
                                           :inactive-value="0"
                                           data-testid="contact-property-untagged-contacts-switch"
                                           @change="changeUntagged">
                                </el-switch>
                            </el-form-item>
                        </el-form>

                        <span class="filter-header text-muted">Contact Status</span>
                        <div class="dropdown-divider"></div>
                        <el-form label-position="left"
                                 label-width="150px">
                            <el-form-item label="Has Disposition Status">
                                <disposition-status-selector
                                    class="filter-select"
                                    ref="hasDispositionStatus"
                                    v-model="filter_disposition_statuses"
                                    :value.sync="filter_disposition_statuses"
                                    :multiple="true"
                                    :exclude="filter_no_disposition_statuses"
                                    :show_not_disposed="false"
                                    :is_disabled="Boolean(not_disposed)"
                                    data-testid="contact-status-has-disposition-status-selector"
                                    @change="changeDispositionStatuses">
                                </disposition-status-selector>
                            </el-form-item>
                        </el-form>

                        <el-form
                            label-position="left"
                            label-width="250px">
                            <el-form-item label="Does Not Have Disposition Status">
                                <disposition-status-selector
                                    class="filter-select ml-auto"
                                    ref="notInDispositionStatus"
                                    v-model="filter_no_disposition_statuses"
                                    :value.sync="filter_no_disposition_statuses"
                                    :multiple="true"
                                    :exclude="filter_disposition_statuses"
                                    :show_not_disposed="false"
                                    :is_disabled="Boolean(not_disposed)"
                                    data-testid="contact-status-without-disposition-status-selector"
                                    @change="changeNoDispositionStatuses">
                                </disposition-status-selector>
                            </el-form-item>
                        </el-form>

                        <el-form
                            label-position="left"
                             label-width="200px">
                            <el-form-item label="Not Disposed">
                                <el-switch
                                    v-model="not_disposed"
                                    :disabled="isNotDisposeDisabled"
                                    active-color="#00a344"
                                    :active-value="1"
                                    :inactive-value="0"
                                    data-testid="contact-status-not-disposed-switch"
                                    @change="changeNotDisposed">
                                </el-switch>
                            </el-form-item>
                        </el-form>

                        <el-form label-position="left"
                                 label-width="200px">
                            <el-form-item label="Blocked Contacts">
                                <el-switch v-model="is_blocked"
                                           active-color="#00a344"
                                           :active-value="1"
                                           :inactive-value="0"
                                           data-testid="contact-status-blocked-contacts-switch"
                                           @change="changeIsBlocked">
                                </el-switch>
                            </el-form-item>
                        </el-form>

                        <el-form label-position="left"
                                 label-width="200px">
                            <el-form-item label="DNC and Opt-Out Options">
                                <el-select v-model="dnc_option"
                                           :disabled="broadcast"
                                           class="filter-select ml-auto"
                                           data-testid="contact-status-dnc-opt-out-select"
                                           @change="changeDncOption">
                                    <el-option v-for="dnc_option in dnc_options"
                                               :key="dnc_option.value"
                                               :value="dnc_option.value"
                                               :label="dnc_option.label">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-form>

                        <span class="filter-header text-muted">Events</span>
                        <div class="dropdown-divider"></div>

                        <el-form label-position="left"
                                 label-width="200px">
                            <el-form-item label="Has Appointments">
                                <el-switch v-model="has_appointments"
                                           active-color="#00a344"
                                           :active-value="1"
                                           :inactive-value="0"
                                           data-testid="events-has-appointments-switch"
                                           @change="changeEventFilters">
                                </el-switch>
                            </el-form-item>
                        </el-form>

                        <el-form label-position="left"
                                 label-width="200px">
                            <el-form-item label="Has Reminders">
                                <el-switch v-model="has_reminders"
                                           active-color="#00a344"
                                           :active-value="1"
                                           :inactive-value="0"
                                           data-testid="events-has-reminders-switch"
                                           @change="changeEventFilters">
                                </el-switch>
                            </el-form-item>
                        </el-form>

                        <el-form v-if="has_appointments == 1 || has_reminders == 1"
                                 label-position="left"
                                 label-width="200px">
                            <el-form-item label="Range">
                                <div class="text-right">
                                    <el-date-picker v-model="event_range"
                                                    type="daterange"
                                                    range-separator="To"
                                                    start-placeholder="Start date"
                                                    end-placeholder="End date"
                                                    :disabled="has_appointments == 0 && has_reminders == 0"
                                                    data-testid="events-date-range-picker"
                                                    @change="changeEventFilters">
                                    </el-date-picker>
                                </div>
                            </el-form-item>
                        </el-form>

                        <div v-if="!campaign_id"
                             class="mt-2">
                            <span class="filter-header text-muted">Attribution</span>
                            <div class="dropdown-divider"></div>
                            <el-form label-position="left"
                                     label-width="150px">
                                <el-form-item label="Lines">
                                    <campaign-selector :value="filter_campaigns"
                                                       :multiple="true"
                                                       :small="true"
                                                       class="filter-select pull-right p-0 campaign-selector"
                                                       v-model="filter_campaigns"
                                                       data-testid="attribution-lines-select"
                                                       @change="changeCampaigns">
                                    </campaign-selector>
                                </el-form-item>
                            </el-form>
                            <div class="d-flex justify-content-end">
                                <div class="d-inline-flex align-items-center">
                                    <el-switch id="initial_line_only"
                                               v-model="initial_line_only"
                                               active-color="#00a344"
                                               :active-value="1"
                                               :inactive-value="0"
                                               data-testid="attribution-initial-line-only-switch"
                                               @change="changeInitialLineOnly">
                                    </el-switch>
                                    <label for="initial_line_only"
                                           class="el-form-item__label mr-2">
                                        Initial line only
                                    </label>
                                </div>
                            </div>
                        </div>

                        <div class="mt-2"
                             v-if="hasPermissionTo('list user') && !auth.user.profile.focus_mode">
                            <el-form label-position="left"
                                     label-width="150px">
                                <el-form-item label="Users">
                                    <user-selector class="filter-select"
                                                   v-model="filter_users"
                                                   :value="filter_users"
                                                   :multiple="true"
                                                   data-testid="attribution-users-select"
                                                   @change="changeAgents">
                                    </user-selector>
                                </el-form-item>
                            </el-form>
                        </div>

                        <div class="mt-2"
                             v-if="hasPermissionTo('list broadcast')">
                            <el-form label-position="left"
                                     label-width="150px">
                                <el-form-item label="Broadcasts">
                                    <broadcast-selector class="filter-select"
                                                        v-model="filter_broadcasts"
                                                        :value="filter_broadcasts"
                                                        :multiple="true"
                                                        data-testid="attribution-broadcast-select"
                                                        @change="changeBroadcasts">
                                    </broadcast-selector>
                                </el-form-item>
                            </el-form>
                        </div>
                        <span class="filter-header text-muted">Other</span>
                        <div class="dropdown-divider"></div>
                        <el-form label-position="left"
                                 label-width="200px">
                            <el-form-item :label="(current_company && current_company.reseller_id == 357) ? 'Has Unread Texts' : 'Has Unread Communications'">
                                <el-switch v-model="has_unread"
                                           active-color="#00a344"
                                           :active-value="1"
                                           :inactive-value="0"
                                           data-testid="other-unread-switch"
                                           @change="changeHasUnread">
                                </el-switch>
                            </el-form-item>
                        </el-form>
                        <el-form label-position="left"
                                 label-width="200px">
                            <el-form-item label="New Leads">
                                <el-switch v-model="is_new_lead"
                                           active-color="#00a344"
                                           :active-value="1"
                                           :inactive-value="0"
                                           data-testid="other-new-leads-switch"
                                           @change="changeIsNewLead">
                                </el-switch>
                            </el-form-item>
                        </el-form>
                        <el-form label-position="left"
                                 label-width="200px">
                            <el-form-item label="Unassigned Leads">
                                <el-switch v-model="unassigned_leads"
                                           active-color="#00a344"
                                           :active-value="1"
                                           :inactive-value="0"
                                           data-testid="other-unassigned-leads-switch"
                                           @change="changeUnassignedLeads">
                                </el-switch>
                            </el-form-item>
                        </el-form>
                        <el-form label-position="left"
                                 label-width="200px">
                            <el-form-item label="Follow the sun">
                                <el-switch v-model="should_follow_the_sun"
                                           active-color="#00a344"
                                           :active-value="1"
                                           :inactive-value="0"
                                           data-testid="other-follow-the-sun-switch"
                                           @change="changeShouldFollowTheSun">
                                </el-switch>
                            </el-form-item>
                        </el-form>
                        <el-form ref="responded-form"
                                 label-position="left"
                                 label-width="200px"
                                 :model="responded_model"
                                 :disabled="not_responded > 0"
                                 :rules="rules_responded"
                                 @submit.prevent.native="preventSubmit">
                            <el-form-item class="not-responded-filter"
                                          prop="responded_hours">
                                <span slot="label">
                                    <el-tooltip
                                        class="item"
                                        effect="dark"
                                        content="Filter contacts that responded in the last number of hours."
                                        placement="top">
                                        <span>Last Response</span>
                                    </el-tooltip>
                                </span>
                                <el-switch v-model="responded"
                                           active-color="#00a344"
                                           :active-value="1"
                                           :inactive-value="0"
                                           data-testid="other-last-response-switch"
                                           @change="changeResponded">
                                </el-switch>
                                <el-input v-if="responded"
                                          v-model="responded_model.responded_hours"
                                          type="number"
                                          min="0"
                                          class="pull-right col-3 p-0 text-center el-input__inner mr-2"
                                          style="text-align: center !important;"
                                          data-testid="other-hour-input"
                                          @input="changeRespondedHours">
                                    <template slot="append">hour(s)</template>
                                </el-input>
                            </el-form-item>
                        </el-form>
                        <el-form ref="not-responded-form"
                                 label-position="left"
                                 label-width="200px"
                                 :model="not_responded_model"
                                 :disabled="responded > 0"
                                 :rules="rules_not_responded"
                                 @submit.prevent.native="preventSubmit">
                            <el-form-item class="not-responded-filter"
                                          prop="not_responded_hours">
                                <span slot="label">
                                    <el-tooltip
                                        class="item"
                                        effect="dark"
                                        content="Filter contacts has no response in the last number of hours."
                                        placement="top">
                                        <span>No Response</span>
                                    </el-tooltip>
                                </span>
                                <el-switch v-model="not_responded"
                                           active-color="#00a344"
                                           :active-value="1"
                                           :inactive-value="0"
                                           data-testid="other-no-response-switch"
                                           @change="changeNotResponded">
                                </el-switch>
                                <el-input v-if="not_responded"
                                          v-model="not_responded_model.not_responded_hours"
                                          type="number"
                                          min="0"
                                          class="pull-right col-3 p-0 text-center el-input__inner mr-2"
                                          style="text-align: center !important;"
                                          data-testid="other-not-responded-hours-input"
                                          @input="changeNotRespondedHours">
                                    <template slot="append">hour(s)</template>
                                </el-input>
                            </el-form-item>
                        </el-form>
                        <el-form ref="not-contacted-form"
                                 label-position="left"
                                 label-width="200px"
                                 :model="not_contacted_model"
                                 :rules="rules_not_contacted"
                                 @submit.prevent.native="preventSubmit">
                            <el-form-item class="not-contacted-filter"
                                          prop="not_contacted_hours">
                                <span slot="label">
                                    <el-tooltip
                                        class="item"
                                        effect="dark"
                                        content="Filter contacts that has not been called or texted by an agent for the last number of hours"
                                        placement="top">
                                        <span>No Contact</span>
                                    </el-tooltip>
                                </span>
                                <el-switch v-model="not_contacted"
                                           active-color="#00a344"
                                           :active-value="1"
                                           :inactive-value="0"
                                           data-testid="other-no-contact-switch"
                                           @change="changeNotContacted">
                                </el-switch>
                                <el-input v-if="not_contacted"
                                          v-model="not_contacted_model.not_contacted_hours"
                                          type="number"
                                          min="0"
                                          class="pull-right col-3 p-0 text-center el-input__inner mr-2"
                                          style="text-align: center !important;"
                                          data-testid="other-no-contact-input"
                                          @input="changeNotContactedHours">
                                    <template slot="append">hour(s)</template>
                                </el-input>
                            </el-form-item>
                        </el-form>
                        <el-form label-position="left"
                                 label-width="200px">
                            <el-form-item label="TCPA Approved">
                                <el-switch v-model="text_authorized"
                                           active-color="#00a344"
                                           :active-value="1"
                                           :inactive-value="0"
                                           data-testid="other-tcpa-approved-switch"
                                           @change="changeTextAuthorized">
                                </el-switch>
                            </el-form-item>
                        </el-form>
                        <el-form ref="outbound-call-count-start"
                                 label-position="left"
                                 label-width="140px"
                                 :model="outbound_call_count_model"
                                 :rules="rules_outbound_call_count"
                                 @submit.prevent.native="preventSubmit">
                            <el-form-item label="Outbound Call Count"
                                          class="outbound-call-count-filter"
                                          prop="outbound_call_count_start">
                                <el-switch v-model="outbound_call_count_switch"
                                           active-color="#00a344"
                                           :active-value="1"
                                           :inactive-value="0"
                                           data-testid="other-outbound-call-count-switch"
                                           @change="changeCallCount">
                                </el-switch>
                                <div class="w-auto d-flex justify-content-end">
                                    <div v-if="outbound_call_count_switch"
                                         class="inputs pull-right d-flex justify-content-end pr-3">
                                        <div class="mr-2 align-self-center small">Called between</div>
                                        <el-input
                                            v-model="outbound_call_count_model.outbound_call_count_start"
                                            type="number"
                                            min="0"
                                            class="col-2 p-0 mr-1"
                                            size="mini"
                                            data-testid="other-called-between-start-input"
                                            @input="changeCallCountStart">
                                        </el-input>
                                        <div class="mr-1 align-self-center small">and</div>
                                        <el-input
                                            v-model="outbound_call_count_model.outbound_call_count_end"
                                            type="number"
                                            :min="outbound_call_count_end_min"
                                            class="col-2 p-0 mr-1"
                                            size="mini"
                                            data-testid="other-called-between-end-input"
                                            @change="changeCallCountEnd">
                                        </el-input>
                                        <div class="align-self-center small">times</div>
                                    </div>
                                </div>
                            </el-form-item>
                        </el-form>

                        <el-form
                            ref="scheduled-message-form"
                            label-position="left"
                            label-width="250px">
                            <el-form-item label="Has Scheduled Messages">
                                <el-switch
                                    v-model="has_scheduled_messages"
                                    active-color="#00a344"
                                    :active-value="1"
                                    :inactive-value="0"
                                    data-testid="other-has-scheduled-messages-switch"
                                    @change="scheduledMessageFilterChange('has_scheduled_messages')">
                                </el-switch>
                            </el-form-item>

                            <el-form-item label="Does Not Have Scheduled Messages">
                                <el-switch
                                    v-model="no_scheduled_messages"
                                    active-color="#00a344"
                                    :active-value="1"
                                    :inactive-value="0"
                                    data-testid="other-does-not-have-scheduled-messages-switch"
                                    @change="scheduledMessageFilterChange('no_scheduled_messages')">
                                </el-switch>
                            </el-form-item>
                        </el-form>

                        <el-form
                            ref="in-sequence"
                            label-position="left"
                            label-width="250px">
                            <el-form-item label="Is Enrolled in Sequences">
                                <el-switch
                                    v-model="enrolled_in_sequence"
                                    active-color="#00a344"
                                    :active-value="1"
                                    :inactive-value="0"
                                    data-testid="other-is-enrolled-in-sequences-switch"
                                    @change="enrolledInSequenceFilterChange('enrolled_in_sequence')">
                                </el-switch>
                            </el-form-item>

                            <el-form-item label="Not Enrolled in Sequences">
                                <el-switch
                                    v-model="not_enrolled_in_sequence"
                                    active-color="#00a344"
                                    :active-value="1"
                                    :inactive-value="0"
                                    data-testid="other-not-enroleld-in-sequences-switch"
                                    @change="enrolledInSequenceFilterChange('not_enrolled_in_sequence')">
                                </el-switch>
                            </el-form-item>
                        </el-form>
                    </div>
                    <div class="col-12"
                         v-if="only_campaign_selector">
                        <div v-if="!campaign_id"
                             class="mt-2">
                            <span class="filter-header text-muted">Attribution</span>
                            <div class="dropdown-divider"></div>
                            <el-form label-position="left"
                                     label-width="150px">
                                <el-form-item label="Lines">
                                    <campaign-selector :value="filter_campaigns"
                                                       :multiple="true"
                                                       class="filter-select"
                                                       v-model="filter_campaigns"
                                                       data-testid="attribution-lines-selector"
                                                       @change="changeCampaigns">
                                    </campaign-selector>
                                </el-form-item>
                            </el-form>
                        </div>
                    </div>
                    <div class="col-12 mt-3">
                        <button class="btn greenish p-x-md pull-right"
                                data-testid="apply-our-filters-button"
                                @click="applyOurFilters">
                            Apply
                        </button>

                        <button class="btn blackish p-x-md pull-right mr-2"
                                data-testid="before-reset-filters-button"
                                @click="beforeResetFilters">
                            Reset Filters
                        </button>
                    </div>
                </div>
            </el-dialog>
        </div>

        <!-- Reset Filters -->
        <div v-show="changed && !hide_reset_filter">
            <button class="btn btn-md blackish p-x-md"
                    style="height: 40px"
                    data-testid="reset-contact-filters-button"
                    @click="resetContactFilters">
                Reset Filters
            </button>
        </div>

        <!-- Export -->
        <div class="dropdown"
             v-if="hasRole('Company Admin') && !filter_options.no_export">
            <button class="btn btn-outline b-dark-greenish text-dark-greenish dropdown-toggle"
                    data-testid="export-button-dropdown"
                    @click="toggleExportsMenu">
                Export
            </button>
            <div class="dropdown-menu dropdown-menu-right"
                 :class="exports_menu_visible ? 'show' : ''">
                <a class="dropdown-item"
                   data-testid="export-to-csv-link"
                   @click.prevent="exportTo('csv-all', false)">
                    <i class="fa fa-table"></i>
                    CSV File
                </a>
            </div>
        </div>

        <div class="d-flex flex-row"
             v-if="!broadcast">
            <div v-if="live_update_contacts === false">
                <el-tooltip placement="top"
                            content="Enable real-time updates">
                    <button class="btn btn-outline b-dark-grayish pt-2 border"
                            data-testid="enable-real-time-button"
                            @click="playLiveUpdates">
                        <i class="fa fa-play"></i>
                    </button>
                </el-tooltip>
                <span class="ml-2 text-sm">{{lastUpdateTime}} - Last Update</span>
            </div>
            <el-tooltip placement="top"
                        content="Pause real-time updates"
                        v-if="live_update_contacts === true">
                <button class="btn btn-outline b-dark-grayish pt-2 border"
                        data-testid="pause-real-time-button"
                        @click="pauseLiveUpdates">
                    <i class="fa fa-pause"></i>
                </button>
            </el-tooltip>
        </div>
    </div>
</template>

<script>
import auth from '../auth'
import {mapActions, mapGetters, mapState} from 'vuex'
import {mixin as clickaway} from 'vue-clickaway'
import {
    acl_mixin,
    date_mixin,
    validator_mixin,
    contact_filters_mixin,
    form_validation_mixin
} from '../mixins'
import {Notification} from 'element-ui'
import {IntakeSources} from '../constants/intake-sources'
import TagCreator from './tag-creator'
import StatesProvinceSelector from './states-province-selector'
import BroadcastSelector from "./broadcast-selector"
import DateSelector from '../components/date-selector'
import * as LrnTypes from '../constants/lrn-types'
import * as Roles from '../constants/roles'
import * as DefaultContactDateFilter from '../constants/company_default_contact_date_filter'
import * as TagCategory from '../constants/tag-categories'
import * as FilterDncOptions from '../constants/filter-dnc-options'

export default {
    mixins: [
        date_mixin,
        acl_mixin,
        clickaway,
        validator_mixin,
        contact_filters_mixin,
        form_validation_mixin
    ],

    components: {
        BroadcastSelector,
        TagCreator,
        StatesProvinceSelector,
        DateSelector
    },

    props: {
        campaign_id: {required: false},
        tag_id: {required: false},
        disposition_status_id: {required: false},
        only_campaign_selector: {
            default: false,
            type: Boolean,
            required: false
        },
        hide_reset_filter: {
            default: false,
            type: Boolean,
            required: false
        },
        broadcast: {
            default: false,
            type: Boolean,
            required: false
        },
        default_date_range: {
            default: null,
            required: false
        },
        has_sort_communications: {
            default: false
        }
    },

    data() {
        let comm_hours_rule = (rule, value) => {
            return new Promise((resolve, reject) => {
                if (value >= 0 && value <= 1440) {
                    resolve();
                } else {
                    reject('Should be less than 1440 hours');  // reject with error message
                }
            })
        }

        return {
            auth: auth,
            live_updates_timer: 0,
            live_updates_timer_interval: {},
            changed: false,
            date_field_option: [
                {
                    value: 'last_engagement_at',
                    label: 'Last Engagement',
                    info: 'Contacts who <b>sent</b> or <b>received</b> their <b>last communication</b> to or from this account during a specific timeframe.',
                    icon: 'fas fa-info-circle'
                },
                {
                    value: 'last_inbound_engagement_at',
                    label: 'Last Inbound Engagement',
                    info: 'Contacts who <b>sent</b> their <b>last communication</b> to this account during a specific timeframe.',
                    icon: 'fas fa-info-circle'
                },
                {
                    value: 'last_outbound_engagement_at',
                    label: 'Last Outbound Engagement',
                    info: 'Contacts who <b>received</b> their <b>last communication</b> from this account during a specific timeframe.',
                    icon: 'fas fa-info-circle'
                },
                {
                    value: 'created_at',
                    label: 'Created at',
                    info: 'Contacts <b>created</b> during a specific timeframe.',
                    icon: 'fas fa-info-circle'
                }
            ],
            date_field: 'last_engagement_at',
            comm_sort_by_options: [
                {
                    value: null,
                    label: 'Default Sort',
                    disabled: false,
                    tooltip: null
                },
                {
                    value: 'least_called',
                    label: 'Least Called',
                    disabled: false,
                    tooltip: null
                },
                {
                    value: 'most_called',
                    label: 'Most Called',
                    disabled: false,
                    tooltip: null
                },
                {
                    value: 'least_texted',
                    label: 'Least Texted',
                    disabled: false,
                    tooltip: null
                },
                {
                    value: 'most_texted',
                    label: 'Most Texted',
                    disabled: false,
                    tooltip: null
                },
                {
                    value: 'newest_unanswered',
                    label: 'Newest Unanswered Leads',
                    disabled: true,
                    tooltip: 'Enabled only when Unanswered Leads filter is activated'
                },
                {
                    value: 'oldest_unanswered',
                    label: 'Oldest Unanswered Leads',
                    disabled: true,
                    tooltip: 'Enabled only when Unanswered Leads filter is activated'
                },
            ],
            comm_sort_by: null,
            filter_tags: [],
            filter_doesnt_have_tags: [],
            filter_disposition_statuses: [],
            filter_no_disposition_statuses: [],
            filter_users: [],
            filter_broadcasts: [],
            filter_campaigns: [],
            dnc_option: null,
            dnc_options: [
                {
                    label: 'All Contacts (with and without DNC)',
                    value: FilterDncOptions.ALL_CONTACTS,
                },
                {
                    label: 'Only Contacts with DNC',
                    value: FilterDncOptions.CONTACTS_WITH_DNC,
                },
                {
                    label: 'Only Contacts without DNC and without Opt-Out',
                    value: FilterDncOptions.CONTACTS_WITHOUT_DNC_WITHOUT_OPTOUT,
                },
                {
                    label: 'Only Contacts without DNC and with Opt-Out',
                    value: FilterDncOptions.CONTACTS_WITHOUT_DNC_WITH_OPTOUT,
                }
            ],
            lrn_types: [],
            intake_source: [],
            untagged_only: 0,
            has_untagged_call: 0,
            not_disposed: 0,
            is_blocked: 0,
            has_scheduled_messages: 0,
            no_scheduled_messages: 0,
            enrolled_in_sequence: 0,
            not_enrolled_in_sequence: 0,
            is_dnc: 0,
            has_unread: 0,
            is_new_lead: 0,
            unassigned_leads: 0,
            should_follow_the_sun: 0,
            not_contacted: 0,
            not_contacted_hours: null,
            not_responded: 0,
            not_responded_hours: null,
            responded: 0,
            responded_hours: null,
            prop_counter: 0,
            text_authorized: 0,
            filter_contact_country: '',
            filter_contact_timezone: '',
            filters_menu_visible: false,
            exports_menu_visible: false,
            is_filters_applied: false,
            filters_changes: [],
            outbound_call_count_switch: 0,
            outbound_call_count_start: null,
            outbound_call_count_end: null,
            outbound_call_count_end_min: 0,
            outbound_call_count_model: {
                outbound_call_count_start: null,
                outbound_call_count_end: null,
            },
            not_contacted_model: {
                not_contacted: 0,
                not_contacted_hours: null
            },
            responded_model: {
                responded: 0,
                responded_hours: null
            },
            not_responded_model: {
                not_responded: 0,
                not_responded_hours: null
            },
            rules_not_contacted: {
                not_contacted_hours: [
                    {
                        required: false,
                        trigger: 'change',
                        validator: comm_hours_rule
                    }
                ],
            },
            rules_not_responded: {
                not_responded_hours: [
                    {
                        required: false,
                        trigger: 'change',
                        validator: comm_hours_rule
                    }
                ],
            },
            rules_responded: {
                responded_hours: [
                    {
                        required: false,
                        trigger: 'change',
                        validator: comm_hours_rule
                    }
                ]
            },
            rules_outbound_call_count: {
                outbound_call_count_start: [
                    {
                        validator: this.outboundCallCountValidator,
                        trigger: 'change'
                    }
                ],
            },
            Roles,
            states_limit: {
                'us': [],
                'ca': []
            },
            initial_line_only: 0,
            // Events data
            has_appointments: 0,
            has_reminders: 0,
            event_range: null,
            no_float: false,
            loading_tags: false,
            tags: [],
            LrnTypes,
            IntakeSources,
            DefaultContactDateFilter,
            TagCategory
        }
    },

    computed: {
        ...mapGetters({
            countries: 'getCountries'
        }),

        ...mapState(['campaigns', 'disposition_statuses', 'filter_options', 'users', 'broadcasts', 'lead_sources']),
        ...mapState('cache', ['current_company', 'live_update_contacts']),

        shouldInitializeFiltersText() {
            return !this.$root.loading && !this.loading_tags
        },

        timezones() {
            if (this.filter_contact_country) {
                if (['US', 'CA'].includes(this.filter_contact_country)) {
                    return this.getNorthAmericanTimezones(this.filter_contact_country)
                }

                return window.countriesAndTimezones.getTimezonesForCountry(this.filter_contact_country).map((timezone) => {
                    return {
                        name: timezone.name.replace('_', ' ') + ' GMT ' + timezone.utcOffsetStr,
                        value: timezone.name
                    }
                })
            } else {
                if (this.current_company && this.current_company.country) {
                    if (['US', 'CA'].includes(this.current_company.country)) {
                        return this.getNorthAmericanTimezones(this.current_company.country)
                    }

                    return window.countriesAndTimezones.getTimezonesForCountry(this.current_company.country).map((timezone) => {
                        return {
                            name: timezone.name.replace('_', ' ') + ' GMT ' + timezone.utcOffsetStr,
                            value: timezone.name
                        }
                    })
                }
            }

            return []
        },

        campaign() {
            if (!this.campaign_id) {
                return null
            }
            if (this.campaigns) {
                return this.campaigns.find(item => {
                    return item.id == this.campaign_id
                })
            }

            return null
        },

        isLargeEnough() {
            let valid_sizes = ['xxl', 'xl', 'lg']
            if (valid_sizes.includes(this.$mq)) {
                return true
            }
            return false
        },

        dispositionStatusesAlphabeticalOrder() {
            if (this.disposition_statuses) {
                let disposition_statuses = {...this.disposition_statuses}
                return disposition_statuses.sort((a, b) => {
                    let textA = a.name.toUpperCase()
                    let textB = b.name.toUpperCase()
                    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0
                })
            }

            return []
        },

        leadSources() {
            let lead_sources = []

            if (!this.lead_sources) {
                return lead_sources
            }

            for (let lead_source of this.lead_sources) {
                lead_sources.push({
                    code: lead_source.id,
                    name: lead_source.name
                })
            }

            return lead_sources
        },

        isNotDisposeDisabled() {
            if (this.filter_disposition_statuses && this.filter_disposition_statuses.length) {
                return true
            }

            return !!(this.filter_no_disposition_statuses && this.filter_no_disposition_statuses.length);
        },

        lastUpdateTime() {
            return moment.duration(this.live_updates_timer, 'seconds').humanize()
        }
    },

    created() {
        // remove the float styles
        this.no_float = this.filter_options.force_size === 'small'

        setTimeout(() => {
            // @custom Business Promotion Dental (376)
            if (this.current_company && this.current_company.id === 376 && !this.tag_id) {
                this.changeDispositionStatuses([358, 360])
                this.applyOurFilters(true, false)

                // if from date is not in route query,
                // change the date range
                if (!this.from_date) {
                    VueEvent.fire('change_date_range', 'All Time')
                }
            }
            if (this.tag_id) {
                this.changeTags([parseInt(this.tag_id)])
                this.applyOurFilters(true, false)
                VueEvent.fire('change_date_range', 'All Time')
            }
            if (this.disposition_status_id) {
                this.changeDispositionStatuses([this.disposition_status_id])
                this.applyOurFilters(true, false)
                VueEvent.fire('change_date_range', 'All Time')
            }
            if (this.lead_source_id) {
                this.changeLeadSource([this.lead_source_id])
                this.applyOurFilters(true, false)
                VueEvent.fire('change_date_range', 'All Time')
            }
        }, 500)

        VueEvent.listen('reset_events', () => {
            this.resetOurFilters(false)
        })

        VueEvent.listen('updateTextualContactFilters', () => {
            this.contactFiltersToText()
        })

        VueEvent.listen('disable_unanswered_leads_sort_options', (option_state) => {
            this.disableUnansweredLeadsSortOptions(option_state)

            // set the default sorting when unanswered leads sort options are disabled
            // and is currently selected
            if (option_state && (this.comm_sort_by == 'newest_unanswered' || this.comm_sort_by == 'oldest_unanswered' )) {
                this.comm_sort_by = null
                this.changeCommSortBy()
            }
        })

        // Process live update timer
        if (!this.live_update_contacts) {
            if (this.live_updates_timer_interval) {
                clearInterval(this.live_updates_timer_interval)
            }
            this.playLiveUpdateTimer()
        } else {
            localStorage.removeItem('live_updates_timer')
        }
        // Get timer from localStorage
        this.live_updates_timer = (localStorage.getItem('live_updates_timer') === null) ? 0 : parseInt(localStorage.getItem('live_updates_timer'))
    },

    mounted() {
        this.initLeadSource()
        this.initFromToDates()

        if (this.filter_options.is_bulk) {
            this.resetOurFilters(false)
        }

        if (this.broadcast) {
            this.dnc_option = FilterDncOptions.CONTACTS_WITHOUT_DNC_WITHOUT_OPTOUT
            this.changeDncOption(this.dnc_option)
        }

        if (localStorage.getItem('PREVIOUS_ROUTE_NAME') === 'Contact') {
            this.initializeFilter(true)
            this.custom_filter = {...this.filter}
        } else {
            this.initializeFilter()
        }
    },

    methods: {
        async getTags(ids = []) {
            if (this.hasPermissionTo('list tag')) {
                this.loading_tags = true
                if (!ids.length) {
                    return
                }
                let params = {
                    full_load: true,
                    tag_ids: ids
                }
                return axios.get('/api/v1/tag', {params}).then(res => {
                    this.tags = res.data
                    this.loading_tags = false
                }).catch(err => {
                    console.log(err)
                    this.loading_tags = false
                })
            }
        },

        assignDefaultDateField() {
            // override default date_field from company.default_contact_date_filter
            this.date_field = {
                [DefaultContactDateFilter.DEFAULT_CONTACT_DATE_FILTER_ENGAGEMENT]: 'last_engagement_at',
                [DefaultContactDateFilter.DEFAULT_CONTACT_DATE_FILTER_CREATED_AT]: 'created_at'
            }[this.auth.user.profile.company.default_contact_date_filter]
            // also set the date field of filter, default, and custom filter variables for comparison
            // and tracking of changes
            this.filter.date_field = this.date_field
            this.default_filter.date_field = this.date_field
            this.custom_filter.date_field = this.date_field
        },

        initializeFilter(force = false) {
            let route_name = this.getCurrentRouteName()
            if (route_name !== 'Contacts' && !force) {
                return
            }

            // if from date is not in route query,
            // change the date range
            if (!this.from_date) {
                VueEvent.fire('reset_date_range')
            }

            this.assignDefaultDateField()
            this.untagged_only = this.filter.untagged_only
            this.has_untagged_call = this.filter.has_untagged_call
            this.filter_tags = this.filter.tags
            this.filter_doesnt_have_tags = this.filter.doesnt_have_tags
            this.filter_disposition_statuses = this.filter.disposition_statuses
            this.filter_no_disposition_statuses = this.filter.no_disposition_statuses
            this.lrn_types = this.filter.lrn_types
            this.has_reminders = this.filter.has_reminders
            this.has_appointments = this.filter.has_appointments
            this.event_range = this.filter.event_range
            this.intake_source = this.filter.intake_source
            this.findLeadSource()
            this.filter_campaigns = this.filter.campaigns
            this.not_disposed = this.filter.not_disposed
            this.is_blocked = this.filter.is_blocked
            this.dnc_option = this.filter.dnc_option
            this.has_scheduled_messages = this.filter.has_scheduled_messages
            this.no_scheduled_messages = this.filter.no_scheduled_messages
            this.enrolled_in_sequence = this.filter.enrolled_in_sequence
            this.not_enrolled_in_sequence = this.filter.not_enrolled_in_sequence
            this.has_unread = this.filter.has_unread
            this.is_new_lead = this.filter.is_new_lead
            this.unassigned_leads = this.filter.unassigned_leads
            this.should_follow_the_sun = this.filter.should_follow_the_sun
            this.not_responded = this.filter.not_responded
            this.not_responded_hours = this.filter.not_responded_hours
            this.not_responded_model.not_responded_hours = this.not_responded_hours
            this.responded = this.filter.responded
            this.responded_hours = this.filter.responded_hours
            this.responded_model.responded_hours = this.responded_hours
            this.not_contacted = this.filter.not_contacted
            this.not_contacted_hours = this.filter.not_contacted_hours
            this.not_contacted_model.not_contacted_hours = this.not_contacted_hours
            this.text_authorized = this.filter.text_authorized
            this.filter_users = this.filter.users
            this.filter_broadcasts = this.filter.broadcasts
            this.states_limit.us = this.filter.states_limit.us
            this.states_limit.ca = this.filter.states_limit.ca
            this.initial_line_only = this.filter.initial_line_only
            this.outbound_call_count_start = this.filter.outbound_call_count_start
            this.outbound_call_count_model.outbound_call_count_start = this.outbound_call_count_start
            this.outbound_call_count_end = this.filter.outbound_call_count_end
            this.outbound_call_count_model.outbound_call_count_end = this.outbound_call_count_end
            this.comm_sort_by = this.filter.comm_sort_by

            // invoke unanswered leads sort options' state based on unanswered leads filter state
            if (this.has_unread) {
                this.disableUnansweredLeadsSortOptions(false)
            }

            this.outbound_call_count_switch = this.filter.outbound_call_count_start &&
                this.filter.outbound_call_count_end ? 1 : 0
            this.$nextTick(() => {
                if (this.hasFilterMenuChanges(this.custom_filter, this.default_filter)) {
                    this.changed = true
                } else {
                    this.changed = this.filter.changed
                }
                if (this.changed) {
                    this.filter_options.is_changed = !this.filter_options.is_changed
                }
            })
        },

        closeAllMenus(event = null) {
            if (this.exports_menu_visible) {
                this.exports_menu_visible = false
            }
        },

        closeFiltersMenu(done) {
            if (this.hasFilterMenuChanges(this.custom_filter, this.default_filter) && !this.is_filters_applied) {
                this.$confirm(`Are you sure you want to close the filters?`, 'Filters', {
                    confirmButtonText: 'Yes, I\'m sure',
                    cancelButtonText: 'No, I\'m not',
                    type: 'warning',
                    customClass: 'width-500 fixed'
                }).then(() => {
                    this.custom_filter = {...this.filter}
                    this.resetModels()
                    this.clearFieldErrors()
                    this.filter_options.is_filter_visible = false
                    done()
                }).catch((err) => {
                    console.log(err)
                })
            } else {
                this.filter_options.is_filter_visible = false
                this.clearFieldErrors()
                done()
            }
        },

        toggleFiltersMenu() {
            this.filters_menu_visible = !this.filters_menu_visible
            this.filter_options.is_filter_visible = this.filters_menu_visible
        },

        toggleExportsMenu() {
            let old_exports_menu_visible = this.exports_menu_visible
            this.closeAllMenus()
            this.exports_menu_visible = !old_exports_menu_visible
        },

        changeDateField(date_field) {
            this.filter.changed = true
            this.filter.date_field = date_field
            this.custom_filter.date_field = date_field
        },

        changeCommSortBy() {
            this.filter.comm_sort_by = this.comm_sort_by
            this.custom_filter.comm_sort_by = this.comm_sort_by
            if (this.comm_sort_by !== null) {
                this.filter.changed = true
            } else {
                this.filter.changed = false
                this.is_filters_applied = true
            }
        },

        changeCallCount() {
            if (!this.outbound_call_count_switch) {
                this.outbound_call_count_start = null
                this.outbound_call_count_model.outbound_call_count_start = null
                this.custom_filter.outbound_call_count_start = null
                this.outbound_call_count_end = null
                this.outbound_call_count_model.outbound_call_count_end = null
                this.custom_filter.outbound_call_count_end = null
            }
            this.custom_filter.is_new_lead = 0
            this.is_new_lead = 0
            this.is_filters_applied = false
        },

        changeCallCountStart() {
            this.outbound_call_count_start = this.outbound_call_count_model.outbound_call_count_start
            this.custom_filter.outbound_call_count_start = this.outbound_call_count_start
            this.is_filters_applied = false
            if (this.outbound_call_count_end != null &&
                this.outbound_call_count_start != null &&
                parseInt(this.outbound_call_count_end) < parseInt(this.outbound_call_count_start)) {
                this.outbound_call_count_model.outbound_call_count_end = this.outbound_call_count_start
                this.outbound_call_count_end = this.outbound_call_count_start
                this.custom_filter.outbound_call_count_end = this.outbound_call_count_end
            }
            this.outbound_call_count_end_min = this.outbound_call_count_start
        },

        changeCallCountEnd() {
            if (this.outbound_call_count_model.outbound_call_count_start != null &&
                this.outbound_call_count_start > parseInt(this.outbound_call_count_model.outbound_call_count_end)) {
                this.outbound_call_count_model.outbound_call_count_end = this.outbound_call_count_start
            }
            this.outbound_call_count_end = this.outbound_call_count_model.outbound_call_count_end
            this.custom_filter.outbound_call_count_end = this.outbound_call_count_end
            this.is_filters_applied = false
        },

        changeTags(tags) {
            this.custom_filter.tags = tags
            this.is_filters_applied = false
        },

        changeDoesntHaveTags(tags) {
            this.custom_filter.doesnt_have_tags = tags
            this.is_filters_applied = false
        },

        changeDispositionStatuses(disposition_statuses) {
            this.custom_filter.disposition_statuses = disposition_statuses
            this.is_filters_applied = false
        },

        changeNoDispositionStatuses(disposition_statuses) {
            this.custom_filter.no_disposition_statuses = disposition_statuses
            this.is_filters_applied = false
        },

        changeLrnTypes(lrn_types) {
            this.custom_filter.lrn_types = lrn_types
            this.is_filters_applied = false
        },

        changeEventFilters() {
            this.custom_filter.has_appointments = this.has_appointments
            this.custom_filter.has_reminders = this.has_reminders
            if (this.has_appointments === 0 && this.has_reminders === 0) {
                this.custom_filter.event_range = null
            } else if (Array.isArray(this.event_range)) {
                this.custom_filter.event_range = this.event_range
            }
            this.is_filters_applied = false
        },

        changeIntakeSource(intake_source) {
            this.custom_filter.intake_source = intake_source
            this.is_filters_applied = false
        },

        changeLeadSource(lead_source_id) {
            this.custom_filter.lead_source = lead_source_id
            this.is_filters_applied = false
        },

        changeUntagged(untagged_only) {
            this.custom_filter.untagged_only = untagged_only ? 1 : 0
            this.is_filters_applied = false
        },

        changeHasUntaggedCall(has_untagged_call) {
            this.custom_filter.has_untagged_call = has_untagged_call ? 1 : 0
            this.is_filters_applied = false
        },

        changeNotDisposed(not_disposed) {
            this.custom_filter.not_disposed = not_disposed
            this.custom_filter.disposition_statuses = []
            this.filter_disposition_statuses = []

            this.is_filters_applied = false
        },

        changeIsBlocked(is_blocked) {
            this.custom_filter.is_blocked = is_blocked ? 1 : 0
            this.is_filters_applied = false
        },

        changeDncOption(dnc_option) {
            this.custom_filter.dnc_option = dnc_option
            this.is_filters_applied = false
        },

        changeHasUnread(has_unread) {
            this.custom_filter.has_unread = has_unread ? 1 : 0
            this.custom_filter.is_new_lead = this.custom_filter.is_new_lead == 1 && has_unread == 1 ? 0 : this.custom_filter.is_new_lead
            this.custom_filter.should_follow_the_sun = 0
            this.is_new_lead = this.custom_filter.is_new_lead
            this.should_follow_the_sun = 0
            this.is_filters_applied = false
        },

        changeIsNewLead(is_new_lead) {
            this.custom_filter.is_new_lead = is_new_lead ? 1 : 0
            this.custom_filter.has_unread = this.custom_filter.has_unread == 1 && is_new_lead == 1 ? 0 : this.custom_filter.has_unread
            this.custom_filter.should_follow_the_sun = 0
            this.has_unread = this.custom_filter.has_unread
            this.should_follow_the_sun = 0
            this.outbound_call_count_switch = 0
            this.outbound_call_count_start = null
            this.outbound_call_count_end = null
            this.custom_filter.outbound_call_count_start = null
            this.custom_filter.outbound_call_count_end = null
            this.is_filters_applied = false
        },

        changeUnassignedLeads(unassigned_leads) {
            this.custom_filter.unassigned_leads = unassigned_leads ? 1 : 0
            this.is_filters_applied = false
        },

        changeShouldFollowTheSun(should_follow_the_sun) {
            this.custom_filter.should_follow_the_sun = should_follow_the_sun ? 1 : 0
            this.custom_filter.has_unread = 0
            this.custom_filter.is_new_lead = 0
            this.has_unread = 0
            this.is_new_lead = 0
            this.is_filters_applied = false
        },

        changeTextAuthorized(text_authorized) {
            this.custom_filter.text_authorized = text_authorized ? 1 : 0
            this.is_filters_applied = false
        },

        changeCampaigns(filter_campaigns) {
            this.custom_filter.campaigns = filter_campaigns
            this.is_filters_applied = false
        },

        changeAgents(filter_users) {
            this.custom_filter.users = filter_users
            this.is_filters_applied = false
        },

        changeBroadcasts(filter_broadcasts) {
            this.custom_filter.broadcasts = filter_broadcasts
            this.is_filters_applied = false
        },

        changeNotResponded(not_responded) {
            this.custom_filter.not_responded = not_responded ? 1 : 0
            if (!not_responded) {
                this.not_responded_hours = null
                this.custom_filter.not_responded_hours = null
            }
            this.not_responded_model.not_responded_hours = null
            this.rules_not_responded.not_responded_hours[0].required = not_responded === 1
            this.not_responded_model.not_responded = not_responded
            this.is_filters_applied = false
            this.preValidateForm('not-responded-form')
        },

        changeNotRespondedHours() {
            this.not_responded_hours = this.not_responded_model.not_responded_hours
            this.custom_filter.not_responded_hours = this.not_responded_hours
            this.is_filters_applied = false
        },

        changeResponded(responded) {
            this.custom_filter.responded = responded ? 1 : 0;
            if (!responded) {
                this.responded_hours = null
                this.custom_filter.responded_hours = null
            }
            this.responded_model.responded_hours = null
            this.rules_responded.responded_hours[0].required = responded === 1
            this.responded_model.responded = responded
            this.is_filters_applied = false
            this.preValidateForm('responded-form')
        },

        changeRespondedHours() {
            this.responded_hours = this.responded_model.responded_hours
            this.custom_filter.responded_hours = this.responded_hours
            this.is_filters_applied = false
        },

        changeNotContacted(not_contacted) {
            this.custom_filter.not_contacted = not_contacted ? 1 : 0
            if (!not_contacted) {
                this.not_contacted_hours = null
                this.custom_filter.not_contacted_hours = null
            }
            this.not_contacted_model.not_contacted_hours = null
            this.rules_not_contacted.not_contacted_hours[0].required = not_contacted === 1
            this.not_contacted_model.not_contacted = not_contacted
            this.is_filters_applied = false
            this.preValidateForm('not-contacted-form')
        },

        changeNotContactedHours() {
            this.not_contacted_hours = this.not_contacted_model.not_contacted_hours
            this.custom_filter.not_contacted_hours = this.not_contacted_hours
            this.is_filters_applied = false
        },

        changeStatesLimitFilter(states_info) {
            this.states_limit = states_info
            this.custom_filter.states_limit = states_info
            this.is_filters_applied = false
            this.preValidateForm('states-limit-form')
        },

        changeInitialLineOnly(initial_line_only) {
            this.custom_filter.initial_line_only = initial_line_only ? 1 : 0
            this.is_filters_applied = false
        },

        changeContactCountry(country) {
            this.custom_filter.contact_country = country
            this.filter_contact_timezone = ''
            this.custom_filter.contact_timezone = ''
            this.is_filters_applied = false
        },

        changeContactTimezone(timezone) {
            this.custom_filter.contact_timezone = timezone
            this.is_filters_applied = false
        },

        scheduledMessageFilterChange(variable) {
            this.custom_filter[variable] = this[variable]
            this.is_filters_applied = false

            if (variable === 'has_scheduled_messages') {
                this.no_scheduled_messages = 0
                this.custom_filter.no_scheduled_messages = 0
            }

            if (variable === 'no_scheduled_messages') {
                this.has_scheduled_messages = 0
                this.custom_filter.has_scheduled_messages = 0
            }
        },

        enrolledInSequenceFilterChange(variable) {
            this.custom_filter[variable] = this[variable]
            this.is_filters_applied = false

            if (variable === 'enrolled_in_sequence') {
                this.not_enrolled_in_sequence = 0
                this.custom_filter.not_enrolled_in_sequence = 0
            }

            if (variable === 'not_enrolled_in_sequence') {
                this.enrolled_in_sequence = 0
                this.custom_filter.enrolled_in_sequence = 0
            }
        },

        noClose(event) {
            if (event) {
                event.stopPropagation()
            }
        },

        getFixPhone(phoneNumber, format = 'NATIONAL') {
            return this.$options.filters.fixPhone(phoneNumber, format)
        },

        resetModels() {
            this.untagged_only = this.custom_filter.untagged_only
            this.has_untagged_call = this.custom_filter.has_untagged_call
            this.filter_tags = this.custom_filter.tags
            this.filter_doesnt_have_tags = this.custom_filter.doesnt_have_tags
            this.filter_disposition_statuses = this.custom_filter.disposition_statuses
            this.filter_no_disposition_statuses = this.custom_filter.no_disposition_statuses
            this.lrn_types = this.custom_filter.lrn_types
            this.intake_source = this.custom_filter.intake_source
            this.lead_source = this.custom_filter.lead_source
            this.filter_campaigns = this.custom_filter.campaigns
            this.not_disposed = this.custom_filter.not_disposed
            this.is_blocked = this.custom_filter.is_blocked
            this.dnc_option = this.custom_filter.dnc_option
            this.has_unread = this.custom_filter.has_unread
            this.is_new_lead = this.custom_filter.is_new_lead
            this.has_scheduled_messages = this.custom_filter.has_scheduled_messages
            this.no_scheduled_messages = this.custom_filter.no_scheduled_messages
            this.enrolled_in_sequence = this.custom_filter.enrolled_in_sequence
            this.not_enrolled_in_sequence = this.custom_filter.not_enrolled_in_sequence
            this.unassigned_leads = this.custom_filter.unassigned_leads
            this.should_follow_the_sun = this.custom_filter.should_follow_the_sun
            this.text_authorized = this.custom_filter.text_authorized
            this.filter_users = this.custom_filter.users
            this.filter_broadcasts = this.custom_filter.broadcasts
            this.changed = this.custom_filter.changed
            this.not_contacted = this.custom_filter.not_contacted
            this.not_contacted_hours = this.custom_filter.not_contacted_hours
            this.not_responded = this.custom_filter.not_responded
            this.not_responded_hours = this.custom_filter.not_responded_hours
            this.responded = this.custom_filter.responded
            this.responded_hours = this.custom_filter.responded_hours
            this.states_limit = this.custom_filter.states_limit
            this.initial_line_only = this.custom_filter.initial_line_only
            this.contact_country = this.custom_filter.contact_country
            this.contact_timezone = this.custom_filter.contact_timezone
            this.outbound_call_count_switch = 0
            this.outbound_call_count_start = this.custom_filter.outbound_call_count_start
            this.outbound_call_count_end = this.custom_filter.outbound_call_count_end
            this.outbound_call_count_model.outbound_call_count_start = this.outbound_call_count_start
            this.outbound_call_count_model.outbound_call_count_end = this.outbound_call_count_end
            this.not_contacted_model.not_contacted_hours = this.not_contacted_hours
            this.responded_model.responded_hours = this.responded_hours
            this.not_responded_model.not_responded_hours = this.not_responded_hours
            // Events
            this.has_appointments = this.custom_filter.has_appointments
            this.has_reminders = this.custom_filter.has_reminders
            this.event_range = this.custom_filter.event_range
        },

        beforeResetFilters() {
            this.resetOurFilters()
            this.$emit('resetFilters')
        },

        resetOurFilters(should_toggle = true) {
            this.resetFilters()
            this.custom_filter = {...this.filter}
            this.default_filter = {...this.filter}
            this.resetModels()
            this.assignDefaultDateField()

            // if from date is not in route query,
            // change the date range
            if (!this.from_date) {
                VueEvent.fire('change_date_range', 'All Time')
            }

            this.filter.changed = false
            this.changed = false
            this.filter_options.is_changed = !this.filter_options.is_changed
            this.is_filters_applied = false
            VueEvent.fire('update_contact_filter_text', [])
            VueEvent.fire('unfiltered_new_leads_unanswered_messages')
            if (should_toggle) {
                this.toggleFiltersMenu()
            }

            this.clearFieldErrors()
        },

        clearFieldErrors() {
            this.resetForm('responded-form')
            this.resetForm('not-responded-form')
            this.resetForm('not-contacted-form')
            this.resetForm('outbound-call-count-start')
        },

        applyOurFilters(skip_verification = false, should_toggle = true) {
            let not_responded_validated = this.preValidateForm('not-responded-form')
            let not_contacted_validated = this.preValidateForm('not-contacted-form')
            if (!skip_verification && (!not_responded_validated || !not_contacted_validated)) {
                return
            }

            if (this.hasFilterMenuChanges(this.custom_filter, this.default_filter)) {
                this.changed = true
            } else {
                this.changed = false
            }
            if (!this.is_filters_applied) {
                for (let index in this.custom_filter) {
                    this.$set(this.filter, index, this.custom_filter[index])
                }
                this.filter.changed = true
                this.is_filters_applied = true
                this.filter_options.is_changed = !this.filter_options.is_changed
            }
            this.contactFiltersToText()

            if (should_toggle) {
                this.toggleFiltersMenu()
            }
        },

        checkSize() {
            let valid_sizes = ['xxl', 'xl']
            if (valid_sizes.includes(this.$mq)) {
                return true
            }
            return false
        },

        exportTo: function (type, filtered_contacts) {
            let exportParams = this.filter

            // remove pagination stuff
            exportParams.page = null
            exportParams.per_page = null

            switch (type) {
                case 'csv':
                    exportParams.export_type = 'export'
                    break
                case 'csv-all':
                    exportParams.export_type = 'export'
                    break
            }
            axios.get('/api/v1/contact', {
                params: exportParams
            }).then(() => {
                exportParams.export_type = 'json'
                this.$notify.info({
                    offset: 95,
                    title: 'Exports',
                    dangerouslyUseHTMLString: true,
                    message: `Your export is on its way. You can check the progress from the export page or <b class="text-dark-greenish pointer">click here</b>.`,
                    duration: 3000,
                    onClick: () => {
                        this.$router.push({name: 'Account', query: {tab: 'exports'}}).catch((err) => {
                        })
                        Notification.closeAll()
                    }
                })
                this.exports_menu_visible = false
            }).catch(err => {
                this.exports_menu_visible = false
                this.$root.handleErrors(err.response)
                exportParams.export_type = 'json'
            })
        },

        preventSubmit() {
            return
        },

        resetContactFilters() {
            this.resetOurFilters(false)
            VueEvent.fire('update_contact_filter_text', [])
        },

        getNorthAmericanTimezones(country = 'US') {
            if (country == 'CA') {
                return [
                    {
                        name: 'Newfoundland',
                        value: 'America/St_Johns'
                    }, {
                        name: 'Atlantic',
                        value: 'America/Halifax'
                    }, {
                        name: 'Atlantic (no DST)',
                        value: 'America/Blanc-Sablon'
                    }, {
                        name: 'Eastern',
                        value: 'America/Toronto'
                    }, {
                        name: 'Eastern (no DST)',
                        value: 'America/Atikokan'
                    }, {
                        name: 'Central',
                        value: 'America/Winnipeg'
                    }, {
                        name: 'Central {no DST)',
                        value: 'America/Regina'
                    }, {
                        name: 'Mountain',
                        value: 'America/Edmonton'
                    }, {
                        name: 'Mountain {no DST)',
                        value: 'America/Creston'
                    }, {
                        name: 'Pacific',
                        value: 'America/Vancouver'
                    },
                ]
            }

            return [
                {
                    name: 'Eastern Time',
                    value: 'America/New_York'
                }, {
                    name: 'Central Time',
                    value: 'America/Chicago'
                }, {
                    name: 'Mountain Time',
                    value: 'America/Denver'
                }, {
                    name: 'Mountain Time (no DST)',
                    value: 'America/Phoenix'
                }, {
                    name: 'Pacific Time',
                    value: 'America/Los_Angeles'
                }, {
                    name: 'Alaska Time',
                    value: 'America/Anchorage'
                }, {
                    name: 'Hawaii-Aleutian',
                    value: 'America/Adak'
                }, {
                    name: 'Hawaii-Aleutian Time (no DST)',
                    value: 'Pacific/Honolulu'
                },
            ]
        },

        async contactFiltersToText() {
            if (!this.shouldInitializeFiltersText) {
                setTimeout(() => {
                    this.contactFiltersToText()
                }, 500)
                return
            }

            let active_filters = []
            let exclude_properties = [
                'report_type',
                'search_text',
                'search_fields',
                'page',
                'per_page',
                'chart_period',
                'export_type',
                'date_field',
                'from_date',
                'to_date',
                'event_range',
                'not_responded_hours',
                'responded_hours',
                'not_contacted_hours',
                'comm_sort_by',
                'outbound_call_count_end'
            ]
            let cleaned_filter = {...this.custom_filter}
            cleaned_filter = _.pickBy(cleaned_filter, _.identity)
            for (let index in cleaned_filter) {
                let default_filter_value = this.default_filter[index]
                let custom_filter_value = cleaned_filter[index]
                if (!exclude_properties.includes(index) &&
                    default_filter_value !== undefined &&
                    default_filter_value != custom_filter_value) {
                    // here, we fix the filter names
                    let filter_name = index
                    switch (index) {
                        case 'lrn_types':
                            filter_name = 'line types'
                            break
                        case 'doesnt_have_tags':
                            filter_name = "doesn't have tags"
                            break
                        case 'contact_country':
                            filter_name = 'country'
                            break
                        case 'contact_timezone':
                            filter_name = 'timezone'
                            break
                        case 'untagged_only':
                            filter_name = 'untagged contacts'
                            break
                        case 'not_disposed':
                            filter_name = 'not disposed'
                            break
                        case 'is_blocked':
                            filter_name = 'blocked contacts'
                            break
                        case 'is_dnc':
                            filter_name = 'DNC contacts'
                            break
                        case 'has_scheduled_messages':
                            filter_name = 'Has Scheduled Messages'
                            break
                        case 'no_scheduled_messages':
                            filter_name = 'Does Not Have Scheduled Messages'
                            break
                        case 'enrolled_in_sequence':
                            filter_name = 'Is Enrolled in Sequence'
                            break
                        case 'not_enrolled_in_sequence':
                            filter_name = 'Not Enrolled in Sequence'
                            break
                        case 'is_new_lead':
                            filter_name = 'new leads'
                            break
                        case 'unassigned_leads':
                            filter_name = 'unassigned leads'
                            break
                        case 'should_follow_the_sun':
                            filter_name = 'follow the sun'
                            break
                        case 'lines':
                            filter_name = 'campaigns'
                            break
                        case 'has_unread':
                            filter_name = 'has unread messages'
                            break
                        case 'not_responded':
                            filter_name = "no response"
                            break
                        case 'responded':
                            filter_name = "last response"
                            break
                        case 'not_contacted':
                            filter_name = "no contact"
                            break
                        case 'text_authorized':
                            filter_name = 'TCPA approved'
                            break
                        case 'has_untagged_call':
                            filter_name = 'has untagged calls'
                            break
                        case 'states_limit':
                            filter_name = 'states'
                            break
                        case 'outbound_call_count_start':
                            filter_name = 'outbound call count'
                            break
                        case 'dnc_option':
                            filter_name = 'Contacts'
                            break
                    }
                    filter_name = filter_name.replace(/_/g, ' ')

                    let value = this.custom_filter[index]
                    let found = null
                    let is_date_range = false
                    let new_value = []

                    switch (index) {
                        case 'lrn_types':
                            for (let id in value) {
                                new_value.push(this.$options.filters.fixLrnType(value[id]))
                            }
                            break
                        case 'intake_source':
                            for (let key in value) {
                                found = this.IntakeSources.find(intake_source => intake_source.code === value[key])
                                let name = found ? found.name : value[key]
                                name = name.toLowerCase() == 'sms' ? name.toUpperCase() : name
                                new_value.push(name)
                            }
                            break
                        case 'lead_source':
                            for (let id in value) {
                                found = this.leadSources.find(lead_source => lead_source.code === value[id])
                                new_value.push(found ? found.name : value[id])
                            }
                            break
                        case 'tags':
                        case 'doesnt_have_tags':
                            await this.getTags(value)
                            for (let id in value) {
                                found = this.tags.find(o => o.id === parseInt(value[id]))
                                new_value.push(found !== undefined ? found.name : value[id])
                            }
                            break
                        case 'contact_country':
                            found = this.countries.find(country => country.id == value)
                            value = found ? found.name : value
                            break
                        case 'contact_timezone':
                            found = this.timezones.find(timezone => timezone.value == value)
                            value = found ? found.name : value
                            break
                        case 'disposition_statuses':
                        case 'no_disposition_statuses':
                            new_value.push(...this.buildDispositionTextual(value))
                            break
                        case 'has_appointments':
                        case 'has_reminders':
                            if (this.event_range && this.event_range[0] && this.event_range[1]) {
                                new_value = [
                                    this.$options.filters.fixFullDateUTC(this.event_range[0]),
                                    this.$options.filters.fixFullDateUTC(this.event_range[1])
                                ]
                                is_date_range = true
                            } else {
                                value = null
                            }
                            break
                        case 'campaigns':
                            for (let id in value) {
                                found = this.campaigns.find(campaign => campaign.id === parseInt(value[id]))
                                new_value.push(found ? found.name : value[id])
                            }
                            break
                        case 'users':
                            for (let id in value) {
                                found = this.users.find(user => user.id === parseInt(value[id]))
                                new_value.push(found ? (found.first_name + ' ' + found.last_name) : value[id])
                            }
                            break
                        case 'broadcasts':
                            for (let id in value) {
                                found = this.broadcasts.find(broadcast => broadcast.id === parseInt(value[id]))
                                new_value.push(found ? found.name : value[id])
                            }
                            break
                        case 'states_limit':
                            if (value.us.length) {
                                new_value = value.us
                            }
                            if (value.ca.length) {
                                new_value = value.ca
                            }
                            break
                        case 'not_responded':
                            value = value && this.not_responded_hours ? (this.not_responded_hours + ' hour' + (parseInt(this.not_responded_hours) > 1 ? 's' : '')) : ''
                            break;
                        case 'responded':
                            value = value && this.responded_hours ? (this.responded_hours + ' hour' + (parseInt(this.responded_hours) > 1 ? 's' : '')) : ''
                            break
                        case 'not_contacted':
                            value = value && this.not_contacted_hours ? (this.not_contacted_hours + ' hour' + (parseInt(this.not_responded_hours) > 1 ? 's' : '')) : ''
                            break
                        case 'outbound_call_count_start':
                            if (value != null) {
                                let start = parseInt(this.outbound_call_count_start)
                                let end = parseInt(this.outbound_call_count_end)
                                let times = start == end ? start : (start + ' - ' + end)
                                value = times + ' time' +
                                    (this.outbound_call_count_end > 1 ||
                                        (this.outbound_call_count_end - this.outbound_call_count_start) > 1 ? 's' : '')
                            }
                            break
                        case 'dnc_option':
                            switch (value) {
                                case FilterDncOptions.ALL_CONTACTS:
                                    value = 'All'
                                    break
                                case FilterDncOptions.CONTACTS_WITH_DNC:
                                    value = 'DNC'
                                    break
                                case FilterDncOptions.CONTACTS_WITHOUT_DNC_WITHOUT_OPTOUT:
                                    value = 'Not DNC, not Opt-Out'
                                    break
                                case FilterDncOptions.CONTACTS_WITHOUT_DNC_WITH_OPTOUT:
                                    value = 'Not DNC, Opt-Out'
                                    break
                            }
                            break
                    }
                    let data = {}
                    let has_value = false
                    if ((typeof value !== 'object' && value) || (typeof value === 'object' && value && value.length > 0)) {
                        data.value = [value]
                        has_value = true
                    }
                    if (!is_date_range && new_value.length) {
                        data.value = new_value
                        has_value = true
                    }
                    if (is_date_range) {
                        data.date_range = new_value
                        has_value = true
                    }
                    if (has_value) {
                        data.name = this.$options.filters.capitalize(filter_name)
                        active_filters.push(data)
                    }
                }
            }
            VueEvent.fire('update_contact_filter_text', active_filters)
        },

        buildDispositionTextual(ids) {
            let new_value = []

            for (let id in ids) {
                let found = this.disposition_statuses.find(disposition_status => disposition_status.id === parseInt(ids[id]))
                new_value.push(found ? found.name : ids[id])
            }

            return new_value
        },

        findLeadSource() {
            // check if there are lead sources to compare with
            if (!this.filter.lead_source ||
                !this.leadSources ||
                this.filter.lead_source.length == 0 ||
                this.leadSources.length == 0) {
                return
            }

            if (!this.shouldInitializeFiltersText) {
                setTimeout(() => {
                    this.findLeadSource()
                }, 500)
                return
            }

            for (let lead_source of this.filter.lead_source) {
                let found = this.leadSources.find(ls => ls.code.toString() == lead_source)
                if (found && !this.lead_source.includes(found.code)) {
                    this.lead_source.push(found.code)
                }
            }
        },

        updateCustomFilter(data) {
            this.custom_filter.from_date = data.from_date
            this.custom_filter.to_date = data.to_date
        },

        getCurrentRouteName() {
            let route_name = this.$route.name
            // if route name is undefined, let's get the parent route name
            if (typeof route_name === 'undefined') {
                route_name = typeof this.$route.matched[0].name !== 'undefined' ? this.$route.matched[0].name : route_name
            }
            return route_name
        },

        disableUnansweredLeadsSortOptions(state) {
            this.comm_sort_by_options.forEach(option => {
                // toggle `disabled` value
                if (option.value == 'newest_unanswered' || option.value == 'oldest_unanswered') {
                    option.disabled = state
                }
            })
        },

        pauseLiveUpdates() {
            this.setLiveContactUpdates(false)
            VueEvent.unlisten('contact_unanswered_leads_cleared')
            VueEvent.unlisten('update_unanswered_leads_count_and_filter')
            VueEvent.unlisten('contact_created')
            VueEvent.unlisten('contact_updated')
            VueEvent.unlisten('contact_deleted')
            VueEvent.unlisten('unfiltered_new_leads_unanswered_messages')
            VueEvent.unlisten('filtered_new_leads')
            VueEvent.unlisten('filtered_unanswered_messages')
        },

        playLiveUpdates() {
            this.setLiveContactUpdates(true)
            this.$router.go()
        },

        playLiveUpdateTimer() {
            this.live_updates_timer_interval = setInterval(() => {
                this.live_updates_timer++
                localStorage.setItem('live_updates_timer', this.live_updates_timer)
            }, 1000)
        },

        stopLiveUpdateTimer() {
            clearInterval(this.live_updates_timer_interval)
            this.live_updates_timer_interval = null
        },

        ...mapActions(['resetFilters']),
        ...mapActions('cache', ['setLiveContactUpdates']),
    },

    watch: {
        'filter.untagged_only': function () {
            this.untagged_only = this.filter.untagged_only
        },

        'filter.has_untagged_call': function () {
            this.has_untagged_call = this.filter.has_untagged_call
        },

        'filter.tags': function () {
            this.filter_tags = this.filter.tags
        },

        'filter.doesnt_have_tags': function () {
            this.filter_doesnt_have_tags = this.filter.doesnt_have_tags
        },

        'filter.disposition_statuses': function () {
            this.filter_disposition_statuses = this.filter.disposition_statuses
        },

        'filter.no_disposition_statuses': function () {
            this.filter_no_disposition_statuses = this.filter.no_disposition_statuses
        },

        'filter.lrn_types': function () {
            this.lrn_types = this.filter.lrn_types
        },

        'filter.has_reminders': function () {
            this.has_reminders = this.filter.has_reminders
        },

        'filter.has_appointments': function () {
            this.has_appointments = this.filter.has_appointments
        },

        'filter.event_range': function () {
            this.event_range = this.filter.event_range
        },

        'filter.intake_source': function () {
            this.intake_source = this.filter.intake_source
        },

        'filter.lead_source': function () {
            this.findLeadSource()
        },

        'filter.campaigns': function () {
            this.filter_campaigns = this.filter.campaigns
        },

        'filter.not_disposed': function () {
            this.not_disposed = this.filter.not_disposed
        },

        'filter.is_blocked': function () {
            this.is_blocked = this.filter.is_blocked
        },

        'filter.has_scheduled_messages': function () {
            this.has_scheduled_messages = this.filter.has_scheduled_messages
        },

        'filter.no_scheduled_messages': function () {
            this.no_scheduled_messages = this.filter.no_scheduled_messages
        },

        'filter.enrolled_in_sequence': function () {
            this.enrolled_in_sequence = this.filter.enrolled_in_sequence
        },

        'filter.not_enrolled_in_sequence': function () {
            this.not_enrolled_in_sequence = this.filter.not_enrolled_in_sequence
        },

        'filter.has_unread': function () {
            this.has_unread = this.filter.has_unread
            this.custom_filter.has_unread = this.has_unread
        },

        'filter.is_new_lead': function () {
            this.is_new_lead = this.filter.is_new_lead
            this.custom_filter.is_new_lead = this.is_new_lead
        },

        'filter.unassigned_leads': function () {
            this.unassigned_leads = this.filter.unassigned_leads
            this.custom_filter.unassigned_leads = this.unassigned_leads
        },

        'filter.should_follow_the_sun': function () {
            this.should_follow_the_sun = this.filter.should_follow_the_sun
            this.custom_filter.should_follow_the_sun = this.should_follow_the_sun
        },

        'filter.dnc_option': function () {
            this.dnc_option = this.filter.dnc_option
            this.custom_filter.dnc_option = this.dnc_option
        },

        'filter.not_responded': function () {
            this.not_responded = this.filter.not_responded
        },

        'filter.not_responded_hours': function () {
            this.not_responded_hours = this.filter.not_responded_hours
        },

        'filter.responded': function () {
            this.responded = this.filter.responded
        },

        'filter.responded_hours': function () {
            this.responded_hours = this.filter.responded_hours
        },

        'filter.not_contacted': function () {
            this.not_contacted = this.filter.not_contacted
        },

        'filter.not_contacted_hours': function () {
            this.not_contacted_hours = this.filter.not_contacted_hours
        },

        'filter.text_authorized': function () {
            this.text_authorized = this.filter.text_authorized
        },

        'filter.users': function () {
            this.filter_users = this.filter.users
        },

        'filter.broadcasts': function () {
            this.filter_broadcasts = this.filter.broadcasts
        },

        'filter.changed': function () {
            this.changed = this.filter.changed
        },

        'filter.states_limit.us': function (newValue, oldValue) {
            if (JSON.stringify(newValue) != JSON.stringify(oldValue)) {
                this.states_limit.us = this.filter.states_limit.us
            }
        },

        'filter.states_limit.ca': function (newValue, oldValue) {
            if (JSON.stringify(newValue) != JSON.stringify(oldValue)) {
                this.states_limit.ca = this.filter.states_limit.ca
            }
        },

        'filter.initial_line_only': function () {
            this.initial_line_only = this.filter.initial_line_only
        },

        'filter.search_text': function () {
            if (this.filter.search_text.length > 0) {
                this.filter.changed = true
            } else if (!this.hasFilterMenuChanges(this.custom_filter, this.default_filter)) {
                this.filter.changed = false
            }
        },

        'filter.contact_country': function () {
            this.filter_contact_country = this.filter.contact_country
        },

        'filter.contact_timezone': function () {
            this.filter_contact_timezone = this.filter.contact_timezone
        },

        'filter.date_field': function() {
            this.date_field = this.filter.date_field
        },

        'filter.comm_sort_by': function() {
            this.comm_sort_by = this.filter.comm_sort_by
        },

        'filter.outbound_call_count_start': function() {
            this.outbound_call_count_start = this.filter.outbound_call_count_start
        },

        'filter.outbound_call_count_end': function() {
            this.outbound_call_count_end = this.filter.outbound_call_count_end
        },

        changed() {
            if (this.changed) {
                this.filter_options.is_changed = !this.filter_options.is_changed
            }
            this.contactFiltersToText()
            this.$emit('changed', this.changed)
        },

        tag_id() {
            setTimeout(() => {
                if (this.tag_id) {
                    this.changeTags([parseInt(this.tag_id)])
                    this.applyOurFilters(true, false)
                    VueEvent.fire('change_date_range', 'All Time')
                }
            }, 500)
        },

        live_update_contacts(val) {
            if (val) {
                this.stopLiveUpdateTimer()
                this.live_updates_timer = 0
            } else {
                this.playLiveUpdateTimer()
            }
        }
    },

    beforeDestroy() {
        clearInterval(this.$options.date_range_interval)
        this.stopLiveUpdateTimer()
    }
}
</script>
