var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          staticClass: "dialog-blank",
          attrs: {
            width: "60vw",
            top: "5vh",
            "append-to-body": "",
            "show-close": false,
            "data-testid": "schedule-messages-dialog",
            visible: _vm.scheduledMessagesDialogVisible,
          },
          on: {
            "update:visible": function ($event) {
              _vm.scheduledMessagesDialogVisible = $event
            },
          },
        },
        [
          _c("div", { staticClass: "container-listing" }, [
            _c("div", { staticClass: "container-header d-flex" }, [
              _c("h5", { staticClass: "listing-heading" }, [
                _vm._v("Scheduled Messages for " + _vm._s(_vm.contactFullName)),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "ml-auto" },
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "btn-dialog-close",
                      attrs: {
                        type: "text",
                        size: "large",
                        "data-testid": "schedule-messages-close-dialog-button",
                      },
                      on: { click: _vm.closeDialog },
                    },
                    [_c("i", { staticClass: "fa fa-remove" })]
                  ),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "container-body" }, [
              _c("div", { staticClass: "container-listing-tools row mb-1" }, [
                _c(
                  "div",
                  { attrs: { id: "toolbox-scheduled-msg" } },
                  [
                    _c(
                      "el-select",
                      {
                        staticClass: "mr-2",
                        attrs: {
                          placeholder: "Select",
                          size: "small",
                          "data-testid": "schedule-messages-select",
                        },
                        on: { change: _vm.handleSelectChange },
                        model: {
                          value: _vm.filters.sort,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "sort", $$v)
                          },
                          expression: "filters.sort",
                        },
                      },
                      _vm._l(_vm.options, function (item) {
                        return _c("el-option", {
                          key: item.value,
                          attrs: {
                            "default-first-option": "",
                            label: item.label,
                            value: item.value,
                          },
                        })
                      }),
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: {
                          size: "small",
                          "data-testid": "schdule-messages-refresh-list-button",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.fetchScheduledMessages(1)
                          },
                        },
                      },
                      [
                        _c("i", { staticClass: "fa fa-refresh" }),
                        _vm._v(" Refresh List\n                        "),
                      ]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "ml-auto d-flex" }, [
                  _c("div", { staticClass: "mr-2 align-self-center" }, [
                    _c("span", { staticClass: "text-grey" }, [
                      _vm._v("Total Scheduled Messages:"),
                    ]),
                    _vm._v(" "),
                    _c("strong", [
                      _vm._v(_vm._s(_vm._f("numFormat")(_vm.pagination.total))),
                    ]),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.is_scheduled_messages_loading,
                      expression: "is_scheduled_messages_loading",
                    },
                  ],
                  staticStyle: { color: "initial", "font-size": "0.95rem" },
                },
                [
                  _c(
                    "table",
                    {
                      staticClass: "table table-hover",
                      attrs: { "data-testid": "schedule-messages-table" },
                    },
                    [
                      _c(
                        "thead",
                        {
                          attrs: {
                            "data-testid": "schedule-messages-head-table",
                          },
                        },
                        [
                          _c(
                            "tr",
                            {
                              attrs: {
                                "data-testid": "schedule-messages-head-row",
                              },
                            },
                            [
                              _c(
                                "th",
                                {
                                  attrs: {
                                    "data-testid":
                                      "schedule-message-head-column",
                                    width: "40%",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                                    Message\n                                "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "th",
                                {
                                  attrs: {
                                    "data-testid": "schedule-media-head-column",
                                  },
                                },
                                [_vm._v("Media")]
                              ),
                              _vm._v(" "),
                              _c(
                                "th",
                                {
                                  attrs: {
                                    "data-testid": "schedule-for-head-column",
                                    width: "20%",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                                    Scheduled For\n                                "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "th",
                                {
                                  attrs: {
                                    "data-testid": "schedule-by-head-column",
                                    width: "15%",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                                    Scheduled By\n                                "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c("th", {
                                attrs: {
                                  "data-testid":
                                    "schedule-last-column-head-column",
                                  width: "5%",
                                },
                              }),
                            ]
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "tbody",
                        {
                          attrs: {
                            "data-testid": "schedule-messages-body-table",
                          },
                        },
                        _vm._l(_vm.pagination.data, function (message, index) {
                          return _c(
                            "tr",
                            {
                              key: message.id,
                              attrs: {
                                "data-testid": "schedule-messages-body-row",
                              },
                            },
                            [
                              _c(
                                "td",
                                {
                                  staticClass: "align-middle",
                                  attrs: {
                                    "data-testid":
                                      "schedule-messages-body-column",
                                  },
                                },
                                [
                                  message.message.length < 100
                                    ? _c("span", [
                                        _vm._v(
                                          "\n                                        " +
                                            _vm._s(message.message) +
                                            "\n                                    "
                                        ),
                                      ])
                                    : _c(
                                        "el-popover",
                                        {
                                          attrs: {
                                            placement: "bottom-end",
                                            width: "400",
                                            trigger: "hover",
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "p-1 handle-whitespace",
                                              attrs: {
                                                id: "popover-scheduled-msg",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                                            " +
                                                  _vm._s(message.message) +
                                                  "\n                                        "
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              attrs: { slot: "reference" },
                                              slot: "reference",
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm._f("strLimit")(
                                                    message.message,
                                                    100
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "td",
                                {
                                  staticClass: "align-middle",
                                  attrs: {
                                    "data-testid":
                                      "schedule-messages-body-column",
                                  },
                                },
                                [
                                  !message.gif_url &&
                                  message.uploaded_files.length < 1
                                    ? _c(
                                        "div",
                                        { staticClass: "d-inline px-1" },
                                        [_c("span", [_vm._v("---")])]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "d-flex align-items-center",
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "px-0" },
                                        [
                                          message.gif_url
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass: "d-inline px-1",
                                                },
                                                [
                                                  _c("img", {
                                                    attrs: {
                                                      width: "20%",
                                                      "data-testid":
                                                        "gif-url-img",
                                                      src: message.gif_url,
                                                    },
                                                  }),
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _vm._l(
                                            message.uploaded_files,
                                            function (file) {
                                              return _c(
                                                "div",
                                                {
                                                  key: file.id,
                                                  staticClass: "d-inline px-1",
                                                },
                                                [
                                                  file.mimetype &&
                                                  _vm.isAttachmentImage(
                                                    file.mimetype
                                                  )
                                                    ? _c("img", {
                                                        directives: [
                                                          {
                                                            name: "show",
                                                            rawName: "v-show",
                                                            value: file.show,
                                                            expression:
                                                              "file.show",
                                                          },
                                                        ],
                                                        attrs: {
                                                          width: "20%",
                                                          src: _vm.getFileUrl(
                                                            file.uuid,
                                                            file.extension
                                                          ),
                                                          "data-testid":
                                                            "uploaded-files-img",
                                                        },
                                                      })
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  file.mimetype &&
                                                  (_vm.isAttachmentText(
                                                    file.mimetype
                                                  ) ||
                                                    _vm.isAttachmentApplication(
                                                      file.mimetype
                                                    ))
                                                    ? _c(
                                                        "a",
                                                        {
                                                          directives: [
                                                            {
                                                              name: "show",
                                                              rawName: "v-show",
                                                              value: file.show,
                                                              expression:
                                                                "file.show",
                                                            },
                                                          ],
                                                          attrs: {
                                                            target: "_blank",
                                                            href: _vm.getFileUrl(
                                                              file.uuid,
                                                              file.extension
                                                            ),
                                                            "data-testid":
                                                              "uploaded-files-links",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "d-inline",
                                                            },
                                                            [
                                                              _c("img", {
                                                                attrs: {
                                                                  width: "15%",
                                                                  "data-testid":
                                                                    "svg-file-img",
                                                                  src: "/assets/images/app-icons/file.svg",
                                                                },
                                                              }),
                                                              _vm._v(" "),
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "text-xxs",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      file.original_file
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  file.mimetype &&
                                                  _vm.isAttachmentVideo(
                                                    file.mimetype
                                                  )
                                                    ? _c(
                                                        "div",
                                                        {
                                                          directives: [
                                                            {
                                                              name: "show",
                                                              rawName: "v-show",
                                                              value: file.show,
                                                              expression:
                                                                "file.show",
                                                            },
                                                          ],
                                                        },
                                                        [
                                                          _c(
                                                            "video",
                                                            {
                                                              staticClass:
                                                                "rounded",
                                                              attrs: {
                                                                width: "200",
                                                                "data-testid":
                                                                  "uploaded-files-video",
                                                                controls: "",
                                                              },
                                                            },
                                                            [
                                                              _c("source", {
                                                                attrs: {
                                                                  src: _vm.getFileUrl(
                                                                    file.uuid,
                                                                    file.extension
                                                                  ),
                                                                  type: file.mimetype,
                                                                },
                                                              }),
                                                              _vm._v(
                                                                "\n                                                        Your browser does not support the video tag.\n                                                    "
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  file.mimetype &&
                                                  _vm.isAttachmentAudio(
                                                    file.mimetype
                                                  )
                                                    ? _c(
                                                        "div",
                                                        {
                                                          directives: [
                                                            {
                                                              name: "show",
                                                              rawName: "v-show",
                                                              value: file.show,
                                                              expression:
                                                                "file.show",
                                                            },
                                                          ],
                                                        },
                                                        [
                                                          _c(
                                                            "audio",
                                                            {
                                                              staticClass:
                                                                "mt-2",
                                                              staticStyle: {
                                                                height: "25px",
                                                                width: "200px",
                                                              },
                                                              attrs: {
                                                                "data-testid":
                                                                  "uploaded-files-audio",
                                                                controls: "",
                                                              },
                                                            },
                                                            [
                                                              _c("source", {
                                                                attrs: {
                                                                  src: _vm.getFileUrl(
                                                                    file.uuid,
                                                                    file.extension
                                                                  ),
                                                                  type: file.mimetype,
                                                                },
                                                              }),
                                                              _vm._v(
                                                                "\n                                                        Your browser does not support the audio element.\n                                                    "
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                ]
                                              )
                                            }
                                          ),
                                        ],
                                        2
                                      ),
                                      _vm._v(" "),
                                      message.uploaded_files.length > 1
                                        ? _c(
                                            "div",
                                            { staticClass: "px-1" },
                                            [
                                              _c(
                                                "el-tooltip",
                                                {
                                                  staticClass: "item",
                                                  attrs: {
                                                    effect: "dark",
                                                    placement: "top",
                                                    content:
                                                      _vm.getMediaDisplayTooltipContent(
                                                        message
                                                          .uploaded_files[1]
                                                          .show
                                                      ),
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "el-button",
                                                    {
                                                      attrs: {
                                                        size: "small",
                                                        plain: "",
                                                        round: "",
                                                        "data-testid":
                                                          "toggle-message-files-button",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.toggleMessageFilesDisplay(
                                                            index
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "font-weight-bold",
                                                        },
                                                        [
                                                          _vm._v(
                                                            "+" +
                                                              _vm._s(
                                                                message
                                                                  .uploaded_files
                                                                  .length - 1
                                                              ) +
                                                              " more "
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "align-middle",
                                                        },
                                                        [
                                                          _c("i", {
                                                            staticClass: "fa",
                                                            class: {
                                                              "fa-angle-up":
                                                                message
                                                                  .uploaded_files[1]
                                                                  .show,
                                                              "fa-angle-down":
                                                                !message
                                                                  .uploaded_files[1]
                                                                  .show,
                                                            },
                                                          }),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ]
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c("td", { staticClass: "align-middle" }, [
                                _c(
                                  "span",
                                  { staticClass: "font-weight-normal" },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("momentFormat")(
                                          message.scheduled_at,
                                          "MMM D, YYYY h:mma z",
                                          true
                                        )
                                      )
                                    ),
                                  ]
                                ),
                              ]),
                              _vm._v(" "),
                              _c("td", { staticClass: "align-middle" }, [
                                message.user
                                  ? _c("span", [
                                      _vm._v(_vm._s(message.user.full_name)),
                                    ])
                                  : _vm._e(),
                              ]),
                              _vm._v(" "),
                              _c(
                                "td",
                                { staticClass: "align-middle text-right" },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      staticClass: "text-danger",
                                      attrs: {
                                        size: "small",
                                        "data-testid":
                                          "delete-scheduled-message",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.deleteScheduledMessage(
                                            message.id,
                                            index
                                          )
                                        },
                                      },
                                    },
                                    [_c("i", { staticClass: "fa fa-trash-o" })]
                                  ),
                                ],
                                1
                              ),
                            ]
                          )
                        }),
                        0
                      ),
                    ]
                  ),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "container-footer d-flex" }, [
              _c(
                "div",
                { staticClass: "ml-auto" },
                [
                  _c("el-pagination", {
                    attrs: {
                      layout: "sizes, prev, pager, next",
                      "current-page": _vm.pagination.current_page,
                      "page-count": _vm.pagination.per_page,
                      "page-size": _vm.filters.size,
                      "pager-count": 5,
                      total: _vm.pagination.total,
                      "hide-on-single-page": true,
                      "data-testid": "schedule-messages-pagination",
                    },
                    on: {
                      "update:currentPage": function ($event) {
                        return _vm.$set(_vm.pagination, "current_page", $event)
                      },
                      "update:current-page": function ($event) {
                        return _vm.$set(_vm.pagination, "current_page", $event)
                      },
                      "update:pageCount": function ($event) {
                        return _vm.$set(_vm.pagination, "per_page", $event)
                      },
                      "update:page-count": function ($event) {
                        return _vm.$set(_vm.pagination, "per_page", $event)
                      },
                      "update:pageSize": function ($event) {
                        return _vm.$set(_vm.filters, "size", $event)
                      },
                      "update:page-size": function ($event) {
                        return _vm.$set(_vm.filters, "size", $event)
                      },
                      "size-change": function ($event) {
                        return _vm.fetchScheduledMessages(1)
                      },
                      "current-change": _vm.fetchScheduledMessages,
                    },
                  }),
                ],
                1
              ),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }