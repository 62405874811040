var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      staticClass: "dialog-blank",
      attrs: {
        width: "35%",
        "append-to-body": "",
        "show-close": false,
        "before-close": _vm.handleClose,
        visible: _vm.form_visible,
      },
      on: {
        "update:visible": function ($event) {
          _vm.form_visible = $event
        },
      },
    },
    [
      _c("div", { staticClass: "container-listing rounded" }, [
        _c("div", { staticClass: "container-header d-flex" }, [
          _c("h4", { staticClass: "listing-heading" }, [_vm._v("New Chatbot")]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "ml-auto" },
            [
              _c(
                "el-button",
                {
                  staticClass: "btn-dialog-close",
                  attrs: { type: "text", size: "large" },
                  on: { click: _vm.handleClose },
                },
                [_c("i", { staticClass: "fa fa-remove" })]
              ),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "container-body" },
          [
            _c(
              "el-form",
              { attrs: { model: _vm.chatbot } },
              [
                _c(
                  "el-form-item",
                  [
                    _c("div", { staticClass: "form-label" }, [
                      _c("h5", { staticClass: "text-dark" }, [
                        _vm._v("Name (*)"),
                      ]),
                      _vm._v(" "),
                      _c("small", [
                        _vm._v(
                          "\n                            Specify a name for this chatbot.\n                        "
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("el-input", {
                      model: {
                        value: _vm.chatbot.name,
                        callback: function ($$v) {
                          _vm.$set(_vm.chatbot, "name", $$v)
                        },
                        expression: "chatbot.name",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  [
                    _c("div", { staticClass: "form-label" }, [
                      _c("h5", { staticClass: "text-dark" }, [
                        _vm._v("Workflow (*)"),
                      ]),
                      _vm._v(" "),
                      _c("small", [
                        _vm._v(
                          "\n                            Specify a workflow for this chatbot.\n                        "
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("el-input", {
                      model: {
                        value: _vm.chatbot.workflow,
                        callback: function ($$v) {
                          _vm.$set(_vm.chatbot, "workflow", $$v)
                        },
                        expression: "chatbot.workflow",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  [
                    _c("div", { staticClass: "form-label" }, [
                      _c("h5", { staticClass: "text-dark" }, [
                        _vm._v("Lines (*)"),
                      ]),
                      _vm._v(" "),
                      _c("small", [
                        _vm._v(
                          "\n                            Select 1 or multiple lines to connect to this chatbot.\n                        "
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c(
                      "el-select",
                      {
                        staticClass: "w-100",
                        attrs: {
                          multiple: "",
                          filterable: "",
                          placeholder: "Select lines",
                        },
                        model: {
                          value: _vm.chatbot.campaign_ids,
                          callback: function ($$v) {
                            _vm.$set(_vm.chatbot, "campaign_ids", $$v)
                          },
                          expression: "chatbot.campaign_ids",
                        },
                      },
                      _vm._l(_vm.lines, function (line) {
                        return _c("el-option", {
                          key: line.id,
                          attrs: { label: line.name, value: line.id },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  [
                    _c("div", { staticClass: "form-label" }, [
                      _c("h5", { staticClass: "text-dark" }, [_vm._v("Notes")]),
                      _vm._v(" "),
                      _c("small", [
                        _vm._v(
                          "\n                            Write any notes about this chatbot.\n                        "
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("el-input", {
                      attrs: { type: "textarea", rows: 2 },
                      model: {
                        value: _vm.chatbot.notes,
                        callback: function ($$v) {
                          _vm.$set(_vm.chatbot, "notes", $$v)
                        },
                        expression: "chatbot.notes",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("el-form-item", [
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-12" }, [
                      _c(
                        "div",
                        { staticClass: "d-flex" },
                        [
                          _c(
                            "el-button",
                            {
                              staticClass: "ml-auto mb-3",
                              attrs: {
                                type: "success",
                                disabled: _vm.creating_bot,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.createChatbot()
                                },
                              },
                            },
                            [
                              _vm.creating_bot
                                ? _c("i", {
                                    staticClass: "fa fa-spinner fa-spin",
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.creating_bot
                                ? _c("strong", [_vm._v(" Creating...")])
                                : _c("strong", [_vm._v(" Create")]),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ]),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }