<template>
    <div class="app-body max-height no-footer">
        <div class="h-v no-header">
            <div class="box row-col no-shadow"
                 style="min-height:100%">
                <div class="row p-2"
                     v-if="hasPermissionTo('list campaign') && selected_contact">
                    <div class="col-12">
                        <div class="pull-left no-select">
                            <button class="btn btn-xs blue text-xs"
                                    v-if="selected_contact.unread_count !== 0"
                                    :disabled="loading_mark_as_read"
                                    @click="markAllAsRead">
                                <i v-show="loading_mark_as_read"
                                   class="material-icons loader">
                                    &#xE863;
                                </i>
                                Mark All As Read
                            </button>
                        </div>

                        <div class="pull-right no-select">
                            <strong class="text-xs">Phone Number:</strong>
                            <el-select v-model="selected_phone_number"
                                       placeholder="Select a phone number"
                                       size="mini"
                                       style="width: 150px"
                                       class="no-select"
                                       @change="changeSelectedPhoneNumber">
                                <el-option
                                    v-for="phone_number in contact_phone_numbers"
                                    :key="phone_number.id"
                                    :label="phone_number.phone_number"
                                    :value="phone_number.phone_number">
                                    <span class="pull-left">{{ phone_number.phone_number | fixPhone }}</span>
                                </el-option>
                            </el-select>
                        </div>
                    </div>
                </div>
                <div class="row-row">
                    <div class="row-body">
                        <div class="row-inner pl-2 pr-2"
                             ref="activitiesWrap"
                             v-if="selected_contact && !loading_contact_communications">
                            <div class="p-a-sm">
                                <ul class="timeline timeline-center">
                                    <li class="tl-header mb-3"
                                        v-if="has_more_communications">
                                        <el-button type="primary"
                                                   size="medium"
                                                   :loading="is_loading_previous_activities"
                                                   plain
                                                   @click="loadMorePreviousActivities">
                                            Previous Activities
                                        </el-button>
                                    </li>
                                </ul>
                                <activity v-for="(communication, index) in filteredCommunications"
                                          :key="communication.id + '-comm-' + index"
                                          :ref="(communication.type !== undefined ? 'communication-' : 'contact-audit-') + communication.id"
                                          :communication="communication"
                                          :contact="selected_contact"
                                          :is_widget="true"
                                          @unread="increaseUnreadCount">
                                </activity>

                                <div class="el-table__empty-block"
                                     v-if="filteredCommunications.length == 0">
                                    <span class="el-table__empty-text"
                                          style="color: #606266;"
                                          v-if="selected_contact">
                                        No Data
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div class="d-flex justify-content-center align-items-center p-a-sm h-100"
                             v-if="loading_contact || loading_contact_communications">
                            <div class="el-table__empty-block">
                                <span class="el-table__empty-text"
                                      style="color: #606266;">
                                    Loading <span class="loader__dot">.</span><span class="loader__dot">.</span><span class="loader__dot">.</span>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <message-composer ref="message_composer"
                                  v-model="message_object"
                                  :phone_numbers="contact_phone_numbers"
                                  :api_key="api_key"
                                  v-if="contact_loaded"
                                  @send-message="sendNewMessage"
                                  @create-scheduled-message="createScheduledMessage">
                </message-composer>
            </div>
        </div>
    </div>
</template>

<script>
import auth from '../../auth'
import {mapActions} from 'vuex'
import {acl_mixin, avatar_mixin, contact_activities_mixin, form_validation_mixin} from '../../mixins'
import SearchGiphy from '../../components/search-giphy'
import TemplateListDialog from '../../components/template-list-dialog'
import MessageComposer from '../../components/messenger/message-composer'
import * as Carriers from '../../constants/carriers'

export default {
    mixins: [
        acl_mixin,
        avatar_mixin,
        contact_activities_mixin,
        form_validation_mixin
    ],

    components: {
        SearchGiphy,
        TemplateListDialog,
        MessageComposer
    },

    props: {
        api_key: {
            type: String,
            required: false,
            default: null
        },

        contact_id: {
            required: false
        }
    },

    data() {
        return {
            loading: false,
            initialized: false,
            loading_contact_phone_numbers: false,
            message_object: {},
            contact_loaded: false,
            Carriers
        }
    },

    created() {
        // flag for this widget used in the mixin
        this.sms_only = true
        this.$root.$data.is_widget = true
        this.init()
    },

    methods: {
        init() {
            if (this.api_key) {
                localStorage.setItem('api_token', this.api_key)
            }
            this.loading = true
            auth.check().then((res) => {
                localStorage.setItem('company_id', res.data.user.company.id)
                this.setCurrentCompany(res.data.user.company)
                if (!this.$root.$data.is_widget) {
                    this.resetVuex()
                }
                this.headers.Authorization = 'Bearer ' + this.auth.user.profile.api_token
                this.loading = false
                this.initialized = true
                if (this.contact_id) {
                    this.processFetchContactInfo()
                }
            }).catch((err) => {
                this.loading = false
                if (this.api_key) {
                    console.log('Error: api key is not valid', err)
                } else {
                    this.$router.push({name: 'Login', query: {redirect: this.$route.fullPath}})
                }
            })
        },

        resetSelectedContact() {
            this.selected_contact = null
            this.selected_campaign_id = null
            this.selected_phone_number = null
            this.contact_phone_numbers = []
        },

        processFetchContactInfo() {
            if (!this.initialized) {
                setTimeout(() => {
                    this.processFetchContactInfo()
                }, 500)
                return
            }
            this.fetchContactInfo().then(res => {
                if (res && res.data) {
                    this.processFetchedContactInfo(res.data)
                }
            }).catch(err => {
                console.log(err)
            });
        },

        processFetchedContactInfo(selected_contact) {
            this.message_object.contact = selected_contact
            this.fetchedContactInfo(selected_contact)
            this.fetchContactPhoneNumbers()
            this.loading_contact = false
            this.contact_loaded = true
        },

        fetchContactPhoneNumbers() {
            this.loading_contact_phone_numbers = true
            axios.get('/api/v1/contact/' + this.selected_contact.id + '/phone-numbers').then(res => {
                this.loading_contact_phone_numbers = false
                this.contact_phone_numbers = res.data
            }).catch(err => {
                this.$root.handleErrors(err.response)
                this.loading_contact_phone_numbers = false
                console.log(err)
            })
        },

        increaseUnreadCount() {
            if (!this.selected_contact) {
                return
            }
            this.selected_contact.unread_count += 1
        },

        sendNewMessage(message_object) {
            if (this.$refs.message_composer) {
                this.$refs.message_composer.isLoading()
                axios.post('/api/v1/messages', message_object)
                    .then(res => {
                        this.message_object = {}
                        this.$refs.message_composer.isLoading(false)
                        this.$refs.message_composer.resetStaticFields()
                    })
                    .catch(err => {
                        if (this.$refs.message_composer) {
                            this.$refs.message_composer.isLoading(false)
                        }

                        if (err.response && err.response.status === 422) {
                            this.$notify.error({
                                title: 'Error',
                                message: err.response.data.message
                            })
                        }
                    })
            }
        },

        createScheduledMessage(message_object) {
            if (this.$refs.message_composer) {
                this.$refs.message_composer.isLoading()
                axios.post('/api/v1/scheduled-messages', message_object)
                    .then(res => {
                        this.message_object = {}
                        this.$refs.message_composer.isLoading(false)
                        this.$refs.message_composer.resetStaticFields()
                        this.$notify({
                            offset: 95,
                            title: 'Contact',
                            message: 'Message is scheduled to be sent',
                            type: 'success',
                            showClose: true,
                        })
                    })
                    .catch(err => {
                        if (this.$refs.message_composer) {
                            this.$refs.message_composer.isLoading(false)
                        }

                        if (err.response && err.response.status === 422) {
                            this.$notify.error({
                                title: 'Error',
                                message: err.response.data.message
                            })
                        }
                    })
            }
        },

        ...mapActions(['resetVuex']),
        ...mapActions('cache', ['setCurrentCompany'])
    },

    watch: {
        contact_id(newVal, oldVal) {
            if (newVal && !oldVal) {
                this.processFetchContactInfo()
            }
        },

        selected_phone_number() {
            this.$refs.message_composer.setPhoneNumber(this.selected_phone_number)
        }
    }
}
</script>
