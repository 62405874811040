var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "pt-3 pl-2 pr-2 clear" }, [
        _vm.hasPermissionTo("see usage page")
          ? _c("div", { staticClass: "row mb-4 mt-2 pl-2 pr-2" }, [
              !_vm.company.chargebee_auto_collection
                ? _c("div", { staticClass: "col-12 text-md" }, [
                    _c("span", [
                      _vm._v(
                        "Your account is manually invoiced. Contact our support for your billing needs."
                      ),
                    ]),
                  ])
                : _c("div", { staticClass: "col-12 text-md" }, [
                    _c("p", [
                      _vm._v("You are currently on\n                    "),
                      _c(
                        "a",
                        {
                          staticClass: "text-dark-greenish el-green-hover",
                          attrs: {
                            disabled:
                              _vm.isChargeBeeSessionOrSubscriptionDisabled,
                          },
                          on: { click: _vm.openChargebeePortal },
                        },
                        [
                          _c("b", [
                            _vm._v(
                              _vm._s(_vm._f("capitalize")(_vm.usage.plan.name))
                            ),
                          ]),
                        ]
                      ),
                      _vm._v(" Plan.\n                    "),
                      _vm.hasChargeBeeSession &&
                      _vm.enabledToSkipTrialAndSubscribe()
                        ? _c(
                            "a",
                            {
                              staticClass: "mr-2 text-bluish",
                              attrs: {
                                title: "More plan information",
                                disabled:
                                  _vm.isChargeBeeSessionOrSubscriptionDisabled,
                              },
                              on: { click: _vm.openChargebeePortal },
                            },
                            [
                              _vm._v(
                                "\n                        Click here to manage subscription\n                    "
                              ),
                            ]
                          )
                        : _vm._e(),
                    ]),
                    _vm._v(" "),
                    _c("p", [
                      _vm._v(
                        "\n                    Your current credit balance is:\n                    "
                      ),
                      _c(
                        "a",
                        {
                          staticClass: "text-dark-greenish el-green-hover",
                          attrs: {
                            disabled:
                              _vm.isChargeBeeSessionOrSubscriptionDisabled,
                          },
                          on: { click: _vm.openChargebeePortal },
                        },
                        [
                          _c("b", { style: { color: _vm.creditColor } }, [
                            _vm._v(
                              _vm._s(
                                _vm._f("toCurrency")(
                                  _vm._f("fixRounding")(_vm.usage.credits)
                                )
                              )
                            ),
                          ]),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _vm.auth.user.profile.usage.auto_recharge
                      ? _c("p", [
                          _vm._v("Your account is on\n                    "),
                          _c(
                            "a",
                            {
                              staticClass: "text-dark-greenish el-green-hover",
                              attrs: {
                                disabled:
                                  _vm.isChargeBeeSessionOrSubscriptionDisabled,
                              },
                              on: { click: _vm.openChargebeePortal },
                            },
                            [_c("b", [_vm._v("Auto Recharge")])]
                          ),
                        ])
                      : _vm._e(),
                  ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-12 text-md" }, [
                _c(
                  "div",
                  { staticClass: "pull-right" },
                  [
                    _vm.auth.user.profile &&
                    (!_vm.auth.user.profile.created_by_reseller ||
                      (_vm.auth.user.profile.created_by_reseller &&
                        _vm.auth.user.profile.independent_billing))
                      ? [
                          !_vm.enabledToSkipTrialAndSubscribe()
                            ? _c(
                                "el-popover",
                                {
                                  ref: "trial-skip-popover",
                                  staticClass: "custom-popover",
                                  attrs: {
                                    placement: "top",
                                    width: "200",
                                    "popper-class": "btn-primary",
                                    trigger: "hover",
                                  },
                                },
                                [
                                  _c("custom-message-display", {
                                    attrs: {
                                      config:
                                        _vm.customMessage("billing.subscribe"),
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.hasPermissionTo("see chargebee portal")
                            ? _c(
                                "button",
                                {
                                  directives: [
                                    {
                                      name: "popover",
                                      rawName: "v-popover:trial-skip-popover",
                                      arg: "trial-skip-popover",
                                    },
                                  ],
                                  staticClass:
                                    "btn btn-sm bluish pull-right pr-2 pl-2",
                                  attrs: {
                                    disabled:
                                      _vm.isChargeBeeSessionOrSubscriptionDisabled,
                                  },
                                  on: { click: _vm.chargebeePaymentSources },
                                },
                                [
                                  _c("i", { staticClass: "material-icons" }, [
                                    _vm._v("credit_card_outline"),
                                  ]),
                                  _vm._v(" "),
                                  !_vm.cardAdded
                                    ? _c("span", [_vm._v("Add Payment Method")])
                                    : _c("span", [
                                        _vm._v("Manage Payment Methods"),
                                      ]),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          !_vm.enabledToSkipTrialAndSubscribe()
                            ? _c(
                                "el-popover",
                                {
                                  ref: "trial-skip-billing-popover",
                                  staticClass: "custom-popover",
                                  attrs: {
                                    placement: "top",
                                    width: "200",
                                    "popper-class": "btn-primary",
                                    trigger: "hover",
                                  },
                                },
                                [
                                  _c("custom-message-display", {
                                    attrs: {
                                      config:
                                        _vm.customMessage("billing.subscribe"),
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.hasPermissionTo("see chargebee portal")
                            ? _c(
                                "button",
                                {
                                  directives: [
                                    {
                                      name: "popover",
                                      rawName:
                                        "v-popover:trial-skip-billing-popover",
                                      arg: "trial-skip-billing-popover",
                                    },
                                  ],
                                  staticClass:
                                    "btn btn-sm greenish pull-right pr-2 pl-2 mr-2",
                                  attrs: {
                                    disabled:
                                      _vm.isChargeBeeSessionOrSubscriptionDisabled,
                                  },
                                  on: { click: _vm.openChargebeePortal },
                                },
                                [
                                  _c("i", { staticClass: "material-icons" }, [
                                    _vm._v("payment"),
                                  ]),
                                  _vm._v(
                                    "\n                            Manage Subscription\n                        "
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.hasPermissionTo("see chargebee portal")
                            ? _c(
                                "button",
                                {
                                  staticClass:
                                    "btn btn-sm greenish pull-right pr-2 pl-2 mr-2",
                                  on: { click: _vm.chargebeeAddons },
                                },
                                [
                                  _c("i", { staticClass: "material-icons" }, [
                                    _vm._v("attach_file"),
                                  ]),
                                  _vm._v(
                                    "\n                            Manage Addons\n                        "
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.hasPermissionTo("see chargebee portal")
                            ? _c(
                                "button",
                                {
                                  staticClass:
                                    "btn btn-sm white pull-right pr-2 pl-2 mr-2",
                                  attrs: { disabled: !_vm.hasChargeBeeSession },
                                  on: { click: _vm.billingHistory },
                                },
                                [
                                  _c("i", { staticClass: "material-icons" }, [
                                    _vm._v("history"),
                                  ]),
                                  _vm._v(
                                    "\n                            Payment History\n                        "
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ]
                      : _vm._e(),
                    _vm._v(" "),
                    _vm._m(0),
                  ],
                  2
                ),
              ]),
            ])
          : _vm._e(),
        _vm._v(" "),
        !_vm.usage.plan.is_on_trial &&
        _vm.usage.subscription_status == "active" &&
        _vm.hasPermissionTo("buy credits") &&
        _vm.auth.user.profile &&
        (!_vm.auth.user.profile.created_by_reseller ||
          (_vm.auth.user.profile.created_by_reseller &&
            _vm.auth.user.profile.independent_billing))
          ? _c("div", { staticClass: "row pl-2 pr-2 mb-3" }, [
              _c("div", { staticClass: "col-12" }, [
                _c("div", { staticClass: "box new-card" }, [
                  _c("h4", { staticClass: "text-lg" }, [
                    _vm._v("Recharge Your Account"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.loading,
                          expression: "loading",
                        },
                      ],
                      staticClass: "box-body text-left",
                    },
                    [
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-12" }, [
                          _c("p", { staticClass: "text-blackish" }, [
                            _vm._v("Choose an amount in USD to recharge:"),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "row mt-4 mb-2" }, [
                            _c(
                              "div",
                              { staticClass: "col-12" },
                              [
                                _c(
                                  "el-radio-group",
                                  {
                                    staticClass: "pull-left text-lg",
                                    model: {
                                      value: _vm.credits,
                                      callback: function ($$v) {
                                        _vm.credits = $$v
                                      },
                                      expression: "credits",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-radio",
                                      {
                                        staticClass: "_600",
                                        attrs: { label: 100, border: "" },
                                      },
                                      [
                                        _vm._v(
                                          "\n                                                $100\n                                            "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-radio",
                                      {
                                        staticClass: "_600",
                                        attrs: { label: 250, border: "" },
                                      },
                                      [
                                        _vm._v(
                                          "\n                                                $250\n                                            "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-radio",
                                      {
                                        staticClass: "_600",
                                        attrs: { label: 500, border: "" },
                                      },
                                      [
                                        _vm._v(
                                          "\n                                                $500\n                                            "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-radio",
                                      {
                                        staticClass: "_600",
                                        attrs: { label: 1000, border: "" },
                                      },
                                      [
                                        _vm._v(
                                          "\n                                                $1000\n                                            "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-button",
                                  {
                                    staticClass: "pull-left ml-4",
                                    attrs: {
                                      type: "success",
                                      disabled: _vm.loading_credits,
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.verifyCardExists(
                                          _vm.buyCredits
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "i",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: _vm.loading_credits,
                                            expression: "loading_credits",
                                          },
                                        ],
                                        staticClass:
                                          "material-icons loader pull-right",
                                      },
                                      [_vm._v("")]
                                    ),
                                    _vm._v(
                                      "\n                                            Buy Credits\n                                        "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-12" }, [
                          _c(
                            "div",
                            { staticClass: "col-12 mt-4 mb-2 form-aloware" },
                            [
                              _c(
                                "div",
                                { staticClass: "switch-label" },
                                [
                                  _c("el-switch", {
                                    attrs: {
                                      "active-color": "#00a344",
                                      disabled:
                                        !_vm.current_company
                                          .chargebee_auto_collection ||
                                        _vm.current_company.auto_recharge,
                                    },
                                    on: { change: _vm.checkAutoRecharge },
                                    model: {
                                      value: _vm.auto_recharge,
                                      callback: function ($$v) {
                                        _vm.auto_recharge = $$v
                                      },
                                      expression: "auto_recharge",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "label",
                                    {
                                      class: [
                                        _vm.current_company.auto_recharge
                                          ? "inactive"
                                          : "",
                                      ],
                                    },
                                    [_vm._v("Auto Recharge")]
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("div", { staticClass: "mb-3" }, [
                                _c(
                                  "small",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value:
                                          _vm.current_company
                                            .chargebee_auto_collection &&
                                          _vm.current_company.auto_recharge,
                                        expression:
                                          "current_company.chargebee_auto_collection && current_company.auto_recharge",
                                      },
                                    ],
                                  },
                                  [
                                    _vm._v(
                                      "Auto-recharge is mandatory for your payment plan."
                                    ),
                                  ]
                                ),
                              ]),
                              _vm._v(" "),
                              _vm._m(1),
                              _vm._v(" "),
                              _vm.auto_recharge
                                ? _c(
                                    "div",
                                    { staticClass: "mt-4" },
                                    [
                                      _vm._v(
                                        "\n                                        For amount\n                                        "
                                      ),
                                      _c(
                                        "el-select",
                                        {
                                          attrs: { placeholder: "Select" },
                                          model: {
                                            value: _vm.recharge_for_amount,
                                            callback: function ($$v) {
                                              _vm.recharge_for_amount = $$v
                                            },
                                            expression: "recharge_for_amount",
                                          },
                                        },
                                        _vm._l(
                                          _vm.recharge_for_amount_options,
                                          function (item) {
                                            return _c("el-option", {
                                              key: item.value,
                                              attrs: {
                                                label: item.label,
                                                value: item.value,
                                              },
                                            })
                                          }
                                        ),
                                        1
                                      ),
                                      _vm._v(
                                        "\n                                        When Balance is below\n                                        "
                                      ),
                                      _c(
                                        "el-select",
                                        {
                                          attrs: { placeholder: "Select" },
                                          model: {
                                            value: _vm.auto_recharge_threshold,
                                            callback: function ($$v) {
                                              _vm.auto_recharge_threshold = $$v
                                            },
                                            expression:
                                              "auto_recharge_threshold",
                                          },
                                        },
                                        _vm._l(
                                          _vm.auto_recharge_threshold_options,
                                          function (item) {
                                            return _c("el-option", {
                                              key: item.value,
                                              attrs: {
                                                label: item.label,
                                                value: item.value,
                                              },
                                            })
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.changed
                                ? _c(
                                    "div",
                                    { staticClass: "mt-4" },
                                    [
                                      _vm.auto_recharge
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "alert alert-danger alert-aloware p-2 w-50",
                                            },
                                            [
                                              _vm._v(
                                                "\n                                            Auto-recharge can't be disabled once enabled.\n                                        "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _c(
                                        "el-button",
                                        {
                                          staticClass: "pull-left",
                                          attrs: {
                                            type: "success",
                                            disabled: _vm.loading_auto_recharge,
                                          },
                                          on: { click: _vm.changeAutoRecharge },
                                        },
                                        [
                                          _c(
                                            "i",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value:
                                                    _vm.loading_auto_recharge,
                                                  expression:
                                                    "loading_auto_recharge",
                                                },
                                              ],
                                              staticClass:
                                                "material-icons loader pull-right",
                                            },
                                            [_vm._v("")]
                                          ),
                                          _vm._v(
                                            "\n                                            Save Details\n                                        "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ]
                          ),
                        ]),
                      ]),
                    ]
                  ),
                ]),
              ]),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "row pl-2 pr-2 mb-3" }, [
          _c("div", { staticClass: "col-12" }, [
            _c("div", { staticClass: "box new-card" }, [
              _c("h4", { staticClass: "text-lg" }, [
                _vm._v("Your Account Rates"),
              ]),
              _vm._v(" "),
              _c("small", [
                _vm._v("You are on our "),
                _c("b", [_vm._v(_vm._s(_vm.auth.user.profile.rate.name))]),
                _vm._v(" rates plan."),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "box-body text-left" }, [
                _vm.usage.plan &&
                _vm.usage.plan.unlimited_inbound == 1 &&
                _vm.usage.plan.unlimited_outbound == 0
                  ? _c("p", { staticClass: "text-md _600 text-blue" }, [
                      _vm._v(
                        "\n                            Inbound local minutes and SMS are free on your plan.\n                        "
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.usage.plan &&
                _vm.usage.plan.unlimited_inbound == 1 &&
                _vm.usage.plan.unlimited_outbound == 1
                  ? _c("p", { staticClass: "text-md _600 text-blue" }, [
                      _vm._v(
                        "\n                            Inbound & outbound local minutes and SMS are free on your plan. Automated usage is considered paid.\n                        "
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("div", { staticClass: "table-responsive" }, [
                  _c("table", { staticClass: "table table-borderless" }, [
                    _c("tbody", [
                      _c("tr", { staticClass: "mb-2" }, [
                        _c("td", { staticStyle: { width: "250px" } }, [
                          _c(
                            "table",
                            { staticClass: "table table-bordered border mb-0" },
                            [
                              _vm._m(2),
                              _vm._v(" "),
                              _c("tbody", [
                                _c("tr", [
                                  _c("td", [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("toCurrency")(
                                          _vm._f("fixRounding")(
                                            _vm.auth.user.profile.rate.local_pn
                                          )
                                        )
                                      )
                                    ),
                                  ]),
                                ]),
                              ]),
                            ]
                          ),
                        ]),
                        _vm._v(" "),
                        _c("td", { staticStyle: { width: "250px" } }, [
                          _c(
                            "table",
                            { staticClass: "table table-bordered border mb-0" },
                            [
                              _vm._m(3),
                              _vm._v(" "),
                              _c("tbody", [
                                _c("tr", [
                                  _c("td", [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("toCurrency")(
                                          _vm._f("fixRounding")(
                                            _vm.auth.user.profile.rate
                                              .tollfree_pn
                                          )
                                        )
                                      )
                                    ),
                                  ]),
                                ]),
                              ]),
                            ]
                          ),
                        ]),
                        _vm._v(" "),
                        _c("td", { staticStyle: { width: "250px" } }, [
                          _c(
                            "table",
                            { staticClass: "table table-bordered border mb-0" },
                            [
                              _vm._m(4),
                              _vm._v(" "),
                              _c("tbody", [
                                _c("tr", [
                                  _c("td", [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("toCurrency")(
                                          _vm._f("fixRounding")(
                                            _vm.auth.user.profile.rate.local_min
                                          )
                                        )
                                      )
                                    ),
                                  ]),
                                ]),
                              ]),
                            ]
                          ),
                        ]),
                        _vm._v(" "),
                        _c("td", { staticStyle: { width: "250px" } }, [
                          _c(
                            "table",
                            { staticClass: "table table-bordered border mb-0" },
                            [
                              _vm._m(5),
                              _vm._v(" "),
                              _c("tbody", [
                                _c("tr", [
                                  _c("td", [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("toCurrency")(
                                          _vm._f("fixRounding")(
                                            _vm.auth.user.profile.rate
                                              .tollfree_min
                                          )
                                        )
                                      ) +
                                        "\n                                                "
                                    ),
                                  ]),
                                ]),
                              ]),
                            ]
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("tr", [
                        _c("td", { staticStyle: { width: "250px" } }, [
                          _c(
                            "table",
                            { staticClass: "table table-bordered border mb-0" },
                            [
                              _vm._m(6),
                              _vm._v(" "),
                              _c("tbody", [
                                _c("tr", [
                                  _c("td", [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("toCurrency")(
                                          _vm._f("fixRounding")(
                                            _vm.auth.user.profile.rate.local_sms
                                          )
                                        )
                                      )
                                    ),
                                  ]),
                                ]),
                              ]),
                            ]
                          ),
                        ]),
                        _vm._v(" "),
                        _c("td", { staticStyle: { width: "250px" } }, [
                          _c(
                            "table",
                            { staticClass: "table table-bordered border mb-0" },
                            [
                              _vm._m(7),
                              _vm._v(" "),
                              _c("tbody", [
                                _c("tr", [
                                  _c("td", [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("toCurrency")(
                                          _vm._f("fixRounding")(
                                            _vm.auth.user.profile.rate
                                              .tollfree_sms
                                          )
                                        )
                                      ) +
                                        "\n                                                "
                                    ),
                                  ]),
                                ]),
                              ]),
                            ]
                          ),
                        ]),
                        _vm._v(" "),
                        _c("td", { staticStyle: { width: "250px" } }, [
                          _c(
                            "table",
                            { staticClass: "table table-bordered border mb-0" },
                            [
                              _vm._m(8),
                              _vm._v(" "),
                              _c("tbody", [
                                _c("tr", [
                                  _c("td", [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("toCurrency")(
                                          _vm._f("fixRounding")(
                                            _vm.auth.user.profile.rate
                                              .short_code_sms
                                          )
                                        )
                                      ) +
                                        "\n                                                "
                                    ),
                                  ]),
                                ]),
                              ]),
                            ]
                          ),
                        ]),
                        _vm._v(" "),
                        _c("td", { staticStyle: { width: "250px" } }, [
                          _c(
                            "table",
                            { staticClass: "table table-bordered border mb-0" },
                            [
                              _vm._m(9),
                              _vm._v(" "),
                              _c("tbody", [
                                _c("tr", [
                                  _c("td", [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("toCurrency")(
                                          _vm._f("fixRounding")(
                                            _vm.auth.user.profile.rate.local_mms
                                          )
                                        )
                                      )
                                    ),
                                  ]),
                                ]),
                              ]),
                            ]
                          ),
                        ]),
                        _vm._v(" "),
                        _c("td", { staticStyle: { width: "250px" } }, [
                          _c(
                            "table",
                            { staticClass: "table table-bordered border mb-0" },
                            [
                              _vm._m(10),
                              _vm._v(" "),
                              _c("tbody", [
                                _c("tr", [
                                  _c("td", [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("toCurrency")(
                                          _vm._f("fixRounding")(
                                            _vm.auth.user.profile.rate
                                              .tollfree_mms
                                          )
                                        )
                                      ) +
                                        "\n                                                "
                                    ),
                                  ]),
                                ]),
                              ]),
                            ]
                          ),
                        ]),
                        _vm._v(" "),
                        _c("td", { staticStyle: { width: "250px" } }, [
                          _c(
                            "table",
                            { staticClass: "table table-bordered border mb-0" },
                            [
                              _vm._m(11),
                              _vm._v(" "),
                              _c("tbody", [
                                _c("tr", [
                                  _c("td", [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("toCurrency")(
                                          _vm._f("fixRounding")(
                                            _vm.auth.user.profile.rate
                                              .short_code_mms
                                          )
                                        )
                                      ) +
                                        "\n                                                "
                                    ),
                                  ]),
                                ]),
                              ]),
                            ]
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("tr", [
                        _c("td", { staticStyle: { width: "250px" } }, [
                          _c(
                            "table",
                            { staticClass: "table table-bordered border mb-0" },
                            [
                              _vm._m(12),
                              _vm._v(" "),
                              _c("tbody", [
                                _c("tr", [
                                  _c("td", [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("toCurrency")(
                                          _vm._f("fixRounding")(
                                            _vm.auth.user.profile.rate.rvm
                                          )
                                        )
                                      )
                                    ),
                                  ]),
                                ]),
                              ]),
                            ]
                          ),
                        ]),
                        _vm._v(" "),
                        _c("td", { staticStyle: { width: "250px" } }, [
                          _c(
                            "table",
                            { staticClass: "table table-bordered border mb-0" },
                            [
                              _vm._m(13),
                              _vm._v(" "),
                              _c("tbody", [
                                _c("tr", [
                                  _c("td", [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("toCurrency")(
                                          _vm._f("fixRounding")(
                                            _vm.auth.user.profile.rate.amd
                                          )
                                        )
                                      )
                                    ),
                                  ]),
                                ]),
                              ]),
                            ]
                          ),
                        ]),
                        _vm._v(" "),
                        _c("td", { staticStyle: { width: "250px" } }, [
                          _c(
                            "table",
                            { staticClass: "table table-bordered border mb-0" },
                            [
                              _vm._m(14),
                              _vm._v(" "),
                              _c("tbody", [
                                _c("tr", [
                                  _c("td", [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("toCurrency")(
                                          _vm._f("fixRounding")(
                                            _vm.auth.user.profile.rate.email
                                          )
                                        )
                                      ) +
                                        "\n                                                "
                                    ),
                                  ]),
                                ]),
                              ]),
                            ]
                          ),
                        ]),
                        _vm._v(" "),
                        _c("td", { staticStyle: { width: "250px" } }, [
                          _c(
                            "table",
                            { staticClass: "table table-bordered border mb-0" },
                            [
                              _vm._m(15),
                              _vm._v(" "),
                              _c("tbody", [
                                _c("tr", [
                                  _c("td", [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("toCurrency")(
                                          _vm._f("fixRounding")(
                                            _vm.auth.user.profile.rate.fax
                                          )
                                        )
                                      )
                                    ),
                                  ]),
                                ]),
                              ]),
                            ]
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("tr", [
                        _c("td", { staticStyle: { width: "250px" } }, [
                          _c(
                            "table",
                            { staticClass: "table table-bordered border mb-0" },
                            [
                              _vm._m(16),
                              _vm._v(" "),
                              _c("tbody", [
                                _c("tr", [
                                  _c("td", [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("toCurrency")(
                                          _vm._f("fixRounding")(
                                            _vm.auth.user.profile.rate
                                              .transcription
                                          )
                                        )
                                      )
                                    ),
                                  ]),
                                ]),
                              ]),
                            ]
                          ),
                        ]),
                      ]),
                    ]),
                  ]),
                ]),
              ]),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "Addons Manager",
            visible: _vm.chargebeeAddonsManagerOpen,
          },
          on: {
            "update:visible": function ($event) {
              _vm.chargebeeAddonsManagerOpen = $event
            },
          },
        },
        [
          _c("charge-bee-addons-manager", {
            ref: "charge-bee-addon-manager",
            attrs: { visible: _vm.chargebeeAddonsManagerOpen },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("el-backtop", {
        ref: "backtop",
        attrs: { target: "html", bottom: 20, right: 20 },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "a",
      {
        staticClass: "btn btn-sm pull-left mr-2 text-bluish",
        attrs: {
          href: "https://support.aloware.com/en/articles/9033652-understanding-aloware-invoices",
          title: "Billing Help",
          target: "_blank",
        },
      },
      [
        _c("i", { staticClass: "material-icons text-lg" }, [_vm._v("help")]),
        _vm._v("\n                        Help\n                    "),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass:
          "alert alert-warning alert-aloware p-2 d-inline-block w-50",
      },
      [
        _c("i", { staticClass: "fa fa-exclamation-triangle" }),
        _vm._v(
          " When an auto recharge attempt fails,\n                                        we will turn off auto recharge settings to prevent any further issues. "
        ),
        _c("br"),
        _vm._v(
          "\n                                        When the billing issue is fixed, the auto recharge needs to be set up once again.\n                                    "
        ),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c(
          "th",
          {
            staticClass: "text-greenish border-bottom",
            attrs: { scope: "col" },
          },
          [
            _vm._v(
              "\n                                                    Local Phone Numbers\n                                                "
            ),
          ]
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c(
          "th",
          {
            staticClass: "text-greenish border-bottom",
            attrs: { scope: "col" },
          },
          [
            _vm._v(
              "\n                                                    Toll-free Phone Numbers\n                                                "
            ),
          ]
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c(
          "th",
          {
            staticClass: "text-greenish border-bottom",
            attrs: { scope: "col" },
          },
          [
            _vm._v(
              "\n                                                    Local Minutes\n                                                "
            ),
          ]
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c(
          "th",
          {
            staticClass: "text-greenish border-bottom",
            attrs: { scope: "col" },
          },
          [
            _vm._v(
              "\n                                                    Toll-free Minutes\n                                                "
            ),
          ]
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c(
          "th",
          {
            staticClass: "text-greenish border-bottom",
            attrs: { scope: "col" },
          },
          [
            _vm._v(
              "\n                                                    Local Text Messages\n                                                "
            ),
          ]
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c(
          "th",
          {
            staticClass: "text-greenish border-bottom",
            attrs: { scope: "col" },
          },
          [
            _vm._v(
              "\n                                                    Toll-free Text Messages\n                                                "
            ),
          ]
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c(
          "th",
          {
            staticClass: "text-greenish border-bottom",
            attrs: { scope: "col" },
          },
          [
            _vm._v(
              "\n                                                    ShortCode Text Messages\n                                                "
            ),
          ]
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c(
          "th",
          {
            staticClass: "text-greenish border-bottom",
            attrs: { scope: "col" },
          },
          [
            _vm._v(
              "\n                                                    Local MMS\n                                                "
            ),
          ]
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c(
          "th",
          {
            staticClass: "text-greenish border-bottom",
            attrs: { scope: "col" },
          },
          [
            _vm._v(
              "\n                                                    Toll-free MMS\n                                                "
            ),
          ]
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c(
          "th",
          {
            staticClass: "text-greenish border-bottom",
            attrs: { scope: "col" },
          },
          [
            _vm._v(
              "\n                                                    ShortCode MMS\n                                                "
            ),
          ]
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c(
          "th",
          {
            staticClass: "text-greenish border-bottom",
            attrs: { scope: "col" },
          },
          [
            _vm._v(
              "\n                                                    Ringless Voicemail\n                                                "
            ),
          ]
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c(
          "th",
          {
            staticClass: "text-greenish border-bottom",
            attrs: { scope: "col" },
          },
          [
            _vm._v(
              "\n                                                    Automatic Machine Detection\n                                                "
            ),
          ]
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c(
          "th",
          {
            staticClass: "text-greenish border-bottom",
            attrs: { scope: "col" },
          },
          [
            _vm._v(
              "\n                                                    Email\n                                                "
            ),
          ]
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c(
          "th",
          {
            staticClass: "text-greenish border-bottom",
            attrs: { scope: "col" },
          },
          [
            _vm._v(
              "\n                                                    Fax\n                                                "
            ),
          ]
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c(
          "th",
          {
            staticClass: "text-greenish border-bottom",
            attrs: { scope: "col" },
          },
          [
            _vm._v(
              "\n                                                    Transcription\n                                                "
            ),
          ]
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }