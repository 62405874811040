<template>
    <el-form
        :model="contact_settings"
        :rules="rules"
        class="form-aloware"
        ref="companySettingsForm"
        v-loading="loading_whitelabel"
        @submit.prevent.native="submitForm('companySettingsForm')">
        <div class="row">
            <div class="col-md-7">
                <el-form-item
                    prop="appointment_adp_recipients">
                    <span
                        class="page-anchor"
                        id="appointment_adp_recipients">
                    </span>
                    <div class="form-label">
                        <h5>Appointment Recipients</h5>
                        <small>
                            You can copy some emails (or smart calendars!) to receive a copy
                            of your appointments set on the Calendar.
                        </small>
                    </div>
                    <el-select
                        @change="updateParent"
                        v-model="contact_settings.appointment_adp_recipients"
                        allow-create
                        default-first-option
                        filterable
                        multiple
                        clearable
                        placeholder="Select or create a new recipient."
                        class="w-full">
                        <el-option
                            :key="user.id"
                            :label="user.email"
                            :value="user.email"
                            v-for="user in users">
                            <div class="media">
                                <div class="media-body">
                                    <label>{{ user.name }}</label>
                                    <small>{{ user.email }}</small>
                                </div>
                            </div>
                        </el-option>
                    </el-select>
                </el-form-item>

                <el-form-item prop="lead_recipients">
                    <span
                        class="page-anchor"
                        id="lead_recipients">
                    </span>
                    <div class="form-label">
                        <h5>Lead Recipients</h5>
                        <small>
                            We can forward a lead we receive through our API. email
                            processor, or other mechanisms to an email.
                        </small>
                    </div>
                    <el-select
                        v-model="contact_settings.lead_recipients"
                        @change="updateParent"
                        allow-create
                        default-first-option
                        filterable
                        multiple
                        clearable
                        placeholder="Select or create a new recipient."
                        class="w-full">
                        <el-option
                            :key="user.id"
                            :label="user.email"
                            :value="user.email"
                            v-for="user in users">
                            <div class="media">
                                <div class="media-body">
                                    <label>{{ user.name }}</label>
                                    <small>{{ user.email }}</small>
                                </div>
                            </div>
                        </el-option>
                    </el-select>
                </el-form-item>

                <el-form-item prop="default_contact_date_filter">
                    <span
                        class="page-anchor"
                        id="default_contact_date_filter">
                    </span>
                    <div class="form-label">
                        <h5>Default Contact Date Filter</h5>
                        <small>
                            How the Contacts page should be sorted.
                        </small>
                    </div>
                    <el-select
                        @change="updateParent"
                        v-model="contact_settings.default_contact_date_filter"
                        placeholder="Select"
                        class="w-full">
                        <el-option
                            label="Engagement"
                            :value="DefaultContactDateFilter.DEFAULT_CONTACT_DATE_FILTER_ENGAGEMENT">
                        </el-option>
                        <el-option
                            label="Created At"
                            :value="DefaultContactDateFilter.DEFAULT_CONTACT_DATE_FILTER_CREATED_AT">
                        </el-option>
                    </el-select>
                </el-form-item>

                <el-form-item prop="auto_assign_contacts_enabled">
                    <span class="page-anchor" id="auto_assign_contacts_enabled"></span>
                    <div class="form-label">
                        <h5>Auto Assign Contact</h5>
                        <small>With Auto Assign, any new contact added in Aloware will be assigned to the user who makes the first communication with the contact.</small>
                    </div>
                    <el-switch
                        @change="handleAutoAssignContactChange"
                        v-model="contact_settings.auto_assign_contacts_enabled"
                        active-color="#00a344">
                    </el-switch>
                </el-form-item>

                <el-form-item prop="default_contact_owner"
                              v-if="!contact_settings.auto_assign_contacts_enabled">
                    <span class="page-anchor" id="default_contact_owner"></span>
                    <div class="form-label">
                        <h5>Default Contact Owner</h5>
                        <small>You can choose the Default owner of new contacts here.</small>
                    </div>
                    <el-select
                        v-model="contact_settings.default_contact_owner"
                        @change="handleAutoAssignContactChange"
                        allow-create
                        default-first-option
                        filterable
                        clearable
                        placeholder="Select the default contact owner"
                        class="w-full">
                        <el-option
                            :key="user.id"
                            :label="user.email"
                            :value="user.id"
                            v-for="user in users">
                            <div class="media">
                                <div class="media-body">
                                    <label>{{ user.name }}</label>
                                    <small>{{ user.email }}</small>
                                </div>
                            </div>
                        </el-option>
                    </el-select>
                </el-form-item>

                <el-form-item prop="sms_reminder_enabled">
                    <div class="form-label">
                        <span class="page-anchor"
                              id="sms_reminder_enabled">
                        </span>
                        <h5>SMS reminder enabled</h5>
                    </div>
                    <el-switch
                        @change="updateParent"
                        v-model="contact_settings.sms_reminder_enabled"
                        active-color="#00a344">
                    </el-switch>
                </el-form-item>

                <el-form-item prop="sms_reminder_default_text">
                    <div class="form-label">
                        <span
                            class="page-anchor"
                            id="sms_reminder_default_text">
                        </span>
                        <h5>SMS reminder default text</h5>
                        <div class="pb-2 line-he">
                            <span
                                v-for="item in sms_reminder_template_variables"
                                :key="item"
                                @click="addTemplateVariableToSmsReminderText(item)"
                                class="text-danger sms-reminder-template-variables">
                                {{ item }}
                            </span>
                        </div>
                    </div>
                    <el-input
                        ref="smsReminderDefaultTxt"
                        type="textarea"
                        maxlength="255"
                        show-word-limit
                        v-model="contact_settings.sms_reminder_default_text"
                        @blur="updateSmsReminderText">
                    </el-input>
                </el-form-item>

                <el-form-item prop="sms_reminder_default_campaign_id">
                    <div class="form-label">
                        <h5>SMS reminder use personal line</h5>
                        <small>
                            Use User's Personal Line as default line
                        </small>
                    </div>
                    <el-switch
                        @change="updateParent"
                        v-model="contact_settings.sms_reminder_use_personal_line"
                        active-color="#00a344">
                    </el-switch>

                    <div v-if="!contact_settings.sms_reminder_use_personal_line">
                        <div class="form-label mt-4">
                            <span
                                class="page-anchor"
                                id="sms_reminder_default_campaign_id">
                            </span>
                            <h5>SMS reminder default line</h5>
                        </div>
                        <campaign-selector
                            class_prop="w-100"
                            :clearable="true"
                            :show_paused="false"
                            v-model="contact_settings.sms_reminder_default_campaign_id"
                            @change="updateSmsReminderCampaign"/>
                    </div>
                </el-form-item>

                <el-form-item prop="sms_reminder_default_time">
                    <div class="form-label">
                        <span
                            class="page-anchor"
                            id="sms_reminder_default_time">
                        </span>
                        <h5>SMS reminder default time</h5>
                    </div>
                    <el-select
                        v-model="contact_settings.sms_reminder_default_time"
                        placeholder="Select time"
                        filterable
                        clearable
                        class="w-100"
                        @change="updateParent">
                        <el-option
                            v-for="time in times"
                            :key="time.value"
                            :label="time.label"
                            :value="time.value">
                        </el-option>
                    </el-select>
                </el-form-item>

                <el-form-item prop="sms_reminder_default_send_before_days">
                    <div class="form-label">
                        <span
                            class="page-anchor"
                            id="sms_reminder_default_send_before_days">
                        </span>
                        <h5>SMS reminder default send before days</h5>
                    </div>
                    <el-select
                        class="w-full"
                        filterable
                        multiple
                        v-model="sms_reminder_default_send_before_days"
                        @change="updateSmsReminderSendBeforeDays">
                        <el-option value="0" label="On appointment day"></el-option>
                        <el-option value="1" label="1 day before"></el-option>
                        <el-option value="2" label="2 days before"></el-option>
                        <el-option value="3" label="3 days before"></el-option>
                        <el-option value="4" label="4 days before"></el-option>
                        <el-option value="5" label="5 days before"></el-option>
                        <el-option value="6" label="6 days before"></el-option>
                        <el-option value="7" label="7 days before"></el-option>
                    </el-select>
                </el-form-item>

                <el-form-item
                    prop="returning_lead_as_new_lead"
                    class="pb-1">
                    <span
                        class="page-anchor"
                        id="returning_lead_as_new_lead">
                    </span>
                    <div class="form-label">
                        <h5>New Lead Settings</h5>
                    </div>

                    <div class="switch-label">
                        <el-radio-group
                            @change="updateParent"
                            v-model="contact_settings.returning_lead_as_new_lead">
                            <el-radio
                                :label="false"
                                class="d-flex">
                                <div>
                                    <span>
                                        Default
                                    </span>
                                    <div class="font-weight-light">
                                        Existing contacts re-added to the platform will not be treated as new leads. Contacts will be ignored or updated (if they comes from an intake API)
                                    </div>
                                </div>
                            </el-radio>
                            <el-radio
                                :label="true"
                                class="d-flex">
                                <div>
                                    <span>
                                        Treat existing contacts re-added to platform as new leads
                                    </span>
                                    <div class="font-weight-light">
                                        If any existing contacts are re-added to {{ statics.name }} through one of the following methods, they will be considered new leads:
                                        <ul class="ml-2">
                                            <li>Zapier</li>
                                            <li>Webhook (API)</li>
                                            <li>ADF/XML (email)</li>
                                            <li>Webform (API)</li>
                                            <li>Facebook leadgen form</li>
                                        </ul>
                                    </div>
                                </div>
                            </el-radio>
                        </el-radio-group>
                    </div>
                    <div
                        class="switch-label"
                        v-if="isSimpSocial">
                        <el-switch
                            @change="updateParent"
                            v-model="contact_settings.undnc_readded_contacts"
                            active-color="#00a344">
                        </el-switch>
                        <label>Automatically unDNC a contact when they resubmit a form</label>
                        <br>
                        <a
                            class="text-decoration-underline el-link el-link--primary mt-1"
                            href="https://simpsocial.s3.us-east-2.amazonaws.com/assets/DNC-article.pdf"
                            target="_blank">
                            See More Details
                        </a>
                    </div>
                </el-form-item>

                <el-form-item
                    prop="archive_communications_from_blocked_contacts">
                    <span
                        class="page-anchor"
                        id="archive_communications_from_blocked_contacts">
                    </span>
                    <div class="form-label">
                        <h5>Reject & Archive Communications From Blocked Contacts</h5>
                        <ul class="mt-3">
                            <li>
                                By default, the application doesn't route communications from blocked contacts; but they still show up in logs and reports.
                            </li>
                            <li>
                                By enabling this setting, the application rejects and archives communications from blocked contacts on the platform. These communications won’t appear in call logs or reports.
                            </li>
                        </ul>
                    </div>
                    <div class="switch-label">
                        <el-switch
                            @change="updateParent"
                            v-model="contact_settings.archive_communications_from_blocked_contacts"
                            active-color="#00a344">
                        </el-switch>
                        <label>Enable reject & archive communications from blocked contacts</label>
                    </div>
                </el-form-item>

                <el-form-item
                    prop="mark_all_as_read_enabled"
                    class="pb-1">
                    <span
                        class="page-anchor"
                        id="mark_all_as_read_enabled">
                    </span>
                    <div class="form-label">
                        <h5>'Mark All Contacts As Read' Option</h5>
                        <small>
                            Give any Agent or Admin the ability to 'Mark All Contacts as Read' on the Contacts Page
                        </small>
                    </div>
                    <div class="switch-label">
                        <el-switch
                            @change="updateParent"
                            v-model="contact_settings.mark_all_as_read_enabled"
                            active-color="#00a344">
                        </el-switch>
                        <label>Enable 'Mark All Contacts As Read' Option</label>
                    </div>
                </el-form-item>

                <el-form-item
                    prop="enforce_tcpa"
                    class="pb-1">
                    <span
                        class="page-anchor"
                        id="enforce_tcpa">
                    </span>
                    <div class="form-label">
                        <h5>'Prevent Messaging to Non TCPA Approved Contacts' Option</h5>
                        <small>
                            Prevents sending message to the contact that is not TCPA Approved
                        </small>
                    </div>
                    <div class="switch-label">
                        <el-switch
                            @change="updateParent"
                            v-model="contact_settings.enforce_tcpa"
                            active-color="#00a344">
                        </el-switch>
                        <label>Enable 'Prevent Messaging to Non TCPA Approved Contacts' Option</label>
                    </div>
                </el-form-item>

                <el-form-item
                    prop="automatically_tag_imported_contacts"
                    class="pb-1">
                    <span
                        class="page-anchor"
                        id="automatically_tag_imported_contacts">
                    </span>
                    <div class="form-label">
                        <h5>Automatically tag imported contacts</h5>
                    </div>
                    <div class="switch-label">
                        <el-switch
                            active-color="#00a344"
                            v-model="contact_settings.automatically_tag_imported_contacts"
                            @change="updateParent">
                        </el-switch>
                        <label>Automatically tag imported contacts</label>
                    </div>
                </el-form-item>
            </div>

            <div class="col-md-2 d-none d-md-block ml-auto">
                <div class="list-group list-group-alo list-group-flush sticky-top stick-top-with-header top-70">
                    <h6 class="list-group-item">
                        On This Page
                    </h6>
                    <a href="#appointment_adp_recipients"
                       class="list-group-item list-group-item-action"
                       @click="shine('appointment_adp_recipients')">
                        Appointment Recipients
                    </a>
                    <a href="#lead_recipients"
                       class="list-group-item list-group-item-action"
                       @click="shine('lead_recipients')">
                        Lead Recipients
                    </a>
                    <a href="#default_contact_date_filter"
                       class="list-group-item list-group-item-action"
                       @click="shine('default_contact_date_filter')">
                        Default Contact Date Filter
                    </a>

                    <a href="#auto_assign_contacts_enabled"
                       class="list-group-item list-group-item-action"
                       @click="shine('auto_assign_contacts_enabled')">
                        Auto Assign Contact
                    </a>

                    <a href="#default_contact_owner"
                       v-if="!contact_settings.auto_assign_contacts_enabled"
                       class="list-group-item list-group-item-action"
                       @click="shine('default_contact_owner')">
                        Default Contact Owner
                    </a>
                    <a href="#sms_reminder_enabled"
                       class="list-group-item list-group-item-action"
                       @click="shine('sms_reminder_enabled')">
                        SMS Reminder
                    </a>
                    <a href="#sms_reminder_default_text"
                       class="list-group-item list-group-item-action"
                       @click="shine('sms_reminder_default_text')">
                        SMS Reminder Default Text
                    </a>
                    <a href="#sms_reminder_default_campaign_id"
                       class="list-group-item list-group-item-action"
                       @click="shine('sms_reminder_default_campaign_id')">
                        SMS Reminder Default Line
                    </a>
                    <a href="#sms_reminder_default_time"
                       class="list-group-item list-group-item-action"
                       @click="shine('sms_reminder_default_time')">
                        SMS Reminder Default Time
                    </a>
                    <a href="#sms_reminder_default_send_before_days"
                       class="list-group-item list-group-item-action"
                       @click="shine('sms_reminder_default_send_before_days')">
                        SMS Reminder Default Send Before Days
                    </a>
                    <a href="#returning_lead_as_new_lead"
                       class="list-group-item list-group-item-action"
                       @click="shine('returning_lead_as_new_lead')">
                        New Lead Settings
                    </a>
                    <a href="#archive_communications_from_blocked_contacts"
                       class="list-group-item list-group-item-action"
                       @click="shine('archive_communications_from_blocked_contacts')">
                        Reject & Archive Communications From Blocked Contacts
                    </a>
                    <a href="#mark_all_as_read_enabled"
                       class="list-group-item list-group-item-action"
                       @click="shine('mark_all_as_read_enabled')">
                        'Mark All Contacts As Read' Option
                    </a>
                    <a href="#enforce_tcpa"
                       class="list-group-item list-group-item-action"
                       @click="shine('enforce_tcpa')">
                        'Prevent Messaging to Non TCPA Approved Contacts' Option
                    </a>
                    <a href="#automatically_tag_imported_contacts"
                       class="list-group-item list-group-item-action"
                       @click="shine('automatically_tag_imported_contacts')">
                       'Automatically tag imported contacts' Option
                    </a>
                </div>
            </div>
        </div>
    </el-form>
</template>

<script>
    import debounce from "lodash/debounce"
    import { mapGetters, mapState } from "vuex"
    import { form_handler, statics_mixin } from '../../mixins'
    import * as DefaultContactDateFilter from '../../constants/company_default_contact_date_filter'

    export default {
        name: "account-contact-settings",

        mixins: [form_handler, statics_mixin],

        props: {
            company_clone: {
                type: Object,
                required: true
            }
        },

        data() {
            return {
                contact_settings: {},
                rules: {
                    sms_reminder_default_text: {
                        required: true,
                        message: 'SMS reminder text cannot be empty.',
                        trigger: 'change'
                    },
                    default_contact_owner: {
                        required: true,
                        message: 'Default contact owner cannot be empty',
                        trigger: 'click'
                    }
                },
                sms_reminder_template_variables: [
                    '[FirstName]', '[CompanyName]', '[AgentName]', '[DateTime]', '[TimeLeft]'
                ],
                sms_reminder_default_send_before_days: [],
                DefaultContactDateFilter
            }
        },

        computed: {
            ...mapState(['users']),

            ...mapGetters('cache', ['isSimpSocial']),

            times() {
                const times = []
                for (let hour = 6; hour < 24; hour++) {
                    times.push({
                        label: moment({hour}).format('h:mm A'),
                        value: moment({hour}).format('HH:mm')
                    })
                    times.push({
                            label: moment({hour, minute: 15}).format('h:mm A'),
                            value: moment({hour, minute: 15}).format('HH:mm')
                        }
                    )
                    times.push({
                            label: moment({hour, minute: 30}).format('h:mm A'),
                            value: moment({hour, minute: 30}).format('HH:mm')
                        }
                    )
                    times.push({
                            label: moment({hour, minute: 45}).format('h:mm A'),
                            value: moment({hour, minute: 45}).format('HH:mm')
                        }
                    )
                }
                return times
            }
        },

        created() {
            // sets the needed property of the component
            if (this.company_clone && this.company_clone.id) {
                this.initializeProperties()
            }

            this.getStatics()
        },

        methods: {
            initializeProperties() {
                this.sms_reminder_default_send_before_days = this.company_clone.sms_reminder_default_send_before_days
                    ? this.company_clone.sms_reminder_default_send_before_days.split(',')
                    : null

                // initialize the company props that are being used in this component
                this.contact_settings = {
                    appointment_adp_recipients: this.company_clone.appointment_adp_recipients,
                    lead_recipients: this.company_clone.lead_recipients,
                    default_contact_date_filter: this.company_clone.default_contact_date_filter,
                    sms_reminder_enabled: this.company_clone.sms_reminder_enabled,
                    sms_reminder_default_text: this.company_clone.sms_reminder_default_text,
                    sms_reminder_default_campaign_id: this.company_clone.sms_reminder_default_campaign_id,
                    sms_reminder_use_personal_line: this.company_clone.sms_reminder_use_personal_line,
                    sms_reminder_default_time: this.company_clone.sms_reminder_default_time,
                    sms_reminder_default_send_before_days: this.company_clone.sms_reminder_default_send_before_days,
                    archive_communications_from_blocked_contacts: this.company_clone.archive_communications_from_blocked_contacts,
                    returning_lead_as_new_lead: this.company_clone.returning_lead_as_new_lead,
                    undnc_readded_contacts: this.company_clone.undnc_readded_contacts,
                    mark_all_as_read_enabled: this.company_clone.mark_all_as_read_enabled,
                    enforce_tcpa: this.company_clone.enforce_tcpa,
                    auto_assign_contacts_enabled: this.company_clone.auto_assign_contacts_enabled,
                    default_contact_owner: this.company_clone.default_contact_owner,
                    automatically_tag_imported_contacts: this.company_clone.automatically_tag_imported_contacts,
                }
            },

            addTemplateVariableToSmsReminderText(variable) {
                let body = this.contact_settings.sms_reminder_default_text ?? ''
                this.contact_settings.sms_reminder_default_text = `${body} ${variable}`

                // refocus on the input field after adding a variable
                if (this.$refs.smsReminderDefaultTxt) {
                    this.$refs.smsReminderDefaultTxt.focus()
                }
            },

            updateSmsReminderText() {
                if (this.contact_settings.sms_reminder_default_text !== this.company_clone.sms_reminder_default_text) {
                    this.updateParent()
                }
            },

            updateSmsReminderCampaign(value) {
                this.contact_settings.sms_reminder_default_campaign_id = value || null
                this.updateParent()
            },

            updateSmsReminderSendBeforeDays: debounce(function(value) {
                // join all selected values separated by ","
                this.contact_settings.sms_reminder_default_send_before_days = value.sort().join(',') || null

                this.updateParent()
            }, 1500),

            updateParent: debounce(function() {
                if (this.$refs.companySettingsForm) {
                    this.$refs.companySettingsForm.validate(valid => {
                        if (valid) {
                            if(this.contact_settings.auto_assign_contacts_enabled){
                                this.contact_settings.default_contact_owner = null;
                            }

                            this.$emit('update-parent', this.contact_settings)
                        }
                    })
                }
            },500),

            handleAutoAssignContactChange() {
                if ((!this.contact_settings.auto_assign_contacts_enabled && this.contact_settings.default_contact_owner) || this.contact_settings.auto_assign_contacts_enabled) {
                    this.updateParent();
                }
            }
        },

        watch: {
            'contact_settings.sms_reminder_use_personal_line' (value) {
                if (value) {
                    this.contact_settings.sms_reminder_default_campaign_id = null
                }
            }
        }
    }
</script>
