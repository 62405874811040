var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.hasPermissionTo("create contact") &&
    _vm.contact &&
    _vm.phone_number
    ? _c(
        "div",
        [
          _c(
            "el-dialog",
            {
              staticClass: "dialog-padding auto",
              attrs: {
                title: "Update phone number",
                width: _vm.isLargeEnough ? "450px" : "100%",
                visible: _vm.hide_edit,
                "data-testid": "edit-contact-phone-number-dialog",
                "append-to-body": "",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.hide_edit = $event
                },
              },
            },
            [
              _c(
                "el-form",
                {
                  ref: "edit_phone_number",
                  staticClass: "p-0",
                  attrs: {
                    "label-position": "top",
                    rules: _vm.rules_phone_number,
                    model: _vm.edit_phone_number,
                    "data-testid": "edit-contact-phone-number-form",
                  },
                  nativeOn: {
                    submit: function ($event) {
                      $event.preventDefault()
                      return _vm.savePhoneNumber.apply(null, arguments)
                    },
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { prop: "title", label: "Title" } },
                    [
                      _c(
                        "div",
                        { staticClass: "width-400" },
                        [
                          _c("el-input", {
                            attrs: { "data-testid": "edit-phone-title-input" },
                            on: {
                              input: function ($event) {
                                return _vm.preValidateForm("edit_phone_number")
                              },
                            },
                            model: {
                              value: _vm.edit_phone_number.title,
                              callback: function ($$v) {
                                _vm.$set(_vm.edit_phone_number, "title", $$v)
                              },
                              expression: "edit_phone_number.title",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "phone_number", label: "Phone Number" } },
                    [
                      _c(
                        "div",
                        { staticClass: "width-400" },
                        [
                          _c(
                            "el-tooltip",
                            {
                              staticClass: "item mb-1",
                              attrs: {
                                effect: "light",
                                content:
                                  "It's not possible to edit phone number which came from integration.",
                                "data-testid":
                                  "can-not-edit-phone-number-tooltip",
                                placement: "top",
                                disabled: _vm.canEdit,
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  disabled: !_vm.canEdit,
                                  "data-testid": "edit-phone-number-input",
                                },
                                on: {
                                  input: function ($event) {
                                    return _vm.preValidateForm(
                                      "edit_phone_number"
                                    )
                                  },
                                },
                                model: {
                                  value: _vm.edit_phone_number.phone_number,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.edit_phone_number,
                                      "phone_number",
                                      $$v
                                    )
                                  },
                                  expression: "edit_phone_number.phone_number",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _vm.contact.phone_number !== this.phone_number.phone_number
                    ? _c(
                        "el-form-item",
                        { attrs: { prop: "is_primary" } },
                        [
                          _c("el-checkbox", {
                            attrs: {
                              label: "Primary phone number",
                              "data-testid": "primary-number-checkbox",
                            },
                            on: {
                              change: function ($event) {
                                return _vm.preValidateForm("edit_phone_number")
                              },
                            },
                            model: {
                              value: _vm.edit_phone_number.is_primary,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.edit_phone_number,
                                  "is_primary",
                                  $$v
                                )
                              },
                              expression: "edit_phone_number.is_primary",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-checkbox",
                {
                  staticStyle: { "margin-right": "0" },
                  attrs: { "data-testid": "wrong-number-checkbox" },
                  model: {
                    value: _vm.edit_phone_number.is_wrong_number,
                    callback: function ($$v) {
                      _vm.$set(_vm.edit_phone_number, "is_wrong_number", $$v)
                    },
                    expression: "edit_phone_number.is_wrong_number",
                  },
                },
                [_vm._v("\n            Wrong Number\n        ")]
              ),
              _vm._v(" "),
              _c(
                "el-tooltip",
                {
                  staticClass: "item mb-1",
                  attrs: {
                    effect: "light",
                    content:
                      "Select this box if number isn't associated with this contact.",
                    "data-testid": "is-not-associated-tooltip",
                    placement: "top",
                  },
                },
                [_c("i", { staticClass: "fas fa-info-circle" })]
              ),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              _c(
                "el-checkbox",
                {
                  attrs: {
                    disabled: this.phone_number.is_opted_out,
                    "data-testid": "sms-opt-out-checkbox",
                  },
                  model: {
                    value: _vm.edit_phone_number.is_opted_out,
                    callback: function ($$v) {
                      _vm.$set(_vm.edit_phone_number, "is_opted_out", $$v)
                    },
                    expression: "edit_phone_number.is_opted_out",
                  },
                },
                [_vm._v("\n            SMS Opt-Out\n        ")]
              ),
              _vm._v(" "),
              _c(
                "el-tooltip",
                {
                  staticClass: "item ml-2 mb-1",
                  attrs: {
                    effect: "light",
                    content:
                      "Once the phone number is opted out, you can't uncheck it.",
                    placement: "top",
                  },
                },
                [_c("i", { staticClass: "fas fa-info-circle" })]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "d-flex align-items-center pb-3" }, [
                _c(
                  "div",
                  { staticClass: "ml-auto" },
                  [
                    _c(
                      "el-button",
                      {
                        staticClass: "pull-right ml-2",
                        attrs: {
                          type: "success",
                          disabled: _vm.loading_btn,
                          "data-testid": "edit-phone-number-save-button",
                        },
                        on: { click: _vm.savePhoneNumber },
                      },
                      [
                        _c(
                          "i",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.loading_btn,
                                expression: "loading_btn",
                              },
                            ],
                            staticClass: "material-icons loader",
                          },
                          [_vm._v("")]
                        ),
                        _vm._v(" "),
                        _c(
                          "i",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: !_vm.loading_btn,
                                expression: "!loading_btn",
                              },
                            ],
                            staticClass: "material-icons",
                          },
                          [
                            _vm._v(
                              "\n                        save\n                    "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("span", [_vm._v("Save")]),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        staticClass: "pull-right",
                        attrs: {
                          type: "info",
                          "data-testid": "edit-phone-number-close-button",
                        },
                        on: { click: _vm.closeModal },
                      },
                      [
                        _c("span", { staticClass: "fa fa-close" }),
                        _vm._v(" "),
                        _c("span", [_vm._v("Cancel")]),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _vm._t("trigger", function () {
            return [
              _c(
                "button",
                {
                  staticClass:
                    "btn btn-sm btn-outline-warning w-30 d-flex justify-content-center align-items-center",
                  attrs: {
                    slot: "reference",
                    "data-testid": "edit-phone-number-slot-button",
                  },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      _vm.hide_edit = true
                    },
                  },
                  slot: "reference",
                },
                [_c("i", { staticClass: "fa fa-edit" })]
              ),
            ]
          }),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }