var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.contact
    ? _c("div", [
        _vm._v("\n    Appointment with " + _vm._s(_vm.contact.name)),
        _vm.time_diff != 0
          ? _c("span", [
              _vm._v(" in " + _vm._s(_vm.time_diff) + " " + _vm._s(_vm.unit)),
            ])
          : _vm._e(),
        _vm._v(".\n    "),
        _c("br"),
        _vm._v(" "),
        _c("span", {
          domProps: {
            innerHTML: _vm._s(_vm.$options.filters.nl2br(_vm.engagement.body)),
          },
        }),
        _vm._v(" "),
        _c("br"),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "Phone number: " +
              _vm._s(_vm._f("fixPhone")(_vm.contact.phone_number))
          ),
        ]),
        _vm._v(" "),
        _vm._m(0),
      ])
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c(
        "button",
        {
          staticClass:
            "btn rounded greenish btn-appointment btn-sm mt-2 pull-right",
        },
        [_vm._v("\n            Go To Contact\n        ")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }