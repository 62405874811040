<template>
    <div class="app-body sold-report-container"
         id="view">
         <router-link class="mt-2 mb-1"
                      tag="a"
                      :to="{ name: 'Contact', params: { contact_id: contact } }"
                      v-if="contact">
            <i class="fa fa-arrow-left"></i>
            Return to contact
         </router-link>
        <simpsocial-email-i-frame :contact_id="contact">
        </simpsocial-email-i-frame>
  </div>
</template>

<script>
import auth from '../../auth'
import {mapState} from 'vuex'
import SimpsocialEmailIFrame from '../../components/simpsocial/simpsocial-email-iframe.vue'
export default {
    data() {
        return {
            auth: auth,
            source: ''
        }
    },

    computed: {
        contact() {
            return this.$route.params.contact_id ? parseInt(this.$route.params.contact_id) : null
        }
    },

    components: {
        SimpsocialEmailIFrame
    },

    created() {
        this.getStatics()
    },

    methods: {
        getStatics() {
            axios.get('/get-statics')
                .then(res => {
                    this.statics = res.data
                    this.setPageTitle('Email - ' + this.statics.name)
                })
                .catch(err => {
                    this.setPageTitle('Email')
                    console.log(err)
                    this.$root.handleErrors(err.response)
                })
        },
    },

    beforeRouteEnter(to, from, next) {
        auth.check()
            .then((res) => {
                if (res.data.user.is_reseller) {
                    // redirect to account management portal if the company is a reseller
                    next({name: 'Account Management Portal'})
                } else {
                    next()
                }
            })
            .catch((err) => {
                next({name: 'Login', query: {redirect: to.fullPath}})
            })
    }
}
</script>
