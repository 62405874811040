<template>
    <div>
        <div class="col-12">
            <p class="font-weight-bold side-panel-title">Send Message Panel</p>
        </div>

        <div class="col-12 mt-4">
            <p class="mb-0 font-weight-bold">Message:</p>
            <el-input
                type="textarea"
                placeholder="Type bot message here."
                :rows="3"
                v-model="nodeData.message">
            </el-input>
        </div>

        <div class="col-12 text-right">
            <el-tooltip
                content="Add variable"
                placement="top">
                <Variables @variable-selected="variableSelected"/>
            </el-tooltip>
        </div>
    </div>
</template>

<script>
import {alohabot_side_panel_mixin} from '../../../mixins'
import Variables from "../../messenger/variables"

export default {
    name: 'message-panel',
    components: {Variables},
    mixins: [
        alohabot_side_panel_mixin
    ],
    methods: {
        /**
         * Listens when text variable has changed.
         *
         * @param {string} variable
         *
         * @return {void}
         */
        variableSelected(variable) {
            this.nodeData.message = (this.nodeData.message ?? '') + ' ' + variable
        }
    }
}
</script>
