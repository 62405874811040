<template>
    <div class="p-3 w-100"
         v-loading="is_loading">
        <label class="font-weight-bold">Sequence</label>
        <div v-if="sequence">
            <div class="d-flex mb-3">
                <img
                    data-testid="sequence-img"
                    alt="The Sequence icon"
                    class="mr-2"
                    :src="sequence.img_src"/>
                <div class="lh-1p4">
                    <h6 class="mb-0 text-black">{{ workflow.name }}</h6>
                    <div>
                        <span class="text-gray">#{{ sequence.order }}</span>
                        {{ sequence.name }}
                    </div>
                </div>
                <el-button size="small"
                           class="ml-auto"
                           round
                           title="Refresh sequence info"
                           data-testid="refresh-sequence-info-button"
                           @click="fetchContactSequence">
                    <i class="fa fa-refresh" style="font-size: 12px"></i>
                </el-button>
            </div>

            <div class="text-center">
                <el-button size="small"
                           round
                           class="text-danger"
                           :loading="loading_disenroll_contact"
                           data-testid="disenroll-contact-button"
                           @click="disenrollContact">
                    <i class="fa fa-trash-o"></i> Disenroll From Sequence
                </el-button>
            </div>
        </div>

        <div class="text-center"
             v-show="!sequence">
            <p>
                This contact is currently not <br/>
                enrolled to a sequence.
            </p>
            <workflow-bulk-enroller
                model="contact"
                :id="contact.id"
                :phone_number="activePhoneNumber"
                @enrolled="enrolled">
            </workflow-bulk-enroller>
        </div>
    </div>
</template>

<script>
import WorkflowBulkEnroller from "../automations/workflow-bulk-enroller"

export default {
    name: "contact-sequence",

    components: {WorkflowBulkEnroller},

    props: {
        value: {
            required: true,
            type: Object
        },

        contact_phone_numbers: {
            type: Array,
            required: false
        }
    },

    data() {
        return {
            contact: this.value,
            workflow: null,
            sequence: null,
            loading_disenroll_contact: false,
            is_loading: false
        }
    },

    computed: {
        activePhoneNumber() {
            let numbers = this.contact_phone_numbers

            // search the phone number data from the collection of contact's phone number based on the phone number
            if (this.contact.phone_number && numbers && numbers.length) {
                return numbers.find(num => num.phone_number === this.contact.phone_number)
            }

            return null
        },
    },

    mounted() {
        this.fetchContactSequence()
    },

    methods: {
        fetchContactSequence() {
            if (!this.contact.id) {
                return
            }

            this.is_loading = true
            axios.get(`/api/v1/contact/${this.contact.id}/sequence-info`).then(res => {
                this.workflow = res.data.workflow
                this.sequence = res.data.sequence

                this.is_loading = false
            })
        },

        resetSequence() {
            this.sequence = null
            this.workflow = null
        },

        disenrollContact() {
            this.$confirm('Are you sure you want to disenroll this contact from all sequences?', 'Warning', {
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                customClass: 'width-500 fixed',
                type: 'warning'
            }).then(res => {
                this.loading_disenroll_contact = true
                axios.post('/api/v1/contact/' + this.contact.id + '/disenroll')
                    .then((res) => {
                        this.loading_disenroll_contact = false
                        this.$notify({
                            offset: 95,
                            title: 'Sequence',
                            message: res.data.message,
                            type: 'success',
                            showClose: true,
                        })

                        this.resetSequence()
                    })
                    .catch((err) => {
                        this.loading_disenroll_contact = false
                    })
            }).catch(err => {
                console.log(err)
            })
        },

        enrolled() {
            this.is_loading = true
            setTimeout(() => {
                this.fetchContactSequence()
            }, 3000)
        }
    }
}
</script>
