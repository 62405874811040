var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.contact
    ? _c(
        "div",
        [
          _c("div", { staticClass: "row no-gutter text-center m-t" }, [
            !_vm.hasReporterAccess
              ? _c(
                  "div",
                  { staticClass: "col p-0" },
                  [
                    _c(
                      "el-badge",
                      {
                        staticClass: "item",
                        attrs: {
                          value: _vm.unreadCallCount,
                          max: 99,
                          min: 0,
                          hidden: _vm.unreadCallCount < 1,
                        },
                      },
                      [
                        !_vm.enabledToCallNumber(_vm.activePhoneNumber)
                          ? _c(
                              "el-popover",
                              {
                                attrs: {
                                  placement: "top",
                                  width: "300",
                                  trigger: "hover",
                                  "popper-class": "p-2 btn-primary",
                                },
                              },
                              [
                                _c("custom-message-display", {
                                  attrs: { config: _vm.customMessage("call") },
                                }),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  {
                                    attrs: { slot: "reference" },
                                    slot: "reference",
                                  },
                                  [
                                    _c(
                                      "button",
                                      {
                                        staticClass:
                                          "md-btn md-fab md-sm m-b-sm greenish",
                                        attrs: {
                                          slot: "reference",
                                          disabled: "",
                                        },
                                        slot: "reference",
                                      },
                                      [
                                        _c(
                                          "i",
                                          {
                                            staticClass: "material-icons md-24",
                                          },
                                          [_vm._v("call")]
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.enabledToCallNumber(_vm.activePhoneNumber)
                          ? _c(
                              "el-popover",
                              {
                                attrs: {
                                  placement: "top",
                                  width: "300",
                                  trigger: "hover",
                                  "popper-class": "p-2",
                                  disabled: !_vm.isNumberInvalidOrWrong(
                                    _vm.activePhoneNumber
                                  ),
                                },
                              },
                              [
                                _c("span", { staticClass: "text-black-dk" }, [
                                  _vm._v(
                                    "\n                        " +
                                      _vm._s(
                                        _vm.getTooltipMessage(
                                          _vm.activePhoneNumber
                                        )
                                      ) +
                                      "\n                    "
                                  ),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  {
                                    attrs: { slot: "reference" },
                                    slot: "reference",
                                  },
                                  [
                                    _c(
                                      "button",
                                      {
                                        staticClass:
                                          "md-btn md-fab md-sm m-b-sm greenish",
                                        attrs: {
                                          disabled: _vm.isNumberInvalidOrWrong(
                                            _vm.activePhoneNumber
                                          ),
                                          "data-testid": "call-contact-button",
                                        },
                                        on: { click: _vm.callContact },
                                      },
                                      [
                                        _c(
                                          "i",
                                          {
                                            staticClass: "material-icons md-24",
                                          },
                                          [_vm._v("call")]
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("span", { staticClass: "d-block text-xs text-muted" }, [
                      _vm._v("Call"),
                    ]),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col p-0" },
              [
                _c(
                  "el-badge",
                  {
                    staticClass: "item",
                    attrs: {
                      value: _vm.contact.unread_count,
                      max: 99,
                      min: 0,
                      hidden: _vm.contact.unread_count < 1,
                    },
                  },
                  [
                    !_vm.enabledToTextNumber(_vm.activePhoneNumber)
                      ? _c(
                          "el-popover",
                          {
                            attrs: {
                              placement: "top",
                              width: "300",
                              trigger: "hover",
                              "popper-class": "p-2 btn-primary",
                            },
                          },
                          [
                            _c("custom-message-display", {
                              attrs: { config: _vm.customMessage("text") },
                            }),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                attrs: { slot: "reference" },
                                slot: "reference",
                              },
                              [
                                _c(
                                  "button",
                                  {
                                    staticClass:
                                      "md-btn md-fab md-sm m-b-sm greenish",
                                    attrs: {
                                      slot: "reference",
                                      disabled: "",
                                      "data-testid":
                                        "forum-contact-popover-button",
                                    },
                                    slot: "reference",
                                  },
                                  [
                                    _c(
                                      "i",
                                      { staticClass: "material-icons md-24" },
                                      [_vm._v("forum")]
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.enabledToTextNumber(_vm.activePhoneNumber)
                      ? _c(
                          "el-popover",
                          {
                            attrs: {
                              placement: "top",
                              width: "300",
                              trigger: "hover",
                              "popper-class": "p-2",
                              disabled:
                                (!_vm.isPrimaryNumberErroneous ||
                                  !_vm.isNumberInvalidOrWrong(
                                    _vm.activePhoneNumber
                                  )) &&
                                !_vm.isOptedOut(_vm.activePhoneNumber) &&
                                _vm.canSendNonTCPAContacts,
                            },
                          },
                          [
                            _c("span", {
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.getTooltipMessage(
                                    _vm.activePhoneNumber,
                                    !_vm.canSendNonTCPAContacts
                                  )
                                ),
                              },
                            }),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                attrs: { slot: "reference" },
                                slot: "reference",
                              },
                              [
                                _c(
                                  "button",
                                  {
                                    staticClass:
                                      "md-btn md-fab md-sm m-b-sm bluish",
                                    attrs: {
                                      "data-testid":
                                        "forum-text-contact-button",
                                      disabled:
                                        _vm.contact.is_dnc ||
                                        (_vm.isPrimaryNumberErroneous &&
                                          _vm.isNumberInvalidOrWrong(
                                            _vm.activePhoneNumber
                                          )) ||
                                        _vm.isOptedOut(_vm.activePhoneNumber) ||
                                        !_vm.canSendNonTCPAContacts,
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.changeSelectedPhoneNumber(
                                          _vm.contact.phone_number
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "i",
                                      { staticClass: "material-icons md-24" },
                                      [_vm._v("forum")]
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("span", { staticClass: "d-block text-xs text-muted" }, [
                  _vm._v("Text"),
                ]),
              ],
              1
            ),
            _vm._v(" "),
            _vm.hasPermissionTo("toggle block contact")
              ? _c("div", { staticClass: "col p-0" }, [
                  !_vm.contact.is_blocked
                    ? _c(
                        "button",
                        {
                          staticClass:
                            "md-btn md-fab md-sm m-b-sm orange text-whitish",
                          attrs: { "data-testid": "block-contact-button" },
                          on: { click: _vm.blockContact },
                        },
                        [
                          _c(
                            "i",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: !_vm.loading_block_contact,
                                  expression: "!loading_block_contact",
                                },
                              ],
                              staticClass: "material-icons md-24",
                            },
                            [_vm._v("lock_outline")]
                          ),
                          _vm._v(" "),
                          _c(
                            "i",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.loading_block_contact,
                                  expression: "loading_block_contact",
                                },
                              ],
                              staticClass: "material-icons md-24 loader",
                            },
                            [_vm._v("")]
                          ),
                        ]
                      )
                    : _c(
                        "button",
                        {
                          staticClass: "md-btn md-fab md-sm m-b-sm blue",
                          attrs: { "data-testid": "unblock-contact-button" },
                          on: { click: _vm.unblockContact },
                        },
                        [
                          _c(
                            "i",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: !_vm.loading_block_contact,
                                  expression: "!loading_block_contact",
                                },
                              ],
                              staticClass: "material-icons md-24",
                            },
                            [_vm._v("lock_open")]
                          ),
                          _vm._v(" "),
                          _c(
                            "i",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.loading_block_contact,
                                  expression: "loading_block_contact",
                                },
                              ],
                              staticClass: "material-icons md-24 loader",
                            },
                            [_vm._v("")]
                          ),
                        ]
                      ),
                  _vm._v(" "),
                  !_vm.contact.is_blocked
                    ? _c(
                        "span",
                        { staticClass: "d-block text-xs text-muted" },
                        [_vm._v("\n                Block\n            ")]
                      )
                    : _c(
                        "span",
                        { staticClass: "d-block text-xs text-muted" },
                        [_vm._v("\n                Unblock\n            ")]
                      ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.hasPermissionTo("toggle block contact") && !_vm.contact.is_dnc
              ? _c("div", { staticClass: "col p-0" }, [
                  _c(
                    "button",
                    {
                      staticClass: "md-btn md-fab md-sm m-b-sm red",
                      attrs: { "data-testid": "dnc-contact-button" },
                      on: { click: _vm.dncContact },
                    },
                    [
                      _c(
                        "i",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: !_vm.loading_dnc_contact,
                              expression: "!loading_dnc_contact",
                            },
                          ],
                          staticClass: "material-icons md-24",
                        },
                        [_vm._v("do_not_disturb_on")]
                      ),
                      _vm._v(" "),
                      _c(
                        "i",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.loading_dnc_contact,
                              expression: "loading_dnc_contact",
                            },
                          ],
                          staticClass: "material-icons md-24 loader",
                        },
                        [_vm._v("")]
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  !_vm.contact.is_dnc
                    ? _c(
                        "span",
                        { staticClass: "d-block text-xs text-muted" },
                        [_vm._v("\n                DNC\n            ")]
                      )
                    : _vm._e(),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.hasRole("Company Admin") &&
            _vm.contact.is_dnc &&
            _vm.current_company &&
            _vm.current_company.undnc_enabled
              ? _c("div", { staticClass: "col p-0" }, [
                  _c(
                    "button",
                    {
                      staticClass: "md-btn md-fab md-sm m-b-sm red",
                      attrs: { "data-testid": "undnc-contact-button" },
                      on: { click: _vm.showUnDncDialog },
                    },
                    [
                      _c(
                        "i",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: !_vm.undnc.updating_dnc_status,
                              expression: "!undnc.updating_dnc_status",
                            },
                          ],
                          staticClass: "material-icons md-24",
                        },
                        [_vm._v("do_not_disturb_on")]
                      ),
                      _vm._v(" "),
                      _c(
                        "i",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.undnc.updating_dnc_status,
                              expression: "undnc.updating_dnc_status",
                            },
                          ],
                          staticClass: "material-icons md-24 loader",
                        },
                        [_vm._v("")]
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _vm.contact.is_dnc
                    ? _c(
                        "span",
                        { staticClass: "d-block text-xs text-muted" },
                        [_vm._v("\n                UN-DNC\n            ")]
                      )
                    : _vm._e(),
                ])
              : _vm._e(),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "row no-gutter text-center m-t" }, [
            _c("div", { staticClass: "col p-0" }, [
              _c(
                "button",
                {
                  staticClass: "md-btn md-fab md-sm m-b-sm yellow",
                  attrs: {
                    slot: "reference",
                    disabled: _vm.contact.is_dnc,
                    "data-testid": "add-appointment-button",
                  },
                  on: { click: _vm.addAppointment },
                  slot: "reference",
                },
                [
                  _c("i", { staticClass: "material-icons md-24" }, [
                    _vm._v("event_available"),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c("span", { staticClass: "d-block text-xs text-muted" }, [
                _vm._v("Appt"),
              ]),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col p-0" },
              [
                _c(
                  "el-popover",
                  {
                    directives: [
                      {
                        name: "closable",
                        rawName: "v-closable",
                        value: {
                          handler: "onAddNoteFormClose",
                        },
                        expression:
                          "{\n                            handler: 'onAddNoteFormClose'\n                        }",
                      },
                    ],
                    ref: "noteForm",
                    attrs: {
                      placement: "right",
                      title: "Add Note",
                      width: "250",
                      trigger: "manual",
                      "data-testid": "add-note-popover",
                    },
                    on: { hide: _vm.resetAddEngagementForm },
                    model: {
                      value: _vm.add_note_form_visibility,
                      callback: function ($$v) {
                        _vm.add_note_form_visibility = $$v
                      },
                      expression: "add_note_form_visibility",
                    },
                  },
                  [
                    _c(
                      "el-form",
                      {
                        ref: "add_note_form",
                        staticClass: "h-full",
                        attrs: {
                          "data-testid": "add-note-form",
                          model: _vm.add_engagement,
                        },
                      },
                      [
                        _c(
                          "el-form-item",
                          [
                            _c("app-mentionable", {
                              attrs: {
                                items: _vm.mention_items,
                                searchKey: "full_name",
                                "data-testid": "add-mention-items-mentionable",
                              },
                              on: { input: _vm.validateAddNoteEngagementForm },
                              model: {
                                value: _vm.add_engagement.body,
                                callback: function ($$v) {
                                  _vm.$set(_vm.add_engagement, "body", $$v)
                                },
                                expression: "add_engagement.body",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            staticClass: "d-block mt-2 btn-block",
                            attrs: {
                              type: "success",
                              loading: _vm.loading_add_engagement,
                              disabled: !_vm.add_engagement_validated,
                              "data-testid": "add-note-button",
                            },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.addEngagement(
                                  _vm.CommunicationTypes.NOTE
                                )
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n                        Add Note\n                    "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "md-btn md-fab md-sm m-b-sm accent",
                        attrs: {
                          slot: "reference",
                          "data-testid": "add-note-form-visibility-button",
                        },
                        on: {
                          click: function ($event) {
                            _vm.add_note_form_visibility = true
                          },
                        },
                        slot: "reference",
                      },
                      [
                        _c("i", { staticClass: "material-icons md-24" }, [
                          _vm._v("event_note"),
                        ]),
                      ]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("span", { staticClass: "d-block text-xs text-muted" }, [
                  _vm._v("Note"),
                ]),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col p-0" },
              [
                _c(
                  "el-popover",
                  {
                    ref: "reminderForm",
                    attrs: {
                      placement: "right",
                      title: "Add Reminder",
                      width: "250",
                      trigger: "click",
                    },
                    on: { hide: _vm.resetAddEngagementForm },
                  },
                  [
                    _c(
                      "el-form",
                      {
                        ref: "add_note_form",
                        staticClass: "h-full",
                        attrs: {
                          "data-testid": "add-reminder-form",
                          model: _vm.add_engagement,
                        },
                      },
                      [
                        _c(
                          "el-form-item",
                          [
                            _c("el-date-picker", {
                              staticClass: "w-100",
                              attrs: {
                                format: "MM/dd/yyyy",
                                "value-format": "MM/dd/yyyy",
                                type: "date",
                                placeholder: "Select date",
                                "picker-options": _vm.pickerOptions,
                                "data-testid": "reminder-date-picker",
                              },
                              on: {
                                change:
                                  _vm.validateAddAppointmentOrReminderEngagementForm,
                              },
                              model: {
                                value: _vm.add_engagement.date,
                                callback: function ($$v) {
                                  _vm.$set(_vm.add_engagement, "date", $$v)
                                },
                                expression: "add_engagement.date",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          [
                            _c(
                              "el-select",
                              {
                                staticClass: "w-100",
                                attrs: {
                                  placeholder: "Select time",
                                  filterable: "",
                                  "data-testid": "reminder-time-picker",
                                },
                                on: {
                                  change:
                                    _vm.validateAddAppointmentOrReminderEngagementForm,
                                },
                                model: {
                                  value: _vm.add_engagement.time,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.add_engagement, "time", $$v)
                                  },
                                  expression: "add_engagement.time",
                                },
                              },
                              _vm._l(_vm.times, function (time) {
                                return _c("el-option", {
                                  key: time.value,
                                  attrs: {
                                    label: time.label,
                                    value: time.value,
                                  },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          [
                            _c(
                              "el-select",
                              {
                                staticClass: "w-100",
                                attrs: {
                                  placeholder: "Select Timezone",
                                  "data-testid": "reminder-timezone-picker",
                                  filterable: "",
                                },
                                model: {
                                  value: _vm.add_engagement.timezone,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.add_engagement,
                                      "timezone",
                                      $$v
                                    )
                                  },
                                  expression: "add_engagement.timezone",
                                },
                              },
                              _vm._l(
                                _vm.filteredTimezones,
                                function (timezone) {
                                  return _c("el-option", {
                                    key: timezone.value,
                                    attrs: {
                                      label: timezone.name,
                                      value: timezone.value,
                                    },
                                  })
                                }
                              ),
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          [
                            _c("el-input", {
                              attrs: {
                                type: "textarea",
                                autosize: { minRows: 2, maxRows: 4 },
                                "data-testid": "reminder-notes-input",
                                placeholder: "Reminder notes",
                              },
                              model: {
                                value: _vm.add_engagement.body,
                                callback: function ($$v) {
                                  _vm.$set(_vm.add_engagement, "body", $$v)
                                },
                                expression: "add_engagement.body",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            staticClass: "d-block mt-2 btn-block",
                            attrs: {
                              type: "success",
                              loading: _vm.loading_add_engagement,
                              disabled: !_vm.add_engagement_validated,
                              "data-testid": "add-reminder-button",
                            },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.addEngagement(
                                  _vm.CommunicationTypes.REMINDER
                                )
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n                        Add Reminder\n                    "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "md-btn md-fab md-sm m-b-sm greyish",
                        attrs: {
                          slot: "reference",
                          disabled: _vm.contact.is_dnc,
                          "data-testid": "access-alarms-button",
                        },
                        slot: "reference",
                      },
                      [
                        _c("i", { staticClass: "material-icons md-24" }, [
                          _vm._v("access_alarms"),
                        ]),
                      ]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("span", { staticClass: "d-block text-xs text-muted" }, [
                  _vm._v("Reminder"),
                ]),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col p-0" },
              [
                _c(
                  "el-popover",
                  {
                    attrs: {
                      placement: "top",
                      width: "300",
                      trigger: "hover",
                      "popper-class": "p-2",
                      "data-testid": "power-dialer-popover",
                      disabled:
                        !_vm.isPrimaryNumberErroneous ||
                        !_vm.isNumberInvalidOrWrong(_vm.activePhoneNumber) ||
                        _vm.isPowerDialerDepreciated,
                    },
                  },
                  [
                    _c("span", { staticClass: "text-black-dk" }, [
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm.getTooltipMessage(_vm.activePhoneNumber)) +
                          "\n                "
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "span",
                      { attrs: { slot: "reference" }, slot: "reference" },
                      [
                        _c(
                          "button",
                          {
                            directives: [
                              {
                                name: "loading",
                                rawName: "v-loading",
                                value: _vm.loading_add_to_power_dialer,
                                expression: "loading_add_to_power_dialer",
                              },
                            ],
                            staticClass:
                              "md-btn md-fab md-sm m-b-sm new-purple",
                            attrs: {
                              "data-testid": "phone-forwarded-button",
                              disabled:
                                _vm.loading_add_to_power_dialer ||
                                _vm.contact.is_dnc ||
                                (_vm.isPrimaryNumberErroneous &&
                                  _vm.isNumberInvalidOrWrong(
                                    _vm.activePhoneNumber
                                  )) ||
                                (_vm.isPowerDialerDepreciated && _vm.forceTalk),
                            },
                            on: { click: _vm.addToPowerDialer },
                          },
                          [
                            _c("i", { staticClass: "material-icons md-24" }, [
                              _vm._v("phone_forwarded"),
                            ]),
                          ]
                        ),
                      ]
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("span", { staticClass: "d-block text-xs text-muted" }, [
                  _vm._v("Power Dialer"),
                ]),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "row no-gutter text-center m-t" }, [
            _vm.current_company && _vm.current_company.reseller_id !== 357
              ? _c(
                  "div",
                  { staticClass: "col-3 p-0" },
                  [
                    [
                      _c(
                        "el-popover",
                        {
                          attrs: {
                            placement: "top",
                            width: "300",
                            trigger: "hover",
                            "popper-class": "p-2",
                            "data-testid": "send-fax-popover",
                            disabled:
                              !_vm.isNumberInvalidOrWrong(
                                _vm.activePhoneNumber
                              ) && !_vm.disable_send_fax_button,
                          },
                        },
                        [
                          _c(
                            "span",
                            { staticClass: "text-black-dk" },
                            [
                              _vm.isNumberInvalidOrWrong(_vm.activePhoneNumber)
                                ? [
                                    _vm._v(
                                      "\n                            " +
                                        _vm._s(
                                          _vm.getTooltipMessage(
                                            _vm.activePhoneNumber
                                          )
                                        ) +
                                        "\n                        "
                                    ),
                                  ]
                                : _vm.disable_send_fax_button
                                ? [
                                    _vm._v(
                                      "\n                            Selected line is not capable of sending faxes\n                        "
                                    ),
                                  ]
                                : _vm._e(),
                            ],
                            2
                          ),
                          _vm._v(" "),
                          _c(
                            "span",
                            { attrs: { slot: "reference" }, slot: "reference" },
                            [
                              _c(
                                "button",
                                {
                                  staticClass:
                                    "md-btn md-fab md-sm m-b-sm text-white bg-teal",
                                  attrs: {
                                    "data-testid": "send-fax-button",
                                    disabled:
                                      _vm.isNumberInvalidOrWrong(
                                        _vm.activePhoneNumber
                                      ) || _vm.disable_send_fax_button,
                                  },
                                  on: { click: _vm.openSendFaxDialog },
                                },
                                [
                                  _c(
                                    "i",
                                    { staticClass: "material-icons md-24" },
                                    [_vm._v("print")]
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "span",
                        { staticClass: "d-block text-xs text-muted" },
                        [_vm._v("Send Fax")]
                      ),
                    ],
                  ],
                  2
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.current_company && _vm.current_company.reseller_id !== 357
              ? _c(
                  "div",
                  { staticClass: "col-3 p-0" },
                  [
                    [
                      _c(
                        "button",
                        {
                          staticClass:
                            "md-btn md-fab md-sm m-b-sm text-white bg-blue",
                          attrs: {
                            disabled: !_vm.contact.email,
                            "data-testid": "send-email-button",
                          },
                          on: { click: _vm.openSendEmailDialog },
                        },
                        [
                          _c("i", { staticClass: "material-icons md-24" }, [
                            _vm._v("email"),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "span",
                        { staticClass: "d-block text-xs text-muted" },
                        [_vm._v("Send Email")]
                      ),
                    ],
                  ],
                  2
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.hasRole("Company Admin") &&
            ((_vm.current_company && _vm.current_company.reseller_id === 357) ||
              !_vm.hasCrmLink) &&
            !_vm.getCrmLink(_vm.contact)
              ? _c(
                  "div",
                  { staticClass: "col-3 p-0" },
                  [
                    [
                      _c(
                        "button",
                        {
                          staticClass:
                            "md-btn md-fab md-sm m-b-sm text-white bg-brown",
                          attrs: { "data-testid": "call-merge-contact-button" },
                          on: { click: _vm.openMergeContactDialog },
                        },
                        [
                          _c(
                            "i",
                            {
                              staticClass: "material-icons md-24 fa-rotate-90",
                            },
                            [_vm._v("call_merge")]
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "span",
                        { staticClass: "d-block text-xs text-muted" },
                        [_vm._v("Merge")]
                      ),
                    ],
                  ],
                  2
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.isSimpSocial
              ? _c(
                  "div",
                  { staticClass: "col-3 p-0" },
                  [
                    _vm.current_company &&
                    _vm.current_company.simpsocial_integration_enabled &&
                    _vm.auth.user.profile &&
                    _vm.isSimpSocial
                      ? _c(
                          "router-link",
                          {
                            staticClass: "md-btn md-fab md-sm m-b-sm red",
                            attrs: {
                              tag: "button",
                              "data-testid": "simp-social-email-button",
                              to: {
                                name: "Email Blast",
                                params: { contact_id: _vm.contact_id },
                              },
                            },
                          },
                          [
                            _c(
                              "i",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.isSimpSocial,
                                    expression: "isSimpSocial",
                                  },
                                ],
                                staticClass: "material-icons md-24",
                              },
                              [_vm._v("email")]
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c("span", { staticClass: "d-block text-xs text-muted" }, [
                      _vm._v("\n                Email\n            "),
                    ]),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.isSimpSocial
              ? _c("div", { staticClass: "col-3 p-0" }, [
                  _c(
                    "button",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.isVideoConferenceLinkSending,
                          expression: "isVideoConferenceLinkSending",
                        },
                      ],
                      staticClass:
                        "md-btn md-fab md-sm m-b-sm text-white bg-white p-0",
                      attrs: { "data-testid": "video-conference-button" },
                      on: {
                        click: function ($event) {
                          return _vm.sendVideoConferenceLink()
                        },
                      },
                    },
                    [
                      _c("img", {
                        staticClass: "md-24 w-100",
                        attrs: {
                          src: "/assets/images/video_conference_btn.png",
                        },
                      }),
                    ]
                  ),
                  _vm._v(" "),
                  _c("span", { staticClass: "d-block text-xs text-muted" }, [
                    _vm._v("Video Conference"),
                  ]),
                ])
              : _vm._e(),
          ]),
          _vm._v(" "),
          _c(
            "el-dialog",
            {
              attrs: {
                title: "Confirm",
                visible: _vm.undnc.visible,
                "append-to-body": "",
                width: "30%",
              },
              on: {
                "update:visible": function ($event) {
                  return _vm.$set(_vm.undnc, "visible", $event)
                },
              },
            },
            [
              _c(
                "div",
                [
                  _c("p", [_vm._v(_vm._s(_vm.undnc.confirm_message))]),
                  _vm._v(" "),
                  _c("div", [_c("span", [_vm._v("Please enter a reason:")])]),
                  _vm._v(" "),
                  _c("el-input", {
                    model: {
                      value: _vm.undnc.reason,
                      callback: function ($$v) {
                        _vm.$set(_vm.undnc, "reason", $$v)
                      },
                      expression: "undnc.reason",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { "data-testid": "cancel-undnc-button" },
                      on: { click: _vm.resetUnDncFields },
                    },
                    [_vm._v("\n                        Cancel\n            ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: "success",
                        "data-testid": "confirm-undnc-button",
                        disabled:
                          !_vm.undnc.reason || _vm.undnc.updating_dnc_status,
                      },
                      on: { click: _vm.unDncContact },
                    },
                    [_vm._v("\n                Confirm\n            ")]
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }