<template>
    <div>
        <el-table
            :data="chatbotsData"
            style="width: 100%">

            <el-table-column
                label="Name"
                align="left"
                :min-width="50">
                <template v-slot="scope">
                    <div class="d-flex">
                        <el-switch
                            class="mr-4 align-self-center"
                            v-model="scope.row.enabled"
                            active-color="#00BF50"
                            @change="updateChatbotEnabled(scope.row)">
                        </el-switch>
                        <div>
                            <router-link :to="{
                                    name: 'Chatbot',
                                    params: {bot_id: scope.row.id},
                                    query: {tab:'settings'}
                                }">
                                <div class="hover-lower-opacity">
                                    <p class="mb-0">
                                        <b>{{ scope.row.name }}</b>
                                    </p>
                                    <span>#{{ scope.row.id }}</span>
                                </div>
                            </router-link>
                        </div>
                    </div>
                </template>
            </el-table-column>

            <el-table-column
                label="Workflow"
                prop="workflow"
                align="left"
                :min-width="40">
                <template v-slot="scope">
                    <router-link :to="{
                            name: 'Chatbot',
                            params: {bot_id: scope.row.id},
                            query: {tab:'builder'}
                        }">
                        <div class="hover-lower-opacity">
                            <p class="mb-0">
                                <span class="font-weight-bold">{{ scope.row.workflow }}</span>
                            </p>
                            <span class="d-block pt-1 text-xs">
                                <div class="hover-lower-opacity">
                                    Build Status:
                                    <span v-if="scope.row.build_status == NOT_BUILD" class="font-weight-bold">Not Ready</span>
                                    <span v-if="scope.row.build_status == BUILD_SUCCESS" class="font-weight-bold text-green">Ready</span>
                                    <span v-if="scope.row.build_status == BUILD_IN_PROGRESS" class="font-weight-bold">Building...</span>
                                    <span v-if="scope.row.build_status == BUILD_FAILED" class="font-weight-bold text-red">Failed</span>
                                </div>
                            </span>
                        </div>
                    </router-link>
                </template>
            </el-table-column>

            <el-table-column
                label="Lines"
                :min-width="40"
                align="left">
                <template v-slot="scope">
                    <a>
                        <!-- One line -->
                        <span
                            v-if="scope.row.campaigns.length === 1"
                            class="text-dark-greenish">
                            <router-link :to="{
                                name: 'Line Settings',
                                params: {
                                    campaign_id: scope.row.campaigns[0]?.id
                                }
                            }">
                                <span class="hover-lower-opacity">
                                    <span>{{ scope.row.campaigns[0]?.name }}</span>
                                    <br/>
                                    <!-- One phone number -->
                                    <span v-if="scope.row.campaigns[0].incoming_numbers.length === 1">
                                        {{ scope.row.campaigns[0]?.incoming_numbers[0]?.phone_number | fixPhone }}
                                    </span>
                                    <!-- Multiple phone numbers -->
                                    <span v-else>
                                        {{ scope.row.campaigns[0]?.incoming_numbers.length }} Numbers
                                    </span>
                                </span>
                            </router-link>
                        </span>
                        <!-- Multiple lines -->
                        <span
                            v-else
                            class="text-dark-greenish">
                            <router-link :to="{
                                    name: 'Chatbot',
                                    params: {bot_id: scope.row.id},
                                    query: {tab:'settings'}
                                }">
                                <div class="hover-lower-opacity">
                                    <span>{{ scope.row.campaigns.length }} Lines</span>
                                </div>
                            </router-link>
                        </span>
                    </a>
                </template>
            </el-table-column>

            <el-table-column
                align="left"
                label="Built by"
                :min-width="50">
                <template v-slot="scope">
                    <router-link :to="{ name: 'User Dialog', params: { user_id: scope.row.builder_user.id }}"
                                 v-if="scope.row.builder_user">
                        <div class="hover-lower-opacity">
                            <p class="mb-0">
                                <span class="font-weight-bold">{{ scope.row.builder_user.name }}</span>
                            </p>
                            <span>#{{ scope.row.builder_user.id }}</span>
                            <span v-if="scope.row.built_at"
                                    class="d-block pt-1 text-xs">
                                {{ scope.row.built_at | fixDateTime }}
                            </span>
                        </div>
                    </router-link>
                </template>
            </el-table-column>

            <el-table-column
                align="left"
                label="Created by"
                :min-width="50">
                <template v-slot="scope">
                    <router-link :to="{ name: 'User Dialog', params: { user_id: scope.row.user.id }}"
                                 v-if="scope.row.user">
                        <div class="hover-lower-opacity">
                            <p class="mb-0">
                                <span class="font-weight-bold">{{ scope.row.user.name }}</span>
                            </p>
                            <span>#{{ scope.row.user.id }}</span>
                            <span v-if="scope.row.updated_at"
                                    class="d-block pt-1 text-xs">
                                {{ scope.row.updated_at | fixDateTime }}
                            </span>
                        </div>
                    </router-link>
                </template>
            </el-table-column>

            <el-table-column
                align="left"
                label="Status"
                :min-width="20">
                <template v-slot="scope">
                    <span v-if="scope.row.enabled" class="text-green">
                        <i class="fa fa-play-circle text-success mr-1"></i>
                        <span>Running</span>
                    </span>
                    <span v-else class="text-red">
                        <i class="fa fa-pause-circle text-warning mr-1"></i>
                        <span>Paused</span>
                    </span>
                </template>
            </el-table-column>

            <el-table-column
                align="center"
                label="Notes"
                :min-width="30">
                <template v-slot="scope">
                    <ChatbotNotes :chatbot="scope.row"/>
                </template>
            </el-table-column>

            <el-table-column
                align="center"
                :min-width="40">
                <template v-slot="scope">
                    <div class="d-flex">
                        <el-tooltip
                            class="item"
                            effect="dark"
                            content="Modify"
                            placement="top">
                            <div
                            class="hover-lower-opacity-warning ml-4 mr-4"
                                @click="handleEdit(scope.row)">
                                <i style="font-size:17px" class="fa fa-pencil" aria-hidden="true"/>
                            </div>
                        </el-tooltip>

                        <el-tooltip
                            class="item"
                            effect="dark"
                            content="Export"
                            placement="top">
                            <div
                                class="hover-lower-opacity-primary mr-4"
                                @click="handleExport(scope.row)"
                                :disabled="export_disabled">
                                <i style="font-size:17px" class="fa fa-upload" aria-hidden="true"/>
                            </div>
                        </el-tooltip>

                        <el-tooltip
                            class="item"
                            effect="dark"
                            content="Delete"
                            placement="top">
                            <div
                                class="hover-lower-opacity-danger"
                                :disabled="delete_disabled"
                                @click="handleDelete(scope.row)">
                                <i style="font-size:17px" class="fa fa-trash-o" aria-hidden="true"/>
                            </div>
                        </el-tooltip>
                    </div>
                </template>
            </el-table-column>
        </el-table>

        <DeleteChatbot
            :form_visible="delete_chatbot_visible"
            :chatbot="chatbot"
            @submit="onChatbotDelete"
            @discard="closeDeleteChatbot"/>
    </div>
</template>

<script>
import axios from 'axios'
import DeleteChatbot from './delete-chatbot.vue'
import ChatbotNotes from './chatbot-notes.vue'

const NOT_BUILD = 1
const BUILD_SUCCESS = 2
const BUILD_IN_PROGRESS = 3
const BUILD_FAILED = 4

export default {
    components: {
        ChatbotNotes,
        DeleteChatbot
    },
    props: {
        chatbots: {
            required: false,
            default: []
        }
    },
    data() {
        return {
            NOT_BUILD,
            BUILD_SUCCESS,
            BUILD_IN_PROGRESS,
            BUILD_FAILED,
            delete_chatbot_visible: false,
            chatbot: null,
            count: 0,
            export_disabled: false,
            delete_disabled: false,
            // Todo: implement both search and pagination
            search: '',
            filters: {
                page: 1,
                size: 5,
            }
        }
    },
    methods: {
        /**
         * Creates a template from an existing Chatbot.
         *
         * @return {void}
         */
         createChatbotTemplate() {
            this.setLoading(true)
            axios.post(`/api/v1/bots/builder/${this.chatbot.id}/template/export`).then(res => {
                this.$emit('chatbot-export')
                this.setLoading(false)
                this.$notify.success({
                    offset: 95,
                    title: 'Chatbots',
                    message: "Your chatbot was exported successfully. Click on 'Templates' tab to see it.",
                })
            }).catch(err => {
                console.log(err)
                this.setLoading(false)
                this.$notify.error({
                    offset: 95,
                    title: 'Chatbots',
                    message: "Your Chatbot was not exported. Make sure its Build Status is 'Ready'.",
                })
            })
        },

        /**
         * Updates any chatbot attribute.
         *
         * @param {object} chatbot
         *
         * @return {void}
         */
         updateChatbotEnabled(chatbot) {
            this.setLoading(true)

            // We only need campaign ids.
            let campaign_ids = []
            chatbot.campaigns.map(campaign => campaign_ids.push(campaign.id))

            if (campaign_ids.length === 0) {
                this.$notify.error({
                    offset: 95,
                    title: 'Chatbots',
                    message: 'Your chatbot needs to be connected to at least one line.',
                })
                this.$emit('chatbot-update')

                return
            }

            if (!chatbot.workflow) {
                this.$notify.error({
                    offset: 95,
                    title: 'Chatbots',
                    message: 'Your chatbot needs a workflow.',
                })
                this.$emit('chatbot-update')

                return
            }

            let req = {
                notes: chatbot.notes,
                name: chatbot.name,
                workflow: chatbot.workflow,
                // We don't use !chatbot.enabled because it's already been changed by the switch button.
                enabled: chatbot.enabled,
                time_format_24h: chatbot.time_format_24h,
                campaign_ids,
            }

            axios.patch(`/api/v1/bots/builder/bot/${chatbot.id}`, req).then(res => {
                this.setLoading(false)
                this.$notify.success({
                    offset: 95,
                    title: 'Chatbots',
                    message: 'Chatbot updated successfully.',
                })
                this.$emit('chatbot-update')
            }).catch(err => {
                console.log(err)
                this.setLoading(false)
                this.$emit('chatbot-update')
                this.$root.handleErrors(err.response)
            })
        },

        /**
         * Handles any logic for pressing the Edit button in the table.
         *
         * @param {object} row
         *
         * @return {void}
         */
        handleEdit(row) {
            // Each row is a chatbot object
            this.chatbot = row
            // Goes to the Chatbot Settings page
            this.$router.push({
                name: 'Chatbot',
                params: {
                    bot_id: this.chatbot.id
                }
            })
        },

        /**
         * Handles any logic for pressing the Export button in the table.
         *
         * @param {object} row
         *
         * @return {void}
         */
        handleExport(row) {
            // Each row is a chatbot object
            this.chatbot = row
            this.createChatbotTemplate()
        },

        /**
         * Handles any logic for pressing the Delete button in the table.
         *
         * @param {object} row
         *
         * @return {void}
         */
        handleDelete(row) {
            // Each row is a chatbot object
            this.chatbot = row
            this.openDeleteChatbot()
        },

        /**
         * Makes the Delete chatbot form visible.
         *
         * @return {void}
         */
        openDeleteChatbot() {
            this.delete_chatbot_visible = true
        },

        /**
         * Makes the Delete chatbot form invisible.
         *
         * @return {void}
         */
        closeDeleteChatbot() {
            this.delete_chatbot_visible = false
        },

        /**
         * Listens when a chatbot is deleted.
         *
         * @return {void}
         */
        onChatbotDelete() {
            this.$emit('chatbot-delete')
            this.closeDeleteChatbot()
        },

        /**
         * Set all the components to loading state
         *
         * @param {boolean} loading
         *
         * @return {void}
         */
         setLoading(loading) {
            this.export_disabled = loading
            this.delete_disabled = loading
        }
    },
    computed: {
        // Todo: implement search logic
        chatbotsData() {
            if(!this.chatbots) {
                return this.chatbots
            }
            return this.chatbots.filter(data => !this.search || data.name.toLowerCase().includes(this.search.toLowerCase()))
        }
    }
}
</script>
