<template>
    <div>
        <div class="col-12">
            <div class="d-flex">
                <div class="ml-auto">
                    <el-button
                        type="success"
                        round
                        size="medium"
                        :disabled="disable_add_intent_button"
                        @click="onAddIntentClick">
                        <i class="fa fa-plus mr-1"/>
                        Add Intent
                    </el-button>
                </div>
            </div>
        </div>

        <el-table
            ref="chatbot-intent-manager-table"
            :data="filteredIntents"
            row-key="id"
            @row-click="onExpandChange"
            @expand-change="onExpandChange">

            <el-table-column type="expand">
                <template #default>
                    <TagIntents
                        :phrases.sync="edit.phrases"
                        @created="onTrainingPhraseUpdated"
                        @updated="onTrainingPhraseUpdated"
                        @deleted="onTrainingPhraseUpdated"/>
                </template>
            </el-table-column>

            <el-table-column prop="name">
                <template v-slot:header>
                    <span class="text-dark" style="font-size: 18px">Intents</span>
                </template>
                <template #default="{row}">
                    <p class="mb-0" style="font-size: 16px">{{ row.name }}</p>
                </template>
            </el-table-column>

            <el-table-column align="right">
                <template #header>
                    <el-input
                        v-model="search"
                        size="medium"
                        placeholder="Type to search"/>
                </template>

                <template #default="props">
                    <div class="d-flex">
                        <div class="ml-auto">
                            <div class="d-flex">
                                <div
                                    class="hover-lower-opacity-warning mr-3"
                                    @click="onEditIntentClick(props.row)">
                                    <i style="font-size:17px" class="fa fa-pencil mr-1" aria-hidden="true"/>
                                    Rename
                                </div>

                                <el-tooltip
                                    class="item"
                                    effect="dark"
                                    content="Delete"
                                    placement="top">
                                    <div
                                        class="hover-lower-opacity-danger"
                                        @click="onDeleteIntentClick(props.row)">
                                        <i style="font-size:17px" class="fa fa-trash-o mr-1" aria-hidden="true"/>
                                        Delete
                                    </div>
                                </el-tooltip>
                            </div>

                        </div>
                    </div>
                </template>
            </el-table-column>
        </el-table>

        <!-- Add Intent Dialog -->
        <el-dialog
            class="dialog-blank"
            width="35%"
            append-to-body
            :show-close="false"
            :before-close="handleBeforeClose"
            :visible.sync="modal.createIntent">

            <div class="container pt-4 pb-3 px-4">
                <div class="container-header d-flex">
                    <h4 class="listing-heading text-dark">Add Intent</h4>
                    <div class="ml-auto">
                        <el-button
                            type="text"
                            class="btn-dialog-close"
                            size="large"
                            @click="() => closeModal('createIntent')">
                            <i class="fa fa-remove"></i>
                        </el-button>
                    </div>
                </div>
                <div class="container-body">
                    <el-form>
                        <el-form-item class="my-3">
                            <div class="form-label mb-2">
                                <h5 class="text-dark">Name</h5>
                                <small>Specify a name for this intent to be easily identified. It must be unique.</small>
                            </div>
                            <el-input
                                v-model="edit.intent.name"
                                class="mb-2"
                                placeholder="Intent name..."/>
                        </el-form-item>

                        <el-form-item class="mt-1">
                            <div class="form-label">
                                <h5 class="text-dark">Training Phrases</h5>
                                <small class="text-muted">At least one must be added. Click any to modify it.</small>
                                <TagIntents
                                    class="mt-3"
                                    :phrases.sync="edit.intent.phrases"/>
                            </div>
                        </el-form-item>

                        <el-form-item class="mb-3">
                            <div class="d-flex">
                                <el-button
                                    type="success"
                                    round
                                    :disabled="edit.intent.phrases.length === 0 || edit.intent.name.length === 0"
                                    :loading="buttons.loading.createIntent"
                                    @click="storeIntent">
                                    <i class="fa fa-floppy-o mr-1" aria-hidden="true"/>
                                    <strong>Save</strong>
                                </el-button>

                                <el-button
                                    type="info"
                                    round
                                    @click="() => closeModal('createIntent')">
                                    <strong>Cancel</strong>
                                </el-button>
                            </div>
                        </el-form-item>
                    </el-form>
                </div>
            </div>
        </el-dialog>

        <!-- Rename Intent Dialog -->
        <el-dialog
            class="dialog-blank"
            width="35%"
            append-to-body
            :show-close="false"
            :visible.sync="modal.renameIntent">

            <div class="container pt-4 pb-3 px-4">
                <div class="container-header d-flex">
                    <h4 class="listing-heading text-dark">Rename Intent</h4>
                    <div class="ml-auto">
                        <el-button
                            type="text"
                            class="btn-dialog-close"
                            size="large"
                            @click="() => closeModal('renameIntent')">
                            <i class="fa fa-remove"></i>
                        </el-button>
                    </div>
                </div>
                <div class="container-body">
                    <el-form>
                        <el-form-item class="my-3">
                            <div class="form-label mb-2">
                                <h5 class="text-dark">Name</h5>
                                <small>Specify a name for this intent to be easily identified. It must be unique.</small>
                            </div>
                            <el-input
                                v-model="edit.intent.name"
                                class="mb-2"
                                placeholder="Intent name..."/>
                        </el-form-item>

                        <el-form-item class="mt-1">
                            <div class="form-label">
                                <h5 class="text-dark">Training Phrases</h5>
                                <TagIntents
                                    :disabled="disable_phrases_manager"
                                    class="mt-3"
                                    :phrases.sync="edit.intent.phrases"/>
                            </div>
                        </el-form-item>

                        <el-form-item class="mb-3">
                            <div class="d-flex">
                                <el-button
                                    type="success"
                                    round
                                    :disabled="edit.intent.name.length === 0"
                                    :loading="buttons.loading.renameIntent"
                                    @click="saveIntent">
                                    <i class="fa fa-floppy-o mr-1" aria-hidden="true"/>
                                    <strong>Save</strong>
                                </el-button>

                                <el-button
                                    type="info"
                                    round
                                    @click="() => closeModal('renameIntent')">
                                    <strong>Cancel</strong>
                                </el-button>
                            </div>
                        </el-form-item>
                    </el-form>
                </div>
            </div>
        </el-dialog>

        <!-- Delete Intent Dialog -->
        <el-dialog
            class="dialog-blank"
            width="35%"
            append-to-body
            :show-close="false"
            :visible.sync="modal.deleteIntent">

            <div class="container pt-4 pb-3 px-4">
                <div class="container-header d-flex">
                    <h4 class="listing-heading text-dark">Delete Intent</h4>
                    <div class="ml-auto">
                        <el-button
                            type="text"
                            class="btn-dialog-close"
                            size="large"
                            @click="() => closeModal('deleteIntent')">
                            <i class="fa fa-remove"></i>
                        </el-button>
                    </div>
                </div>
                <div class="container-body">
                    <el-form>
                        <el-form-item class="my-3">
                            <div class="form-label mb-2">
                                <h5 class="font-weight-light text-dark">Are you sure you want to delete <span class="font-weight-bold">{{ edit.intent.name }}</span> intent?</h5>
                            </div>
                        </el-form-item>

                        <el-form-item class="mb-3">
                            <div class="d-flex">
                                <el-button
                                    type="danger"
                                    round
                                    :loading="buttons.loading.deleteIntent"
                                    @click="deleteIntent">
                                    <i class="fa fa-trash-o mr-1" aria-hidden="true"/>
                                    <strong>Delete</strong>
                                </el-button>

                                <el-button
                                    type="info"
                                    round
                                    @click="() => closeModal('deleteIntent')">
                                    <strong>Cancel</strong>
                                </el-button>
                            </div>
                        </el-form-item>
                    </el-form>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import axios from 'axios'
import TagIntents from './chatbot-intent-manager-phrases.vue'

export default {
    components: {
        TagIntents
    },
    props: {
        chatbot: {
            required: true,
            default: null
        },
        intents: {
            required: false,
            default: []
        },
    },
    data() {
        return {
            expanded: null,
            search: '',
            edit: {
                phrases: null,
                intent: {
                    id: null,
                    name: '',
                    phrases: ['Example phrase']
                },
            },
            modal: {
                renameIntent: false,
                deleteIntent: false,
                createIntent: false
            },
            buttons: {
                loading: {
                    createIntent: false,
                    deleteIntent: false,
                    renameIntent: false
                }
            },
            disable_add_intent_button: false,
            disable_phrases_manager: false
        }
    },
    methods: {
        /**
         * Store intent in database
         *
         * @return {void}
         */
        storeIntent() {
            this.setLoading('createIntent')
            let { name, phrases } = this.edit.intent
            let body = { name, phrases }

            axios.post(`/api/v1/bots/builder/${this.chatbot.id}/intents`, body)
                .then(res => {
                    let intent = res.data

                    this.intents.push(intent)

                    // Hide create intent dialog.
                    this.modal.createIntent = false

                    this.$notify.success({
                        title: 'Chatbots',
                        offset: 95,
                        message: 'Intent created successfully.'
                    })
                }).catch(err => {
                    console.log(err)

                    // Hide create intent dialog
                    this.modal.createIntent = false

                    this.$notify.success({
                        title: 'Chatbots',
                        offset: 95,
                        message: 'Intent could not be created.'
                    })
                }).finally(() => {
                    this.setLoading('createIntent', false)
                })
        },

        /**
         * Update intent
         *
         * @return {void}
         */
        saveIntent() {
            this.setLoading('renameIntent')

            let { id, name, phrases } = this.edit.intent

            axios.post(`/api/v1/bots/builder/${this.chatbot.id}/intents/${id}`, {
                name,
                phrases
            }).then(() => {
                // Updates only the modified item instead of fetching again.
                this.intents.map(item => {
                    if (item.id == this.edit.intent.id) {
                        item.name = this.edit.intent.name
                    }
                    return item
                })

                // Hide edit intent dialog.
                this.modal.renameIntent = false

                this.$notify.success({
                    title: 'Chatbots',
                    offset: 95,
                    message: 'Your intent was saved successfully.'
                })
            }).catch(err => {
                this.$notify.error({
                    title: 'Chatbots',
                    offset: 95,
                    message: 'Something went wrong while updating your intent.'
                })
                console.log(err)
            }).finally(() => {
                this.setLoading('renameIntent', false)
            })
        },

        /**
         * Delete intent
         *
         * @return {void}
         */
        deleteIntent() {
            this.setLoading('deleteIntent')

            axios.delete(`/api/v1/bots/builder/${this.chatbot.id}/intents/${this.edit.intent.id}`)
                .then(() => {
                    this.intents = this.intents.filter(item => item.id != this.edit.intent.id)

                    // Hide delete intent dialog.
                    this.modal.deleteIntent = false

                    this.$notify.success({
                        title: 'Chatbots',
                        offset: 95,
                        message: 'Your intent was deleted successfully.'
                    })
                }).catch(err => {
                    console.log(err)
                    this.$notify.error({
                        title: 'Chatbots',
                        offset: 95,
                        message: 'Something went wrong while deleting your intent.'
                    })
                }).finally(() => {
                    this.setLoading('deleteIntent', false)
                })
        },

        /**
         * Handles Add Intent button click event.
         *
         * @return {void}
         */
        onAddIntentClick() {
            this.clearSelectedIntent()
            this.modal.createIntent = true
        },

        /**
         * Handles Edit Intent button click event.
         *
         * @return {void}
         */
        onEditIntentClick(intent) {
            this.edit.intent.name = intent.name
            this.edit.intent.id = intent.id
            this.edit.intent.phrases = intent.phrases
            this.edit.phrases = intent.phrases
            // Shows edit intent dialog.
            this.modal.renameIntent = true
            this.disable_phrases_manager = true
        },

        /**
         * Handles Delete Intent button click event.
         *
         * @return {void}
         */
        onDeleteIntentClick(intent) {
            this.edit.intent.name = intent.name
            this.edit.intent.id = intent.id
            // Shows delete intent dialog.
            this.modal.deleteIntent = true
        },

        /**
         * Helper to clone any dialog.
         *
         * @return {void}
         */
        closeModal(modal) {
            if (modal == 'renameIntent') {
                this.disable_phrases_manager = false
            }
            this.modal[modal] = false
        },

        /**
         * Prevents the user from closing create form by mistake
         *
         * @return {void}
         */
        handleBeforeClose(done) {
            this.$confirm('Are you sure to close this dialog?')
            .then(_ => {
                done()
            })
            .catch(_ => {})
        },

        /**
         * Helper to extract a specific intent by id.
         *
         * @return {object}
         */
        getIntentById(id) {
            return this.intents.filter(intent => intent.id === id)[0]
        },

        /**
         * Unselects any previously selected intent.
         *
         * @return {void}
         */
        clearSelectedIntent() {
            this.edit.intent.name = ''
            this.edit.intent.id = null
            this.edit.intent.phrases = []
            this.edit.phrases = []
        },

        /**
         * Helper to set any loading state
         *
         * @return {void}
         */
        setLoading(type, value = true) {
            this.buttons.loading[type] = value
        },

        /**
         * Listens when an intent in opened in the Intents table.
         *
         * @return {void}
         */
        onExpandChange(event, expanded) {
            // Due to our current design we must prevent adding new intents while editing another one.
            this.disable_add_intent_button = true

            // Due to how we handle Training Phrases, we can only have 1 row expanded at the same time.
            if (expanded.length > 1) {
                // If there's already an existing expanded Intent, close it.
                this.$refs['chatbot-intent-manager-table'].toggleRowExpansion(expanded[0], false)
                // Set the new training phrases to be displayed
                this.expanded = expanded[1]
                return
            } else if (expanded.length == 1) {
                // Set the new training phrases to be displayed
                this.expanded = expanded[0]
            } else {
                // Enable Add Intent Button when row in unexpanded.
                this.disable_add_intent_button = false
            }

            this.edit.phrases = this.expanded ? this.expanded.phrases : null
        },

        /**
         * Listens create, update, or delete any training phrase.
         *
         * @return {void}
         */
        onTrainingPhraseUpdated() {
            let { id, name } = this.expanded
            this.updateTrainingPhrases(id, { name, phrases: this.edit.phrases })
        },

        /**
         * Updates intent training phrases.
         *
         * @return {void}
         */
        updateTrainingPhrases(intent_id, body) {
            axios.post(`/api/v1/bots/builder/${this.chatbot.id}/intents/${intent_id}`, body)
                .then(res => {
                    this.$notify.success({
                        title: 'Chatbots',
                        offset: 95,
                        message: 'Traning phrases updated successfully.'
                    })
                }).catch(err => {
                    this.$notify.error({
                        title: 'Chatbots',
                        offset: 95,
                        message: 'Training phrases could not be updated.'
                    })
                })
        }
    },
    watch: {
        /**
         * Watches if the Add Intent dialog is opened or closed.
         *
         * @return {void}
         */
        'modal.createIntent'(open) {
            if (open && this.edit.intent.name === '' && this.edit.intent.phrases.length === 0) {
                // Resets intent when closing the create intent dialog
                this.edit.intent = {
                    id: null,
                    name: '',
                    phrases: ['Example phrase']
                }
            }
        }
    },
    computed: {
        /**
         * Filters the Intents based on the Search input above the Intents table.
         *
         * @return {object[]}
         */
        filteredIntents() {
            // If search input is empty, return all intents.
            if (this.search == '') {
                return this.intents
            }

            return this.intents.filter(intent => {
                return intent.name.toLowerCase().includes(this.search.toLowerCase())
            })
        }
    }
}
</script>
