<template>
    <el-card :id="id" :class="[onlyHeader ? 'only-header' : '']">
        <template v-slot:header>
            <div class="d-flex">
                <div class="vertical-center mr-auto">
                    <span class="text-black font-weight-bold">
                        <slot name="header"/>
                    </span>
                </div>
                <div class="vertical-center ml-auto">
                    <div
                        v-if="!hide_edit_button"
                        class="text-dark text-muted hover-lower-opacity-warning d-inline"
                        @click="openSidePanel">
                        <i style="font-size:17px" class="fa fa-external-link mr-3" aria-hidden="true"/>
                    </div>
                    <div
                        v-if="!hide_close_button"
                        class="text-dark text-muted hover-lower-opacity-danger d-inline"
                        @click="removeNode">
                        <i style="font-size:17px" class="fa fa-trash-o" aria-hidden="true"/>
                    </div>
                </div>

            </div>
        </template>

        <div class="px-3 py-2">
            <slot></slot>
        </div>
    </el-card>
</template>

<script>
export default {
    props: {
        id: {
            required: false,
            default: ''
        },
        onlyHeader: {
            required: false,
            default: false
        },
        hide_close_button: {
            required: false,
            default: false
        },
        hide_edit_button: {
            required: false,
            default: false
        }
    },
    methods: {
        removeNode() {
            this.$confirm(`Are you sure you want to delete this node?`, 'Warning', {
                confirmButtonText: 'Yes, I\'m sure',
                cancelButtonText: 'No, I\'m not',
                type: 'warning',
                customClass: 'width-500 fixed'
            }).then(() => {
                if (typeof this.$parent.$parent.$parent.removeNode == 'function') {
                    return this.$parent.$parent.$parent.removeNode(this.node_id)
                }

                return this.$parent.$parent.$parent.$parent.removeNode(this.node_id)
            }).catch((err) => {
                console.log(err)
            })
        },
        openSidePanel() {
            this.$root.$emit('open-side-panel', this.node_id)
        }
    },
    computed: {
        node_id() {
            if (!this.$parent.id) {
                return this.$parent.$parent.id
            }

            return this.$parent.id
        }
    }
}
</script>