<template>
    <div v-if="layers && ring_group">
        <div v-for="layer in flattenedUsers">
            <div class="text-center py-2">
                <i class="fas fa-arrow-down text-xl text-muted"></i>
            </div>
            <div class="card m-2 mb-3">
                <table class="table table-sm m-0 table-routing-test">
                    <thead>
                        <tr>
                            <th colspan="4"
                                class="text-center">
                                <h6 class="mb-0">Layer {{ layer.layer }}</h6>
                            </th>
                        </tr>
                        <tr>
                            <th>Order</th>
                            <th>Status</th>
                            <th>User</th>
                            <th>Teams</th>
                            <th class="text-center">Can take calls?</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr :key="user_result.model.id"
                            v-for="user_result in layer.user_results">
                            <td class="td-order">
                                <span v-if="user_result.agent_is_eligible_to_take_call">{{ ring_group.dial_mode === RingGroupDialMode.DIAL_MODE_SIMUL ? 1 : layer.order++ }}</span>
                                <span v-else>-</span>
                            </td>
                            <td class="td-status pt-3 pr-0">
                                <span class="btn btn-xs text-white cursor-default btn-block"
                                      :class="[ $options.filters.agentStatusClass(1) ]"
                                      v-if="user_result.model.is_destination">
                                    Available
                                </span>
                                <span class="btn btn-xs text-white cursor-default btn-block"
                                      :class="[ $options.filters.agentStatusClass(user_result.model.agent_status) ]"
                                      v-else>
                                    {{ AgentStatusLabels.LABELS.find(label => label.value === user_result.model.agent_status).label }}
                                </span>
                            </td>
                            <td>
                                <el-collapse class="mb-0">
                                    <el-collapse-item class="mb-0"
                                                      name="1" >
                                        <template #title>
                                            <span class="mr-2">{{ user_result.model.full_name }}</span>
                                        </template>
                                        <div>
                                            <ul class="list-unstyled">
                                                <li :key="result.key"
                                                    v-for="result in user_result.results">
                                                    <i class="material-icons text-dark-greenish"
                                                       v-if="result.value">
                                                        check
                                                    </i>
                                                    <i class="material-icons text-danger"
                                                       v-else>
                                                        close
                                                    </i>
                                                    {{ result.label }}
                                                </li>
                                            </ul>
                                        </div>
                                    </el-collapse-item>
                                </el-collapse>
                            </td>
                            <td>
                                <span v-if="user_result.model.teams_name">
                                    <div class="mr-2"
                                         :key="team_name"
                                         v-for="(team_name, index) in user_result.model.teams_name">
                                        {{ team_name }}<span v-if="index < user_result.model.teams_name.length - 1">, </span>
                                    </div>
                                </span>
                            </td>
                            <td class="td-can-take-calls text-center">
                                <strong class="text-dark-greenish"
                                        v-if="user_result.agent_is_eligible_to_take_call">
                                    Yes
                                </strong>
                                <strong class="text-danger"
                                        v-else>
                                    No
                                </strong>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import auth from '../../auth'
import * as RingGroupDialMode from '../../constants/ring-group-dial-modes'
import * as AgentStatusLabels from '../../constants/agent-status-labels'
import { acl_mixin } from '../../mixins'

export default {
    name: "ring-group-routing-display",

    mixins: [acl_mixin],

    props: ['ring_group', 'layers'],

    data() {
        return {
            auth: auth,
            display_layers: [],
            AgentStatusLabels,
            RingGroupDialMode
        }
    },

    computed: {
        flattenedUsers() {
            const layers = this.display_layers.map(layer => {
                let user_results = []
                let seen_users = new Set()

                layer.user_results?.forEach(user_result => {
                    if (!seen_users.has(user_result.model.id)) {
                        user_results.push(user_result)
                        seen_users.add(user_result.model.id)
                    }
                })

                layer.team_users_results?.forEach((team) => {
                    if (team.users) {
                        team.users.forEach(user_result => {
                            if (!seen_users.has(user_result.model.id)) {
                                let modified_teams_name = []

                                this.ring_group.ordered_team_ids[layer?.layer]?.forEach(team_id => {
                                    this.ring_group?.teams?.forEach(team => {
                                        if (team_id === team.id && user_result.model?.teams_name?.includes(team.name) && !modified_teams_name.includes(team.name)) {
                                            modified_teams_name.push(team.name)
                                        }
                                    })
                                })

                                user_result.model.teams_name = modified_teams_name
                                user_results.push(user_result)
                                seen_users.add(user_result.model.id)
                            }
                        })
                    }
                })

                return {
                    layer: layer.layer,
                    user_results
                }
            })

            // sorting layers by layer value then by order
            layers.sort((a, b) => {
                return a.layer - b.layer
            })

            return layers
        }
    },

    created() {
        this.display_layers = this.layers
        this.display_layers.forEach(function (layer) {
            layer.order = 1
        })
    },

    watch: {
        layers() {
            this.display_layers = this.layers
            this.display_layers.forEach(function (layer) {
                layer.order = 1
            })
        }
    }
}
</script>
