<template>
    <div>
        <div v-if="shouldShowAlohabots"
             class="container-listing">
            <div class="container-header d-flex">
                <h3 class="listing-heading">AlohaBot</h3>
                <div class="ml-auto">
                    <div class="d-flex">
                        <el-popover v-if="!canAddAlohabot"
                                    ref="import-alohabot-popover"
                                    placement="top"
                                    width="200"
                                    class="custom-popover"
                                    popper-class="btn-primary"
                                    trigger="hover">
                            <custom-message-display :config="customMessage('alohabot')" />
                        </el-popover>
                        <el-button
                            type="info"
                            round
                            class="mr-3"
                            size="medium"
                            v-popover:import-alohabot-popover
                            :disabled="!canAddAlohabot"
                            @click="openImportChatbotForm()">
                            <i class="fa fa-download mr-1" aria-hidden="true"/>
                            <strong>Import Chatbot</strong>
                        </el-button>

                        <el-popover v-if="!canAddAlohabot"
                                    ref="add-alohabot-popover"
                                    placement="top"
                                    width="200"
                                    class="custom-popover"
                                    popper-class="btn-primary"
                                    trigger="hover">
                            <custom-message-display :config="customMessage('alohabot')" />
                        </el-popover>
                        <el-button
                            type="success"
                            round
                            class="mr-3"
                            size="medium"
                            v-popover:add-alohabot-popover
                            :disabled="!canAddAlohabot"
                            @click="openNewChatbotForm()">
                            <i class="fa fa-plus mr-1"/>
                            <strong>New Chatbot</strong>
                        </el-button>
                    </div>
                </div>
            </div>

            <el-tabs
                v-model="active_tab"
                class="pt-2 h-100"
                style="max-height: calc(100% - 4rem)"
                @tab-click="onTabClick">

                <el-tab-pane
                    name="chatbots"
                    label="Chatbots">

                    <ChatbotsTable
                        :chatbots="chatbots"
                        @chatbot-update="onChatbotUpdate"
                        @chatbot-delete="onChatbotDelete"
                        @chatbot-export="onChatbotExport"/>

                </el-tab-pane>

                <el-tab-pane
                    name="templates"
                    label="Templates">

                    <TemplatesTable
                        :templates="templates"
                        @template-update="onTemplateUpdate"
                        @template-delete="onTemplateDelete"/>

                </el-tab-pane>
            </el-tabs>

            <NewChatbotForm
                :form_visible="new_chatbot_form_visible"
                :lines="lines"
                @bot-created="onChatbotCreated"
                @bot-discarded="closeNewChatbotForm"/>

            <ImportTemplateForm
                :form_visible="import_chatbot_form_visible"
                :lines="lines"
                :templates="templates"
                @bot-imported="onChatbotImported"
                @bot-discarded="closeImportChatbotForm"/>
        </div>
        <upgrade-now-page image-link="/assets/images/alohabot-advertising.svg"
                          text="Engage customers and prospects round the clock with the power of our AI-powered conversational SMS bot"
                          extra-text="Contact your CSM today for early beta access to this feature."
                          title-text="AlohaBot"
                          button-text="Sign Up for Early Access"
                          kb-link="https://support.aloware.com/en/articles/9037495-alohabot"
                          class="mt-5"
                          v-if="!shouldShowAlohabots && shouldShowUpgradeNow"
                          :disabled="hasRequestedAlohabot"
                          :default-click="false"
                          @click="requestAlohabot()">
        </upgrade-now-page>
    </div>
</template>

<script>
import auth from '../../auth'
import ChatbotsTable from './chatbots-table'
import TemplatesTable from './templates-table'
import NewChatbotForm from './new-chatbot-form'
import ImportTemplateForm from './import-template-form'
import {acl_mixin, kyc_mixin} from '../../mixins'
import UpgradeNowPage from "../upgrade-now-page.vue"
import CustomMessageDisplay from '../kyc/custom-message-display'

export default {
    components: {
        UpgradeNowPage,
        ChatbotsTable,
        NewChatbotForm,
        TemplatesTable,
        ImportTemplateForm,
        CustomMessageDisplay
    },
    mixins: [acl_mixin, kyc_mixin],
    data() {
        return {
            auth: auth,
            new_chatbot_form_visible: false,
            import_chatbot_form_visible: false,
            chatbots: [],
            lines: [],
            templates: [],
            active_tab: 'chatbots'
        }
    },
    computed: {
        /**
         * Helper to know if this user has requested alohabot
         *
         * @return {boolean}
         */
        hasRequestedAlohabot() {
            return this.auth.user.profile.has_requested_alohabot
        },

        canAddAlohabot() {
            return this.enabledToAddAlohabots()
        }
    },
    methods: {
        /**
         * Fetches company's chatbots.
         *
         * @return {void}
         */
        fetchChatbots() {
            axios.get('/api/v1/bots/builder/bot').then(res => {
                this.chatbots = res.data.data
            }).catch(err => {
                console.log(err)
            })
        },

        /**
         * Fetches company's chatbot templates.
         *
         * @return {void}
         */
        fetchTemplates() {
            axios.get('/api/v1/bots/builder/template').then(res => {
                this.templates = res.data
            }).catch(err => {
                console.log(err)
            })
        },

        /**
         * Fetches company's lines to be selected for the chatbots.
         *
         * @return {void}
         */
        fetchLines() {
            axios.get('/api/v1/campaign').then(res => {
                this.lines = res.data
            }).catch(err => {
                console.log(err)
            })
        },

        /**
         * Makes the new chatbot form visible
         *
         * @return {void}
         */
        openNewChatbotForm() {
            this.new_chatbot_form_visible = true
        },

        /**
         * Makes the new chatbot form invisible
         *
         * @return {void}
         */
        closeNewChatbotForm() {
            this.new_chatbot_form_visible = false
        },

        /**
         * Makes the import chatbot form visible
         *
         * @return {void}
         */
        openImportChatbotForm() {
            this.import_chatbot_form_visible = true
        },

        /**
         * Makes the new import chatbot form invisible
         *
         * @return {void}
         */
        closeImportChatbotForm() {
            this.import_chatbot_form_visible = false
        },

        /**
         * Listens when a chatbot is created.
         *
         * @return {void}
         */
        onChatbotCreated() {
            this.fetchChatbots()
            this.closeNewChatbotForm()
        },

        /**
         * Listens when a chatbot is imported.
         *
         * @return {void}
         */
        onChatbotImported() {
            this.fetchChatbots()
            this.closeImportChatbotForm()
        },

        /**
         * Listens when a chatbot is updated.
         *
         * @return {void}
         */
        onChatbotUpdate() {
            this.fetchChatbots()
        },

        /**
         * Listens when a chatbot is deleted.
         *
         * @return {void}
         */
        onChatbotDelete() {
            this.fetchChatbots()
        },

        /**
         * Listens when a chatbot is exported (template is created).
         *
         * @return {void}
         */
        onChatbotExport() {
            this.fetchTemplates()
        },

        /**
         * Listens when a chatbot template is updated.
         *
         * @return {void}
         */
        onTemplateUpdate() {
            // For future implementation
        },

        /**
         * Listens when a chatbot is deleted.
         *
         * @return {void}
         */
        onTemplateDelete() {
            this.fetchTemplates()
        },

        /**
         * Chatbots/Templates Tab click listener.
         *
         * @param {string} tab
         *
         * @return {void}
         */
        onTabClick(tab) {
            // Changes route on tab click
            this.$router.replace({
                query: {
                    tab: tab.name
                }
            }).catch(err => {
                console.log(err)
            })
        },

        /**
         * Requests AlohaBot to Aloware Team
         *
         * @return {void}
         */
        requestAlohabot() {
            this.auth.user.profile.has_requested_alohabot = true
            axios.post(`/api/v1/bots/request`)
                .then((res) => {
                    // Notification when requested for the first time.
                    this.$notify({
                        offset: 95,
                        title: 'AlohaBot',
                        message: res.data.message,
                        type: 'success',
                    })
                }).catch((err) => {
                this.$notify({
                    offset: 95,
                    title: 'AlohaBot',
                    message: 'Whoops! Something went wrong.',
                    type: 'error',
                })
                this.auth.user.profile.has_requested_alohabot = false
                console.log(err)
            })
        }
    },
    mounted() {
        this.fetchTemplates()
        this.fetchChatbots()
        this.fetchLines()

        if (this.$route.query.tab === 'templates') {
            this.active_tab = 'templates'
        } else {
            this.active_tab = 'chatbots'
        }

        if (!this.shouldShowAlohabotMenu) {
            this.goBack()
        }
    },
    watch: {
        $route({params, query}) {
            // This logic allows us to be aware of url changes and load the proper content.
            if (query.tab === 'templates') {
                this.active_tab = 'templates'
            } else {
                this.active_tab = 'chatbots'
            }

            if (!this.shouldShowAlohabotMenu) {
                this.goBack()
            }
        }
    }
}
</script>
