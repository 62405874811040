<template>
    <div>
        <div class="row mb-1">
            <div class="col-6">
                <img height="30px" src="/assets/images/app-icons/alohabot-chat-bot.svg">
            </div>
            <div class="col-6 text-right">
                <img height="30px" style="margin-right: 5px" src="/assets/images/app-icons/alohabot-chat-contact.svg">
            </div>
        </div>
        <div class="d-flex flex-column">
            <div
                id="messages"
                class="flex-grow-1"
                style="overflow-y: scroll; overflow-x: clip; max-height: 600px; height: 400px;"
                @wheel="handleWheel">
                <ChatBubble
                    v-for="(communication, id) in communications"
                    v-bind:key="id"
                    :communication="communication"
                    :class="'mb-2'"/>
            </div>
            <div id="input"
                 style="border: 1px solid rgb(221, 221, 221); border-radius: 5px;"
                 class="p-1">

                <div class="messenger-input">
                    <textarea id="message-input"
                              name="message"
                              placeholder="Write a message"
                              maxlength="1600"
                              class="message-input w-100 p-1"
                              spellcheck="false"
                              v-model="input"
                              @keydown="handleKeyDown"/>
                </div>

                <div class="container-fluid">
                    <div class="row">
                        <el-button
                            class="ml-auto"
                            type="success"
                            size="small"
                            :disabled="input == ''"
                            @click="sendMessage()">
                            Send
                        </el-button>

                        <el-button
                            type="warning"
                            size="small"
                            :disabled="communications.length !== 0"
                            :loading="start_loading"
                            @click="sendStartMessage">
                            Start
                        </el-button>

                        <el-button
                            type="primary"
                            size="small"
                            :loading="reset_loading"
                            @click="sendResetMessage(true)">
                            Reset
                        </el-button>
                    </div>
                </div>
            </div>

            <div
                id="contact"
                class="mt-2">
                <contact-select
                    id="contact-select"
                    class="px-1"
                    v-model="selected_contact"
                    label="Search contact"
                    @select-contacts="onSelectContact"
                    :contact_selected="selected_contact"/>
            </div>
        </div>
    </div>
</template>

<script>
import * as CommunicationDirection from '../../constants/communication-direction'
import ChatBubble from './chatbox/chat-bubble.vue'
import ContactSelect from './chatbox-contact-select.vue'
import auth from '../../auth.js'
import _ from 'lodash'

export default {
    components: {
        ChatBubble,
        ContactSelect
    },
    props: {
        bot: {
            required: true
        }
    },
    data() {
        return {
            selected_contact: null,
            input: '',
            communications: [],
            bot_id: this.$route.params.bot_id,
            user: auth.user.profile,
            source: null,
            reset_loading: false,
            start_loading: false,
        }
    },
    created() {
        this.CancelToken = axios.CancelToken
        this.source = this.CancelToken.source()
    },
    mounted() {
        this.scrollMessagesDown()
        this.initDefaultContact()
    },
    methods: {
        /**
         * Initializes default contact
         *
         * @return {void}
         */
        initDefaultContact() {
            if (this.bot.default_contact_id != null) {
                this.fetchContactById(this.bot.default_contact_id)
                return
            }
            if (this.user.phone_number != null) {
                this.fetchContactByPhoneNumber(this.user.phone_number)
                return
            }
        },

        /**
         * Helper to scroll messages down
         *
         * @return {void}
         */
        scrollMessagesDown() {
            let messagesDiv = document.getElementById("messages")
            messagesDiv.scrollTop = messagesDiv.scrollHeight
        },

        /**
         * Transforms the bot messages to be displayed in the UI
         *
         * @return {array}
         */
        transformBotResponse(response) {
            let comms = []

            for (let message of response.messages) {
                comms.push({
                    direction: CommunicationDirection.INBOUND,
                    body: message,
                    contact: {name: 'Bot'}
                })
            }

            return comms
        },

        /**
         * Sends a message to test the chatbot.
         *
         * @return {void}
         */
        sendMessage(start_message = null, should_append_to_chat = true) {
            if (!this.selected_contact) {
                this.$notify({
                    type: 'error',
                    title: 'Contact is missing',
                    message: 'Please select a contact.',
                    showClose: true,
                })
                return
            }

            let req_body = this.input
            if (start_message) {
                req_body = start_message
            }

            let comm = {
                direction: CommunicationDirection.OUTBOUND,
                body: req_body,
                contact: {name: 'You'}
            }

            if (should_append_to_chat) {
                this.communications.push(comm)
            }
            setTimeout(() => {
                this.scrollMessagesDown()
            }, 300)
            this.input = ''

            let uri = '/api/v1/bots/' + this.bot_id + '/chat'
            let body = {
                body: comm.body
            }

            if (this.selected_contact) {
                uri += '/' + this.selected_contact.id
            }

            axios.post(uri, body).then(({data}) => {
                let response = this.transformBotResponse(data)

                this.communications.push(...response)
                if (start_message) {
                    this.start_loading = false
                }
                setTimeout(() => {
                    this.scrollMessagesDown()
                }, 300)
            }).catch((err) => {
                if (start_message) {
                    this.start_loading = false
                }
                console.log(err)
            })
        },

        sendStartMessage() {
            if (!this.selected_contact) {
                this.$notify({
                    type: 'error',
                    title: 'Contact is missing',
                    message: 'Please select a contact.',
                    showClose: true,
                })
                return
            }

            this.start_loading = true

            this.sendMessage('start', false)
        },

        /**
         * Resets bot and conversation.
         *
         * @return {void}
         */
        sendResetMessage(loading = true) {
            this.reset_loading = loading

            let uri = '/api/v1/bots/' + this.bot_id + '/chat'
            let body = {
                body: 'reset'
            }

            if (this.selected_contact) {
                uri += '/' + this.selected_contact.id
            }

            axios.post(uri, body)
                .then(({data}) => {
                    this.communications = []
                    this.reset_loading = false
                }).catch((err) => {
                this.reset_loading = false
                console.log(err)
            })
        },

        /**
         * Send using enter key.
         *
         * @return {void}
         */
        handleKeyDown(event) {
            if (event.key === 'Enter') {
                event.preventDefault()
                this.sendMessage()
            }
        },

        /**
         * Helper to scroll the messages up.
         *
         * @return {void}
         */
        handleWheel(event) {
            let messagesDiv = document.getElementById("messages")
            messagesDiv.scrollTop = messagesDiv.scrollTop - event.wheelDelta
        },

        /**
         * Listens event to select a contact.
         *
         * @param {integer} contact_id
         *
         * @return {void}
         */
        onSelectContact(contact_id) {
            this.selected_contact = contact_id
        },

        /**
         * Fetches contact by phone number.
         *
         * @param {string} string
         *
         * @return {void}
         */
        fetchContactByPhoneNumber(query) {
            this.source = this.CancelToken.source()
            this.loading = true

            const params = {
                search_fields: ['phone_number'],
                search_text: query
            }

            axios.get('/api/v1/contact', {
                params: params,
                cancelToken: this.source.token
            }).then(res => {
                this.selected_contact = res.data.data[0]
            }).catch(err => {
                if (!axios.isCancel(err)) {
                    this.$root.handleErrors(err.response)
                }
            })
        },

        /**
         * Fetches contact by id.
         *
         * @param {integer} id
         *
         * @return {void}
         */
        fetchContactById(id) {
            axios.get('/api/v1/contact/' + id)
                .then(res => {
                    let {data} = res
                    this.selected_contact = data
                }).catch(err => {
                if (!axios.isCancel(err)) {
                    this.$root.handleErrors(err.response)
                }
            })
        }
    }
}
</script>
