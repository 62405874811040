<template>
    <el-popover title="Templates"
                width="500"
                v-if="hasPermissionTo('list sms template')">
        <div class="dialog-padding auto overflow-y-scroll"
             style="max-height: 500px;">
            <template-list template_scope="user"
                           mode="return"
                           @template-selected="emitSelectedTemplate">
            </template-list>
            <template-list template_scope="company"
                           mode="return"
                           @template-selected="emitSelectedTemplate">
            </template-list>
        </div>
        <el-button  id="template-selector-trigger"
                    slot="reference"
                    class="border-0 bg-transparent"
                    size="mini"
                    circle
                    :disabled="disabled">
            <i class="fa fa-file-code-o"></i>
        </el-button>
    </el-popover>
</template>

<script>
import auth from '../../auth'
import {acl_mixin} from "../../mixins"
import TemplateDialog from "../template-dialog"
import TemplateList from "../template-list"

export default {
    components: {TemplateList, TemplateDialog},

    mixins: [acl_mixin],

    props: {
        short: {
            type: Boolean,
            default: false
        },

        disabled: {
            type: Boolean,
            default: false
        },

        text_button: {
            type: Boolean,
            default: false
        },

        btn_class: {
            type: String,
            default: ''
        }
    },

    data() {
        return {
            auth: auth,
            dialogVisible: false,
        }
    },

    computed: {
        isLargeEnough() {
            let valid_sizes = ['xxl', 'xl', 'lg', 'md']
            if (valid_sizes.includes(this.$mq)) {
                return true
            }
            return false
        }
    },

    methods: {
        emitSelectedTemplate(template) {
            this.$emit('template-selected', template)
            this.$el.querySelector('#template-selector-trigger').click()
        }
    }
}
</script>
