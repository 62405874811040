<template>
    <div class="pl-3 pr-3">
        <div class="row mb-2">
            <div class="col-6">
                <h4 class="m-a-0 text-lg mb-0">
                    <span>{{ summary.inbound_calls_count | numFormat  }}</span>
                </h4>
                <small class="text-muted text-mini">Inbound Calls</small>
            </div>
            <div class="col-6">
                <h4 class="m-a-0 text-lg mb-0">
                    <span>{{ summary.outbound_calls_count | numFormat }}</span>
                </h4>
                <small class="text-muted text-mini">Outbound Calls</small>
            </div>
        </div>

        <div class="row mb-2">
            <div class="col-6">
                <h4 class="m-a-0 text-lg mb-0">
                    <span>{{ summary.inbound_texts_count | numFormat  }}</span>
                </h4>
                <small class="text-muted text-mini">Inbound Texts</small>
            </div>
            <div class="col-6">
                <h4 class="m-a-0 text-lg mb-0">
                    <span>{{ summary.outbound_texts_count | numFormat  }}</span>
                </h4>
                <small class="text-muted text-mini">Outbound Texts</small>
            </div>
        </div>

        <div class="row">
            <div class="col-12">
                <h4 class="m-a-0 text-lg mb-0">
                    <span>{{ summary.total_count | numFormat  }}</span>
                </h4>
                <small class="text-muted text-mini">All Communications</small>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "contact-communications-summary",
        props: {
            summary: {
                type: Object,
                required: true
            }
        }
    }
</script>

<style scoped>

</style>
