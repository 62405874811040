<template>
    <div class="row no-gutter"
         v-if="contact && contact.broadcasts && contact.broadcasts.length > 0 && hasPermissionTo('list broadcast')">
        <div class="text-center mr-2 mb-2"
             v-for="broadcast in contact.broadcasts">
            <router-link :to="{ name: 'Broadcast'}">
                <a class="btn btn-xs bluish" 
                   data-testid="contact-broadcasts-link">
                    {{ broadcast.name }}
                </a>
            </router-link>
        </div>
    </div>
</template>

<script>
import auth from '../auth'
import {acl_mixin} from '../mixins'

export default {
    mixins: [acl_mixin],

    props: {
        contact: {
            required: true
        }
    },

    data() {
        return {
            auth: auth,
        }
    },
}
</script>
