var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "multiple fallback" },
    [
      _vm.showSuccess
        ? _c("div", { staticClass: "panel" }, [
            _vm._v("\n        Success\n        "),
            _vm._v(" "),
            _c("div", { class: `link output_${_vm.start_id} output_success` }),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm._l(_vm.actualFallbacks, function (fallback, id) {
        return _vm.fallbacks.length > 0
          ? _c("div", { key: id, staticClass: "panel" }, [
              _vm._v(
                "\n\n        Error " +
                  _vm._s(_vm.getErrorComplement(fallback.type)) +
                  "\n        "
              ),
              _c("div", {
                class: `link output_${
                  _vm.showSuccess ? _vm.start_id + id + 1 : _vm.start_id + id
                } output_fallback output_fallback_${fallback.type}`,
              }),
            ])
          : _vm._e()
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }