<template>
  <el-form class="text-left" @submit.prevent.native="() => false"> <!-- changing from AM to PM in v-date-picker submits the form -->
    <!-- Start Direction -->
    <el-form-item class="mb-2">
      <div scope="label"
           class="form-label mb-1">
        <label>Push contacts to</label>
      </div>
      <div class="row">
        <div class="col-6">
          <el-radio v-model="model.direction"
                    :label="DIRECTION_BOTTOM"
                    :value="DIRECTION_BOTTOM"
                    class="w-full"
                    border
                    size="medium">
            Bottom
          </el-radio>
        </div>
        <div class="col-6">
          <el-radio v-model="model.direction"
                    :label="DIRECTION_TOP"
                    :value="DIRECTION_TOP"
                    class="w-full"
                    border
                    size="medium">
            Top
          </el-radio>
        </div>
      </div>
    </el-form-item>
    <!-- End Direction -->
    <!-- Start Allow Multiple Phone Numbers -->
    <el-form-item class="mb-2">
      <div scope="label"
           class="form-label">
        <el-checkbox v-model="model.multiple_phone_numbers"
                     label="Add multiple phone numbers?"
                     class="w-full">
        </el-checkbox>
      </div>
    </el-form-item>
    <!-- End Allow Multiple Phone Numbers -->
    <!-- Start Prevent Duplicates -->
    <el-form-item class="mb-2">
      <div scope="label"
           class="form-label">
        <el-checkbox v-model="model.prevent_duplicates"
                     label="Prevent duplicate task creation"
                     class="w-full">
        </el-checkbox>
      </div>
    </el-form-item>
    <!-- End Prevent Duplicates -->
    <!-- Start Add Own Contacts -->
    <el-form-item class="mb-2">
      <div scope="label"
           class="form-label">
        <el-checkbox v-model="model.own_contacts_only"
                     label="Add your own contacts?"
                     class="w-full">
        </el-checkbox>
      </div>
    </el-form-item>
    <!-- End Add Own Contacts -->
    <el-form-item class="mb-2">
      <div scope="label"
           class="form-label">
        <el-checkbox v-model="model.future_scheduled"
                     label="Future scheduled task?"
                     class="w-full">
        </el-checkbox>
      </div>
    </el-form-item>
    <!-- Start Schedule -->
    <el-form-item label="Scheduled time:"
                  v-if="model.future_scheduled">
      <v-date-picker v-model="model.future_scheduled_time"
                     mode="dateTime"
                     title-position="left"
                     color="green"
                     :min-date="new Date()"
                     :masks="masks"
                     :popover="popover_config">
        <template v-slot="{ inputValue, inputEvents }">
          <input
              class="px-2 py-1 border rounded"
              :value="inputValue"
              v-on="inputEvents"
          />
        </template>
      </v-date-picker>
    </el-form-item>
    <!-- End Schedule -->
    <!-- Start Add Internation Numbers -->
    <el-form-item class="mb-2">
      <div scope="label"
           :class="`form-label ${!hasInternationalAccess ? 'd-inline-flex' : ''}`">
        <el-checkbox v-model="model.allow_international_phone_numbers"
                     :disabled="!hasInternationalAccess"
                     label="Allow international phone numbers"
                     class="w-full">
        </el-checkbox>
        <el-tooltip v-if="!hasInternationalAccess"
                    class="item ml-2"
                    effect="dark"
                    content="This account has no international access"
                    placement="top">
          <i class="fas fa-info-circle"></i>
        </el-tooltip>
      </div>
    </el-form-item>
    <!-- End Add International Numbers -->
  </el-form>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import { DIRECTION_BOTTOM, DIRECTION_TOP } from '../../../constants/power-dialer'
import * as International from '../../../constants/international-tier'
import DateSelector from '../../date-selector'

export default {
  name: 'pipedrive-add-to-power-dialer',
  components: { DateSelector },
  data: () => ({
    // UI
    DIRECTION_TOP,
    DIRECTION_BOTTOM,
    masks: {
      input: 'MM/DD/YYYY HH:mm'
    },
    popover_config: {
      placement: 'right'
    },

    // payload
    model: {
      multiple_phone_numbers: false,
      prevent_duplicates: true,
      allow_international_phone_numbers: false,
      own_contacts_only: false,
      direction: DIRECTION_BOTTOM,
      future_scheduled: false,
      future_scheduled_time: new Date()
    }
  }),
  computed: {
    ...mapGetters(['getPowerDialerFormInputs', 'cache/getCurrentCompany']),
    hasInternationalAccess() {
      return !!(this['cache/getCurrentCompany'] && [International.TIER_2, International.TIER_3, International.TIER_4].includes(this['cache/getCurrentCompany'].international_tier))
    }
  },
  methods: {
    ...mapActions(['updatePowerDialerFormInputs']),
    handleUpdatePowerDialerFormInputs() {
      this.updatePowerDialerFormInputs(this.model)
    }
  }
}
</script>
