<template>
    <div>
        <el-table
            :data="templatesData"
            style="width: 100%">

            <el-table-column
                align="left"
                label="Workflow"
                :min-width="15">
                <template v-slot="scope">
                    <div>
                        <p class="mb-0 font-weight-bold">
                            {{ scope.row.workflow }}
                        </p>
                        <span>#{{ scope.row.id }}</span>
                    </div>
                </template>
            </el-table-column>

            <el-table-column
                align="left"
                label="Created by"
                :min-width="10">
                <template v-slot="scope">
                    <div v-if="scope.row.user">
                        <router-link v-if="!scope.row.user.deleted_at" :to="{ name: 'User Dialog', params: { user_id: scope.row.user.id }}">
                            <div class="hover-lower-opacity">
                                <p class="mb-0">
                                    <span class="font-weight-bold">{{ scope.row.user.name }}</span>
                                </p>
                                <span>#{{ scope.row.user.id }}</span>
                            </div>
                        </router-link>
                        <div v-else>
                            <div class="hover-lower-opacity">
                                <p class="mb-0">
                                    <span class="font-weight-bold">{{ scope.row.user.name }}</span>
                                </p>
                                <span>#{{ scope.row.user.id }}</span>
                            </div>
                        </div>
                    </div>
                    <div v-else>
                        <span>-</span>
                    </div>
                </template>
            </el-table-column>

            <el-table-column
                align="center"
                label="Version"
                :min-width="10">
                <template v-slot="scope">
                    <b>{{ scope.row.version }}</b>
                </template>
            </el-table-column>

            <el-table-column
                align="center"
                label="Notes"
                :min-width="10">
                <template v-slot="scope">
                    <TemplateNotes :template="scope.row"/>
                </template>
            </el-table-column>

            <el-table-column
                align="center"
                :min-width="10">
                <template v-slot="scope">
                    <div
                        class="hover-lower-opacity-danger ml-auto"
                        :disabled="shouldDisableDeleteButton(scope.row)"
                        @click="handleDelete(scope.row)">
                        <i style="font-size:17px" class="fa fa-trash-o mr-1" aria-hidden="true"/>
                        Delete
                    </div>
                </template>
            </el-table-column>
        </el-table>

        <DeleteTemplate
            :form_visible="delete_template_visible"
            :template="template"
            @submit="onDeleteSubmit"
            @discard="closeDeleteTemplate"/>
    </div>
</template>

<script>
import DeleteTemplate from './delete-template.vue'
import TemplateNotes from './template-notes.vue'
import {acl_mixin} from '../../mixins'

export default {
    mixins: [acl_mixin],
    components: {
        TemplateNotes,
        DeleteTemplate
    },
    props: {
        templates: {
            required: false,
            default: []
        }
    },
    data() {
        return {
            template: null,
            delete_template_visible: false,
            search: '',
            filters: {
                page: 1,
                size: 5,
            },
            count: 0
        }
    },
    methods: {
        /**
         * Listens when a tempate note is deleted.
         *
         * @return {void}
         */
        onDeleteSubmit() {
            this.$emit('template-delete')
            this.closeDeleteTemplate()
        },

        /**
         * Handles any logic for pressing the Delete button in the table.
         * @param {object} row
         *
         * @return {void}
         */
        handleDelete(row) {
            // Each row is a chatbot template.
            this.template = row
            this.openDeleteTemplate()
        },

        /**
         * Makes the delete chatbot template form visible
         *
         * @return {void}
         */
        openDeleteTemplate() {
            this.delete_template_visible = true
        },

        /**
         * Makes the chatbot template form invisible
         *
         * @return {void}
         */
        closeDeleteTemplate() {
            this.delete_template_visible = false
        },

        /**
         * Disables and enables the Delete Template button.
         *
         * @param {object} template
         *
         * @return {boolean}
         */
        shouldDisableDeleteButton(template) {
            // We must force boolean here, not 0 or 1. Otherwise, :disabled propertry complains.
            return (template.is_public && this.current_company.id !== template.company_id) ? true : false
        }
    },
    computed: {
        /**
         * Filters the templates based on the search input. If the search input is empty,
         * it returns all templates. If there's a search input, it returns only the
         * templates whose 'workflow' property contains the search input (case-insensitive).
         *
         * @return {object[]} - An array of filtered templates based on the search input.
         */
        templatesData() {
            if(!this.templates) {
                return this.templates
            }
            return this.templates.filter(data => !this.search || data.workflow.toLowerCase().includes(this.search.toLowerCase()))
        }
    }
}
</script>
