<template>
    <div class="pl-3 pr-3"
         v-if="contact">
        <add-contact-phone-number class="mb-2"
                                  v-if="hasPermissionTo('update contact')"
                                  :contact="contact"
                                  @success="addPhoneNumber">
        </add-contact-phone-number>

        <div v-for="phone_number in phone_numbers"
             class="d-flex flex-row justify-content-between align-items-center mb-3"
             :key="phone_number.id">
            <div class="d-flex flex-column align-items-start">
                <div class="md-form-group pb-2"
                     v-if="phone_number.phone_number">
                    <input class="md-input"
                           :disabled="true"
                           data-testid="phone-number-input"
                           :value="phone_number.phone_number | fixPhone">
                    <label>{{ phone_number.title | fixName }}</label>
                </div>

                <div class="d-flex flex-row">
                    <span class="label p-1 text-xxs success mr-2"
                          data-testid="primary-phone-number"
                          v-if="phone_number.is_primary">
                        Primary
                    </span>
                    <span class="label p-1 text-xxs light-blue mr-2"
                          data-testid="lrn-type-phone-number"
                          v-if="phone_number.lrn_type !== undefined">
                        {{ phone_number.lrn_type | fixLrnType }}
                    </span>

                    <span class="label p-1 text-xxs red-A400 mr-2"
                          data-testid="wrong-phone-number"
                          v-if="phone_number.is_wrong_number">
                        Wrong Number
                    </span>

                    <span class="label p-1 text-xxs red-A400"
                          data-testid="invalid-phone-number"
                          v-if="phone_number.is_invalid">
                        Invalid Number
                    </span>

                    <span class="label p-1 text-xxs red-A400"
                          data-testid="opt-out-phone-number"
                          v-if="phone_number.is_opted_out">
                        Opt-Out
                    </span>

                    <span class="label p-1 text-xxs has_conflict_color_background"
                          data-testid="has-conflicts-phone-number"
                          v-if="phone_number?.conflicted_contacts?.length > 0">
                        Has Conflicts
                    </span>
                </div>
            </div>
            <div class="d-flex position-absolute right-0">
                <contact-phone-number-duplicates
                    :phone_number="phone_number"
                    v-if="phone_number?.conflicted_contacts?.length > 0"/>
                <edit-contact-phone-number
                    data-testid="edit-contact-phone-number"
                    v-if="hasPermissionTo('update contact')"
                    @success="updatePhoneNumber"
                    :contact="contact"
                    :phone_number="phone_number">
                </edit-contact-phone-number>

                <el-popover
                    placement="top"
                    width="300"
                    trigger="hover"
                    popper-class="p-2"
                    :disabled="!isNumberInvalidOrWrong(phone_number)">
                    <span class="text-black-dk">
                        {{ getTooltipMessage(phone_number) }}
                    </span>
                    <span slot="reference">
                        <button
                            data-testid="make-a-call-button"
                            v-if="hasPermissionTo('update contact')"
                            @click.prevent="callContact($event, contact, phone_number.phone_number)"
                            :disabled="call_loading === phone_number.id || contact.is_dnc || isNumberInvalidOrWrong(phone_number)"
                            class="btn btn-sm btn-icon btn-outline-success w-30 d-flex justify-content-center align-items-center ml-1">
                            <i v-if="call_loading === phone_number.id"
                               class="fa fa-spinner fa-spin">
                            </i>
                            <i v-if="!call_loading || call_loading !== phone_number.id"
                               class="fa fa-phone">
                            </i>
                        </button>
                    </span>
                </el-popover>

                <el-popover
                    placement="top"
                    width="300"
                    trigger="hover"
                    popper-class="p-2"
                    :disabled="!isNumberInvalidOrWrong(phone_number)  && !isOptedOut(phone_number)">
                    <span class="text-black-dk">
                        {{ getTooltipMessage(phone_number) }}
                    </span>

                    <span slot="reference">
                        <button
                            data-testid="update-contact-comment-button"
                            v-if="hasPermissionTo('update contact')"
                            @click.prevent="gotoMessages($event, phone_number.phone_number)"
                            :disabled="contact.is_dnc || isNumberInvalidOrWrong(phone_number) || isOptedOut(phone_number)"
                            class="btn btn-sm btn-icon btn-outline-info w-30 d-flex justify-content-center align-items-center ml-1">
                            <i class="fa fa-comment"></i>
                        </button>
                    </span>
                </el-popover>

                <button class="btn btn-sm btn-icon btn-outline-danger w-30 d-flex justify-content-center align-items-center ml-1"
                        data-testid="archive-contact-action-button"
                        v-if="hasPermissionTo('archive contact') && contact.phone_number != phone_number.phone_number"
                        @click.prevent="deletePhoneNumber($event, contact, phone_number)">
                    <i class="fa fa-spinner fa-spin"
                       v-if="loading_delete && loading_delete == phone_number.id"></i>
                    <i class="fa fa-trash"
                       v-else></i>
                </button>
            </div>
        </div>

        <div v-for="phone_number in conflicted_phone_numbers"
             class="d-flex flex-row justify-content-between align-items-center mb-3"
             :key="`${phone_number.id}-conflicted`">
            <div class="d-flex flex-column align-items-start">
                <div class="md-form-group pb-2"
                     v-if="phone_number.phone_number">
                    <input class="md-input"
                           :disabled="true"
                           data-testid="phone-number-input"
                           :value="phone_number.phone_number | fixPhone">
                    <label>{{ phone_number.title | fixName }}</label>
                </div>

                <div class="d-flex flex-row">
                    <span class="label p-1 text-xxs light-blue mr-2"
                          data-testid="lrn-type-phone-number"
                          v-if="phone_number.lrn_type !== undefined">
                        {{ phone_number.lrn_type | fixLrnType }}
                    </span>

                    <span class="label p-1 text-xxs red-A400 mr-2"
                          data-testid="wrong-phone-number"
                          v-if="phone_number.is_wrong_number">
                        Wrong Number
                    </span>

                    <span class="label p-1 text-xxs red-A400"
                          data-testid="invalid-phone-number"
                          v-if="phone_number.is_invalid">
                        Invalid Number
                    </span>

                    <span class="label p-1 text-xxs red-A400"
                          data-testid="opt-out-phone-number"
                          v-if="phone_number.is_opted_out">
                        Opt-Out
                    </span>

                    <span class="label p-1 text-xxs has_conflict_color_background"
                          data-testid="has-conflicts-phone-number">
                        Conflicted
                    </span>
                </div>
            </div>
            <div class="d-flex position-absolute right-0">
                <contact-phone-number-duplicates
                    :phone_number="phone_number"/>
            </div>
        </div>
    </div>
</template>

<script>
    import cloneDeep from "lodash/cloneDeep"
    import auth from '../auth'
    import {mapState} from 'vuex'
    import AddContactPhoneNumber from './add-contact-phone-number'
    import EditContactPhoneNumber from './edit-contact-phone-number'
    import {acl_mixin, contact_phone_number_mixin} from '../mixins'
    import ContactPhoneNumberDuplicates from "./contact-phone-number-duplicates";

    export default {
        mixins: [acl_mixin, contact_phone_number_mixin],

        props: {
            contact: {
                required: true
            }
        },

        components: {
            ContactPhoneNumberDuplicates,
            AddContactPhoneNumber,
            EditContactPhoneNumber
        },

        data() {
            return {
                auth: auth,
                phone_numbers: [],
                conflicted_phone_numbers: [],
                loading_contact_phone_numbers: false,
                loading_contact_conflicted_phone_numbers: false,
                loading_delete: null,
                call_loading: null
            }
        },

        computed: {
            ...mapState('cache', ['current_company']),
        },

        mounted() {
            this.getPhoneNumbers()
            this.getConflictedPhoneNumbers()
        },

        methods: {
            callContact($event, contact, phone_number) {
                $event.target.blur()
                // check contact has timezone or not
                if (contact.timezone) {
                    // if have timezone check is it day time?
                    const start_day = moment().tz(contact.timezone).hour(8).minute(0).second(0)
                    const end_day = moment().tz(contact.timezone).hour(18).minute(0).second(0)
                    const contact_local_time = moment().tz(contact.timezone)
                    if (!contact_local_time.isBetween(start_day, end_day)) {
                        return this.$confirm(`This is outside the lead's day time. Do you want to make a call? It's ${contact_local_time.format('hh:mm A')} for ${contact.name}.`, 'Call Lead', {
                            confirmButtonText: 'OK',
                            cancelButtonText: 'Cancel',
                            customClass: 'width-500 fixed',
                            type: 'warning'
                        }).then(() => {
                            this.makeCall(contact, phone_number)
                        }).catch(() => {
                        })
                    }
                }

                this.makeCall(contact, phone_number)
            },

            makeCall(contact, phone_number) {
                if (this.auth.user.profile.enabled_two_legged_outbound) {
                    let message = 'We will call your secondary phone'
                    message += ' on ' + this.auth.user.profile.secondary_phone_number
                    message += ` and connect you with ${contact.name}. Proceed?`
                    return this.$confirm(message, 'Going old school?', {
                        confirmButtonText: 'OK',
                        cancelButtonText: 'Cancel',
                        customClass: 'width-500 fixed',
                        type: 'warning'
                    }).then(() => {
                        this.makeTwoLeggedCall(contact, phone_number)
                    }).catch(() => {
                    })
                }

                // make regular call
                VueEvent.fire('make_new_call', {
                    phone_number: phone_number
                })
            },

            makeTwoLeggedCall(contact, phone_number) {
                this.call_loading = contact.id
                axios.post('/api/v1/contact/' + contact.id + '/make-two-legged-call', {
                    phone_number: phone_number
                }).then(res => {
                    this.$notify({
                        offset: 95,
                        title: 'Call Lead',
                        message: `We are calling your phone to connect you to ${contact.name}`,
                        type: 'success',
                        showClose: true
                    })
                    this.call_loading = null
                }).catch(err => {
                    this.$root.handleErrors(err.response)
                    this.call_loading = null
                })
            },

            gotoMessages($event, phone_number) {
                $event.target.blur()
                this.$emit('message', phone_number)
            },

            deletePhoneNumber($event, contact, phone_number) {
                $event.target.blur()
                this.$confirm('Are you sure you want to delete this phone number?', 'Warning', {
                    confirmButtonText: 'Yes, Remove',
                    cancelButtonText: 'No, Keep',
                    customClass: 'width-500 fixed',
                    type: 'warning',
                }).then(() => {
                    this.loading_delete = phone_number.id
                    axios.delete('/api/v1/contact/' + contact.id + '/phone-number/' + phone_number.id).then(res => {
                        this.$notify({
                            offset: 95,
                            title: 'Contact',
                            message: 'Phone number has been deleted successfully.',
                            type: 'success',
                            showClose: true
                        })
                        this.loading_delete = null
                        this.phone_numbers = this.phone_numbers.filter(pn => pn.id != phone_number.id)
                    }).catch(err => {
                        this.$root.handleErrors(err.response)
                        this.loading_delete = null
                    })
                }).catch(() => {
                })
            },

            addPhoneNumber(phone_number) {
                this.phone_numbers.push(phone_number)
            },

            updatePhoneNumber(phone_number) {
                let found = this.phone_numbers.find(o => o.id === phone_number.id)
                if (found) {
                    // we will update the contact's phone_number if primary number is updated
                    if (phone_number.is_primary) {
                        // set first all phone numbers that is not a primary number to to false
                        this.phone_numbers.map(pn => {
                            if (pn.id !== phone_number.id) {
                                pn.is_primary = false
                            }
                        })

                        this.$emit('primary-updated', phone_number)
                    }

                    Vue.set(this.phone_numbers, this.phone_numbers.indexOf(found), phone_number)
                }
            },
            setPhoneNumbers(phone_numbers) {
                this.phone_numbers = cloneDeep(phone_numbers)
            },
            getPhoneNumbers() {
                if (this.contact && this.contact.id) {
                    this.loading_contact_phone_numbers = true
                    axios.get('/api/v1/contact/' + this.contact.id + '/phone-numbers').then(res => {
                        this.loading_contact_phone_numbers = false
                        this.phone_numbers = res.data
                    }).catch(err => {
                        this.$root.handleErrors(err.response)
                        this.loading_contact_phone_numbers = false
                        console.log(err)
                    })
                }
            },
            getConflictedPhoneNumbers() {
                if (this.contact && this.contact.id) {
                    this.loading_contact_conflicted_phone_numbers = true
                    axios.get('/api/v1/contact/' + this.contact.id + '/conflicted-phone-numbers').then(res => {
                        this.loading_contact_conflicted_phone_numbers= false
                        this.conflicted_phone_numbers = res.data
                    }).catch(err => {
                        this.$root.handleErrors(err.response)
                        this.loading_contact_conflicted_phone_numbers = false
                        console.log(err)
                    })
                }
            }
        },

        watch: {
            contact() {
                this.getPhoneNumbers()
                this.getConflictedPhoneNumbers()
            },

            phone_numbers() {
                this.$emit('updated', this.phone_numbers)
            }
        }
    }
</script>
