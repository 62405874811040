var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-popover",
    {
      attrs: {
        id: "contacts-filters-popover",
        placement: "left-start",
        width: "500",
        trigger: "manual",
      },
      on: { hide: _vm.resetForms },
      model: {
        value: _vm.popover_visibility,
        callback: function ($$v) {
          _vm.popover_visibility = $$v
        },
        expression: "popover_visibility",
      },
    },
    [
      _c(
        "div",
        { staticClass: "power-dialer-filter-close-button" },
        [
          _c(
            "el-button",
            {
              staticClass: "p-0 text-black-50",
              attrs: { type: "text" },
              on: { click: _vm.resetForms },
            },
            [_c("i", { staticClass: "fas fa-times" })]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-tabs",
        {
          staticClass: "mt-2",
          on: { "tab-click": _vm.tabClicked },
          model: {
            value: _vm.tab_name,
            callback: function ($$v) {
              _vm.tab_name = $$v
            },
            expression: "tab_name",
          },
        },
        [
          _c(
            "el-tab-pane",
            { attrs: { label: "Bulk", name: "bulk" } },
            [
              _c(
                "el-form",
                {
                  ref: "add_auto_dial_tasks",
                  attrs: {
                    "label-width": "100px",
                    "label-position": "top",
                    model: _vm.auto_dial_tasks,
                    rules: _vm.rules_auto_dial_tasks,
                  },
                  nativeOn: {
                    submit: function ($event) {
                      $event.preventDefault()
                      return _vm.preventSubmit.apply(null, arguments)
                    },
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { staticClass: "mb-3", attrs: { prop: "target_contacts" } },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "form-label mb-1",
                          attrs: { scope: "label" },
                        },
                        [_c("label", [_vm._v("Select contact group")])]
                      ),
                      _vm._v(" "),
                      _c("contact-group-selector", {
                        attrs: {
                          allow_all_contacts: false,
                          tag_category: _vm.TagCategories.CAT_CONTACTS,
                          full_width: "",
                        },
                        on: { change: _vm.contactGroupChanged },
                        model: {
                          value: _vm.auto_dial_tasks.target_contacts,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.auto_dial_tasks,
                              "target_contacts",
                              $$v
                            )
                          },
                          expression: "auto_dial_tasks.target_contacts",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("el-form-item", { staticClass: "mb-2" }, [
                    _c(
                      "div",
                      {
                        staticClass: "form-label mb-1",
                        attrs: { scope: "label" },
                      },
                      [_c("label", [_vm._v("Push contacts to")])]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "row" }, [
                      _c(
                        "div",
                        { staticClass: "col" },
                        [
                          _c(
                            "el-radio",
                            {
                              staticClass: "w-full",
                              attrs: {
                                label: _vm.PowerDialer.DIRECTION_BOTTOM,
                                value: _vm.PowerDialer.DIRECTION_BOTTOM,
                                border: "",
                                size: "medium",
                              },
                              model: {
                                value: _vm.direction,
                                callback: function ($$v) {
                                  _vm.direction = $$v
                                },
                                expression: "direction",
                              },
                            },
                            [
                              _vm._v(
                                "\n                                Bottom\n                            "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "col" },
                        [
                          _c(
                            "el-radio",
                            {
                              staticClass: "w-full",
                              attrs: {
                                label: _vm.PowerDialer.DIRECTION_TOP,
                                value: _vm.PowerDialer.DIRECTION_TOP,
                                border: "",
                                size: "medium",
                              },
                              model: {
                                value: _vm.direction,
                                callback: function ($$v) {
                                  _vm.direction = $$v
                                },
                                expression: "direction",
                              },
                            },
                            [
                              _vm._v(
                                "\n                                Top\n                            "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("el-form-item", { staticClass: "mb-2" }, [
                    _c(
                      "div",
                      { staticClass: "form-label", attrs: { scope: "label" } },
                      [
                        _c("el-checkbox", {
                          staticClass: "w-full",
                          attrs: { label: "Add multiple phone numbers?" },
                          model: {
                            value: _vm.multiple_phone_numbers,
                            callback: function ($$v) {
                              _vm.multiple_phone_numbers = $$v
                            },
                            expression: "multiple_phone_numbers",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("el-form-item", { staticClass: "mb-2" }, [
                    _c(
                      "div",
                      { staticClass: "form-label", attrs: { scope: "label" } },
                      [
                        _c("el-checkbox", {
                          staticClass: "w-full",
                          attrs: { label: "Prevent duplicate task creation" },
                          model: {
                            value: _vm.prevent_duplicates,
                            callback: function ($$v) {
                              _vm.prevent_duplicates = $$v
                            },
                            expression: "prevent_duplicates",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("el-form-item", { staticClass: "mb-2" }, [
                    _c(
                      "div",
                      { staticClass: "form-label", attrs: { scope: "label" } },
                      [
                        _c("el-checkbox", {
                          staticClass: "w-full",
                          attrs: { label: "Future scheduled task?" },
                          model: {
                            value: _vm.future_scheduled,
                            callback: function ($$v) {
                              _vm.future_scheduled = $$v
                            },
                            expression: "future_scheduled",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _vm.future_scheduled
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "Scheduled time:" } },
                        [
                          _c("v-date-picker", {
                            attrs: {
                              mode: "dateTime",
                              "title-position": "left",
                              color: "green",
                              "min-date": new Date(),
                              masks: _vm.masks,
                              popover: _vm.popover_config,
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function ({ inputValue, inputEvents }) {
                                    return [
                                      _c(
                                        "input",
                                        _vm._g(
                                          {
                                            staticClass:
                                              "px-2 py-1 border rounded",
                                            domProps: { value: inputValue },
                                          },
                                          inputEvents
                                        )
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              667095556
                            ),
                            model: {
                              value: _vm.future_scheduled_time,
                              callback: function ($$v) {
                                _vm.future_scheduled_time = $$v
                              },
                              expression: "future_scheduled_time",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c("el-form-item", { staticClass: "mb-2" }, [
                    _c(
                      "div",
                      {
                        class: `form-label ${
                          !_vm.hasInternationalAccess ? "d-inline-flex" : ""
                        }`,
                        attrs: { scope: "label" },
                      },
                      [
                        _c("el-checkbox", {
                          staticClass: "w-full",
                          attrs: {
                            disabled: !_vm.hasInternationalAccess,
                            label: "Allow international phone numbers",
                          },
                          model: {
                            value: _vm.allow_international_phone_numbers,
                            callback: function ($$v) {
                              _vm.allow_international_phone_numbers = $$v
                            },
                            expression: "allow_international_phone_numbers",
                          },
                        }),
                        _vm._v(" "),
                        !_vm.hasInternationalAccess
                          ? _c(
                              "el-tooltip",
                              {
                                staticClass: "item ml-2",
                                attrs: {
                                  effect: "dark",
                                  content:
                                    "This account has no international access",
                                  placement: "top",
                                },
                              },
                              [_c("i", { staticClass: "fas fa-info-circle" })]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "row no-gutter centered-content" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-block greenish",
                        attrs: {
                          loading: _vm.loading_add_auto_dial_tasks,
                          disabled:
                            _vm.loading_add_auto_dial_tasks ||
                            _vm.disable_add_auto_dial_tasks,
                        },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.addAutoDialTasks.apply(null, arguments)
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n                        Add Tasks\n                    "
                        ),
                      ]
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-tab-pane",
            { attrs: { label: "Filter", name: "filter" } },
            [
              _c(
                "el-form",
                {
                  ref: "contact_filter_auto_dial_tasks",
                  attrs: {
                    "label-width": "100px",
                    "label-position": "top",
                    model: _vm.auto_dial_tasks,
                    rules: _vm.rules_auto_dial_contact_filter_tasks,
                  },
                  nativeOn: {
                    submit: function ($event) {
                      $event.preventDefault()
                      return _vm.preventSubmit.apply(null, arguments)
                    },
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { staticClass: "mb-3", attrs: { prop: "filters" } },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "form-label mb-1",
                          attrs: { scope: "label" },
                        },
                        [_c("label", [_vm._v("Filters")])]
                      ),
                      _vm._v(" "),
                      _c("contact-tags-filters-selector", {
                        on: { resetFilters: _vm.resetFilters },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("el-form-item", { staticClass: "mb-2" }, [
                    _c(
                      "div",
                      {
                        staticClass: "form-label mb-1",
                        attrs: { scope: "label" },
                      },
                      [_c("label", [_vm._v("Push contacts to")])]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "row" }, [
                      _c(
                        "div",
                        { staticClass: "col" },
                        [
                          _c(
                            "el-radio",
                            {
                              staticClass: "w-full",
                              attrs: {
                                label: _vm.PowerDialer.DIRECTION_BOTTOM,
                                value: _vm.PowerDialer.DIRECTION_BOTTOM,
                                border: "",
                                size: "medium",
                              },
                              model: {
                                value: _vm.direction,
                                callback: function ($$v) {
                                  _vm.direction = $$v
                                },
                                expression: "direction",
                              },
                            },
                            [
                              _vm._v(
                                "\n                                Bottom\n                            "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "col" },
                        [
                          _c(
                            "el-radio",
                            {
                              staticClass: "w-full",
                              attrs: {
                                label: _vm.PowerDialer.DIRECTION_TOP,
                                value: _vm.PowerDialer.DIRECTION_TOP,
                                border: "",
                                size: "medium",
                              },
                              model: {
                                value: _vm.direction,
                                callback: function ($$v) {
                                  _vm.direction = $$v
                                },
                                expression: "direction",
                              },
                            },
                            [
                              _vm._v(
                                "\n                                Top\n                            "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("el-form-item", { staticClass: "mb-2" }, [
                    _c(
                      "div",
                      { staticClass: "form-label", attrs: { scope: "label" } },
                      [
                        _c("el-checkbox", {
                          staticClass: "w-full",
                          attrs: { label: "Add multiple phone numbers?" },
                          model: {
                            value: _vm.multiple_phone_numbers,
                            callback: function ($$v) {
                              _vm.multiple_phone_numbers = $$v
                            },
                            expression: "multiple_phone_numbers",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("el-form-item", { staticClass: "mb-2" }, [
                    _c(
                      "div",
                      { staticClass: "form-label", attrs: { scope: "label" } },
                      [
                        _c("el-checkbox", {
                          staticClass: "w-full",
                          attrs: { label: "Prevent duplicate task creation" },
                          model: {
                            value: _vm.prevent_duplicates,
                            callback: function ($$v) {
                              _vm.prevent_duplicates = $$v
                            },
                            expression: "prevent_duplicates",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("el-form-item", { staticClass: "mb-2" }, [
                    _c(
                      "div",
                      { staticClass: "form-label", attrs: { scope: "label" } },
                      [
                        _c("el-checkbox", {
                          staticClass: "w-full",
                          attrs: { label: "Future scheduled task?" },
                          model: {
                            value: _vm.future_scheduled,
                            callback: function ($$v) {
                              _vm.future_scheduled = $$v
                            },
                            expression: "future_scheduled",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _vm.future_scheduled
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "Scheduled time:" } },
                        [
                          _c("v-date-picker", {
                            attrs: {
                              mode: "dateTime",
                              "title-position": "left",
                              color: "green",
                              "min-date": new Date(),
                              masks: _vm.masks,
                              popover: _vm.popover_config,
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function ({ inputValue, inputEvents }) {
                                    return [
                                      _c(
                                        "input",
                                        _vm._g(
                                          {
                                            staticClass:
                                              "px-2 py-1 border rounded",
                                            domProps: { value: inputValue },
                                          },
                                          inputEvents
                                        )
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              667095556
                            ),
                            model: {
                              value: _vm.future_scheduled_time,
                              callback: function ($$v) {
                                _vm.future_scheduled_time = $$v
                              },
                              expression: "future_scheduled_time",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c("el-form-item", { staticClass: "mb-2" }, [
                    _c(
                      "div",
                      {
                        class: `form-label ${
                          !_vm.hasInternationalAccess ? "d-inline-flex" : ""
                        }`,
                        attrs: { scope: "label" },
                      },
                      [
                        _c("el-checkbox", {
                          staticClass: "w-full",
                          attrs: {
                            disabled: !_vm.hasInternationalAccess,
                            label: "Allow international phone numbers",
                          },
                          model: {
                            value: _vm.allow_international_phone_numbers,
                            callback: function ($$v) {
                              _vm.allow_international_phone_numbers = $$v
                            },
                            expression: "allow_international_phone_numbers",
                          },
                        }),
                        _vm._v(" "),
                        !_vm.hasInternationalAccess
                          ? _c(
                              "el-tooltip",
                              {
                                staticClass: "item ml-2",
                                attrs: {
                                  effect: "dark",
                                  content:
                                    "This account has no international access",
                                  placement: "top",
                                },
                              },
                              [_c("i", { staticClass: "fas fa-info-circle" })]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "row no-gutter centered-content" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-block greenish",
                        attrs: {
                          loading: _vm.loading_contact_filter_auto_dial_tasks,
                          disabled:
                            _vm.loading_contact_filter_auto_dial_tasks ||
                            _vm.disable_contact_filter_auto_dial_tasks,
                        },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.contactFilterAutoDialTasks.apply(
                              null,
                              arguments
                            )
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n                        Add Tasks\n                    "
                        ),
                      ]
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-tab-pane",
            { attrs: { label: "Test", name: "test" } },
            [
              _c(
                "el-form",
                {
                  ref: "add_auto_dial_task",
                  attrs: {
                    "label-width": "100px",
                    "label-position": "top",
                    model: _vm.auto_dial_task,
                    rules: _vm.rules_auto_dial_task,
                  },
                  nativeOn: {
                    submit: function ($event) {
                      $event.preventDefault()
                      return _vm.addAutoDialTask.apply(null, arguments)
                    },
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { staticClass: "mb-3", attrs: { prop: "contact_id" } },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "form-label mb-1",
                          attrs: { scope: "label" },
                        },
                        [_c("label", [_vm._v("Select a contact")])]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-select",
                        {
                          staticClass: "no-select w-full",
                          attrs: {
                            placeholder: "Type to search ...",
                            "remote-method": _vm.fetchContact,
                            loading: _vm.loading_fetching_contact,
                            filterable: "",
                            remote: "",
                            clearable: "",
                          },
                          on: { change: _vm.changeTargetContact },
                          model: {
                            value: _vm.auto_dial_task.contact_id,
                            callback: function ($$v) {
                              _vm.$set(_vm.auto_dial_task, "contact_id", $$v)
                            },
                            expression: "auto_dial_task.contact_id",
                          },
                        },
                        _vm._l(
                          _vm.contactsAlphabeticalOrder,
                          function (contact) {
                            return _c(
                              "el-option",
                              {
                                key: contact.id,
                                staticClass: "p-0",
                                attrs: {
                                  label: contact.name.length
                                    ? contact.name
                                    : "No Name",
                                  value: contact.id,
                                },
                              },
                              [
                                _c("div", { staticClass: "media" }, [
                                  _c("div", { staticClass: "media-body" }, [
                                    contact.name.length
                                      ? _c("label", [
                                          _vm._v(_vm._s(contact.name)),
                                        ])
                                      : _c("label", [_vm._v("No Name")]),
                                    _vm._v(" "),
                                    _c("small", [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("fixPhone")(
                                            contact.phone_number
                                          )
                                        )
                                      ),
                                    ]),
                                  ]),
                                ]),
                              ]
                            )
                          }
                        ),
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("el-form-item", { staticClass: "mb-2" }, [
                    _c(
                      "div",
                      {
                        staticClass: "form-label mb-1",
                        attrs: { scope: "label" },
                      },
                      [_c("label", [_vm._v("Push contacts to")])]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "row" }, [
                      _c(
                        "div",
                        { staticClass: "col" },
                        [
                          _c(
                            "el-radio",
                            {
                              staticClass: "w-full",
                              attrs: {
                                label: _vm.PowerDialer.DIRECTION_BOTTOM,
                                value: _vm.PowerDialer.DIRECTION_BOTTOM,
                                border: "",
                                size: "medium",
                              },
                              model: {
                                value: _vm.direction,
                                callback: function ($$v) {
                                  _vm.direction = $$v
                                },
                                expression: "direction",
                              },
                            },
                            [
                              _vm._v(
                                "\n                                Bottom\n                            "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "col" },
                        [
                          _c(
                            "el-radio",
                            {
                              staticClass: "w-full",
                              attrs: {
                                label: _vm.PowerDialer.DIRECTION_TOP,
                                value: _vm.PowerDialer.DIRECTION_TOP,
                                border: "",
                                size: "medium",
                              },
                              model: {
                                value: _vm.direction,
                                callback: function ($$v) {
                                  _vm.direction = $$v
                                },
                                expression: "direction",
                              },
                            },
                            [
                              _vm._v(
                                "\n                                Top\n                            "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("el-form-item", { staticClass: "mb-2" }, [
                    _c(
                      "div",
                      { staticClass: "form-label", attrs: { scope: "label" } },
                      [
                        _c("el-checkbox", {
                          staticClass: "w-full",
                          attrs: { label: "Add multiple phone numbers?" },
                          model: {
                            value: _vm.multiple_phone_numbers,
                            callback: function ($$v) {
                              _vm.multiple_phone_numbers = $$v
                            },
                            expression: "multiple_phone_numbers",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("el-form-item", { staticClass: "mb-2" }, [
                    _c(
                      "div",
                      { staticClass: "form-label", attrs: { scope: "label" } },
                      [
                        _c("el-checkbox", {
                          staticClass: "w-full",
                          attrs: { label: "Prevent duplicate task creation" },
                          model: {
                            value: _vm.prevent_duplicates,
                            callback: function ($$v) {
                              _vm.prevent_duplicates = $$v
                            },
                            expression: "prevent_duplicates",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("el-form-item", { staticClass: "mb-2" }, [
                    _c(
                      "div",
                      { staticClass: "form-label", attrs: { scope: "label" } },
                      [
                        _c("el-checkbox", {
                          staticClass: "w-full",
                          attrs: { label: "Future scheduled task?" },
                          model: {
                            value: _vm.future_scheduled,
                            callback: function ($$v) {
                              _vm.future_scheduled = $$v
                            },
                            expression: "future_scheduled",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _vm.future_scheduled
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "Scheduled time:" } },
                        [
                          _c("v-date-picker", {
                            attrs: {
                              mode: "dateTime",
                              "title-position": "left",
                              color: "green",
                              "min-date": new Date(),
                              masks: _vm.masks,
                              popover: _vm.popover_config,
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function ({ inputValue, inputEvents }) {
                                    return [
                                      _c(
                                        "input",
                                        _vm._g(
                                          {
                                            staticClass:
                                              "px-2 py-1 border rounded",
                                            domProps: { value: inputValue },
                                          },
                                          inputEvents
                                        )
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              667095556
                            ),
                            model: {
                              value: _vm.future_scheduled_time,
                              callback: function ($$v) {
                                _vm.future_scheduled_time = $$v
                              },
                              expression: "future_scheduled_time",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c("el-form-item", { staticClass: "mb-2" }, [
                    _c(
                      "div",
                      {
                        class: `form-label ${
                          !_vm.hasInternationalAccess ? "d-inline-flex" : ""
                        }`,
                        attrs: { scope: "label" },
                      },
                      [
                        _c("el-checkbox", {
                          staticClass: "w-full",
                          attrs: {
                            disabled: !_vm.hasInternationalAccess,
                            label: "Allow international phone numbers",
                          },
                          model: {
                            value: _vm.allow_international_phone_numbers,
                            callback: function ($$v) {
                              _vm.allow_international_phone_numbers = $$v
                            },
                            expression: "allow_international_phone_numbers",
                          },
                        }),
                        _vm._v(" "),
                        !_vm.hasInternationalAccess
                          ? _c(
                              "el-tooltip",
                              {
                                staticClass: "item ml-2",
                                attrs: {
                                  effect: "dark",
                                  content:
                                    "This account has no international access",
                                  placement: "top",
                                },
                              },
                              [_c("i", { staticClass: "fas fa-info-circle" })]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "row no-gutter centered-content" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-block greenish",
                        attrs: {
                          loading: _vm.loading_add_auto_dial_task,
                          disabled:
                            _vm.loading_add_auto_dial_task ||
                            _vm.disable_auto_dial_task,
                        },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.addAutoDialTask.apply(null, arguments)
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n                        Add Task\n                    "
                        ),
                      ]
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _vm.hubspotEnabled || _vm.zohoEnabled || _vm.pipedriveEnabled
            ? _c(
                "el-tab-pane",
                { attrs: { label: "Integrations", name: "integrations" } },
                [
                  _vm.hubspotEnabled
                    ? _c(
                        "el-form",
                        {
                          ref: "add_hubspot_list",
                          attrs: {
                            "label-width": "100px",
                            "label-position": "top",
                            model: _vm.hubspot_list,
                            rules: _vm.rules_hubspot_list,
                          },
                          nativeOn: {
                            submit: function ($event) {
                              $event.preventDefault()
                              return _vm.addHubSpotList.apply(null, arguments)
                            },
                          },
                        },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticClass: "mb-3",
                              attrs: {
                                prop: "list_id",
                                label: "Select a HubSpot list",
                              },
                            },
                            [
                              _c("hubspot-list-selector", {
                                attrs: { value: _vm.hubspot_list.list_id },
                                on: { change: _vm.hubspotListChanged },
                                model: {
                                  value: _vm.hubspot_list.list_id,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.hubspot_list, "list_id", $$v)
                                  },
                                  expression: "hubspot_list.list_id",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("el-form-item", { staticClass: "mb-2" }, [
                            _c(
                              "div",
                              {
                                staticClass: "form-label mb-1",
                                attrs: { scope: "label" },
                              },
                              [_c("label", [_vm._v("Push contacts to")])]
                            ),
                            _vm._v(" "),
                            _c("div", { staticClass: "row" }, [
                              _c(
                                "div",
                                { staticClass: "col" },
                                [
                                  _c(
                                    "el-radio",
                                    {
                                      staticClass: "w-full",
                                      attrs: {
                                        label: _vm.PowerDialer.DIRECTION_BOTTOM,
                                        value: _vm.PowerDialer.DIRECTION_BOTTOM,
                                        border: "",
                                        size: "medium",
                                      },
                                      model: {
                                        value: _vm.direction,
                                        callback: function ($$v) {
                                          _vm.direction = $$v
                                        },
                                        expression: "direction",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                                Bottom\n                            "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "col" },
                                [
                                  _c(
                                    "el-radio",
                                    {
                                      staticClass: "w-full",
                                      attrs: {
                                        label: _vm.PowerDialer.DIRECTION_TOP,
                                        value: _vm.PowerDialer.DIRECTION_TOP,
                                        border: "",
                                        size: "medium",
                                      },
                                      model: {
                                        value: _vm.direction,
                                        callback: function ($$v) {
                                          _vm.direction = $$v
                                        },
                                        expression: "direction",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                                Top\n                            "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("el-form-item", { staticClass: "mb-2" }, [
                            _c(
                              "div",
                              {
                                staticClass: "form-label",
                                attrs: { scope: "label" },
                              },
                              [
                                _c("el-checkbox", {
                                  staticClass: "w-full",
                                  attrs: {
                                    label: "Add multiple phone numbers?",
                                  },
                                  model: {
                                    value: _vm.multiple_phone_numbers,
                                    callback: function ($$v) {
                                      _vm.multiple_phone_numbers = $$v
                                    },
                                    expression: "multiple_phone_numbers",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                          _vm._v(" "),
                          _c("el-form-item", { staticClass: "mb-2" }, [
                            _c(
                              "div",
                              {
                                staticClass: "form-label",
                                attrs: { scope: "label" },
                              },
                              [
                                _c("el-checkbox", {
                                  staticClass: "w-full",
                                  attrs: {
                                    label: "Prevent duplicate task creation",
                                  },
                                  model: {
                                    value: _vm.prevent_duplicates,
                                    callback: function ($$v) {
                                      _vm.prevent_duplicates = $$v
                                    },
                                    expression: "prevent_duplicates",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                          _vm._v(" "),
                          _c("el-form-item", { staticClass: "mb-2" }, [
                            _c(
                              "div",
                              {
                                staticClass: "form-label",
                                attrs: { scope: "label" },
                              },
                              [
                                _c("el-checkbox", {
                                  staticClass: "w-full",
                                  attrs: { label: "Add your own contacts?" },
                                  model: {
                                    value: _vm.own_contacts_only,
                                    callback: function ($$v) {
                                      _vm.own_contacts_only = $$v
                                    },
                                    expression: "own_contacts_only",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                          _vm._v(" "),
                          _c("el-form-item", { staticClass: "mb-2" }, [
                            _c(
                              "div",
                              {
                                staticClass: "form-label",
                                attrs: { scope: "label" },
                              },
                              [
                                _c("el-checkbox", {
                                  staticClass: "w-full",
                                  attrs: { label: "Future scheduled task?" },
                                  model: {
                                    value: _vm.future_scheduled,
                                    callback: function ($$v) {
                                      _vm.future_scheduled = $$v
                                    },
                                    expression: "future_scheduled",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                          _vm._v(" "),
                          _vm.future_scheduled
                            ? _c(
                                "el-form-item",
                                { attrs: { label: "Scheduled time:" } },
                                [
                                  _c("v-date-picker", {
                                    attrs: {
                                      mode: "dateTime",
                                      "title-position": "left",
                                      color: "green",
                                      "min-date": new Date(),
                                      masks: _vm.masks,
                                      popover: _vm.popover_config,
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function ({
                                            inputValue,
                                            inputEvents,
                                          }) {
                                            return [
                                              _c(
                                                "input",
                                                _vm._g(
                                                  {
                                                    staticClass:
                                                      "px-2 py-1 border rounded",
                                                    domProps: {
                                                      value: inputValue,
                                                    },
                                                  },
                                                  inputEvents
                                                )
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      667095556
                                    ),
                                    model: {
                                      value: _vm.future_scheduled_time,
                                      callback: function ($$v) {
                                        _vm.future_scheduled_time = $$v
                                      },
                                      expression: "future_scheduled_time",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c("el-form-item", { staticClass: "mb-2" }, [
                            _c(
                              "div",
                              {
                                class: `form-label ${
                                  !_vm.hasInternationalAccess
                                    ? "d-inline-flex"
                                    : ""
                                }`,
                                attrs: { scope: "label" },
                              },
                              [
                                _c("el-checkbox", {
                                  staticClass: "w-full",
                                  attrs: {
                                    disabled: !_vm.hasInternationalAccess,
                                    label: "Allow international phone numbers",
                                  },
                                  model: {
                                    value:
                                      _vm.allow_international_phone_numbers,
                                    callback: function ($$v) {
                                      _vm.allow_international_phone_numbers =
                                        $$v
                                    },
                                    expression:
                                      "allow_international_phone_numbers",
                                  },
                                }),
                                _vm._v(" "),
                                !_vm.hasInternationalAccess
                                  ? _c(
                                      "el-tooltip",
                                      {
                                        staticClass: "item ml-2",
                                        attrs: {
                                          effect: "dark",
                                          content:
                                            "This account has no international access",
                                          placement: "top",
                                        },
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "fas fa-info-circle",
                                        }),
                                      ]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "row no-gutter centered-content" },
                            [
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-block greenish",
                                  attrs: {
                                    loading: _vm.loading_add_hubspot_list,
                                    disabled:
                                      _vm.loading_add_hubspot_list ||
                                      _vm.disable_add_list,
                                  },
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      return _vm.addHubSpotList.apply(
                                        null,
                                        arguments
                                      )
                                    },
                                  },
                                },
                                [
                                  _vm.loading_add_hubspot_list
                                    ? _c(
                                        "i",
                                        {
                                          staticClass: "material-icons loader",
                                        },
                                        [_vm._v("")]
                                      )
                                    : _vm._e(),
                                  _vm._v(
                                    "\n                        Add Contacts\n                    "
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.zohoEnabled
                    ? _c(
                        "el-form",
                        {
                          ref: "add_zoho_view",
                          attrs: {
                            "label-width": "100px",
                            "label-position": "top",
                            model: _vm.zoho_view,
                            rules: _vm.rules_zoho_view,
                          },
                          nativeOn: {
                            submit: function ($event) {
                              $event.preventDefault()
                              return _vm.addZohoView.apply(null, arguments)
                            },
                          },
                        },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticClass: "mb-3",
                              attrs: {
                                prop: "view_id",
                                label: "Select a Zoho custom view",
                              },
                            },
                            [
                              _c("zoho-view-selector", {
                                attrs: { value: _vm.zoho_view.view_id },
                                on: { change: _vm.zohoViewChanged },
                                model: {
                                  value: _vm.zoho_view.view_id,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.zoho_view, "view_id", $$v)
                                  },
                                  expression: "zoho_view.view_id",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("el-form-item", { staticClass: "mb-2" }, [
                            _c(
                              "div",
                              {
                                staticClass: "form-label mb-1",
                                attrs: { scope: "label" },
                              },
                              [_c("label", [_vm._v("Push contacts to")])]
                            ),
                            _vm._v(" "),
                            _c("div", { staticClass: "row" }, [
                              _c(
                                "div",
                                { staticClass: "col" },
                                [
                                  _c(
                                    "el-radio",
                                    {
                                      staticClass: "w-full",
                                      attrs: {
                                        label: _vm.PowerDialer.DIRECTION_BOTTOM,
                                        value: _vm.PowerDialer.DIRECTION_BOTTOM,
                                        border: "",
                                        size: "medium",
                                      },
                                      model: {
                                        value: _vm.direction,
                                        callback: function ($$v) {
                                          _vm.direction = $$v
                                        },
                                        expression: "direction",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                                Bottom\n                            "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "col" },
                                [
                                  _c(
                                    "el-radio",
                                    {
                                      staticClass: "w-full",
                                      attrs: {
                                        label: _vm.PowerDialer.DIRECTION_TOP,
                                        value: _vm.PowerDialer.DIRECTION_TOP,
                                        border: "",
                                        size: "medium",
                                      },
                                      model: {
                                        value: _vm.direction,
                                        callback: function ($$v) {
                                          _vm.direction = $$v
                                        },
                                        expression: "direction",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                                Top\n                            "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("el-form-item", { staticClass: "mb-2" }, [
                            _c(
                              "div",
                              {
                                staticClass: "form-label",
                                attrs: { scope: "label" },
                              },
                              [
                                _c("el-checkbox", {
                                  staticClass: "w-full",
                                  attrs: {
                                    label: "Add multiple phone numbers?",
                                  },
                                  model: {
                                    value: _vm.multiple_phone_numbers,
                                    callback: function ($$v) {
                                      _vm.multiple_phone_numbers = $$v
                                    },
                                    expression: "multiple_phone_numbers",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                          _vm._v(" "),
                          _c("el-form-item", { staticClass: "mb-2" }, [
                            _c(
                              "div",
                              {
                                staticClass: "form-label",
                                attrs: { scope: "label" },
                              },
                              [
                                _c("el-checkbox", {
                                  staticClass: "w-full",
                                  attrs: {
                                    label: "Prevent duplicate task creation",
                                  },
                                  model: {
                                    value: _vm.prevent_duplicates,
                                    callback: function ($$v) {
                                      _vm.prevent_duplicates = $$v
                                    },
                                    expression: "prevent_duplicates",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                          _vm._v(" "),
                          _c("el-form-item", { staticClass: "mb-2" }, [
                            _c(
                              "div",
                              {
                                staticClass: "form-label",
                                attrs: { scope: "label" },
                              },
                              [
                                _c("el-checkbox", {
                                  staticClass: "w-full",
                                  attrs: { label: "Add your own contacts?" },
                                  model: {
                                    value: _vm.own_contacts_only,
                                    callback: function ($$v) {
                                      _vm.own_contacts_only = $$v
                                    },
                                    expression: "own_contacts_only",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                          _vm._v(" "),
                          _c("el-form-item", { staticClass: "mb-2" }, [
                            _c(
                              "div",
                              {
                                staticClass: "form-label",
                                attrs: { scope: "label" },
                              },
                              [
                                _c("el-checkbox", {
                                  staticClass: "w-full",
                                  attrs: { label: "Future scheduled task?" },
                                  model: {
                                    value: _vm.future_scheduled,
                                    callback: function ($$v) {
                                      _vm.future_scheduled = $$v
                                    },
                                    expression: "future_scheduled",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                          _vm._v(" "),
                          _vm.future_scheduled
                            ? _c(
                                "el-form-item",
                                { attrs: { label: "Scheduled time:" } },
                                [
                                  _c("v-date-picker", {
                                    attrs: {
                                      mode: "dateTime",
                                      "title-position": "left",
                                      color: "green",
                                      "min-date": new Date(),
                                      masks: _vm.masks,
                                      popover: _vm.popover_config,
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function ({
                                            inputValue,
                                            inputEvents,
                                          }) {
                                            return [
                                              _c(
                                                "input",
                                                _vm._g(
                                                  {
                                                    staticClass:
                                                      "px-2 py-1 border rounded",
                                                    domProps: {
                                                      value: inputValue,
                                                    },
                                                  },
                                                  inputEvents
                                                )
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      667095556
                                    ),
                                    model: {
                                      value: _vm.future_scheduled_time,
                                      callback: function ($$v) {
                                        _vm.future_scheduled_time = $$v
                                      },
                                      expression: "future_scheduled_time",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "row no-gutter centered-content" },
                            [
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-block greenish",
                                  attrs: {
                                    loading: _vm.loading_add_zoho_view,
                                    disabled:
                                      _vm.loading_add_zoho_view ||
                                      _vm.disable_add_view,
                                  },
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      return _vm.addZohoView.apply(
                                        null,
                                        arguments
                                      )
                                    },
                                  },
                                },
                                [
                                  _vm.loading_add_zoho_view
                                    ? _c(
                                        "i",
                                        {
                                          staticClass: "material-icons loader",
                                        },
                                        [_vm._v("")]
                                      )
                                    : _vm._e(),
                                  _vm._v(
                                    "\n                        Add Contacts\n                    "
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.pipedriveEnabled
                    ? _c(
                        "el-form",
                        {
                          ref: "add_pipedrive_filter",
                          attrs: {
                            "label-width": "100px",
                            "label-position": "top",
                            model: _vm.pipedrive_filter,
                            rules: _vm.rules_pipedrive_filter,
                          },
                          nativeOn: {
                            submit: function ($event) {
                              $event.preventDefault()
                              return _vm.addPipedriveFilter.apply(
                                null,
                                arguments
                              )
                            },
                          },
                        },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticClass: "mb-3",
                              attrs: {
                                prop: "filter_id",
                                label: "Select a Pipedrive custom filter",
                              },
                            },
                            [
                              _c("pipedrive-filter-selector", {
                                attrs: {
                                  value: _vm.pipedrive_filter.filter_id,
                                },
                                on: {
                                  selectedFilter: _vm.pipedriveFilterChanged,
                                },
                                model: {
                                  value: _vm.pipedrive_filter.filter_id,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.pipedrive_filter,
                                      "filter_id",
                                      $$v
                                    )
                                  },
                                  expression: "pipedrive_filter.filter_id",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("el-form-item", { staticClass: "mb-2" }, [
                            _c(
                              "div",
                              {
                                staticClass: "form-label mb-1",
                                attrs: { scope: "label" },
                              },
                              [_c("label", [_vm._v("Push contacts to")])]
                            ),
                            _vm._v(" "),
                            _c("div", { staticClass: "row" }, [
                              _c(
                                "div",
                                { staticClass: "col" },
                                [
                                  _c(
                                    "el-radio",
                                    {
                                      staticClass: "w-full",
                                      attrs: {
                                        label: _vm.PowerDialer.DIRECTION_BOTTOM,
                                        value: _vm.PowerDialer.DIRECTION_BOTTOM,
                                        border: "",
                                        size: "medium",
                                      },
                                      model: {
                                        value: _vm.direction,
                                        callback: function ($$v) {
                                          _vm.direction = $$v
                                        },
                                        expression: "direction",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                      Bottom\n                    "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "col" },
                                [
                                  _c(
                                    "el-radio",
                                    {
                                      staticClass: "w-full",
                                      attrs: {
                                        label: _vm.PowerDialer.DIRECTION_TOP,
                                        value: _vm.PowerDialer.DIRECTION_TOP,
                                        border: "",
                                        size: "medium",
                                      },
                                      model: {
                                        value: _vm.direction,
                                        callback: function ($$v) {
                                          _vm.direction = $$v
                                        },
                                        expression: "direction",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                      Top\n                    "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("el-form-item", { staticClass: "mb-2" }, [
                            _c(
                              "div",
                              {
                                staticClass: "form-label",
                                attrs: { scope: "label" },
                              },
                              [
                                _c("el-checkbox", {
                                  staticClass: "w-full",
                                  attrs: {
                                    label: "Add multiple phone numbers?",
                                  },
                                  model: {
                                    value: _vm.multiple_phone_numbers,
                                    callback: function ($$v) {
                                      _vm.multiple_phone_numbers = $$v
                                    },
                                    expression: "multiple_phone_numbers",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                          _vm._v(" "),
                          _c("el-form-item", { staticClass: "mb-2" }, [
                            _c(
                              "div",
                              {
                                staticClass: "form-label",
                                attrs: { scope: "label" },
                              },
                              [
                                _c("el-checkbox", {
                                  staticClass: "w-full",
                                  attrs: {
                                    label: "Prevent duplicate task creation",
                                  },
                                  model: {
                                    value: _vm.prevent_duplicates,
                                    callback: function ($$v) {
                                      _vm.prevent_duplicates = $$v
                                    },
                                    expression: "prevent_duplicates",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                          _vm._v(" "),
                          _c("el-form-item", { staticClass: "mb-2" }, [
                            _c(
                              "div",
                              {
                                staticClass: "form-label",
                                attrs: { scope: "label" },
                              },
                              [
                                _c("el-checkbox", {
                                  staticClass: "w-full",
                                  attrs: { label: "Add your own contacts?" },
                                  model: {
                                    value: _vm.own_contacts_only,
                                    callback: function ($$v) {
                                      _vm.own_contacts_only = $$v
                                    },
                                    expression: "own_contacts_only",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                          _vm._v(" "),
                          _c("el-form-item", { staticClass: "mb-2" }, [
                            _c(
                              "div",
                              {
                                staticClass: "form-label",
                                attrs: { scope: "label" },
                              },
                              [
                                _c("el-checkbox", {
                                  staticClass: "w-full",
                                  attrs: { label: "Future scheduled task?" },
                                  model: {
                                    value: _vm.future_scheduled,
                                    callback: function ($$v) {
                                      _vm.future_scheduled = $$v
                                    },
                                    expression: "future_scheduled",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                          _vm._v(" "),
                          _vm.future_scheduled
                            ? _c(
                                "el-form-item",
                                { attrs: { label: "Scheduled time:" } },
                                [
                                  _c("v-date-picker", {
                                    attrs: {
                                      mode: "dateTime",
                                      "title-position": "left",
                                      color: "green",
                                      "min-date": new Date(),
                                      masks: _vm.masks,
                                      popover: _vm.popover_config,
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function ({
                                            inputValue,
                                            inputEvents,
                                          }) {
                                            return [
                                              _c(
                                                "input",
                                                _vm._g(
                                                  {
                                                    staticClass:
                                                      "px-2 py-1 border rounded",
                                                    domProps: {
                                                      value: inputValue,
                                                    },
                                                  },
                                                  inputEvents
                                                )
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      667095556
                                    ),
                                    model: {
                                      value: _vm.future_scheduled_time,
                                      callback: function ($$v) {
                                        _vm.future_scheduled_time = $$v
                                      },
                                      expression: "future_scheduled_time",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c("el-form-item", { staticClass: "mb-2" }, [
                            _c(
                              "div",
                              {
                                class: `form-label ${
                                  !_vm.hasInternationalAccess
                                    ? "d-inline-flex"
                                    : ""
                                }`,
                                attrs: { scope: "label" },
                              },
                              [
                                _c("el-checkbox", {
                                  staticClass: "w-full",
                                  attrs: {
                                    disabled: !_vm.hasInternationalAccess,
                                    label: "Allow international phone numbers",
                                  },
                                  model: {
                                    value:
                                      _vm.allow_international_phone_numbers,
                                    callback: function ($$v) {
                                      _vm.allow_international_phone_numbers =
                                        $$v
                                    },
                                    expression:
                                      "allow_international_phone_numbers",
                                  },
                                }),
                                _vm._v(" "),
                                !_vm.hasInternationalAccess
                                  ? _c(
                                      "el-tooltip",
                                      {
                                        staticClass: "item ml-2",
                                        attrs: {
                                          effect: "dark",
                                          content:
                                            "This account has no international access",
                                          placement: "top",
                                        },
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "fas fa-info-circle",
                                        }),
                                      ]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "row no-gutter centered-content" },
                            [
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-block greenish",
                                  attrs: {
                                    loading: _vm.loading_add_pipedrive_filter,
                                    disabled:
                                      _vm.loading_add_pipedrive_filter ||
                                      _vm.disable_add_view,
                                  },
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      return _vm.addPipedriveFilter.apply(
                                        null,
                                        arguments
                                      )
                                    },
                                  },
                                },
                                [
                                  _vm.loading_add_pipedrive_filter
                                    ? _c(
                                        "i",
                                        {
                                          staticClass: "material-icons loader",
                                        },
                                        [_vm._v("")]
                                      )
                                    : _vm._e(),
                                  _vm._v(
                                    "\n                  Add Contacts\n                "
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-button",
        {
          staticClass: "mb-2",
          attrs: {
            slot: "reference",
            icon: "fa fa-plus",
            type: "success",
            disabled: _vm.disabled,
            size: _vm.small ? "small" : "",
          },
          on: { click: _vm.toggleAddTaskForm },
          slot: "reference",
        },
        [_c("span", { staticClass: "ml-2" }, [_vm._v("Add Tasks")])]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }