<template>
    <div>
        <h5 class="text-dark mb-1">Communication Logs</h5>
        <div class="d-flex">
            <span v-if="!communications_count_loading"><strong>{{ communicationsCount | numFormat }}</strong> Communications</span>
            <template v-if="current_company && current_company.is_high_volume && !loading">
                <br>
                <span class="text-danger">
                    Due to high volume, your metrics are not live. Please refresh the screen to see the latest updates.
                </span>
            </template>
        </div>
        <div class="d-flex my-2">
            <div class="d-flex flex-grow-1">
                <el-input
                    ref="commLogTableSearch"
                    class="width-500"
                    placeholder="Press ENTER to search..."
                    suffix-icon="el-icon-search"
                    v-model="search_text"
                    clearable
                    autofocus
                    data-testid="comm-log-table-input-search"
                    @change="searchCommsChange">
                </el-input>
                <button class="btn greenish pointer ml-2"
                        style="height: 40px"
                        data-testid="comm-log-table-refresh-button"
                        @click.prevent="getCommunications()">
                    <i class="material-icons">refresh</i>
                </button>
            </div>
            <div class="d-flex ml-auto">
                <div class="dropdown dropdown-toggle-caret-none">
                    <button class="btn btn-outline b-dark-greenish text-dark-greenish dropdown-toggle ml-2"
                            data-toggle="dropdown"
                            data-flip="false"
                            aria-expanded="false"
                            data-testid="comm-log-table-settings-button"
                            @click="checkBlur">
                        Table Settings <i class="el-icon-arrow-down"></i>
                    </button>
                    <div class="dropdown-menu dropdown-menu-right p-2">
                        <div class="filter-item">
                            <li>
                                <b>Rows:</b>
                            </li>
                            <li v-on:click="noClose($event)">
                                <el-radio-group v-model="filter.per_page"
                                                class="row-filter"
                                                data-testid="comm-log-table-per-page-filter"
                                                @change="changeRows">
                                    <el-radio :label="10">10</el-radio>
                                    <el-radio :label="20">20</el-radio>
                                    <el-radio :label="50">50</el-radio>
                                    <el-radio :label="100">100</el-radio>
                                    <el-radio :label="250">250</el-radio>
                                </el-radio-group>
                            </li>
                            <li>
                                <b>Columns:</b>
                            </li>
                            <el-checkbox-group v-model="checked_table_fields"
                                               data-testid="comm-log-table-fields-checkbox"
                                               @change="changeTableFields">
                                <li v-for="field in table_fields"
                                    :key="field.text"
                                    v-on:click="noClose($event)">
                                    <el-checkbox :label="field.val">
                                        {{ field.text }}
                                    </el-checkbox>
                                </li>
                            </el-checkbox-group>
                        </div>
                    </div>
                </div>
                <button class="btn blackish pointer ml-2"
                        style="height: 40px"
                        data-testid="comm-log-table-graph-button"
                        @click.prevent="toggleGraph">
                    <i class="fa fa-area-chart"></i> {{ show_graph ? 'Hide' : 'Show' }} Graph
                </button>
            </div>
        </div>

        <el-table
            class="w-full mt-3"
            v-loading="loading"
            stripe
            row-key="id"
            :default-sort="{prop: 'created_at', order: 'descending'}"
            :data="communications"
            :expand-row-keys="expandableCommunicationsId"
            data-testid="comm-log-table"
            @cell-mouse-enter="cellEnter">
            <el-table-column type="expand" 
                             data-testid="expand-column" >
                <template v-slot="scope">
                    <div class="row align-items-top">
                        <div class="col-md-3"
                             v-if="scope.row.contact">
                            <router-link
                                data-testid="comm-log-link-contact"
                                :to="{ name: 'Contact', params: { contact_id: scope.row.contact.id }}">
                                <el-tooltip class="item pull-left"
                                            effect="dark"
                                            content="Click For More Info"
                                            data-testid="comm-log-tooltip-for-more-info"
                                            placement="top">
                                    <span class="text-dark-greenish"
                                          :title="scope.row.contact.name | fixContactName">
                                        {{ scope.row.contact.name | fixContactName }}
                                    </span>
                                </el-tooltip>
                                <i v-if="scope.row.first_time_caller"
                                   class="on b-white bottom caller-status pull-left"
                                   title="First Time Conversation">
                                </i>
                            </router-link>
                            <br>
                            <span class="text-greyish"
                                  v-if="scope.row.type !== 5">
                                {{ scope.row.lead_number | fixPhone }}
                            </span>
                            <span class="text-greyish text-xs"
                                  v-else>
                                {{ scope.row.lead_number }}
                            </span>
                            <div class="mt-1"
                                 data-testid="comm-log-tag-div"
                                 v-if="scope.row.contact.disposition_status_id">
                                <span class="text-greyish text-xs"
                                      v-if="scope.row.contact.disposition_status_id">
                                    <i class="material-icons"
                                       :style="{ color: $options.filters.dispositionStatusColor(scope.row.contact.disposition_status_id) }">label</i>
                                    <span class="text-grey-900">{{ scope.row.contact.disposition_status_id | dispositionStatusName }}</span>
                                </span>
                            </div>
                            <div class="mt-1 text-pink-500 text-xs"
                                 data-testid="business-center-div"
                                 v-if="scope.row.contact.company_name">
                                <i class="material-icons">business_center</i>
                                {{ scope.row.contact.company_name }}
                            </div>
                            <div class="mt-1"
                                 v-if="scope.row.contact.tags && scope.row.contact.tags.length > 0">
                                <div v-for="tag in $options.filters.lastTags(scope.row.contact.tags, 3)"
                                     :key="tag.id"
                                     class="text-xs">
                                    <i class="fa fa-circle"
                                       :style="{ color: tag.color }"></i>
                                    <span class="ml-1 text-grey-900">{{ tag.name }}</span>
                                </div>
                                <div class="d-flex align-items-center justify-content-center text-muted text-sm"
                                     v-if="scope.row.contact.tags.length > 3">
                                    ...
                                </div>
                            </div>
                            <div class="mt-1"
                                 v-if="scope.row.contact.cnam_city || scope.row.contact.cnam_state">
                                <span class="text-greyish"
                                      v-if="scope.row.contact.cnam_city">
                                    {{ scope.row.contact.cnam_city }},
                                </span>
                                <span class="text-greyish"
                                      v-if="scope.row.contact.cnam_state">
                                    {{ scope.row.contact.cnam_state }}
                                </span>
                            </div>
                        </div>
                        <div class="col-md-3"
                             :class="[checkSize() ? 'b-l' : 'mt-2']"
                             v-if="scope.row.contact">
                            <target-users-tree v-if="scope.row.type === CommunicationTypes.CALL"
                                               data-testid="comm-log-target-users-tree"
                                               :communication="scope.row"/>
                        </div>
                        <div class="col-md-3"
                             :class="[checkSize() ? 'b-l' : 'mt-2']"
                             v-if="scope.row.type !== CommunicationTypes.CALL && (scope.row.body || scope.row.attachments)">
                            <strong class="text-greyish"
                                    v-if="scope.row.type === CommunicationTypes.FAX">
                                Files:
                            </strong>
                            <br v-if="scope.row.type === CommunicationTypes.FAX">
                            <div v-if="scope.row.attachments && scope.row.attachments.length > 0"
                                 v-for="(attachment, index) in scope.row.attachments"
                                 :key="index">
                                <vue-load-image v-if="(attachment.mime_type && isAttachmentImage(attachment.mime_type)) || !attachment.mime_type">
                                    <img slot="image"
                                         class="img-fluid d-block r-2x"
                                         style="max-height: 150px;"
                                         :class="index > 0 ? 'mb-1' : ''"
                                         data-testid="comm-log-attachment-image"
                                         :src="attachment.url"/>
                                    <img slot="preloader"
                                         style="max-height: 150px;"
                                         data-testid="comm-log-loading-img"
                                         src="/assets/images/loading.svg"/>
                                    <div slot="error">Error on loading the image attachment</div>
                                </vue-load-image>
                                <template v-if="attachment.mime_type">
                                    <div v-if="isAttachmentAudio(attachment.mime_type)">
                                        <audio class="audio-player"
                                               data-testid="comm-log-attachment-audio"
                                               controls>
                                            <source :src="attachment.url"
                                                    :type="attachment.mime_type">
                                            Your browser does not support the audio element.
                                        </audio>
                                    </div>

                                    <div v-if="isAttachmentVideo(attachment.mime_type)">
                                        <video width="320"
                                               class="rounded"
                                               data-testid="comm-log-attachment-video"
                                               controls>
                                            <source :src="attachment.url"
                                                    :type="attachment.mime_type">
                                            Your browser does not support the video tag.
                                        </video>
                                    </div>

                                    <a :href="attachment.url"
                                       v-if="(isAttachmentText(attachment.mime_type) || isAttachmentApplication(attachment.mime_type))"
                                       target="_blank">
                                        <div class="p-2 text-center">
                                            <figure>
                                                <img height="32"
                                                     width="32"
                                                     data-testid="comm-log-attachment-file"
                                                     src="/assets/images/app-icons/file.svg">
                                                <figcaption>{{ attachment.name ? truncateFileName(attachment.name) : 'Click Here To Download' }}</figcaption>
                                            </figure>
                                        </div>
                                    </a>
                                </template>
                            </div>

                            <div v-if="scope.row.body && scope.row.type !== CommunicationTypes.EMAIL">
                                <span class="text-greyish break-word">
                                    {{ scope.row.body }}
                                </span>
                            </div>
                            <div v-else-if="scope.row.body && scope.row.type === CommunicationTypes.EMAIL">
                                <span class="text-greyish break-word"
                                      v-html="$options.filters.nl2br(scope.row.body)">
                                </span>
                            </div>
                        </div>
                        <div class="col-md-3"
                             :class="[checkSize() ? 'b-l' : 'mt-2']"
                             v-if="scope.row.notes">
                            <strong class="text-greyish">Notes:</strong>
                            <br>
                            <span class="text-greyish">
                                {{ scope.row.notes }}
                            </span>
                        </div>
                    </div>
                </template>
            </el-table-column>
            <el-table-column
                data-testid="disposition-column"
                prop="disposition_status"
                width="105"
                v-if="checkField('disposition_status')">
                <template v-slot="scope">
                    <el-tooltip class="item"
                                effect="dark"
                                data-testid="comm-log-tooltip-disposition"
                                :content="dispositionTooltipData(scope.row.disposition_status2, scope.row.direction, scope.row.type, scope.row.callback_status)"
                                placement="right">
                        <router-link
                            data-testid="comm-log-link-disposition"
                            :to="{ name: 'Communication', params: {communication_id: scope.row.id }}">
                            <div :state="scope.row.disposition_status2"
                                 class="status-icon centered-content"
                                 v-html="stateToIcon(scope.row.disposition_status2, scope.row.direction, scope.row.type, scope.row.callback_status)">
                            </div>
                            <div class="text-center">
                                <span :class="stateToIcon(scope.row.disposition_status2, scope.row.direction, scope.row.type, scope.row.callback_status, true)"
                                      v-if="scope.row.resolution2 === CommunicationResolution.RESOLUTION_FORWARD_NEW"
                                      class="badge d-inline-flex align-items-center py-1">
                                    External FWD
                                </span>
                            </div>
                        </router-link>
                    </el-tooltip>
                </template>
            </el-table-column>
            <el-table-column
                data-testid="line-column"
                label="Line"
                v-if="checkField('campaign')"
                :min-width="150">
                <template v-slot="scope">
                    <router-link :to="{ name: 'Line Activity', params: {campaign_id: scope.row.campaign_id }}"
                                 data-testid="comm-log-link-campaign"
                                 v-if="scope.row.campaign_id">
                        <a class="text-dark-greenish"
                           v-if="getCampaign(scope.row.campaign_id)">
                            {{ getCampaign(scope.row.campaign_id).name }}
                        </a>
                    </router-link>
                </template>
            </el-table-column>
            <el-table-column
                label="Number"
                prop="incoming_number"
                data-testid="incoming-number-column"
                v-if="checkField('incoming_number')"
                :min-width="150">
                <template v-slot="scope">
                    <div class="row">
                        <div class="col-12 d-flex align-items-center justify-content-left">
                            <router-link
                                data-testid="comm-log-link-line-activity"
                                :to="{ name: 'Line Activity', params: {campaign_id: scope.row.campaign_id }}"
                                v-if="scope.row.campaign_id">
                                <a class="text-dark-greenish"
                                   v-if="getCampaign(scope.row.campaign_id)">
                                    {{ getCampaign(scope.row.campaign_id).name }}
                                </a>
                            </router-link>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 d-flex align-items-center justify-content-left"
                             v-if="scope.row.type !== 5">
                            <span class="text-greyish">{{ scope.row.incoming_number | fixPhone }}</span>
                        </div>
                        <div class="col-12 d-flex align-items-center justify-content-left"
                             v-else>
                            <span class="text-greyish">{{ scope.row.incoming_number }}</span>
                        </div>
                    </div>
                </template>
            </el-table-column>
            <el-table-column
                label="Team"
                data-testid="team-column"
                prop="teams"
                sortable
                :sort-method="sortTeams"
                :min-width="150"
                v-if="checkField('team')">
                <template v-slot="scope">
                    <span class="text-dark-greenish"
                          v-if="scope.row.teams">
                        <ul class="mb-0">
                            <li v-for="(teamName, index) in scope.row.teams"
                                :key="index">
                                {{ teamName }}
                            </li>
                        </ul>
                    </span>
                    <span class="text-greyish"
                          v-else>
                        -
                    </span>
                </template>
            </el-table-column>
            <el-table-column
                label="Ring Group"
                v-if="checkField('ring_group')"
                data-testid="ring-group-column"
                :min-width="150">
                <template v-slot="scope">
                    <router-link
                        v-if="scope.row.ring_group_id && getRingGroup(scope.row.ring_group_id)"
                        data-testid="comm-log-link-ring-group"
                        :to="{ name: 'Ring Group Dialog', params: {ring_group_id: scope.row.ring_group_id }}">
                        <span class="text-dark-greenish">
                            {{ getRingGroup(scope.row.ring_group_id).name }}
                        </span>
                    </router-link>
                    <span class="text-greyish"
                          v-else>
                        -
                    </span>
                </template>
            </el-table-column>
            <el-table-column
                label="Sequence"
                v-if="checkField('workflow')"
                data-testid="sequence-column"
                :min-width="150">
                <template v-slot="scope">
                    <router-link
                        v-if="scope.row.workflow_id && getWorkflow(scope.row.workflow_id)"
                        data-testid="comm-log-link-sequence-manager"
                        :to="{ name: 'Sequence Manager', params: { workflow_id: scope.row.workflow_id }}">
                        <span class="text-dark-greenish">
                            {{ getWorkflow(scope.row.workflow_id).name }}
                        </span>
                    </router-link>
                    <span class="text-greyish"
                          v-else>
                        -
                    </span>
                </template>
            </el-table-column>
            <el-table-column
                label="Broadcast"
                v-if="checkField('broadcast')"
                data-testid="broadcast-column"
                :min-width="150">
                <template v-slot="scope">
                    <router-link
                        v-if="scope.row.broadcast_id && getBroadcast(scope.row.broadcast_id)"
                        data-testid="comm-log-link-broadcast-activity"
                        :to="{ name: 'Broadcast Activity', params: { broadcast_id: scope.row.broadcast_id }}">
                        <span class="text-dark-greenish">
                            {{ getBroadcast(scope.row.broadcast_id).name }}
                        </span>
                    </router-link>
                    <span class="text-greyish"
                          v-else>
                        -
                    </span>
                </template>
            </el-table-column>
            <el-table-column
                label="Start Time"
                prop="created_at"
                v-if="checkField('created_at')"
                data-testid="start-time-column"
                width="162"
                sortable>
                <template v-slot="scope">
                    <span class="text-greyish">{{ scope.row.created_at | fixDateTime }}</span>
                    <div class="row"
                         data-testid="start-time-row"
                         v-if="scope.row.call_disposition_id">
                        <div class="col-12 d-flex align-items-center justify-content-left text-xs">
                            <i class="fa fa-bolt"
                               :style="{ color: $options.filters.callDispositionColor(scope.row.call_disposition_id) }"></i>
                            <span class="ml-1 text-grey-900">{{ scope.row.call_disposition_id | callDispositionName }}</span>
                        </div>
                    </div>
                    <div class="row mt-2"
                         data-testid="start-time-row"
                         v-if="scope.row.has_recording || scope.row.recording_is_deleted">
                        <div class="col-12">
                            <communication-audio :communication="scope.row"
                                                 data-testid="comm-log-communication-audio"
                                                 :type="UploadedFileTypes.TYPE_CALL_RECORDING">
                            </communication-audio>
                        </div>
                    </div>
                    <div class="row mt-2"
                         data-testid="start-time-row"
                         v-if="scope.row.has_voicemail">
                        <div class="col-12">
                            <communication-audio :communication="scope.row"
                                                 data-testid="comm-log-communication-voicemail"
                                                 :type="UploadedFileTypes.TYPE_CALL_VOICEMAIL">
                            </communication-audio>
                        </div>
                    </div>
                    <div class="row mt-2"
                         v-if="scope.row.has_transcription && !scope.row.transcription_is_deleted">
                        <div class="col-12">
                            <communication-transcription-modal
                                data-testid="comm-log-communication-transcription-modal"
                                :communication="scope.row"
                                :button_text="'Show Transcription'"
                            ></communication-transcription-modal>
                        </div>
                    </div>
                </template>
            </el-table-column>
            <el-table-column
                label="Talk Time"
                prop="talk_time"
                v-if="checkField('talk_time')"
                sortable
                data-testid="talk-time-column"
                width="120">
                <template v-slot="scope">
                    <div class="row"
                         data-testid="talk-time-row"
                         v-if="scope.row.type === CommunicationTypes.CALL">
                        <div class="col-12 d-flex align-items-center justify-content-left">
                            <span
                                v-if="scope.row.disposition_status2 !== CommunicationDispositionStatus.DISPOSITION_STATUS_INPROGRESS_NEW && scope.row.current_status2 === CommunicationCurrentStatus.CURRENT_STATUS_COMPLETED_NEW">{{
                                    scope.row.talk_time | fixDuration
                                }}</span>
                            <relative-time class="text-muted"
                                           data-testid="comm-log-relative-time"
                                           :from_time="scope.row.created_at"
                                           v-else-if="scope.row.disposition_status2 === CommunicationDispositionStatus.DISPOSITION_STATUS_INPROGRESS_NEW && scope.row.current_status2 === CommunicationCurrentStatus.CURRENT_STATUS_INPROGRESS_NEW">
                            </relative-time>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 d-flex align-items-center justify-content-left">
                            <span
                                v-if="scope.row.direction === CommunicationDirection.INBOUND && scope.row.disposition_status2 === CommunicationDispositionStatus.DISPOSITION_STATUS_ABANDONED_NEW && [CallbackStatus.CALLBACK_STATUS_INITIATED, CallbackStatus.CALLBACK_STATUS_REQUESTED].includes(scope.row.callback_status)">
                                Callback {{ getVisibleCallbackStatus(scope.row) }}
                            </span>
                            <span v-else>{{ getVisibleStatus(scope.row) }}</span>
                        </div>
                    </div>
                </template>
            </el-table-column>
            <el-table-column
                label="Wait Time"
                prop="wait_time"
                data-testid="wait-time-column"
                v-if="checkField('wait_time')"
                sortable
                width="120">
                <template v-slot="scope">
                    <div class="row">
                        <div class="col-12 d-flex align-items-center justify-content-left"
                             data-testid="wait-time-div">
                            <span v-if="scope.row.type === CommunicationTypes.CALL">{{ scope.row.wait_time | fixDuration }}</span>
                            <span v-else>
                                -
                            </span>
                        </div>
                    </div>
                </template>
            </el-table-column>
            <el-table-column
                label="Hold Time"
                prop="hold_time"
                data-testid="hold-time-column"
                v-if="checkField('hold_time')"
                sortable
                width="120">
                <template v-slot="scope">
                    <div class="row"
                         data-testid="hold-time-row">
                        <div class="col-12 d-flex align-items-center justify-content-left"
                             data-testid="hold-time-div">
                            <span v-if="scope.row.type === CommunicationTypes.CALL">{{ scope.row.hold_time | fixDuration }}</span>
                            <span v-else>
                                -
                            </span>
                        </div>
                    </div>
                </template>
            </el-table-column>
            <el-table-column
                label="Duration"
                prop="duration"
                data-testid="duration-column"
                v-if="checkField('duration')"
                sortable
                width="110">
                <template v-slot="scope">
                    <div class="row"
                         v-if="scope.row.type === CommunicationTypes.CALL"
                         data-testid="duration-div">
                        <div class="col-12 d-flex align-items-center justify-content-left">
                            <span>{{ scope.row.duration | fixDuration }}</span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 d-flex align-items-center justify-content-left"
                             data-testid="status-div">
                            <span>{{ getVisibleStatus(scope.row) }}</span>
                        </div>
                    </div>
                </template>
            </el-table-column>
            <el-table-column
                label="Resolution"
                prop="resolution2"
                data-testid="resolution-column"
                v-if="checkField('resolution2')"
                sortable
                width="120">
                <template v-slot="scope">
                    <div class="row"
                         data-testid="resolution-row">
                        <div class="col-12 d-flex align-items-center justify-content-left"
                             data-testid="resolution-div">
                            <span>{{ getVisibleResolution(scope.row) }}</span>
                        </div>
                    </div>
                </template>
            </el-table-column>
            <el-table-column
                label="Queue Resolution"
                prop="queue_resolution2"
                data-testid="queue-resolution-column"
                v-if="checkField('queue_resolution2')"
                sortable
                width="120">
                <template v-slot="scope">
                    <div class="row"
                         data-testid="queue-resolution-row">
                        <div class="col-12 d-flex align-items-center justify-content-left"
                             data-testid="queue-resolution">
                            <span>{{ getVisibleQueueResolution(scope.row) }}</span>
                        </div>
                    </div>
                </template>
            </el-table-column>
            <el-table-column
                label="Contact"
                prop="lead_number"
                data-testid="contact-column"
                v-if="checkField('lead_number')"
                :min-width="300">
                <template v-slot="scope">
                    <div class="row d-flex align-items-center"
                         data-testid="contact-div">
                        <div :class="[(scope.row.contact && scope.row.contact.tags && scope.row.contact.tags.length > 0) ? 'col-6' : 'col-12']">
                            <div class="row">
                                <div class="col-12 d-flex align-items-center justify-content-left"
                                     v-if="scope.row.contact">
                                    <router-link
                                        data-testid="contact-router-link"
                                        :to="{ name: 'Contact', params: { contact_id: scope.row.contact.id }}">
                                        <el-tooltip class="item pull-left"
                                                    effect="dark"
                                                    content="Click For More Info"
                                                    data-testid="comm-log-tooltip-contact"
                                                    placement="top">
                                            <span class="text-dark-greenish"
                                                  :title="scope.row.contact.name | fixContactName">
                                                {{ scope.row.contact.name | fixContactName }}
                                            </span>
                                        </el-tooltip>
                                        <i v-if="scope.row.first_time_caller"
                                           data-testid="first-time-conv-i"
                                           class="on b-white bottom caller-status pull-left"
                                           title="First Time Conversation"></i>
                                    </router-link>
                                </div>
                                <div class="col-12 d-flex align-items-center justify-content-left"
                                     v-else>
                                    <span class="text-dark-greenish pull-left">{{ scope.row.lead_number | fixPhone }}</span>
                                    <i v-if="scope.row.first_time_caller"
                                       data-testid="first-time-conv-i"
                                       class="on b-white bottom caller-status pull-left"
                                       title="First Time Conversation"></i>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12 d-flex align-items-center justify-content-left"
                                     data-testid="lead-number">
                                    <span class="text-greyish">
                                        {{ scope.row.lead_number | fixPhone }}
                                    </span>
                                </div>
                            </div>
                            <div class="row"
                                 v-if="scope.row.contact && scope.row.contact.disposition_status_id">
                                <div class="col-12 d-flex align-items-center justify-content-left text-xs">
                                    <i class="material-icons"
                                        data-test="disposition-status-icon"
                                       :style="{ color: $options.filters.dispositionStatusColor(scope.row.contact.disposition_status_id) }">label</i>
                                    <span class="ml-1 text-grey-900">{{ scope.row.contact.disposition_status_id | dispositionStatusName }}</span>
                                </div>
                            </div>
                            <div class="row"
                                 v-if="scope.row.contact && scope.row.contact.company_name">
                                <div class="col-12 d-flex align-items-center justify-content-left text-pink-500 text-xs"
                                     data-testid="company-name-icon">
                                    <i class="material-icons">business_center</i>
                                    <span class="ml-1">{{ scope.row.contact.company_name }}</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-6"
                             v-if="scope.row.contact && scope.row.contact.tags && scope.row.contact.tags.length">
                            <div v-for="tag in $options.filters.lastTags(scope.row.contact.tags, 3)"
                                 :key="tag.id"
                                 class="d-flex align-items-center justify-content-left text-xs">
                                <i class="fa fa-circle"
                                   data-testid="tag-circle"
                                   :style="{ color: tag.color }"></i>
                                <span class="ml-1 text-grey-900">{{ tag.name }}</span>
                            </div>
                            <div class="d-flex align-items-center justify-content-center text-muted text-sm"
                                 v-if="scope.row.contact.tags.length > 3">
                                ...
                            </div>
                        </div>
                    </div>
                </template>
            </el-table-column>
            <el-table-column
                data-testid="email-column"
                v-if="checkField('email')"
                align="center"
                label="Email"
                :min-width="120"
                prop="contact.email"
                sortable>
                <template v-slot="scope">
                    <span data-testid="email-span">{{ scope.row.contact ? scope.row.contact.email : '' }}</span>
                </template>
            </el-table-column>
            <el-table-column
                label="Location"
                prop="lead_location"
                data-testid="location-column"
                v-if="checkField('lead_location')"
                sortable
                width="140">
                <template v-slot="scope">
                    <div class="row"
                         data-testid="location-column-row">
                        <div class="col-12 d-flex align-items-center justify-content-left"
                             data-testid="location-div">
                            <span class="text-greyish"
                                  v-if="scope.row.state">
                                {{ scope.row.city || '' }}{{ (scope.row.city && scope.row.state) ? ', ' : '' }}{{ scope.row.state }}
                            </span>
                            <span class="text-greyish"
                                  v-else>
                                -
                            </span>
                        </div>
                    </div>
                </template>
            </el-table-column>
            <el-table-column
                label="User"
                prop="user"
                data-testid="users-column"
                v-if="checkField('user')"
                sortable
                :min-width="150">
                <template v-slot="scope">
                    <div class="row">
                        <div class="col-12 d-flex align-items-center justify-content-left">
                            <el-tooltip class="item"
                                        effect="dark"
                                        popper-class="rejection-tooltip"
                                        data-testid="rejected-by-app"
                                        :content="rejectionTooltipData(scope.row.rejected_by_app, scope.row.type)"
                                        placement="right"
                                        v-if="scope.row.rejected_by_app != 0">
                                <router-link :to="{ name: 'Communication', params: {communication_id: scope.row.id }}">
                                    <div :state="scope.row.rejected_by_app"
                                         class="status-icon d-inline-block"
                                         v-html="rejectionToIcon(scope.row.rejected_by_app)">
                                    </div>
                                </router-link>
                            </el-tooltip>
                            <div v-else-if="scope.row.user_id && getUser(scope.row.user_id)">
                                <router-link
                                    data-testid="users-router-link"
                                    :to="{ name: 'User Activity', params: {user_id: scope.row.user_id }}">
                                    <span class="text-dark-greenish"
                                          :title="getUserName(getUser(scope.row.user_id))">
                                        {{ getUserName(getUser(scope.row.user_id)) }}
                                    </span>
                                </router-link>
                            </div>
                            <div v-else>
                                <span class="text-greyish">
                                    -
                                </span>
                            </div>
                        </div>
                    </div>
                </template>
            </el-table-column>
            <el-table-column
                label="Attempting"
                prop="attempting_users"
                data-testid="attempting-users-column"
                v-if="checkField('attempting_users')"
                sortable
                :min-width="150">
                <template v-slot="scope">
                    <div class="row"
                         data-test="attempting-users-column-row">
                        <div class="col-12 d-flex align-items-center justify-content-left">
                            <span class="text-greyish"
                                  v-if="scope.row.attempting_users && scope.row.attempting_users.length > 0">
                                <ul data-test="attempting-users-ul"
                                    class="list list-unstyled inset mb-0">
                                    <div v-if="!show_more_list.includes(scope.row.id)">
                                        <li data-test="attempting-users-li"
                                            v-for="attempting_user in scope.row.attempting_users.slice(0, 3)"
                                            :key="attempting_user"
                                            v-if="getUser(attempting_user) && getUser(attempting_user).id"
                                            class="pb-1">
                                            <router-link
                                                data-test="attempting-users-router-link"
                                                :to="{ name: 'User Activity', params: {user_id: getUser(attempting_user).id }}">
                                                <span :class="getAttemptingClass(attempting_user, scope.row.disposition_status2, scope.row.user_id)"
                                                      :title="getUserName(getUser(attempting_user))">
                                                    {{ getUserName(getUser(attempting_user)) }}
                                                </span>
                                            </router-link>
                                        </li>
                                    </div>
                                    <div v-else>
                                        <li v-for="attempting_user in scope.row.attempting_users"
                                            :key="attempting_user"
                                            v-if="getUser(attempting_user) && getUser(attempting_user).id"
                                            class="pb-1">
                                            <router-link
                                                data-test="attempting-users-router-link"
                                                :to="{ name: 'User Activity', params: {user_id: getUser(attempting_user).id }}">
                                                <span :class="getAttemptingClass(attempting_user, scope.row.disposition_status2, scope.row.user_id)"
                                                      :title="getUserName(getUser(attempting_user))">
                                                    {{ getUserName(getUser(attempting_user)) }}
                                                </span>
                                            </router-link>
                                        </li>
                                    </div>
                                    <a class="d-flex btn align-items-center justify-content-center text-muted text-sm"
                                       data-test="attempting-users-a"
                                       v-if="scope.row.attempting_users.length > 3"
                                       @click="showMore(scope.row)">
                                        {{ !show_more_list.includes(scope.row.id) ? 'more ...' : 'less' }}
                                    </a>
                                </ul>
                            </span>
                            <span class="text-greyish"
                                  v-else>
                                -
                            </span>
                        </div>
                    </div>
                </template>
            </el-table-column>
            <el-table-column
                label="Cold Transferred?"
                prop="in_cold_transfer"
                data-testid="in-cold-transfer-column"
                v-if="checkField('in_cold_transfer')"
                sortable
                :min-width="180">
                <template v-slot="scope">
                    <div class="row"
                         data-testid="in-cold-transfer-column-div">
                        <div class="col-12 d-flex align-items-center justify-content-center">
                            <span class="text-dark-greenish _400">{{ scope.row.in_cold_transfer ? 'Yes' : 'No' }}</span>
                        </div>
                    </div>
                </template>
            </el-table-column>
            <el-table-column
                label="Transferred From"
                prop="transfer_prior_user_ids"
                data-testid="transfer-prior-user-ids-column"
                v-if="checkField('transfer_prior_user_ids')"
                sortable
                :min-width="180">
                <template v-slot="scope">
                    <div class="row"
                         data-testid="transfer-prior-user-ids-column-row">
                        <div class="col-12 d-flex align-items-center justify-content-left">
                            <span class="text-greyish"
                                  v-if="scope.row.transfer_prior_user_ids && scope.row.transfer_prior_user_ids.length > 0">
                                <ul class="list list-unstyled inset mb-0"
                                    data-testid="transfer-prior-user-ids-column-ul">
                                    <li data-testid="transfer-prior-user-ids-column-li"
                                        v-for="transfer_prior_user_id in scope.row.transfer_prior_user_ids"
                                        :key="transfer_prior_user_id"
                                        v-if="getUser(transfer_prior_user_id) && getUser(transfer_prior_user_id).id"
                                        class="pb-1">
                                        <router-link
                                            :to="{ name: 'User Activity', params: {user_id: getUser(transfer_prior_user_id).id }}">
                                            <span :class="getAttemptingClass(transfer_prior_user_id, scope.row.disposition_status2, scope.row.user_id)"
                                                  :title="getUserName(getUser(transfer_prior_user_id))">
                                                {{ getUserName(getUser(transfer_prior_user_id)) }}
                                            </span>
                                        </router-link>
                                    </li>
                                </ul>
                            </span>
                            <span class="text-greyish"
                                  v-else>
                                -
                            </span>
                        </div>
                    </div>
                </template>
            </el-table-column>
            <el-table-column
                label="Transferred To"
                prop="transfer_target_user_ids"
                data-testid="transfer-target-user-ids-column"
                v-if="checkField('transfer_target_user_ids')"
                sortable
                :min-width="180">
                <template v-slot="scope">
                    <div class="row"
                         data-testid="transfer-target-user-ids-column-row">
                        <div class="col-12 d-flex align-items-center justify-content-left">
                            <span class="text-greyish"
                                  v-if="scope.row.transfer_target_user_ids && scope.row.transfer_target_user_ids.length > 0">
                                <ul class="list list-unstyled inset mb-0"
                                    data-testid="transfer-target-user-ids-ul">
                                    <li data-testid="transfer-target-user-ids-li"
                                        v-for="transfer_target_user_id in scope.row.transfer_target_user_ids"
                                        :key="transfer_target_user_id"
                                        v-if="getUser(transfer_target_user_id) && getUser(transfer_target_user_id).id"
                                        class="pb-1">
                                        <router-link
                                            data-testid="transfer-target-user-ids-router-link"
                                            :to="{ name: 'User Activity', params: {user_id: getUser(transfer_target_user_id).id }}">
                                            <span :class="getAttemptingClass(transfer_target_user_id, scope.row.disposition_status2, scope.row.user_id)"
                                                  :title="getUserName(getUser(transfer_target_user_id))">
                                                {{ getUserName(getUser(transfer_target_user_id)) }}
                                            </span>
                                        </router-link>
                                    </li>
                                </ul>
                            </span>
                            <span class="text-greyish"
                                  v-else>
                                -
                            </span>
                        </div>
                    </div>
                </template>
            </el-table-column>
            <el-table-column
                label="Transfer Type"
                prop="transfer_type"
                data-testid="transfer-type-column"
                v-if="checkField('transfer_type')"
                width="150"
                sortable>
                <template v-slot="scope">
                    <span class="text-greyish"
                          data-testid="transfer-type-span"
                          v-if="scope.row.transfer_type">
                        {{ scope.row.transfer_type | translateTransferTypeText }}
                    </span>
                    <div v-else>
                        <span class="text-greyish"
                              data-testid="transfer-type-span">
                          -
                        </span>
                    </div>
                </template>
            </el-table-column>
            <el-table-column
                label="Callback Status"
                prop="callback_status"
                data-testid="callback-status-column"
                v-if="checkField('callback_status')"
                width="150"
                sortable>
                <template v-slot="scope">
                    <span class="text-greyish"
                          data-testid="callback-status-span"
                          v-if="scope.row.callback_status">
                        {{ scope.row.callback_status | translateCallbackStatusText }}
                    </span>
                    <div v-else>
                        <span class="text-greyish"
                              data-testid="callback-status-span">
                          -
                        </span>
                    </div>
                </template>
            </el-table-column>
            <el-table-column
                label="Creator Type"
                prop="creator_type"
                data-testid="creator-type-column"
                v-if="checkField('creator_type')"
                width="150"
                sortable>
                <template v-slot="scope">
                    <span class="text-greyish"
                          data-testid="creator-type-span">
                        {{ scope.row.creator_type | translateCreatorTypeText }}
                    </span>
                </template>
            </el-table-column>
            <el-table-column
                label="Tags"
                data-testid="tags-column"
                v-if="checkField('tags')"
                :min-width="140">
                <template v-slot="scope">
                    <communication-tags :communication="scope.row"></communication-tags>
                </template>
            </el-table-column>
            <el-table-column
                align="center"
                label="Notes"
                v-if="checkField('notes')"
                :min-width="70">
                <template v-slot="scope">
                    <communication-note data-testid="communication-note-component" 
                                        :communication="scope.row">
                    </communication-note>
                </template>
            </el-table-column>
            <el-table-column
                align="center"
                data-testid="csat-score-column"
                label="CSAT Score"
                v-if="checkField('csat_score')"
                :min-width="140">
                <template v-slot="scope">
                    <el-rate data-testid="el-rate-component" 
                             v-model="scope.row.csat_score" 
                             disabled>
                    </el-rate>
                </template>
            </el-table-column>
            <el-table-column
                align="left"
                label="Operations"
                data-testid="operation-column"
                v-if="checkField('operations')"
                width="250">
                <template v-slot="scope">
                    <el-tooltip class="item"
                                effect="dark"
                                content="Reply"
                                placement="bottom"
                                v-if="scope.row.contact && scope.row.type == 2 && hasPermissionTo('send sms')">
                        <button class="btn btn-sm blue btn-icon opaq"
                                data-testid="go-to-messages-btn"
                                @click="gotoMessages(scope.row.contact.id)">
                            <i class="material-icons">reply</i>
                        </button>
                    </el-tooltip>
                    <el-tooltip class="item"
                                effect="dark"
                                content="Call Back"
                                placement="bottom"
                                v-if="!hasReporterAccess && scope.row.contact && scope.row.type !== 5">
                        <button class="btn btn-sm dark-greenish btn-icon opaq"
                                :disabled="call_loading === scope.row.contact_id"
                                data-test="call-back-btn"
                                @click="callContact(scope.row.contact, scope.row.lead_number)">
                            <i class="material-icons">call</i>
                        </button>
                    </el-tooltip>
                    <el-tooltip class="item"
                                effect="dark"
                                content="More Details"
                                placement="bottom">
                        <button class="btn btn-sm greyish btn-icon opaq"
                                data-testid="go-to-communication-info-btn"
                                @click="gotoCommunicationInfo(scope.row.id)">
                            <i class="material-icons">info_outline</i>
                        </button>
                    </el-tooltip>
                    <el-tooltip class="item"
                                effect="dark"
                                content="Archive"
                                placement="bottom"
                                v-if="hasPermissionTo('archive communication')">
                        <button class="btn btn-sm deep-orange btn-icon opaq"
                                :loading="archive_loading"
                                :disabled="archive_loading"
                                @click="archiveCommunication(scope.row.id)">
                            <i class="material-icons">archive</i>
                        </button>
                    </el-tooltip>
                    <el-tooltip class="item"
                                effect="dark"
                                content="Terminate"
                                placement="bottom"
                                v-if="hasRole('Company Admin') && scope.row.type == CommunicationTypes.CALL && scope.row.disposition_status2 == CommunicationDispositionStatus.DISPOSITION_STATUS_INPROGRESS_NEW">
                        <button class="btn btn-sm danger btn-icon opaq"
                                :loading="terminate_loading"
                                :disabled="terminate_loading"
                                data-testid="terminate-communication-btn"
                                @click="terminateCommunication(scope.row.id)">
                            <i class="material-icons">power_settings_new</i>
                        </button>
                    </el-tooltip>

                    <template v-if="userCanBargeAndWhisper(scope.row)">
                        <template v-if="isBlockedFrom('barge & whisper')">
                            <el-tooltip class="item"
                                        content="The Barge option is not included in your current plan, to use it, you have to upgrade to one of our plans that offers it!"
                                        effect="power-dialer-start-button-tooltip"
                                        data-testid="barge-tooltip"
                                        placement="bottom">
                                <button class="btn btn-sm deep-purple btn-icon opaq"
                                        data-testid="barge-btn"
                                        :disabled="true">
                                    <i class="material-icons">volume_up</i>
                                </button>
                            </el-tooltip>
                            <el-tooltip class="item"
                                        content="The Whisper option is not included in your current plan, to use it, you have to upgrade to one of our plans that offers it!"
                                        effect="power-dialer-start-button-tooltip"
                                        data-testid="whisper-tooltip"
                                        placement="bottom">
                                <button class="btn btn-sm lime btn-icon opaq"
                                        data-testid="whisper-btn"
                                        :disabled="true">
                                    <i class="material-icons">hearing</i>
                                </button>
                            </el-tooltip>
                        </template>
                        <template v-else>
                            <el-tooltip class="item"
                                        effect="dark"
                                        content="Barge"
                                        placement="bottom"
                                        data-testid="barge-tooltip"
                                        v-if="userCanBargeAndWhisper(scope.row)">
                                <button class="btn btn-sm deep-purple btn-icon opaq"
                                        :loading="barge_loading"
                                        :disabled="barge_loading"
                                        data-testid="barge-btn"
                                        @click="bargeCommunicationDialog(scope.row)">
                                    <i class="material-icons">volume_up</i>
                                </button>
                            </el-tooltip>
                            <el-tooltip class="item"
                                        effect="dark"
                                        content="Whisper"
                                        placement="bottom"
                                        data-testid="whisper-tooltip"
                                        v-if="userCanBargeAndWhisper(scope.row)">
                                <button class="btn btn-sm lime btn-icon opaq"
                                        :loading="whisper_loading"
                                        :disabled="whisper_loading"
                                        data-testid="whisper-btn"
                                        @click="whisperCommunicationDialog(scope.row)">
                                    <i class="material-icons">hearing</i>
                                </button>
                            </el-tooltip>
                        </template>
                    </template>
                </template>
            </el-table-column>
            <template slot="empty"
                      class="text-center text-muted">
                <span data-testid="no-data" 
                      v-if="!loading">
                      No Data
                </span>
                <span data-testid="loading" 
                      v-if="loading">
                </span>
            </template>
        </el-table>
        <div class="row mt-3">
            <div class="col-12">
                <el-button-group class="pull-right">
                    <el-button size="small"
                               icon="el-icon-arrow-left"
                               v-if="!pagination_pages || !pagination_pages.prev_page_url || pagination_loading || loading"
                               disabled
                               data-testid="prev-page-btn"
                               plain>
                        Previous Page
                    </el-button>
                    <el-button type="success"
                               size="small"
                               icon="el-icon-arrow-left"
                               data-testid="prev-page-btn"
                               v-else
                               @click="previousPage">
                        Previous Page
                    </el-button>
                    <el-button size="small"
                               v-if="!pagination_pages || !pagination_pages.next_page_url || pagination_loading || loading"
                               disabled
                               data-testid="next-page-btn"
                               plain>
                        Next Page
                        <i class="el-icon-arrow-right"></i>
                    </el-button>
                    <el-button type="success"
                               size="small"
                               data-testid="next-page-btn"
                               v-else
                               @click="nextPage">
                        Next Page
                        <i class="el-icon-arrow-right"></i>
                    </el-button>
                </el-button-group>
            </div>
        </div>
    </div>
</template>

<script>
import auth from '../auth'
import VueLoadImage from 'vue-load-image'
import RelativeTime from "./relative-time"
import TargetUsersTree from "./target-users-tree"
import {acl_mixin, communication_info_mixin, communication_mixin, date_mixin, report_mixin, user_info_mixin} from '../mixins'
import {mapActions, mapGetters, mapState} from 'vuex'
import * as CommunicationDispositionStatus from '../constants/communication-disposition-status'
import * as CommunicationCurrentStatus from '../constants/communication-current-status'
import * as CommunicationResolution from '../constants/communication-resolution'
import * as CommunicationTypes from '../constants/communication-types'
import * as CommunicationDirection from '../constants/communication-direction'
import * as CallbackStatus from '../constants/callback-status'
import * as AnswerTypes from '../constants/answer-types'
import * as UploadedFileTypes from '../constants/uploaded-file-types'
import * as AgentStatus from '../constants/agent-status'

export default {
    mixins: [
        date_mixin,
        report_mixin,
        acl_mixin,
        communication_mixin,
        communication_info_mixin,
        user_info_mixin
    ],

    components: {
        RelativeTime,
        TargetUsersTree,
        'vue-load-image': VueLoadImage
    },

    props: {
        campaign_id: {required: false},
        workflow_id: {required: false},
        ring_group_id: {required: false},
        broadcast_id: {required: false},
        user_id: {required: false},
    },

    data() {
        return {
            reporting_url: localStorage.getItem('reporting_url'),
            auth: auth,
            env: null,
            loading: false,
            archive_loading: false,
            terminate_loading: false,
            barge_loading: false,
            call_loading: false,
            whisper_loading: false,
            webrtc_ready: false,
            campaign: null,
            search_text: '',
            communications: [],
            show_more_list: [],
            table_fields: [{
                text: 'Team',
                val: 'team'
            },
            {
                text: 'Ring Group',
                val: 'ring_group'
            }, {
                text: 'Sequence',
                val: 'workflow'
            }, {
                text: 'Broadcast',
                val: 'broadcast'
            }, {
                text: 'Start Time',
                val: 'created_at'
            }, {
                text: 'Talk Time',
                val: 'talk_time'
            }, {
                text: 'Wait Time',
                val: 'wait_time'
            }, {
                text: 'Hold Time',
                val: 'hold_time'
            }, {
                text: 'Duration',
                val: 'duration'
            }, {
                text: 'Resolution',
                val: 'resolution2'
            }, {
                text: 'Contact',
                val: 'lead_number'
            }, {
                text: 'Location',
                val: 'lead_location'
            }, {
                text: 'Lines',
                val: 'line'
            }, {
                text: 'User',
                val: 'user'
            }, {
                text: 'Attempting',
                val: 'attempting_users'
            }, {
                text: 'Transferred From',
                val: 'transfer_prior_user_ids'
            }, {
                text: 'Transferred To',
                val: 'transfer_target_user_ids'
            }, {
                text: 'Cold Transferred?',
                val: 'in_cold_transfer'
            }, {
                text: 'Transfer Type',
                val: 'transfer_type'
            }, {
                text: 'Callback Status',
                val: 'callback_status'
            }, {
                text: 'Queue Resolution',
                val: 'queue_resolution2'
            }, {
                text: 'Email',
                val: 'email'
            }, {
                text: 'Creator Type',
                val: 'creator_type'
            }, {
                text: 'Tags',
                val: 'tags'
            }, {
                text: 'Notes',
                val: 'notes'
            }, {
                text: 'CSAT Score',
                val: 'csat_score'
            }, {
                text: 'Operations',
                val: 'operations'
            }],
            checked_table_fields: ['disposition_status', 'team', 'incoming_number', 'ring_group', 'created_at', 'talk_time', 'lead_number', 'user', 'notes', 'operations'],
            search_fields: ['lead_number', 'contact.name'],
            pagination: {
                type: Object,
                required: true
            },
            pagination_pages: {
                type: Object,
                required: true
            },
            pagination_loading: false,
            pageNumbers: [],
            table_height: 769,
            extra_height: 0,
            is_first_load: true,
            communications_count_loading: true,
            show_report_button_row_id: null,
            CommunicationDispositionStatus,
            CommunicationCurrentStatus,
            CommunicationResolution,
            CommunicationTypes,
            CommunicationDirection,
            CallbackStatus,
            AnswerTypes,
            UploadedFileTypes,
            AgentStatus,
        }
    },

    computed: {
        ...mapGetters({
            communicationsCount: 'getCommunicationsCount'
        }),

        ...mapGetters({
            filter: 'getFilter',
        }),

        ...mapState(['ring_groups', 'workflows', 'broadcasts', 'disposition_statuses', 'call_dispositions', 'on_call', 'teams']),
        ...mapState('cache', ['current_company', 'comm_table_fields', 'show_graph']),

        expandableCommunicationsId() {
            // check if loaded
            if (this.communications) {
                return this.communications.filter(communication => [CommunicationTypes.SMS, CommunicationTypes.FAX].includes(communication.type)).map(item => {
                    return item.id
                })
            }
        },

        allTableFields() {
            return _.map(this.table_fields, item => {
                return item.val
            })
        },

        isAnAgentPermittedToBargeAndWhisperOnCall() {
            return this.hasRole('Company Agent') && this.hasPermissionTo('barge and whisper on call')
        }
    },

    created() {
        this.checked_table_fields = this.comm_table_fields
        if (this.hasPermissionTo('list communication')) {
            this.getCommunications()
        }

        VueEvent.listen('webrtc_ready', (ready) => {
            this.webrtc_ready = ready
        })

        VueEvent.listen('new_communication', (data) => {

            if (!this.displayCommunicationsFilteredByTeams(data)) {
                return
            }

            // ignore all other communication types
            if (![CommunicationTypes.CALL, CommunicationTypes.SMS, CommunicationTypes.RVM, CommunicationTypes.EMAIL].includes(data.type)) {
                return
            }
            // disable live dashboard for reporters
            if (this.hasReporterAccess) {
                return
            }
            // check data loaded
            if (this.pagination.prev_page_url === null) {
                // check new communication exists in the old list
                let found = this.communications.filter(communication => {
                    return communication.id === data.id
                })
                if (!found.length) {
                    if (this.checkCommunicationMatchesSearch(data) && this.checkCommunicationMatchesFilters(data) && this.checkCommunicationMatchesUserAccessibility(data) && this.checkCommunicationMatchesCampaign(data) && this.checkCommunicationMatchesWorkflow(data) && this.checkCommunicationMatchesUser(data) && this.checkCommunicationMatchesRingGroup(data) && this.checkCommunicationMatchesBroadcast(data)) {
                        this.pagination.total += 1
                        // push new data to top of array
                        this.communications.unshift(data)

                        if (this.communications.length > this.filter.per_page) {
                            // push out last data from bottom of array
                            this.communications.pop()
                        }
                    }
                }
            }
        })

        VueEvent.listen('update_communication', (data) => {

            if (!this.displayCommunicationsFilteredByTeams(data)) {
                return
            }

            // ignore all other communication types
            if (![CommunicationTypes.CALL, CommunicationTypes.SMS, CommunicationTypes.RVM, CommunicationTypes.EMAIL].includes(data.type)) {
                return
            }
            // disable live dashboard for reporters
            if (this.hasReporterAccess) {
                return
            }
            // check data loaded
            if (this.pagination.prev_page_url === null) {
                // check new communication exists in the old list
                let found = this.communications.filter(communication => {
                    return communication.id === data.id
                })
                if (found.length) {
                    // update communication
                    data = _.merge(found[0], data)
                    if (this.checkCommunicationMatchesSearch(data) && this.checkCommunicationMatchesFilters(data) && this.checkCommunicationMatchesUserAccessibility(data) && this.checkCommunicationMatchesCampaign(data) && this.checkCommunicationMatchesWorkflow(data) && this.checkCommunicationMatchesUser(data) && this.checkCommunicationMatchesRingGroup(data) && this.checkCommunicationMatchesBroadcast(data)) {
                        this.$set(this.communications, this.communications.indexOf(found[0]), data)
                    } else {
                        this.communications = this.communications.filter(communication => {
                            return communication.id !== data.id
                        })
                        this.pagination.total -= 1
                    }
                } else {
                    // add the communication if it's not already there and if it matches the criteria
                    if (this.checkCommunicationMatchesSearch(data) && this.checkCommunicationMatchesFilters(data) && this.checkCommunicationMatchesUserAccessibility(data) && this.checkCommunicationMatchesCampaign(data) && this.checkCommunicationMatchesWorkflow(data) && this.checkCommunicationMatchesUser(data) && this.checkCommunicationMatchesRingGroup(data) && this.checkCommunicationMatchesBroadcast(data) && this.pagination.current_page === 1) {
                        this.pagination.total += 1
                        // push new data to top of array
                        this.communications.unshift(data)

                        if (this.communications.length > this.filter.per_page) {
                            // push out last data from bottom of array
                            this.communications.pop()
                        }
                    }
                }
            }
        })

        VueEvent.listen('delete_communication', (data) => {
            // check data loaded
            if (this.pagination.prev_page_url === null) {
                // try to find the communication
                let found = this.communications.find(communication => communication.id === data.id)
                if (found) {
                    // remove it from the list
                    this.communications.splice(this.communications.indexOf(found), 1)
                    this.pagination.total -= 1
                }
            }
        })
    },

    mounted() {
        this.search_text = ''
        this.filter.search_fields = []
        this.filter.search_text = ''
        // focus comm log table search on page visit
        if (this.$refs.commLogTableSearch) {
            this.$refs.commLogTableSearch.focus()
        }
    },

    methods: {
        sortTeams(a, b) {
            const teamA = a.teams && a.teams.length ? a.teams[0] : ''
            const teamB = b.teams && b.teams.length ? b.teams[0] : ''

            if (teamA === '' && teamB === '') return 0;
            if (teamA === '') return 1;
            if (teamB === '') return -1;

            return teamA.localeCompare(teamB)
        },
        showMore(comm) {
            if (this.show_more_list.includes(comm.id)) {
                let index = this.show_more_list.indexOf(comm.id);

                if (index > -1) {
                    this.show_more_list.splice(index, 1);
                }
            } else {
                this.show_more_list.push(comm.id)
            }
        },

        changeTableFields() {
            this.setCommTableFields(this.checked_table_fields)
        },

        checkSize() {
            let valid_sizes = ['xxl', 'xl', 'lg', 'md']
            if (valid_sizes.includes(this.$mq)) {
                return true
            }
            return false
        },

        getCampaign(id) {
            if (!id) {
                return null
            }
            let found = this.campaigns.find(campaign => campaign.id === id)
            if (found) {
                return found
            }

            return null
        },

        getRingGroup(id) {
            if (!id) {
                return null
            }
            let found = this.ring_groups.find(ring_group => ring_group.id === id)
            if (found) {
                return found
            }

            return null
        },

        getWorkflow(id) {
            if (!id) {
                return null
            }
            let found = this.workflows.find(workflow => workflow.id === id)
            if (found) {
                return found
            }

            return null
        },

        getBroadcast(id) {
            if (!id) {
                return null
            }
            let found = this.broadcasts.find(broadcast => broadcast.id === id)
            if (found) {
                return found
            }

            return null
        },

        getCommunications(url = null) {
            this.source.cancel('getCommunications canceled by the user.')
            this.source = this.CancelToken.source()
            this.loading = true
            this.pagination_loading = true
            this.filter.search_fields = this.search_fields
            this.filter.search_text = this.search_text
            if (!url) {
                url = '/api/v1/reports/communications'
            }
            this.getCommunicationsCount()

            const params = {...this.filter}

            delete params.timezone;
            delete params.contact_timezone;

            return axios.get(url, {
                params,
                cancelToken: this.source.token
            }).then(res => {
                this.pagination = res.data
                this.pagination_pages = _.clone(this.pagination)
                delete this.pagination_pages.data
                this.pagination_loading = false
                this.pageNumbers = this.getPageNumbers()
                this.communications = res.data.data
                this.extra_height = 0
                this.communications.map((o) => {
                    o.tag_ids = o.tags.map((a) => a.id)
                    if (o.tag_ids.length > 0) {
                        this.extra_height += (o.tag_ids.length * 23) - 6
                    }
                })
                this.resize()
                this.loading = false

                return Promise.resolve(res)
            }).catch(err => {
                if (axios.isCancel(err)) {
                    console.log('Request canceled', err.message)

                    // return Promise.reject(err)
                } else {
                    console.log(err)
                    this.loading = false
                    this.pagination_loading = false

                    return Promise.reject(err)
                }
            })
        },

        getCommunicationsCount() {
            if (this.countSource) {
                this.countSource.cancel('getCommunicationsCount canceled by the user.')
            }
            this.countSource = this.CancelToken.source()
            this.communications_count_loading = true
            this.filter.search_fields = this.search_fields
            this.filter.search_text = this.search_text
            this.setCommunicationsCount()

            const params = {...this.filter}

            delete params.timezone;
            delete params.contact_timezone;

            return axios.get(this.reporting_url + '/api/v1/reports/communications/count', {
                params,
                cancelToken: this.countSource.token
            }).then(res => {
                this.setCommunicationsCount(res.data)
                this.communications_count_loading = false
                return Promise.resolve(res)
            }).catch(err => {
                this.communications_count_loading = false
                if (axios.isCancel(err)) {
                    console.log('Request canceled', err.message)

                    // return Promise.reject(err)
                } else {
                    console.log(err)

                    return Promise.reject(err)
                }
            })
        },

        archiveCommunication(communication_id) {
            this.$confirm('Archiving communication will remove it from all reports and plots. Continue?', 'Warning', {
                confirmButtonText: 'OK',
                cancelButtonText: 'Cancel',
                customClass: 'width-500 fixed',
                type: 'warning'
            }).then(() => {
                this.deleteCommunication(communication_id)
            }).catch(() => {
                // archive canceled
            })
        },

        deleteCommunication(communication_id) {
            this.source.cancel('deleteCommunication canceled by the user.')
            this.source = this.CancelToken.source()
            this.archive_loading = true
            this.loading = true
            axios.delete(`/api/v1/communication/${communication_id}`, {
                cancelToken: this.source.token
            }).then(res => {
                this.archive_loading = false
                this.loading = false
                // trigger event manually to remove the communication from the list
                VueEvent.fire('delete_communication', {id: communication_id})
                // send an notification to inform user the communication is archived
                this.$notify({
                    offset: 95,
                    title: 'Communication',
                    message: 'Communication archived successfully.',
                    type: 'success',
                    showClose: true
                })
            }).catch(err => {
                if (axios.isCancel(err)) {
                    console.log('Request canceled', err.message)

                    // return Promise.reject(err)
                } else {
                    this.loading = false
                    this.archive_loading = false

                    this.$root.handleErrors(err.response)
                }
            })
        },

        terminateCommunication(communication_id) {
            this.$confirm('Terminating communication will forcefully dispose it. Continue?', 'Warning', {
                confirmButtonText: 'OK',
                cancelButtonText: 'Cancel',
                customClass: 'width-500 fixed',
                type: 'warning'
            }).then(() => {
                this.forceTerminateCommunication(communication_id)
            }).catch(() => {
                // terminate canceled
            })
        },

        forceTerminateCommunication(communication_id) {
            this.source.cancel('forceTerminateCommunication canceled by the user.')
            this.source = this.CancelToken.source()
            this.terminate_loading = true
            this.loading = true
            axios.post(`/api/v1/communication/${communication_id}/force-terminate`, {
                cancelToken: this.source.token
            }).then(res => {
                this.terminate_loading = false
                this.loading = false
                // send an notification to inform user the communication is terminated
                this.$notify({
                    offset: 95,
                    title: 'Communication',
                    message: 'Communication terminated successfully.',
                    type: 'success',
                    showClose: true
                })
            }).catch(err => {
                if (axios.isCancel(err)) {
                    console.log('Request canceled', err.message)

                    // return Promise.reject(err)
                } else {
                    this.loading = false
                    this.terminate_loading = false

                    this.$root.handleErrors(err.response)
                }
            })
        },

        bargeCommunicationDialog(communication) {
            this.$confirm('Do you want to barge into this call? You\'ll be muted by default. If you unmute yourself, both parties will hear you.', 'Confirm', {
                confirmButtonText: 'OK',
                cancelButtonText: 'Cancel',
                customClass: 'width-500 fixed',
                type: 'info'
            }).then(() => {
                this.bargeCommunication(communication)
            }).catch(() => {
                // barge canceled
            })
        },

        bargeCommunication(communication) {
            if (this.forceTalk) {
                let talk_url = this.getTalkUrl()

                if (talk_url) {
                    talk_url += '?call=barge:' + communication.id
                    this.openInNewTab(talk_url)
                }
            } else {
                VueEvent.fire('make_new_call', {
                    task: 'barge:' + communication.id,
                    phone_number: communication.lead_number,
                    campaign_id: communication.campaign_id
                })
            }
        },

        whisperCommunicationDialog(communication) {
            this.$confirm('Do you want to whisper to the agent of this call? Note that you will be muted by default.', 'Confirm', {
                confirmButtonText: 'OK',
                cancelButtonText: 'Cancel',
                customClass: 'width-500 fixed',
                type: 'info'
            }).then(() => {
                this.whisperCommunication(communication)
            }).catch(() => {
                // whisper canceled
            })
        },

        whisperCommunication(communication) {
            if (this.forceTalk) {
                let talk_url = this.getTalkUrl()

                if (talk_url) {
                    talk_url += '?call=whisper:' + communication.id
                    this.openInNewTab(talk_url)
                }
            } else {
                VueEvent.fire('make_new_call', {
                    task: 'whisper:' + communication.id,
                    phone_number: communication.lead_number,
                    campaign_id: communication.campaign_id
                })
            }
        },

        openInNewTab(url) {
            let win = window.open(url, '_blank')
            win.focus()
        },

        checkBlur: function (e) {
            if (e.currentTarget.getAttribute('aria-expanded') == 'true') {
                e.currentTarget.blur()
            }
        },

        getPageNumbers() {
            if (!this.pagination || !this.pagination.to) {
                return []
            }
            let pagesArray = []
            for (let page = 1; page <= this.pagination.last_page; page++) {
                pagesArray.push(page)
            }
            return pagesArray
        },

        nextPage($event) {
            $event.target.blur()
            if (this.loading) {
                return
            }
            delete this.filter.page
            this.getCommunications(this.pagination.next_page_url)
                .then(res => {
                    this.$emit('paginate')
                })
                .catch(err => {
                    console.log(err)
                })
        },

        previousPage($event) {
            $event.target.blur()
            if (this.loading) {
                return
            }
            delete this.filter.page
            this.getCommunications(this.pagination.prev_page_url)
                .then(res => {
                    this.$emit('paginate')
                })
                .catch(err => {
                    console.log(err)
                })
        },

        changePage() {
            if (this.loading) {
                return
            }

            this.getCommunications()
                .then(res => {
                    this.$emit('paginate')
                })
                .catch(err => {
                    console.log(err)
                })
        },

        noClose(event) {
            if (event) {
                event.stopPropagation()
            }
        },

        changeRows(per_page) {
            this.filter.per_page = per_page
            this.changePage()
        },

        resize() {
            if (this.communications.length == 0) {
                this.table_height = 400
            } else if (this.communications.length < this.filter.per_page) {
                this.table_height = 59 + (this.communications.length * 71)
            } else {
                this.table_height = 59 + (this.filter.per_page * 71)
            }

            this.table_height += this.extra_height
        },

        checkField(field) {
            if (this.checked_table_fields.indexOf(field) !== -1) {
                return true
            } else {
                return false
            }
        },

        checkCommunicationMatchesFilters(communication) {
            // if answer status filter is other than all
            if (this.filter.answer_status !== 'all') {
                // handle live & hold as a special case
                if (['live', 'hold', 'queued'].includes(this.filter.answer_status) && communication.disposition_status2 !== CommunicationDispositionStatus.DISPOSITION_STATUS_INPROGRESS_NEW) {
                    return false
                }
                // check the communication disposition status matches the answer status filter
                if (this.filter.answer_status === 'answered' && communication.disposition_status2 !== CommunicationDispositionStatus.DISPOSITION_STATUS_COMPLETED_NEW) {
                    return false
                }
                if (this.filter.answer_status === 'unanswered' && ![CommunicationDispositionStatus.DISPOSITION_STATUS_ABANDONED_NEW, CommunicationDispositionStatus.DISPOSITION_STATUS_MISSED_NEW, CommunicationDispositionStatus.DISPOSITION_STATUS_VOICEMAIL_NEW].includes(communication.disposition_status2)) {
                    return false
                }
                if (this.filter.answer_status === 'missed' && communication.disposition_status2 !== CommunicationDispositionStatus.DISPOSITION_STATUS_MISSED_NEW) {
                    return false
                }
                if (this.filter.answer_status === 'abandoned' && communication.disposition_status2 !== CommunicationDispositionStatus.DISPOSITION_STATUS_ABANDONED_NEW) {
                    return false
                }
                if (this.filter.answer_status === 'voicemail' && ![CommunicationDispositionStatus.DISPOSITION_STATUS_ABANDONED_NEW, CommunicationDispositionStatus.DISPOSITION_STATUS_MISSED_NEW, CommunicationDispositionStatus.DISPOSITION_STATUS_VOICEMAIL_NEW].includes(communication.disposition_status2)) {
                    return false
                }
                if (this.filter.answer_status === 'in-progress' && communication.disposition_status2 !== CommunicationDispositionStatus.DISPOSITION_STATUS_INPROGRESS_NEW) {
                    return false
                }
                if (this.filter.answer_status === 'failed' && communication.disposition_status2 !== CommunicationDispositionStatus.DISPOSITION_STATUS_FAILED_NEW) {
                    return false
                }
                if (this.filter.answer_status === 'deadend' && communication.disposition_status2 !== CommunicationDispositionStatus.DISPOSITION_STATUS_DEADEND_NEW) {
                    return false
                }
            }
            // if campaign filter is selected
            if (this.filter.campaigns.length > 0) {
                // check the communication campaign matches the campaign filter
                if (this.filter.campaigns.indexOf(communication.campaign_id) < 0) {
                    return false
                }
            }
            // if workflow filter is selected
            if (this.filter.workflows.length > 0) {
                // check the communication campaign matches the campaign filter
                if (this.filter.workflows.indexOf(communication.workflow_id) < 0) {
                    return false
                }
            }
            // if user filter is selected
            if (this.filter.users.length > 0) {
                // check the communication user matches the user filter
                if (this.filter.users.indexOf(communication.user_id) < 0) {
                    return false
                }
            }
            // if number filter is selected
            if (this.filter.incoming_numbers.length > 0) {
                // check the communication number matches the number filter
                if (this.filter.incoming_numbers.indexOf(communication.incoming_number_id) < 0) {
                    return false
                }
            }
            // if ring groups filter is selected
            if (this.filter.ring_groups.length > 0) {
                // check the communication ring group matches the ring group filter
                if (this.filter.ring_groups.indexOf(communication.ring_group_id) < 0) {
                    return false
                }
            }
            // if broadcasts filter is selected
            if (this.filter.broadcasts.length > 0) {
                // check the communication broadcast matches the broadcast filter
                if (this.filter.broadcasts.indexOf(communication.broadcast_id) < 0) {
                    return false
                }
            }
            // if direction filter is selected
            if (this.filter.direction !== 'all') {
                // check the communication direction matches the direction filter
                if (this.filter.direction == 'inbound' && communication.direction != 1) {
                    return false
                } else if (this.filter.direction == 'outbound' && communication.direction != 2) {
                    return false
                }
            }
            // checks first time conversations filter is selected and matches the communication
            if (this.filter.first_time_only && !communication.first_time_caller) {
                return false
            }
            // checks date range filter matches communication start time
            if (this.filter.from_date && this.filter.to_date && !this.utcToLocalizedMoment(communication.created_at).isBetween(this.filter.from_date, this.filter.to_date)) {
                return false
            }
            // checks min talk time filter matches communication talk time
            if (this.filter.min_talk_time > communication.talk_time) {
                return false
            }
            // if type of communication filter is selected
            if (this.filter.type !== 'all') {
                // check type of communication matches the type of communication filter
                if (this.filter.type == 'call' && communication.type != CommunicationTypes.CALL) {
                    return false
                } else if (this.filter.type == 'sms' && communication.type != CommunicationTypes.SMS) {
                    return false
                } else if (this.filter.type == 'email' && communication.type != CommunicationTypes.EMAIL) {
                    return false
                } else if (this.filter.type == 'fax' && communication.type != CommunicationTypes.FAX) {
                    return false
                }
            }
            // checks tags filter matches communication tags
            if (this.filter.tags.length > 0 && communication.tags.length <= 0) {
                return false
            }
            // checks untagged only filter matches communication tags
            if (this.filter.untagged_only && communication.tags.length > 0) {
                return false
            }
            // checks exclude automated communications filter and matches the communication
            if (this.filter.exclude_automated_communications && communication.workflow_id) {
                return false
            }
            return true
        },

        checkCommunicationMatchesCampaign(communication) {
            // checks if communication belongs to this campaign
            if (this.campaign_id) {
                if (communication.campaign_id != this.campaign_id) {
                    return false
                }
            }

            return true
        },

        checkCommunicationMatchesRingGroup(communication) {
            // checks if communication belongs to this ring group
            if (this.ring_group_id) {
                if (communication.ring_group_id != this.ring_group_id) {
                    return false
                }
            }

            return true
        },

        checkCommunicationMatchesBroadcast(communication) {
            // checks if communication belongs to this broadcast
            if (this.broadcast_id) {
                if (communication.broadcast_id != this.broadcast_id) {
                    return false
                }
            }

            return true
        },

        checkCommunicationMatchesUser(communication) {
            // checks if communication belongs to this user
            if (this.user_id) {
                if (communication.user_id && communication.user_id != this.user_id) {
                    return false
                }
                // check if we have tried to call this user
                if (!communication.user_id && communication.target_users && !communication.target_users.find(user_id => user_id == this.user_id)) {
                    return false
                }
            }

            return true
        },

        checkCommunicationMatchesWorkflow(communication) {
            // checks if communication belongs to this workflow
            if (this.workflow_id) {
                if (communication.workflow_id != this.workflow_id) {
                    return false
                }
            }

            return true
        },

        checkCommunicationMatchesSearch(communication) {
            // checks if communication matches search
            if (this.search_text != '') {
                for (let search_field of this.search_fields) {
                    if (communication[search_field]) {
                        if (communication[search_field].toString().indexOf(this.search_text) > -1) {
                            return true
                        }
                    }
                }

                return false
            }

            return true
        },

        callContact(contact, phone_number) {
            // check contact has timezone or not
            if (contact.timezone) {
                // if have timezone check is it day time?
                const start_day = moment().tz(contact.timezone).hour(8).minute(0).second(0)
                const end_day = moment().tz(contact.timezone).hour(18).minute(0).second(0)
                const contact_local_time = moment().tz(contact.timezone)
                if (!contact_local_time.isBetween(start_day, end_day)) {
                    return this.$confirm(`This is outside the lead's day time. Do you want to make a call? It's ${contact_local_time.format('hh:mm A')} for ${contact.name}.`, 'Call Lead', {
                        confirmButtonText: 'OK',
                        cancelButtonText: 'Cancel',
                        customClass: 'width-500 fixed',
                        type: 'warning'
                    }).then(() => {
                        this.makeCall(contact, phone_number)
                    }).catch(() => {
                    })
                }
            }

            this.makeCall(contact, phone_number)
        },

        makeCall(contact, phone_number) {
            if (this.forceTalk) {
                let talk_url = this.getTalkUrl()

                if (talk_url) {
                    talk_url += '?call=' + phone_number
                    this.openInNewTab(talk_url)
                }
            } else {
                if (this.auth.user.profile.enabled_two_legged_outbound) {
                    let message = 'We will call your secondary phone'
                    message += ' on ' + this.auth.user.profile.secondary_phone_number
                    message += ` and connect you with ${contact.name}. Proceed?`
                    return this.$confirm(message, 'Going old school?', {
                        confirmButtonText: 'OK',
                        cancelButtonText: 'Cancel',
                        customClass: 'width-500 fixed',
                        type: 'warning'
                    }).then(() => {
                        this.makeTwoLeggedCall(contact, phone_number)
                    }).catch(() => {
                    })
                }

                // make regular call
                VueEvent.fire('make_new_call', {
                    phone_number: phone_number
                })
            }
        },

        makeTwoLeggedCall(contact, phone_number) {
            this.call_loading = contact.id
            axios.post('/api/v1/contact/' + contact.id + '/make-two-legged-call', {
                phone_number: phone_number
            }).then(res => {
                this.$notify({
                    offset: 95,
                    title: 'Call Lead',
                    message: `We are calling your phone to connect you to ${contact.name}`,
                    type: 'success',
                    showClose: true
                })
                this.call_loading = null
            }).catch(err => {
                this.$root.handleErrors(err.response)
                this.call_loading = null
            })
        },

        gotoMessages(contact_id) {
            this.$router.push({name: 'Contact', params: {contact_id: contact_id}}).catch(err => {
            })
        },

        gotoCommunicationInfo(communication_id) {
            this.$router.push({name: 'Communication', params: {communication_id: communication_id}})
        },

        getVisibleStatus(communication) {
            if (communication.current_status2 != CommunicationCurrentStatus.CURRENT_STATUS_COMPLETED_NEW) {
                return this.$options.filters.capitalize(this.$options.filters.replaceDash(this.$options.filters.translateCurrentStatusText(communication.current_status2)))
            }

            return this.$options.filters.capitalize(this.$options.filters.replaceDash(this.$options.filters.translateDispositionStatusText(communication.disposition_status2)))
        },

        getVisibleResolution(communication) {
            return this.$options.filters.capitalize(this.$options.filters.replaceDash(this.$options.filters.translateResolutionText(communication.resolution2)))
        },

        getVisibleCallbackStatus(communication) {
            return this.$options.filters.capitalize(this.$options.filters.replaceDash(this.$options.filters.translateCallbackStatusText(communication.callback_status)))
        },

        getVisibleQueueResolution(communication) {
            return this.$options.filters.capitalize(this.$options.filters.replaceDash(this.$options.filters.translateQueueResolutionText(communication.queue_resolution2)))
        },

        toggleGraph() {
            this.setShowGraph(!this.show_graph)
        },

        searchCommsChange() {
            if (this.search_text.length < 3 && this.search_text.length != 0) {
                return
            }
            this.filter.page = 1
            this.getCommunications()
        },

        isCall(comm_type) {
            return comm_type === CommunicationTypes.CALL
        },

        isCallInProgress(disposition_status, current_status) {
            return disposition_status === CommunicationDispositionStatus.DISPOSITION_STATUS_INPROGRESS_NEW &&
                current_status === CommunicationCurrentStatus.CURRENT_STATUS_INPROGRESS_NEW
        },

        userCanBargeAndWhisper(row) {
            return (this.hasRole('Company Admin') ||
                    this.isAnAgentPermittedToBargeAndWhisperOnCall) &&
                !this.on_call &&
                this.isCall(row.type) &&
                this.isCallInProgress(row.disposition_status2, row.current_status2) &&
                !this.isSameWithCurrentUserId(row.user_id)
        },

        isSameWithCurrentUserId(user_id) {
            if (this.auth && this.auth.user && this.auth.user.profile) {
                return this.auth.user.profile.id === user_id
            }

            return false
        },

        displayCommunicationsFilteredByTeams(data) {
            if (this.filter && this.filter.teams && this.filter.teams.length > 0) {
    
                if(!data.teams || data.teams.length == 0) {
                    return false
                }

                const filtered_teams_by_id = this.teams.filter(team => {
                    return this.filter.teams.includes(team.id)
                })

                if(!filtered_teams_by_id){
                    return false
                }

                const filtered_teams_by_name = filtered_teams_by_id.filter(team => {
                    return data.teams.includes(team.name)
                })

                if(!filtered_teams_by_name){
                    return false
                }
            
                const hasMatchingTeam = this.teams.some(teamA => 
                    filtered_teams_by_name.some(teamB => teamA.id === teamB.id)
                );

                if (!hasMatchingTeam) {
                    return false
                }

                return true
            }

            return true
        },

        cellEnter: _.throttle(function (row, column, cell, event) {
            this.show_report_button_row_id = row.id
        }, 50),

        ...mapActions('cache', ['setCommTableFields', 'setShowGraph']),
        ...mapActions(['setCommunicationsCount'])
    },

    beforeCreate() {
        axios.get('/build-info.json')
            .then(res => {
                this.env = res.data.env
            })
            .catch(err => {
                console.log(err)
            })
    }
}
</script>
