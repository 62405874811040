<template>
    <el-dialog
        title="Delete Chatbot"
        :visible.sync="form_visible"
        width="30%"
        :before-close="handleClose"
        center>
        
        <p class="font-weight-bold">Are you sure you want to delete this bot?</p>

        <el-button
            round
            type="danger"
            :disabled="loading"
            @click="deleteChatbot()">
            <i v-if="loading" class="fa fa-spinner fa-spin"/>
            <i v-else class="fa fa-trash-o" aria-hidden="true"/>
            <strong> Delete</strong>
        </el-button>

    </el-dialog>
</template>

<script>
export default {
    name: 'delete-chatbot',
    props: {
        form_visible: {
            required: true,
            default: false
        },
        chatbot: {
            required: true,
            default: {
                id: null
            }
        },
    },
    data() {
        return {
            loading: false
        }
    },
    methods: {
        /**
         * Deletes a chatbot
         *
         * @return {void}
         */
        deleteChatbot() {
            this.setLoading(true)
            axios.delete(`/api/v1/bots/builder/bot/${this.chatbot.id}`).then(res => {
                this.$emit('submit')
                this.$notify.success({
                    offset: 95,
                    title: 'Chatbots',
                    message: 'Chatbot deleted successfully.',
                })
                this.setLoading(false)
            }).catch(err => {
                this.$root.handleErrors(err.response)
                console.log(err)
            })
        },

        /**
         * Set loading state
         *
         * @param {boolean} state
         *
         * @return {void}
         */
        setLoading(state) {
            this.loading = state
        },

        /**
         * Closes this dialog
         *
         * @return {void}
         */
        handleClose() {
            this.$emit('discard')
        }
    }
}
</script>