var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("p", { staticClass: "font-weight-bold side-panel-title mb-0" }, [
        _vm._v("Fallback"),
      ]),
      _vm._v(" "),
      !_vm.hasFallbacks
        ? _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.addFallback.apply(null, arguments)
                },
              },
            },
            [_vm._v("\n        Add fallback\n    ")]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm._l(_vm.fallbacks_, function (fallback, id) {
        return _c(
          "div",
          { key: id, staticClass: "pt-3" },
          [
            _c("FallbackTexts", {
              attrs: { fallback: fallback },
              on: {
                "update:fallback": function ($event) {
                  fallback = $event
                },
                change: _vm.updateFallback,
              },
            }),
          ],
          1
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }