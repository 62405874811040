<template>
    <div v-if="hasPermissionTo('create contact') && contact">
        <!--create new phone number-->
        <el-dialog title="Create a new phone number"
                   class="dialog-padding auto"
                   :width="isLargeEnough ? '400px' : '100%'"
                   :visible.sync="hide_add"
                   :before-close="beforeCloseModal"
                   data-testid="add-contact-phone-number-dialog"
                   append-to-body>
            <el-form ref="add_phone_number"
                     class="p-0"
                     label-position="top"
                     :rules="rules_phone_number"
                     :model="phone_number"
                     data-testid="add-contact-phone-number-form"
                     @submit.prevent.native="addPhoneNumber">
                <el-form-item prop="title"
                              label="Title">
                    <div class="width-400">
                        <el-input v-model="phone_number.title"
                                  data-testid="add-phone-title-input"
                                  @input="preValidateForm('add_phone_number')">
                        </el-input>
                    </div>
                </el-form-item>
                <el-form-item prop="phone_number"
                              label="Phone Number">
                    <div class="width-400">
                        <el-input v-model="phone_number.phone_number"
                                  data-testid="add-phone-number-input"
                                  @input="preValidateForm('add_phone_number')">
                        </el-input>
                    </div>
                </el-form-item>
            </el-form>

            <div class="row pb-3">
                <div class="col-12">
                    <el-button type="success"
                               :disabled="loading_btn"
                               class="pull-right ml-2"
                               data-testid="add-phone-number-button"
                               @click="addPhoneNumber">
                        <i class="material-icons loader"
                           v-show="loading_btn">&#xE863;</i>
                        <i class="fa fa-plus"
                           v-show="!loading_btn"></i>
                        <span>Add</span>
                    </el-button>

                    <el-button type="info"
                               class="pull-right"
                               data-testid="add-phone-close-dialog-button"
                               @click="closeDialog">
                        <span class="fa fa-close"></span>
                        <span>Cancel</span>
                    </el-button>
                </div>
            </div>
        </el-dialog>

        <slot name="trigger">
            <el-button slot="reference"
                       type="text text-sm text-dark-greenish p-0"
                       data-testid="add-phone-number-slot-button"
                       @click="hide_add = true">
                <i class="el-icon-circle-plus-outline"></i>
                Add a phone number
            </el-button>
        </slot>
    </div>
</template>

<script>
    import auth from '../auth'
    import {mapState} from 'vuex'
    import {
        acl_mixin,
        validator_mixin,
        form_validation_mixin
    } from '../mixins'

    export default {
        mixins: [
            acl_mixin,
            validator_mixin,
            form_validation_mixin
        ],

        props: {
            contact: {
                required: true
            }
        },

        data() {
            return {
                auth: auth,
                loading_btn: false,
                hide_add: false,
                phone_number: {
                    title: null,
                    phone_number: null
                },
                rules_phone_number: {
                    phone_number: [
                        {
                            required: true,
                            message: 'Please provide a phone number',
                            trigger: 'blur'
                        },
                        {
                            required: true,
                            validator: this.phoneValidator,
                            trigger: 'blur'
                        },
                    ]
                }
            }
        },

        computed: {
            ...mapState('cache', ['current_company']),

            isLargeEnough() {
                let valid_sizes = ['xxl', 'xl', 'lg', 'md']
                if (valid_sizes.includes(this.$mq)) {
                    return true
                }
                return false
            }
        },

        methods: {
            addPhoneNumber() {
                if (this.validateForm('add_phone_number')) {
                    this.loading_btn = true
                    axios.post('/api/v1/contact/' + this.contact.id + '/phone-number', this.phone_number).then(res => {
                        this.loading_btn = false
                        this.hide_add = false
                        this.$emit('success', res.data)
                        this.resetPhoneNumber()
                    }).catch(err => {
                        this.$root.handleErrors(err.response)
                        this.loading_btn = false
                        this.resetPhoneNumber()
                    })
                }
            },

            resetPhoneNumber() {
                this.phone_number = {
                    title: null,
                    phone_number: null
                }
            },

            beforeCloseModal(done) {
                if (this.phone_number) {
                    this.$confirm('Are you sure you want to leave? Your changes will not be saved.', 'Warning', {
                        confirmButtonText: 'Yes, Leave',
                        cancelButtonText: 'No, Stay',
                        customClass: 'width-500 fixed',
                        type: 'warning'
                    }).then(res => {
                        this.resetPhoneNumber()
                        this.preValidateForm('add_phone_number', true)
                        done()
                    }).catch(() => {

                    })
                } else {
                    this.resetPhoneNumber()
                    this.preValidateForm('add_phone_number', true)
                    done()
                }
            },

            closeDialog() {
                this.preValidateForm('add_phone_number', true)
                this.hide_add = false
            }
        },

        watch: {
            hide_add() {
                if (this.hide_add) {
                    this.$emit('hide')
                } else {
                    this.$emit('show')
                }
            }
        }
    }
</script>
