<template>
    <div>
        <div v-for="(links, category) in categorized_links" :key="category">
            <div class="row p-2">
                <div class="col-12 d-flex flex-row justify-content-between">
                    <h5>{{ category | ucwords }}</h5>
                    <el-popover
                        v-if="category.includes('CRM')"
                        ref="aloInfo"
                        placement="bottom-end"
                        width="550"
                        trigger="hover"
                        popper-class="alo-popover">
                        <div class="alo-default-app-selector-dialog">
                            We only support one CRM connected at a time. If you have a need for multiple CRMs, please contact us.
                        </div>
                        <button
                            slot="reference"
                            class="btn btn-xs btn-outline btn-default text-white pl-2 pr-2 alo-talk-info-button">
                            <span>
                                <img src="/assets/images/app-icons/info-icon-outline.png"
                                        alt="info-icon"/>
                            </span>
                        </button>
                    </el-popover>
                </div>
            </div>
            <hr class="no-top-margin">
            <div class="row p-2">
                <div v-if="!isCrmIntegrationsEnabled"
                     class="w-100 d-flex justify-content-center align-items-cente my-2">
                    <i class="fa fa-exclamation text-amber-10 text-2x mr-2"></i>
                    <span class="vertical-center text-lg font-weight-bold">
                        {{ integrationsNotAllowedMessage(current_company.created_at) }}
                    </span>
                </div>
                <div :key="index + '-' + link.name"
                     class="col-xl-4 col-lg-6 mb-2"
                     v-for="(link, index) in links"
                     v-if="link.show">
                    <el-card class="integration-box"
                             :class="{'border-0': !link.name || !link.image}"
                             :shadow="(!link.name || !link.image) ? 'never': 'always'"
                             v-if="!link.disabled && !link_without_settings.includes(link.name)"
                             v-loading="link.loading">
                        <div v-if="link.name && link.image"
                             class="row d-flex align-items-center">
                            <el-tooltip class="item"
                                        effect="dark"
                                        placement="top"
                                        v-if="link.is_beta">
                                <div slot="content">
                                    <small>
                                        This feature is in beta phase, so you can expect some changes. Feel free to send us feedback &#129321;
                                    </small>
                                </div>
                                <span class="nav-label beta-box">
                                    <b class="label beta-label">Beta</b>
                                </span>
                            </el-tooltip>

                            <div class="col-6">
                                <h2 class="text-md _600"
                                    v-if="link.name === 'custom_link_builder'">
                                    Custom Integration Link Builder
                                </h2>
                                <img :class="!link.status ? 'deactivated' : ''"
                                     :src="link.image"
                                     class="integration-boxes"
                                     style="max-width: 100%; height: 45px;"
                                     v-if="link.name === 'custom_link_builder'"/>
                                <img :class="!link.status ? 'deactivated' : ''"
                                     :src="link.image"
                                     class="integration-boxes"
                                     v-else/>
                                <div v-if="link.name === 'SMTP'">
                                    <span class="pl-4 font-weight-bold">{{ link.name }}</span>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="row">
                                    <div class="col-12 text-right">
                                        <el-popover v-if="!allowedToEnableIntegrationsPage()"
                                                    :ref="'settings-popover' + link.name"
                                                    placement="top"
                                                    width="200"
                                                    class="custom-popover"
                                                    popper-class="btn-primary"
                                                    trigger="hover">
                                            <custom-message-display :config="customMessage('integrations')" />
                                            <button v-if="link.name !== 'webhook'"
                                                    v-on:click="checkCrmAccess(link.name)"
                                                    slot="reference"
                                                    class="btn btn-sm ml-2 mb-2"
                                                    :disabled="!allowedToEnableIntegrationsPage() || !allowIntegrationSettingsView(link.name)"
                                                    :class="[allowIntegrationSettingsView(link.name) ? 'greenish' : 'border']">
                                                Settings
                                            </button>
                                            <div v-if="link.name === 'webhook'">
                                                <button class="btn btn-sm greenish ml-2 mb-2"
                                                        slot="reference"
                                                        disabled>
                                                    Settings
                                                </button>
                                            </div>
                                        </el-popover>

                                        <div v-else>
                                            <button v-if="showSettingsButtonForNonWebhook(link)"
                                                    v-on:click="checkCrmAccess(link.name)"
                                                    slot="reference"
                                                    class="btn btn-sm ml-2 mb-2"
                                                    :disabled="!allowIntegrationSettingsView(link.name)"
                                                    :class="[allowIntegrationSettingsView(link.name) ? 'greenish' : 'border']">
                                                Settings
                                            </button>
                                            <router-link v-if="link.name === 'webhook'"
                                                         :to="{ name: 'Webhooks' }">
                                                <button class="btn btn-sm greenish ml-2 mb-2">
                                                    Settings
                                                </button>
                                            </router-link>
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div v-if="showIfNotMultiGuesty(link)"
                                         class="col-12 text-right">
                                        <el-tooltip v-if="!allowedToEnableIntegrationsPage() && !link.status"
                                                    class="item"
                                                    effect="light"
                                                    content="To turn this integration on, click settings"
                                                    placement="left-start">
                                        </el-tooltip>
                                        <el-switch :disabled="!allowedToEnableIntegrationsPage() || !link.status"
                                                   active-color="#00a344"
                                                   v-model="link.status"
                                                   @change="updateStatus(link)">
                                        </el-switch>
                                    </div>
                                    <div v-else
                                         class="col-12 text-right">
                                        <el-switch disabled
                                            v-model="enabled"
                                            active-color="#00a344">
                                        </el-switch>
                                    </div>
                                </div>

                                <div v-if="link.connection_status == 2"
                                     class="row">
                                    <div class="col d-flex justify-content-end pt-2">
                                        <span v-if="!allowedToEnableIntegrationsPage()"
                                              class="text-danger text-right text-xs font-weight-normal mr-1">
                                            <i class="fa fa-exclamation-triangle"></i> <span class="border-bottom border-danger">Not Connected</span>
                                        </span>
                                        <el-popover
                                            placement="right"
                                            width="300"
                                            trigger="hover">
                                            <span class="text-dark">Try re-authenticating to {{ link.name | prettifyCamelCase | ucwords | fixIntegrationName }} by clicking <strong>Settings > Re-authenticate</strong> button.</span>
                                            <div class="text-primary"
                                                 slot="reference">
                                                <i class="fa fa-question-circle-o"></i>
                                            </div>
                                        </el-popover>
                                    </div>
                                </div>

                                <p class="_600 text-danger mt-2 mb-0 text-right"
                                   v-if="!link.is_required_fields_set && link.status && link.name !== 'webhook'">
                                    More Info Required
                                </p>
                            </div>
                        </div>
                    </el-card>
                    <el-card class="integration-box"
                             v-else-if="['execvision', 'contact_center_compliance', 'zapier'].includes(link.name)">
                        <div class="row d-flex align-items-center">
                            <div class="col-6">
                                <img class="integration-boxes"
                                     :src="link.image"/>
                            </div>
                            <div class="col-6">
                                <a class="btn btn-sm bluish pull-right"
                                   href="https://support.aloware.com/en/articles/9037436-how-to-integrate-aloware-with-zapier-a-comprehensive-guide"
                                   target="_blank"
                                   v-if="link.name === 'zapier' && allowedToEnableIntegrationsPage()">
                                    Check our tutorial
                                </a>
                                <el-popover v-if="!allowedToEnableIntegrationsPage()"
                                            ref="tutorial-popover"
                                            placement="top"
                                            width="200"
                                            class="custom-popover"
                                            popper-class="btn-primary"
                                            trigger="hover">
                                    <custom-message-display :config="customMessage('integrations')" />
                                </el-popover>
                                <button v-if="link.name === 'zapier' && !allowedToEnableIntegrationsPage()"
                                        class="btn btn-sm bluish pull-right cursor-default"
                                        v-popover:tutorial-popover
                                        disabled>
                                        Check our tutorial
                                </button>
                                <a class="btn btn-sm bluish pull-right"
                                   href="mailto:support@aloware.com"
                                   target="_blank"
                                   v-if="link.name !== 'zapier' && allowedToEnableIntegrationsPage()">
                                    Contact Us
                                </a>
                                <el-popover v-if="!allowedToEnableIntegrationsPage()"
                                            ref="contact-popover"
                                            placement="top"
                                            width="200"
                                            class="custom-popover"
                                            popper-class="btn-primary"
                                            trigger="hover">
                                    <custom-message-display :config="customMessage('integrations')" />
                                </el-popover>
                                <button v-if="link.name !== 'zapier' && !allowedToEnableIntegrationsPage()"
                                        class="btn btn-sm bluish pull-right cursor-default"
                                        v-popover:contact-popover
                                        disabled>
                                    Contact Us
                                </button>
                            </div>
                        </div>
                    </el-card>
                    <el-card class="integration-box"
                             v-else-if="link.name === 'aloware_click_to_call'">
                        <div class="row d-flex align-items-center">
                            <div class="col-6">
                                <h2 class="text-md _600">Aloware Click-To-Call</h2>
                                <img class="integration-boxes"
                                     src="/assets/images/ChromeWebStore_Badge_v2_496x150.png"
                                     style="max-width: 100%; height: 45px;"/>
                            </div>
                            <div class="col-6">
                                <a v-if="allowedToEnableIntegrationsPage()" class="btn btn-sm bluish pull-right"
                                   href="https://chrome.google.com/webstore/detail/kmchiimcbhaaadgpnelddelgiaeahobi"
                                   target="_blank">
                                    Add to Chrome
                                </a>
                                <el-popover v-if="!allowedToEnableIntegrationsPage()"
                                            ref="chrome-popover"
                                            placement="top"
                                            width="200"
                                            class="custom-popover"
                                            popper-class="btn-primary"
                                            trigger="hover">
                                    <custom-message-display :config="customMessage('integrations')" />
                                </el-popover>
                                <button v-if="!allowedToEnableIntegrationsPage()"
                                        class="btn btn-sm bluish pull-right cursor-default"
                                        v-popover:chrome-popover
                                        disabled>
                                    Add to Chrome
                                </button>
                            </div>
                        </div>
                    </el-card>
                    <el-card class="integration-box"
                             v-else-if="link.name === 'missing_integration'">
                        <div class="row d-flex align-items-center">
                            <div class="col-12">
                                <h2 class="text-md _600">Missing an integration?</h2>
                                <p class="text-md">Let us know and we will make it!</p>
                            </div>
                        </div>
                    </el-card>
                    <el-card :body-style="{ filter: 'grayscale(100%)' }"
                             class="integration-box"
                             v-else>
                        <div class="row d-flex align-items-center">
                            <div class="col-6">
                                <img :class="!link.status ? 'deactivated' : ''"
                                     :src="link.image"
                                     class="integration-boxes"/>
                            </div>
                            <div class="col-6">
                                <el-switch active-color="#00a344"
                                           class="pull-right"
                                           disabled>
                                </el-switch>
                            </div>
                        </div>
                    </el-card>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import auth from '../auth'
import {mapActions, mapState} from 'vuex'
import {crm_integration_access_mixin} from '../mixins'
import {acl_mixin, kyc_mixin} from '../mixins'
import groupBy from 'lodash/groupBy'
import CustomMessageDisplay from './kyc/custom-message-display'
import { PrismaticMessageEvent } from "@prismatic-io/embedded";

export default {
    components: {
        CustomMessageDisplay
    },

    mixins: [
        crm_integration_access_mixin, acl_mixin, kyc_mixin
    ],

    props: {
        links: {
            required: true
        },
        link_without_settings: {
            required: true
        }
    },

    data() {
        return {
            auth: auth,
            statics: {
                logo: null,
                logo_inverse: null,
                logo_square: null,
                logo_square_inverse: null,
                host: null,
                referer: null,
                name: null,
                domain: null,
                whitelabel: false,
                path: null
            },
            loading_whitelabel: true,
            env: null,
            show_cards: false,
            categorized_links: {},
            aloware_links: {
                hubspot: 'https://aloware.com/integrations/hubspot',
                pipedrive: 'https://aloware.com/integrations/pipedrive',
                zoho: 'https://aloware.com/integrations/zoho',
                guesty: 'https://aloware.com/integrations/guesty',
                google: 'https://aloware.com/integrations/google',
                gohighlevel: 'https://aloware.com/integrations/gohighlevel',
            },
            enabled: true,
        }
    },

    computed: {
        ...mapState({
            current_company: state => state.cache.current_company
        }),
    },

    created() {
        this.categorized_links = groupBy(this.links, function (n) {
            return n.category
        })

        const integration_name = this.$route.query.name
        const valid_integration_name = this.links.filter(value => value.name === integration_name).length

        if (integration_name && valid_integration_name && !this.link_without_settings.includes(integration_name)) {
            // Remove name param in query string once setting has been rendered
            this.selected_item = integration_name
            this.drawer = true
        }

        this.getStatics()
        axios.get('/build-info.json').then(res => {
            this.env = res.data.env
            // Only show the simpsocial integration when it's a simpsocial resold account
            if (this.current_company && this.current_company.reseller_id == 357 && this.env != 'production') {
                this.links.push({
                    'image': '/assets/images/simpsocial.png',
                    'name': 'simpsocial',
                    'status': false,
                    'disabled': false,
                    'loading': false,
                    'is_required_fields_set': false,
                })
            }
            this.fetchDetails()
        }).catch(err => {
            this.fetchDetails()
            console.log(err)
        })
    },

    mounted() {
        window.addEventListener('resize', this.onResize)
        window.addEventListener('message', this.processPrismaticEvents);
    },

    beforeDestroy() {
        window.removeEventListener('resize', this.onResize)
        window.removeEventListener('message', this.processPrismaticEvents);
    },

    methods: {
        showIfNotMultiGuesty(link) {
          return link.name === 'guesty' ? !this.current_company.is_multi_guesty : true;
        },
        showSettingsButtonForNonWebhook(link) {
            return link.name !== 'webhook' && (link.name === 'guesty' ? !this.current_company.is_multi_guesty : true);
        },
        getStatics() {
            this.loading_whitelabel = true
            axios.get('/get-statics').then(res => {
                this.statics = res.data
                this.loading_whitelabel = false
            }).catch(err => {
                console.log(err)
                this.$root.handleErrors(err.response)
                this.loading_whitelabel = false
            })
        },

        checkCrmAccess(link_name) {
            if (this.allowIntegrationSettingsView(link_name)) {
                this.$router.push({name: "Integrations", query: {tab: 'integrations', name: link_name}})
                this.$emit('selected', link_name)
            }
        },

        fetchDetails: function () {
            for (let key in this.links) {
                if (!this.links[key].disabled) {
                    //check if required fields are filled
                    axios.get('/api/v1/integration/' + this.links[key].name).then(res => {
                        let credentials = ''

                        if (res.data.integration !== null && res.data.integration.credentials && res.data.integration.credentials !== 'null') {
                            credentials = JSON.parse(res.data.integration.credentials)
                        }

                        switch (this.links[key].name) {
                            case 'google-analytics':
                                if (credentials !== '' && credentials.tracking_id) {
                                    this.links[key].is_required_fields_set = true
                                }
                                this.links[key].status = this.current_company.g_analytics_integration_enabled
                                this.links[key].loading = false

                                if (res.data?.integration?.connection_status) {
                                    this.links[key].connection_status = res.data.integration.connection_status
                                }
                                break
                            case 'zapier':
                                this.links[key].status = this.current_company.zapier_integration_enabled
                                this.links[key].loading = false
                                break
                            case 'pipedrive':
                                if (credentials !== '' && credentials.access_token) {
                                    this.links[key].is_required_fields_set = true
                                }
                                this.links[key].status = this.current_company.pipedrive_integration_enabled
                                this.links[key].loading = false

                                if (res.data?.integration?.connection_status) {
                                    this.links[key].connection_status = res.data.integration.connection_status
                                }
                                break
                            case 'hubspot':
                                if (credentials !== '' && credentials.access_token) {
                                    this.links[key].is_required_fields_set = true
                                }
                                this.links[key].status = this.current_company.hubspot_integration_enabled
                                this.links[key].loading = false

                                if (res.data?.integration?.connection_status) {
                                    this.links[key].connection_status = res.data.integration.connection_status
                                }
                                break
                            case 'slack':
                                if (credentials !== '' && credentials.api_token) {
                                    this.links[key].is_required_fields_set = true
                                }
                                this.links[key].status = this.current_company.slack_integration_enabled
                                this.links[key].loading = false

                                if (res.data?.integration?.connection_status) {
                                    this.links[key].connection_status = res.data.integration.connection_status
                                }
                                break
                            case 'helpscout':
                                if (credentials !== '' && credentials.access_token) {
                                    this.links[key].is_required_fields_set = true
                                }
                                this.links[key].status = this.current_company.helpscout_integration_enabled
                                this.links[key].loading = false

                                if (res.data?.integration?.connection_status) {
                                    this.links[key].connection_status = res.data.integration.connection_status
                                }
                                break
                            case 'gohighlevel':
                                if (credentials !== '' && credentials.access_token) {
                                    this.links[key].is_required_fields_set = true
                                }
                                this.links[key].status = this.current_company.gohighlevel_integration_enabled
                                this.links[key].loading = false

                                if (res.data?.integration?.connection_status) {
                                    this.links[key].connection_status = res.data.integration.connection_status
                                }
                                break
                            case 'salesforce':
                                this.links[key].status = this.current_company.salesforce_integration_enabled
                                this.links[key].loading = false
                                this.links[key].is_required_fields_set = true

                                if (res.data?.integration?.connection_status) {
                                  this.links[key].connection_status = res.data.integration.connection_status
                                }
                                break
                            case 'stripe':
                                // right now stripe doesn't need settings. ARR 08.12.19
                                if (true) {
                                    this.links[key].is_required_fields_set = true
                                }
                                this.links[key].status = this.current_company.stripe_integration_enabled
                                this.links[key].loading = false

                                if (res.data?.integration?.connection_status) {
                                    this.links[key].connection_status = res.data.integration.connection_status
                                }
                                break
                            case 'zoho':
                                if (credentials !== '' && credentials.access_token) {
                                    this.links[key].is_required_fields_set = true
                                }
                                this.links[key].status = this.current_company.zoho_integration_enabled
                                this.links[key].loading = false

                                if (res.data?.integration?.connection_status) {
                                    this.links[key].connection_status = res.data.integration.connection_status
                                }
                                break
                            case 'sendgrid':
                                if (credentials !== '' && credentials.api_token) {
                                    this.links[key].is_required_fields_set = true
                                }
                                this.links[key].status = this.current_company.sendgrid_integration_enabled
                                this.links[key].loading = false
                                break
                            case 'mailgun':
                                if (credentials !== '' && credentials.api_token) {
                                    this.links[key].is_required_fields_set = true
                                }
                                this.links[key].status = this.current_company.mailgun_integration_enabled
                                this.links[key].loading = false
                                break
                            case 'guesty':
                                if (credentials !== '' && credentials.api_token) {
                                    this.links[key].is_required_fields_set = true
                                }
                                this.links[key].status = this.current_company.guesty_integration_enabled
                                this.links[key].loading = false

                                if (res.data?.integration?.connection_status) {
                                    this.links[key].connection_status = res.data.integration.connection_status
                                }
                                break
                            case 'simpsocial':
                                this.links[key].is_required_fields_set = true
                                this.links[key].status = this.current_company.simpsocial_integration_enabled
                                this.links[key].loading = false
                                break
                            case 'webhook':
                                if (credentials) {
                                    this.links[key].is_required_fields_set = true
                                }
                                this.links[key].status = this.current_company.webhook_integration_enabled
                                this.links[key].loading = false
                                break
                            case 'gong':
                                if (credentials !== '' && (
                                    (credentials.access_key && credentials.access_key_secret) ||
                                    (credentials.access_token)
                                )) {
                                    this.links[key].is_required_fields_set = true
                                }
                                this.links[key].status = this.current_company.gong_integration_enabled
                                this.links[key].loading = false

                                if (res.data?.integration?.connection_status) {
                                    this.links[key].connection_status = res.data.integration.connection_status
                                }
                                break
                            case 'facebook':
                                if (credentials !== '' && credentials.access_token) {
                                    this.links[key].is_required_fields_set = true
                                }
                                this.links[key].status = this.current_company.facebook_integration_enabled
                                this.links[key].loading = false

                                if (res.data?.integration?.connection_status) {
                                    this.links[key].connection_status = res.data.integration.connection_status
                                }
                                break
                            case 'SMTP':
                                if (credentials !== '' && credentials.username && credentials.password) {
                                    this.links[key].is_required_fields_set = true
                                }
                                this.links[key].status = this.current_company.smtp_integration_enabled
                                this.links[key].loading = false

                                if (res.data && res.data.integration && res.data.integration.connection_status) {
                                    this.links[key].connection_status = res.data.integration.connection_status
                                }
                                break
                            case 'custom_link_builder':
                                this.links[key].is_required_fields_set = true
                                this.links[key].status = this.current_company.custom_link_builder_integration_enabled
                                this.links[key].loading = false

                                if (res.data?.integration?.connection_status) {
                                    this.links[key].connection_status = res.data.integration.connection_status
                                }
                                break
                            case 'google-calendar':
                                this.links[key].is_required_fields_set = true
                                this.links[key].status = this.current_company.g_calendar_integration_enabled
                                this.links[key].loading = false

                                if (res.data?.integration?.connection_status) {
                                    this.links[key].connection_status = res.data.integration.connection_status
                                }
                                break
                        }
                    })
                }
            }
        },

        updateStatus(link) {
            link.loading = true
            axios.post('/api/v1/integration', link).then(res => {
                link.loading = false
                this.getCompanyUpdatedInfo()
            }).catch(err => {
                link.loading = false
                link.status = !link.status
                this.$root.handleErrors(err.response)
            })
        },

        getCompanyUpdatedInfo() {
            axios.get(`/api/v1/company/${this.current_company.id}`).then((res) => {
                this.setCurrentCompany(res.data)
            }).catch((err) => {
                console.log(err.response)
            })
        },

        async processPrismaticEvents(event) {
          const eventType = event?.data?.event
          const eventData = event?.data?.data

          if (!eventType || !eventData) return
          const changedIntegrationName = eventData?.integrationName?.toLowerCase()

          const changedLink = this.links.find((link) => changedIntegrationName.includes(link.name.toLowerCase()))

          if (eventType === PrismaticMessageEvent.INSTANCE_DELETED) {
            if (changedLink) {
              changedLink.status = false
              this.updateStatus(changedLink)
            }
          }

          if (eventType === PrismaticMessageEvent.INSTANCE_DEPLOYED) {
            if (changedLink) {
              changedLink.status = true
            }
          }
        },

        ...mapActions('cache', ['setCurrentCompany'])
    },
}
</script>
