<template>
    <div>
        <div class="pt-3 pl-2 pr-2 clear">
            <div class="row mb-4 mt-2 pl-2 pr-2" 
                 v-if="hasPermissionTo('see usage page')">
                <div class="col-12 text-md"
                     v-if="!company.chargebee_auto_collection">
                    <span>Your account is manually invoiced. Contact our support for your billing needs.</span>
                </div>
                <div class="col-12 text-md"
                     v-else>
                    <p>You are currently on
                        <a class="text-dark-greenish el-green-hover"
                           :disabled="isChargeBeeSessionOrSubscriptionDisabled"
                           @click="openChargebeePortal">
                            <b>{{ usage.plan.name | capitalize }}</b>
                        </a> Plan.
                        <a title="More plan information"
                           class="mr-2 text-bluish"
                           :disabled="isChargeBeeSessionOrSubscriptionDisabled"
                           @click="openChargebeePortal"
                           v-if="hasChargeBeeSession && enabledToSkipTrialAndSubscribe()">
                            Click here to manage subscription
                        </a>
                    </p>
                    <p>
                        Your current credit balance is:
                        <a class="text-dark-greenish el-green-hover"
                           :disabled="isChargeBeeSessionOrSubscriptionDisabled"
                           @click="openChargebeePortal">
                            <b v-bind:style="{ color: creditColor }">{{ usage.credits | fixRounding | toCurrency }}</b>
                        </a>
                    </p>
                    <p v-if="auth.user.profile.usage.auto_recharge">Your account is on
                        <a class="text-dark-greenish el-green-hover"
                           :disabled="isChargeBeeSessionOrSubscriptionDisabled"
                           @click="openChargebeePortal">
                            <b>Auto Recharge</b>
                        </a>
                    </p>
                </div>
                <div class="col-12 text-md">
                    <div class="pull-right">
                        <template
                            v-if="auth.user.profile && (!auth.user.profile.created_by_reseller || (auth.user.profile.created_by_reseller && auth.user.profile.independent_billing))">
                            <el-popover v-if="!enabledToSkipTrialAndSubscribe()"
                                        ref="trial-skip-popover"
                                        placement="top"
                                        width="200"
                                        class="custom-popover"
                                        popper-class="btn-primary"
                                        trigger="hover">
                                <custom-message-display :config="customMessage('billing.subscribe')" />
                            </el-popover>
                            <button class="btn btn-sm bluish pull-right pr-2 pl-2"
                                    :disabled="isChargeBeeSessionOrSubscriptionDisabled"
                                    v-popover:trial-skip-popover
                                    v-if="hasPermissionTo('see chargebee portal')"
                                    @click="chargebeePaymentSources">
                                <i class="material-icons">credit_card_outline</i>
                                <span v-if="!cardAdded">Add Payment Method</span>
                                <span v-else>Manage Payment Methods</span>
                            </button>

                            <el-popover v-if="!enabledToSkipTrialAndSubscribe()"
                                        ref="trial-skip-billing-popover"
                                        placement="top"
                                        width="200"
                                        class="custom-popover"
                                        popper-class="btn-primary"
                                        trigger="hover">
                                <custom-message-display :config="customMessage('billing.subscribe')" />
                            </el-popover>
                            <button class="btn btn-sm greenish pull-right pr-2 pl-2 mr-2"
                                    :disabled="isChargeBeeSessionOrSubscriptionDisabled"
                                    v-popover:trial-skip-billing-popover
                                    v-if="hasPermissionTo('see chargebee portal')"
                                    @click="openChargebeePortal">
                                <i class="material-icons">payment</i>
                                Manage Subscription
                            </button>
                            <button class="btn btn-sm greenish pull-right pr-2 pl-2 mr-2"
                                    @click="chargebeeAddons"
                                    v-if="hasPermissionTo('see chargebee portal')">
                                <i class="material-icons">attach_file</i>
                                Manage Addons
                            </button>
                            <button class="btn btn-sm white pull-right pr-2 pl-2 mr-2"
                                    @click="billingHistory"
                                    :disabled="!hasChargeBeeSession"
                                    v-if="hasPermissionTo('see chargebee portal')">
                                <i class="material-icons">history</i>
                                Payment History
                            </button>
                        </template>
                        <a href="https://support.aloware.com/en/articles/9033652-understanding-aloware-invoices"
                           title="Billing Help"
                           target="_blank"
                           class="btn btn-sm pull-left mr-2 text-bluish">
                            <i class="material-icons text-lg">help</i>
                            Help
                        </a>
                    </div>
                </div>
            </div>

            <div class="row pl-2 pr-2 mb-3"
                 v-if="!usage.plan.is_on_trial && usage.subscription_status == 'active' && hasPermissionTo('buy credits') && auth.user.profile && (!auth.user.profile.created_by_reseller || (auth.user.profile.created_by_reseller && auth.user.profile.independent_billing))">
                <div class="col-12">
                    <div class="box new-card">
                        <h4 class="text-lg">Recharge Your Account</h4>
                        <div class="box-body text-left"
                             v-loading="loading">
                            <div class="row">
                                <div class="col-12">
                                    <p class="text-blackish">Choose an amount in USD to recharge:</p>
                                    <div class="row mt-4 mb-2">
                                        <div class="col-12">
                                            <el-radio-group v-model="credits"
                                                            class="pull-left text-lg">
                                                <el-radio :label="100"
                                                          class="_600"
                                                          border>
                                                    $100
                                                </el-radio>
                                                <el-radio :label="250"
                                                          class="_600"
                                                          border>
                                                    $250
                                                </el-radio>
                                                <el-radio :label="500"
                                                          class="_600"
                                                          border>
                                                    $500
                                                </el-radio>
                                                <el-radio :label="1000"
                                                          class="_600"
                                                          border>
                                                    $1000
                                                </el-radio>
                                            </el-radio-group>

                                            <el-button type="success"
                                                       @click="verifyCardExists(buyCredits)"
                                                       :disabled="loading_credits"
                                                       class="pull-left ml-4">
                                                <i class="material-icons loader pull-right"
                                                   v-show="loading_credits">&#xE863;</i>
                                                Buy Credits
                                            </el-button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <div class="col-12 mt-4 mb-2 form-aloware">
                                        <div class="switch-label">
                                            <el-switch active-color="#00a344"
                                                       v-model="auto_recharge"
                                                       :disabled="!current_company.chargebee_auto_collection || current_company.auto_recharge"
                                                       @change="checkAutoRecharge">
                                            </el-switch>
                                            <label :class="[ current_company.auto_recharge ? 'inactive' : '' ]">Auto Recharge</label>
                                        </div>
                                        <div class="mb-3">
                                            <small v-show="current_company.chargebee_auto_collection && current_company.auto_recharge">Auto-recharge is mandatory for your payment plan.</small>
                                        </div>
                                        <div class="alert alert-warning alert-aloware p-2 d-inline-block w-50">
                                            <i class="fa fa-exclamation-triangle"></i> When an auto recharge attempt fails,
                                            we will turn off auto recharge settings to prevent any further issues. <br/>
                                            When the billing issue is fixed, the auto recharge needs to be set up once again.
                                        </div>
                                        <div class="mt-4"
                                             v-if="auto_recharge">
                                            For amount
                                            <el-select placeholder="Select"
                                                       v-model="recharge_for_amount">
                                                <el-option
                                                    v-for="item in recharge_for_amount_options"
                                                    :key="item.value"
                                                    :label="item.label"
                                                    :value="item.value">
                                                </el-option>
                                            </el-select>
                                            When Balance is below
                                            <el-select placeholder="Select"
                                                       v-model="auto_recharge_threshold">
                                                <el-option
                                                    v-for="item in auto_recharge_threshold_options"
                                                    :key="item.value"
                                                    :label="item.label"
                                                    :value="item.value">
                                                </el-option>
                                            </el-select>
                                        </div>
                                        <div class="mt-4"
                                             v-if="changed">
                                            <div class="alert alert-danger alert-aloware p-2 w-50"
                                                 v-if="auto_recharge">
                                                Auto-recharge can't be disabled once enabled.
                                            </div>

                                            <el-button type="success"
                                                       @click="changeAutoRecharge"
                                                       :disabled="loading_auto_recharge"
                                                       class="pull-left">
                                                <i class="material-icons loader pull-right"
                                                   v-show="loading_auto_recharge">&#xE863;</i>
                                                Save Details
                                            </el-button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row pl-2 pr-2 mb-3">
                <div class="col-12">
                    <div class="box new-card">
                        <h4 class="text-lg">Your Account Rates</h4>
                        <small>You are on our <b>{{ auth.user.profile.rate.name }}</b> rates plan.</small>
                        <div class="box-body text-left">
                            <p class="text-md _600 text-blue"
                               v-if="usage.plan && usage.plan.unlimited_inbound == 1 && usage.plan.unlimited_outbound == 0">
                                Inbound local minutes and SMS are free on your plan.
                            </p>
                            <p class="text-md _600 text-blue"
                               v-if="usage.plan && usage.plan.unlimited_inbound == 1 && usage.plan.unlimited_outbound == 1">
                                Inbound & outbound local minutes and SMS are free on your plan. Automated usage is considered paid.
                            </p>
                            <div class="table-responsive">
                                <table class="table table-borderless">
                                    <tbody>
                                    <tr class="mb-2">
                                        <td style="width: 250px;">
                                            <table class="table table-bordered border mb-0">
                                                <thead>
                                                <tr>
                                                    <th class="text-greenish border-bottom"
                                                        scope="col">
                                                        Local Phone Numbers
                                                    </th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                <tr>
                                                    <td>{{ auth.user.profile.rate.local_pn | fixRounding | toCurrency }}</td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </td>
                                        <td style="width: 250px;">
                                            <table class="table table-bordered border mb-0">
                                                <thead>
                                                <tr>
                                                    <th class="text-greenish border-bottom"
                                                        scope="col">
                                                        Toll-free Phone Numbers
                                                    </th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                <tr>
                                                    <td>{{ auth.user.profile.rate.tollfree_pn | fixRounding | toCurrency }}</td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </td>
                                        <td style="width: 250px;">
                                            <table class="table table-bordered border mb-0">
                                                <thead>
                                                <tr>
                                                    <th class="text-greenish border-bottom"
                                                        scope="col">
                                                        Local Minutes
                                                    </th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                <tr>
                                                    <td>{{ auth.user.profile.rate.local_min | fixRounding | toCurrency }}</td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </td>
                                        <td style="width: 250px;">
                                            <table class="table table-bordered border mb-0">
                                                <thead>
                                                <tr>
                                                    <th class="text-greenish border-bottom"
                                                        scope="col">
                                                        Toll-free Minutes
                                                    </th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                <tr>
                                                    <td>{{ auth.user.profile.rate.tollfree_min | fixRounding |toCurrency }}
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style="width: 250px;">
                                            <table class="table table-bordered border mb-0">
                                                <thead>
                                                <tr>
                                                    <th class="text-greenish border-bottom"
                                                        scope="col">
                                                        Local Text Messages
                                                    </th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                <tr>
                                                    <td>{{ auth.user.profile.rate.local_sms | fixRounding | toCurrency }}</td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </td>
                                        <td style="width: 250px;">
                                            <table class="table table-bordered border mb-0">
                                                <thead>
                                                <tr>
                                                    <th class="text-greenish border-bottom"
                                                        scope="col">
                                                        Toll-free Text Messages
                                                    </th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                <tr>
                                                    <td>{{ auth.user.profile.rate.tollfree_sms | fixRounding |toCurrency }}
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </td>
                                        <td style="width: 250px;">
                                            <table class="table table-bordered border mb-0">
                                                <thead>
                                                <tr>
                                                    <th class="text-greenish border-bottom"
                                                        scope="col">
                                                        ShortCode Text Messages
                                                    </th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                <tr>
                                                    <td>{{ auth.user.profile.rate.short_code_sms | fixRounding |toCurrency }}
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </td>
                                        <td style="width: 250px;">
                                            <table class="table table-bordered border mb-0">
                                                <thead>
                                                <tr>
                                                    <th class="text-greenish border-bottom"
                                                        scope="col">
                                                        Local MMS
                                                    </th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                <tr>
                                                    <td>{{ auth.user.profile.rate.local_mms | fixRounding | toCurrency }}</td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </td>
                                        <td style="width: 250px;">
                                            <table class="table table-bordered border mb-0">
                                                <thead>
                                                <tr>
                                                    <th class="text-greenish border-bottom"
                                                        scope="col">
                                                        Toll-free MMS
                                                    </th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                <tr>
                                                    <td>{{ auth.user.profile.rate.tollfree_mms | fixRounding |toCurrency }}
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </td>
                                        <td style="width: 250px;">
                                            <table class="table table-bordered border mb-0">
                                                <thead>
                                                <tr>
                                                    <th class="text-greenish border-bottom"
                                                        scope="col">
                                                        ShortCode MMS
                                                    </th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                <tr>
                                                    <td>{{ auth.user.profile.rate.short_code_mms | fixRounding |toCurrency }}
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style="width: 250px;">
                                            <table class="table table-bordered border mb-0">
                                                <thead>
                                                <tr>
                                                    <th class="text-greenish border-bottom"
                                                        scope="col">
                                                        Ringless Voicemail
                                                    </th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                <tr>
                                                    <td>{{ auth.user.profile.rate.rvm | fixRounding | toCurrency }}</td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </td>
                                        <td style="width: 250px;">
                                            <table class="table table-bordered border mb-0">
                                                <thead>
                                                <tr>
                                                    <th class="text-greenish border-bottom"
                                                        scope="col">
                                                        Automatic Machine Detection
                                                    </th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                <tr>
                                                    <td>{{ auth.user.profile.rate.amd | fixRounding | toCurrency }}</td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </td>
                                        <td style="width: 250px;">
                                            <table class="table table-bordered border mb-0">
                                                <thead>
                                                <tr>
                                                    <th class="text-greenish border-bottom"
                                                        scope="col">
                                                        Email
                                                    </th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                <tr>
                                                    <td>{{ auth.user.profile.rate.email | fixRounding | toCurrency }}
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </td>
                                        <td style="width: 250px;">
                                            <table class="table table-bordered border mb-0">
                                                <thead>
                                                <tr>
                                                    <th class="text-greenish border-bottom"
                                                        scope="col">
                                                        Fax
                                                    </th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                <tr>
                                                    <td>{{ auth.user.profile.rate.fax | fixRounding | toCurrency }}</td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style="width: 250px;">
                                            <table class="table table-bordered border mb-0">
                                                <thead>
                                                <tr>
                                                    <th class="text-greenish border-bottom"
                                                        scope="col">
                                                        Transcription
                                                    </th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                <tr>
                                                    <td>{{ auth.user.profile.rate.transcription | fixRounding | toCurrency }}</td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <el-dialog title="Addons Manager"
                   :visible.sync="chargebeeAddonsManagerOpen">
            <charge-bee-addons-manager ref="charge-bee-addon-manager"
                                       :visible="chargebeeAddonsManagerOpen">
            </charge-bee-addons-manager>
        </el-dialog>

        <el-backtop target="html"
                    ref="backtop"
                    :bottom="20"
                    :right="20">
        </el-backtop>
    </div>
</template>

<script>
import auth from '../auth'
import {mapState} from 'vuex'
import {
    chargebee_mixin,
    scroll_mixin,
    kyc_mixin
} from '../mixins'
import ChargeBeeAddonsManager from './account/chargebee-addons-manager.vue'
import CustomMessageDisplay from './kyc/custom-message-display.vue'
import * as TrialStatus from '../constants/trial-account-status'

export default {
    mixins: [
        chargebee_mixin,
        scroll_mixin,
        kyc_mixin
    ],

    props: ['company'],

    components: {
        ChargeBeeAddonsManager,
        CustomMessageDisplay
    },

    data() {
        return {
            auth: auth,
            loading: false,
            loading_credits: false,
            loading_auto_recharge: false,
            plans: [],
            changed: false,
            credits: 100,
            auto_recharge: auth.user.profile.usage.auto_recharge,
            recharge_for_amount: auth.user.profile.usage.recharge_for_amount !== null ? auth.user.profile.usage.recharge_for_amount : 100,
            recharge_for_amount_options: [{
                value: 100,
                label: '$100'
            }, {
                value: 250,
                label: '$250'
            }, {
                value: 500,
                label: '$500'
            }, {
                value: 1000,
                label: '$1000'
            }],
            auto_recharge_threshold: auth.user.profile.usage.auto_recharge_threshold !== null ? auth.user.profile.usage.auto_recharge_threshold : 50,
            auto_recharge_threshold_options: [{
                value: 50,
                label: '$50'
            }, {
                value: 100,
                label: '$100'
            }, {
                value: 250,
                label: '$250'
            }, {
                value: 500,
                label: '$500'
            }, {
                value: 1000,
                label: '$1000'
            }],
            chargebeeAddonsManagerOpen: false,
            TrialStatus
        }
    },

    computed: {
        ...mapState('cache', ['current_company']),

        ...mapState({
            usage: state => state.usage
        }),

        creditColor() {
            if (this.usage) {
                if (this.usage.credits < 0) {
                    return '#f56c6c'
                } else if (this.usage.credits == 0) {
                    return '#090A0D'
                } else if (this.usage.credits <= 10) {
                    return '#fd7200'
                } else {
                    return '#00A344'
                }
            }
        }
    },

    created() {
        this.prepareChargeBee()
        this.getPlans()
        VueEvent.listen('plan_changed', (data) => {
            this.getPlans(data.id)
        })
    },

    methods: {
        getPlans(plan_id = null) {
            this.loading = true
            let params = {
                current_plan_id: plan_id ? plan_id : this.usage.plan.id
            }
            return axios.get('/api/v1/subscription/get-plans', {
                params: params,
                mode: 'no-cors'
            }).then(res => {
                this.loading = false
                this.plans = res.data

                Promise.resolve(res)
            }).catch(err => {
                this.loading = false
                this.$root.handleErrors(err.response)

                Promise.resolve(err)
            })
        },

        hasPlan(plan_name) {
            let plan = this.getPlan(plan_name)
            if (!plan) {
                return false
            }
            return true
        },

        changePlan(plan_name) {
            let plan = this.getPlan(plan_name)
            if (!plan) {
                return false
            }
            this.$confirm('You have requested to change your plan to ' + plan_name + '. Please confirm to continue', 'Upgrade Plan Confirmation', {
                confirmButtonText: 'Confirm',
                cancelButtonText: 'Cancel',
                customClass: 'width-500 fixed',
            }).then(() => {
                this.loading = true
                axios.post('/api/v1/subscription/change-plan', {
                    plan_id: plan.id
                }).then(res => {
                    this.loading = false
                    this.$notify({
                        offset: 95,
                        title: 'Billing',
                        message: 'Your change plan request is being processed.',
                        type: 'success',
                        showClose: true
                    })
                }).catch(err => {
                    this.loading = false
                    this.$root.handleErrors(err.response)
                })
            }).catch(() => {

            })
        },

        checkAutoRecharge() {
            this.changed = true
        },

        changeAutoRecharge() {
            this.loading_auto_recharge = true
            let params = {
                auto_recharge: this.auto_recharge,
            }
            if (this.auto_recharge) {
                params.recharge_for_amount = this.recharge_for_amount
                params.auto_recharge_threshold = this.auto_recharge_threshold
            }
            axios.post('/api/v1/subscription/change-auto-recharge', params).then(res => {
                this.loading_auto_recharge = false
                this.$notify({
                    offset: 95,
                    title: 'Billing',
                    message: 'Your auto recharge request has been processed.',
                    type: 'success',
                    showClose: true
                })
                this.auto_recharge = res.data.auto_recharge
                this.auth.user.profile.usage.auto_recharge = res.data.auto_recharge
                this.current_company.auto_recharge = res.data.auto_recharge
                this.recharge_for_amount = res.data.recharge_for_amount
                this.auth.user.profile.usage.recharge_for_amount = res.data.recharge_for_amount
                this.auto_recharge_threshold = res.data.auto_recharge_threshold
                this.auth.user.profile.usage.auto_recharge_threshold = res.data.auto_recharge_threshold
                this.changed = false
            }).catch(err => {
                this.loading_auto_recharge = false
                this.$root.handleErrors(err.response)
            })
        },

        getPlan(plan_name) {
            return this.plans.find(o => o.name === plan_name)
        },

        buyCredits() {
            this.$confirm('We will charge $' + this.credits + ' to your credit card. Please confirm to continue.', 'Payment Confirmation', {
                confirmButtonText: 'Confirm',
                cancelButtonText: 'Cancel',
                customClass: 'width-500 fixed',
            }).then(() => {
                this.loading_credits = true
                axios.post('/api/v1/subscription/buy-credits', {
                    credits: this.credits
                }).then(res => {
                    this.loading_credits = false
                    this.$notify({
                        offset: 95,
                        title: 'Billing',
                        message: 'Your account recharge request is being processed.',
                        type: 'success',
                        showClose: true
                    })
                }).catch(err => {
                    const actionCallback = err.response.data.action_required && this.hasPermissionTo('see chargebee portal') ? this.openChargebeePortal : null
                    this.loading_credits = false
                    this.$root.handleErrors(err.response, 'The purchase of your credits could not be processed.', actionCallback)
                })
            }).catch(() => {

            })
        },

        verifyCardExists(cb, option) {
            if (typeof cb !== 'function') {
                return
            }

            if (!this.usage.card_added) {
                this.paymentSourceSettings(cb, option)
                return
            }

            cb(option)
        },

        getRechargeForAmountLabel(value) {
            let label = '$0'

            for (let option of this.recharge_for_amount_options) {
                if (option['value'] === value) {
                    label = option['label']
                    break
                }
            }

            return label
        },

        getAutoRechargeThresholdLabel(value) {
            let label = '$0'

            for (let option of this.auto_recharge_threshold_options) {
                if (option['value'] === value) {
                    label = option['label']
                    break
                }
            }

            return label
        },

        chargebeeAddons() {
            this.chargebeeAddonsManagerOpen = true
        },

        openChargebeePortal() {
            if (this.isChargeBeeSessionOrSubscriptionDisabled) {
                return
            }

            this.chargebeePortal()
        }
    }
}

</script>
