var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.contact
      ? _c("div", [
          _c("p", [_vm._v("You have been assigned to a contact.")]),
          _vm._v(" "),
          _c("p", [_vm._v("Name: " + _vm._s(_vm.contact.name))]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "Phone number: " +
                _vm._s(_vm._f("fixPhone")(_vm.contact.phone_number))
            ),
          ]),
          _vm._v(" "),
          _vm._m(0),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.massAssign
      ? _c("div", [
          _c("p", [_vm._v("You have been assigned to a group of contacts.")]),
          _vm._v(" "),
          _vm._m(1),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c(
        "button",
        {
          staticClass:
            "btn rounded greenish btn-contact btn-sm mt-2 pull-right",
        },
        [_vm._v("\n        Go To Contact\n      ")]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c(
        "button",
        {
          staticClass:
            "btn rounded greenish btn-contacts btn-sm mt-2 pull-right",
        },
        [_vm._v("\n        Go To Contacts\n      ")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }