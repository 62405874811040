<template>
    <router-view></router-view>
</template>

<script>
import {mapActions, mapGetters, mapState} from "vuex"
import { contact_filters_mixin, contacts_live_updates_mixin, contact_counts_mixin } from '../../mixins'
import auth from "../../auth"

export default {
    data() {
        return {
            auth: auth,
            new_leads: [],
        }
    },

    mixins: [
        contact_filters_mixin,
        contacts_live_updates_mixin,
        contact_counts_mixin
    ],

    computed: {
        ...mapGetters({
            contactsCount: 'getContactsCount',
            newLeadsCount: 'getNewLeadsCount',
            unreadsCount: 'getUnreadsCount'
        }),

        ...mapState('cache', ['live_update_contacts'])
    },

    mounted() {
        this.getContactsCount().then(() => {
            this.default_filter = JSON.parse(JSON.stringify(this.defaultStateFilter))

            // Stop initializing event listeners if contact live update is disabled
            if (!this.live_update_contacts) {
                return
            }

            VueEvent.listen('contact_created', (data) => {
                this.updateContactCounts(data)
            })

            VueEvent.listen('contact_updated', (data) => {
                this.updateContactCounts(data)
            })

            VueEvent.listen('contact_deleted', (data) => {
                this.updateContactCounts(data, true)
            })

            VueEvent.listen('contact_merged', (data) => {
                this.updateContactCounts(data, true)
            })

            VueEvent.unlisten('clear_new_leads_count')
            VueEvent.listen('clear_new_leads_count', () => {
                this.new_leads = []
            })
        }).catch(err => {
            console.log(err)
        })
    },

    methods: {
        updateContactCounts(data, is_deleted = false) {
            this.updateUnansweredLeadsCount(data, is_deleted)
            this.updateNewLeadsCount(data, is_deleted)
        },

        updateUnansweredLeadsCount(data, is_deleted) {
            // allow live updates only if:
            // - new leads filter is active and not combined with other filters
            // - has unread messages filter is active and not combined with other filters
            // - filters are in their default values
            // also, don't continue if unread_change is not found in the data
            if (!this.allowLiveUpdates() || data.unread_change === undefined) {
                return
            }

            let unread_change = parseInt(data.unread_change)
            // when in focus mode, we must only apply changes to counts if
            // contact's user_id is the same with the current logged in
            // user id
            if (unread_change && this.allowCountUpdate(data)) {
                unread_change = this.unreadsCount + unread_change
                // prevent negative count
                unread_change = unread_change < 0 ? 0 : unread_change

                // update unread count
                this.setUnreadsCount(unread_change)
            }
            if (is_deleted && data.unread_count > 0 && this.unreadsCount > 0) {
                this.setUnreadsCount(this.unreadsCount - 1)
            }
        },

        updateNewLeadsCount(data, is_deleted = false) {
            // allow live updates only if:
            // - new leads filter is active and all other filters are in their default value
            // - has unread messages filter is active and all other filters are in their default value
            // - filters are in their default values
            if (!this.allowLiveUpdates()) {
                return
            }

            // when in focus mode, we must only apply changes to counts if
            // contact's user_id is the same with the current logged in
            // user id
            if (this.allowCountUpdate(data)) {
                let found = this.new_leads.find(o => o.id === data.id)

                // if contact is not found in new_leads array because new_leads array is empty
                // and there is a/are new lead(s) and updated contact is no longer a new lead,
                // decrement the new leads count.
                if (!found && !is_deleted && this.new_leads.length == 0 && this.newLeadsCount > 0 && data.last_engagement_at !== null) {
                    this.setNewLeadsCount(this.newLeadsCount - 1)
                } else if (!found && !is_deleted && !data.last_engagement_at) {
                    this.new_leads.push({
                        id: data.id
                    })
                    // set the new leads count
                    this.setNewLeadsCount(this.newLeadsCount + 1)
                    // set the total contact counts
                    let new_count = (this.contactsCount == null ? 0 : this.contactsCount) + 1
                    this.setContactsCount(new_count)
                } else if (found && !is_deleted && data.last_engagement_at !== null) {
                    this.new_leads.splice(this.new_leads.indexOf(found), 1)
                    // set the new leads count
                    this.setNewLeadsCount(this.newLeadsCount - 1)
                }

                if (is_deleted && !data.last_engagement_at) {
                    // set the new leads count
                    this.setNewLeadsCount(this.newLeadsCount - 1)
                    // update the contacts count
                    this.setContactsCount(this.contactsCount - 1)
                }

                if (is_deleted && data.last_engagement_at !== null) {
                    // update the contacts count
                    this.setContactsCount(this.contactsCount - 1)
                }
            }
        },

        allowCountUpdate(data) {
            return this.auth.user
                && this.auth.user.profile
                && (!this.auth.user.profile.focus_mode || (this.auth.user.profile.focus_mode && data.user_id && data.user_id == this.auth.user.profile.id))
        },

        ...mapActions(['setContactsCount', 'setNewLeadsCount', 'setUnreadsCount'])
    }
}
</script>
