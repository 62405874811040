var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.layers && _vm.ring_group
    ? _c(
        "div",
        _vm._l(_vm.flattenedUsers, function (layer) {
          return _c("div", [
            _vm._m(0, true),
            _vm._v(" "),
            _c("div", { staticClass: "card m-2 mb-3" }, [
              _c(
                "table",
                { staticClass: "table table-sm m-0 table-routing-test" },
                [
                  _c("thead", [
                    _c("tr", [
                      _c(
                        "th",
                        { staticClass: "text-center", attrs: { colspan: "4" } },
                        [
                          _c("h6", { staticClass: "mb-0" }, [
                            _vm._v("Layer " + _vm._s(layer.layer)),
                          ]),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _vm._m(1, true),
                  ]),
                  _vm._v(" "),
                  _c(
                    "tbody",
                    _vm._l(layer.user_results, function (user_result) {
                      return _c("tr", { key: user_result.model.id }, [
                        _c("td", { staticClass: "td-order" }, [
                          user_result.agent_is_eligible_to_take_call
                            ? _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.ring_group.dial_mode ===
                                      _vm.RingGroupDialMode.DIAL_MODE_SIMUL
                                      ? 1
                                      : layer.order++
                                  )
                                ),
                              ])
                            : _c("span", [_vm._v("-")]),
                        ]),
                        _vm._v(" "),
                        _c("td", { staticClass: "td-status pt-3 pr-0" }, [
                          user_result.model.is_destination
                            ? _c(
                                "span",
                                {
                                  staticClass:
                                    "btn btn-xs text-white cursor-default btn-block",
                                  class: [
                                    _vm.$options.filters.agentStatusClass(1),
                                  ],
                                },
                                [
                                  _vm._v(
                                    "\n                                Available\n                            "
                                  ),
                                ]
                              )
                            : _c(
                                "span",
                                {
                                  staticClass:
                                    "btn btn-xs text-white cursor-default btn-block",
                                  class: [
                                    _vm.$options.filters.agentStatusClass(
                                      user_result.model.agent_status
                                    ),
                                  ],
                                },
                                [
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(
                                        _vm.AgentStatusLabels.LABELS.find(
                                          (label) =>
                                            label.value ===
                                            user_result.model.agent_status
                                        ).label
                                      ) +
                                      "\n                            "
                                  ),
                                ]
                              ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "td",
                          [
                            _c(
                              "el-collapse",
                              { staticClass: "mb-0" },
                              [
                                _c(
                                  "el-collapse-item",
                                  {
                                    staticClass: "mb-0",
                                    attrs: { name: "1" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "title",
                                          fn: function () {
                                            return [
                                              _c(
                                                "span",
                                                { staticClass: "mr-2" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      user_result.model
                                                        .full_name
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  },
                                  [
                                    _vm._v(" "),
                                    _c("div", [
                                      _c(
                                        "ul",
                                        { staticClass: "list-unstyled" },
                                        _vm._l(
                                          user_result.results,
                                          function (result) {
                                            return _c(
                                              "li",
                                              { key: result.key },
                                              [
                                                result.value
                                                  ? _c(
                                                      "i",
                                                      {
                                                        staticClass:
                                                          "material-icons text-dark-greenish",
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                                                    check\n                                                "
                                                        ),
                                                      ]
                                                    )
                                                  : _c(
                                                      "i",
                                                      {
                                                        staticClass:
                                                          "material-icons text-danger",
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                                                    close\n                                                "
                                                        ),
                                                      ]
                                                    ),
                                                _vm._v(
                                                  "\n                                                " +
                                                    _vm._s(result.label) +
                                                    "\n                                            "
                                                ),
                                              ]
                                            )
                                          }
                                        ),
                                        0
                                      ),
                                    ]),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("td", [
                          user_result.model.teams_name
                            ? _c(
                                "span",
                                _vm._l(
                                  user_result.model.teams_name,
                                  function (team_name, index) {
                                    return _c(
                                      "div",
                                      { key: team_name, staticClass: "mr-2" },
                                      [
                                        _vm._v(
                                          "\n                                    " +
                                            _vm._s(team_name)
                                        ),
                                        index <
                                        user_result.model.teams_name.length - 1
                                          ? _c("span", [_vm._v(", ")])
                                          : _vm._e(),
                                      ]
                                    )
                                  }
                                ),
                                0
                              )
                            : _vm._e(),
                        ]),
                        _vm._v(" "),
                        _c(
                          "td",
                          { staticClass: "td-can-take-calls text-center" },
                          [
                            user_result.agent_is_eligible_to_take_call
                              ? _c(
                                  "strong",
                                  { staticClass: "text-dark-greenish" },
                                  [
                                    _vm._v(
                                      "\n                                Yes\n                            "
                                    ),
                                  ]
                                )
                              : _c("strong", { staticClass: "text-danger" }, [
                                  _vm._v(
                                    "\n                                No\n                            "
                                  ),
                                ]),
                          ]
                        ),
                      ])
                    }),
                    0
                  ),
                ]
              ),
            ]),
          ])
        }),
        0
      )
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "text-center py-2" }, [
      _c("i", { staticClass: "fas fa-arrow-down text-xl text-muted" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("th", [_vm._v("Order")]),
      _vm._v(" "),
      _c("th", [_vm._v("Status")]),
      _vm._v(" "),
      _c("th", [_vm._v("User")]),
      _vm._v(" "),
      _c("th", [_vm._v("Teams")]),
      _vm._v(" "),
      _c("th", { staticClass: "text-center" }, [_vm._v("Can take calls?")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }