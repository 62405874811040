var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-popover",
    {
      attrs: { placement: "right-start", width: "350", trigger: "click" },
      on: { show: _vm.resetForm },
    },
    [
      _c(
        "el-form",
        [
          _c(
            "el-form-item",
            { staticClass: "mb-3" },
            [
              _c(
                "div",
                {
                  staticClass: "form-label text-dark",
                  attrs: { scope: "label" },
                },
                [
                  _c("h5", { staticClass: "mb-2 text-black" }, [
                    _vm._v("Add tasks to user's PowerDialer"),
                  ]),
                  _vm._v(" "),
                  _c("p", { staticClass: "lh-1p4" }, [
                    _vm._v(
                      "\n                    This tool will respect user's visibility limits\n                "
                    ),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c("p", { staticClass: "lh-1p4 mb-2 text-dark" }, [
                _vm._v("Add tasks to this user's PowerDialer:"),
              ]),
              _vm._v(" "),
              _c("user-selector", {
                ref: "userSelector",
                staticClass: "mb-2",
                attrs: { hide_extensions: true, clearable: "" },
                on: { change: _vm.changeUserId },
                model: {
                  value: _vm.user_id,
                  callback: function ($$v) {
                    _vm.user_id = $$v
                  },
                  expression: "user_id",
                },
              }),
              _vm._v(" "),
              _c("el-form-item", { staticClass: "mb-2" }, [
                _c(
                  "div",
                  { staticClass: "form-label mb-1", attrs: { scope: "label" } },
                  [_c("label", [_vm._v("Push contacts to")])]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col" },
                    [
                      _c(
                        "el-radio",
                        {
                          staticClass: "w-full",
                          attrs: {
                            label: _vm.PowerDialer.DIRECTION_BOTTOM,
                            value: _vm.PowerDialer.DIRECTION_BOTTOM,
                            border: "",
                            size: "medium",
                          },
                          model: {
                            value: _vm.direction,
                            callback: function ($$v) {
                              _vm.direction = $$v
                            },
                            expression: "direction",
                          },
                        },
                        [
                          _vm._v(
                            "\n                            Bottom\n                        "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "col" },
                    [
                      _c(
                        "el-radio",
                        {
                          staticClass: "w-full",
                          attrs: {
                            label: _vm.PowerDialer.DIRECTION_TOP,
                            value: _vm.PowerDialer.DIRECTION_TOP,
                            border: "",
                            size: "medium",
                          },
                          model: {
                            value: _vm.direction,
                            callback: function ($$v) {
                              _vm.direction = $$v
                            },
                            expression: "direction",
                          },
                        },
                        [
                          _vm._v(
                            "\n                            Top\n                        "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("el-form-item", { staticClass: "mb-2" }, [
                _c(
                  "div",
                  { staticClass: "form-label", attrs: { scope: "label" } },
                  [
                    _c("el-checkbox", {
                      staticClass: "w-full",
                      attrs: { label: "Add multiple phone numbers?" },
                      model: {
                        value: _vm.multiple_phone_numbers,
                        callback: function ($$v) {
                          _vm.multiple_phone_numbers = $$v
                        },
                        expression: "multiple_phone_numbers",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("el-form-item", [
                _c(
                  "div",
                  { staticClass: "form-label mb-1", attrs: { scope: "label" } },
                  [
                    _c("el-checkbox", {
                      staticClass: "w-full",
                      attrs: { label: "Prevent duplicate task creation" },
                      model: {
                        value: _vm.prevent_duplicates,
                        callback: function ($$v) {
                          _vm.prevent_duplicates = $$v
                        },
                        expression: "prevent_duplicates",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            [
              _c(
                "el-button",
                {
                  staticClass: "w-full",
                  attrs: {
                    type: "success",
                    size: "small",
                    disabled: !_vm.user_id,
                  },
                  on: { click: _vm.addTasks },
                },
                [_vm._v("\n                Add Tasks\n            ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "btn btn-block deep-purple btn-sm",
          attrs: { slot: "reference" },
          slot: "reference",
        },
        [
          _c("i", { staticClass: "fa fa-phone pull-left" }),
          _vm._v(" "),
          _vm._t("default", function () {
            return [_vm._v("Add To PowerDialer")]
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }