var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.is_loading,
          expression: "is_loading",
        },
      ],
      staticClass: "p-3 w-100",
    },
    [
      _c("label", { staticClass: "font-weight-bold" }, [_vm._v("Sequence")]),
      _vm._v(" "),
      _vm.sequence
        ? _c("div", [
            _c(
              "div",
              { staticClass: "d-flex mb-3" },
              [
                _c("img", {
                  staticClass: "mr-2",
                  attrs: {
                    "data-testid": "sequence-img",
                    alt: "The Sequence icon",
                    src: _vm.sequence.img_src,
                  },
                }),
                _vm._v(" "),
                _c("div", { staticClass: "lh-1p4" }, [
                  _c("h6", { staticClass: "mb-0 text-black" }, [
                    _vm._v(_vm._s(_vm.workflow.name)),
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _c("span", { staticClass: "text-gray" }, [
                      _vm._v("#" + _vm._s(_vm.sequence.order)),
                    ]),
                    _vm._v(
                      "\n                    " +
                        _vm._s(_vm.sequence.name) +
                        "\n                "
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "el-button",
                  {
                    staticClass: "ml-auto",
                    attrs: {
                      size: "small",
                      round: "",
                      title: "Refresh sequence info",
                      "data-testid": "refresh-sequence-info-button",
                    },
                    on: { click: _vm.fetchContactSequence },
                  },
                  [
                    _c("i", {
                      staticClass: "fa fa-refresh",
                      staticStyle: { "font-size": "12px" },
                    }),
                  ]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "text-center" },
              [
                _c(
                  "el-button",
                  {
                    staticClass: "text-danger",
                    attrs: {
                      size: "small",
                      round: "",
                      loading: _vm.loading_disenroll_contact,
                      "data-testid": "disenroll-contact-button",
                    },
                    on: { click: _vm.disenrollContact },
                  },
                  [
                    _c("i", { staticClass: "fa fa-trash-o" }),
                    _vm._v(" Disenroll From Sequence\n            "),
                  ]
                ),
              ],
              1
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.sequence,
              expression: "!sequence",
            },
          ],
          staticClass: "text-center",
        },
        [
          _vm._m(0),
          _vm._v(" "),
          _c("workflow-bulk-enroller", {
            attrs: {
              model: "contact",
              id: _vm.contact.id,
              phone_number: _vm.activePhoneNumber,
            },
            on: { enrolled: _vm.enrolled },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _vm._v("\n            This contact is currently not "),
      _c("br"),
      _vm._v("\n            enrolled to a sequence.\n        "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }