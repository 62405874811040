<template>
    <div class="row no-gutter"
         v-if="contact">
        <div class="text-center mr-2 mb-2"
             v-for="ring_group_id in ring_group_ids">
            <router-link :to="{ name: 'Ring Group Activity', params: {ring_group_id: ring_group_id }}"
                         v-if="ring_group_id">
                <a class="btn btn-xs bluish"
                   data-testid="contact-ring-group-link"
                   v-if="getRingGroup(ring_group_id)">
                    {{ getRingGroup(ring_group_id).name }}
                </a>
            </router-link>
        </div>
        <div class="col-12"
             v-if="hasRole('Company Admin') || (hasRole('Company Agent') && hasPermissionTo('modify contact ring groups'))">
            <el-popover ref="popover_ring_groups"
                        title="Ring Groups:"
                        placement="left"
                        width="400"
                        data-testid="contact-ring-group-popover"
                        trigger="click">
                <el-button slot="reference"
                           data-testid="add-modify-ring-groups-button"
                           type="text text-dark-greenish text-sm p-0">
                    <i class="el-icon-circle-plus-outline"></i>
                    <span v-if="new_ring_group_ids.length === 0">Add Ring Groups</span>
                    <span v-else>Modify Ring Groups</span>
                </el-button>
                <div class="row">
                    <div class="col-12">
                        <el-form v-if="hasRole('Company Admin') || (hasRole('Company Agent') && hasPermissionTo('modify contact ring groups'))" 
                                 data-testid="contact-ring-groups-form">
                            <el-form-item prop="ring_group">
                                <el-select v-model="new_ring_group_ids"
                                           placeholder="Select ring groups"
                                           class="d-block"
                                           multiple
                                           data-testid="contact-ring-groups-select"
                                           @change="changeRingGroups($event, new_ring_group_ids)">
                                    <el-option
                                        v-for="item in ringGroupOptions"
                                        :key="item.value"
                                        :label="item.label"
                                        :value="item.value">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-form>
                    </div>
                </div>
            </el-popover>
        </div>
    </div>
</template>

<script>
import auth from '../auth'
import {mapState} from 'vuex'
import {acl_mixin} from '../mixins'

export default {
    mixins: [acl_mixin],

    props: {
        contact: {
            required: true
        }
    },

    data() {
        return {
            auth: auth,
            current_contact_id: null,
            ring_group_ids: [],
            new_ring_group_ids: [],
            loading_btn: false,
            validated: false,
        }
    },

    computed: {
        ...mapState(['ring_groups']),
        ringGroupOptions() {
            return this.ring_groups.map(ring_group => {
                return {
                    label: ring_group.name,
                    value: ring_group.id
                }
            })
        }
    },

    methods: {
        getRingGroups() {
            this.current_contact_id = this.contact.id
            axios.get(`/api/v1/contact/${this.contact.id}/ring-groups`).then(res => {
                this.ring_group_ids = res.data
                this.new_ring_group_ids = this.ring_group_ids
            }).catch(err => {
                this.ring_group_ids = []
                this.$root.handleErrors(err.response)
            })
        },

        getRingGroup(id) {
            if (!id) {
                return null
            }

            return this.ring_groups.find(ring_group => ring_group.id === id)
        },

        updateRingGroup() {
            axios.put(`/api/v1/contact/${this.contact.id}/ring-groups`, {
                ring_group_ids: this.new_ring_group_ids
            }).then(res => {
                this.ring_group_ids = this.new_ring_group_ids
                this.$notify({
                    offset: 95,
                    title: 'Contact',
                    message: 'Successfully updated Contact\'s ring groups.',
                    type: 'success'
                })
            }).catch(err => {
                this.$root.handleErrors(err.response)
            })
        },

        changeRingGroups(event, model) {
            model = event
            this.updateRingGroup()
        }
    },

    watch: {
        contact() {
            if (this.contact && (!this.current_contact_id || this.current_contact_id != this.contact.id)) {
                this.getRingGroups()
            }
        }
    }
}
</script>
