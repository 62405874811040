var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.communication.type == _vm.filter_type || _vm.filter_type == 0
    ? _c(
        "li",
        {
          staticClass: "tl-item",
          class: [_vm.communication.direction === 2 ? "tl-right" : "tl-left"],
        },
        [
          _c(
            "div",
            {
              staticClass: "tl-wrap",
              class: _vm.stateToClass(_vm.communication.disposition_status2),
            },
            [
              _c("span", {
                staticClass: "tl-date text-muted pl-1",
                domProps: { innerHTML: _vm._s(_vm.relative_datetime) },
              }),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "tl-content box-color text-color w-auto-xs",
                  class: { "w-xl": _vm.is_center },
                },
                [
                  _c("span", {
                    staticClass: "arrow b-white pull-top",
                    class: [_vm.getClass],
                  }),
                  _vm._v(" "),
                  _c("communication-info", {
                    attrs: {
                      communication: _vm.communication,
                      activity_mode: true,
                    },
                  }),
                  _vm._v(" "),
                  ![10, 11, 12, 13].includes(_vm.communication.type)
                    ? _c("div", { staticClass: "row p-l p-r" }, [
                        _c(
                          "div",
                          { staticClass: "col-12" },
                          [
                            _c(
                              "router-link",
                              {
                                attrs: {
                                  to: {
                                    name: "Communication",
                                    params: {
                                      communication_id: _vm.communication.id,
                                    },
                                  },
                                },
                              },
                              [
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-sm greyish text-left",
                                  },
                                  [
                                    _c(
                                      "i",
                                      { staticClass: "material-icons mr-2" },
                                      [_vm._v("info")]
                                    ),
                                    _vm._v(
                                      "\n                            More Details\n                        "
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.communication.type === 13
                    ? _c("div", { staticClass: "row p-l p-r" }, [
                        _c(
                          "div",
                          { staticClass: "col-12" },
                          [
                            _c(
                              "el-radio-group",
                              {
                                attrs: {
                                  size: "small",
                                  disabled: _vm.loading_update_engagement,
                                },
                                on: { change: _vm.changeReminderStatus },
                                model: {
                                  value: _vm.communication.disposition_status2,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.communication,
                                      "disposition_status2",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "communication.disposition_status2",
                                },
                              },
                              [
                                _c(
                                  "el-radio-button",
                                  {
                                    attrs: {
                                      label:
                                        _vm.CommunicationDispositionStatus
                                          .DISPOSITION_STATUS_COMPLETED_NEW,
                                    },
                                  },
                                  [_vm._v("Completed")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-radio-button",
                                  {
                                    attrs: {
                                      label:
                                        _vm.CommunicationDispositionStatus
                                          .DISPOSITION_STATUS_PLACED_NEW,
                                    },
                                  },
                                  [_vm._v("Pending")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-radio-button",
                                  {
                                    attrs: {
                                      label:
                                        _vm.CommunicationDispositionStatus
                                          .DISPOSITION_STATUS_FAILED_NEW,
                                    },
                                  },
                                  [_vm._v("Failed")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                ],
                1
              ),
            ]
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }