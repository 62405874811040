<template>
    <div class="multiple fallback">
        <div class="panel"
            v-if="showSuccess">
            Success
            <!-- We load the css class through the start_id as well as in intents.vue component -->
            <div :class="`link output_${start_id} output_success`"></div>
        </div>
        <div
            class="panel"
            v-if="fallbacks.length > 0"
            v-for="(fallback, id) in actualFallbacks"
            v-bind:key="id">

            Error {{ getErrorComplement(fallback.type) }}
            <div :class="`link output_${showSuccess ? start_id + id + 1 : start_id + id} output_fallback output_fallback_${fallback.type}`"></div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        showSuccess: {
            required: false,
            default: false
        },
        fallbacks: {
            required: false,
            default: () => ([])
        },
        start_id: {
            required: false,
            default: 1
        }
    },
    methods: {
        /**
         * Adds additional information to the Error string in the fallback node.
         *
         * @return {string}
         */
        getErrorComplement(type) {
            if (type != 'state') {
                return ' - ' + this.$options.filters.ucfirst(type)
            }

            return ''
        }
    },
    computed: {
        /**
         * Determines if there are any non-empty fallback messages.
         *
         * @return {object[]}
         */
        actualFallbacks() {
            if (!this.fallbacks){
                return []
            }

            // This block of code checks if any of the fallback messages have content.
            // 'ocupied' will be set to true if there is at least one non-empty message.
            let ocupied = this.fallbacks.reduce((prev, curr) => {
                // If the current fallback has no messages, return the previous value.
                if (curr.messages.length == 0) {
                    return prev
                }

                // If the current fallback has only one message, return true if the message is non-empty or return the previous value otherwise.
                if (curr.messages.length == 1) {
                    return prev || curr.messages[0] != ''
                }

                // If the current fallback has more than one message, return true as it is considered occupied.
                return true
            }, false)

            // If there are any non-empty fallback messages, return the fallbacks.
            if (ocupied) {
                return this.fallbacks
            }

            // If there are no non-empty fallback messages, return an empty array.
            return []
        }
    }
}
</script>