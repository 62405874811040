var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { "padding-left": "12px", "padding-right": "12px" } },
    [
      !_vm.isSimpSocial &&
      _vm.current_company.show_toll_free_verification_banner &&
      _vm.show_toll_free_banner
        ? _c(
            "div",
            {
              staticClass: "row pb-3",
              staticStyle: { "padding-left": "3px !important" },
            },
            [
              _c("el-alert", { attrs: { "show-icon": "", type: "info" } }, [
                _c("span", { staticClass: "d-block mb-1 text-dark" }, [
                  _vm._v("\n                    Please register your "),
                  _c("b", [_vm._v("Toll-Free Numbers")]),
                  _vm._v(
                    " today to avoid disruption, our support team is available to help.\n                    "
                  ),
                  _c(
                    "a",
                    {
                      staticClass: "font-weight-bold",
                      attrs: {
                        href: "https://support.aloware.com/en/articles/9032064-toll-free-number-verification-in-aloware",
                      },
                    },
                    [
                      _vm._v(
                        "\n                        Learn more\n                    "
                      ),
                    ]
                  ),
                ]),
              ]),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.shouldShowAmc
        ? _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-12" }, [
              _c(
                "div",
                { staticClass: "d-flex justify-content-end mb-3" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: "success",
                        size: "medium",
                        disabled: _vm.amcWizardUsed,
                      },
                      on: { click: _vm.openAmcWizardModal },
                    },
                    [
                      _vm._v(
                        "\n                    Get Aloware Managed Compliance\n                "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-tooltip",
                    {
                      staticClass: "item",
                      attrs: {
                        effect: "dark",
                        content: _vm.AmcWizardTooltipMessage,
                        placement: "top",
                        width: 600,
                      },
                    },
                    [
                      _c(
                        "i",
                        {
                          staticClass:
                            "material-icons text-lg cursor-pointer text-blue p-1 pt-2",
                        },
                        [_vm._v("info_outline")]
                      ),
                    ]
                  ),
                ],
                1
              ),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-12" }, [
          _c("div", { staticClass: "d-flex mb-2" }, [
            _vm._m(0),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "ml-auto d-flex" },
              _vm._l(_vm.icons, function (icon) {
                return _c(
                  "p",
                  { key: icon.name, staticClass: "no-select mb-0 ml-5" },
                  [
                    _c(
                      "i",
                      { staticClass: "material-icons", class: icon.class },
                      [_vm._v(_vm._s(icon.name))]
                    ),
                    _vm._v(" "),
                    _c("span", [_vm._v(_vm._s(icon.status))]),
                  ]
                )
              }),
              0
            ),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _vm.registrationSteps.length > 0
          ? _c(
              "div",
              { staticClass: "col-12" },
              _vm._l(_vm.registrationSteps, function (registrationStep, index) {
                return _c(
                  "p",
                  { key: index, staticClass: "mb-0 d-flex align-items-center" },
                  [
                    _c(
                      "span",
                      { staticClass: "font-weight-bold circle-number" },
                      [_vm._v(_vm._s(index + 1))]
                    ),
                    _vm._v(" "),
                    _c("span", { staticClass: "font-weight-bold mr-1" }, [
                      _vm._v(_vm._s(registrationStep.notice)),
                    ]),
                    _vm._v(
                      " " + _vm._s(registrationStep.message) + "\n            "
                    ),
                  ]
                )
              }),
              0
            )
          : _vm._e(),
        _vm._v(" "),
        !_vm.isSimpSocial
          ? _c("div", { staticClass: "col-12 my-2" }, [
              _c(
                "a",
                {
                  staticClass: "text-blue font-weight-bold",
                  attrs: {
                    target: "_blank",
                    href: "https://support.aloware.com/en/articles/9032003-a2p-10dlc-fees-brand-registration-and-campaign-costs",
                  },
                },
                [_vm._v("\n                Fees breakdown\n            ")]
              ),
            ])
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-12" },
          [
            _c(
              "el-collapse",
              {
                attrs: {
                  accordion: "",
                  value: _vm.shouldAutoExpandBusinessRegistration,
                },
              },
              [
                _c(
                  "el-collapse-item",
                  { attrs: { name: "1" } },
                  [
                    _c("template", { slot: "title" }, [
                      _c(
                        "p",
                        {
                          staticClass:
                            "mb-0 font-120 d-flex align-items-center",
                        },
                        [
                          _c(
                            "span",
                            { staticClass: "circle-number-title mr-2" },
                            [_vm._v("1")]
                          ),
                          _vm._v(" "),
                          _c("span", [_vm._v("Business Registration")]),
                        ]
                      ),
                      _vm._v(" "),
                      _vm.current_company.kyc_filled
                        ? _c(
                            "i",
                            {
                              staticClass: "ml-2 material-icons",
                              class: _vm.customerProfileTooltip.iconClass,
                            },
                            [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(_vm.customerProfileTooltip.iconName) +
                                  "\n                        "
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.current_company.kyc_filled
                        ? _c(
                            "p",
                            {
                              staticClass:
                                "ml-2 mb-0 font-120 d-flex align-items-center font-weight-bold",
                            },
                            [
                              _c(
                                "span",
                                { class: _vm.customerProfileTooltip.iconClass },
                                [
                                  _vm._v(
                                    _vm._s(_vm.customerProfileTooltip.notice)
                                  ),
                                ]
                              ),
                            ]
                          )
                        : _vm._e(),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-12" }, [
                        _c("p", { staticClass: "font-weight-bold" }, [
                          _vm._v(
                            "Unlock Outgoing Calls: Verify Your Business Today! 📞"
                          ),
                        ]),
                        _vm._v(" "),
                        _c("small", [
                          _vm._v(
                            "Telecommunications regulatory authorities need certain information to make sure that calls and texts are from a real business. This also helps us fight spam and\n                                fraud. Please take a moment to provide this information so we can verify your business & unlock outgoing calls during your trial. 🚀"
                          ),
                        ]),
                        _vm._v(" "),
                        _c("hr", { staticClass: "mt-0" }),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "col-12" },
                        [
                          _c("BusinessRegistration", {
                            attrs: {
                              customer_profile: _vm.customer_profile_in_use,
                              a2p_profile_bundle: _vm.a2p_profile_bundle_in_use,
                              a2p_brand: _vm.a2p_brand_in_use,
                            },
                            on: {
                              "customer-profile-submitted":
                                _vm.onCustomerProfileSubmitted,
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ],
                  2
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row mt-3" }, [
        _c(
          "div",
          { staticClass: "col-12" },
          [
            _c(
              "el-collapse",
              { attrs: { accordion: "", value: _vm.shouldAutoExpandA2PBrand } },
              [
                _c(
                  "el-collapse-item",
                  { attrs: { name: "1" } },
                  [
                    _c("template", { slot: "title" }, [
                      _c(
                        "p",
                        {
                          staticClass:
                            "mb-0 font-120 d-flex align-items-center",
                        },
                        [
                          _c(
                            "span",
                            { staticClass: "circle-number-title mr-2" },
                            [_vm._v("2")]
                          ),
                          _vm._v(" "),
                          _c("span", [_vm._v("A2P Brand")]),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "i",
                        {
                          staticClass: "ml-2 material-icons",
                          class: _vm.a2pBrandTooltip.iconClass,
                        },
                        [
                          _vm._v(
                            "\n                            " +
                              _vm._s(_vm.a2pBrandTooltip.iconName) +
                              "\n                        "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "p",
                        {
                          staticClass:
                            "ml-2 mb-0 font-120 d-flex align-items-center font-weight-bold",
                        },
                        [
                          _c("span", { class: _vm.a2pBrandTooltip.iconClass }, [
                            _vm._v(_vm._s(_vm.a2pBrandTooltip.notice)),
                          ]),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-12" }, [
                        _c("small", [
                          _vm._v(
                            "The A2P (Application-to-Person) Brand registration is a requirement of US Mobile Carriers to allow A2P messaging traffic in their network. The Business\n                                Information will be submitted to "
                          ),
                          _c(
                            "a",
                            {
                              staticClass: "text-blue font-weight-bold",
                              attrs: {
                                href: "https://www.campaignregistry.com",
                                target: "_blank",
                              },
                            },
                            [_vm._v("The Campaign Registry (TCR)")]
                          ),
                          _vm._v(
                            ", the third-party that handles the\n                                process in behalf of the US Mobile Carriers."
                          ),
                        ]),
                        _vm._v(" "),
                        _c("hr", { staticClass: "mt-0" }),
                      ]),
                      _vm._v(" "),
                      !_vm.isSimpSocial
                        ? _c("div", { staticClass: "col-12" }, [
                            _c("h6", [_vm._v("Fee Disclosure")]),
                            _vm._v(" "),
                            _c("small", [
                              _vm._v(
                                "There is a passthrough fee of $44 to register the A2P Brand. If the information submitted does not match your legal business registration, it will be rejected by\n                                TCR and will incur a passthrough fee of $10 to appeal."
                              ),
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "mt-2" },
                              [
                                _c("span", {
                                  staticClass: "page-anchor",
                                  attrs: {
                                    id: "accepted_compliance_one_time_fee",
                                  },
                                }),
                                _vm._v(" "),
                                _c(
                                  "el-checkbox",
                                  {
                                    attrs: {
                                      disabled:
                                        _vm.disableComplianceFeeCheckbox,
                                    },
                                    model: {
                                      value:
                                        _vm.current_company
                                          .accepted_compliance_one_time_fee,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.current_company,
                                          "accepted_compliance_one_time_fee",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "current_company.accepted_compliance_one_time_fee",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                                    I agree to the one-time non-refundable $44 Brand Registration Fee and $10 Appeal Fee in case the A2P Brand is rejected.\n                                "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "col-12" },
                        [
                          _c(
                            "el-tooltip",
                            {
                              attrs: {
                                disabled: !_vm.disableSubmitA2pBrandButton,
                                content: _vm.a2p_brand_message,
                              },
                            },
                            [
                              !_vm.isSimpSocial
                                ? _c(
                                    "el-button",
                                    {
                                      staticClass: "mt-3",
                                      attrs: {
                                        type: "primary",
                                        size: "small",
                                        disabled:
                                          _vm.disableSubmitA2pBrandButton,
                                      },
                                      on: { click: _vm.submitA2pBrand },
                                    },
                                    [
                                      _vm._v(
                                        "\n                                    Submit A2P Brand\n                                "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.a2p_brand_in_use
                        ? _c("div", { staticClass: "col-12 mt-3" }, [
                            [
                              _vm.ComplianceBundleStatus.STATUS_REJECTED,
                              _vm.ComplianceBundleStatus.STATUS_APPROVED,
                            ].includes(_vm.a2p_brand_in_use?.status) &&
                            _vm.a2p_brand_in_use?.score !== null
                              ? _c("p", { staticClass: "mb-0" }, [
                                  _c("b", [_vm._v("Score:")]),
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.a2p_brand_in_use?.score) +
                                      " / 100\n                                "
                                  ),
                                  !_vm.isSimpSocial
                                    ? _c(
                                        "a",
                                        {
                                          staticClass:
                                            "ml-2 text-blue font-weight-bold",
                                          attrs: {
                                            target: "_blank",
                                            href: "https://support.aloware.com/en/articles/9031981-a2p-10dlc-compliance-brand-registration-guide",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                                    Learn more\n                                "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.a2p_brand_in_use?.submitted_at
                              ? _c("p", { staticClass: "mb-0" }, [
                                  _c("b", [_vm._v("Submitted at:")]),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm._f("fixFullDateLocal")(
                                          _vm.a2p_brand_in_use?.submitted_at
                                        )
                                      ) +
                                      "\n                            "
                                  ),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _c("p", { staticClass: "mb-0" }, [
                              _c("b", [_vm._v("Type:")]),
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.a2p_brand_in_use?.type_name || "-"
                                  ) +
                                  "\n                            "
                              ),
                            ]),
                            _vm._v(" "),
                            _vm.a2p_brand_in_use?.status ===
                            _vm.ComplianceBundleStatus.STATUS_REJECTED
                              ? _c("p", { staticClass: "mb-0" }, [
                                  _c("b", [_vm._v("Failure Reason:")]),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.a2p_brand_in_use?.failure_reason
                                      ) +
                                      "\n                            "
                                  ),
                                ])
                              : _vm._e(),
                          ])
                        : _vm._e(),
                    ]),
                  ],
                  2
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row mt-3" }, [
        _c(
          "div",
          { staticClass: "col-12" },
          [
            _c(
              "el-collapse",
              {
                attrs: {
                  accordion: "",
                  value: _vm.shouldAutoExpandA2pCampaignsCases,
                },
              },
              [
                _c(
                  "el-collapse-item",
                  { attrs: { name: "1" } },
                  [
                    _c("template", { slot: "title" }, [
                      _c(
                        "p",
                        {
                          staticClass:
                            "mb-0 font-120 d-flex align-items-center",
                        },
                        [
                          _c(
                            "span",
                            { staticClass: "circle-number-title mr-2" },
                            [_vm._v("3")]
                          ),
                          _vm._v(" "),
                          _c("span", [_vm._v("A2P Campaigns")]),
                          _vm._v(" "),
                          _c(
                            "i",
                            {
                              staticClass: "ml-2 material-icons",
                              class: _vm.messagingServicesTooltip.iconClass,
                            },
                            [
                              _vm._v(
                                "\n                                " +
                                  _vm._s(
                                    _vm.messagingServicesTooltip.iconName
                                  ) +
                                  "\n                            "
                              ),
                            ]
                          ),
                        ]
                      ),
                      _c(
                        "p",
                        {
                          staticClass:
                            "ml-2 mb-0 font-120 d-flex align-items-center font-weight-bold",
                        },
                        [
                          _c(
                            "span",
                            { class: _vm.messagingServicesTooltip.iconClass },
                            [
                              _vm._v(
                                _vm._s(_vm.messagingServicesTooltip.notice)
                              ),
                            ]
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("p"),
                    ]),
                    _vm._v(" "),
                    _c("small", [
                      _vm._v(
                        "\n                        You must register at least ONE A2P Campaign to send outbound SMS. Each A2P Campaign can hold up to 400 Numbers.\n                        "
                      ),
                      !_vm.isSimpSocial
                        ? _c(
                            "a",
                            {
                              staticClass: "text-blue font-weight-bold",
                              attrs: {
                                target: "_blank",
                                href: "https://support.aloware.com/en/articles/9031988-a2p-10dlc-compliance-campaign-registration",
                              },
                            },
                            [
                              _vm._v(
                                "\n                            Learn More\n                        "
                              ),
                            ]
                          )
                        : _vm._e(),
                    ]),
                    _vm._v(" "),
                    _vm.disableRemoveLineFromA2pCampaign()
                      ? _c("small", [
                          _c("br"),
                          _vm._v(
                            "To remove or reassign a phone number from the campaign, kindly contact Aloware Support for assistance\n                    "
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("hr", { staticClass: "mt-0" }),
                    _vm._v(" "),
                    _c("div", { staticClass: "row d-flex" }, [
                      _c(
                        "div",
                        { staticClass: "ml-auto" },
                        [
                          _c(
                            "el-tooltip",
                            {
                              attrs: {
                                disabled: !_vm.hasFailedUseCases,
                                effect: "dark",
                                placement: "top",
                                content:
                                  "Your account has a rejected A2P campaign. Please review and resolve the issues with the rejected campaign before proceeding with registering a new one.",
                              },
                            },
                            [
                              _c(
                                "el-button",
                                {
                                  staticClass: "mr-2",
                                  attrs: {
                                    type: "primary",
                                    size: "mini",
                                    disabled: !_vm.a2pBrandApproved,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.openComplianceWizard(
                                        1,
                                        [],
                                        true
                                      )
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                                    Quick Setup\n                                "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-tooltip",
                            {
                              attrs: {
                                disabled: !_vm.hasFailedUseCases,
                                effect: "dark",
                                placement: "top",
                                content:
                                  "Your account has a rejected A2P campaign. Please review and resolve the issues with the rejected campaign before proceeding with registering a new one.",
                              },
                            },
                            [
                              _c(
                                "el-button",
                                {
                                  staticClass: "mr-3",
                                  attrs: {
                                    type: "secondary",
                                    size: "mini",
                                    disabled: !_vm.a2pBrandApproved,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.openComplianceWizard(1, [])
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                                    Advanced Setup\n                                "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _vm.hasFailedUseCases
                      ? _c(
                          "div",
                          _vm._l(
                            _vm.selected_messaging_service?.a2p_use_case
                              ?.errors,
                            function (error) {
                              return _c("el-alert", {
                                key: error.error_code,
                                staticClass: "mb-2",
                                attrs: {
                                  description: `Error Code ${error.error_code}: ${error.description}`,
                                  title: _vm.fixToUcFirst(error.fields[0]),
                                  type: "error",
                                  "show-icon": "",
                                  closable: false,
                                },
                              })
                            }
                          ),
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "el-table",
                      {
                        directives: [
                          {
                            name: "loading",
                            rawName: "v-loading",
                            value: _vm.messaging_services_loading,
                            expression: "messaging_services_loading",
                          },
                        ],
                        staticClass: "w-full",
                        attrs: { data: _vm.messaging_services, type: "expand" },
                      },
                      [
                        _c("el-table-column", {
                          attrs: { type: "expand" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (props) {
                                return [
                                  _c(
                                    "el-table",
                                    {
                                      attrs: { data: props.row.lines },
                                      on: {
                                        "selection-change": (e) =>
                                          _vm.handleRegisteredLineSelection(
                                            e,
                                            props.row.id
                                          ),
                                      },
                                    },
                                    [
                                      !_vm.disableRemoveLineFromA2pCampaign()
                                        ? _c("el-table-column", {
                                            attrs: {
                                              type: "selection",
                                              selectable: (row) =>
                                                !_vm.disableRemoveLineFromA2pCampaign(),
                                            },
                                          })
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _c("el-table-column", {
                                        attrs: {
                                          prop: "id",
                                          label: "ID",
                                          width: "100",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("el-table-column", {
                                        attrs: { prop: "name", label: "Name" },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function (scope) {
                                                return [
                                                  _c(
                                                    "router-link",
                                                    {
                                                      attrs: {
                                                        to: {
                                                          name: "Line Settings",
                                                          params: {
                                                            campaign_id:
                                                              scope.row.id,
                                                          },
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "text-dark-greenish hover-lower-opacity",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              scope.row.name
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  scope.row.has_local_presence
                                                    ? _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "label ml-2 p-1 text-xxs blue",
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                                                Has Local Presence\n                                            "
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      }),
                                      _vm._v(" "),
                                      _c("el-table-column", {
                                        attrs: {
                                          align: "center",
                                          label: "10DLC Numbers",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function (scope) {
                                                return [
                                                  _vm.messagingServiceNumbers(
                                                    props.row,
                                                    scope.row
                                                  ).length > 0
                                                    ? _c(
                                                        "span",
                                                        [
                                                          _vm._v(
                                                            "\n                                                " +
                                                              _vm._s(
                                                                _vm._f(
                                                                  "fixPhone"
                                                                )(
                                                                  _vm.firstMessagingServiceNumber(
                                                                    props.row,
                                                                    scope.row
                                                                  ).phone_number
                                                                )
                                                              ) +
                                                              "\n                                                "
                                                          ),
                                                          _vm.isNumberHosted(
                                                            _vm.firstMessagingServiceNumber(
                                                              props.row,
                                                              scope.row
                                                            )
                                                          )
                                                            ? _c(
                                                                "span",
                                                                {
                                                                  directives: [
                                                                    {
                                                                      name: "popover",
                                                                      rawName:
                                                                        "v-popover:messaging-service-first-gray-hosted-remark",
                                                                      arg: "messaging-service-first-gray-hosted-remark",
                                                                    },
                                                                  ],
                                                                  staticClass:
                                                                    "font-weight-bold",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n                                                    *\n                                                "
                                                                  ),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                          _vm._v(" "),
                                                          _vm.isNumberHosted(
                                                            _vm.firstMessagingServiceNumber(
                                                              props.row,
                                                              scope.row
                                                            )
                                                          )
                                                            ? _c(
                                                                "el-popover",
                                                                {
                                                                  ref: "messaging-service-first-gray-hosted-remark",
                                                                  attrs: {
                                                                    placement:
                                                                      "top",
                                                                    width: "30",
                                                                    trigger:
                                                                      "hover",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "p",
                                                                    {
                                                                      staticClass:
                                                                        "text-center mb-0",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "Gray Number"
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                        ],
                                                        1
                                                      )
                                                    : _c("span", [
                                                        _vm._v(
                                                          "\n                                                -\n                                            "
                                                        ),
                                                      ]),
                                                  _vm._v(" "),
                                                  _vm.messagingServiceAdditionalNumbers(
                                                    props.row,
                                                    scope.row
                                                  ).length > 0
                                                    ? _c(
                                                        "span",
                                                        [
                                                          _c(
                                                            "el-tag",
                                                            {
                                                              directives: [
                                                                {
                                                                  name: "popover",
                                                                  rawName:
                                                                    "v-popover:messaging-service-numbers",
                                                                  arg: "messaging-service-numbers",
                                                                },
                                                              ],
                                                              staticClass:
                                                                "ml-1",
                                                              attrs: {
                                                                effect: "dark",
                                                                size: "mini",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                                                    + " +
                                                                  _vm._s(
                                                                    _vm.messagingServiceAdditionalNumbers(
                                                                      props.row,
                                                                      scope.row
                                                                    ).length
                                                                  ) +
                                                                  "\n                                                "
                                                              ),
                                                            ]
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "el-popover",
                                                            {
                                                              ref: "messaging-service-numbers",
                                                              attrs: {
                                                                placement:
                                                                  "right",
                                                                width: "200",
                                                                trigger:
                                                                  "hover",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "p",
                                                                {
                                                                  staticClass:
                                                                    "font-weight-bold text-center",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "Additional Numbers"
                                                                  ),
                                                                ]
                                                              ),
                                                              _vm._v(" "),
                                                              _c(
                                                                "ul",
                                                                _vm._l(
                                                                  _vm.messagingServiceAdditionalNumbers(
                                                                    props.row,
                                                                    scope.row
                                                                  ),
                                                                  function (
                                                                    number
                                                                  ) {
                                                                    return _c(
                                                                      "li",
                                                                      {
                                                                        key: number.id,
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "\n                                                            " +
                                                                            _vm._s(
                                                                              _vm._f(
                                                                                "fixPhone"
                                                                              )(
                                                                                number.phone_number
                                                                              )
                                                                            ) +
                                                                            "\n                                                            "
                                                                        ),
                                                                        _vm.isNumberHosted(
                                                                          number
                                                                        )
                                                                          ? _c(
                                                                              "span",
                                                                              {
                                                                                directives:
                                                                                  [
                                                                                    {
                                                                                      name: "popover",
                                                                                      rawName:
                                                                                        "v-popover:messaging-service-gray-hosted-remark",
                                                                                      arg: "messaging-service-gray-hosted-remark",
                                                                                    },
                                                                                  ],
                                                                                staticClass:
                                                                                  "font-weight-bold",
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  "\n                                                                *\n                                                            "
                                                                                ),
                                                                              ]
                                                                            )
                                                                          : _vm._e(),
                                                                        _vm._v(
                                                                          " "
                                                                        ),
                                                                        _vm.isNumberHosted(
                                                                          number
                                                                        )
                                                                          ? _c(
                                                                              "el-popover",
                                                                              {
                                                                                ref: "messaging-service-gray-hosted-remark",
                                                                                refInFor: true,
                                                                                attrs:
                                                                                  {
                                                                                    placement:
                                                                                      "right",
                                                                                    width:
                                                                                      "30",
                                                                                    trigger:
                                                                                      "hover",
                                                                                  },
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "p",
                                                                                  {
                                                                                    staticClass:
                                                                                      "text-center mb-0",
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      "Gray Number"
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                              ]
                                                                            )
                                                                          : _vm._e(),
                                                                      ],
                                                                      1
                                                                    )
                                                                  }
                                                                ),
                                                                0
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      }),
                                      _vm._v(" "),
                                      _c("el-table-column", {
                                        attrs: {
                                          align: "center",
                                          label: "Enrolled At",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function (scope) {
                                                return [
                                                  scope.row
                                                    ? _c("span", [
                                                        _vm._v(
                                                          "\n                                                " +
                                                            _vm._s(
                                                              _vm._f(
                                                                "fixFullDateTimeLocal"
                                                              )(
                                                                _vm.firstMessagingServiceNumber(
                                                                  props.row,
                                                                  scope.row
                                                                )
                                                                  .messaging_service_assigned_at
                                                              )
                                                            ) +
                                                            "\n                                            "
                                                        ),
                                                      ])
                                                    : _vm._e(),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      }),
                                      _vm._v(" "),
                                      _c("el-table-column", {
                                        attrs: { align: "center" },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "header",
                                              fn: function () {
                                                return [
                                                  _vm.canTakeActionOnRegisteredLines
                                                    ? _c(
                                                        "el-button",
                                                        {
                                                          staticClass: "mr-3",
                                                          attrs: {
                                                            type: "danger",
                                                            size: "mini",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.handleOpen()
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "i",
                                                            {
                                                              staticClass:
                                                                "material-icons text-grey text-md",
                                                            },
                                                            [
                                                              _vm._v(
                                                                "remove_circle_outline"
                                                              ),
                                                            ]
                                                          ),
                                                          _vm._v(
                                                            " Remove from campaign\n                                            "
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ]),
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: { label: "ID", width: "100", prop: "id" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(scope.row.id) +
                                      "\n                            "
                                  ),
                                ]
                              },
                            },
                          ]),
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: { label: "Use Case", prop: "use_case" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(
                                        _vm.getUseCaseName(
                                          scope.row.a2p_use_case
                                        )
                                      ) +
                                      "\n                                "
                                  ),
                                  _vm.messagingServiceHasLocalPresenceLines(
                                    scope.row
                                  )
                                    ? _c(
                                        "span",
                                        {
                                          staticClass:
                                            "label ml-1 text-xxs blue",
                                        },
                                        [
                                          _vm._v(
                                            "\n                                    For Local Presence\n                                "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]
                              },
                            },
                          ]),
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: {
                            label: "10DLC Numbers",
                            prop: "incoming_numbers_count",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        scope.row.incoming_numbers.length ?? "0"
                                      ) + " / 400"
                                    ),
                                  ]),
                                ]
                              },
                            },
                          ]),
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: { label: "Status", prop: "status_name" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  !scope.row.a2p_use_case
                                    ? _c(
                                        "b",
                                        { staticClass: "label gray-600" },
                                        [
                                          _vm._v(
                                            "\n                                    Draft\n                                "
                                          ),
                                        ]
                                      )
                                    : !scope.row.a2p_use_case.submitted_at
                                    ? _c(
                                        "el-tooltip",
                                        {
                                          attrs: {
                                            placement: "right",
                                            content: _vm._f(
                                              "prevettingUseCaseStatusExplanation"
                                            )(
                                              scope.row.a2p_use_case
                                                .prevetting_status
                                            ),
                                          },
                                        },
                                        [
                                          _c(
                                            "b",
                                            {
                                              staticClass: "label",
                                              class: _vm._f(
                                                "prevettingStatusColor"
                                              )(
                                                scope.row.a2p_use_case
                                                  .prevetting_status
                                              ),
                                            },
                                            [
                                              _vm._v(
                                                "\n                                        " +
                                                  _vm._s(
                                                    _vm.a2pUseCasePrevettingStatusName(
                                                      scope.row.a2p_use_case
                                                    )
                                                  ) +
                                                  "\n                                    "
                                              ),
                                            ]
                                          ),
                                        ]
                                      )
                                    : _c(
                                        "el-tooltip",
                                        {
                                          attrs: {
                                            placement: "right",
                                            content: _vm._f(
                                              "useCaseStatusExplanation"
                                            )(scope.row.a2p_use_case.status),
                                          },
                                        },
                                        [
                                          _c(
                                            "b",
                                            {
                                              staticClass: "label",
                                              class: _vm._f(
                                                "a2pUseCaseStatusColor"
                                              )(scope.row.a2p_use_case.status),
                                            },
                                            [
                                              _vm._v(
                                                "\n                                        " +
                                                  _vm._s(
                                                    _vm.a2pUseCaseStatusName(
                                                      scope.row.a2p_use_case
                                                    )
                                                  ) +
                                                  "\n                                    "
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                ]
                              },
                            },
                          ]),
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: {
                            label: "Submitted At",
                            prop: "submitted_at",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  !scope.row.a2p_use_case
                                    ? _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("fixFullDateTimeLocal")(
                                              scope.row.submitted_at
                                            )
                                          )
                                        ),
                                      ])
                                    : !scope.row.a2p_use_case.submitted_at
                                    ? _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("fixFullDateTimeLocal")(
                                              scope.row.a2p_use_case
                                                .prevetting_submitted_at
                                            )
                                          )
                                        ),
                                      ])
                                    : _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("fixFullDateTimeLocal")(
                                              scope.row.a2p_use_case
                                                .submitted_at
                                            )
                                          )
                                        ),
                                      ]),
                                ]
                              },
                            },
                          ]),
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: { label: "Actions" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        circle: "",
                                        loading: _vm.deleteUseCaseLoading,
                                        disabled: _vm.deleteUseCaseLoading,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.openComplianceWizard(
                                            2,
                                            [],
                                            false,
                                            scope.row
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "i",
                                        {
                                          staticClass:
                                            "material-icons text-grey",
                                        },
                                        [_vm._v("edit")]
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _vm.is_impersonating
                                    ? _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            circle: "",
                                            loading: _vm.deleteUseCaseLoading,
                                            disabled: _vm.deleteUseCaseLoading,
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.confirmUseCaseDeletion(
                                                scope.row
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "i",
                                            {
                                              staticClass:
                                                "material-icons text-grey",
                                            },
                                            [_vm._v("delete_for_ever")]
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]
                              },
                            },
                          ]),
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "d-flex justify-content-end mt-3" },
                      [
                        _c("el-pagination", {
                          attrs: {
                            layout: "sizes, prev, pager, next",
                            "current-page":
                              _vm.messaging_services_pagination.page,
                            "page-sizes": [10, 20, 50, 100],
                            "page-size": _vm.messaging_services_pagination.size,
                            total: _vm.messaging_services_pagination.total,
                            disabled:
                              _vm.messaging_services_pagination.length === 0,
                          },
                          on: {
                            "update:currentPage": function ($event) {
                              return _vm.$set(
                                _vm.messaging_services_pagination,
                                "page",
                                $event
                              )
                            },
                            "update:current-page": function ($event) {
                              return _vm.$set(
                                _vm.messaging_services_pagination,
                                "page",
                                $event
                              )
                            },
                            "update:pageSize": function ($event) {
                              return _vm.$set(
                                _vm.messaging_services_pagination,
                                "size",
                                $event
                              )
                            },
                            "update:page-size": function ($event) {
                              return _vm.$set(
                                _vm.messaging_services_pagination,
                                "size",
                                $event
                              )
                            },
                            "size-change": function ($event) {
                              return _vm.getMessagingServices(1)
                            },
                            "current-change": _vm.getMessagingServices,
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-dialog",
                      {
                        attrs: {
                          title: "Important Notice on Deregistration",
                          visible: _vm.form_visible,
                          "before-close": _vm.handleClose,
                          center: "",
                        },
                        on: {
                          "update:visible": function ($event) {
                            _vm.form_visible = $event
                          },
                        },
                      },
                      [
                        _c("p", [
                          _vm._v("Hello, "),
                          _c("b", [_vm._v(_vm._s(_vm.current_company.name))]),
                        ]),
                        _vm._v(" "),
                        _c("p", [
                          _vm._v(
                            "We noticed you're attempting to deregister lines along with their number/s. Please be informed:"
                          ),
                        ]),
                        _vm._v(" "),
                        _c("ul", [
                          _c("li", [
                            _vm._v("Unregistering a line can result in "),
                            _c("b", [_vm._v("delays of up to 30 days")]),
                            _vm._v(
                              " before its number/s can be fully utilized again for "
                            ),
                            _c("b", [_vm._v("outbound messaging")]),
                            _vm._v("."),
                          ]),
                          _vm._v(" "),
                          _c("li", [
                            _vm._v(
                              "Your number is still within its initial onboarding phase with the carrier, and frequent changes can cause longer wait times in the future."
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("p", [
                          _c("b", [
                            _vm._v("Accidentally registered these lines?"),
                          ]),
                          _vm._v(
                            " If so, you can proceed, but we strongly advise against frequent changes to ensure a smoother experience."
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "p",
                          [
                            _vm._v(
                              "Are you sure you want to remove the line/s "
                            ),
                            _c("b", [
                              _vm._v(
                                "#" +
                                  _vm._s(
                                    _vm.selected_lines_to_detach.join(", #")
                                  )
                              ),
                            ]),
                            _vm._v(" from this "),
                            _c("B", [_vm._v("A2P Campaign")]),
                            _vm._v("?"),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "template",
                          { slot: "footer" },
                          [
                            _c(
                              "el-button",
                              {
                                staticClass: "ms-auto",
                                attrs: { type: "info" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleClose()
                                  },
                                },
                              },
                              [_c("strong", [_vm._v("Cancel")])]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                staticClass: "ms-auto",
                                attrs: { type: "danger" },
                                on: {
                                  click: function ($event) {
                                    return _vm.detachLinesFromCampaign()
                                  },
                                },
                              },
                              [
                                _c(
                                  "i",
                                  {
                                    staticClass:
                                      "material-icons text-grey text-md",
                                  },
                                  [_vm._v("remove_circle_outline")]
                                ),
                                _vm._v(" "),
                                _c("strong", [_vm._v("Remove")]),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      2
                    ),
                  ],
                  2
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row mt-3" }, [
        _c(
          "div",
          { staticClass: "col-12" },
          [
            _c(
              "el-collapse",
              {
                attrs: {
                  accordion: "",
                  value: _vm.shouldAutoExpandUnregisteredLines,
                },
              },
              [
                _c(
                  "el-collapse-item",
                  { attrs: { name: "1" } },
                  [
                    _c("template", { slot: "title" }, [
                      _c(
                        "p",
                        {
                          staticClass:
                            "mb-0 font-120 d-flex align-items-center",
                        },
                        [
                          _c(
                            "span",
                            { staticClass: "circle-number-title mr-2" },
                            [_vm._v("4")]
                          ),
                          _vm._v(" "),
                          _c("span", [_vm._v("Unregistered Lines")]),
                          _vm._v(" "),
                          _c(
                            "i",
                            {
                              staticClass: "ml-2 material-icons",
                              class: _vm.unregisteredLinesTooltip.iconClass,
                            },
                            [
                              _vm._v(
                                "\n                                " +
                                  _vm._s(
                                    _vm.unregisteredLinesTooltip.iconName
                                  ) +
                                  "\n                            "
                              ),
                            ]
                          ),
                        ]
                      ),
                      _c(
                        "p",
                        {
                          staticClass:
                            "ml-2 mb-0 font-120 d-flex align-items-center font-weight-bold",
                        },
                        [
                          _c(
                            "span",
                            { class: _vm.unregisteredLinesTooltip.iconClass },
                            [
                              _vm._v(
                                _vm._s(_vm.unregisteredLinesTooltip.notice)
                              ),
                            ]
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("p"),
                    ]),
                    _vm._v(" "),
                    _c("hr", { staticClass: "mt-0" }),
                    _vm._v(" "),
                    _c(
                      "el-table",
                      {
                        directives: [
                          {
                            name: "loading",
                            rawName: "v-loading",
                            value: _vm.unregisteredLinesLoading,
                            expression: "unregisteredLinesLoading",
                          },
                        ],
                        staticClass: "w-full",
                        attrs: { data: _vm.unregistered_lines },
                        on: { "selection-change": _vm.handleLineSelection },
                      },
                      [
                        _c("el-table-column", { attrs: { type: "selection" } }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: { prop: "id", label: "ID", width: "100" },
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: { prop: "name", label: "Name" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c(
                                    "router-link",
                                    {
                                      attrs: {
                                        to: {
                                          name: "Line Settings",
                                          params: {
                                            campaign_id: scope.row.id,
                                          },
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "text-dark-greenish hover-lower-opacity",
                                        },
                                        [_vm._v(_vm._s(scope.row.name))]
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  scope.row.has_local_presence
                                    ? _c(
                                        "span",
                                        {
                                          staticClass:
                                            "label ml-2 p-1 text-xxs blue",
                                        },
                                        [
                                          _vm._v(
                                            "\n                                    Has Local Presence\n                                "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]
                              },
                            },
                          ]),
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          scopedSlots: _vm._u([
                            {
                              key: "header",
                              fn: function () {
                                return [
                                  _c("span", { staticClass: "text-nowrap" }, [
                                    _vm._v("Unregistered Numbers"),
                                  ]),
                                ]
                              },
                              proxy: true,
                            },
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  scope.row.incoming_numbers.length === 1
                                    ? _c("span", [
                                        _vm._v(
                                          "\n                                    " +
                                            _vm._s(
                                              _vm._f("fixPhone")(
                                                scope.row.incoming_numbers[0]
                                                  ?.phone_number
                                              )
                                            ) +
                                            "\n                                "
                                        ),
                                      ])
                                    : scope.row.incoming_numbers.length > 1
                                    ? _c(
                                        "span",
                                        [
                                          _vm._v(
                                            "\n                                    " +
                                              _vm._s(
                                                _vm._f("fixPhone")(
                                                  scope.row.incoming_numbers[0]
                                                    ?.phone_number
                                                )
                                              ) +
                                              "\n                                    "
                                          ),
                                          _c(
                                            "el-tag",
                                            {
                                              staticClass:
                                                "ml-1 font-weight-bold",
                                              attrs: {
                                                type: "danger",
                                                effect: "dark",
                                                size: "mini",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                                        + " +
                                                  _vm._s(
                                                    scope.row.incoming_numbers
                                                      .length - 1
                                                  ) +
                                                  "\n                                    "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ]
                              },
                            },
                          ]),
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: { align: "right" },
                          scopedSlots: _vm._u([
                            {
                              key: "header",
                              fn: function () {
                                return [
                                  _vm.canTakeActionOnUnregisteredLines &&
                                  _vm.messaging_services_to_attach.length > 0
                                    ? _c(
                                        "el-popover",
                                        {
                                          attrs: {
                                            placement: "left",
                                            trigger: "click",
                                          },
                                          on: {
                                            show: _vm.getMessagingServicesToAttach,
                                          },
                                        },
                                        [
                                          [
                                            _c(
                                              "el-table",
                                              {
                                                directives: [
                                                  {
                                                    name: "loading",
                                                    rawName: "v-loading",
                                                    value:
                                                      _vm.messaging_services_to_attach_loading,
                                                    expression:
                                                      "messaging_services_to_attach_loading",
                                                  },
                                                ],
                                                attrs: {
                                                  data: _vm.filteredMessagingServices,
                                                },
                                              },
                                              [
                                                _c("el-table-column", {
                                                  attrs: { width: "280" },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "header",
                                                        fn: function () {
                                                          return [
                                                            _c("el-input", {
                                                              staticStyle: {
                                                                width: "100%",
                                                              },
                                                              attrs: {
                                                                clearable: "",
                                                                size: "mini",
                                                                placeholder:
                                                                  "Type to search",
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.messaging_service_search,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.messaging_service_search =
                                                                      $$v
                                                                  },
                                                                expression:
                                                                  "messaging_service_search",
                                                              },
                                                            }),
                                                          ]
                                                        },
                                                        proxy: true,
                                                      },
                                                      {
                                                        key: "default",
                                                        fn: function (scope) {
                                                          return [
                                                            _vm._v(
                                                              "\n                                                    #" +
                                                                _vm._s(
                                                                  scope.row.id
                                                                ) +
                                                                " - " +
                                                                _vm._s(
                                                                  _vm.getUseCaseName(
                                                                    scope.row
                                                                      .a2p_use_case
                                                                  )
                                                                ) +
                                                                " [" +
                                                                _vm._s(
                                                                  scope.row
                                                                    .incoming_numbers
                                                                    .length ??
                                                                    "0"
                                                                ) +
                                                                " / 400]\n                                                    "
                                                            ),
                                                            !_vm.possibleToAttachLine(
                                                              scope.row
                                                            ) &&
                                                            _vm.isLineTypeValid(
                                                              scope.row
                                                            )
                                                              ? _c(
                                                                  "el-tooltip",
                                                                  {
                                                                    attrs: {
                                                                      placement:
                                                                        "right",
                                                                      content:
                                                                        _vm._f(
                                                                          "statusExplanation"
                                                                        )(
                                                                          scope
                                                                            .row
                                                                            ?.a2p_use_case
                                                                            ?.status
                                                                        ),
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "el-tag",
                                                                      {
                                                                        attrs: {
                                                                          type: "danger",
                                                                          effect:
                                                                            "dark",
                                                                          size: "mini",
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "\n                                                            Not Available [" +
                                                                            _vm._s(
                                                                              scope
                                                                                .row
                                                                                .incoming_numbers
                                                                                .length +
                                                                                _vm.selectedLinesToAttachIncomingNumbersCount ??
                                                                                "0"
                                                                            ) +
                                                                            " / 400]\n                                                        "
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ],
                                                                  1
                                                                )
                                                              : _vm._e(),
                                                            _vm._v(" "),
                                                            _vm.messagingServiceHasLocalPresenceLines(
                                                              scope.row
                                                            )
                                                              ? _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "label ml-1 text-xxs blue",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "\n                                                        For Local Presence\n                                                    "
                                                                    ),
                                                                  ]
                                                                )
                                                              : _vm._e(),
                                                          ]
                                                        },
                                                      },
                                                    ],
                                                    null,
                                                    false,
                                                    1844322590
                                                  ),
                                                }),
                                                _vm._v(" "),
                                                _c("el-table-column", {
                                                  attrs: { align: "left" },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "default",
                                                        fn: function (scope) {
                                                          return [
                                                            _c(
                                                              "el-tooltip",
                                                              {
                                                                attrs: {
                                                                  disabled:
                                                                    _vm.possibleToAttachLine(
                                                                      scope.row
                                                                    ),
                                                                  placement:
                                                                    "right",
                                                                  content:
                                                                    _vm.reason,
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "button",
                                                                  {
                                                                    staticClass:
                                                                      "btn btn-icon btn-sm",
                                                                    class:
                                                                      _vm.possibleToAttachLine(
                                                                        scope.row
                                                                      )
                                                                        ? "btn-primary"
                                                                        : "btn-grey",
                                                                    attrs: {
                                                                      loading:
                                                                        _vm.attaching_line_to_messaging_service,
                                                                      disabled:
                                                                        !_vm.possibleToAttachLine(
                                                                          scope.row
                                                                        ) ||
                                                                        _vm.attaching_line_to_messaging_service,
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.attachLineToCampaign(
                                                                            scope
                                                                              .row
                                                                              .id
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "i",
                                                                      {
                                                                        staticClass:
                                                                          "material-icons text-grey",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "add"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        },
                                                      },
                                                    ],
                                                    null,
                                                    false,
                                                    260464939
                                                  ),
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          _vm._v(" "),
                                          _c(
                                            "el-button",
                                            {
                                              staticClass: "mr-1",
                                              attrs: {
                                                slot: "reference",
                                                type: "primary",
                                                size: "mini",
                                                disabled: !_vm.a2pBrandApproved,
                                              },
                                              slot: "reference",
                                            },
                                            [
                                              _c(
                                                "i",
                                                {
                                                  staticClass:
                                                    "material-icons text-grey",
                                                },
                                                [_vm._v("open_in_new")]
                                              ),
                                              _vm._v(
                                                " Add to existing campaign\n                                    "
                                              ),
                                            ]
                                          ),
                                        ],
                                        2
                                      )
                                    : _vm._e(),
                                ]
                              },
                              proxy: true,
                            },
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  scope.row?.a2p_use_case?.status ===
                                  _vm.ComplianceBundleStatus.STATUS_REJECTED
                                    ? _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            circle: "",
                                            loading: _vm.deleteUseCaseLoading,
                                            disabled: _vm.deleteUseCaseLoading,
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.confirmUseCaseDeletion(
                                                scope.row
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "i",
                                            {
                                              staticClass:
                                                "material-icons text-grey",
                                            },
                                            [_vm._v("replay")]
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  scope.row?.a2p_use_case?.status ===
                                  _vm.ComplianceBundleStatus.STATUS_APPROVED
                                    ? _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            circle: "",
                                            loading: _vm.deleteUseCaseLoading,
                                            disabled: _vm.deleteUseCaseLoading,
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.confirmUseCaseDeletion(
                                                scope.row
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "i",
                                            {
                                              staticClass:
                                                "material-icons text-grey",
                                            },
                                            [_vm._v("delete_for_ever")]
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]
                              },
                            },
                          ]),
                        }),
                      ],
                      1
                    ),
                  ],
                  2
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("AgreementOnSmsFees", {
        attrs: { show_form: _vm.show_sms_fees_form },
        on: { close: _vm.closeSmsFeesForm, agreed: _vm.onAgreedSmsFees },
      }),
      _vm._v(" "),
      _c("A2pCampaignWizard", {
        on: { next: _vm.handleNext, close: _vm.closeA2pCampaignWizard },
      }),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.amc_wizard_visible,
            width: "70vw",
            top: "4vh",
            title: "Aloware Managed Compliance",
            "append-to-body": "",
            "custom-class": "wizard-dialog dialog-no-border",
            "data-testid": "amc-creator-dialog",
          },
          on: {
            "update:visible": function ($event) {
              _vm.amc_wizard_visible = $event
            },
            close: _vm.closeAmcWizardModal,
          },
        },
        [
          _c(
            "div",
            {
              attrs: { slot: "title", "data-testid": "new-wizard-title" },
              slot: "title",
            },
            [
              _c("h5", { staticClass: "mb-0" }, [
                _c("img", {
                  staticStyle: { width: "22px", height: "22px" },
                  attrs: {
                    src: "/assets/images/icons/amc-shield-check-mark.svg",
                    alt: "AMC Shield",
                  },
                }),
                _vm._v(
                  "\n                Aloware Managed Compliance\n            "
                ),
              ]),
            ]
          ),
          _vm._v(" "),
          _c("amc-wizard", {
            ref: "amcWizard",
            attrs: { onWizardComplete: _vm.onWizardComplete },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "mr-auto d-flex" }, [
      _c("h5", { staticClass: "text-dark" }, [
        _vm._v("Compliance Registration"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }