<template>
    <div class="app-body"
         id="view"
         v-if="profile && company && !loading_whitelabel">

        <div class="container-fluid pt-3 d-relative">
            <div class="card">
                <div class="card-body">
                    <div v-loading="loading_company">
                        <div class="d-flex justify-content-between mb-2">
                            <h5 class="_300 mb-0">Account</h5>

                            <div class="d-flex align-items-center">
                                <video-modal class="pr-4"
                                             title="Exploring your Account Settings ⚙️"
                                             cookie-name="account"
                                             notes="🔥 Master your experience in <strong>Account Settings</strong>!</br></br>Customize, tweak, and command your preferences to perfection.</br></br>Your epic journey of personalization begins now! 💥"
                                             video-url="https://www.youtube.com/embed/75nMW_ZkpA4?si=bIa0KSTaEidiiI-X"
                                             learn-more-link="https://support.aloware.com/en/articles/9037890-frequently-asked-questions-account-menu"
                                             v-if="!isComplianceTabSelected && isTrial && !isSimpSocial"/>
                                <a class="ml-auto"
                                   href="https://meetings.hubspot.com/alwr/aloware-demo"
                                   target="_blank"
                                   v-if="!loading_whitelabel && !statics.whitelabel && !isCompanyKYC">
                                    <el-button type="success"
                                                size="small"
                                                round>
                                        <i class='material-icons'>perm_contact_calendar</i>
                                        Book a Demo
                                    </el-button>
                                </a>
                            </div>
                        </div>
                        <el-tabs @tab-click="handleClick"
                                 tab-position="left"
                                 type="border-card"
                                 ref="tabs"
                                 id="account-settings-tab"
                                 v-model="activeName">
                            <el-tab-pane label="General Settings"
                                         name="settings"
                                         class="px-3 py-2"
                                         v-if="company && hasPermissionTo('update company')">
                                <el-form :model="company_clone"
                                         :rules="rules.company"
                                         label-position="top"
                                         class="form-aloware"
                                         ref="companySettingsForm"
                                         @submit.prevent.native="submitForm('companySettingsForm')">
                                    <div class="row">
                                        <div class="col-md-6">
                                            <el-row class="pb-3"
                                                    v-if="forceTalk && hasRole('Company Admin')">
                                                <el-alert show-icon
                                                          :closable=false>
                                                    <span class="d-block mb-1">Communication functionality and contact information are now only accessible in Aloware Talk. Click <b><a :href="talk2Url" target="_blank">here</a></b> to navigate to it.</span>
                                                </el-alert>
                                            </el-row>

                                            <el-form-item prop="name">
                                                <span class="page-anchor"
                                                      id="name">
                                                </span>
                                                <div class="form-label">
                                                    <h5>Name</h5>
                                                    <small>Your main account name. Please contact us if you wish to
                                                        change the legal owner of the account.</small>
                                                </div>
                                                <el-input placeholder="Name of the company"
                                                          v-model="company_clone.name"
                                                          @input="updateCompanySettings">
                                                </el-input>
                                            </el-form-item>

                                            <el-form-item prop="timezone">
                                                <span class="page-anchor"
                                                      id="timezone">
                                                </span>
                                                <div class="form-label">
                                                    <h5>Timezone</h5>
                                                    <small>
                                                        Account's main timezone. Sequences, business hours, and some
                                                        more controls follow this timezone.
                                                        This is also the base timezone for your reports.
                                                    </small>
                                                </div>
                                                <el-select class="w-full"
                                                           filterable
                                                           placeholder="Select Your Timezone"
                                                           v-model="company_clone.timezone"
                                                           @change="updateCompanySettings">
                                                    <el-option :key="timezone.value"
                                                               :label="timezone.name"
                                                               :value="timezone.value"
                                                               v-for="timezone in timezones">
                                                    </el-option>
                                                    <el-option :label="timezone.text"
                                                               :value="timezone.value"
                                                               v-if="timezone.found">
                                                    </el-option>
                                                </el-select>
                                            </el-form-item>

                                            <el-form-item prop="default_homepage">
                                                <span class="page-anchor"
                                                      id="default_homepage">
                                                </span>
                                                <div class="form-label">
                                                    <h5>Default Homepage</h5>
                                                    <small>
                                                        Where you want your users to go to first when they login to
                                                        Aloware.
                                                    </small>
                                                </div>
                                                <el-select
                                                    class="w-full"
                                                    v-model="company_clone.default_homepage"
                                                    placeholder="Select"
                                                    @change="changeHomepage">
                                                    <el-option
                                                        v-for="page in homepages"
                                                        class="p-0"
                                                        :key="page.value"
                                                        :label="page.label"
                                                        :value="page.value">
                                                        {{ page.label }}
                                                    </el-option>
                                                </el-select>
                                            </el-form-item>

                                            <el-form-item prop="private_inbox_toggle">
                                                <span class="page-anchor"
                                                      id="private_inbox_toggle">
                                                </span>
                                                <div class="form-label">
                                                    <h5>Private Lines</h5>
                                                    <small>Restrict agents from seeing other agents’ personal lines.</small>
                                                </div>
                                                <div class="switch-label">
                                                    <el-switch active-color="#00a344"
                                                               v-model="company_clone.private_inbox_enabled"
                                                               @change="updateCompanySettings">
                                                    </el-switch>
                                                    <label>Enable Private Lines</label>
                                                </div>
                                                <div class="mt-2 pointer text-blue"
                                                     v-if="auth.user && auth.user.profile">
                                                    <a :href="'/users/dialog/' + auth.user.profile.id + '?tab=visibility'">
                                                        Do you want to restrict individual agent line? Go to user's settings
                                                    </a>
                                                </div>
                                            </el-form-item>

                                            <el-form-item prop="send_long_messages_as_mms_toggle">
                                                <span class="page-anchor"
                                                      id="send_long_messages_as_mms_toggle">
                                                </span>
                                                <div class="form-label">
                                                    <h5>Send long text messages as MMS</h5>
                                                    <small>Messages longer than 160 characters will automatically send as an MMS rather than multiple SMS segments.</small>
                                                    <small>This setting can also be made on individual Line Settings.</small>
                                                </div>
                                                <div class="switch-label">
                                                    <el-switch active-color="#00a344"
                                                               v-model="company_clone.send_long_messages_as_mms"
                                                               @change="updateCompanySettings">
                                                    </el-switch>
                                                    <label>Enable Send long text messages as MMS</label>
                                                </div>
                                            </el-form-item>

                                            <h4 class="mb-4 _500">Broadcast Settings</h4>

                                            <el-form-item prop="broadcasts_business_hours">
                                                <span class="page-anchor"
                                                      id="broadcasts_business_hours">
                                                </span>
                                                <div class="form-label">
                                                    <h5>Business Hours for Broadcasts</h5>
                                                    <small>Broadcast can only be run during business hours you set. This prevents users from accidentally sending Broadcasts late at night.</small>
                                                </div>
                                                <div class="form-inline">
                                                    <div class="form-label mb-0">
                                                        <h5 class="text-black">Timezone: </h5>
                                                    </div>
                                                    <span class="ml-2 font-weight-normal">{{ companyTimezone }}</span>
                                                </div>
                                                <div class="form-inline">
                                                    <time-range-picker v-model="broadcast_hours"
                                                                       :default_start_time="company_clone.broadcast_open"
                                                                       :default_end_time="company_clone.broadcast_close"
                                                                       @change="updateBroadcastHours">
                                                    </time-range-picker>
                                                </div>
                                            </el-form-item>

                                            <el-form-item prop="sequence">
                                                <span class="page-anchor"
                                                      id="sequence">
                                                </span>
                                                <div class="form-label">
                                                    <h5>Sequence Reenrollment</h5>
                                                    <small>Allow triggers to disenroll contacts from the current sequence and enroll in a different sequence? Note: Contacts can only be enrolled in one sequence at a time</small>
                                                </div>
                                                <div class="switch-label">
                                                    <el-switch active-color="#00a344"
                                                               v-model="company_clone.sequence_allow_reenrollment"
                                                               @change="updateCompanySettings">
                                                    </el-switch>
                                                    <label>Allow reenrollment</label>
                                                </div>
                                            </el-form-item>

                                            <el-form-item prop="task_status_logs">
                                                <span class="page-anchor"
                                                      id="task_status_logs">
                                                </span>
                                                <div class="form-label">
                                                    <h5>Show Task Status Logs</h5>
                                                    <small>In the inbox, Admins can choose to hide the display of task status changes from the communication inbox. For example, "Open to Pending by System". By default users see every change, but if disabled then these logs will be hidden.</small>
                                                </div>
                                                <div class="switch-label">
                                                    <el-switch active-color="#00a344"
                                                               v-model="company_clone.task_status_logs"
                                                               @change="updateCompanySettings">
                                                    </el-switch>
                                                    <label>Task Status Changes</label>
                                                </div>
                                            </el-form-item>
                                        </div>
                                        <div class="col-md-2 d-none d-md-block ml-auto">
                                            <div class="list-group list-group-alo list-group-flush sticky-top stick-top-with-header top-70">
                                                <h6 class="list-group-item">
                                                    On This Page
                                                </h6>
                                                <a href="#name"
                                                   class="list-group-item list-group-item-action"
                                                   @click="shine('name')">
                                                    Name
                                                </a>
                                                <a href="#timezone"
                                                   class="list-group-item list-group-item-action"
                                                   @click="shine('timezone')">
                                                    Timezone
                                                </a>
                                                <a href="#default_homepage"
                                                   class="list-group-item list-group-item-action"
                                                   @click="shine('default_homepage')">
                                                    Default Homepage
                                                </a>
                                                <a href="#private_inbox_toggle"
                                                   class="list-group-item list-group-item-action"
                                                   @click="shine('private_inbox_toggle')">
                                                    Private Lines
                                                </a>
                                                <a href="#send_long_messages_as_mms_toggle"
                                                   class="list-group-item list-group-item-action"
                                                   @click="shine('send_long_messages_as_mms_toggle')">
                                                    Send long text messages as MMS
                                                </a>
                                                <a href="#broadcasts_business_hours"
                                                   class="list-group-item list-group-item-action"
                                                   @click="shine('broadcasts_business_hours')">
                                                    Business Hours for Broadcasts
                                                </a>
                                                <a href="#sequence"
                                                   class="list-group-item list-group-item-action"
                                                   @click="shine('sequence')">
                                                    Sequence Reenrollment
                                                </a>
                                                <a href="#task_status_logs"
                                                   class="list-group-item list-group-item-action"
                                                   @click="shine('task_status_logs')">
                                                   Show Task Status Logs
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </el-form>
                            </el-tab-pane>
                            <el-tab-pane label="Compliance" class="px-3 py-2" name="compliance">
                                <compliance-tab />
                            </el-tab-pane>
                            <el-tab-pane
                                label="Transcription Settings"
                                name="transcription-settings"
                                class="px-3 py-2"
                                :disabled="!company.transcription_enabled"
                                v-if="company && hasPermissionTo('update company')">
                                <account-transcription-settings
                                    ref="account_transcription_settings"
                                    :company_clone="company_clone"
                                    @update-parent="updateParent">
                                </account-transcription-settings>
                            </el-tab-pane>
                            <el-tab-pane
                                label="Contact Settings"
                                name="contact-settings"
                                class="px-3 py-2"
                                v-if="company && hasPermissionTo('update company')">
                                <account-contact-settings
                                    ref="account_contact_setting"
                                    :company_clone="company_clone"
                                    @update-parent="updateParent">
                                </account-contact-settings>
                            </el-tab-pane>
                            <el-tab-pane label="Calling Settings"
                                         class="px-3 py-2"
                                         name="calling-settings">
                                <el-form :model="company_clone"
                                         :rules="rules.company"
                                         label-position="top"
                                         class="form-aloware"
                                         ref="companySettingsForm"
                                         @submit.prevent.native="submitForm('companySettingsForm')">
                                    <div class="row">
                                        <div class="col-md-6">
                                            <el-form-item prop="force_users_always_available">
                                                <span class="page-anchor"
                                                      id="force_users_always_available">
                                                </span>
                                                <div class="form-label">
                                                    <h5>
                                                        Force Users to Always Available
                                                        <el-popover
                                                            placement="right"
                                                            width="450"
                                                            trigger="hover">
                                                            <div>
                                                                <span class="d-flex font-weight-bold">Turning on 'Force Users to Always Available' Setting:</span>
                                                                <ul class="mb-0">
                                                                    <li>Users' statuses are set to 'Available' even after they login</li>
                                                                    <li>Users cannot manually change their status</li>
                                                                    <li>User's application will receive inbound calls until they logout</li>
                                                                    <li>Admins can still manually change agent's statuses in Wallboard</li>
                                                                </ul>
                                                            </div>
                                                            <i slot="reference"
                                                               class="fa fa-info-circle text-primary ml-2">
                                                            </i>
                                                        </el-popover>
                                                    </h5>
                                                    <small>
                                                        <span class="d-flex">Set all users' statuses to <b class="pl-1">Available</b></span>
                                                        <span class="d-flex">Remove users' ability to manually change their status to <b class="pl-1">Busy / Break</b></span>
                                                        <span class="d-flex">Admins can still manually change agents' statuses in Wallboard</span>
                                                    </small>
                                                </div>
                                                <div class="switch-label">
                                                    <el-switch active-color="#00a344"
                                                               v-model="company_clone.force_users_always_available"
                                                               @change="updateCompanySettings">
                                                    </el-switch>
                                                    <label>Enable force users to always available</label>
                                                </div>
                                            </el-form-item>

                                            <el-form-item prop="wrap_up_seconds">
                                                <span class="page-anchor"
                                                      id="wrap_up_seconds">
                                                </span>
                                                <div class="form-label">
                                                    <h5>Wrap Up Duration</h5>
                                                    <small>
                                                        Time to keep the dialer in wrap-up before making the agent
                                                        available again.
                                                        Agents can override the setting in their profile.
                                                    </small>
                                                </div>
                                                <el-select class="w-full"
                                                           placeholder="Select wrap up seconds"
                                                           v-model="company_clone.wrap_up_seconds"
                                                           @change="updateCompanySettings">
                                                    <el-option
                                                        :key="item.value"
                                                        :label="item.label"
                                                        :value="item.value"
                                                        v-for="item in wrap_up_seconds">
                                                    </el-option>
                                                </el-select>
                                            </el-form-item>

                                            <el-form-item prop="force_wrap_up">
                                                <span class="page-anchor"
                                                      id="force_wrap_up">
                                                </span>
                                                <div class="form-label">
                                                    <h5>Force Wrap Up Settings</h5>
                                                    <small>Force wrap up duration on agents. This prevents the agents
                                                        from overriding settings.</small>
                                                </div>
                                                <div class="switch-label">
                                                    <el-switch active-color="#00a344"
                                                               v-model="company_clone.force_wrap_up"
                                                               @change="updateCompanySettings">
                                                    </el-switch>
                                                    <label>Enable force wrap up</label>
                                                </div>
                                            </el-form-item>

                                            <RecordingMode v-model="company_clone.inbound_call_recording_mode"
                                                            id="inbound_call_recording_mode"
                                                            title="Inbound Call Recordings"
                                                            @change="updateInboundCallRecording"
                                                            :options="inbound_recording_mode_options">
                                                Force call recording for all inbound calls across Lines. By
                                                default, this setting is configured inside each line.
                                            </RecordingMode>

                                            <div class="row"
                                                 v-if="company_clone.inbound_call_recording_mode === InboundCallRecordingModes.INBOUND_CALL_RECORDING_MODE_ALWAYS">
                                                <div class="col-12">
                                                    <span>You can record or upload an audio file for incoming phone call notification.</span>
                                                    <div class="b-b nav-active-greenish">
                                                        <ul class="nav nav-tabs">
                                                            <li class="nav-item">
                                                                <a class="border border-right-0 rounded-top-left"
                                                                   :class="recordNavClass.inbound_read"
                                                                   href=""
                                                                   data-toggle="tab"
                                                                   data-target="#inbound-record-tts"
                                                                   aria-expanded="true">
                                                                    <i class="fa fa-robot"></i>
                                                                    Text to Speech
                                                                </a>
                                                            </li>
                                                            <li class="nav-item"
                                                                v-if="hasPermissionTo('update company')">
                                                                <a class="border rounded-top-right"
                                                                   :class="recordNavClass.inbound_play"
                                                                   href=""
                                                                   data-toggle="tab"
                                                                   data-target="#inbound-record-file"
                                                                   aria-expanded="false">
                                                                    <i class="fa fa-play text-md"></i>
                                                                    Play Recording
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </div>

                                                    <div class="tab-content p-a m-b-md border border-top-0">
                                                        <div :class="recordContainerClass.inbound_read"
                                                             id="inbound-record-tts"
                                                             aria-expanded="true">
                                                            <el-form-item label="Recording Message"
                                                                          prop="should_record">
                                                                <div class="row">
                                                                    <div class="col-12">
                                                                        <div class="input-group">
                                                                            <input type="text"
                                                                                   class="form-control pt-2 pb-2"
                                                                                   v-model="company_clone.inbound_record_tts"
                                                                                   @change="updateInboundCallRecording"
                                                                                   @input="preValidateForm('companySettingsForm')"/>
                                                                            <variable-dialog></variable-dialog>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </el-form-item>
                                                        </div>
                                                        <div :class="recordContainerClass.inbound_play"
                                                             id="inbound-record-file"
                                                             aria-expanded="false"
                                                             v-if="hasPermissionTo('update company')">
                                                            <audio-recorder
                                                                v-if="company_clone.inbound_record_file === null"
                                                                :responsive="true"
                                                                :action="'/api/v1/company/' + company_clone.id + '/upload-ivr/inbound_recording_notices'"
                                                                :upload_type="'inbound_record_file'"
                                                                @applyUploadedAudio="applyUploadedAudio">
                                                            </audio-recorder>
                                                            <div class="audio-uploader-wrapper">
                                                                <el-upload drag
                                                                           v-if="company_clone.inbound_record_file === null"
                                                                           :headers="headers"
                                                                           :action="'/api/v1/company/' + company_clone.id + '/upload-ivr/inbound_recording_notices'"
                                                                           :on-success="onSuccessUploadInboundRecording"
                                                                           :on-error="onFailedUploadRecording"
                                                                           :on-progress="progressUploadRecording"
                                                                           :before-upload="beforeUploadInboundRecording">
                                                                    <i class="el-icon-upload"></i>
                                                                    <div class="el-upload__text">
                                                                        Drop file here or <em>click to
                                                                        upload</em>
                                                                    </div>
                                                                    <div class="el-upload__tip"
                                                                         slot="tip">
                                                                        MP3/WAV file (less than 8MB)
                                                                    </div>
                                                                </el-upload>
                                                                <el-progress :text-inside="true"
                                                                             :stroke-width="18"
                                                                             :percentage="uploadPercentage.record"
                                                                             :status="uploadStatus.record"
                                                                             v-if="company_clone.inbound_record_file === null">
                                                                </el-progress>
                                                                <div
                                                                    class="d-flex justify-content-start align-items-center">
                                                                    <audio controls
                                                                           v-if="company_clone.inbound_record_file !== null">
                                                                        <source
                                                                            :src="'/static/uploaded_file/' + company_clone.inbound_record_file">
                                                                        Your browser does not support the audio element.
                                                                    </audio>
                                                                    <button class="btn btn-sm btn-danger ml-3"
                                                                            v-if="company_clone.inbound_record_file !== null && hasPermissionTo('update company')"
                                                                            @click.prevent="deleteFile('inbound_recording_notices')">
                                                                        <i class="material-icons loader pull-left"
                                                                           v-show="loading_inbound_recording">&#xE863;</i>
                                                                        <i class="material-icons pull-left"
                                                                           v-show="!loading_inbound_recording">&#xE872;</i>
                                                                        <span>Remove file</span>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <RecordingMode v-model="company_clone.outbound_call_recording_mode"
                                                            id="outbound_call_recording_mode"
                                                            title="Outbound Call Recordings"
                                                            @change="updateOutboundCallRecording"
                                                            :options="outbound_recording_mode_options">
                                                Force call recording for all outbound calls across all agents.
                                                By default, this setting is configured per agent.
                                            </RecordingMode>

                                            <RecordingForce v-model="company_clone.force_outbound_recording"
                                                             id="force_outbound_recording"
                                                            title="Force Outbound Call Recording Settings"
                                                            @change="updateCompanySettings">
                                                Enable force outbound call recording
                                            </RecordingForce>

                                            <div class="row"
                                                 v-if="company_clone.outbound_call_recording_mode === OutboundCallRecordingModes.OUTBOUND_CALL_RECORDING_MODE_ALWAYS">
                                                <div class="col-12">
                                                    <span>You can record or upload an audio file for outgoing phone call notifications.</span>
                                                    <div class="b-b nav-active-greenish">
                                                        <ul class="nav nav-tabs">
                                                            <li class="nav-item">
                                                                <a class="border border-right-0 rounded-top-left"
                                                                   :class="recordNavClass.outbound_read"
                                                                   href=""
                                                                   data-toggle="tab"
                                                                   data-target="#outbound-record-tts"
                                                                   aria-expanded="true">
                                                                    <i class="fa fa-robot"></i>
                                                                    Text to Speech
                                                                </a>
                                                            </li>
                                                            <li class="nav-item"
                                                                v-if="hasPermissionTo('update company')">
                                                                <a class="border rounded-top-right"
                                                                   :class="recordNavClass.outbound_play"
                                                                   href=""
                                                                   data-toggle="tab"
                                                                   data-target="#outbound-record-file"
                                                                   aria-expanded="false">
                                                                    <i class="fa fa-play text-md"></i>
                                                                    Play Recording
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </div>

                                                    <div class="tab-content p-a m-b-md border border-top-0">
                                                        <div :class="recordContainerClass.outbound_read"
                                                             id="outbound-record-tts"
                                                             aria-expanded="true">
                                                            <el-form-item label="Recording Message"
                                                                          prop="should_record">
                                                                <div class="row">
                                                                    <div class="col-12">
                                                                        <div class="input-group">
                                                                            <input type="text"
                                                                                   class="form-control pt-2 pb-2"
                                                                                   v-model="company_clone.outbound_record_tts"
                                                                                   @change="updateOutboundCallRecording"
                                                                                   @input="preValidateForm('companySettingsForm')"/>
                                                                            <variable-dialog></variable-dialog>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </el-form-item>
                                                        </div>
                                                        <div :class="recordContainerClass.outbound_play"
                                                             id="outbound-record-file"
                                                             aria-expanded="false"
                                                             v-if="hasPermissionTo('update company')">
                                                            <audio-recorder
                                                                v-if="company_clone.outbound_record_file === null"
                                                                :responsive="true"
                                                                :action="'/api/v1/company/' + company_clone.id + '/upload-ivr/outbound_recording_notices'"
                                                                :upload_type="'outbound_record_file'"
                                                                @applyUploadedAudio="applyUploadedAudio">
                                                            </audio-recorder>
                                                            <div class="audio-uploader-wrapper">
                                                                <el-upload drag
                                                                           v-if="company_clone.outbound_record_file === null"
                                                                           :headers="headers"
                                                                           :action="'/api/v1/company/' + company_clone.id + '/upload-ivr/outbound_recording_notices'"
                                                                           :on-success="onSuccessUploadOutboundRecording"
                                                                           :on-error="onFailedUploadRecording"
                                                                           :on-progress="progressUploadRecording"
                                                                           :before-upload="beforeUploadOutboundRecording">
                                                                    <i class="el-icon-upload"></i>
                                                                    <div class="el-upload__text">
                                                                        Drop file here or <em>click to upload</em>
                                                                    </div>
                                                                    <div class="el-upload__tip"
                                                                         slot="tip">
                                                                        MP3/WAV file (less than 8MB)
                                                                    </div>
                                                                </el-upload>
                                                                <el-progress :text-inside="true"
                                                                             :stroke-width="18"
                                                                             :percentage="uploadPercentage.record"
                                                                             :status="uploadStatus.record"
                                                                             v-if="company_clone.outbound_record_file === null">
                                                                </el-progress>
                                                                <div
                                                                    class="d-flex justify-content-start align-items-center">
                                                                    <audio controls
                                                                           v-if="company_clone.outbound_record_file !== null">
                                                                        <source
                                                                            :src="'/static/uploaded_file/' + company_clone.outbound_record_file">
                                                                        Your browser does not support the audio element.
                                                                    </audio>
                                                                    <button class="btn btn-sm btn-danger ml-3"
                                                                            v-if="company_clone.outbound_record_file !== null && hasPermissionTo('update company')"
                                                                            @click.prevent="deleteFile('outbound_recording_notices')">
                                                                        <i class="material-icons loader pull-left"
                                                                           v-show="loading_outbound_recording">&#xE863;</i>
                                                                        <i class="material-icons pull-left"
                                                                           v-show="!loading_outbound_recording">&#xE872;</i>
                                                                        <span>Remove file</span>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <el-form-item prop="default_outbound_campaign_id">
                                                <span class="page-anchor"
                                                      id="default_outbound_campaign_id">
                                                </span>
                                                <div class="form-label">
                                                    <h5>Default Outbound Line</h5>
                                                    <small>
                                                        Choose an outbound line for your outbound calls.
                                                        You can also let the agents choose this setting on their
                                                        profile.
                                                    </small>
                                                </div>
                                                <campaign-selector
                                                    class_prop="no-select mb-3 w-full"
                                                    placeholder="Always ask"
                                                    :show_paused="false"
                                                    clearable
                                                    filterable
                                                    v-model="company_clone.default_outbound_campaign_id"
                                                    @change="updateDefaultOutboundCampaignId">
                                                </campaign-selector>
                                                <div
                                                    class="alert alert-warning alert-aloware d-inline-block lh-1 p-2">
                                                    <i class="fa fa-exclamation-triangle"></i>
                                                    If no line is selected, your agents will be prompted to choose a
                                                    line before making an outbound call on the dialer.
                                                </div>
                                            </el-form-item>

                                            <el-form-item prop="wait_voice_enabled">
                                                <span class="page-anchor"
                                                      id="wait_voice_enabled">
                                                </span>
                                                <div class="form-label">
                                                    <h5>Wait Voice</h5>
                                                    <small>Make or prevent agents from hearing <i>Connecting...</i> at the beginning of outbound calls.</small>
                                                </div>
                                                <div class="switch-label">
                                                    <el-switch active-color="#00a344"
                                                               v-model="company_clone.wait_voice_enabled"
                                                               @change="updateCompanySettings">
                                                    </el-switch>
                                                    <label>Enable Wait Voice</label>
                                                </div>
                                            </el-form-item>

                                            <el-form-item prop="force_outbound_line">
                                                <span class="page-anchor"
                                                      id="force_outbound_line">
                                                </span>
                                                <div class="form-label">
                                                    <h5>Force Outbound Line Settings</h5>
                                                    <small>Prevent agents from overriding these settings.</small>
                                                </div>
                                                <div class="switch-label">
                                                    <el-switch active-color="#00a344"
                                                               v-model="company_clone.force_outbound_line"
                                                               @change="updateCompanySettings">
                                                    </el-switch>
                                                    <label>Enable force outbound line settings on users</label>
                                                </div>
                                            </el-form-item>

                                            <el-form-item>
                                                <span class="page-anchor"
                                                      id="force_call_disposition">
                                                </span>
                                                <div class="form-label">
                                                    <h5>Force Call Disposition (Beta)</h5>
                                                    <small>
                                                        If this option is turned on, wrap-up timer will be
                                                        paused, back button, callback, and agent status dropdown
                                                        will be locked until the agent disposes of the call.
                                                    </small>
                                                </div>
                                                <div class="switch-label">
                                                    <el-switch active-color="#00a344"
                                                               v-model="company_clone.force_call_disposition"
                                                               @change="updateCompanySettings">
                                                    </el-switch>
                                                    <label>Enable force call disposition</label>
                                                </div>
                                            </el-form-item>

                                            <el-form-item>
                                                <span class="page-anchor"
                                                      id="force_contact_disposition">
                                                </span>
                                                <div class="form-label">
                                                    <h5>Force Contact Disposition (Beta)</h5>
                                                    <small>
                                                        If this option is turned on, wrap-up timer will be
                                                        paused, back button, callback, and agent status dropdown
                                                        will be locked until the agent disposes of the contact.
                                                    </small>
                                                </div>
                                                <div class="switch-label">
                                                    <el-switch active-color="#00a344"
                                                               v-model="company_clone.force_contact_disposition"
                                                               @change="updateCompanySettings">
                                                    </el-switch>
                                                    <label>Enable force contact disposition</label>
                                                </div>
                                            </el-form-item>

                                            <el-form-item v-if="false">
                                                <span class="page-anchor"
                                                      id="show_call_missed_modal">
                                                </span>
                                                <div class="form-label">
                                                    <h5>Missed-Call Status <span class="label indigo">Talk Only</span></h5>
                                                    <small>
                                                        When a call is missed, the agent status will change to Busy.
                                                        However, if this option is enabled, it will allow agents to mark
                                                        themselves as available with a pop-up after the missed call.
                                                    </small>
                                                </div>
                                                <div class="switch-label">
                                                    <el-switch active-color="#00a344"
                                                               v-model="company_clone.show_call_missed_modal"
                                                               @change="updateCompanySettings">
                                                    </el-switch>
                                                    <label>Show missed call pop-up</label>
                                                </div>
                                            </el-form-item>

                                            <el-form-item>
                                                <span class="page-anchor"
                                                      id="hold_music">
                                                </span>
                                                <div class="form-label">
                                                    <h5>Hold Music</h5>
                                                    <small>
                                                        Here you can customize the hold music for the entire account. Leave blank to use our default hold music.
                                                    </small>
                                                </div>
                                                <div class="switch-label">
                                                    <el-switch active-color="#00a344"
                                                               v-model="show_custom_hold_music"
                                                               @change="checkHoldMusicFile">
                                                    </el-switch>
                                                    <label class="el-form-item__label ml-2">
                                                        Customize hold music
                                                    </label>
                                                </div>
                                                <div v-if="show_custom_hold_music">
                                                    <audio-recorder v-if="company_clone.hold_music_file === null"
                                                                    class="audio-recorder-wrapper"
                                                                    :action="'/api/v1/company/' + company_clone.id + '/upload-ivr/hold_musics'"
                                                                    :upload_type="'hold_music_file'"
                                                                    @applyUploadedAudio="applyUploadedAudio">
                                                    </audio-recorder>
                                                    <div class="audio-uploader-wrapper">
                                                        <el-upload v-if="company_clone.hold_music_file === null"
                                                                   :action="'/api/v1/company/' + company_clone.id + '/upload-ivr/hold_musics'"
                                                                   :headers="headers"
                                                                   :on-success="onSuccessUploadHoldMusic"
                                                                   :on-error="onFailedUploadHoldMusic"
                                                                   :on-progress="progressUploadHoldMusic"
                                                                   :before-upload="beforeUploadHoldMusic"
                                                                   :file-list="uploadFileList.holdMusic"
                                                                   drag>
                                                            <i class="el-icon-upload"></i>
                                                            <div class="el-upload__text">
                                                                Drop file here or <em>click to upload</em>
                                                            </div>
                                                            <div class="el-upload__tip"
                                                                 slot="tip">
                                                                MP3/WAV file (less than 8MB)
                                                            </div>
                                                        </el-upload>
                                                        <el-progress :text-inside="true"
                                                                     :stroke-width="18"
                                                                     :percentage="uploadPercentage.holdMusic"
                                                                     :status="uploadStatus.holdMusic"
                                                                     v-if="company_clone.hold_music_file === null">
                                                        </el-progress>
                                                        <audio v-if="company_clone.hold_music_file !== null"
                                                               controls>
                                                            <source :src="'/static/uploaded_file/' + company_clone.hold_music_file">
                                                            Your browser does not support the audio element.
                                                        </audio>
                                                        <button class="btn btn-sm btn-danger pull-right"
                                                                v-if="company_clone.hold_music_file !== null"
                                                                @click.prevent="deleteFile('hold_musics')">
                                                            <i class="material-icons loader pull-left"
                                                               v-show="loading_hold_music">&#xE863;</i>
                                                            <i class="material-icons pull-left"
                                                               v-show="!loading_hold_music">&#xE872;</i>
                                                            <span>Remove file</span>
                                                        </button>
                                                    </div>
                                                </div>
                                            </el-form-item>

                                            <el-form-item>
                                                <span class="page-anchor"
                                                      id="dual_channel_recording">
                                                </span>
                                                <div class="form-label">
                                                    <h5>Call Recording Channels</h5>
                                                    <small>
                                                        Enable dual-channel recording for calls
                                                    </small>
                                                </div>
                                                <div class="switch-label">
                                                    <el-switch active-color="#00a344"
                                                               v-model="company_clone.dual_channel_recording"
                                                               @change="updateCompanySettings">
                                                    </el-switch>
                                                    <label>Enable dual-channel recording for calls</label>
                                                </div>
                                            </el-form-item>

                                            <el-form-item prop="caller_id_spoofing"
                                                          class="   mt-3">
                                                <span class="page-anchor"
                                                      id="call_masking">
                                                </span>
                                                <div class="form-label">
                                                    <h5>Call Masking</h5>
                                                    <small>Enable to use lead’s phone number for all transfers (inbound and outbound)</small>
                                                </div>
                                                <div class="switch-label">
                                                    <el-switch active-color="#00a344"
                                                               v-model="company_clone.caller_id_spoofing"
                                                               @change="updateCompanySettings">
                                                    </el-switch>
                                                    <label>Enable Call Masking</label>
                                                </div>
                                            </el-form-item>
                                            <el-form-item prop="fishing_mode_notification_sound"
                                                          class="   mt-3">
                                                <span class="page-anchor"
                                                      id="notification_sound_of_fishing_mode">
                                                </span>
                                                <div class="form-label">
                                                    <h5>Notification Sound of Fishing Mode</h5>
                                                    <small>When Fishing Mode is turned on, the sound of the notification for each user during an incoming call can make a single notification sound or ring like a normal call.</small>
                                                </div>
                                                <div class="switch-label">
                                                    <el-switch active-color="#00a344"
                                                               v-model="company_clone.fishing_mode_notification_sound"
                                                               @change="updateCompanySettings">
                                                    </el-switch>
                                                    <label>Normal Ring Enabled</label>
                                                </div>
                                            </el-form-item>
                                            <el-form-item
                                                prop="call_recording_retention">
                                                <span class="page-anchor"
                                                      id="call_recording_retention">
                                                </span>
                                                <div class="form-label">
                                                    <h5>Call records retention duration</h5>
                                                </div>
                                                <retention-duration-feature-overview/>
                                                <el-tooltip
                                                    placement="top"
                                                    effect="dark"
                                                    :disabled="hasRole('Billing Admin')"
                                                    content="Disabled - only Billing Admins can change this setting">
                                                    <el-select
                                                        class="width-300"
                                                        :disabled="!hasRole('Billing Admin')"
                                                        v-model="company_clone.call_recording_retention"
                                                        @change="updateCompanySettings">
                                                        <el-option
                                                            :value="CallRecordsRetentionDurations.CALL_RECORDS_RETENTION_DURATIONS_INDEFINITE"
                                                            label="Indefinite">
                                                        </el-option>
                                                        <el-option
                                                            :value="CallRecordsRetentionDurations.CALL_RECORDS_RETENTION_DURATIONS_FOLLOW_LINE"
                                                            label="Follow Line’s Setting">
                                                        </el-option>
                                                        <el-option
                                                            :value="CallRecordsRetentionDurations.CALL_RECORDS_RETENTION_DURATIONS_10_DAYS"
                                                            label="10 days">
                                                        </el-option>
                                                        <el-option
                                                            :value="CallRecordsRetentionDurations.CALL_RECORDS_RETENTION_DURATIONS_30_DAYS"
                                                            label="30 days">
                                                        </el-option>
                                                        <el-option
                                                            :value="CallRecordsRetentionDurations.CALL_RECORDS_RETENTION_DURATIONS_60_DAYS"
                                                            label="60 days">
                                                        </el-option>
                                                    </el-select>
                                                </el-tooltip>
                                            </el-form-item>
                                        </div>
                                        <div class="col-md-2 d-none d-md-block ml-auto">
                                            <div
                                                class="list-group list-group-alo list-group-flush sticky-top stick-top-with-header top-70">
                                                <h6 class="list-group-item">
                                                    On This Page
                                                </h6>
                                                <a href="#force_users_always_available"
                                                   class="list-group-item list-group-item-action"
                                                   @click="shine('force_users_always_available')">
                                                    Force Users to Always Available
                                                </a>
                                                <a href="#wrap_up_seconds"
                                                   class="list-group-item list-group-item-action"
                                                   @click="shine('wrap_up_seconds')">
                                                    Wrap Up Duration
                                                </a>
                                                <a href="#force_wrap_up"
                                                   class="list-group-item list-group-item-action"
                                                   @click="shine('force_wrap_up')">
                                                    Force Wrap Up Settings
                                                </a>
                                                <a href="#inbound_call_recording_mode"
                                                   class="list-group-item list-group-item-action"
                                                   @click="shine('inbound_call_recording_mode')">
                                                    Inbound Call Recordings
                                                </a>
                                                <a href="#outbound_call_recording_mode"
                                                   class="list-group-item list-group-item-action"
                                                   @click="shine('outbound_call_recording_mode')">
                                                    Outbound Call Recordings
                                                </a>
                                                <a href="#force_outbound_recording"
                                                   class="list-group-item list-group-item-action"
                                                   @click="shine('force_outbound_recording')">
                                                    Force Outbound Call Recording
                                                </a>
                                                <a href="#default_outbound_campaign_id"
                                                   class="list-group-item list-group-item-action"
                                                   @click="shine('default_outbound_campaign_id')">
                                                    Default Outbound Line
                                                </a>
                                                <a href="#wait_voice_enabled"
                                                   class="list-group-item list-group-item-action"
                                                   @click="shine('wait_voice_enabled')">
                                                    Wait Voice
                                                </a>
                                                <a href="#force_outbound_line"
                                                   class="list-group-item list-group-item-action"
                                                   @click="shine('force_outbound_line')">
                                                    Force Outbound Line Settings
                                                </a>
                                                <a href="#force_call_disposition"
                                                   class="list-group-item list-group-item-action"
                                                   @click="shine('force_call_disposition')">
                                                    Force Call Disposition
                                                </a>
                                                <a href="#force_contact_disposition"
                                                   class="list-group-item list-group-item-action"
                                                   @click="shine('force_contact_disposition')">
                                                    Force Contact Disposition
                                                </a>
                                                <a v-if="false" href="#show_call_missed_modal"
                                                   class="list-group-item list-group-item-action"
                                                   @click="shine('show_call_missed_modal')">
                                                    Missed-Call Status
                                                </a>
                                                <a href="#hold_music"
                                                   class="list-group-item list-group-item-action"
                                                   @click="shine('hold_music')">
                                                    Hold Music
                                                </a>
                                                <a href="#dual_channel_recording"
                                                   class="list-group-item list-group-item-action"
                                                   @click="shine('dual_channel_recording')">
                                                    Call Recording Channels
                                                </a>
                                                <a href="#call_masking"
                                                   class="list-group-item list-group-item-action"
                                                   @click="shine('call_masking')">
                                                    Call Masking
                                                </a>
                                                <a href="#notification_sound_of_fishing_mode"
                                                   class="list-group-item list-group-item-action"
                                                   @click="shine('notification_sound_of_fishing_mode')">
                                                    Notification Sound of Fishing Mode
                                                </a>
                                                <a href="#call_recording_retention"
                                                   class="list-group-item list-group-item-action"
                                                   @click="shine('call_recording_retention')">
                                                    Call records retention duration
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </el-form>
                            </el-tab-pane>
                            <el-tab-pane
                                v-if="talkEnabled"
                                label="Inbox Settings"
                                class="px-3 py-2"
                                name="inbox-filters">
                                <inbox-filter-index :company_clone="company_clone" />
                            </el-tab-pane>
                            <el-tab-pane label="PowerDialer Settings"
                                         class="px-3 py-2"
                                         name="power-dialer-settings"
                                         :disabled="!profile.auto_dialer_enabled">
                                <el-form :model="company_clone"
                                         :rules="rules.company"
                                         label-position="top"
                                         class="form-aloware"
                                         ref="companySettingsForm"
                                         @submit.prevent.native="submitForm('companySettingsForm')">
                                    <div class="row">
                                        <div class="col-md-9">
                                            <el-form-item prop="default_power_dialer_campaign_id"
                                                          class="no-border pb-2 width-500">
                                                <span class="page-anchor"
                                                      id="default_power_dialer_campaign_id">
                                                </span>
                                                <div class="form-label">
                                                    <h5>Default PowerDialer Line</h5>
                                                    <small>
                                                        Choose a line (Caller ID) for your PowerDialer calls.
                                                        You can also let the agents choose this setting on their
                                                        PowerDialer.
                                                    </small>
                                                </div>
                                                <campaign-selector
                                                    class_prop="no-select mb-3 w-full"
                                                    placeholder="Always ask"
                                                    :show_paused="false"
                                                    clearable
                                                    filterable
                                                    v-model="company_clone.default_power_dialer_campaign_id"
                                                    @change="updateDefaultPowerDialerCampaignId">
                                                </campaign-selector>
                                            </el-form-item>

                                            <el-form-item prop="disable_power_dialer_add"
                                                          class="no-border pb-2">
                                                <span class="page-anchor"
                                                      id="disable_power_dialer_add">
                                                </span>
                                                <div class="form-label">
                                                    <h5>Master Control</h5>
                                                    <small>
                                                        By selecting this option, only admins can add/clear tasks on the agents' PowerDialer.
                                                    </small>
                                                </div>
                                                <div class="switch-label">
                                                    <el-switch active-color="#00a344"
                                                               v-model="company_clone.disable_power_dialer_add"
                                                               @change="updateCompanySettings">
                                                    </el-switch>
                                                    <label>Enable PowerDialer master control</label>
                                                </div>
                                            </el-form-item>

                                            <el-form-item prop="pd_remove_manually_dialed_contact"
                                                          class="no-border pb-2">
                                                <span class="page-anchor"
                                                      id="pd_remove_manually_dialed_contact">
                                                </span>
                                                <div class="form-label">
                                                    <h5>Remove Manually Dialed Contacts</h5>
                                                    <small>
                                                        Automatically remove any contact from the PowerDialer list who is manually dialed anywhere in the platform to eliminate the chance for a double call.
                                                    </small>
                                                </div>
                                                <div class="switch-label">
                                                    <el-switch active-color="#00a344"
                                                               v-model="company_clone.pd_remove_manually_dialed_contact"
                                                               @change="updateCompanySettings">
                                                    </el-switch>
                                                    <label>Remove manually dialed contacts from PowerDialer</label>
                                                </div>
                                                <div class="switch-label">
                                                    <el-switch active-color="#00a344"
                                                               v-model="company_clone.pd_remove_include_called_from_sequence"
                                                               @change="updateCompanySettings">
                                                    </el-switch>
                                                    <label>Include contacts called from Sequence</label>
                                                </div>
                                            </el-form-item>

                                            <el-form-item class="no-border pb-2">
                                                <span class="page-anchor"
                                                      id="power_dialer_open_close_settings">
                                                </span>
                                                <div class="row width-500">
                                                    <div class="col">
                                                        <div class="form-label">
                                                            <h5>Open Time</h5>
                                                            <small>
                                                                Choose the open time
                                                            </small>
                                                        </div>
                                                        <el-time-select
                                                            v-model="company_clone.power_dialer_settings.open_time"
                                                            :picker-options="{
                                                              start: '06:00',
                                                              step: '00:15',
                                                              end: '24:00'
                                                            }"
                                                            class="w-full w-100"
                                                            placeholder="Select open time"
                                                            @change="updateCompanySettings">
                                                        </el-time-select>
                                                    </div>
                                                    <div class="col">
                                                        <div class="form-label">
                                                            <h5>Close Time</h5>
                                                            <small>
                                                                Choose the close time
                                                            </small>
                                                        </div>
                                                        <el-time-select
                                                            v-model="company_clone.power_dialer_settings.close_time"
                                                            :picker-options="{
                                                              start: '06:00',
                                                              step: '00:15',
                                                              end: '24:00'
                                                            }"
                                                            class="w-full w-100"
                                                            placeholder="Select close time"
                                                            @change="updateCompanySettings">
                                                        </el-time-select>
                                                    </div>
                                                </div>
                                            </el-form-item>

                                            <el-form-item class="no-border pb-2">
                                                    <span class="page-anchor"
                                                          id="power_dialer_triggers">
                                                    </span>
                                                <div class="form-label">
                                                    <h5>PowerDialer Triggers</h5>
                                                    <small>
                                                        Here you can define triggers for the PowerDialer calls
                                                    </small>
                                                </div>
                                                <div>
                                                    <triggers :triggers="company_clone.power_dialer_triggers"
                                                              :power_dialer_trigger="true"
                                                              @save="saveTriggers">
                                                    </triggers>
                                                </div>
                                            </el-form-item>

                                            <template v-if="talkEnabled">
                                                <el-divider></el-divider>
                                                <span class="page-anchor"
                                                      id="power_dialer_session_setting">
                                                </span>
                                                <power-dialer-session-setting></power-dialer-session-setting>
                                            </template>
                                        </div>
                                        <div class="col-md-2 d-none d-md-block ml-auto">
                                            <div class="list-group list-group-alo list-group-flush sticky-top stick-top-with-header top-70">
                                                <h6 class="list-group-item">
                                                    On This Page
                                                </h6>
                                                <a href="#default_power_dialer_campaign_id"
                                                   class="list-group-item list-group-item-action"
                                                   @click="shine('default_power_dialer_campaign_id')">
                                                    Default PowerDialer Line
                                                </a>
                                                <a href="#disable_power_dialer_add"
                                                   class="list-group-item list-group-item-action"
                                                   @click="shine('disable_power_dialer_add')">
                                                    PowerDialer Master Control
                                                </a>
                                                <a href="#pd_remove_manually_dialed_contact"
                                                   class="list-group-item list-group-item-action"
                                                   @click="shine('pd_remove_manually_dialed_contact')">
                                                    Remove Manually Dialed Contacts from PowerDialer
                                                </a>
                                                <a href="#power_dialer_open_close_settings"
                                                   class="list-group-item list-group-item-action"
                                                   @click="shine('power_dialer_open_close_settings')">
                                                    PowerDialer Open/Close Time
                                                </a>
                                                <a href="#power_dialer_triggers"
                                                   class="list-group-item list-group-item-action"
                                                   @click="shine('power_dialer_triggers')">
                                                    PowerDialer Triggers
                                                </a>
                                                <a href="#power_dialer_session_setting"
                                                   class="list-group-item list-group-item-action"
                                                   v-if="talkEnabled"
                                                   @click="shine('power_dialer_session_setting')">
                                                    PowerDialer Session Settings
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </el-form>
                            </el-tab-pane>
                            <el-tab-pane
                                label="Reporting Settings"
                                class="px-3 py-2"
                                name="reporting-settings">
                                <account-report-settings
                                    ref="account_report_settings"
                                    :company_clone="company_clone"
                                    @update-parent="updateParent">
                                </account-report-settings>
                            </el-tab-pane>
                            <el-tab-pane label="Contact Dispositions"
                                         name="contact_dispositions"
                                         v-if="company && hasPermissionTo('list disposition status')">
                                <account-contact-dispositions/>
                            </el-tab-pane>
                            <el-tab-pane label="Call Dispositions"
                                         name="call_dispositions"
                                         v-if="company && hasPermissionTo('list disposition status')">
                                <account-call-dispositions/>
                            </el-tab-pane>
                            <el-tab-pane label="Lead Sources"
                                         name="lead_sources"
                                         v-if="company && hasPermissionTo('list lead source')">
                                <lead-sources-list/>
                            </el-tab-pane>
                            <el-tab-pane label="Billing"
                                         name="billing"
                                         v-if="canSeeBilling">
                                <account-billing :company="company"></account-billing>
                            </el-tab-pane>
                            <el-tab-pane label="Usage"
                                         name="usage"
                                         v-if="!isSimpSocial || (isSimpSocial && hasRole('Billing Admin'))">
                                <company-usage v-if="load_usage"
                                               :company="current_company">
                                </company-usage>
                            </el-tab-pane>
                            <el-tab-pane label="International Pricing"
                                         name="international_pricing">
                                <account-international-pricing></account-international-pricing>
                            </el-tab-pane>
                            <el-tab-pane @click="fetchImports"
                                         label="Imports"
                                         name="imports">
                                <import-listing ref="importListing"></import-listing>
                            </el-tab-pane>
                            <el-tab-pane label="Exports"
                                         name="exports"
                                         :lazy="true">
                                <account-exports ref="exportListing"></account-exports>
                            </el-tab-pane>
                            <el-tab-pane label="Scripts"
                                         name="scripts">
                                <account-script/>
                            </el-tab-pane>
                            <el-tab-pane v-if="hasPermissionTo('list sms template')"
                                         label="SMS Templates"
                                         name="sms-templates">
                                <sms-template-list/>
                            </el-tab-pane>
                            <el-tab-pane label="Shortened URLs"
                                         name="shortened-urls"
                                         :lazy="true"
                                         v-if="current_company && !current_company.is_whitelabel">
                                <short-url-list @enabled="toggleUrlShortener" />
                            </el-tab-pane>
                            <el-tab-pane label="Custom Contact Fields"
                                         name="attribute-dictionary">
                                <attribute-dictionary-list/>
                            </el-tab-pane>
                            <el-tab-pane
                                label="Calendar Services"
                                name="calendar-services"
                                lazy>
                                <calendar-services-list/>
                            </el-tab-pane>
                            <el-tab-pane
                                v-if="isSimpSocial"
                                label="Calendar Settings"
                                name="calendar-settings"
                                lazy>
                                <account-calendar-settings/>
                            </el-tab-pane>
                        </el-tabs>
                    </div>
                </div>
            </div>
            <form-height-indicator bottom="20"/>
        </div>

        <el-backtop target="html"
                    ref="backtop"
                    :bottom="20"
                    :right="20">
        </el-backtop>
    </div>
</template>

<script>
import auth from '../auth'
import {mapActions, mapState, mapGetters} from 'vuex'
import {acl_mixin, compliance_mixin, form_handler, form_validation_mixin, paginator_mixin, scroll_mixin, tab_section_mixin, kyc_mixin, validator_mixin} from '../mixins'
import AccountScript from "../components/account-script"
import AccountCallDispositions from '../components/account-call-dispositions'
import AccountContactDispositions from '../components/account-contact-dispositions'
import ImportListing from "../components/import-wizard/import-listing"
import SimpsocialSettings from '../components/simpsocial/simpsocial-settings'
import SmsTemplateList from "../components/sms-templates/sms-template-list"
import VariableDialog from '../components/variable-dialog'
import FormHeightIndicator from "../components/form-height-indicator"
import AttributeDictionaryList from "../components/account/attribute-dictionary/attribute-dictionary-list";
import LeadSourcesList from '../components/account/lead-sources/lead-sources-list'
import AccountReportSettings from '../components/account/account-report-settings'
import AccountContactSettings from '../components/account/account-contact-settings'
import InboxFilterIndex from '../components/account/inbox-filter/inbox-filter-index'
import CompanyUsage from "../components/account/company-usage/company-usage"
import AccountCalendarSettings from "../components/account/account-calendar-settings"
import AccountTranscriptionSettings from '../components/account/account-transcription-settings'
import ShortUrlList from "../components/account/short-url-list"
import Triggers from '../components/triggers'
import ComplianceTab from '../components/account/compliance-tab'
import * as InboundCallRecordingModes from '../constants/inbound-call-recording-modes'
import * as OutboundCallRecordingModes from '../constants/outbound-call-recording-modes'
import * as CallRecordsRetentionDurations from '../constants/call-records-retention-durations'
import * as DefaultHomepage from '../constants/default-homepage'
import * as A2PRegistrationTypes from '../constants/a2p-registration-types'
import CalendarServicesList from "../components/account/calendar-services/calendar-services-list"
import VideoModal from '../components/video-modal.vue'
import RetentionDurationFeatureOverview from "./settings/retention-duration-feature-overview"
import RecordingMode from '../components/account/recording/recording-mode.vue'
import RecordingForce from '../components/account/recording/recording-force.vue'

export default {
    mixins: [
        acl_mixin,
        compliance_mixin,
        scroll_mixin,
        paginator_mixin,
        form_handler,
        tab_section_mixin,
        validator_mixin,
        form_validation_mixin,
        kyc_mixin
    ],

    components: {
        RetentionDurationFeatureOverview,
        CalendarServicesList,
        CompanyUsage,
        AttributeDictionaryList,
        FormHeightIndicator,
        SimpsocialSettings,
        AccountCallDispositions,
        AccountContactDispositions,
        AccountScript,
        ImportListing,
        VariableDialog,
        Triggers,
        SmsTemplateList,
        LeadSourcesList,
        AccountReportSettings,
        AccountContactSettings,
        InboxFilterIndex,
        AccountCalendarSettings,
        AccountTranscriptionSettings,
        ShortUrlList,
        ComplianceTab,
        VideoModal,
        RecordingMode,
        RecordingForce
    },

    data() {
        return {
            auth: auth,
            is_impersonated: localStorage.getItem('impersonate'),
            statics: {
                logo: null,
                logo_inverse: null,
                logo_square: null,
                logo_square_inverse: null,
                host: null,
                referer: null,
                name: null,
                domain: null,
                whitelabel: false,
                path: null
            },
            loading_whitelabel: true,
            profile: auth.user.profile,
            company: null,
            company_clone: {},
            loading_company: true,
            loading_delete: false,
            loading: false,
            loading_compliance: false,
            loading_address: false,
            showMoreComplianceDetails: false,
            timezone: {
                found: false,
                text: '',
                value: ''
            },
            homepages: [
                {
                    label: 'Dashboard',
                    value: DefaultHomepage.DEFAULT_HOMEPAGE_DASHBOARD,
                    src: '/assets/images/app-icons/icon-dashboard-grey.svg'
                },
                {
                    label: 'Contacts',
                    value: DefaultHomepage.DEFAULT_HOMEPAGE_CONTACTS,
                    src: '/assets/images/app-icons/icon-contacts-grey.svg'
                }
            ],
            rules: {
                company: {
                    name: [
                        {
                            required: true,
                            message: 'Please provide an account name',
                            trigger: 'blur'
                        }
                    ],
                },
            },
            wrap_up_seconds: [
                {
                    label: 'No Wrap up',
                    value: -1
                },
                {
                    label: '10 seconds',
                    value: 10
                },
                {
                    label: '15 seconds',
                    value: 15
                },
                {
                    label: '30 seconds',
                    value: 30
                },
                {
                    label: '45 seconds',
                    value: 45
                },
                {
                    label: '60 seconds',
                    value: 60
                },
                {
                    label: '120 seconds',
                    value: 120
                },
                {
                    label: '180 seconds',
                    value: 180
                },
                {
                    label: '300 seconds',
                    value: 300
                },
                {
                    label: 'Indefinitely',
                    value: 0
                }
            ],
            activeName: 'settings',
            loading_inbound_recording: false,
            loading_outbound_recording: false,
            headers: {
                Accept: 'application/json',
                Authorization: 'Bearer ' + auth.user.profile.api_token,
                'X-Socket-Id': window.Echo.socketId()
            },
            uploadPercentage: {
                record: 0,
                holdMusic: 0,
            },
            uploadStatus: {
                record: 'success',
                holdMusic: 'success',
            },
            uploadFileList: {
                holdMusic: [],
            },
            show_custom_hold_music: false,
            loading_hold_music: false,
            test_activity_report: {
                loading: false
            },
            countries: [],
            DefaultHomepage,
            InboundCallRecordingModes,
            inbound_recording_mode_options: {
                'Use Line Default': InboundCallRecordingModes.INBOUND_CALL_RECORDING_MODE_DEFAULT,
                'Always Record': InboundCallRecordingModes.INBOUND_CALL_RECORDING_MODE_ALWAYS,
                'Never Record': InboundCallRecordingModes.INBOUND_CALL_RECORDING_MODE_NEVER
            },
            OutboundCallRecordingModes,
            outbound_recording_mode_options: {
                'Use Line Default': OutboundCallRecordingModes.OUTBOUND_CALL_RECORDING_MODE_DEFAULT,
                'Always Record': OutboundCallRecordingModes.OUTBOUND_CALL_RECORDING_MODE_ALWAYS,
                'Never Record': OutboundCallRecordingModes.OUTBOUND_CALL_RECORDING_MODE_NEVER
            },
            CallRecordsRetentionDurations,
            A2PRegistrationTypes,
            broadcast_business_hours: {
                open_hour: {
                    start: '09:00:00',
                    end: '20:00:00',
                },
                close_hour: {
                    start: '10:00:00',
                    end: '21:00:00',
                }
            },
            broadcast_hours: null,
            load_usage: false,
            env: null,
        }
    },

    computed: {
        ...mapState(['users', 'campaigns', 'call_dispositions']),

        ...mapState('cache', ['current_company']),

        ...mapGetters('cache', [
            'isSimpSocial',
            'isTrial'
        ]),

        isComplianceTabSelected() {
            return this.$route.query.tab === 'compliance'
        },

        talk2Url() {
            return this.getTalkUrl()
        },

        recordNavClass() {
            return {
                inbound_read: {
                    'disabled': null,
                    'nav-link': true,
                    'active': !this.company_clone.inbound_record_file
                },
                inbound_play: {
                    'nav-link': true,
                    'active': !!this.company_clone.inbound_record_file
                },
                outbound_read: {
                    'disabled': null,
                    'nav-link': true,
                    'active': !this.company_clone.outbound_record_file
                },
                outbound_play: {
                    'nav-link': true,
                    'active': !!this.company_clone.outbound_record_file
                }
            }
        },

        recordContainerClass() {
            return {
                inbound_read: {
                    'tab-pane': true,
                    'animated': true,
                    'fadeIn': true,
                    'text-muted': true,
                    'active': !this.company_clone.inbound_record_file
                },
                inbound_play: {
                    'tab-pane': true,
                    'animated': true,
                    'fadeIn': true,
                    'text-muted': true,
                    'active': !!this.company_clone.inbound_record_file
                },
                outbound_read: {
                    'tab-pane': true,
                    'animated': true,
                    'fadeIn': true,
                    'text-muted': true,
                    'active': !this.company_clone.outbound_record_file
                },
                outbound_play: {
                    'tab-pane': true,
                    'animated': true,
                    'fadeIn': true,
                    'text-muted': true,
                    'active': !!this.company_clone.outbound_record_file
                },
            }
        },

        timezones() {
            if (this.current_company && this.current_company.country) {
                if (!['US'].includes(this.current_company.country)) {
                    return window.countriesAndTimezones.getTimezonesForCountry(this.current_company.country)
                        .map((timezone) => {
                            return {
                                name: timezone.name + ' GMT ' + timezone.utcOffsetStr,
                                value: timezone.name
                            }
                        })
                }

                if (['US'].includes(this.current_company.country)) {
                    return [
                        {
                            value: 'America/New_York',
                            name: 'New York (Eastern)',
                        },
                        {
                            value: 'America/Chicago',
                            name: 'Chicago (Central Standard Time)',
                        },
                        {
                            value: 'America/Denver',
                            name: 'Denver (Mountain Daylight Time)',
                        },
                        {
                            value: 'America/Phoenix',
                            name: 'Phoenix (Mountain Standard Time)',
                        },
                        {
                            value: 'America/Los_Angeles',
                            name: 'Los Angeles (Pacific Standard Time)',
                        },
                        {
                            value: 'Pacific/Honolulu',
                            name: 'Honolulu (Hawaii Standard Time)',
                        },
                    ]
                }
            }

            return []
        },

        companyTimezone() {
            return moment().tz(this.current_company.timezone).format('z')
        },

        canSeeBilling() {
            return this.current_company &&
                this.hasRole('Billing Admin') &&
                !this.isSimpSocial
        }
    },

    created() {
        this.getStatics()
        this.getCountries()
        this.getAppEnv()

        if (!this.$route.query.tab) {
            this.$router.push({name: 'Account', query: {tab: this.activeName}}).catch(err => {
            })
        }

        // load company-usage component
        if (this.$route.query.tab === 'usage') {
            this.load_usage = true
        }
    },

    mounted() {
        if (this.hasPermissionTo('update company')) {
            this.getCompany()

            if (this.$route.query.tab) {
                this.activeName = this.$route.query.tab
            }
        } else {
            this.goBack()
        }

        if (this.$refs.tabs) {
            this.$refs.tabs.$forceUpdate()
        }
    },

    methods: {
        getAppEnv() {
            // get environment
            axios.get('/build-info.json').then(res => {
                this.env = res.data.env
            })
        },

        updateParent(fields) {
            this.company_clone = {...this.company_clone, ...fields}

            this.updateCompanySettings()
        },

        getCountries() {
            const countries = window.countriesAndTimezones.getAllCountries()
            const countriesArr = Object.keys(countries).map((key) => {
                return countries[key]
            })

            let mappedCountries = countriesArr.map((country) => {
                if (!['US', 'CA'].includes(country.id)) {
                    return {
                        id: country.id,
                        name: country.name
                    }
                }
            }).filter((country) => country !== undefined)

            mappedCountries.unshift({
                id: 'CA',
                name: 'Canada'
            })

            mappedCountries.unshift({
                id: 'US',
                name: 'United States'
            })

            this.countries = mappedCountries
        },

        applyUploadedAudio(data) {
            setTimeout(() => {
                this.$notify({
                    offset: 95,
                    title: 'Account',
                    message: 'File uploaded successfully',
                    type: 'success',
                    showClose: true,
                })

                switch (data.upload_type) {
                    case 'inbound_record_file':
                        this.company_clone.inbound_record_file = data.uid
                        this.resetInboundRecording()
                        this.updateInboundCallRecording()
                        break
                    case 'outbound_record_file':
                        this.company_clone.outbound_record_file = data.uid
                        this.resetOutboundRecording()
                        this.updateOutboundCallRecording()
                        break
                    case 'hold_music_file':
                        this.company_clone.hold_music_file = data.uid
                        this.resetHoldMusic()
                        this.updateCompanySettings()
                        break
                }
            }, 200)
        },

        onSuccessUploadInboundRecording(res) {
            this.$notify({
                offset: 95,
                title: 'Account',
                message: 'File uploaded successfully',
                type: 'success',
                showClose: true,
            })
            this.company_clone.inbound_record_file = res.file_name
            this.resetInboundRecording()
            this.updateInboundCallRecording()
        },

        onSuccessUploadOutboundRecording(res) {
            this.$notify({
                offset: 95,
                title: 'Account',
                message: 'File uploaded successfully',
                type: 'success',
                showClose: true,
            })
            this.company_clone.outbound_record_file = res.file_name
            this.resetOutboundRecording()
            this.updateOutboundCallRecording()
        },

        onFailedUploadRecording(err) {
            this.handleUploadErrors(err.message)
            this.uploadStatus.record = 'exception'
            this.uploadPercentage.record = 0
            this.preValidateForm('companySettingsForm')
        },

        beforeUploadInboundRecording() {
            this.resetInboundRecording()
        },

        beforeUploadOutboundRecording() {
            this.resetOutboundRecording()
        },

        progressUploadRecording(event) {
            this.uploadPercentage.record = parseInt(event.percent)
        },

        deleteFile(type) {
            this.$confirm('Your recorded file will be lost forever. Are you sure you want to remove it?', 'Warning', {
                confirmButtonText: 'Yes, Remove',
                cancelButtonText: 'No, Don\'t remove it',
                customClass: 'width-500 fixed',
                type: 'warning'
            }).then(res => {
                switch (type) {
                    case 'inbound_recording_notices':
                        this.loading_inbound_recording = true
                        break
                    case 'outbound_recording_notices':
                        this.loading_outbound_recording = true
                        break
                    case 'hold_musics':
                        this.loading_hold_music = true
                        break
                }

                axios.delete('/api/v1/company/' + this.company_clone.id + '/delete/' + type).then(res => {
                    switch (type) {
                        case 'inbound_recording_notices':
                            this.company_clone.inbound_record_file = null
                            this.resetInboundRecording()
                            break
                        case 'outbound_recording_notices':
                            this.company_clone.outbound_record_file = null
                            this.resetOutboundRecording()
                            break
                        case 'hold_musics':
                            this.company_clone.hold_music_file = null
                            this.show_custom_hold_music = false
                            this.resetHoldMusic()
                            break
                    }
                    this.$notify({
                        offset: 95,
                        title: 'Account',
                        message: 'File deleted successfully',
                        type: 'success',
                        showClose: true,
                    })
                }).catch(err => {
                    this.$root.handleErrors(err.response)
                    switch (type) {
                        case 'inbound_recording_notices':
                            this.resetInboundRecording()
                            break
                        case 'outbound_recording_notices':
                            this.resetOutboundRecording()
                            break
                        case 'hold_musics':
                            this.resetHoldMusic()
                            break
                    }
                })
            }).catch(() => {

            })
        },

        resetInboundRecording() {
            this.loading_inbound_recording = false
            this.uploadPercentage.record = 0
            this.uploadStatus.record = 'success'
        },

        resetOutboundRecording() {
            this.loading_outbound_recording = false
            this.uploadPercentage.record = 0
            this.uploadStatus.record = 'success'
        },

        resetHoldMusic() {
            this.loading_hold_music = false
            this.uploadPercentage.holdMusic = 0
            this.uploadStatus.holdMusic = 'success'
        },

        handleUploadErrors(error) {
            if (typeof error === 'string') {
                error = JSON.parse(error)
            }
            let err
            if (error.message === 'This action is unauthorized.') {
                err = {
                    status: 403,
                }
            } else {
                err = {
                    status: 422,
                    data: {
                        errors: error.errors.file
                    }
                }
            }

            this.$root.handleErrors(err)
        },

        getStatics() {
            this.loading_whitelabel = true
            axios.get('/get-statics')
                .then(res => {
                    this.statics = res.data
                    this.setPageTitle('Account - ' + this.statics.name)
                    this.loading_whitelabel = false
                })
                .catch(err => {
                    this.setPageTitle('Account - Aloware')
                    console.log(err)
                    this.$root.handleErrors(err.response)
                    this.loading_whitelabel = false
                })
        },

        setupCompany() {
            this.company_clone = Object.assign({}, this.company)

            // update the properties in account report settings
            if (this.$refs.account_report_settings) {
                this.$refs.account_report_settings.initializeProperties()
            }

            if (!this.company_clone.appointment_adp_recipients) {
                this.company_clone.appointment_adp_recipients = []
            }
            if (typeof this.company_clone.appointment_adp_recipients === 'string') {
                this.company_clone.appointment_adp_recipients = JSON.parse(this.company_clone.appointment_adp_recipients)
            }

            if (!this.company_clone.lead_recipients) {
                this.company_clone.lead_recipients = []
            }
            if (typeof this.company_clone.lead_recipients === 'string') {
                this.company_clone.lead_recipients = JSON.parse(this.company_clone.lead_recipients)
            }

            if (!this.company_clone.inbound_record_tts) {
                this.company_clone.inbound_record_tts = 'Your call may be recorded for quality assurance purposes.'
            }
            if (!this.company_clone.outbound_record_tts) {
                this.company_clone.outbound_record_tts = 'This call is being recorded.'
            }
            this.show_custom_hold_music = this.company_clone.hold_music_file != null
            if (typeof this.company_clone.power_dialer_triggers === 'string') {
                this.company_clone.power_dialer_triggers = JSON.parse(this.company_clone.power_dialer_triggers)
            } else {
                this.company_clone.power_dialer_triggers = []
            }
        },

        getCompany() {
            this.loading_company = true
            axios.get('/api/v1/company/' + this.current_company.id, {
                mode: 'no-cors',
            }).then(res => {
                this.company = res.data
                this.setupCompany()
                if (window.timezone != "") {
                    this.timezone.value = window.timezone
                    this.timezone.text = window.timezone.split("/").slice(-1)[0].replace("_", " ")
                    let timezones = ['New York', 'Chicago', 'Denver', 'Phoenix', 'Los Angeles', 'Honolulu']
                    if (timezones.indexOf(this.timezone.text) === -1) {
                        this.timezone.found = true
                    }
                }
                this.loading_company = false
                setTimeout(() => {
                    this.preValidateForm('companySettingsForm')
                }, 100)
            }).catch(err => {
                this.$root.handleErrors(err.response)
                this.loading_company = false
            })
        },

        submitForm(formName) {
            this.loading = true
            if (!this.$refs[formName]) {
                return false
            }
            this.preValidateForm(formName)
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    let params = _.pick(this.company_clone, [
                        'name',
                        'default_outbound_campaign_id',
                        'timezone',
                        'wrap_up_seconds',
                        'dual_channel_recording',
                        'daily_activity_reports_enabled',
                        'send_daily_activity_reports_on',
                        'send_daily_activity_reports_for',
                        'daily_sms_report',
                        'daily_outbound_sms_report',
                        'daily_spend_report',
                        'daily_dnc_report_enabled',
                        'inbound_call_recording_mode',
                        'outbound_call_recording_mode',
                        'force_call_disposition',
                        'force_contact_disposition',
                        'show_call_missed_modal',
                        'default_report_period',
                        'appointment_adp_recipients',
                        'inbound_record_tts',
                        'outbound_record_tts',
                        'default_homepage',
                        'force_wrap_up',
                        'force_outbound_line',
                        'force_outbound_recording',
                        'default_contact_date_filter',
                        'lead_recipients',
                        'hold_music_file',
                        'sms_reminder_enabled',
                        'sms_reminder_default_text',
                        'sms_reminder_default_campaign_id',
                        'sms_reminder_use_personal_line',
                        'sms_reminder_default_time',
                        'sms_reminder_default_send_before_days',
                        'disable_power_dialer_add',
                        'power_dialer_settings',
                        'power_dialer_triggers',
                        'default_power_dialer_campaign_id',
                        'caller_id_spoofing',
                        'fishing_mode_notification_sound',
                        'private_inbox_enabled',
                        'send_long_messages_as_mms',
                        'broadcast_open',
                        'broadcast_close',
                        'force_users_always_available',
                        'archive_communications_from_blocked_contacts',
                        'returning_lead_as_new_lead',
                        'mark_all_as_read_enabled',
                        'enforce_tcpa',
                        'automatically_tag_imported_contacts',
                        'automatically_list_imported_contacts',
                        'pd_remove_manually_dialed_contact',
                        'pd_remove_include_called_from_sequence',
                        'url_shortener_enabled',
                        'call_transcription_enabled',
                        'overusage_restriction_enabled',
                        'minimum_talk_time',
                        'voicemail_transcription_enabled',
                        'custom_dictionary',
                        'custom_keywords',
                        'automatic_redaction_enabled',
                        'content_moderation_enabled',
                        'send_long_messages_as_mms_toggle',
                        'sequence_allow_reenrollment',
                        'task_status_logs',
                        'send_daily_activity_reports_with_user_extensions',
                        'send_daily_activity_reports_hiding_users_without_activity',
                        'undnc_readded_contacts',
                        'wait_voice_enabled',
                        'auto_assign_contacts_enabled',
                        'default_contact_owner',
                        'call_recording_retention',
                        'summarization_enabled',
                        'qualified_calls_threshold1',
                        'qualified_calls_threshold2',
                    ])

                    axios.patch('/api/v1/company/' + this.company_clone.id, params).then(res => {
                        this.company = res.data
                        this.setupCompany()
                        this.setCurrentCompany(this.company)
                        this.loading = false
                        this.$notify({
                            offset: 95,
                            title: 'Account',
                            message: 'Account has been updated successfully.',
                            type: 'success',
                            showClose: true,
                        })
                    }).catch(err => {
                        this.$root.handleErrors(err.response)
                        if (err.response && err.response.data && err.response.data.company) {
                            this.company = err.response.data.company
                            this.setupCompany()
                            this.setCurrentCompany(this.company)
                        }
                        this.loading = false
                    })
                } else {
                    this.loading = false
                    return false
                }
            })
        },

        reset: function () {
            this.company = null
        },

        handleClick(tab, event) {
            this.$router.push({name: 'Account', query: {tab: tab.name}}).catch(err => {
            })

            this.fetchImports()

            // only load the company-usage component once
            if (tab.name === 'usage' && !this.load_usage) {
                this.load_usage = true
            }
        },

        updateInboundCallRecording() {
            this.preValidateForm('companySettingsForm')

            if (!this.company_clone.inbound_record_tts) {
                this.company_clone.inbound_record_tts = 'Your call may be recorded for quality assurance purposes.'
            }

                this.updateCompanySettings()
        },

        updateOutboundCallRecording() {
            this.preValidateForm('companySettingsForm')

            if (!this.company_clone.outbound_record_tts) {
                this.company_clone.outbound_record_tts = 'This call is being recorded.'
            }

            this.updateCompanySettings()
        },

        updateDefaultOutboundCampaignId(value) {
            this.company_clone.default_outbound_campaign_id = value
            this.preValidateForm('companySettingsForm')
            this.updateCompanySettings()
        },

        updateDefaultPowerDialerCampaignId(value) {
            this.company_clone.default_power_dialer_campaign_id = value
            this.preValidateForm('companySettingsForm')
            this.updateCompanySettings()
        },

        updateCompanySettings: _.debounce(function () {
            if (this.hasPermissionTo('update company')) {
                this.submitForm('companySettingsForm')
            }
        }, 2000),

        changeHomepage() {
            this.updateCompanySettings()
        },

        fetchImports: function () {
            this.$refs.importListing.fetchImports()
        },

        onSuccessUploadHoldMusic(res) {
            this.$notify({
                offset: 95,
                title: 'Account',
                message: this.company_clone.hold_music_file,
                type: 'success',
                showClose: true,
            })
            this.$set(this.company_clone, 'hold_music_file', res.file_name)
            this.resetHoldMusic()
            this.preValidateForm('companySettingsForm')
        },

        onFailedUploadHoldMusic(err) {
            this.handleUploadErrors(err.message)
            this.uploadStatus.holdMusic = 'exception'
            this.uploadPercentage.holdMusic = 0
            this.preValidateForm('companySettingsForm')
        },

        beforeUploadHoldMusic() {
            this.resetHoldMusic()
        },

        progressUploadHoldMusic(event) {
            this.uploadPercentage.holdMusic = parseInt(event.percent)
        },

        checkHoldMusicFile() {
            if (!this.show_custom_hold_music) {
                this.company_clone.hold_music_file = null
                this.updateCompanySettings()
            }
        },

        saveTriggers() {
            this.company_clone.power_dialer_triggers = this.company_clone.power_dialer_triggers.filter(trigger => trigger.valid === true)
            this.updateCompanySettings()
        },

        getOpenHourAdjustedTime() {
            // compose a valid date from the open hour time value
            const date = (new Date()).toLocaleDateString() + ' ' + this.company_clone.broadcast_open + ':00'

            // add +1 hour starting point for close hour
            return moment(date)
                .add(1, 'hour')
                .set({minute: 0, second: 0})
                .format('HH:mm:ss')
        },

        updateBroadcastHours(time_range) {
            this.company_clone.broadcast_open = time_range.start
            this.company_clone.broadcast_close = time_range.end

            this.updateCompanySettings()
        },

        toggleUrlShortener(value) {
            this.company_clone.url_shortener_enabled = value
            this.setUrlShortenerEnabled(value)
            this.updateCompanySettings()
        },

        ...mapActions(['resetVuex']),
        ...mapActions('cache', ['setCurrentCompany', 'setUrlShortenerEnabled'])
    },

    watch: {
        $route({params, query}) {
            if (query.tab) {
                this.activeName = query.tab
            }
        }
    },

    beforeRouteEnter(to, from, next) {
        auth.check()
            .then((res) => {
                let is_impersonated = localStorage.getItem('impersonate')
                if (res.data.user.is_reseller) {
                    // redirect to account management portal if the company is a reseller
                    next({name: 'Account Management Portal'})
                } else if (
                    res.data.user.created_by_reseller
                    && (!is_impersonated && !res.data.user.access_user_id)
                    && res.data.user.reseller_id == 357
                ) {
                    // don't let the resold companies see account page
                    next({name: 'Dashboard'})
                } else {
                    next()
                }
            })
            .catch((err) => {
                next({name: 'Login', query: {redirect: to.fullPath}})
            })
    }
}
</script>
