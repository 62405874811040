<template>
    <div class="col-12 h-100 mh-100">
        <div class="sidebar-wrapper px-2 py-3 h-100">
            <div class="body pb-4">
                <div v-if="panel === GENERAL_PANEL">
                    <GeneralPanel />
                </div>
                <div v-if="panel === START_PANEL">
                    <StartPanel
                        :transitions="state.transitions"
                        @transition-changed="selected_transition = $event"/>
                </div>
                <div v-if="panel === MESSAGE_PANEL">
                    <MessagePanel
                        :data="data"
                        :node_id="node_id"
                        @data-updated="dataUpdated"/>
                </div>
                <div v-if="panel === GET_INPUT_PANEL">
                    <GetInputPanel
                        :data="data"
                        :node_id="node_id"
                        :intents="intents"
                        @data-updated="dataUpdated"/>
                </div>
                <div v-if="panel === STORE_INPUT_PANEL">
                    <StoreInputPanel
                        :data="data"
                        :node_id="node_id"
                        @data-updated="dataUpdated"/>
                </div>
                <div v-if="panel === ESCALATION_PANEL">
                    <EscalationPanel
                        :data="data"
                        :node_id="node_id"
                        @data-updated="dataUpdated" />
                </div>
                <div v-if="panel === CUSTOM_PANEL">
                    <CustomPanel
                        :data="data"
                        :node_id="node_id"
                        @data-updated="dataUpdated" />
                </div>
                <div v-if="panel === APPOINTMENT_PANEL">
                    <AppointmentPanel
                        :data="data"
                        :node_id="node_id"
                        :appointment_services="appointment_services"
                        @data-updated="dataUpdated"/>
                </div>
                <div v-if="panel === CONDITION_PANEL">
                    <ConditionPanel
                        :data="data"
                        :node_id="node_id"
                        @data-updated="dataUpdated"/>
                </div>
            </div>
            <div class="container-fluid mt-2">
                <div class="row">
                    <el-button
                        type="success"
                        class="ml-auto"
                        @click="saveState()"
                        :loading="saving"
                        v-if="node_id">
                        Close
                    </el-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import GeneralPanel from './panels/general-panel.vue'
import StartPanel from './panels/start-panel.vue'
import MessagePanel from './panels/message-panel.vue'
import GetInputPanel from './panels/get-input-panel.vue'
import StoreInputPanel from './panels/store-input-panel.vue'
import EscalationPanel from './panels/escalation-panel.vue'
import CustomPanel from './panels/custom-panel.vue'
import AppointmentPanel from './panels/appointment-panel.vue'
import ConditionPanel from './panels/condition-panel.vue'

const GENERAL_PANEL = 'general_panel'
const START_PANEL = 'start_panel'
const MESSAGE_PANEL = 'message_panel'
const GET_INPUT_PANEL = 'get_input_panel'
const STORE_INPUT_PANEL = 'store_input_panel'
const ESCALATION_PANEL = 'escalation_panel'
const CUSTOM_PANEL = 'custom_panel'
const APPOINTMENT_PANEL = 'appointment_panel'
const CONDITION_PANEL = 'condition_panel'

export default {
    name: 'side-panel',
    components: {
        GeneralPanel,
        StartPanel,
        MessagePanel,
        GetInputPanel,
        StoreInputPanel,
        EscalationPanel,
        CustomPanel,
        AppointmentPanel,
        ConditionPanel
    },
    props: {
        chatbot: {
            required: true
        },
        intents: {
            required: false,
            default: []
        },
        node_id: {
            default: null,
            required: false
        },
        editor: {
            default: null,
            required: false
        },
        states: {
            default: [],
            required: false
        },
        state: {
            default: {
                transitions: []
            },
            required: false
        },
        panel: {
            default: GENERAL_PANEL,
            required: false
        },
        selected_transition: {
            required: false,
            default: null
        },
        appointment_services: {
            required: false,
            default: []
        }
    },
    data() {
        return {
            GENERAL_PANEL: GENERAL_PANEL,
            START_PANEL: START_PANEL,
            MESSAGE_PANEL: MESSAGE_PANEL,
            GET_INPUT_PANEL: GET_INPUT_PANEL,
            STORE_INPUT_PANEL: STORE_INPUT_PANEL,
            ESCALATION_PANEL: ESCALATION_PANEL,
            CUSTOM_PANEL: CUSTOM_PANEL,
            APPOINTMENT_PANEL: APPOINTMENT_PANEL,
            CONDITION_PANEL: CONDITION_PANEL,
            saving: false,
            temporaryData: {},
            bot_id: this.$route.params.bot_id,
            is_build_notified: false
        }
    },
    methods: {
        /**
         * Creates a promise that resolves after a specified time.
         *
         * @param {number} ms - The time in milliseconds to wait before resolving the promise.
         * @return {Promise} A promise that resolves after the specified time.
         */
        sleep(ms) {
            return new Promise(resolve => setTimeout(resolve, ms))
        },

        /**
         * Emits a 'node-panel-closed' event with the updated data for the node.
         *
         * @return {void}
         */
        saveState() {
            this.$emit('node-panel-closed', {
                id: this.node_id,
                data: JSON.parse(JSON.stringify(this.temporaryData))
            })
        },

        /**
         * Emits an 'export-data' event.
         *
         * @return {void}
         */
        exportData() {
            this.$emit('export-data')
        },

        /**
         * Updates the temporary data with the latest data. This is done by creating a
         * shallow copy of the data object using Object.assign, which ensures that
         * changes made to the temporaryData object do not affect the original data.
         *
         * @param {object} data - The updated data.
         * @return {void}
         */
        dataUpdated(data) {
            this.temporaryData = Object.assign({}, data)
        },
    },

    computed: {
        /**
         * Retrieves the message from the state associated with the current node ID.
         *
         * @return {string|undefined} The message or undefined if not found.
         */
        message() {
            return this.states.filter(item => item.id === Number.parseInt(this.node_id))[0]?.properties?.message
        },

        /**
         * Retrieves the data associated with the current node ID.
         *
         * @return {object} The data object of the current node.
         */
        data() {
            return this.$df.getNodeFromId(this.node_id).data
        }
    }
}
</script>

<style scoped>
.rotation-animation {
    animation: div-rotate 0.7s infinite steps(8);
    transform: translateZ(0);
    transform-origin: 50% 50%;
}
</style>
