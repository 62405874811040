var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "d-flex flex-wrap wrap-auto-margin" }, [
    _c(
      "div",
      { staticClass: "el-badge" },
      [
        _c(
          "el-button",
          {
            staticClass: "p-x-md",
            class: [_vm.filter.is_new_lead ? "greenish" : ""],
            attrs: {
              plain: "",
              loading: _vm.newLeadsCount == null,
              "data-testid": "new-leads-button",
            },
            on: { click: _vm.toggleNewLeadsFilter },
          },
          [
            _vm._v("\n            New Leads\n            "),
            _c(
              "span",
              {
                staticClass: "badge badge-danger text-sm",
                attrs: { size: "tiny" },
              },
              [
                _c("strong", [
                  _vm._v(_vm._s(_vm._f("numFormat")(_vm.newLeadsCount))),
                ]),
              ]
            ),
          ]
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "el-badge" },
      [
        _c(
          "el-button-group",
          [
            _c(
              "el-button",
              {
                attrs: {
                  type: _vm.filter.has_unread ? "success" : "default",
                  loading: _vm.unreadsCount == null,
                  "data-testid": "has-unread-communications-button",
                },
                on: { click: _vm.toggleUnansweredLeadsFilter },
              },
              [
                _vm._v(
                  "\n                Has Unread Communications\n                "
                ),
                _c(
                  "span",
                  {
                    staticClass: "badge badge-danger text-sm",
                    class: [_vm.unreadsCount > 0 ? "flashy-counts" : ""],
                  },
                  [
                    _c("strong", [
                      _vm._v(_vm._s(_vm._f("numFormat")(_vm.unreadsCount))),
                    ]),
                  ]
                ),
              ]
            ),
            _vm._v(" "),
            _vm.unreadsCount > 0 &&
            _vm.hasMarkAllAsReadAccess &&
            _vm.current_company &&
            _vm.current_company.mark_all_as_read_enabled
              ? _c(
                  "el-dropdown",
                  {
                    attrs: { trigger: "click" },
                    on: { command: _vm.markAllAsRead },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "dropdown",
                          fn: function () {
                            return [
                              _c(
                                "el-dropdown-menu",
                                [
                                  _c(
                                    "el-dropdown-item",
                                    {
                                      attrs: {
                                        command: "user",
                                        "data-testid":
                                          "mark-my-contacts-as-read-item",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                            Mark my contacts as read\n                        "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      false,
                      3876507375
                    ),
                  },
                  [
                    _c(
                      "el-button",
                      {
                        staticClass: "px-2 btn-contact-shortcut",
                        attrs: { id: "btn-mark-unanswered-read" },
                      },
                      [_c("i", { staticClass: "fa fa-caret-down" })]
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ],
          1
        ),
      ],
      1
    ),
    _vm._v(" "),
    _vm.current_company &&
    (!_vm.current_company.reseller_id ||
      _vm.current_company.reseller_id !== 357)
      ? _c(
          "div",
          { staticClass: "el-badge" },
          [
            _c(
              "el-button",
              {
                staticClass: "p-x-md btn-contact-shortcut",
                class: [_vm.filter.should_follow_the_sun ? "greenish" : ""],
                attrs: { plain: "", "data-testid": "follow-the-sun-button" },
                on: { click: _vm.toggleShouldFollowTheSun },
              },
              [_vm._v("\n            Follow The Sun\n        ")]
            ),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }