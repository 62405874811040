<template>
    <div class="container-fluid w-v h-v mellow-blue"
         v-show="!loading_whitelabel">
        <div class="row full-height">
            <div class="col-lg-7 d-none d-sm-none d-lg-flex justify-content-center align-items-center blue-purple-gradient pattern text-white text-center"
                 v-if="!statics.whitelabel">
                <div class="row"
                     style="max-width:550px">
                    <div class="col-12">
                        <h1 class="mb-4 banner-font">
                            Our AI-powered <br> call transcription feature <br> is now available
                        </h1>
                        <p class="mb-4 text-lg banner-font">
                            <br>
                            Get up to 5000 minutes for free
                        </p>
                        <div>
                            <a class="btn btn-primary btn-xl px-5"
                               target="_blank"
                               href="mailto:support@aloware.com?subject=Enable transcription for free">
                                Request access
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-5 d-flex justify-content-center align-items-center text-color"
                 :class="[ statics.whitelabel ? 'offset-lg-3' : '' ]"
                 v-if="!loading_whitelabel">
                <new-login :statics="statics"></new-login>
            </div>
        </div>
    </div>
</template>

<script>
import auth from '../auth'
import {mapActions} from 'vuex'
import {Loading, Notification} from 'element-ui'
import {login_mixin} from '../mixins'
import * as DefaultHomepage from '../constants/default-homepage'
import * as AppTypes from "../constants/aloware-app-types"

export default {
    mixins: [login_mixin],

    data() {
        return {
            email_verified: false,
            statics: {
                logo: null,
                logo_inverse: null,
                logo_square: null,
                logo_square_inverse: null,
                host: null,
                referer: null,
                name: null,
                domain: null,
                whitelabel: false,
                path: null
            },
            loading_whitelabel: true,
            register_status: false,
            loading: false,
            DefaultHomepage
        }
    },

    created() {
        this.register_status = localStorage.getItem('register_status')
        this.getStatics()
    },

    mounted() {
        this.init()

        const emailVerified = this.$route.query.email_verified;

        if (emailVerified === '1') {
            this.$notify.info({
                offset: 95,
                title: 'Email Verified',
                dangerouslyUseHTMLString: true,
                message: `Your email is already verified`,
                duration: 3000,
                onClick: () => {
                    this.$router.push({name: 'Account', query: {tab: 'exports'}}).catch((err) => {
                    })
                    Notification.closeAll()
                }
            })
        }
    },

    methods: {
        getStatics() {
            this.loading_whitelabel = true
            axios.get('/get-statics')
                .then(res => {
                    this.statics = res.data
                    this.setPageTitle('Sign In - ' + this.statics.name)
                    this.loading_whitelabel = false
                })
                .catch(err => {
                    this.setPageTitle('Sign In - Aloware')
                    this.$root.handleErrors(err.response)
                    this.loading_whitelabel = false
                })
        },

        init() {
            if (this.$route.query.api_token && (!localStorage.getItem('api_token') || localStorage.getItem('api_token') != this.$route.query.api_token)) {
                document.body.className = 'd-none'
                this.loading = true
                let loadingInstance = Loading.service({
                    lock: true,
                    customClass: 'blue-purple-gradient',
                    fullscreen: true
                })
                localStorage.setItem('api_token', this.$route.query.api_token)
                auth.check().then((res) => {
                    localStorage.setItem('company_id', res.data.user.company.id)
                    this.setCurrentCompany(res.data.user.company)
                    this.resetVuex()

                    if (res.data.user.company.default_homepage === DefaultHomepage.DEFAULT_HOMEPAGE_CONTACTS) {
                        if (!res.data.user.company.force_talk && !res.data.user.user_roles.includes('Company Admin')) {
                            window.location.href = this.$route.query.redirect || '/contacts'
                        } else {
                            window.location.href = this.$route.query.redirect || '/dashboard'
                        }
                    } else {
                        window.location.href = this.$route.query.redirect || '/dashboard'
                    }
                }).catch((err) => {
                    console.error('Error: api key is not valid', err)
                    loadingInstance.close()
                    this.loading = false
                })
            }
        },

        ...mapActions(['resetVuex']),
        ...mapActions('cache', ['setCurrentCompany'])
    },

    beforeRouteEnter(to, from, next) {
        auth.check().then((res) => {
            const urlParams = new URLSearchParams(window.location.search)
            const from_talk_2 = urlParams.get('from_talk_2')
            const talk_enabled = res.data.user.company.talk_enabled
            const roles = res.data.user.user_roles
            const is_admin = roles.some(role => ['Billing Admin', 'Company Admin'].includes(role))

            // alo talk enabled in company settings?
            if (talk_enabled) {
                // if the agent's selected default app is alo talk and alo talk is enabled then redirect to AloTalk
                if (res.data.user.default_app === AppTypes.APP_ALOWARE_TALK && !is_admin && Number(from_talk_2) !== 1) {
                    const is_impersonating = localStorage.getItem('impersonate') == 'true'
                    window.location.href = localStorage.getItem('talk_url') + (is_impersonating ? '?impersonating=1' : '')

                    return
                } else {
                    // if default app is not Alo Talk then let's introduce Aloware Talk
                    localStorage.setItem(`show_default_app_selection_${res.data.user.company.id}_${res.data.user.id}`, true)
                    localStorage.setItem(`show_default_app_selection_${res.data.user.company.id}`, true)
                }
            }

            next(vm => {
                vm.verifyRedirect(res, next)
            })

        }).catch((err) => {
            next()
        })
    }
}
</script>
