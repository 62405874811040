var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "mentionableContainer",
      staticClass: "position-relative",
      on: { click: _vm.handleMentionClick },
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "click-outside",
              rawName: "v-click-outside",
              value: _vm.handleSuggestionsClickOutside,
              expression: "handleSuggestionsClickOutside",
            },
          ],
        },
        [
          _c(
            "ul",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isMentioning,
                  expression: "isMentioning",
                },
              ],
              ref: "mentionableListContainer",
              staticClass:
                "mentionable-list shadow bg-white position-absolute w-100 mb-0 list-unstyled",
              style: { bottom: `${_vm.offsetY}px`, left: `${_vm.offsetX}px` },
            },
            _vm._l(_vm.mutableItems, function (mutableItem, i) {
              return _c(
                "li",
                {
                  key: i,
                  ref: "mentionableListItem",
                  refInFor: true,
                  staticClass: "mentionable-list-item p-1",
                  class: i == 0 ? "selected" : "",
                  on: {
                    click: function ($event) {
                      return _vm.handleClick($event, mutableItem)
                    },
                    mouseenter: function ($event) {
                      return _vm.handleMouseEnter(i)
                    },
                    mouseleave: function ($event) {
                      return _vm.handleMouseLeave(i)
                    },
                  },
                },
                [
                  _vm._t(
                    "item",
                    function () {
                      return [
                        _vm._v(
                          "\n                    " +
                            _vm._s(
                              _vm.searchKey
                                ? mutableItem[_vm.searchKey]
                                : mutableItem
                            ) +
                            "\n                "
                        ),
                      ]
                    },
                    { item: mutableItem }
                  ),
                ],
                2
              )
            }),
            0
          ),
        ]
      ),
      _vm._v(" "),
      _c("contenteditable", {
        ref: "editor",
        staticClass: "editor p-1",
        attrs: {
          contenteditable: !_vm.disabled,
          tag: "div",
          noHTML: false,
          noNL: true,
          placeholder: _vm.placeholder,
          disabled: _vm.disabled,
        },
        on: {
          input: _vm.handleInput,
          keydown: _vm.handleKeyDown,
          mousedown: _vm.handleMouseDown,
          mouseup: _vm.handleMouseUp,
          mousemove: _vm.handleMouseMove,
          click: _vm.handleEditorClick,
        },
        model: {
          value: _vm.message,
          callback: function ($$v) {
            _vm.message = $$v
          },
          expression: "message",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }