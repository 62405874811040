<template>
    <div class="container-fluid w-v bg-white">
        <div class="row full-height">
            <div class="col-lg-6 d-flex flex-column align-items-center text-color m-auto bg-white"
                 style="height: 100vh; padding-top: 10rem">
                <div class="login-brand mb-2">
                    <img :src="statics.logo"
                         alt="."/>
                </div>
                <div class="my-4 text-center">
                    <p class="mb-1">Welcome {{ auth.user.profile.first_name }}! You have multiple accounts.</p>
                    <p>Please choose the account you want to proceed with.</p>
                </div>
                <el-button class="mb-2 ml-0 lead"
                           v-for="access in accesses"
                           :key="access.company_id"
                           v-if="access.enabled && access.company.enabled"
                           @click="companyLogin(access)">
                    <b>{{ access.company.name }}</b>
                </el-button>
            </div>
        </div>
    </div>
</template>

<script>
import auth from '../auth'
import {acl_mixin} from '../mixins'
import {mapActions} from 'vuex'

export default {
    mixins: [acl_mixin],
    data() {
        return {
            auth: auth,
            accesses: [],
            statics: {
                logo: null,
                logo_inverse: null,
                logo_square: null,
                logo_square_inverse: null,
                host: null,
                referer: null,
                name: null,
                domain: null,
                whitelabel: false,
                path: null
            },
        }
    },

    mounted() {
        this.auth.user.authenticated = false
        this.accesses = this.auth.user.profile.user_accesses
    },

    created() {
        this.getStatics()
        this.getAccesses()
    },

    methods: {
        getAccesses() {
            axios.get('/api/v1/user/company/accesses')
                .then(res => {
                    this.accesses = res.data
                })
                .catch(err => {
                    console.log(err)
                    this.$root.handleErrors(err.response)
                })
        },

        getStatics() {
            axios.get('/get-statics')
                .then(res => {
                    this.statics = res.data
                    this.setPageTitle('Select Company - ' + this.statics.name)
                })
                .catch(err => {
                    this.setPageTitle('Select Company - Aloware')
                    console.log(err)
                    this.$root.handleErrors(err.response)
                })
        },

        companyLogin(access) {
            // Logout session from all other tabs when changing company
            if (this.current_company.id != access.company_id) {
                this.logoutUser()
            }

            this.auth.impersonate(access.id, true).then(res => {
                window.location.href = '/'
            })

        },

        ...mapActions('cache', ['setCurrentCompany']),
        ...mapActions(['logoutUser'])
    },

    beforeRouteEnter(to, from, next) {
        auth.check(false, false).then((res) => {
            // allow only user with multiple access
            if (res.data.user.has_multiple_access) {
                next()
            }
            // redirect to dashboard if user have no multiple access
            if (!res.data.user.has_multiple_access) {
                next({name: 'Dashboard'})
            }
        }).catch((err) => {
            next({name: 'Login', query: {redirect: to.fullPath}})
        })
    }
}
</script>
