<template>
    <div class="app-body"
         id="view">
        <el-dialog :visible.sync="show_help"
                   title="What are users?"
                   top="10vh"
                   data-testid="what-are-users-dialog"
                   append-to-body>
            <p class="text-md _600 break-word">Users can be your agents or external phone numbers that handle your
                calls. You can view their activity, or temporarily pause them, on this page.</p>
            <p class="text-md _600 break-word">User settings can be edited to configure working hours, visibility,
                notifications, and much more. <span v-if="!isWhitelabelAccount">Learn more about users <a
                    data-testid="learn-more-about-users-link"
                    href="https://support.aloware.com/en/articles/9034191-introducing-aloware-wallboard-your-real-time-communication-metrics-dashboard" target="_blank"
                    class="text-decoration-underline el-link--primary">here</a></span>
            </p>
        </el-dialog>

        <add-user-to-team-modal :is-visible.sync="isAddUserToTeamModalVisible"
                                :user="selected_user"
                                :teams="teams"
                                :is-loading-user-teams="isLoadingUserTeams"
                                @fetch-user-teams="fetchUserTeams(selected_user?.id)"
                                @close="closeAddUserToTeamModal" />

        <user-extension-wizard data-testid="user-extension-wizard" 
                               ref="newExtension">
        </user-extension-wizard>

        <div class="fixed-header padding pt-0 pb-0" :style="{top: (total_offset+50) + 'px'}">
            <div class="row mb-0 mt-0">
                <div class="d-flex flex-fill flex-wrap p-2">
                    <div class="d-flex flex-wrap flex-xl-nowrap flex-fill mr-auto">
                        <div class="mr-sm-2 flex-fill flex-xl-grow-0 search-toolbar">
                            <el-input ref="userSearch"
                                      placeholder="Search"
                                      prefix-icon="el-icon-search"
                                      clearable
                                      autofocus
                                      v-model="search_text"
                                      data-testid="user-search-input">
                            </el-input>
                        </div>

                        <div class="no-select mt-2 mt-sm-0 mr-md-2 flex-fill flex-xl-grow-0 w-m">
                            <el-select v-model="type_filter"
                                       class="no-select"
                                       placeholder="Select"
                                       default-first-option
                                       filterable
                                       data-testid="role-type-dropdown"
                                       @change="changedFilter">
                                <el-option :key="1"
                                           label="Users"
                                           value="Users">
                                </el-option>
                                <el-option :key="2"
                                           label="Admins"
                                           value="Company Admin">
                                </el-option>
                                <el-option :key="3"
                                           label="Agents"
                                           value="Company Agent">
                                </el-option>
                                <el-option :key="4"
                                           label="Reporters"
                                           value="Company Reporter Access">
                                </el-option>
                                <el-option :key="5"
                                           label="Extensions"
                                           value="Extensions">
                                </el-option>
                                <el-option :key="6"
                                           label="Supervisors"
                                           value="Company Supervisor">
                                </el-option>
                            </el-select>
                        </div>

                        <div class="no-select mt-2 mt-xl-0 mr-2 flex-sm-shrink-0">
                            <el-radio-group class="no-select"
                                            v-model="user_filter"
                                            v-if="!isMobileSize"
                                            data-testid="filter-radio-group"
                                            @change="changedFilter">
                                <el-radio-button label="all" 
                                                 data-testid="filter-all">
                                    All <span v-if="current_company">({{ current_company.purchased_licenses }})</span>
                                </el-radio-button>
                                <el-radio-button label="active" 
                                                 data-testid="filter-active">
                                    Active <span v-if="activeCount">({{ activeCount }})</span>
                                </el-radio-button>
                                <el-radio-button label="paused"
                                                 :class="[user_filter == 'paused' ? 'pause-deep-orange': '']"
                                                 data-testid="filter-paused">
                                    Paused <span v-if="pausedCount">({{ pausedCount }})</span>
                                </el-radio-button>
                                <el-radio-button label="suspended" 
                                                 data-testid="filter-suspended">
                                    Suspended <span v-if="suspendedCount">({{ suspendedCount }})</span>
                                </el-radio-button>
                            </el-radio-group>
                            <el-select v-else
                                       placeholder="Select"
                                       class="no-select"
                                       default-first-option
                                       filterable
                                       v-model="user_filter"
                                       data-testid="filter-dropdown-selector"
                                       @change="changedFilter">
                                <el-option :key="1"
                                           :label="'All '  + (current_company ? '(' + current_company.purchased_licenses + ')' : '')"
                                           value="all">
                                </el-option>
                                <el-option :key="2"
                                           :label="'Active ' + (activeCount ? '(' + activeCount + ')' : '')"
                                           value="active">
                                </el-option>
                                <el-option :key="3"
                                           :label="'Paused '  + (pausedCount ? '(' + pausedCount + ')' : '')"
                                           value="paused">
                                </el-option>
                                <el-option :key="4"
                                           :label="'Suspended '  + (suspendedCount ? '(' + suspendedCount + ')' : '')"
                                           value="suspended">
                                </el-option>
                            </el-select>
                        </div>
                    </div>

                    <div class="d-flex flex-wrap flex-wrap flex-xl-nowrap flex-shrink-0">
                        <div class="mt-2 mt-xl-0 mr-2">
                            <template
                                v-if="canCreateUser">
                                <button class="btn rounded greenish h-100"
                                        data-testid="add-user-button"
                                        @click="addUser">
                                    <i class="fa fa-plus"></i>
                                    Add User
                                </button>
                            </template>
                        </div>

                        <div class="mt-2 mt-xl-0 mr-2"
                             v-if="shouldContactSupport">
                            <template
                                v-if="shouldContactSupport">
                                <button class="btn rounded greenish h-100 "
                                        data-testid="contact-support-button"
                                        @click="contactSupport">
                                    <i class="fa fa-plus"></i>
                                    Add User
                                </button>
                            </template>
                        </div>

                        <div class="mt-2 mt-xl-0 mr-1">
                            <template>
                                <button class="btn rounded orangish h-100"
                                        data-testid="add-extension-button"
                                        @click="openExtensionForm">
                                    <i class="fa fa-plus"></i>
                                    Add Extension
                                </button>
                            </template>
                        </div>

                        <div class="mt-3 mt-xl-0 mr-2">
                            <video-modal-activator class="pl-1" 
                                                   data-testid="video-activator-button"
                                                   v-if="isTrial && isNotSimpSocial"/>
                        </div>

                        <div class="mt-2 mt-lg-0 mr-2 mr-lg-0 d-flex flex-column justify-content-center text-blue"
                             v-if="!isCompanyKYC">
                            <i class="material-icons text-lg cursor-pointer"
                               data-testid="info-outline-i"
                               @click="toggleHelp">
                                info_outline
                            </i>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="padding mt-5 pt-3"
             v-if="hasPermissionTo('list report') && !search_text && !$route.query.hasOwnProperty('uid')">
            <div class="row box">
                <div class="col-12">
                    <div class="no-effect">
                        <div class="box-header pb-0">
                            <activity-graph base="user"
                                            :default_date_range="7"
                                            :is_first_load="reports_first_load"
                                            data-testid="activity-graph">
                            </activity-graph>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="padding"
             v-if="hasPermissionTo('list user')">
            <div class="row box">
                <div class="col-12">
                    <div class="no-effect">
                        <div class="box-header pb-0">
                            <div class="row">
                                <div class="col-3">
                                    <span class="pull-left font-120"
                                          v-if="filteredUsers">
                                        <strong>{{ filteredUsers.length || 0 }}</strong>
                                        {{ filteredUsers.length > 1 ? 'users' : 'user' }}
                                        <span v-if="user_filter == 'all'">
                                            / <strong>{{ current_company.purchased_licenses }}</strong> {{ current_company.purchased_licenses > 1 ? 'licenses' : 'license' }}
                                        </span>
                                    </span>
                                </div>
                                <div class="col-9">
                                    <p>
                                        <div class="font-120"
                                             v-if="users && users.filter(user => user.enabled == false).length > 0">
                                            Note: Suspended users are billable. <span v-if="!isWhitelabelAccount">Learn more <a
                                            href="https://support.aloware.com/en/articles/9033784-billing-implication-of-paused-suspended-users"
                                            target="_blank" class="text-decoration-underline el-link--primary"
                                            data-testid="billing-implication-link">here</a>.</span>
                                        </div>
                                        <template v-if="users && current_company.min_user_seat > 1 && hasPerSeatPlanUseCase()">
                                            <!-- Minimum seat count not covered message -->
                                            <span class="font-120"
                                                  v-if="users.length < current_company.min_user_seat">
                                                You still have <strong>{{ current_company.min_user_seat - users.length }}</strong> more seats to assign. Invite more users today! (Minimum seat count = <strong>{{
                                                    current_company.min_user_seat
                                                }}</strong>)
                                            </span>
                                            <!-- Available licenses info message -->
                                            <span class="font-120"
                                                  v-if="users.length >= current_company.min_user_seat && getAvailableLicenses() > 0">
                                                You still have <strong>{{ this.getAvailableLicenses() }}</strong> more licenses to assign. Invite more users today! (Purchased licenses = <strong>{{ current_company.purchased_licenses }}</strong>)
                                            </span>
                                        </template>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="box-body pt-0">
                            <div v-on:mouseleave="tableLeave">
                                <el-table
                                    v-loading="loading"
                                    :data="paginatedData"
                                    :default-sort="{prop: 'id', order: 'ascending'}"
                                    class="w-full"
                                    fit
                                    stripe
                                    data-testid="users-table"
                                    @cell-mouse-enter="cellEnter">
                                    <el-table-column
                                        data-testid="users-status-column"
                                        prop="status"
                                        align="left"
                                        width="120">
                                        <template v-slot="scope">
                                            <span class="status d-flex align-items-center justify-content-left">
                                                <i :class="[ $options.filters.agentStatusClass(scope.row.agent_status) ]"
                                                   class="b-white mr-2"></i>
                                                {{ scope.row.agent_status | fixAgentStatus }}
                                            </span>
                                        </template>
                                    </el-table-column>
                                    <el-table-column
                                        data-testid="users-id-column"
                                        prop="id"
                                        label="ID"
                                        width="100"
                                        sortable>
                                        <template slot="header" slot-scope="scope">
                                            <span data-testid="id-column-table">ID</span>
                                        </template>
                                        <template v-slot="scope">
                                            <span class="text-greyish">{{ scope.row.id }}</span>
                                        </template>
                                    </el-table-column>
                                    <el-table-column
                                        data-testid="users-name-column"
                                        prop="name"
                                        label="Name"
                                        :min-width="200"
                                        sortable>
                                        <template slot="header" slot-scope="scope">
                                            <span data-testid="name-column-table">Name</span>
                                        </template>
                                        <template v-slot="scope">
                                            <router-link
                                                data-testid="user-name-link"
                                                :to="{ name: 'User Dialog', params: { user_id: scope.row.id }}">
                                                <span class="text-dark-greenish text-ellipsis">{{ scope.row.name || "-" }}</span>
                                                <span class="text-greyish text-ellipsis"
                                                      :title="scope.row.email"
                                                      v-if="!scope.row.is_destination">
                                                    {{ scope.row.email }}
                                                </span>
                                            </router-link>
                                            <button class="btn btn-info btn-sm mt-2"
                                                    data-testid="resend-welcome-email-button"
                                                    @click.prevent="resendWelcomeEmail(scope.row.id)"
                                                    v-if="!scope.row.is_destination && !scope.row.email_verified_at">
                                                Resend welcome email
                                            </button>
                                        </template>
                                    </el-table-column>
                                    <el-table-column
                                        data-testid="users-description-column"
                                        prop="description"
                                        label="Description"
                                        :min-width="200">
                                        <template v-slot="scope">
                                            <span v-if="scope.row.description">{{ scope.row.description }}</span>
                                            <span v-else>-</span>
                                        </template>
                                    </el-table-column>
                                    <el-table-column
                                        data-testid="users-last-login-column"
                                        prop="last_login"
                                        label="Last login"
                                        :min-width="200">
                                        <template v-slot="scope">
                                            <span v-if="scope.row.last_login && !scope.row.is_destination">{{ scope.row.last_login | fixFullDateUTCRelative }}</span>
                                            <span v-else>-</span>
                                        </template>
                                    </el-table-column>
                                    <el-table-column
                                        data-testid="users-last-role-names-column"
                                        prop="role_names"
                                        label="Role"
                                        :min-width="200">
                                        <template v-slot="scope">
                                            <template v-if="!scope.row.is_destination">
                                                <div class="row"
                                                     v-for="role_name in scope.row.role_names">
                                                    <div class="col-12">
                                                        <span class="text-ellipsis"
                                                              :title="role_name">
                                                            {{ role_name }}
                                                        </span>
                                                    </div>
                                                </div>
                                            </template>
                                            <span class="text-ellipsis"
                                                  v-else>
                                                Extension
                                            </span>
                                        </template>
                                    </el-table-column>
                                    <el-table-column
                                        data-testid="users-last-extension-column"
                                        prop="extension"
                                        label="Extension"
                                        :min-width="120"
                                        sortable>
                                        <template slot="header" slot-scope="scope">
                                            <span data-testid="extension-column-table">Extension</span>
                                        </template>
                                        <template v-slot="scope">
                                            <span class="text-greyish text-ellipsis">{{ scope.row.extension || "-" }}</span>
                                        </template>
                                    </el-table-column>
                                    <el-table-column
                                        data-testid="users-last-status-column"
                                        label="Status"
                                        prop="active"
                                        align="left"
                                        width="100"
                                        sortable>
                                        <template slot="header" slot-scope="scope">
                                            <span data-testid="status-column-table">Status</span>
                                        </template>
                                        <template v-slot="scope">
                                            <span class="status d-flex align-items-center justify-content-left">
                                                {{ scope.row.active ? 'Active' : 'Paused' }}
                                            </span>
                                        </template>
                                    </el-table-column>
                                    <el-table-column
                                        data-testid="users-last-answer-by-column"
                                        prop="answer_by"
                                        label="Answer By"
                                        align="left"
                                        :min-width="150"
                                        sortable>
                                        <template slot="header" slot-scope="scope">
                                            <span data-testid="answer-by-column-table">Answer by</span>
                                        </template>
                                        <template v-slot="scope">
                                            <span class="text-greyish"
                                                  v-if="scope.row.answer_by === AnswerTypes.BY_BROWSER">
                                                Browser / Apps
                                            </span>
                                            <span class="text-greyish"
                                                  v-else-if="scope.row.answer_by === AnswerTypes.BY_PHONE_NUMBER">
                                                <span v-if="scope.row.phone_number">{{ scope.row.phone_number | fixPhone }}</span>
                                                <span v-else>-</span>
                                            </span>
                                            <span class="text-greyish"
                                                  v-else-if="scope.row.answer_by === AnswerTypes.BY_IP_PHONE">
                                                SIP
                                            </span>
                                            <span class="text-greyish"
                                                  v-else-if="scope.row.answer_by === AnswerTypes.BY_NONE">
                                                Will not answer
                                            </span>
                                            <el-tooltip class="item"
                                                        effect="dark"
                                                        popper-class="rejection-tooltip"
                                                        content="This user will can not make outbound calls unless you select a default outbound line for this user or for your company."
                                                        placement="right"
                                                        v-if="scope.row.answer_by === AnswerTypes.BY_IP_PHONE && scope.row.sip_uri && current_company && !current_company.default_outbound_campaign_id">
                                                <div class="status-icon d-inline-block">
                                                    <i class="material-icons text-danger">error</i>
                                                </div>
                                            </el-tooltip>
                                        </template>
                                    </el-table-column>
                                    <el-table-column
                                        data-testid="users-last-daily-call-limit-column"
                                        prop="daily_call_limit"
                                        label="Daily Call Limit"
                                        align="left"
                                        :min-width="150"
                                        v-if="auth.user.profile.lead_gen_tools_enabled"
                                        sortable>
                                        <template v-slot="scope">
                                            <span class="text-greyish">{{ scope.row.daily_call_limit || "0" }}</span>
                                        </template>
                                    </el-table-column>
                                    <el-table-column
                                        data-testid="users-last-operations-column"
                                        label="Operations"
                                        align="center"
                                        width="150"
                                        fixed="right">
                                        <template v-slot="scope">
                                            <div v-bind:style="getStyle(scope.row.id)">
                                                <div class="row"
                                                     v-if="hasPermissionTo('impersonate user') && !isUserSuspended(scope.row) && allowUserUpdate(scope.row)">
                                                    <div class="col-12">
                                                        <button class="btn btn-sm btn-block blackish"
                                                                v-if="auth.user.profile.id !== scope.row.id && !scope.row.is_destination && !inArray('Billing Admin', scope.row.role_names)"
                                                                data-testid="login-as-button"
                                                                @click="impersonate(scope.row.id)">
                                                            <i class="fa fa-user-secret pull-left"></i>
                                                            Login As
                                                        </button>
                                                    </div>
                                                </div>
                                                <div class="row mt-2"
                                                     v-if="(hasPermissionTo('update user') || (hasPermissionTo('update profile') && auth.user.profile.id == scope.row.id)) && !isUserSuspended(scope.row)">
                                                    <div class="col-12">
                                                        <router-link
                                                            :to="{ name: 'User Timeline', params: { user_id: scope.row.id }}">
                                                            <button class="btn btn-sm btn-block purple-800"
                                                                    data-testid="timeline-button">
                                                                <i class="fa fa-history pull-left"></i>
                                                                Timeline
                                                            </button>
                                                        </router-link>
                                                    </div>
                                                </div>
                                                <div class="row mt-2"
                                                     v-if="hasPermissionTo(['view user', 'list communication', 'view communication'])">
                                                    <div class="col-12">
                                                        <router-link
                                                            :to="{ name: 'User Activity', params: {user_id: scope.row.id }}">
                                                            <button class="btn btn-sm btn-block purplish" 
                                                                    data-testid="activity-button">
                                                                <i class="fa fa-signal pull-left"></i>
                                                                Activity
                                                            </button>
                                                        </router-link>
                                                    </div>
                                                </div>
                                                <div class="row mt-2"
                                                     v-if="(hasPermissionTo('update user') || (hasPermissionTo('update profile') && auth.user.profile.id == scope.row.id)) && !isUserSuspended(scope.row) && allowUserUpdate(scope.row)">
                                                    <div class="col-12">
                                                        <router-link
                                                            :to="{ name: 'User Dialog', params: { user_id: scope.row.id }}">
                                                            <button class="btn btn-sm btn-block greenish" 
                                                                    data-testid="settings-button">
                                                                <i class="fa fa-cogs pull-left"></i>
                                                                Settings
                                                            </button>
                                                        </router-link>
                                                    </div>
                                                </div>
                                                <div class="row mt-2"
                                                     v-if="(hasPermissionTo('update user') || (hasPermissionTo('update profile') && auth.user.profile.id == scope.row.id)) && !isUserSuspended(scope.row) && allowUserUpdate(scope.row)">
                                                    <div class="col-12">
                                                        <button class="btn btn-sm btn-block bluish d-flex align-items-center"
                                                                data-testid="add-to-team-button"
                                                                @click="openAddUserToTeamModal(scope.row)">
                                                            <i class="fa fa-plus mr-3"></i>
                                                            <div class="mr-2">Add to team</div>
                                                        </button>
                                                    </div>
                                                </div>
                                                <div class="row mt-2"
                                                     v-if="(hasPermissionTo('update user') || (hasPermissionTo('toggle active user') && auth.user.profile.id == scope.row.id)) && !isUserSuspended(scope.row) && auth.user.profile.id != scope.row.id && allowUserUpdate(scope.row)">
                                                    <div class="col-12">
                                                        <button class="btn btn-sm btn-block"
                                                                :class="scope.row.active == true ? 'deep-orange-500' : 'light-blue-500'"
                                                                data-testid="pause-active-button"
                                                                :disabled="loading_btn_id == scope.row.id"
                                                                @click="toggleActive(scope.row.id)">
                                                            <i class="fa fa-spin fa-spinner pull-left"
                                                               v-if="loading_btn_id == scope.row.id"></i>
                                                            <template v-else>
                                                                <i class="fa fa-pause pull-left"
                                                                   v-if="scope.row.active == true"></i>
                                                                <i class="fa fa-play pull-left"
                                                                   v-else></i>
                                                            </template>
                                                            {{ scope.row.active == true ? 'Pause' : 'Activate' }}
                                                        </button>
                                                    </div>
                                                </div>
                                                <div class="row mt-2"
                                                     v-if="(hasPermissionTo('archive user') || hasPermissionTo('update user')) && auth.user.profile.id != scope.row.id && !scope.row.is_destination && allowUserUpdate(scope.row) && allowUserSuspension(scope.row)">
                                                    <div class="col-12">
                                                        <button class="btn btn-sm btn-block btn-info"
                                                                v-if="isUserSuspended(scope.row)"
                                                                data-testid="unsuspend-button"
                                                                @click="unsuspendUserRemote(scope.row.id)">
                                                            <i class="fa fa-briefcase-medical pull-left"></i>
                                                            Unsuspend
                                                        </button>
                                                        <button class="btn btn-sm btn-block purple-A400"
                                                                v-else
                                                                data-testid="suspend-button"
                                                                @click="suspendUserRemote(scope.row.id)">
                                                            <i class="fa fa-power-off pull-left"></i>
                                                            Suspend
                                                        </button>
                                                    </div>
                                                </div>
                                                <div class="row mt-2"
                                                     v-if="hasPermissionTo('archive user') && auth.user.profile.id != scope.row.id && allowUserUpdate(scope.row) && allowUserDelete(scope.row)">
                                                    <div class="col-12">
                                                        <button class="btn btn-sm btn-block danger"
                                                                data-testid="delete-button"
                                                                @click="deleteUserRemote(scope.row.id)">
                                                            <i class="fa fa-trash pull-left"></i>
                                                            Delete
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </template>
                                    </el-table-column>
                                    <template slot="empty"
                                              data-testid="no-data-users-template"
                                              class="text-center text-muted">
                                        <span v-if="!loading">No Data</span>
                                        <span v-if="loading"></span>
                                    </template>
                                </el-table>
                            </div>
                            <div class="mt-3"
                                 v-if="pageCount >= 1">
                                <el-pagination class="pull-right"
                                               background
                                               layout="sizes, prev, pager, next"
                                               :page-sizes="[10, 20, 50, 100, 250]"
                                               :page-size="20"
                                               :current-page.sync="pagination.current_page"
                                               :total="dataCount"
                                               data-testid="users-list-pagination"
                                               @current-change="changePage"
                                               @size-change="handleSizeChange">
                                </el-pagination>
                                <div class="clearfix"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <el-backtop target="html"
                    ref="backtop"
                    :bottom="20"
                    :right="20">
        </el-backtop>

        <video-modal class="pl-4"
                     title="Adding Users to Your Aloware Account 👥"
                     cookie-name="users"
                     notes="🔥 Meet <strong>Users</strong> - your agents or external digits mastering your calls!</br></br>🔍 Check their buzz, or hit pause, right here. Dive deep into user settings: tweak hours, control visibility, set notifications, and unleash more!</br></br>Dive deeper into the user universe here."
                     video-url="https://www.youtube.com/embed/ysMOYBj6MAE?si=OvKsYP8mEUuWApk8"
                     learn-more-link="https://support.aloware.com/setting-user-status-to-available-in-aloware-admin-a-step-by-step-guide"
                     :has-activator-button="true"
                     v-if="isTrial && isNotSimpSocial"
                     data-testid="video-modal-to-add-user"/>
    </div>
</template>

<script>
import auth from '../../auth'
import {mapActions, mapGetters, mapState} from 'vuex'
import {acl_mixin, announce_kit_mixin, chargebee_mixin, kyc_mixin, paginator_mixin, scroll_mixin, styling_mixin, supervisor_mixin, user_mixin, teams_mixin, users_mixin} from '../../mixins'
import RelativeTime from "../../components/relative-time"
import * as AgentStatusLabels from '../../constants/agent-status-labels'
import * as AnswerTypes from '../../constants/answer-types'
import * as CommunicationDirection from '../../constants/communication-direction'
import VideoModal from '../../components/video-modal.vue'
import VideoModalActivator from '../../components/video-modal-activator.vue'
import AddUserToTeamModal from '../../components/teams/add-user-to-team-modal.vue'

export default {
    mixins: [
        acl_mixin,
        styling_mixin,
        chargebee_mixin,
        paginator_mixin,
        scroll_mixin,
        user_mixin,
        announce_kit_mixin,
        kyc_mixin,
        supervisor_mixin,
        teams_mixin,
        users_mixin,
    ],

    components: {
        RelativeTime,
        VideoModal,
        VideoModalActivator,
        AddUserToTeamModal
    },

    data() {
        return {
            auth: auth,
            env: null,
            loading: false,
            search_fields: ['id', 'name', 'phone_number', 'extension', 'email'],
            search_text: '',
            selected_user: {},
            loading_btn: false,
            visible_row_id: null,
            loading_btn_id: null,
            user_filter: 'active',
            type_filter: 'Users',
            dataKey: 'filteredUsers',
            dataSort: 'id',
            dataSortType: 'ASC',
            show_help: false,
            reports_first_load: true,
            allowed_statuses: [
                'all',
                'active',
                'paused'
            ],
            allowed_types: [
                'users',
                'company_admin',
                'company_agent',
                'company_reporter_access',
                'extensions',
                'company_supervisor'
            ],
            AnswerTypes,
            CommunicationDirection,
            AgentStatusLabels
        }
    },

    computed: {
        ...mapState(['users', 'usage']),
        ...mapState('cache', ['current_company', 'show_graph']),
        ...mapGetters('cache', ['isTrial', 'isNotSimpSocial']),

        isMobileSize() {
            return window.screen.width <= 425
        },

        visibleUsers() {
            if (this.users) {
                if (this.search_text != "") {
                    this.user_filter = 'all'
                    let filtered = []
                    for (let user of this.users) {
                        let keys = this.search_fields

                        for (let key of keys) {
                            if (key.indexOf('.') !== -1) {
                                let mixed = key.split(".")
                                if (user[mixed[0]]) {
                                    if (user[mixed[0]][mixed[1]].toString().toLowerCase().indexOf(this.search_text.toString().toLowerCase()) !== -1) {
                                        filtered.push(user)
                                        break
                                    }
                                }
                            } else if (user[key]) {
                                if (user[key].toString().toLowerCase().indexOf(this.search_text.toString().toLowerCase()) !== -1) {
                                    filtered.push(user)
                                    break
                                }
                            }
                        }
                    }
                    return filtered
                } else if (this.user_filter == 'active') {
                    return this.users.filter(user => user.active == true)
                } else if (this.user_filter == 'paused') {
                    return this.users.filter(user => user.enabled == true && user.active == false)
                } else if (this.user_filter == 'suspended') {
                    return this.users.filter(user => user.enabled == false && user.active == false)
                } else {
                    return this.users
                }
            }

            return []
        },

        activeCount() {
            if (this.type_filter == 'Users') {
                return this.users.filter(user => user.active == true && !user.is_destination).length
            }
            if (this.type_filter == 'Extensions') {
                return this.users.filter(user => user.active == true && user.is_destination).length
            }
            return this.users.filter(user => user.active == true && this.inArray(this.type_filter, user.role_names) && !user.is_destination).length
        },

        allCount() {
            if (this.type_filter == 'Users') {
                return this.users.filter(user => !user.is_destination).length
            }
            if (this.type_filter == 'Extensions') {
                return this.users.filter(user => user.is_destination).length
            }
            return this.users.filter(user => this.inArray(this.type_filter, user.role_names) && !user.is_destination).length
        },

        pausedCount() {
            if (this.type_filter == 'Users') {
                return this.users.filter(user => user.enabled == true && !user.is_destination && user.active == false).length
            }
            if (this.type_filter == 'Extensions') {
                return this.users.filter(user => user.enabled == true && user.active == false && user.is_destination).length
            }
            return this.users.filter(user => user.enabled == true && user.active == false && this.inArray(this.type_filter, user.role_names) && !user.is_destination).length
        },

        suspendedCount() {
            if (this.type_filter == 'Users') {
                return this.users.filter(user => user.enabled == false && !user.is_destination).length
            }
            if (this.type_filter == 'Extensions') {
                return this.users.filter(user => user.enabled == false && user.is_destination).length
            }
            return this.users.filter(user => user.enabled == false && this.inArray(this.type_filter, user.role_names) && !user.is_destination).length
        },

        filteredUsers() {
            // filter a specific user from a redirect
            if (this.$route.query.uid && this.$route.query.uid != '') {
                return this.visibleUsers.filter(user => +user.id === +this.$route.query.uid)
            }

            if (this.visibleUsers) {
                if (this.type_filter == 'Extensions') {
                    return this.visibleUsers.filter(user => user.is_destination)
                } else if (this.type_filter != 'Users') {
                    return this.visibleUsers.filter(user => this.inArray(this.type_filter, user.role_names) && !user.is_destination)
                } else if (this.type_filter == 'Users') {
                    return this.visibleUsers.filter(user => !user.is_destination)
                } else {
                    return this.visibleUsers
                }
            }

            return []
        },

        isUserUsageLimitValid() {
            if (this.usage && _.has(this.usage, 'user.included') && _.has(this.usage, 'user.used')) {
                return this.usage.user.included == 0 ||
                    this.usage.user.used < this.usage.user.included
            }

            return false
        },

        isUserUsageLimitReached() {
            if (this.usage && _.has(this.usage, 'user.included') && _.has(this.usage, 'user.used')) {
                return this.usage.user.included != 0 &&
                    this.usage.user.used >= this.usage.user.included
            }

            return false
        },

        canCreateUser() {
            return this.hasPermissionTo('create user') &&
                this.isUserUsageLimitValid
        },

        shouldContactSupport() {
            return this.hasPermissionTo('create user') &&
                this.isUserUsageLimitReached
        }
    },

    mounted() {
        this.setTitle()

        if (!this.hasPermissionTo('list user')) {
            this.goBack()
        }

        this.search_text = ''

        if (this.$route.query && typeof this.$route.query.status != 'undefined' && this.$route.query.status) {
            this.user_filter = this.allowed_statuses.includes(this.$route.query.status) ? this.$route.query.status : this.user_filter
        }

        if (this.$route.query && typeof this.$route.query.role != 'undefined' && this.$route.query.role) {
            this.type_filter = this.allowed_types.includes(this.$route.query.role.replace(/\s/g, "_").toLowerCase()) ? this.$route.query.role : this.type_filter
        }

        // focus user search input on page visit
        if (this.$refs.userSearch) {
            this.$refs.userSearch.focus()
        }
    },

    methods: {
        setTitle() {
            axios.get('/get-statics')
                .then(res => {
                    let name = res.data.name
                    this.setPageTitle('Users - ' + name)
                })
                .catch(err => {
                    this.setPageTitle('Users - Aloware')
                    console.log(err)
                    this.$root.handleErrors(err.response)
                })
        },

        toggleActive(user_id) {
            let user = this.users.find(user => user.id === user_id)
            let new_state = user.active == true ? 'pause' : 'activate'
            this.$confirm('This will ' + new_state + ' routing calls to the user. Continue?', 'Warning', {
                confirmButtonText: 'OK',
                cancelButtonText: 'Cancel',
                customClass: 'width-500 fixed',
                type: 'warning'
            }).then(() => {
                this.loading_btn_id = user.id
                axios.post('/api/v1/user/' + user.id + '/toggle-active')
                    .then(res => {
                        this.updateUser(res.data)
                        this.loading_btn_id = null

                        // for extension user (if v2)
                        this.updateFilteredUsers()
                    })
                    .catch(err => {
                        this.$root.handleErrors(err.response)
                        this.loading_btn_id = null
                    })
            }).catch(() => {

            })
        },

        isUserSuspended(user) {
            return !user.enabled
        },

        suspendUserRemote(user_id) {
            let learnMoreLink = !this.isWhitelabelAccount ? "Learn more about suspension vs deletion of users <a class='text-decoration-underline el-link--primary' href='https://support.aloware.com/en/articles/9033784-billing-implication-of-paused-suspended-users' target='_blank'>here</a>." : '';
            this.$confirm("Suspended users will lose their access to the platform, but they will remain billable. " + learnMoreLink, 'Warning', {
                confirmButtonText: 'OK',
                cancelButtonText: 'Cancel',
                customClass: 'width-500 fixed',
                type: 'warning',
                dangerouslyUseHTMLString: true
            }).then(() => {
                this.loading = true
                axios.post(`/api/v1/user/${user_id}/suspend`)
                    .then(res => {
                        this.updateUser(res.data)
                        this.loading = false
                        this.$notify({
                            offset: 95,
                            title: 'User',
                            message: 'User has been suspended.',
                            type: 'success',
                            showClose: true
                        })
                    })
                    .catch(err => {
                        this.$root.handleErrors(err.response)
                        this.loading = false
                    })
            }).catch(() => {

            })
        },

        unsuspendUserRemote(user_id) {
            this.$confirm('Do you really want to unsuspend this user?', 'Warning', {
                confirmButtonText: 'OK',
                cancelButtonText: 'Cancel',
                customClass: 'width-500 fixed',
                type: 'warning'
            }).then(() => {
                this.loading = true
                axios.post(`/api/v1/user/${user_id}/unsuspend`)
                    .then(res => {
                        this.updateUser(res.data)
                        this.loading = false
                        this.$notify({
                            offset: 95,
                            title: 'User',
                            message: 'User has been enabled.',
                            type: 'success',
                            showClose: true
                        })
                    })
                    .catch(err => {
                        this.$root.handleErrors(err.response)
                        this.loading = false
                    })
            }).catch(() => {

            })
        },

        deleteUserRemote(user_id) {
            let user = this.users.find(user => user.id === user_id)
            let learnMoreLink = !this.isWhitelabelAccount ? "Learn more <a class='text-decoration-underline el-link--primary' href='https://support.aloware.com/en/articles/9033784-billing-implication-of-paused-suspended-users' target='_blank'>here</a>. " : '';

            let deleteArticleLink =
                this.$confirm('Deleting a user will remove it from all reports, plots and deletes all communication data. ' +
                    'Furthermore, user will be detached from the lines associated with it. If you want to temporarily stop a user from getting calls,' +
                    ' use the pause button. ' + learnMoreLink + '<br><br><span class="text-red-10">Continue to permanently delete this user? </span>', 'Warning', {
                    confirmButtonText: 'OK',
                    cancelButtonText: 'Cancel',
                    customClass: 'width-500 fixed',
                    dangerouslyUseHTMLString: true,
                    type: 'warning'
                }).then(() => {
                    this.loading = true
                    axios.delete('/api/v1/user/' + user_id)
                        .then(res => {
                            this.deleteUser(user)
                            this.loading = false
                            this.$notify({
                                offset: 95,
                                title: 'User',
                                message: 'User has been deleted.',
                                type: 'success',
                                showClose: true
                            })
                        })
                        .catch(err => {
                            this.$root.handleErrors(err.response)
                            this.loading = false
                        })
                }).catch(() => {

                })
        },

        cellEnter: _.throttle(function (row, column, cell, event) {
            this.visible_row_id = row.id
        }, 50),

        tableLeave() {
            this.visible_row_id = null
        },

        getStyle(id) {
            let opacity = 0.5
            if (id == this.visible_row_id) {
                opacity = 1
            }

            return {
                opacity: opacity
            }
        },

        changedFilter() {
            this.search_text = ''
            this.pagination.current_page = 1
            this.$router.push({name: 'Users', query: {role: this.type_filter, status: this.user_filter}})
        },

        sortUsers(a, b) {
            // nulls sort after anything else
            if (a === null) {
                return 1
            }
            if (b === null) {
                return -1
            }

            let textA = a.name.toUpperCase()
            let textB = b.name.toUpperCase()
            return (textA < textB) ? -1 : (textA > textB) ? 1 : 0
        },

        toggleHelp() {
            this.show_help = !this.show_help
        },

        addUser() {
            this.$router.push({name: 'User Dialog'})
        },

        impersonate(user_id) {
            this.logoutUser()
            this.auth.impersonate(user_id).then(res => {
                window.location.href = '/'
            })
        },

        resendWelcomeEmail(user_id) {
            axios.post('/api/v1/user/' + user_id + '/resend-welcome-email')
                .then(res => {
                    this.$notify({
                        offset: 95,
                        title: 'User',
                        message: 'We have successfully sent the welcome email.',
                        type: 'success'
                    })
                })
                .catch(err => {
                    this.$root.handleErrors(err.response)
                })
        },

        contactSupport() {
            this.$alert('Sorry, you have reached the limits of your plan, please contact support.', 'Oops!', {
                confirmButtonText: 'Ok',
                type: 'error',
                center: false,
                callback: cancel => {
                }
            })
        },

        openAddUserToTeamModal(user) {
            this.selected_user = user
            this.isAddUserToTeamModalVisible = true
            this.fetchUserTeams(user.id)
        },

        closeAddUserToTeamModal() {
            this.selected_user = {}
            this.teams = []
            this.isAddUserToTeamModalVisible = false
        },

        openExtensionForm() {
            this.$refs.newExtension.show_extension_wizard = true
        },

        ...mapActions(['deleteUser', 'updateUser', 'resetVuex', 'logoutUser']),
        ...mapActions('cache', ['setCurrentCompany'])
    },

    watch: {
        'show_graph': function () {
            this.reports_first_load = false
        },
    },

    beforeCreate() {
        axios.get('/build-info.json')
            .then(res => {
                this.env = res.data.env
            })
            .catch(err => {
                console.log(err)
            })
    },

    beforeRouteEnter(to, from, next) {
        auth.check()
            .then((res) => {
                if (res.data.user.is_reseller) {
                    // redirect to account management portal if the company is a reseller
                    next({name: 'Account Management Portal'})
                } else {
                    next()
                }
            })
            .catch((err) => {
                next({name: 'Login', query: {redirect: to.fullPath}})
            })
    }
}
</script>
