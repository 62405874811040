var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("input", {
      directives: [
        {
          name: "model",
          rawName: "v-model",
          value: _vm.contactAttribute.value,
          expression: "contactAttribute.value",
        },
        {
          name: "show",
          rawName: "v-show",
          value: !_vm.contactAttribute.edit_mode,
          expression: "!contactAttribute.edit_mode",
        },
      ],
      staticClass: "md-input",
      attrs: { "data-testid": "contact-attributes-input", disabled: true },
      domProps: { value: _vm.contactAttribute.value },
      on: {
        input: function ($event) {
          if ($event.target.composing) return
          _vm.$set(_vm.contactAttribute, "value", $event.target.value)
        },
      },
    }),
    _vm._v(" "),
    _c("input", {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.contactAttribute.edit_mode,
          expression: "contactAttribute.edit_mode",
        },
        {
          name: "model",
          rawName: "v-model",
          value: _vm.contactAttribute.value,
          expression: "contactAttribute.value",
        },
      ],
      ref: _vm.contactAttribute.slug,
      staticClass: "md-input",
      attrs: { "data-testid": "edit-contact-attributes-input" },
      domProps: { value: _vm.contactAttribute.value },
      on: {
        blur: function ($event) {
          return _vm.$emit(
            "input-set-editable",
            _vm.contactAttribute,
            false,
            _vm.AttributeDictionary.TYPE_TEXT
          )
        },
        keyup: function ($event) {
          return _vm.$emit("input-save-contact-attribute", _vm.contactAttribute)
        },
        input: function ($event) {
          if ($event.target.composing) return
          _vm.$set(_vm.contactAttribute, "value", $event.target.value)
        },
      },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }