var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-popover",
    {
      staticClass: "p-0",
      attrs: {
        placement: "top-start",
        width: "400",
        trigger: "click",
        "data-testid": "attachment-popover",
        "popper-class": "variable-popover",
      },
    },
    [
      _c(
        "el-upload",
        {
          ref: "attachment_upload",
          attrs: {
            "data-testid": "attachment-upload",
            action: "/api/v1/uploaded-files/" + _vm.campaign_id,
            headers: _vm.headers,
            "on-success": _vm.fileUploaded,
            "on-error": _vm.uploadFailed,
            "file-list": _vm.media.files,
            "show-file-list": false,
            "before-upload": _vm.beforeFileUpload,
            multiple: "",
            drag: "",
          },
        },
        [
          _c("i", { staticClass: "el-icon-upload" }),
          _vm._v(" "),
          _c("div", { staticClass: "el-upload__text" }, [
            _vm._v("\n            Drop file here or "),
            _c("em", [_vm._v("click to upload")]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "el-upload__tip p-3 break-word",
              attrs: { slot: "tip" },
              slot: "tip",
            },
            [
              _vm._v(
                "\n            We support various media file types.\n            The file size limit for images is 5MB, and for the other file types, it should be below 600KB for carriers to accept it.\n        "
              ),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-button",
        {
          staticClass: "border-0 bg-transparent",
          attrs: {
            slot: "reference",
            id: "attachment-popover-trigger",
            "data-testid": "attachment-popover-trigger",
            size: "mini",
            disabled: _vm.disabled,
            circle: "",
          },
          slot: "reference",
        },
        [_c("i", { staticClass: "fa fa-paperclip" })]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }