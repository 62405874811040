var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          staticClass: "import-wizard dialog-no-border",
          attrs: {
            title: "Import Wizard",
            width: "70vw",
            top: "4vh",
            visible: _vm.importDialogVisible,
            "append-to-body": true,
            "close-on-click-modal": false,
            "before-close": _vm.beforeClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.importDialogVisible = $event
            },
          },
        },
        [
          _c("div", { attrs: { slot: "title" }, slot: "title" }, [
            _c("h5", { staticClass: "mb-0" }, [
              _c("i", { staticClass: "fas fa-hat-wizard" }),
              _vm._v("\n                Import Wizard\n            "),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "el-steps",
            {
              staticClass: "mb-3",
              attrs: {
                "align-center": "",
                "finish-status": "success",
                active: _vm.currentStep,
              },
            },
            [
              _c("el-step", { attrs: { title: "DNC Agreement" } }),
              _vm._v(" "),
              _c("el-step", { attrs: { title: "Upload CSV" } }),
              _vm._v(" "),
              _c("el-step", { attrs: { title: "Select Columns" } }),
              _vm._v(" "),
              _c("el-step", { attrs: { title: "Review Data" } }),
              _vm._v(" "),
              _c("el-step", { attrs: { title: "Settings" } }),
              _vm._v(" "),
              _c("el-step", { attrs: { title: "Finish" } }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              staticStyle: { position: "relative" },
            },
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.currentStep === 0,
                      expression: "currentStep === 0",
                    },
                  ],
                  staticClass: "step-0",
                },
                [
                  _c(
                    "div",
                    { staticClass: "alert alert-success alert-aloware" },
                    [
                      _c("div", { staticClass: "media" }, [
                        _c("i", {
                          staticClass: "fas fa-info-circle alert-icon",
                        }),
                        _vm._v(" "),
                        _c("div", { staticClass: "media-body lh-1" }, [
                          _c(
                            "div",
                            [
                              _c(
                                "el-checkbox",
                                {
                                  attrs: {
                                    "data-testid": "dnc-agreement-checkbox",
                                  },
                                  model: {
                                    value: _vm.is_dnc_agreed,
                                    callback: function ($$v) {
                                      _vm.is_dnc_agreed = $$v
                                    },
                                    expression: "is_dnc_agreed",
                                  },
                                },
                                [
                                  _c("b", [_vm._v("DNC Agreement")]),
                                  _vm._v(
                                    " - You verify this list does not contain any phone numbers found in the United States DNC (Do Not Call) List or your own DNC records.\n                                "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c("ul", { staticClass: "ml-4" }, [
                                _c("li", [
                                  _vm._v(
                                    "Organizations that contact phone numbers whose owners have requested DNC status may run the risk of legal action."
                                  ),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "li",
                                  [
                                    _vm._v(
                                      "\n                                        To help avoid legal action, scrub all contact lists before you import them using a DNC Checking Service. " +
                                        _vm._s(
                                          _vm.isSimpSocial
                                            ? "SimpSocial"
                                            : "Aloware"
                                        ) +
                                        " can\n                                        assist you with this -\n                                        "
                                    ),
                                    !_vm.is_dnc_scrubbing_requested
                                      ? _c(
                                          "el-button",
                                          {
                                            directives: [
                                              {
                                                name: "loading",
                                                rawName: "v-loading",
                                                value:
                                                  _vm.requesting_dnc_scrubbing,
                                                expression:
                                                  "requesting_dnc_scrubbing",
                                              },
                                            ],
                                            staticClass:
                                              "text-decoration-underline pb-0 pt-0",
                                            attrs: {
                                              "data-testid":
                                                "request-more-details-button",
                                              type: "text",
                                            },
                                            on: {
                                              click:
                                                _vm.requestDncScrubbingDetails,
                                            },
                                          },
                                          [
                                            _c("span", [
                                              _vm._v("Request more details"),
                                            ]),
                                          ]
                                        )
                                      : _c(
                                          "span",
                                          { staticClass: "text-success" },
                                          [
                                            _vm._v(
                                              "\n                                            DNC Scrubbing Request Has Been Sent\n                                        "
                                            ),
                                          ]
                                        ),
                                  ],
                                  1
                                ),
                              ]),
                            ],
                            1
                          ),
                        ]),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "text-center dnc-info-container" },
                    [
                      _c("h4", [
                        _vm._v(
                          "You must agree to the DNC Agreement above before importing."
                        ),
                      ]),
                      _vm._v(" "),
                      _c("p", { staticClass: "mb-0 mt-5" }, [
                        _vm._v(
                          "\n                        Want to test, but don't have a clean list?\n                    "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("p", [
                        _vm._v(
                          "\n                        Agree to the terms above, download, and then upload this example list to test import process.\n                    "
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "el-link",
                        {
                          staticClass: "text-md mt-2 a-hover",
                          attrs: {
                            "data-testid": "download-contacts-template-step-1",
                            type: "success",
                            target: "_blank",
                            underline: false,
                            href: "/templates/Contacts-Template.csv",
                          },
                        },
                        [
                          _c("i", { staticClass: "fa fa-cloud-download" }),
                          _vm._v(
                            "\n                        Download Contacts Template\n                    "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.currentStep === 1,
                      expression: "currentStep === 1",
                    },
                  ],
                  staticClass: "step-1",
                },
                [
                  _c(
                    "div",
                    { staticClass: "container-template-download text-center" },
                    [
                      _c("p", [
                        _vm._v(
                          "To get started with a file template, click link below."
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "el-link",
                        {
                          staticClass: "text-md mt-2 a-hover",
                          attrs: {
                            type: "success",
                            target: "_blank",
                            underline: false,
                            "data-testid": "download-contacts-template-step-2",
                            href: "/templates/Contacts-Template.csv",
                          },
                        },
                        [
                          _c("i", { staticClass: "fa fa-cloud-download" }),
                          _vm._v(
                            "\n                        Download Contacts Template\n                    "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-upload",
                    {
                      ref: "file_upload",
                      attrs: {
                        action: "/api/v1/import-wizard/parse",
                        "data-testid": "upload-csv-file",
                        "auto-upload": true,
                        headers: _vm.headers,
                        multiple: false,
                        "on-success": _vm.renderColumnChooser,
                        "on-error": _vm.columnChooserFailed,
                        "show-file-list": false,
                        "before-upload": _vm.beforeUpload,
                        drag: "",
                      },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "dz-label" },
                        [
                          _c("i", { staticClass: "fas fa-file-csv" }),
                          _vm._v(" "),
                          _c("p", [_vm._v("Drop your CSV file here or")]),
                          _vm._v(" "),
                          !_vm.uploading
                            ? _c(
                                "el-button",
                                {
                                  staticClass: "mb-2",
                                  attrs: {
                                    type: "success",
                                    "data-testid":
                                      "upload-from-computer-button",
                                  },
                                },
                                [
                                  _c("span", [
                                    _c("i", { staticClass: "fa fa-upload" }),
                                    _vm._v(
                                      "\n                                Upload From Computer\n                            "
                                    ),
                                  ]),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.uploadError.length > 0
                            ? _c(
                                "div",
                                {
                                  staticClass: "text-danger",
                                  staticStyle: { "word-break": "break-word" },
                                },
                                [
                                  _c("i", {
                                    staticClass: "fa fa-exclamation-circle",
                                  }),
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s(_vm.uploadError) +
                                      "\n                        "
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.currentStep === 2,
                      expression: "currentStep === 2",
                    },
                  ],
                  staticClass: "step-1",
                },
                [
                  _c("div", { staticClass: "mb-3" }, [
                    _c(
                      "div",
                      { staticClass: "alert alert-success alert-aloware" },
                      [
                        _c("div", { staticClass: "media" }, [
                          _c("i", {
                            staticClass: "fas fa-info-circle alert-icon",
                          }),
                          _vm._v(" "),
                          _c("div", { staticClass: "media-body lh-1" }, [
                            _c("h6", { staticClass: "mb-0" }, [
                              _vm._v("Instructions"),
                            ]),
                            _vm._v(" "),
                            _c("p", { staticClass: "mb-0" }, [
                              _vm._v(
                                "\n                                    We've fetched the first 10 rows in your file so you can assign each columns to\n                                    its appropriate property. Take Note that at least one phone number\n                                    (separated by comma if multiple) is required to move on to the next step.\n                                "
                              ),
                            ]),
                          ]),
                        ]),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "table-responsive" }, [
                    _c(
                      "table",
                      {
                        staticClass:
                          "table table-bordered table-csv table-striped",
                      },
                      [
                        _c("thead", [
                          _c(
                            "tr",
                            [
                              _c("th"),
                              _vm._v(" "),
                              _vm._l(_vm.tableHeader, function (th, i) {
                                return _c(
                                  "th",
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        staticClass: "select-table-header",
                                        attrs: {
                                          placeholder:
                                            "Select proper column type",
                                          filterable: "",
                                          clearable: "",
                                          "data-testid":
                                            "colum-type-selector-" + i,
                                        },
                                        on: { change: _vm.tableHeaderChange },
                                        model: {
                                          value: _vm.tableHeaderValue[i],
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.tableHeaderValue,
                                              i,
                                              $$v
                                            )
                                          },
                                          expression: "tableHeaderValue[i]",
                                        },
                                      },
                                      _vm._l(_vm.contact_fields, function (cf) {
                                        return _vm.contact_fields.length
                                          ? _c(
                                              "el-option-group",
                                              {
                                                key: cf.group_name,
                                                attrs: { label: cf.group_name },
                                              },
                                              _vm._l(
                                                cf.fields,
                                                function (field) {
                                                  return _c("el-option", {
                                                    key: field.value,
                                                    attrs: {
                                                      label: field.label,
                                                      value: field.value,
                                                      disabled:
                                                        _vm.checkHeaderMap(
                                                          field.value
                                                        ),
                                                    },
                                                  })
                                                }
                                              ),
                                              1
                                            )
                                          : _vm._e()
                                      }),
                                      1
                                    ),
                                  ],
                                  1
                                )
                              }),
                            ],
                            2
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "tbody",
                          _vm._l(_vm.tableData, function (row, index) {
                            return _c(
                              "tr",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: !(_vm.noFirstColumn && index === 0),
                                    expression:
                                      "!(noFirstColumn && index === 0)",
                                  },
                                ],
                              },
                              [
                                _c(
                                  "td",
                                  [
                                    _vm._v(
                                      "\n                                " +
                                        _vm._s(index > 0 ? index + 1 : "") +
                                        "\n                                "
                                    ),
                                    _c(
                                      "el-tooltip",
                                      {
                                        staticClass: "item",
                                        attrs: {
                                          effect: "dark",
                                          content: "Remove this first row",
                                          placement: "right",
                                        },
                                      },
                                      [
                                        index === 0
                                          ? _c(
                                              "el-button",
                                              {
                                                staticClass: "px-1",
                                                attrs: {
                                                  size: "mini",
                                                  "data-testid":
                                                    "remove-first-row-button",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    _vm.noFirstColumn = true
                                                  },
                                                },
                                              },
                                              [
                                                _c("i", {
                                                  staticClass: "fa fa-trash-o",
                                                }),
                                              ]
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _vm._l(row, function (col) {
                                  return _c("td", [_vm._v(_vm._s(col))])
                                }),
                              ],
                              2
                            )
                          }),
                          0
                        ),
                      ]
                    ),
                  ]),
                ]
              ),
              _vm._v(" "),
              _vm.currentStep === 3
                ? _c("div", { staticClass: "step-2" }, [
                    _c(
                      "div",
                      { staticClass: "alert alert-success alert-aloware" },
                      [
                        _c("div", { staticClass: "media" }, [
                          _c("i", {
                            staticClass: "fas fa-info-circle alert-icon",
                          }),
                          _vm._v(" "),
                          _c("div", { staticClass: "media-body lh-1" }, [
                            _c("h6", { staticClass: "mb-0" }, [
                              _vm._v("Review Data"),
                            ]),
                            _vm._v(" "),
                            _c("p", { staticClass: "mb-0" }, [
                              _vm._v(
                                "\n                                We've fetched and analyzed the first 10 rows in your file and merged all phone\n                                numbers\n                                found in one column. Warnings and errors will show at the right most columns in the\n                                table below. Please review the partial rows before going to the next step.\n                            "
                              ),
                            ]),
                          ]),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "container-table-csv" }, [
                      _vm.reviewData.length > 0
                        ? _c(
                            "table",
                            {
                              staticClass:
                                "table table-bordered table-csv table-striped",
                            },
                            [
                              _c("thead", [
                                _c(
                                  "tr",
                                  [
                                    _c("th"),
                                    _vm._v(" "),
                                    _vm._l(
                                      Object.keys(_vm.reviewData[0]),
                                      function (title) {
                                        return title != "unknown_columns"
                                          ? _c(
                                              "th",
                                              {
                                                class:
                                                  (title === "warnings"
                                                    ? "cell-warning"
                                                    : "") +
                                                  " " +
                                                  (title === "errors"
                                                    ? "cell-danger"
                                                    : ""),
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                " +
                                                    _vm._s(
                                                      _vm.fieldName(title)
                                                    ) +
                                                    "\n                            "
                                                ),
                                              ]
                                            )
                                          : _vm._e()
                                      }
                                    ),
                                  ],
                                  2
                                ),
                              ]),
                              _vm._v(" "),
                              _c(
                                "tbody",
                                _vm._l(
                                  _vm.reviewData,
                                  function (contact, index) {
                                    return _c(
                                      "tr",
                                      [
                                        _c("td", [_vm._v(_vm._s(index + 1))]),
                                        _vm._v(" "),
                                        _vm._l(
                                          Object.keys(contact),
                                          function (key) {
                                            return key != "unknown_columns"
                                              ? _c(
                                                  "td",
                                                  {
                                                    class:
                                                      (key === "warnings" &&
                                                      contact[key].length > 0
                                                        ? "cell-warning"
                                                        : "") +
                                                      " " +
                                                      (key === "errors" &&
                                                      contact[key].length > 0
                                                        ? "cell-danger"
                                                        : ""),
                                                  },
                                                  [
                                                    key === "phone_numbers"
                                                      ? _c("span", [
                                                          _vm._v(
                                                            "\n                                        " +
                                                              _vm._s(
                                                                contact[
                                                                  key
                                                                ].join(", ")
                                                              ) +
                                                              "\n                                    "
                                                          ),
                                                        ])
                                                      : _c("span", [
                                                          _vm._v(
                                                            "\n                                        " +
                                                              _vm._s(
                                                                contact[key]
                                                              ) +
                                                              "\n                                    "
                                                          ),
                                                        ]),
                                                  ]
                                                )
                                              : _vm._e()
                                          }
                                        ),
                                      ],
                                      2
                                    )
                                  }
                                ),
                                0
                              ),
                            ]
                          )
                        : _vm._e(),
                    ]),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.currentStep === 4
                ? _c(
                    "div",
                    { staticClass: "step-3" },
                    [
                      _c(
                        "el-form",
                        {
                          ref: "form",
                          attrs: {
                            "label-position": "top",
                            model: _vm.import_model,
                          },
                          on: {
                            submit: function ($event) {
                              $event.preventDefault()
                            },
                          },
                        },
                        [
                          _c("al-alert", { staticClass: "mx-auto mb-2" }, [
                            _c("span", {
                              staticClass: "mr-5 text-dark",
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.TAGS_DEPRECATION_IMPORT_CONTACTS_MESSAGE
                                ),
                              },
                            }),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "row" }, [
                            _c(
                              "div",
                              { staticClass: "col-md-6 pr-4" },
                              [
                                !_vm.is_contact_list
                                  ? _c(
                                      "el-form-item",
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "form-label",
                                            attrs: { scope: "label" },
                                          },
                                          [
                                            _c("label", [
                                              _vm._v(
                                                "Treat this as a DNC list"
                                              ),
                                            ]),
                                            _vm._v(" "),
                                            _c("small", [
                                              _c("i", {
                                                staticClass:
                                                  "fa fa-exclamation-triangle text-danger",
                                              }),
                                              _vm._v(
                                                "\n                                        This will set imported contacts to DNC (Do Not Call) and is not reversible.\n                                    "
                                              ),
                                            ]),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c("el-checkbox", {
                                          staticClass:
                                            "w-full el-border-checkbox-danger",
                                          attrs: {
                                            label:
                                              "Set all imported contacts on DNC",
                                            "data-testid":
                                              "set-all-contacts-checkbox",
                                            border: "",
                                          },
                                          model: {
                                            value: _vm.import_model.is_dnc,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.import_model,
                                                "is_dnc",
                                                $$v
                                              )
                                            },
                                            expression: "import_model.is_dnc",
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                !_vm.import_model.is_dnc
                                  ? _c(
                                      "el-form-item",
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "form-label",
                                            attrs: { scope: "label" },
                                          },
                                          [
                                            _c("label", [
                                              _vm._v(
                                                "Update existing contacts"
                                              ),
                                            ]),
                                            _vm._v(" "),
                                            _c("small", [
                                              _vm._v(
                                                "\n                                        Override the original existing contact data with what's in the CSV file?\n                                    "
                                              ),
                                            ]),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c("el-checkbox", {
                                          staticClass: "w-full",
                                          attrs: {
                                            label: "Update existing contacts",
                                            "data-testid":
                                              "update-existing-contacts-checkbox",
                                            border: "",
                                          },
                                          model: {
                                            value:
                                              _vm.import_model.update_existing,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.import_model,
                                                "update_existing",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "import_model.update_existing",
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                !_vm.import_model.is_dnc
                                  ? _c(
                                      "el-form-item",
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "form-label",
                                            attrs: { scope: "label" },
                                          },
                                          [
                                            _c("label", [
                                              _vm._v("Save unknown columns"),
                                            ]),
                                            _vm._v(" "),
                                            _c("small", [
                                              _vm._v(
                                                "\n                                        Columns that are not set will be saved to the contact's notes\n                                    "
                                              ),
                                            ]),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c("el-checkbox", {
                                          staticClass: "w-full",
                                          attrs: {
                                            label:
                                              "Save unknown columns to notes",
                                            "data-testid":
                                              "save-unkown-columns-checkbox",
                                            border: "",
                                          },
                                          model: {
                                            value:
                                              _vm.import_model
                                                .unknown_columns_to_notes,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.import_model,
                                                "unknown_columns_to_notes",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "import_model.unknown_columns_to_notes",
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                !_vm.import_model.is_dnc
                                  ? _c(
                                      "el-form-item",
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "form-label",
                                            attrs: { scope: "label" },
                                          },
                                          [
                                            _c("label", [
                                              _vm._v(
                                                "Multiple contacts for each phone number"
                                              ),
                                            ]),
                                            _vm._v(" "),
                                            _c("small", [
                                              _vm._v(
                                                "\n                                        Creates a separate contact for each phone number found in the file.\n                                    "
                                              ),
                                            ]),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c("el-checkbox", {
                                          staticClass: "w-full",
                                          attrs: {
                                            label:
                                              "Create a new contact for each contact’s phone number",
                                            "data-testid":
                                              "create-new-contact-checkbox",
                                            border: "",
                                          },
                                          model: {
                                            value:
                                              _vm.import_model.cascade_contacts,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.import_model,
                                                "cascade_contacts",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "import_model.cascade_contacts",
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                !_vm.import_model.is_dnc
                                  ? _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          error:
                                            _vm.errors.first("campaign_id"),
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "form-label",
                                            attrs: { scope: "label" },
                                          },
                                          [
                                            _c("label", [_vm._v("Line")]),
                                            _vm._v(" "),
                                            _c("small", [
                                              _vm._v(
                                                "Select the line that you want to import these contacts to"
                                              ),
                                            ]),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-select",
                                          {
                                            staticClass: "w-full",
                                            attrs: {
                                              clearable: "",
                                              filterable: "",
                                              "data-testid":
                                                "line-selector-in-step-5",
                                              placeholder: "Select line",
                                            },
                                            model: {
                                              value:
                                                _vm.import_model.campaign_id,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.import_model,
                                                  "campaign_id",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "import_model.campaign_id",
                                            },
                                          },
                                          _vm._l(
                                            _vm.sortedCampaigns,
                                            function (campaign) {
                                              return _c("el-option", {
                                                key: campaign.id,
                                                attrs: {
                                                  label: campaign.name,
                                                  value: campaign.id,
                                                },
                                              })
                                            }
                                          ),
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.is_contact_list
                                  ? [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            prop: "name",
                                            error: _vm.errors.first("name"),
                                            rules: {
                                              required: true,
                                              message:
                                                "You need to specify the contact list name",
                                              trigger: ["blur", "change"],
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "form-label",
                                              attrs: { scope: "label" },
                                            },
                                            [
                                              _c("label", [
                                                _vm._v("Contact List Name"),
                                              ]),
                                              _vm._v(" "),
                                              _c("small", [
                                                _vm._v(
                                                  "This will be the contact list name where these contacts will be grouped"
                                                ),
                                              ]),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c("el-input", {
                                            attrs: {
                                              clearable: "",
                                              "data-testid":
                                                "my-contacts-input",
                                              placeholder: "My contacts...",
                                            },
                                            model: {
                                              value: _vm.import_model.name,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.import_model,
                                                  "name",
                                                  $$v
                                                )
                                              },
                                              expression: "import_model.name",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            error:
                                              _vm.errors.first(
                                                "contact_folder_id"
                                              ),
                                            prop: "contact_folder_id",
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "form-label",
                                              attrs: { scope: "label" },
                                            },
                                            [
                                              _c("label", [
                                                _vm._v("Contact Folder"),
                                              ]),
                                              _vm._v(" "),
                                              _c("small", [
                                                _vm._v(
                                                  "Select the folder where you want to place this list"
                                                ),
                                              ]),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "el-select",
                                            {
                                              staticClass: "w-100",
                                              attrs: {
                                                filterable: "",
                                                clearable: "",
                                                remote: "",
                                                "data-testid":
                                                  "search-a-folder-select",
                                                "remote-method":
                                                  _vm.searchContactFolders,
                                                loading:
                                                  _vm.contact_folder_searching,
                                                "no-data-text":
                                                  _vm.no_contact_folders_data,
                                                placeholder:
                                                  "Type to search for a folder",
                                              },
                                              model: {
                                                value:
                                                  _vm.import_model
                                                    .contact_folder_id,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.import_model,
                                                    "contact_folder_id",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "import_model.contact_folder_id",
                                              },
                                            },
                                            _vm._l(
                                              _vm.contact_folders,
                                              function (folder) {
                                                return _c("el-option", {
                                                  key: folder.id,
                                                  attrs: {
                                                    label: folder.name,
                                                    value: folder.id,
                                                  },
                                                })
                                              }
                                            ),
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  : _vm._e(),
                              ],
                              2
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "col-md-6" },
                              [
                                !_vm.import_model.is_dnc
                                  ? _c(
                                      "el-form-item",
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "form-label",
                                            attrs: { scope: "label" },
                                          },
                                          [
                                            _c("label", [
                                              _vm._v("Assign contacts to"),
                                            ]),
                                            _vm._v(" "),
                                            _c("small", [
                                              _vm._v(
                                                "Distribute the leads to:"
                                              ),
                                            ]),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-tabs",
                                          {
                                            model: {
                                              value: _vm.tab_name,
                                              callback: function ($$v) {
                                                _vm.tab_name = $$v
                                              },
                                              expression: "tab_name",
                                            },
                                          },
                                          [
                                            _c(
                                              "el-tab-pane",
                                              {
                                                attrs: {
                                                  label: "User",
                                                  "data-testid": "user-tab",
                                                  name: "user",
                                                },
                                              },
                                              [
                                                _c("small", [
                                                  _vm._v(
                                                    "Assign the leads to this user"
                                                  ),
                                                ]),
                                                _vm._v(" "),
                                                _c("user-selector", {
                                                  ref: "userSelector",
                                                  attrs: {
                                                    clearable: "",
                                                    "data-testid":
                                                      "assing-leads-user-tab-select",
                                                  },
                                                  on: {
                                                    change: _vm.changeUserId,
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.import_model.user_id,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.import_model,
                                                        "user_id",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "import_model.user_id",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "el-tab-pane",
                                              {
                                                attrs: {
                                                  label: "Ring Group",
                                                  "data-testid": "user-tab",
                                                  name: "ring_group",
                                                },
                                              },
                                              [
                                                _c("small", [
                                                  _vm._v(
                                                    "Assign the leads evenly and randomly between the users on this ring group"
                                                  ),
                                                ]),
                                                _vm._v(" "),
                                                _c("ring-group-selector", {
                                                  attrs: {
                                                    "data-testid":
                                                      "assing-leads-ring-group-tab-select",
                                                  },
                                                  on: {
                                                    change: _vm.ringGroupChange,
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.import_model
                                                        .ring_group_id,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.import_model,
                                                        "ring_group_id",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "import_model.ring_group_id",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                !_vm.import_model.is_dnc
                                  ? _c(
                                      "el-form-item",
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "form-label",
                                            attrs: { scope: "label" },
                                          },
                                          [
                                            _c("label", [_vm._v("Sequence")]),
                                            _vm._v(" "),
                                            _c("small", [
                                              _vm._v(
                                                "\n                                        Select sequence that you want to enroll these contacts to\n                                        "
                                              ),
                                              _c(
                                                "span",
                                                { staticClass: "text-muted" },
                                                [_vm._v("(optional)")]
                                              ),
                                            ]),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c("workflow-selector", {
                                          ref: "workflowSelector",
                                          attrs: {
                                            clearable: "",
                                            "data-testid":
                                              "work-flow-select-step-five",
                                          },
                                          on: {
                                            change: _vm.changeImportingWorkflow,
                                          },
                                          model: {
                                            value: _vm.import_model.workflow_id,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.import_model,
                                                "workflow_id",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "import_model.workflow_id",
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                !_vm.import_model.is_dnc
                                  ? _c(
                                      "el-form-item",
                                      { staticClass: "mb-1" },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "form-label",
                                            attrs: { scope: "label" },
                                          },
                                          [
                                            _c("label", [_vm._v("Tags")]),
                                            _vm._v(" "),
                                            _c("small", [
                                              _vm._v(
                                                "\n                                        Select tags that you want to assign to this contacts\n                                        "
                                              ),
                                              _c(
                                                "span",
                                                { staticClass: "text-muted" },
                                                [_vm._v("(optional)")]
                                              ),
                                            ]),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c("tag-selector", {
                                          ref: "tagSelector",
                                          attrs: {
                                            category_prop:
                                              _vm.TagCategory.CAT_CONTACTS,
                                            multiple: true,
                                            clearable: "",
                                            "data-testid":
                                              "tag-select-step-five",
                                          },
                                          on: {
                                            change: function ($event) {
                                              return _vm.changeTags(
                                                $event,
                                                _vm.import_model
                                              )
                                            },
                                          },
                                          model: {
                                            value: _vm.import_model.tag_ids,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.import_model,
                                                "tag_ids",
                                                $$v
                                              )
                                            },
                                            expression: "import_model.tag_ids",
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("tag-creator", {
                                          ref: "addTag",
                                          attrs: {
                                            category_prop:
                                              _vm.TagCategory.CAT_CONTACTS,
                                          },
                                          on: {
                                            success: _vm.addTagToImportContacts,
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value:
                                                  _vm.import_model.tag_ids
                                                    .length > 0,
                                                expression:
                                                  "import_model.tag_ids.length > 0",
                                              },
                                            ],
                                            staticClass: "row",
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "col-md-12" },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "form-label",
                                                    attrs: { scope: "label" },
                                                  },
                                                  [
                                                    _c("label", [
                                                      _vm._v("Split Tag"),
                                                    ]),
                                                    _vm._v(" "),
                                                    _c("small", [
                                                      _vm._v(
                                                        '\n                                                Splitting a tag would append " page-[:page_num]" to the original tag name, and create new tags accordingly.\n                                            '
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              { staticClass: "col-md-12 pr-4" },
                                              [
                                                _c("el-checkbox", {
                                                  staticClass:
                                                    "w-full break-word",
                                                  attrs: {
                                                    label:
                                                      "Do you want to split tag into smaller tag?",
                                                    "data-testid":
                                                      "split-tag-checkbox",
                                                    border: "",
                                                  },
                                                  model: {
                                                    value: _vm.split_tag.action,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.split_tag,
                                                        "action",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "split_tag.action",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                directives: [
                                                  {
                                                    name: "show",
                                                    rawName: "v-show",
                                                    value: _vm.split_tag.action,
                                                    expression:
                                                      "split_tag.action",
                                                  },
                                                ],
                                                staticClass: "col-md-5",
                                              },
                                              [
                                                _c(
                                                  "el-select",
                                                  {
                                                    staticClass: "w-full",
                                                    attrs: {
                                                      "data-testid":
                                                        "split-tag-select",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.split_tag.selected,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.split_tag,
                                                          "selected",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "split_tag.selected",
                                                    },
                                                  },
                                                  _vm._l(
                                                    _vm.split_tag.options,
                                                    function (item) {
                                                      return _c("el-option", {
                                                        key: item.value,
                                                        attrs: {
                                                          label: item.value,
                                                          value: item.value,
                                                        },
                                                      })
                                                    }
                                                  ),
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "form-label",
                                            attrs: { scope: "label" },
                                          },
                                          [
                                            _c("label", [_vm._v("List")]),
                                            _vm._v(" "),
                                            _c("small", [
                                              _vm._v(
                                                "\n                                        Select a list that you want to assign to this contacts\n                                    "
                                              ),
                                            ]),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c("contact-lists-selector", {
                                          ref: "contactListSelector",
                                          attrs: {
                                            use_first_list: false,
                                            user_id: _vm.auth.user.profile.id,
                                            list_type:
                                              _vm.ContactListTypes.STATIC_LIST,
                                            module_type:
                                              _vm.ContactListModuleTypes
                                                .CONTACT_LIST,
                                            "data-testid":
                                              "contact-list-select-step-five",
                                          },
                                          on: { change: _vm.changeContactList },
                                          model: {
                                            value:
                                              _vm.import_model.contact_list_id,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.import_model,
                                                "contact_list_id",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "import_model.contact_list_id",
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("contact-list-creator", {
                                          ref: "addContactList",
                                          on: { success: _vm.setContactList },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.is_rerun && _vm.import_model.rows > 0
                                  ? _c("el-form-item", [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "form-label",
                                          attrs: { scope: "label" },
                                        },
                                        [
                                          _c("label", [
                                            _vm._v("Restart or continue"),
                                          ]),
                                          _vm._v(" "),
                                          _c("small", [
                                            _vm._v(
                                              "\n                                        Do you want to start from the beginning of the file? Or continue at line\n                                        " +
                                                _vm._s(_vm.import_model.rows) +
                                                "?\n                                    "
                                            ),
                                          ]),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "row" }, [
                                        _c(
                                          "div",
                                          { staticClass: "col" },
                                          [
                                            _c(
                                              "el-radio",
                                              {
                                                staticClass: "w-full",
                                                attrs: {
                                                  name: "start_from_zero",
                                                  label: false,
                                                  "data-testid":
                                                    "start-from-zero-radio",
                                                  border: "",
                                                },
                                                model: {
                                                  value: _vm.start_from_zero,
                                                  callback: function ($$v) {
                                                    _vm.start_from_zero = $$v
                                                  },
                                                  expression: "start_from_zero",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                            Continue\n                                        "
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "col" },
                                          [
                                            _c(
                                              "el-radio",
                                              {
                                                staticClass: "w-full",
                                                attrs: {
                                                  name: "start_from_zero",
                                                  label: true,
                                                  "data-testid":
                                                    "start-from-beginning-radio",
                                                  border: "",
                                                },
                                                model: {
                                                  value: _vm.start_from_zero,
                                                  callback: function ($$v) {
                                                    _vm.start_from_zero = $$v
                                                  },
                                                  expression: "start_from_zero",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                            Start from beginning\n                                        "
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ]),
                                    ])
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.currentStep === 5,
                      expression: "currentStep === 5",
                    },
                  ],
                },
                [
                  _c("div", { staticClass: "container-import-summary" }, [
                    _c("h2", [
                      _c("i", { staticClass: "fa fa-check" }),
                      _vm._v(
                        "\n                        Importing contacts...\n                    "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("i", { staticClass: "fas fa-file-import" }),
                    _vm._v(" "),
                    _c("p", { staticClass: "lead" }, [
                      _vm._v("This might take a moment. "),
                    ]),
                    _vm._v(" "),
                    _c("p", { staticClass: "lead" }, [
                      _vm._v(
                        "\n                        You can see the progress of the import in Account » Imports page.\n                    "
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "text-center" },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { "data-testid": "close-wizard-step-6" },
                            on: { click: _vm.closeImportWizard },
                          },
                          [
                            _vm._v(
                              "\n                            Close\n                        "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _vm.$route.name !== "Account"
                          ? _c(
                              "router-link",
                              {
                                attrs: {
                                  to: {
                                    name: "Account",
                                    query: { tab: "imports" },
                                  },
                                },
                              },
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: {
                                      type: "success",
                                      "data-testid": "go-to-account-button",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                                Go to Account » Imports\n                            "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]),
                ]
              ),
              _vm._v(" "),
              _vm.currentStep < 5
                ? _c(
                    "div",
                    { staticClass: "d-flex pt-3" },
                    [
                      _c(
                        "el-button",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.showBackButton,
                              expression: "showBackButton",
                            },
                          ],
                          attrs: { "data-testid": "import-wizard-back-button" },
                          on: { click: _vm.back },
                        },
                        [
                          _c("i", { staticClass: "fa fa-chevron-left" }),
                          _vm._v(
                            "\n                    Back\n                "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "ml-auto" },
                        [
                          _vm.currentStep === 0 ||
                          _vm.currentStep === 2 ||
                          _vm.currentStep === 3
                            ? _c(
                                "el-button",
                                {
                                  attrs: {
                                    disabled: !_vm.stepIsValid,
                                    type: "success",
                                    "data-testid":
                                      "import-wizard-nest-step-button",
                                  },
                                  on: { click: _vm.next },
                                },
                                [
                                  _c("strong", [
                                    _vm._v("Next Step "),
                                    _c("i", {
                                      staticClass: "fa fa-chevron-right",
                                    }),
                                  ]),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.currentStep === 4
                            ? _c(
                                "el-button",
                                {
                                  staticClass: "px-4",
                                  attrs: {
                                    type: "success",
                                    "data-testid":
                                      "import-wizard-start-import-button",
                                  },
                                  on: { click: _vm.startImport },
                                },
                                [
                                  _c("strong", [
                                    _c("i", {
                                      staticClass: "fas fa-file-import",
                                    }),
                                    _vm._v(" Start Import"),
                                  ]),
                                ]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ]
          ),
          _vm._v(" "),
          _c(
            "el-dialog",
            {
              staticClass: "import-wizard dialog-no-border",
              attrs: {
                width: "40vw",
                visible: _vm.show_dnc_scrubbing_info_dialog,
                "append-to-body": "",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.show_dnc_scrubbing_info_dialog = $event
                },
                close: function ($event) {
                  _vm.show_dnc_scrubbing_info_dialog = false
                },
              },
            },
            [
              _c("div", { attrs: { slot: "title" }, slot: "title" }, [
                _c("h5", { staticClass: "mb-0" }, [
                  _vm._v(
                    "\n                    DNC Scrubbing Service Details\n                "
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("div", [
                _c("p", [
                  _vm._v(
                    "A contact representative will contact you or your team shortly."
                  ),
                ]),
              ]),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }