<template>
    <div class="row no-gutter centered-content"
         :class="[ no_padding ? '' : 'pt-2 pl-2 pr-2' ]">
        <div class="col-md-12 text-center">
            <button class="btn btn-xs greenish px-4"
                    :disabled="pushed"
                    data-testid="push-contact-to-crm-buttom"
                    @click="pushToCrm">
                <i class="el-icon-loading"
                   v-if="showLoading"></i>
                {{ showLoading ? 'Please wait..' : 'Push to CRM' }}
            </button>
        </div>
    </div>
</template>

<script>
import auth from '../auth'

export default {
    props: {
        contact: {
            required: true
        },

        no_padding: {
            type: Boolean,
            required: false,
            default: false
        },
    },

    data() {
        return {
            auth: auth,
            pushed: false,
            showLoading: false
        }
    },

    methods: {
        pushToCrm() {
            this.showLoading = true
            axios.post(`/api/v1/contact/${this.contact.id}/push-to-crm`).then(response => {
                if (response.data.status === 'success') {
                    this.pushed = true
                    this.showLoading = false
                    this.$notify({
                        offset: 95,
                        title: 'Contact',
                        message: response.data.message,
                        type: 'success',
                        showClose: true
                    })
                }
            }).catch(err => {
                this.$root.handleErrors(err.response)
            }).then(() => {
                this.showLoading = false
            })
        }
    },

    watch: {
        contact() {
            if (this.contact) {
                this.pushed = false
            }
        }
    }
}
</script>
