<template>
    <div v-if="user">
        <p>You have a new message</p>
        <p>Message: {{ message }}</p>
    </div>
</template>

<script>
    import auth from '../auth'
    import {acl_mixin, html_mixin} from '../mixins'
    import {mapState} from 'vuex'

    export default {
        mixins: [acl_mixin, html_mixin],

        props: [
            'user',
            'message'
        ],

        data() {
            return {
                auth: auth
            }
        },

        computed: {
            ...mapState(['campaigns']),
            ...mapState('cache', ['current_company']),
        }
    }
</script>
