<template>
    <div>
        <div class="col-12">
            <p class="font-weight-bold side-panel-title">Escalation Panel</p>
        </div>

        <div class="col-12 mt-2">
            <p class="mb-0 font-weight-bold">Type:</p>
            <div>
                <el-radio-group v-model="nodeData.type">
                    <el-radio-button :label="ESCALATION_TYPE_SEQUENCE">Sequence</el-radio-button>
                    <el-radio-button :label="ESCALATION_TYPE_SMS">SMS</el-radio-button>
                </el-radio-group>
            </div>
        </div>

        <div v-if="nodeData.type === ESCALATION_TYPE_SEQUENCE"
            class="col-12 mt-4">
            <p class="mb-0 font-weight-bold">Select a Workflow:</p>
            <el-select
                v-model="nodeData.id"
                filterable
                placeholder="Sequence">

                <el-option
                    v-for="workflow in workflows"
                    v-bind:key="workflow.id"
                    :label="workflow.name"
                    :value="workflow.id"/>

            </el-select>
        </div>

        <div v-if="nodeData.type === ESCALATION_TYPE_SMS"
            class="col-12 mt-4">
            <p class="mb-0 font-weight-bold">Numbers:</p>

            <el-tag
                class="font-weight-bold"
                :key="`${number}`"
                v-for="number in nodeData.numbers"
                closable
                :disable-transitions="false"
                @close="handleClose(number)">
                {{ number }}
            </el-tag>

            <el-input
                v-if="inputVisible"
                class="input-new-tag"
                v-model="inputValue"
                ref="saveTagInput"
                size="mini"
                @keyup.enter.native="handleInputConfirm"
                @blur="handleInputConfirm"/>

            <el-button
                v-else
                class="button-new-tag"
                size="small"
                @click="showInput">
                + Add Number
            </el-button>
        </div>

        <div v-if="nodeData.type === ESCALATION_TYPE_SMS"
            class="col-12 mt-4">
            <p class="mb-0 font-weight-bold">Message:</p>

            <el-input
                type="textarea"
                placeholder="Type the escalation message here."
                :rows="3"
                v-model="nodeData.message">
            </el-input>

            <div class="col-12 text-right">
                <el-tooltip
                    content="Add variable"
                    placement="top">
                    <Variables @variable-selected="variableSelected"/>
                </el-tooltip>
            </div>
        </div>
    </div>
</template>

<script>
import { alohabot_side_panel_mixin } from '../../../mixins'
import { mapState } from 'vuex'
import Variables from '../../messenger/variables'

const ESCALATION_TYPE_SEQUENCE = 1
const ESCALATION_TYPE_SMS = 2

export default {
    name: 'escalation-panel',
    mixins: [
        alohabot_side_panel_mixin
    ],
    components: {
        Variables
    },
    data() {
        return {
            inputVisible: false,
            inputValue: '',
            ESCALATION_TYPE_SEQUENCE,
            ESCALATION_TYPE_SMS
        }
    },
    methods: {
        /**
         * Deletes number from SMS type.
         *
         * @param {string} number Number to remove
         *
         * @return {void}
         */
        handleClose(number) {
            this.nodeData.numbers.splice(this.nodeData.numbers.indexOf(number), 1)
        },

        /**
         * Shows and focus numbers input.
         *
         * @return {void}
         */
        showInput() {
            this.inputVisible = true
            this.$nextTick(_ => {
                this.$refs.saveTagInput.$refs.input.focus()
            })
        },

        /**
         * Adds number to current numbers stack to send SMS.
         *
         * @return {void}
         */
        handleInputConfirm() {
            let inputValue = this.inputValue
            if (inputValue) {
                if (!this.nodeData.numbers) {
                    this.nodeData.numbers = []
                }
                this.nodeData.numbers.push(inputValue)
            }
            this.inputVisible = false
            this.inputValue = ''
        },

        /**
         * Selects text variable
         *
         * @param {string} variable
         *
         * @return {void}
         */
        variableSelected(variable) {
            this.nodeData.message = (this.nodeData.message ?? '') + ' ' + variable
        },
    },
    computed: {
        ...mapState(['workflows']),
    }
}
</script>
