var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.contact
    ? _c(
        "div",
        { staticClass: "row no-gutter" },
        [
          _vm._l(_vm.ring_group_ids, function (ring_group_id) {
            return _c(
              "div",
              { staticClass: "text-center mr-2 mb-2" },
              [
                ring_group_id
                  ? _c(
                      "router-link",
                      {
                        attrs: {
                          to: {
                            name: "Ring Group Activity",
                            params: { ring_group_id: ring_group_id },
                          },
                        },
                      },
                      [
                        _vm.getRingGroup(ring_group_id)
                          ? _c(
                              "a",
                              {
                                staticClass: "btn btn-xs bluish",
                                attrs: {
                                  "data-testid": "contact-ring-group-link",
                                },
                              },
                              [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(
                                      _vm.getRingGroup(ring_group_id).name
                                    ) +
                                    "\n            "
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]
                    )
                  : _vm._e(),
              ],
              1
            )
          }),
          _vm._v(" "),
          _vm.hasRole("Company Admin") ||
          (_vm.hasRole("Company Agent") &&
            _vm.hasPermissionTo("modify contact ring groups"))
            ? _c(
                "div",
                { staticClass: "col-12" },
                [
                  _c(
                    "el-popover",
                    {
                      ref: "popover_ring_groups",
                      attrs: {
                        title: "Ring Groups:",
                        placement: "left",
                        width: "400",
                        "data-testid": "contact-ring-group-popover",
                        trigger: "click",
                      },
                    },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: {
                            slot: "reference",
                            "data-testid": "add-modify-ring-groups-button",
                            type: "text text-dark-greenish text-sm p-0",
                          },
                          slot: "reference",
                        },
                        [
                          _c("i", {
                            staticClass: "el-icon-circle-plus-outline",
                          }),
                          _vm._v(" "),
                          _vm.new_ring_group_ids.length === 0
                            ? _c("span", [_vm._v("Add Ring Groups")])
                            : _c("span", [_vm._v("Modify Ring Groups")]),
                        ]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "row" }, [
                        _c(
                          "div",
                          { staticClass: "col-12" },
                          [
                            _vm.hasRole("Company Admin") ||
                            (_vm.hasRole("Company Agent") &&
                              _vm.hasPermissionTo("modify contact ring groups"))
                              ? _c(
                                  "el-form",
                                  {
                                    attrs: {
                                      "data-testid": "contact-ring-groups-form",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-form-item",
                                      { attrs: { prop: "ring_group" } },
                                      [
                                        _c(
                                          "el-select",
                                          {
                                            staticClass: "d-block",
                                            attrs: {
                                              placeholder: "Select ring groups",
                                              multiple: "",
                                              "data-testid":
                                                "contact-ring-groups-select",
                                            },
                                            on: {
                                              change: function ($event) {
                                                return _vm.changeRingGroups(
                                                  $event,
                                                  _vm.new_ring_group_ids
                                                )
                                              },
                                            },
                                            model: {
                                              value: _vm.new_ring_group_ids,
                                              callback: function ($$v) {
                                                _vm.new_ring_group_ids = $$v
                                              },
                                              expression: "new_ring_group_ids",
                                            },
                                          },
                                          _vm._l(
                                            _vm.ringGroupOptions,
                                            function (item) {
                                              return _c("el-option", {
                                                key: item.value,
                                                attrs: {
                                                  label: item.label,
                                                  value: item.value,
                                                },
                                              })
                                            }
                                          ),
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }