<template>
  <div>
    <div v-if="contact">
      <p>You have been assigned to a contact.</p>
      <p>Name: {{ contact.name }}</p>
      <p>Phone number: {{ contact.phone_number | fixPhone }}</p>

      <div>
        <button class="btn rounded greenish btn-contact btn-sm mt-2 pull-right">
          Go To Contact
        </button>
      </div>
    </div>
    <div v-if="massAssign">
      <p>You have been assigned to a group of contacts.</p>

      <div>
        <button class="btn rounded greenish btn-contacts btn-sm mt-2 pull-right">
          Go To Contacts
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import auth from '../auth'
import {acl_mixin, html_mixin} from '../mixins'
import {mapState} from 'vuex'

export default {
  mixins: [acl_mixin, html_mixin],

  props: {
    contact: {
      type: Object,
      required: false
    },
    massAssign: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  data() {
    return {
      auth: auth
    }
  },

  computed: {
    ...mapState(['campaigns']),
    ...mapState('cache', ['current_company']),
  }
}
</script>
