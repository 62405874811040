var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.is_loading
    ? _c(
        "div",
        { staticClass: "app-body m-h-v", attrs: { id: "view" } },
        [
          _vm.shouldShowPowerDialer
            ? [
                _c(
                  "el-dialog",
                  {
                    attrs: {
                      title: _vm.scriptDialogTitle,
                      visible: _vm.showScriptDialog,
                      "before-close": _vm.hideScript,
                      "append-to-body": "",
                      width: "50%",
                    },
                    on: {
                      "update:visible": function ($event) {
                        _vm.showScriptDialog = $event
                      },
                    },
                  },
                  [
                    _vm.selected_script
                      ? _c("span", {
                          domProps: {
                            innerHTML: _vm._s(_vm.selected_script.text),
                          },
                        })
                      : _vm._e(),
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "padding full-height" }, [
                  _c("div", { staticClass: "row flex-nowrap full-height" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.task_list_open,
                            expression: "task_list_open",
                          },
                        ],
                        staticClass: "col-md-3 full-height auto-dialer-sidebar",
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "box full-height no-box-shadow" },
                          [
                            _c(
                              "el-collapse",
                              {
                                staticClass: "fullscreen",
                                attrs: { accordion: "" },
                                model: {
                                  value: _vm.activeName,
                                  callback: function ($$v) {
                                    _vm.activeName = $$v
                                  },
                                  expression: "activeName",
                                },
                              },
                              [
                                _c(
                                  "el-collapse-item",
                                  { attrs: { name: "in-progress" } },
                                  [
                                    _c("template", { slot: "title" }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "font-120 w-100 d-flex align-items-center justify-content-between",
                                        },
                                        [
                                          _c(
                                            "span",
                                            { staticClass: "pull-left" },
                                            [_vm._v("In-Progress")]
                                          ),
                                          _vm._v(" "),
                                          _vm.pagination_in_progress_tasks
                                            .total !== undefined
                                            ? _c(
                                                "b",
                                                {
                                                  staticClass:
                                                    "label label-md blue m-l-xs pull-right m-r-sm",
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                            " +
                                                      _vm._s(
                                                        _vm
                                                          .pagination_in_progress_tasks
                                                          .total
                                                      ) +
                                                      "\n                                        "
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value:
                                              !_vm.pagination_in_progress_tasks_loading &&
                                              _vm.in_progress_tasks.length == 0,
                                            expression:
                                              "!pagination_in_progress_tasks_loading && in_progress_tasks.length == 0",
                                          },
                                        ],
                                        staticClass:
                                          "el-table__empty-block full-height",
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "text-lg _700 text-greyish",
                                          },
                                          [
                                            _vm._v(
                                              "\n                                        No Tasks\n                                    "
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _vm._l(
                                      _vm.in_progress_tasks,
                                      function (task, index) {
                                        return _c(
                                          "div",
                                          {
                                            key: index,
                                            staticClass:
                                              "d-flex align-items-center pointer p-t-sm p-b-sm",
                                            class: [
                                              index <=
                                              _vm.in_progress_tasks.length - 2
                                                ? "b-b"
                                                : "",
                                            ],
                                            on: {
                                              click: function ($event) {
                                                return _vm.selectTask(task)
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "w-40 avatar grey-300",
                                                style: _vm.avatarStyle(
                                                  task.contact.name
                                                ),
                                              },
                                              [
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm._f("initials")(
                                                        _vm._f(
                                                          "fixContactName"
                                                        )(task.contact.name)
                                                      )
                                                    )
                                                  ),
                                                ]),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "ml-2 allow-select",
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "d-block font-weight-bold",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm._f(
                                                          "fixContactName"
                                                        )(task.contact.name)
                                                      )
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "span",
                                                  { staticClass: "d-block" },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm._f("fixPhone")(
                                                          task.phone_number
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                task.contact
                                                  .disposition_status_id
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "d-block text-xxs",
                                                      },
                                                      [
                                                        task.contact
                                                          .disposition_status_id
                                                          ? _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "text-greyish",
                                                              },
                                                              [
                                                                _c(
                                                                  "i",
                                                                  {
                                                                    staticClass:
                                                                      "material-icons",
                                                                    style: {
                                                                      color:
                                                                        _vm.$options.filters.dispositionStatusColor(
                                                                          task
                                                                            .contact
                                                                            .disposition_status_id
                                                                        ),
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "label"
                                                                    ),
                                                                  ]
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "text-grey-900",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm._f(
                                                                          "dispositionStatusName"
                                                                        )(
                                                                          task
                                                                            .contact
                                                                            .disposition_status_id
                                                                        )
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                task.contact.company_name
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "d-block text-pink-500 text-xxs",
                                                      },
                                                      [
                                                        _c(
                                                          "i",
                                                          {
                                                            staticClass:
                                                              "material-icons",
                                                          },
                                                          [
                                                            _vm._v(
                                                              "business_center"
                                                            ),
                                                          ]
                                                        ),
                                                        _vm._v(
                                                          "\n                                            " +
                                                            _vm._s(
                                                              task.contact
                                                                .company_name
                                                            ) +
                                                            "\n                                        "
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                task.contact.timezone
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "d-block text-indigo-500 text-xxs",
                                                      },
                                                      [
                                                        _c(
                                                          "i",
                                                          {
                                                            staticClass:
                                                              "material-icons",
                                                          },
                                                          [_vm._v("public")]
                                                        ),
                                                        _vm._v(
                                                          "\n                                            " +
                                                            _vm._s(
                                                              task.contact
                                                                .timezone
                                                            ) +
                                                            "\n                                        "
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  { staticClass: "d-block" },
                                                  [
                                                    _vm.isSkipped(task.id)
                                                      ? _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "badge badge-warning",
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                                                Skipped\n                                            "
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              { staticClass: "ml-auto" },
                                              [
                                                _c(
                                                  "button",
                                                  {
                                                    staticClass:
                                                      "btn btn-sm btn-outline-danger pull-right",
                                                    on: {
                                                      click: function ($event) {
                                                        $event.preventDefault()
                                                        return _vm.skipTask.apply(
                                                          null,
                                                          arguments
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _vm.loading_skip
                                                      ? _c("i", {
                                                          staticClass:
                                                            "fa fa-spinner fa-spin",
                                                        })
                                                      : _c("i", {
                                                          staticClass:
                                                            "fa fa-times",
                                                        }),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        )
                                      }
                                    ),
                                    _vm._v(" "),
                                    _vm.hasMoreInProgressTasks
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "d-flex align-items-center justify-content-center p-t-sm p-b-sm",
                                          },
                                          [
                                            _c(
                                              "el-button",
                                              {
                                                attrs: {
                                                  type: "primary",
                                                  loading:
                                                    _vm.pagination_in_progress_tasks_loading,
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.changePage(
                                                      _vm.AutoDialTaskStatus
                                                        .STATUS_IN_PROGRESS,
                                                      _vm
                                                        .pagination_in_progress_tasks
                                                        .current_page + 1
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                        Load More\n                                    "
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ],
                                  2
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-collapse-item",
                                  { attrs: { name: "queued" } },
                                  [
                                    _c("template", { slot: "title" }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "font-120 w-100 d-flex align-items-center justify-content-between",
                                        },
                                        [
                                          _c(
                                            "span",
                                            { staticClass: "pull-left" },
                                            [_vm._v("Queued")]
                                          ),
                                          _vm._v(" "),
                                          _vm.pagination_queued_tasks.total !==
                                          undefined
                                            ? _c(
                                                "b",
                                                {
                                                  staticClass:
                                                    "label label-md warn m-l-xs pull-right m-r-sm",
                                                },
                                                [
                                                  !_vm.loading_queue
                                                    ? _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm
                                                              .pagination_queued_tasks
                                                              .total
                                                          )
                                                        ),
                                                      ])
                                                    : _c(
                                                        "i",
                                                        {
                                                          staticClass:
                                                            "material-icons loader",
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                                                \n                                            "
                                                          ),
                                                        ]
                                                      ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value:
                                              !_vm.pagination_queued_tasks_loading &&
                                              _vm.queued_tasks.length == 0,
                                            expression:
                                              "!pagination_queued_tasks_loading && queued_tasks.length == 0",
                                          },
                                        ],
                                        staticClass:
                                          "el-table__empty-block full-height",
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "text-lg _700 text-greyish",
                                          },
                                          [
                                            _vm._v(
                                              "\n                                        No Tasks\n                                    "
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _vm._l(
                                      _vm.queued_tasks,
                                      function (task, index) {
                                        return _c(
                                          "div",
                                          {
                                            key: index,
                                            staticClass:
                                              "d-flex align-items-center p-t-sm p-b-sm",
                                            class: [
                                              index <=
                                              _vm.queued_tasks.length - 2
                                                ? "b-b"
                                                : "",
                                            ],
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "w-40 avatar grey-300",
                                                style: _vm.avatarStyle(
                                                  task.contact.name
                                                ),
                                              },
                                              [
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm._f("initials")(
                                                        _vm._f(
                                                          "fixContactName"
                                                        )(task.contact.name)
                                                      )
                                                    )
                                                  ),
                                                ]),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "ml-2 allow-select",
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "d-block font-weight-bold",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm._f(
                                                          "fixContactName"
                                                        )(task.contact.name)
                                                      )
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "span",
                                                  { staticClass: "d-block" },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm._f("fixPhone")(
                                                          task.phone_number
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                task.contact
                                                  .disposition_status_id
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "d-block text-xxs",
                                                      },
                                                      [
                                                        task.contact
                                                          .disposition_status_id
                                                          ? _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "text-greyish",
                                                              },
                                                              [
                                                                _c(
                                                                  "i",
                                                                  {
                                                                    staticClass:
                                                                      "material-icons",
                                                                    style: {
                                                                      color:
                                                                        _vm.$options.filters.dispositionStatusColor(
                                                                          task
                                                                            .contact
                                                                            .disposition_status_id
                                                                        ),
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "label"
                                                                    ),
                                                                  ]
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "text-grey-900",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm._f(
                                                                          "dispositionStatusName"
                                                                        )(
                                                                          task
                                                                            .contact
                                                                            .disposition_status_id
                                                                        )
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                task.contact.company_name
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "d-block text-pink-500 text-xxs",
                                                      },
                                                      [
                                                        _c(
                                                          "i",
                                                          {
                                                            staticClass:
                                                              "material-icons",
                                                          },
                                                          [
                                                            _vm._v(
                                                              "business_center"
                                                            ),
                                                          ]
                                                        ),
                                                        _vm._v(
                                                          "\n                                            " +
                                                            _vm._s(
                                                              task.contact
                                                                .company_name
                                                            ) +
                                                            "\n                                        "
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                task.contact.timezone
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "d-block text-indigo-500 text-xxs",
                                                      },
                                                      [
                                                        _c(
                                                          "i",
                                                          {
                                                            staticClass:
                                                              "material-icons",
                                                          },
                                                          [_vm._v("public")]
                                                        ),
                                                        _vm._v(
                                                          "\n                                            " +
                                                            _vm._s(
                                                              task.contact
                                                                .timezone
                                                            ) +
                                                            "\n                                        "
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  { staticClass: "d-block" },
                                                  [
                                                    _vm.isSkipped(task.id)
                                                      ? _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "badge badge-warning",
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                                                Skipped\n                                            "
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              { staticClass: "ml-auto" },
                                              [
                                                !_vm.masterControl
                                                  ? _c(
                                                      "button",
                                                      {
                                                        staticClass:
                                                          "btn btn-sm btn-outline-danger pull-right",
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            $event.preventDefault()
                                                            return _vm.deleteTask(
                                                              task
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _vm.loading_delete &&
                                                        _vm.loading_delete ==
                                                          task.id
                                                          ? _c("i", {
                                                              staticClass:
                                                                "fa fa-spinner fa-spin",
                                                            })
                                                          : _c("i", {
                                                              staticClass:
                                                                "fa fa-trash",
                                                            }),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                              ]
                                            ),
                                          ]
                                        )
                                      }
                                    ),
                                    _vm._v(" "),
                                    _vm.hasMoreQueuedTasks
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "d-flex align-items-center justify-content-center p-t-sm p-b-sm",
                                          },
                                          [
                                            _c(
                                              "el-button",
                                              {
                                                attrs: {
                                                  type: "primary",
                                                  loading:
                                                    _vm.pagination_queued_tasks_loading,
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.changePage(
                                                      _vm.AutoDialTaskStatus
                                                        .STATUS_QUEUED,
                                                      _vm
                                                        .pagination_queued_tasks
                                                        .current_page + 1
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                        Load More\n                                    "
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ],
                                  2
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-collapse-item",
                                  { attrs: { name: "completed" } },
                                  [
                                    _c("template", { slot: "title" }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "font-120 w-100 d-flex align-items-center justify-content-between",
                                        },
                                        [
                                          _c(
                                            "span",
                                            { staticClass: "pull-left" },
                                            [_vm._v("Completed")]
                                          ),
                                          _vm._v(" "),
                                          _vm.pagination_completed_tasks
                                            .total !== undefined
                                            ? _c(
                                                "b",
                                                {
                                                  staticClass:
                                                    "label label-md success m-l-xs pull-right m-r-sm",
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                            " +
                                                      _vm._s(
                                                        _vm
                                                          .pagination_completed_tasks
                                                          .total
                                                      ) +
                                                      "\n                                        "
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value:
                                              !_vm.pagination_completed_tasks_loading &&
                                              _vm.completed_tasks.length == 0,
                                            expression:
                                              "!pagination_completed_tasks_loading && completed_tasks.length == 0",
                                          },
                                        ],
                                        staticClass:
                                          "el-table__empty-block full-height",
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "text-lg _700 text-greyish",
                                          },
                                          [
                                            _vm._v(
                                              "\n                                        No Tasks\n                                    "
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _vm._l(
                                      _vm.completed_tasks,
                                      function (task, index) {
                                        return _c(
                                          "div",
                                          {
                                            key: index,
                                            staticClass:
                                              "d-flex align-items-center p-t-sm p-b-sm",
                                            class: [
                                              index <=
                                              _vm.completed_tasks.length - 2
                                                ? "b-b"
                                                : "",
                                            ],
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "w-40 avatar grey-300",
                                                style: _vm.avatarStyle(
                                                  task.contact.name
                                                ),
                                              },
                                              [
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm._f("initials")(
                                                        _vm._f(
                                                          "fixContactName"
                                                        )(task.contact.name)
                                                      )
                                                    )
                                                  ),
                                                ]),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "ml-2 allow-select",
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "d-block font-weight-bold",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm._f(
                                                          "fixContactName"
                                                        )(task.contact.name)
                                                      )
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "span",
                                                  { staticClass: "d-block" },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm._f("fixPhone")(
                                                          task.phone_number
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                task.contact
                                                  .disposition_status_id
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "d-block text-xxs",
                                                      },
                                                      [
                                                        task.contact
                                                          .disposition_status_id
                                                          ? _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "text-greyish",
                                                              },
                                                              [
                                                                _c(
                                                                  "i",
                                                                  {
                                                                    staticClass:
                                                                      "material-icons",
                                                                    style: {
                                                                      color:
                                                                        _vm.$options.filters.dispositionStatusColor(
                                                                          task
                                                                            .contact
                                                                            .disposition_status_id
                                                                        ),
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "label"
                                                                    ),
                                                                  ]
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "text-grey-900",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm._f(
                                                                          "dispositionStatusName"
                                                                        )(
                                                                          task
                                                                            .contact
                                                                            .disposition_status_id
                                                                        )
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                task.contact.company_name
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "d-block text-pink-500 text-xxs",
                                                      },
                                                      [
                                                        _c(
                                                          "i",
                                                          {
                                                            staticClass:
                                                              "material-icons",
                                                          },
                                                          [
                                                            _vm._v(
                                                              "business_center"
                                                            ),
                                                          ]
                                                        ),
                                                        _vm._v(
                                                          "\n                                            " +
                                                            _vm._s(
                                                              task.contact
                                                                .company_name
                                                            ) +
                                                            "\n                                        "
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                task.contact.timezone
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "d-block text-indigo-500 text-xxs",
                                                      },
                                                      [
                                                        _c(
                                                          "i",
                                                          {
                                                            staticClass:
                                                              "material-icons",
                                                          },
                                                          [_vm._v("public")]
                                                        ),
                                                        _vm._v(
                                                          "\n                                            " +
                                                            _vm._s(
                                                              task.contact
                                                                .timezone
                                                            ) +
                                                            "\n                                        "
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              { staticClass: "ml-auto" },
                                              [
                                                !_vm.masterControl
                                                  ? _c(
                                                      "button",
                                                      {
                                                        staticClass:
                                                          "btn btn-sm btn-outline-danger pull-right",
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            $event.preventDefault()
                                                            return _vm.deleteTask(
                                                              task
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _vm.loading_delete &&
                                                        _vm.loading_delete ==
                                                          task.id
                                                          ? _c("i", {
                                                              staticClass:
                                                                "fa fa-spinner fa-spin",
                                                            })
                                                          : _c("i", {
                                                              staticClass:
                                                                "fa fa-trash",
                                                            }),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                              ]
                                            ),
                                          ]
                                        )
                                      }
                                    ),
                                    _vm._v(" "),
                                    _vm.hasMoreCompletedTasks
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "d-flex align-items-center justify-content-center p-t-sm p-b-sm",
                                          },
                                          [
                                            _c(
                                              "el-button",
                                              {
                                                attrs: {
                                                  type: "primary",
                                                  loading:
                                                    _vm.pagination_completed_tasks_loading,
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.changePage(
                                                      _vm.AutoDialTaskStatus
                                                        .STATUS_COMPLETED,
                                                      _vm
                                                        .pagination_completed_tasks
                                                        .current_page + 1
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                        Load More\n                                    "
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ],
                                  2
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-collapse-item",
                                  { attrs: { name: "failed" } },
                                  [
                                    _c("template", { slot: "title" }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "font-120 w-100 d-flex align-items-center justify-content-between",
                                        },
                                        [
                                          _c(
                                            "span",
                                            { staticClass: "pull-left" },
                                            [_vm._v("Failed")]
                                          ),
                                          _vm._v(" "),
                                          _vm.pagination_failed_tasks.total !==
                                          undefined
                                            ? _c(
                                                "b",
                                                {
                                                  staticClass:
                                                    "label label-md danger m-l-xs pull-right m-r-sm",
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                            " +
                                                      _vm._s(
                                                        _vm
                                                          .pagination_failed_tasks
                                                          .total
                                                      ) +
                                                      "\n                                        "
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value:
                                              !_vm.pagination_failed_tasks_loading &&
                                              _vm.failed_tasks.length == 0,
                                            expression:
                                              "!pagination_failed_tasks_loading && failed_tasks.length == 0",
                                          },
                                        ],
                                        staticClass:
                                          "el-table__empty-block full-height",
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "text-lg _700 text-greyish",
                                          },
                                          [
                                            _vm._v(
                                              "\n                                        No Tasks\n                                    "
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _vm._l(
                                      _vm.failed_tasks,
                                      function (task, index) {
                                        return _c(
                                          "div",
                                          {
                                            key: index,
                                            staticClass:
                                              "d-flex align-items-center p-t-sm p-b-sm",
                                            class: [
                                              index <=
                                              _vm.failed_tasks.length - 2
                                                ? "b-b"
                                                : "",
                                            ],
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "w-40 avatar grey-300",
                                                style: _vm.avatarStyle(
                                                  task.contact.name
                                                ),
                                              },
                                              [
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm._f("initials")(
                                                        _vm._f(
                                                          "fixContactName"
                                                        )(task.contact.name)
                                                      )
                                                    )
                                                  ),
                                                ]),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "ml-2 allow-select",
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "d-block font-weight-bold",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm._f(
                                                          "fixContactName"
                                                        )(task.contact.name)
                                                      )
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "span",
                                                  { staticClass: "d-block" },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm._f("fixPhone")(
                                                          task.phone_number
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                task.contact
                                                  .disposition_status_id
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "d-block text-xxs",
                                                      },
                                                      [
                                                        task.contact
                                                          .disposition_status_id
                                                          ? _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "text-greyish",
                                                              },
                                                              [
                                                                _c(
                                                                  "i",
                                                                  {
                                                                    staticClass:
                                                                      "material-icons",
                                                                    style: {
                                                                      color:
                                                                        _vm.$options.filters.dispositionStatusColor(
                                                                          task
                                                                            .contact
                                                                            .disposition_status_id
                                                                        ),
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "label"
                                                                    ),
                                                                  ]
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "text-grey-900",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm._f(
                                                                          "dispositionStatusName"
                                                                        )(
                                                                          task
                                                                            .contact
                                                                            .disposition_status_id
                                                                        )
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                task.contact.company_name
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "d-block text-pink-500 text-xxs",
                                                      },
                                                      [
                                                        _c(
                                                          "i",
                                                          {
                                                            staticClass:
                                                              "material-icons",
                                                          },
                                                          [
                                                            _vm._v(
                                                              "business_center"
                                                            ),
                                                          ]
                                                        ),
                                                        _vm._v(
                                                          "\n                                            " +
                                                            _vm._s(
                                                              task.contact
                                                                .company_name
                                                            ) +
                                                            "\n                                        "
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                task.contact.timezone
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "d-block text-indigo-500 text-xxs",
                                                      },
                                                      [
                                                        _c(
                                                          "i",
                                                          {
                                                            staticClass:
                                                              "material-icons",
                                                          },
                                                          [_vm._v("public")]
                                                        ),
                                                        _vm._v(
                                                          "\n                                            " +
                                                            _vm._s(
                                                              task.contact
                                                                .timezone
                                                            ) +
                                                            "\n                                        "
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              { staticClass: "ml-auto" },
                                              [
                                                !_vm.masterControl
                                                  ? _c(
                                                      "button",
                                                      {
                                                        staticClass:
                                                          "btn btn-sm btn-outline-danger pull-right",
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            $event.preventDefault()
                                                            return _vm.deleteTask(
                                                              task
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _vm.loading_delete &&
                                                        _vm.loading_delete ==
                                                          task.id
                                                          ? _c("i", {
                                                              staticClass:
                                                                "fa fa-spinner fa-spin",
                                                            })
                                                          : _c("i", {
                                                              staticClass:
                                                                "fa fa-trash",
                                                            }),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                              ]
                                            ),
                                          ]
                                        )
                                      }
                                    ),
                                    _vm._v(" "),
                                    _vm.hasMoreFailedTasks
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "d-flex align-items-center justify-content-center p-t-sm p-b-sm",
                                          },
                                          [
                                            _c(
                                              "el-button",
                                              {
                                                attrs: {
                                                  type: "primary",
                                                  loading:
                                                    _vm.pagination_failed_tasks_loading,
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.changePage(
                                                      _vm.AutoDialTaskStatus
                                                        .STATUS_FAILED,
                                                      _vm
                                                        .pagination_failed_tasks
                                                        .current_page + 1
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                        Load More\n                                    "
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ],
                                  2
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-collapse-item",
                                  { attrs: { name: "scheduled" } },
                                  [
                                    _c("template", { slot: "title" }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "font-120 w-100 d-flex align-items-center justify-content-between",
                                        },
                                        [
                                          _c(
                                            "span",
                                            { staticClass: "pull-left" },
                                            [_vm._v("Scheduled")]
                                          ),
                                          _vm._v(" "),
                                          _vm.pagination_scheduled_tasks
                                            .total !== undefined
                                            ? _c(
                                                "b",
                                                {
                                                  staticClass:
                                                    "label label-md purple m-l-xs pull-right m-r-sm",
                                                },
                                                [
                                                  !_vm.loading_schedule_task
                                                    ? _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm
                                                              .pagination_scheduled_tasks
                                                              .total
                                                          )
                                                        ),
                                                      ])
                                                    : _c(
                                                        "i",
                                                        {
                                                          staticClass:
                                                            "material-icons loader",
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                                                \n                                            "
                                                          ),
                                                        ]
                                                      ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value:
                                              !_vm.pagination_scheduled_tasks_loading &&
                                              _vm.scheduled_tasks.length == 0,
                                            expression:
                                              "!pagination_scheduled_tasks_loading && scheduled_tasks.length == 0",
                                          },
                                        ],
                                        staticClass:
                                          "el-table__empty-block full-height",
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "text-lg _700 text-greyish",
                                          },
                                          [
                                            _vm._v(
                                              "\n                                        No Tasks\n                                    "
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _vm._l(
                                      _vm.scheduled_tasks,
                                      function (task, index) {
                                        return _c(
                                          "div",
                                          {
                                            key: index,
                                            staticClass:
                                              "d-flex align-items-center p-t-sm p-b-sm",
                                            class: [
                                              index <=
                                              _vm.scheduled_tasks.length - 2
                                                ? "b-b"
                                                : "",
                                            ],
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "w-40 avatar grey-300",
                                                style: _vm.avatarStyle(
                                                  task.contact.name
                                                ),
                                              },
                                              [
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm._f("initials")(
                                                        _vm._f(
                                                          "fixContactName"
                                                        )(task.contact.name)
                                                      )
                                                    )
                                                  ),
                                                ]),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "ml-2 allow-select",
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "d-block font-weight-bold",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm._f(
                                                          "fixContactName"
                                                        )(task.contact.name)
                                                      )
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "span",
                                                  { staticClass: "d-block" },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm._f("fixPhone")(
                                                          task.phone_number
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                task.contact
                                                  .disposition_status_id
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "d-block text-xxs",
                                                      },
                                                      [
                                                        task.contact
                                                          .disposition_status_id
                                                          ? _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "text-greyish",
                                                              },
                                                              [
                                                                _c(
                                                                  "i",
                                                                  {
                                                                    staticClass:
                                                                      "material-icons",
                                                                    style: {
                                                                      color:
                                                                        _vm.$options.filters.dispositionStatusColor(
                                                                          task
                                                                            .contact
                                                                            .disposition_status_id
                                                                        ),
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "label"
                                                                    ),
                                                                  ]
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "text-grey-900",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm._f(
                                                                          "dispositionStatusName"
                                                                        )(
                                                                          task
                                                                            .contact
                                                                            .disposition_status_id
                                                                        )
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                task.contact.company_name
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "d-block text-pink-500 text-xxs",
                                                      },
                                                      [
                                                        _c(
                                                          "i",
                                                          {
                                                            staticClass:
                                                              "material-icons",
                                                          },
                                                          [
                                                            _vm._v(
                                                              "business_center"
                                                            ),
                                                          ]
                                                        ),
                                                        _vm._v(
                                                          "\n                                            " +
                                                            _vm._s(
                                                              task.contact
                                                                .company_name
                                                            ) +
                                                            "\n                                        "
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                task.contact.timezone
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "d-block text-indigo-500 text-xxs",
                                                      },
                                                      [
                                                        _c(
                                                          "i",
                                                          {
                                                            staticClass:
                                                              "material-icons",
                                                          },
                                                          [_vm._v("public")]
                                                        ),
                                                        _vm._v(
                                                          "\n                                            " +
                                                            _vm._s(
                                                              task.contact
                                                                .timezone
                                                            ) +
                                                            "\n                                        "
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "d-block text-purple text-xxs",
                                                  },
                                                  [
                                                    _c("span", [
                                                      _vm._v(
                                                        "Scheduled for: " +
                                                          _vm._s(
                                                            _vm._f(
                                                              "fixDateTime"
                                                            )(task.scheduled_at)
                                                          )
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              { staticClass: "ml-auto" },
                                              [
                                                !_vm.masterControl
                                                  ? _c(
                                                      "button",
                                                      {
                                                        staticClass:
                                                          "btn btn-sm btn-outline-danger pull-right",
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            $event.preventDefault()
                                                            return _vm.deleteTask(
                                                              task
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _vm.loading_delete &&
                                                        _vm.loading_delete ==
                                                          task.id
                                                          ? _c("i", {
                                                              staticClass:
                                                                "fa fa-spinner fa-spin",
                                                            })
                                                          : _c("i", {
                                                              staticClass:
                                                                "fa fa-trash",
                                                            }),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                              ]
                                            ),
                                          ]
                                        )
                                      }
                                    ),
                                    _vm._v(" "),
                                    _vm.hasMoreScheduledTasks
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "d-flex align-items-center justify-content-center p-t-sm p-b-sm",
                                          },
                                          [
                                            _c(
                                              "el-button",
                                              {
                                                attrs: {
                                                  type: "primary",
                                                  loading:
                                                    _vm.pagination_scheduled_tasks_loading,
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.changePage(
                                                      _vm.AutoDialTaskStatus
                                                        .STATUS_SCHEDULED,
                                                      _vm
                                                        .pagination_scheduled_tasks
                                                        .current_page + 1
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                        Load More\n                                    "
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ],
                                  2
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-md full-height" }, [
                      _c(
                        "div",
                        {
                          staticClass: "row box b-a full-height no-box-shadow",
                        },
                        [
                          _c("div", { staticClass: "col-12" }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "no-effect full-height d-flex flex-column",
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "box-header pt-0 pb-0" },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "row b-b pt-2 pb-2" },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "col-12" },
                                          [
                                            _c(
                                              "el-form",
                                              {
                                                ref: "auto_dialer_settings",
                                                staticClass: "w-full",
                                                attrs: {
                                                  model: _vm.auto_dialer,
                                                  inline: true,
                                                  rules: _vm.rules,
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "row" },
                                                  [
                                                    _vm.selected_auto_dial_task &&
                                                    _vm.selected_contact
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "col fixed-width-500 align-items-center",
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "row",
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "col-6 d-flex align-items-center",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "div",
                                                                      [
                                                                        _c(
                                                                          "el-tooltip",
                                                                          {
                                                                            staticClass:
                                                                              "item",
                                                                            attrs:
                                                                              {
                                                                                effect:
                                                                                  "dark",
                                                                                disabled:
                                                                                  !_vm.avatarTooltip(
                                                                                    _vm.selected_contact
                                                                                  ),
                                                                                content:
                                                                                  _vm.avatarTooltip(
                                                                                    _vm.selected_contact
                                                                                  ),
                                                                                placement:
                                                                                  "top",
                                                                              },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "span",
                                                                              {
                                                                                staticClass:
                                                                                  "w-40 avatar grey-300 d-block pull-left",
                                                                                style:
                                                                                  _vm.avatarStyle(
                                                                                    _vm.selected_contact
                                                                                  ),
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "span",
                                                                                  [
                                                                                    _vm._v(
                                                                                      _vm._s(
                                                                                        _vm._f(
                                                                                          "initials"
                                                                                        )(
                                                                                          _vm._f(
                                                                                            "fixContactName"
                                                                                          )(
                                                                                            _vm
                                                                                              .selected_contact
                                                                                              .name
                                                                                          )
                                                                                        )
                                                                                      )
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _vm._v(
                                                                          " "
                                                                        ),
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "ml-2 d-inline-block width-200",
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "span",
                                                                              {
                                                                                staticClass:
                                                                                  "d-block font-weight-bold text-ellipsis",
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    _vm._f(
                                                                                      "fixContactName"
                                                                                    )(
                                                                                      _vm
                                                                                        .selected_contact
                                                                                        .name
                                                                                    )
                                                                                  )
                                                                                ),
                                                                              ]
                                                                            ),
                                                                            _vm._v(
                                                                              " "
                                                                            ),
                                                                            _c(
                                                                              "span",
                                                                              {
                                                                                staticClass:
                                                                                  "d-block",
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    _vm._f(
                                                                                      "fixPhone"
                                                                                    )(
                                                                                      _vm
                                                                                        .selected_auto_dial_task
                                                                                        .phone_number
                                                                                    )
                                                                                  )
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ]
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "col-6",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "list-item pl-0 pr-0",
                                                                      },
                                                                      [
                                                                        _vm
                                                                          .selected_contact
                                                                          .user_id
                                                                          ? _c(
                                                                              "span",
                                                                              {
                                                                                staticClass:
                                                                                  "d-block text-bluish",
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "i",
                                                                                  {
                                                                                    staticClass:
                                                                                      "material-icons",
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      "person"
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                                _vm._v(
                                                                                  "\n                                                                            " +
                                                                                    _vm._s(
                                                                                      _vm.getUserName(
                                                                                        _vm
                                                                                          .selected_contact
                                                                                          .user_id
                                                                                      )
                                                                                    ) +
                                                                                    "\n                                                                        "
                                                                                ),
                                                                              ]
                                                                            )
                                                                          : _vm._e(),
                                                                        _vm._v(
                                                                          " "
                                                                        ),
                                                                        _vm
                                                                          .selected_contact
                                                                          .disposition_status_id
                                                                          ? _c(
                                                                              "span",
                                                                              {
                                                                                staticClass:
                                                                                  "d-block text-red-500",
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "i",
                                                                                  {
                                                                                    staticClass:
                                                                                      "material-icons",
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      "folder"
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                                _vm._v(
                                                                                  "\n                                                                            " +
                                                                                    _vm._s(
                                                                                      _vm.getDispositionStatusName(
                                                                                        _vm
                                                                                          .selected_contact
                                                                                          .disposition_status_id
                                                                                      )
                                                                                    ) +
                                                                                    "\n                                                                        "
                                                                                ),
                                                                              ]
                                                                            )
                                                                          : _vm._e(),
                                                                        _vm._v(
                                                                          " "
                                                                        ),
                                                                        _vm
                                                                          .selected_contact
                                                                          .initial_campaign_id &&
                                                                        _vm.getCampaign(
                                                                          _vm
                                                                            .selected_contact
                                                                            .initial_campaign_id
                                                                        )
                                                                          ? _c(
                                                                              "span",
                                                                              {
                                                                                staticClass:
                                                                                  "d-block text-purple-500",
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "i",
                                                                                  {
                                                                                    staticClass:
                                                                                      "material-icons",
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      "power"
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                                _vm._v(
                                                                                  "\n                                                                            " +
                                                                                    _vm._s(
                                                                                      _vm.getCampaign(
                                                                                        _vm
                                                                                          .selected_contact
                                                                                          .initial_campaign_id
                                                                                      )
                                                                                        .name
                                                                                    ) +
                                                                                    "\n                                                                        "
                                                                                ),
                                                                              ]
                                                                            )
                                                                          : _vm._e(),
                                                                        _vm._v(
                                                                          " "
                                                                        ),
                                                                        _vm
                                                                          .selected_contact
                                                                          .company_name
                                                                          ? _c(
                                                                              "div",
                                                                              {
                                                                                staticClass:
                                                                                  "d-block text-pink-500",
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "i",
                                                                                  {
                                                                                    staticClass:
                                                                                      "material-icons",
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      "business_center"
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                                _vm._v(
                                                                                  "\n                                                                        " +
                                                                                    _vm._s(
                                                                                      _vm
                                                                                        .selected_contact
                                                                                        .company_name
                                                                                    ) +
                                                                                    "\n                                                                    "
                                                                                ),
                                                                              ]
                                                                            )
                                                                          : _vm._e(),
                                                                        _vm._v(
                                                                          " "
                                                                        ),
                                                                        _vm
                                                                          .selected_contact
                                                                          .cnam_city ||
                                                                        _vm
                                                                          .selected_contact
                                                                          .cnam_state
                                                                          ? _c(
                                                                              "span",
                                                                              {
                                                                                staticClass:
                                                                                  "d-block text-new-purple",
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "i",
                                                                                  {
                                                                                    staticClass:
                                                                                      "material-icons",
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      "location_on"
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                                _vm._v(
                                                                                  " "
                                                                                ),
                                                                                _vm
                                                                                  .selected_contact
                                                                                  .cnam_city
                                                                                  ? _c(
                                                                                      "span",
                                                                                      [
                                                                                        _vm._v(
                                                                                          _vm._s(
                                                                                            _vm
                                                                                              .selected_contact
                                                                                              .cnam_city
                                                                                          ) +
                                                                                            ", "
                                                                                        ),
                                                                                      ]
                                                                                    )
                                                                                  : _vm._e(),
                                                                                _vm._v(
                                                                                  " "
                                                                                ),
                                                                                _vm
                                                                                  .selected_contact
                                                                                  .cnam_state
                                                                                  ? _c(
                                                                                      "span",
                                                                                      [
                                                                                        _vm._v(
                                                                                          _vm._s(
                                                                                            _vm
                                                                                              .selected_contact
                                                                                              .cnam_state
                                                                                          )
                                                                                        ),
                                                                                      ]
                                                                                    )
                                                                                  : _vm._e(),
                                                                              ]
                                                                            )
                                                                          : _vm._e(),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      { staticClass: "col" },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "pull-right",
                                                          },
                                                          [
                                                            _c(
                                                              "el-tooltip",
                                                              {
                                                                staticClass:
                                                                  "pull-right",
                                                                attrs: {
                                                                  effect:
                                                                    "power-dialer-start-button-tooltip",
                                                                  placement:
                                                                    "bottom",
                                                                  disabled:
                                                                    !_vm.disabledStart,
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    attrs: {
                                                                      slot: "content",
                                                                    },
                                                                    slot: "content",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.disabled_start_button_reason_tooltip
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "span",
                                                                  [
                                                                    !_vm.isStarted
                                                                      ? _c(
                                                                          "el-button",
                                                                          {
                                                                            staticClass:
                                                                              "mb-2 ml-2 pull-right",
                                                                            attrs:
                                                                              {
                                                                                icon: "fa fa-play",
                                                                                size: "small",
                                                                                type: "primary",
                                                                                disabled:
                                                                                  _vm.disabledStart,
                                                                                loading:
                                                                                  _vm.loading_queue,
                                                                              },
                                                                            on: {
                                                                              click:
                                                                                _vm.start,
                                                                            },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "span",
                                                                              {
                                                                                staticClass:
                                                                                  "ml-2",
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  "Start"
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ]
                                                                        )
                                                                      : _vm._e(),
                                                                  ],
                                                                  1
                                                                ),
                                                              ]
                                                            ),
                                                            _vm._v(" "),
                                                            _vm.isStarted
                                                              ? _c(
                                                                  "el-button",
                                                                  {
                                                                    staticClass:
                                                                      "mb-2 ml-2 pull-right",
                                                                    attrs: {
                                                                      icon: "fa fa-stop",
                                                                      size: "small",
                                                                      type: "warning",
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        _vm.stop,
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "span",
                                                                      {
                                                                        staticClass:
                                                                          "ml-2",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "Stop"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                )
                                                              : _vm._e(),
                                                            _vm._v(" "),
                                                            _vm.isStarted &&
                                                            _vm.isPause
                                                              ? _c(
                                                                  "el-button",
                                                                  {
                                                                    staticClass:
                                                                      "mb-2 ml-2 pull-right",
                                                                    attrs: {
                                                                      icon: "fa fa-play",
                                                                      size: "small",
                                                                      type: "primary",
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        _vm.play,
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "span",
                                                                      {
                                                                        staticClass:
                                                                          "ml-2",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "Play"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                )
                                                              : _vm._e(),
                                                            _vm._v(" "),
                                                            _vm.isStarted &&
                                                            !_vm.isPause
                                                              ? _c(
                                                                  "el-button",
                                                                  {
                                                                    staticClass:
                                                                      "blackish mb-2 ml-2 pull-right",
                                                                    attrs: {
                                                                      icon: "fa fa-pause",
                                                                      size: "small",
                                                                      type: "info",
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        _vm.pause,
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "span",
                                                                      {
                                                                        staticClass:
                                                                          "ml-2",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "Pause"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                )
                                                              : _vm._e(),
                                                            _vm._v(" "),
                                                            _vm.isStarted &&
                                                            _vm.selected_auto_dial_task
                                                              ? _c(
                                                                  "el-button",
                                                                  {
                                                                    staticClass:
                                                                      "mb-2 ml-2 pull-right",
                                                                    attrs: {
                                                                      icon: "fa fa-times",
                                                                      size: "small",
                                                                      type: "danger",
                                                                      loading:
                                                                        _vm.loading_skip,
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        _vm.skipTask,
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "span",
                                                                      {
                                                                        staticClass:
                                                                          "ml-2",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "Skip Task"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                )
                                                              : _vm._e(),
                                                            _vm._v(" "),
                                                            !_vm.isStarted &&
                                                            !_vm.masterControl
                                                              ? _c(
                                                                  "el-button",
                                                                  {
                                                                    staticClass:
                                                                      "mb-2 ml-2 pull-right",
                                                                    attrs: {
                                                                      icon: "fa fa-trash",
                                                                      size: "small",
                                                                      type: "danger",
                                                                      loading:
                                                                        _vm.loading_clear,
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        _vm.clearTasks,
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "span",
                                                                      {
                                                                        staticClass:
                                                                          "ml-2",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "Clear Tasks"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                )
                                                              : _vm._e(),
                                                            _vm._v(" "),
                                                            !_vm.masterControl
                                                              ? _c(
                                                                  "add-auto-dial-tasks",
                                                                  {
                                                                    staticClass:
                                                                      "pull-right",
                                                                    attrs: {
                                                                      small: true,
                                                                    },
                                                                    on: {
                                                                      success:
                                                                        _vm.addedTask,
                                                                    },
                                                                  }
                                                                )
                                                              : _vm._e(),
                                                            _vm._v(" "),
                                                            _vm.selected_auto_dial_task
                                                              ? _c(
                                                                  "el-popover",
                                                                  {
                                                                    attrs: {
                                                                      placement:
                                                                        "left-start",
                                                                      width:
                                                                        "285",
                                                                      trigger:
                                                                        "click",
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        _vm.schedule_task_popover,
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.schedule_task_popover =
                                                                            $$v
                                                                        },
                                                                      expression:
                                                                        "schedule_task_popover",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-date-picker",
                                                                      {
                                                                        attrs: {
                                                                          mode: "dateTime",
                                                                          "title-position":
                                                                            "left",
                                                                          color:
                                                                            "green",
                                                                          "min-date":
                                                                            new Date(),
                                                                        },
                                                                        model: {
                                                                          value:
                                                                            _vm.future_scheduled_time,
                                                                          callback:
                                                                            function (
                                                                              $$v
                                                                            ) {
                                                                              _vm.future_scheduled_time =
                                                                                $$v
                                                                            },
                                                                          expression:
                                                                            "future_scheduled_time",
                                                                        },
                                                                      }
                                                                    ),
                                                                    _vm._v(" "),
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "row no-gutter centered-content mt-2",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "button",
                                                                          {
                                                                            staticClass:
                                                                              "btn btn-block greenish",
                                                                            attrs:
                                                                              {
                                                                                loading:
                                                                                  _vm.loading_schedule_task,
                                                                                disabled:
                                                                                  _vm.loading_schedule_task,
                                                                              },
                                                                            on: {
                                                                              click:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  $event.preventDefault()
                                                                                  return _vm.scheduleTask.apply(
                                                                                    null,
                                                                                    arguments
                                                                                  )
                                                                                },
                                                                            },
                                                                          },
                                                                          [
                                                                            _vm.loading_schedule_task
                                                                              ? _c(
                                                                                  "i",
                                                                                  {
                                                                                    staticClass:
                                                                                      "material-icons loader",
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      ""
                                                                                    ),
                                                                                  ]
                                                                                )
                                                                              : _vm._e(),
                                                                            _vm._v(
                                                                              "\n                                                                        Schedule Task\n                                                                    "
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _vm._v(" "),
                                                                    _c(
                                                                      "el-button",
                                                                      {
                                                                        staticClass:
                                                                          "mb-2 mr-2 pull-right",
                                                                        attrs: {
                                                                          slot: "reference",
                                                                          icon: "fa fa-calendar",
                                                                          size: "small",
                                                                          type: "purple",
                                                                        },
                                                                        slot: "reference",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "span",
                                                                          {
                                                                            staticClass:
                                                                              "ml-2",
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "Schedule Task"
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ],
                                                                  1
                                                                )
                                                              : _vm._e(),
                                                            _vm._v(" "),
                                                            _vm.isStarted
                                                              ? _c(
                                                                  "el-button",
                                                                  {
                                                                    staticClass:
                                                                      "mb-2 mr-2 pull-right",
                                                                    attrs: {
                                                                      icon: "fa fa-list",
                                                                      size: "small",
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        _vm.toggleTaskList,
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "span",
                                                                      {
                                                                        staticClass:
                                                                          "ml-2",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "Task List"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                )
                                                              : _vm._e(),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "row d-flex align-items-center flex-row-reverse",
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      { staticClass: "col" },
                                                      [
                                                        !_vm.isStarted
                                                          ? _c(
                                                              "el-form-item",
                                                              {
                                                                staticClass:
                                                                  "mb-0 mr-2",
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "mb-0 lh-1",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "Order by:"
                                                                    ),
                                                                  ]
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "el-select",
                                                                  {
                                                                    staticStyle:
                                                                      {
                                                                        width:
                                                                          "150px",
                                                                      },
                                                                    attrs: {
                                                                      size: "small",
                                                                      placeholder:
                                                                        "Select warm up duration",
                                                                      disabled:
                                                                        _vm.isStarted,
                                                                    },
                                                                    on: {
                                                                      change:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.fetchAutoDialTasks()
                                                                        },
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        _vm.order,
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.order =
                                                                            $$v
                                                                        },
                                                                      expression:
                                                                        "order",
                                                                    },
                                                                  },
                                                                  _vm._l(
                                                                    _vm.orders,
                                                                    function (
                                                                      item
                                                                    ) {
                                                                      return _c(
                                                                        "el-option",
                                                                        {
                                                                          key: item.value,
                                                                          attrs:
                                                                            {
                                                                              label:
                                                                                item.label,
                                                                              value:
                                                                                item.value,
                                                                            },
                                                                        }
                                                                      )
                                                                    }
                                                                  ),
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            )
                                                          : _vm._e(),
                                                        _vm._v(" "),
                                                        !_vm.isStarted
                                                          ? _c(
                                                              "el-form-item",
                                                              {
                                                                staticClass:
                                                                  "mb-0 mr-2",
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "mb-0 lh-1",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "Warm up duration:"
                                                                    ),
                                                                  ]
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "el-select",
                                                                  {
                                                                    staticStyle:
                                                                      {
                                                                        width:
                                                                          "150px",
                                                                      },
                                                                    attrs: {
                                                                      size: "small",
                                                                      placeholder:
                                                                        "Select warm up duration",
                                                                      disabled:
                                                                        _vm.isStarted,
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        _vm.warm_up_seconds,
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.warm_up_seconds =
                                                                            $$v
                                                                        },
                                                                      expression:
                                                                        "warm_up_seconds",
                                                                    },
                                                                  },
                                                                  _vm._l(
                                                                    _vm.warm_up_seconds_options,
                                                                    function (
                                                                      item
                                                                    ) {
                                                                      return _c(
                                                                        "el-option",
                                                                        {
                                                                          key: item.value,
                                                                          attrs:
                                                                            {
                                                                              label:
                                                                                item.label,
                                                                              value:
                                                                                item.value,
                                                                            },
                                                                        }
                                                                      )
                                                                    }
                                                                  ),
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            )
                                                          : _vm._e(),
                                                        _vm._v(" "),
                                                        _c(
                                                          "el-form-item",
                                                          {
                                                            staticClass:
                                                              "mb-0 mr-2",
                                                            attrs: {
                                                              prop: "outbound_campaign_id",
                                                              disabled:
                                                                _vm.isStarted,
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "mb-0 lh-1",
                                                              },
                                                              [_vm._v("Line:")]
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "campaign-selector",
                                                              {
                                                                staticStyle: {
                                                                  width:
                                                                    "200px",
                                                                },
                                                                attrs: {
                                                                  value:
                                                                    _vm
                                                                      .auto_dialer
                                                                      .outbound_campaign_id,
                                                                  small: true,
                                                                  multiple: false,
                                                                  disabled:
                                                                    _vm.isStarted,
                                                                  show_paused: false,
                                                                },
                                                                on: {
                                                                  change:
                                                                    _vm.changeCampaign,
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm
                                                                      .auto_dialer
                                                                      .outbound_campaign_id,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.auto_dialer,
                                                                        "outbound_campaign_id",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "auto_dialer.outbound_campaign_id",
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "el-form-item",
                                                          {
                                                            staticClass:
                                                              "mb-0 mr-2",
                                                            attrs: {
                                                              disabled:
                                                                _vm.isStarted,
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "mb-0 lh-1",
                                                              },
                                                              [_vm._v(" ")]
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "el-checkbox",
                                                              {
                                                                attrs: {
                                                                  size: "small",
                                                                  disabled:
                                                                    _vm.isStarted,
                                                                  border: "",
                                                                },
                                                                on: {
                                                                  change:
                                                                    _vm.setSkipOutsideDayTimes,
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm.should_skip,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.should_skip =
                                                                        $$v
                                                                    },
                                                                  expression:
                                                                    "should_skip",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                                                                Skip outside day times\n                                                            "
                                                                ),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _vm._v(" "),
                                                        _vm.hasPowerDialerCrmLink
                                                          ? _c(
                                                              "el-form-item",
                                                              {
                                                                staticClass:
                                                                  "mb-0 mr-2",
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "mb-0 lh-1",
                                                                  },
                                                                  [_vm._v(" ")]
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "el-checkbox",
                                                                  {
                                                                    attrs: {
                                                                      size: "small",
                                                                      border:
                                                                        "",
                                                                    },
                                                                    on: {
                                                                      change:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          _vm.should_open_contact = false
                                                                        },
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        _vm.should_open_crm,
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.should_open_crm =
                                                                            $$v
                                                                        },
                                                                      expression:
                                                                        "should_open_crm",
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "\n                                                                Open CRM Links\n                                                            "
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            )
                                                          : _vm._e(),
                                                        _vm._v(" "),
                                                        _c(
                                                          "el-form-item",
                                                          {
                                                            staticClass:
                                                              "mb-0 mr-2",
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "mb-0 lh-1",
                                                              },
                                                              [_vm._v(" ")]
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "el-checkbox",
                                                              {
                                                                attrs: {
                                                                  size: "small",
                                                                  border: "",
                                                                },
                                                                on: {
                                                                  change:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      _vm.should_open_crm = false
                                                                    },
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm.should_open_contact,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.should_open_contact =
                                                                        $$v
                                                                    },
                                                                  expression:
                                                                    "should_open_contact",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                                                                Open Contact Page\n                                                            "
                                                                ),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _vm.selected_auto_dial_task
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "box-body full-height d-flex col overflow-y-scroll",
                                        class: [
                                          (_vm.should_open_crm &&
                                            _vm.crm_link) ||
                                          _vm.should_open_contact
                                            ? "p-0"
                                            : "",
                                        ],
                                      },
                                      [
                                        _vm.countdown_started &&
                                        _vm.selected_contact &&
                                        _vm.isStarted &&
                                        !_vm.isPause &&
                                        _vm.countdown_timer > 0
                                          ? _c(
                                              "div",
                                              {
                                                staticClass: "shadow",
                                                attrs: {
                                                  id: "container-auto-dialer-count-down",
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "media" },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "media-body mr-3",
                                                      },
                                                      [
                                                        _c(
                                                          "h6",
                                                          {
                                                            staticClass: "mb-0",
                                                          },
                                                          [_vm._v("Calling")]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "p",
                                                          {
                                                            staticClass: "mb-0",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm
                                                                  .selected_contact
                                                                  .name
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "align-self-center",
                                                        attrs: {
                                                          id: "count-down-number",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                                                " +
                                                            _vm._s(
                                                              _vm.countdown_timer
                                                            ) +
                                                            "\n                                            "
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "row full-height w-100 m-0",
                                          },
                                          [
                                            _vm.should_open_crm && _vm.crm_link
                                              ? [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "col-12 p-0",
                                                    },
                                                    [
                                                      _c(
                                                        "iframe",
                                                        {
                                                          attrs: {
                                                            src: _vm.crm_link,
                                                            width: "100%",
                                                            height: "100%",
                                                            frameBorder: "0",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                                                    Browser is not compatible.\n                                                "
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              : _vm.should_open_contact
                                              ? [
                                                  _c("contact", {
                                                    attrs: {
                                                      contact_id:
                                                        _vm.selected_contact.id,
                                                      embedded: true,
                                                    },
                                                  }),
                                                ]
                                              : [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "col-md-4 b-r",
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "contact-details",
                                                        },
                                                        [
                                                          _c(
                                                            "contact-details",
                                                            {
                                                              attrs: {
                                                                contact:
                                                                  _vm.selected_contact,
                                                              },
                                                            }
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "col-md-4 b-r",
                                                    },
                                                    [
                                                      _vm
                                                        .selected_auto_dial_task
                                                        .communication
                                                        ? _c(
                                                            "communication-info",
                                                            {
                                                              attrs: {
                                                                communication:
                                                                  _vm
                                                                    .selected_auto_dial_task
                                                                    .communication,
                                                                hide_contact_disposition:
                                                                  "",
                                                                display_contact:
                                                                  "",
                                                                dialer_mode: "",
                                                              },
                                                            }
                                                          )
                                                        : _vm._e(),
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _vm.selected_contact
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "col-md-4",
                                                        },
                                                        [
                                                          _c(
                                                            "el-table",
                                                            {
                                                              directives: [
                                                                {
                                                                  name: "loading",
                                                                  rawName:
                                                                    "v-loading",
                                                                  value:
                                                                    _vm.loading_script,
                                                                  expression:
                                                                    "loading_script",
                                                                },
                                                              ],
                                                              staticClass:
                                                                "mb-4",
                                                              attrs: {
                                                                data: _vm.scripts,
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "el-table-column",
                                                                {
                                                                  attrs: {
                                                                    prop: "title",
                                                                    label:
                                                                      "Script Title",
                                                                    "min-width": 250,
                                                                  },
                                                                }
                                                              ),
                                                              _vm._v(" "),
                                                              _c(
                                                                "el-table-column",
                                                                {
                                                                  attrs: {
                                                                    width:
                                                                      "120",
                                                                  },
                                                                  scopedSlots:
                                                                    _vm._u(
                                                                      [
                                                                        {
                                                                          key: "default",
                                                                          fn: function (
                                                                            scope
                                                                          ) {
                                                                            return [
                                                                              _c(
                                                                                "el-button",
                                                                                {
                                                                                  staticClass:
                                                                                    "greenish",
                                                                                  attrs:
                                                                                    {
                                                                                      size: "mini",
                                                                                    },
                                                                                  on: {
                                                                                    click:
                                                                                      function (
                                                                                        $event
                                                                                      ) {
                                                                                        return _vm.showScript(
                                                                                          scope.row
                                                                                        )
                                                                                      },
                                                                                  },
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "i",
                                                                                    {
                                                                                      staticClass:
                                                                                        "material-icons",
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        "launch"
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    "\n                                                                Open\n                                                            "
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ]
                                                                          },
                                                                        },
                                                                      ],
                                                                      null,
                                                                      false,
                                                                      1977419196
                                                                    ),
                                                                }
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "row b-t",
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "col-6 p-3",
                                                                },
                                                                [
                                                                  _c("h6", [
                                                                    _vm._v(
                                                                      "Notes"
                                                                    ),
                                                                  ]),
                                                                  _vm._v(" "),
                                                                  _c("div", {
                                                                    staticClass:
                                                                      "p-1",
                                                                    domProps: {
                                                                      innerHTML:
                                                                        _vm._s(
                                                                          _vm.$options.filters.nl2br(
                                                                            _vm
                                                                              .selected_contact
                                                                              .notes
                                                                          )
                                                                        ),
                                                                    },
                                                                  }),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                ],
                                          ],
                                          2
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ]
                            ),
                          ]),
                        ]
                      ),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _c("el-backtop", {
                  ref: "backtop",
                  attrs: { target: "html", bottom: 20, right: 20 },
                }),
              ]
            : _vm._e(),
          _vm._v(" "),
          !_vm.shouldShowPowerDialer && _vm.shouldShowUpgradeNow
            ? _c("upgrade-now-page", {
                staticClass: "mt-5",
                attrs: {
                  "image-link": "/assets/images/PowerDialer.svg",
                  text: "Boost your sales team’s productivity! Power Dialer automatically calls contacts one by one from a list so agents have less idle time.",
                  "extra-text": "Upgrade today to unlock this feature",
                  "title-text": "Power Dialer",
                  "kb-link":
                    "https://support.aloware.com/en/articles/9037581-power-up-your-outbound-calls-with-aloware-talk-s-power-dialer",
                },
              })
            : _vm._e(),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }