<template>
    <div class="app-body" id="view">
        <div v-if="shouldShowAloAis"
             class="container-listing">
            <div class="container-header d-flex">
                <h3 class="listing-heading">
                    AloAi
                </h3>
                <div class="ml-auto">
                    <div class="d-flex">
                        <el-button
                            @click="gotoChatbots"
                            type="info"
                            round
                            size="medium">
                            <i class="fa fa-arrow-left mr-1"></i>
                            <strong>Back</strong>
                        </el-button>
                    </div>
                </div>
            </div>
            <div class="container-fluid pb-3">
                <el-form v-loading="loading">
                    <alo-ai-form-settings
                        :lines="lines"
                        :chatbot="chatbot"/>
                    <alo-ai-form-instructions
                        :chatbot="chatbot"/>
                    <el-form-item class="d-flex justify-content-end">
                        <el-button
                            @click="submitForm"
                            :disabled="!contentIsValid"
                            type="success">
                            Save
                        </el-button>
                    </el-form-item>
                </el-form>
            </div>
        </div>
    </div>
</template>

<script>
import _ from 'lodash'
import auth from '../../auth.js'
import {acl_mixin, html_mixin} from '../../mixins'
import AloAiTable from '../../components/aloai/aloai-table.vue'
import AloAiFormSettings from '../../components/aloai/form/settings.vue'
import AloAiFormInstructions from '../../components/aloai/form/model-settings.vue'
import * as AloAi from "../../constants/aloai"

export default {
    components: {AloAiFormInstructions, AloAiFormSettings, AloAiTable},
    mixins: [acl_mixin, html_mixin],
    props: {
        bot_id: {
            required: false
        }
    },
    data() {
        return {
            loading: false,
            edit_mode: false,
            lines: [],
            chatbot: {
                name: '',
                description: '',
                model: AloAi.MODEL_GPT_4_T,
                use_case: AloAi.USE_CASE_SALES,
                ttl: 10080,
                opener: '',
                campaign_ids: [],
                instructions: [
                    {
                        role: 'system',
                        content: ''
                    }
                ],
                tools: [],
                uploaded_files: [],
                is_assistant: true
            },
        }
    },
    computed: {
        contentIsValid() {
            return (!(_.isEmpty(this.chatbot.name) ||
                (this.chatbot.use_case === AloAi.USE_CASE_SALES && _.isEmpty(this.chatbot.opener)) ||
                _.isEmpty(this.chatbot.campaign_ids) ||
                !this.chatbot.ttl ||
                _.isEmpty(this.chatbot.instructions[0].content)
            ));
        },
    },
    beforeRouteEnter(to, from, next) {
        // This logic help us wrap the Chatbots section with the Aloware App components.
        if (to.query.api_token) {
            return next({
                name: 'Login',
                query: {
                    api_token: to.query.api_token
                }
            })
        }

        auth.check().then((res) => {
            if (res.data.user.is_reseller) {
                // redirect to account management portal if the company is a reseller
                next({name: 'Account Management Portal'})
            } else {
                next()
            }
        }).catch((err) => {
            next({
                name: 'Login',
                query: {
                    redirect: to.fullPath
                }
            })
        })
    },
    methods: {
        gotoChatbots() {
            this.$router.push({name: 'AloAi'})
        },
        submitForm() {
            if (!this.contentIsValid || (this.edit_mode && !this.chatbot.campaign_ids.length)) {
                this.$message({
                    message: 'Please fill in all required fields',
                    type: 'error'
                })
                return
            }
            this.loading = true
            // If edit_mode, update the chatbot otherwise create a new one
            if (this.edit_mode) {
                this.updateChatbot()
            } else {
                this.createChatbot()
            }

        },
        createChatbot() {
            const bot = this.replaceInstructionsReadOnlyDelimitators()
            axios.post('/api/v1/aloai/chat-bot', bot)
                .then(response => {
                    this.$message({
                        message: 'Chatbot created successfully',
                        type: 'success'
                    })
                    setTimeout(() => {
                        this.$router.push({
                            name: 'AloAi: Edit chatbot',
                            params: {
                                bot_id: response.data.id
                            }
                        })
                    }, 1000)
                })
                .catch(error => {
                    const errorMessage = error.response?.data?.message ? `There was an error creating the chatbot: ${error.response?.data?.message}` : 'There was an error creating the chatbot';
                    this.$message({
                        message: errorMessage,
                        type: 'error',
                    })
                })
                .finally(() => {
                    this.loading = false
                })
        },
        updateChatbot() {
            const bot = this.replaceInstructionsReadOnlyDelimitators()
            axios.put(`/api/v1/aloai/chat-bot/${this.bot_id}`, bot)
                .then(response => {
                    this.$message({
                        message: 'Chatbot updated successfully',
                        type: 'success'
                    })
                })
                .catch(error => {
                    const errorMessage = error.response?.data?.message ? `There was an error creating the chatbot: ${error.response?.data?.message}` : 'There was an error creating the chatbot';
                    this.$message({
                        message: errorMessage,
                        type: 'error',
                    })
                })
                .finally(() => {
                    this.loading = false
                })
        },
        replaceInstructionsReadOnlyDelimitators() {
            // create a deep copy from this.chatbot
            const bot = JSON.parse(JSON.stringify(this.chatbot))

            // check if inside bot.instructions[0].content we found '[' or ']' and replace them with \"
            bot.instructions[0].content = this.transformTagsString(bot.instructions[0].content)

            // check if inside bot.instructions[0].content we found '(' or ')' and replace them with \'
            bot.instructions[0].content = this.transformDispositionsString(bot.instructions[0].content)
            return bot
        },
        transformTagsString(input) {
            // Use regular expression to find all occurrences of "{Add [ ... ] tag(s) to the contact}"
            const regex = /\{Add\s+(\[\s*[^\]]+\s*\]\s*)+tag\(s\)\s+to\s+the\s+contact\}/g
            
            // Replace each occurrence found with the transformed version
            const transformed = input.replace(regex, (match) => {
                // Find all occurrences of "[ ... ]"
                const tagRegex = /\[\s*([^\]]+?)\s*\]/g
                const tags = []
                let tagMatch
                
                // Extract all tags inside the brackets
                while ((tagMatch = tagRegex.exec(match)) !== null) {
                    tags.push(tagMatch[1].trim())
                }

                // Join the tags with " || "
                const joinedTags = tags.join(' || ')

                // Replace the content of the tags in the original string
                return `{Add " ${joinedTags} " tag(s) to the contact}`
            })

            return transformed
        },
        transformDispositionsString(input) {
            // Use regular expression to find all occurrences of "{Add ( ... ) disposition to the contact}"
            const regex = /\{Add\s+\(\s*[^)]+\s*\)\s+disposition\s+to\s+the\s+contact\}/g

            // Replace each occurrence found with the transformed version
            const transformed = input.replace(regex, (match) => {
                // Find the content inside the parentheses
                const dispositionRegex = /\(\s*([^)]+?)\s*\)/g
                const dispositions = []
                let dispositionMatch

                // Extract the disposition inside the parentheses
                while ((dispositionMatch = dispositionRegex.exec(match)) !== null) {
                    dispositions.push(dispositionMatch[1].trim())
                }

                // Join the dispositions with " || "
                const joinedDispositions = dispositions.join(' || ')

                // Replace the content of the dispositions in the original string
                return `{Add ' ${joinedDispositions} ' disposition to the contact}`
            })

            return transformed
        },
        fetchLines() {
            axios.get('/api/v1/campaign').then(res => {
                this.lines = res.data
            }).catch(err => {
                console.log(err)
            })
        },
        fetchBot() {
            axios.get(`/api/v1/aloai/chat-bot/${this.bot_id}`).then(res => {
                this.chatbot = res.data
                this.chatbot.campaign_ids = this.chatbot.campaigns.length ? this.chatbot.campaigns.map((campaign) => {
                    return campaign.id
                }) : []
                this.chatbot.tools = this.chatbot.tools.length ? this.chatbot.tools : []
            }).catch(err => {
                console.log(err)
                this.goBack()
            })
        },
    },
    created() {
        if (!this.shouldShowAloAis) {
            this.goBack()
        }
    },
    mounted() {
        this.setPageTitle(this.$route.name)

        if (this.bot_id) {
            this.edit_mode = true
            this.fetchBot()
        }

        this.fetchLines()
    }
}
</script>
