<template>
    <div class="app-body m-h-v"
         id="view"
         v-if="!is_loading">
        <template v-if="shouldShowPowerDialer">
            <el-dialog :title="scriptDialogTitle"
                       :visible.sync="showScriptDialog"
                       :before-close="hideScript"
                       append-to-body
                       width="50%">
                <span v-if="selected_script"
                      v-html="selected_script.text">
                </span>
            </el-dialog>

            <div class="padding full-height">
                <div class="row flex-nowrap full-height">
                    <div class="col-md-3 full-height auto-dialer-sidebar"
                         v-show="task_list_open">
                        <div class="box full-height no-box-shadow">
                            <el-collapse v-model="activeName"
                                         class="fullscreen"
                                         accordion>
                                <el-collapse-item name="in-progress">
                                    <template slot="title">
                                        <div class="font-120 w-100 d-flex align-items-center justify-content-between">
                                            <span class="pull-left">In-Progress</span>
                                            <b class="label label-md blue m-l-xs pull-right m-r-sm"
                                               v-if="pagination_in_progress_tasks.total !== undefined">
                                                {{ pagination_in_progress_tasks.total }}
                                            </b>
                                        </div>
                                    </template>

                                    <div class="el-table__empty-block full-height"
                                         v-show="!pagination_in_progress_tasks_loading && in_progress_tasks.length == 0">
                                        <span class="text-lg _700 text-greyish">
                                            No Tasks
                                        </span>
                                    </div>

                                    <div class="d-flex align-items-center pointer p-t-sm p-b-sm"
                                         :class="[ index <= (in_progress_tasks.length - 2) ? 'b-b' : '']"
                                         v-for="(task, index) in in_progress_tasks"
                                         :key="index"
                                         @click="selectTask(task)">
                                        <span class="w-40 avatar grey-300"
                                              v-bind:style="avatarStyle(task.contact.name)">
                                            <span>{{ task.contact.name | fixContactName | initials }}</span>
                                        </span>

                                        <div class="ml-2 allow-select">
                                            <span class="d-block font-weight-bold">{{ task.contact.name | fixContactName }}</span>
                                            <span class="d-block">{{ task.phone_number | fixPhone }}</span>
                                            <div class="d-block text-xxs"
                                                 v-if="task.contact.disposition_status_id">
                                                <span class="text-greyish"
                                                      v-if="task.contact.disposition_status_id">
                                                    <i class="material-icons"
                                                       :style="{ color: $options.filters.dispositionStatusColor(task.contact.disposition_status_id) }">label</i>
                                                    <span class="text-grey-900">{{ task.contact.disposition_status_id | dispositionStatusName }}</span>
                                                </span>
                                            </div>
                                            <div class="d-block text-pink-500 text-xxs"
                                                 v-if="task.contact.company_name">
                                                <i class="material-icons">business_center</i>
                                                {{ task.contact.company_name }}
                                            </div>
                                            <div class="d-block text-indigo-500 text-xxs"
                                                 v-if="task.contact.timezone">
                                                <i class="material-icons">public</i>
                                                {{ task.contact.timezone }}
                                            </div>
                                            <div class="d-block">
                                                <span v-if="isSkipped(task.id)"
                                                      class="badge badge-warning">
                                                    Skipped
                                                </span>
                                            </div>
                                        </div>

                                        <div class="ml-auto">
                                            <button class="btn btn-sm btn-outline-danger pull-right"
                                                    @click.prevent="skipTask">
                                                <i v-if="loading_skip"
                                                   class="fa fa-spinner fa-spin"></i>
                                                <i v-else
                                                   class="fa fa-times"></i>
                                            </button>
                                        </div>
                                    </div>

                                    <div class="d-flex align-items-center justify-content-center p-t-sm p-b-sm"
                                         v-if="hasMoreInProgressTasks">
                                        <el-button type="primary"
                                                   :loading="pagination_in_progress_tasks_loading"
                                                   @click="changePage(AutoDialTaskStatus.STATUS_IN_PROGRESS, pagination_in_progress_tasks.current_page + 1)">
                                            Load More
                                        </el-button>
                                    </div>
                                </el-collapse-item>
                                <el-collapse-item name="queued">
                                    <template slot="title">
                                        <div class="font-120 w-100 d-flex align-items-center justify-content-between">
                                            <span class="pull-left">Queued</span>
                                            <b class="label label-md warn m-l-xs pull-right m-r-sm"
                                               v-if="pagination_queued_tasks.total !== undefined">
                                                <span v-if="!loading_queue">{{ pagination_queued_tasks.total }}</span>
                                                <i class="material-icons loader"
                                                   v-else>
                                                    &#xE863;
                                                </i>
                                            </b>
                                        </div>
                                    </template>

                                    <div class="el-table__empty-block full-height"
                                         v-show="!pagination_queued_tasks_loading && queued_tasks.length == 0">
                                        <span class="text-lg _700 text-greyish">
                                            No Tasks
                                        </span>
                                    </div>

                                    <div class="d-flex align-items-center p-t-sm p-b-sm"
                                         :class="[ index <= (queued_tasks.length - 2) ? 'b-b' : '']"
                                         v-for="(task, index) in queued_tasks"
                                         :key="index">
                                        <span class="w-40 avatar grey-300"
                                              v-bind:style="avatarStyle(task.contact.name)">
                                            <span>{{ task.contact.name | fixContactName | initials }}</span>
                                        </span>

                                        <div class="ml-2 allow-select">
                                            <span class="d-block font-weight-bold">{{ task.contact.name | fixContactName }}</span>
                                            <span class="d-block">{{ task.phone_number | fixPhone }}</span>
                                            <div class="d-block text-xxs"
                                                 v-if="task.contact.disposition_status_id">
                                                <span class="text-greyish"
                                                      v-if="task.contact.disposition_status_id">
                                                    <i class="material-icons"
                                                       :style="{ color: $options.filters.dispositionStatusColor(task.contact.disposition_status_id) }">label</i>
                                                    <span class="text-grey-900">{{ task.contact.disposition_status_id | dispositionStatusName }}</span>
                                                </span>
                                            </div>
                                            <div class="d-block text-pink-500 text-xxs"
                                                 v-if="task.contact.company_name">
                                                <i class="material-icons">business_center</i>
                                                {{ task.contact.company_name }}
                                            </div>
                                            <div class="d-block text-indigo-500 text-xxs"
                                                 v-if="task.contact.timezone">
                                                <i class="material-icons">public</i>
                                                {{ task.contact.timezone }}
                                            </div>
                                            <div class="d-block">
                                                <span v-if="isSkipped(task.id)"
                                                      class="badge badge-warning">
                                                    Skipped
                                                </span>
                                            </div>
                                        </div>

                                        <div class="ml-auto">
                                            <button class="btn btn-sm btn-outline-danger pull-right"
                                                    v-if="!masterControl"
                                                    @click.prevent="deleteTask(task)">
                                                <i v-if="loading_delete && loading_delete == task.id"
                                                   class="fa fa-spinner fa-spin"></i>
                                                <i v-else
                                                   class="fa fa-trash"></i>
                                            </button>
                                        </div>
                                    </div>

                                    <div class="d-flex align-items-center justify-content-center p-t-sm p-b-sm"
                                         v-if="hasMoreQueuedTasks">
                                        <el-button type="primary"
                                                   :loading="pagination_queued_tasks_loading"
                                                   @click="changePage(AutoDialTaskStatus.STATUS_QUEUED, pagination_queued_tasks.current_page + 1)">
                                            Load More
                                        </el-button>
                                    </div>
                                </el-collapse-item>
                                <el-collapse-item name="completed">
                                    <template slot="title">
                                        <div class="font-120 w-100 d-flex align-items-center justify-content-between">
                                            <span class="pull-left">Completed</span>
                                            <b class="label label-md success m-l-xs pull-right m-r-sm"
                                               v-if="pagination_completed_tasks.total !== undefined">
                                                {{ pagination_completed_tasks.total }}
                                            </b>
                                        </div>
                                    </template>

                                    <div class="el-table__empty-block full-height"
                                         v-show="!pagination_completed_tasks_loading && completed_tasks.length == 0">
                                        <span class="text-lg _700 text-greyish">
                                            No Tasks
                                        </span>
                                    </div>

                                    <div class="d-flex align-items-center p-t-sm p-b-sm"
                                         :class="[ index <= (completed_tasks.length - 2) ? 'b-b' : '']"
                                         v-for="(task, index) in completed_tasks"
                                         :key="index">
                                        <span class="w-40 avatar grey-300"
                                              v-bind:style="avatarStyle(task.contact.name)">
                                            <span>{{ task.contact.name | fixContactName | initials }}</span>
                                        </span>

                                        <div class="ml-2 allow-select">
                                            <span class="d-block font-weight-bold">{{ task.contact.name | fixContactName }}</span>
                                            <span class="d-block">{{ task.phone_number | fixPhone }}</span>
                                            <div class="d-block text-xxs"
                                                 v-if="task.contact.disposition_status_id">
                                                <span class="text-greyish"
                                                      v-if="task.contact.disposition_status_id">
                                                    <i class="material-icons"
                                                       :style="{ color: $options.filters.dispositionStatusColor(task.contact.disposition_status_id) }">label</i>
                                                    <span class="text-grey-900">{{ task.contact.disposition_status_id | dispositionStatusName }}</span>
                                                </span>
                                            </div>
                                            <div class="d-block text-pink-500 text-xxs"
                                                 v-if="task.contact.company_name">
                                                <i class="material-icons">business_center</i>
                                                {{ task.contact.company_name }}
                                            </div>
                                            <div class="d-block text-indigo-500 text-xxs"
                                                 v-if="task.contact.timezone">
                                                <i class="material-icons">public</i>
                                                {{ task.contact.timezone }}
                                            </div>
                                        </div>

                                        <div class="ml-auto">
                                            <button class="btn btn-sm btn-outline-danger pull-right"
                                                    v-if="!masterControl"
                                                    @click.prevent="deleteTask(task)">
                                                <i v-if="loading_delete && loading_delete == task.id"
                                                   class="fa fa-spinner fa-spin"></i>
                                                <i v-else
                                                   class="fa fa-trash"></i>
                                            </button>
                                        </div>
                                    </div>

                                    <div class="d-flex align-items-center justify-content-center p-t-sm p-b-sm"
                                         v-if="hasMoreCompletedTasks">
                                        <el-button type="primary"
                                                   :loading="pagination_completed_tasks_loading"
                                                   @click="changePage(AutoDialTaskStatus.STATUS_COMPLETED, pagination_completed_tasks.current_page + 1)">
                                            Load More
                                        </el-button>
                                    </div>
                                </el-collapse-item>
                                <el-collapse-item name="failed">
                                    <template slot="title">
                                        <div class="font-120 w-100 d-flex align-items-center justify-content-between">
                                            <span class="pull-left">Failed</span>
                                            <b class="label label-md danger m-l-xs pull-right m-r-sm"
                                               v-if="pagination_failed_tasks.total !== undefined">
                                                {{ pagination_failed_tasks.total }}
                                            </b>
                                        </div>
                                    </template>

                                    <div class="el-table__empty-block full-height"
                                         v-show="!pagination_failed_tasks_loading && failed_tasks.length == 0">
                                        <span class="text-lg _700 text-greyish">
                                            No Tasks
                                        </span>
                                    </div>

                                    <div class="d-flex align-items-center p-t-sm p-b-sm"
                                         :class="[ index <= (failed_tasks.length - 2) ? 'b-b' : '']"
                                         v-for="(task, index) in failed_tasks"
                                         :key="index">
                                        <span class="w-40 avatar grey-300"
                                              v-bind:style="avatarStyle(task.contact.name)">
                                            <span>{{ task.contact.name | fixContactName | initials }}</span>
                                        </span>

                                        <div class="ml-2 allow-select">
                                            <span class="d-block font-weight-bold">{{ task.contact.name | fixContactName }}</span>
                                            <span class="d-block">{{ task.phone_number | fixPhone }}</span>
                                            <div class="d-block text-xxs"
                                                 v-if="task.contact.disposition_status_id">
                                                <span class="text-greyish"
                                                      v-if="task.contact.disposition_status_id">
                                                    <i class="material-icons"
                                                       :style="{ color: $options.filters.dispositionStatusColor(task.contact.disposition_status_id) }">label</i>
                                                    <span class="text-grey-900">{{ task.contact.disposition_status_id | dispositionStatusName }}</span>
                                                </span>
                                            </div>
                                            <div class="d-block text-pink-500 text-xxs"
                                                 v-if="task.contact.company_name">
                                                <i class="material-icons">business_center</i>
                                                {{ task.contact.company_name }}
                                            </div>
                                            <div class="d-block text-indigo-500 text-xxs"
                                                 v-if="task.contact.timezone">
                                                <i class="material-icons">public</i>
                                                {{ task.contact.timezone }}
                                            </div>
                                        </div>

                                        <div class="ml-auto">
                                            <button class="btn btn-sm btn-outline-danger pull-right"
                                                    v-if="!masterControl"
                                                    @click.prevent="deleteTask(task)">
                                                <i v-if="loading_delete && loading_delete == task.id"
                                                   class="fa fa-spinner fa-spin"></i>
                                                <i v-else
                                                   class="fa fa-trash"></i>
                                            </button>
                                        </div>
                                    </div>

                                    <div class="d-flex align-items-center justify-content-center p-t-sm p-b-sm"
                                         v-if="hasMoreFailedTasks">
                                        <el-button type="primary"
                                                   :loading="pagination_failed_tasks_loading"
                                                   @click="changePage(AutoDialTaskStatus.STATUS_FAILED, pagination_failed_tasks.current_page + 1)">
                                            Load More
                                        </el-button>
                                    </div>
                                </el-collapse-item>
                                <el-collapse-item name="scheduled">
                                    <template slot="title">
                                        <div class="font-120 w-100 d-flex align-items-center justify-content-between">
                                            <span class="pull-left">Scheduled</span>
                                            <b class="label label-md purple m-l-xs pull-right m-r-sm"
                                               v-if="pagination_scheduled_tasks.total !== undefined">
                                                <span v-if="!loading_schedule_task">{{ pagination_scheduled_tasks.total }}</span>
                                                <i class="material-icons loader"
                                                   v-else>
                                                    &#xE863;
                                                </i>
                                            </b>
                                        </div>
                                    </template>
                                    <div class="el-table__empty-block full-height"
                                         v-show="!pagination_scheduled_tasks_loading && scheduled_tasks.length == 0">
                                        <span class="text-lg _700 text-greyish">
                                            No Tasks
                                        </span>
                                    </div>

                                    <div class="d-flex align-items-center p-t-sm p-b-sm"
                                         :class="[ index <= (scheduled_tasks.length - 2) ? 'b-b' : '']"
                                         v-for="(task, index) in scheduled_tasks"
                                         :key="index">
                                        <span class="w-40 avatar grey-300"
                                              v-bind:style="avatarStyle(task.contact.name)">
                                            <span>{{ task.contact.name | fixContactName | initials }}</span>
                                        </span>

                                        <div class="ml-2 allow-select">
                                            <span class="d-block font-weight-bold">{{ task.contact.name | fixContactName }}</span>
                                            <span class="d-block">{{ task.phone_number | fixPhone }}</span>
                                            <div class="d-block text-xxs"
                                                 v-if="task.contact.disposition_status_id">
                                                <span class="text-greyish"
                                                      v-if="task.contact.disposition_status_id">
                                                    <i class="material-icons"
                                                       :style="{ color: $options.filters.dispositionStatusColor(task.contact.disposition_status_id) }">label</i>
                                                    <span class="text-grey-900">{{ task.contact.disposition_status_id | dispositionStatusName }}</span>
                                                </span>
                                            </div>
                                            <div class="d-block text-pink-500 text-xxs"
                                                 v-if="task.contact.company_name">
                                                <i class="material-icons">business_center</i>
                                                {{ task.contact.company_name }}
                                            </div>
                                            <div class="d-block text-indigo-500 text-xxs"
                                                 v-if="task.contact.timezone">
                                                <i class="material-icons">public</i>
                                                {{ task.contact.timezone }}
                                            </div>
                                            <div class="d-block text-purple text-xxs">
                                                <span>Scheduled for: {{ task.scheduled_at | fixDateTime }}</span>
                                            </div>
                                        </div>

                                        <div class="ml-auto">
                                            <button class="btn btn-sm btn-outline-danger pull-right"
                                                    v-if="!masterControl"
                                                    @click.prevent="deleteTask(task)">
                                                <i v-if="loading_delete && loading_delete == task.id"
                                                   class="fa fa-spinner fa-spin"></i>
                                                <i v-else
                                                   class="fa fa-trash"></i>
                                            </button>
                                        </div>
                                    </div>

                                    <div class="d-flex align-items-center justify-content-center p-t-sm p-b-sm"
                                         v-if="hasMoreScheduledTasks">
                                        <el-button type="primary"
                                                   :loading="pagination_scheduled_tasks_loading"
                                                   @click="changePage(AutoDialTaskStatus.STATUS_SCHEDULED, pagination_scheduled_tasks.current_page + 1)">
                                            Load More
                                        </el-button>
                                    </div>
                                </el-collapse-item>
                            </el-collapse>
                        </div>
                    </div>
                    <div class="col-md full-height">
                        <div class="row box b-a full-height no-box-shadow">
                            <div class="col-12">
                                <div class="no-effect full-height d-flex flex-column">
                                    <div class="box-header pt-0 pb-0">
                                        <div class="row b-b pt-2 pb-2">
                                            <div class="col-12">
                                                <el-form ref="auto_dialer_settings"
                                                         class="w-full"
                                                         :model="auto_dialer"
                                                         :inline="true"
                                                         :rules="rules">
                                                    <div class="row">
                                                        <div class="col fixed-width-500 align-items-center"
                                                             v-if="selected_auto_dial_task && selected_contact">
                                                            <div class="row">
                                                                <div class="col-6 d-flex align-items-center">
                                                                    <div>
                                                                        <el-tooltip class="item"
                                                                                    effect="dark"
                                                                                    :disabled="!avatarTooltip(selected_contact)"
                                                                                    :content="avatarTooltip(selected_contact)"
                                                                                    placement="top">
                                                                            <span class="w-40 avatar grey-300 d-block pull-left"
                                                                                  :style="avatarStyle(selected_contact)">
                                                                                <span>{{ selected_contact.name | fixContactName | initials }}</span>
                                                                            </span>
                                                                        </el-tooltip>
                                                                        <div class="ml-2 d-inline-block width-200">
                                                                            <span class="d-block font-weight-bold text-ellipsis">{{ selected_contact.name | fixContactName }}</span>
                                                                            <span class="d-block">{{ selected_auto_dial_task.phone_number | fixPhone }}</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-6">
                                                                    <div class="list-item pl-0 pr-0">
                                                                            <span class="d-block text-bluish"
                                                                                  v-if="selected_contact.user_id">
                                                                                <i class="material-icons">person</i>
                                                                                {{ getUserName(selected_contact.user_id) }}
                                                                            </span>
                                                                        <span class="d-block text-red-500"
                                                                              v-if="selected_contact.disposition_status_id">
                                                                                <i class="material-icons">folder</i>
                                                                                {{ getDispositionStatusName(selected_contact.disposition_status_id) }}
                                                                            </span>
                                                                        <span class="d-block text-purple-500"
                                                                              v-if="selected_contact.initial_campaign_id && getCampaign(selected_contact.initial_campaign_id)">
                                                                                <i class="material-icons">power</i>
                                                                                {{ getCampaign(selected_contact.initial_campaign_id).name }}
                                                                            </span>
                                                                        <div class="d-block text-pink-500"
                                                                             v-if="selected_contact.company_name">
                                                                            <i class="material-icons">business_center</i>
                                                                            {{ selected_contact.company_name }}
                                                                        </div>
                                                                        <span class="d-block text-new-purple"
                                                                              v-if="selected_contact.cnam_city || selected_contact.cnam_state">
                                                                            <i class="material-icons">location_on</i>
                                                                            <span v-if="selected_contact.cnam_city">{{ selected_contact.cnam_city }}, </span>
                                                                            <span v-if="selected_contact.cnam_state">{{ selected_contact.cnam_state }}</span>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col">
                                                            <div class="pull-right">
                                                                <el-tooltip class="pull-right"
                                                                            effect="power-dialer-start-button-tooltip"
                                                                            placement="bottom"
                                                                            :disabled="!disabledStart">
                                                                    <div slot="content">{{ disabled_start_button_reason_tooltip }}</div>
                                                                    <span>
                                                                        <el-button
                                                                            icon="fa fa-play"
                                                                            size="small"
                                                                            type="primary"
                                                                            class="mb-2 ml-2 pull-right"
                                                                            v-if="!isStarted"
                                                                            :disabled="disabledStart"
                                                                            :loading="loading_queue"
                                                                            @click="start">
                                                                            <span class="ml-2">Start</span>
                                                                        </el-button>
                                                                    </span>
                                                                </el-tooltip>

                                                                <el-button icon="fa fa-stop"
                                                                           size="small"
                                                                           type="warning"
                                                                           class="mb-2 ml-2 pull-right"
                                                                           v-if="isStarted"
                                                                           @click="stop">
                                                                    <span class="ml-2">Stop</span>
                                                                </el-button>

                                                                <el-button icon="fa fa-play"
                                                                           size="small"
                                                                           type="primary"
                                                                           class="mb-2 ml-2 pull-right"
                                                                           v-if="isStarted && isPause"
                                                                           @click="play">
                                                                    <span class="ml-2">Play</span>
                                                                </el-button>

                                                                <el-button icon="fa fa-pause"
                                                                           size="small"
                                                                           type="info"
                                                                           class="blackish mb-2 ml-2 pull-right"
                                                                           v-if="isStarted && !isPause"
                                                                           @click="pause">
                                                                    <span class="ml-2">Pause</span>
                                                                </el-button>

                                                                <el-button icon="fa fa-times"
                                                                           size="small"
                                                                           type="danger"
                                                                           class="mb-2 ml-2 pull-right"
                                                                           v-if="isStarted && selected_auto_dial_task"
                                                                           :loading="loading_skip"
                                                                           @click="skipTask">
                                                                    <span class="ml-2">Skip Task</span>
                                                                </el-button>

                                                                <el-button icon="fa fa-trash"
                                                                           size="small"
                                                                           type="danger"
                                                                           class="mb-2 ml-2 pull-right"
                                                                           v-if="!isStarted && !masterControl"
                                                                           :loading="loading_clear"
                                                                           @click="clearTasks">
                                                                    <span class="ml-2">Clear Tasks</span>
                                                                </el-button>

                                                                <add-auto-dial-tasks class="pull-right"
                                                                                     :small="true"
                                                                                     v-if="!masterControl"
                                                                                     @success="addedTask">
                                                                </add-auto-dial-tasks>

                                                                <el-popover v-model="schedule_task_popover"
                                                                            placement="left-start"
                                                                            v-if="selected_auto_dial_task"
                                                                            width="285"
                                                                            trigger="click">
                                                                    <v-date-picker v-model="future_scheduled_time"
                                                                                   mode="dateTime"
                                                                                   title-position="left"
                                                                                   color="green"
                                                                                   :min-date="new Date()">
                                                                    </v-date-picker>
                                                                    <div class="row no-gutter centered-content mt-2">
                                                                        <button class="btn btn-block greenish"
                                                                                :loading="loading_schedule_task"
                                                                                :disabled="loading_schedule_task"
                                                                                @click.prevent="scheduleTask">
                                                                            <i class="material-icons loader"
                                                                               v-if="loading_schedule_task">&#xE863;</i>
                                                                            Schedule Task
                                                                        </button>
                                                                    </div>

                                                                    <el-button icon="fa fa-calendar"
                                                                               size="small"
                                                                               type="purple"
                                                                               slot="reference"
                                                                               class="mb-2 mr-2 pull-right">
                                                                        <span class="ml-2">Schedule Task</span>
                                                                    </el-button>
                                                                </el-popover>

                                                                <el-button icon="fa fa-list"
                                                                           size="small"
                                                                           class="mb-2 mr-2 pull-right"
                                                                           v-if="isStarted"
                                                                           @click="toggleTaskList">
                                                                    <span class="ml-2">Task List</span>
                                                                </el-button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row d-flex align-items-center flex-row-reverse">
                                                        <div class="col">
                                                            <el-form-item
                                                                v-if="!isStarted"
                                                                class="mb-0 mr-2">
                                                                <div class="mb-0 lh-1">Order by:</div>
                                                                <el-select v-model="order"
                                                                           style="width: 150px"
                                                                           size="small"
                                                                           placeholder="Select warm up duration"
                                                                           :disabled="isStarted"
                                                                           @change="fetchAutoDialTasks()">
                                                                    <el-option
                                                                        v-for="item in orders"
                                                                        :key="item.value"
                                                                        :label="item.label"
                                                                        :value="item.value">
                                                                    </el-option>
                                                                </el-select>
                                                            </el-form-item>
                                                            <el-form-item
                                                                v-if="!isStarted"
                                                                class="mb-0 mr-2">
                                                                <div class="mb-0 lh-1">Warm up duration:</div>
                                                                <el-select v-model="warm_up_seconds"
                                                                           style="width: 150px"
                                                                           size="small"
                                                                           placeholder="Select warm up duration"
                                                                           :disabled="isStarted">
                                                                    <el-option
                                                                        v-for="item in warm_up_seconds_options"
                                                                        :key="item.value"
                                                                        :label="item.label"
                                                                        :value="item.value">
                                                                    </el-option>
                                                                </el-select>
                                                            </el-form-item>
                                                            <el-form-item
                                                                class="mb-0 mr-2"
                                                                prop="outbound_campaign_id"
                                                                :disabled="isStarted">
                                                                <div class="mb-0 lh-1">Line:</div>
                                                                <campaign-selector
                                                                    style="width: 200px"
                                                                    :value="auto_dialer.outbound_campaign_id"
                                                                    :small="true"
                                                                    :multiple="false"
                                                                    :disabled="isStarted"
                                                                    :show_paused="false"
                                                                    v-model="auto_dialer.outbound_campaign_id"
                                                                    @change="changeCampaign">
                                                                </campaign-selector>
                                                            </el-form-item>
                                                            <el-form-item
                                                                class="mb-0 mr-2"
                                                                :disabled="isStarted">
                                                                <div class="mb-0 lh-1">&nbsp;</div>
                                                                <el-checkbox v-model="should_skip"
                                                                             size="small"
                                                                             :disabled="isStarted"
                                                                             border
                                                                             @change="setSkipOutsideDayTimes">
                                                                    Skip outside day times
                                                                </el-checkbox>
                                                            </el-form-item>
                                                            <el-form-item
                                                                class="mb-0 mr-2"
                                                                v-if="hasPowerDialerCrmLink">
                                                                <div class="mb-0 lh-1">&nbsp;</div>
                                                                <el-checkbox v-model="should_open_crm"
                                                                             size="small"
                                                                             border
                                                                             @change="should_open_contact = false">
                                                                    Open CRM Links
                                                                </el-checkbox>
                                                            </el-form-item>
                                                            <el-form-item
                                                                class="mb-0 mr-2">
                                                                <div class="mb-0 lh-1">&nbsp;</div>
                                                                <el-checkbox v-model="should_open_contact"
                                                                             size="small"
                                                                             border
                                                                             @change="should_open_crm = false">
                                                                    Open Contact Page
                                                                </el-checkbox>
                                                            </el-form-item>
                                                        </div>
                                                    </div>
                                                </el-form>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="box-body full-height d-flex col overflow-y-scroll"
                                         :class="[ ((should_open_crm && crm_link) || should_open_contact) ? 'p-0' : '' ]"
                                         v-if="selected_auto_dial_task">
                                        <div id="container-auto-dialer-count-down"
                                             class="shadow"
                                             v-if="countdown_started && selected_contact && isStarted && !isPause && countdown_timer > 0">
                                            <div class="media">
                                                <div class="media-body mr-3">
                                                    <h6 class="mb-0">Calling</h6>
                                                    <p class="mb-0">{{ selected_contact.name }}</p>
                                                </div>
                                                <div class="align-self-center"
                                                     id="count-down-number">
                                                    {{ countdown_timer }}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row full-height w-100 m-0">
                                            <template v-if="should_open_crm && crm_link">
                                                <div class="col-12 p-0">
                                                    <iframe :src="crm_link"
                                                            width="100%"
                                                            height="100%"
                                                            frameBorder="0">
                                                        Browser is not compatible.
                                                    </iframe>
                                                </div>
                                            </template>
                                            <template v-else-if="should_open_contact">
                                                <contact :contact_id="selected_contact.id"
                                                         :embedded="true">
                                                </contact>
                                            </template>
                                            <template v-else>
                                                <div class="col-md-4 b-r">
                                                    <div class="contact-details">
                                                        <contact-details :contact="selected_contact"></contact-details>
                                                    </div>
                                                </div>
                                                <div class="col-md-4 b-r">
                                                    <communication-info
                                                        v-if="selected_auto_dial_task.communication"
                                                        :communication="selected_auto_dial_task.communication"
                                                        hide_contact_disposition
                                                        display_contact
                                                        dialer_mode>
                                                    </communication-info>
                                                </div>
                                                <div class="col-md-4"
                                                     v-if="selected_contact">
                                                    <el-table class="mb-4"
                                                              :data="scripts"
                                                              v-loading="loading_script">
                                                        <el-table-column prop="title"
                                                                         label="Script Title"
                                                                         :min-width="250">
                                                        </el-table-column>
                                                        <el-table-column width="120">
                                                            <template v-slot="scope">
                                                                <el-button class="greenish"
                                                                           @click="showScript(scope.row)"
                                                                           size="mini">
                                                                    <i class="material-icons">launch</i>
                                                                    Open
                                                                </el-button>
                                                            </template>
                                                        </el-table-column>
                                                    </el-table>
                                                    <div class="row b-t">
                                                        <div class="col-6 p-3">
                                                            <h6>Notes</h6>
                                                            <div class="p-1"
                                                                 v-html="$options.filters.nl2br(selected_contact.notes)">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </template>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <el-backtop target="html"
                        ref="backtop"
                        :bottom="20"
                        :right="20">
            </el-backtop>
        </template>
        <upgrade-now-page image-link="/assets/images/PowerDialer.svg"
                          text="Boost your sales team’s productivity! Power Dialer automatically calls contacts one by one from a list so agents have less idle time."
                          extra-text="Upgrade today to unlock this feature"
                          title-text="Power Dialer"
                          kb-link="https://support.aloware.com/en/articles/9037581-power-up-your-outbound-calls-with-aloware-talk-s-power-dialer"
                          class="mt-5"
                          v-if="!shouldShowPowerDialer && shouldShowUpgradeNow">
        </upgrade-now-page>
    </div>
</template>

<script>
import auth from '../auth'
import {mapActions, mapState, mapGetters} from 'vuex'
import {
    acl_mixin,
    avatar_mixin,
    base_report_mixin,
    crm_link_mixin,
    form_validation_mixin,
    scroll_mixin,
    supervisor_mixin
} from '../mixins'
import ScriptDialog from '../components/script-dialog'
import PowerDialerDepreciationDialog from '../components/power-dialer-depreciation-dialog.vue'
import * as UserOutboundCallingModes from '../constants/user-outbound-calling-modes'
import * as AutoDialTaskStatus from '../constants/auto-dial-task-status'
import * as AutoDialTask from '../constants/auto-dial-task'
import * as PowerDialer from '../constants/power-dialer'
import UpgradeNowPage from "../components/upgrade-now-page.vue"

export default {
    mixins: [
        acl_mixin,
        scroll_mixin,
        avatar_mixin,
        base_report_mixin,
        crm_link_mixin,
        form_validation_mixin,
        supervisor_mixin
    ],

    components: {
        UpgradeNowPage,
        ScriptDialog,
        PowerDialerDepreciationDialog
    },

    data() {
        return {
            auth: auth,
            activeName: 'queued',
            per_page: 100,
            selected_auto_dial_task: null,
            should_skip: true,
            should_open_crm: false,
            should_open_contact: false,
            auto_dialer_timer_enabled: false,
            task_list_open: true,
            auto_dialer: {
                outbound_campaign_id: null,
                ratio: 1
            },
            ratio_options: [
                {
                    id: 1,
                    label: '1:1',
                    value: 1
                },
                {
                    id: 2,
                    label: '2:1',
                    value: 2
                },
                {
                    id: 3,
                    label: '3:1',
                    value: 3
                },
                {
                    id: 4,
                    label: '4:1',
                    value: 4
                },
                {
                    id: 5,
                    label: '5:1',
                    value: 5
                }
            ],
            rules: {
                outbound_campaign_id: [
                    {
                        required: true,
                        message: 'Please select an outbound line',
                        trigger: 'change'
                    }
                ]
            },
            isStarted: false,
            isPause: false,
            loading: false,
            loading_clear: false,
            loading_script: false,
            loading_delete: null,
            scripts: [],
            selected_script: null,
            queued_tasks: [],
            pagination_queued_tasks: {
                type: Object,
                required: true
            },
            pagination_queued_tasks_loading: false,
            in_progress_tasks: [],
            pagination_in_progress_tasks: {
                type: Object,
                required: true
            },
            pagination_in_progress_tasks_loading: false,
            completed_tasks: [],
            pagination_completed_tasks: {
                type: Object,
                required: true
            },
            pagination_completed_tasks_loading: false,
            failed_tasks: [],
            pagination_failed_tasks: {
                type: Object,
                required: true
            },
            pagination_failed_tasks_loading: false,
            scheduled_tasks: [],
            pagination_scheduled_tasks: {
                type: Object,
                required: true
            },
            pagination_scheduled_tasks_loading: false,
            selected_contact: {
                id: null,
                first_name: null,
                last_name: null,
                cnam_country: null,
                cnam_state: null,
                cnam_city: null,
                cnam_zipcode: null,
                company_name: null,
                address: null,
                email: null,
                timezone: null,
                notes: '',
                csf1: null,
                csf2: null,
                date_of_birth: false,
                phone_number: null,
                is_blocked: false,
                communications: [],
                campaign_ids: [],
                user: null,
                user_id: null,
                lrn_type: null,
                is_dnc: false,
                tags: [],
                tag_ids: []
            },
            warm_up_seconds: 0,
            countdown_timer: 0,
            countdown_interval: null,
            countdown_started: false,
            last_task: null,
            is_running: false,
            is_impersonated: localStorage.getItem('impersonate'),
            schedule_task_popover: false,
            future_scheduled_time: new Date(),
            loading_schedule_task: false,
            orders: [
                {
                    value: AutoDialTask.ORDER_BY_DEFAULT,
                    label: 'Default'
                },
                {
                    value: AutoDialTask.ORDER_BY_TIMEZONE,
                    label: 'Timezone'
                }
            ],
            order: AutoDialTask.ORDER_BY_DEFAULT,
            loading_assign_tasks: false,
            loading_queue: false,
            crm_link: null,
            skipped_list: [],
            loading_skip: false,
            AutoDialTaskStatus,
            PowerDialer,
            AutoDialTask,
            schedule_task_from_pipedrive: null,
            is_loading: true
        }
    },

    computed: {
        ...mapState(['campaigns', 'users', 'disposition_statuses', 'usage', 'on_call']),
        ...mapState('cache', ['current_company', 'skip_outside_day_times']),
        ...mapGetters('cache', ['isSimpSocial']),

        power_dialer_settings() {
            let settings = auth.user.profile.company.power_dialer_settings
            if (settings !== null && settings.open_time && settings.close_time) {
                return settings
            }

            return {
                open_time: '09:00:00',
                close_time: '18:00:00'
            }
        },

        campaignsAlphabeticalOrder() {
            if (this.campaigns) {
                let campaigns = _.clone(this.campaigns)
                return campaigns.sort((a, b) => {
                    let textA = a.name.toUpperCase()
                    let textB = b.name.toUpperCase()
                    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0
                })
            }

            return []
        },

        isLargeEnough() {
            let valid_sizes = ['xxl', 'xl', 'lg', 'md']
            if (valid_sizes.includes(this.$mq)) {
                return true
            }
            return false
        },

        scriptDialogTitle() {
            if (this.selected_script) {
                return this.selected_script.title
            }

            return ''
        },

        showScriptDialog() {
            return this.selected_script != null
        },

        hasMoreQueuedTasks() {
            if (!this.pagination_queued_tasks.current_page) {
                return false
            }
            return this.pagination_queued_tasks.total > this.queued_tasks.length
        },

        hasMoreInProgressTasks() {
            if (!this.pagination_in_progress_tasks.current_page) {
                return false
            }
            return this.per_page * this.pagination_in_progress_tasks.current_page < this.pagination_in_progress_tasks.length
        },

        hasMoreCompletedTasks() {
            if (!this.pagination_completed_tasks.current_page) {
                return false
            }
            return this.per_page * this.pagination_completed_tasks.current_page < this.pagination_completed_tasks.total
        },

        hasMoreFailedTasks() {
            if (!this.pagination_failed_tasks.current_page) {
                return false
            }
            return this.per_page * this.pagination_failed_tasks.current_page < this.pagination_failed_tasks.total
        },

        hasMoreScheduledTasks() {
            if (!this.pagination_scheduled_tasks.current_page) {
                return false
            }
            return this.per_page * this.pagination_scheduled_tasks.current_page < this.pagination_scheduled_tasks.total
        },

        warm_up_seconds_options() {
            let data = []
            for (let i = 0; i <= 60; i += 5) {
                data.push({
                    value: i,
                    label: i === 0 ? 'No Warm Up' : i + ' seconds'
                })
            }
            return data
        },

        masterControl() {
            return this.current_company && this.current_company.disable_power_dialer_add && !this.hasRole('Company Admin') && !this.is_impersonated
        },

        disabledStart() {
            return this.on_call ||
                !this.validated ||
                this.pagination_queued_tasks === undefined ||
                this.queued_tasks.length === 0 ||
                this.loading_queue
        },

        disabled_start_button_reason_tooltip() {
            if (this.on_call) {
                return 'Cannot start power dialer while on call or during wrap-up. Please finish the call before starting (red phone button - top left of menu) '
            }

            if (this.pagination_queued_tasks === undefined || this.queued_tasks.length < 1) {
                return 'You must select contacts'
            }

            return ''
        }
    },

    created() {
        if (this.hasReporterAccess || this.forceTalk) {
            this.goBack()
        }

        this.should_skip = this.skip_outside_day_times

        VueEvent.listen('pause_power_dialer', () => {
            if (this.isStarted && !this.isPause) {
                this.pause()
            }
        })

        VueEvent.listen('auto_dial_task_created', (auto_dial_task) => {
            if (auto_dial_task.user_id !== this.auth?.user?.profile?.id) {
                return
            }
            this.addTaskToList(auto_dial_task)
        })

        VueEvent.listen('auto_dial_task_updated', (auto_dial_task) => {
            if (auto_dial_task.user_id !== this.auth?.user?.profile?.id) {
                return
            }

            console.log('auto_dial_task_updated', auto_dial_task.status)

            switch (auto_dial_task.status) {
                case AutoDialTaskStatus.STATUS_IN_PROGRESS:
                    this.removeTaskFromList(auto_dial_task, AutoDialTaskStatus.STATUS_QUEUED)
                    this.removeTaskFromList(auto_dial_task, AutoDialTaskStatus.STATUS_SCHEDULED)
                    this.selectTask(auto_dial_task)
                    this.getScripts(auto_dial_task.communication_id)
                    break
                case AutoDialTaskStatus.STATUS_QUEUED:
                    this.removeTaskFromList(auto_dial_task, AutoDialTaskStatus.STATUS_SCHEDULED)
                    this.fetchAutoDialTasks(AutoDialTaskStatus.STATUS_QUEUED)
                    break
                case AutoDialTaskStatus.STATUS_FAILED:
                case AutoDialTaskStatus.STATUS_COMPLETED:
                    this.removeTaskFromList(auto_dial_task, AutoDialTaskStatus.STATUS_IN_PROGRESS)
                    if (this.auto_dialer_timer_enabled) {
                        this.checkAutoDialer()
                    }
                    break
            }

            this.addTaskToList(auto_dial_task)
        })

        VueEvent.listen('auto_dial_task_deleting', (auto_dial_task) => {
            if (auto_dial_task.user_id !== this.auth?.user?.profile?.id) {
                return
            }

            this.removeTaskFromList(auto_dial_task, auto_dial_task.status)
        })

        VueEvent.listen('contact_updated', (data) => {
            // check data loaded
            if (this.selected_contact && this.selected_contact.id === data.id) {
                this.updateSelectedContact(data)
            }
        })

        VueEvent.listen('bulk_auto_dialer_tasks_created', (data) => {
            if (this.schedule_task_from_pipedrive) {
                this.fetchAutoDialTasks(AutoDialTaskStatus.STATUS_SCHEDULED)
                this.loading_schedule_task = false
                this.schedule_task_from_pipedrive = false
            } else {
                this.fetchAutoDialTasks(AutoDialTaskStatus.STATUS_QUEUED)
                this.loading_queue = false
            }
        })

        VueEvent.listen('call_ended', () => {
            this.resetSelectedTaskAndContact()
        })

        VueEvent.listen('integration_pipedrive_app_extension_power_dialer_completed', (data) => {
            if (data.meta_data.future_scheduled) {
                this.schedule_task_from_pipedrive = true
                this.loading_schedule_task = true
                this.activeName = 'scheduled'
            } else {
                this.loading_queue = true
            }
        })

        VueEvent.listen('power_dialer_contact_removed', contact_id => {
            // try to find the contact and remove it if found
            const index = this.queued_tasks.findIndex(task => task.contact.id === contact_id)

            if (index === -1) {
                return
            }

            // remove from queue if found
            this.queued_tasks.splice(index, 1)

            if (this.pagination_queued_tasks.total > 0) {
                this.pagination_queued_tasks.total--
            }
        })

        VueEvent.listen('new_call_failed', (params) => {
            this.$notify({
                duration: 2000,
                offset: 175,
                title: 'Phone',
                message: 'We cannot place the call, something went wrong.',
                type: 'error',
                showClose: true
            })
            console.log('Error making a new call to: ', params?.to)
        })

        // @custom for SimpSocial
        if (this.current_company && this.current_company.reseller_id == 357) {
            this.should_open_contact = true
        }

        this.fetchAutoDialTasks()
    },

    mounted() {
        this.setTitle()

        if (this.campaigns) {
            // find default outbound campaign
            this.findDefaultOutboundCampaign()
        }

        this.preValidateForm('auto_dialer_settings')
    },

    methods: {
        isSkipped(task_id) {
            return this.skipped_list.includes(task_id)
        },

        resetSelectedTaskAndContact() {
            this.resetSelectedTask()
            this.resetSelectedContact()
        },

        fetchAutoDialTasks(status) {
            this.resetTasks(status)
            switch (status) {
                case AutoDialTaskStatus.STATUS_QUEUED:
                    this.getTasks(AutoDialTaskStatus.STATUS_QUEUED, 1)
                    break
                case AutoDialTaskStatus.STATUS_IN_PROGRESS:
                    this.getTasks(AutoDialTaskStatus.STATUS_IN_PROGRESS, 1)
                    break
                case AutoDialTaskStatus.STATUS_COMPLETED:
                    this.getTasks(AutoDialTaskStatus.STATUS_COMPLETED, 1)
                    break
                case AutoDialTaskStatus.STATUS_FAILED:
                    this.getTasks(AutoDialTaskStatus.STATUS_FAILED, 1)
                    break
                case AutoDialTaskStatus.STATUS_SCHEDULED:
                    this.getTasks(AutoDialTaskStatus.STATUS_SCHEDULED, 1)
                    break
                default:
                    this.getTasks(AutoDialTaskStatus.STATUS_QUEUED, 1)
                    this.getTasks(AutoDialTaskStatus.STATUS_IN_PROGRESS, 1)
                    this.getTasks(AutoDialTaskStatus.STATUS_SCHEDULED, 1)
                    this.getTasks(AutoDialTaskStatus.STATUS_COMPLETED, 1)
                    this.getTasks(AutoDialTaskStatus.STATUS_FAILED, 1)
            }
        },

        fetchContactInfo(contact_id, get_contact_try = 1) {
            return axios.get('/api/v1/contact/' + contact_id + '/info').then(res => {
                this.selected_contact = res.data
                this.crm_link = this.getPowerDialerCrmLink(this.selected_contact)
                this.selected_contact.tag_ids = this.selected_contact.tags.map((a) => a.id)
            }).catch(err => {
                get_contact_try++
                // check if we have found the contact after 5 retries
                if (get_contact_try > 5) {
                    // error
                    console.log("An error occurred while getting the contact", err)
                    this.$root.handleErrors(err.response)
                    this.resetSelectedContact()
                } else {
                    return this.fetchContactInfo(contact_id, get_contact_try)
                }
            })
        },

        updateSelectedContact(contact) {
            for (let index in contact) {
                if (index !== 'communications') {
                    this.selected_contact[index] = contact[index]
                }
            }
        },

        setTitle() {
            axios.get('/get-statics').then(res => {
                let name = res.data.name
                this.setPageTitle('PowerDialer - ' + name)
            }).catch(err => {
                this.setPageTitle('PowerDialer - Aloware')
                console.log(err)
                this.$root.handleErrors(err.response)
            })
        },

        changeCampaign(campaign_id) {
            this.auto_dialer.outbound_campaign_id = campaign_id
            this.preValidateForm('auto_dialer_settings')
        },

        findDefaultOutboundCampaign() {
            // sanity check: skip assigning outbound campaign id if there's a selected one
            if (this.auto_dialer.outbound_campaign_id) {
                return
            }

            this.auto_dialer.outbound_campaign_id = null

            // default PowerDialer outbound line
            if (this.current_company && this.current_company.default_power_dialer_campaign_id) {
                this.auto_dialer.outbound_campaign_id = this.current_company.default_power_dialer_campaign_id
                this.preValidateForm('auto_dialer_settings')
                return
            }

            // force outbound line on all users
            if (this.current_company && this.current_company.default_outbound_campaign_id && this.current_company.force_outbound_line) {
                this.auto_dialer.outbound_campaign_id = this.current_company.default_outbound_campaign_id
                this.preValidateForm('auto_dialer_settings')
                return
            }

            // outbound line is set to use account default and account has a default
            if (this.current_company && this.current_company.default_outbound_campaign_id && this.auth.user.profile.outbound_calling_mode == UserOutboundCallingModes.OUTBOUND_CALLING_MODE_DEFAULT && !this.auth.user.profile.default_outbound_campaign_id) {
                this.auto_dialer.outbound_campaign_id = this.current_company.default_outbound_campaign_id
                this.preValidateForm('auto_dialer_settings')
                return
            }

            // user has a default outbound line
            if (this.auth && this.auth.user && this.auth.user.profile && this.auth.user.profile.default_outbound_campaign_id && this.auth.user.profile.outbound_calling_mode == UserOutboundCallingModes.OUTBOUND_CALLING_MODE_DEFAULT) {
                this.auto_dialer.outbound_campaign_id = this.auth.user.profile.default_outbound_campaign_id
                this.preValidateForm('auto_dialer_settings')
                return
            }

            // user has to choose outbound line every time
            if (this.auth && this.auth.user && this.auth.user.profile && this.auth.user.profile.outbound_calling_mode == UserOutboundCallingModes.OUTBOUND_CALLING_MODE_ALWAYS_ASK) {
                this.auto_dialer.outbound_campaign_id = null
            }
            this.preValidateForm('auto_dialer_settings')
        },

        start() {
            if (this.validateForm('auto_dialer_settings')) {
                VueEvent.fire('init_dialer')
                this.last_task = null
                this.skipped_list = []
                this.isStarted = true
                this.task_list_open = false
                this.play()
            }
        },

        stop() {
            this.isStarted = false
            this.task_list_open = true
            this.pause()
            this.activeName = 'queued'
            this.resetSelectedTaskAndContact()
            this.skipped_list = []
            this.last_task = null
        },

        pause() {
            this.isPause = true
            this.auto_dialer_timer_enabled = false
            this.clearWarmUpCountDown()
        },

        play() {
            this.isPause = false
            this.auto_dialer_timer_enabled = true
            this.activeName = 'in-progress'
        },

        getTasks(status, page) {
            let current_tasks = 0
            let last_task
            this.loading = true
            switch (status) {
                case AutoDialTaskStatus.STATUS_QUEUED:
                    this.pagination_queued_tasks_loading = true
                    current_tasks = this.queued_tasks.length
                    last_task = (this.queued_tasks.length > 0) ? this.queued_tasks[this.queued_tasks.length - 1] : null
                    break
                case AutoDialTaskStatus.STATUS_IN_PROGRESS:
                    this.pagination_in_progress_tasks_loading = true
                    current_tasks = this.in_progress_tasks.length
                    last_task = (this.in_progress_tasks.length > 0) ? this.in_progress_tasks[this.in_progress_tasks.length - 1] : null
                    break
                case AutoDialTaskStatus.STATUS_COMPLETED:
                    this.pagination_completed_tasks_loading = true
                    current_tasks = this.completed_tasks.length
                    last_task = (this.completed_tasks.length > 0) ? this.completed_tasks[this.completed_tasks.length - 1] : null
                    break
                case AutoDialTaskStatus.STATUS_FAILED:
                    this.pagination_failed_tasks_loading = true
                    current_tasks = this.failed_tasks.length
                    last_task = (this.failed_tasks.length > 0) ? this.failed_tasks[this.failed_tasks.length - 1] : null
                    break
                case AutoDialTaskStatus.STATUS_SCHEDULED:
                    this.pagination_scheduled_tasks_loading = true
                    current_tasks = this.scheduled_tasks.length
                    last_task = (this.scheduled_tasks.length > 0) ? this.scheduled_tasks[this.scheduled_tasks.length - 1] : null
                    break
            }

            return axios.get('/api/v1/auto-dialer', {
                params: {
                    status,
                    page,
                    per_page: this.per_page,
                    order: this.order,
                    current_tasks: current_tasks,
                    last_task_id: last_task ? last_task.id : null
                }
            }).then(res => {
                switch (status) {
                    case AutoDialTaskStatus.STATUS_QUEUED:
                        this.pagination_queued_tasks = res.data
                        this.pagination_queued_tasks_loading = false
                        this.queued_tasks = this.queued_tasks.concat(res.data.data)
                        break
                    case AutoDialTaskStatus.STATUS_IN_PROGRESS:
                        this.pagination_in_progress_tasks = res.data
                        this.pagination_in_progress_tasks_loading = false
                        this.in_progress_tasks = this.in_progress_tasks.concat(res.data.data)
                        break
                    case AutoDialTaskStatus.STATUS_COMPLETED:
                        this.pagination_completed_tasks = res.data
                        this.pagination_completed_tasks_loading = false
                        this.completed_tasks = this.completed_tasks.concat(res.data.data)
                        break
                    case AutoDialTaskStatus.STATUS_FAILED:
                        this.pagination_failed_tasks = res.data
                        this.pagination_failed_tasks_loading = false
                        this.failed_tasks = this.failed_tasks.concat(res.data.data)
                        break
                    case AutoDialTaskStatus.STATUS_SCHEDULED:
                        this.pagination_scheduled_tasks = res.data
                        this.pagination_scheduled_tasks_loading = false
                        this.scheduled_tasks = this.scheduled_tasks.concat(res.data.data)
                        break
                }
                this.loading = false

                return Promise.resolve(res)
            }).catch(err => {
                console.log(err)
                this.loading = false

                switch (status) {
                    case AutoDialTaskStatus.STATUS_QUEUED:
                        this.pagination_queued_tasks_loading = false
                        break
                    case AutoDialTaskStatus.STATUS_IN_PROGRESS:
                        this.pagination_in_progress_tasks_loading = false
                        break
                    case AutoDialTaskStatus.STATUS_COMPLETED:
                        this.pagination_completed_tasks_loading = false
                        break
                    case AutoDialTaskStatus.STATUS_FAILED:
                        this.pagination_failed_tasks_loading = false
                        break
                    case AutoDialTaskStatus.STATUS_SCHEDULED:
                        this.pagination_scheduled_tasks_loading = false
                        break
                }

                return Promise.reject(err)
            })
        },

        getPageNumbers(pagination) {
            if (!pagination || !pagination.to) {
                return []
            }
            let pagesArray = []
            for (let page = 1; page <= pagination.last_page; page++) {
                pagesArray.push(page)
            }
            return pagesArray
        },

        changePage(status, page) {
            if (this.loading) {
                return
            }

            let pagination

            switch (status) {
                case AutoDialTaskStatus.STATUS_QUEUED:
                    pagination = this.pagination_queued_tasks
                    break
                case AutoDialTaskStatus.STATUS_IN_PROGRESS:
                    pagination = this.pagination_in_progress_tasks
                    break
                case AutoDialTaskStatus.STATUS_COMPLETED:
                    pagination = this.pagination_completed_tasks
                    break
                case AutoDialTaskStatus.STATUS_FAILED:
                    pagination = this.pagination_failed_tasks
                    break
                case AutoDialTaskStatus.STATUS_SCHEDULED:
                    pagination = this.pagination_scheduled_tasks
                    break
            }

            let old_page = pagination.current_page
            pagination.current_page = page
            this.getTasks(status, page).then(() => {
                this.$emit('paginate')
            }).catch(err => {
                console.log(err)
                pagination.current_page = old_page
                this.getTasks(status, old_page)
            })
        },

        async checkAutoDialer() {
            console.log('Checking PowerDialer for tasks')

            // make sure the dialer is not active before making another call
            if (!this.on_call && !this.is_running) {
                if (this.in_progress_tasks.length < this.auto_dialer.ratio && !this.isPause && this.queued_tasks.length > 0) {
                    let number_of_tasks = this.auto_dialer.ratio - this.in_progress_tasks.length
                    let tasks = this.queued_tasks.filter(task => !this.isSkipped(task.id)).slice(0, number_of_tasks)

                    for (let task of tasks) {
                        // don't run the same task twice
                        if (this.last_task && task.id == this.last_task.id) {
                            continue
                        }

                        await this.selectTask(task)

                        if (this.warm_up_seconds > 0) {
                            this.startWarmUpCountDown(task)
                        } else {
                            this.runTask(task)
                        }
                    }

                    // if there are no more unskipped tasks
                    if (tasks.length == 0) {
                        this.fetchAutoDialTasks(AutoDialTaskStatus.STATUS_QUEUED)
                        this.$notify({
                            duration: 5000,
                            offset: 175,
                            title: 'PowerDialer',
                            message: "All remaining tasks are skipped.",
                            type: 'warning',
                            showClose: true
                        })
                        this.stop()
                        return
                    }

                    return
                }
            }

            if (this.queued_tasks.length == 0 && this.in_progress_tasks.length == 0) {
                console.log('Stopping PowerDialer: No more tasks found')
                this.stop()
            }
        },

        startWarmUpCountDown(task) {
            if (this.warm_up_seconds == 0 || this.countdown_started) {
                return
            }

            this.countdown_started = true
            this.countdown_timer = this.warm_up_seconds
            this.countdown_interval = setInterval(() => {
                this.countdown_timer--
                console.log(`Counting down: ${this.countdown_timer}`)
                if (this.countdown_timer == 0) {
                    this.clearWarmUpCountDown()
                    this.runTask(task)
                }
            }, 1000)
        },

        clearWarmUpCountDown() {
            this.countdown_started = false
            this.countdown_timer = 0

            clearInterval(this.countdown_interval)
            console.log("Stopped countdown")
        },

        async selectTask(auto_dial_task) {
            // exit function when auto_dial_task is not set
            if (!auto_dial_task) {
                return
            }
            // load selected contact once
            if (!this.selected_contact || this.selected_contact.id !== auto_dial_task.contact_id) {
                await this.fetchContactInfo(auto_dial_task.contact_id)
            }
            this.selected_auto_dial_task = auto_dial_task
        },

        runTask(auto_dial_task) {
            console.log('Running auto dial task: #' + auto_dial_task.id)
            this.is_running = true

            let timezone = auto_dial_task.contact.timezone

            // if there is no contact tz then
            // use the company tz
            if (this.skip_outside_day_times && !timezone) {
                console.log(`Skipped task #${auto_dial_task.id} because contact did not have a timezone`)
                this.skipSingleTask(auto_dial_task, "Task is skipped because timezone has not been set for this contact. Pushed the task to the bottom of the list.").then(() => {
                    this.resetPowerDialerQueue()
                })
                return
            }

            // check if it's outside working hours or not
            if (this.skip_outside_day_times && timezone) {
                // time without time zone
                const start_day = moment.tz(this.power_dialer_settings.open_time, 'HH:mm:ss', timezone)
                // time without time zone
                const end_day = moment.tz(this.power_dialer_settings.close_time, 'HH:mm:ss', timezone)
                // local time with timezone
                const contact_local_time = moment.tz(moment.tz(timezone).format('HH:mm:ss'), 'HH:mm:ss', timezone)
                // check if time is in between open and close
                if (!contact_local_time.isBetween(start_day, end_day)) {
                    console.log(`Skipped task #${auto_dial_task.id} in ${timezone} timezone because it was outside day times. ` + contact_local_time.format('h:mm a') + ' is not in between ' + start_day.format('h:mm a') + ' - ' + end_day.format('h:mm a'))
                    this.skipSingleTask(auto_dial_task, "Task is skipped because it's outside day times. Pushed the task to the bottom of the list.").then(() => {
                        this.resetPowerDialerQueue()
                    })
                    return
                }
            }

            // instead of pinging backend directly call client
            VueEvent.fire('make_new_call', {
                task: 'auto_dial_task:' + auto_dial_task.id,
                phone_number: auto_dial_task.phone_number,
                campaign_id: this.auto_dialer.outbound_campaign_id
            })
            this.last_task = auto_dial_task
        },

        deleteTask(auto_dial_task) {
            this.$confirm('Are you sure?', 'Warning', {
                confirmButtonText: 'OK',
                cancelButtonText: 'Cancel',
                customClass: 'width-500 fixed',
                type: 'warning'
            }).then(() => {
                this.loading_delete = auto_dial_task.id
                axios.delete('/api/v1/auto-dialer/' + auto_dial_task.id).then(() => {
                    VueEvent.fire('auto_dial_task_deleting', auto_dial_task)
                    this.$notify({
                        duration: 2000,
                        offset: 175,
                        title: 'PowerDialer',
                        message: 'Task has been deleted successfully.',
                        type: 'success',
                        showClose: true
                    })
                    this.loading_delete = null
                }).catch(err => {
                    this.$root.handleErrors(err.response)
                    this.loading_delete = null
                })
            }).catch(() => {
            })
        },

        skipTask() {
            if (!this.selected_auto_dial_task) {
                return
            }

            console.log(`Manually skipped ${this.selected_auto_dial_task.id}`)
            this.skipSingleTask(this.selected_auto_dial_task, "Task is manually skipped", true).then(() => {
                if (this.on_call) {
                    VueEvent.fire('hangup_call')
                }

                this.resetPowerDialerQueue(true)
            })
        },

        resetPowerDialerQueue(in_progress = false) {
            if (in_progress) {
                this.fetchAutoDialTasks(AutoDialTaskStatus.STATUS_IN_PROGRESS)
            }
            this.resetSelectedTaskAndContact()
            this.clearWarmUpCountDown()
        },

        /**
         * Skip and move the task to bottom
         * of the dialer
         *
         * @param auto_dial_task
         */
        skipSingleTask(auto_dial_task, message, skip_task = false) {
            this.loading_skip = true
            return axios.post(`/api/v1/auto-dialer/${auto_dial_task.id}/skip`, {
                skip_task: skip_task
            }).then(res => {
                if (auto_dial_task.status != AutoDialTaskStatus.STATUS_QUEUED) {
                    // add to bottom of list
                    auto_dial_task.direction = PowerDialer.DIRECTION_BOTTOM
                    this.addTaskToList(auto_dial_task)
                }
                this.skipped_list.push(auto_dial_task.id)
                this.loading_skip = false
                this.$notify({
                    duration: 5000,
                    offset: 175,
                    title: 'PowerDialer',
                    message: message,
                    type: 'warning',
                    showClose: true
                })
                return Promise.resolve(res)
            }).catch(err => {
                this.$root.handleErrors(err.response)
                this.loading_skip = false
                return Promise.reject(err)
            })
        },

        clearTasks() {
            this.$confirm('Are you sure?', 'Warning', {
                confirmButtonText: 'OK',
                cancelButtonText: 'Cancel',
                customClass: 'width-500 fixed',
                type: 'warning'
            }).then(() => {
                this.loading_clear = true
                axios.post('/api/v1/auto-dialer/clear-tasks').then(() => {
                    this.loading_clear = false
                    this.resetTasks()
                    this.$notify({
                        duration: 2000,
                        offset: 175,
                        title: 'PowerDialer',
                        message: 'All tasks have been cleared successfully.',
                        type: 'success',
                        showClose: true
                    })
                }).catch(err => {
                    this.$root.handleErrors(err.response)
                    this.loading_clear = false
                })
            }).catch(() => {
            })
        },

        resetTasks(status) {
            switch (status) {
                case AutoDialTaskStatus.STATUS_QUEUED:
                    this.pagination_queued_tasks.total = 0
                    this.queued_tasks = []
                    break
                case AutoDialTaskStatus.STATUS_IN_PROGRESS:
                    this.pagination_in_progress_tasks.total = 0
                    this.in_progress_tasks = []
                    break
                case AutoDialTaskStatus.STATUS_COMPLETED:
                    this.pagination_completed_tasks.total = 0
                    this.completed_tasks = []
                    break
                case AutoDialTaskStatus.STATUS_FAILED:
                    this.pagination_failed_tasks.total = 0
                    this.failed_tasks = []
                    break
                case AutoDialTaskStatus.STATUS_SCHEDULED:
                    this.pagination_scheduled_tasks.total = 0
                    this.scheduled_tasks = []
                    break
                default:
                    this.pagination_queued_tasks.total = 0
                    this.queued_tasks = []
                    this.pagination_in_progress_tasks.total = 0
                    this.in_progress_tasks = []
                    this.pagination_completed_tasks.total = 0
                    this.completed_tasks = []
                    this.pagination_failed_tasks.total = 0
                    this.failed_tasks = []
                    this.pagination_scheduled_tasks.total = 0
                    this.scheduled_tasks = []
            }

            this.resetSelectedTaskAndContact()
        },

        resetSelectedContact() {
            this.selected_contact = {
                id: null,
                first_name: null,
                last_name: null,
                cnam_country: null,
                cnam_state: null,
                cnam_city: null,
                cnam_zipcode: null,
                company_name: null,
                address: null,
                email: null,
                timezone: null,
                notes: '',
                csf1: null,
                csf2: null,
                date_of_birth: false,
                phone_number: null,
                is_blocked: false,
                communications: [],
                campaign_ids: [],
                user: null,
                user_id: null,
                lrn_type: null,
                is_dnc: false,
                tags: [],
                tag_ids: []
            }
            this.crm_link = null
        },

        resetSelectedTask() {
            this.selected_auto_dial_task = null
            this.selected_script = null
            this.scripts = []
            this.is_running = false
        },

        getScripts(communication_id) {
            if (!communication_id) {
                return
            }

            this.scripts = []
            this.loading_script = true
            axios.get('/api/v1/communication/' + communication_id + '/scripts').then((res) => {
                this.loading_script = false
                this.scripts = res.data
            }).catch((err) => {
                this.loading_script = false
                this.$root.handleErrors(err.response)
            })
        },

        showScript(script) {
            this.selected_script = script
        },

        hideScript() {
            this.selected_script = null
        },

        addTaskToList(auto_dial_task) {
            let task_list
            let pagination
            switch (auto_dial_task.status) {
                case AutoDialTaskStatus.STATUS_QUEUED:
                    pagination = this.pagination_queued_tasks
                    task_list = this.queued_tasks
                    break
                case AutoDialTaskStatus.STATUS_IN_PROGRESS:
                    pagination = this.pagination_in_progress_tasks
                    task_list = this.in_progress_tasks
                    break
                case AutoDialTaskStatus.STATUS_COMPLETED:
                    pagination = this.pagination_completed_tasks
                    task_list = this.completed_tasks
                    break
                case AutoDialTaskStatus.STATUS_FAILED:
                    pagination = this.pagination_failed_tasks
                    task_list = this.failed_tasks
                    break
                case AutoDialTaskStatus.STATUS_SCHEDULED:
                    pagination = this.pagination_scheduled_tasks
                    task_list = this.scheduled_tasks
                    break
            }

            if (pagination.current_page) {
                let found = task_list.find(task => task.id === auto_dial_task.id)
                if (!found) {
                    pagination.total += 1

                    if (task_list.length < this.per_page) {
                        if (auto_dial_task.status == AutoDialTaskStatus.STATUS_QUEUED) {
                            if (auto_dial_task.direction == PowerDialer.DIRECTION_TOP) {
                                task_list.unshift(auto_dial_task)
                            }

                            if (auto_dial_task.direction == PowerDialer.DIRECTION_BOTTOM) {
                                task_list.push(auto_dial_task)
                            }
                        } else {
                            task_list.push(auto_dial_task)
                        }
                    }

                    if (task_list.length >= this.per_page) {
                        if (auto_dial_task.status == AutoDialTaskStatus.STATUS_QUEUED) {
                            if (auto_dial_task.direction == PowerDialer.DIRECTION_TOP) {
                                task_list.unshift(auto_dial_task)
                                task_list.pop()
                            }
                        }
                    }
                }
            }

            switch (auto_dial_task.status) {
                case AutoDialTaskStatus.STATUS_QUEUED:
                    this.pagination_queued_tasks = pagination
                    this.queued_tasks = task_list
                    break
                case AutoDialTaskStatus.STATUS_IN_PROGRESS:
                    this.pagination_in_progress_tasks = pagination
                    this.in_progress_tasks = task_list
                    break
                case AutoDialTaskStatus.STATUS_COMPLETED:
                    this.pagination_completed_tasks = pagination
                    this.completed_tasks = task_list
                    break
            }
        },

        removeTaskFromList(auto_dial_task, status) {
            let task_list
            let pagination
            switch (status) {
                case AutoDialTaskStatus.STATUS_QUEUED:
                    pagination = this.pagination_queued_tasks
                    task_list = this.queued_tasks
                    break
                case AutoDialTaskStatus.STATUS_IN_PROGRESS:
                    pagination = this.pagination_in_progress_tasks
                    task_list = this.in_progress_tasks
                    break
                case AutoDialTaskStatus.STATUS_COMPLETED:
                    pagination = this.pagination_completed_tasks
                    task_list = this.completed_tasks
                    break
                case AutoDialTaskStatus.STATUS_FAILED:
                    pagination = this.pagination_failed_tasks
                    task_list = this.failed_tasks
                    break
                case AutoDialTaskStatus.STATUS_SCHEDULED:
                    pagination = this.pagination_scheduled_tasks
                    task_list = this.scheduled_tasks
                    break
            }

            if (pagination.current_page) {
                let found = task_list.find(task => task.id === auto_dial_task.id)
                if (found) {
                    pagination.total -= 1
                    task_list = task_list.filter(task => task.id !== auto_dial_task.id)
                }
            }

            switch (status) {
                case AutoDialTaskStatus.STATUS_QUEUED:
                    this.pagination_queued_tasks = pagination
                    this.queued_tasks = task_list
                    if (this.queued_tasks.length < this.per_page && this.pagination_queued_tasks.total > this.queued_tasks.length) {
                        this.changePage(AutoDialTaskStatus.STATUS_QUEUED, this.pagination_queued_tasks.current_page + 1)
                    }
                    break
                case AutoDialTaskStatus.STATUS_IN_PROGRESS:
                    this.pagination_in_progress_tasks = pagination
                    this.in_progress_tasks = task_list
                    if (this.in_progress_tasks.length < this.per_page && this.pagination_in_progress_tasks.total > this.in_progress_tasks.length) {
                        this.changePage(AutoDialTaskStatus.STATUS_IN_PROGRESS, this.pagination_in_progress_tasks.current_page + 1)
                    }
                    break
                case AutoDialTaskStatus.STATUS_COMPLETED:
                    this.pagination_completed_tasks = pagination
                    this.completed_tasks = task_list
                    if (this.completed_tasks.length < this.per_page && this.pagination_completed_tasks.total > this.completed_tasks.length) {
                        this.changePage(AutoDialTaskStatus.STATUS_COMPLETED, this.pagination_completed_tasks.current_page + 1)
                    }
                    break
                case AutoDialTaskStatus.STATUS_FAILED:
                    this.pagination_failed_tasks = pagination
                    this.failed_tasks = task_list
                    if (this.failed_tasks.length < this.per_page && this.pagination_failed_tasks.total > this.failed_tasks.length) {
                        this.changePage(AutoDialTaskStatus.STATUS_FAILED, this.pagination_failed_tasks.current_page + 1)
                    }
                    break
                case AutoDialTaskStatus.STATUS_SCHEDULED:
                    this.pagination_scheduled_tasks = pagination
                    this.scheduled_tasks = task_list
                    if (this.scheduled_tasks.length < this.per_page && this.pagination_scheduled_tasks.total > this.scheduled_tasks.length) {
                        this.changePage(AutoDialTaskStatus.STATUS_SCHEDULED, this.pagination_scheduled_tasks.current_page + 1)
                    }
                    break
            }
        },

        addedTask(should_refresh) {
            this.activeName = 'queued'
            this.preValidateForm('auto_dialer_settings')

            // if we should refresh the queued tasks
            if (should_refresh) {
                this.loading_queue = true
                // refresh queue if oder by timezone
                if (this.order === AutoDialTask.ORDER_BY_TIMEZONE) {
                    this.fetchAutoDialTasks(AutoDialTaskStatus.STATUS_QUEUED)
                }
            }
        },

        getUserName(userId) {
            if (!userId) {
                return ''
            }

            let user = this.users.find(o => o.id === userId)

            if (user) {
                return user.name
            }

            return ''
        },

        getDispositionStatusName(dispositionStatusId) {
            if (!dispositionStatusId) {
                return ''
            }

            let dispositionStatus = this.disposition_statuses.find(o => o.id === dispositionStatusId)

            if (dispositionStatus) {
                return dispositionStatus.name
            }

            return ''
        },

        toggleTaskList() {
            this.task_list_open = !this.task_list_open
        },

        getCampaign(id) {
            if (!id) {
                return null
            }
            let found = this.campaigns.find(campaign => campaign.id === id)
            if (found) {
                return found
            }

            return null
        },

        scheduleTask() {
            if (!this.selected_auto_dial_task) {
                return
            }

            this.loading_schedule_task = true
            axios.post('/api/v1/auto-dialer/' + this.selected_auto_dial_task.id + '/future-schedule', {
                future_scheduled_time: this.future_scheduled_time
            }).then(() => {
                this.$notify({
                    duration: 2000,
                    offset: 175,
                    title: 'PowerDialer',
                    message: 'Task has been scheduled successfully.',
                    type: 'success',
                    showClose: true
                })
                this.loading_schedule_task = false
                this.future_scheduled_time = new Date()
                this.schedule_task_popover = false
            }).catch(err => {
                this.$root.handleErrors(err.response)
                this.loading_schedule_task = false
            })
        },

        ...mapActions('cache', ['setSkipOutsideDayTimes']),
    },

    watch: {
        current_company() {
            if (!this.auto_dialer.outbound_campaign_id && this.isPause) {
                // find default outbound campaign
                this.findDefaultOutboundCampaign()
            }
        },

        auto_dialer_timer_enabled() {
            if (this.auto_dialer_timer_enabled == true) {
                this.checkAutoDialer()
                // check every 2 seconds
                this.$options.auto_dialer_interval = setInterval(this.checkAutoDialer, 2000)
            } else {
                clearInterval(this.$options.auto_dialer_interval)
            }
        },

        should_open_crm() {
            if (this.should_open_crm && this.selected_contact && this.selected_contact.id && !this.crm_link) {
                this.fetchContactInfo(this.selected_contact.id)
            }
        }
    },

    beforeRouteEnter(to, from, next) {
        auth.check().then((res) => {
            if (res.data.user?.reseller_id === 357 && res.data.user?.company?.talk_enabled && res.data.user?.company?.auto_dialer_enabled) {
                next(vm => {
                    return vm.simpsocialRedirectToTalkPage(to, false, 'PowerDialer')
                })
            } else if (res.data.user.is_reseller) {
                // redirect to account management portal if the company is a reseller
                next({name: 'Account Management Portal'})
            } else {
                next(vm => vm.is_loading = false)
            }
        }).catch((err) => {
            next({name: 'Login', query: {redirect: to.fullPath}})
        })
    },

    beforeDestroy() {
        this.stop()
        clearInterval(this.$options.auto_dialer_interval)
    }
}
</script>
