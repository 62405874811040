<template>
    <div>
        <div class="col-12">
            <p class="font-weight-bold side-panel-title">Custom Field Panel</p>
        </div>

        <div class="col-12 mt-2">
            <p class="mb-0 font-weight-bold">Field:</p>
            <el-select
                filterable
                placeholder="Select custom field"
                :rows="3"
                v-model="nodeData.key">

                <el-option
                    v-for="attribute in attribute_dictionaries"
                    v-bind:key="attribute.id"
                    :label="attribute.slug"
                    :value="attribute.slug">
                </el-option>

            </el-select>
        </div>

        <div class="col-12 mt-4">
            <p class="mb-0 font-weight-bold">Value:</p>
            <el-input
                placeholder="Type the value here."
                :rows="3"
                v-model="nodeData.value">
            </el-input>
        </div>
    </div>
</template>

<script>
import { alohabot_side_panel_mixin } from '../../../mixins'
import { mapActions, mapState } from "vuex"

export default {
    name: 'custom-panel',
    mixins: [
        alohabot_side_panel_mixin
    ],
    
    data () {
        return {
            loading: false,
        }
    },

    mounted () {
        if (this.attribute_dictionaries.length == 0) {
            this.fetchAttributeDictionaries()
        }
    },

    methods: {
        ...mapActions(['setAttributeDictionaries']),

        /**
         * Fetches attributes dictioneries.
         *
         * @return {void}
         */
        fetchAttributeDictionaries() {
            this.loading = true
            axios.get(`/api/v1/attribute-dictionary`).then(({ data }) => {
                this.setAttributeDictionaries(data.data)
            }).finally(() => {
                this.loading = false
            })
        },
    },

    computed: {
        ...mapState(['attribute_dictionaries'])
    }
}
</script>