<template>
    <div>
        <div class="row b-b">
            <div class="col-12 p-3">
                <company-form-token></company-form-token>
            </div>
        </div>
        <div class="row">
            <div class="col-12 p-3">
                <info-alert-box text="Please note that the SMS API usage is considered automated & paid. You are agreeing to our billing terms by using the API."
                                kb-link="https://support.aloware.com/en/articles/9033804-aloware-pricing-plans-empowering-your-teams-with-flexible-solutions">
                </info-alert-box>

                <div class="mb-2">
                    <strong class="integration-api-titles">Send an SMS to a phone number.</strong>
                </div>
                <p class="text-md">
                    <span class="text-dark-greenish _600">POST /api/v1/webhook/sms-gateway/send</span>
                </p>
                <p>
                    <strong>Required Fields:</strong>
                    <br>
                    <span class="text-dark-greenish _600">api_token</span>,
                    <span class="text-dark-greenish _600">from or line_id</span>,
                    <span class="text-dark-greenish _600">to</span>,
                    <span class="text-dark-greenish _600">message</span>
                </p>

                <p>
                    <strong>Optional Fields:</strong>
                    <br>
                    <span class="text-dark-greenish _600">image_url (to send mms)</span>
                    <br>
                    <span class="text-dark-greenish _600">force_random (to ignore number stickiness can be 0 or 1)</span>
                </p>

                <p>
                    <strong>Example POST URL:</strong>
                    <br>
                    <span class="text-dark-greenish _600">{{ statics.domain | fixDomain }}/api/v1/webhook/sms-gateway/send</span>
                </p>

                <p class="mb-2">
                    <strong>Example POST body:</strong>
                    <br>
                    <span class="text-muted">
                        The code sample below represents some example JSON with
                        standard fields to pass in the body of your request in
                        order to send an SMS:
                    </span>
                </p>
                <pre v-highlightjs :key="renderRefreshToken + '_sms_a'"><code class="json">{
    "api_token": "{{ companyToken }}",
    "from": "+18552562001", // put one of your line's phone numbers here
    "to": "+18181234567", // put your lead’s phone number here
    "message": "Hello, an SMS from Aloware.", // Limit to 160 characters.
}</code></pre>

                <p class="mb-2">
                    <strong>Example POST body:</strong>
                    <br>
                    <span class="text-muted">
                        The code sample below represents some example JSON with
                        standard fields to pass in the body of your request in
                        order to send an SMS with Line ID:
                    </span>
                </p>
                <pre v-highlightjs :key="renderRefreshToken + '_sms_b'"><code class="json">{
    "api_token": "{{ companyToken }}",
    "line_id": 1234, // Aloware Line ID to send SMS from.
    "to": "+18181234567", // put your lead’s phone number here
    "message": "Hello, an SMS from Aloware.", // Limit to 160 characters.
}</code></pre>

                <p class="mb-2">
                    <strong>Example POST body:</strong>
                    <br>
                    <span class="text-muted">
                        The code sample below represents some example JSON with
                        standard fields to pass in the body of your request in
                        order to send an MMS:
                    </span>
                </p>
                <pre v-highlightjs :key="renderRefreshToken + '_sms_c'"><code class="json">{
    "api_token": "{{ companyToken }}",
    "from": "+18552562001", // put one of your line's phone numbers here
    "to": "+18181234567", // put your lead’s phone number here
    "message": "Hello, an MMS from Aloware.", // Limit to 160 characters.
    "image_url": "https://placekitten.com/408/287",
}</code></pre>
                <p class="mb-2">
                    <strong>Example POST body:</strong>
                    <br>
                    <span class="text-muted">
                        The code sample below represents some example JSON with
                        standard fields to pass in the body of your request in
                        order to send an MMS with Line ID:
                    </span>
                </p>
                <pre v-highlightjs :key="renderRefreshToken + '_sms_d'"><code class="json">{
    "api_token": "{{ companyToken }}",
    "line_id": 1234, // Aloware Line ID to send SMS from.
    "to": "+18181234567", // put your lead’s phone number here
    "message": "Hello, an MMS from Aloware.", // Limit to 160 characters.
    "image_url": "https://placekitten.com/408/287",
}</code></pre>

                <p class="mb-2">
                    <strong>Example POST header:</strong>
                </p>
                <pre v-highlightjs><code class="json">{
    "Accept": "application/json",
    "Content-Type": "application/json"
}</code></pre>

                <p class="mb-2">
                    <strong>Returns an HTTP 202 response on sms sent, Example success
                        response:</strong>
                </p>
                <pre v-highlightjs><code class="json">{
    "message": "Message sent."
}</code></pre>

                <p class="mb-2">
                    <strong>Returns an HTTP 400 on failure with a detailed response, Example
                        failure
                        response:</strong>
                </p>
                <pre v-highlightjs><code class="json">{
    "message": "The given data was invalid.",
    "errors": {
        "message": [
            "The message field is required."
        ]
    }
}</code></pre>

                <p class="mb-2">
                    <strong>cURL Example:</strong>
                    <span class="text-muted">
                        With phone number.
                    </span>
                </p>
                <pre v-highlightjs :key="renderRefreshToken + '_sms_e'" v-if="!loading_whitelabel"><code class="bash">curl -X POST \
  {{ statics.domain | fixDomain }}/api/v1/webhook/sms-gateway/send \
  -H 'Accept: application/json' \
  -H 'Content-Type: application/json' \
  -d '{
    "api_token": "{{ companyToken }}",
    "from": "+18552562001",
    "to": "+18181234567",
    "message": "Hello, a SMS from Aloware.",
  }'</code></pre>

                <p class="mb-2">
                    <strong>cURL Example:</strong>
                    <span class="text-muted">
                        With Line ID.
                    </span>
                </p>
                <pre v-highlightjs :key="renderRefreshToken + '_sms_f'" v-if="!loading_whitelabel"><code class="bash">curl -X POST \
  {{ statics.domain | fixDomain }}/api/v1/webhook/sms-gateway/send \
  -H 'Accept: application/json' \
  -H 'Content-Type: application/json' \
  -d '{
    "api_token": "{{ companyToken }}",
    "line_id": 1234, // Aloware Line ID to send SMS from.
    "to": "+18181234567",
    "message": "Hello, a SMS from Aloware."
  }'</code></pre>

                <p class="_600"
                   v-if="!statics.whitelabel">
                    If you need more API functions, please contact our support at support@aloware.com .
                </p>
            </div>
        </div>
    </div>
</template>

<script>
import {company_token_mixin} from '../mixins'
import CompanyFormToken from './account/company-form-token'
import InfoAlertBox from "./info-alert-box.vue"

export default {
    mixins: [
        company_token_mixin
    ],

    components: {
        InfoAlertBox,
        CompanyFormToken
    },

    data() {
        return {
            statics: {
                logo: null,
                logo_inverse: null,
                logo_square: null,
                logo_square_inverse: null,
                host: null,
                referer: null,
                name: null,
                domain: null,
                whitelabel: false,
                path: null
            },
            loading_whitelabel: true
        }
    },

    created() {
        this.getStatics()
    },

    methods: {
        getStatics() {
            this.loading_whitelabel = true
            axios.get('/get-statics')
                .then(res => {
                    this.statics = res.data
                    this.loading_whitelabel = false
                })
                .catch(err => {
                    console.log(err)
                    this.$root.handleErrors(err.response)
                    this.loading_whitelabel = false
                })
        }
    }
}
</script>
