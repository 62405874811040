var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-tooltip",
    {
      staticClass: "item",
      attrs: {
        effect: "dark",
        content: _vm.data.message,
        disabled: !_vm.data.message || _vm.shouldHideTooltip,
        placement: "bottom",
      },
    },
    [
      _c(
        "node-template",
        {
          attrs: { id: _vm.nodeId },
          scopedSlots: _vm._u([
            {
              key: "header",
              fn: function () {
                return [_c("span", [_vm._v("Escalation")])]
              },
              proxy: true,
            },
          ]),
        },
        [
          _vm._v(" "),
          _vm.data.type === _vm.ESCALATION_TYPE_SEQUENCE
            ? _c("div", { staticClass: "row-inline mt-2" }, [
                _c("div", { staticClass: "col-12 text-center" }, [
                  _c("span", [_c("b", [_vm._v("Type:")]), _vm._v(" Sequence")]),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.data.type === _vm.ESCALATION_TYPE_SEQUENCE
            ? _c("div", { staticClass: "row-inline mt-2" }, [
                _c("div", { staticClass: "col-12 text-center" }, [
                  _c("span", [
                    _c("b", [_vm._v("Workflow:")]),
                    _vm._v(" " + _vm._s(_vm.getWorkflow())),
                  ]),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.data.type === _vm.ESCALATION_TYPE_SMS
            ? _c("div", { staticClass: "row-inline mt-2" }, [
                _c("div", { staticClass: "col-12 text-center" }, [
                  _c("span", [_c("b", [_vm._v("Type:")]), _vm._v(" SMS")]),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.data.type === _vm.ESCALATION_TYPE_SMS
            ? _c("div", { staticClass: "row-inline mt-2" }, [
                _c("div", { staticClass: "col-12 text-center" }, [
                  _c("span", [
                    _c("b", [_vm._v("Message:")]),
                    _vm._v(" " + _vm._s(_vm.data.message)),
                  ]),
                ]),
              ])
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }