var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { key: _vm.key },
    _vm._l(_vm.contact_attributes, function (contact_attribute) {
      return _c(
        "div",
        {
          key: contact_attribute.attribute_id,
          staticClass: "md-form-group pb-2",
        },
        [
          contact_attribute.type === _vm.AttributeDictionary.TYPE_DATE_PICKER
            ? _c("attribute-type-date-picker", {
                attrs: {
                  "contact-attribute": contact_attribute,
                  timezone: _vm.contact.timezone,
                },
                on: {
                  "input-set-editable": _vm.setEditable,
                  "input-save-contact-attribute": _vm.saveContactAttribute,
                },
              })
            : _c("attribute-type-text", {
                ref: _vm.AttributeDictionary.TYPE_TEXT,
                refInFor: true,
                attrs: { "contact-attribute": contact_attribute },
                on: {
                  "input-set-editable": _vm.setEditable,
                  "input-save-contact-attribute": _vm.saveContactAttribute,
                },
              }),
          _vm._v(" "),
          _c("div", { staticClass: "edit-field-button active" }, [
            _c(
              "span",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: contact_attribute.saving,
                    expression: "contact_attribute.saving",
                  },
                ],
                staticClass: "text-md text-greenish pointer no-select",
                attrs: { "data-testid": "saving-icon" },
              },
              [_c("i", { staticClass: "material-icons loader" }, [_vm._v("")])]
            ),
            _vm._v(" "),
            !contact_attribute.saving && !contact_attribute.edit_mode
              ? _c(
                  "span",
                  {
                    staticClass: "text-md text-greenish pointer btn-edit",
                    attrs: { "data-testid": "edit-contact-attributes-icon" },
                    on: {
                      click: function ($event) {
                        return _vm.setEditable(
                          contact_attribute,
                          true,
                          contact_attribute.type
                        )
                      },
                    },
                  },
                  [_c("i", { staticClass: "material-icons" }, [_vm._v("edit")])]
                )
              : _vm._e(),
          ]),
          _vm._v(" "),
          _c("label", [_vm._v(_vm._s(contact_attribute.name))]),
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }