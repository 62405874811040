<template>
    <el-dialog
        width="35vw"
        top="10vh"
        append-to-body
        :before-close="closeDialog"
        :visible.sync="show_dialog">
        <div slot="title">
            <h5 v-if="!active_step">Which contact do you want to merge <b>{{ fromContact }}</b> with ?</h5>
            <h5 v-else>Merge {{ fromContact }} with {{ toContact }}</h5>
        </div>

        <el-steps
            :active="active_step"
            finish-status="success"
            align-center>
            <el-step title="Select Contact"></el-step>
            <el-step title="Merge Contact"></el-step>
        </el-steps>

        <div
            v-loading="loading"
            class="mt-3">
            <el-select
                v-if="active_step === 0"
                class="w-100"
                data-testid="merge-contact-search"
                v-model="selected_contact"
                filterable
                clearable
                remote
                value-key="id"
                placeholder="Search Contact..."
                :remote-method="fetchContact"
                :loading="contact_searching"
                :no-data-text="no_data_text">
                <el-option
                    v-for="contact in filteredContacts"
                    class="p-0"
                    :key="contact.id"
                    :label="contact.name"
                    :value="contact">
                    <div class="media">
                        <div class="media-body">
                            <label>{{ contact.name | fixContactName }}</label>
                            <small>{{ contact.phone_number | fixPhone }}</small>
                        </div>
                    </div>
                </el-option>
            </el-select>

            <div v-else>
                <h6>The following <span class="text-black">{{ fromContact }}</span> info will be added to the <span class="text-black">{{ toContact }}</span> Contact:</h6>
                <ul>
                    <li>Phone Number</li>
                    <li>Power Dialer Tasks</li>
                    <li>Communications</li>
                    <li>Tags</li>
                    <li>Future Scheduled Messages</li>
                    <li>Ring Groups</li>
                    <li>Lines <span class="pull-right">(if doesn't exist)</span></li>
                    <li>Initial Line <span class="pull-right">(if doesn't exist)</span></li>
                    <li>Contact Disposition <span class="pull-right">(if doesn't exist)</span></li>
                    <li>Owner <span class="pull-right">(if doesn't exist)</span></li>
                </ul>

                <el-divider></el-divider>

                <h6>The <span class="text-black">{{ fromContact }}</span> contact will be deleted and its following info will be lost:</h6>

                <ul>
                    <li>Contact Information (e.g. First Name, Last Name, etc.)</li>
                    <li>Contact Audits</li>
                    <li>Active Broadcast or Sequence</li>
                </ul>

                <el-divider></el-divider>

                <h6>Are you sure you want to merge?</h6>
            </div>
        </div>

        <div
            slot="footer"
            class="dialog-footer d-flex">
            <el-button
                @click="active_step ? backToSearch() : closeDialog()"
                data-testid="merge-cancel-button"
                class="flex-grow-1">{{ active_step ? 'Back' : 'Cancel' }}</el-button>
            <el-button
                @click="active_step ? mergeContact() : reviewMerge()"
                :disabled="!selected_contact"
                type="success"
                data-testid="merge-review-button"
                class="flex-grow-1">
                {{ active_step ? 'Merge' : 'Review' }}
            </el-button>
        </div>
    </el-dialog>
</template>

<script>
export default {
    name: "contact-merge-dialog",

    props: {
        contact: {}
    },

    data() {
        return {
            loading: false,
            contact_searching: false,
            selected_contact: null,
            show_dialog: false,
            contacts: [],
            CancelToken: null,
            no_data_text: 'No data',
            active_step: 0
        }
    },

    computed: {
        fromContact() {
            if (this.contact) {
                let filters = this.$options.filters
                return this.contact.name ? filters.fixContactName(this.contact.name) : filters.fixPhone(this.contact.phone_number)
            }

            return ''
        },
        toContact() {
            if (this.selected_contact) {
                let filters = this.$options.filters
                return this.selected_contact.name ? filters.fixContactName(this.selected_contact.name) : filters.fixPhone(this.selected_contact.phone_number)
            }

            return ''
        },
        filteredContacts() {
            return this.contacts.filter(con => this.contact && con.id !== this.contact.id)
        }
    },

    created () {
        this.CancelToken = axios.CancelToken
        this.source = this.CancelToken.source()

        VueEvent.listen('open-contact-merge-dialog', _ => {
            this.show_dialog = true
        })

    },

    methods: {
        mergeContact() {
            this.loading = true
            this.active_step++

            axios.put(`/api/v1/contact/${this.contact.id}/merge-to/${this.selected_contact.id}`)
                .then(res => {
                    if (res.data) {
                        this.showNotification('Merge Complete', 'success', `<b>${this.fromContact}</b> successfully merged with <b>${this.toContact}</b>`)

                        let id = this.selected_contact.id
                        this.resetAll()

                        setTimeout(_ => {
                            this.$router.push({name: 'Contact', params: {contact_id: id}}).catch(err => {})
                        }, 200)
                    }
                })
                .catch(err => {
                    console.log(err)

                    this.showNotification('Merge Failed', 'error', 'Something went wrong while merging contacts.')
                })
                .finally(_ => {
                    this.loading = false
                })
        },

        fetchContact(query) {
            this.no_data_text = 'Please input at least 3 character'

            if (query.length >= 3) {
                this.source.cancel('fetchContact canceled by the user.')
                this.source = this.CancelToken.source()
                this.contact_searching = true
                const params = {
                    search_fields: ['name', 'email', 'phone_number'],
                    search_text: query
                }
                axios.get('/api/v1/contact', {
                    params: params,
                    cancelToken: this.source.token
                }).then((res) => {
                    this.contacts = res.data.data
                }).catch((err) => {
                    if (axios.isCancel(err)) {
                        console.log('Request canceled', err.message)
                    } else {
                        this.$root.handleErrors(err.response)
                    }
                })
                    .finally(_ => {
                        this.contact_searching = false
                        this.no_data_text = 'No data'
                    })
            } else {
                this.contacts = []
            }
        },

        closeDialog() {
            if (this.selected_contact) {
                this.$confirm(`Are you sure you want to close this form?`, 'Merge Contact', {
                    confirmButtonText: "Yes, I'm sure",
                    cancelButtonText: "No, I'm not",
                    type: 'warning',
                    customClass: 'width-500 fixed'
                }).then(() => {
                    this.resetAll()
                }).catch(err => {})

                return
            }

            this.resetAll()
        },

        resetAll() {
            this.selected_contact = null
            this.contacts = []
            this.show_dialog = false
            this.active_step = 0
            this.loading = false
            this.contact_searching = false
        },

        backToSearch() {
            this.active_step--
        },

        reviewMerge() {
            this.active_step++
        },

        showNotification(title, type, message) {
            this.$notify({
                offset: 95,
                title: title,
                message: message,
                type: type,
                dangerouslyUseHTMLString: true,
                showClose: true,
            })
        }
    }
}
</script>
