<template>
    <el-popover :placement="small ? 'bottom-end' : 'right-start'"
                width="255"
                v-model="add_contact_popover_visibility"
                @hide="resetAddContact">
        <el-form ref="add_contact"
                 label-width="100px"
                 label-position="top"
                 :model="add_contact"
                 :rules="rules_test"
                 @submit.prevent.native="addContact">
            <el-form-item label="Contact"
                          prop="phone_number">
                <el-select placeholder="Type to search ..."
                           v-model="add_contact.phone_number"
                           class="no-select w-full"
                           filterable
                           remote
                           :remote-method="fetchContact"
                           :loading="loading_fetching_contact"
                           @change="changeTargetContact"
                           clearable>
                    <el-option
                        v-for="contact in contactsAlphabeticalOrder"
                        class="p-0"
                        :key="contact.id"
                        :label="contact.name"
                        :value="contact.phone_number">
                        <div class="media">
                            <div class="media-body">
                                <label>{{ contact.name }}</label>
                                <small>{{ contact.phone_number | fixPhone }}</small>
                            </div>
                        </div>
                    </el-option>
                </el-select>
            </el-form-item>
            <div class="row no-gutter mt-2 centered-content">
                <button class="btn btn-block greenish"
                        @click.prevent="addContact"
                        :disabled="disable_add_contact">
                    Add
                </button>
            </div>
        </el-form>
        <button class="md-btn md-flat text-whitish search-btn p-1"
                v-if="referrer == 'btn'"
                slot="reference">
            <i class="fa fa-search"></i>
        </button>

        <el-input type="text"
                  slot="reference"
                  v-model="add_contact.name"
                  v-if="referrer == 'input'"
                  readonly>
        </el-input>
    </el-popover>
</template>

<script>
    import auth from '../auth'
    import {acl_mixin, form_validation_mixin} from '../mixins'
    import {mapState} from 'vuex'

    export default {
        mixins: [acl_mixin, form_validation_mixin],

        props: {
            small: {
                default: false,
                type: Boolean,
                required: false
            },
            referrer: {
                default: 'btn'
            }
        },

        data() {
            return {
                auth: auth,
                add_contact: {
                    phone_number: null
                },
                rules_test: {
                    phone_number: [
                        {
                            required: true,
                            message: 'Please select a contact',
                            trigger: 'change'
                        }
                    ]
                },
                contacts: [],
                CancelToken: null,
                source: null,
                loading_fetching_contact: false,
                disable_add_contact: true,
                add_contact_popover_visibility: false,
            }
        },

        computed: {
            ...mapState('cache', ['current_company']),

            contactsAlphabeticalOrder() {
                if (this.contacts.length) {
                    let contacts = _.clone(this.contacts)
                    return contacts.sort((a, b) => {
                        let textA = a.name.toUpperCase()
                        let textB = b.name.toUpperCase()
                        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0
                    })
                }

                return []
            }
        },

        created() {
            this.CancelToken = axios.CancelToken
            this.source = this.CancelToken.source()
        },

        methods: {
            fetchContact(query) {
                if (query.length >= 3) {
                    this.source.cancel('fetchContact canceled by the user.')
                    this.source = this.CancelToken.source()
                    this.loading_fetching_contact = true
                    const params = {
                        search_fields: ['name', 'email', 'phone_number'],
                        search_text: query
                    }
                    axios.get('/api/v1/contact', {
                        params: params,
                        cancelToken: this.source.token
                    }).then((res) => {
                        this.loading_fetching_contact = false
                        this.contacts = res.data.data
                    }).catch((err) => {
                        if (axios.isCancel(err)) {
                            console.log('Request canceled', err.message)
                        } else {
                            this.$root.handleErrors(err.response)
                            this.loading_fetching_contact = false
                        }
                    })
                } else {
                    this.contacts = []
                }
            },

            changeTargetContact() {
                this.disable_add_contact = !this.validateForm('add_contact')
            },

            resetAddContact() {
                this.add_contact.phone_number = null
                this.resetForm('add_contact')
                this.disable_add_contact = true
                this.add_contact_popover_visibility = false
            },

            addContact() {
                if (this.validateForm('add_contact') == true) {
                    this.$emit('success', this.add_contact.phone_number)

                    this.$emit('contact-added', {
                        name: this.add_contact.name,
                        phone_number: this.add_contact.phone_number
                    })

                    this.resetAddContact()
                } else {
                    return false
                }
            }
        }
    }
</script>
