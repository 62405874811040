<template>
    <div>
        <h5>
            Select any node to interact with the UI.
        </h5>
    </div>
</template>

<script>
export default {
    name: 'general-panel'
}
</script>
