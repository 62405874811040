<template>
    <div class="multiple">
        <div class="panel"
             v-for="(intent, index) in data['intents']"
             :key="index">
            <!-- We load the css class through the index because it hasn't been added yet to the transition -->
            {{ intent.name }}
            <div :class="`link output_${index + 1} output_intent`"></div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'intents',
    props: {
        data: {
            required: false,
            default: () => ({
                intents: []
            })
        }
    }
}
</script>