var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.isSimpSocial
    ? _c("div", { staticClass: "upgrade-now no-select" }, [
        _c("h3", { staticClass: "title-text mt-4" }, [
          _vm._v(_vm._s(_vm.titleText)),
        ]),
        _vm._v(" "),
        _c("img", { staticClass: "mt-3 mb-3", attrs: { src: _vm.imageLink } }),
        _vm._v(" "),
        _c("p", { staticClass: "info-text font-weight-bold" }, [
          _vm._v(_vm._s(_vm.text)),
        ]),
        _vm._v(" "),
        _vm.extraText
          ? _c("p", { staticClass: "info-text" }, [
              _vm._v("\n        " + _vm._s(_vm.extraText) + "\n    "),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "flex-row mb-4 mt-3" },
          [
            _vm.kbLink
              ? _c(
                  "el-button",
                  {
                    attrs: { size: "large", type: "text" },
                    on: { click: _vm.openKnowledgeBaseLink },
                  },
                  [_vm._v("\n            Learn More\n        ")]
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "el-button",
              {
                attrs: { type: "primary", size: "large", round: "" },
                on: { click: _vm.checkClick },
              },
              [_vm._v("\n            " + _vm._s(_vm.buttonText) + "\n        ")]
            ),
          ],
          1
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }