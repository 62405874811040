<template>
    <div class="row no-gutter"
         v-if="contact">
        <div class="text-center mr-2 mb-2"
             v-for="line_id in line_ids">
            <router-link :to="{ name: 'Line Activity', params: {campaign_id: line_id }}"
                         v-if="line_id">
                <a class="btn btn-xs bluish"
                   data-testid="contact-lines-link"
                   v-if="getCampaign(line_id)">
                    {{ getCampaign(line_id).name }}
                </a>
            </router-link>
        </div>
        <div class="col-12"
             v-if="hasRole('Company Admin')">
            <el-popover ref="popover_campaigns"
                        title="Lines:"
                        placement="left"
                        width="400"
                        data-testid="contact-lines-popover"
                        trigger="click">
                <el-button slot="reference"
                           data-testid="add-modify-contact-lines-button"
                           type="text text-dark-greenish text-sm p-0">
                    <i class="el-icon-circle-plus-outline"></i>
                    <span v-if="new_line_ids.length === 0">Add Lines</span>
                    <span v-else>Modify Lines</span>
                </el-button>
                <div class="row">
                    <div class="col-12">
                        <el-form>
                            <el-form-item prop="ring_group">
                                <el-select v-model="new_line_ids"
                                           placeholder="Select lines"
                                           class="d-block"
                                           multiple
                                           data-testid="contact-lines-select"
                                           @change="changeCampaigns($event, new_line_ids)">
                                    <el-option
                                        v-for="item in lineOptions"
                                        :key="item.value"
                                        :label="item.label"
                                        :value="item.value">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-form>
                    </div>
                </div>
            </el-popover>
        </div>
    </div>
</template>

<script>
import auth from '../auth'
import {mapState} from 'vuex'
import {acl_mixin} from '../mixins'

export default {
    mixins: [acl_mixin],

    props: {
        contact: {
            required: true
        }
    },

    data() {
        return {
            auth: auth,
            current_contact_id: null,
            line_ids: [],
            new_line_ids: [],
            loading_btn: false,
            validated: false,
        }
    },

    computed: {
        ...mapState(['campaigns']),

        lineOptions() {
            return this.campaigns.map(campaign => {
                return {
                    label: campaign.name,
                    value: campaign.id
                }
            })
        }
    },

    methods: {
        getCampaigns() {
            this.current_contact_id = this.contact.id
            axios.get(`/api/v1/contact/${this.contact.id}/campaigns`).then(res => {
                this.line_ids = res.data
                this.new_line_ids = this.line_ids
            }).catch(err => {
                this.line_ids = []
                this.$root.handleErrors(err.response)
            })
        },

        getCampaign(id) {
            if (!id) {
                return null
            }

            return this.campaigns.find(campaign => campaign.id === id)
        },

        updateCampaign() {
            axios.put(`/api/v1/contact/${this.contact.id}/campaigns`, {
                campaign_ids: this.new_line_ids
            }).then(res => {
                this.line_ids = this.new_line_ids
                this.$notify({
                    offset: 95,
                    title: 'Contact',
                    message: 'Successfully updated Contact\'s lines.',
                    type: 'success'
                })
            }).catch(err => {
                this.$root.handleErrors(err.response)
            })
        },

        changeCampaigns(event, model) {
            model = event
            this.updateCampaign()
        }
    },

    watch: {
        contact() {
            if (this.contact && (!this.current_contact_id || this.current_contact_id != this.contact.id)) {
                this.getCampaigns()
            }
        }
    }
}
</script>
