var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "multiple" },
    _vm._l(_vm.data["intents"], function (intent, index) {
      return _c("div", { key: index, staticClass: "panel" }, [
        _vm._v("\n        " + _vm._s(intent.name) + "\n        "),
        _c("div", { class: `link output_${index + 1} output_intent` }),
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }