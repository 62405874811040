<template>
    <div v-if="hasPermissionTo('send sms')">
        <!--send a new sms-->
        <el-dialog title="Send an SMS"
                   class="dialog-padding auto"
                   :width="isLargeEnough ? '500px' : '100%'"
                   :visible.sync="hide_add"
                   :before-close="beforeCloseModal"
                   append-to-body>
            <el-form ref="send_sms"
                     class="p-0"
                     label-position="top"
                     :rules="rules_sms"
                     :model="sms"
                     @submit.prevent.native="sendSMS">
                <el-form-item prop="campaign_id"
                              label="Line">
                    <campaign-selector select_style="width: 100%;"
                                       check_blocked_messaging
                                       clearable
                                       :value="sms.campaign_id"
                                       :is_sms="true"
                                       @change="changeCampaignId">
                    </campaign-selector>
                </el-form-item>
                <el-form-item prop="phone_number"
                              label="Phone Number">
                    <el-input v-model="sms.phone_number"
                              @input="preValidateForm('send_sms')">
                    </el-input>
                </el-form-item>
                <el-form-item prop="message"
                              label="Message Body">
                    <el-input v-model="sms.message"
                              type="textarea"
                              rows="4"
                              @blur="detectLongUrls"
                              @input="preValidateForm('send_sms')"
                              v-loading="generatingUrls">
                    </el-input>
                </el-form-item>
            </el-form>

            <div class="row pb-3">
                <div class="col-8">
                    <div class="row">
                        <div class="col-6">
                            <variable-dialog :in_input_group="false"></variable-dialog>
                        </div>
                        <div class="col-6">
                            <template-list-dialog/>
                        </div>
                    </div>
                </div>
                <div class="col-4">
                    <el-popover v-if="!enabledToTextNumber(sms.phone_number) && customMessage('text')?.prefix"
                                ref="send-text"
                                placement="top"
                                width="200"
                                class="custom-popover"
                                trigger="hover">
                        <custom-message-display :config="customMessage('text')" />
                    </el-popover>
                    <el-button type="success"
                               :disabled="isSendTextDisabled"
                               v-popover:send-text
                               class="pull-right ml-2"
                               @click="sendSMS">
                        <i class="material-icons loader"
                           v-show="loading_btn">&#xE863;</i>
                        <i class="material-icons"
                           v-show="!loading_btn">
                            send
                        </i>
                        <span>Send</span>
                    </el-button>
                </div>
            </div>
        </el-dialog>

        <slot name="trigger">
            <el-button slot="reference"
                       type="text text-sm text-dark-greenish"
                       @click="hide_add = true">
                <i class="el-icon-circle-plus-outline"></i>
                New Message
            </el-button>
        </slot>
    </div>
</template>

<script>
    import auth from '../auth'
    import {
        acl_mixin,
        validator_mixin,
        form_validation_mixin,
        url_shortener_mixin,
        kyc_mixin
    } from '../mixins'
    import {mapState} from 'vuex'
    import VariableDialog from "./variable-dialog"
    import TemplateListDialog from "./template-list-dialog"
    import CustomMessageDisplay from './kyc/custom-message-display'
    import isEmpty from "lodash/isEmpty"

    export default {
        components: {TemplateListDialog, VariableDialog, CustomMessageDisplay},

        mixins: [
            acl_mixin,
            validator_mixin,
            form_validation_mixin,
            url_shortener_mixin,
            kyc_mixin
        ],

        data() {
            return {
                auth: auth,
                can_send_message: false,
                loading_btn: false,
                loading_sms: false,
                hide_add: false,
                sms: {
                    campaign_id: null,
                    phone_number: null,
                    message: null
                },
                rules_sms: {
                    campaign_id: [
                        {
                            required: true,
                            message: 'Please select a campaign',
                            trigger: 'blur'
                        }
                    ],
                    phone_number: [
                        {
                            required: true,
                            message: 'Please provide a phone number',
                            trigger: 'blur'
                        },
                        {
                            required: true,
                            validator: this.phoneValidator,
                            trigger: 'blur'
                        },
                    ],
                    message: [
                        {
                            required: true,
                            message: 'Please provide message body',
                            trigger: 'blur'
                        }
                    ],
                }
            }
        },

        computed: {
            ...mapState('cache', ['current_company']),
            ...mapState(['campaigns']),

            isLargeEnough() {
                let valid_sizes = ['xxl', 'xl', 'lg', 'md']
                if (valid_sizes.includes(this.$mq)) {
                    return true
                }
                return false
            },

            isSendTextDisabled () {
                return this.loading_btn || !this.validated || !this.can_send_message || !this.enabledToTextNumber(this.sms.phone_number)
            }
        },

        methods: {
            changeCampaignId(val) {
                this.sms.campaign_id = val
                const selectedCampaign = this.campaigns.find(campaign => campaign.id === val)
                if (!isEmpty(selectedCampaign)) {
                    /**
                     * Disable check for A2P block, and let the backend handle it for now.
                     * @todo Implement new and better logic for this block
                     */
                    let bypass_a2p = selectedCampaign.blocked_messaging_information['bypassed']
                    let is_blocked = selectedCampaign.blocked_messaging_information['blocked']
                    let automated_only = selectedCampaign.blocked_messaging_information['automated_only']

                    // Determine if message can be sent
                    this.can_send_message = bypass_a2p || (!is_blocked || automated_only)
                }
                this.preValidateForm('send_sms')
            },

            sendSMS() {
                if (this.validateForm('send_sms')) {
                    this.loading_btn = true
                    axios.post('/api/v1/campaign/send-message-to-phone-number/' + this.sms.campaign_id, this.sms)
                        .then(res => {
                            this.loading_btn = false
                            this.hide_add = false
                            this.$emit('success', res.data)
                            this.resetSMS()
                            this.$router.push({name: 'Contact', params: {contact_id: res.data.id}}).catch(err => {
                            })
                        })
                        .catch(err => {
                            this.$root.handleErrors(err.response)
                            this.loading_btn = false
                        })
                }
            },

            resetSMS() {
                this.sms = {
                    campaign_id: null,
                    phone_number: null,
                    message: null
                }
            },

            changeTagColor(val) {
                this.sms.color = val
            },

            beforeCloseModal(done) {
                if (this.sms.campaign_id || this.sms.phone_number || this.sms.message) {
                    this.$confirm('Are you sure you want to leave?', 'Warning', {
                        confirmButtonText: 'Yes, Leave',
                        cancelButtonText: 'No, Stay',
                        customClass: 'width-500 fixed',
                        type: 'warning'
                    }).then(res => {
                        this.resetSMS()
                        done()
                    }).catch(() => {

                    })
                } else {
                    this.resetSMS()
                    done()
                }
            },

            async detectLongUrls() {
                this.sms.message = await this.shortenUrls(this.sms.message)
            }
        },

        watch: {
            hide_add() {
                if (this.hide_add) {
                    this.$emit('hide')
                } else {
                    this.$emit('show')
                }
            }
        }
    }
</script>
