var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "app-body", attrs: { id: "view" } },
    [
      _vm.shouldShowBroadcast && !_vm.is_loading
        ? [
            _c("aup-consent", {
              on: {
                loaded: function ($event) {
                  _vm.loading_aup = false
                },
              },
            }),
            _vm._v(" "),
            _c(
              "el-dialog",
              {
                attrs: {
                  title: "What are broadcasts?",
                  top: "10vh",
                  "append-to-body": "",
                  visible: _vm.show_help,
                },
                on: {
                  "update:visible": function ($event) {
                    _vm.show_help = $event
                  },
                },
              },
              [_c("p", { staticClass: "text-md _600 break-word" })]
            ),
            _vm._v(" "),
            !_vm.loading_aup
              ? _c(
                  "div",
                  {
                    staticClass: "fixed-header padding pt-0 pb-0",
                    style: { top: _vm.total_offset + 50 + "px" },
                  },
                  [
                    _c("div", { staticClass: "row mb-0 mt-0" }, [
                      _c(
                        "div",
                        { staticClass: "d-flex flex-fill flex-wrap p-2" },
                        [
                          _c(
                            "div",
                            { staticClass: "d-flex flex-wrap mr-auto" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "mr-sm-2 flex-fill search-toolbar",
                                },
                                [
                                  _c("el-input", {
                                    ref: "broadcastSearch",
                                    attrs: {
                                      placeholder: "Search...",
                                      "prefix-icon": "el-icon-search",
                                      clearable: "",
                                    },
                                    on: { input: _vm.search },
                                    model: {
                                      value: _vm.search_text,
                                      callback: function ($$v) {
                                        _vm.search_text = $$v
                                      },
                                      expression: "search_text",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "no-select mt-2 mt-xl-0 mr-2" },
                                [
                                  _c(
                                    "el-radio-group",
                                    {
                                      staticClass: "no-select",
                                      on: { change: _vm.changedFilter },
                                      model: {
                                        value: _vm.broadcast_status,
                                        callback: function ($$v) {
                                          _vm.broadcast_status = $$v
                                        },
                                        expression: "broadcast_status",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-radio-button",
                                        { attrs: { label: "new" } },
                                        [
                                          _vm._v(
                                            "\n                                    New\n                                "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-radio-button",
                                        { attrs: { label: "enrolling" } },
                                        [
                                          _vm._v(
                                            "\n                                    Enrolling\n                                "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-radio-button",
                                        { attrs: { label: "done" } },
                                        [
                                          _vm._v(
                                            "\n                                    Sent\n                                "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-radio-button",
                                        {
                                          class: [
                                            _vm.broadcast_status == "paused"
                                              ? "pause-deep-orange"
                                              : "",
                                          ],
                                          attrs: { label: "paused" },
                                        },
                                        [
                                          _vm._v(
                                            "\n                                    Paused\n                                "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-radio-button",
                                        { attrs: { label: "all" } },
                                        [
                                          _vm._v(
                                            "\n                                    All\n                                "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "d-flex flex-wrap" },
                            [
                              !_vm.enabledToAddBroadcasts()
                                ? _c(
                                    "el-popover",
                                    {
                                      ref: "new-bulk-popover",
                                      staticClass: "custom-popover",
                                      attrs: {
                                        placement: "top",
                                        width: "200",
                                        "popper-class": "btn-primary",
                                        trigger: "hover",
                                      },
                                    },
                                    [
                                      _c("custom-message-display", {
                                        attrs: {
                                          config:
                                            _vm.customMessage("broadcasts"),
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c("div", { staticClass: "mt-2 mt-xl-0 mr-2" }, [
                                _c(
                                  "button",
                                  {
                                    directives: [
                                      {
                                        name: "popover",
                                        rawName: "v-popover:new-bulk-popover",
                                        arg: "new-bulk-popover",
                                      },
                                    ],
                                    staticClass: "btn btn-md rounded greenish",
                                    attrs: {
                                      disabled: !_vm.canCreateBroadcastMessage,
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.$router.push({
                                          name: "Bulk Message",
                                        })
                                      },
                                    },
                                  },
                                  [
                                    _c("i", { staticClass: "fa fa-plus" }),
                                    _vm._v(
                                      "\n                                New Bulk Message\n                            "
                                    ),
                                  ]
                                ),
                              ]),
                              _vm._v(" "),
                              !_vm.enabledToAddBroadcasts()
                                ? _c(
                                    "el-popover",
                                    {
                                      ref: "bulk-rvm-popover",
                                      staticClass: "custom-popover",
                                      attrs: {
                                        placement: "top",
                                        width: "200",
                                        "popper-class": "btn-primary",
                                        trigger: "hover",
                                      },
                                    },
                                    [
                                      _c("custom-message-display", {
                                        attrs: {
                                          config:
                                            _vm.customMessage("broadcasts"),
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "mt-2 mt-xl-0 mr-2 mr-xl-0" },
                                [
                                  _c(
                                    "button",
                                    {
                                      directives: [
                                        {
                                          name: "popover",
                                          rawName: "v-popover:bulk-rvm-popover",
                                          arg: "bulk-rvm-popover",
                                        },
                                      ],
                                      staticClass: "btn btn-md rounded bluish",
                                      attrs: { disabled: !_vm.canBroadcastRvm },
                                      on: {
                                        click: function ($event) {
                                          return _vm.$router.push({
                                            name: "Bulk RVM",
                                          })
                                        },
                                      },
                                    },
                                    [
                                      _c("i", { staticClass: "fa fa-plus" }),
                                      _vm._v(
                                        "\n                                New Bulk RVM\n                            "
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _vm.isTrial && _vm.isNotSimpSocial
                                ? _c("video-modal-activator", {
                                    staticClass: "pl-2",
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]
                      ),
                    ]),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.hasPermissionTo("list report") &&
            !_vm.search_text &&
            !_vm.loading_aup
              ? _c("div", { staticClass: "padding mt-5 pt-3" }, [
                  _c("div", { staticClass: "row box" }, [
                    _c("div", { staticClass: "col-12" }, [
                      _c("div", { staticClass: "no-effect" }, [
                        _c(
                          "div",
                          { staticClass: "box-body" },
                          [
                            _c("activity-graph", {
                              attrs: {
                                base: "broadcast",
                                default_date_range: 7,
                                is_first_load: _vm.reports_first_load,
                                filter_ids: _vm.filteredBroadcasts.map(
                                  (broadcast) => broadcast.id
                                ),
                              },
                              on: { finished_loading: _vm.focusSearchBar },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ]),
                  ]),
                ])
              : _vm._e(),
            _vm._v(" "),
            !_vm.loading_aup
              ? _c("div", { staticClass: "padding" }, [
                  _c("div", { staticClass: "row box" }, [
                    _c("div", { staticClass: "col-12" }, [
                      _c("div", { staticClass: "no-effect" }, [
                        _c("div", { staticClass: "box-header pb-0" }, [
                          _c("div", { staticClass: "row mt-2" }, [
                            _c("div", { staticClass: "col-12" }, [
                              _vm.broadcasts.length > 0
                                ? _c(
                                    "span",
                                    { staticClass: "pull-left font-120" },
                                    [
                                      _c("strong", [_vm._v(_vm._s(_vm.total))]),
                                      _vm._v(
                                        "\n                                    " +
                                          _vm._s(
                                            _vm.total > 1
                                              ? "broadcasts"
                                              : "broadcast"
                                          ) +
                                          "\n                                "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "box-body pt-0" }, [
                          _c(
                            "div",
                            { on: { mouseleave: _vm.tableLeave } },
                            [
                              _c(
                                "el-table",
                                {
                                  directives: [
                                    {
                                      name: "loading",
                                      rawName: "v-loading",
                                      value: _vm.loading_broadcasts,
                                      expression: "loading_broadcasts",
                                    },
                                  ],
                                  staticClass: "w-full",
                                  attrs: {
                                    "row-key": "id",
                                    stripe: "",
                                    fit: "",
                                    data: _vm.paginatedData,
                                    "default-sort": {
                                      prop: "id",
                                      order: "descending",
                                    },
                                  },
                                  on: { "cell-mouse-enter": _vm.cellEnter },
                                },
                                [
                                  _c("el-table-column", {
                                    attrs: { prop: "status", width: "35" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _c(
                                                "span",
                                                { staticClass: "status" },
                                                [
                                                  _c("i", {
                                                    staticClass: "b-white",
                                                    class: [
                                                      scope.row.status !=
                                                      _vm.BroadcastStatuses
                                                        .STATUS_PAUSED
                                                        ? "greenish"
                                                        : "danger",
                                                    ],
                                                  }),
                                                ]
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      3791328708
                                    ),
                                  }),
                                  _vm._v(" "),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "id",
                                      label: "ID",
                                      width: "100",
                                      sortable: "",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _c(
                                                "span",
                                                { staticClass: "text-greyish" },
                                                [_vm._v(_vm._s(scope.row.id))]
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      2427425926
                                    ),
                                  }),
                                  _vm._v(" "),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "name",
                                      label: "Name",
                                      sortable: "",
                                      "min-width": 180,
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _c(
                                                "span",
                                                { staticClass: "text-greyish" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      scope.row.name || "-"
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      1629878337
                                    ),
                                  }),
                                  _vm._v(" "),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "status_name",
                                      label: "Status",
                                      sortable: "",
                                      "min-width": 180,
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              ![
                                                _vm.BroadcastStatuses
                                                  .STATUS_PAUSED,
                                                _vm.BroadcastStatuses
                                                  .STATUS_STOPPED,
                                              ].includes(scope.row.status)
                                                ? [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "text-greyish",
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            scope.row
                                                              .status_name
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                : [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "text-danger",
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            scope.row
                                                              .status_name
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      1092572860
                                    ),
                                  }),
                                  _vm._v(" "),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "run_at",
                                      label: "Scheduled Time",
                                      width: "150",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              scope.row.run_at
                                                ? _c("relative-datetime", {
                                                    attrs: {
                                                      datetime:
                                                        scope.row.run_at,
                                                    },
                                                  })
                                                : _vm._e(),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      1607543759
                                    ),
                                  }),
                                  _vm._v(" "),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "pending_tasks",
                                      label: "Pending Tasks",
                                      width: "190",
                                      align: "center",
                                      sortable: "",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _c(
                                                "span",
                                                { staticClass: "_600 text-md" },
                                                [
                                                  _vm._v(
                                                    "\n                                              " +
                                                      _vm._s(
                                                        scope.row
                                                          .total_enrolled > 0
                                                          ? scope.row
                                                              .pending_tasks
                                                          : "-"
                                                      ) +
                                                      "\n                                          "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      2383274018
                                    ),
                                  }),
                                  _vm._v(" "),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "total_failed",
                                      width: "150",
                                      align: "center",
                                      sortable: "",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "header",
                                          fn: function () {
                                            return [
                                              _vm._v(
                                                "\n                                            Failed\n                                            "
                                              ),
                                              _c(
                                                "el-popover",
                                                {
                                                  attrs: {
                                                    placement: "right",
                                                    width: "300",
                                                    trigger: "hover",
                                                    "popper-class": "p-2",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "lh-1" },
                                                    [
                                                      _vm._v(
                                                        "Number of contacts that failed to send because phone number is a landline."
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c("img", {
                                                    attrs: {
                                                      slot: "reference",
                                                      src: "/assets/images/app-icons/info-icon-outline.png",
                                                      alt: "info-icon",
                                                    },
                                                    slot: "reference",
                                                  }),
                                                ]
                                              ),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _c(
                                                "span",
                                                { staticClass: "_600 text-md" },
                                                [
                                                  _vm._v(
                                                    "\n                                          " +
                                                      _vm._s(
                                                        scope.row.total_failed >
                                                          0
                                                          ? scope.row
                                                              .total_failed
                                                          : "-"
                                                      ) +
                                                      "\n                                      "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      1301686169
                                    ),
                                  }),
                                  _vm._v(" "),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "total_enrolled",
                                      label: "Total Tasks",
                                      width: "180",
                                      align: "center",
                                      sortable: "",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _c(
                                                "span",
                                                { staticClass: "_600 text-md" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      scope.row.total_enrolled >
                                                        0
                                                        ? scope.row
                                                            .total_enrolled
                                                        : "-"
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      1113289782
                                    ),
                                  }),
                                  _vm._v(" "),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "engagement_rate",
                                      label: "Engagement",
                                      "min-width": 110,
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _c(
                                                "div",
                                                { staticClass: "text-center" },
                                                [
                                                  scope.row.engagement_rate > 0
                                                    ? _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            scope.row
                                                              .engagement_rate
                                                          ) + "%"
                                                        ),
                                                      ])
                                                    : _c("span", [_vm._v("-")]),
                                                ]
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      3677137229
                                    ),
                                  }),
                                  _vm._v(" "),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "total_unsubscribed",
                                      label: "Unsubscribed",
                                      "min-width": 110,
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _c(
                                                "div",
                                                { staticClass: "text-center" },
                                                [
                                                  scope.row.status ==
                                                  _vm.BroadcastStatuses
                                                    .STATUS_DONE
                                                    ? _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            scope.row
                                                              .total_unsubscribed
                                                          )
                                                        ),
                                                      ])
                                                    : _c("span", [_vm._v("-")]),
                                                ]
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      4112489739
                                    ),
                                  }),
                                  _vm._v(" "),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "tag",
                                      label: "Tag",
                                      sortable: "",
                                      "min-width": 180,
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              scope.row.tag
                                                ? _c(
                                                    "div",
                                                    [
                                                      _c(
                                                        "el-tooltip",
                                                        {
                                                          staticClass: "item",
                                                          attrs: {
                                                            effect: "dark",
                                                            placement: "top",
                                                            content:
                                                              scope.row.tag
                                                                .name,
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "router-link",
                                                            {
                                                              attrs: {
                                                                to: {
                                                                  name: "Contacts",
                                                                  query: {
                                                                    tag_id:
                                                                      scope.row
                                                                        .tag.id,
                                                                  },
                                                                },
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "span",
                                                                {
                                                                  style:
                                                                    "color:" +
                                                                    scope.row
                                                                      .tag
                                                                      .color,
                                                                },
                                                                [
                                                                  _c("i", {
                                                                    staticClass:
                                                                      "fa fa-tag",
                                                                  }),
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        _vm._f(
                                                                          "strLimit"
                                                                        )(
                                                                          scope
                                                                            .row
                                                                            .tag
                                                                            .name,
                                                                          40
                                                                        )
                                                                      )
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      1867117495
                                    ),
                                  }),
                                  _vm._v(" "),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "campaign_id",
                                      label: "Line Used",
                                      sortable: "",
                                      "min-width": 180,
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _vm.getCampaign(
                                                scope.row.campaign_id
                                              )
                                                ? _c(
                                                    "router-link",
                                                    {
                                                      attrs: {
                                                        to: {
                                                          name: "Line Settings",
                                                          params: {
                                                            campaign_id:
                                                              scope.row
                                                                .campaign_id,
                                                          },
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "el-tooltip",
                                                        {
                                                          staticClass: "item",
                                                          attrs: {
                                                            effect: "dark",
                                                            content:
                                                              "Go to line settings",
                                                            placement: "left",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "text-dark-greenish",
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                                                    " +
                                                                  _vm._s(
                                                                    _vm.getCampaign(
                                                                      scope.row
                                                                        .campaign_id
                                                                    ).name ||
                                                                      "-"
                                                                  ) +
                                                                  "\n                                                "
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "text-greyish",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                                            -\n                                        "
                                                      ),
                                                    ]
                                                  ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      2828609841
                                    ),
                                  }),
                                  _vm._v(" "),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "throttle_limit",
                                      label: "Throttling",
                                      "min-width": 150,
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _c(
                                                "div",
                                                { staticClass: "text-left" },
                                                [
                                                  scope.row.throttle_limit > 0
                                                    ? _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.getThrottling(
                                                              scope.row
                                                                .throttle_limit
                                                            )
                                                          )
                                                        ),
                                                      ])
                                                    : _c("span", [_vm._v("-")]),
                                                ]
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      280901609
                                    ),
                                  }),
                                  _vm._v(" "),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "created_at",
                                      label: "Date Created",
                                      "min-width": 150,
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _c(
                                                "div",
                                                { staticClass: "text-left" },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm._f("fixDateTime")(
                                                          scope.row.created_at
                                                        )
                                                      )
                                                    ),
                                                  ]),
                                                ]
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      1487292802
                                    ),
                                  }),
                                  _vm._v(" "),
                                  _c("el-table-column", {
                                    attrs: {
                                      label: "Operations",
                                      align: "center",
                                      width: "120",
                                      fixed: "right",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _c(
                                                "div",
                                                {
                                                  style: _vm.getStyle(
                                                    scope.row.id
                                                  ),
                                                },
                                                [
                                                  scope.row.description
                                                    ? _c(
                                                        "el-popover",
                                                        {
                                                          attrs: {
                                                            placement: "right",
                                                            width: "400",
                                                            trigger: "click",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "p",
                                                            {
                                                              staticClass:
                                                                "_600",
                                                            },
                                                            [_vm._v("Message:")]
                                                          ),
                                                          _vm._v(" "),
                                                          _c("p", {
                                                            staticClass:
                                                              "text-sm",
                                                            staticStyle: {
                                                              color: "#606266",
                                                              "max-width":
                                                                "90%",
                                                              overflow: "auto",
                                                            },
                                                            domProps: {
                                                              innerHTML: _vm._s(
                                                                _vm.$options.filters.nl2br(
                                                                  scope.row
                                                                    .description
                                                                )
                                                              ),
                                                            },
                                                          }),
                                                          _vm._v(" "),
                                                          _c(
                                                            "button",
                                                            {
                                                              staticClass:
                                                                "btn btn-sm btn-block btn-dark",
                                                              attrs: {
                                                                slot: "reference",
                                                              },
                                                              slot: "reference",
                                                            },
                                                            [
                                                              _c("i", {
                                                                staticClass:
                                                                  "fa fa-info-circle pull-left",
                                                              }),
                                                              _vm._v(
                                                                "\n                                                        Content\n                                                    "
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  _vm.hasPermissionTo([
                                                    "list broadcast",
                                                    "list communication",
                                                    "view communication",
                                                  ])
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "row mt-2",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "col-12",
                                                            },
                                                            [
                                                              _c(
                                                                "router-link",
                                                                {
                                                                  attrs: {
                                                                    to: {
                                                                      name: "Broadcast Activity",
                                                                      params: {
                                                                        broadcast_id:
                                                                          scope
                                                                            .row
                                                                            .id,
                                                                      },
                                                                    },
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "button",
                                                                    {
                                                                      staticClass:
                                                                        "btn btn-sm btn-block purplish",
                                                                    },
                                                                    [
                                                                      _c("i", {
                                                                        staticClass:
                                                                          "fa fa-signal pull-left",
                                                                      }),
                                                                      _vm._v(
                                                                        "\n                                                                Activity\n                                                            "
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  _vm.hasPermissionTo(
                                                    "update broadcast"
                                                  ) &&
                                                  [
                                                    _vm.BroadcastStatuses
                                                      .STATUS_ENROLLING,
                                                    _vm.BroadcastStatuses
                                                      .STATUS_PAUSED,
                                                    _vm.BroadcastStatuses
                                                      .STATUS_NEW,
                                                  ].includes(scope.row.status)
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "row mt-2",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "col-12",
                                                            },
                                                            [
                                                              _c(
                                                                "button",
                                                                {
                                                                  staticClass:
                                                                    "btn btn-sm btn-block",
                                                                  class:
                                                                    scope.row
                                                                      .status ===
                                                                    _vm
                                                                      .BroadcastStatuses
                                                                      .STATUS_PAUSED
                                                                      ? "light-blue-500"
                                                                      : "deep-orange-500",
                                                                  attrs: {
                                                                    disabled:
                                                                      _vm.loading_btn_id ==
                                                                      scope.row
                                                                        .id,
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.toggleActive(
                                                                          scope
                                                                            .row
                                                                            .id
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _vm.loading_btn_id ==
                                                                  scope.row.id
                                                                    ? _c("i", {
                                                                        staticClass:
                                                                          "fa fa-spin fa-spinner pull-left",
                                                                      })
                                                                    : [
                                                                        [
                                                                          _vm
                                                                            .BroadcastStatuses
                                                                            .STATUS_ENROLLING,
                                                                          _vm
                                                                            .BroadcastStatuses
                                                                            .STATUS_NEW,
                                                                        ].includes(
                                                                          scope
                                                                            .row
                                                                            .status
                                                                        )
                                                                          ? _c(
                                                                              "i",
                                                                              {
                                                                                staticClass:
                                                                                  "fa fa-pause pull-left",
                                                                              }
                                                                            )
                                                                          : _c(
                                                                              "i",
                                                                              {
                                                                                staticClass:
                                                                                  "fa fa-play pull-left",
                                                                              }
                                                                            ),
                                                                      ],
                                                                  _vm._v(
                                                                    "\n                                                            " +
                                                                      _vm._s(
                                                                        scope
                                                                          .row
                                                                          .status ===
                                                                          _vm
                                                                            .BroadcastStatuses
                                                                            .STATUS_PAUSED
                                                                          ? "Activate"
                                                                          : "Pause"
                                                                      ) +
                                                                      "\n                                                        "
                                                                  ),
                                                                ],
                                                                2
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    { staticClass: "row mt-2" },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass: "col-12",
                                                        },
                                                        [
                                                          _c(
                                                            "rename-broadcast",
                                                            {
                                                              attrs: {
                                                                broadcast:
                                                                  scope.row,
                                                              },
                                                              on: {
                                                                success:
                                                                  _vm.updateBroadcastData,
                                                              },
                                                            }
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _vm.hasRole(
                                                    "Company Admin"
                                                  ) &&
                                                  _vm.hasPermissionTo(
                                                    "delete broadcast"
                                                  )
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "row mt-2",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "col-12",
                                                            },
                                                            [
                                                              _c(
                                                                "button",
                                                                {
                                                                  staticClass:
                                                                    "btn btn-sm btn-block btn-danger",
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.deleteBroadcastRemote(
                                                                          scope
                                                                            .row
                                                                            .id
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _c("i", {
                                                                    staticClass:
                                                                      "fa fa-trash",
                                                                  }),
                                                                  _vm._v(
                                                                    " Delete\n                                                        "
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                ],
                                                1
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      3370900805
                                    ),
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "template",
                                    {
                                      staticClass: "text-center text-muted",
                                      slot: "empty",
                                    },
                                    [
                                      !_vm.loading
                                        ? _c("span", [_vm._v("No Data")])
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.loading ? _c("span") : _vm._e(),
                                    ]
                                  ),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.pageCount >= 1
                            ? _c(
                                "div",
                                { staticClass: "mt-3 no-select" },
                                [
                                  _c("el-pagination", {
                                    staticClass: "pull-right",
                                    attrs: {
                                      background: "",
                                      layout: "prev, pager, next",
                                      "page-size": _vm.per_page,
                                      "current-page":
                                        _vm.pagination.current_page,
                                      total: _vm.total,
                                    },
                                    on: {
                                      "current-change": _vm.changePage,
                                      "size-change": _vm.handleSizeChange,
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "clearfix" }),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]),
                      ]),
                    ]),
                  ]),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("el-backtop", {
              ref: "backtop",
              attrs: { target: "html", bottom: 20, right: 20 },
            }),
          ]
        : _vm._e(),
      _vm._v(" "),
      !_vm.shouldShowBroadcast && _vm.shouldShowUpgradeNow
        ? _c("upgrade-now-page", {
            staticClass: "mt-5",
            attrs: {
              "image-link": "/assets/images/Broadcast.svg",
              text: "Scale your outreach with the click of a button. Send captivating SMS campaigns to many contacts at once via Broadcast.",
              "extra-text": "Upgrade today to unlock this feature",
              "title-text": "Broadcast",
              "kb-link":
                "https://support.aloware.com/en/articles/9031607-a-guide-to-aloware-admin-broadcast",
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.isTrial && _vm.isNotSimpSocial
        ? _c("video-modal", {
            staticClass: "pl-2",
            attrs: {
              title: "Master Bulk SMS with Aloware Broadcast 💬",
              "cookie-name": "broadcasts",
              notes:
                "Dive deep into Aloware's Bulk SMS feature, perfect for boosting your marketing campaigns.#SuperchargeYourOutreach. </br></br> 📱 Explore the power of Aloware's Bulk SMS Broadcast: a game-changer for your marketing strategies.",
              "video-url":
                "https://www.youtube.com/embed/nSAAyHLAnk4?si=J6vLHzAAXlrDd6l6",
              "learn-more-link":
                "https://support.aloware.com/en/articles/9031607-a-guide-to-aloware-admin-broadcast",
              "has-activator-button": true,
            },
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }