var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "wizard d-flex flex-column full-height",
    },
    [
      _c(
        "el-form",
        {
          ref: _vm.cardname,
          staticClass: "flex-1 scroll-y px-4",
          attrs: {
            "label-position": "left",
            rules: _vm.rules,
            model: _vm.card,
          },
        },
        [
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-md-12" },
              [
                _vm.cardname === "hubspot" &&
                _vm.isBlockedFrom("integrations hubspot workflows")
                  ? _c(
                      "div",
                      { staticClass: "mb-3" },
                      [
                        _vm.shouldShowUpgradeNow
                          ? _c("upgrade-now-lite", {
                              attrs: {
                                "kb-link":
                                  "https://support.aloware.com/en/articles/9019886-aloware-actions-for-ticket-based-and-deal-based-workflows-in-hubspot",
                                text: "Workflows on HubSpot is not included in your current plan. To use it, please contact us to upgrade today!",
                                "default-click": true,
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.cardname === "hubspot"
                  ? _c("info-alert-box", {
                      attrs: {
                        text: "HubSpot Workflow > Send a text (SMS/MMS) action's usage is considered automated and paid. You are agreeing to our billing terms by using the action in HubSpot.",
                        "kb-link":
                          "https://support.aloware.com/en/articles/9033804-aloware-pricing-plans-empowering-your-teams-with-flexible-solutions",
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "h5",
                  [
                    _vm._v(
                      "\n                    General\n                    "
                    ),
                    _vm.cardname == "stripe"
                      ? _c(
                          "el-tooltip",
                          {
                            staticClass: "item",
                            attrs: { effect: "dark", placement: "right" },
                          },
                          [
                            _c(
                              "div",
                              { attrs: { slot: "content" }, slot: "content" },
                              [
                                _c("small", [
                                  _vm._v(
                                    "\n                                Our Stripe integration will only add a search button to your contacts and does\n                                "
                                  ),
                                  _c("br"),
                                  _vm._v(
                                    "\n                                NOT\n                                push or pull data from your Stripe account.\n                            "
                                  ),
                                ]),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "i",
                              {
                                staticClass:
                                  "material-icons text-lg cursor-pointer text-blue p-0",
                              },
                              [_vm._v("info_outline")]
                            ),
                          ]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("hr", { staticClass: "no-top-margin" }),
                _vm._v(" "),
                _vm.hasOtherEmailIntegrationEnabled(_vm.cardname)
                  ? _c("div", [
                      _c("strong", [_vm._v("Note:")]),
                      _vm._v(" "),
                      _c("small", [
                        _vm._v(
                          "\n                        You must disable other email integrations first before you can enable this integration.\n                    "
                        ),
                      ]),
                    ])
                  : [
                      _vm.integrations_always_show_status.includes(_vm.cardname)
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "row form-group align-content-center",
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "col" },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      staticClass: "my-0 inline",
                                      attrs: { prop: "status" },
                                    },
                                    [
                                      _vm.isStatusToggleDisabled
                                        ? [
                                            _c(
                                              "el-tooltip",
                                              {
                                                staticClass: "item",
                                                attrs: {
                                                  effect: "dark",
                                                  content:
                                                    "You can only enable one CRM integration.",
                                                  placement: "top",
                                                },
                                              },
                                              [
                                                _c("el-switch", {
                                                  attrs: {
                                                    disabled:
                                                      _vm.isStatusToggleDisabled,
                                                    "active-color": "#00a344",
                                                  },
                                                  on: {
                                                    change: _vm.checkAndSave,
                                                  },
                                                  model: {
                                                    value: _vm.card.status,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.card,
                                                        "status",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "card.status",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ]
                                        : _c("el-switch", {
                                            attrs: {
                                              value:
                                                !_vm.isStatusToggleDisabled,
                                              "active-color": "#00a344",
                                            },
                                            on: { change: _vm.checkAndSave },
                                          }),
                                    ],
                                    2
                                  ),
                                  _vm._v(
                                    "\n                            Integration Status\n                        "
                                  ),
                                ],
                                1
                              ),
                            ]
                          )
                        : !_vm.integrations_always_show_status.includes(
                            _vm.cardname
                          ) && _vm.card.status
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "row form-group align-content-center",
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "col" },
                                [
                                  _vm.cardname == "sendgrid" ||
                                  _vm.cardname == "mailgun" ||
                                  _vm.cardname == "SMTP"
                                    ? _c(
                                        "el-form-item",
                                        {
                                          staticClass: "ml-1 my-0 inline",
                                          attrs: { prop: "status" },
                                        },
                                        [
                                          _c("el-switch", {
                                            attrs: {
                                              "active-color": "#00a344",
                                            },
                                            on: {
                                              change:
                                                _vm.checkCompanyUpdatedInfo,
                                            },
                                            model: {
                                              value: _vm.card.status,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.card,
                                                  "status",
                                                  $$v
                                                )
                                              },
                                              expression: "card.status",
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm.cardname == "salesforce"
                                    ? _c(
                                        "el-form-item",
                                        {
                                          staticClass: "ml-1 my-0 inline",
                                          attrs: { prop: "status" },
                                        },
                                        [
                                          _c("el-switch", {
                                            attrs: {
                                              disabled:
                                                _vm.cardname == "salesforce",
                                              "active-color": "#00a344",
                                            },
                                            on: { change: _vm.checkAndSave },
                                            model: {
                                              value: _vm.card.status,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.card,
                                                  "status",
                                                  $$v
                                                )
                                              },
                                              expression: "card.status",
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _c(
                                        "el-form-item",
                                        {
                                          staticClass: "my-0 inline",
                                          attrs: { prop: "status" },
                                        },
                                        [
                                          _vm.isStatusToggleDisabled
                                            ? [
                                                _c(
                                                  "el-tooltip",
                                                  {
                                                    staticClass: "item",
                                                    attrs: {
                                                      effect: "dark",
                                                      content:
                                                        "You can only enable one CRM integration.",
                                                      placement: "top",
                                                    },
                                                  },
                                                  [
                                                    _c("el-switch", {
                                                      attrs: {
                                                        disabled:
                                                          _vm.isStatusToggleDisabled,
                                                        "active-color":
                                                          "#00a344",
                                                      },
                                                      on: {
                                                        change:
                                                          _vm.checkAndSave,
                                                      },
                                                      model: {
                                                        value: _vm.card.status,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.card,
                                                            "status",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "card.status",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            : _c("el-switch", {
                                                attrs: {
                                                  "active-color": "#00a344",
                                                },
                                                on: {
                                                  change: _vm.checkAndSave,
                                                },
                                                model: {
                                                  value: _vm.card.status,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.card,
                                                      "status",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "card.status",
                                                },
                                              }),
                                        ],
                                        2
                                      ),
                                  _vm._v(
                                    "\n                            Integration Status\n                        "
                                  ),
                                ],
                                1
                              ),
                            ]
                          )
                        : _vm._e(),
                    ],
                _vm._v(" "),
                _vm.cardname == "google-analytics"
                  ? [
                      _c(
                        "div",
                        { staticClass: "row form-group align-items-center" },
                        [
                          _c("div", { staticClass: "col" }, [
                            _c("h6", { staticClass: "text-black" }, [
                              _vm._v("Tracking ID"),
                            ]),
                            _vm._v(" "),
                            _c(
                              "small",
                              { staticClass: "p-0 m-0 block text-black-lt" },
                              [
                                _vm._v(
                                  "\n                                This is the tracking ID you can get from your google analytics account.\n                                e.g.,\n                                "
                                ),
                                _c("code", [_vm._v("UA-123456789-1")]),
                              ]
                            ),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "row form-group align-items-center" },
                        [
                          _c(
                            "div",
                            { staticClass: "col-6" },
                            [
                              _c(
                                "el-form-item",
                                {
                                  staticClass: "pull-right m-0 w-100",
                                  attrs: { prop: "credentials" },
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      placeholder: "Put your tracking id here",
                                      clearable: "",
                                    },
                                    on: { input: _vm.customPreValidateForm },
                                    model: {
                                      value: _vm.card.credentials.tracking_id,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.card.credentials,
                                          "tracking_id",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "card.credentials.tracking_id",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "row form-group mt-4" }, [
                        _c("div", { staticClass: "col" }, [
                          _c("h6", { staticClass: "text-black" }, [
                            _vm._v("Include Outbound Communications"),
                          ]),
                          _vm._v(" "),
                          _c(
                            "small",
                            { staticClass: "p-0 m-0 block text-black-lt" },
                            [
                              _vm._v(
                                "\n                                Enabling this will report outbound communications to\n                                " +
                                  _vm._s(
                                    _vm._f("ucwords")(
                                      _vm._f("prettifyCamelCase")(_vm.cardname)
                                    )
                                  ) +
                                  ".\n                            "
                              ),
                            ]
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "col" },
                          [
                            _c(
                              "el-form-item",
                              {
                                staticClass: "pull-right m-0",
                                attrs: { prop: "settings" },
                              },
                              [
                                _c("el-switch", {
                                  attrs: { "active-color": "#00a344" },
                                  on: { change: _vm.customPreValidateForm },
                                  model: {
                                    value: _vm.card.settings.report_outbound,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.card.settings,
                                        "report_outbound",
                                        $$v
                                      )
                                    },
                                    expression: "card.settings.report_outbound",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                    ]
                  : _vm._e(),
                _vm._v(" "),
                _vm.cardname == "google-calendar"
                  ? [
                      _vm.card.credentials.email
                        ? _c(
                            "div",
                            {
                              staticClass: "row form-group align-items-center",
                            },
                            [
                              _c("div", { staticClass: "col-6" }, [
                                _c("small", [
                                  _vm._v("Synched Google Account: "),
                                  _c("b", [
                                    _vm._v(_vm._s(_vm.card.credentials.email)),
                                  ]),
                                ]),
                              ]),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "div",
                        [
                          _c(
                            "el-tooltip",
                            {
                              staticClass: "item",
                              attrs: { effect: "dark", placement: "right" },
                            },
                            [
                              _c(
                                "div",
                                { attrs: { slot: "content" }, slot: "content" },
                                [
                                  _c("strong", [_vm._v("Authentication")]),
                                  _vm._v(" "),
                                  _c("br"),
                                  _vm._v(" "),
                                  _c("small", [
                                    _vm._v(
                                      "\n                                    This will redirect you to Google for authentication.\n                                "
                                    ),
                                  ]),
                                ]
                              ),
                              _vm._v(" "),
                              !_vm.card.credentials.token
                                ? _c("GoogleSignInButton", {
                                    staticClass: "d-inline py-2",
                                    attrs: { text: "Sync with Google" },
                                    nativeOn: {
                                      click: function ($event) {
                                        return _vm.oauth(
                                          "google",
                                          _vm.getGoogleCalendarParams
                                        )
                                      },
                                    },
                                  })
                                : _c("GoogleSignInButton", {
                                    staticClass: "d-inline py-2",
                                    attrs: { text: "Re-Sync with Google" },
                                    nativeOn: {
                                      click: function ($event) {
                                        return _vm.oauth(
                                          "google",
                                          _vm.getGoogleCalendarParams
                                        )
                                      },
                                    },
                                  }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.card.credentials.email
                            ? _c(
                                "div",
                                [
                                  _c("h5", { staticClass: "mt-4" }, [
                                    _vm._v("Google Calendar Settings"),
                                  ]),
                                  _vm._v(" "),
                                  _c("hr", { staticClass: "no-top-margin" }),
                                  _vm._v(" "),
                                  _c("h6", { staticClass: "mt-4 text-black" }, [
                                    _vm._v("Synched Calendar"),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        filterable: "",
                                        placeholder: "Calendar",
                                      },
                                      on: {
                                        change: function ($event) {
                                          return _vm.submit(
                                            _vm.card.name,
                                            false
                                          )
                                        },
                                      },
                                      model: {
                                        value: _vm.card.settings.calendar.id,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.card.settings.calendar,
                                            "id",
                                            $$v
                                          )
                                        },
                                        expression: "card.settings.calendar.id",
                                      },
                                    },
                                    _vm._l(
                                      _vm.card.settings.calendars,
                                      function (calendar_list_entry, idx) {
                                        return _c("el-option", {
                                          key: idx,
                                          attrs: {
                                            label: calendar_list_entry.summary,
                                            value: calendar_list_entry.id,
                                          },
                                        })
                                      }
                                    ),
                                    1
                                  ),
                                  _vm._v(" "),
                                  _vm.card.settings.calendar.id
                                    ? _c(
                                        "h6",
                                        { staticClass: "mt-4 text-black" },
                                        [
                                          _vm._v(
                                            "\n                                Calendar Timezone\n                            "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c(
                                    "el-select",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.card.settings.calendar.id,
                                          expression:
                                            "card.settings.calendar.id",
                                        },
                                      ],
                                      attrs: {
                                        filterable: "",
                                        placeholder: "Timezone",
                                      },
                                      model: {
                                        value:
                                          _vm.card.settings.calendar.timeZone,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.card.settings.calendar,
                                            "timeZone",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "card.settings.calendar.timeZone",
                                      },
                                    },
                                    [
                                      _c("el-option", {
                                        attrs: {
                                          label:
                                            _vm.card.settings.calendar.timeZone,
                                          value:
                                            _vm.card.settings.calendar.timeZone,
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.card.settings.calendar.id
                            ? [
                                _c("h5", { staticClass: "mt-4" }, [
                                  _vm._v("Manual Sync"),
                                ]),
                                _vm._v(" "),
                                _c("hr", { staticClass: "no-top-margin" }),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "row form-group align-items-center",
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "col" },
                                      [
                                        _c(
                                          "h6",
                                          { staticClass: "text-black" },
                                          [_vm._v("Sync Calendar")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "small",
                                          {
                                            staticClass:
                                              "p-0 m-0 block text-black-lt",
                                          },
                                          [
                                            _c(
                                              "span",
                                              { staticClass: "mb-1" },
                                              [
                                                _vm._v(
                                                  "\n                                            Use "
                                                ),
                                                _c("b", [
                                                  _vm._v("push calendar"),
                                                ]),
                                                _vm._v(" to see your "),
                                                _c("b", [_vm._v("upcoming")]),
                                                _vm._v(
                                                  " Aloware Calendar Events and Appointments in Google Calendar.\n                                        "
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c("br"),
                                            _vm._v(" "),
                                            _c(
                                              "span",
                                              { staticClass: "mb-1" },
                                              [
                                                _vm._v(
                                                  "\n                                            Use "
                                                ),
                                                _c("b", [
                                                  _vm._v("pull calendar"),
                                                ]),
                                                _vm._v(" to see all your "),
                                                _c("b", [_vm._v("upcoming")]),
                                                _vm._v(
                                                  " Google Calendar Events within Aloware Calendar.\n                                        "
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-alert",
                                          {
                                            staticClass: "my-2",
                                            attrs: {
                                              type: "info",
                                              closable: false,
                                              "show-icon": "",
                                            },
                                          },
                                          [
                                            _c("b", [_vm._v("Note")]),
                                            _vm._v(
                                              ": Pulling calendar events will update users and contacts events and appointments.\n                                    "
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "row form-group align-items-center",
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "col d-flex justify-content-end",
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "d-flex flex-column mr-2",
                                          },
                                          [
                                            _c(
                                              "el-button",
                                              {
                                                attrs: {
                                                  disabled:
                                                    _vm.loading_push ||
                                                    [585, 1000].includes(
                                                      _vm.current_company.id
                                                    ),
                                                  plain: "",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.pushCalendar()
                                                  },
                                                },
                                              },
                                              [
                                                _c("span", [
                                                  _vm._v(
                                                    "Push Upcoming Events"
                                                  ),
                                                ]),
                                                _vm._v(" "),
                                                !_vm.loading_push
                                                  ? _c("i", {
                                                      staticClass:
                                                        "fa fa-arrow-up",
                                                    })
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                _vm.loading_push
                                                  ? _c(
                                                      "i",
                                                      {
                                                        staticClass:
                                                          "material-icons loader pull-left",
                                                      },
                                                      [_vm._v("")]
                                                    )
                                                  : _vm._e(),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "d-flex flex-column" },
                                          [
                                            _c(
                                              "el-button",
                                              {
                                                attrs: {
                                                  disabled:
                                                    _vm.loading_pull ||
                                                    [585, 1000].includes(
                                                      _vm.current_company.id
                                                    ),
                                                  plain: "",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.pullCalendar()
                                                  },
                                                },
                                              },
                                              [
                                                _c("span", [
                                                  _vm._v(
                                                    "Pull Upcoming Events"
                                                  ),
                                                ]),
                                                _vm._v(" "),
                                                !_vm.loading_pull
                                                  ? _c("i", {
                                                      staticClass:
                                                        "fa fa-arrow-down",
                                                    })
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                _vm.loading_pull
                                                  ? _c(
                                                      "i",
                                                      {
                                                        staticClass:
                                                          "material-icons loader pull-left",
                                                      },
                                                      [_vm._v("")]
                                                    )
                                                  : _vm._e(),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "row form-group align-items-center",
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "col" },
                                      [
                                        _c(
                                          "h6",
                                          { staticClass: "text-black" },
                                          [_vm._v("Watch Google Calendar")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "small",
                                          {
                                            staticClass:
                                              "p-0 m-0 block text-black-lt",
                                          },
                                          [
                                            _c(
                                              "span",
                                              { staticClass: "mb-1" },
                                              [
                                                _vm._v(
                                                  "\n                                            Use "
                                                ),
                                                _c("b", [
                                                  _vm._v("start watching"),
                                                ]),
                                                _vm._v(
                                                  " to let Aloware know when an event is added in your Google Calendar from now on.\n                                        "
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c("br"),
                                            _vm._v(" "),
                                            _c(
                                              "span",
                                              { staticClass: "mb-1" },
                                              [
                                                _vm._v(
                                                  "\n                                            Use "
                                                ),
                                                _c("b", [
                                                  _vm._v("stop watching"),
                                                ]),
                                                _vm._v(
                                                  " to stop adding events created in you Google Calendar.\n                                        "
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-alert",
                                          {
                                            staticClass: "my-2",
                                            attrs: {
                                              type: "info",
                                              closable: false,
                                              "show-icon": "",
                                            },
                                          },
                                          [
                                            _c("b", [_vm._v("Note")]),
                                            _vm._v(": If you "),
                                            _c("b", [_vm._v("stop watching")]),
                                            _vm._v(
                                              ", events and appointments created in Aloware Calendar will still be reflected in Google Calendar\n                                    "
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "col d-flex justify-content-end",
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "d-flex flex-column mr-2",
                                          },
                                          [
                                            _c("el-switch", {
                                              attrs: {
                                                "active-text":
                                                  "Watch Google Calendar Events",
                                              },
                                              on: { change: _vm.watchCalendar },
                                              model: {
                                                value:
                                                  _vm.shouldWatchGoogleCalendar,
                                                callback: function ($$v) {
                                                  _vm.shouldWatchGoogleCalendar =
                                                    $$v
                                                },
                                                expression:
                                                  "shouldWatchGoogleCalendar",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            : _vm._e(),
                        ],
                        2
                      ),
                    ]
                  : _vm._e(),
                _vm._v(" "),
                _vm.cardname == "custom_link_builder" && _vm.card.status
                  ? [
                      _c(
                        "div",
                        { staticClass: "row form-group align-items-center" },
                        [
                          _c("div", { staticClass: "col" }, [
                            _c("h6", { staticClass: "text-black" }, [
                              _vm._v("Custom link"),
                            ]),
                            _vm._v(" "),
                            _c(
                              "small",
                              { staticClass: "p-0 m-0 block text-black-lt" },
                              [
                                _vm._v(
                                  "\n                                Our custom link integration will help you add a dynamically generated link\n                                to an external system based on contact information\n                            "
                                ),
                              ]
                            ),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "row form-group align-items-center" },
                        [
                          _c(
                            "div",
                            { staticClass: "col" },
                            [
                              _c(
                                "el-form-item",
                                {
                                  staticClass: "pull-right m-0 w-100",
                                  attrs: { prop: "settings.custom_link" },
                                },
                                [
                                  _c(
                                    "el-input",
                                    {
                                      attrs: {
                                        placeholder:
                                          "Put your custom link here",
                                        clearable: "",
                                      },
                                      on: {
                                        input: function ($event) {
                                          return _vm.getNewCustomLink()
                                        },
                                      },
                                      model: {
                                        value: _vm.card.settings.custom_link,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.card.settings,
                                            "custom_link",
                                            $$v
                                          )
                                        },
                                        expression: "card.settings.custom_link",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-tooltip",
                                        {
                                          attrs: {
                                            slot: "append",
                                            content: "Add variable",
                                            placement: "bottom",
                                          },
                                          slot: "append",
                                        },
                                        [
                                          _c("variables", {
                                            attrs: {
                                              popover_placement: "bottom-start",
                                              icon: "fa fa-plus",
                                              variable_btn_class:
                                                "mx-auto border-0 bg-transparent",
                                            },
                                            on: {
                                              "variable-selected":
                                                _vm.variableSelected,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _vm._l(_vm.card.settings.tags, function (tag) {
                                return _c(
                                  "el-tag",
                                  {
                                    key: tag.name,
                                    staticClass: "mt-4 mx-1",
                                    attrs: {
                                      closable: "",
                                      type: tag.type,
                                      effect: "dark",
                                    },
                                    on: {
                                      close: function ($event) {
                                        return _vm.removeTag(tag)
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                                " +
                                        _vm._s(tag.name) +
                                        "\n                            "
                                    ),
                                  ]
                                )
                              }),
                            ],
                            2
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _vm.card.settings.selected_contact
                        ? _c(
                            "div",
                            {
                              staticClass: "row form-group align-items-center",
                            },
                            [
                              _c("div", { staticClass: "col" }, [
                                _vm._v(
                                  "\n                            Link preview:\n                            "
                                ),
                                _c(
                                  "small",
                                  {
                                    staticClass:
                                      "p-0 m-0 text-black-lt text-xs",
                                  },
                                  [
                                    _c(
                                      "a",
                                      {
                                        staticClass: "text-bluish _600",
                                        attrs: {
                                          href: _vm.card.settings
                                            .selected_contact.custom_link,
                                          target: "_blank",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                                    " +
                                            _vm._s(
                                              _vm.card.settings.selected_contact
                                                .custom_link
                                            ) +
                                            "\n                                "
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "row form-group align-items-center mt-4",
                        },
                        [
                          _c("div", { staticClass: "col" }, [
                            _c("h6", { staticClass: "text-black" }, [
                              _vm._v("Variable Separator"),
                            ]),
                            _vm._v(" "),
                            _c(
                              "small",
                              { staticClass: "p-0 m-0 block text-black-lt" },
                              [
                                _vm._v(
                                  "\n                                Automatically insert before each new variable in the url. You can also type the character manually in the link builder\n                            "
                                ),
                              ]
                            ),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "row form-group align-items-center" },
                        [
                          _c("div", { staticClass: "col-6" }, [
                            _c(
                              "div",
                              { staticClass: "d-flex" },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    staticClass: "m-0 p-0 w-full",
                                    attrs: { prop: "settings.prefix" },
                                  },
                                  [
                                    _c(
                                      "el-input",
                                      {
                                        attrs: {
                                          placeholder: "e.g. (/, &, etc.)",
                                          clearable: "",
                                        },
                                        model: {
                                          value: _vm.card.settings.prefix,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.card.settings,
                                              "prefix",
                                              $$v
                                            )
                                          },
                                          expression: "card.settings.prefix",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              slot: "append",
                                              disabled:
                                                !_vm.card.settings.prefix,
                                              plain: "",
                                              size: "small",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.replacePrefix()
                                              },
                                            },
                                            slot: "append",
                                          },
                                          [_c("span", [_vm._v("Replace all")])]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "row form-group align-items-center mt-4 mb-0",
                        },
                        [
                          _c("div", { staticClass: "col" }, [
                            _c("h6", { staticClass: "text-black" }, [
                              _vm._v("Settings"),
                            ]),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "row form-group align-items-center" },
                        [
                          _c(
                            "div",
                            { staticClass: "col" },
                            [
                              _c(
                                "el-form-item",
                                {
                                  staticClass: "my-0 inline",
                                  attrs: {
                                    prop: "settings.remove_special_char",
                                  },
                                },
                                [
                                  _c("el-switch", {
                                    attrs: { "active-color": "#00a344" },
                                    on: {
                                      change: function ($event) {
                                        return _vm.getNewCustomLink()
                                      },
                                    },
                                    model: {
                                      value:
                                        _vm.card.settings.remove_special_char,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.card.settings,
                                          "remove_special_char",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "card.settings.remove_special_char",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(
                                "\n                            Remove special characters in phone number\n                        "
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "row form-group align-items-center mt-4",
                        },
                        [
                          _c("div", { staticClass: "col" }, [
                            _c("h6", { staticClass: "text-black" }, [
                              _vm._v("Contact"),
                            ]),
                            _vm._v(" "),
                            _c(
                              "small",
                              { staticClass: "p-0 m-0 block text-black-lt" },
                              [
                                _vm._v(
                                  "\n                                Search contacts to see link preview while building your URL\n                            "
                                ),
                              ]
                            ),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "row form-group align-items-center" },
                        [
                          _c(
                            "div",
                            { staticClass: "col-6" },
                            [
                              _c("contact-select", {
                                attrs: {
                                  label: "Search contact",
                                  contact_selected:
                                    _vm.card.settings.selected_contact,
                                },
                                on: { "select-contacts": _vm.selectContact },
                                model: {
                                  value: _vm.card.settings.selected_contact,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.card.settings,
                                      "selected_contact",
                                      $$v
                                    )
                                  },
                                  expression: "card.settings.selected_contact",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _vm.card.settings.selected_contact
                        ? _c(
                            "div",
                            {
                              staticClass: "row form-group align-items-center",
                            },
                            [
                              _c("div", { staticClass: "col" }, [
                                _vm._v(
                                  "\n                            Currently selected contact:\n                            "
                                ),
                                _c(
                                  "small",
                                  {
                                    staticClass:
                                      "p-0 m-0 text-black-lt text-xs",
                                  },
                                  [
                                    _vm._v(
                                      "\n                                " +
                                        _vm._s(
                                          _vm.card.settings.selected_contact
                                            .name
                                        ) +
                                        "\n                            "
                                    ),
                                  ]
                                ),
                              ]),
                            ]
                          )
                        : _vm._e(),
                    ]
                  : _vm._e(),
                _vm._v(" "),
                _vm.cardname == "hubspot"
                  ? [
                      _c("div", { staticClass: "row form-group" }, [
                        _c("div", { staticClass: "col d-flex" }, [
                          _c(
                            "div",
                            { staticClass: "mr-2" },
                            [
                              _vm.card.credentials.access_token !== ""
                                ? _c("integration-settings-tester-btn", {
                                    attrs: {
                                      name: _vm.cardname,
                                      card: _vm.card,
                                    },
                                    on: { reload: _vm.fetchDetails },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            [
                              _c(
                                "el-tooltip",
                                {
                                  staticClass: "item",
                                  attrs: { effect: "dark", placement: "right" },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      attrs: { slot: "content" },
                                      slot: "content",
                                    },
                                    [
                                      _c("strong", [_vm._v("Authentication")]),
                                      _vm._v(" "),
                                      _c("br"),
                                      _vm._v(" "),
                                      _c("small", [
                                        _vm._v(
                                          "\n                                            This will redirect you to HubSpot for authentication.\n                                        "
                                        ),
                                      ]),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _vm.card.credentials.access_token !== ""
                                    ? _c(
                                        "el-button",
                                        {
                                          attrs: { plain: "" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.oauth(_vm.cardname)
                                            },
                                          },
                                        },
                                        [
                                          _c("span", [
                                            _vm._v("Re-Authenticate"),
                                          ]),
                                          _vm._v(" "),
                                          _c("i", {
                                            staticClass: "fa fa-sign-in mr-2",
                                          }),
                                        ]
                                      )
                                    : !_vm.isStatusToggleDisabled
                                    ? _c(
                                        "el-button",
                                        {
                                          attrs: { plain: "" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.oauth(_vm.cardname)
                                            },
                                          },
                                        },
                                        [
                                          _c("span", [_vm._v("Authenticate")]),
                                          _vm._v(" "),
                                          _c("i", {
                                            staticClass: "fa fa-sign-in mr-2",
                                          }),
                                        ]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _vm.isStatusToggleDisabled
                                ? _c(
                                    "el-alert",
                                    {
                                      staticClass: "my-2",
                                      attrs: {
                                        type: "info",
                                        closable: false,
                                        "show-icon": "",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                                    You can only enable one CRM integration.\n                                "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _vm.card.credentials.access_token !== ""
                        ? [
                            _c("h5", { staticClass: "mt-4" }, [
                              _vm._v("Synchronization"),
                            ]),
                            _vm._v(" "),
                            _c("hr", { staticClass: "no-top-margin" }),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "row form-group align-items-center",
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "col" },
                                  [
                                    _c("h6", { staticClass: "text-black" }, [
                                      _vm._v("Sync Contacts"),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "small",
                                      {
                                        staticClass:
                                          "p-0 m-0 block text-black-lt",
                                      },
                                      [
                                        _c("span", { staticClass: "mb-1" }, [
                                          _vm._v(
                                            "\n                                        Use "
                                          ),
                                          _c("b", [_vm._v("push contacts")]),
                                          _vm._v(
                                            " to see your " +
                                              _vm._s(_vm.statics.name) +
                                              " contacts in HubSpot\n                                    "
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _c("br"),
                                        _vm._v(" "),
                                        _c("span", { staticClass: "mb-1" }, [
                                          _vm._v(
                                            "\n                                        Use "
                                          ),
                                          _c("b", [_vm._v("pull contacts")]),
                                          _vm._v(
                                            " to see all your HubSpot contacts within " +
                                              _vm._s(_vm.statics.name) +
                                              "\n                                    "
                                          ),
                                        ]),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-alert",
                                      {
                                        staticClass: "my-2",
                                        attrs: {
                                          type: "info",
                                          closable: false,
                                          "show-icon": "",
                                        },
                                      },
                                      [
                                        _c("b", [_vm._v("Note")]),
                                        _vm._v(
                                          ": Pulling contacts will update " +
                                            _vm._s(_vm.statics.name) +
                                            " contacts using CRM data\n                                "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-alert",
                                      {
                                        staticClass: "my-2",
                                        attrs: {
                                          type: "warning",
                                          closable: false,
                                          "show-icon": "",
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "d-block mb-1 text-black",
                                          },
                                          [
                                            _vm._v(
                                              "\n                                        Pulling contacts will cause LRN lookup charges to be applied\n                                    "
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "col d-flex justify-content-end",
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "d-flex flex-column mr-2",
                                      },
                                      [
                                        _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              disabled:
                                                _vm.loading_push ||
                                                [585, 1000].includes(
                                                  _vm.current_company.id
                                                ),
                                              plain: "",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.pushContacts(
                                                  _vm.cardname
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("span", [
                                              _vm._v("Push Contacts"),
                                            ]),
                                            _vm._v(" "),
                                            !_vm.loading_push
                                              ? _c("i", {
                                                  staticClass: "fa fa-arrow-up",
                                                })
                                              : _vm._e(),
                                            _vm._v(" "),
                                            _vm.loading_push
                                              ? _c(
                                                  "i",
                                                  {
                                                    staticClass:
                                                      "material-icons loader pull-left",
                                                  },
                                                  [_vm._v("")]
                                                )
                                              : _vm._e(),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c("div", { staticClass: "pt-2" }, [
                                          _vm.card.settings &&
                                          _vm.card.settings
                                            .last_sync_push_contact
                                            ? _c(
                                                "small",
                                                {
                                                  staticClass:
                                                    "p-0 m-0 block text-black-lt text-xs",
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                            Last push contact: "
                                                  ),
                                                  _c("b", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm._f(
                                                          "fixFullDateUTCRelative"
                                                        )(
                                                          _vm.card.settings
                                                            .last_sync_push_contact
                                                        )
                                                      )
                                                    ),
                                                  ]),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "d-flex flex-column" },
                                      [
                                        _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              disabled:
                                                _vm.loading_pull ||
                                                [585, 1000].includes(
                                                  _vm.current_company.id
                                                ),
                                              plain: "",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.pullContacts(
                                                  _vm.cardname
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("span", [
                                              _vm._v("Pull Contacts"),
                                            ]),
                                            _vm._v(" "),
                                            !_vm.loading_pull
                                              ? _c("i", {
                                                  staticClass:
                                                    "fa fa-arrow-down",
                                                })
                                              : _vm._e(),
                                            _vm._v(" "),
                                            _vm.loading_pull
                                              ? _c(
                                                  "i",
                                                  {
                                                    staticClass:
                                                      "material-icons loader pull-left",
                                                  },
                                                  [_vm._v("")]
                                                )
                                              : _vm._e(),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c("div", { staticClass: "pt-2" }, [
                                          _vm.card.settings &&
                                          _vm.card.settings
                                            .last_sync_pull_contact
                                            ? _c(
                                                "small",
                                                {
                                                  staticClass:
                                                    "p-0 m-0 block text-black-lt text-xs",
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                            Last pull contact: "
                                                  ),
                                                  _c("b", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm._f(
                                                          "fixFullDateUTCRelative"
                                                        )(
                                                          _vm.card.settings
                                                            .last_sync_pull_contact
                                                        )
                                                      )
                                                    ),
                                                  ]),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "row form-group mt-3 align-items-center",
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "col" },
                                  [
                                    _c("h6", { staticClass: "text-black" }, [
                                      _vm._v("Sync Users"),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "small",
                                      {
                                        staticClass:
                                          "p-0 m-0 block text-black-lt",
                                      },
                                      [
                                        _vm._v(
                                          "\n                                    Connect your HubSpot users to " +
                                            _vm._s(_vm.statics.name) +
                                            " users.\n                                    "
                                        ),
                                        _c("br"),
                                        _vm._v(
                                          "\n                                    Only current users in " +
                                            _vm._s(_vm.statics.name) +
                                            " are synced, and no new users will be created.\n                                "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-alert",
                                      {
                                        staticClass: "my-2",
                                        attrs: {
                                          type: "info",
                                          closable: false,
                                          "show-icon": "",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                                    Heads up! Users are synced by email, please make sure your agents have the same email address on " +
                                            _vm._s(_vm.statics.name) +
                                            " and HubSpot.\n                                "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "col d-flex justify-content-end",
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "d-flex flex-column" },
                                      [
                                        _c(
                                          "el-button",
                                          {
                                            staticClass: "pull-right",
                                            attrs: {
                                              disabled: _vm.loading_pull_users,
                                              plain: "",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.pullUsers(
                                                  _vm.cardname
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("span", [_vm._v("Sync Users")]),
                                            _vm._v(" "),
                                            _vm.loading_pull_users
                                              ? _c(
                                                  "i",
                                                  {
                                                    staticClass:
                                                      "material-icons loader pull-left",
                                                  },
                                                  [_vm._v("")]
                                                )
                                              : _vm._e(),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c("div", { staticClass: "pt-2" }, [
                                          _vm.card.settings &&
                                          _vm.card.settings.last_sync_user
                                            ? _c(
                                                "small",
                                                {
                                                  staticClass:
                                                    "p-0 m-0 block text-black-lt text-xs",
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                            Last user sync: "
                                                  ),
                                                  _c("b", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm._f(
                                                          "fixFullDateUTCRelative"
                                                        )(
                                                          _vm.card.settings
                                                            .last_sync_user
                                                        )
                                                      )
                                                    ),
                                                  ]),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "row form-group mt-3 align-items-center",
                              },
                              [
                                _c("div", { staticClass: "col" }, [
                                  _c("h6", { staticClass: "text-black" }, [
                                    _vm._v("Pull Lead Statuses"),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "small",
                                    {
                                      staticClass:
                                        "p-0 m-0 block text-black-lt",
                                    },
                                    [
                                      _vm._v(
                                        "\n                                    Pulls your HubSpot lead statuses to " +
                                          _vm._s(_vm.statics.name) +
                                          " as disposition status\n                                "
                                      ),
                                    ]
                                  ),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "col d-flex justify-content-end",
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-flex flex-column width-300 fixed",
                                      },
                                      [
                                        _c(
                                          "el-button",
                                          {
                                            staticClass: "pull-right",
                                            attrs: {
                                              disabled:
                                                _vm.loading_pull_lead_status,
                                              plain: "",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.pullLeadStatus(
                                                  _vm.cardname
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("span", [
                                              _vm._v("Pull Lead Statuses"),
                                            ]),
                                            _vm._v(" "),
                                            !_vm.loading_pull_lead_status
                                              ? _c("i", {
                                                  staticClass:
                                                    "fa fa-arrow-down",
                                                })
                                              : _vm._e(),
                                            _vm._v(" "),
                                            _vm.loading_pull_lead_status
                                              ? _c(
                                                  "i",
                                                  {
                                                    staticClass:
                                                      "material-icons loader pull-left",
                                                  },
                                                  [_vm._v("")]
                                                )
                                              : _vm._e(),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c("div", { staticClass: "pt-2" }, [
                                          _vm.card.settings &&
                                          _vm.card.settings
                                            .last_sync_disposition_status
                                            ? _c(
                                                "small",
                                                {
                                                  staticClass:
                                                    "p-0 m-0 block text-black-lt text-xs",
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                            Last disposition status sync: "
                                                  ),
                                                  _c("b", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm._f(
                                                          "fixFullDateUTCRelative"
                                                        )(
                                                          _vm.card.settings
                                                            .last_sync_disposition_status
                                                        )
                                                      )
                                                    ),
                                                  ]),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "row form-group mt-3 align-items-center",
                              },
                              [
                                _c("div", { staticClass: "col" }, [
                                  _c("h6", { staticClass: "text-black" }, [
                                    _vm._v("Pull Call Outcomes"),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "small",
                                    {
                                      staticClass:
                                        "p-0 m-0 block text-black-lt",
                                    },
                                    [
                                      _vm._v(
                                        "\n                                    Pulls your HubSpot call outcomes to " +
                                          _vm._s(_vm.statics.name) +
                                          " as call dispositions\n                                "
                                      ),
                                    ]
                                  ),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "col d-flex justify-content-end",
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-flex flex-column width-300 fixed",
                                      },
                                      [
                                        _c(
                                          "el-button",
                                          {
                                            staticClass: "pull-right",
                                            attrs: {
                                              disabled:
                                                _vm.loading_pull_call_dispositions,
                                              plain: "",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.pullCallDispositions(
                                                  _vm.cardname
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("span", [
                                              _vm._v("Pull Call Outcomes"),
                                            ]),
                                            _vm._v(" "),
                                            !_vm.loading_pull_call_dispositions
                                              ? _c("i", {
                                                  staticClass:
                                                    "fa fa-arrow-down",
                                                })
                                              : _vm._e(),
                                            _vm._v(" "),
                                            _vm.loading_pull_call_dispositions
                                              ? _c(
                                                  "i",
                                                  {
                                                    staticClass:
                                                      "material-icons loader pull-left",
                                                  },
                                                  [_vm._v("")]
                                                )
                                              : _vm._e(),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c("div", { staticClass: "pt-2" }, [
                                          _vm.card.settings &&
                                          _vm.card.settings
                                            .last_sync_call_disposition
                                            ? _c(
                                                "small",
                                                {
                                                  staticClass:
                                                    "p-0 m-0 block text-black-lt text-xs",
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                            Last call disposition sync: "
                                                  ),
                                                  _c("b", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm._f(
                                                          "fixFullDateUTCRelative"
                                                        )(
                                                          _vm.card.settings
                                                            .last_sync_call_disposition
                                                        )
                                                      )
                                                    ),
                                                  ]),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _vm.current_company.hubspot_integration_enabled
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "row form-group mt-3 align-items-center",
                                  },
                                  [
                                    _c("div", { staticClass: "col" }, [
                                      _c("h6", { staticClass: "text-black" }, [
                                        _vm._v("Pull Activity Types"),
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "small",
                                        {
                                          staticClass:
                                            "p-0 m-0 block text-black-lt",
                                        },
                                        [
                                          _vm._v(
                                            "\n                                    Pulls your HubSpot activity types to " +
                                              _vm._s(_vm.statics.name) +
                                              "\n                                "
                                          ),
                                        ]
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "col d-flex justify-content-end",
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "d-flex flex-column width-300 fixed",
                                          },
                                          [
                                            _c(
                                              "el-button",
                                              {
                                                staticClass: "pull-right",
                                                attrs: {
                                                  disabled:
                                                    _vm.loading_pull_activity_types,
                                                  plain: "",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.pullActivityTypes(
                                                      _vm.cardname
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c("span", [
                                                  _vm._v("Pull Activity Types"),
                                                ]),
                                                _vm._v(" "),
                                                !_vm.loading_pull_activity_types
                                                  ? _c("i", {
                                                      staticClass:
                                                        "fa fa-arrow-down",
                                                    })
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                _vm.loading_pull_activity_types
                                                  ? _c(
                                                      "i",
                                                      {
                                                        staticClass:
                                                          "material-icons loader pull-left",
                                                      },
                                                      [_vm._v("")]
                                                    )
                                                  : _vm._e(),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c("div", { staticClass: "pt-2" }, [
                                              _vm.card.settings &&
                                              _vm.card.settings
                                                .last_sync_call_activity_types
                                                ? _c(
                                                    "small",
                                                    {
                                                      staticClass:
                                                        "p-0 m-0 block text-black-lt text-xs",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                                            Last call activity types sync: "
                                                      ),
                                                      _c("b", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm._f(
                                                              "fixFullDateUTCRelative"
                                                            )(
                                                              _vm.card.settings
                                                                .last_sync_call_activity_types
                                                            )
                                                          )
                                                        ),
                                                      ]),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ]),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.current_company.hubspot_integration_enabled
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "row form-group mt-3 align-items-center",
                                  },
                                  [
                                    _c("div", { staticClass: "col" }, [
                                      _c("h6", { staticClass: "text-black" }, [
                                        _vm._v("Pull Deals"),
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "small",
                                        {
                                          staticClass:
                                            "p-0 m-0 block text-black-lt",
                                        },
                                        [
                                          _vm._v(
                                            "\n                                    Pulls your HubSpot deals to " +
                                              _vm._s(_vm.statics.name) +
                                              "\n                                "
                                          ),
                                        ]
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "col d-flex justify-content-end",
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "d-flex flex-column width-300 fixed",
                                          },
                                          [
                                            _c(
                                              "el-button",
                                              {
                                                staticClass: "pull-right",
                                                attrs: {
                                                  disabled:
                                                    _vm.loading_pull_deals,
                                                  plain: "",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.pullDeals(
                                                      _vm.cardname
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c("span", [
                                                  _vm._v("Pull Deals"),
                                                ]),
                                                _vm._v(" "),
                                                !_vm.loading_pull_deals
                                                  ? _c("i", {
                                                      staticClass:
                                                        "fa fa-arrow-down",
                                                    })
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                _vm.loading_pull_deals
                                                  ? _c(
                                                      "i",
                                                      {
                                                        staticClass:
                                                          "material-icons loader pull-left",
                                                      },
                                                      [_vm._v("")]
                                                    )
                                                  : _vm._e(),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c("div", { staticClass: "pt-2" }, [
                                              _vm.card.settings &&
                                              _vm.card.settings.last_sync_deals
                                                ? _c(
                                                    "small",
                                                    {
                                                      staticClass:
                                                        "p-0 m-0 block text-black-lt text-xs",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                                            Last call deals sync: "
                                                      ),
                                                      _c("b", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm._f(
                                                              "fixFullDateUTCRelative"
                                                            )(
                                                              _vm.card.settings
                                                                .last_sync_deals
                                                            )
                                                          )
                                                        ),
                                                      ]),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ]),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ]
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.showHubspotSendAsNoteOption ||
                      _vm.showHubspotSendCallEvents
                        ? _c("div", { staticClass: "mt-4" }, [
                            _c("h5", [_vm._v("Settings")]),
                            _vm._v(" "),
                            _c("hr", { staticClass: "no-top-margin" }),
                            _vm._v(" "),
                            _c("div", { staticClass: "row" }, [
                              _c(
                                "div",
                                { staticClass: "col" },
                                [
                                  _vm.showHubspotSendAsNoteOption
                                    ? _c(
                                        "el-form-item",
                                        { staticClass: "my-0 inline" },
                                        [
                                          _c("el-switch", {
                                            attrs: {
                                              "active-color": "#00a344",
                                            },
                                            model: {
                                              value:
                                                _vm.card.settings
                                                  .send_sms_as_note,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.card.settings,
                                                  "send_sms_as_note",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "card.settings.send_sms_as_note",
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm._v(
                                    "\n                                Log text messages as SMS activity\n                            "
                                  ),
                                ],
                                1
                              ),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "row form-group mt-3" }, [
                              _c(
                                "div",
                                { staticClass: "col" },
                                [
                                  _vm.showHubspotSendCallEvents
                                    ? _c(
                                        "el-form-item",
                                        { staticClass: "my-0 inline" },
                                        [
                                          _c("el-switch", {
                                            attrs: {
                                              "active-color": "#00a344",
                                            },
                                            model: {
                                              value:
                                                _vm.card.settings
                                                  .send_call_events,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.card.settings,
                                                  "send_call_events",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "card.settings.send_call_events",
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm._v(
                                    "\n                                Send call events (Beta)\n                            "
                                  ),
                                ],
                                1
                              ),
                            ]),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.card.credentials.access_token !== ""
                        ? [
                            _c(
                              "h5",
                              { staticClass: "my-2" },
                              [
                                _vm._v(
                                  "Contact Property Mapping\n                            "
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-tooltip",
                                  {
                                    staticClass: "item",
                                    attrs: {
                                      effect: "dark",
                                      placement: "right",
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        attrs: { slot: "content" },
                                        slot: "content",
                                      },
                                      [
                                        _c(
                                          "small",
                                          {
                                            staticClass:
                                              "font-weight-bold mb-1",
                                          },
                                          [
                                            _vm._v(
                                              "\n                                        Map HubSpot Contact Properties to Aloware's default and Custom Contact Fields.\n                                    "
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c("br"),
                                        _c("br"),
                                        _vm._v(" "),
                                        _c(
                                          "small",
                                          { staticClass: "font-weight-bold" },
                                          [_vm._v("Legends")]
                                        ),
                                        _c("br"),
                                        _vm._v(" "),
                                        _c("small", { staticClass: "mb-1" }, [
                                          _c("i", {
                                            staticClass: "fa fa-lock mr-2",
                                          }),
                                          _vm._v(
                                            "The contact property mapping is disabled for modifications"
                                          ),
                                          _c("br"),
                                        ]),
                                        _vm._v(" "),
                                        _c("small", { staticClass: "mb-1" }, [
                                          _c("i", {
                                            staticClass: "fa fa-ban mr-2",
                                          }),
                                          _vm._v(
                                            "The contact property is already selected and cannot be mapped multiple times"
                                          ),
                                          _c("br"),
                                        ]),
                                        _vm._v(" "),
                                        _c("small", { staticClass: "mb-1" }, [
                                          _c("i", {
                                            staticClass: "fa fa-trash mr-2",
                                          }),
                                          _vm._v(
                                            "Delete a contact property mapping"
                                          ),
                                          _c("br"),
                                        ]),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "i",
                                      {
                                        staticClass:
                                          "material-icons text-lg cursor-pointer text-blue p-0",
                                      },
                                      [_vm._v("info_outline")]
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _vm.card.settings.last_mapping_update &&
                            _vm.card.updated_by
                              ? _c(
                                  "small",
                                  {
                                    staticClass: "p-0 m-0 block text-black-lt",
                                  },
                                  [
                                    _vm._v(
                                      "\n                            Last edited by: " +
                                        _vm._s(
                                          _vm.getUser(_vm.card.updated_by).name
                                        ) +
                                        " " +
                                        _vm._s(
                                          _vm._f("fixFullDateUTCRelative")(
                                            _vm.card.settings
                                              .last_mapping_update
                                          )
                                        ) +
                                        "\n                        "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.card.credentials.access_token
                              ? _c("div", { staticClass: "row form-group" }, [
                                  _c(
                                    "div",
                                    { staticClass: "col-md-12" },
                                    [
                                      _c("hubspot-property-mapper", {
                                        ref: "hubspotPropertyMapper",
                                      }),
                                    ],
                                    1
                                  ),
                                ])
                              : _vm._e(),
                          ]
                        : _vm._e(),
                    ]
                  : _vm._e(),
                _vm._v(" "),
                _vm.cardname == "guesty"
                  ? [
                      _c("div", { staticClass: "row form-group" }, [
                        _c("div", { staticClass: "col d-flex" }, [
                          _c(
                            "div",
                            { staticClass: "mr-2" },
                            [
                              _vm.card.credentials.access_token !== ""
                                ? _c("integration-settings-tester-btn", {
                                    attrs: {
                                      name: _vm.cardname,
                                      card: _vm.card,
                                    },
                                    on: { reload: _vm.fetchDetails },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("div"),
                        ]),
                      ]),
                      _vm._v(" "),
                      !_vm.isStatusToggleDisabled
                        ? [
                            !_vm.isStatusToggleDisabled &&
                            _vm.card.credentials.access_token === ""
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "row form-group mt-3 align-items-center",
                                  },
                                  [
                                    !_vm.isStatusToggleDisabled
                                      ? _c("div", { staticClass: "col" }, [
                                          _c(
                                            "h6",
                                            { staticClass: "text-black" },
                                            [_vm._v("Integrations Token")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "small",
                                            {
                                              staticClass:
                                                "p-0 m-0 block text-black-lt",
                                            },
                                            [
                                              _vm._v(
                                                "\n                                    You can find your Integrations token from here:\n                                    "
                                              ),
                                              _c(
                                                "a",
                                                {
                                                  staticClass:
                                                    "text-bluish _600",
                                                  attrs: {
                                                    target: "_blank",
                                                    href: _vm.guestyTokenUrl,
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                        here\n                                    "
                                                  ),
                                                ]
                                              ),
                                              _vm._v(
                                                "\n                                    and integrate with " +
                                                  _vm._s(_vm.statics.name) +
                                                  ". Then copy your key and paste it here.\n                                "
                                              ),
                                            ]
                                          ),
                                        ])
                                      : _c(
                                          "div",
                                          { staticClass: "col" },
                                          [
                                            _c(
                                              "el-alert",
                                              {
                                                staticClass: "my-2",
                                                attrs: {
                                                  type: "info",
                                                  closable: false,
                                                  "show-icon": "",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                    You can only enable one CRM integration.\n                                "
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            !_vm.isStatusToggleDisabled &&
                            _vm.card.credentials.access_token === ""
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "row form-group align-items-center",
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "col-6" },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            staticClass: "m-0",
                                            attrs: {
                                              prop: "credentials.api_token",
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "input-group" },
                                              [
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        _vm.card.credentials
                                                          .api_token,
                                                      expression:
                                                        "card.credentials.api_token",
                                                    },
                                                  ],
                                                  staticClass: "form-control",
                                                  attrs: { type: "text" },
                                                  domProps: {
                                                    value:
                                                      _vm.card.credentials
                                                        .api_token,
                                                  },
                                                  on: {
                                                    input: [
                                                      function ($event) {
                                                        if (
                                                          $event.target
                                                            .composing
                                                        )
                                                          return
                                                        _vm.$set(
                                                          _vm.card.credentials,
                                                          "api_token",
                                                          $event.target.value
                                                        )
                                                      },
                                                      _vm.customPreValidateForm,
                                                    ],
                                                  },
                                                }),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ]
                        : _c(
                            "div",
                            {
                              staticClass: "row form-group align-items-center",
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "col-6" },
                                [
                                  _c(
                                    "el-alert",
                                    {
                                      staticClass: "my-2",
                                      attrs: {
                                        type: "info",
                                        closable: false,
                                        "show-icon": "",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                                You can only enable one CRM integration.\n                            "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                      _vm._v(" "),
                      _vm.card.credentials.access_token !== ""
                        ? [
                            _c("h5", { staticClass: "mt-4" }, [
                              _vm._v("Synchronization"),
                            ]),
                            _vm._v(" "),
                            _c("hr", { staticClass: "no-top-margin" }),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "row form-group align-items-center",
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "col" },
                                  [
                                    _c("h6", { staticClass: "text-black" }, [
                                      _vm._v("Sync Contacts"),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "small",
                                      {
                                        staticClass:
                                          "p-0 m-0 block text-black-lt",
                                      },
                                      [
                                        _c("span", { staticClass: "mb-1" }, [
                                          _vm._v(
                                            "\n                                        Use "
                                          ),
                                          _c("b", [_vm._v("push contacts")]),
                                          _vm._v(
                                            " to see your " +
                                              _vm._s(_vm.statics.name) +
                                              " contacts in Guesty\n                                    "
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _c("br"),
                                        _vm._v(" "),
                                        _c("span", { staticClass: "mb-1" }, [
                                          _vm._v(
                                            "\n                                        Use "
                                          ),
                                          _c("b", [_vm._v("pull contacts")]),
                                          _vm._v(
                                            " to see all your Guesty contacts within " +
                                              _vm._s(_vm.statics.name) +
                                              "\n                                    "
                                          ),
                                        ]),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-alert",
                                      {
                                        staticClass: "my-2",
                                        attrs: {
                                          type: "info",
                                          closable: false,
                                          "show-icon": "",
                                        },
                                      },
                                      [
                                        _c("b", [_vm._v("Note")]),
                                        _vm._v(
                                          ": Pulling contacts will update " +
                                            _vm._s(_vm.statics.name) +
                                            " contacts using CRM data\n                                "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-alert",
                                      {
                                        staticClass: "my-2",
                                        attrs: {
                                          type: "warning",
                                          closable: false,
                                          "show-icon": "",
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "d-block mb-1 text-black",
                                          },
                                          [
                                            _vm._v(
                                              "\n                                        Pulling contacts will cause LRN lookup charges to be applied\n                                    "
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "col d-flex justify-content-end",
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "d-flex flex-column mr-2",
                                      },
                                      [
                                        _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              disabled: _vm.loading_push,
                                              plain: "",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.pushContacts(
                                                  _vm.cardname
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("span", [
                                              _vm._v("Push Contacts"),
                                            ]),
                                            _vm._v(" "),
                                            !_vm.loading_push
                                              ? _c("i", {
                                                  staticClass: "fa fa-arrow-up",
                                                })
                                              : _vm._e(),
                                            _vm._v(" "),
                                            _vm.loading_push
                                              ? _c(
                                                  "i",
                                                  {
                                                    staticClass:
                                                      "material-icons loader pull-left",
                                                  },
                                                  [_vm._v("")]
                                                )
                                              : _vm._e(),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c("div", { staticClass: "pt-2" }, [
                                          _vm.card.settings &&
                                          _vm.card.settings
                                            .last_sync_push_contact
                                            ? _c(
                                                "small",
                                                {
                                                  staticClass:
                                                    "p-0 m-0 block text-black-lt text-xs",
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                            Last push contact: "
                                                  ),
                                                  _c("b", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm._f(
                                                          "fixFullDateUTCRelative"
                                                        )(
                                                          _vm.card.settings
                                                            .last_sync_push_contact
                                                        )
                                                      )
                                                    ),
                                                  ]),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "d-flex flex-column" },
                                      [
                                        _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              disabled: _vm.loading_pull,
                                              plain: "",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.pullContacts(
                                                  _vm.cardname
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("span", [
                                              _vm._v("Pull Contacts"),
                                            ]),
                                            _vm._v(" "),
                                            !_vm.loading_pull
                                              ? _c("i", {
                                                  staticClass:
                                                    "fa fa-arrow-down",
                                                })
                                              : _vm._e(),
                                            _vm._v(" "),
                                            _vm.loading_pull
                                              ? _c(
                                                  "i",
                                                  {
                                                    staticClass:
                                                      "material-icons loader pull-left",
                                                  },
                                                  [_vm._v("")]
                                                )
                                              : _vm._e(),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c("div", { staticClass: "pt-2" }, [
                                          _vm.card.settings &&
                                          _vm.card.settings
                                            .last_sync_pull_contact
                                            ? _c(
                                                "small",
                                                {
                                                  staticClass:
                                                    "p-0 m-0 block text-black-lt text-xs",
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                            Last pull contact: "
                                                  ),
                                                  _c("b", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm._f(
                                                          "fixFullDateUTCRelative"
                                                        )(
                                                          _vm.card.settings
                                                            .last_sync_pull_contact
                                                        )
                                                      )
                                                    ),
                                                  ]),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        : _vm._e(),
                    ]
                  : _vm._e(),
                _vm._v(" "),
                _vm.cardname == "zoho"
                  ? [
                      _c("div", { staticClass: "row form-group" }, [
                        _c("div", { staticClass: "col d-flex" }, [
                          _c(
                            "div",
                            { staticClass: "mr-2" },
                            [
                              _vm.card.credentials.access_token !== ""
                                ? _c("integration-settings-tester-btn", {
                                    attrs: {
                                      name: _vm.cardname,
                                      card: _vm.card,
                                    },
                                    on: { reload: _vm.fetchDetails },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            [
                              _c(
                                "el-tooltip",
                                {
                                  staticClass: "item",
                                  attrs: { effect: "dark", placement: "right" },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      attrs: { slot: "content" },
                                      slot: "content",
                                    },
                                    [
                                      _c("strong", [_vm._v("Authentication")]),
                                      _vm._v(" "),
                                      _c("br"),
                                      _vm._v(" "),
                                      _c("small", [
                                        _vm._v(
                                          "\n                                            This will redirect you to Zoho for authentication.\n                                        "
                                        ),
                                      ]),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _vm.card.credentials.access_token !== ""
                                    ? _c(
                                        "el-button",
                                        {
                                          attrs: { plain: "" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.oauth(_vm.cardname)
                                            },
                                          },
                                        },
                                        [
                                          _c("span", [
                                            _vm._v("Re-Authenticate"),
                                          ]),
                                          _vm._v(" "),
                                          _c("i", {
                                            staticClass: "fa fa-sign-in mr-2",
                                          }),
                                        ]
                                      )
                                    : !_vm.isStatusToggleDisabled
                                    ? _c(
                                        "el-button",
                                        {
                                          attrs: { plain: "" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.oauth(_vm.cardname)
                                            },
                                          },
                                        },
                                        [
                                          _c("span", [_vm._v("Authenticate")]),
                                          _vm._v(" "),
                                          _c("i", {
                                            staticClass: "fa fa-sign-in mr-2",
                                          }),
                                        ]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _vm.isStatusToggleDisabled
                                ? _c(
                                    "el-alert",
                                    {
                                      staticClass: "my-2",
                                      attrs: {
                                        type: "info",
                                        closable: false,
                                        "show-icon": "",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                                    You can only enable one CRM integration.\n                                "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _vm.card.credentials.access_token !== ""
                        ? [
                            _c("h5", { staticClass: "mt-4" }, [
                              _vm._v("Synchronization"),
                            ]),
                            _vm._v(" "),
                            _c("hr", { staticClass: "no-top-margin" }),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "row form-group align-items-center",
                              },
                              [
                                _c("div", { staticClass: "col" }, [
                                  _c("h6", { staticClass: "text-black" }, [
                                    _vm._v("Sync Users"),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "small",
                                    {
                                      staticClass:
                                        "p-0 m-0 block text-black-lt",
                                    },
                                    [
                                      _vm._v(
                                        "\n                                    Connect your Zoho users to " +
                                          _vm._s(_vm.statics.name) +
                                          " users.\n                                "
                                      ),
                                    ]
                                  ),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "col d-flex justify-content-end",
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "d-flex flex-column" },
                                      [
                                        _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              disabled: _vm.loading_pull_users,
                                              plain: "",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.pullUsers(
                                                  _vm.cardname
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("span", [
                                              _vm._v(
                                                "Connect Zoho users to " +
                                                  _vm._s(_vm.statics.name)
                                              ),
                                            ]),
                                            _vm._v(" "),
                                            _vm.loading_pull_users
                                              ? _c(
                                                  "i",
                                                  {
                                                    staticClass:
                                                      "material-icons loader pull-left",
                                                  },
                                                  [_vm._v("")]
                                                )
                                              : _vm._e(),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c("div", { staticClass: "pt-2" }, [
                                          _vm.card.settings &&
                                          _vm.card.settings.last_sync_user
                                            ? _c(
                                                "small",
                                                {
                                                  staticClass:
                                                    "p-0 m-0 block text-black-lt text-xs",
                                                },
                                                [
                                                  _c("small", [
                                                    _vm._v("Last user sync: "),
                                                    _c("b", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm._f(
                                                            "fixFullDateUTCRelative"
                                                          )(
                                                            _vm.card.settings
                                                              .last_sync_user
                                                          )
                                                        )
                                                      ),
                                                    ]),
                                                  ]),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "row form-group mt-3 align-items-center",
                              },
                              [
                                _c("div", { staticClass: "col" }, [
                                  _c("h6", { staticClass: "text-black" }, [
                                    _vm._v("Pull Lead Statuses"),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "small",
                                    {
                                      staticClass:
                                        "p-0 m-0 block text-black-lt",
                                    },
                                    [
                                      _vm._v(
                                        "\n                                    Pulls your Zoho lead statuses to " +
                                          _vm._s(_vm.statics.name) +
                                          " as disposition status\n                                "
                                      ),
                                    ]
                                  ),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "col d-flex justify-content-end",
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-flex flex-column width-300 fixed",
                                      },
                                      [
                                        _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              disabled:
                                                _vm.loading_pull_lead_status,
                                              plain: "",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.pullLeadStatus(
                                                  _vm.cardname
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("span", [
                                              _vm._v("Pull Lead Statuses"),
                                            ]),
                                            _vm._v(" "),
                                            _vm.loading_pull_lead_status
                                              ? _c(
                                                  "i",
                                                  {
                                                    staticClass:
                                                      "material-icons loader pull-left",
                                                  },
                                                  [_vm._v("")]
                                                )
                                              : _vm._e(),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c("div", { staticClass: "pt-2" }, [
                                          _vm.card.settings &&
                                          _vm.card.settings
                                            .last_sync_disposition_status
                                            ? _c(
                                                "small",
                                                {
                                                  staticClass:
                                                    "p-0 m-0 block text-black-lt text-xs",
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                            Last disposition status sync: "
                                                  ),
                                                  _c("b", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm._f(
                                                          "fixFullDateUTCRelative"
                                                        )(
                                                          _vm.card.settings
                                                            .last_sync_disposition_status
                                                        )
                                                      )
                                                    ),
                                                  ]),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "row form-group mt-3 align-items-center",
                              },
                              [
                                _c("div", { staticClass: "col" }, [
                                  _c("h6", { staticClass: "text-black" }, [
                                    _vm._v("Default Zoho CRM Module"),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "small",
                                    {
                                      staticClass:
                                        "p-0 m-0 block text-black-lt",
                                    },
                                    [
                                      _vm._v(
                                        "\n                                    " +
                                          _vm._s(_vm.statics.name) +
                                          " will integrate with the module that you select here.\n                                    All new contacts created in " +
                                          _vm._s(_vm.statics.name) +
                                          " will be added to this module type in Zoho.\n                                "
                                      ),
                                    ]
                                  ),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "col" },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        staticClass: "m-0",
                                        attrs: { prop: "settings.module" },
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "input-group" },
                                          [
                                            _c(
                                              "el-select",
                                              {
                                                staticClass: "w-100",
                                                attrs: {
                                                  placeholder: "Select Module",
                                                },
                                                on: {
                                                  change: function ($event) {
                                                    return _vm.submit(
                                                      _vm.cardname,
                                                      false
                                                    )
                                                  },
                                                },
                                                model: {
                                                  value:
                                                    _vm.card.settings.module,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.card.settings,
                                                      "module",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "card.settings.module",
                                                },
                                              },
                                              _vm._l(
                                                _vm.zohoModuleOptions,
                                                function (item) {
                                                  return _c("el-option", {
                                                    key: item.value,
                                                    attrs: {
                                                      label: item.label,
                                                      value: item.value,
                                                    },
                                                  })
                                                }
                                              ),
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "row form-group mt-3 align-items-center",
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "col" },
                                  [
                                    _c("h6", { staticClass: "text-black" }, [
                                      _vm._v("Sync Contacts"),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "small",
                                      {
                                        staticClass:
                                          "p-0 m-0 block text-black-lt",
                                      },
                                      [
                                        _c("span", { staticClass: "mb-1" }, [
                                          _vm._v(
                                            "\n                                        Use "
                                          ),
                                          _c("b", [_vm._v("push contacts")]),
                                          _vm._v(
                                            " to see your " +
                                              _vm._s(_vm.statics.name) +
                                              " contacts in Zoho\n                                    "
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _c("br"),
                                        _vm._v(" "),
                                        _c("span", { staticClass: "mb-1" }, [
                                          _vm._v(
                                            "\n                                        Use "
                                          ),
                                          _c("b", [_vm._v("pull contacts")]),
                                          _vm._v(
                                            " to see all your Zoho contacts within " +
                                              _vm._s(_vm.statics.name) +
                                              "\n                                    "
                                          ),
                                        ]),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-alert",
                                      {
                                        staticClass: "my-2",
                                        attrs: {
                                          type: "info",
                                          closable: false,
                                          "show-icon": "",
                                        },
                                      },
                                      [
                                        _c("b", [_vm._v("Note")]),
                                        _vm._v(
                                          ": Pulling contacts will update " +
                                            _vm._s(_vm.statics.name) +
                                            " contacts using CRM data\n                                "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-alert",
                                      {
                                        staticClass: "my-2",
                                        attrs: {
                                          type: "warning",
                                          closable: false,
                                          "show-icon": "",
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "d-block mb-1 text-black",
                                          },
                                          [
                                            _vm._v(
                                              "\n                                        Pulling contacts will cause LRN lookup charges to be applied\n                                    "
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "col d-flex justify-content-end",
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "d-flex flex-column mr-2",
                                      },
                                      [
                                        _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              disabled: _vm.loading_push,
                                              plain: "",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.pushContacts(
                                                  _vm.cardname
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("span", [
                                              _vm._v("Push Contacts"),
                                            ]),
                                            _vm._v(" "),
                                            !_vm.loading_push
                                              ? _c("i", {
                                                  staticClass: "fa fa-arrow-up",
                                                })
                                              : _vm._e(),
                                            _vm._v(" "),
                                            _vm.loading_push
                                              ? _c(
                                                  "i",
                                                  {
                                                    staticClass:
                                                      "material-icons loader pull-left",
                                                  },
                                                  [_vm._v("")]
                                                )
                                              : _vm._e(),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c("div", { staticClass: "pt-2" }, [
                                          _vm.card.settings &&
                                          _vm.card.settings
                                            .last_sync_push_contact
                                            ? _c(
                                                "small",
                                                {
                                                  staticClass:
                                                    "p-0 m-0 block text-black-lt text-xs",
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                            Last push contact: "
                                                  ),
                                                  _c("b", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm._f(
                                                          "fixFullDateUTCRelative"
                                                        )(
                                                          _vm.card.settings
                                                            .last_sync_push_contact
                                                        )
                                                      )
                                                    ),
                                                  ]),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "d-flex flex-column" },
                                      [
                                        _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              disabled: _vm.loading_pull,
                                              plain: "",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.pullContacts(
                                                  _vm.cardname
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("span", [
                                              _vm._v("Pull Contacts"),
                                            ]),
                                            _vm._v(" "),
                                            !_vm.loading_pull
                                              ? _c("i", {
                                                  staticClass:
                                                    "fa fa-arrow-down",
                                                })
                                              : _vm._e(),
                                            _vm._v(" "),
                                            _vm.loading_pull
                                              ? _c(
                                                  "i",
                                                  {
                                                    staticClass:
                                                      "material-icons loader pull-left",
                                                  },
                                                  [_vm._v("")]
                                                )
                                              : _vm._e(),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c("div", { staticClass: "pt-2" }, [
                                          _vm.card.settings &&
                                          _vm.card.settings
                                            .last_sync_pull_contact
                                            ? _c(
                                                "small",
                                                {
                                                  staticClass:
                                                    "p-0 m-0 block text-black-lt text-xs",
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                            Last pull contact: "
                                                  ),
                                                  _c("b", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm._f(
                                                          "fixFullDateUTCRelative"
                                                        )(
                                                          _vm.card.settings
                                                            .last_sync_pull_contact
                                                        )
                                                      )
                                                    ),
                                                  ]),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        : _vm._e(),
                    ]
                  : _vm._e(),
                _vm._v(" "),
                _vm.cardname == "pipedrive"
                  ? [
                      _c("div", { staticClass: "row form-group" }, [
                        _c("div", { staticClass: "col d-flex" }, [
                          _c(
                            "div",
                            { staticClass: "mr-2" },
                            [
                              _vm.card.credentials.access_token !== ""
                                ? _c("integration-settings-tester-btn", {
                                    attrs: {
                                      name: _vm.cardname,
                                      card: _vm.card,
                                    },
                                    on: { reload: _vm.fetchDetails },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            [
                              _c(
                                "el-tooltip",
                                {
                                  staticClass: "item",
                                  attrs: { effect: "dark", placement: "right" },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      attrs: { slot: "content" },
                                      slot: "content",
                                    },
                                    [
                                      _c("strong", [_vm._v("Authentication")]),
                                      _vm._v(" "),
                                      _c("br"),
                                      _vm._v(" "),
                                      _c("small", [
                                        _vm._v(
                                          "\n                                            This will redirect you to Pipedrive for authentication.\n                                        "
                                        ),
                                      ]),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _vm.card.credentials.access_token !== ""
                                    ? _c(
                                        "el-button",
                                        {
                                          attrs: { plain: "" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.oauth(_vm.cardname)
                                            },
                                          },
                                        },
                                        [
                                          _c("span", [
                                            _vm._v("Re-Authenticate"),
                                          ]),
                                          _vm._v(" "),
                                          _c("i", {
                                            staticClass: "fa fa-sign-in mr-2",
                                          }),
                                        ]
                                      )
                                    : !_vm.isStatusToggleDisabled
                                    ? _c(
                                        "el-button",
                                        {
                                          attrs: { plain: "" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.oauth(_vm.cardname)
                                            },
                                          },
                                        },
                                        [
                                          _c("span", [_vm._v("Authenticate")]),
                                          _vm._v(" "),
                                          _c("i", {
                                            staticClass: "fa fa-sign-in mr-2",
                                          }),
                                        ]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _vm.isStatusToggleDisabled
                                ? _c(
                                    "el-alert",
                                    {
                                      staticClass: "my-2",
                                      attrs: {
                                        type: "info",
                                        closable: false,
                                        "show-icon": "",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                                    You can only enable one CRM integration.\n                                "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _vm.card.credentials.access_token !== ""
                        ? [
                            _c("h5", { staticClass: "mt-4" }, [
                              _vm._v("Synchronization"),
                            ]),
                            _vm._v(" "),
                            _c("hr", { staticClass: "no-top-margin" }),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "row form-group align-items-center",
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "col" },
                                  [
                                    _c("h6", { staticClass: "text-black" }, [
                                      _vm._v("Sync Contacts"),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "small",
                                      {
                                        staticClass:
                                          "p-0 m-0 block text-black-lt",
                                      },
                                      [
                                        _c("span", { staticClass: "mb-1" }, [
                                          _vm._v(
                                            "\n                                        Use "
                                          ),
                                          _c("b", [_vm._v("push contacts")]),
                                          _vm._v(
                                            " to see your " +
                                              _vm._s(_vm.statics.name) +
                                              " contacts in Pipedrive\n                                    "
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _c("br"),
                                        _vm._v(" "),
                                        _c("span", { staticClass: "mb-1" }, [
                                          _vm._v(
                                            "\n                                        Use "
                                          ),
                                          _c("b", [_vm._v("pull contacts")]),
                                          _vm._v(
                                            " to see all your Pipedrive contacts within " +
                                              _vm._s(_vm.statics.name) +
                                              "\n                                    "
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _c("br"),
                                        _vm._v(" "),
                                        _c("span", { staticClass: "mb-1" }, [
                                          _vm._v(
                                            "\n                                        Pull operation will update " +
                                              _vm._s(_vm.statics.name) +
                                              " for lead owners\n                                    "
                                          ),
                                        ]),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-alert",
                                      {
                                        staticClass: "my-2",
                                        attrs: {
                                          type: "info",
                                          closable: false,
                                          "show-icon": "",
                                        },
                                      },
                                      [
                                        _c("b", [_vm._v("Note")]),
                                        _vm._v(
                                          ": Pulling contacts will update " +
                                            _vm._s(_vm.statics.name) +
                                            " contacts using CRM data\n                                "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-alert",
                                      {
                                        staticClass: "my-2",
                                        attrs: {
                                          type: "warning",
                                          closable: false,
                                          "show-icon": "",
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "d-block mb-1 text-black",
                                          },
                                          [
                                            _vm._v(
                                              "\n                                        Pulling contacts will cause LRN lookup charges to be applied\n                                    "
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "col d-flex justify-content-end",
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "d-flex flex-column mr-2",
                                      },
                                      [
                                        _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              disabled: _vm.loading_push,
                                              plain: "",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.pushContacts(
                                                  _vm.cardname
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("span", [
                                              _vm._v("Push Contacts"),
                                            ]),
                                            _vm._v(" "),
                                            !_vm.loading_push
                                              ? _c("i", {
                                                  staticClass: "fa fa-arrow-up",
                                                })
                                              : _vm._e(),
                                            _vm._v(" "),
                                            _vm.loading_push
                                              ? _c(
                                                  "i",
                                                  {
                                                    staticClass:
                                                      "material-icons loader pull-left",
                                                  },
                                                  [_vm._v("")]
                                                )
                                              : _vm._e(),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c("div", { staticClass: "pt-2" }, [
                                          _vm.card.settings &&
                                          _vm.card.settings
                                            .last_sync_push_contact
                                            ? _c(
                                                "small",
                                                {
                                                  staticClass:
                                                    "p-0 m-0 block text-black-lt text-xs",
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                            Last push contact: "
                                                  ),
                                                  _c("b", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm._f(
                                                          "fixFullDateUTCRelative"
                                                        )(
                                                          _vm.card.settings
                                                            .last_sync_push_contact
                                                        )
                                                      )
                                                    ),
                                                  ]),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "d-flex flex-column" },
                                      [
                                        _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              disabled: _vm.loading_pull,
                                              plain: "",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.pullContacts(
                                                  _vm.cardname
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("span", [
                                              _vm._v("Pull Contacts"),
                                            ]),
                                            _vm._v(" "),
                                            !_vm.loading_pull
                                              ? _c("i", {
                                                  staticClass:
                                                    "fa fa-arrow-down",
                                                })
                                              : _vm._e(),
                                            _vm._v(" "),
                                            _vm.loading_pull
                                              ? _c(
                                                  "i",
                                                  {
                                                    staticClass:
                                                      "material-icons loader pull-left",
                                                  },
                                                  [_vm._v("")]
                                                )
                                              : _vm._e(),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c("div", { staticClass: "pt-2" }, [
                                          _vm.card.settings &&
                                          _vm.card.settings
                                            .last_sync_pull_contact
                                            ? _c(
                                                "small",
                                                {
                                                  staticClass:
                                                    "p-0 m-0 block text-black-lt text-xs",
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                            Last pull contact: "
                                                  ),
                                                  _c("b", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm._f(
                                                          "fixFullDateUTCRelative"
                                                        )(
                                                          _vm.card.settings
                                                            .last_sync_pull_contact
                                                        )
                                                      )
                                                    ),
                                                  ]),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "row form-group mt-3 align-items-center mt-3",
                              },
                              [
                                _c("div", { staticClass: "col" }, [
                                  _c("h6", { staticClass: "text-black" }, [
                                    _vm._v("Sync Users"),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "small",
                                    {
                                      staticClass:
                                        "p-0 m-0 block text-black-lt",
                                    },
                                    [
                                      _vm._v(
                                        "\n                                    Connect your Pipedrive users to " +
                                          _vm._s(_vm.statics.name) +
                                          " users.\n                                "
                                      ),
                                    ]
                                  ),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "col d-flex justify-content-end",
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "d-flex flex-column" },
                                      [
                                        _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              disabled: _vm.loading_pull_users,
                                              plain: "",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.pullUsers(
                                                  _vm.cardname
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("span", [
                                              _vm._v(
                                                "Connect Pipedrive users to " +
                                                  _vm._s(_vm.statics.name)
                                              ),
                                            ]),
                                            _vm._v(" "),
                                            _vm.loading_pull_users
                                              ? _c(
                                                  "i",
                                                  {
                                                    staticClass:
                                                      "material-icons loader pull-left",
                                                  },
                                                  [_vm._v("")]
                                                )
                                              : _vm._e(),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c("div", { staticClass: "pt-2" }, [
                                          _vm.card.settings &&
                                          _vm.card.settings.last_sync_user
                                            ? _c(
                                                "small",
                                                {
                                                  staticClass:
                                                    "p-0 m-0 block text-black-lt text-xs",
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                            Last user sync: "
                                                  ),
                                                  _c("b", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm._f(
                                                          "fixFullDateUTCRelative"
                                                        )(
                                                          _vm.card.settings
                                                            .last_sync_user
                                                        )
                                                      )
                                                    ),
                                                  ]),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "row form-group mt-3 align-items-center",
                              },
                              [
                                _c("div", { staticClass: "col" }, [
                                  _c("h6", { staticClass: "text-black" }, [
                                    _vm._v("Pull Deal Stages"),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "small",
                                    {
                                      staticClass:
                                        "p-0 m-0 block text-black-lt",
                                    },
                                    [
                                      _vm._v(
                                        "\n                                    Pulls your Pipedrive deal stages to " +
                                          _vm._s(_vm.statics.name) +
                                          " as disposition status\n                                "
                                      ),
                                    ]
                                  ),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "col d-flex justify-content-end",
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-flex flex-column width-300 fixed",
                                      },
                                      [
                                        _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              disabled:
                                                _vm.loading_pull_lead_status,
                                              plain: "",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.pullLeadStatus(
                                                  _vm.cardname
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("span", [
                                              _vm._v(
                                                "Pull deal stages from Pipedrive"
                                              ),
                                            ]),
                                            _vm._v(" "),
                                            _vm.loading_pull_lead_status
                                              ? _c(
                                                  "i",
                                                  {
                                                    staticClass:
                                                      "material-icons loader pull-left",
                                                  },
                                                  [_vm._v("")]
                                                )
                                              : _vm._e(),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c("div", { staticClass: "pt-2" }, [
                                          _vm.card.settings &&
                                          _vm.card.settings
                                            .last_sync_disposition_status
                                            ? _c(
                                                "small",
                                                {
                                                  staticClass:
                                                    "p-0 m-0 block text-black-lt text-xs",
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                            Last disposition status sync: "
                                                  ),
                                                  _c("b", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm._f(
                                                          "fixFullDateUTCRelative"
                                                        )(
                                                          _vm.card.settings
                                                            .last_sync_disposition_status
                                                        )
                                                      )
                                                    ),
                                                  ]),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        : _vm._e(),
                    ]
                  : _vm._e(),
                _vm._v(" "),
                _vm.cardname == "gohighlevel"
                  ? [
                      _c("div", { staticClass: "row form-group" }, [
                        _c("div", { staticClass: "col d-flex" }, [
                          _c(
                            "div",
                            { staticClass: "mr-2" },
                            [
                              _vm.card.credentials.access_token !== ""
                                ? _c("integration-settings-tester-btn", {
                                    attrs: {
                                      name: _vm.cardname,
                                      card: _vm.card,
                                    },
                                    on: { reload: _vm.fetchDetails },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            [
                              _c(
                                "el-tooltip",
                                {
                                  staticClass: "item",
                                  attrs: { effect: "dark", placement: "right" },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      attrs: { slot: "content" },
                                      slot: "content",
                                    },
                                    [
                                      _c("strong", [_vm._v("Authentication")]),
                                      _vm._v(" "),
                                      _c("br"),
                                      _vm._v(" "),
                                      _c("small", [
                                        _vm._v(
                                          "\n                                            This will redirect you to GoHighLevel for authentication. After authentication the GHL contacts will be synced with your Aloware contacts\n                                        "
                                        ),
                                      ]),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _vm.card.credentials.access_token !== ""
                                    ? _c(
                                        "el-button",
                                        {
                                          attrs: { plain: "" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.oauth(_vm.cardname)
                                            },
                                          },
                                        },
                                        [
                                          _c("span", [
                                            _vm._v("Re-Authenticate"),
                                          ]),
                                          _vm._v(" "),
                                          _c("i", {
                                            staticClass: "fa fa-sign-in mr-2",
                                          }),
                                        ]
                                      )
                                    : !_vm.isStatusToggleDisabled
                                    ? _c(
                                        "el-button",
                                        {
                                          attrs: { plain: "" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.oauth(_vm.cardname)
                                            },
                                          },
                                        },
                                        [
                                          _c("span", [_vm._v("Authenticate")]),
                                          _vm._v(" "),
                                          _c("i", {
                                            staticClass: "fa fa-sign-in mr-2",
                                          }),
                                        ]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _vm.isStatusToggleDisabled
                                ? _c(
                                    "el-alert",
                                    {
                                      staticClass: "my-2",
                                      attrs: {
                                        type: "info",
                                        closable: false,
                                        "show-icon": "",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                                    You can only enable one CRM integration.\n                                "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _vm.card.credentials.access_token !== ""
                        ? [
                            _c("h5", { staticClass: "mt-4" }, [
                              _vm._v("Synchronization"),
                            ]),
                            _vm._v(" "),
                            _c("hr", { staticClass: "no-top-margin" }),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "row form-group align-items-center",
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "col" },
                                  [
                                    _c("h6", { staticClass: "text-black" }, [
                                      _vm._v("Sync Contacts"),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "small",
                                      {
                                        staticClass:
                                          "p-0 m-0 block text-black-lt",
                                      },
                                      [
                                        _c("span", { staticClass: "mb-1" }, [
                                          _vm._v(
                                            "\n                                        Use "
                                          ),
                                          _c("b", [_vm._v("push contacts")]),
                                          _vm._v(
                                            " to see your " +
                                              _vm._s(_vm.statics.name) +
                                              " contacts in GoHighLevel\n                                    "
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _c("br"),
                                        _vm._v(" "),
                                        _c("span", { staticClass: "mb-1" }, [
                                          _vm._v(
                                            "\n                                        Use "
                                          ),
                                          _c("b", [_vm._v("pull contacts")]),
                                          _vm._v(
                                            " to see all your GoHighLevel contacts within " +
                                              _vm._s(_vm.statics.name) +
                                              "\n                                    "
                                          ),
                                        ]),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-alert",
                                      {
                                        staticClass: "my-2",
                                        attrs: {
                                          type: "info",
                                          closable: false,
                                          "show-icon": "",
                                        },
                                      },
                                      [
                                        _c("b", [_vm._v("Note")]),
                                        _vm._v(
                                          ": Pulling contacts will update " +
                                            _vm._s(_vm.statics.name) +
                                            " contacts using CRM data\n                                "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-alert",
                                      {
                                        staticClass: "my-2",
                                        attrs: {
                                          type: "warning",
                                          closable: false,
                                          "show-icon": "",
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "d-block mb-1 text-black",
                                          },
                                          [
                                            _vm._v(
                                              "\n                                        Pulling contacts will cause LRN lookup charges to be applied\n                                    "
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "col d-flex justify-content-end",
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "d-flex flex-column mr-2",
                                      },
                                      [
                                        _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              plain: "",
                                              disabled: _vm.loading_push,
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.pushContacts(
                                                  _vm.cardname
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("span", [
                                              _vm._v("Push Contacts"),
                                            ]),
                                            _vm._v(" "),
                                            !_vm.loading_push
                                              ? _c("i", {
                                                  staticClass: "fa fa-arrow-up",
                                                })
                                              : _vm._e(),
                                            _vm._v(" "),
                                            _vm.loading_push
                                              ? _c(
                                                  "i",
                                                  {
                                                    staticClass:
                                                      "material-icons loader pull-left",
                                                  },
                                                  [_vm._v("")]
                                                )
                                              : _vm._e(),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c("div", { staticClass: "pt-2" }, [
                                          _vm.card.settings &&
                                          _vm.card.settings
                                            .last_sync_push_contact
                                            ? _c(
                                                "small",
                                                {
                                                  staticClass:
                                                    "p-0 m-0 block text-black-lt text-xs",
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                            Last push contact: "
                                                  ),
                                                  _c("b", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm._f(
                                                          "fixFullDateUTCRelative"
                                                        )(
                                                          _vm.card.settings
                                                            .last_sync_push_contact
                                                        )
                                                      )
                                                    ),
                                                  ]),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "d-flex flex-column" },
                                      [
                                        _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              disabled: _vm.loading_pull,
                                              plain: "",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.pullContacts(
                                                  _vm.cardname
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("span", [
                                              _vm._v("Pull Contacts"),
                                            ]),
                                            _vm._v(" "),
                                            !_vm.loading_pull
                                              ? _c("i", {
                                                  staticClass:
                                                    "fa fa-arrow-down",
                                                })
                                              : _vm._e(),
                                            _vm._v(" "),
                                            _vm.loading_pull
                                              ? _c(
                                                  "i",
                                                  {
                                                    staticClass:
                                                      "material-icons loader pull-left",
                                                  },
                                                  [_vm._v("")]
                                                )
                                              : _vm._e(),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c("div", { staticClass: "pt-2" }, [
                                          _vm.card.settings &&
                                          _vm.card.settings.last_sync_contact
                                            ? _c(
                                                "small",
                                                {
                                                  staticClass:
                                                    "p-0 m-0 block text-black-lt text-xs",
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                            Last pull contact: "
                                                  ),
                                                  _c("b", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm._f(
                                                          "fixFullDateUTCRelative"
                                                        )(
                                                          _vm.card.settings
                                                            .last_sync_contact
                                                        )
                                                      )
                                                    ),
                                                  ]),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "row form-group mt-3 align-items-center",
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "col" },
                                  [
                                    _c("h6", { staticClass: "text-black" }, [
                                      _vm._v("Sync Users"),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "small",
                                      {
                                        staticClass:
                                          "p-0 m-0 block text-black-lt",
                                      },
                                      [
                                        _vm._v(
                                          "\n                                    Connect your GoHighLevel users to " +
                                            _vm._s(_vm.statics.name) +
                                            " users.\n                                    "
                                        ),
                                        _c("br"),
                                        _vm._v(
                                          "\n                                    Only current users in " +
                                            _vm._s(_vm.statics.name) +
                                            " are synced, and no new users will be created.\n                                "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-alert",
                                      {
                                        staticClass: "my-2",
                                        attrs: {
                                          type: "info",
                                          "show-icon": "",
                                          closable: false,
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                                    Heads up! Users are synced by email, please make sure your agents have the same email address on " +
                                            _vm._s(_vm.statics.name) +
                                            " and GoHighLevel.\n                                "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "col d-flex justify-content-end",
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "d-flex flex-column" },
                                      [
                                        _c(
                                          "el-tooltip",
                                          {
                                            staticClass: "item",
                                            attrs: {
                                              effect: "dark",
                                              placement: "top",
                                              disabled:
                                                !!_vm.card.credentials
                                                  .company_id,
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                attrs: { slot: "content" },
                                                slot: "content",
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                            You need to re-authenticate the integration before you can start using this feature.\n                                        "
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "el-button",
                                              {
                                                staticClass: "pull-right",
                                                attrs: {
                                                  plain: "",
                                                  disabled:
                                                    _vm.loading_pull_users ||
                                                    !_vm.card.credentials
                                                      .company_id,
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.pullUsers(
                                                      _vm.cardname
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c("span", [
                                                  _vm._v("Sync Users"),
                                                ]),
                                                _vm._v(" "),
                                                _vm.loading_pull_users
                                                  ? _c(
                                                      "i",
                                                      {
                                                        staticClass:
                                                          "material-icons loader pull-left",
                                                      },
                                                      [_vm._v("")]
                                                    )
                                                  : _vm._e(),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c("div", { staticClass: "pt-2" }, [
                                          _vm.card.settings &&
                                          _vm.card.settings.last_sync_user
                                            ? _c(
                                                "small",
                                                {
                                                  staticClass:
                                                    "p-0 m-0 block text-black-lt text-xs",
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                            Last user sync: "
                                                  ),
                                                  _c("b", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm._f(
                                                          "fixFullDateUTCRelative"
                                                        )(
                                                          _vm.card.settings
                                                            .last_sync_user
                                                        )
                                                      )
                                                    ),
                                                  ]),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("h6", { staticClass: "mt-4 text-black" }, [
                              _vm._v("Aloware Line to send from "),
                              _c("i", { staticClass: "text-danger" }, [
                                _vm._v("*"),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c(
                              "el-tooltip",
                              {
                                staticClass: "item",
                                attrs: {
                                  effect: "dark",
                                  placement: "top",
                                  disabled:
                                    !!_vm.card.credentials.scopes &&
                                    !!_vm.card.credentials.scopes.includes(
                                      "conversations/message.write"
                                    ),
                                },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    attrs: { slot: "content" },
                                    slot: "content",
                                  },
                                  [
                                    _vm._v(
                                      "\n                                You need to re-authenticate the integration before you can start using this feature.\n                            "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-select",
                                  {
                                    staticClass: "text-black",
                                    attrs: {
                                      placeholder: "Select Line",
                                      filterable: "",
                                      disabled:
                                        !_vm.card.credentials.scopes ||
                                        !_vm.card.credentials.scopes.includes(
                                          "conversations/message.write"
                                        ),
                                    },
                                    model: {
                                      value:
                                        _vm.card.settings.filters.sms_campaign,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.card.settings.filters,
                                          "sms_campaign",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "card.settings.filters.sms_campaign",
                                    },
                                  },
                                  _vm._l(_vm.listSmsCampaigns, function (item) {
                                    return _c("el-option", {
                                      key: item.id,
                                      attrs: {
                                        label: item.name,
                                        value: item.id,
                                      },
                                    })
                                  }),
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        : _vm._e(),
                    ]
                  : _vm._e(),
                _vm._v(" "),
                _vm.cardname == "salesforce"
                  ? [
                      _c("div", { staticClass: "row form-group" }, [
                        _c("div", { staticClass: "col d-flex" }, [
                          _c(
                            "div",
                            { staticClass: "mr-2" },
                            [
                              _vm.card.credentials.access_token !== ""
                                ? _c("integration-settings-tester-btn", {
                                    attrs: {
                                      name: _vm.cardname,
                                      card: _vm.card,
                                    },
                                    on: { reload: _vm.fetchDetails },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            [
                              _c(
                                "el-tooltip",
                                {
                                  staticClass: "item",
                                  attrs: { effect: "dark", placement: "right" },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      attrs: { slot: "content" },
                                      slot: "content",
                                    },
                                    [
                                      _c("strong", [_vm._v("Authentication")]),
                                      _vm._v(" "),
                                      _c("br"),
                                      _vm._v(" "),
                                      _c("small", [
                                        _vm._v(
                                          "\n                                            This will redirect you to Salesforce for authentication.\n                                        "
                                        ),
                                      ]),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _vm.card.credentials.access_token !== ""
                                    ? _c(
                                        "el-button",
                                        {
                                          attrs: { plain: "" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.openPrismaticConfig()
                                            },
                                          },
                                        },
                                        [
                                          _c("span", [
                                            _vm._v("Re-Authenticate"),
                                          ]),
                                          _vm._v(" "),
                                          _c("i", {
                                            staticClass: "fa fa-sign-in mr-2",
                                          }),
                                        ]
                                      )
                                    : !_vm.isStatusToggleDisabled
                                    ? _c(
                                        "el-button",
                                        {
                                          attrs: { plain: "" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.openPrismaticConfig()
                                            },
                                          },
                                        },
                                        [
                                          _c("span", [_vm._v("Authenticate")]),
                                          _vm._v(" "),
                                          _c("i", {
                                            staticClass: "fa fa-sign-in mr-2",
                                          }),
                                        ]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _vm.isStatusToggleDisabled
                                ? _c(
                                    "el-alert",
                                    {
                                      staticClass: "my-2",
                                      attrs: {
                                        type: "info",
                                        closable: false,
                                        "show-icon": "",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                                    You can only enable one CRM integration.\n                                "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _vm.card.credentials.access_token !== ""
                        ? [
                            _c("h5", { staticClass: "mt-4" }, [
                              _vm._v("Synchronization"),
                            ]),
                            _vm._v(" "),
                            _c("hr", { staticClass: "no-top-margin" }),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "row form-group align-items-center",
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "col" },
                                  [
                                    _c("h6", { staticClass: "text-black" }, [
                                      _vm._v("Sync Contacts"),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "small",
                                      {
                                        staticClass:
                                          "p-0 m-0 block text-black-lt",
                                      },
                                      [
                                        _c("span", { staticClass: "mb-1" }, [
                                          _vm._v(
                                            "\n                                    Use "
                                          ),
                                          _c("b", [_vm._v("pull contacts")]),
                                          _vm._v(
                                            " to see all your Salesforce contacts within " +
                                              _vm._s(_vm.statics.name) +
                                              "\n                                "
                                          ),
                                        ]),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-alert",
                                      {
                                        staticClass: "my-2",
                                        attrs: {
                                          type: "info",
                                          closable: false,
                                          "show-icon": "",
                                        },
                                      },
                                      [
                                        _c("b", [_vm._v("Note")]),
                                        _vm._v(
                                          ": Pulling contacts will update " +
                                            _vm._s(_vm.statics.name) +
                                            " contacts using CRM data\n                                "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-alert",
                                      {
                                        staticClass: "my-2",
                                        attrs: {
                                          type: "warning",
                                          closable: false,
                                          "show-icon": "",
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "d-block mb-1 text-black",
                                          },
                                          [
                                            _vm._v(
                                              "\n                                        Pulling contacts will cause LRN lookup charges to be applied\n                                    "
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "col d-flex justify-content-end",
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "d-flex flex-column" },
                                      [
                                        _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              disabled:
                                                _vm.loading_pull ||
                                                [585, 1000].includes(
                                                  _vm.current_company.id
                                                ),
                                              plain: "",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.pullContacts(
                                                  _vm.cardname
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("span", [
                                              _vm._v("Pull Contacts"),
                                            ]),
                                            _vm._v(" "),
                                            !_vm.loading_pull
                                              ? _c("i", {
                                                  staticClass:
                                                    "fa fa-arrow-down",
                                                })
                                              : _vm._e(),
                                            _vm._v(" "),
                                            _vm.loading_pull
                                              ? _c(
                                                  "i",
                                                  {
                                                    staticClass:
                                                      "material-icons loader pull-left",
                                                  },
                                                  [_vm._v("")]
                                                )
                                              : _vm._e(),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c("div", { staticClass: "pt-2" }, [
                                          _vm.card.settings &&
                                          _vm.card.settings.last_sync_contact
                                            ? _c(
                                                "small",
                                                {
                                                  staticClass:
                                                    "p-0 m-0 block text-black-lt text-xs",
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                            Last pull contact: "
                                                  ),
                                                  _c("b", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm._f(
                                                          "fixFullDateUTCRelative"
                                                        )(
                                                          _vm.card.settings
                                                            .last_sync_contact
                                                        )
                                                      )
                                                    ),
                                                  ]),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "row form-group mt-3 align-items-center",
                              },
                              [
                                _c("div", { staticClass: "col" }, [
                                  _c("h6", { staticClass: "text-black" }, [
                                    _vm._v("Pull Salesforce Leads"),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "small",
                                    {
                                      staticClass:
                                        "p-0 m-0 block text-black-lt",
                                    },
                                    [
                                      _vm._v(
                                        "\n                                    Pulls your Salesforce leads as contacts\n                                "
                                      ),
                                    ]
                                  ),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "col d-flex justify-content-end",
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-flex flex-column width-300 fixed",
                                      },
                                      [
                                        _c(
                                          "el-button",
                                          {
                                            staticClass: "pull-right",
                                            attrs: {
                                              disabled:
                                                _vm.loading_salesforce_pull_leads,
                                              plain: "",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.pullSalesforceLeads()
                                              },
                                            },
                                          },
                                          [
                                            _c("span", [
                                              _vm._v("Pull Salesforce Leads"),
                                            ]),
                                            _vm._v(" "),
                                            !_vm.loading_salesforce_pull_leads
                                              ? _c("i", {
                                                  staticClass:
                                                    "fa fa-arrow-down",
                                                })
                                              : _vm._e(),
                                            _vm._v(" "),
                                            _vm.loading_salesforce_pull_leads
                                              ? _c(
                                                  "i",
                                                  {
                                                    staticClass:
                                                      "material-icons loader pull-left",
                                                  },
                                                  [_vm._v("")]
                                                )
                                              : _vm._e(),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c("div", { staticClass: "pt-2" }, [
                                          _vm.card.settings &&
                                          _vm.card.settings.last_sync_lead
                                            ? _c(
                                                "small",
                                                {
                                                  staticClass:
                                                    "p-0 m-0 block text-black-lt text-xs",
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                            Last pull leads: "
                                                  ),
                                                  _c("b", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm._f(
                                                          "fixFullDateUTCRelative"
                                                        )(
                                                          _vm.card.settings
                                                            .last_sync_lead
                                                        )
                                                      )
                                                    ),
                                                  ]),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        : _vm._e(),
                    ]
                  : _vm._e(),
                _vm._v(" "),
                _vm.cardname == "helpscout"
                  ? [
                      _c("div", { staticClass: "row form-group" }, [
                        _c("div", { staticClass: "col d-flex" }, [
                          _c(
                            "div",
                            { staticClass: "mr-2" },
                            [
                              _vm.card.credentials.access_token !== ""
                                ? _c("integration-settings-tester-btn", {
                                    attrs: {
                                      name: _vm.cardname,
                                      card: _vm.card,
                                    },
                                    on: { reload: _vm.fetchDetails },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            [
                              _c(
                                "el-tooltip",
                                {
                                  staticClass: "item",
                                  attrs: { effect: "dark", placement: "right" },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      attrs: { slot: "content" },
                                      slot: "content",
                                    },
                                    [
                                      _c("strong", [_vm._v("Authentication")]),
                                      _vm._v(" "),
                                      _c("br"),
                                      _vm._v(" "),
                                      _c("small", [
                                        _vm._v(
                                          "\n                                            This will redirect you to Pipedrive for authentication.\n                                        "
                                        ),
                                      ]),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _vm.card.credentials.access_token !== ""
                                    ? _c(
                                        "el-button",
                                        {
                                          attrs: { plain: "" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.oauth(_vm.cardname)
                                            },
                                          },
                                        },
                                        [
                                          _c("span", [
                                            _vm._v("Re-Authenticate"),
                                          ]),
                                          _vm._v(" "),
                                          _c("i", {
                                            staticClass: "fa fa-sign-in mr-2",
                                          }),
                                        ]
                                      )
                                    : !_vm.isStatusToggleDisabled
                                    ? _c(
                                        "el-button",
                                        {
                                          attrs: { plain: "" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.oauth(_vm.cardname)
                                            },
                                          },
                                        },
                                        [
                                          _c("span", [_vm._v("Authenticate")]),
                                          _vm._v(" "),
                                          _c("i", {
                                            staticClass: "fa fa-sign-in mr-2",
                                          }),
                                        ]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _vm.isStatusToggleDisabled
                                ? _c(
                                    "el-alert",
                                    {
                                      staticClass: "my-2",
                                      attrs: {
                                        type: "info",
                                        closable: false,
                                        "show-icon": "",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                                    You can only enable one CRM integration.\n                                "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _vm.card.credentials.access_token !== ""
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "row form-group mt-3 align-items-center",
                            },
                            [
                              _c("div", { staticClass: "col" }, [
                                _c("h6", { staticClass: "text-black" }, [
                                  _vm._v("Mailbox"),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "small",
                                  {
                                    staticClass: "p-0 m-0 block text-black-lt",
                                  },
                                  [
                                    !_vm.helpscout_mailboxes.length &&
                                    !_vm.loading_mailboxes
                                      ? _c("span", [
                                          _vm._v(
                                            "\n                                    Please create a mailbox on your Help Scout account and select it through here\n                                "
                                          ),
                                        ])
                                      : _c("span", [
                                          _vm._v(
                                            "\n                                    Select a mailbox to integrate with it\n                                "
                                          ),
                                        ]),
                                  ]
                                ),
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "col" },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      staticClass: "m-0",
                                      attrs: { prop: "settings.mailbox_id" },
                                    },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          staticClass: "w-100",
                                          attrs: {
                                            value: _vm.card.settings.mailbox_id,
                                            loading: _vm.loading_mailboxes,
                                            disabled: _vm.loading_mailboxes,
                                            filterable: "",
                                          },
                                          on: {
                                            change: _vm.customPreValidateForm,
                                          },
                                          model: {
                                            value: _vm.card.settings.mailbox_id,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.card.settings,
                                                "mailbox_id",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "card.settings.mailbox_id",
                                          },
                                        },
                                        _vm._l(
                                          _vm.helpscout_mailboxes,
                                          function (mailbox) {
                                            return _c("el-option", {
                                              key: mailbox.id,
                                              attrs: {
                                                label: mailbox.name,
                                                value: mailbox.id,
                                              },
                                            })
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.card.credentials.access_token !== ""
                        ? [
                            _c("h5", { staticClass: "mt-4" }, [
                              _vm._v("Synchronization"),
                            ]),
                            _vm._v(" "),
                            _c("hr", { staticClass: "no-top-margin" }),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "row form-group align-items-center",
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "col" },
                                  [
                                    _c("h6", { staticClass: "text-black" }, [
                                      _vm._v("Sync Contacts"),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "small",
                                      {
                                        staticClass:
                                          "p-0 m-0 block text-black-lt",
                                      },
                                      [
                                        _c("span", { staticClass: "mb-1" }, [
                                          _vm._v(
                                            "\n                                            Use "
                                          ),
                                          _c("b", [_vm._v("push contacts")]),
                                          _vm._v(
                                            " to see your " +
                                              _vm._s(_vm.statics.name) +
                                              " contacts in Help Scout\n                                        "
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _c("br"),
                                        _vm._v(" "),
                                        _c("span", { staticClass: "mb-1" }, [
                                          _vm._v(
                                            "\n                                            Use "
                                          ),
                                          _c("b", [_vm._v("pull contacts")]),
                                          _vm._v(
                                            " to see all your Help Scout contacts within " +
                                              _vm._s(_vm.statics.name) +
                                              "\n                                        "
                                          ),
                                        ]),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-alert",
                                      {
                                        staticClass: "my-2",
                                        attrs: {
                                          type: "info",
                                          closable: false,
                                          "show-icon": "",
                                        },
                                      },
                                      [
                                        _c("b", [_vm._v("Note")]),
                                        _vm._v(
                                          ": Pulling contacts will update " +
                                            _vm._s(_vm.statics.name) +
                                            " contacts using CRM data\n                                "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-alert",
                                      {
                                        staticClass: "my-2",
                                        attrs: {
                                          type: "warning",
                                          closable: false,
                                          "show-icon": "",
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "d-block mb-1 text-black",
                                          },
                                          [
                                            _vm._v(
                                              "\n                                        Pulling contacts will cause LRN lookup charges to be applied\n                                    "
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "col d-flex justify-content-end",
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "d-flex flex-column mr-2",
                                      },
                                      [
                                        _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              disabled: _vm.loading_push,
                                              plain: "",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.pushContacts(
                                                  _vm.cardname
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("span", [
                                              _vm._v("Push Contacts"),
                                            ]),
                                            _vm._v(" "),
                                            !_vm.loading_push
                                              ? _c("i", {
                                                  staticClass: "fa fa-arrow-up",
                                                })
                                              : _vm._e(),
                                            _vm._v(" "),
                                            _vm.loading_push
                                              ? _c(
                                                  "i",
                                                  {
                                                    staticClass:
                                                      "material-icons loader pull-left",
                                                  },
                                                  [_vm._v("")]
                                                )
                                              : _vm._e(),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c("div", { staticClass: "pt-2" }, [
                                          _vm.card.settings &&
                                          _vm.card.settings
                                            .last_sync_push_contact
                                            ? _c(
                                                "small",
                                                {
                                                  staticClass:
                                                    "p-0 m-0 block text-black-lt text-xs",
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                            Last push contact: "
                                                  ),
                                                  _c("b", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm._f(
                                                          "fixFullDateUTCRelative"
                                                        )(
                                                          _vm.card.settings
                                                            .last_sync_push_contact
                                                        )
                                                      )
                                                    ),
                                                  ]),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "d-flex flex-column" },
                                      [
                                        _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              disabled: _vm.loading_pull,
                                              plain: "",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.pullContacts(
                                                  _vm.cardname
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("span", [
                                              _vm._v("Pull Contacts"),
                                            ]),
                                            _vm._v(" "),
                                            !_vm.loading_pull
                                              ? _c("i", {
                                                  staticClass:
                                                    "fa fa-arrow-down",
                                                })
                                              : _vm._e(),
                                            _vm._v(" "),
                                            _vm.loading_pull
                                              ? _c(
                                                  "i",
                                                  {
                                                    staticClass:
                                                      "material-icons loader pull-left",
                                                  },
                                                  [_vm._v("")]
                                                )
                                              : _vm._e(),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c("div", { staticClass: "pt-2" }, [
                                          _vm.card.settings &&
                                          _vm.card.settings
                                            .last_sync_pull_contact
                                            ? _c(
                                                "small",
                                                {
                                                  staticClass:
                                                    "p-0 m-0 block text-black-lt text-xs",
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                            Last pull contact: "
                                                  ),
                                                  _c("b", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm._f(
                                                          "fixFullDateUTCRelative"
                                                        )(
                                                          _vm.card.settings
                                                            .last_sync_pull_contact
                                                        )
                                                      )
                                                    ),
                                                  ]),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        : _vm._e(),
                    ]
                  : _vm._e(),
                _vm._v(" "),
                _vm.cardname == "facebook"
                  ? [
                      _c("div", { staticClass: "row form-group" }, [
                        _c("div", { staticClass: "col d-flex" }, [
                          _c(
                            "div",
                            { staticClass: "mr-2" },
                            [
                              _vm.card.credentials.access_token !== ""
                                ? _c("integration-settings-tester-btn", {
                                    attrs: {
                                      name: _vm.cardname,
                                      card: _vm.card,
                                    },
                                    on: { reload: _vm.fetchDetails },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            [
                              _c(
                                "el-tooltip",
                                {
                                  staticClass: "item",
                                  attrs: { effect: "dark", placement: "right" },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      attrs: { slot: "content" },
                                      slot: "content",
                                    },
                                    [
                                      _c("strong", [_vm._v("Authentication")]),
                                      _vm._v(" "),
                                      _c("br"),
                                      _vm._v(" "),
                                      _c("small", [
                                        _vm._v(
                                          "\n                                            This will redirect you to Facebook for authentication.\n                                        "
                                        ),
                                      ]),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _vm.card.credentials.access_token !== ""
                                    ? _c(
                                        "el-button",
                                        {
                                          attrs: { plain: "" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.oauth(_vm.cardname)
                                            },
                                          },
                                        },
                                        [
                                          _c("span", [
                                            _vm._v("Re-Authenticate"),
                                          ]),
                                          _vm._v(" "),
                                          _c("i", {
                                            staticClass: "fa fa-sign-in mr-2",
                                          }),
                                        ]
                                      )
                                    : _c(
                                        "el-button",
                                        {
                                          attrs: { plain: "" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.oauth(_vm.cardname)
                                            },
                                          },
                                        },
                                        [
                                          _c("span", [_vm._v("Authenticate")]),
                                          _vm._v(" "),
                                          _c("i", {
                                            staticClass: "fa fa-sign-in mr-2",
                                          }),
                                        ]
                                      ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _vm.card.credentials.access_token !== "" &&
                      _vm.card.status === true &&
                      _vm.current_company.facebook_integration_enabled
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "row form-group mt-3 align-items-center",
                            },
                            [
                              _c("div", { staticClass: "col" }, [
                                _c("h6", { staticClass: "text-black" }, [
                                  _vm._v("Facebook Page"),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "small",
                                  {
                                    staticClass: "p-0 m-0 block text-black-lt",
                                  },
                                  [
                                    !_vm.facebook_pages.length &&
                                    !_vm.loading_pages
                                      ? _c("span", [
                                          _vm._v(
                                            "\n                                    Please create a page on facebook and select it here\n                                "
                                          ),
                                        ])
                                      : _c("span", [
                                          _vm._v(
                                            "\n                                    Select the facebook page that you want to receive leads from.\n                                "
                                          ),
                                        ]),
                                  ]
                                ),
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "col" },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      staticClass: "m-0",
                                      attrs: { prop: "settings.page_id" },
                                    },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          staticClass: "w-100",
                                          attrs: {
                                            value: _vm.card.settings.page_id,
                                            loading: _vm.loading_pages,
                                            disabled:
                                              _vm.loading_pages ||
                                              !_vm.facebook_pages.length,
                                            filterable: "",
                                          },
                                          on: { change: _vm.setFacebookPage },
                                          model: {
                                            value: _vm.card.settings.page_id,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.card.settings,
                                                "page_id",
                                                $$v
                                              )
                                            },
                                            expression: "card.settings.page_id",
                                          },
                                        },
                                        _vm._l(
                                          _vm.facebook_pages,
                                          function (page) {
                                            return _c("el-option", {
                                              key: page.id,
                                              attrs: {
                                                label: page.name,
                                                value: page.id,
                                              },
                                            })
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.card.credentials.access_token !== "" &&
                      _vm.card.status === true &&
                      _vm.card.settings.page_id &&
                      _vm.card.settings.page
                        ? [
                            _c("h5", { staticClass: "mt-4" }, [
                              _vm._v("Synchronization"),
                            ]),
                            _vm._v(" "),
                            _c("hr", { staticClass: "no-top-margin" }),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "row form-group align-items-center",
                              },
                              [
                                _c("div", { staticClass: "col" }, [
                                  _c("h6", { staticClass: "text-black" }, [
                                    _vm._v("Sequence"),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "small",
                                    {
                                      staticClass:
                                        "p-0 m-0 block text-black-lt",
                                    },
                                    [
                                      _c("b", [
                                        _vm._v("Auto Enroll (Optional)"),
                                      ]),
                                      _vm._v(" "),
                                      _c("br"),
                                      _vm._v(
                                        "\n                                    Please select the sequence that you want to enroll the leads to\n                                "
                                      ),
                                    ]
                                  ),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "col" },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        staticClass: "m-0",
                                        attrs: { prop: "settings.workflow_id" },
                                      },
                                      [
                                        _c("workflow-selector", {
                                          staticStyle: { width: "100%" },
                                          on: { change: _vm.setWorkflow },
                                          model: {
                                            value:
                                              _vm.card.settings.workflow_id,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.card.settings,
                                                "workflow_id",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "card.settings.workflow_id",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "row form-group mt-3 align-items-center",
                              },
                              [
                                _c("div", { staticClass: "col" }, [
                                  _c("h6", { staticClass: "text-black" }, [
                                    _vm._v("Leadgen Forms"),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "small",
                                    {
                                      staticClass:
                                        "p-0 m-0 block text-black-lt",
                                    },
                                    [
                                      _vm._v(
                                        "\n                                    You can pull your leads from your existing leadgen forms in Facebook\n                                "
                                      ),
                                    ]
                                  ),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "col d-flex justify-content-end",
                                  },
                                  [
                                    _c(
                                      "el-form-item",
                                      { staticClass: "inline mr-2" },
                                      [
                                        _c(
                                          "el-select",
                                          {
                                            attrs: {
                                              loading:
                                                _vm.loading_leadgen_forms,
                                              disabled:
                                                _vm.loading_leadgen_forms ||
                                                !_vm.leadgen_forms.length,
                                              filterable: "",
                                            },
                                            model: {
                                              value: _vm.leadgen_form_id,
                                              callback: function ($$v) {
                                                _vm.leadgen_form_id = $$v
                                              },
                                              expression: "leadgen_form_id",
                                            },
                                          },
                                          _vm._l(
                                            _vm.leadgen_forms,
                                            function (leadgen_form) {
                                              return _c("el-option", {
                                                key: leadgen_form.id,
                                                attrs: {
                                                  label: leadgen_form.name,
                                                  value: leadgen_form.id,
                                                },
                                              })
                                            }
                                          ),
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-button",
                                      {
                                        attrs: {
                                          disabled:
                                            !_vm.leadgen_form_id ||
                                            _vm.loading_pull_leadgen_leads,
                                          plain: "",
                                        },
                                        on: { click: _vm.pullFacebookLeads },
                                      },
                                      [
                                        _c("span", [_vm._v("Pull Leads")]),
                                        _vm._v(" "),
                                        !_vm.loading_pull
                                          ? _c("i", {
                                              staticClass: "fa fa-arrow-down",
                                            })
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.loading_pull
                                          ? _c(
                                              "i",
                                              {
                                                staticClass:
                                                  "material-icons loader pull-left",
                                              },
                                              [_vm._v("")]
                                            )
                                          : _vm._e(),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            ),
                          ]
                        : _vm._e(),
                    ]
                  : _vm._e(),
                _vm._v(" "),
                _vm.cardname == "slack"
                  ? [
                      _c("div", { staticClass: "row form-group" }, [
                        _c(
                          "div",
                          { staticClass: "col" },
                          [
                            _c(
                              "el-tooltip",
                              {
                                staticClass: "item",
                                attrs: { effect: "dark", placement: "right" },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    attrs: { slot: "content" },
                                    slot: "content",
                                  },
                                  [
                                    _c("strong", [_vm._v("Authentication")]),
                                    _vm._v(" "),
                                    _c("br"),
                                    _vm._v(" "),
                                    _c("small", [
                                      _vm._v(
                                        "\n                                        This will redirect you to Slack for authentication.\n                                    "
                                      ),
                                    ]),
                                  ]
                                ),
                                _vm._v(" "),
                                _vm.card.credentials.api_token !== ""
                                  ? _c(
                                      "el-button",
                                      {
                                        attrs: { plain: "" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.oauth(_vm.cardname)
                                          },
                                        },
                                      },
                                      [
                                        _c("span", [_vm._v("Re-Authenticate")]),
                                        _vm._v(" "),
                                        _c("i", {
                                          staticClass: "fa fa-sign-in mr-2",
                                        }),
                                      ]
                                    )
                                  : _c(
                                      "el-button",
                                      {
                                        attrs: { plain: "" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.oauth(_vm.cardname)
                                          },
                                        },
                                      },
                                      [
                                        _c("span", [_vm._v("Authenticate")]),
                                        _vm._v(" "),
                                        _c("i", {
                                          staticClass: "fa fa-sign-in mr-2",
                                        }),
                                      ]
                                    ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                    ]
                  : _vm._e(),
                _vm._v(" "),
                _vm.cardname == "sendgrid" &&
                !_vm.hasOtherEmailIntegrationEnabled(_vm.cardname)
                  ? [
                      _c(
                        "div",
                        { staticClass: "row form-group align-items-center" },
                        [
                          _c("div", { staticClass: "col" }, [
                            _c("h6", { staticClass: "text-black" }, [
                              _vm._v("API key"),
                            ]),
                            _vm._v(" "),
                            _c(
                              "small",
                              { staticClass: "p-0 m-0 block text-black-lt" },
                              [
                                _vm._v(
                                  "\n                                Your Sendgrid API key.\n                                You can find your API keys from here:\n                                "
                                ),
                                _c(
                                  "a",
                                  {
                                    staticClass: "text-bluish _600",
                                    attrs: {
                                      href: "https://app.sendgrid.com/settings/api_keys",
                                      target: "_blank",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                                    here\n                                "
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "row form-group align-items-center" },
                        [
                          _c(
                            "div",
                            { staticClass: "col-6" },
                            [
                              _c(
                                "el-form-item",
                                {
                                  staticClass: "m-0",
                                  attrs: { prop: "credentials.api_token" },
                                },
                                [
                                  _c("div", { staticClass: "input-group" }, [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.card.credentials.api_token,
                                          expression:
                                            "card.credentials.api_token",
                                        },
                                      ],
                                      staticClass: "form-control",
                                      attrs: {
                                        type: "text",
                                        placeholder: "Put your API key here",
                                      },
                                      domProps: {
                                        value: _vm.card.credentials.api_token,
                                      },
                                      on: {
                                        input: [
                                          function ($event) {
                                            if ($event.target.composing) return
                                            _vm.$set(
                                              _vm.card.credentials,
                                              "api_token",
                                              $event.target.value
                                            )
                                          },
                                          _vm.customPreValidateForm,
                                        ],
                                      },
                                    }),
                                  ]),
                                ]
                              ),
                              _vm._v(" "),
                              _c("div", { staticClass: "pt-3" }, [
                                _c(
                                  "small",
                                  {
                                    staticClass: "p-0 m-0 block text-black-lt",
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        class:
                                          _vm.testingApiKeyElementsAttrs.color,
                                      },
                                      [
                                        _vm._v(
                                          "\n                                        " +
                                            _vm._s(
                                              _vm.testingApiKeyElementsAttrs
                                                .TestStatus
                                            ) +
                                            "\n                                        "
                                        ),
                                        _c("i", {
                                          class:
                                            _vm.testingApiKeyElementsAttrs.icon,
                                        }),
                                      ]
                                    ),
                                  ]
                                ),
                              ]),
                            ],
                            1
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "row form-group align-items-center" },
                        [
                          _c("div", { staticClass: "col" }, [
                            _c("h6", { staticClass: "text-black" }, [
                              _vm._v("Sender address"),
                            ]),
                            _vm._v(" "),
                            _c(
                              "small",
                              { staticClass: "p-0 m-0 block text-black-lt" },
                              [
                                _vm._v(
                                  "\n                                The sender email address that " +
                                    _vm._s(_vm.statics.name) +
                                    " should send email from it.\n                            "
                                ),
                              ]
                            ),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "row form-group align-items-center" },
                        [
                          _c(
                            "div",
                            { staticClass: "col-6" },
                            [
                              _c(
                                "el-form-item",
                                {
                                  staticClass: "m-0",
                                  attrs: { prop: "settings.sender_address" },
                                },
                                [
                                  _c("div", { staticClass: "input-group" }, [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value:
                                            _vm.card.settings.sender_address,
                                          expression:
                                            "card.settings.sender_address",
                                        },
                                      ],
                                      staticClass: "form-control",
                                      attrs: {
                                        type: "text",
                                        placeholder:
                                          "Put your sender address here",
                                      },
                                      domProps: {
                                        value: _vm.card.settings.sender_address,
                                      },
                                      on: {
                                        input: [
                                          function ($event) {
                                            if ($event.target.composing) return
                                            _vm.$set(
                                              _vm.card.settings,
                                              "sender_address",
                                              $event.target.value
                                            )
                                          },
                                          _vm.customPreValidateForm,
                                        ],
                                      },
                                    }),
                                  ]),
                                ]
                              ),
                              _vm._v(" "),
                              _c("div", { staticClass: "pt-3" }, [
                                _c(
                                  "small",
                                  {
                                    staticClass: "p-0 m-0 block text-black-lt",
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        class:
                                          _vm.testingApiKeyElementsAttrs.color,
                                      },
                                      [
                                        _vm._v(
                                          "\n                                        " +
                                            _vm._s(
                                              _vm.testingApiKeyElementsAttrs
                                                .TestStatus
                                            ) +
                                            "\n                                        "
                                        ),
                                        _c("i", {
                                          class:
                                            _vm.testingApiKeyElementsAttrs.icon,
                                        }),
                                      ]
                                    ),
                                  ]
                                ),
                              ]),
                            ],
                            1
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "row form-group align-items-center" },
                        [
                          _c("div", { staticClass: "col" }, [
                            _c("h6", { staticClass: "text-black" }, [
                              _vm._v("Sender name"),
                            ]),
                            _vm._v(" "),
                            _c(
                              "small",
                              { staticClass: "p-0 m-0 block text-black-lt" },
                              [
                                _vm._v(
                                  "\n                                The sender name that " +
                                    _vm._s(_vm.statics.name) +
                                    " should send email from it.\n                            "
                                ),
                              ]
                            ),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "row form-group align-items-center" },
                        [
                          _c(
                            "div",
                            { staticClass: "col-6" },
                            [
                              _c(
                                "el-form-item",
                                {
                                  staticClass: "m-0",
                                  attrs: { prop: "settings.sender_name" },
                                },
                                [
                                  _c("div", { staticClass: "input-group" }, [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.card.settings.sender_name,
                                          expression:
                                            "card.settings.sender_name",
                                        },
                                      ],
                                      staticClass: "form-control",
                                      attrs: {
                                        type: "text",
                                        placeholder:
                                          "Put your sender name here",
                                      },
                                      domProps: {
                                        value: _vm.card.settings.sender_name,
                                      },
                                      on: {
                                        input: [
                                          function ($event) {
                                            if ($event.target.composing) return
                                            _vm.$set(
                                              _vm.card.settings,
                                              "sender_name",
                                              $event.target.value
                                            )
                                          },
                                          _vm.customPreValidateForm,
                                        ],
                                      },
                                    }),
                                  ]),
                                ]
                              ),
                              _vm._v(" "),
                              _c("div", { staticClass: "pt-3" }, [
                                _c(
                                  "small",
                                  {
                                    staticClass: "p-0 m-0 block text-black-lt",
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        class:
                                          _vm.testingApiKeyElementsAttrs.color,
                                      },
                                      [
                                        _vm._v(
                                          "\n                                        " +
                                            _vm._s(
                                              _vm.testingApiKeyElementsAttrs
                                                .TestStatus
                                            ) +
                                            "\n                                        "
                                        ),
                                        _c("i", {
                                          class:
                                            _vm.testingApiKeyElementsAttrs.icon,
                                        }),
                                      ]
                                    ),
                                  ]
                                ),
                              ]),
                            ],
                            1
                          ),
                        ]
                      ),
                    ]
                  : _vm._e(),
                _vm._v(" "),
                _vm.cardname == "mailgun" &&
                !_vm.hasOtherEmailIntegrationEnabled(_vm.cardname)
                  ? [
                      _vm.api_key_input
                        ? [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "row form-group align-items-center",
                              },
                              [
                                _c("div", { staticClass: "col" }, [
                                  _c("h6", { staticClass: "text-black" }, [
                                    _vm._v("API key"),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "small",
                                    {
                                      staticClass:
                                        "p-0 m-0 block text-black-lt",
                                    },
                                    [
                                      _vm._v(
                                        "\n                                    Your Mailgun Private API key.\n                                    You can find your API keys from:\n                                    "
                                      ),
                                      _c(
                                        "a",
                                        {
                                          staticClass: "text-bluish _600",
                                          attrs: {
                                            href: "https://app.mailgun.com/app/account/security/api_keys",
                                            target: "_blank",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                                        here\n                                    "
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "row form-group align-items-center",
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "col-6" },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        staticClass: "m-0",
                                        attrs: {
                                          prop: "credentials.api_token",
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "input-group" },
                                          [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.card.credentials
                                                      .api_token,
                                                  expression:
                                                    "card.credentials.api_token",
                                                },
                                              ],
                                              staticClass: "form-control",
                                              attrs: {
                                                type: "text",
                                                placeholder:
                                                  "Put your API key here",
                                              },
                                              domProps: {
                                                value:
                                                  _vm.card.credentials
                                                    .api_token,
                                              },
                                              on: {
                                                input: [
                                                  function ($event) {
                                                    if ($event.target.composing)
                                                      return
                                                    _vm.$set(
                                                      _vm.card.credentials,
                                                      "api_token",
                                                      $event.target.value
                                                    )
                                                  },
                                                  _vm.customPreValidateForm,
                                                ],
                                              },
                                            }),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "pt-3" }, [
                                      _c(
                                        "small",
                                        {
                                          staticClass:
                                            "p-0 m-0 block text-black-lt",
                                        },
                                        [
                                          _vm.card.credentials.api_token
                                            ? _c(
                                                "span",
                                                {
                                                  class:
                                                    _vm
                                                      .testingApiKeyElementsAttrs
                                                      .color,
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                            " +
                                                      _vm._s(
                                                        _vm
                                                          .testingApiKeyElementsAttrs
                                                          .TestStatus
                                                      ) +
                                                      "\n                                            "
                                                  ),
                                                  _c("i", {
                                                    class:
                                                      _vm
                                                        .testingApiKeyElementsAttrs
                                                        .icon,
                                                  }),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ]
                            ),
                          ]
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.apiTokenTestStatus ===
                        _vm.WebhookTestStatuses.SUCCESS &&
                      _vm.api_key_input &&
                      _vm.card.status
                        ? [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "row form-group mt-3 align-items-center",
                              },
                              [
                                _c("div", { staticClass: "col" }, [
                                  _c("h6", { staticClass: "text-black" }, [
                                    _vm._v("Mailgun Domain"),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "small",
                                    {
                                      staticClass:
                                        "p-0 m-0 block text-black-lt",
                                    },
                                    [
                                      _vm._v(
                                        "\n                                    This is the domain you will use to send email using your mailgun account.\n                                "
                                      ),
                                    ]
                                  ),
                                ]),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "row form-group align-items-center",
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "col-6" },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        staticClass: "m-0",
                                        attrs: { prop: "settings.domain" },
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "input-group" },
                                          [
                                            _c(
                                              "el-select",
                                              {
                                                attrs: {
                                                  placeholder: "Select Domain",
                                                  clearable: true,
                                                  disabled:
                                                    !_vm.mailgun_domains.length,
                                                },
                                                on: {
                                                  change:
                                                    _vm.customPreValidateForm,
                                                },
                                                model: {
                                                  value:
                                                    _vm.card.settings.domain,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.card.settings,
                                                      "domain",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "card.settings.domain",
                                                },
                                              },
                                              _vm._l(
                                                _vm.mailgun_domains,
                                                function (opt) {
                                                  return _c(
                                                    "el-option",
                                                    {
                                                      key: opt.id,
                                                      attrs: {
                                                        label: opt.name,
                                                        value: opt.name,
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        { staticClass: "ml-2" },
                                                        [
                                                          _vm._v(
                                                            _vm._s(opt.name)
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  )
                                                }
                                              ),
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "row form-group mt-5 align-items-center",
                              },
                              [
                                _c("div", { staticClass: "col" }, [
                                  _c("h6", { staticClass: "text-black" }, [
                                    _vm._v("Sender address"),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "small",
                                    {
                                      staticClass:
                                        "p-0 m-0 block text-black-lt",
                                    },
                                    [
                                      _vm._v(
                                        "\n                                    The sender email address that " +
                                          _vm._s(_vm.statics.name) +
                                          " should send email from it.\n                                "
                                      ),
                                    ]
                                  ),
                                ]),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "row form-group align-items-center",
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "col-6" },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        staticClass: "m-0",
                                        attrs: {
                                          prop: "settings.sender_address",
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "input-group" },
                                          [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.card.settings
                                                      .sender_address,
                                                  expression:
                                                    "card.settings.sender_address",
                                                },
                                              ],
                                              staticClass: "form-control",
                                              attrs: {
                                                type: "text",
                                                placeholder:
                                                  "Put your sender address here",
                                                disabled:
                                                  !_vm.mailgun_domains.length,
                                              },
                                              domProps: {
                                                value:
                                                  _vm.card.settings
                                                    .sender_address,
                                              },
                                              on: {
                                                input: [
                                                  function ($event) {
                                                    if ($event.target.composing)
                                                      return
                                                    _vm.$set(
                                                      _vm.card.settings,
                                                      "sender_address",
                                                      $event.target.value
                                                    )
                                                  },
                                                  _vm.customPreValidateForm,
                                                ],
                                              },
                                            }),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "row form-group mt-5 align-items-center",
                              },
                              [
                                _c("div", { staticClass: "col" }, [
                                  _c("h6", { staticClass: "text-black" }, [
                                    _vm._v("Sender name"),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "small",
                                    {
                                      staticClass:
                                        "p-0 m-0 block text-black-lt",
                                    },
                                    [
                                      _vm._v(
                                        "\n                                    The sender name that " +
                                          _vm._s(_vm.statics.name) +
                                          " should send email from it.\n                                "
                                      ),
                                    ]
                                  ),
                                ]),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "row form-group align-items-center",
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "col-6" },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        staticClass: "m-0",
                                        attrs: { prop: "settings.sender_name" },
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "input-group" },
                                          [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.card.settings
                                                      .sender_name,
                                                  expression:
                                                    "card.settings.sender_name",
                                                },
                                              ],
                                              staticClass: "form-control",
                                              attrs: {
                                                type: "text",
                                                placeholder:
                                                  "Put your sender name here",
                                                disabled:
                                                  !_vm.mailgun_domains.length,
                                              },
                                              domProps: {
                                                value:
                                                  _vm.card.settings.sender_name,
                                              },
                                              on: {
                                                input: [
                                                  function ($event) {
                                                    if ($event.target.composing)
                                                      return
                                                    _vm.$set(
                                                      _vm.card.settings,
                                                      "sender_name",
                                                      $event.target.value
                                                    )
                                                  },
                                                  _vm.customPreValidateForm,
                                                ],
                                              },
                                            }),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            ),
                          ]
                        : _vm._e(),
                    ]
                  : _vm._e(),
                _vm._v(" "),
                _vm.cardname == "gong"
                  ? [
                      _c("div", { staticClass: "row form-group" }, [
                        _c("div", { staticClass: "col d-flex" }, [
                          _c(
                            "div",
                            { staticClass: "mr-2" },
                            [
                              _vm.card.credentials.access_token !== ""
                                ? _c("integration-settings-tester-btn", {
                                    attrs: {
                                      name: _vm.cardname,
                                      card: _vm.card,
                                    },
                                    on: { reload: _vm.fetchDetails },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            [
                              _c(
                                "el-tooltip",
                                {
                                  staticClass: "item",
                                  attrs: { effect: "dark", placement: "right" },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      attrs: { slot: "content" },
                                      slot: "content",
                                    },
                                    [
                                      _c("strong", [_vm._v("Authentication")]),
                                      _vm._v(" "),
                                      _c("br"),
                                      _vm._v(" "),
                                      _c("small", [
                                        _vm._v(
                                          "\n                                            This will redirect you to Gong for authentication.\n                                        "
                                        ),
                                      ]),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _vm.current_company
                                    .gong_integration_enabled &&
                                  _vm.card.credentials.hasOwnProperty(
                                    "access_key"
                                  ) &&
                                  _vm.card.credentials["access_key"] !== ""
                                    ? _c(
                                        "el-button",
                                        {
                                          attrs: { plain: "" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.oauth(_vm.cardname)
                                            },
                                          },
                                        },
                                        [
                                          _c("span", [
                                            _vm._v("Upgrade to OAuth"),
                                          ]),
                                          _vm._v(" "),
                                          _c("i", {
                                            staticClass: "fa fa-sign-in mr-2",
                                          }),
                                        ]
                                      )
                                    : !_vm.current_company
                                        .gong_integration_enabled
                                    ? _c(
                                        "el-button",
                                        {
                                          attrs: { plain: "" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.oauth(_vm.cardname)
                                            },
                                          },
                                        },
                                        [
                                          _c("span", [_vm._v("Authenticate")]),
                                          _vm._v(" "),
                                          _c("i", {
                                            staticClass: "fa fa-sign-in mr-2",
                                          }),
                                        ]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _vm.isStatusToggleDisabled
                                ? _c(
                                    "el-alert",
                                    {
                                      staticClass: "my-2",
                                      attrs: {
                                        type: "info",
                                        closable: false,
                                        "show-icon": "",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                                    You can only enable one CRM integration.\n                                "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _vm.card.credentials.hasOwnProperty("access_key") &&
                      _vm.card.credentials["access_key"] !== ""
                        ? _c(
                            "div",
                            {
                              staticClass: "row form-group align-items-center",
                            },
                            [
                              _c("div", { staticClass: "col" }, [
                                _c("h6", { staticClass: "text-black" }, [
                                  _vm._v("Access Key"),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "small",
                                  {
                                    staticClass: "p-0 m-0 block text-black-lt",
                                  },
                                  [
                                    _vm._v(
                                      "\n                                You can find your access keys from your Workspace Settings > Data Export > API\n                                "
                                    ),
                                    _c("br"),
                                    _vm._v(" "),
                                    _c(
                                      "a",
                                      {
                                        staticClass: "text-bluish _600",
                                        attrs: {
                                          href: "https://help.gong.io/hc/en-us/articles/360042449471-View-API-documentation-on-call-data",
                                          target: "_blank",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                                    Learn more\n                                "
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.card.credentials.hasOwnProperty("access_key") &&
                      _vm.card.credentials["access_key"] !== ""
                        ? _c(
                            "div",
                            {
                              staticClass: "row form-group align-items-center",
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "col-6" },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      staticClass: "m-0",
                                      attrs: { prop: "credentials.access_key" },
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "input-group" },
                                        [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value:
                                                  _vm.card.credentials
                                                    .access_key,
                                                expression:
                                                  "card.credentials.access_key",
                                              },
                                            ],
                                            staticClass: "form-control",
                                            attrs: {
                                              type: "text",
                                              placeholder:
                                                "Put your access key here",
                                            },
                                            domProps: {
                                              value:
                                                _vm.card.credentials.access_key,
                                            },
                                            on: {
                                              input: [
                                                function ($event) {
                                                  if ($event.target.composing)
                                                    return
                                                  _vm.$set(
                                                    _vm.card.credentials,
                                                    "access_key",
                                                    $event.target.value
                                                  )
                                                },
                                                _vm.customPreValidateForm,
                                              ],
                                            },
                                          }),
                                        ]
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.card.credentials.hasOwnProperty("access_key") &&
                      _vm.card.credentials["access_key"] !== ""
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "row form-group mt-5 align-items-center",
                            },
                            [
                              _c("div", { staticClass: "col" }, [
                                _c("h6", { staticClass: "text-black" }, [
                                  _vm._v("Access Key Secret"),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "small",
                                  {
                                    staticClass: "p-0 m-0 block text-black-lt",
                                  },
                                  [
                                    _vm._v(
                                      "\n                                You can find your access keys from your Workspace Settings > Data Export > API\n                                "
                                    ),
                                    _c("br"),
                                    _vm._v(" "),
                                    _c(
                                      "a",
                                      {
                                        staticClass: "text-bluish _600",
                                        attrs: {
                                          href: "https://help.gong.io/hc/en-us/articles/360042449471-View-API-documentation-on-call-data",
                                          target: "_blank",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                                    Learn more\n                                "
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.card.credentials.hasOwnProperty("access_key") &&
                      _vm.card.credentials["access_key"] !== ""
                        ? _c(
                            "div",
                            {
                              staticClass: "row form-group align-items-center",
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "col-6" },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      staticClass: "m-0",
                                      attrs: {
                                        prop: "credentials.access_key_secret",
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "input-group" },
                                        [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value:
                                                  _vm.card.credentials
                                                    .access_key_secret,
                                                expression:
                                                  "card.credentials.access_key_secret",
                                              },
                                            ],
                                            staticClass: "form-control w-100",
                                            attrs: {
                                              type: "text",
                                              placeholder:
                                                "Put your access key secret here",
                                            },
                                            domProps: {
                                              value:
                                                _vm.card.credentials
                                                  .access_key_secret,
                                            },
                                            on: {
                                              input: [
                                                function ($event) {
                                                  if ($event.target.composing)
                                                    return
                                                  _vm.$set(
                                                    _vm.card.credentials,
                                                    "access_key_secret",
                                                    $event.target.value
                                                  )
                                                },
                                                _vm.customPreValidateForm,
                                              ],
                                            },
                                          }),
                                        ]
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.card.credentials.access_key !== "" &&
                      _vm.card.credentials.access_key_secret !== "" &&
                      _vm.card.status === true &&
                      _vm.current_company.gong_integration_enabled
                        ? [
                            _c("h5", { staticClass: "mt-4" }, [
                              _vm._v("Synchronization"),
                            ]),
                            _vm._v(" "),
                            _c("hr", { staticClass: "no-top-margin" }),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "row form-group align-items-center",
                              },
                              [
                                _c("div", { staticClass: "col" }, [
                                  _c("h6", { staticClass: "text-black" }, [
                                    _vm._v("Workspace Settings"),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "small",
                                    {
                                      staticClass:
                                        "p-0 m-0 block text-black-lt",
                                    },
                                    [
                                      !_vm.gong_workspaces.length &&
                                      !_vm.loading_workspaces
                                        ? _c("span", [
                                            _vm._v(
                                              "\n                                        Please create a workspace on your Gong account and select it here\n                                    "
                                            ),
                                          ])
                                        : _c("span", [
                                            _vm._v(
                                              "\n                                        Select one of your Gong workspaces to integrate with it\n                                    "
                                            ),
                                          ]),
                                    ]
                                  ),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "col" },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        staticClass: "m-0",
                                        attrs: {
                                          prop: "settings.workspace_id",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-select",
                                          {
                                            staticClass: "w-100",
                                            attrs: {
                                              value:
                                                _vm.card.settings.workspace_id,
                                              loading: _vm.loading_workspaces,
                                              disabled:
                                                _vm.loading_workspaces ||
                                                !_vm.gong_workspaces.length,
                                              filterable: "",
                                            },
                                            on: {
                                              change: _vm.customPreValidateForm,
                                            },
                                            model: {
                                              value:
                                                _vm.card.settings.workspace_id,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.card.settings,
                                                  "workspace_id",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "card.settings.workspace_id",
                                            },
                                          },
                                          _vm._l(
                                            _vm.gong_workspaces,
                                            function (workspace) {
                                              return _c("el-option", {
                                                key: workspace.id,
                                                attrs: {
                                                  label: workspace.name,
                                                  value: workspace.id,
                                                },
                                              })
                                            }
                                          ),
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _vm.card.settings.workspace_id
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "row form-group align-items-center mt-3",
                                  },
                                  [
                                    _c("div", { staticClass: "col" }, [
                                      _c("h6", { staticClass: "text-black" }, [
                                        _vm._v("Sync users"),
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "small",
                                        {
                                          staticClass:
                                            "p-0 m-0 block text-black-lt",
                                        },
                                        [
                                          _vm._v(
                                            "\n                                    Sync your Gong users with " +
                                              _vm._s(_vm.statics.name) +
                                              ". This will allow us to send out\n                                    call information and assign it to the correct user in Gong.\n                                "
                                          ),
                                        ]
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "col d-flex justify-content-end",
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "d-flex flex-column" },
                                          [
                                            _c(
                                              "el-button",
                                              {
                                                attrs: {
                                                  disabled:
                                                    _vm.loading_pull_users,
                                                  plain: "",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.pullUsers(
                                                      _vm.cardname
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c("span", [
                                                  _vm._v("Sync Gong users"),
                                                ]),
                                                _vm._v(" "),
                                                _vm.loading_pull_users
                                                  ? _c(
                                                      "i",
                                                      {
                                                        staticClass:
                                                          "material-icons loader pull-left",
                                                      },
                                                      [_vm._v("")]
                                                    )
                                                  : _vm._e(),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c("div", { staticClass: "pt-2" }, [
                                              _vm.card.settings &&
                                              _vm.card.settings.last_sync_user
                                                ? _c(
                                                    "small",
                                                    {
                                                      staticClass:
                                                        "p-0 m-0 block text-black-lt text-xs",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                                            Last user sync: "
                                                      ),
                                                      _c("b", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm._f(
                                                              "fixFullDateUTCRelative"
                                                            )(
                                                              _vm.card.settings
                                                                .last_sync_user
                                                            )
                                                          )
                                                        ),
                                                      ]),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ]),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ]
                        : _vm._e(),
                    ]
                  : _vm._e(),
                _vm._v(" "),
                [
                  "slack",
                  "hubspot",
                  "pipedrive",
                  "helpscout",
                  "zoho",
                  "guesty",
                  "gong",
                  "gohighlevel",
                ].includes(_vm.cardname) &&
                _vm.card.status == true &&
                _vm.card.settings.filters &&
                ((_vm.card.credentials.access_token &&
                  _vm.card.credentials.access_token !== "") ||
                  _vm.card.credentials.user_data ||
                  _vm.card.credentials.user ||
                  _vm.card.credentials.api_token)
                  ? [
                      _c("h5", { staticClass: "mt-4" }, [_vm._v("Filters")]),
                      _vm._v(" "),
                      _c("hr", { staticClass: "no-top-margin" }),
                      _vm._v(" "),
                      _c("div", { staticClass: "row form-group" }, [
                        _c(
                          "div",
                          { staticClass: "col-12" },
                          [
                            _c(
                              "el-timeline",
                              { staticClass: "ivr-timeline" },
                              [
                                _c(
                                  "el-timeline-item",
                                  {
                                    attrs: { placement: "top", size: "large" },
                                  },
                                  [
                                    _c(
                                      "template",
                                      { slot: "dot" },
                                      [
                                        _c("el-switch", {
                                          attrs: { "active-color": "#00a344" },
                                          model: {
                                            value:
                                              _vm.card.settings.filters.status,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.card.settings.filters,
                                                "status",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "card.settings.filters.status",
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c(
                                          "label",
                                          {
                                            staticClass:
                                              "el-form-item__label ml-2",
                                          },
                                          [_vm._v("Choose Events To Send")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-tooltip",
                                          {
                                            staticClass: "item",
                                            attrs: {
                                              effect: "dark",
                                              placement: "right",
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                attrs: { slot: "content" },
                                                slot: "content",
                                              },
                                              [
                                                _c("small", [
                                                  _vm._v(
                                                    "\n                                                    All\n                                                    " +
                                                      _vm._s(
                                                        _vm._f(
                                                          "fixIntegrationName"
                                                        )(
                                                          _vm._f("ucwords")(
                                                            _vm._f(
                                                              "prettifyCamelCase"
                                                            )(_vm.cardname)
                                                          )
                                                        )
                                                      ) +
                                                      " events will be enabled by default.\n                                                    "
                                                  ),
                                                  _c("br"),
                                                  _vm._v(
                                                    "\n                                                    Use this if you want to filter our specific events like direction, type and more.\n                                                    "
                                                  ),
                                                  _c("br"),
                                                  _vm._v(" "),
                                                  _c("br"),
                                                  _vm._v(" "),
                                                  _c("b", [_vm._v("Note:")]),
                                                  _vm._v(
                                                    " if you select a filter but no options for that filter are selected,\n                                                    "
                                                  ),
                                                  _c("br"),
                                                  _vm._v(
                                                    "\n                                                    no events will be sent.\n                                                "
                                                  ),
                                                ]),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "i",
                                              {
                                                staticClass:
                                                  "material-icons text-lg cursor-pointer text-blue p-0 ml-1",
                                              },
                                              [_vm._v("info_outline")]
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  2
                                ),
                                _vm._v(" "),
                                _vm.cardname == "pipedrive" &&
                                _vm.card.settings.filters.status
                                  ? _c(
                                      "el-timeline-item",
                                      {
                                        attrs: {
                                          placement: "top",
                                          size: "large",
                                        },
                                      },
                                      [
                                        _c(
                                          "template",
                                          { slot: "dot" },
                                          [
                                            _c("el-switch", {
                                              attrs: {
                                                "active-color": "#00a344",
                                              },
                                              model: {
                                                value:
                                                  _vm.card.settings.filters
                                                    .create_deal,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.card.settings.filters,
                                                    "create_deal",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "card.settings.filters.create_deal",
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c(
                                              "label",
                                              {
                                                staticClass:
                                                  "el-form-item__label ml-2",
                                              },
                                              [_vm._v("Create Deal")]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "el-tooltip",
                                              {
                                                staticClass: "item",
                                                attrs: {
                                                  effect: "dark",
                                                  placement: "right",
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    attrs: { slot: "content" },
                                                    slot: "content",
                                                  },
                                                  [
                                                    _c("small", [
                                                      _vm._v(
                                                        "\n                                                    Create deal when contact created or disposed\n                                                "
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "i",
                                                  {
                                                    staticClass:
                                                      "material-icons text-lg cursor-pointer text-blue p-0 ml-1",
                                                  },
                                                  [_vm._v("info_outline")]
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "transition",
                                          {
                                            attrs: {
                                              name: "el-fade-in-linear",
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                directives: [
                                                  {
                                                    name: "show",
                                                    rawName: "v-show",
                                                    value:
                                                      _vm.card.settings.filters
                                                        .create_deal,
                                                    expression:
                                                      "card.settings.filters.create_deal",
                                                  },
                                                ],
                                                staticClass: "mt-2",
                                              },
                                              [
                                                _c(
                                                  "el-radio-group",
                                                  {
                                                    attrs: { min: 1 },
                                                    on: {
                                                      change:
                                                        _vm.customPreValidateForm,
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.card.settings
                                                          .filters
                                                          .create_deal_when,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.card.settings
                                                            .filters,
                                                          "create_deal_when",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "card.settings.filters.create_deal_when",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "el-radio",
                                                      {
                                                        attrs: {
                                                          label: "created",
                                                          border: "",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          "Contact Created\n                                                "
                                                        ),
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "el-radio",
                                                      {
                                                        attrs: {
                                                          label: "disposed",
                                                          border: "",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          "Contact Disposed\n                                                "
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    attrs: {
                                                      prop: "settings.filters.create_deal_disposition_status_ids",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "disposition-status-selector",
                                                      {
                                                        directives: [
                                                          {
                                                            name: "show",
                                                            rawName: "v-show",
                                                            value:
                                                              _vm.card.settings
                                                                .filters
                                                                .create_deal_when ===
                                                              "disposed",
                                                            expression:
                                                              "card.settings.filters.create_deal_when === 'disposed'",
                                                          },
                                                        ],
                                                        attrs: {
                                                          select_style:
                                                            "width: 100%;",
                                                          multiple: "",
                                                          clearable: "",
                                                        },
                                                        on: {
                                                          change:
                                                            _vm.changeCreateDealWhenDisposed,
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.card.settings
                                                              .filters
                                                              .create_deal_disposition_status_ids,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.card.settings
                                                                .filters,
                                                              "create_deal_disposition_status_ids",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "card.settings.filters.create_deal_disposition_status_ids",
                                                        },
                                                      }
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                      ],
                                      2
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.card.settings.filters.status &&
                                _vm.cardname != "gohighlevel"
                                  ? _c(
                                      "el-timeline-item",
                                      {
                                        attrs: {
                                          placement: "top",
                                          type: "primary",
                                          size: "large",
                                        },
                                      },
                                      [
                                        _c(
                                          "template",
                                          { slot: "dot" },
                                          [
                                            _c("el-switch", {
                                              attrs: {
                                                "active-color": "#00a344",
                                              },
                                              model: {
                                                value:
                                                  _vm.card.settings.filters
                                                    .filter_direction,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.card.settings.filters,
                                                    "filter_direction",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "card.settings.filters.filter_direction",
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c(
                                              "label",
                                              {
                                                staticClass:
                                                  "el-form-item__label ml-2",
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                            Direction\n                                            "
                                                ),
                                                _vm.card.settings.filters
                                                  .filter_direction
                                                  ? _c(
                                                      "i",
                                                      {
                                                        staticClass:
                                                          "text-danger",
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                                                *\n                                            "
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "el-tooltip",
                                              {
                                                staticClass: "item",
                                                attrs: {
                                                  effect: "dark",
                                                  placement: "right",
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    attrs: { slot: "content" },
                                                    slot: "content",
                                                  },
                                                  [
                                                    _c("small", [
                                                      _vm._v(
                                                        "\n                                                    Filter by communication direction\n                                                "
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "i",
                                                  {
                                                    staticClass:
                                                      "material-icons text-lg cursor-pointer text-blue p-0 ml-1",
                                                  },
                                                  [_vm._v("info_outline")]
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "transition",
                                          {
                                            attrs: {
                                              name: "el-fade-in-linear",
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                directives: [
                                                  {
                                                    name: "show",
                                                    rawName: "v-show",
                                                    value:
                                                      _vm.card.settings.filters
                                                        .filter_direction,
                                                    expression:
                                                      "card.settings.filters.filter_direction",
                                                  },
                                                ],
                                                staticClass: "mt-2",
                                              },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    attrs: {
                                                      prop: "settings.filters.direction",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "el-checkbox-group",
                                                      {
                                                        on: {
                                                          change:
                                                            _vm.customPreValidateForm,
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.card.settings
                                                              .filters
                                                              .direction,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.card.settings
                                                                .filters,
                                                              "direction",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "card.settings.filters.direction",
                                                        },
                                                      },
                                                      _vm._l(
                                                        [
                                                          _vm
                                                            .CommunicationDirections
                                                            .INBOUND,
                                                          _vm
                                                            .CommunicationDirections
                                                            .OUTBOUND,
                                                        ],
                                                        function (direction) {
                                                          return _c(
                                                            "el-checkbox",
                                                            {
                                                              key: direction,
                                                              staticClass:
                                                                "mt-2 mx-1",
                                                              attrs: {
                                                                label:
                                                                  direction,
                                                                border: "",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                                                        " +
                                                                  _vm._s(
                                                                    _vm._f(
                                                                      "fixCommDirection"
                                                                    )(direction)
                                                                  ) +
                                                                  "\n                                                    "
                                                              ),
                                                            ]
                                                          )
                                                        }
                                                      ),
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                      ],
                                      2
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.card.settings.filters.status
                                  ? _c(
                                      "el-timeline-item",
                                      {
                                        attrs: {
                                          placement: "top",
                                          type: "primary",
                                          size: "large",
                                        },
                                      },
                                      [
                                        _c(
                                          "template",
                                          { slot: "dot" },
                                          [
                                            _c("el-switch", {
                                              attrs: {
                                                "active-color": "#00a344",
                                              },
                                              model: {
                                                value:
                                                  _vm.card.settings.filters
                                                    .filter_type,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.card.settings.filters,
                                                    "filter_type",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "card.settings.filters.filter_type",
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c(
                                              "label",
                                              {
                                                staticClass:
                                                  "el-form-item__label ml-2",
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                            Type\n                                            "
                                                ),
                                                _vm.card.settings.filters
                                                  .filter_type
                                                  ? _c(
                                                      "i",
                                                      {
                                                        staticClass:
                                                          "text-danger",
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                                                *\n                                            "
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "el-tooltip",
                                              {
                                                staticClass: "item",
                                                attrs: {
                                                  effect: "dark",
                                                  placement: "right",
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    attrs: { slot: "content" },
                                                    slot: "content",
                                                  },
                                                  [
                                                    _c("small", [
                                                      _vm._v(
                                                        "\n                                                    Filter by communication type\n                                                "
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "i",
                                                  {
                                                    staticClass:
                                                      "material-icons text-lg cursor-pointer text-blue p-0 ml-1",
                                                  },
                                                  [_vm._v("info_outline")]
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "transition",
                                          {
                                            attrs: {
                                              name: "el-fade-in-linear",
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                directives: [
                                                  {
                                                    name: "show",
                                                    rawName: "v-show",
                                                    value:
                                                      _vm.card.settings.filters
                                                        .filter_type,
                                                    expression:
                                                      "card.settings.filters.filter_type",
                                                  },
                                                ],
                                                staticClass: "mt-2",
                                              },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    attrs: {
                                                      prop: "settings.filters.type",
                                                    },
                                                  },
                                                  [
                                                    _vm.cardname !==
                                                    "gohighlevel"
                                                      ? _c(
                                                          "el-checkbox-group",
                                                          {
                                                            on: {
                                                              change:
                                                                _vm.customPreValidateForm,
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.card
                                                                  .settings
                                                                  .filters.type,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    _vm.card
                                                                      .settings
                                                                      .filters,
                                                                    "type",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "card.settings.filters.type",
                                                            },
                                                          },
                                                          _vm._l(
                                                            _vm.filteredCommunicationTypes,
                                                            function (type) {
                                                              return _c(
                                                                "el-checkbox",
                                                                {
                                                                  key: type,
                                                                  staticClass:
                                                                    "mt-2 mx-1",
                                                                  attrs: {
                                                                    label: type,
                                                                    border: "",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n                                                        " +
                                                                      _vm._s(
                                                                        _vm._f(
                                                                          "fixCommType"
                                                                        )(type)
                                                                      ) +
                                                                      "\n                                                    "
                                                                  ),
                                                                ]
                                                              )
                                                            }
                                                          ),
                                                          1
                                                        )
                                                      : _c(
                                                          "el-checkbox-group",
                                                          {
                                                            on: {
                                                              change:
                                                                _vm.customPreValidateForm,
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.card
                                                                  .settings
                                                                  .filters.type,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    _vm.card
                                                                      .settings
                                                                      .filters,
                                                                    "type",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "card.settings.filters.type",
                                                            },
                                                          },
                                                          _vm._l(
                                                            _vm.filteredCommunicationTypesForGHL,
                                                            function (type) {
                                                              return _c(
                                                                "el-checkbox",
                                                                {
                                                                  key: type,
                                                                  staticClass:
                                                                    "mt-2 mx-1",
                                                                  attrs: {
                                                                    label: type,
                                                                    border: "",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n                                                        " +
                                                                      _vm._s(
                                                                        _vm._f(
                                                                          "fixCommType"
                                                                        )(type)
                                                                      ) +
                                                                      "\n                                                    "
                                                                  ),
                                                                ]
                                                              )
                                                            }
                                                          ),
                                                          1
                                                        ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                      ],
                                      2
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.card.settings.filters.status &&
                                _vm.cardname != "gohighlevel"
                                  ? _c(
                                      "el-timeline-item",
                                      {
                                        attrs: {
                                          placement: "top",
                                          type: "primary",
                                          size: "large",
                                        },
                                      },
                                      [
                                        _c(
                                          "template",
                                          { slot: "dot" },
                                          [
                                            _c("el-switch", {
                                              attrs: {
                                                "active-color": "#00a344",
                                              },
                                              model: {
                                                value:
                                                  _vm.card.settings.filters
                                                    .filter_communication_disposition_status,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.card.settings.filters,
                                                    "filter_communication_disposition_status",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "card.settings.filters.filter_communication_disposition_status",
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c(
                                              "label",
                                              {
                                                staticClass:
                                                  "el-form-item__label ml-2",
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                            Communication Disposition Status\n                                            "
                                                ),
                                                _vm.card.settings.filters
                                                  .filter_communication_disposition_status
                                                  ? _c(
                                                      "i",
                                                      {
                                                        staticClass:
                                                          "text-danger",
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                                                *\n                                            "
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "el-tooltip",
                                              {
                                                staticClass: "item",
                                                attrs: {
                                                  effect: "dark",
                                                  placement: "right",
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    attrs: { slot: "content" },
                                                    slot: "content",
                                                  },
                                                  [
                                                    _c("small", [
                                                      _vm._v(
                                                        "\n                                                    Filter by communication disposition status\n                                                "
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "i",
                                                  {
                                                    staticClass:
                                                      "material-icons text-lg cursor-pointer text-blue p-0 ml-1",
                                                  },
                                                  [_vm._v("info_outline")]
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "transition",
                                          {
                                            attrs: {
                                              name: "el-fade-in-linear",
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                directives: [
                                                  {
                                                    name: "show",
                                                    rawName: "v-show",
                                                    value:
                                                      _vm.card.settings.filters
                                                        .filter_communication_disposition_status,
                                                    expression:
                                                      "card.settings.filters.filter_communication_disposition_status",
                                                  },
                                                ],
                                                staticClass: "mt-2",
                                              },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    attrs: {
                                                      prop: "settings.filters.communication_disposition_statuses",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "el-checkbox-group",
                                                      {
                                                        on: {
                                                          change:
                                                            _vm.customPreValidateForm,
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.card.settings
                                                              .filters
                                                              .communication_disposition_statuses,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.card.settings
                                                                .filters,
                                                              "communication_disposition_statuses",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "card.settings.filters.communication_disposition_statuses",
                                                        },
                                                      },
                                                      _vm._l(
                                                        _vm.filteredCommunicationDispositionStatuses,
                                                        function (status) {
                                                          return _c(
                                                            "el-checkbox",
                                                            {
                                                              key: status,
                                                              staticClass:
                                                                "mt-2 mx-1",
                                                              attrs: {
                                                                label: status,
                                                                border: "",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                                                        " +
                                                                  _vm._s(
                                                                    _vm._f(
                                                                      "fixName"
                                                                    )(
                                                                      _vm._f(
                                                                        "translateDispositionStatusText"
                                                                      )(status)
                                                                    )
                                                                  ) +
                                                                  "\n                                                    "
                                                              ),
                                                            ]
                                                          )
                                                        }
                                                      ),
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                      ],
                                      2
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.card.settings.filters.status &&
                                _vm.cardname != "gohighlevel"
                                  ? _c(
                                      "el-timeline-item",
                                      {
                                        attrs: {
                                          placement: "top",
                                          type: "primary",
                                          size: "large",
                                        },
                                      },
                                      [
                                        _c(
                                          "template",
                                          { slot: "dot" },
                                          [
                                            _c("el-switch", {
                                              attrs: {
                                                "active-color": "#00a344",
                                              },
                                              model: {
                                                value:
                                                  _vm.card.settings.filters
                                                    .filter_disposed_contacts,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.card.settings.filters,
                                                    "filter_disposed_contacts",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "card.settings.filters.filter_disposed_contacts",
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c(
                                              "label",
                                              {
                                                staticClass:
                                                  "el-form-item__label ml-2",
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                            Contacts\n                                            "
                                                ),
                                                _vm.card.settings.filters
                                                  .filter_disposed_contacts
                                                  ? _c(
                                                      "i",
                                                      {
                                                        staticClass:
                                                          "text-danger",
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                                                *\n                                            "
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "el-tooltip",
                                              {
                                                staticClass: "item",
                                                attrs: {
                                                  effect: "dark",
                                                  placement: "right",
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    attrs: { slot: "content" },
                                                    slot: "content",
                                                  },
                                                  [
                                                    _c("small", [
                                                      _vm._v(
                                                        "\n                                                    Filter by contact type\n                                                "
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "i",
                                                  {
                                                    staticClass:
                                                      "material-icons text-lg cursor-pointer text-blue p-0 ml-1",
                                                  },
                                                  [_vm._v("info_outline")]
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "transition",
                                          {
                                            attrs: {
                                              name: "el-fade-in-linear",
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                directives: [
                                                  {
                                                    name: "show",
                                                    rawName: "v-show",
                                                    value:
                                                      _vm.card.settings.filters
                                                        .filter_disposed_contacts,
                                                    expression:
                                                      "card.settings.filters.filter_disposed_contacts",
                                                  },
                                                ],
                                                staticClass: "mt-2",
                                              },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  [
                                                    _c(
                                                      "el-radio-group",
                                                      {
                                                        on: {
                                                          change:
                                                            _vm.customPreValidateForm,
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.card.settings
                                                              .filters.contacts,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.card.settings
                                                                .filters,
                                                              "contacts",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "card.settings.filters.contacts",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "el-radio",
                                                          {
                                                            attrs: {
                                                              label: "all",
                                                              border: "",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                                                        All\n                                                    "
                                                            ),
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "el-radio",
                                                          {
                                                            attrs: {
                                                              label:
                                                                "disposed_contacts",
                                                              border: "",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                                                        Disposed contacts\n                                                    "
                                                            ),
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "el-radio",
                                                          {
                                                            attrs: {
                                                              label:
                                                                "selected_disposition_statuses",
                                                              border: "",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                                                        Selected dispositions\n                                                    "
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    attrs: {
                                                      prop: "settings.filters.disposition_status_ids",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "disposition-status-selector",
                                                      {
                                                        directives: [
                                                          {
                                                            name: "show",
                                                            rawName: "v-show",
                                                            value:
                                                              _vm.card.settings
                                                                .filters
                                                                .contacts ===
                                                              "selected_disposition_statuses",
                                                            expression:
                                                              "card.settings.filters.contacts === 'selected_disposition_statuses'",
                                                          },
                                                        ],
                                                        attrs: {
                                                          select_style:
                                                            "width: 100%;",
                                                          multiple: "",
                                                          clearable: "",
                                                        },
                                                        on: {
                                                          change:
                                                            _vm.changeSyncContactsWithDisposition,
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.card.settings
                                                              .filters
                                                              .disposition_status_ids,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.card.settings
                                                                .filters,
                                                              "disposition_status_ids",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "card.settings.filters.disposition_status_ids",
                                                        },
                                                      }
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                      ],
                                      2
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.card.settings.filters.status &&
                                _vm.cardname != "gohighlevel"
                                  ? _c(
                                      "el-timeline-item",
                                      {
                                        attrs: {
                                          placement: "top",
                                          type: "primary",
                                          size: "large",
                                        },
                                      },
                                      [
                                        _c(
                                          "template",
                                          { slot: "dot" },
                                          [
                                            _c("el-switch", {
                                              attrs: {
                                                "active-color": "#00a344",
                                              },
                                              model: {
                                                value:
                                                  _vm.card.settings.filters
                                                    .filter_lines,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.card.settings.filters,
                                                    "filter_lines",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "card.settings.filters.filter_lines",
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c(
                                              "label",
                                              {
                                                staticClass:
                                                  "el-form-item__label ml-2",
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                            Skip lines\n                                            "
                                                ),
                                                _vm.card.settings.filters
                                                  .filter_lines
                                                  ? _c(
                                                      "i",
                                                      {
                                                        staticClass:
                                                          "text-danger",
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                                                *\n                                            "
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "el-tooltip",
                                              {
                                                staticClass: "item",
                                                attrs: {
                                                  effect: "dark",
                                                  placement: "right",
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    attrs: { slot: "content" },
                                                    slot: "content",
                                                  },
                                                  [
                                                    _c("small", [
                                                      _vm._v(
                                                        "\n                                                    Here you can exclude the lines "
                                                      ),
                                                      _c("br"),
                                                      _vm._v(
                                                        " you don't want to send to\n                                                    this integration\n                                                "
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "i",
                                                  {
                                                    staticClass:
                                                      "material-icons text-lg cursor-pointer text-blue p-0 ml-1",
                                                  },
                                                  [_vm._v("info_outline")]
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "transition",
                                          {
                                            attrs: {
                                              name: "el-fade-in-linear",
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                directives: [
                                                  {
                                                    name: "show",
                                                    rawName: "v-show",
                                                    value:
                                                      _vm.card.settings.filters
                                                        .filter_lines,
                                                    expression:
                                                      "card.settings.filters.filter_lines",
                                                  },
                                                ],
                                                staticClass: "mt-2",
                                              },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    attrs: {
                                                      prop: "settings.filters.skip_line_ids",
                                                    },
                                                  },
                                                  [
                                                    _c("campaign-selector", {
                                                      attrs: {
                                                        select_style:
                                                          "width: 100%;",
                                                        multiple: "",
                                                      },
                                                      on: {
                                                        change:
                                                          _vm.changeSkipLines,
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.card.settings
                                                            .filters
                                                            .skip_line_ids,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.card.settings
                                                              .filters,
                                                            "skip_line_ids",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "card.settings.filters.skip_line_ids",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                      ],
                                      2
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.card.settings.filters.status &&
                                _vm.cardname != "gohighlevel"
                                  ? _c(
                                      "el-timeline-item",
                                      {
                                        attrs: {
                                          placement: "top",
                                          type: "primary",
                                          size: "large",
                                        },
                                      },
                                      [
                                        _c(
                                          "template",
                                          { slot: "dot" },
                                          [
                                            _c("el-switch", {
                                              attrs: {
                                                "active-color": "#00a344",
                                              },
                                              model: {
                                                value:
                                                  _vm.card.settings.filters
                                                    .filter_call_duration,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.card.settings.filters,
                                                    "filter_call_duration",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "card.settings.filters.filter_call_duration",
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c(
                                              "label",
                                              {
                                                staticClass:
                                                  "el-form-item__label ml-2",
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                            Duration\n                                            "
                                                ),
                                                _vm.card.settings.filters
                                                  .filter_call_duration
                                                  ? _c(
                                                      "i",
                                                      {
                                                        staticClass:
                                                          "text-danger",
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                                                *\n                                            "
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "el-tooltip",
                                              {
                                                staticClass: "item",
                                                attrs: {
                                                  effect: "dark",
                                                  placement: "right",
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    attrs: { slot: "content" },
                                                    slot: "content",
                                                  },
                                                  [
                                                    _c("small", [
                                                      _vm._v(
                                                        "\n                                                    Filter by call talk-time/duration\n                                                "
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "i",
                                                  {
                                                    staticClass:
                                                      "material-icons text-lg cursor-pointer text-blue p-0 ml-1",
                                                  },
                                                  [_vm._v("info_outline")]
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "transition",
                                          {
                                            attrs: {
                                              name: "el-fade-in-linear",
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                directives: [
                                                  {
                                                    name: "show",
                                                    rawName: "v-show",
                                                    value:
                                                      _vm.card.settings.filters
                                                        .filter_call_duration,
                                                    expression:
                                                      "card.settings.filters.filter_call_duration",
                                                  },
                                                ],
                                                staticClass: "mt-2",
                                              },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    staticStyle: {
                                                      display: "inline-block",
                                                    },
                                                    attrs: {
                                                      prop: "settings.filters.call_duration_type",
                                                    },
                                                  },
                                                  [
                                                    _c("span", [
                                                      _vm._v(
                                                        "Don’t send calls with"
                                                      ),
                                                    ]),
                                                    _vm._v(" "),
                                                    _c(
                                                      "el-select",
                                                      {
                                                        attrs: {
                                                          size: "mini",
                                                          placeholder:
                                                            "Select Duration Type",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.card.settings
                                                              .filters
                                                              .call_duration_type,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.card.settings
                                                                .filters,
                                                              "call_duration_type",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "card.settings.filters.call_duration_type",
                                                        },
                                                      },
                                                      _vm._l(
                                                        _vm.callTypeOptions,
                                                        function (item) {
                                                          return _c(
                                                            "el-option",
                                                            {
                                                              key: item.value,
                                                              attrs: {
                                                                label:
                                                                  item.label,
                                                                value:
                                                                  item.value,
                                                              },
                                                            }
                                                          )
                                                        }
                                                      ),
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _vm._v(" "),
                                                _c("span", [
                                                  _vm._v("less than"),
                                                ]),
                                                _vm._v(" "),
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    staticStyle: {
                                                      display: "inline-block",
                                                    },
                                                    attrs: {
                                                      prop: "settings.filters.call_duration",
                                                    },
                                                  },
                                                  [
                                                    _c("el-input", {
                                                      attrs: {
                                                        size: "mini",
                                                        placeholder: "Duration",
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.card.settings
                                                            .filters
                                                            .call_duration,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.card.settings
                                                              .filters,
                                                            "call_duration",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "card.settings.filters.call_duration",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                                _vm._v(" "),
                                                _c("span", [_vm._v("minutes")]),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                      ],
                                      2
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.card.settings.filters.status &&
                                _vm.cardname != "gohighlevel"
                                  ? _c(
                                      "el-timeline-item",
                                      {
                                        attrs: {
                                          placement: "top",
                                          type: "primary",
                                          size: "large",
                                        },
                                      },
                                      [
                                        _c(
                                          "template",
                                          { slot: "dot" },
                                          [
                                            _c("el-switch", {
                                              attrs: {
                                                "active-color": "#00a344",
                                              },
                                              model: {
                                                value:
                                                  _vm.card.settings.filters
                                                    .filter_qualified_only,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.card.settings.filters,
                                                    "filter_qualified_only",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "card.settings.filters.filter_qualified_only",
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c(
                                              "label",
                                              {
                                                staticClass:
                                                  "el-form-item__label ml-2",
                                              },
                                              [_vm._v("Qualified Contacts")]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "el-tooltip",
                                              {
                                                staticClass: "item",
                                                attrs: {
                                                  effect: "dark",
                                                  placement: "right",
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    attrs: { slot: "content" },
                                                    slot: "content",
                                                  },
                                                  [
                                                    _c("small", [
                                                      _vm._v(
                                                        "\n                                                    Only create contacts when a qualified communication passes the\n                                                    filters\n                                                "
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "i",
                                                  {
                                                    staticClass:
                                                      "material-icons text-lg cursor-pointer text-blue p-0 ml-1",
                                                  },
                                                  [_vm._v("info_outline")]
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      2
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                [
                                  "hubspot",
                                  "zoho",
                                  "pipedrive",
                                  "gohighlevel",
                                ].includes(_vm.cardname)
                                  ? _c(
                                      "el-timeline-item",
                                      {
                                        attrs: {
                                          placement: "top",
                                          type: "primary",
                                          size: "large",
                                        },
                                      },
                                      [
                                        _c(
                                          "template",
                                          { slot: "dot" },
                                          [
                                            _c("el-switch", {
                                              attrs: {
                                                "active-color": "#00a344",
                                              },
                                              model: {
                                                value:
                                                  _vm.card.settings.filters
                                                    .filter_never_create_new_contact,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.card.settings.filters,
                                                    "filter_never_create_new_contact",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "card.settings.filters.filter_never_create_new_contact",
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c(
                                              "label",
                                              {
                                                staticClass:
                                                  "el-form-item__label ml-2",
                                              },
                                              [
                                                _vm._v(
                                                  "Don't create new contacts"
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "el-tooltip",
                                              {
                                                staticClass: "item",
                                                attrs: {
                                                  effect: "dark",
                                                  placement: "right",
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    attrs: { slot: "content" },
                                                    slot: "content",
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                                                When new contacts are created in Aloware,\n                                                "
                                                    ),
                                                    _c("br"),
                                                    _vm._v(
                                                      "\n                                                " +
                                                        _vm._s(
                                                          _vm._f(
                                                            "fixIntegrationName"
                                                          )(
                                                            _vm._f("ucwords")(
                                                              _vm._f(
                                                                "prettifyCamelCase"
                                                              )(_vm.cardname)
                                                            )
                                                          )
                                                        ) +
                                                        "\n                                                will not reflect that new contact.\n                                                "
                                                    ),
                                                    _c("br"),
                                                    _vm._v(
                                                      "\n                                                This option will only update the contacts that are already in\n                                                " +
                                                        _vm._s(
                                                          _vm._f(
                                                            "fixIntegrationName"
                                                          )(
                                                            _vm._f("ucwords")(
                                                              _vm._f(
                                                                "prettifyCamelCase"
                                                              )(_vm.cardname)
                                                            )
                                                          )
                                                        ) +
                                                        ".\n                                            "
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "i",
                                                  {
                                                    staticClass:
                                                      "material-icons text-lg cursor-pointer text-blue p-0 ml-1",
                                                  },
                                                  [_vm._v("info_outline")]
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      2
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                [
                                  "hubspot",
                                  "zoho",
                                  "pipedrive",
                                  "gohighlevel",
                                ].includes(_vm.cardname)
                                  ? _c(
                                      "el-timeline-item",
                                      {
                                        attrs: {
                                          placement: "top",
                                          type: "primary",
                                          size: "large",
                                        },
                                      },
                                      [
                                        _c(
                                          "template",
                                          { slot: "dot" },
                                          [
                                            _c("el-switch", {
                                              attrs: {
                                                "active-color": "#00a344",
                                              },
                                              model: {
                                                value:
                                                  _vm.card.settings.filters
                                                    .filter_never_update_contact,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.card.settings.filters,
                                                    "filter_never_update_contact",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "card.settings.filters.filter_never_update_contact",
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c(
                                              "label",
                                              {
                                                staticClass:
                                                  "el-form-item__label ml-2",
                                              },
                                              [
                                                _vm._v(
                                                  "Don't update contact information"
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "el-tooltip",
                                              {
                                                staticClass: "item",
                                                attrs: {
                                                  effect: "dark",
                                                  placement: "right",
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    attrs: { slot: "content" },
                                                    slot: "content",
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                                                Changes to contact information in Aloware will not update your\n                                                "
                                                    ),
                                                    _c("br"),
                                                    _vm._v(
                                                      "\n                                                " +
                                                        _vm._s(
                                                          _vm._f(
                                                            "fixIntegrationName"
                                                          )(
                                                            _vm._f("ucwords")(
                                                              _vm._f(
                                                                "prettifyCamelCase"
                                                              )(_vm.cardname)
                                                            )
                                                          )
                                                        ) +
                                                        "\n                                                contact details\n                                            "
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "i",
                                                  {
                                                    staticClass:
                                                      "material-icons text-lg cursor-pointer text-blue p-0 ml-1",
                                                  },
                                                  [_vm._v("info_outline")]
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      2
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                ["hubspot"].includes(_vm.cardname)
                                  ? _c(
                                      "el-timeline-item",
                                      {
                                        attrs: {
                                          placement: "top",
                                          type: "primary",
                                          size: "large",
                                        },
                                      },
                                      [
                                        _c(
                                          "template",
                                          { slot: "dot" },
                                          [
                                            _c("el-switch", {
                                              attrs: {
                                                "active-color": "#00a344",
                                              },
                                              model: {
                                                value:
                                                  _vm.card.settings.filters
                                                    .filter_never_update_contact_dispositions,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.card.settings.filters,
                                                    "filter_never_update_contact_dispositions",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "card.settings.filters.filter_never_update_contact_dispositions",
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c(
                                              "label",
                                              {
                                                staticClass:
                                                  "el-form-item__label ml-2",
                                              },
                                              [
                                                _vm._v(
                                                  "Don't update contact's lead status"
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "el-tooltip",
                                              {
                                                staticClass: "item",
                                                attrs: {
                                                  effect: "dark",
                                                  placement: "right",
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    attrs: { slot: "content" },
                                                    slot: "content",
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                                                Changes to contact lead status in Aloware will not update your\n                                                "
                                                    ),
                                                    _c("br"),
                                                    _vm._v(
                                                      "\n                                                " +
                                                        _vm._s(
                                                          _vm._f(
                                                            "fixIntegrationName"
                                                          )(
                                                            _vm._f("ucwords")(
                                                              _vm._f(
                                                                "prettifyCamelCase"
                                                              )(_vm.cardname)
                                                            )
                                                          )
                                                        ) +
                                                        "\n                                                lead status\n                                            "
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "i",
                                                  {
                                                    staticClass:
                                                      "material-icons text-lg cursor-pointer text-blue p-0 ml-1",
                                                  },
                                                  [_vm._v("info_outline")]
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      2
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                [
                                  "hubspot",
                                  "zoho",
                                  "pipedrive",
                                  "gohighlevel",
                                ].includes(_vm.cardname)
                                  ? _c(
                                      "el-timeline-item",
                                      {
                                        attrs: {
                                          placement: "top",
                                          type: "primary",
                                          size: "large",
                                        },
                                      },
                                      [
                                        _c(
                                          "template",
                                          { slot: "dot" },
                                          [
                                            _c(
                                              "el-tooltip",
                                              {
                                                staticClass: "item",
                                                attrs: {
                                                  effect: "dark",
                                                  placement: "top-start",
                                                  disabled:
                                                    _vm.cardname ===
                                                    "gohighlevel"
                                                      ? !!_vm.card.credentials
                                                          .company_id
                                                      : true,
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    attrs: { slot: "content" },
                                                    slot: "content",
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                                                You need to re-authenticate the integration before you can start using this feature.\n                                            "
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c("el-switch", {
                                                  attrs: {
                                                    "active-color": "#00a344",
                                                    disabled:
                                                      _vm.cardname ===
                                                      "gohighlevel"
                                                        ? !_vm.card.credentials
                                                            .company_id
                                                        : false,
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.card.settings.filters
                                                        .filter_never_update_contact_owners,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.card.settings
                                                          .filters,
                                                        "filter_never_update_contact_owners",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "card.settings.filters.filter_never_update_contact_owners",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "label",
                                              {
                                                staticClass:
                                                  "el-form-item__label ml-2",
                                              },
                                              [
                                                _vm._v(
                                                  "Don't update contact owners"
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "el-tooltip",
                                              {
                                                staticClass: "item",
                                                attrs: {
                                                  effect: "dark",
                                                  placement: "right",
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    attrs: { slot: "content" },
                                                    slot: "content",
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                                                Changes to contact ownership in Aloware\n                                                "
                                                    ),
                                                    _c("br"),
                                                    _vm._v(
                                                      "\n                                                will not update the owner in\n                                                " +
                                                        _vm._s(
                                                          _vm._f(
                                                            "fixIntegrationName"
                                                          )(
                                                            _vm._f("ucwords")(
                                                              _vm._f(
                                                                "prettifyCamelCase"
                                                              )(_vm.cardname)
                                                            )
                                                          )
                                                        ) +
                                                        "\n                                            "
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "i",
                                                  {
                                                    staticClass:
                                                      "material-icons text-lg cursor-pointer text-blue p-0 ml-1",
                                                  },
                                                  [_vm._v("info_outline")]
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      2
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.cardname == "zoho"
                                  ? _c(
                                      "el-timeline-item",
                                      {
                                        attrs: {
                                          placement: "top",
                                          type: "primary",
                                          size: "large",
                                        },
                                      },
                                      [
                                        _c(
                                          "template",
                                          { slot: "dot" },
                                          [
                                            _c("el-switch", {
                                              attrs: {
                                                "active-color": "#00a344",
                                              },
                                              model: {
                                                value:
                                                  _vm.card.settings.filters
                                                    .filter_only_update_empty_fields,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.card.settings.filters,
                                                    "filter_only_update_empty_fields",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "card.settings.filters.filter_only_update_empty_fields",
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c(
                                              "label",
                                              {
                                                staticClass:
                                                  "el-form-item__label ml-2",
                                              },
                                              [
                                                _vm._v(
                                                  "Only update empty fields"
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "el-tooltip",
                                              {
                                                staticClass: "item",
                                                attrs: {
                                                  effect: "dark",
                                                  placement: "right",
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    attrs: { slot: "content" },
                                                    slot: "content",
                                                  },
                                                  [
                                                    _c("small", [
                                                      _vm._v(
                                                        "\n                                                    Only update first name, last name, phone, email, website &\n                                                    company from Aloware if the data\n                                                    in " +
                                                          _vm._s(
                                                            _vm._f(
                                                              "fixIntegrationName"
                                                            )(
                                                              _vm._f("ucwords")(
                                                                _vm._f(
                                                                  "prettifyCamelCase"
                                                                )(_vm.cardname)
                                                              )
                                                            )
                                                          ) +
                                                          " is empty.\n                                                "
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "i",
                                                  {
                                                    staticClass:
                                                      "material-icons text-lg cursor-pointer text-blue p-0 ml-1",
                                                  },
                                                  [_vm._v("info_outline")]
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      2
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                    ]
                  : _vm._e(),
                _vm._v(" "),
                _vm.cardname === "hubspot" &&
                _vm.card.credentials.access_token !== ""
                  ? [
                      _c(
                        "h5",
                        { staticClass: "mt-4" },
                        [
                          _vm._v(
                            "\n                        Connection Information\n                        "
                          ),
                          _c(
                            "el-tooltip",
                            {
                              staticClass: "item",
                              attrs: { effect: "dark", placement: "right" },
                            },
                            [
                              _c(
                                "div",
                                { attrs: { slot: "content" }, slot: "content" },
                                [
                                  _vm._v(
                                    "\n                                This will show the HubSpot account that has been integrated with " +
                                      _vm._s(_vm.statics.name) +
                                      ".\n                            "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "i",
                                {
                                  staticClass:
                                    "material-icons text-lg cursor-pointer text-blue p-0 ml-1",
                                },
                                [_vm._v("info_outline")]
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("hr", { staticClass: "no-top-margin" }),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "mb-4" },
                        [
                          _vm.card.credentials.user &&
                          _vm.card.credentials.hub_domain &&
                          _vm.card.credentials.hub_id
                            ? [
                                _c("b", [_vm._v("Email")]),
                                _vm._v(
                                  ": " +
                                    _vm._s(_vm.card.credentials.user) +
                                    "\n                            "
                                ),
                                _c("br"),
                                _vm._v(" "),
                                _c("b", [_vm._v("Account Name")]),
                                _vm._v(
                                  ": " +
                                    _vm._s(_vm.card.credentials.hub_domain) +
                                    "\n                            "
                                ),
                                _c("br"),
                                _vm._v(" "),
                                _c("b", [_vm._v("Account ID")]),
                                _vm._v(
                                  ": " +
                                    _vm._s(_vm.card.credentials.hub_id) +
                                    "\n                        "
                                ),
                              ]
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.card.credentials.scopes
                            ? [
                                _c("br"),
                                _vm._v(" "),
                                _c("strong", [_vm._v("Scopes")]),
                                _vm._v(" "),
                                _c("br"),
                                _vm._v(" "),
                                _c("code", [
                                  _vm._v(_vm._s(_vm.card.credentials.scopes)),
                                ]),
                              ]
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.card.credentials.user
                            ? [
                                _c("br"),
                                _vm._v(" "),
                                _c("strong", [_vm._v("UI Domain")]),
                                _vm._v(":\n                            "),
                                _vm.card.credentials.uiDomain
                                  ? _c("span", [
                                      _vm._v(
                                        "\n                                " +
                                          _vm._s(
                                            _vm.card.credentials.uiDomain
                                          ) +
                                          "\n                            "
                                      ),
                                    ])
                                  : _c("span", [
                                      _vm._v(
                                        "\n                                Please re-authenticate to fetch from HubSpot (default: app.hubspot.com)\n                            "
                                      ),
                                    ]),
                              ]
                            : _vm._e(),
                        ],
                        2
                      ),
                    ]
                  : _vm._e(),
                _vm._v(" "),
                _vm.cardname === "zoho" &&
                _vm.card.credentials.access_token !== ""
                  ? [
                      _c(
                        "h5",
                        { staticClass: "mt-4" },
                        [
                          _vm._v(
                            "\n                        Connection Information\n                        "
                          ),
                          _c(
                            "el-tooltip",
                            {
                              staticClass: "item",
                              attrs: { effect: "dark", placement: "right" },
                            },
                            [
                              _c(
                                "div",
                                { attrs: { slot: "content" }, slot: "content" },
                                [
                                  _vm._v(
                                    "\n                                This will show the Zoho account that has been integrated with " +
                                      _vm._s(_vm.statics.name) +
                                      ".\n                            "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "i",
                                {
                                  staticClass:
                                    "material-icons text-lg cursor-pointer text-blue p-0 ml-1",
                                },
                                [_vm._v("info_outline")]
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("hr", { staticClass: "no-top-margin" }),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "mb-4" },
                        [
                          _vm.card.credentials.org_id &&
                          _vm.card.credentials.accounts_server &&
                          _vm.card.credentials.api_domain
                            ? [
                                _c("strong", [_vm._v("Zoho Account")]),
                                _vm._v(" "),
                                _c("br"),
                                _vm._v(" "),
                                _c("b", [_vm._v("Org id:")]),
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.card.credentials.org_id) +
                                    "\n                            "
                                ),
                                _c("br"),
                                _vm._v(" "),
                                _c("b", [_vm._v("Accounts server:")]),
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.card.credentials.accounts_server
                                    ) +
                                    "\n                            "
                                ),
                                _c("br"),
                                _vm._v(" "),
                                _c("b", [_vm._v("API domain:")]),
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.card.credentials.api_domain) +
                                    "\n                        "
                                ),
                              ]
                            : _vm._e(),
                        ],
                        2
                      ),
                    ]
                  : _vm._e(),
                _vm._v(" "),
                _vm.cardname === "pipedrive" &&
                _vm.card.credentials.access_token !== ""
                  ? [
                      _c(
                        "h5",
                        { staticClass: "mt-4" },
                        [
                          _vm._v(
                            "\n                        Connection Information\n                        "
                          ),
                          _c(
                            "el-tooltip",
                            {
                              staticClass: "item",
                              attrs: { effect: "dark", placement: "right" },
                            },
                            [
                              _c(
                                "div",
                                { attrs: { slot: "content" }, slot: "content" },
                                [
                                  _vm._v(
                                    "\n                                This will show the Pipedrive account that has been integrated with " +
                                      _vm._s(_vm.statics.name) +
                                      ".\n                            "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "i",
                                {
                                  staticClass:
                                    "material-icons text-lg cursor-pointer text-blue p-0 ml-1",
                                },
                                [_vm._v("info_outline")]
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("hr", { staticClass: "no-top-margin" }),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "mb-4" },
                        [
                          _vm.card.credentials.user_data &&
                          _vm.card.credentials.user_data.company_name
                            ? [
                                _c("strong", [_vm._v("Company Name")]),
                                _vm._v(
                                  ": " +
                                    _vm._s(
                                      _vm.card.credentials.user_data
                                        .company_name
                                    ) +
                                    "\n                        "
                                ),
                              ]
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.card.credentials.user_data &&
                          _vm.card.credentials.user_data.company_domain
                            ? [
                                _c("br"),
                                _vm._v(" "),
                                _c("strong", [_vm._v("Company Domain")]),
                                _vm._v(
                                  ": " +
                                    _vm._s(
                                      _vm.card.credentials.user_data
                                        .company_domain
                                    ) +
                                    "\n                        "
                                ),
                              ]
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.card.credentials.scope
                            ? [
                                _c("br"),
                                _vm._v(" "),
                                _c("strong", [_vm._v("Scopes")]),
                                _vm._v(": "),
                                _c("code", [
                                  _vm._v(_vm._s(_vm.card.credentials.scope)),
                                ]),
                              ]
                            : _vm._e(),
                        ],
                        2
                      ),
                    ]
                  : _vm._e(),
                _vm._v(" "),
                _vm.cardname === "helpscout" && _vm.card.credentials.user_data
                  ? [
                      _c(
                        "h5",
                        { staticClass: "mt-4" },
                        [
                          _vm._v(
                            "\n                        Connection Information\n                        "
                          ),
                          _c(
                            "el-tooltip",
                            {
                              staticClass: "item",
                              attrs: { effect: "dark", placement: "right" },
                            },
                            [
                              _c(
                                "div",
                                { attrs: { slot: "content" }, slot: "content" },
                                [
                                  _vm._v(
                                    "\n                                This will show the Help Scout account that has been integrated with " +
                                      _vm._s(_vm.statics.name) +
                                      ".\n                            "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "i",
                                {
                                  staticClass:
                                    "material-icons text-lg cursor-pointer text-blue p-0 ml-1",
                                },
                                [_vm._v("info_outline")]
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("hr", { staticClass: "no-top-margin" }),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "mb-4" },
                        [
                          _vm.card.credentials.user_data.firstName
                            ? [
                                _c("strong", [_vm._v("Name")]),
                                _vm._v(" "),
                                _c("br"),
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(
                                      _vm.card.credentials.user_data.firstName
                                    ) +
                                    " " +
                                    _vm._s(
                                      _vm.card.credentials.user_data.lastName
                                    ) +
                                    "\n                        "
                                ),
                              ]
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.card.credentials.user_data.email
                            ? [
                                _c("br"),
                                _vm._v(" "),
                                _c("strong", [_vm._v("Email")]),
                                _vm._v(" "),
                                _c("br"),
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(
                                      _vm.card.credentials.user_data.email
                                    ) +
                                    "\n                        "
                                ),
                              ]
                            : _vm._e(),
                        ],
                        2
                      ),
                    ]
                  : _vm._e(),
                _vm._v(" "),
                _vm.cardname === "gohighlevel" &&
                _vm.card.credentials.access_token
                  ? [
                      _c(
                        "h5",
                        { staticClass: "mt-4" },
                        [
                          _vm._v(
                            "\n                        Connection Information\n                        "
                          ),
                          _c(
                            "el-tooltip",
                            {
                              staticClass: "item",
                              attrs: { effect: "dark", placement: "right" },
                            },
                            [
                              _c(
                                "div",
                                { attrs: { slot: "content" }, slot: "content" },
                                [
                                  _vm._v(
                                    "\n                                This will show the GoHighLevel account that has been integrated with " +
                                      _vm._s(_vm.statics.name) +
                                      ".\n                            "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "i",
                                {
                                  staticClass:
                                    "material-icons text-lg cursor-pointer text-blue p-0 ml-1",
                                },
                                [_vm._v("info_outline")]
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("hr", { staticClass: "no-top-margin" }),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "mb-4" },
                        [
                          _vm.card.credentials.user_data.first_name
                            ? [
                                _c("strong", [_vm._v("User Name")]),
                                _vm._v(
                                  ": " +
                                    _vm._s(
                                      _vm.card.credentials.user_data.first_name
                                    ) +
                                    " " +
                                    _vm._s(
                                      _vm.card.credentials.user_data.last_name
                                    ) +
                                    "\n                        "
                                ),
                              ]
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.card.credentials.user_id
                            ? [
                                _c("br"),
                                _vm._v(" "),
                                _c("strong", [_vm._v("User ID")]),
                                _vm._v(
                                  ": " +
                                    _vm._s(_vm.card.credentials.user_id) +
                                    "\n                        "
                                ),
                              ]
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.card.credentials.location_id
                            ? [
                                _c("br"),
                                _vm._v(" "),
                                _c("strong", [_vm._v("Location ID")]),
                                _vm._v(
                                  ": " +
                                    _vm._s(_vm.card.credentials.location_id) +
                                    "\n                        "
                                ),
                              ]
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.card.credentials.company_id
                            ? [
                                _c("br"),
                                _vm._v(" "),
                                _c("strong", [_vm._v("Company ID")]),
                                _vm._v(
                                  ": " +
                                    _vm._s(_vm.card.credentials.company_id) +
                                    "\n                        "
                                ),
                              ]
                            : _vm._e(),
                        ],
                        2
                      ),
                    ]
                  : _vm._e(),
                _vm._v(" "),
                _vm.cardname === "facebook" && _vm.card.credentials.user
                  ? [
                      _c(
                        "h5",
                        { staticClass: "mt-4" },
                        [
                          _vm._v(
                            "\n                        Connection Information\n                        "
                          ),
                          _c(
                            "el-tooltip",
                            {
                              staticClass: "item",
                              attrs: { effect: "dark", placement: "right" },
                            },
                            [
                              _c(
                                "div",
                                { attrs: { slot: "content" }, slot: "content" },
                                [
                                  _vm._v(
                                    "\n                                This will show the Facebook account that has been integrated with " +
                                      _vm._s(_vm.statics.name) +
                                      ".\n                            "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "i",
                                {
                                  staticClass:
                                    "material-icons text-lg cursor-pointer text-blue p-0 ml-1",
                                },
                                [_vm._v("info_outline")]
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("hr", { staticClass: "no-top-margin" }),
                      _vm._v(" "),
                      _c("div", { staticClass: "mb-4" }, [
                        _c(
                          "div",
                          [
                            _c("el-avatar", {
                              attrs: {
                                src: _vm.card.credentials.user.avatar,
                                size: 60,
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("div", [
                          _c("strong", [
                            _vm._v(_vm._s(_vm.card.credentials.user.name)),
                          ]),
                          _vm._v(" "),
                          _c("br"),
                          _vm._v(" "),
                          _c("span", [
                            _vm._v(_vm._s(_vm.card.credentials.user.email)),
                          ]),
                        ]),
                      ]),
                    ]
                  : _vm._e(),
                _vm._v(" "),
                _vm.cardname === "slack" &&
                _vm.card.credentials.api_token &&
                _vm.card.credentials.api_token !== "" &&
                _vm.card.credentials.values
                  ? [
                      _c(
                        "h5",
                        { staticClass: "mt-4" },
                        [
                          _vm._v(
                            "\n                        Connection Information\n                        "
                          ),
                          _c(
                            "el-tooltip",
                            {
                              staticClass: "item",
                              attrs: { effect: "dark", placement: "right" },
                            },
                            [
                              _c(
                                "div",
                                { attrs: { slot: "content" }, slot: "content" },
                                [
                                  _vm._v(
                                    "\n                                This will show the Slack account that has been integrated with " +
                                      _vm._s(_vm.statics.name) +
                                      ".\n                            "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "i",
                                {
                                  staticClass:
                                    "material-icons text-lg cursor-pointer text-blue p-0 ml-1",
                                },
                                [_vm._v("info_outline")]
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("hr", { staticClass: "no-top-margin" }),
                      _vm._v(" "),
                      _c("div", { staticClass: "mb-4" }, [
                        _c("b", [_vm._v("Workspace:")]),
                        _vm._v(
                          " " + _vm._s(_vm.card.credentials.values.team_name)
                        ),
                        _c("br"),
                        _vm._v(" "),
                        _c("b", [_vm._v("Channel:")]),
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.card.credentials.values.incoming_webhook
                                .channel
                            ) +
                            "\n                    "
                        ),
                      ]),
                    ]
                  : _vm._e(),
                _vm._v(" "),
                _vm.cardname === "sendgrid" && _vm.card.credentials.user_data
                  ? [
                      _c(
                        "h5",
                        { staticClass: "my-4" },
                        [
                          _vm._v(
                            "\n                        Connection Information\n                        "
                          ),
                          _c(
                            "el-tooltip",
                            {
                              staticClass: "item",
                              attrs: { effect: "dark", placement: "right" },
                            },
                            [
                              _c(
                                "div",
                                { attrs: { slot: "content" }, slot: "content" },
                                [
                                  _vm._v(
                                    "\n                                This will show the Sendgrid account that has been integrated with " +
                                      _vm._s(_vm.statics.name) +
                                      ".\n                            "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "i",
                                {
                                  staticClass:
                                    "material-icons text-lg cursor-pointer text-blue p-0 ml-1",
                                },
                                [_vm._v("info_outline")]
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "mb-4" },
                        [
                          _vm.card.credentials.user_data.userid
                            ? [
                                _c("strong", [_vm._v("User ID")]),
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.card.credentials.user_data.userid
                                    ) +
                                    "\n                        "
                                ),
                              ]
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.card.credentials.user_data.first_name
                            ? [
                                _c("br"),
                                _vm._v(" "),
                                _c("strong", [_vm._v("First Name")]),
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.card.credentials.user_data.first_name
                                    ) +
                                    "\n                        "
                                ),
                              ]
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.card.credentials.user_data.last_name
                            ? [
                                _c("br"),
                                _vm._v(" "),
                                _c("strong", [_vm._v("Last Name")]),
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.card.credentials.user_data.last_name
                                    ) +
                                    "\n                        "
                                ),
                              ]
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.card.credentials.phone
                            ? [
                                _c("br"),
                                _vm._v(" "),
                                _c("strong", [_vm._v("Phone")]),
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.card.credentials.phone) +
                                    "\n                        "
                                ),
                              ]
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.card.credentials.user_data.website
                            ? [
                                _c("br"),
                                _vm._v(" "),
                                _c("strong", [_vm._v("Website")]),
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.card.credentials.user_data.website
                                    ) +
                                    "\n                        "
                                ),
                              ]
                            : _vm._e(),
                        ],
                        2
                      ),
                    ]
                  : _vm._e(),
                _vm._v(" "),
                _vm.cardname === "SMTP" &&
                !_vm.hasOtherEmailIntegrationEnabled(_vm.cardname)
                  ? [
                      _c("div", { staticClass: "row px-2" }, [
                        _c(
                          "div",
                          { staticClass: "col-6" },
                          [
                            _c("span", { staticClass: "h5" }, [
                              _vm._v("Server Address"),
                            ]),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              { attrs: { prop: "settings.address" } },
                              [
                                _c(
                                  "div",
                                  { staticClass: "input-group" },
                                  [
                                    _c("el-input", {
                                      attrs: { type: "text" },
                                      model: {
                                        value: _vm.card.settings.address,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.card.settings,
                                            "address",
                                            $$v
                                          )
                                        },
                                        expression: "card.settings.address",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "col-2" },
                          [
                            _c("span", { staticClass: "h5" }, [_vm._v("Port")]),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              { attrs: { prop: "settings.port" } },
                              [
                                _c(
                                  "div",
                                  { staticClass: "input-group" },
                                  [
                                    _c("el-input", {
                                      attrs: { type: "number" },
                                      on: { input: _vm.customPreValidateForm },
                                      model: {
                                        value: _vm.card.settings.port,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.card.settings,
                                            "port",
                                            $$v
                                          )
                                        },
                                        expression: "card.settings.port",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-4" }, [
                          _c(
                            "div",
                            [
                              _c("span", { staticClass: "h5" }, [
                                _vm._v("Encryption type"),
                              ]),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                {
                                  staticClass: "m-0",
                                  attrs: { prop: "settings.encryption" },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      model: {
                                        value: _vm.card.settings.encryption,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.card.settings,
                                            "encryption",
                                            $$v
                                          )
                                        },
                                        expression: "card.settings.encryption",
                                      },
                                    },
                                    _vm._l(
                                      _vm.smtp_encryption_types,
                                      function (item) {
                                        return _c("el-option", {
                                          key: item.value,
                                          attrs: {
                                            label: item.label,
                                            value: item.value,
                                          },
                                        })
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "row px-2 mt-3" }, [
                        _c(
                          "div",
                          { staticClass: "col-6" },
                          [
                            _c("span", { staticClass: "h5" }, [
                              _vm._v("Sender Address"),
                            ]),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              { attrs: { prop: "settings.sender_address" } },
                              [
                                _c(
                                  "div",
                                  { staticClass: "input-group" },
                                  [
                                    _c("el-input", {
                                      attrs: { type: "text" },
                                      on: { input: _vm.customPreValidateForm },
                                      model: {
                                        value: _vm.card.settings.sender_address,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.card.settings,
                                            "sender_address",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "card.settings.sender_address",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "col-6" },
                          [
                            _c("span", { staticClass: "h5" }, [
                              _vm._v("Sender Name"),
                            ]),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              { attrs: { prop: "settings.sender_name" } },
                              [
                                _c(
                                  "div",
                                  { staticClass: "input-group" },
                                  [
                                    _c("el-input", {
                                      attrs: { type: "email" },
                                      on: { input: _vm.customPreValidateForm },
                                      model: {
                                        value: _vm.card.settings.sender_name,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.card.settings,
                                            "sender_name",
                                            $$v
                                          )
                                        },
                                        expression: "card.settings.sender_name",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _c("h5", { staticClass: "mt-5" }, [
                        _vm._v("Credentials"),
                      ]),
                      _vm._v(" "),
                      _c("hr", { staticClass: "no-top-margin" }),
                      _vm._v(" "),
                      _c("div", { staticClass: "row px-2" }, [
                        _c(
                          "div",
                          { staticClass: "col-8" },
                          [
                            _c("span", { staticClass: "h5" }, [
                              _vm._v("Username"),
                            ]),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              { attrs: { prop: "credentials.username" } },
                              [
                                _c(
                                  "div",
                                  { staticClass: "input-group" },
                                  [
                                    _c("el-input", {
                                      attrs: { type: "text" },
                                      on: { input: _vm.customPreValidateForm },
                                      model: {
                                        value: _vm.card.credentials.username,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.card.credentials,
                                            "username",
                                            $$v
                                          )
                                        },
                                        expression: "card.credentials.username",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "row px-2 mt-3" }, [
                        _c(
                          "div",
                          { staticClass: "col-8" },
                          [
                            _c("span", { staticClass: "h5" }, [
                              _vm._v("Password"),
                            ]),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              {
                                staticClass: "m-0",
                                attrs: { prop: "credentials.password" },
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "input-group" },
                                  [
                                    _c("el-input", {
                                      attrs: { type: "password" },
                                      on: { input: _vm.customPreValidateForm },
                                      model: {
                                        value: _vm.card.credentials.password,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.card.credentials,
                                            "password",
                                            $$v
                                          )
                                        },
                                        expression: "card.credentials.password",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]),
                    ]
                  : _vm._e(),
              ],
              2
            ),
          ]),
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "pt-3 px-4" }, [
        _vm.isTestAndSaveButtonDisabled()
          ? _c(
              "div",
              { staticClass: "form-group text-right" },
              [
                _c(
                  "el-button",
                  {
                    staticClass: "pull-left",
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.goBack.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _c("i", { staticClass: "fa fa-arrow-left" }),
                    _vm._v("\n                Back\n            "),
                  ]
                ),
              ],
              1
            )
          : _c(
              "div",
              { staticClass: "form-group text-right" },
              [
                _c(
                  "el-button",
                  {
                    staticClass: "pull-left",
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.goBack.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _c("i", { staticClass: "fa fa-arrow-left" }),
                    _vm._v("\n                Back\n            "),
                  ]
                ),
                _vm._v(" "),
                _vm.cardname !== "salesforce"
                  ? _c(
                      "el-button",
                      {
                        staticClass: "right",
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.resetForm(_vm.cardname)
                          },
                        },
                      },
                      [_vm._v("\n                Reset\n            ")]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.cardname == "mailgun"
                  ? _c(
                      "el-button",
                      {
                        attrs: {
                          type: "primary",
                          loading: _vm.mailgun_testing_status,
                          disabled:
                            _vm.mailgun_testing_status ||
                            _vm.card.credentials.api_token == "",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.mailgunTestAndGetDomains(
                              _vm.card.credentials.api_token
                            )
                          },
                        },
                      },
                      [_vm._v("\n                Test\n            ")]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.cardname == "mailgun"
                  ? _c(
                      "el-button",
                      {
                        attrs: {
                          type: "success",
                          loading: _vm.saveLoading || _vm.testLoading,
                          disabled:
                            !_vm.validated ||
                            _vm.testLoading ||
                            _vm.apiTokenTestStatus !==
                              _vm.WebhookTestStatuses.SUCCESS,
                        },
                        on: {
                          click: function ($event) {
                            return _vm.testAndSave(_vm.cardname)
                          },
                        },
                      },
                      [_vm._v("\n                Save\n            ")]
                    )
                  : _vm.cardname == "sendgrid" && _vm.card.status == true
                  ? _c(
                      "el-button",
                      {
                        attrs: {
                          type: "success",
                          loading: _vm.saveLoading || _vm.testLoading,
                          disabled: !_vm.validated || _vm.testLoading,
                        },
                        on: {
                          click: function ($event) {
                            return _vm.testAndSave(_vm.cardname)
                          },
                        },
                      },
                      [_vm._v("\n                Test & Save\n            ")]
                    )
                  : _vm.cardname == "SMTP"
                  ? _c(
                      "el-button",
                      {
                        attrs: {
                          type: "success",
                          loading: _vm.saveLoading || _vm.testLoading,
                          disabled: !_vm.validated || _vm.testLoading,
                        },
                        on: {
                          click: function ($event) {
                            return _vm.testAndSave(_vm.cardname)
                          },
                        },
                      },
                      [_vm._v("\n                Test & Save\n            ")]
                    )
                  : _vm.cardname == "google-calendar"
                  ? _c(
                      "el-button",
                      {
                        attrs: {
                          type: "success",
                          loading: _vm.saveLoading,
                          disabled:
                            !_vm.validated || !_vm.card.credentials.token,
                        },
                        on: {
                          click: function ($event) {
                            return _vm.submit(_vm.cardname)
                          },
                        },
                      },
                      [_vm._v("\n                Save\n            ")]
                    )
                  : _c(
                      "el-button",
                      {
                        attrs: {
                          type: "success",
                          loading: _vm.saveLoading,
                          disabled:
                            !_vm.validated || _vm.cardname == "salesforce",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.submit(_vm.cardname)
                          },
                        },
                      },
                      [_vm._v("\n                Save\n            ")]
                    ),
              ],
              1
            ),
      ]),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            top: "4vh",
            width: "70vw",
            visible: _vm.show_prismatic_dialog,
            "append-to-body": "",
            title: _vm.prismaticModalConfigTitle,
          },
          on: {
            close: function ($event) {
              _vm.show_prismatic_dialog = false
            },
          },
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              staticClass: "height-600",
            },
            [
              _c("prismatic-config-page", {
                attrs: { integration: _vm.cardname },
                on: {
                  finishLoading: function ($event) {
                    _vm.loading = false
                  },
                  reload: _vm.fetchDetails,
                  close: function ($event) {
                    _vm.show_prismatic_dialog = false
                  },
                  back: _vm.goBack,
                },
              }),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }