<template>
    <el-popover
        ref="popover_notes"
        title="Notes:"
        :placement="small ? 'top' : 'left'"
        :width="small ? 265 : 400"
        trigger="click"
        @show="showNote">

        <slot
            name="trigger"
            slot="reference">

            <el-button type="text text-greyish opacity-4 hover-opacity text-md m-0 p-0"
                       v-if="!chatbot.notes">
                <i class="el-icon-document"></i>
            </el-button>

            <el-button type="text text-dark-greenish hover-text-blackish text-md m-0 p-0"
                       v-else>
                <i class="el-icon-document"></i>
            </el-button>
        </slot>

        <div class="row">
            <div class="col-12">
                <el-input
                    type="textarea"
                    placeholder="Write your call notes here..."
                    ref="chatbot_notes"
                    v-model="note"
                    :autosize="{ minRows: 3, maxRows: 5}"
                    autofocus>
                </el-input>

                <div class="mt-1 text-right h-24">
                    <span
                        class="text-muted"
                        v-show="loading_btn">
                        <i class="material-icons loader text-dark-greenish">&#xE863;</i>
                        saving
                    </span>
                    <span
                        class="text-muted"
                        v-show="!loading_btn && loading">
                        saved
                    </span>
                    <span
                        v-if="hasUnsavedChanges && !loading_btn"
                        class="text-deep-orange _600">
                        unsaved changes
                    </span>
                </div>
            </div>
        </div>

        <div class="row mt-1">
            <div class="col-12">
                <el-button
                    class="pull-right"
                    type="success"
                    size="small"
                    :loading="loading_btn"
                    :disabled="loading_btn"
                    @click="saveNote">
                    Save
                </el-button>
            </div>
        </div>
    </el-popover>
</template>

<script>
    export default {
        props: {
            chatbot: {
                required: true
            },

            small: {
                default: false,
                type: Boolean,
                required: false
            }
        },

        data() {
            return {
                loading: false,
                loading_btn: false,
                note: null,
            }
        },

        computed: {
            hasUnsavedChanges() {
                if (this.chatbot && this.note != this.chatbot.notes) {
                    return true
                }

                return false
            }
        },

        mounted() {
            this.note = this.chatbot.notes
        },

        methods: {
            /**
             * Sets note whenever needed.
             *
             * @return {void}
             */
            showNote() {
                this.note = this.chatbot.notes
            },

            changeNote: _.debounce(function () {
                this.saveNote()
            }, 2000),

            /**
             * Saves the note.
             *
             * @return {void}
             */
            saveNote() {
                // Lock save button.
                this.loading_btn = true
                this.loading = true

                let campaign_ids = []
                // We only need campaign ids in the request.
                if (this.chatbot) {
                    this.chatbot.campaigns.map(campaign => campaign_ids.push(campaign.id))
                }

                axios.patch(`/api/v1/bots/builder/bot/${this.chatbot.id}`, {
                    notes: this.note,
                    name: this.chatbot.name,
                    workflow: this.chatbot.workflow,
                    campaign_ids: campaign_ids,
                }).then(res => {
                    this.chatbot.notes = this.note
                    this.loading_btn = false
                    this.loading = false
                    this.$notify.success({
                        offset: 95,
                        title: 'Chatbots',
                        message: 'Bot updated successfully.',
                    })
                }).catch(err => {
                    // this.note = null
                    this.$root.handleErrors(err.response)
                    this.loading = false
                    this.loading_btn = false
                })
            },
        },

        watch: {
            'chatbot.notes': function() {
                this.showNote()
            }
        }
    }
</script>