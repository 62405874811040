<template>
  <div>
    <el-form ref="trigger_form"
             v-if="!['contact', 'disposition', 'call disposition', 'appointment', 'dnc status', 'tag', 'remove_tag'].includes(trigger.type)"
             label-position="top"
             :model="trigger"
             :rules="form_trigger"
             @submit.prevent.native="">
      <span class="inline">When an </span>
      <span class="inline"
            v-if="automation_trigger || power_dialer_trigger">
                {{ trigger.direction | fixCommDirection | toLowerCase }}
            </span>
      <el-form-item class="inline mb-3 no-border"
                    prop="direction"
                    v-else>
        <el-select placeholder="Select Direction"
                   v-model="trigger.direction"
                   :disabled="automation_trigger || power_dialer_trigger"
                   @change="customPreValidateForm('trigger_form')">
          <el-option v-for="direction in directions"
                     :label="direction | fixCommDirection"
                     :key="direction"
                     :value="direction">
          </el-option>
        </el-select>
      </el-form-item>
      <span class="inline"
            v-if="trigger.type !== CommunicationTypes.EMAIL">
                {{ trigger.type | fixCommType | toLowerCase }}
            </span>
      <span class="inline"
            v-if="trigger.type === CommunicationTypes.EMAIL && trigger.direction"> email comes {{ trigger.direction == 1 ? 'in' : 'out' }}, then
            </span>
      <!-- Direction -->
      <template v-if="trigger.direction">
                <span class="inline"
                      v-show="trigger.type !== CommunicationTypes.EMAIL"> is </span>
        <span class="inline"
              v-if="automation_trigger || power_dialer_trigger">
                    {{ trigger.disposition_status | translateDispositionStatusText | replaceDash }}
                </span>
        <el-form-item class="inline mb-3 no-border"
                      prop="disposition_status"
                      v-show="trigger.type !== CommunicationTypes.EMAIL"
                      v-else>
          <el-select placeholder="Select Disposition Status"
                     style="width: 220px"
                     v-model="trigger.disposition_status"
                     :disabled="automation_trigger || power_dialer_trigger"
                     @change="checkTriggerForm">
            <el-option v-for="status in availableDispositionStatus"
                       :label="status | translateDispositionStatusText | replaceDash | capitalize"
                       :key="status"
                       :value="status">
            </el-option>
          </el-select>
        </el-form-item>
      </template>
      <template
        v-if="trigger.direction && trigger.disposition_status && trigger.disposition_status !== CommunicationDispositionStatus.DISPOSITION_STATUS_FAILED_NEW && availableVariables.length > 0">
        <!-- Variable -->
        <span class="inline"
              v-if="automation_trigger || power_dialer_trigger">
                    <span class="font-weight-bold">AND</span> its
                </span>
        <el-checkbox v-model="trigger.has_condition"
                     class="_600 mr-2 ml-2"
                     :disabled="automation_trigger || power_dialer_trigger"
                     @change="clearConditions"
                     v-else>
          and if
        </el-checkbox>
        <template v-if="trigger.has_condition">
          <el-form-item class="inline mb-3 no-border"
                        prop="variable">
            <el-select placeholder="Select Variable"
                       style="width: 170px"
                       v-model="trigger.variable"
                       :disabled="power_dialer_trigger"
                       @change="customPreValidateForm('trigger_form')">
              <el-option v-for="variable in availableVariables"
                         :label="variable.name"
                         :key="variable.id"
                         :value="variable.value">
              </el-option>
            </el-select>
          </el-form-item>
          <!-- For calls -->
          <template v-if="trigger.type === CommunicationTypes.CALL">
            <!-- Symbol -->
            <template v-if="trigger.variable && trigger.variable !== 'last_transferred_leg'">
              <span class="inline"> is </span>
              <span class="inline"
                    v-if="automation_trigger">
                                {{ symbolWords }}
                            </span>
              <el-form-item class="inline mb-3 no-border"
                            prop="symbol"
                            v-else>
                <el-select placeholder="Math Symbol"
                           v-model="trigger.symbol"
                           style="width: 130px"
                           :disabled="automation_trigger"
                           @change="customPreValidateForm('trigger_form')">
                  <el-option v-for="symbol in symbols"
                             :label="symbol"
                             :key="symbol"
                             :value="symbol">
                  </el-option>
                </el-select>
              </el-form-item>
            </template>
            <!-- Value -->
            <template v-if="trigger.symbol">
              <el-form-item class="inline mb-3 no-border"
                            prop="value">
                <el-input-number v-model="trigger.value"
                                 :min="0"
                                 @input="customPreValidateForm('trigger_form')">
                </el-input-number>
              </el-form-item>
              <span class="inline"> seconds<span v-if="automation_trigger">,</span><span v-else>.</span> </span>
            </template>
          </template>
          <!-- For text messages -->
          <template v-if="trigger.type === CommunicationTypes.SMS">
            <el-form-item v-if="trigger.variable !== 'first_time_message'"
                          class="inline mb-3 no-border"
                          prop="keywords">
              <el-select placeholder="Choose or create keywords"
                         v-model="trigger.keywords"
                         style="width: 300px"
                         multiple
                         filterable
                         allow-create
                         default-first-option
                         @change="customPreValidateForm('trigger_form')">
                <el-option v-for="keyword in keywords"
                           :label="keyword.label"
                           :key="keyword.value"
                           :value="keyword.value">
                </el-option>
              </el-select>
            </el-form-item>
          </template>
        </template>
      </template>

      <!-- Operation email -->
      <template v-if="trigger.disposition_status && trigger.type === CommunicationTypes.EMAIL">
        <el-form-item class="inline mb-3 no-border"
                      prop="operation">
          <el-select placeholder="Select Operation"
                     style="width: 220px"
                     v-model="trigger.operation"
                     default-first-option
                     filterable
                     autocomplete="off"
                     clearable
                     @change="clearData">
            <el-option v-for="operation in operations"
                       :label="operation.name"
                       :key="operation.id"
                       :value="operation.value">
            </el-option>
          </el-select>
        </el-form-item>
      </template>
      <template v-if="trigger.operation && trigger.type === CommunicationTypes.EMAIL">
        <br>
        <!-- email trigger tag selection -->
        <el-form-item class="inline mb-3 no-border"
                      v-if="hasPermissionTo(['list tag', 'view tag']) && trigger.operation === 'tag'"
                      prop="tag_ids">
          <tag-selector v-model="trigger.tag_ids"
                        style="width: 300px"
                        :multiple="true"
                        :category_prop="TagCategory.CAT_COMMUNICATIONS"
                        @change="changeTags">
          </tag-selector>
        </el-form-item>

        <el-form-item class="inline mb-3 no-border"
                      v-if="hasPermissionTo(['list tag', 'view tag']) && ['tag_contact', 'untag_contact'].includes(trigger.operation)"
                      prop="tag_ids">
          <tag-selector v-model="trigger.tag_ids"
                        style="width: 300px"
                        :multiple="true"
                        :category_prop="TagCategory.CAT_CONTACTS"
                        @change="changeTags">
          </tag-selector>
        </el-form-item>

        <!-- email trigger sequence selection -->
        <el-form-item class="inline mb-3 no-border"
                      v-if="hasPermissionTo(['list workflow', 'view workflow']) && trigger.operation === 'enroll'"
                      prop="workflow_ids">
          <workflow-selector v-model="workflow_id"
                             style="width: 300px"
                             :multiple="false"
                             @change="changeWorkflows">
          </workflow-selector>
        </el-form-item>

        <!-- email trigger disposition-status selection -->
        <el-form-item class="inline mb-3 no-border"
                      v-if="hasPermissionTo(['list disposition status', 'view disposition status']) && trigger.operation === 'dispose'"
                      prop="disposition_status_id">
          <disposition-status-selector v-model="trigger.disposition_status_id"
                                       :multiple="false"
                                       style="width: 300px"
                                       @change="changeDispositionStatus">
          </disposition-status-selector>
        </el-form-item>

        <el-form-item class="inline mb-3 no-border"
                      v-if="trigger.operation === 'create_hubspot_ticket'"
                      prop="stage_id">
          <hubspot-ticketing-pipeline-selector :ticket-name="trigger.ticket_name"
                                               :pipeline-id="trigger.pipeline_id"
                                               :stage-id="trigger.stage_id"
                                               :ticket-description="trigger.ticket_description"
                                               @selectedPipeline="changeSelectedPipeline"
                                               @selectedStage="changeSelectedStage"
                                               @changedTicketName="changeTicketName"
                                               @changedTicketDescription="changeTicketDescription">
          </hubspot-ticketing-pipeline-selector>
        </el-form-item>
      </template>
      <template v-if="trigger.disposition_status && trigger.type !== CommunicationTypes.EMAIL">
                <span class="inline"
                      v-if="!automation_trigger">
                    Then
                </span>
        <span class="inline"
              v-if="automation_trigger">
                    {{ triggerOperationName | toLowerCase }}
                </span>
        <el-form-item class="inline mb-3 no-border"
                      prop="operation"
                      v-else>
          <el-select placeholder="Select Operation"
                     style="width: 220px"
                     v-model="trigger.operation"
                     autocomplete="off"
                     :disabled="automation_trigger"
                     default-first-option
                     filterable
                     clearable
                     @change="clearData">
            <el-option v-for="operation in operations"
                       :label="operation.name"
                       :key="operation.id"
                       :value="operation.value">
            </el-option>
          </el-select>
        </el-form-item>
      </template>

      <div class="row"
           v-if="trigger.type !== CommunicationTypes.EMAIL">
        <div class="col-12">
          <!-- Operation -->
          <template v-if="trigger.operation && ['tag', 'tag_contact', 'untag_contact', 'enroll', 'dispose', 'create_hubspot_ticket'].includes(trigger.operation)">
            <el-form-item class="inline mb-3 no-border"
                          v-if="hasPermissionTo(['list tag', 'view tag']) && trigger.operation === 'tag'"
                          prop="tag_ids">
              <tag-selector v-model="trigger.tag_ids"
                            style="width: 300px"
                            :multiple="true"
                            :category_prop="TagCategory.CAT_COMMUNICATIONS"
                            @change="changeTags">
              </tag-selector>
            </el-form-item>

            <el-form-item class="inline mb-3 no-border"
                          v-if="hasPermissionTo(['list tag', 'view tag']) && ['tag_contact', 'untag_contact'].includes(trigger.operation)"
                          prop="tag_ids">
              <tag-selector v-model="trigger.tag_ids"
                            style="width: 300px"
                            :multiple="true"
                            :category_prop="TagCategory.CAT_CONTACTS"
                            @change="changeTags">
              </tag-selector>
            </el-form-item>

            <el-form-item class="inline mb-3 no-border"
                          v-if="hasPermissionTo(['list workflow', 'view workflow']) && trigger.operation === 'enroll'"
                          prop="workflow_ids">
              <workflow-selector v-model="workflow_id"
                                 style="width: 300px"
                                 :multiple="false"
                                 @change="changeWorkflows">
              </workflow-selector>
            </el-form-item>

            <el-form-item class="inline mb-3 no-border"
                          v-if="hasPermissionTo(['list disposition status', 'view disposition status']) && trigger.operation === 'dispose'"
                          prop="disposition_status_id">
              <disposition-status-selector v-model="trigger.disposition_status_id"
                                           :multiple="false"
                                           style="width: 300px"
                                           @change="changeDispositionStatus">
              </disposition-status-selector>
            </el-form-item>

            <el-form-item class="inline mb-3 no-border"
                          v-if="trigger.operation === 'create_hubspot_ticket'"
                          prop="stage_id">
              <hubspot-ticketing-pipeline-selector :ticket-name="trigger.ticket_name"
                                                   :pipeline-id="trigger.pipeline_id"
                                                   :stage-id="trigger.stage_id"
                                                   :ticket-description="trigger.ticket_description"
                                                   @selectedPipeline="changeSelectedPipeline"
                                                   @selectedStage="changeSelectedStage"
                                                   @changedTicketName="changeTicketName"
                                                   @changedTicketDescription="changeTicketDescription">
              </hubspot-ticketing-pipeline-selector>
            </el-form-item>
          </template>
        </div>
      </div>

      <div class="row no-select">
        <div class="col-12">
                <span class="pull-right _600 opacity-5"
                      :class="[ trigger.valid ? 'text-dark-greenish' : 'text-danger' ]">
                    <i class="fa fa-check-circle"
                       v-if="trigger.valid"></i>
                    <i class="fa fa-times-circle"
                       v-else></i>
                    {{ trigger.valid ? 'Valid trigger' : 'Invalid trigger' }}
                </span>
        </div>
      </div>
    </el-form>
    <el-form ref="trigger_form"
             v-else-if="trigger.type == 'contact' && automation_trigger && !power_dialer_trigger"
             :model="trigger"
             :rules="form_trigger"
             @submit.prevent.native="">
      <div class="row">
        <div class="col-12">
          <span class="el-form-item__label inline">When a contact is created, then enroll to this sequence</span>
        </div>
      </div>

      <div class="row no-select">
        <div class="col-12">
                <span class="pull-right _600 opacity-5"
                      :class="[ trigger.valid ? 'text-dark-greenish' : 'text-danger' ]">
                    <i class="fa fa-check-circle"
                       v-if="trigger.valid"></i>
                    <i class="fa fa-times-circle"
                       v-else></i>
                    {{ trigger.valid ? 'Valid trigger' : 'Invalid trigger' }}
                </span>
        </div>
      </div>
    </el-form>

    <!-- contact trigger -->
    <el-form ref="trigger_form"
             v-else-if="trigger.type == 'contact'"
             :model="trigger"
             :rules="form_trigger"
             @submit.prevent.native="">
      <div class="row">
        <div class="col-12">
          <span class="el-form-item__label inline">When a contact is created, then</span>
          <el-form-item class="inline mb-3 no-border"
                        prop="operation">
            <el-select placeholder="Select Operation"
                       style="width: 220px"
                       v-model="trigger.operation"
                       default-first-option
                       autocomplete="off"
                       filterable
                       clearable
                       @change="clearData">
              <el-option v-for="operation in operations"
                         :label="operation.name"
                         :key="operation.id"
                         :value="operation.value">
              </el-option>
            </el-select>
          </el-form-item>
          <template v-if="trigger.operation">
            <br>
            <!-- contact trigger tags selection -->
            <el-form-item class="inline mb-3 no-border"
                          v-if="hasPermissionTo(['list tag', 'view tag']) && trigger.operation === 'tag'"
                          prop="tag_ids">
              <tag-selector v-model="trigger.tag_ids"
                            style="width: 300px"
                            :multiple="true"
                            :category_prop="TagCategory.CAT_COMMUNICATIONS"
                            @change="changeTags">
              </tag-selector>
            </el-form-item>

            <el-form-item class="inline mb-3 no-border"
                          v-if="hasPermissionTo(['list tag', 'view tag']) && ['tag_contact', 'untag_contact'].includes(trigger.operation)"
                          prop="tag_ids">
              <tag-selector v-model="trigger.tag_ids"
                            style="width: 300px"
                            :multiple="true"
                            :category_prop="TagCategory.CAT_CONTACTS"
                            @change="changeTags">
              </tag-selector>
            </el-form-item>

            <!-- contact trigger sequence selection -->
            <el-form-item class="inline mb-3 no-border"
                          v-if="hasPermissionTo(['list workflow', 'view workflow']) && trigger.operation === 'enroll'"
                          prop="workflow_ids">
              <workflow-selector v-model="workflow_id"
                                 style="width: 300px"
                                 :multiple="false"
                                 @change="changeWorkflows">
              </workflow-selector>
            </el-form-item>

            <!-- contact trigger disposition-status selection -->
            <el-form-item class="inline mb-3 no-border"
                          v-if="hasPermissionTo(['list disposition status', 'view disposition status']) && trigger.operation === 'dispose'"
                          prop="disposition_status_id">
              <disposition-status-selector v-model="trigger.disposition_status_id"
                                           :multiple="false"
                                           style="width: 300px"
                                           @change="changeDispositionStatus">
              </disposition-status-selector>
            </el-form-item>
          </template>
        </div>
      </div>

      <div class="row no-select">
        <div class="col-12">
                <span class="pull-right _600 opacity-5"
                      :class="[ trigger.valid ? 'text-dark-greenish' : 'text-danger' ]">
                    <i class="fa fa-check-circle"
                       v-if="trigger.valid"></i>
                    <i class="fa fa-times-circle"
                       v-else></i>
                    {{ trigger.valid ? 'Valid trigger' : 'Invalid trigger' }}
                </span>
        </div>
      </div>
    </el-form>
    <el-form ref="trigger_form"
             v-else-if="trigger.type == 'disposition'"
             :model="trigger"
             :rules="form_trigger"
             @submit.prevent.native="">
      <div class="row">
        <div class="col-12">
          <span class="inline">When a contact is disposed to </span>
          <el-form-item class="inline mb-3 no-border"
                        prop="disposition_status_id">
            <el-select placeholder="Select Disposition"
                       style="width: 220px"
                       v-model="trigger.disposition_status_id"
                       default-first-option
                       autocomplete="off"
                       filterable
                       clearable
                       @change="clearData">
              <el-option v-for="disposition in availableContactDispositionStatus"
                         :label="disposition.name"
                         :key="disposition.id"
                         :value="disposition.id">
                <template v-if="disposition.is_external !== undefined">
                  <i class="material-icons"
                     :style="{ color: disposition.color }"
                     v-if="disposition.is_external">lock</i>
                  <i class="material-icons"
                     :style="{ color: disposition.color }"
                     v-else>label</i>
                </template>
                <span>{{ disposition.name }}</span>
              </el-option>
            </el-select>
          </el-form-item>
          <span class="inline">, then </span>
          <el-form-item class="inline mb-3 no-border"
                        prop="enroll_action">
            <el-select placeholder="Select Action"
                       style="width: 220px"
                       v-model="trigger.enroll_action"
                       autocomplete="off"
                       default-first-option
                       filterable
                       clearable
                       @change="clearData">
              <el-option v-for="action in availableActions"
                         :label="action.name"
                         :key="action.id"
                         :value="action.value">
                <span>{{ action.name }}</span>
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item class="inline mb-3 no-border"
                        v-if="hasPermissionTo(['list workflow', 'view workflow']) && trigger.enroll_action && trigger.enroll_action === 'enroll' && power_dialer_trigger"
                        prop="workflow_ids">
            <workflow-selector v-model="workflow_id"
                               style="width: 300px"
                               :multiple="false"
                               @change="changeWorkflows">
            </workflow-selector>
          </el-form-item>
        </div>
      </div>

      <div class="row no-select">
        <div class="col-12">
                    <span class="pull-right _600 opacity-5"
                          :class="[ trigger.valid ? 'text-dark-greenish' : 'text-danger' ]">
                        <i class="fa fa-check-circle"
                           v-if="trigger.valid"></i>
                        <i class="fa fa-times-circle"
                           v-else></i>
                        {{ trigger.valid ? 'Valid trigger' : 'Invalid trigger' }}
                    </span>
        </div>
      </div>
    </el-form>
    <el-form ref="trigger_form"
             v-else-if="trigger.type == 'tag'"
             :model="trigger"
             :rules="form_trigger"
             @submit.prevent.native="">
      <div class="row">
        <div class="col-12">
          <span class="inline">When added tag </span>
          <el-form-item class="inline mb-3 no-border"
                        prop="tag_option_id">
            <el-select placeholder="Select Option"
                       style="width: 220px"
                       v-model="trigger.tag_option_id"
                       default-first-option
                       autocomplete="off"
                       filterable
                       clearable
                       @change="clearData">
              <el-option v-for="tag_option in availableTagOptions"
                         :label="tag_option.name"
                         :key="tag_option.id"
                         :value="tag_option.id">
                <span>{{ tag_option.name }}</span>
              </el-option>
            </el-select>
          </el-form-item>
          <span class="inline"> of </span>
          <el-form-item class="inline mb-3 no-border"
                        prop="tag_ids">
            <tag-selector v-model="trigger.tag_ids"
                          style="width: 300px"
                          :multiple="true"
                          :category_prop="TagCategory.CAT_CONTACTS"
                          @change="changeTags">
            </tag-selector>
          </el-form-item>
          <span class="inline">, then </span>
          <el-form-item class="inline mb-3 no-border"
                        prop="enroll_action">
            <el-select placeholder="Select Action"
                       style="width: 220px"
                       v-model="trigger.enroll_action"
                       autocomplete="off"
                       default-first-option
                       filterable
                       clearable
                       @change="clearData">
              <el-option v-for="action in availableActions"
                         :label="action.name"
                         :key="action.id"
                         :value="action.value">
                <span>{{ action.name }}</span>
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item class="inline mb-3 no-border"
                        v-if="hasPermissionTo(['list workflow', 'view workflow']) && trigger.enroll_action && trigger.enroll_action === 'enroll' && power_dialer_trigger"
                        prop="workflow_ids">
            <workflow-selector v-model="workflow_id"
                               style="width: 300px"
                               :multiple="false"
                               @change="changeWorkflows">
            </workflow-selector>
          </el-form-item>
        </div>
      </div>

      <div class="row no-select">
        <div class="col-12">
                    <span class="pull-right _600 opacity-5"
                          :class="[ trigger.valid ? 'text-dark-greenish' : 'text-danger' ]">
                        <i class="fa fa-check-circle"
                           v-if="trigger.valid"></i>
                        <i class="fa fa-times-circle"
                           v-else></i>
                        {{ trigger.valid ? 'Valid trigger' : 'Invalid trigger' }}
                    </span>
        </div>
      </div>
    </el-form>
    <el-form ref="trigger_form"
             v-else-if="trigger.type == 'remove_tag'"
             :model="trigger"
             :rules="form_trigger"
             @submit.prevent.native="">
      <div class="row">
        <div class="col-12">
          <span class="inline">When removed tag </span>
          <el-form-item class="inline mb-3 no-border"
                        prop="tag_option_id">
            <el-select placeholder="Select Option"
                       style="width: 220px"
                       v-model="trigger.tag_option_id"
                       default-first-option
                       autocomplete="off"
                       filterable
                       clearable
                       @change="clearData">
              <el-option v-for="tag_option in availableTagOptions"
                         :label="tag_option.name"
                         :key="tag_option.id"
                         :value="tag_option.id">
                <span>{{ tag_option.name }}</span>
              </el-option>
            </el-select>
          </el-form-item>
          <span class="inline"> of </span>
          <el-form-item class="inline mb-3 no-border"
                        prop="tag_ids">
            <tag-selector v-model="trigger.tag_ids"
                          style="width: 300px"
                          :multiple="true"
                          :category_prop="TagCategory.CAT_CONTACTS"
                          @change="changeTags">
            </tag-selector>
          </el-form-item>
          <span class="inline">, then </span>
          <el-form-item class="inline mb-3 no-border"
                        prop="enroll_action">
            <el-select placeholder="Select Action"
                       style="width: 220px"
                       v-model="trigger.enroll_action"
                       autocomplete="off"
                       default-first-option
                       filterable
                       clearable
                       @change="clearData">
              <el-option v-for="action in availableActions"
                         :label="action.name"
                         :key="action.id"
                         :value="action.value">
                <span>{{ action.name }}</span>
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item class="inline mb-3 no-border"
                        v-if="hasPermissionTo(['list workflow', 'view workflow']) && trigger.enroll_action && trigger.enroll_action === 'enroll' && power_dialer_trigger"
                        prop="workflow_ids">
            <workflow-selector v-model="workflow_id"
                               style="width: 300px"
                               :multiple="false"
                               @change="changeWorkflows">
            </workflow-selector>
          </el-form-item>
        </div>
      </div>

      <div class="row no-select">
        <div class="col-12">
                    <span class="pull-right _600 opacity-5"
                          :class="[ trigger.valid ? 'text-dark-greenish' : 'text-danger' ]">
                        <i class="fa fa-check-circle"
                           v-if="trigger.valid"></i>
                        <i class="fa fa-times-circle"
                           v-else></i>
                        {{ trigger.valid ? 'Valid trigger' : 'Invalid trigger' }}
                    </span>
        </div>
      </div>
    </el-form>
    <el-form ref="trigger_form"
             v-else-if="trigger.type == 'call disposition'"
             :model="trigger"
             :rules="form_trigger"
             @submit.prevent.native="">
      <div class="row">
        <div class="col-12">
          <span class="inline">When a call is disposed to </span>
          <el-form-item class="inline mb-3 no-border"
                        prop="call_disposition_id">
            <el-select placeholder="Select Disposition"
                       style="width: 220px"
                       v-model="trigger.call_disposition_id"
                       default-first-option
                       autocomplete="off"
                       filterable
                       clearable
                       @change="clearData">
              <el-option v-for="disposition in callDispositionAlphabeticalOrder"
                         :label="disposition.name"
                         :key="disposition.id"
                         :value="disposition.id">
                <template v-if="disposition.is_external !== undefined">
                  <i class="material-icons"
                     :style="{ color: disposition.color }"
                     v-if="disposition.is_external">lock</i>
                  <i class="material-icons"
                     :style="{ color: disposition.color }"
                     v-else>label</i>
                </template>
                <span>{{ disposition.name }}</span>
              </el-option>
            </el-select>
          </el-form-item>
          <span class="inline">, then </span>
          <el-form-item class="inline mb-3 no-border"
                        prop="enroll_action">
            <el-select placeholder="Select Action"
                       style="width: 220px"
                       v-model="trigger.enroll_action"
                       autocomplete="off"
                       default-first-option
                       filterable
                       clearable
                       @change="clearData">
              <el-option v-for="action in availableActions"
                         :label="action.name"
                         :key="action.id"
                         :value="action.value">
                <span>{{ action.name }}</span>
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item class="inline mb-3 no-border"
                        v-if="hasPermissionTo(['list workflow', 'view workflow']) && trigger.enroll_action && !automation_trigger && ((power_dialer_trigger && trigger.enroll_action == 'enroll') || !power_dialer_trigger)"
                        prop="workflow_ids">
            <workflow-selector v-model="workflow_id"
                               style="width: 300px"
                               :multiple="false"
                               @change="changeWorkflows">
            </workflow-selector>
          </el-form-item>
          <el-form-item class="inline mb-3 no-border"
                        v-if="hasPermissionTo(['list tag', 'view tag']) && ['tag_contact', 'untag_contact'].includes(trigger.enroll_action)"
                        prop="tag_ids">
            <tag-selector v-model="trigger.tag_ids"
                          style="width: 300px"
                          :multiple="true"
                          :category_prop="TagCategory.CAT_CONTACTS"
                          @change="changeTags">
            </tag-selector>
          </el-form-item>
        </div>
      </div>

      <div class="row no-select">
        <div class="col-12">
                    <span class="pull-right _600 opacity-5"
                          :class="[ trigger.valid ? 'text-dark-greenish' : 'text-danger' ]">
                        <i class="fa fa-check-circle"
                           v-if="trigger.valid"></i>
                        <i class="fa fa-times-circle"
                           v-else></i>
                        {{ trigger.valid ? 'Valid trigger' : 'Invalid trigger' }}
                    </span>
        </div>
      </div>
    </el-form>
    <el-form ref="trigger_form"
             v-else-if="trigger.type == 'dnc status'"
             :model="trigger"
             :rules="form_trigger"
             @submit.prevent.native="">
      <div class="row">
        <div class="col-12">
          <span class="inline">When a contact is </span>
          <el-form-item class="inline mb-3 no-border"
                        prop="trigger_option">
            <el-select placeholder="Select"
                       style="width: 220px"
                       v-model="trigger.trigger_option"
                       default-first-option
                       autocomplete="off"
                       filterable
                       clearable
                       @change="clearData">
              <el-option v-for="trigger_option in dnc_trigger_options" :key="trigger_option.value" :value="trigger_option.value" :label="trigger_option.label">
                <span>{{ trigger_option.label }}</span>
              </el-option>
            </el-select>
          </el-form-item>
          <span class="inline">, then enroll to this sequence.</span>
        </div>
      </div>

      <div class="row no-select">
        <div class="col-12">
                    <span class="pull-right _600 opacity-5"
                          :class="[ trigger.valid ? 'text-dark-greenish' : 'text-danger' ]">
                        <i class="fa fa-check-circle"
                           v-if="trigger.valid"></i>
                        <i class="fa fa-times-circle"
                           v-else></i>
                        {{ trigger.valid ? 'Valid trigger' : 'Invalid trigger' }}
                    </span>
        </div>
      </div>
    </el-form>
    <el-form ref="trigger_form"
             v-else-if="trigger.type == 'appointment'"
             :model="trigger"
             :rules="form_trigger"
             @submit.prevent.native="">
      <div class="row">
        <div class="col-12">
          <span class="inline">When an appointment is set to </span>
          <el-form-item class="inline mb-3 no-border"
                        prop="appointment_status_id">
            <el-select placeholder="Select Appointment Status"
                       style="width: 220px"
                       v-model="trigger.appointment_status_id"
                       default-first-option
                       autocomplete="off"
                       filterable
                       clearable
                       @change="clearData">
              <el-option v-for="appointment in appointmentOptions"
                         :label="appointment.label"
                         :key="appointment.value"
                         :value="appointment.value">
                <span>{{ appointment.label }}</span>
              </el-option>
            </el-select>
          </el-form-item>
          <span class="inline">, then enroll to this sequence</span>
        </div>
      </div>

      <div class="row no-select">
        <div class="col-12">
                    <span class="pull-right _600 opacity-5"
                          :class="[ trigger.valid ? 'text-dark-greenish' : 'text-danger' ]">
                        <i class="fa fa-check-circle"
                           v-if="trigger.valid"></i>
                        <i class="fa fa-times-circle"
                           v-else></i>
                        {{ trigger.valid ? 'Valid trigger' : 'Invalid trigger' }}
                    </span>
        </div>
      </div>
    </el-form>
  </div>
</template>

<script>
import _ from 'lodash'
import auth from '../auth'
import {acl_mixin, form_validation_mixin} from '../mixins'
import {mapGetters, mapState} from 'vuex'
import HubspotTicketingPipelineSelector from "./hubspot-ticket-composer"
import * as CommunicationDispositionStatus from '../constants/communication-disposition-status'
import * as CommunicationDirection from '../constants/communication-direction'
import * as CommunicationTypes from '../constants/communication-types'
import * as TagCategory from '../constants/tag-categories'
import * as TriggerVariables from '../constants/trigger-variables'
import * as TriggerOperations from '../constants/trigger-operations'

export default {
  mixins: [
    acl_mixin,
    form_validation_mixin
  ],

  components: {HubspotTicketingPipelineSelector},

  props: {
    trigger: {
      required: true
    },

    automation_trigger: {
      type: Boolean,
      default: false,
      required: false
    },

    power_dialer_trigger: {
      type: Boolean,
      default: false,
      required: false
    },

    disable_any_contact_disposition: {
      type: Boolean,
      default: false,
      required: false
    },

    disable_any_call_disposition: {
      type: Boolean,
      default: false,
      required: false
    },

    used_contact_disposition_statuses: {
      required: false
    }
  },

  data() {
    return {
      auth: auth,
      directions: [
        CommunicationDirection.INBOUND,
        CommunicationDirection.OUTBOUND
      ],
      disposition_status: [
        CommunicationDispositionStatus.DISPOSITION_STATUS_ABANDONED_NEW,
        CommunicationDispositionStatus.DISPOSITION_STATUS_MISSED_NEW,
        CommunicationDispositionStatus.DISPOSITION_STATUS_MISSED_AND_VOICEMAIL_NEW,
        CommunicationDispositionStatus.DISPOSITION_STATUS_VOICEMAIL_NEW,
        CommunicationDispositionStatus.DISPOSITION_STATUS_COMPLETED_NEW,
        CommunicationDispositionStatus.DISPOSITION_STATUS_FAILED_NEW,
        CommunicationDispositionStatus.DISPOSITION_STATUS_PLACED_NEW,
        CommunicationDispositionStatus.DISPOSITION_STATUS_DEADEND_NEW
      ],
      symbols: [
        '>=',
        '<='
      ],
      variables: TriggerVariables.TRIGGER_VARIABLES,
      operations: TriggerOperations.TRIGGER_OPERATIONS,
      keywords: [
        {
          value: 'yes',
          label: 'yes'
        }, {
          value: 'interested',
          label: 'interested'
        }, {
          value: 'not interested',
          label: 'not interested'
        }, {
          value: 'stop',
          label: 'stop'
        }, {
          value: 'more info',
          label: 'more info'
        }, {
          value: 'thanks',
          label: 'thanks'
        }
      ],
      dnc_trigger_options: [
        {
          value: 1,
          label: 'Set to DNC'
        },
        {
          value: 2,
          label: 'Removed from DNC'
        }
      ],
      form_trigger: {
        direction: [
          {
            required: true,
            message: 'Please select a direction',
            trigger: 'change'
          }
        ],
        disposition_status: [
          {
            required: true,
            message: 'Please select a disposition status',
            trigger: 'change'
          }
        ],
        enroll_action: [
          {
            required: true,
            message: 'Please select an action',
            trigger: 'change'
          }
        ],
        operation: [
          {
            required: true,
            message: 'Please select an operation',
            trigger: 'change'
          }
        ],
        disposition_status_id: [
          {
            required: true,
            message: 'Please select a disposition',
            trigger: 'change'
          }
        ],
        appointment_status_id: [
          {
            required: true,
            message: 'Please select appointment status',
            trigger: 'change'
          }
        ],
        call_disposition_id: [
          {
            required: true,
            message: 'Please select a call disposition',
            trigger: 'change'
          }
        ],
        tag_ids: [
          {
            type: 'array',
            required: true,
            message: 'Please select one or more tags',
            trigger: 'change'
          },
        ],
        workflow_ids: [
          {
            type: 'array',
            required: true,
            message: 'Please select a sequence',
            trigger: 'change'
          },
        ],
        variable: [
          {
            required: true,
            message: 'Please select a variable',
            trigger: 'change'
          }
        ],
        symbol: [
          {
            required: true,
            message: 'Please select a math symbol',
            trigger: 'change'
          }
        ],
        value: [
          {
            required: true,
            message: 'Please provide a number',
            trigger: 'blur'
          }
        ],
        keywords: [
          {
            type: 'array',
            required: true,
            message: 'Please add or select one or more keywords',
            trigger: 'change'
          },
        ],
        stage_id: [
          {
            required: true,
            message: 'Please select a pipeline and stage',
            trigger: 'change'
          },
        ],
        trigger_option: [
          {
            required: true,
            message: 'Please select an option',
            trigger: 'change'
          },
        ],
        tag_option_id: [
          {
            required: true,
            message: 'Please select an option',
            trigger: 'change'
          }
        ],
      },
      workflow_id: null,
      CommunicationDispositionStatus,
      CommunicationDirection,
      CommunicationTypes,
      TagCategory
    }
  },

  computed: {
    ...mapState(['disposition_statuses', 'call_dispositions']),

    ...mapState('cache', ['current_company']),

    ...mapGetters('cache', ['isSimpSocial']),

    availableVariables() {
      if (!this.trigger || !this.trigger.type) {
        return []
      }

      // for calls
      if (this.trigger.type === CommunicationTypes.CALL) {
        switch (this.trigger.disposition_status) {
          case CommunicationDispositionStatus.DISPOSITION_STATUS_COMPLETED_NEW:
            return this.variables.filter(variable => ![TriggerVariables.TRIGGER_VARIABLE_HAS_ALL_KEYWORDS.value, TriggerVariables.TRIGGER_VARIABLE_HAS_ANY_KEYWORDS.value].includes(variable.value))
          case CommunicationDispositionStatus.DISPOSITION_STATUS_MISSED_NEW:
          case CommunicationDispositionStatus.DISPOSITION_STATUS_ABANDONED_NEW:
            return this.variables.filter(variable => [TriggerVariables.TRIGGER_VARIABLE_WAIT_TIME.value,TriggerVariables.TRIGGER_VARIABLE_DURATION.value].includes(variable.value))
          case CommunicationDispositionStatus.DISPOSITION_STATUS_FAILED_NEW:
            return []
          default:
            return this.variables.filter(variable => ![TriggerVariables.TRIGGER_VARIABLE_HAS_ALL_KEYWORDS.value, TriggerVariables.TRIGGER_VARIABLE_HAS_ANY_KEYWORDS.value, TriggerVariables.TRIGGER_VARIABLE_LAST_TRANSFERRED_LEG.value].includes(variable.value))
        }
      }

      // for text messages
      if (this.trigger.type === CommunicationTypes.SMS) {
        let ids = [TriggerVariables.TRIGGER_VARIABLE_HAS_ALL_KEYWORDS.id, TriggerVariables.TRIGGER_VARIABLE_HAS_ANY_KEYWORDS.id]
        // add the first time message variable for inbound messages
        if (this.trigger.direction === CommunicationDirection.INBOUND) {
          ids.push(TriggerVariables.TRIGGER_VARIABLE_FIRST_TIME_MESSAGE.id)
        }
        return this.variables.filter(variable => ids.includes(variable.id))
      }

      if (this.trigger.type === CommunicationTypes.EMAIL) {
        return []
      }
    },

    availableDispositionStatus() {
      if (!this.trigger || !this.trigger.type) {
        return []
      }

      // for calls
      if (this.trigger.type === CommunicationTypes.CALL) {
        return this.disposition_status
      }

      // for text messages and emails
      if (this.trigger.type === CommunicationTypes.SMS) {
        return [
          CommunicationDispositionStatus.DISPOSITION_STATUS_COMPLETED_NEW
        ]
      }

      if (this.trigger.type === CommunicationTypes.EMAIL) {
        this.trigger.disposition_status = CommunicationDispositionStatus.DISPOSITION_STATUS_COMPLETED_NEW
        return [
          CommunicationDispositionStatus.DISPOSITION_STATUS_COMPLETED_NEW
        ]
      }
    },

    availableActions() {
      if (!this.trigger || !this.trigger.type) {
        return []
      }

      if (this.power_dialer_trigger) {
        return [
          {
            id: 1,
            name: 'Apply tags to contact',
            value: 'tag_contact'
          },
          {
            id: 2,
            name: 'Remove tags from contact',
            value: 'untag_contact'
          },
          {
            id: 3,
            name: 'Remove task tag from contact',
            value: 'untag'
          },
          {
            id: 4,
            name: 'Enroll to a sequence',
            value: 'enroll'
          },
        ]
      }

      return [
        {
          id: 1,
          name: 'Enroll to this sequence',
          value: 'enroll'
        },
        {
          id: 2,
          name: 'Disenroll from this sequence',
          value: 'disenroll'
        }
      ]
    },

    contactDispositionStatusesAlphabeticalOrder() {
      if (this.disposition_statuses) {
        let disposition_statuses = _.clone(this.disposition_statuses)
        disposition_statuses = disposition_statuses.sort((a, b) => {
          let textA = a.name.toUpperCase()
          let textB = b.name.toUpperCase()
          return (textA < textB) ? -1 : (textA > textB) ? 1 : 0
        })
        if (!this.disable_any_contact_disposition) {
          disposition_statuses.unshift({
            id: 0,
            name: 'Any Disposition'
          })
        }

        return disposition_statuses
      }

      return []
    },

    callDispositionAlphabeticalOrder() {
      if (this.call_dispositions) {
        let call_dispositions = _.clone(this.call_dispositions)
        call_dispositions = call_dispositions.sort((a, b) => {
          let textA = a.name.toUpperCase()
          let textB = b.name.toUpperCase()
          return (textA < textB) ? -1 : (textA > textB) ? 1 : 0
        })
        if (!this.disable_any_call_disposition) {
          call_dispositions.unshift({
            id: 0,
            name: 'Any Call Disposition'
          })
        }

        return call_dispositions
      }

      return []
    },

    availableContactDispositionStatus() {
      if (this.used_contact_disposition_statuses) {
        let used_contact_disposition_statuses = this.used_contact_disposition_statuses.filter(disposition_status_id => disposition_status_id != this.trigger.disposition_status_id)
        return this.contactDispositionStatusesAlphabeticalOrder.filter(disposition_status => !used_contact_disposition_statuses.includes(disposition_status.id))
      }

      return this.contactDispositionStatusesAlphabeticalOrder
    },

    availableTagOptions() {
      return [
        {
          id: 1,
          name: 'Is any',
          value: 'is_any'
        },
        {
          id: 2,
          name: 'Is none',
          value: 'is_none'
        }
      ]
    },

    appointmentOptions() {
      return [
        {
          value: CommunicationDispositionStatus.DISPOSITION_STATUS_APPOINTMENT_SET,
          label: 'Set'
        },
        {
          value: CommunicationDispositionStatus.DISPOSITION_STATUS_APPOINTMENT_SHOWN,
          label: this.isSimpSocial ? 'Shown' : 'Completed'
        },
        {
          value: CommunicationDispositionStatus.DISPOSITION_STATUS_APPOINTMENT_CANCELED,
          label: 'Cancelled'
        },
        {
          value: CommunicationDispositionStatus.DISPOSITION_STATUS_APPOINTMENT_MISSED,
          label: 'Missed'
        }
      ]
    },

    triggerOperationName() {
      const operation = _.find(this.operations, {value: this.trigger.operation})
      return operation.name
    },

    symbolWords() {
      switch (this.trigger.symbol) {
        case '<=':
          return 'less than or equals'
        case '>=':
          return 'greater than or equals'
      }
    },

    hubspotEnabled() {
      if (this.current_company &&
        this.current_company.hubspot_integration_enabled) {
        return true
      }

      return false
    }
  },

  created() {
    if (this.trigger.workflow_ids && this.trigger.workflow_ids.length > 0) {
      this.workflow_id = this.trigger.workflow_ids[0]
    }

    if (this.automation_trigger) {
      this.addOperation({
        name: 'Disenroll from this sequence',
        value: 'disenroll'
      })
    }

    if (this.power_dialer_trigger) {
      this.addOperation({
        name: 'Remove task tag from contact',
        value: 'untag'
      })
    }

    if (!this.automation_trigger && !this.power_dialer_trigger && this.hubspotEnabled) {
      this.addOperation({
        name: 'Create HubSpot ticket',
        value: 'create_hubspot_ticket'
      })
    }

    if (!['contact'].includes(this.trigger.type)) {
      // check if we have `untag_contact` in the operations array
      this.addOperation({
        name: 'Remove tags from contact',
        value: 'untag_contact'
      }, 'tag_contact')
    }
  },

  mounted() {
    // If disposition status id is not in available communication disposition statuses now, set it as null and trigger validation
    if (this.availableContactDispositionStatus.findIndex((contact_disposition) => contact_disposition.id === this.trigger.disposition_status_id) < 0) {
      this.trigger.disposition_status_id = null
    }

    // If disposition status id is not in available call disposition statuses now, set it as null and trigger validation
    if (this.callDispositionAlphabeticalOrder.findIndex((call_disposition) => call_disposition.id === this.trigger.call_disposition_id) < 0) {
      this.trigger.call_disposition_id = null
    }

    this.customPreValidateForm('trigger_form')
  },

  methods: {
    resetVariable() {
      this.trigger.variable = null
      this.trigger.symbol = null
      this.trigger.value = 0
    },

    clearConditions() {
      this.resetVariable()
      this.trigger.keywords = []
      if (this.availableVariables.length == 1) {
        this.trigger.variable = this.availableVariables[0].value
      }

      this.customPreValidateForm('trigger_form')
    },

    clearData() {
      this.workflow_id = null

      if (!this.automation_trigger) {
        this.trigger.tag_ids = []
        this.trigger.workflow_ids = []
      }

      if (!this.automation_trigger && !this.power_dialer_trigger) {
        this.trigger.disposition_status_id = []
      }

      this.customPreValidateForm('trigger_form')
    },

    checkTriggerForm() {
      this.resetVariable()
      this.trigger.has_condition = false
      this.trigger.operation = null
      this.clearData()
    },

    changeTags(event) {
      this.trigger.tag_ids = event
      this.customPreValidateForm('trigger_form')
    },

    changeWorkflows(event) {
      if (event) {
        this.trigger.workflow_ids = [event]
      } else {
        this.trigger.workflow_ids = []
      }
      this.customPreValidateForm('trigger_form')
    },

    changeDispositionStatus(event) {
      this.trigger.disposition_status_id = event
      this.customPreValidateForm('trigger_form')
    },

    changeTicketName(ticket_name) {
      this.trigger.ticket_name = ticket_name
      this.customPreValidateForm('trigger_form')
    },

    changeSelectedPipeline(pipeline_id) {
      this.trigger.pipeline_id = pipeline_id
      this.trigger.stage_id = null
      this.customPreValidateForm('trigger_form')
    },

    changeSelectedStage(stage_id) {
      this.trigger.stage_id = stage_id
      this.customPreValidateForm('trigger_form')
    },

    changeTicketDescription(ticket_description) {
      this.trigger.ticket_description = ticket_description
      this.customPreValidateForm('trigger_form')
    },

    changeTagOptionStatus(event) {
      this.trigger.tag_option_id = event
      this.customPreValidateForm('trigger_form')
    },

    customPreValidateForm: _.debounce(function (form_name) {
      let form_element = _.get(this.$refs, form_name, null)

      if (!form_element) {
        return
      }

      let fields = form_element.fields
      if (fields.find((f) => f.validateState === 'validating')) {
        setTimeout(() => {
          this.customPreValidateForm(form_name)
        }, 100)

        return
      }

      if (this.validateForm(form_name)) {
        this.validated = true
        this.trigger.valid = this.validated
        this.$emit('valid')
        return
      }

      this.validated = false
      this.trigger.valid = this.validated
      this.$emit('invalid')
    }, 100),

    addOperation(operationToAdd, afterOperation) {
      const existingOperation = this.operations.find(operation => operation.value === operationToAdd.value)
      // if not found, add it to the operation array
      if (existingOperation) {
        return
      }
      let insertionIndex = this.operations.length
      // find the biggest id in the operation array
      const biggestId = Math.max.apply(Math, this.operations.map(operation => operation.id))
      if (afterOperation) {
        const searchIndex = this.operations.findIndex(operation => operation.value === afterOperation)
        if (searchIndex >= 0) {
          insertionIndex = searchIndex + 1
        }
      }
      this.operations.splice(insertionIndex, 0, {
        id: biggestId + 1,
        name: operationToAdd.name,
        value: operationToAdd.value
      })
    }
  },

  watch: {
    validated() {
      if (this.validated) {
        this.$emit('valid')
      } else {
        this.$emit('invalid')
      }

      this.trigger.valid = this.validated
    }
  }
}
</script>
