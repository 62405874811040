<template>
    <el-tooltip
        class="item"
        effect="dark"
        :content="data.message"
        :disabled="!data.message || shouldHideTooltip"
        placement="bottom">

        <node-template :id="nodeId">

            <template v-slot:header>
                <span>Escalation</span>
            </template>

            <div
                v-if="data.type === ESCALATION_TYPE_SEQUENCE"
                class="row-inline mt-2">
                <div class="col-12 text-center">
                    <span><b>Type:</b> Sequence</span>
                </div>
            </div>

            <div
                v-if="data.type === ESCALATION_TYPE_SEQUENCE"
                class="row-inline mt-2">
                <div class="col-12 text-center">
                    <span><b>Workflow:</b> {{ getWorkflow() }}</span>
                </div>
            </div>

            <div
                v-if="data.type === ESCALATION_TYPE_SMS"
                class="row-inline mt-2">
                <div class="col-12 text-center">
                    <span><b>Type:</b> SMS</span>
                </div>
            </div>

            <div
                v-if="data.type === ESCALATION_TYPE_SMS"
                class="row-inline mt-2">
                <div class="col-12 text-center">
                    <span><b>Message:</b> {{ data.message }}</span>
                </div>
            </div>
        </node-template>
    </el-tooltip>
</template>

<script>
import { mapState } from 'vuex'
import { alohabot_node_mixin  } from '../../../mixins'

const ESCALATION_TYPE_SEQUENCE = 1
const ESCALATION_TYPE_SMS = 2

export default {
    name: 'escalation-node',
    mixins: [
        alohabot_node_mixin
    ],
    data() {
        return {
            ESCALATION_TYPE_SEQUENCE,
            ESCALATION_TYPE_SMS
        }
    },
    computed: {
        ...mapState(['workflows']),
    },
    methods: {
        /**
         * Selects the workflow name to be shown in the node
         *
         * @return {string}
         */
        getWorkflow() {
            const workflow = this.workflows.find(_workflow => _workflow.id === this.data.id)

            if (!workflow) {
                return 'Workflow not found'
            }

            return workflow.name
        }
    }
}
</script>
