var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "app-body", attrs: { id: "view" } },
    [
      _vm.companyHasTranscriptionEnabled
        ? _c(
            "div",
            {
              staticClass: "fixed-header padding pt-0 pb-0",
              style: { top: _vm.total_offset + 50 + "px" },
            },
            [
              _c("div", { staticClass: "row mb-0 mt-0" }, [
                _c("div", { staticClass: "col-12 p-2" }, [
                  _c("div", { staticClass: "d-flex" }, [
                    _c(
                      "div",
                      { staticClass: "d-flex flex-fill" },
                      [_c("comm-advanced-filters-wrapper")],
                      1
                    ),
                  ]),
                ]),
              ]),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("div", [
        _c(
          "div",
          { staticClass: "padding mt-5 pt-3" },
          [_c("transcription-reporting-panel")],
          1
        ),
      ]),
      _vm._v(" "),
      _c("el-backtop", {
        ref: "backtop",
        attrs: { target: "html", bottom: 20, right: 20 },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }