var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          staticClass: "dialog-padding auto conflicted_numbers_modal",
          attrs: {
            title: _vm.getTitle,
            "data-testid": "show-contact-phone-number-duplicates-dialog",
            visible: _vm.dialogTableVisible,
            width: _vm.isLargeEnough ? "600px" : "100%",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogTableVisible = $event
            },
          },
        },
        [
          _c(
            "el-table",
            { attrs: { data: [_vm.phone_number.contact] } },
            [
              _c("el-table-column", {
                attrs: { label: "Main Contact" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "router-link",
                          {
                            attrs: {
                              to: {
                                name: "Contact",
                                params: { contact_id: scope.row?.id },
                              },
                            },
                          },
                          [_vm._v(_vm._s(scope.row?.id))]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { property: "first_name", label: "First Name" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { property: "last_name", label: "Last Name" },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              staticClass: "pb-4 conflicted_contacts_table",
              attrs: { data: _vm.phone_number.conflicted_contacts },
            },
            [
              _c("el-table-column", {
                attrs: { label: "Conflicted Contacts" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "router-link",
                          {
                            attrs: {
                              to: {
                                name: "Contact",
                                params: { contact_id: scope.row?.id },
                              },
                            },
                          },
                          [_vm._v(_vm._s(scope.row?.id))]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { property: "first_name", label: "First Name" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { property: "last_name", label: "Last Name" },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm._t("trigger", function () {
        return [
          _c(
            "button",
            {
              staticClass:
                "btn btn-sm btn-outline-danger has_conflict_color_outline w-30 d-flex justify-content-center align-items-center mr-1",
              attrs: {
                slot: "reference",
                "data-testid":
                  "show-contact-phone-number-duplicates-slot-button",
              },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  _vm.dialogTableVisible = true
                },
              },
              slot: "reference",
            },
            [_c("i", { staticClass: "fa fa-exclamation" })]
          ),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }