<template>
    <div>
        <div class="row"
             v-if="hasPermissionTo('tag contact') && !show_only && contact.tag_ids">
            <div class="col-12">
                <el-popover ref="popover_tags"
                            title="Tags:"
                            placement="left"
                            width="400"
                            v-model="is_open"
                            data-testid="contact-tags-popover"
                            trigge="click">
                    <el-button slot="reference"
                               data-testid="contact-tags-button"
                               type="text text-dark-greenish text-sm p-0">
                        <i class="el-icon-circle-plus-outline"></i>
                        <span v-if="contact.tag_ids.length === 0">Add Tags</span>
                        <span v-else>Modify Tags</span>
                    </el-button>
                    <tag-creator ref="addTag"
                                 :category_prop="category"
                                 data-testid="contact-tags-creator"
                                 @success="addTagToContact"
                                 @show="showAdd"
                                 @hide="hideAdd">
                    </tag-creator>
                    <div class="row"
                         v-if="hasPermissionTo(['list tag', 'view tag']) && is_open"
                         v-show="!hide_add">
                        <div class="col-12">
                            <tag-selector v-model="contact.tag_ids"
                                          :category_prop="category"
                                          :custom_tags="contact.tags"
                                          :multiple="true"
                                          :no-collapse="noCollapse"
                                          data-testid="contact-tags-category-selector"
                                          @change="changeTags($event, contact)">
                            </tag-selector>
                        </div>
                    </div>
                </el-popover>
            </div>
        </div>
        <div class="row"
             v-if="contact.tags && contact.tags.length > 0"
             v-loading="loading_tag">
            <div class="col-12">
                <template v-if="summary">
                    <div v-for="tag in $options.filters.lastTags(contact.tags, 3)"
                         :key="tag.id"
                         class="text-xs width-300">
                        <i class="fa fa-circle"
                           :style="{ color: tag.color }"></i>
                        <span class="ml-1 text-grey-900">{{ tag.name }}</span>
                    </div>
                    <div class="d-flex align-items-center justify-content-center text-muted text-sm width-300"
                         v-if="contact.tags.length > 3">
                        ...
                    </div>
                </template>
                <template v-else>
                    <div v-for="tag in $options.filters.lastTags(contact.tags)"
                         :key="tag.id"
                         class="text-xs width-300">
                        <i class="fa fa-circle"
                           :style="{ color: tag.color }"></i>
                        <span class="ml-1 text-grey-900">{{ tag.name }}</span>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
    import auth from '../auth'
    import {acl_mixin} from '../mixins'
    import {mapState} from 'vuex'
    import TagCreator from './tag-creator'
    import * as TagCategory from '../constants/tag-categories'

    export default {
        mixins: [acl_mixin],

        components: {TagCreator},

        props: {
            contact: {
                required: true
            },

            show_only: {
                default: false,
                type: Boolean,
                required: false
            },

            summary: {
                default: false,
                type: Boolean,
                required: false
            },

            noCollapse: {
                type: Boolean,
                required: false,
                default: false
            },
        },

        data() {
            return {
                auth: auth,
                loading_tag: false,
                hide_add: false,
                is_open: false,
                category: TagCategory.CAT_CONTACTS,
            }
        },

        computed: {
            ...mapState('cache', ['current_company'])
        },

        methods: {
            showAdd() {
                this.hide_add = false
            },

            hideAdd() {
                this.hide_add = true
            },

            saveTags() {
                this.loading_tag = true
                axios.post('/api/v1/contact/' + this.contact.id + '/tag', {
                    tags: this.contact.tag_ids
                }).then(res => {
                    this.contact.tags = res.data
                    this.contact.tag_ids = this.contact.tags.map((o) => o.id)
                    this.$emit('contact_updated', this.contact)
                    this.loading_tag = false
                }).catch(err => {
                    this.$root.handleErrors(err.response)
                    this.loading_tag = false
                    this.contact.tag_ids = this.contact.tags.map((o) => o.id)
                })
            },

            addTagToContact(tag) {
                if (this.hasPermissionTo('tag contact')) {
                    this.contact.tags.push(tag)
                    this.contact.tag_ids = this.contact.tags.map((o) => o.id)
                    this.saveTags()
                }
            },

            changeTags(event, model) {
                if (this.hasPermissionTo('tag contact')) {
                    model.tag_ids = event
                    this.saveTags()
                }
            }
        }
    }
</script>
