<template>
    <div style="padding-left: 12px;padding-right: 12px;">
        <!-- Toll-Free Verification Notice -->
        <div
            class="row pb-3"
            style="padding-left: 3px !important"
            v-if="!isSimpSocial && current_company.show_toll_free_verification_banner && show_toll_free_banner">
            <el-alert
                show-icon
                type="info">
                    <span class="d-block mb-1 text-dark">
                        Please register your <b>Toll-Free Numbers</b> today to avoid disruption, our support team is available to help.
                        <a href="https://support.aloware.com/en/articles/9032064-toll-free-number-verification-in-aloware"
                           class="font-weight-bold">
                            Learn more
                        </a>
                    </span>
            </el-alert>
        </div>
        <!-- AMC Wizard -->
        <div
            class="row"
            v-if="!isSimpSocial">
            <div class="col-12">
                <div class="d-flex justify-content-end mb-3">
                    <el-button
                        type="success"
                        size="medium"
                        :disabled="amcWizardUsed"
                        @click="openAmcWizardModal">
                        Get Aloware Managed Compliance
                    </el-button>
                    <el-tooltip
                        class="item"
                        effect="dark"
                        :content="AmcWizardTooltipMessage"
                        placement="top"
                        :width="600"
                    >
                        <i class="material-icons text-lg cursor-pointer text-blue p-1 pt-2">info_outline</i>
                    </el-tooltip>
                </div>
            </div>
        </div>

        <!-- Title & Status Samples -->
        <div class="row">
            <div class="col-12">
                <div class="d-flex mb-2">
                    <div class="mr-auto d-flex">
                        <h5 class="text-dark">Compliance Registration</h5>
                    </div>
                    <div class="ml-auto d-flex">
                        <p
                            v-for="icon in icons"
                            :key="icon.name"
                            class="no-select mb-0 ml-5">
                            <i class="material-icons" :class="icon.class">{{ icon.name }}</i>
                            <span>{{ icon.status }}</span>
                        </p>
                    </div>
                </div>
            </div>
        </div>

        <!-- Current Compliance Status -->
        <div class="row">
            <div v-if="registrationSteps.length > 0" class="col-12">
                <p
                    v-for="(registrationStep, index) of registrationSteps"
                    :key="index"
                    class="mb-0 d-flex align-items-center">
                    <span class="font-weight-bold circle-number">{{ index + 1 }}</span>
                    <span class="font-weight-bold mr-1">{{ registrationStep.notice }}</span> {{ registrationStep.message }}
                </p>
            </div>
            <div class="col-12 my-2" v-if="!isSimpSocial">
                <a
                    class="text-blue font-weight-bold"
                    target="_blank"
                    href="https://support.aloware.com/en/articles/9032003-a2p-10dlc-fees-brand-registration-and-campaign-costs">
                    Fees breakdown
                </a>
            </div>
        </div>

        <!-- Compliance Wizard -->
        <div class="row">
            <div class="col-12">
                <el-collapse accordion :value="shouldAutoExpandBusinessRegistration">
                    <!-- Business Registration Step -->
                    <el-collapse-item name="1">
                        <template slot="title">
                            <p class="mb-0 font-120 d-flex align-items-center">
                                <span class="circle-number-title mr-2">1</span>
                                <span>Business Registration</span>
                            </p>
                            <!-- Status Icon -->
                            <i v-if="current_company.kyc_filled" :class="customerProfileTooltip.iconClass" class="ml-2 material-icons">
                                {{ customerProfileTooltip.iconName }}
                            </i>
                            <!-- Status Text -->
                            <p v-if="current_company.kyc_filled" class="ml-2 mb-0 font-120 d-flex align-items-center font-weight-bold">
                                <span :class="customerProfileTooltip.iconClass">{{ customerProfileTooltip.notice }}</span>
                            </p>
                        </template>

                        <div class="row">
                            <div class="col-12">
                                <p class="font-weight-bold">Unlock Outgoing Calls: Verify Your Business Today! 📞</p>
                                <small>Telecommunications regulatory authorities need certain information to make sure that calls and texts are from a real business. This also helps us fight spam and
                                    fraud. Please take a moment to provide this information so we can verify your business & unlock outgoing calls during your trial. 🚀</small>
                                <hr class="mt-0"/>
                            </div>
                            <div class="col-12">
                                <BusinessRegistration
                                    :customer_profile="customer_profile_in_use"
                                    :a2p_profile_bundle="a2p_profile_bundle_in_use"
                                    :a2p_brand="a2p_brand_in_use"
                                    @customer-profile-submitted="onCustomerProfileSubmitted"
                                />
                            </div>
                        </div>
                    </el-collapse-item>
                </el-collapse>
            </div>
        </div>

        <div class="row mt-3">
            <div class="col-12">
                <el-collapse accordion :value="shouldAutoExpandA2PBrand">
                    <el-collapse-item name="1">
                        <template slot="title">
                            <p class="mb-0 font-120 d-flex align-items-center">
                                <span class="circle-number-title mr-2">2</span>
                                <span>A2P Brand</span>
                            </p>
                            <!-- Make this icon follow the status and icons object -->
                            <i :class="a2pBrandTooltip.iconClass" class="ml-2 material-icons">
                                {{ a2pBrandTooltip.iconName }}
                            </i>
                            <p class="ml-2 mb-0 font-120 d-flex align-items-center font-weight-bold">
                                <span :class="a2pBrandTooltip.iconClass">{{ a2pBrandTooltip.notice }}</span>
                            </p>
                        </template>

                        <div class="row">
                            <div class="col-12">
                                <small>The A2P (Application-to-Person) Brand registration is a requirement of US Mobile Carriers to allow A2P messaging traffic in their network. The Business
                                    Information will be submitted to <a class="text-blue font-weight-bold" href="https://www.campaignregistry.com" target="_blank">The Campaign Registry (TCR)</a>, the third-party that handles the
                                    process in behalf of the US Mobile Carriers.</small>
                                <hr class="mt-0"/>
                            </div>
                            <div class="col-12" v-if="!isSimpSocial">
                                <h6>Fee Disclosure</h6>
                                <small>There is a passthrough fee of $44 to register the A2P Brand. If the information submitted does not match your legal business registration, it will be rejected by
                                    TCR and will incur a passthrough fee of $10 to appeal.</small>
                                <div class="mt-2">
                                    <span id="accepted_compliance_one_time_fee" class="page-anchor"/>
                                    <el-checkbox
                                        v-model="current_company.accepted_compliance_one_time_fee"
                                        :disabled="disableComplianceFeeCheckbox">
                                        I agree to the one-time non-refundable $44 Brand Registration Fee and $10 Appeal Fee in case the A2P Brand is rejected.
                                    </el-checkbox>
                                </div>
                            </div>

                            <div class="col-12">
                                <el-tooltip
                                    :disabled="!disableSubmitA2pBrandButton"
                                    :content="a2p_brand_message">
                                    <el-button
                                        class="mt-3"
                                        type="primary"
                                        size="small"
                                        v-if="!isSimpSocial"
                                        @click="submitA2pBrand"
                                        :disabled="disableSubmitA2pBrandButton">
                                        Submit A2P Brand
                                    </el-button>
                                </el-tooltip>
                            </div>

                            <div v-if="a2p_brand_in_use" class="col-12 mt-3">
                                <p
                                    v-if="[ComplianceBundleStatus.STATUS_REJECTED, ComplianceBundleStatus.STATUS_APPROVED].includes(a2p_brand_in_use?.status) && a2p_brand_in_use?.score !== null"
                                    class="mb-0">
                                    <b>Score:</b> {{ a2p_brand_in_use?.score }} / 100
                                    <a
                                        v-if="!isSimpSocial"
                                        class="ml-2 text-blue font-weight-bold"
                                        target="_blank"
                                        href="https://support.aloware.com/en/articles/9031981-a2p-10dlc-compliance-brand-registration-guide">
                                        Learn more
                                    </a>
                                </p>
                                <p
                                    v-if="a2p_brand_in_use?.submitted_at"
                                    class="mb-0">
                                    <b>Submitted at:</b> {{ a2p_brand_in_use?.submitted_at | fixFullDateLocal }}
                                </p>
                                <p class="mb-0">
                                    <b>Type:</b> {{ a2p_brand_in_use?.type_name || '-' }}
                                </p>
                                <p
                                    v-if="a2p_brand_in_use?.status === ComplianceBundleStatus.STATUS_REJECTED"
                                    class="mb-0">
                                    <b>Failure Reason:</b> {{ a2p_brand_in_use?.failure_reason }}
                                </p>
                            </div>
                        </div>
                    </el-collapse-item>
                </el-collapse>
            </div>
        </div>

        <div class="row mt-3">
            <div class="col-12">
                <el-collapse accordion :value="shouldAutoExpandA2pCampaignsCases">
                    <el-collapse-item name="1">
                        <template slot="title">
                            <p class="mb-0 font-120 d-flex align-items-center">
                                <span class="circle-number-title mr-2">3</span>
                                <span>A2P Campaigns</span>
                                <!-- Make this icon follow the status and icons object -->
                                <i :class="messagingServicesTooltip.iconClass"
                                   class="ml-2 material-icons">
                                    {{ messagingServicesTooltip.iconName }}
                                </i>
                                <p class="ml-2 mb-0 font-120 d-flex align-items-center font-weight-bold">
                                    <span :class="messagingServicesTooltip.iconClass">{{ messagingServicesTooltip.notice }}</span>
                                </p>
                            </p>
                        </template>

                        <small>
                            You must register at least ONE A2P Campaign to send outbound SMS. Each A2P Campaign can hold up to 400 Numbers.
                            <a
                                v-if="!isSimpSocial"
                                class="text-blue font-weight-bold"
                                target="_blank"
                                href="https://support.aloware.com/en/articles/9031988-a2p-10dlc-compliance-campaign-registration"
                            >
                                Learn More
                            </a>
                        </small>
                        <small v-if="disableRemoveLineFromA2pCampaign()">
                            <br>To remove or reassign a phone number from the campaign, kindly contact Aloware Support for assistance
                        </small>
                        <hr class="mt-0"/>

                        <div class="row d-flex">
                            <div class="ml-auto">
                                <el-button
                                    type="primary"
                                    size="mini"
                                    class="mr-2"
                                    :disabled="!a2pBrandApproved"
                                    @click="openComplianceWizard(1, [], true)">
                                    Quick Setup
                                </el-button>
                                <el-button
                                    type="secondary"
                                    size="mini"
                                    class="mr-3"
                                    :disabled="!a2pBrandApproved"
                                    @click="openComplianceWizard(1, [])">
                                    Advanced Setup
                                </el-button>
                            </div>
                        </div>

                        <el-table
                            :data="messaging_services"
                            v-loading="messaging_services_loading"
                            type="expand"
                            class="w-full">
                            <!-- Registered Lines in A2P Use Case -->
                            <el-table-column type="expand">
                                <template v-slot="props">
                                    <el-table
                                        :data="props.row.lines"
                                        @selection-change="(e) => handleRegisteredLineSelection(e, props.row.id)">

                                        <el-table-column
                                            type="selection"
                                            v-if="!disableRemoveLineFromA2pCampaign()"
                                            :selectable="(row) => !disableRemoveLineFromA2pCampaign()"
                                        />

                                        <el-table-column
                                            prop="id"
                                            label="ID"
                                            width="100"
                                        />

                                        <el-table-column prop="name"
                                                         label="Name">
                                            <template v-slot="scope">
                                                <router-link :to="{
                                                        name: 'Line Settings',
                                                        params: {
                                                            campaign_id: scope.row.id
                                                        }
                                                    }">
                                                    <span class="text-dark-greenish hover-lower-opacity">{{ scope.row.name }}</span>
                                                </router-link>
                                                <span class="label ml-2 p-1 text-xxs blue"
                                                      v-if="scope.row.has_local_presence">
                                                    Has Local Presence
                                                </span>
                                            </template>
                                        </el-table-column>

                                        <el-table-column
                                            align="center"
                                            label="10DLC Numbers">
                                            <template v-slot="scope">
                                                <!-- First phone number -->
                                                <span v-if="messagingServiceNumbers(props.row, scope.row).length > 0">
                                                    {{ firstMessagingServiceNumber(props.row, scope.row).phone_number | fixPhone }}
                                                    <span
                                                        v-if="isNumberHosted(firstMessagingServiceNumber(props.row, scope.row))"
                                                        v-popover:messaging-service-first-gray-hosted-remark
                                                        class="font-weight-bold">
                                                        *
                                                    </span>
                                                    <el-popover
                                                        v-if="isNumberHosted(firstMessagingServiceNumber(props.row, scope.row))"
                                                        ref="messaging-service-first-gray-hosted-remark"
                                                        placement="top"
                                                        width="30"
                                                        trigger="hover">
                                                        <p class="text-center mb-0">Gray Number</p>
                                                    </el-popover>
                                                </span>
                                                <span v-else>
                                                    -
                                                </span>
                                                <!-- Multiple phone numbers -->
                                                <span v-if="messagingServiceAdditionalNumbers(props.row, scope.row).length > 0">
                                                    <el-tag
                                                        v-popover:messaging-service-numbers
                                                        effect="dark"
                                                        size="mini"
                                                        class="ml-1">
                                                        + {{ messagingServiceAdditionalNumbers(props.row, scope.row).length }}
                                                    </el-tag>
                                                    <el-popover
                                                        ref="messaging-service-numbers"
                                                        placement="right"
                                                        width="200"
                                                        trigger="hover">
                                                        <p class="font-weight-bold text-center">Additional Numbers</p>
                                                        <ul>
                                                            <li
                                                                v-for="number in messagingServiceAdditionalNumbers(props.row, scope.row)"
                                                                :key="number.id">
                                                                {{ number.phone_number | fixPhone }}
                                                                <span
                                                                    v-if="isNumberHosted(number)"
                                                                    v-popover:messaging-service-gray-hosted-remark
                                                                    class="font-weight-bold">
                                                                    *
                                                                </span>
                                                                <el-popover
                                                                    v-if="isNumberHosted(number)"
                                                                    ref="messaging-service-gray-hosted-remark"
                                                                    placement="right"
                                                                    width="30"
                                                                    trigger="hover">
                                                                    <p class="text-center mb-0">Gray Number</p>
                                                                </el-popover>
                                                            </li>
                                                        </ul>
                                                    </el-popover>
                                                </span>
                                            </template>
                                        </el-table-column>

                                        <el-table-column
                                            align="center"
                                            label="Enrolled At">
                                            <template v-slot="scope">
                                                <span v-if="scope.row">
                                                    {{ firstMessagingServiceNumber(props.row, scope.row).messaging_service_assigned_at | fixFullDateTimeLocal }}
                                                </span>
                                            </template>
                                        </el-table-column>

                                        <el-table-column align="center">
                                            <template v-slot:header>
                                                <el-button
                                                    v-if="canTakeActionOnRegisteredLines"
                                                    type="danger"
                                                    size="mini"
                                                    class="mr-3"
                                                    @click="handleOpen()">
                                                    <i class="material-icons text-grey text-md">remove_circle_outline</i> Remove from campaign
                                                </el-button>
                                            </template>
                                        </el-table-column>
                                    </el-table>
                                </template>
                            </el-table-column>

                            <el-table-column
                                label="ID"
                                width="100"
                                prop="id"
                            >
                                <template v-slot="scope">
                                    {{ scope.row.id }}
                                </template>
                            </el-table-column>

                            <el-table-column
                                label="Use Case"
                                prop="use_case">
                                <template v-slot="scope">
                                    {{ getUseCaseName(scope.row.a2p_use_case) }}
                                    <span
                                        v-if="messagingServiceHasLocalPresenceLines(scope.row)"
                                        class="label ml-1 text-xxs blue"
                                    >
                                        For Local Presence
                                    </span>
                                </template>
                            </el-table-column>

                            <el-table-column
                                label="10DLC Numbers"
                                prop="incoming_numbers_count">
                                <template v-slot="scope">
                                    <span>{{ scope.row.incoming_numbers.length ?? '0' }} / 400</span>
                                </template>
                            </el-table-column>

                            <el-table-column
                                label="Status"
                                prop="status_name">
                                <template v-slot="scope">
                                    <b v-if="!scope.row.a2p_use_case" class="label gray-600">
                                        Draft
                                    </b>
                                    <el-tooltip
                                        v-else-if="!scope.row.a2p_use_case.submitted_at"
                                        placement="right"
                                        :content="scope.row.a2p_use_case.prevetting_status | prevettingUseCaseStatusExplanation">
                                        <b
                                            class="label"
                                            :class="scope.row.a2p_use_case.prevetting_status | prevettingStatusColor"
                                        >
                                            {{ a2pUseCasePrevettingStatusName(scope.row.a2p_use_case) }}
                                        </b>
                                    </el-tooltip>
                                    <el-tooltip
                                        v-else
                                        placement="right"
                                        :content="scope.row.a2p_use_case.status | useCaseStatusExplanation">
                                        <b
                                            class="label"
                                            :class="scope.row.a2p_use_case.status | a2pUseCaseStatusColor"
                                        >
                                            {{ a2pUseCaseStatusName(scope.row.a2p_use_case) }}
                                        </b>
                                    </el-tooltip>
                                </template>
                            </el-table-column>

                            <el-table-column
                                label="Submitted At"
                                prop="submitted_at">
                                <template v-slot="scope">
                                    <span v-if="!scope.row.a2p_use_case">{{ scope.row.submitted_at | fixFullDateTimeLocal }}</span>
                                    <span v-else-if="!scope.row.a2p_use_case.submitted_at">{{ scope.row.a2p_use_case.prevetting_submitted_at | fixFullDateTimeLocal }}</span>
                                    <span v-else>{{ scope.row.a2p_use_case.submitted_at | fixFullDateTimeLocal }}</span>
                                </template>
                            </el-table-column>

                            <el-table-column label="Actions">
                                <template v-slot="scope">
                                    <el-button
                                        circle
                                        :loading="deleteUseCaseLoading"
                                        :disabled="deleteUseCaseLoading"
                                        @click="openComplianceWizard(2, [], false, scope.row)"
                                    >
                                        <i class="material-icons text-grey">edit</i>
                                    </el-button>
                                    <el-button
                                        circle
                                        v-if="is_impersonating"
                                        :loading="deleteUseCaseLoading"
                                        :disabled="deleteUseCaseLoading"
                                        @click="confirmUseCaseDeletion(scope.row)"
                                    >
                                        <i class="material-icons text-grey">delete_for_ever</i>
                                    </el-button>
                                </template>
                            </el-table-column>
                        </el-table>

                        <div class="d-flex justify-content-end mt-3">
                            <el-pagination
                                layout="sizes, prev, pager, next"
                                :current-page.sync="messaging_services_pagination.page"
                                :page-sizes="[10, 20, 50, 100]"
                                :page-size.sync="messaging_services_pagination.size"
                                :total="messaging_services_pagination.total"
                                :disabled="messaging_services_pagination.length === 0"
                                @size-change="getMessagingServices(1)"
                                @current-change="getMessagingServices">
                            </el-pagination>
                        </div>

                        <el-dialog
                            title="Important Notice on Deregistration"
                            :visible.sync="form_visible"
                            :before-close="handleClose"
                            center>

                            <p>Hello, <b>{{ current_company.name }}</b></p>
                            <p>We noticed you're attempting to deregister lines along with their number/s. Please be informed:</p>
                            <ul>
                                <li>Unregistering a line can result in <b>delays of up to 30 days</b> before its number/s can be fully utilized again for <b>outbound messaging</b>.</li>
                                <li>Your number is still within its initial onboarding phase with the carrier, and frequent changes can cause longer wait times in the future.</li>
                            </ul>
                            <p><b>Accidentally registered these lines?</b> If so, you can proceed, but we strongly advise against frequent changes to ensure a smoother experience.</p>

                            <p>Are you sure you want to remove the line/s <b>#{{ selected_lines_to_detach.join(", #") }}</b> from this <B>A2P Campaign</B>?</p>
                            <template slot="footer">
                                <el-button
                                    type="info"
                                    class="ms-auto"
                                    @click="handleClose()">
                                    <strong>Cancel</strong>
                                </el-button>
                                <el-button
                                    type="danger"
                                    class="ms-auto"
                                    @click="detachLinesFromCampaign()">
                                    <i class="material-icons text-grey text-md">remove_circle_outline</i>
                                    <strong>Remove</strong>
                                </el-button>
                            </template>

                        </el-dialog>
                    </el-collapse-item>
                </el-collapse>
            </div>
        </div>

        <div class="row mt-3">
            <div class="col-12">
                <el-collapse accordion :value="shouldAutoExpandUnregisteredLines">
                    <el-collapse-item name="1">
                        <template slot="title">
                            <p class="mb-0 font-120 d-flex align-items-center">
                                <span class="circle-number-title mr-2">4</span>
                                <span>Unregistered Lines</span>
                                <!-- Make this icon follow the status and icons object -->
                                <i :class="unregisteredLinesTooltip.iconClass"
                                   class="ml-2 material-icons">
                                    {{ unregisteredLinesTooltip.iconName }}
                                </i>
                                <p class="ml-2 mb-0 font-120 d-flex align-items-center font-weight-bold">
                                    <span :class="unregisteredLinesTooltip.iconClass">{{ unregisteredLinesTooltip.notice }}</span>
                                </p>
                            </p>
                        </template>

                        <hr class="mt-0"/>

                        <el-table
                            :data="unregistered_lines"
                            v-loading="unregisteredLinesLoading"
                            class="w-full"
                            @selection-change="handleLineSelection">
                            <el-table-column type="selection"/>

                            <el-table-column prop="id"
                                             label="ID"
                                             width="100"/>

                            <el-table-column prop="name"
                                             label="Name">
                                <template v-slot="scope">
                                    <router-link :to="{
                                            name: 'Line Settings',
                                            params: {
                                                campaign_id: scope.row.id
                                            }
                                        }">
                                        <span class="text-dark-greenish hover-lower-opacity">{{ scope.row.name }}</span>
                                    </router-link>
                                    <span class="label ml-2 p-1 text-xxs blue"
                                          v-if="scope.row.has_local_presence">
                                        Has Local Presence
                                    </span>
                                </template>
                            </el-table-column>

                            <el-table-column>
                                <template v-slot:header>
                                    <span class="text-nowrap">Unregistered Numbers</span>
                                </template>
                                <template v-slot="scope">
                                    <!-- One phone number -->
                                    <span v-if="scope.row.incoming_numbers.length === 1">
                                        {{ scope.row.incoming_numbers[0]?.phone_number | fixPhone }}
                                    </span>
                                    <!-- Multiple phone numbers -->
                                    <span v-else-if="scope.row.incoming_numbers.length > 1">
                                        {{ scope.row.incoming_numbers[0]?.phone_number | fixPhone }}
                                        <el-tag
                                            type="danger"
                                            effect="dark"
                                            size="mini"
                                            class="ml-1 font-weight-bold">
                                            + {{ scope.row.incoming_numbers.length - 1 }}
                                        </el-tag>
                                    </span>
                                </template>
                            </el-table-column>

                            <el-table-column align="right">
                                <template v-slot:header>
                                    <el-popover v-if="canTakeActionOnUnregisteredLines && messaging_services_to_attach.length > 0"
                                                placement="left"
                                                trigger="click"
                                                @show="getMessagingServicesToAttach">
                                        <template>
                                            <el-table :data="filteredMessagingServices"
                                                      v-loading="messaging_services_to_attach_loading">
                                                <el-table-column width="280">
                                                    <template v-slot:header>
                                                        <el-input
                                                            v-model="messaging_service_search"
                                                            clearable
                                                            size="mini"
                                                            style="width: 100%"
                                                            placeholder="Type to search"
                                                        />
                                                    </template>
                                                    <template v-slot="scope">
                                                        #{{ scope.row.id }} - {{ getUseCaseName(scope.row.a2p_use_case) }} [{{ scope.row.incoming_numbers.length ?? '0' }} / 400]
                                                        <el-tooltip v-if="!possibleToAttachLine(scope.row) && isLineTypeValid(scope.row)"
                                                                    placement="right"
                                                                    :content="scope.row?.a2p_use_case?.status | statusExplanation">
                                                            <el-tag type="danger"
                                                                    effect="dark"
                                                                    size="mini">
                                                                Not Available [{{ scope.row.incoming_numbers.length + selectedLinesToAttachIncomingNumbersCount ?? '0' }} / 400]
                                                            </el-tag>
                                                        </el-tooltip>
                                                        <span class="label ml-1 text-xxs blue"
                                                              v-if="messagingServiceHasLocalPresenceLines(scope.row)">
                                                            For Local Presence
                                                        </span>
                                                    </template>
                                                </el-table-column>
                                                <el-table-column align="left">
                                                    <template v-slot="scope">
                                                        <el-tooltip
                                                            :disabled="possibleToAttachLine(scope.row)"
                                                            placement="right"
                                                            :content="reason">
                                                            <button class="btn btn-icon btn-sm"
                                                                    :class="possibleToAttachLine(scope.row) ? 'btn-primary' : 'btn-grey'"
                                                                    :loading="attaching_line_to_messaging_service"
                                                                    :disabled="!possibleToAttachLine(scope.row) || attaching_line_to_messaging_service"
                                                                    @click="attachLineToCampaign(scope.row.id)">
                                                                <i class="material-icons text-grey">add</i>
                                                            </button>
                                                        </el-tooltip>
                                                    </template>
                                                </el-table-column>
                                            </el-table>
                                        </template>

                                        <el-button type="primary"
                                                   size="mini"
                                                   class="mr-1"
                                                   slot="reference"
                                                   :disabled="!a2pBrandApproved">
                                            <i class="material-icons text-grey">open_in_new</i> Add to existing campaign
                                        </el-button>
                                    </el-popover>
                                </template>
                                <template v-slot="scope">
                                    <el-button v-if="scope.row?.a2p_use_case?.status === ComplianceBundleStatus.STATUS_REJECTED"
                                               circle
                                               :loading="deleteUseCaseLoading"
                                               :disabled="deleteUseCaseLoading"
                                               @click="confirmUseCaseDeletion(scope.row)">
                                        <i class="material-icons text-grey">replay</i>
                                    </el-button>
                                    <el-button v-if="scope.row?.a2p_use_case?.status === ComplianceBundleStatus.STATUS_APPROVED"
                                               circle
                                               :loading="deleteUseCaseLoading"
                                               :disabled="deleteUseCaseLoading"
                                               @click="confirmUseCaseDeletion(scope.row)">
                                        <i class="material-icons text-grey">delete_for_ever</i>
                                    </el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                    </el-collapse-item>
                </el-collapse>
            </div>
        </div>

        <!-- Agreement on the SMS fee -->
        <AgreementOnSmsFees
            :show_form="show_sms_fees_form"
            @close="closeSmsFeesForm"
            @agreed="onAgreedSmsFees"
        />

        <!-- A2P 10DLC Campaign Wizard -->
        <A2pCampaignWizard
            @next="handleNext"
            @close="closeA2pCampaignWizard"
        />

        <!-- Wizard Modal -->
        <el-dialog
            :visible.sync="amc_wizard_visible"
            width="70vw"
            top="4vh"
            title="Aloware Managed Compliance"
            append-to-body
            custom-class="wizard-dialog dialog-no-border"
            data-testid="amc-creator-dialog"
            @close="closeAmcWizardModal">
            <div slot="title"
                 data-testid="new-wizard-title">
                <h5 class="mb-0">
                    <img src="/assets/images/icons/amc-shield-check-mark.svg" alt="AMC Shield" style="width: 22px; height: 22px;"/>
                    Aloware Managed Compliance
                </h5>
            </div>
            <amc-wizard ref="amcWizard" :onWizardComplete="onWizardComplete"></amc-wizard>
        </el-dialog>
    </div>
</template>

<script>
import auth from '../../auth'
import {mapActions, mapGetters, mapState} from 'vuex'
import BusinessRegistration from './compliance/steps/business-registration'
import * as ComplianceBundleStatus from '../../constants/compliance-bundle-statuses'
import * as KYC_STATUSES from '../../../../assets/admin/constants/kyc-status'
import * as CompanyIssues from "../../constants/company-issues"
import {acl_mixin, compliance_mixin, campaign_mixin, kyc_mixin, chargebee_mixin} from '../../mixins'
import AgreementOnSmsFees from '../agreement-on-sms-fees'
import A2pCampaignWizard from './compliance/wizard'
import amcWizard from './compliance/amc-wizard.vue'

export default {
    components: {
        AgreementOnSmsFees,
        BusinessRegistration,
        A2pCampaignWizard,
        amcWizard
    },
    mixins: [
        campaign_mixin,
        compliance_mixin,
        kyc_mixin,
        acl_mixin,
        chargebee_mixin
    ],
    data() {
        return {
            KYC_STATUSES,
            ComplianceBundleStatus,
            is_impersonating: localStorage.getItem('impersonate'),
            customer_profile_in_use: null,
            a2p_brand_in_use: null,
            a2p_profile_bundle_in_use: null,
            messaging_services_pagination: {page: 1, size: 10, total: 0},
            messaging_services: [],
            selected_messaging_service: null,
            messaging_services_to_attach: [],
            ten_dlc_lines: [],
            selected_lines_to_attach: [],
            selected_lines_to_detach: [],
            a2p_campaign_wizard_visible: false,
            preSelectedLines: [],
            selected_registered_messaging_service: null,
            a2p_campaign_wizard_index: 0,
            icons: [
                {id: 0, class: 'text-grey', name: 'remove_circle', status: 'Not started', notice: '[Not Started]'},
                {id: ComplianceBundleStatus.STATUS_IN_REVIEW, class: 'text-blue', name: 'access_time_filled', status: 'In Review', notice: '[In Review]'},
                {id: ComplianceBundleStatus.STATUS_REJECTED, class: 'text-danger', name: 'cancel', status: 'Rejected', notice: '[Needs Attention]'},
                {id: ComplianceBundleStatus.STATUS_APPROVED, class: 'text-green', name: 'check_circle_outline', status: 'Approved', notice: '[Approved]'}
            ],
            messaging_service_search: '',
            messaging_services_loading: false,
            messaging_services_to_attach_loading: false,
            unregisteredLinesLoading: false,
            attaching_line_to_messaging_service: false,
            detaching_line_from_messaging_service: false,
            deleteUseCaseLoading: false,
            form_visible: false,
            unregistration_dialog_visible: false,
            show_toll_free_banner: false,
            agreed_on_sms_fees: auth?.user?.profile.agreed_on_sms_fees,
            show_sms_fees_form: false,
            a2p_brand_message: null,
            amc_wizard_visible: false,
            wizard_completed: false
        }
    },
    created() {
        this.customer_profile_in_use = this.current_company?.customer_profile
        this.a2p_brand_in_use = this.current_company?.a2p_brand
        this.a2p_profile_bundle_in_use = this.current_company?.a2p_trust_bundle

        // get Use Cases (messaging services)
        this.getMessagingServices()
        this.getMessagingServicesToAttach()

        // get all lines
        this.getAllLines()
        this.getUnregisteredLines()

        // messaging service events
        VueEvent.listen('messaging_services', (event) => {
            this.getMessagingServices()
            this.getMessagingServicesToAttach()
            this.getAllLines()
            this.getUnregisteredLines()
        })

        VueEvent.listen('compliance-lines-selected', (lineIds) => {
            this.preSelectedLines = lineIds
        })
    },
    mounted() {
        this.runDiagnosis()
    },
    methods: {
        ...mapActions([
            'setComplianceWizardDialogVisible',
            'setEasyComplianceWizard',
            'setUnregisteredLines'
        ]),
        runDiagnosis() {
            axios.get(`/api/v1/status/diagnosis`)
                .then(res => {
                    // if account has veryfiable Toll-Free Numbers
                    if (res?.data?.issue_codes?.includes(CompanyIssues.ISSUE_TOLL_FREE_SMS)) {
                        this.show_toll_free_banner = true;
                    }
                }).catch(err => {
                console.error(err)
            })
        },
        onCustomerProfileSubmitted() {
            // Fake it 'til we make it
            this.customer_profile_in_use = {
                status: ComplianceBundleStatus.STATUS_PENDING
            }
        },
        getMessagingServices(page = 1) {
            this.messaging_services_loading = true
            this.messaging_services_pagination.page = page

            axios
                .get('/api/v1/compliance/messaging-services', {
                    params: {
                        is_messaging_service: true,
                        per_page: this.messaging_services_pagination.size,
                        page,
                    },
                })
                .then(({data}) => {
                    this.messaging_services = data.data || []
                    this.messaging_services = this.messaging_services.filter(
                        service => !service.friendly_name.includes('[Auto-generated]')
                    );
                    this.messaging_services_pagination.total = data.total
                })
                .finally(() => {
                    this.messaging_services_loading = false
                })
        },
        getMessagingServicesToAttach() {
            this.messaging_services_to_attach_loading = true

            axios
                .get('/api/v1/compliance/messaging-services', {
                    params: {
                        is_messaging_service: true,
                        to_attach: true,
                    },
                })
                .then(({data}) => {
                    this.messaging_services_to_attach = data || []
                })
                .finally(() => {
                    this.messaging_services_to_attach_loading = false
                })
        },
        getAllLines() {
            axios
                .get('/api/v1/campaign', {
                    params: {
                        ten_dlc_lines: true,
                        is_lite: true,
                    },
                })
                .then(({data}) => {
                    this.ten_dlc_lines = data || []
                })
        },
        getUnregisteredLines() {
            this.unregisteredLinesLoading = true

            axios
                .get('/api/v1/campaign', {
                    params: {
                        unregistered_only: true,
                        is_lite: true,
                    },
                })
                .then(({data}) => {
                    this.setUnregisteredLines(data)
                })
                .finally(() => {
                    this.unregisteredLinesLoading = false
                })
        },
        getUseCaseName(a2p_use_case) {
            return this.fixToUcFirst(a2p_use_case?.use_case)
        },
        openSmsFeesForm() {
            this.show_sms_fees_form = true
        },
        closeSmsFeesForm() {
            this.show_sms_fees_form = false
        },
        onAgreedSmsFees() {
            this.agreed_on_sms_fees = true
            this.closeSmsFeesForm()
            this.submitA2pBrand()
        },
        openComplianceWizard(requested_step = null, lines = [], easy_wizard = false, messaging_service = null) {
            this.a2p_campaign_wizard_visible = true

            if (messaging_service) {
                this.messaging_service = messaging_service
                VueEvent.fire('edit_messaging_service', messaging_service)
            }

            VueEvent.fire('compliance_wizard_opened', {requested_step, lines, messaging_service})
            this.setComplianceWizardDialogVisible(true)
            this.setEasyComplianceWizard(easy_wizard)
        },
        handleNext(messaging_service) {
            this.messaging_service = messaging_service
            if (this.messaging_service) {
                this.getMessagingServices()
                this.getAllLines()
                this.getUnregisteredLines()
            }
        },
        closeA2pCampaignWizard() {
            this.a2p_campaign_wizard_visible = false
            this.setComplianceWizardDialogVisible(false)
            this.setEasyComplianceWizard(false)
            this.messaging_service = null
        },
        confirmUseCaseDeletion(messaging_service) {
            let message = `Are you sure you want to delete Messaging Service #${messaging_service.id}?`

            if (messaging_service?.a2p_use_case) {
                message = `Are you sure you want to delete A2P Use Case of Messaging Service #${messaging_service.id}?`
            }

            this.$confirm(message, 'Confirm', {
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                customClass: 'width-500 fixed',
                type: 'warning',
            }).then(() => {
                this.deleteUseCase(messaging_service.id)
            })
        },
        deleteUseCase(id) {
            this.deleteUseCaseLoading = true

            axios
                .delete(`/api/v1/compliance/messaging-services/${id}/a2p-use-case`)
                .then(() => {
                    this.getMessagingServices()
                    this.getAllLines()
                    this.getMessagingServicesToAttach()
                    this.getUnregisteredLines()
                    this.$notify({
                        duration: 2000,
                        title: 'A2P Campaign',
                        message: 'A2P Campaign deleted successfully',
                        type: 'success',
                        showClose: true,
                        position: 'top-right'
                    })
                })
                .catch((err) => {
                    this.$root.handleErrors(err.response)
                })
                .finally(() => {
                    this.deleteUseCaseLoading = false
                })
        },
        handleLineSelection(rows) {
            this.selected_lines_to_attach = rows.map(({id}) => id)
        },
        handleRegisteredLineSelection(rows, messaging_service_id) {
            this.selected_lines_to_detach = rows.map(({id}) => id)
            this.selected_registered_messaging_service = messaging_service_id
        },
        attachLineToCampaign(id) {
            this.attaching_line_to_messaging_service = true
            let line_ids = this.selected_lines_to_attach
            // Remove the line (row) unregistered_lines immediately after successful clicking attach.
            this.selected_lines_to_attach = []
            let current_unregistered_lines = this.unregistered_lines
            let new_unregistered_lines = this.unregistered_lines.filter(line => !line_ids.includes(line.id))
            this.setUnregisteredLines(new_unregistered_lines)

            axios
                .post(`/api/v1/compliance/messaging-services/${id}/attach-lines`, {
                    line_ids: line_ids,
                })
                .then((res) => {
                    this.$notify({
                        duration: 2000,
                        title: 'Campaign',
                        message: res.data.message,
                        type: 'success',
                        showClose: true,
                        position: 'top-right'
                    })
                })
                .catch((err) => {
                    this.$root.handleErrors(err.response)
                    this.setUnregisteredLines(current_unregistered_lines)
                })
                .finally(() => {
                    this.attaching_line_to_messaging_service = false
                })
        },
        detachLinesFromCampaign() {
            this.detaching_line_from_messaging_service = true

            // Remove the line (row) registered_lines immediately after successful clicking attach.
            let current_messaging_services = this.messaging_services
            this.messaging_services = this.messaging_services.map(messaging_service => {
                return {
                    ...messaging_service,
                    lines: messaging_service.lines.filter(line => !this.selected_lines_to_detach.includes(line.id))
                };
            });

            axios
                .post(`/api/v1/compliance/messaging-services/${this.selected_registered_messaging_service}/detach-lines`, {
                    line_ids: this.selected_lines_to_detach,
                })
                .then((res) => {
                    this.$notify({
                        duration: 2000,
                        title: 'Campaign',
                        message: res.data.message,
                        type: 'success',
                        showClose: true,
                        position: 'top-right'
                    })
                    this.form_visible = false
                    this.selected_registered_messaging_service = null
                })
                .catch((err) => {
                    this.$root.handleErrors(err.response)
                    this.messaging_services = current_messaging_services
                })
                .finally(() => {
                    this.detaching_line_from_messaging_service = false
                    this.selected_lines_to_detach = []
                })
        },
        handleOpen() {
            this.form_visible = true
        },
        handleClose() {
            this.form_visible = false
        },
        /**
         * Disables the ability to remove any line from an A2P Campaign
         *
         * @param {boolean} campaign
         */
        disableRemoveLineFromA2pCampaign() {
            // Hide this functionality from all accounts except for Impersonated admins, SimpSocial and ModGen.
            if (this.is_impersonating || this.isSimpSocial || this.isModGen) {
                return false
            }
            return true
        },
        messagingServiceNumbers(messaging_service, campaign) {
            if (!messaging_service || !campaign) {
                return []
            }

            return messaging_service.incoming_numbers.filter(incoming_number => incoming_number.campaign_id === campaign.id)
        },
        messagingServiceAdditionalNumbers(messaging_service, campaign) {
            if (!messaging_service || !campaign) {
                return []
            }

            return this.messagingServiceNumbers(messaging_service, campaign).slice(1)
        },
        firstMessagingServiceNumber(messaging_service, campaign) {
            if (!messaging_service || !campaign) {
                return {}
            }

            if (this.messagingServiceNumbers(messaging_service, campaign).length === 0) {
                return {}
            }

            return this.messagingServiceNumbers(messaging_service, campaign)[0]
        },
        isNumberHosted(incoming_number) {
            if (!incoming_number) {
                return false
            }

            return incoming_number.hosted_carrier_name !== null
        },
        submitA2pBrand() {
            if (this.shouldSeeSMSAgreement) {
                return this.openSmsFeesForm()
            }

            // Lock the button
            this.loading = true

            axios.post('/api/v1/compliance/a2p-brands', {force_register: false}).then(data => {
                this.$message({
                    showClose: true,
                    message: 'A2P Brand has been submitted successfully.',
                    type: 'success',
                })

                // Fake it 'til we make it
                this.a2p_brand_in_use = {
                    status: ComplianceBundleStatus.STATUS_PENDING
                }
            }).catch(error => {
                let message = 'Request failed' // Default error message
                if (error.response && error.response.data && error.response.data.message) {
                    // Extract the message from the response data
                    message = error.response.data.message
                } else if (error.response && error.response.statusText) {
                    // Use statusText if no message is present in data
                    message = error.response.statusText
                } else if (error.message) {
                    // Last resort: the error message Axios provides
                    message = error.message
                }

                this.$message({
                    message: message,
                    type: 'error',
                })
            }).finally(() => {
                this.loading = false
            })
        },
        a2pUseCaseStatusName(a2p_use_case) {
            if (!a2p_use_case) {
                return 'Draft'
            }

            if (a2p_use_case.status === ComplianceBundleStatus.STATUS_STAGED) {
                return 'In Review'
            }

            return a2p_use_case.status_name
        },
        a2pUseCasePrevettingStatusName(a2p_use_case) {
            if (!a2p_use_case) {
                return 'Draft'
            }

            if (a2p_use_case.prevetting_status === ComplianceBundleStatus.STATUS_APPROVED) {
                return 'In Review'
            }

            return a2p_use_case.prevetting_status_name
        },
        onWizardComplete() {
            this.wizard_completed = true
        },
        openAmcWizardModal() {
            this.amc_wizard_visible = true
        },
        closeAmcWizardModal() {
            this.amc_wizard_visible = false
            this.$refs.amcWizard.resetWizard()
        }
    },
    computed: {
        ...mapState(['shouldOpenComplianceWizard', 'compliance_wizard_dialog_visible']),
        ...mapState('cache', ['current_company']),
        ...mapGetters('cache', [
            'isSimpSocial',
            'isModGen'
        ]),

        /**
         * Checks if the Submit A2P Brand button should be disabled
         *
         * @return {boolean}
         */
        disableSubmitA2pBrandButton() {
            // Disable if Company has no subscription
            if (!this.current_company.subscription) {
                this.a2p_brand_message = 'Please subscribe to continue.'
                return true
            }

            // Disable if Company has no card added
            if (!this.cardAdded) {
                this.a2p_brand_message = 'Please add a card to continue.'
                return true
            }

            // Disable if Company has not accepted the Compliance Fees, yet
            if (!this.current_company.accepted_compliance_one_time_fee) {
                this.a2p_brand_message = 'Accept the One-Time fee first.'
                return true
            }

            // Disable if Company has not filled KYC information
            if (!this.current_company.kyc_filled) {
                this.a2p_brand_message = 'Submit KYC information first.'
                return true
            }

            // Avoid multiple submissions
            if (this.loading) {
                return true
            }

            // Cleanup tooltip
            this.a2p_brand_message = 'A2P Brand already submitted.'

            // If all we have a brand in use, we disable the button
            return !!this.a2p_brand_in_use && !this.a2pBrandRejected
        },

        /**
         * Checks if the Compliance Fees checkbox should be disabled
         *
         * @return {boolean}
         */
        disableComplianceFeeCheckbox() {
            return this.a2p_brand_in_use && this.current_company.accepted_compliance_one_time_fee
        },

        /**
         * Checks if the Customer Profile is Registered
         *
         * @return {boolean}
         */
        isCustomerProfileRegistered() {
            return this.customer_profile_in_use?.status !== ComplianceBundleStatus.STATUS_STAGED && this.isKYCFilled
        },

        /**
         * Checks if the Customer Profile is Rejected
         *
         * @return {boolean}
         */
        isCustomerProfileRejected() {
            return this.customer_profile_in_use?.status === ComplianceBundleStatus.STATUS_REJECTED
        },

        /**
         * Checks if the A2P Brand is Registered
         *
         * @return {boolean}
         */
        a2pBrandRegistered() {
            return this.a2p_brand_in_use && this.a2p_brand_in_use.status !== ComplianceBundleStatus.STATUS_STAGED
        },

        /**
         * Checks if the A2P Brand is Approved
         *
         * @return {boolean}
         */
        a2pBrandApproved() {
            return this.a2p_brand_in_use && this.a2p_brand_in_use.status === ComplianceBundleStatus.STATUS_APPROVED
        },

        /**
         * Checks if the A2P Brand is Rejected
         *
         * @return {boolean}
         */
        a2pBrandRejected() {
            return this.a2p_brand_in_use && this.a2p_brand_in_use.status === ComplianceBundleStatus.STATUS_REJECTED
        },

        customerProfileInReview() {
            let statuses = [
                ComplianceBundleStatus.STATUS_STAGED,
                ComplianceBundleStatus.STATUS_DRAFT,
                ComplianceBundleStatus.STATUS_PENDING,
                ComplianceBundleStatus.STATUS_IN_REVIEW
            ]
            return statuses.includes(this.customer_profile_in_use?.status)
        },

        customerProfileRejected() {
            return this.customer_profile_in_use?.status === ComplianceBundleStatus.STATUS_REJECTED
        },

        /**
         * Decides which tooltip to show for the Customer Profile based on its status
         *
         * @return {object}
         */
        customerProfileTooltip() {
            // If the customer profile object is not yet populated, we show the `not started` icon
            if (!this.customer_profile_in_use) {
                /** @var {object} icon */
                let icon = this.icons.find(({id}) => id === 0)

                return {
                    content: icon.status,
                    iconClass: icon.class,
                    iconName: icon.name,
                    notice: icon.notice
                }
            }

            if (this.a2p_profile_bundle_in_use?.prevetting_status === ComplianceBundleStatus.STATUS_REJECTED) {
                let icon = this.icons.find(({id}) => id === ComplianceBundleStatus.STATUS_REJECTED)

                return {
                    content: icon.status,
                    iconClass: icon.class,
                    iconName: icon.name,
                    notice: icon.notice
                }
            }

            /** @var {int} status */
            let status = this.customer_profile_in_use?.status
            if (this.kycApproved && status === ComplianceBundleStatus.STATUS_APPROVED) {
                status = ComplianceBundleStatus.STATUS_APPROVED
            } else if (this.kycPending || this.customerProfileInReview) {
                // Draft, pending review and in review are all in review
                status = ComplianceBundleStatus.STATUS_IN_REVIEW
            } else if (this.kycRejected || this.customerProfileRejected) {
                status = ComplianceBundleStatus.STATUS_REJECTED
            }

            /** @var {object} icon - Decide icon based on status */
            let icon = this.icons.find(({id}) => id === status)

            return {
                content: icon.status,
                iconClass: icon.class,
                iconName: icon.name,
                notice: icon.notice
            }
        },

        /**
         * Decides which tooltip to show for the A2P Brand based on its status
         *
         * @return {object}
         */
        a2pBrandTooltip() {
            // If the a2p brand object is not yet populated, we show the `not started` icon
            if (!this.a2p_brand_in_use) {
                /** @var {object} icon */
                let icon = this.icons.find(({id}) => id === 0)

                return {
                    content: icon.status,
                    iconClass: icon.class,
                    iconName: icon.name,
                    notice: icon.notice
                }
            }

            // we use same icon for pre-staged and pending, and in review
            let status = this?.a2p_brand_in_use?.status
            switch (status) {
                case ComplianceBundleStatus.STATUS_STAGED:
                case ComplianceBundleStatus.STATUS_PENDING:
                case ComplianceBundleStatus.STATUS_IN_REVIEW:
                    status = ComplianceBundleStatus.STATUS_IN_REVIEW
                    break
            }

            let icon = this.icons.find(({id}) => id === status)

            return {
                content: icon.status,
                iconClass: icon.class,
                iconName: icon.name,
                notice: icon.notice,
            }
        },

        messagingServicesTooltip() {
            let icon = this.icons.find(({id}) => id === 0)

            if (!this.hasMessagingServices) {
                return {
                    content: icon.status,
                    iconClass: icon.class,
                    iconName: icon.name,
                    notice: icon.notice,
                }
            }

            if (this.hasApprovedUseCases && !this.hasFailedUseCases) {
                icon = this.icons.find(({id}) => id === ComplianceBundleStatus.STATUS_APPROVED)
            }

            if (this.hasFailedUseCases) {
                icon = this.icons.find(({id}) => id === ComplianceBundleStatus.STATUS_REJECTED)
            }

            if (this.hasInReviewUseCases || this.hasInReviewPrevettingUseCases) {
                icon = this.icons.find(({id}) => id === ComplianceBundleStatus.STATUS_IN_REVIEW)
            }

            return {
                content: icon.status,
                iconClass: icon.class,
                iconName: icon.name,
                notice: icon.notice,
            }
        },

        unregisteredLinesTooltip() {
            let icon = this.icons.find(({id}) => id === 0)

            if (this.hasMessagingServices && this.hasUnregisteredLines) {
                icon = this.icons.find(({id}) => id === ComplianceBundleStatus.STATUS_REJECTED)
            }

            if (this.hasMessagingServices && !this.hasUnregisteredLines) {
                icon = this.icons.find(({id}) => id === ComplianceBundleStatus.STATUS_APPROVED)
            }

            let notice = icon.notice

            // Don't show `[Approved]` for unregistered lines
            if (icon.id === ComplianceBundleStatus.STATUS_APPROVED) {
                notice = null
            }

            return {
                content: icon.status,
                iconClass: icon.class,
                iconName: icon.name,
                notice: notice,
            }
        },

        registrationSteps() {
            const steps = []

            steps.push({
                message: 'Submit your A2P Brand Registration by providing relevant information about your business.',
                notice: this.isCustomerProfileRegistered && !this.isCustomerProfileRejected ? '[DONE]' : '[MISSING]'
            })
            steps.push({
                message: 'After your A2P Brand Registration is approved, then you will receive a TCR (The Campaign Registry) score.',
                notice: this.a2pBrandRegistered && !this.a2pBrandRejected ? '[DONE]' : '[MISSING]'
            })
            steps.push({
                message: 'Now create A2P Campaigns for approval by submitting your use case and included lines.',
                notice: this.hasMessagingServices && !this.hasFailedUseCases ? '[DONE]' : '[MISSING]'
            })
            steps.push({
                message: 'Finally, select all unregistered lines and register them within an A2P Campaign. All unregistered 10DLC lines will be blocked from sending SMS/MMS',
                notice: this.hasMessagingServices && !this.hasUnregisteredLines ? '[DONE]' : '[MISSING]'
            })

            return steps
        },

        filteredMessagingServices() {
            if (this.messaging_service_search) {
                return this.messaging_services_to_attach.filter(({id, a2p_use_case}) =>
                    id.toString().includes(this.messaging_service_search) ||
                    a2p_use_case?.use_case?.toLowerCase()?.includes(this.messaging_service_search?.toLowerCase())
                )
            }

            return this.messaging_services_to_attach
        },

        canTakeActionOnUnregisteredLines() {
            return this.selected_lines_to_attach.length > 0
        },

        shouldAutoExpandBusinessRegistration() {
            return !this.current_company.kyc_filled || this.isCustomerProfileRejected ? '1' : '0'
        },

        shouldAutoExpandA2PBrand() {
            return !this.a2p_brand_in_use || this.a2pBrandRejected ? '1' : '0'
        },

        shouldAutoExpandA2pCampaignsCases() {
            return this.hasMessagingServices && this.hasFailedUseCases ? '1' : '0'
        },

        shouldAutoExpandUnregisteredLines() {
            return this.hasMessagingServices && this.hasUnregisteredLines ? '1' : '0'
        },

        hasMessagingServices() {
            return this.messaging_services.length > 0
        },

        // function to get the approved use cases
        hasApprovedUseCases() {
            return this.messaging_services.filter(({a2p_use_case}) => a2p_use_case?.status === ComplianceBundleStatus.STATUS_APPROVED).length > 0
        },

        hasFailedUseCases() {
            return this.messaging_services.filter(({a2p_use_case}) => a2p_use_case?.prevetting_status === ComplianceBundleStatus.STATUS_REJECTED || a2p_use_case?.status === ComplianceBundleStatus.STATUS_REJECTED).length > 0
        },

        hasInReviewUseCases() {
            // let has_pending_review_use_cases = this.messaging_services.filter(({a2p_use_case}) => a2p_use_case?.prevetting_status === ComplianceBundleStatus.STATUS_PENDING).length > 0
            let has_in_review_use_cases = this.messaging_services.filter(({a2p_use_case}) => a2p_use_case?.status === ComplianceBundleStatus.STATUS_IN_REVIEW).length > 0

            return has_in_review_use_cases
        },

        hasInReviewPrevettingUseCases() {
            let has_in_review_prevetting_use_cases = this.messaging_services.filter(({a2p_use_case}) => a2p_use_case?.status === ComplianceBundleStatus.STATUS_STAGED).length > 0

            return has_in_review_prevetting_use_cases
        },

        hasUnregisteredLines() {
            return this.unregistered_lines.length > 0
        },

        shouldSeeSMSAgreement() {
            return !this.agreed_on_sms_fees && this.isIndependentOrNotResold
        },

        AmcWizardTooltipMessage() {
            if (this.amcWizardUsed) {
                return "We've already received your request. Please ensure that your business information has been submitted in the form and the necessary business document has been uploaded. Someone from our team will reach out to you. Thank you.";
            }
            return 'Experience seamless A2P registration and get onboarded fast—click to simplify compliance!'
        },
        amcWizardUsed() {
            return (this.current_company.managed_compliance_requested_at !== null) || this.wizard_completed
        }
    },
    watch: {
        shouldOpenComplianceWizard(value) {
            this.openComplianceWizard()
        },
    },
    filters: {
        statusColor(status) {
            return (
                {
                    [ComplianceBundleStatus.STATUS_STAGED]: 'gray-600',
                    [ComplianceBundleStatus.STATUS_DRAFT]: 'yellow-600',
                    [ComplianceBundleStatus.STATUS_PENDING]: 'yellow-600',
                    [ComplianceBundleStatus.STATUS_IN_REVIEW]: 'blue-600',
                    [ComplianceBundleStatus.STATUS_REJECTED]: 'red-600',
                    [ComplianceBundleStatus.STATUS_APPROVED]: 'green-600',
                }[status] || 'black-300'
            )
        },
        prevettingStatusColor(status) {
            return (
                {
                    [ComplianceBundleStatus.STATUS_PENDING]: 'yellow-600',
                    [ComplianceBundleStatus.STATUS_REJECTED]: 'red-600',
                    [ComplianceBundleStatus.STATUS_APPROVED]: 'blue-600',
                }[status] || 'black-300'
            )
        },
        a2pUseCaseStatusColor(status) {
            return (
                {
                    [ComplianceBundleStatus.STATUS_STAGED]: 'blue-600',
                    [ComplianceBundleStatus.STATUS_DRAFT]: 'blue-600',
                    [ComplianceBundleStatus.STATUS_PENDING]: 'blue-600',
                    [ComplianceBundleStatus.STATUS_IN_REVIEW]: 'blue-600',
                    [ComplianceBundleStatus.STATUS_REJECTED]: 'red-600',
                    [ComplianceBundleStatus.STATUS_APPROVED]: 'green-600',
                }[status] || 'black-300'
            )
        },
        statusExplanation(status) {
            return (
                {
                    [ComplianceBundleStatus.STATUS_STAGED]: 'Waiting for approval of A2P Brand.',
                    [ComplianceBundleStatus.STATUS_DRAFT]: 'Waiting for approval of A2P Brand.',
                    [ComplianceBundleStatus.STATUS_PENDING]: 'May take up to 48 hours to process.',
                    [ComplianceBundleStatus.STATUS_IN_REVIEW]: 'In Review.',
                    [ComplianceBundleStatus.STATUS_REJECTED]: 'Our carrier will provide a rejection reason. Action needed.',
                    [ComplianceBundleStatus.STATUS_APPROVED]: 'Use case is approved by our carrier. No further action needed.',
                }[status] || ''
            )
        },
        useCaseStatusExplanation(status) {
            return (
                {
                    [ComplianceBundleStatus.STATUS_STAGED]: 'In Review by our Team.',
                    [ComplianceBundleStatus.STATUS_IN_REVIEW]: 'In Review by our Carrier.',
                    [ComplianceBundleStatus.STATUS_REJECTED]: 'Our Carrier will provide a Rejection Reason. Action needed.',
                    [ComplianceBundleStatus.STATUS_APPROVED]: 'A2P Campaign is Approved by our Carrier. No further action needed.',
                }[status] || ''
            )
        },
        prevettingUseCaseStatusExplanation(status) {
            return (
                {
                    [ComplianceBundleStatus.STATUS_PENDING]: 'Our internal team is reviewing your A2P Campaign Registration.',
                    [ComplianceBundleStatus.STATUS_APPROVED]: 'Our internal team is reviewing your A2P Campaign Registration.',
                    [ComplianceBundleStatus.STATUS_REJECTED]: 'Our internal team provided a Rejection Reason. Action needed.',
                }[status] || ''
            )
        },
    },
}
</script>

<style scoped>
.text-grey {
    color: inherit !important;
    opacity: 0.7;
}
</style>
