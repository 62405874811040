var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.campaign
    ? _c(
        "div",
        { staticClass: "app-body", attrs: { id: "view" } },
        [
          _vm.hasPermissionTo("view campaign")
            ? _c(
                "el-dialog",
                {
                  attrs: {
                    title: _vm.campaign.name,
                    visible: _vm.dialogVisible,
                    width: "50%",
                  },
                  on: {
                    "update:visible": function ($event) {
                      _vm.dialogVisible = $event
                    },
                  },
                },
                [_c("campaign-details", { attrs: { campaign: _vm.campaign } })],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "fixed-header padding pt-0 pb-0",
              style: { top: _vm.total_offset + 50 + "px" },
            },
            [
              _c("div", { staticClass: "row mb-0 mt-0" }, [
                _c("div", { staticClass: "col-12 p-2" }, [
                  _c("div", { staticClass: "d-flex" }, [
                    _c(
                      "div",
                      { staticClass: "d-flex flex-fill" },
                      [
                        _c("comm-advanced-filters-wrapper", {
                          attrs: { campaign_id: _vm.campaign_id },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "ml-auto" },
                      [
                        _vm.hasPermissionTo("update campaign")
                          ? _c(
                              "router-link",
                              {
                                attrs: {
                                  to: {
                                    name: "Line Settings",
                                    params: { campaign_id: _vm.campaign_id },
                                  },
                                },
                              },
                              [
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-md greenish ml-2",
                                    staticStyle: { height: "40px" },
                                  },
                                  [
                                    _c("i", { staticClass: "fa fa-cogs" }),
                                    _vm._v(
                                      "\n                                Settings\n                            "
                                    ),
                                  ]
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-md new-blue ml-1",
                            staticStyle: { height: "40px" },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.handleCommand("view")
                              },
                            },
                          },
                          [
                            _c("i", { staticClass: "fa fa-info-circle" }),
                            _vm._v(
                              "\n                            View Details\n                        "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.section === "Activities",
                  expression: "section === 'Activities'",
                },
              ],
            },
            [
              _vm.hasPermissionTo("list report")
                ? _c("div", { staticClass: "padding pt-3" }, [
                    _c("div", { staticClass: "row" }, [
                      _c(
                        "div",
                        { staticClass: "col-12 p-0 mt-5" },
                        [
                          _c("new-summary-report", {
                            attrs: { campaign_id: _vm.campaign_id },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.hasPermissionTo("list report") && _vm.show_graph
                ? _c("div", { staticClass: "padding" }, [
                    _c("div", { staticClass: "row box" }, [
                      _c("div", { staticClass: "col-12" }, [
                        _c("div", { staticClass: "no-effect" }, [
                          _c(
                            "div",
                            { staticClass: "box-body" },
                            [
                              _c("activity-graph", {
                                attrs: {
                                  base: "campaign",
                                  campaign_id: _vm.campaign_id,
                                  is_first_load: _vm.reports_first_load,
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ]),
                    ]),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.hasPermissionTo("list communication")
                ? _c("div", { staticClass: "padding" }, [
                    _c("div", { staticClass: "row box" }, [
                      _c("div", { staticClass: "col-12" }, [
                        _c("div", { staticClass: "no-effect" }, [
                          _c(
                            "div",
                            { staticClass: "box-body" },
                            [
                              _c("comm-log-table", {
                                attrs: { campaign_id: _vm.campaign_id },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ]),
                    ]),
                  ])
                : _vm._e(),
            ]
          ),
          _vm._v(" "),
          _vm.section === "Transcriptions" &&
          _vm.shouldDisplayTranscriptionSections
            ? _c("div", [
                _c(
                  "div",
                  { staticClass: "padding mt-5 pt-3" },
                  [
                    _c("transcription-reporting-panel", {
                      attrs: { campaign_id: _vm.campaign_id },
                    }),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("el-backtop", {
            ref: "backtop",
            attrs: { target: "html", bottom: 20, right: 20 },
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }