var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.hasPermissionTo("create contact")
    ? _c(
        "div",
        { attrs: { "data-testid": "contact-creator-wrapper" } },
        [
          _c(
            "el-dialog",
            {
              staticClass: "dialog-padding auto",
              attrs: {
                title: "Create a new contact",
                width: _vm.isLargeEnough ? "700px" : "100%",
                visible: _vm.dialogVisible,
                "before-close": _vm.beforeCloseModal,
                "append-to-body": "",
                "data-testid": "create-contact-dialog",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogVisible = $event
                },
              },
            },
            [
              _c(
                "el-form",
                {
                  ref: "create_contact",
                  attrs: {
                    "data-testid": "create-contact-form",
                    "label-width": "200px",
                    "label-position": "top",
                    model: _vm.new_contact,
                    rules: _vm.rules_create_contact,
                  },
                  nativeOn: {
                    submit: function ($event) {
                      $event.preventDefault()
                      return _vm.createContact.apply(null, arguments)
                    },
                  },
                },
                [
                  _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      { staticClass: "col-6" },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "First Name (Optional)",
                              prop: "first_name",
                              "data-testid": "first-name-form-item",
                            },
                          },
                          [
                            _c("el-input", {
                              attrs: {
                                placeholder: "John",
                                "data-testid": "first-name-input",
                              },
                              model: {
                                value: _vm.new_contact.first_name,
                                callback: function ($$v) {
                                  _vm.$set(_vm.new_contact, "first_name", $$v)
                                },
                                expression: "new_contact.first_name",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-6" },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "Last Name (Optional)",
                              prop: "last_name",
                              "data-testid": "last-name-form-item",
                            },
                          },
                          [
                            _c("el-input", {
                              attrs: {
                                placeholder: "Doe",
                                "data-testid": "last-name-input",
                              },
                              model: {
                                value: _vm.new_contact.last_name,
                                callback: function ($$v) {
                                  _vm.$set(_vm.new_contact, "last_name", $$v)
                                },
                                expression: "new_contact.last_name",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      { staticClass: "col-6" },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "Phone Number",
                              prop: "phone_number",
                              "data-testid": "phone-form-item",
                            },
                          },
                          [
                            _c("el-input", {
                              attrs: {
                                placeholder: "+18185005050",
                                "data-testid": "phone-number-input",
                              },
                              on: {
                                input: function ($event) {
                                  return _vm.preValidateForm("create_contact")
                                },
                              },
                              model: {
                                value: _vm.new_contact.phone_number,
                                callback: function ($$v) {
                                  _vm.$set(_vm.new_contact, "phone_number", $$v)
                                },
                                expression: "new_contact.phone_number",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-6" },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "Email Address (Optional)",
                              prop: "email",
                              "data-testid": "email-form-item",
                            },
                          },
                          [
                            _c("el-input", {
                              attrs: {
                                placeholder: "john.doe@email.com",
                                "data-testid": "email-input",
                              },
                              on: {
                                input: function ($event) {
                                  return _vm.preValidateForm("create_contact")
                                },
                              },
                              model: {
                                value: _vm.new_contact.email,
                                callback: function ($$v) {
                                  _vm.$set(_vm.new_contact, "email", $$v)
                                },
                                expression: "new_contact.email",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      { staticClass: "col-6" },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "Initial Line (Optional)",
                              prop: "initial_campaign_id",
                              "data-testid": "line-selector",
                            },
                          },
                          [
                            _c(
                              "el-select",
                              {
                                staticClass: "no-select w-full",
                                attrs: {
                                  placeholder: "Select Initial Line",
                                  filterable: "",
                                  clearable: "",
                                  "data-testid": "initial-line-selects",
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.preValidateForm("create_contact")
                                  },
                                },
                                model: {
                                  value: _vm.new_contact.initial_campaign_id,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.new_contact,
                                      "initial_campaign_id",
                                      $$v
                                    )
                                  },
                                  expression: "new_contact.initial_campaign_id",
                                },
                              },
                              _vm._l(
                                _vm.campaignsAlphabeticalOrder,
                                function (campaign) {
                                  return _c(
                                    "el-option",
                                    {
                                      key: campaign.id,
                                      attrs: {
                                        label: campaign.name,
                                        value: campaign.id,
                                      },
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(_vm._s(campaign.name)),
                                      ]),
                                    ]
                                  )
                                }
                              ),
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.hasPermissionTo("list user")
                      ? _c(
                          "div",
                          { staticClass: "col-6" },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "Owner (Optional)",
                                  prop: "user_id",
                                  "data-testid": "owner-form-item",
                                },
                              },
                              [
                                _c("user-selector", {
                                  attrs: { "data-testid": "owner-selector" },
                                  on: { change: _vm.changeUserId },
                                  model: {
                                    value: _vm.new_contact.user_id,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.new_contact, "user_id", $$v)
                                    },
                                    expression: "new_contact.user_id",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "Tags (Optional)",
                        prop: "tag_ids",
                        "data-testid": "tags-form-item",
                      },
                    },
                    [
                      _c("tag-creator", {
                        ref: "addTag",
                        attrs: { "data-testid": "tag-creator" },
                        on: {
                          success: _vm.addTagToNewContact,
                          show: _vm.showAdd,
                          hide: _vm.hideAdd,
                        },
                      }),
                      _vm._v(" "),
                      _vm.hasPermissionTo(["list tag", "view tag"])
                        ? _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: !_vm.hide_add,
                                  expression: "!hide_add",
                                },
                              ],
                              staticClass: "row",
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "col-12" },
                                [
                                  _c("tag-selector", {
                                    attrs: {
                                      multiple: true,
                                      "data-testid": "tag-selector",
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.changeTags(
                                          $event,
                                          _vm.new_contact
                                        )
                                      },
                                    },
                                    model: {
                                      value: _vm.new_contact.tag_ids,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.new_contact,
                                          "tag_ids",
                                          $$v
                                        )
                                      },
                                      expression: "new_contact.tag_ids",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { "data-testid": "create-contact-cancel-button" },
                      on: { click: _vm.cancelNewContact },
                    },
                    [
                      _vm._v(
                        "\n                         Cancel\n                "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  !_vm.canCreateContacts
                    ? _c(
                        "el-popover",
                        {
                          ref: "create-contacts-dialog",
                          staticClass: "custom-popover",
                          attrs: {
                            "data-testid": "create-contacts-popover",
                            placement: "top",
                            width: "200",
                            "popper-class": "btn-primary",
                            trigger: "hover",
                          },
                        },
                        [
                          _c("custom-message-display", {
                            attrs: {
                              config: _vm.customMessage("contacts.create"),
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "popover",
                          rawName: "v-popover:create-contacts-dialog",
                          arg: "create-contacts-dialog",
                        },
                      ],
                      attrs: {
                        type: "success",
                        loading: _vm.loading_save_btn,
                        disabled: !_vm.validated || !_vm.canCreateContacts,
                        "data-testid": "create-contact-save-button",
                      },
                      on: { click: _vm.createContact },
                    },
                    [_vm._v("\n                    Save\n                ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _vm._t("trigger", function () {
            return [
              !_vm.canCreateContacts
                ? _c(
                    "el-popover",
                    {
                      ref: "add-contact",
                      staticClass: "custom-popover",
                      attrs: {
                        placement: "top",
                        width: "200",
                        "popper-class": "btn-primary",
                        "data-testid": "add-contact-popover",
                        trigger: "hover",
                      },
                    },
                    [
                      _c("custom-message-display", {
                        attrs: {
                          "data-testid": "custom-message-display",
                          config: _vm.customMessage("contacts.create"),
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              !_vm.canCreateContacts
                ? _c(
                    "el-popover",
                    {
                      ref: "create-contacts-mobile",
                      staticClass: "custom-popover",
                      attrs: {
                        placement: "top",
                        width: "200",
                        "popper-class": "btn-primary",
                        trigger: "hover",
                        "data-testid": "add-contact-popover",
                      },
                    },
                    [
                      _c("custom-message-display", {
                        attrs: {
                          "data-testid": "custom-message-display",
                          config: _vm.customMessage("contacts.create"),
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.isLargeEnough
                ? _c(
                    "button",
                    {
                      directives: [
                        {
                          name: "popover",
                          rawName: "v-popover:add-contact",
                          arg: "add-contact",
                        },
                      ],
                      staticClass: "btn greenish",
                      attrs: {
                        disabled: !_vm.canCreateContacts,
                        "data-testid": "add-contact-button-in-desktop",
                      },
                      on: { click: _vm.openModal },
                    },
                    [
                      _c("i", { staticClass: "material-icons" }, [
                        _vm._v("add"),
                      ]),
                      _vm._v("\n            Add Contact\n        "),
                    ]
                  )
                : _c(
                    "button",
                    {
                      directives: [
                        {
                          name: "popover",
                          rawName: "v-popover:create-contacts-mobile",
                          arg: "create-contacts-mobile",
                        },
                      ],
                      staticClass:
                        "md-btn md-fab m-b-sm greenish abs-bottom abs-right",
                      attrs: {
                        disabled: !_vm.canCreateContacts,
                        "data-testid": "add-contact-button-in-mobile",
                      },
                      on: { click: _vm.openModal },
                    },
                    [
                      _c("i", { staticClass: "material-icons md-24" }, [
                        _vm._v("add"),
                      ]),
                    ]
                  ),
            ]
          }),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }