<template>
    <div id="calendar"
         class="d-relative">

        <div class="fixed-header padding pt-0 pb-0" :style="{top: (total_offset+50) + 'px'}">
            <div class="row mb-0 mt-0">
                <div class="col-12 p-2">
                    <div v-show="searchMode">
                        <div class="d-flex">
                            <div class="container-filter-search w-50">
                                <el-form ref="form"
                                         :model="search"
                                         :class="searchFocused ? 'focused' : 'blurred'"
                                         @submit.prevent.native="handleSearch">
                                    <el-popover placement="top"
                                                title=""
                                                width="300"
                                                trigger="manual"
                                                content="Please provide a search text"
                                                v-model="searchPopover">
                                        <el-input placeholder="Search events..."
                                                  ref="inputSearch"
                                                  class="w-full"
                                                  v-model="search.calendar_search"
                                                  @focus="searchFocused = true"
                                                  @blur="searchFocused = false"
                                                  slot="reference">
                                            <i slot="prefix"
                                               class="el-input__icon el-icon-search"></i>
                                            <el-button slot="append"
                                                       @click="showFilterSearch = !showFilterSearch">
                                                <i v-if="!showFilterSearch"
                                                   class="fa fa-caret-down"></i>
                                                <i v-if="showFilterSearch"
                                                   class="fa fa-caret-up"></i>
                                            </el-button>
                                        </el-input>
                                    </el-popover>

                                    <transition name="slide">
                                        <div class="dropdown"
                                             v-if="showFilterSearch">
                                            <table class="table-filters">
                                                <tr>
                                                    <td>
                                                        Appointments
                                                    </td>
                                                    <td class="text-right">
                                                        <el-form-item class="mb-0"
                                                                      :active-value="1"
                                                                      :inactive-value="0">
                                                            <el-switch v-model="search.appointments"
                                                                       class="mb-0"
                                                                       active-color="#00a344">
                                                            </el-switch>
                                                        </el-form-item>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        Reminders
                                                    </td>
                                                    <td class="text-right">
                                                        <el-form-item class="mb-0"
                                                                      :active-value="1"
                                                                      :inactive-value="0">
                                                            <el-switch v-model="search.reminders"
                                                                       class="mb-0"
                                                                       active-color="#00a344">
                                                            </el-switch>
                                                        </el-form-item>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        Status
                                                    </td>
                                                    <td class="text-right">
                                                        <el-form-item class="mb-0 text-right"
                                                                      width="200"
                                                                      :active-value="1"
                                                                      :inactive-value="0">

                                                            <el-select v-model="search.calendar_status"
                                                                       multiple
                                                                       placeholder="All"
                                                                       class="filter-select text-right"
                                                                       @change="searchStatusChange">
                                                                <el-option key="0"
                                                                           value="0"
                                                                           label="All">
                                                                    <strong>All</strong>
                                                                </el-option>
                                                                <el-option v-for="s in search_statuses"
                                                                           :key="s"
                                                                           :label="statusNames[s]"
                                                                           :value="s">
                                                                </el-option>
                                                            </el-select>
                                                        </el-form-item>
                                                    </td>
                                                </tr>
                                            </table>

                                            <div class="p-3 text-right">
                                                <el-button type="text"
                                                           class="text-muted"
                                                           @click="resetSearchForm">
                                                    Reset
                                                </el-button>
                                                <el-button type="success"
                                                           @click="handleSearch"
                                                           :disabled="search.calendar_search.length === 0">
                                                    Search
                                                </el-button>
                                            </div>
                                        </div>
                                    </transition>
                                </el-form>
                            </div>

                            <div class="ml-auto">
                                <span
                                    v-if="loading"
                                    class="ml-auto">
                                    <i class="el-icon-loading"></i> Loading Events...
                                </span>
                                <calendar-help/>
                            </div>

                            <el-button @click="toggleSearch">Close</el-button>
                        </div>
                    </div>

                    <div class="d-flex"
                         v-if="!searchMode">
                        <div>
                            <span id="gotoDatePicker">
                                <el-date-picker v-model="gotoDate"
                                                type="date"
                                                placeholder="Pick a day"
                                                ref="gotoDatePicker"
                                                :picker-options="dpOptions"
                                                @change="gotoDateView">
                                </el-date-picker>
                            </span>

                            <el-button @click="triggerGotoDatePicker"><i class="fa fa-calendar"></i></el-button>

                            <el-button @click="goToday"
                                       class="mr-1">
                                Today
                            </el-button>

                            <el-tooltip class="item"
                                        effect="dark"
                                        :content="'Previous ' + view"
                                        placement="bottom">
                                <el-button type="text"
                                           class="px-1 btn-direction"
                                           @click="changeDirection('subtract')">
                                    <i class="fa fa-chevron-left"></i>
                                </el-button>
                            </el-tooltip>

                            <el-tooltip class="item"
                                        effect="dark"
                                        :content="'Next ' + view"
                                        placement="bottom">
                                <el-button type="text"
                                           class="px-1 btn-direction"
                                           @click="changeDirection('add')">
                                    <i class="fa fa-chevron-right"></i>
                                </el-button>
                            </el-tooltip>

                        </div>
                        <h5 id="h5-calendar-date">{{ formattedDate }}</h5>
                        <div class="ml-auto">
                            <span v-if="loading">
                                <i class="el-icon-loading"></i> Loading Events...
                            </span>

                            <el-button class="btn-direction"
                                       type="text"
                                       @click="toggleSearch">
                                <i class="fa fa-search"></i>
                            </el-button>

                            <calendar-help/>

                            <el-badge class="item"
                                      :value="0"
                                      :hidden="true">
                                <el-dropdown class="mr-1"
                                             trigger="click"
                                             :hide-on-click="true"
                                             @command="setView">
                                    <el-button>
                                        <span style="text-transform: capitalize">
                                            {{ view }}
                                        </span>
                                        <i class="fa fa-caret-down"></i>
                                    </el-button>
                                    <template v-slot:dropdown>
                                        <el-dropdown-menu>
                                            <el-dropdown-item command="month">Month</el-dropdown-item>
                                            <el-dropdown-item command="week">Week</el-dropdown-item>
                                            <el-dropdown-item command="day">Day</el-dropdown-item>
                                        </el-dropdown-menu>
                                    </template>
                                </el-dropdown>
                            </el-badge>
                            <el-badge class="item"
                                      type="success"
                                      :value="filterCount"
                                      :hidden="filterCount === 0">
                                <el-button @click="toggleFilters">Filters</el-button>
                            </el-badge>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div id="container-sched">
            <div v-if="showSearchResult"
                 id="container-search-result">
                <table class="table table-hover"
                       v-if="events.length > 0">
                    <thead>
                    <tr>
                        <th colspan="2">Date</th>
                        <th>Time</th>
                        <th>Status</th>
                        <th>Contact</th>
                        <th>Note</th>
                        <th></th>
                    </tr>
                    </thead>
                    <thead>
                    <tr v-for="sd in events">
                        <td class="day-of-month">
                            <span>{{ sd.start_date | momentFormat('D') }}</span>
                        </td>
                        <td class="date">
                            {{ sd.start_date | momentFormat('MMM YYYY, ddd') }}
                        </td>
                        <td class="time">
                            {{ sd.start_date | momentFormat('h:mm') }} – {{ sd.end_date | momentFormat('h:mm a') }}
                        </td>
                        <td>
                            <i class="fa fa-circle mr-2"
                               :class="'event-color-type-' + sd.type + ' status-' + sd.status + ' ' + (sd.is_past ? 'is_past' : '')">
                            </i>
                            {{ sd.status_name }}
                        </td>
                        <td class="contact">
                            <router-link :to="{ name: 'Contact', params: { contact_id: sd.contact.id }}"
                                         v-if="sd.contact != null"
                                         target="_blank"
                                         class="text-dark-greenish">
                                <el-tooltip class="item"
                                            effect="dark"
                                            content="Click to go to contact's screen"
                                            placement="bottom">
                                    <div class="media">
                                        <div class="media-body lh-1">
                                            <strong class="d-block">{{ sd.contact.name }}</strong>
                                            <span>{{ sd.contact.phone_number | fixPhone }}</span>
                                        </div>
                                    </div>
                                </el-tooltip>
                            </router-link>

                            <span v-if="sd.contact === null">
                                {{ sd.text }}
                            </span>
                        </td>
                        <td class="note">
                            <span v-if="sd.body && sd.body.length > 0">{{ sd.body }}</span>
                            <span v-if="!sd.body || sd.body.length === 0"
                                  style="color: #999">
                                No note provided
                            </span>
                        </td>
                        <td class="text-right">
                            <el-button size="small"
                                       @click="editSchedule(sd)">
                                Details
                            </el-button>
                        </td>
                    </tr>
                    </thead>
                </table>

                <div class="text-center py-4"
                     v-if="events.length === 0">
                    <p class="text-muted">No search result for "{{ search.calendar_search }}"</p>
                </div>
            </div>

            <div id="sched-header"
                 v-show="!showSearchResult">
                <table id="sched-header-table">
                    <tr v-if="view === 'week'">
                        <td id="td-scale"></td>
                        <td v-for="d in formattedWeekDays"
                            :class="d.today ? 'today': ''">
                            <span class="day-of-week">{{ d.dayOfWeek }}</span>
                            <span class="day">{{ d.day }}</span>
                        </td>
                        <td style="width: 20px"></td>
                    </tr>

                    <tr v-if="view === 'month'">
                        <td v-for="d in ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']"><span class="day-of-week">{{ d }}</span>
                        </td>
                    </tr>
                </table>
            </div>
            <div id="sched-body"
                 v-show="!showSearchResult"
                 :class="view">
                <scheduler ref="scheduler"
                           class="actual-scheduler"
                           :class="view + '-view'"
                           :events="events"
                           @edit-schedule="editSchedule"
                           @add-schedule="addSchedule"
                           @filter-click="toggleFilters"
                           @render-events="renderFromEvent"
                           @toggle-goto-date="toggleGotoDate"
                           @update-current-date="updateCurrentDate">
                </scheduler>
            </div>
        </div>

        <appointment-reminder-manager ref="manager"
                                      @render-schedule="renderSchedule">
        </appointment-reminder-manager>

        <el-dialog title="Filters"
                   :visible.sync="dialogVisible"
                   :before-close="closeFiltersMenu"
                   width="40%">
            <div class="">
                <div class="right-align">
                    <el-form label-position="left"
                             label-width="200px">
                        <el-form-item label="Appointments"
                                      class="mb-0"
                                      :active-value="1"
                                      :inactive-value="0">
                            <el-switch v-model="filters.appointments"
                                       class="mb-0"
                                       active-color="#00a344">
                            </el-switch>
                        </el-form-item>
                    </el-form>
                </div>

                <div class="right-align mb-2">
                    <el-form label-position="left"
                             label-width="200px">
                        <el-form-item label="Reminders"
                                      class="mb-0"
                                      :active-value="1"
                                      :inactive-value="0">
                            <el-switch v-model="filters.reminders"
                                       active-color="#00A344">
                            </el-switch>
                        </el-form-item>
                    </el-form>
                </div>

                <div class="right-align mb-2">
                    <el-form label-position="left"
                             label-width="200px">
                        <el-form-item label="Status"
                                      class="mb-0 text-right"
                                      :active-value="1"
                                      :inactive-value="0">

                            <el-select v-model="filters.calendar_status"
                                       multiple
                                       placeholder="All"
                                       class="filter-select"
                                       @change="filterStatusChange">
                                <el-option key="0"
                                           value="0"
                                           label="All">
                                    <strong>All</strong>
                                </el-option>
                                <el-option v-for="s in dialog_statuses"
                                           :key="s"
                                           :label="statusNames[s]"
                                           :value="s">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-form>
                </div>

                <div class="dropdown-divider"></div>

                <div class="right-align">
                    <el-form label-position="left"
                             label-width="200px">
                        <el-form-item label="Users"
                                      class="mb-3">

                            <user-selector :value="filters.calendar_users"
                                           :multiple="true"
                                           :hide_extensions="true"
                                           class="filter-select"
                                           v-model="filters.calendar_users"
                                           @change="changeAgents">
                            </user-selector>
                        </el-form-item>
                    </el-form>
                </div>

                <div class="text-right mt-2">
                    <el-button @click="closeFiltersMenu">Cancel</el-button>
                    <el-button type="success"
                               @click="applyFilters"
                               :disabled="loading">
                        Apply Filters
                    </el-button>
                </div>
            </div>
        </el-dialog>

        <el-dialog title="Go To Date"
                   width="30%"
                   :visible.sync="gotoDateVisible">
            <span>
                <el-date-picker v-model="gotoDate"
                                type="date"
                                class="w-100"
                                format="d MMM yyyy"
                                placeholder="Pick a day">
                </el-date-picker>
            </span>
            <span slot="footer"
                  class="dialog-footer">
                <el-button @click="gotoDateVisible = false">Cancel</el-button>
                <el-button type="success"
                           @click="gotoDateView()">
                    Confirm
                </el-button>
            </span>
        </el-dialog>
    </div>

</template>

<script>
import {mapGetters, mapState} from 'vuex'
import auth from '../../auth'
import cloneDeep from "lodash/cloneDeep"
import {acl_mixin, styling_mixin, announce_kit_mixin} from '../../mixins'
import Scheduler from '../../components/calendar/scheduler.vue'
import AppointmentReminderManager from '../../components/calendar/appointment-reminder-manager.vue'
import CalendarHelp from '../../components/calendar/calendar-help'
import * as CommunicationDispositionStatus from '../../constants/communication-disposition-status'

export default {
    mixins: [
        acl_mixin,
        styling_mixin,
        announce_kit_mixin
    ],

    components: {
        Scheduler,
        AppointmentReminderManager,
        CalendarHelp
    },

    data() {
        return {
            auth: auth,
            events: [],
            dialogVisible: false,
            filters: {
                appointments: true,
                reminders: true,
                calendar_users: [],
                calendar_mode: null,
                calendar_min_date: new Date(),
                calendar_max_date: new Date(),
                calendar_status: [],
                limit: 25,
                page: 1
            },
            original_filters: {},
            loading: true,
            gotoDateVisible: false,
            gotoDate: new Date(),
            statusNames: {},
            view: 'month',
            stepMap: {
                'day': 'd',
                'week': 'w',
                'month': 'M'
            },
            dpOptions: {
                firstDayOfWeek: 1
            },
            legend: false,

            searchMode: false,
            search: {
                calendar_search: '',
                appointments: true,
                reminders: true,
                calendar_users: [],
                calendar_status: []
            },
            originalSearch: null,
            showFilterSearch: false,
            showSearchResult: false,
            search_loading: false,
            searchFocused: false,
            searchPopover: false,
            cancel_token: axios.CancelToken,
            source: null
        }
    },

    mounted() {
        this.setStatusNames()

        if ('view' in this.$route.query) {
            this.setView(this.$route.query.view)
        }

        if ('communication_id' in this.$route.query) {
            axios.get('/api/v1/calendar/events/show/' + this.$route.query.communication_id + '/communication').then(res => {
                this.editSchedule(res.data)
            })
        }
    },

    computed: {
        ...mapState('cache', ['current_company']),

        ...mapGetters('cache', ['isSimpSocial']),

        dialog_statuses() {
            let data = []
            if (this.filters.appointments) {
                data.push(CommunicationDispositionStatus.DISPOSITION_STATUS_APPOINTMENT_CANCELED)
                data.push(CommunicationDispositionStatus.DISPOSITION_STATUS_APPOINTMENT_SET)
                data.push(CommunicationDispositionStatus.DISPOSITION_STATUS_APPOINTMENT_SHOWN)
                data.push(CommunicationDispositionStatus.DISPOSITION_STATUS_APPOINTMENT_MISSED)
            }

            if (this.filters.reminders) {
                data.push(CommunicationDispositionStatus.DISPOSITION_STATUS_COMPLETED_NEW)
                data.push(CommunicationDispositionStatus.DISPOSITION_STATUS_FAILED_NEW)
                data.push(CommunicationDispositionStatus.DISPOSITION_STATUS_PLACED_NEW)
                data.push(CommunicationDispositionStatus.DISPOSITION_STATUS_MISSED_NEW)
            }
            return data
        },

        search_statuses() {
            let data = []
            if (this.search.appointments) {
                data.push(CommunicationDispositionStatus.DISPOSITION_STATUS_APPOINTMENT_CANCELED)
                data.push(CommunicationDispositionStatus.DISPOSITION_STATUS_APPOINTMENT_SET)
                data.push(CommunicationDispositionStatus.DISPOSITION_STATUS_APPOINTMENT_SHOWN)
                data.push(CommunicationDispositionStatus.DISPOSITION_STATUS_APPOINTMENT_MISSED)
            }

            if (this.search.reminders) {
                data.push(CommunicationDispositionStatus.DISPOSITION_STATUS_COMPLETED_NEW)
                data.push(CommunicationDispositionStatus.DISPOSITION_STATUS_FAILED_NEW)
                data.push(CommunicationDispositionStatus.DISPOSITION_STATUS_PLACED_NEW)
                data.push(CommunicationDispositionStatus.DISPOSITION_STATUS_MISSED_NEW)
            }
            return data
        },

        formattedDate() {
            let d = ""
            const m = moment(this.gotoDate)
            if (this.view == 'day') {
                d = m.format('D MMM YYYY')
            } else if (this.view == 'week') {
                const start = moment(this.gotoDate).startOf('isoWeek')
                const end = moment(this.gotoDate).endOf('isoWeek')

                d = start.format("D MMM") + ' - ' + end.format("D MMM YYYY")
                if (start.format("MMM") == end.format("MMM")) {
                    d = start.format("D") + ' - ' + end.format("D MMM YYYY")
                }

            } else if (this.view == 'month') {
                d = m.format('MMMM YYYY')
            }
            return d;
        },

        formattedWeekDays() {
            const start = moment(this.gotoDate).startOf('isoWeek')
            const end = moment(this.gotoDate).endOf('isoWeek')

            let cd = start;
            let dates = [];
            for (let i = 0; i < 7; i++) {
                dates.push({
                    date: cd,
                    dayOfWeek: cd.format('ddd'),
                    day: cd.format('D'),
                    today: cd.format("YYYY-MM-DD") == moment().format("YYYY-MM-DD")
                })
                cd.add(1, 'd')
            }

            return dates
        },

        filterCount() {
            let count = 0
            count += this.filters.appointments ? 0 : 1
            count += this.filters.reminders ? 0 : 1
            count += this.filters.calendar_users.length
            count += this.filters.calendar_status.length
            return count
        }
    },

    methods: {
        setStatusNames() {
            this.statusNames = {
                [CommunicationDispositionStatus.DISPOSITION_STATUS_COMPLETED_NEW]: 'Completed Reminder',
                [CommunicationDispositionStatus.DISPOSITION_STATUS_FAILED_NEW]: 'Cancelled Reminder',
                [CommunicationDispositionStatus.DISPOSITION_STATUS_PLACED_NEW]: 'Pending Reminder',
                [CommunicationDispositionStatus.DISPOSITION_STATUS_APPOINTMENT_CANCELED]: 'Cancelled Appointment',
                [CommunicationDispositionStatus.DISPOSITION_STATUS_APPOINTMENT_SET]: 'Appointment Set',
                [CommunicationDispositionStatus.DISPOSITION_STATUS_APPOINTMENT_SHOWN]: this.isSimpSocial ? 'Appointment Shown' : 'Appointment Completed',
                [CommunicationDispositionStatus.DISPOSITION_STATUS_APPOINTMENT_MISSED]: 'Appointment Missed',
                [CommunicationDispositionStatus.DISPOSITION_STATUS_MISSED_NEW]: 'Reminder Missed',
                0: 'All'
            }
        },

        loadCalendarData(state) {
            this.loading = true
            this.source = this.cancel_token.source()

            this.filters.calendar_mode = state.mode
            this.filters.calendar_min_date = state.min_date
            this.filters.calendar_max_date = state.max_date

            // reset only if the page is set back to one
            // it basically means that it will reload the data
            // from the beginning
            if (this.filters.page === 1) {
                this.events = []
                this.$refs.scheduler.clearAll()
            }

            axios.get('/api/v1/calendar/events', {
                params: this.filters,
                cancelToken: this.source.token
            }).then(res => {
                this.events.push(...res.data)

                if (this.$refs.scheduler) {
                    this.$refs.scheduler.customParse(this.events)
                }

                if (res.data && res.data.length) {
                    this.filters.page++
                    this.reloadFromCurrentFilter()
                } else {
                    this.loading = false
                }

                this.dialogVisible = false
            }).catch(err => {
                console.log(err)

                this.loading = false
                this.dialogVisible = false
            })
        },

        renderFromEvent(state) {
            this.cancelRequestToken()
            this.resetPage()
            this.loadCalendarData(state)
        },

        toggleFilters() {
            this.original_filters = _.clone(this.filters)
            this.dialogVisible = true
        },

        applyFilters() {
            this.cancelRequestToken()
            this.resetPage()
            this.reloadFromCurrentFilter()
        },

        reloadFromCurrentFilter() {
            this.loadCalendarData({
                mode: this.filters.calendar_mode,
                min_date: this.filters.calendar_min_date,
                max_date: this.filters.calendar_max_date
            })
        },

        changeAgents(filter_users) {
            this.filters.calendar_users = filter_users
        },

        addSchedule(date) {
            this.$refs.manager.addSchedule(date)
        },

        editSchedule(event) {
            this.$refs.manager.editSchedule(event)
        },

        renderSchedule(engagement) {
            let data = cloneDeep(engagement.data)
            let action = engagement.action

            if (action === 'add') {
                this.events.push(data)
            }

            if (action === 'update') {
                let index = this.events.findIndex(ev => ev.id === data.id)
                if (index > -1) {
                    this.events.splice(index, 1, data)
                }
            }

            if (action === 'delete') {
                let index = this.events.findIndex(ev => ev.id === data.id)
                if (index > -1) {
                    this.events.splice(index, 1)

                    // remove the event directly from the scheduler
                    this.$refs.scheduler.deleteEvent(data.id)
                }
            }

            this.$refs.scheduler.customParse(this.events)
        },

        resetForm() {
            this.filters = this.original_filters
        },

        closeFiltersMenu(done) {
            if (_.isEqual(this.filters, this.original_filters)) {
                this.dialogVisible = false
            } else {
                this.$confirm(`Are you sure you want to close this filter?`, 'Filter', {
                    confirmButtonText: "Yes, I'm sure",
                    cancelButtonText: "No, I'm not",
                    type: 'warning',
                    customClass: 'width-500 fixed'
                }).then(() => {
                    this.resetForm()
                    this.dialogVisible = false
                }).catch(err => {
                    console.log(err)
                })
            }
        },

        toggleGotoDate() {
            this.gotoDateVisible = true
        },

        gotoDateView() {
            this.$refs.scheduler.setCurrentView(this.gotoDate, this.view)
            this.gotoDateVisible = false
        },

        updateCurrentDate(date) {
            this.gotoDate = date
        },

        filterStatusChange(status) {
            if (this.filters.calendar_status.includes("0")) {
                this.filters.calendar_status = []
            }
        },

        setView(view) {
            // we don't update anything if the selected view
            // is the same with the current view
            if (view === this.view) {
                return
            }

            this.view = view
            this.$refs.scheduler.setCurrentView(this.gotoDate, view)
        },

        goToday() {
            this.gotoDate = new Date()
            this.$refs.scheduler.setCurrentView(this.gotoDate, this.view)
        },

        triggerGotoDatePicker() {
            this.$refs.gotoDatePicker.focus()
        },

        changeDirection(direction) {
            let date = moment(this.gotoDate)[direction](1, this.stepMap[this.view]).toDate();
            if (this.view === 'day') {
                this.gotoDate = date;
            }
            this.$refs.scheduler.setCurrentView(date, this.view)
        },

        toggleSearch() {
            this.searchMode = !this.searchMode
            this.resetPage()

            if (this.searchMode) {
                setTimeout(() => {
                    this.$refs.inputSearch.focus()
                }, 100)
            } else {
                this.showSearchResult = false
                this.applyFilters()
                this.resetSearchForm()
            }
        },

        searchStatusChange() {
            if (this.search.calendar_status.includes("0")) {
                this.search.calendar_status = []
            }
        },

        changeSearchUser(users) {
            this.search.calendar_users = users
        },

        handleSearch() {
            this.cancelRequestToken()
            this.resetPage()
            this.applySearch()
        },

        applySearch() {
            if (this.search.calendar_search.length > 0 && this.searchMode) {
                this.source = this.cancel_token.source()
                this.search_loading = true
                this.loading = true
                this.searchPopover = false

                let params = cloneDeep(this.search)
                params.limit = this.filters.limit
                params.page = this.filters.page

                // reset data if page is set back to one
                if (this.filters.page === 1) {
                    this.events = []
                }

                axios.get('/api/v1/calendar/events', {
                    params: params,
                    cancelToken: this.source.token
                }).then(res => {
                    this.events.push(...res.data)

                    if (res.data && res.data.length) {
                        this.filters.page++
                        this.applySearch()
                    } else {
                        this.loading = false
                    }

                    if (this.searchMode) {
                        this.showSearchResult = true
                    }

                    this.search_loading = false
                    this.showFilterSearch = false
                }).catch(err => {
                    console.log(err)

                    this.loading = false
                })
            } else {
                this.searchPopover = true
                setTimeout(() => this.searchPopover = false, 2500)
            }
        },

        resetSearchForm() {
            this.search = {
                calendar_search: '',
                appointments: true,
                reminders: true,
                calendar_users: [],
                status: [],
            }
        },

        resetPage() {
            this.filters.page = 1
        },

        cancelRequestToken() {
            if (this.source) {
                this.source.cancel('Calendar: Request Cancelled.')
            }
        }
    }
}
</script>
