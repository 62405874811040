<template>
    <div v-if="contact">
        <div class="md-form-group pb-2"
             v-if="hasPermissionTo('list user')">
            <user-selector ref="agent_selector"
                           v-model="contact.user_id"
                           :disabled="!hasPermissionTo('change contact ownership') || loading_field['user_id']"
                           hide-extensions
                           @change="changeOwner">
            </user-selector>
            <label>Owner</label>
        </div>
        <div v-if="hasPermissionTo('list disposition status')"
             class="md-form-group pb-2">
            <contact-disposition-selector placeholder="Select contact disposition"
                                          :key="'contact-page-' + contact.id"
                                          :contact="contact"
                                          :is_contact_page="true"
                                          :disabled="!hasPermissionTo('dispose contact')"/>
            <label>Contact Disposition</label>
        </div>
        <div class="md-form-group pb-2">
            <label>Tags</label>
            <div class="mt-2">
                <contact-tags :contact="contact"
                              :no-collapse="true">
                </contact-tags>
            </div>
        </div>
        <div class="md-form-group pb-2"
             v-if="contact.text_authorized_at">
            <input class="md-input"
                   data-testid="contacto-details-tcpa-approved-at-input"
                   :disabled="true"
                   :value="contact.text_authorized_at | fixDateTime">
            <label>TCPA Approved At</label>
        </div>

        <div class="md-form-group pb-2">
            <input class="md-input"
                   data-testid="contact-details-email-input"
                   :value="contact.email | fixEmail"
                   :disabled="true"
                   v-if="!edit.overall && !edit.email"
                   v-focus>
            <input class="md-input"
                   ref="email"
                   :value="contact.email"
                   data-testid="contact-details-edit-email-input"
                   @blur="edit.email = false"
                   @keyup.enter="fastUpdateField('email', $event)"
                   v-focus
                   v-else>
            <div class="edit-field-button"
                 v-if="hasPermissionTo('update contact')"
                 :class="edit.email || loading_field['email'] ? 'active' : ''"
                 data-testid="edit-email-icon"
                 @click="edit.email = true">
                <span class="text-md text-greenish pointer no-select">
                    <i class="material-icons"
                       v-if="!edit.email && !loading_field['email']">edit</i>
                    <i class="material-icons loader"
                       v-if="loading_field['email']">&#xE863;</i>
                </span>
            </div>
            <label>Email</label>
        </div>
        <div class="md-form-group pb-2">
            <input class="md-input"
                   :value="contact.address"
                   :disabled="true"
                   data-testid="contact-details-address-input"
                   v-if="!edit.overall && !edit.address"
                   v-focus>
            <input class="md-input"
                   ref="address"
                   :value="contact.address"
                   data-testid="contact-details-edit-address-input"
                   @blur="edit.address = false"
                   @keyup.enter="fastUpdateField('address', $event)"
                   v-focus
                   v-else>
            <div class="edit-field-button"
                 v-if="hasPermissionTo('update contact')"
                 :class="edit.address || loading_field['address'] ? 'active' : ''"
                 data-testid="edit-address-icon"
                 @click="edit.address = true">
                <span class="text-md text-greenish pointer no-select">
                    <i class="material-icons"
                       v-if="!edit.address && !loading_field['address']">edit</i>
                    <i class="material-icons loader"
                       v-if="loading_field['address']">&#xE863;</i>
                </span>
            </div>
            <label>Address</label>
        </div>
        <div class="md-form-group pb-2">
            <a class="md-input-style"
               v-if="!edit.overall && !edit.website && contact.website"
               :src="contact.website"
               data-testid="contact-details-link-to-website"
               @click="openInNewTab(contact.website)">
                {{ contact.website }}
            </a>
            <input class="md-input"
                   :disabled="true"
                   data-testid="contact-details-website-input"
                   v-if="!edit.overall && !edit.website && !contact.website"
                   v-focus>
            <input class="md-input"
                   ref="website"
                   :value="contact.website"
                   data-testid="contact-details-edit-website-input"
                   @blur="edit.website = false"
                   @keyup.enter="fastUpdateField('website', $event)"
                   v-focus
                   v-if="edit.overall || edit.website">
            <div class="edit-field-button"
                 v-if="hasPermissionTo('update contact')"
                 :class="edit.website || loading_field['website'] ? 'active' : ''"
                 data-testid="edit-website-icon"
                 @click="edit.website = true">
                <span class="text-md text-greenish pointer no-select">
                    <i class="material-icons"
                       v-if="!edit.website && !loading_field['website']">edit</i>
                    <i class="material-icons loader"
                       v-if="loading_field['website']">&#xE863;</i>
                </span>
            </div>
            <label>Website</label>
        </div>
        <div class="md-form-group pb-2">
            <input class="md-input"
                   :value="contact.company_name | fixCompanyName"
                   :disabled="true"
                   data-testid="contact-details-company-name-input"
                   v-if="!edit.overall && !edit.company_name"
                   v-focus>
            <input class="md-input"
                   ref="company_name"
                   data-testid="contact-details-edit-company-name-input"
                   :value="contact.company_name"
                   @blur="edit.company_name = false"
                   @keyup.enter="fastUpdateField('company_name', $event)"
                   v-focus
                   v-else>
            <div class="edit-field-button"
                 v-if="hasPermissionTo('update contact')"
                 :class="edit.company_name || loading_field['company_name'] ? 'active' : ''"
                 data-testid="edit-company-name-icon"
                 @click="edit.company_name = true">
                <span class="text-md text-greenish pointer no-select">
                    <i class="material-icons"
                       v-if="!edit.company_name && !loading_field['company_name']">edit</i>
                    <i class="material-icons loader"
                       v-if="loading_field['company_name']">&#xE863;</i>
                </span>
            </div>
            <label>Company Name</label>
        </div>
        <div class="row">
            <div class="col-6">
                <div class="md-form-group pb-2">
                    <input class="md-input"
                           data-testid="contact-details-country-input"
                           :value="contact.cnam_country | fixCountry"
                           :disabled="true"
                           v-if="!edit.overall && !edit.cnam_country"
                           v-focus>
                    <template v-else>
                        <el-select placeholder="Select Country"
                                   v-model="contact.cnam_country"
                                   size="mini"
                                   autocomplete="off"
                                   class="w-100"
                                   data-testid="contact-details-country-select"
                                   @change="updateField('cnam_country', '', contact.cnam_country)"
                                   default-first-option
                                   filterable
                                   clearable>
                            <el-option v-for="(country, index) in countries"
                                       :key="index"
                                       :label="country.name"
                                       :value="country.code">
                                <span>{{ country.name }}</span>
                            </el-option>
                        </el-select>
                    </template>
                    <div class="edit-field-button"
                         v-if="!edit.cnam_country && hasPermissionTo('update contact')"
                         :class="edit.cnam_country ? 'active' : ''"
                         data-testid="contact-details-edit-country"
                         @click="edit.cnam_country = true">
                        <span class="text-md text-greenish pointer no-select">
                            <i class="material-icons"
                               v-if="!edit.cnam_country">edit</i>
                            <i class="material-icons loader"
                               v-if="loading_field['cnam_country']">&#xE863;</i>
                        </span>
                    </div>
                    <label>Country</label>
                </div>
            </div>
            <div class="col-6">
                <div class="md-form-group pb-2">
                    <input class="md-input"
                           :value="contact.cnam_zipcode | fixZipcode"
                           :disabled="true"
                           data-testid="contact-details-zip-code-input"
                           v-if="!edit.overall && !edit.cnam_zipcode"
                           v-focus>
                    <input class="md-input"
                           ref="cnam_zipcode"
                           data-testid="contact-details-edit-zip-code-input"
                           :value="contact.cnam_zipcode"
                           @blur="edit.cnam_zipcode = false"
                           @keyup.enter="fastUpdateField('cnam_zipcode', $event)"
                           v-focus
                           v-else>
                    <div class="edit-field-button"
                         v-if="hasPermissionTo('update contact')"
                         :class="edit.cnam_zipcode || loading_field['cnam_zipcode'] ? 'active' : ''"
                         data-testid="edit-zip-code-icon"
                         @click="edit.cnam_zipcode = true">
                        <span class="text-md text-greenish pointer no-select">
                            <i class="material-icons"
                               v-if="!edit.cnam_zipcode && !loading_field['cnam_zipcode']">edit</i>
                            <i class="material-icons loader"
                               v-if="loading_field['cnam_zipcode']">&#xE863;</i>
                        </span>
                    </div>
                    <label>Zip Code</label>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-6">
                <div class="md-form-group pb-2">
                    <input class="md-input"
                           :value="contact.cnam_city | fixCity"
                           :disabled="true"
                           data-testid="contact-details-city-input"
                           v-if="!edit.overall && !edit.cnam_city"
                           v-focus>
                    <input class="md-input"
                           ref="cnam_city"
                           :value="contact.cnam_city"
                           data-testid="contact-details-edit-city-input"
                           @blur="edit.cnam_city = false"
                           @keyup.enter="fastUpdateField('cnam_city', $event)"
                           v-focus
                           v-else>
                    <div class="edit-field-button"
                         v-if="hasPermissionTo('update contact')"
                         :class="edit.cnam_city || loading_field['cnam_city'] ? 'active' : ''"
                         data-testid="edit-city-icon"
                         @click="edit.cnam_city = true">
                        <span class="text-md text-greenish pointer no-select">
                            <i class="material-icons"
                               v-if="!edit.cnam_city && !loading_field['csf1']">edit</i>
                            <i class="material-icons loader"
                               v-if="loading_field['cnam_city']">&#xE863;</i>
                        </span>
                    </div>
                    <label>City</label>
                </div>
            </div>
            <div class="col-6">
                <div class="md-form-group pb-2"
                     v-if="contact.cnam_country && ['US', 'CA'].includes(contact.cnam_country)">
                    <input class="md-input"
                           :value="contact.cnam_state | fixState"
                           :disabled="true"
                           data-testid="contact-details-state-input"
                           v-if="!edit.overall && !edit.cnam_state"
                           v-focus>
                    <template v-else>
                        <el-select placeholder="Select State"
                                   v-model="contact.cnam_state"
                                   v-if="contact.cnam_country == 'US'"
                                   size="mini"
                                   autocomplete="off"
                                   class="w-100"
                                   data-testid="contact-details-state-select"
                                   @change="updateField('cnam_state', '', contact.cnam_state)"
                                   default-first-option
                                   filterable
                                   clearable>
                            <el-option
                                v-for="(state, index) in country_states.US"
                                :key="index"
                                :label="state"
                                :value="state">
                                <span>{{ state }}</span>
                            </el-option>
                        </el-select>
                        <el-select placeholder="Select Province"
                                   v-model="contact.cnam_state"
                                   v-if="contact.cnam_country == 'CA'"
                                   size="mini"
                                   autocomplete="off"
                                   class="w-100"
                                   data-testid="contact-details-province-select"
                                   @change="updateField('cnam_state', '', contact.cnam_state)"
                                   default-first-option
                                   filterable
                                   clearable>
                            <el-option
                                v-for="(state, index) in country_states.CA"
                                :key="index"
                                :label="state"
                                :value="state">
                                <span>{{ state }}</span>
                            </el-option>
                        </el-select>
                    </template>
                    <div class="edit-field-button"
                         v-if="!edit.cnam_state && hasPermissionTo('update contact')"
                         :class="edit.cnam_state ? 'active' : ''"
                         data-testid="edit-state-icon"
                         @click="edit.cnam_state = true">
                        <span class="text-md text-greenish pointer no-select">
                            <i class="material-icons"
                               v-if="!edit.cnam_state">edit</i>
                            <i class="material-icons loader"
                               v-if="loading_field['cnam_state']">&#xE863;</i>
                        </span>
                    </div>
                    <label>State</label>
                </div>
            </div>
        </div>
        <div class="md-form-group pb-2">
            <input class="md-input"
                   :value="contact.timezone | fixTimezone"
                   :disabled="true"
                   data-testid="contact-details-time-zone-input"
                   v-if="!edit.overall && !edit.timezone"
                   v-focus>
            <template v-else>
                <el-select placeholder="Select Time Zone"
                           v-model="contact.timezone"
                           size="mini"
                           autocomplete="off"
                           class="w-100"
                           data-testid="contact-details-time-zone-select"
                           @change="updateField('timezone', '', contact.timezone)"
                           default-first-option
                           filterable
                           clearable>
                    <el-option v-for="(timezone, index) in timezones"
                               :key="index"
                               :label="timezone"
                               :value="timezone">
                        <span>{{ timezone }}</span>
                    </el-option>
                </el-select>
            </template>
            <div class="edit-field-button"
                 v-if="!edit.timezone && isAdmin"
                 :class="edit.timezone ? 'active' : ''"
                 data-testid="edit-time-zone-icon"
                 @click="edit.timezone = true">
                <span class="text-md text-greenish pointer no-select">
                    <i class="material-icons"
                       v-if="!edit.timezone">edit</i>
                    <i class="material-icons loader"
                       v-if="loading_field['timezone']">&#xE863;</i>
                </span>
            </div>
            <label>Time Zone</label>
        </div>
        <div class="md-form-group pb-2">
            <input class="md-input"
                   :value="contact.date_of_birth | fixDateOfBirth"
                   :disabled="true"
                   data-testid="contact-details-date-of-birth-input"
                   v-if="!edit.overall && !edit.date_of_birth"
                   v-focus>
            <template v-else>
                <el-date-picker v-model="contact.date_of_birth"
                                size="mini"
                                type="date"
                                format="MM/dd/yyyy"
                                value-format="yyyy-MM-dd"
                                placeholder="Date Of Birth"
                                data-testid="contact-details-date-of-birth-picker-us"
                                class="w-100"
                                v-if="auth.user.profile.country=='US'"
                                @change="updateField('date_of_birth', '', contact.date_of_birth)">
                </el-date-picker>
                <el-date-picker v-model="contact.date_of_birth"
                                size="mini"
                                type="date"
                                format="dd/MM/yyyy"
                                value-format="yyyy-MM-dd"
                                placeholder="Date Of Birth"
                                class="w-100"
                                data-testid="contact-details-date-of-birth-picker-ca"
                                v-if="auth.user.profile.country=='CA'"
                                @change="updateField('date_of_birth', '', contact.date_of_birth)">
                </el-date-picker>
            </template>
            <div class="edit-field-button"
                 v-if="!edit.date_of_birth && hasPermissionTo('update contact')"
                 :class="edit.date_of_birth ? 'active' : ''"
                 data-testid="edit-date-of-birth-icon"
                 @click="edit.date_of_birth = true">
                <span class="text-md text-greenish pointer no-select">
                    <i class="material-icons"
                       v-if="!edit.date_of_birth">edit</i>
                    <i class="material-icons loader"
                       v-if="loading_field['date_of_birth']">&#xE863;</i>
                </span>
            </div>
            <label>Date Of Birth</label>
        </div>
        <div class="md-form-group pb-2">
            <input class="md-input"
                   :value="contact.lead_source | fixCustomField"
                   :disabled="true"
                   data-testid="contact-details-lead-source-input"
                   v-if="!edit.overall && !edit.lead_source"
                   v-focus>
            <template v-else>
                <lead-source-selector v-model="contact.lead_source_id"
                                      @change="changeLeadSource">
                </lead-source-selector>
            </template>
            <div class="edit-field-button"
                 v-if="!edit.lead_source && hasPermissionTo('update contact')"
                 :class="edit.lead_source || loading_field['lead_source'] ? 'active' : ''"
                 data-testid="edit-lead-source-icon"
                 @click="edit.lead_source = true">
                <span class="text-md text-greenish pointer no-select">
                    <i class="material-icons"
                       v-if="!edit.lead_source && !loading_field['lead_source']">edit</i>
                    <i class="material-icons loader"
                       v-if="loading_field['lead_source']">&#xE863;</i>
                </span>
            </div>
            <label>Lead Source</label>
        </div>

        <div class="md-form-group pb-2">
            <label>Notes</label>
            <div class="mt-2">
                <contact-note :contact="contact"></contact-note>
            </div>
        </div>
        <div class="md-form-group pb-2">
            <input class="md-input"
                   :value="contact.csf1 | fixCustomField"
                   :disabled="true"
                   data-testid="contact-details-custom-field-1-input"
                   v-if="!edit.overall && !edit.csf1"
                   v-focus>
            <input class="md-input"
                   ref="csf1"
                   :value="contact.csf1"
                   data-testid="contact-details-edit-custom-field-1-input"
                   @blur="edit.csf1 = false"
                   @keyup.enter="fastUpdateField('csf1', $event)"
                   v-focus
                   v-else>
            <div class="edit-field-button"
                 v-if="hasPermissionTo('update contact')"
                 :class="edit.csf1 || loading_field['csf1'] ? 'active' : ''"
                 data-testid="edit-custom-field-1-icon"
                 @click="edit.csf1 = true">
                <span class="text-md text-greenish pointer no-select">
                    <i class="material-icons"
                       v-if="!edit.csf1 && !loading_field['csf1']">edit</i>
                    <i class="material-icons loader"
                       v-if="loading_field['csf1']">&#xE863;</i>
                </span>
            </div>
            <label>Custom Field 1</label>
        </div>
        <div class="md-form-group pb-2">
            <input class="md-input"
                   :value="contact.csf2 | fixCustomField"
                   :disabled="true"
                   data-testid="contact-details-custom-field-2-input"
                   v-if="!edit.overall && !edit.csf2"
                   v-focus>
            <input class="md-input"
                   ref="csf2"
                   :value="contact.csf2"
                   data-testid="contact-details-edit-custom-field-2-input"
                   @blur="edit.csf2 = false"
                   @keyup.enter="fastUpdateField('csf2', $event)"
                   v-focus
                   v-else>
            <div class="edit-field-button"
                 v-if="hasPermissionTo('update contact')"
                 :class="edit.csf2 || loading_field['csf2'] ? 'active' : ''"
                 data-testid="edit-custom-field-2-icon"
                 @click="edit.csf2 = true">
                <span class="text-md text-greenish pointer no-select">
                    <i class="material-icons"
                       v-if="!edit.csf2 && !loading_field['csf2']">edit</i>
                    <i class="material-icons loader"
                       v-if="loading_field['csf2']">&#xE863;</i>
                </span>
            </div>
            <label>Custom Field 2</label>
        </div>

        <contact-attributes :contact="contact"></contact-attributes>

        <div class="md-form-group pb-2">
            <input class="md-input"
                   :value="lineName | fixName"
                   :disabled="true"
                   data-testid="contact-details-initial-line-input"
                   v-if="!edit.overall && !edit.initial_campaign_id"
                   v-focus>
            <template v-else>
                <campaign-selector ref="campaign_selector"
                                   :value="contact.initial_campaign_id"
                                   v-model="contact.initial_campaign_id"
                                   @change="changeInitialCampaign"
                                   :show_paused="false">
                </campaign-selector>
            </template>
            <div class="edit-field-button"
                 v-if="hasPermissionTo('update contact')"
                 :class="edit.initial_campaign_id || loading_field['initial_campaign_id'] ? 'active' : ''"
                 data-testid="edit-initial-line-icon"
                 @click="edit.initial_campaign_id = true">
                <span class="text-md text-greenish pointer no-select">
                    <i class="material-icons"
                       v-if="!edit.initial_campaign_id && !loading_field['initial_campaign_id']">edit</i>
                    <i class="material-icons loader"
                       v-if="loading_field['initial_campaign_id']">&#xE863;</i>
                </span>
            </div>
            <label>Initial Line</label>
        </div>
        <div class="md-form-group pb-2">
            <input class="md-input"
                   :disabled="true"
                   data-testid="contact-details-tcpa-approved-input"
                   :value="contact.text_authorized | fixBooleanType">
            <label>TCPA Approved</label>
        </div>
        <div class="md-form-group pb-2"
             v-if="contact.created_at">
            <input class="md-input"
                   :disabled="true"
                   data-testid="contact-details-created-at-input"
                   :value="contact.created_at | fixFullDateUTCRelative">
            <label>Created At</label>
        </div>
        <div class="md-form-group pb-2"
             v-if="first_outbound_call">
            <input class="md-input"
                   :disabled="true"
                   data-testid="contact-details-first-outbound-call-input"
                   :value="timeOfFirstOutboundCall">
            <label>Time of First Outbound Call</label>
        </div>
        <div class="md-form-group pb-2"
             v-if="first_outbound_call">
            <input class="md-input"
                   :disabled="true"
                   data-testid="contact-details-time-to-first-outbound-call-input"
                   :value="timeToFirstOutboundCall">
            <label>Time to First Outbound Call</label>
        </div>
        <div class="md-form-group pb-2">
            <input class="md-input"
                   :disabled="true"
                   data-testid="contact-details-intake-source-input"
                   :value="contact.intake_source | toUpperCase">
            <label>Intake Source</label>
        </div>
        <div class="md-form-group pb-2"
             v-if="contact.external_data && contact.external_data.source">
            <input class="md-input"
                   :disabled="true"
                   data-testid="contact-details-external-source-input"
                   :value="contact.external_data.source | fixName">
            <label>External Source</label>
        </div>
    </div>
</template>

<script>
import auth from '../auth'
import {acl_mixin} from '../mixins'
import * as Countries from '../constants/countries'
import {mapState} from 'vuex'
import ContactAttributes from './contact-attributes/contact-attributes'
import ContactDispositionSelector from './contact-disposition-selector'
import LeadSourceSelector from './lead-source-selector'

export default {
    components: {ContactAttributes, ContactDispositionSelector, LeadSourceSelector},
    mixins: [acl_mixin],

    props: {
        contact: {
            required: true
        },
        first_outbound_call: {}
    },

    data() {
        return {
            auth: auth,
            edit: {
                lead_source: false,
                cnam_country: false,
                cnam_state: false,
                cnam_city: false,
                cnam_zipcode: false,
                company_name: false,
                address: false,
                website: false,
                email: false,
                timezone: false,
                notes: false,
                csf1: false,
                csf2: false,
                date_of_birth: false,
                user_id: false,
                initial_campaign_id: false,
            },
            loading_field: {
                lead_source: false,
                cnam_country: false,
                cnam_state: false,
                cnam_city: false,
                cnam_zipcode: false,
                company_name: false,
                address: false,
                website: false,
                email: false,
                timezone: false,
                csf1: false,
                csf2: false,
                date_of_birth: false,
                user_id: false,
                initial_campaign_id: false,
            },
            countries: Countries.COUNTRIES,
            country_states: {
                US: ['AL', 'AK', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'DC', 'FL', 'GA', 'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MD', 'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ', 'NM', 'NY', 'NC', 'ND', 'OH', 'OK', 'OR', 'PA', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT', 'VT', 'VA', 'WA', 'WV', 'WI', 'WY'],
                CA: ['AB', 'BC', 'MB', 'NB', 'NL', 'NT', 'NS', 'NU', 'ON', 'PE', 'QC', 'SK', 'YT']
            },
            loading_dispose: false,
        }
    },

    computed: {
        ...mapState(['disposition_statuses', 'campaigns']),
        ...mapState('cache', ['current_company', 'timezones']),

        dispositionStatusesAlphabeticalOrder() {
            if (this.disposition_statuses) {
                let disposition_statuses = _.clone(this.disposition_statuses)
                return disposition_statuses.sort((a, b) => {
                    let textA = a.name.toUpperCase()
                    let textB = b.name.toUpperCase()
                    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0
                })
            }
            return []
        },

        timeOfFirstOutboundCall() {
            if (this.first_outbound_call) {
                return this.$options.filters.fixFullDateUTCRelative(this.first_outbound_call.created_at)
            }

            return '--:--'
        },

        timeToFirstOutboundCall() {
            if (this.contact && this.first_outbound_call) {
                let contactCreated = moment(this.contact.created_at)
                let callCreated = moment(this.first_outbound_call.created_at)

                let formatted = this.formatHumanized(callCreated.diff(contactCreated))

                return !formatted ? '--:--' : `After ${formatted}`
            }

            return '--:--'
        },


        lineName() {
            let id = this.contact ? this.contact.initial_campaign_id : null

            if (!id) {
                return null
            }
            let found = this.campaigns.filter(campaign => campaign.active == true).find(campaign => campaign.id === id)
            if (found) {
                return found.name
            }

            return ''
        }
    },

    methods: {
        /**
         * Formats provided period(moment.diff) into human readable format
         *
         * @param period
         *
         * @return string
         */
        formatHumanized(period) {
            if (period === 0) {
                return '0 second'
            }

            let segments = []
            const duration = moment.duration(period)

            // return nothing when the duration is falsy or not correctly parsed (P0D)
            if (duration.toISOString() === "P0D" || !duration.isValid()) return ''

            // for duration's year value
            if (duration.years() >= 1) {
                segments.push(this.computeAndHumanize(duration, 'years', 'year'))
            }

            // for duration's month value
            if (duration.months() >= 1) {
                segments.push(this.computeAndHumanize(duration, 'months', 'month'))
            }

            // for duration's days value
            if (duration.days() >= 1) {
                segments.push(this.computeAndHumanize(duration, 'days', 'day'))
            }

            // for duration's hours value
            if (duration.hours() >= 1) {
                segments.push(this.computeAndHumanize(duration, 'hours', 'hour'))
            }

            // for duration's minutes value
            if (duration.minutes() >= 1) {
                segments.push(this.computeAndHumanize(duration, 'minutes', 'minute'))
            }

            // for duration's seconds value, this will only pass if segments is empty
            if (duration.seconds() >= 1 && !segments.length) {
                segments.push(this.computeAndHumanize(duration, 'seconds', 'second'))
            }

            // sanity test, if there are items added to the segment
            if (!segments.length) {
                return ''
            }

            // do formatting
            return segments.reduce((acc, cur, index) => acc += acc ? `${index === segments.length - 1 ? ' and' : ','} ${cur}` : cur, '')
        },

        /**
         * Converts the duration value and humanize result
         *
         * @param duration
         * @param field
         * @param singular
         *
         * @return string
         */
        computeAndHumanize(duration, field, singular) {
            const temp = Math.floor(duration[field]())
            return `${temp} ${temp > 1 ? field : singular}`
        },

        changeOwner(owner) {
            this.updateField('user_id', null, owner)
        },

        changeLeadSource(lead_source) {
            let lead_source_name = lead_source ? lead_source.name : null
            this.updateField('lead_source', null, lead_source_name)
        },

        changeInitialCampaign(campaign) {
            this.updateField('initial_campaign_id', null, campaign)
        },

        updateField: _.debounce(function (field, event, value) {
            this.fastUpdateField(field, event, value)
        }, 1000),

        fastUpdateField(field, event, value) {
            let params = {}
            if (value) {
                params[field] = value
                this.contact[field] = value
            } else if (event && event.target && event.target.value) {
                params[field] = event.target.value
                this.contact[field] = event.target.value
            } else {
                params[field] = null
                this.contact[field] = null
            }
            this.loading_field[field] = true
            // send an axios patch for a single field
            axios.patch('/api/v1/contact/' + this.contact.id, params)
                .then(res => {
                    this.loading_field[field] = false
                    this.$emit('contactUpdated', res.data)
                    this.edit[field] = false
                })
                .catch(err => {
                    this.loading_field[field] = false
                    this.$root.handleErrors(err.response)
                })
        },

        addHttps(url) {
            if (!/^(?:f|ht)tps?\:\/\//.test(url)) {
                url = "https://" + url
            }
            return url
        },

        openInNewTab(url) {
            url = this.addHttps(url)
            let win = window.open(url, '_blank')
            win.focus()
        },
    },

    watch: {
        'contact.tags': function () {
            this.$emit('contactUpdated', this.contact)
        }
    }
}
</script>
