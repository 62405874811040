<template>
    <el-tooltip
        class="item"
        effect="dark"
        :content="data.message"
        :disabled="emptyMessage || shouldHideTooltip"
        :append-to="'#' + nodeId"
        placement="bottom">

        <node-template :id="nodeId">
            <template v-slot:header>
                <span>Send Message</span>
            </template>

            <span v-text="data.message"
               v-if="!emptyMessage" />
            <span v-else>Enter bot's message.</span>
        </node-template>

    </el-tooltip>
</template>

<script>
import { alohabot_node_mixin  } from '../../../mixins'
import _ from 'lodash'

export default {
    name: 'message-node',
    mixins: [
        alohabot_node_mixin
    ],
    computed: {
        emptyMessage() {
            let message = _.get(this, 'data.message', undefined)
            return message == undefined || message == ''
        }
    }
}
</script>

<style scoped>
.el-tooltip {
    max-width: 300px;
}

.el-tooltip span {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    line-clamp: 2;
    -webkit-box-orient: vertical;
}
</style>

<style>
.el-tooltip__popper {
    max-width: 250px;
}
</style>
