var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "d-relative", attrs: { id: "calendar" } },
    [
      _c(
        "div",
        {
          staticClass: "fixed-header padding pt-0 pb-0",
          style: { top: _vm.top_offset + 50 + "px" },
        },
        [
          _c("div", { staticClass: "row mb-0 mt-0" }, [
            _c("div", { staticClass: "col-12 p-2" }, [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.searchMode,
                      expression: "searchMode",
                    },
                  ],
                },
                [
                  _c(
                    "div",
                    { staticClass: "d-flex" },
                    [
                      _c(
                        "div",
                        { staticClass: "container-filter-search w-50" },
                        [
                          _c(
                            "el-form",
                            {
                              ref: "form",
                              class: _vm.searchFocused ? "focused" : "blurred",
                              attrs: { model: _vm.search },
                              nativeOn: {
                                submit: function ($event) {
                                  $event.preventDefault()
                                  return _vm.handleSearch.apply(null, arguments)
                                },
                              },
                            },
                            [
                              _c(
                                "el-popover",
                                {
                                  attrs: {
                                    placement: "top",
                                    title: "",
                                    width: "300",
                                    trigger: "manual",
                                    content: "Please provide a search text",
                                  },
                                  model: {
                                    value: _vm.searchPopover,
                                    callback: function ($$v) {
                                      _vm.searchPopover = $$v
                                    },
                                    expression: "searchPopover",
                                  },
                                },
                                [
                                  _c(
                                    "el-input",
                                    {
                                      ref: "inputSearch",
                                      staticClass: "w-full",
                                      attrs: {
                                        slot: "reference",
                                        placeholder: "Search events...",
                                      },
                                      on: {
                                        focus: function ($event) {
                                          _vm.searchFocused = true
                                        },
                                        blur: function ($event) {
                                          _vm.searchFocused = false
                                        },
                                      },
                                      slot: "reference",
                                      model: {
                                        value: _vm.search.calendar_search,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.search,
                                            "calendar_search",
                                            $$v
                                          )
                                        },
                                        expression: "search.calendar_search",
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass:
                                          "el-input__icon el-icon-search",
                                        attrs: { slot: "prefix" },
                                        slot: "prefix",
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "el-button",
                                        {
                                          attrs: { slot: "append" },
                                          on: {
                                            click: function ($event) {
                                              _vm.showFilterSearch =
                                                !_vm.showFilterSearch
                                            },
                                          },
                                          slot: "append",
                                        },
                                        [
                                          !_vm.showFilterSearch
                                            ? _c("i", {
                                                staticClass: "fa fa-caret-down",
                                              })
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _vm.showFilterSearch
                                            ? _c("i", {
                                                staticClass: "fa fa-caret-up",
                                              })
                                            : _vm._e(),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("transition", { attrs: { name: "slide" } }, [
                                _vm.showFilterSearch
                                  ? _c("div", { staticClass: "dropdown" }, [
                                      _c(
                                        "table",
                                        { staticClass: "table-filters" },
                                        [
                                          _c("tr", [
                                            _c("td", [
                                              _vm._v(
                                                "\n                                                    Appointments\n                                                "
                                              ),
                                            ]),
                                            _vm._v(" "),
                                            _c(
                                              "td",
                                              { staticClass: "text-right" },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    staticClass: "mb-0",
                                                    attrs: {
                                                      "active-value": 1,
                                                      "inactive-value": 0,
                                                    },
                                                  },
                                                  [
                                                    _c("el-switch", {
                                                      staticClass: "mb-0",
                                                      attrs: {
                                                        "active-color":
                                                          "#00a344",
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.search
                                                            .appointments,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.search,
                                                            "appointments",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "search.appointments",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          _c("tr", [
                                            _c("td", [
                                              _vm._v(
                                                "\n                                                    Reminders\n                                                "
                                              ),
                                            ]),
                                            _vm._v(" "),
                                            _c(
                                              "td",
                                              { staticClass: "text-right" },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    staticClass: "mb-0",
                                                    attrs: {
                                                      "active-value": 1,
                                                      "inactive-value": 0,
                                                    },
                                                  },
                                                  [
                                                    _c("el-switch", {
                                                      staticClass: "mb-0",
                                                      attrs: {
                                                        "active-color":
                                                          "#00a344",
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.search.reminders,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.search,
                                                            "reminders",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "search.reminders",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          _c("tr", [
                                            _c("td", [
                                              _vm._v(
                                                "\n                                                    Status\n                                                "
                                              ),
                                            ]),
                                            _vm._v(" "),
                                            _c(
                                              "td",
                                              { staticClass: "text-right" },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    staticClass:
                                                      "mb-0 text-right",
                                                    attrs: {
                                                      width: "200",
                                                      "active-value": 1,
                                                      "inactive-value": 0,
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "el-select",
                                                      {
                                                        staticClass:
                                                          "filter-select text-right",
                                                        attrs: {
                                                          multiple: "",
                                                          placeholder: "All",
                                                        },
                                                        on: {
                                                          change:
                                                            _vm.searchStatusChange,
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.search
                                                              .calendar_status,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.search,
                                                              "calendar_status",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "search.calendar_status",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "el-option",
                                                          {
                                                            key: "0",
                                                            attrs: {
                                                              value: "0",
                                                              label: "All",
                                                            },
                                                          },
                                                          [
                                                            _c("strong", [
                                                              _vm._v("All"),
                                                            ]),
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _vm._l(
                                                          _vm.search_statuses,
                                                          function (s) {
                                                            return _c(
                                                              "el-option",
                                                              {
                                                                key: s,
                                                                attrs: {
                                                                  label:
                                                                    _vm
                                                                      .statusNames[
                                                                      s
                                                                    ],
                                                                  value: s,
                                                                },
                                                              }
                                                            )
                                                          }
                                                        ),
                                                      ],
                                                      2
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "p-3 text-right" },
                                        [
                                          _c(
                                            "el-button",
                                            {
                                              staticClass: "text-muted",
                                              attrs: { type: "text" },
                                              on: {
                                                click: _vm.resetSearchForm,
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                                                Reset\n                                            "
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "el-button",
                                            {
                                              attrs: {
                                                type: "success",
                                                disabled:
                                                  _vm.search.calendar_search
                                                    .length === 0,
                                              },
                                              on: { click: _vm.handleSearch },
                                            },
                                            [
                                              _vm._v(
                                                "\n                                                Search\n                                            "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ])
                                  : _vm._e(),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "ml-auto" },
                        [
                          _vm.loading
                            ? _c("span", { staticClass: "ml-auto" }, [
                                _c("i", { staticClass: "el-icon-loading" }),
                                _vm._v(
                                  " Loading Events...\n                            "
                                ),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _c("calendar-help"),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("el-button", { on: { click: _vm.toggleSearch } }, [
                        _vm._v("Close"),
                      ]),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              !_vm.searchMode
                ? _c("div", { staticClass: "d-flex" }, [
                    _c(
                      "div",
                      [
                        _c(
                          "span",
                          { attrs: { id: "gotoDatePicker" } },
                          [
                            _c("el-date-picker", {
                              ref: "gotoDatePicker",
                              attrs: {
                                type: "date",
                                placeholder: "Pick a day",
                                "picker-options": _vm.dpOptions,
                              },
                              on: { change: _vm.gotoDateView },
                              model: {
                                value: _vm.gotoDate,
                                callback: function ($$v) {
                                  _vm.gotoDate = $$v
                                },
                                expression: "gotoDate",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          { on: { click: _vm.triggerGotoDatePicker } },
                          [_c("i", { staticClass: "fa fa-calendar" })]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          { staticClass: "mr-1", on: { click: _vm.goToday } },
                          [
                            _vm._v(
                              "\n                            Today\n                        "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-tooltip",
                          {
                            staticClass: "item",
                            attrs: {
                              effect: "dark",
                              content: "Previous " + _vm.view,
                              placement: "bottom",
                            },
                          },
                          [
                            _c(
                              "el-button",
                              {
                                staticClass: "px-1 btn-direction",
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.changeDirection("subtract")
                                  },
                                },
                              },
                              [_c("i", { staticClass: "fa fa-chevron-left" })]
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-tooltip",
                          {
                            staticClass: "item",
                            attrs: {
                              effect: "dark",
                              content: "Next " + _vm.view,
                              placement: "bottom",
                            },
                          },
                          [
                            _c(
                              "el-button",
                              {
                                staticClass: "px-1 btn-direction",
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.changeDirection("add")
                                  },
                                },
                              },
                              [_c("i", { staticClass: "fa fa-chevron-right" })]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("h5", { attrs: { id: "h5-calendar-date" } }, [
                      _vm._v(_vm._s(_vm.formattedDate)),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "ml-auto" },
                      [
                        _vm.loading
                          ? _c("span", [
                              _c("i", { staticClass: "el-icon-loading" }),
                              _vm._v(
                                " Loading Events...\n                        "
                              ),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            staticClass: "btn-direction",
                            attrs: { type: "text" },
                            on: { click: _vm.toggleSearch },
                          },
                          [_c("i", { staticClass: "fa fa-search" })]
                        ),
                        _vm._v(" "),
                        _c("calendar-help"),
                        _vm._v(" "),
                        _c(
                          "el-badge",
                          {
                            staticClass: "item",
                            attrs: { value: 0, hidden: true },
                          },
                          [
                            _c(
                              "el-dropdown",
                              {
                                staticClass: "mr-1",
                                attrs: {
                                  trigger: "click",
                                  "hide-on-click": true,
                                },
                                on: { command: _vm.setView },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "dropdown",
                                      fn: function () {
                                        return [
                                          _c(
                                            "el-dropdown-menu",
                                            [
                                              _c(
                                                "el-dropdown-item",
                                                { attrs: { command: "month" } },
                                                [_vm._v("Month")]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "el-dropdown-item",
                                                { attrs: { command: "week" } },
                                                [_vm._v("Week")]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "el-dropdown-item",
                                                { attrs: { command: "day" } },
                                                [_vm._v("Day")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  false,
                                  1321933686
                                ),
                              },
                              [
                                _c("el-button", [
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        "text-transform": "capitalize",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                                        " +
                                          _vm._s(_vm.view) +
                                          "\n                                    "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("i", { staticClass: "fa fa-caret-down" }),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-badge",
                          {
                            staticClass: "item",
                            attrs: {
                              type: "success",
                              value: _vm.filterCount,
                              hidden: _vm.filterCount === 0,
                            },
                          },
                          [
                            _c(
                              "el-button",
                              { on: { click: _vm.toggleFilters } },
                              [_vm._v("Filters")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
            ]),
          ]),
        ]
      ),
      _vm._v(" "),
      _c("div", { attrs: { id: "container-sched" } }, [
        _vm.showSearchResult
          ? _c("div", { attrs: { id: "container-search-result" } }, [
              _vm.events.length > 0
                ? _c("table", { staticClass: "table table-hover" }, [
                    _vm._m(0),
                    _vm._v(" "),
                    _c(
                      "thead",
                      _vm._l(_vm.events, function (sd) {
                        return _c("tr", [
                          _c("td", { staticClass: "day-of-month" }, [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm._f("momentFormat")(sd.start_date, "D")
                                )
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("td", { staticClass: "date" }, [
                            _vm._v(
                              "\n                        " +
                                _vm._s(
                                  _vm._f("momentFormat")(
                                    sd.start_date,
                                    "MMM YYYY, ddd"
                                  )
                                ) +
                                "\n                    "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("td", { staticClass: "time" }, [
                            _vm._v(
                              "\n                        " +
                                _vm._s(
                                  _vm._f("momentFormat")(sd.start_date, "h:mm")
                                ) +
                                " – " +
                                _vm._s(
                                  _vm._f("momentFormat")(sd.end_date, "h:mm a")
                                ) +
                                "\n                    "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("td", [
                            _c("i", {
                              staticClass: "fa fa-circle mr-2",
                              class:
                                "event-color-type-" +
                                sd.type +
                                " status-" +
                                sd.status +
                                " " +
                                (sd.is_past ? "is_past" : ""),
                            }),
                            _vm._v(
                              "\n                        " +
                                _vm._s(sd.status_name) +
                                "\n                    "
                            ),
                          ]),
                          _vm._v(" "),
                          _c(
                            "td",
                            { staticClass: "contact" },
                            [
                              sd.contact != null
                                ? _c(
                                    "router-link",
                                    {
                                      staticClass: "text-dark-greenish",
                                      attrs: {
                                        to: {
                                          name: "Contact",
                                          params: { contact_id: sd.contact.id },
                                        },
                                        target: "_blank",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-tooltip",
                                        {
                                          staticClass: "item",
                                          attrs: {
                                            effect: "dark",
                                            content:
                                              "Click to go to contact's screen",
                                            placement: "bottom",
                                          },
                                        },
                                        [
                                          _c("div", { staticClass: "media" }, [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "media-body lh-1",
                                              },
                                              [
                                                _c(
                                                  "strong",
                                                  { staticClass: "d-block" },
                                                  [
                                                    _vm._v(
                                                      _vm._s(sd.contact.name)
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm._f("fixPhone")(
                                                        sd.contact.phone_number
                                                      )
                                                    )
                                                  ),
                                                ]),
                                              ]
                                            ),
                                          ]),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              sd.contact === null
                                ? _c("span", [
                                    _vm._v(
                                      "\n                            " +
                                        _vm._s(sd.text) +
                                        "\n                        "
                                    ),
                                  ])
                                : _vm._e(),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("td", { staticClass: "note" }, [
                            sd.body && sd.body.length > 0
                              ? _c("span", [_vm._v(_vm._s(sd.body))])
                              : _vm._e(),
                            _vm._v(" "),
                            !sd.body || sd.body.length === 0
                              ? _c("span", { staticStyle: { color: "#999" } }, [
                                  _vm._v(
                                    "\n                            No note provided\n                        "
                                  ),
                                ])
                              : _vm._e(),
                          ]),
                          _vm._v(" "),
                          _c(
                            "td",
                            { staticClass: "text-right" },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: { size: "small" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.editSchedule(sd)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                            Details\n                        "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ])
                      }),
                      0
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.events.length === 0
                ? _c("div", { staticClass: "text-center py-4" }, [
                    _c("p", { staticClass: "text-muted" }, [
                      _vm._v(
                        'No search result for "' +
                          _vm._s(_vm.search.calendar_search) +
                          '"'
                      ),
                    ]),
                  ])
                : _vm._e(),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.showSearchResult,
                expression: "!showSearchResult",
              },
            ],
            attrs: { id: "sched-header" },
          },
          [
            _c("table", { attrs: { id: "sched-header-table" } }, [
              _vm.view === "week"
                ? _c(
                    "tr",
                    [
                      _c("td", { attrs: { id: "td-scale" } }),
                      _vm._v(" "),
                      _vm._l(_vm.formattedWeekDays, function (d) {
                        return _c("td", { class: d.today ? "today" : "" }, [
                          _c("span", { staticClass: "day-of-week" }, [
                            _vm._v(_vm._s(d.dayOfWeek)),
                          ]),
                          _vm._v(" "),
                          _c("span", { staticClass: "day" }, [
                            _vm._v(_vm._s(d.day)),
                          ]),
                        ])
                      }),
                      _vm._v(" "),
                      _c("td", { staticStyle: { width: "20px" } }),
                    ],
                    2
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.view === "month"
                ? _c(
                    "tr",
                    _vm._l(
                      ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
                      function (d) {
                        return _c("td", [
                          _c("span", { staticClass: "day-of-week" }, [
                            _vm._v(_vm._s(d)),
                          ]),
                        ])
                      }
                    ),
                    0
                  )
                : _vm._e(),
            ]),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.showSearchResult,
                expression: "!showSearchResult",
              },
            ],
            class: _vm.view,
            attrs: { id: "sched-body" },
          },
          [
            _c("scheduler", {
              ref: "scheduler",
              staticClass: "actual-scheduler",
              class: _vm.view + "-view",
              attrs: { events: _vm.events },
              on: {
                "edit-schedule": _vm.editSchedule,
                "add-schedule": _vm.addSchedule,
                "filter-click": _vm.toggleFilters,
                "render-events": _vm.renderFromEvent,
                "toggle-goto-date": _vm.toggleGotoDate,
                "update-current-date": _vm.updateCurrentDate,
              },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("appointment-reminder-manager", {
        ref: "manager",
        on: { "render-schedule": _vm.renderSchedule },
      }),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "Filters",
            visible: _vm.dialogVisible,
            "before-close": _vm.closeFiltersMenu,
            width: "40%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c("div", {}, [
            _c(
              "div",
              { staticClass: "right-align" },
              [
                _c(
                  "el-form",
                  {
                    attrs: { "label-position": "left", "label-width": "200px" },
                  },
                  [
                    _c(
                      "el-form-item",
                      {
                        staticClass: "mb-0",
                        attrs: {
                          label: "Appointments",
                          "active-value": 1,
                          "inactive-value": 0,
                        },
                      },
                      [
                        _c("el-switch", {
                          staticClass: "mb-0",
                          attrs: { "active-color": "#00a344" },
                          model: {
                            value: _vm.filters.appointments,
                            callback: function ($$v) {
                              _vm.$set(_vm.filters, "appointments", $$v)
                            },
                            expression: "filters.appointments",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "right-align mb-2" },
              [
                _c(
                  "el-form",
                  {
                    attrs: { "label-position": "left", "label-width": "200px" },
                  },
                  [
                    _c(
                      "el-form-item",
                      {
                        staticClass: "mb-0",
                        attrs: {
                          label: "Reminders",
                          "active-value": 1,
                          "inactive-value": 0,
                        },
                      },
                      [
                        _c("el-switch", {
                          attrs: { "active-color": "#00A344" },
                          model: {
                            value: _vm.filters.reminders,
                            callback: function ($$v) {
                              _vm.$set(_vm.filters, "reminders", $$v)
                            },
                            expression: "filters.reminders",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "right-align mb-2" },
              [
                _c(
                  "el-form",
                  {
                    attrs: { "label-position": "left", "label-width": "200px" },
                  },
                  [
                    _c(
                      "el-form-item",
                      {
                        staticClass: "mb-0 text-right",
                        attrs: {
                          label: "Status",
                          "active-value": 1,
                          "inactive-value": 0,
                        },
                      },
                      [
                        _c(
                          "el-select",
                          {
                            staticClass: "filter-select",
                            attrs: { multiple: "", placeholder: "All" },
                            on: { change: _vm.filterStatusChange },
                            model: {
                              value: _vm.filters.calendar_status,
                              callback: function ($$v) {
                                _vm.$set(_vm.filters, "calendar_status", $$v)
                              },
                              expression: "filters.calendar_status",
                            },
                          },
                          [
                            _c(
                              "el-option",
                              { key: "0", attrs: { value: "0", label: "All" } },
                              [_c("strong", [_vm._v("All")])]
                            ),
                            _vm._v(" "),
                            _vm._l(_vm.dialog_statuses, function (s) {
                              return _c("el-option", {
                                key: s,
                                attrs: { label: _vm.statusNames[s], value: s },
                              })
                            }),
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "dropdown-divider" }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "right-align" },
              [
                _c(
                  "el-form",
                  {
                    attrs: { "label-position": "left", "label-width": "200px" },
                  },
                  [
                    _c(
                      "el-form-item",
                      { staticClass: "mb-3", attrs: { label: "Users" } },
                      [
                        _c("user-selector", {
                          staticClass: "filter-select",
                          attrs: {
                            value: _vm.filters.calendar_users,
                            multiple: true,
                            hide_extensions: true,
                          },
                          on: { change: _vm.changeAgents },
                          model: {
                            value: _vm.filters.calendar_users,
                            callback: function ($$v) {
                              _vm.$set(_vm.filters, "calendar_users", $$v)
                            },
                            expression: "filters.calendar_users",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "text-right mt-2" },
              [
                _c("el-button", { on: { click: _vm.closeFiltersMenu } }, [
                  _vm._v("Cancel"),
                ]),
                _vm._v(" "),
                _c(
                  "el-button",
                  {
                    attrs: { type: "success", disabled: _vm.loading },
                    on: { click: _vm.applyFilters },
                  },
                  [
                    _vm._v(
                      "\n                    Apply Filters\n                "
                    ),
                  ]
                ),
              ],
              1
            ),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "Go To Date",
            width: "30%",
            visible: _vm.gotoDateVisible,
          },
          on: {
            "update:visible": function ($event) {
              _vm.gotoDateVisible = $event
            },
          },
        },
        [
          _c(
            "span",
            [
              _c("el-date-picker", {
                staticClass: "w-100",
                attrs: {
                  type: "date",
                  format: "d MMM yyyy",
                  placeholder: "Pick a day",
                },
                model: {
                  value: _vm.gotoDate,
                  callback: function ($$v) {
                    _vm.gotoDate = $$v
                  },
                  expression: "gotoDate",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.gotoDateVisible = false
                    },
                  },
                },
                [_vm._v("Cancel")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "success" },
                  on: {
                    click: function ($event) {
                      return _vm.gotoDateView()
                    },
                  },
                },
                [_vm._v("\n                Confirm\n            ")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th", { attrs: { colspan: "2" } }, [_vm._v("Date")]),
        _vm._v(" "),
        _c("th", [_vm._v("Time")]),
        _vm._v(" "),
        _c("th", [_vm._v("Status")]),
        _vm._v(" "),
        _c("th", [_vm._v("Contact")]),
        _vm._v(" "),
        _c("th", [_vm._v("Note")]),
        _vm._v(" "),
        _c("th"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }