var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("p", { staticClass: "mb-1" }, [
        _c("b", [_vm._v("Responses to: ")]),
        _vm._v(_vm._s(_vm.fallback.type)),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "container-fluid fallback-messages" },
        _vm._l(_vm.fallback.messages, function (message, id) {
          return _c(
            "div",
            {
              key: id,
              staticClass: "px-2 py-1 mb-1 row",
              on: {
                click: function ($event) {
                  _vm.edit_id = id
                },
              },
            },
            [
              _vm.edit_id == id
                ? _c("el-input", {
                    ref: "fallback-" + id,
                    refInFor: true,
                    attrs: { autofocus: true },
                    on: {
                      blur: function ($event) {
                        $event.preventDefault()
                        _vm.edit_id = null
                      },
                      "hook:mounted": _vm.handleMounted,
                    },
                    nativeOn: {
                      keyup: function ($event) {
                        $event.preventDefault()
                        return _vm.handleKeyup.apply(null, arguments)
                      },
                    },
                    model: {
                      value: _vm.messages[id],
                      callback: function ($$v) {
                        _vm.$set(_vm.messages, id, $$v)
                      },
                      expression: "messages[id]",
                    },
                  })
                : [
                    _c("span", [_vm._v(" " + _vm._s(message) + " ")]),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        staticClass: "ml-auto material-icons pt-1",
                        on: {
                          click: function ($event) {
                            return _vm.removePhrase(id)
                          },
                        },
                      },
                      [_vm._v("close")]
                    ),
                  ],
            ],
            2
          )
        }),
        0
      ),
      _vm._v(" "),
      _vm.messages.length <= 5
        ? _c("el-input", {
            attrs: { placeholder: "New Message" },
            nativeOn: {
              keyup: function ($event) {
                $event.preventDefault()
                return _vm.handleNewMessageKeyup.apply(null, arguments)
              },
            },
            model: {
              value: _vm.newMessage,
              callback: function ($$v) {
                _vm.newMessage = $$v
              },
              expression: "newMessage",
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }