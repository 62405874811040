var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-table",
        { staticStyle: { width: "100%" }, attrs: { data: _vm.chatbotsData } },
        [
          _c("el-table-column", {
            attrs: { label: "Name", align: "left", "min-width": 50 },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "div",
                      { staticClass: "d-flex" },
                      [
                        _c("el-switch", {
                          staticClass: "mr-4 align-self-center",
                          attrs: { "active-color": "#00BF50" },
                          on: {
                            change: function ($event) {
                              return _vm.updateChatbotEnabled(scope.row)
                            },
                          },
                          model: {
                            value: scope.row.enabled,
                            callback: function ($$v) {
                              _vm.$set(scope.row, "enabled", $$v)
                            },
                            expression: "scope.row.enabled",
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "div",
                          [
                            _c(
                              "router-link",
                              {
                                attrs: {
                                  to: {
                                    name: "Chatbot",
                                    params: { bot_id: scope.row.id },
                                    query: { tab: "settings" },
                                  },
                                },
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "hover-lower-opacity" },
                                  [
                                    _c("p", { staticClass: "mb-0" }, [
                                      _c("b", [_vm._v(_vm._s(scope.row.name))]),
                                    ]),
                                    _vm._v(" "),
                                    _c("span", [
                                      _vm._v("#" + _vm._s(scope.row.id)),
                                    ]),
                                  ]
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "Workflow",
              prop: "workflow",
              align: "left",
              "min-width": 40,
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "router-link",
                      {
                        attrs: {
                          to: {
                            name: "Chatbot",
                            params: { bot_id: scope.row.id },
                            query: { tab: "builder" },
                          },
                        },
                      },
                      [
                        _c("div", { staticClass: "hover-lower-opacity" }, [
                          _c("p", { staticClass: "mb-0" }, [
                            _c("span", { staticClass: "font-weight-bold" }, [
                              _vm._v(_vm._s(scope.row.workflow)),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("span", { staticClass: "d-block pt-1 text-xs" }, [
                            _c("div", { staticClass: "hover-lower-opacity" }, [
                              _vm._v(
                                "\n                                Build Status:\n                                "
                              ),
                              scope.row.build_status == _vm.NOT_BUILD
                                ? _c(
                                    "span",
                                    { staticClass: "font-weight-bold" },
                                    [_vm._v("Not Ready")]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              scope.row.build_status == _vm.BUILD_SUCCESS
                                ? _c(
                                    "span",
                                    {
                                      staticClass:
                                        "font-weight-bold text-green",
                                    },
                                    [_vm._v("Ready")]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              scope.row.build_status == _vm.BUILD_IN_PROGRESS
                                ? _c(
                                    "span",
                                    { staticClass: "font-weight-bold" },
                                    [_vm._v("Building...")]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              scope.row.build_status == _vm.BUILD_FAILED
                                ? _c(
                                    "span",
                                    {
                                      staticClass: "font-weight-bold text-red",
                                    },
                                    [_vm._v("Failed")]
                                  )
                                : _vm._e(),
                            ]),
                          ]),
                        ]),
                      ]
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "Lines", "min-width": 40, align: "left" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("a", [
                      scope.row.campaigns.length === 1
                        ? _c(
                            "span",
                            { staticClass: "text-dark-greenish" },
                            [
                              _c(
                                "router-link",
                                {
                                  attrs: {
                                    to: {
                                      name: "Line Settings",
                                      params: {
                                        campaign_id: scope.row.campaigns[0]?.id,
                                      },
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "hover-lower-opacity" },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(scope.row.campaigns[0]?.name)
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("br"),
                                      _vm._v(" "),
                                      scope.row.campaigns[0].incoming_numbers
                                        .length === 1
                                        ? _c("span", [
                                            _vm._v(
                                              "\n                                    " +
                                                _vm._s(
                                                  _vm._f("fixPhone")(
                                                    scope.row.campaigns[0]
                                                      ?.incoming_numbers[0]
                                                      ?.phone_number
                                                  )
                                                ) +
                                                "\n                                "
                                            ),
                                          ])
                                        : _c("span", [
                                            _vm._v(
                                              "\n                                    " +
                                                _vm._s(
                                                  scope.row.campaigns[0]
                                                    ?.incoming_numbers.length
                                                ) +
                                                " Numbers\n                                "
                                            ),
                                          ]),
                                    ]
                                  ),
                                ]
                              ),
                            ],
                            1
                          )
                        : _c(
                            "span",
                            { staticClass: "text-dark-greenish" },
                            [
                              _c(
                                "router-link",
                                {
                                  attrs: {
                                    to: {
                                      name: "Chatbot",
                                      params: { bot_id: scope.row.id },
                                      query: { tab: "settings" },
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "hover-lower-opacity" },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(scope.row.campaigns.length) +
                                            " Lines"
                                        ),
                                      ]),
                                    ]
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "left", label: "Built by", "min-width": 50 },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.builder_user
                      ? _c(
                          "router-link",
                          {
                            attrs: {
                              to: {
                                name: "User Dialog",
                                params: { user_id: scope.row.builder_user.id },
                              },
                            },
                          },
                          [
                            _c("div", { staticClass: "hover-lower-opacity" }, [
                              _c("p", { staticClass: "mb-0" }, [
                                _c(
                                  "span",
                                  { staticClass: "font-weight-bold" },
                                  [_vm._v(_vm._s(scope.row.builder_user.name))]
                                ),
                              ]),
                              _vm._v(" "),
                              _c("span", [
                                _vm._v("#" + _vm._s(scope.row.builder_user.id)),
                              ]),
                              _vm._v(" "),
                              scope.row.built_at
                                ? _c(
                                    "span",
                                    { staticClass: "d-block pt-1 text-xs" },
                                    [
                                      _vm._v(
                                        "\n                            " +
                                          _vm._s(
                                            _vm._f("fixDateTime")(
                                              scope.row.built_at
                                            )
                                          ) +
                                          "\n                        "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]),
                          ]
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "left", label: "Created by", "min-width": 50 },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.user
                      ? _c(
                          "router-link",
                          {
                            attrs: {
                              to: {
                                name: "User Dialog",
                                params: { user_id: scope.row.user.id },
                              },
                            },
                          },
                          [
                            _c("div", { staticClass: "hover-lower-opacity" }, [
                              _c("p", { staticClass: "mb-0" }, [
                                _c(
                                  "span",
                                  { staticClass: "font-weight-bold" },
                                  [_vm._v(_vm._s(scope.row.user.name))]
                                ),
                              ]),
                              _vm._v(" "),
                              _c("span", [
                                _vm._v("#" + _vm._s(scope.row.user.id)),
                              ]),
                              _vm._v(" "),
                              scope.row.updated_at
                                ? _c(
                                    "span",
                                    { staticClass: "d-block pt-1 text-xs" },
                                    [
                                      _vm._v(
                                        "\n                            " +
                                          _vm._s(
                                            _vm._f("fixDateTime")(
                                              scope.row.updated_at
                                            )
                                          ) +
                                          "\n                        "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]),
                          ]
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "left", label: "Status", "min-width": 20 },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.enabled
                      ? _c("span", { staticClass: "text-green" }, [
                          _c("i", {
                            staticClass: "fa fa-play-circle text-success mr-1",
                          }),
                          _vm._v(" "),
                          _c("span", [_vm._v("Running")]),
                        ])
                      : _c("span", { staticClass: "text-red" }, [
                          _c("i", {
                            staticClass: "fa fa-pause-circle text-warning mr-1",
                          }),
                          _vm._v(" "),
                          _c("span", [_vm._v("Paused")]),
                        ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "Notes", "min-width": 30 },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("ChatbotNotes", { attrs: { chatbot: scope.row } })]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", "min-width": 40 },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "div",
                      { staticClass: "d-flex" },
                      [
                        _c(
                          "el-tooltip",
                          {
                            staticClass: "item",
                            attrs: {
                              effect: "dark",
                              content: "Modify",
                              placement: "top",
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "hover-lower-opacity-warning ml-4 mr-4",
                                on: {
                                  click: function ($event) {
                                    return _vm.handleEdit(scope.row)
                                  },
                                },
                              },
                              [
                                _c("i", {
                                  staticClass: "fa fa-pencil",
                                  staticStyle: { "font-size": "17px" },
                                  attrs: { "aria-hidden": "true" },
                                }),
                              ]
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-tooltip",
                          {
                            staticClass: "item",
                            attrs: {
                              effect: "dark",
                              content: "Export",
                              placement: "top",
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "hover-lower-opacity-primary mr-4",
                                attrs: { disabled: _vm.export_disabled },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleExport(scope.row)
                                  },
                                },
                              },
                              [
                                _c("i", {
                                  staticClass: "fa fa-upload",
                                  staticStyle: { "font-size": "17px" },
                                  attrs: { "aria-hidden": "true" },
                                }),
                              ]
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-tooltip",
                          {
                            staticClass: "item",
                            attrs: {
                              effect: "dark",
                              content: "Delete",
                              placement: "top",
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "hover-lower-opacity-danger",
                                attrs: { disabled: _vm.delete_disabled },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleDelete(scope.row)
                                  },
                                },
                              },
                              [
                                _c("i", {
                                  staticClass: "fa fa-trash-o",
                                  staticStyle: { "font-size": "17px" },
                                  attrs: { "aria-hidden": "true" },
                                }),
                              ]
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("DeleteChatbot", {
        attrs: {
          form_visible: _vm.delete_chatbot_visible,
          chatbot: _vm.chatbot,
        },
        on: { submit: _vm.onChatbotDelete, discard: _vm.closeDeleteChatbot },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }