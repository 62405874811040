<template>
    <div class="app-header"
         data-testid="app-header-component"
         :style="{top: top_offset + 'px'}">
        <terms-and-conditions data-testid="terms-and-conditions"
                              v-if="!auth.user.profile.created_by_reseller || isSimpSocial">
        </terms-and-conditions>

        <workflow-creator ref="workflow_creator"></workflow-creator>
        <div class="navbar navbar-toggleable-sm flex-row align-items-center justify-content-center bg-white">
            <!-- Open side - Naviation on mobile -->
            <a data-toggle="modal"
               data-target="#aside"
               data-testid="open-sidebar"
               class="d-lg-none mr-2">
                <i class="material-icons">&#xe5d2;</i>
            </a>
            <!-- / -->

            <a v-if="auth.user && auth.user.profile && !auth.user.profile.is_reseller"
               class="d-none d-lg-block mr-2 text-lg text-blackish"
               data-testid="toggle-sidebar"
               @click.prevent="toggleSidebar"
               :title="sidebar_folded ? 'Normal Layout' : 'Expanded Layout'">
                <i class="fa fa-bars"></i>
            </a>

            <!-- Page title - Bind to $state's title -->
            <div class="mb-0 h5 no-wrap"
                 id="page-title"
                 data-testid="page-title">
                <template v-if="isLargeEnough">
                    <template v-for="(route, idx) in validRoutes">
                        <template v-if="idx != 1">
                            <router-link :to="{name: route.name}">
                                {{ applyCustomPageTitle(route.name.capitalize()) }}
                            </router-link>
                            <span class="ml-1 mr-1 _100"
                                  v-if="idx < (validRoutes.length - 1) && currentRouteName.length > 0 && idx != 0">
                                >
                            </span>
                        </template>
                    </template>
                    <template v-if="pageTitle">
                        <span class="ml-1 mr-1 _100">
                            >
                        </span>
                        <span>
                            {{ pageTitle }}
                        </span>
                    </template>
                    <template v-if="contact_name">
                        <span class="ml-1 mr-1 _100">
                            >
                        </span>
                        <span>
                            {{ contact_name }}
                        </span>
                    </template>
                    <template v-if="currentSelectedTeamName">
                        <span class="ml-1 mr-1 _100">
                            >
                        </span>
                        <el-tooltip effect="dark"
                                    placement="bottom-start"
                                    :content="currentSelectedTeamName">
                            <span>{{ currentSelectedTeamName | truncateText }}</span>
                        </el-tooltip>
                    </template>
                </template>
                <template v-else>
                    <router-link :to="{name: lastRoute.name}"
                                 v-if="lastRoute && lastRoute.name && !contact_name">
                        {{ applyCustomPageTitle(lastRoute.name.capitalize()) }}
                    </router-link>
                    <template v-else>
                        <span>
                            {{ contact_name }}
                        </span>
                    </template>
                </template>
            </div>

            <!-- navbar collapse -->
            <div class="collapse navbar-collapse"
                 data-testid="navabar-collapse"
                 id="collapse">
            </div>
            <!-- / navbar collapse -->

            <!-- navbar right -->
            <ul :class="{'nav': true, 'navbar-nav': true, 'ml-auto': true, 'flex-row': true, 'no-select': true}"
                data-testid="navbar-right">
                <li class="nav-item mr-2"
                    v-if="auth.user.profile.focus_mode"
                    data-testid="navabar-right-li">
                    <span class="nav-link">
                        <button class="btn btn-xs btn-outline amber-700 text-white _400"
                                style="vertical-align: -2px;"
                                data-testid="li-focus-mode-on">
                            Focus Mode On
                        </button>
                    </span>
                </li>
                <li v-if="talkEnabled && isLargeEnough"
                    class="nav-item d-flex align-items-center mr-2"
                    data-testid="navabar-right-li">
                    <span class="nav-link">
                        <talk-bridge v-if="auth.user.profile"
                                     data-testid="li-talk-bridge"/>
                    </span>
                </li>
                <li data-testid="navabar-right-li"
                    class="nav-item d-flex align-items-center alo-talk-info-container"
                    v-if="talkEnabled && !forceTalk && isLargeEnough && !isSimpSocial">
                    <span class="nav-link">
                        <el-popover
                            :style="{'verticalAlign': '-2px !important'}"
                            ref="aloInfo"
                            placement="bottom-end"
                            width="550"
                            trigger="hover"
                            popper-class="alo-popover"
                            data-testid="li-popover">
                            <div class="alo-default-app-selector-dialog">
                                <alo-talk-info data-testid="li-alo-talk-info"/>
                            </div>
                            <button
                                slot="reference"
                                class="btn btn-xs btn-outline btn-default text-white pl-2 pr-2 alo-talk-info-button"
                                data-testid="new-btn">
                                <span>
                                    New
                                    <img src="/assets/images/app-icons/info-icon-outline.png"
                                         alt="info-icon"
                                         data-testid="new-btn-img"/>
                                </span>
                            </button>
                        </el-popover>
                    </span>
                </li>
                <li class="nav-item mr-2"
                    v-if="usage && isLargeEnough && !isMobileSize && hasRole('Billing Admin') && !isTrialKYC && !isSimpSocial">
                    <span class="nav-link"
                          data-testid="navabar-right-li">
                        <router-link :to="{ name: 'Account', query: { tab: 'billing' }}"
                                     style="vertical-align: -2px;">
                            <button class="btn btn-xs btn-outline _400"
                                    :style="{ color: '#ffffff', 'background-color': creditColor }">
                                <span>
                                    {{ usage.credits | fixRounding | toCurrency }}
                                </span>
                            </button>
                        </router-link>
                    </span>
                </li>

                <li class="nav-item dropdown pos-stc-xs mr-2"
                    v-if="!whitelabel && !loading_whitelabel && !isMobileSize"
                    data-testid="navabar-right-li">
                    <a class="nav-link"
                       data-toggle="dropdown"
                       data-tour-step="2"
                       data-testid="question-circle-icon-a"
                       ref="helpMenu">
                        <i class="fa fa-question-circle text-2x text-danger"
                           style="vertical-align: -9px;"></i>
                    </a>
                    <!-- WAT:653
                         - for selfServeAccounts show Request a solution engineer and link to the chat support icon
                         - this also applies to account thas as internal sales rep or internal CSM
                         - for trial accounts show Request a Sales Rep call and link to their calendar
                         - for regular accounts show Request a CSM call and link to their calendar
                        -->
                    <HelpDropdown
                        :csm-info="csmInfo"
                        :debugging-info="debuggingInfo"
                        :is-self-served-account="auth.isSelfServedAccount()"
                        :role-name="roleName"
                        :sales-rep-info="salesRepInfo"
                        :service-code="serviceCode"
                        :show-csm-info="hasCsm && !csmInfo.internal && !isTrial && !isSimpSocial"
                        :show-order-training-button="!isSimpSocial"
                        :show-request-solution-engineer-button="(auth.isSelfServedAccount() || csmInfo.internal || salesRepInfo.internal) && !isSimpSocial"
                        :show-sales-rep-info="hasSalesRep && !salesRepInfo.internal && isTrial && !isSimpSocial"
                        :show-setup-guide="!isReseller"
                    >
                        <template #customLegend v-if="showInternalAccountLegend">
                            <hr />
                            <p class="mb-0 mt-2 text-xs">
                                <span class="_600">This is an internal account</span>
                            </p>
                        </template>
                    </HelpDropdown>
                </li>

                <li class="nav-item dropdown"
                    :class="{'bg-dark': is_impersonated}"
                    data-testid="navabar-right-li">
                    <a :class="{'nav-link': true, 'dropdown-toggle': true, 'text-ellipsis': true, 'text-white': is_impersonated}"
                       data-toggle="dropdown"
                       data-flip="false"
                       data-testid="user-logged-in"
                       data-tour-step="3"
                       ref="mainMenu">
                        <div class="profile-info d-flex align-items-center pl-1"
                             v-if="auth.user.profile">
                            <div class="d-flex flex-column ml-2"
                                 style="line-height: 1.2rem;">
                                <small class="dropdown-label"
                                       :class="{'text-white': is_impersonated}">
                                    {{ auth.user.profile.name }}
                                </small>
                                <span class="company-name _600"
                                      :class="{'text-white': is_impersonated}"
                                      v-if="current_company">
                                    {{ current_company.name }}
                                </span>
                            </div>
                            <i class="material-icons ml-2">keyboard_arrow_down</i>
                        </div>
                    </a>
                    <div class="dropdown-menu dropdown-menu-right dropdown-menu-overlay dropdown-menu-scale pull-right">
                        <div class="row"
                             v-if="auth.user.profile">
                            <div class="col-12">
                                <router-link :to="{ name: 'User Dialog', params: {user_id: auth.user.profile.id} }"
                                             class="dropdown-item">
                                    <i class="material-icons">person</i>
                                    Profile Settings
                                </router-link>
                            </div>
                        </div>

                        <div class="row nav-padding"
                             v-if="hasRole(['Company Agent', 'Company Admin']) && auth.user.profile && !auth.user.profile.is_reseller">
                            <div class="dropdown-item"
                                 v-if="!auth.user.profile.company.force_talk"
                                 data-testid="focus-mode"
                                 @click.prevent>
                                <i class="material-icons">adjust</i>
                                <span>Focus Mode</span>
                                <el-switch v-model="auth.user.profile.focus_mode"
                                           class="ml-2 pull-right"
                                           active-color="#00a344"
                                           :disabled="auth.user.profile.company_id == 522 && env == 'production'"
                                           @change="changeFocusMode">
                                </el-switch>
                            </div>
                            <div class="dropdown-item"
                                 data-testid="sleep-mode"
                                 @click.prevent>
                                <i class="material-icons fa-rotate-180">brightness_2</i>
                                <span>Sleep Mode</span>
                                <el-switch v-model="auth.user.profile.sleep_mode"
                                           class="ml-2 pull-right"
                                           active-color="#00a344"
                                           @change="changeSleepMode">
                                </el-switch>
                            </div>
                        </div>

                        <div class="dropdown-divider"></div>

                        <div class="row"
                             v-if="!loading_whitelabel && !whitelabel && !isResold">
                            <div class="col-12">
                                <a href="https://aloware.com/partnerships/"
                                   data-testid="my-referral-link-a"
                                   class="dropdown-item"
                                   target="_blank">
                                    <i class='fas fa-link'></i>
                                    My Referral Link
                                </a>
                            </div>
                        </div>

                        <div class="row"
                             v-if="!loading_whitelabel && !whitelabel">
                            <div class="col-12">
                                <a href="https://meetings.hubspot.com/alwr/aloware-demo"
                                   class="dropdown-item"
                                   data-testid="book-a-demo-a"
                                   target="_blank">
                                    <i class='material-icons'>perm_contact_calendar</i>
                                    Book a Demo
                                </a>
                            </div>
                        </div>

                        <div class="dropdown-divider"></div>

                        <div class="row"
                             v-if="!loading_whitelabel && !whitelabel">
                            <div class="col-12">
                                <a href="https://aloware.com/apps"
                                   class="dropdown-item"
                                   data-testid="desktop-apps-a"
                                   target="_blank">
                                    <i class="material-icons">desktop_mac</i>
                                    Desktop Apps
                                </a>
                            </div>
                        </div>

                        <div class="row"
                             v-if="!loading_whitelabel && !whitelabel">
                            <div class="col-12">
                                <a href="https://aloware.com/apps"
                                   class="dropdown-item"
                                   data-testid="mobile-apps-a"
                                   target="_blank">
                                    <i class="material-icons">devices</i>
                                    Mobile Apps
                                </a>
                            </div>
                        </div>

                        <div class="row"
                             v-if="!loading_whitelabel && !whitelabel">
                            <div class="col-12">
                                <a href="https://chrome.google.com/webstore/detail/aloware-click-to-call/kmchiimcbhaaadgpnelddelgiaeahobi?hl=en-US"
                                   class="dropdown-item"
                                   data-testid="chrome-ext-a"
                                   target="_blank">
                                    <i class="fab fa-chrome"></i>
                                    Chrome Ext. (CTI)
                                </a>
                            </div>
                        </div>

                        <div class="dropdown-divider"
                             v-if="auth.user.profile.has_multiple_access">
                        </div>

                        <template v-if="auth.user.profile.has_multiple_access">
                            <div class="row">
                                <div class="col-12">
                                    <router-link :to="{ name: 'Account Selector' }"
                                                 class="dropdown-item">
                                        <i class="fas fa-list"></i>
                                        Select Account
                                    </router-link>
                                </div>
                            </div>
                        </template>

                        <div class="dropdown-divider"></div>

                        <div class="row"
                             data-testid="logout-row"
                             @click="noClose($event)">
                            <div class="col-12">
                                <a class="dropdown-item text-danger"
                                   data-testid="logout-a"
                                   @click="proceedToLogout()">
                                    <i class="material-icons">power_settings_new</i>
                                    {{ is_impersonated ? 'Stop impersonating' : 'Sign out' }}
                                </a>
                            </div>
                        </div>
                    </div>
                </li>

                <li class="nav-item dropdown"
                    v-if="auth.user.profile && !current_company.force_talk"
                    data-testid="navabar-right-li">
                    <a class="nav-link d-flex align-items-center no-select"
                       data-tour-step="4"
                       data-testid="user-status-a"
                       :class="[statusDropdownMode ? 'disabled pointer-not-allowed' : '']"
                       data-toggle="dropdown"
                       ref="agentMenu">
                        <span class="w-40 avatar agent-avatar grey-300"
                              v-bind:style="avatarStyle(auth.user.profile.name)">
                            <span>{{ auth.user.profile.name | initials }}</span>
                            <i class="b-white bottom"
                               :class="[ $options.filters.agentStatusClass(auth.user.profile.agent_status) ]">
                            </i>
                        </span>
                        <i class="material-icons">keyboard_arrow_down</i>
                    </a>
                    <agent-status-dropdown
                        id="avatar-agent-statuses"
                        class="dropdown-menu dropdown-menu-right dropdown-menu-overlay dropdown-menu-scale pull-right"
                        ref="avatar_agent_statuses"
                        data-testid="avatar-agent-statuses"
                        :user_id="auth.user.profile.id"
                        :forced_disabled_ui="true">
                    </agent-status-dropdown>
                </li>
            </ul>
            <!-- / navbar right -->
        </div>
    </div>
</template>

<script>
import auth from '../auth'
import {
    acl_mixin,
    avatar_mixin,
    csm_info_mixin,
    webrtc_mixin,
    styling_mixin,
    kyc_mixin,
    helper_mixin
} from '../mixins'
import {mapActions, mapGetters, mapState} from 'vuex'
import WorkflowCreator from "./automations/workflow-creator"
import AgentStatusDropdown from './misc/agent-status-dropdown'
import TalkBridge from './talk-bridge'
import AloTalkInfo from './alo-talk-info'
import HelpDropdown from './dropdowns/help-dropdown'
import * as Roles from '../constants/roles'
import * as AgentStatus from '../constants/agent-status'
import * as AgentStatusLabels from '../constants/agent-status-labels'
import VueCookies from 'vue-cookies'

export default {
    mixins: [
        styling_mixin,
        acl_mixin,
        avatar_mixin,
        webrtc_mixin,
        kyc_mixin,
        helper_mixin,
        csm_info_mixin
    ],

    components: {
        WorkflowCreator,
        AgentStatusDropdown,
        TalkBridge,
        AloTalkInfo,
        HelpDropdown
    },

    data() {
        return {
            auth: auth,
            is_impersonated: localStorage.getItem('impersonate'),
            env: null,
            branch: null,
            fake_number_purchase: null,
            fake_power_dialer_calls: null,
            fake_text_messages: null,
            fake_toll_free_verification: null,
            mock_a2p_brand_registration: null,
            mock_a2p_campaign_registration: null,
            pageTitle: null,
            is_live: false,
            whitelabel: false,
            loading_whitelabel: true,
            loading_focus_mode: false,
            loading_sleep_mode: false,
            contact_name: null,
            window_size: null,
            AgentStatusLabels,
            Roles,
        }
    },

    computed: {
        ...mapGetters('cache', ['isSimpSocial', 'isTrial']),
        ...mapGetters(['currentSelectedTeamName']),

        ...mapState({
            on_call: state => state.on_call,
            on_wrap_up: state => state.on_wrap_up,
            sidebar_folded: state => state.cache.sidebar_folded,
            current_company: state => state.cache.current_company,
            usage: state => state.usage,
            campaigns: state => state.campaigns,
            contacts: state => state.contacts
        }),

        canCommunicate() {
            return !this.hasReporterAccess && !this.forceTalk
        },

        isLargeEnough() {
            let valid_sizes = ['xxl', 'xl', 'lg', 'md']
            if (valid_sizes.includes(this.$mq)) {
                return true
            }
            return false
        },

        isMobileSize() {
            return this.window_size <= 425;
        },

        validRoutes() {
            return this.$route.matched.filter(route => route.name !== undefined && route.name !== 'Contact')
        },

        lastRoute() {
            if (this.validRoutes.length > 0) {
                return this.validRoutes.slice(-1)[0]
            }

            return null
        },

        creditColor() {
            if (this.usage) {
                if (this.usage.credits < 0) {
                    return '#f56c6c'
                } else if (this.usage.credits == 0) {
                    return '#090A0D'
                } else if (this.usage.credits <= 10) {
                    return '#fd7200'
                } else {
                    return '#2196f3'
                }
            }
        },

        hasAccess() {
            if (this.hasRole('Billing Admin') && this.auth.user.profile.is_risky && !this.auth.user.profile.card_added) {
                return false
            }

            if (this.hasReporterAccess) {
                return false
            }

            return true
        },

        currentRouteName() {
            return this.$route.name
        },

        isRinging() {
            if (this.auth && this.auth.user && this.auth.user.profile) {
                return this.auth.user.profile.agent_status == AgentStatus.AGENT_STATUS_RINGING
            }

            return false
        },

        isSentryMode() {
            if (this.auth && this.auth.user && this.auth.user.profile) {
                return this.auth.user.profile.agent_status == AgentStatus.AGENT_STATUS_SENTRY
            }

            return false
        },

        statusDropdownMode() {
            // allow manual change from Sentry mode to other status
            if (this.isSentryMode) {
                return false
            }

            const isAgentStatusOnCall = this.auth.user.profile.agent_status === AgentStatus.AGENT_STATUS_ON_CALL

            if ((this.on_call || this.isRinging || isAgentStatusOnCall) && !this.on_wrap_up) {
                return true
            }

            return !!((this.on_call || this.on_wrap_up) && this.current_company && (this.current_company.force_call_disposition || this.current_company.force_contact_disposition))
        },

        currentUser() {
            if (!this.auth.user || !this.auth.user.profile) {
                return {}
            }

            return {
                id: this.auth.user.profile.id,
                email: this.auth.user.profile.email,
                name: this.auth.user.profile.name
            }
        },

        isCreateLineAllowed() {
            return this.enabledToAddLines()
        },

        sequencesFeatureRestricted() {
            return !this.shouldShowSequences && this.shouldShowUpgradeNow()
        },

        isReseller(){
            return this.auth.user?.profile?.company?.reseller_id
        },

        showInternalAccountLegend() {
            if (this.auth.isSelfServedAccount()) {
                return false
            }

            return this.csmInfo.internal || this.salesRepInfo.internal
        },

        salesRepInfo(){
            return this.auth.hasSalesRep()?
                {
                    // we've a mix of combination for names so we use the name column as first option or the combination of first and last name
                    name: auth.user.profile.sales_rep.name || `${auth.user.profile.sales_rep.first_name || ''} ${auth.user.profile.sales_rep.last_name || ''}`,
                    email: auth.user.profile.sales_rep.email || '',
                    phone: auth.user.profile.sales_rep.phone_number || '',
                    calendar_link: auth.user.profile.sales_rep.calendar_link || '',
                    internal: Boolean(auth.user.profile.sales_rep.is_internal_account)
                }
                : {}
        },

        debuggingInfo(){
            return {
                env:  this.env || 'production',
                branch: this.branch || '',
                fake_number_purchase: this.fake_number_purchase,
                fake_power_dialer_calls: this.fake_power_dialer_calls,
                fake_text_messages: this.fake_text_messages,
                fake_toll_free_verification: this.fake_toll_free_verification,
                mock_a2p_brand_registration: this.mock_a2p_brand_registration,
                mock_a2p_campaign_registration: this.mock_a2p_campaign_registration
            }
        },

        serviceCode(){
            return this.auth.user.profile ?  `${this.auth.user.profile.company_id}-${this.auth.user.profile.id}` : ''
        },

        roleName(){
            return this.auth.user.profile.role_name
        }
    },

    created() {
        this.$cookies = VueCookies
        // initialize window width size
        this.window_size = window.screen.width
        // Add listener to window resize
        window.addEventListener("resize", this.windowResize);

        this.getWhitelabelStatus()

        if (!this.isLargeEnough) {
            this.setSidebarFolded(false)
        }

        VueEvent.listen('page_title_updated', (pageTitle) => {
            this.pageTitle = pageTitle
        })

        VueEvent.listen('user_updated', (user) => {
            if (this.auth && this.auth.user.profile && user.id == this.auth.user.profile.id) {
                this.auth.user.profile.focus_mode = user.focus_mode
            }
        })

        VueEvent.listen('agent_status_updated', (event) => {
            if (this.auth && this.auth.user.profile && event.user_id == this.auth.user.profile.id) {
                this.auth.user.profile.agent_status = event.agent_status
            }
        })

        VueEvent.listen('add_contact', (data) => {
            axios.post('/api/v1/contact', {
                add_phone_number: this.$options.filters.fixPhone(data.phone_number)
            }).then(res => {
                this.$router.replace('/contacts/' + res.data.id).catch(err => {
                })
            })
        })

        VueEvent.listen('contact_name_breadcrumb', (data) => {
            this.contact_name = data
        })

        VueEvent.listen('user_mentioned', (data) => {
            // note: i couldn't find a way to use a Vue component instead with this notify package
            // that would've been more ideal
            let redirect_link = `<a class="font-weight-bold text-dark-greenish" href="${data.redirect_path}">contact note</a>`

            this.$notify({
                title: 'Mentioned',
                dangerouslyUseHTMLString: true,
                message: `${data.mentioner} has mentioned you in a ${redirect_link}.`,
                type: 'success',
                showClose: true,
                duration: 10000
            })
        })

        VueEvent.listen('user_logout', (event) => {
            const current_auth_token = this.getCookie('aloware_shared_auth_token')

            if (auth.user.authenticated && current_auth_token === event.cookie_auth_token) {
                auth.clear()
                this.logoutUser()
                window.location.href = '/'
            }
        })

        setInterval(() => {
            this.is_live = (window.Echo && window.Echo.connector.pusher.connection.connection !== null)
        }, 1000)
    },

    methods: {
        windowResize() {
            this.window_size = window.screen.width
        },

        applyCustomPageTitle(title) {
            switch (title.toLowerCase()) {
                case 'account management portal':
                    return 'Dashboard'
                default:
                    return title
            }
        },

        getWhitelabelStatus() {
            this.loading_whitelabel = true
            axios.get('/get-statics').then(res => {
                this.whitelabel = res.data.whitelabel
                this.loading_whitelabel = false
            }).catch(err => {
                console.log(err)
                this.loading_whitelabel = false
                this.$root.handleErrors(err.response)
            })
        },

        changeFocusMode() {
            this.loading_focus_mode = true
            axios.post('/api/v1/profile', {
                focus_mode: this.auth.user.profile.focus_mode
            }).then(res => {
                const message = 'Focus mode ' + ((this.auth.user.profile.focus_mode) ? 'activated' : 'deactivated')
                this.loading_focus_mode = false
                this.$notify({
                    offset: 95,
                    title: 'Focus Mode',
                    message: message + '. Reloading ...',
                    type: 'success'
                })
                localStorage.setItem('PREVIOUS_ROUTE_NAME', 'null')
                VueEvent.fire('reloadPageComponent')
            }).catch(err => {
                console.log(err)
                this.$root.handleErrors(err.response)
                this.loading_focus_mode = false
            })
        },

        changeSleepMode() {
            this.loading_sleep_mode = true
            axios.post('/api/v1/profile', {
                sleep_mode: this.auth.user.profile.sleep_mode
            }).then(res => {
                const message = 'Sleep mode ' + ((this.auth.user.profile.sleep_mode) ? 'activated' : 'deactivated')
                this.loading_sleep_mode = false
                this.$notify({
                    offset: 95,
                    title: 'Sleep Mode',
                    message: message + '. Reloading ...',
                    type: 'success'
                })
                localStorage.setItem('PREVIOUS_ROUTE_NAME', 'null')
                VueEvent.fire('reloadPageComponent')
            }).catch(err => {
                console.log(err)
                this.$root.handleErrors(err.response)
                this.loading_sleep_mode = false
            })
        },

        noClose(event) {
            if (event) {
                event.stopPropagation()
            }
        },

        toggleSidebar() {
            this.setSidebarFolded(!this.sidebar_folded)
            VueEvent.fire('content_resized')
        },

        toggleDialer(e) {
            if (!this.supportsWebrtc) {
                this.$alert('Sorry, your browser does not have the support for the WebRTC feature.', 'Oops!', {
                    confirmButtonText: 'Ok',
                    center: true,
                    callback: cancel => {
                    }
                })
                return
            }
            if (!this.campaigns || this.campaigns.length == 0) {
                this.$alert('Sorry, you need to add a line first to use the dialer.', 'Oops!', {
                    confirmButtonText: 'Ok',
                    center: true,
                    callback: cancel => {
                    }
                })
                return
            }
            VueEvent.fire('open_webrtc')
        },

        proceedToLogout() {
            const parsed_cookie_name = `simpsocial-migration-${this.auth.user.profile.id}`
            this.$cookies.set(parsed_cookie_name, null, 3650)
            this.logout()
        },

        ...mapActions('cache', ['setSidebarFolded', 'resetCache']),
        ...mapActions(['logoutUser'])
    },

    beforeCreate() {
        axios.get('/build-info.json').then(res => {
            this.env = res.data.env
            this.branch = res.data.branch
            this.fake_number_purchase = res.data.fake_number_purchase
            this.fake_power_dialer_calls = res.data.fake_power_dialer_calls
            this.fake_text_messages = res.data.fake_text_messages
            this.fake_toll_free_verification = res.data.fake_toll_free_verification
            this.mock_a2p_brand_registration = res.data.mock_a2p_brand_registration
            this.mock_a2p_campaign_registration = res.data.mock_a2p_campaign_registration
        }).catch(err => {
            console.log(err)
        })
    },

    destroyed() {
        window.removeEventListener("resize", this.windowResize);
    },

    watch: {
        '$route.path': function () {
            this.pageTitle = null
            this.contact_name = null
        },

        is_live: {
            handler(is_live) {
                this.$emit('live:status', is_live)
            }
        },
    }
}
</script>
