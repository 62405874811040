var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm._l(_vm.phrases, function (phrase, id) {
        return _c(
          "span",
          { key: id, staticClass: "mb-2" },
          [
            _c(
              "el-tag",
              {
                staticClass: "mr-2 mb-2 font-weight-bold",
                staticStyle: { "font-size": "14px!important" },
                attrs: {
                  type: id == _vm.editPhrase ? "warning" : "",
                  closable: _vm.phrases.length > 1 && !_vm.disabled,
                  "disable-transitions": false,
                  disabled: _vm.disabled,
                },
                on: {
                  close: function ($event) {
                    return _vm.handlePhraseDelete(id)
                  },
                  click: function ($event) {
                    return _vm.handleClickTag(id)
                  },
                },
              },
              [_vm._v("\n            " + _vm._s(phrase) + "\n        ")]
            ),
          ],
          1
        )
      }),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      (_vm.addPhrase || _vm.editPhrase != null) && !_vm.disabled
        ? _c("el-input", {
            ref: "phrase-input",
            staticClass: "ml-1 mt-2",
            attrs: {
              placeholder: "Type the phrase and press enter to submit",
              size: "small",
              disabled: _vm.disabled,
            },
            on: { "hook:mounted": _vm.focusInput, blur: _vm.handleInputCancel },
            nativeOn: {
              keyup: function ($event) {
                $event.preventDefault()
                return ((event) =>
                  _vm.handleKeyup(event, _vm.editPhrase)).apply(null, arguments)
              },
            },
            model: {
              value: _vm.input,
              callback: function ($$v) {
                _vm.input = $$v
              },
              expression: "input",
            },
          })
        : !_vm.disabled
        ? _c(
            "el-button",
            {
              staticClass: "mt-2",
              attrs: { size: "small", round: "", disabled: _vm.disabled },
              on: {
                click: function ($event) {
                  _vm.addPhrase = true
                },
              },
            },
            [_vm._v("\n        + Add Training Phrase\n    ")]
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }