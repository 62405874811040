var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-card",
    {
      class: [_vm.onlyHeader ? "only-header" : ""],
      attrs: { id: _vm.id },
      scopedSlots: _vm._u(
        [
          {
            key: "header",
            fn: function () {
              return [
                _c("div", { staticClass: "d-flex" }, [
                  _c("div", { staticClass: "vertical-center mr-auto" }, [
                    _c(
                      "span",
                      { staticClass: "text-black font-weight-bold" },
                      [_vm._t("header")],
                      2
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "vertical-center ml-auto" }, [
                    !_vm.hide_edit_button
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "text-dark text-muted hover-lower-opacity-warning d-inline",
                            on: { click: _vm.openSidePanel },
                          },
                          [
                            _c("i", {
                              staticClass: "fa fa-external-link mr-3",
                              staticStyle: { "font-size": "17px" },
                              attrs: { "aria-hidden": "true" },
                            }),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    !_vm.hide_close_button
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "text-dark text-muted hover-lower-opacity-danger d-inline",
                            on: { click: _vm.removeNode },
                          },
                          [
                            _c("i", {
                              staticClass: "fa fa-trash-o",
                              staticStyle: { "font-size": "17px" },
                              attrs: { "aria-hidden": "true" },
                            }),
                          ]
                        )
                      : _vm._e(),
                  ]),
                ]),
              ]
            },
            proxy: true,
          },
        ],
        null,
        true
      ),
    },
    [
      _vm._v(" "),
      _c("div", { staticClass: "px-3 py-2" }, [_vm._t("default")], 2),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }