<template>
    <el-dialog class="dialog-blank alo-force-talk-dialog"
               width="800px"
               ref="force-talk-dialog"
               id="force-talk-dialog"
               append-to-body
               :visible="shouldForceTalk"
               :close-on-click-modal="false"
               :show-close="false"
               v-if="!isSimpSocial">
        <div class="alo-force-talk-dialog__body">
            <h4 class="listing-heading d-flex align-items-center alo-force-talk-dialog__title"
                slot="title">
                New Aloware Talk
                <img src="/assets/images/app-icons/tada.png"
                    alt="tada-icon"
                    class="ml-2">
            </h4>

            <div class="break-word">
                <p>Your administrator is moving you off of {{ alowareClassic }}, and onto Aloware Talk, our new and improved platform built for agents! Please click on the button below to be redirected to Aloware Talk.</p>
            </div>

            <el-divider></el-divider>

            <a :href="talk2Url">
                <el-button size="large"
                           type="primary"
                           class="w-100 d-flex justify-content-center align-items-center alo-force-talk-dialog__redirect-button">
                    Continue to Aloware Talk
                </el-button>
            </a>
        </div>
    </el-dialog>
</template>

<script>
    import auth from "../auth"
    import { acl_mixin, helper_mixin } from '../mixins'

    export default {
        name: 'force-talk-dialog',

        mixins: [
            acl_mixin,
            helper_mixin
        ],

        data: () => ({
            auth
        }),

        computed: {
            talk2Url () {
                const is_impersonating = localStorage.getItem('impersonate') == 'true'
                return localStorage.getItem('talk_url') + (is_impersonating ? '?impersonating=1' : '')
            }
        },

        created() {
            if (this.shouldForceTalk) {
                if (!localStorage.getItem('forceTalkDialogShown')) {
                    localStorage.setItem('forceTalkDialogShown', true)
                } else {
                    this.redirect()
                }
            }
        },

        mounted () {
            var _this = this

            // if the element is not rendered, do not create observer
            if (!this.$refs['force-talk-dialog']?.$el?.parentElement) {
                return
            }

            // check if modal was manually removed to redirect user if needed
            new MutationObserver(function(mutations) {
                mutations.forEach(mutation => {
                    mutation.removedNodes.forEach(node => {
                        if (node.id === 'force-talk-dialog') {
                            _this.redirect()
                        }
                    })
                })
            }).observe(this.$refs['force-talk-dialog'].$el.parentElement, { childList: true })
        },

        methods: {
            redirect () {
                if (this.shouldForceTalk) {
                    console.log('redirecting 1')
                    window.location.href = this.talk2Url
                }
            }
        }
    }
</script>
