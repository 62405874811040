var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.single_button
        ? _c(
            "el-tooltip",
            {
              staticClass: "item",
              attrs: {
                effect: "dark",
                content: "Show Transcription",
                "data-testid": "communication-transcription-tooltip",
                placement: "bottom",
              },
            },
            [
              _c(
                "button",
                {
                  staticClass: "btn btn-sm blue btn-icon opaq",
                  attrs: {
                    "data-testid": "communication-transcription-button",
                  },
                  on: { click: _vm.fetchSmartTranscriptionData },
                },
                [_c("i", { staticClass: "material-icons" }, [_vm._v("chat")])]
              ),
            ]
          )
        : _c(
            "span",
            {
              staticClass: "text text-dark-greenish m-0 p-0 pointer",
              staticStyle: { "font-size": "14px!important" },
              attrs: {
                "data-test": "communication-smart-transcription-button",
              },
              on: { click: _vm.fetchSmartTranscriptionData },
            },
            [
              _c("i", {
                staticClass: "fa fa-file-text",
                staticStyle: { "margin-left": "1px" },
              }),
              _vm._v(" "),
              _c("span", { staticStyle: { "margin-left": "5px" } }, [
                _vm._v(_vm._s(this.button_text)),
              ]),
            ]
          ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            top: "5vh",
            width: "95%",
            visible: _vm.show_form,
            "append-to-body": true,
            "data-testid": "communication-transcription-dialog",
            "before-close": _vm.handleClose,
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "mb-0 pb-0",
              attrs: { slot: "title" },
              slot: "title",
            },
            [
              _c("h4", { staticClass: "text-dark mb-0" }, [
                _vm._v("Smart Transcription"),
              ]),
            ]
          ),
          _vm._v(" "),
          _vm.show_form
            ? _c(
                "el-row",
                { staticClass: "mb-3" },
                [
                  _c("el-col", { attrs: { lg: 24 } }, [
                    _vm.remote_url
                      ? _c(
                          "div",
                          { staticClass: "p-1 border" },
                          [
                            _c("waveform", {
                              attrs: {
                                "data-test":
                                  "communication-transcription-waveform",
                                remote_url: _vm.remote_url,
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.loading
            ? _c(
                "strong",
                {
                  staticClass: "text-center",
                  attrs: {
                    "data-test": "communication-transcription-loading-text",
                  },
                },
                [_vm._v("\n            Loading...\n        ")]
              )
            : _c(
                "el-row",
                {
                  attrs: {
                    "data-test": "communication-transcription-row-table",
                    gutter: 15,
                  },
                },
                [
                  _c(
                    "el-col",
                    {
                      attrs: {
                        lg: 12,
                        md: 24,
                        sm: 24,
                        "data-test": "communication-transcription-col-table",
                      },
                    },
                    [
                      _c("h5", { staticClass: "mb-1 text-dark" }, [
                        _vm._v("Categories"),
                      ]),
                      _vm._v(" "),
                      _c("hr", { staticClass: "my-1" }),
                      _vm._v(" "),
                      !_vm.isEmpty(_vm.iab_categories)
                        ? _c(
                            "table",
                            {
                              staticClass: "my-4",
                              staticStyle: { width: "100%" },
                              attrs: {
                                "data-test":
                                  "communication-transcription-categories-table",
                              },
                            },
                            _vm._l(
                              _vm.iab_categories,
                              function (category_summary, summary_index) {
                                return _c(
                                  "tr",
                                  {
                                    key: summary_index,
                                    attrs: {
                                      "data-test":
                                        "communication-transcription-catgories-row-table",
                                    },
                                  },
                                  [
                                    _c(
                                      "td",
                                      {
                                        attrs: {
                                          "data-test":
                                            "communication-transcription-categories-column-table",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-breadcrumb",
                                          {
                                            attrs: {
                                              "data-test":
                                                "communication-transcription-categories-breadcrumb",
                                              "separator-class":
                                                "el-icon-arrow-right",
                                            },
                                          },
                                          _vm._l(
                                            category_summary.categories,
                                            function (
                                              category,
                                              category_index
                                            ) {
                                              return _c(
                                                "el-breadcrumb-item",
                                                {
                                                  key: category_index,
                                                  attrs: {
                                                    "data-testid":
                                                      "communication-transcription-categories-breadcrum-item",
                                                  },
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v(_vm._s(category)),
                                                  ]),
                                                ]
                                              )
                                            }
                                          ),
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "td",
                                      { staticClass: "text-right" },
                                      [
                                        _c(
                                          "el-tooltip",
                                          {
                                            staticClass: "item",
                                            attrs: {
                                              effect: "dark",
                                              content:
                                                "Relevance between the conversation and this category",
                                              "data-testid":
                                                "relevance-conversation-category-tooltip",
                                              placement: "left",
                                            },
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticStyle: {
                                                  "font-size": "16px",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                    " +
                                                    _vm._s(
                                                      category_summary.relevance
                                                    ) +
                                                    "%\n                                "
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                )
                              }
                            ),
                            0
                          )
                        : _c("div", [
                            _c("span", [
                              _vm._v(
                                "\n                        We couldn't find any categories in this call. For more information please check\n                        "
                              ),
                              _c(
                                "a",
                                {
                                  staticStyle: { color: "blue" },
                                  attrs: {
                                    "data-testid":
                                      "no-categories-this-articles-link",
                                    href: "https://support.aloware.com/en/articles/9037887-frequently-asked-questions-smart-transcription",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                           this article\n                        "
                                  ),
                                ]
                              ),
                              _vm._v(".\n                    "),
                            ]),
                          ]),
                      _vm._v(" "),
                      _c("h5", { staticClass: "mt-3 mb-1 text-dark" }, [
                        _vm._v("Highlights"),
                      ]),
                      _vm._v(" "),
                      _c("hr", { staticClass: "my-1" }),
                      _vm._v(" "),
                      !_vm.isEmpty(_vm.highlights)
                        ? _c(
                            "div",
                            _vm._l(
                              _vm.speakers,
                              function (speaker, speaker_index) {
                                return _c(
                                  "div",
                                  { key: speaker_index, staticClass: "mt-2" },
                                  [
                                    _c("b", [_vm._v(_vm._s(speaker))]),
                                    _vm._v(" "),
                                    _c("br"),
                                    _vm._v(" "),
                                    _vm._l(
                                      _vm.highlights[speaker],
                                      function (highlight, highlight_index) {
                                        return _c(
                                          "div",
                                          {
                                            key: highlight_index,
                                            staticStyle: { display: "inline" },
                                          },
                                          [
                                            _c(
                                              "el-tag",
                                              {
                                                staticClass: "mr-1 mt-1",
                                                staticStyle: {
                                                  background:
                                                    "#67def3!important",
                                                  color: "black",
                                                  "font-size": "15px",
                                                },
                                                attrs: {
                                                  type: "info",
                                                  effect: "dark",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                " +
                                                    _vm._s(highlight) +
                                                    "\n                            "
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        )
                                      }
                                    ),
                                  ],
                                  2
                                )
                              }
                            ),
                            0
                          )
                        : _c("div", [
                            _c("span", { staticClass: "mt-3" }, [
                              _vm._v(
                                "\n                        We couldn't find any highlights in this call. For more information please check\n                        "
                              ),
                              _c(
                                "a",
                                {
                                  staticStyle: { color: "blue" },
                                  attrs: {
                                    "data-testid":
                                      "no-highlights-this-articles-link",
                                    href: "https://support.aloware.com/en/articles/9037887-frequently-asked-questions-smart-transcription",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                           this article\n                        "
                                  ),
                                ]
                              ),
                              _vm._v(".\n                    "),
                            ]),
                          ]),
                      _vm._v(" "),
                      _c("h5", { staticClass: "mt-3 mb-1 text-dark" }, [
                        _vm._v("Entities"),
                      ]),
                      _vm._v(" "),
                      _c("hr", { staticClass: "my-1" }),
                      _vm._v(" "),
                      !_vm.isEmpty(_vm.entities)
                        ? _c(
                            "div",
                            _vm._l(
                              _vm.speakers,
                              function (speaker, speaker_index) {
                                return _c("div", { key: speaker_index }, [
                                  _vm.entities[speaker]
                                    ? _c(
                                        "div",
                                        { staticClass: "mt-2" },
                                        [
                                          _c("b", [_vm._v(_vm._s(speaker))]),
                                          _vm._v(" "),
                                          _vm._l(
                                            _vm.entity_types,
                                            function (type, type_index) {
                                              return _c(
                                                "table",
                                                {
                                                  key: type_index,
                                                  attrs: {
                                                    "data-test":
                                                      "comm-transcription-entities-table",
                                                  },
                                                },
                                                [
                                                  _vm.entities[speaker][type]
                                                    ? _c(
                                                        "tr",
                                                        {
                                                          attrs: {
                                                            "data-test":
                                                              "comm-transcription-entities-row",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "td",
                                                            {
                                                              attrs: {
                                                                "data-test":
                                                                  "comm-transcription-entities-column",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm._f(
                                                                    "ucfirst"
                                                                  )(type)
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "td",
                                                            {
                                                              attrs: {
                                                                "data-test":
                                                                  "comm-transcription-entities-column",
                                                              },
                                                            },
                                                            _vm._l(
                                                              _vm.entities[
                                                                speaker
                                                              ][type],
                                                              function (
                                                                entity,
                                                                entity_index
                                                              ) {
                                                                return _c(
                                                                  "div",
                                                                  {
                                                                    key: entity_index,
                                                                    staticClass:
                                                                      "d-inline",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "el-tag",
                                                                      {
                                                                        staticClass:
                                                                          "mx-1 mt-1",
                                                                        staticStyle:
                                                                          {
                                                                            background:
                                                                              "#87FBB9!important",
                                                                            color:
                                                                              "black!important",
                                                                            "font-size":
                                                                              "15px",
                                                                          },
                                                                        attrs: {
                                                                          type: "success",
                                                                          "data-test":
                                                                            "comm-transcription-entities-tag-table",
                                                                          effect:
                                                                            "dark",
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "\n                                                " +
                                                                            _vm._s(
                                                                              entity
                                                                            ) +
                                                                            "\n                                            "
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ],
                                                                  1
                                                                )
                                                              }
                                                            ),
                                                            0
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                ]
                                              )
                                            }
                                          ),
                                        ],
                                        2
                                      )
                                    : _vm._e(),
                                ])
                              }
                            ),
                            0
                          )
                        : _c("div", [
                            _c("span", { staticClass: "mt-3" }, [
                              _vm._v(
                                "\n                        We couldn't find any specific entities in this call. For more information please check\n                        "
                              ),
                              _c(
                                "a",
                                {
                                  staticStyle: { color: "blue" },
                                  attrs: {
                                    "data-testid":
                                      "no-entities-this-articles-link",
                                    href: "https://support.aloware.com/en/articles/9037887-frequently-asked-questions-smart-transcription",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                           this article\n                        "
                                  ),
                                ]
                              ),
                              _vm._v(".\n                    "),
                            ]),
                          ]),
                      _vm._v(" "),
                      _c("h5", { staticClass: "mt-3 mb-1 text-dark" }, [
                        _vm._v("Custom Keywords Frequency"),
                      ]),
                      _vm._v(" "),
                      _c("hr", { staticClass: "my-1" }),
                      _vm._v(" "),
                      !_vm.isEmpty(_vm.custom_keywords)
                        ? _c(
                            "div",
                            _vm._l(
                              _vm.speakers,
                              function (speaker, speaker_index) {
                                return _c("div", { key: speaker_index }, [
                                  _vm.custom_keywords[speaker]
                                    ? _c(
                                        "div",
                                        { staticClass: "mt-2" },
                                        [
                                          _c("b", [_vm._v(_vm._s(speaker))]),
                                          _vm._v(" "),
                                          _c("br"),
                                          _vm._v(" "),
                                          _vm._l(
                                            Object.keys(
                                              _vm.custom_keywords[speaker]
                                            ),
                                            function (keyword, idx) {
                                              return _c(
                                                "div",
                                                {
                                                  key: idx,
                                                  staticStyle: {
                                                    display: "inline!important",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "el-tag",
                                                    {
                                                      staticClass: "mr-1 mt-1",
                                                      staticStyle: {
                                                        background:
                                                          "#ffd57e!important",
                                                        color:
                                                          "black!important",
                                                        "font-size": "15px",
                                                      },
                                                      attrs: {
                                                        type: "warning",
                                                        "data-test":
                                                          "custom-keywords-tag",
                                                        effect: "dark",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                                    " +
                                                          _vm._s(
                                                            _vm._f("ucfirst")(
                                                              keyword
                                                            )
                                                          )
                                                      ),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticStyle: {
                                                            color:
                                                              "gray!important",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              ` x ${_vm.custom_keywords[speaker][keyword]}`
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              )
                                            }
                                          ),
                                        ],
                                        2
                                      )
                                    : _vm._e(),
                                ])
                              }
                            ),
                            0
                          )
                        : _c("div", [
                            _c("span", [
                              _vm._v(
                                "\n                        We couldn't find any custom keywords in this call. For more information please check\n                        "
                              ),
                              _c(
                                "a",
                                {
                                  staticStyle: { color: "blue" },
                                  attrs: {
                                    "data-testid":
                                      "no-custom-key-this-articles-link",
                                    href: "https://support.aloware.com/en/articles/9037887-frequently-asked-questions-smart-transcription",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                           this article\n                        "
                                  ),
                                ]
                              ),
                              _vm._v(".\n                    "),
                            ]),
                          ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c("el-col", { attrs: { lg: 12, md: 24, sm: 24 } }, [
                    _c("div", [
                      _c("div", { staticClass: "mb-1" }, [
                        _c("strong", [_vm._v("Overall Sentiment:")]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticStyle: { display: "inline!important" } },
                          [
                            !_vm.isEmpty(_vm.sentiment_analysis)
                              ? _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      display: "inline!important",
                                    },
                                  },
                                  _vm._l(
                                    _vm.sentiment_analysis,
                                    function (
                                      sentiment_summary,
                                      sentiment_index
                                    ) {
                                      return _c(
                                        "div",
                                        {
                                          key: sentiment_index,
                                          staticClass: "mx-1",
                                          staticStyle: {
                                            display: "inline!important",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                                    " +
                                              _vm._s(
                                                sentiment_summary.speaker
                                              ) +
                                              " :\n                                    "
                                          ),
                                          _c(
                                            "el-tooltip",
                                            {
                                              staticClass: "item",
                                              staticStyle: {
                                                display: "inline!important",
                                              },
                                              attrs: {
                                                effect: "dark",
                                                content:
                                                  _vm.calculateOverAllSentimentBySpeaker(
                                                    sentiment_summary
                                                  ),
                                                "data-test":
                                                  "overall-sentiment-tooltip",
                                                placement: "top",
                                              },
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    padding: "0.2em 0.2em",
                                                    "border-radius": "5px",
                                                    color: "black",
                                                    "font-size":
                                                      "13px!important",
                                                  },
                                                  style: {
                                                    background:
                                                      _vm.sentimentColors[
                                                        sentiment_summary
                                                          .overall
                                                      ],
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                            " +
                                                      _vm._s(
                                                        sentiment_summary.overall
                                                      ) +
                                                      "\n                                        "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      )
                                    }
                                  ),
                                  0
                                )
                              : _vm._e(),
                          ]
                        ),
                        _vm._v(" "),
                        !_vm.isEmpty(_vm.talk_time_analysis)
                          ? _c(
                              "div",
                              {
                                staticStyle: {
                                  display: "inline!important",
                                  "margin-left": "15px",
                                },
                              },
                              [
                                _c("strong", [_vm._v("Talk Time:")]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      display: "inline!important",
                                    },
                                  },
                                  _vm._l(
                                    _vm.speakers,
                                    function (speaker, speaker_index) {
                                      return _c(
                                        "div",
                                        {
                                          key: speaker_index,
                                          staticClass: "mx-1",
                                          staticStyle: {
                                            display: "inline!important",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                                    " +
                                              _vm._s(speaker) +
                                              ":\n                                    "
                                          ),
                                          _c(
                                            "b",
                                            {
                                              staticStyle: {
                                                padding: "0.2em 0.2em",
                                                "border-radius": "5px",
                                                color: "black",
                                                "font-size": "13px!important",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                                        " +
                                                  _vm._s(
                                                    _vm.talk_time_analysis[
                                                      speaker
                                                    ]
                                                  ) +
                                                  "%\n                                    "
                                              ),
                                            ]
                                          ),
                                        ]
                                      )
                                    }
                                  ),
                                  0
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]),
                      _vm._v(" "),
                      _c("div", [
                        _c(
                          "section",
                          { ref: "chatArea", staticClass: "chat-area" },
                          [
                            !_vm.isEmpty(_vm.messages)
                              ? _c(
                                  "div",
                                  _vm._l(
                                    _vm.messages,
                                    function (message, message_index) {
                                      return _c("div", { key: message_index }, [
                                        _c(
                                          "p",
                                          {
                                            staticClass: "message break-word",
                                            class: {
                                              "message-out": _vm.inArray(
                                                message.speaker,
                                                ["AGENT", "A"]
                                              ),
                                              "message-in": !_vm.inArray(
                                                message.speaker,
                                                ["AGENT", "A"]
                                              ),
                                            },
                                            style: {
                                              background:
                                                _vm.sentimentColors[
                                                  message.sentiment
                                                ],
                                            },
                                          },
                                          [
                                            _c("strong", [
                                              _vm._v(
                                                "Speaker: " +
                                                  _vm._s(message.speaker)
                                              ),
                                            ]),
                                            _vm._v(" "),
                                            _c("br"),
                                            _vm._v(" "),
                                            _c("span", {
                                              staticStyle: {
                                                "line-height": "1.6",
                                              },
                                              domProps: {
                                                innerHTML: _vm._s(
                                                  _vm.circleText(
                                                    message.speaker,
                                                    message.text
                                                  )
                                                ),
                                              },
                                            }),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "span",
                                          {
                                            staticClass: "sentiment",
                                            class: {
                                              "sentiment-out": _vm.inArray(
                                                message.speaker,
                                                ["AGENT", "A"]
                                              ),
                                              "sentiment-in": !_vm.inArray(
                                                message.speaker,
                                                ["AGENT", "A"]
                                              ),
                                            },
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                style: {
                                                  color:
                                                    _vm.sentimentColors[
                                                      message.sentiment
                                                    ],
                                                },
                                              },
                                              [_vm._v("⬤")]
                                            ),
                                            _vm._v(
                                              "\n                                        " +
                                                _vm._s(
                                                  message.sentiment_possibility
                                                ) +
                                                "% " +
                                                _vm._s(message.sentiment) +
                                                "\n                                    "
                                            ),
                                          ]
                                        ),
                                      ])
                                    }
                                  ),
                                  0
                                )
                              : _c("div", { staticClass: "text-center" }, [
                                  _c("span", [_vm._v("No Conversation")]),
                                ]),
                          ]
                        ),
                      ]),
                    ]),
                  ]),
                ],
                1
              ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }