var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.message_object && _vm.message_object.contact
    ? _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticClass: "messenger-wrapper container-fluid px-3 py-2 mb-1",
        },
        [
          _c(
            "el-dialog",
            {
              staticClass: "schedule-dialog",
              attrs: {
                title: "Schedule Message",
                width: "300px",
                "data-testid": "message-composer-dialog",
                visible: _vm.show_schedule_dialog,
                "before-close": _vm.closeSchedulePicker,
                "close-on-click-modal": false,
                "append-to-body": "",
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "footer",
                    fn: function () {
                      return [
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-flex flex-row justify-content-between mt-2 mb-1",
                          },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  size: "small",
                                  "data-testid": "message-composer-reset-buton",
                                },
                                on: { click: _vm.resetScheduleDateTime },
                              },
                              [
                                _vm._v(
                                  "\n                    Reset\n                "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  size: "small",
                                  type: "success",
                                  disabled: !_vm.is_schedule_deliverable,
                                  "data-testid":
                                    "message-composer-schedule-button",
                                },
                                on: { click: _vm.setScheduleDateTime },
                              },
                              [
                                _c(
                                  "i",
                                  { staticClass: "material-icons mr-2" },
                                  [_vm._v("schedule")]
                                ),
                                _vm._v(" "),
                                _c("span", [_vm._v("Schedule Send")]),
                              ]
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        !_vm.is_schedule_deliverable
                          ? _c(
                              "div",
                              {
                                staticClass: "text-right text-danger",
                                staticStyle: { "font-size": "12px" },
                              },
                              [
                                _c("i", {
                                  staticClass:
                                    "fa fa-exclamation-triangle text-danger",
                                }),
                                _vm._v(
                                  " Schedule must be in the future\n            "
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]
                    },
                    proxy: true,
                  },
                ],
                null,
                false,
                624664162
              ),
            },
            [
              _c(
                "div",
                { staticClass: "d-flex flex-column" },
                [
                  _c("v-date-picker", {
                    attrs: {
                      "min-date": new Date(),
                      mode: "dateTime",
                      "data-testid": "message-composer-schedule-date-picker",
                      color: "green",
                    },
                    model: {
                      value: _vm.schedule_date,
                      callback: function ($$v) {
                        _vm.schedule_date = $$v
                      },
                      expression: "schedule_date",
                    },
                  }),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _vm.showDetails
            ? _c(
                "div",
                {
                  staticClass:
                    "messenger-info d-flex flex-wrap wrap-auto-margin mb-2",
                },
                [
                  _vm.name
                    ? _c("span", [
                        _c("strong", [_vm._v("Contact:")]),
                        _vm._v(" "),
                        _c(
                          "a",
                          {
                            staticClass: "_600 text-dark-greenish",
                            attrs: {
                              href: "#",
                              title: "Add to message",
                              "data-testid": "message-composer-contact-link",
                            },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.appendToMessageBody(_vm.name)
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n                " +
                                _vm._s(_vm.name) +
                                "\n            "
                            ),
                          ]
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.auth.user && _vm.auth.user.profile
                    ? _c("span", [
                        _c("strong", [_vm._v("User:")]),
                        _vm._v(" "),
                        _c(
                          "a",
                          {
                            staticClass: "_600 text-dark-greenish",
                            attrs: { href: "#", title: "Add to message" },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.appendToMessageBody(
                                  _vm.auth.user.profile.name
                                )
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n                " +
                                _vm._s(_vm.auth.user.profile.name) +
                                "\n            "
                            ),
                          ]
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.selectedCampaign
                    ? _c("span", [
                        _c("strong", [_vm._v("Line:")]),
                        _vm._v(" "),
                        _c(
                          "a",
                          {
                            staticClass: "_600 text-dark-greenish",
                            attrs: {
                              href: "#",
                              title: "Add to message",
                              "data-testid": "message-composer-line-link",
                            },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.appendToMessageBody(
                                  _vm.selectedCampaign.name
                                )
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n                " +
                                _vm._s(_vm.selectedCampaign.name) +
                                "\n            "
                            ),
                          ]
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.contact_incoming_number &&
                  _vm.contact_incoming_number.phone_number
                    ? _c("span", [
                        _c("strong", [_vm._v("Line Number:")]),
                        _vm._v(" "),
                        _c(
                          "a",
                          {
                            staticClass: "_600 text-dark-greenish",
                            attrs: {
                              href: "#",
                              title: "Add to message",
                              "data-testid":
                                "message-composer-line-number-link",
                            },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.appendToMessageBody(
                                  _vm.contact_incoming_number.phone_number
                                )
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n                " +
                                _vm._s(
                                  _vm._f("fixPhone")(
                                    _vm.contact_incoming_number.phone_number
                                  )
                                ) +
                                "\n            "
                            ),
                          ]
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("span", [
                    _c("strong", [_vm._v("Phone Number:")]),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        staticClass: "_600 text-dark-greenish",
                        attrs: {
                          href: "#",
                          title: "Add to message",
                          "data-testid": "message-composer-phone-number-link",
                        },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.appendToMessageBody(
                              _vm.message_object.phone_number
                            )
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s(
                              _vm._f("fixPhone")(
                                _vm.message_object.phone_number
                              )
                            ) +
                            "\n            "
                        ),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      staticClass: "ml-auto",
                      class: [
                        _vm.reachedMessageLimit || _vm.highlightMessageLimitInfo
                          ? "text-red font-weight-bold"
                          : "",
                      ],
                    },
                    [
                      _vm.reachedMessageLimit || _vm.highlightMessageLimitInfo
                        ? _c(
                            "span",
                            { staticClass: "font-weight-normal mr-2" },
                            [
                              _vm._v(
                                "\n                The message body has reached the allowed character limit.\n            "
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm.message_object.body
                              ? _vm.smartEncodedMessageLength
                              : 0
                          ) +
                          " / " +
                          _vm._s(_vm.message_body_limit) +
                          "\n        "
                      ),
                    ]
                  ),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            {
              staticStyle: { border: "solid 1px #ddd", "border-radius": "5px" },
            },
            [
              _c(
                "div",
                { staticClass: "messenger-input d-flex flex-row mb-2" },
                [
                  _c("textarea", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.message_object.body,
                        expression: "message_object.body",
                      },
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.generatingUrls,
                        expression: "generatingUrls",
                      },
                    ],
                    ref: "message_composer_text",
                    staticClass: "message-input w-100 px-3 py-2",
                    class: _vm.disabled ? "opacity-5" : "",
                    attrs: {
                      id: "message-input",
                      name: "message",
                      placeholder: "Write a message",
                      maxlength: _vm.message_body_limit,
                      disabled: _vm.disabled || _vm.loading,
                      "data-testid": "message-composer-message-textarea",
                    },
                    domProps: { value: _vm.message_object.body },
                    on: {
                      keydown: _vm.handleEnterPress,
                      change: _vm.reHeightMessageInput,
                      focus: _vm.highlightBottomBar,
                      blur: _vm.onBlurMessageInput,
                      keyup: _vm.reHeightMessageInput,
                      input: [
                        function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.message_object,
                            "body",
                            $event.target.value
                          )
                        },
                        function ($event) {
                          return _vm.imposeMessageCharLimit($event)
                        },
                      ],
                    },
                  }),
                ]
              ),
              _vm._v(" "),
              _vm.message_object.attachments.length > 0 ||
              _vm.message_object.gif
                ? _c(
                    "div",
                    {
                      staticClass:
                        "attachment-previews d-flex flex-row px-3 py-2",
                      staticStyle: { "overflow-x": "auto" },
                    },
                    [
                      _vm.message_object.gif
                        ? _c(
                            "div",
                            {
                              staticClass: "attachment-preview",
                              staticStyle: { height: "5rem", width: "5rem" },
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: _vm.message_object.gif,
                                  "data-testid": "message-composer-gif",
                                },
                              }),
                              _vm._v(" "),
                              _c(
                                "el-button",
                                {
                                  staticClass: "attachment-preview-close",
                                  attrs: {
                                    size: "mini",
                                    type: "danger",
                                    circle: "",
                                    "data-testid":
                                      "message-composer-remove-gif",
                                  },
                                  on: { click: _vm.removeGif },
                                },
                                [_c("i", { staticClass: "fa fa-times" })]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm._l(
                        _vm.message_object.attachments,
                        function (attachment) {
                          return [
                            attachment.mimetype.includes("image")
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "attachment-preview",
                                    staticStyle: {
                                      height: "5rem",
                                      width: "5rem",
                                    },
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        src: _vm.getImagePreviewLink(
                                          attachment.uuid
                                        ),
                                        "data-testid": "message-composer-img",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "el-button",
                                      {
                                        staticClass: "attachment-preview-close",
                                        attrs: {
                                          size: "mini",
                                          type: "danger",
                                          circle: "",
                                          "data-testid":
                                            "message-composer-remove-attachment",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.removeAttachment(
                                              attachment
                                            )
                                          },
                                        },
                                      },
                                      [_c("i", { staticClass: "fa fa-times" })]
                                    ),
                                  ],
                                  1
                                )
                              : _c(
                                  "div",
                                  {
                                    staticClass:
                                      "attachment-preview d-flex flex-row align-items-center",
                                    staticStyle: {
                                      height: "5rem",
                                      padding: "1rem",
                                      "font-size": "1.2rem",
                                    },
                                  },
                                  [
                                    _vm._m(0, true),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticStyle: { height: "max-content" },
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(
                                            "\n                            " +
                                              _vm._s(attachment.original_file) +
                                              "\n                        "
                                          ),
                                        ]),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-button",
                                      {
                                        staticClass: "attachment-preview-close",
                                        attrs: {
                                          size: "mini",
                                          type: "danger",
                                          circle: "",
                                          "data-testid":
                                            "message-composer-remove-attachment-1",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.removeAttachment(
                                              attachment
                                            )
                                          },
                                        },
                                      },
                                      [_c("i", { staticClass: "fa fa-times" })]
                                    ),
                                  ],
                                  1
                                ),
                          ]
                        }
                      ),
                    ],
                    2
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "message-actions d-flex flex-wrap justify-content-end justify-sm-content-center align-items-center py-1",
                },
                [
                  _vm.contact_incoming_number &&
                  _vm.contact_incoming_number.is_mms_capable
                    ? _c(
                        "span",
                        { staticClass: "action-item px-2" },
                        [
                          _c(
                            "el-tooltip",
                            {
                              attrs: {
                                content: "Attach file",
                                placement: "top",
                              },
                            },
                            [
                              _c("attachments", {
                                attrs: {
                                  campaign_id: _vm.selected_campaign_id,
                                  disabled: _vm.disabled,
                                  "data-testid": "meesage-composer-attachments",
                                },
                                on: { "file-uploaded": _vm.fileUploaded },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.contact_incoming_number &&
                  _vm.contact_incoming_number.is_mms_capable
                    ? _c(
                        "span",
                        { staticClass: "action-item px-2" },
                        [
                          _c(
                            "el-tooltip",
                            {
                              attrs: {
                                content: "Add gif",
                                "data-testid":
                                  "message-composer-add-gif-tooltip",
                                placement: "top",
                              },
                            },
                            [
                              _c("gifs", {
                                attrs: {
                                  disabled: _vm.disabled,
                                  "data-testid": "message-composer-gifs",
                                },
                                on: { "gif-selected": _vm.gifSelected },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "span",
                    { staticClass: "action-item px-2" },
                    [
                      _c(
                        "el-tooltip",
                        {
                          attrs: {
                            content: "Add template",
                            "data-testid":
                              "message-composer-add-template-tooltip",
                            placement: "top",
                          },
                        },
                        [
                          _c("templates", {
                            attrs: { disabled: _vm.disabled },
                            on: { "template-selected": _vm.templateSelected },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "span",
                    { staticClass: "action-item px-2" },
                    [
                      _c(
                        "el-tooltip",
                        {
                          attrs: {
                            content: "Add variable",
                            "data-testid":
                              "message-composer-add-variable-tooltip",
                            placement: "top",
                          },
                        },
                        [
                          _c("variables", {
                            attrs: { disabled: _vm.disabled },
                            on: { "variable-selected": _vm.variableSelected },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.current_company &&
                  _vm.current_company.simpsocial_integration_enabled
                    ? _c(
                        "span",
                        { staticClass: "action-item px-2" },
                        [
                          _c(
                            "el-tooltip",
                            {
                              attrs: {
                                content: "Inventory",
                                "data-testid":
                                  "message-composer-inventory-tooltip",
                                placement: "top",
                              },
                            },
                            [
                              _c(
                                "el-button",
                                {
                                  staticClass: "border-0 bg-transparent",
                                  attrs: {
                                    id: "inventory-popover-trigger",
                                    size: "mini",
                                    disabled: _vm.disabled,
                                    circle: "",
                                    "data-testid":
                                      "message-composer-inventory-button",
                                  },
                                  on: { click: _vm.openCarForm },
                                },
                                [_c("i", { staticClass: "fa fa-car" })]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.current_company &&
                  _vm.current_company.simpsocial_integration_enabled
                    ? _c(
                        "span",
                        { staticClass: "action-item px-2" },
                        [
                          _c(
                            "el-tooltip",
                            {
                              attrs: {
                                content: "Credit Application",
                                "data-testid":
                                  "message-composer-credit-application-tooltip",
                                placement: "top",
                              },
                            },
                            [
                              _c(
                                "el-button",
                                {
                                  staticClass: "border-0 bg-transparent",
                                  attrs: {
                                    id: "credit-application-popover-trigger",
                                    size: "mini",
                                    disabled: _vm.disabled,
                                    circle: "",
                                    "data-testid":
                                      "message-composer-credit-application-button",
                                  },
                                  on: { click: _vm.sendCreditApplicationLink },
                                },
                                [_c("i", { staticClass: "fa fa-edit" })]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "ml-md-auto no-select pr-2 pr-xl-0" },
                    [
                      _c("strong", { staticClass: "mr-2" }, [_vm._v("Line:")]),
                      _vm._v(" "),
                      _c(
                        "el-select",
                        {
                          staticClass: "no-select",
                          staticStyle: { width: "200px" },
                          attrs: {
                            size: "small",
                            placeholder: "Select a line",
                            disabled: _vm.loading || _vm.disabled,
                            filterable: "",
                            "data-testid":
                              "message-composer-select-a-line-select",
                          },
                          on: { change: _vm.changeSelectedCampaign },
                          model: {
                            value: _vm.selected_campaign_id,
                            callback: function ($$v) {
                              _vm.selected_campaign_id = $$v
                            },
                            expression: "selected_campaign_id",
                          },
                        },
                        [
                          _vm.contactCampaigns.length !== 0
                            ? _c(
                                "el-option-group",
                                {
                                  key: "Contact Lines",
                                  attrs: { label: "Contact Lines" },
                                },
                                _vm._l(
                                  _vm.contactCampaigns,
                                  function (campaign, index) {
                                    return _c(
                                      "el-option",
                                      {
                                        key: campaign.id + "-comm-" + index,
                                        attrs: {
                                          label: campaign.name,
                                          value: campaign.id,
                                        },
                                      },
                                      [
                                        _c(
                                          "el-tooltip",
                                          {
                                            attrs: {
                                              disabled: !_vm.isMessagingBlocked(
                                                campaign,
                                                true
                                              ),
                                              content:
                                                "To send messages to the US, A2P 10DLC Brand and Campaign are required.",
                                              placement: "left",
                                            },
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass: "pull-left",
                                                class: {
                                                  "not-registered":
                                                    _vm.isMessagingBlocked(
                                                      campaign,
                                                      true
                                                    ),
                                                },
                                              },
                                              [_vm._v(_vm._s(campaign.name))]
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    )
                                  }
                                ),
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.otherContactCampaigns.length !== 0
                            ? _c(
                                "el-option-group",
                                {
                                  key: "Other Lines",
                                  attrs: { label: "Other Lines" },
                                },
                                _vm._l(
                                  _vm.otherContactCampaigns,
                                  function (campaign, index) {
                                    return _c(
                                      "el-option",
                                      {
                                        key: campaign.id + "-comm-" + index,
                                        attrs: {
                                          label: campaign.name,
                                          value: campaign.id,
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "pull-left",
                                            class: {
                                              "not-registered":
                                                _vm.isMessagingBlocked(
                                                  campaign
                                                ),
                                            },
                                          },
                                          [_vm._v(_vm._s(campaign.name))]
                                        ),
                                      ]
                                    )
                                  }
                                ),
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.sendEnabled &&
                  _vm.current_company.sms_enabled &&
                  _vm.canSendNonTCPAContacts &&
                  this.shouldAllowSmsTraffic(_vm.selectedCampaign)
                    ? [
                        _c(
                          "el-dropdown",
                          {
                            staticClass:
                              "schedule-message-action-buttons ml-xl-2 mr-2",
                            attrs: {
                              type: "success",
                              size: "small",
                              "split-button": "",
                              trigger: "click",
                              "data-testid": "message-composer-send-dropdown",
                            },
                            on: {
                              command: _vm.showScheduleDialog,
                              click: _vm.send,
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "dropdown",
                                  fn: function () {
                                    return [
                                      _c(
                                        "el-dropdown-menu",
                                        [
                                          _c(
                                            "el-dropdown-item",
                                            {
                                              attrs: {
                                                command: "schedule-message",
                                                "data-testid":
                                                  "schedule-dropdown-schedule-send",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                                Schedule Send\n                            "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              false,
                              1672442966
                            ),
                          },
                          [
                            _vm.mode === "schedule"
                              ? [
                                  _vm._v(
                                    "\n                        Save\n                    "
                                  ),
                                ]
                              : [
                                  _vm._v(
                                    "\n                        Send\n                    "
                                  ),
                                ],
                          ],
                          2
                        ),
                      ]
                    : !this.shouldAllowSmsTraffic(_vm.selectedCampaign)
                    ? [
                        _c(
                          "el-popover",
                          {
                            ref: "text-disabled",
                            attrs: {
                              placement: "top",
                              width: "300",
                              trigger: "hover",
                              "popper-class": "p-2 btn-primary",
                            },
                          },
                          [
                            _c("custom-message-display", {
                              attrs: { config: _vm.customMessage("text") },
                            }),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                attrs: { slot: "reference" },
                                slot: "reference",
                              },
                              [
                                _c(
                                  "el-button",
                                  {
                                    staticClass: "ml-xl-2 mr-2",
                                    attrs: {
                                      type: "success",
                                      size: "small",
                                      "data-testid":
                                        "message-composer-disable-text-number-send-button",
                                      disabled: "",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                            Send\n                        "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    : [
                        _c(
                          "el-popover",
                          {
                            ref: "text-invalid",
                            attrs: {
                              placement: "top",
                              width: "300",
                              trigger: "hover",
                              "popper-class": "p-2",
                              disabled:
                                !_vm.isNumberInvalidOrWrong(
                                  _vm.activePhoneNumber
                                ) &&
                                !_vm.isOptedOut(_vm.activePhoneNumber) &&
                                _vm.canSendNonTCPAContacts &&
                                !_vm.isMessagingBlocked,
                            },
                          },
                          [
                            _c(
                              "span",
                              { staticClass: "text-black-dk" },
                              [
                                !_vm.isNumberInvalidOrWrong(
                                  _vm.activePhoneNumber
                                )
                                  ? [
                                      _vm._v(
                                        "\n                            " +
                                          _vm._s(_vm.tooltipMessage) +
                                          "\n                        "
                                      ),
                                    ]
                                  : _vm._e(),
                              ],
                              2
                            ),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                attrs: { slot: "reference" },
                                slot: "reference",
                              },
                              [
                                _c(
                                  "el-button",
                                  {
                                    staticClass: "ml-xl-2 mr-2",
                                    attrs: {
                                      type: "success",
                                      size: "small",
                                      "data-testid":
                                        "message-composer-enable-text-number-send-button",
                                      disabled: "",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                            Send\n                        "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]
                        ),
                      ],
                ],
                2
              ),
            ]
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticStyle: { height: "max-content" } }, [
      _c("i", {
        staticClass: "fa fa-file-o mr-2",
        staticStyle: { "font-size": "1.5rem" },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }