export default {
    NODE_TYPE_IDS: {
        START_TYPE: 1,
        END_TYPE: 2,
        MESSAGE_TYPE: 3,
        GET_INPUT_TYPE: 4,
        STORE_INPUT_TYPE: 5,
        ESCALATION_TYPE: 6,
        CUSTOM_TYPE: 7,
        APPOINTMENT_TYPE: 8,
        CONDITION_TYPE: 10,
        DISENGAGEMENT_TYPE: 11,
    },
    NODE_TYPES: {
        START_NODE: 'start-node',
        END_NODE: 'end-node',
        MESSAGE_NODE: 'message-node',
        GET_INPUT_NODE: 'get-input-node',
        STORE_INPUT_NODE: 'store-input-node',
        ESCALATION_NODE: 'escalation-node',
        CUSTOM_NODE: 'custom-node',
        APPOINTMENT_NODE: 'appointment-node',
        CONDITION_NODE: 'condition-node',
        DISENGAGEMENT_NODE: 'disengagement-node',
    },
    PANEL_TYPES: {
        GENERAL_PANEL: 'general_panel',
        START_PANEL: 'start_panel',
        MESSAGE_PANEL: 'message_panel',
        GET_INPUT_PANEL: 'get_input_panel',
        STORE_INPUT_PANEL: 'store_input_panel',
        ESCALATION_PANEL: 'escalation_panel',
        CUSTOM_PANEL: 'custom_panel',
        APPOINTMENT_PANEL: 'appointment_panel',
        CONDITION_PANEL: 'condition_panel',
        DISENGAGEMENT_PANEL: 'disengagement_panel',
    },
    MODE: 'vue',
    BUILD_STATES: {
        BUILD_IN_PROGRESS: 3
    },
    APPOINTMENT_TYPES: {
        1: {
            label: 'Schedule'
        },
        2: {
            label: 'Reschedule'
        }
    },
    CONDITION_OPERATOR_TYPES: {
        1: {
            label: '='
        },
        2: {
            label: '!='
        },
        3: {
            label: ':'
        },
        4: {
            label: '>'
        },
        5: {
            label: '<'
        },
        6: {
            label: '>='
        },
        7: {
            label: '<='
        },
    }
}
