<template>
    <el-form :model="transcription_settings"
             class="form-aloware"
             ref="companySettingsForm">
        <div class="row pb-3"
            v-if="current_company &&
                current_company.transcription_settings &&
                current_company.transcription_settings?.call_transcription_enabled &&
                this.transcription_usage.is_ninety_percent_usage"
            >
            <el-alert show-icon
                      type="warning">
                 <span class="d-block mb-1 text-black">
                     You have used <b>{{ this.transcription_usage.used | numFormat }} / {{ this.transcription_usage.available | numFormat }}</b> of Smart Transcription minutes.
                    <span v-if="!transcription_settings.overusage_restriction_enabled">
                        Additional minutes will be billed at <b>{{ auth.user.profile.rate.transcription | fixRounding(2) | toCurrency }}</b> per minute.
                    </span>
                 </span>
            </el-alert>
        </div>
        <div class="row pb-3"
                v-if="current_company && current_company.transcription_settings?.call_transcription_enabled && current_company.hubspot_integration_enabled">
            <el-alert show-icon>
                 <span class="d-block mb-1">
                    <b>Attention:</b> HubSpot integration is enabled for this account. To avoid redundancy, consider disabling transcriptions in HubSpot.
                 </span>
            </el-alert>
        </div>

        <div class="row">
            <div class="col-md-7">
                <el-form-item class="pb-1"
                              prop="call_transcription_enabled">
                    <span id="call_transcription_enabled"
                          class="page-anchor"/>
                    <div class="form-label">
                        <h5>Call Transcription</h5>
                        <small>
                            This feature allows you to review your calls with Smart
                            Transcription
                        </small>
                    </div>
                    <div class="switch-label">
                        <el-switch @change="updateSetting"
                                   v-model="transcription_settings.call_transcription_enabled"
                                   active-color="#00a344"/>
                        <label>Enable call transcription</label>
                    </div>
                    <template v-if="transcription_settings.call_transcription_enabled">
                        <el-button type="success"
                               class="my-2"
                               @click="updateTranscriptionEnabledUsers(true)">
                            <span>Enable Transcription for all users</span>
                            <i v-if="loading_enable_transcription_for_users"
                            class="material-icons loader mr-2 pull-left">
                                &#xE863;
                            </i>
                        </el-button>
                        <el-button type="danger"
                                class="ml-md-2"
                                @click="updateTranscriptionEnabledUsers(false)">
                            <span>Disable Transcription for all users</span>
                            <i v-if="loading_disable_transcription_for_users"
                            class="material-icons loader mr-2 pull-left">
                                &#xE863;
                            </i>
                        </el-button>
                    </template>
                    <template v-if="transcription_settings.call_transcription_enabled">
                        <p>
                            <a class="text-dark-greenish el-green-hover mt-1"
                                v-if="!loading_users_list"
                                @click="displayUserTranscriptionManagementDialog()">
                                <strong>
                                    Show all Users Transcription Status
                                    <i class="fas fa-angle-right mr-1"></i>
                                </strong>
                            </a>
                            <i v-if="loading_users_list"
                                class="text-dark-greenish material-icons loader mr-2 pull-left mb-1">
                                &#xE863;
                            </i>
                        </p>
                    </template>
                </el-form-item>
                <el-form-item class="pb-1"
                              prop="summarization_enabled"
                              v-if="transcription_settings.call_transcription_enabled &&
                              current_company && current_company?.summarization_enabled">
                    <span id="summarization_enabled"
                          class="page-anchor"/>
                    <div class="form-label">
                        <h5>Summarization Settings</h5>
                        <small>
                            This feature allows you to summarize your calls using Alo Voice AI.
                        </small>
                    </div>
                    <div class="switch-label">
                        <el-switch @change="updateSetting"
                                   v-model="transcription_settings.summarization_enabled"
                                   active-color="#00a344"/>
                        <label>Enable call summarization</label>
                    </div>
                </el-form-item>
                <el-form-item class="no-border pb-2"
                              prop="summary_engine"
                              v-if="transcription_settings.summarization_enabled &&
                                    current_company && current_company?.summarization_enabled">
                    <span id="summary_engine"
                    class="page-anchor"/>
                    <div class="form-label">
                        <h5>AI Engine for Summarization</h5>
                        <small>Select the AI engine used to generate the summary of the call transcript.</small>
                    </div>
                    <el-select v-model="transcription_settings.summary_engine"
                            placeholder="Select AI Engine"
                            @change="handleInputConfirm">
                        <el-option :label="'AssemblyAI LeMUR'" :value="Summarizations.SUMMARY_ENGINE.ASSEMBLYAI_LEMUR"></el-option>
                        <el-option :label="'OpenAI GPT-4o'" :value="Summarizations.SUMMARY_ENGINE.OPENAI_GPT_4O"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item class="no-border pb-2"
                              prop="summary_length"
                              v-if="transcription_settings.summarization_enabled &&
                                    current_company && current_company?.summarization_enabled">
                    <span id="summary_length"
                    class="page-anchor"/>
                    <div class="form-label">
                        <h5>Call Summary Length</h5>
                        <small>Target length for the call summary. AI will do its best to honor this value, but it is not guaranteed.</small>
                    </div>
                    <el-input-number v-model="transcription_settings.summary_length"
                                    :min="50"
                                    :max="1000"
                                    :step="50"
                                    size="mini"
                                    @keydown.enter.prevent.native=""
                                    @change="handleInputConfirm"/>
                    <span class="ml-2">words</span>
                </el-form-item>
                <el-form-item class="no-border pb-2"
                              prop="summary_highlights"
                              v-if="transcription_settings.summarization_enabled &&
                                    current_company && current_company?.summarization_enabled">
                    <span id="summary_highlights"
                    class="page-anchor"/>
                    <div class="form-label">
                        <h5>Call Summary Highlights</h5>
                        <small>Include key details in the call summary as well as specify custom highlights.</small>
                    </div>
                    <div class="summary_custom_prompt_enabled">
                        <el-switch @change="updateSetting"
                                   v-model="transcription_settings.summary_custom_prompt_enabled"
                                   active-color="#00a344"/>
                        <label>Enable custom prompts (not recommended).</label>
                    </div>
                    <div class="summary_prompt_list"
                         v-if="!transcription_settings.summary_custom_prompt_enabled">
                        <el-select
                            v-model="transcription_settings.summary_prompt_list"
                            placeholder="Select highlights"
                            class="w-full"
                            filterable
                            multiple
                            @change="handleInputConfirm"
                        >
                            <el-option
                                v-for="summary_highlight_option in summary_highlight_options"
                                :key="summary_highlight_option.id"
                                :label="summary_highlight_option.label"
                                :value="summary_highlight_option.id"
                            >
                            </el-option>
                        </el-select>
                    </div>
                    <div class="summary_custom_prompt"
                         v-if="transcription_settings.summary_custom_prompt_enabled">
                        <el-input
                            type="textarea"
                            maxlength="1000"
                            show-word-limit
                            v-model="transcription_settings.summary_custom_prompt"
                            v-if="transcription_settings.summary_custom_prompt_enabled"
                            @blur="handleInputConfirm">
                        </el-input>
                    </div>
                </el-form-item>
                <el-form-item class="pb-1"
                              prop="overusage_restriction_enabled">
                    <span id="overusage_restriction_enabled"
                          class="page-anchor"/>
                    <div class="form-label">
                        <h5>Overusage Billing Restriction</h5>
                        <small>
                            Automatically pause the call transcription feature once your account exceeds the allotted minutes of Smart Transcription included in your plan. This ensures you won't incur additional charges of $0.03 per minute for extra transcription minutes.
                        </small>
                    </div>
                    <div class="switch-label">
                        <el-switch @change="updateSetting"
                                   v-model="transcription_settings.overusage_restriction_enabled"
                                   active-color="#00a344"/>
                        <label>
                            Turn off call transcriptions automatically when monthly plan limit is reached.
                        </label>
                    </div>
                </el-form-item>

                <el-form-item prop="minimum_talk_time"
                              class="pb-1">
                    <span id="minimum_talk_time"
                          class="page-anchor"/>
                    <div class="form-label">
                        <h5>Minimum Talk-Time</h5>
                        <small>
                            Calls will only be transcribed when talk time is greater than this limit.
                        </small>
                    </div>
                    <el-input-number v-model="transcription_settings.minimum_talk_time"
                                     :min="0"
                                     :max="max_talk_time"
                                     :step="15"
                                     size="mini"
                                     @keydown.enter.prevent.native=""
                                     @change="handleInputConfirm"/>
                    <span class="ml-2">Seconds</span>
                    <div class="text-sm">
                        Current limit is 5 minutes. Contact support if you need to increase this limit.
                    </div>
                </el-form-item>

                <el-form-item v-if="company_clone.transcription_enabled"
                              prop="min_max_communication_duration"
                              class="pb-1">
                    <span id="minimum_talk_time"
                          class="page-anchor"/>
                    <div class="form-label">
                        <h5>Sync transcriptions with CRM</h5>
                        <small>
                            Transcriptions will only be synced when talk time is between this limit.
                        </small>
                    </div>
                    <el-input-number v-model="transcription_settings.transcription_sync_start_seconds"
                                     :min="0"
                                     :max="transcription_settings.transcription_sync_end_seconds"
                                     :step="15"
                                     size="mini"
                                     @keydown.enter.prevent.native=""
                                     @change="handleInputConfirm"/>
                    <span class="ml-2">Seconds</span>
                    <span class="ml-2">AND</span>
                    <span class="ml-2"></span>
                    <el-input-number v-model="transcription_settings.transcription_sync_end_seconds"
                                     :min="transcription_settings.transcription_sync_start_seconds"
                                     :step="15"
                                     size="mini"
                                     @keydown.enter.prevent.native=""
                                     @change="handleInputConfirm"/>
                    <span class="ml-2">Seconds</span>
                </el-form-item>

                <!-- <el-form-item
                    prop="voicemail_transcription_enabled"
                    class="pb-1">
                    <span
                        class="page-anchor"
                        id="voicemail_transcription_enabled">
                    </span>
                    <div class="form-label">
                        <h5>Voicemail Transcription</h5>
                        <small>
                            This feature allows you to review your voicemail with Smart Transcription
                        </small>
                    </div>
                    <div class="switch-label">
                        <el-switch
                            @change="updateParent"
                            v-model="transcription_settings.voicemail_transcription_enabled"
                            active-color="#00a344">
                        </el-switch>
                        <label>Enable voicemail transcription</label>
                    </div>
                </el-form-item> -->

                <el-form-item prop="custom_keywords"
                              class="pb-1">
                    <span id="custom_keywords"
                          class="page-anchor"/>
                    <div class="form-label">
                        <h5>Custom Keywords</h5>
                        <small>
                            Custom Keywords will be counted in Smart Transcription Panel.
                        </small>
                    </div>
                    <el-tag class="m-1"
                            :key="custom_keyword"
                            v-for="custom_keyword in transcription_settings.custom_keywords"
                            closable
                            type="warning"
                            style="font-size:15px!important"
                            :disable-transitions="false"
                            @close="handleKeywordsClose(custom_keyword)">
                        <b>{{ custom_keyword }}</b>
                    </el-tag>
                    <el-input v-if="keyword_input_visible"
                              v-model="custom_keyword_value"
                              ref="saveKeywordInput"
                              size="medium"
                              style="width: 120px"
                              @keydown.enter.prevent.native="handleInputConfirm"
                              @blur="handleInputConfirm"/>
                    <el-button v-else size="small"
                               @click="showKeywordInput">
                        + New Keyword
                    </el-button>
                </el-form-item>

                <el-form-item prop="custom_dictionary"
                              class="pb-1">
                    <span id="custom_dictionary"
                          class="page-anchor"/>
                    <div class="form-label">
                        <h5>Custom Dictionary</h5>
                        <small>
                            Custom Dictionary words will be boosted to match them in the
                            transcription.
                        </small>
                    </div>
                    <el-tag class="m-1"
                            style="font-size: 15px"
                            :key="dictionary_word"
                            v-for="dictionary_word in transcription_settings.custom_dictionary"
                            closable
                            :disable-transitions="false"
                            @close="handleDictionaryClose(dictionary_word)">
                        <b>{{ dictionary_word }}</b>
                    </el-tag>
                    <el-input v-if="dictionary_input_visible"
                              v-model="dictionary_word_value"
                              ref="saveDictionaryWordInput"
                              size="medium"
                              style="width: 120px"
                              @keydown.enter.prevent.native="handleInputConfirm"
                              @blur="handleInputConfirm"/>
                    <el-button v-else
                               size="small"
                               @click="showDictionaryInput">
                        + New Word
                    </el-button>
                </el-form-item>

                <el-form-item prop="automatic_redaction_enabled"
                              class="pb-1">
                    <span id="automatic_redaction_enabled"
                          class="page-anchor"/>
                    <div class="form-label">
                        <h5>Automatic Redaction</h5>
                        <small>
                            Sensitive data in conversations can be redacted by enabling this
                            feature.
                        </small>
                    </div>
                    <div class="switch-label">
                        <el-switch @change="updateSetting"
                                   v-model="transcription_settings.automatic_redaction_enabled"
                                   active-color="#00a344"/>
                        <label>Enable automatic redaction</label>
                    </div>
                </el-form-item>

                <el-form-item prop="content_moderation_enabled"
                              class="pb-1">
                    <span id="content_moderation_enabled"
                          class="page-anchor"/>
                    <div class="form-label">
                        <h5>Sensitive Content Moderation</h5>
                        <small>
                            By enabling this Feature, any conversations regarding sensitive
                            issues like drugs, racism and other inappropiate subjects will be
                            flagged automatically.
                        </small>
                    </div>
                    <div class="switch-label">
                        <el-switch @change="updateSetting"
                                   v-model="transcription_settings.content_moderation_enabled"
                                   active-color="#00a344"/>
                        <label>Enable sensitive content moderation</label>
                    </div>
                </el-form-item>
            </div>

            <div class="col-md-2 d-none d-md-block ml-auto">
                <div class="list-group list-group-alo list-group-flush sticky-top stick-top-with-header top-70">
                    <h6 class="list-group-item">On This Page</h6>
                    <a href="#call_transcription_enabled"
                       class="list-group-item list-group-item-action"
                       @click="shine('call_transcription_enabled')">
                        Call Transcription
                    </a>
                    <a href="#summarization_enabled"
                       class="list-group-item list-group-item-action"
                       v-if="current_company && current_company?.summarization_enabled && transcription_settings.call_transcription_enabled"
                       @click="shine('summarization_enabled')">
                        Summarization Settings
                    </a>
                    <a href="#summary_engine"
                       class="list-group-item list-group-item-action"
                       v-if="transcription_settings.summarization_enabled &&
                       current_company && current_company?.summarization_enabled"
                       @click="shine('summary_engine')">
                        AI Engine for Summarization
                    </a>
                    <a href="#summary_length"
                       class="list-group-item list-group-item-action"
                       v-if="transcription_settings.summarization_enabled &&
                       current_company && current_company?.summarization_enabled"
                       @click="shine('summary_length')">
                        Call Summary Length
                    </a>
                    <a href="#summary_highlights"
                       class="list-group-item list-group-item-action"
                       v-if="transcription_settings.summarization_enabled &&
                       current_company && current_company?.summarization_enabled"
                       @click="shine('summary_highlights')">
                        Call Summary Highlights
                    </a>
                    <a href="#overusage_restriction_enabled"
                       class="list-group-item list-group-item-action"
                       @click="shine('overusage_restriction_enabled')">
                       Overusage Billing Restriction
                    </a>
                    <a href="#minimum_talk_time"
                       class="list-group-item list-group-item-action"
                       @click="shine('minimum_talk_time')">
                        Minimum Talk-Time
                    </a>
                    <a href="#min_max_communication_duration"
                       class="list-group-item list-group-item-action"
                       @click="shine('min_max_communication_duration')">
                        Sync transcriptions with Zoho CRM
                    </a>
                    <!-- <a href="#voicemail_transcription"
                       class="list-group-item list-group-item-action"
                       @click="shine('voicemail_transcription')">
                        Voicemail Transcription
                    </a> -->
                    <a href="#custom_keywords"
                       class="list-group-item list-group-item-action"
                       @click="shine('custom_keywords')">
                        Custom Keywords
                    </a>
                    <a href="#custom_dictionary"
                       class="list-group-item list-group-item-action"
                       @click="shine('custom_dictionary')">
                        Custom Dictionary
                    </a>
                    <a href="#automatic_redaction_enabled"
                       class="list-group-item list-group-item-action"
                       @click="shine('automatic_redaction_enabled')">
                        Automatic Redaction
                    </a>
                    <a href="#content_moderation_enabled"
                       class="list-group-item list-group-item-action"
                       @click="shine('content_moderation_enabled')">
                        Sensitive Content Moderation
                    </a>
                </div>
            </div>
            <el-dialog
                    :title="`Users Transcription Status`"
                    :visible.sync="users_enablement.display_dialog"
                    @close="closeUserTranscriptionManagementDialog"
                    width="40%">
                <account-transcription-user-enablement />
            </el-dialog>
        </div>
    </el-form>
</template>

<script>

import AccountTranscriptionUserEnablement from './account-transcription-user-enablement'
import debounce from 'lodash/debounce'
import {mapState} from 'vuex'
import {form_handler} from '../../mixins'
import auth from '../../auth'
import * as Summarizations from '../../constants/summarizations'

export default {
    name: 'account-transcription-settings',

    components: {
        AccountTranscriptionUserEnablement,
    },

    mixins: [form_handler],

    props: {
        company_clone: {
            type: Object,
            required: true,
        },
    },

    data() {
        return {
            auth,
            users_enablement: {
                display_dialog: false,
            },
            transcription_usage: {
                available: 0,
                used: 0,
                is_ninety_percent_usage: false,
            },
            transcription_settings: {},
            keyword_input_visible: false,
            custom_keyword_value: '',
            dictionary_input_visible: false,
            dictionary_word_value: '',
            summary_highlight_options: [],
            loading_enable_transcription_for_users: false,
            loading_disable_transcription_for_users: false,
            loading_users_list: false,
            max_talk_time: parseInt(
                localStorage.getItem('transcriber_max_talk_time')
            ),
            Summarizations,
        }
    },

    computed: {
        ...mapState('cache', ['current_company']),

        ...mapState(['users']),
    },

    created() {
        // sets the needed property of the component
        if (this.company_clone && this.company_clone.id) {
            this.initializeProperties()
        }

        // Fetch summary highlight options from API
        this.fetchSummaryHighlightOptions();

        // If Transcription Settings are accessed via URL
        if (this.$route.query.tab === 'transcription-settings' && !this.company_clone.transcription_enabled) {
            // Redirect the user to Account Settings when Smart Transcription is not enabled.
            this.$router.push({
                name: 'Account',
                query: {
                    tab: 'settings'
                }
            })
        }
    },

    methods: {
        // initialize the company props that are being used in this component
        initializeProperties() {
            this.transcription_settings = {
                call_transcription_enabled: this.company_clone?.transcription_settings?.call_transcription_enabled ?? false,
                overusage_restriction_enabled: this.company_clone?.transcription_settings?.overusage_restriction_enabled ?? false,
                minimum_talk_time: this.company_clone?.transcription_settings?.minimum_talk_time ?? 0,
                voicemail_transcription_enabled: this.company_clone?.transcription_settings?.voicemail_transcription_enabled ?? false,
                custom_keywords: this.company_clone?.transcription_settings?.custom_keywords ?? [],
                custom_dictionary: this.company_clone?.transcription_settings?.custom_dictionary ?? [],
                automatic_redaction_enabled: this.company_clone?.transcription_settings?.automatic_redaction_enabled ?? false,
                content_moderation_enabled: this.company_clone?.transcription_settings?.content_moderation_enabled ?? false,
                transcription_sync_start_seconds: this.company_clone?.transcription_settings?.transcription_sync_start_seconds ?? 0,
                transcription_sync_end_seconds: this.company_clone?.transcription_settings?.transcription_sync_end_seconds ?? 0,
                summary_engine: this.company_clone?.transcription_settings?.summary_engine ?? Summarizations.SUMMARY_ENGINE.ASSEMBLYAI_LEMUR,
                summary_length: this.company_clone?.transcription_settings?.summary_length ?? 300,
                summary_custom_prompt: this.company_clone?.transcription_settings?.summary_custom_prompt ?? "Include any rebuttals the sales agent used to convince the customer not to cancel their account. Highlight how effective the rebuttals were in addressing the customer's concerns and preventing account cancellation.",
                summary_custom_prompt_enabled: this.company_clone?.transcription_settings?.summary_custom_prompt_enabled ?? false,
                summarization_enabled: this.company_clone?.transcription_settings?.summarization_enabled ?? false,
                summary_prompt_list: this.company_clone?.transcription_settings?.summary_prompt_list ?? [],
            }

            const ninety_perc_usage = this.company_clone?.plan?.included_transcription_min * 0.9

            this.transcription_usage = {
                available: this.company_clone?.plan?.included_transcription_min ?? 0,
                used: this.company_clone?.used_transcription_min ?? 0,
                is_ninety_percent_usage: this.company_clone?.used_transcription_min >= ninety_perc_usage,
            }
        },

        // fetches the summary highlight options
        fetchSummaryHighlightOptions() {
            axios.get('/api/v1/summarization-prompt-options')
            .then((response) => {
                this.summary_highlight_options = response.data.map(option => ({
                    id: option.id,
                    label: option.label,
                })),
                // Set "Call Summary" to the beginning of the list if not included and can't be removed
                this.setCallSummaryInPromptList();
            })
            .catch((err) => {
                this.$root.handleErrors(err.response)
            });
        },

        //update transcription settings when a setting has changed
        updateSetting: debounce(function () {
            // get company id from parent component
            const company_id = this.company_clone.id

            axios.post(`/api/v1/company/${company_id}/transcription-setting`, this.transcription_settings).then((res) => {
                this.$notify({
                    offset: 95,
                    title: 'Account',
                    message: 'Transcription setting has been updated successfully.',
                    type: 'success',
                    showClose: true,
                })
            }).catch((err) => {
                this.$root.handleErrors(err.response)
            })
        }, 500),

        // remove dictionary word handler.
        handleDictionaryClose(tag) {
            this.transcription_settings.custom_dictionary.splice(
                this.transcription_settings.custom_dictionary.indexOf(tag),
                1
            )
            this.updateSetting()
        },

        // remove custom keyword handler.
        handleKeywordsClose(tag) {
            this.transcription_settings.custom_keywords.splice(
                this.transcription_settings.custom_keywords.indexOf(tag),
                1
            )
            this.updateSetting()
        },

        // show dictionary input handler.
        showDictionaryInput() {
            this.dictionary_input_visible = true
            this.$nextTick((_) => {
                this.$refs.saveDictionaryWordInput.$refs.input.focus()
            })
        },

        // show custom keywords input handler.
        showKeywordInput() {
            this.keyword_input_visible = true
            this.$nextTick((_) => {
                this.$refs.saveKeywordInput.$refs.input.focus()
            })
        },

        // set "Call Summary" (id = 1) to the beginning of the list if not included and can't be removed
        setCallSummaryInPromptList() {
            if (this.transcription_settings.summarization_enabled && !this.transcription_settings.summary_prompt_list.includes(1)) {
                // Add "Call Summary" to the beginning of the list
                this.transcription_settings.summary_prompt_list.unshift(1);
                this.updateSetting();
            }
        },

        // handles any input confirmation from the panel.
        handleInputConfirm() {
            // check if last added element already exist in custom dictionary.
            let dictionary_word_value = this.dictionary_word_value.trim()
            if (dictionary_word_value && !this.transcription_settings.custom_dictionary.includes(dictionary_word_value)) {
                // Before updating, validate that the word does not contain any sort of digits.
                if (/\d/.test(dictionary_word_value)) {
                    this.$notify({
                        offset: 95,
                        title: 'Account',
                        message: 'Custom Dictionary words cannot contain any digits.',
                        type: 'error',
                    })
                    return
                }
                this.transcription_settings.custom_dictionary.push(dictionary_word_value)
                this.updateSetting()
            }

            // check if last added element already exist in custom keywords.
            let custom_keyword_value = this.custom_keyword_value.trim()
            const ucfirst_keyword = this.$options.filters.ucfirst(custom_keyword_value)
            if (ucfirst_keyword && !this.transcription_settings.custom_keywords.includes(ucfirst_keyword)) {
                this.transcription_settings.custom_keywords.push(ucfirst_keyword)
                this.updateSetting()
            }

            // check if minimum talk_time has changed.
            if (this.transcription_settings.minimum_talk_time != this.company_clone?.transcription_settings?.minimum_talk_time) {
                this.updateSetting()
            }

            if (this.transcription_settings.transcription_sync_start_seconds !== this.company_clone?.transcription_settings?.transcription_sync_start_seconds) {
                this.updateSetting()
            }

            if (this.transcription_settings.transcription_sync_end_seconds !== this.company_clone?.transcription_settings?.transcription_sync_end_seconds) {
                this.updateSetting()
            }

            if (this.transcription_settings.summary_length !== this.company_clone?.transcription_settings?.summary_length) {
                this.updateSetting()
            }

            if (this.transcription_settings.summary_engine !== this.company_clone?.transcription_settings?.summary_engine) {
                this.updateSetting()
            }

            if (!_.isEqual(this.transcription_settings.summary_prompt_list, this.company_clone?.transcription_settings?.summary_prompt_list)) {
                this.updateSetting();
            }
            
            // set "Call Summary" to the beginning of the list if not included and can't be removed
            this.setCallSummaryInPromptList();

            if (this.transcription_settings.summmary_custom_prompt !== this.company_clone?.transcription_settings?.summary_custom_prompt) {
                this.updateSetting()
            }

            // reset components
            this.dictionary_input_visible = false
            this.keyword_input_visible = false
            this.dictionary_word_value = ''
            this.custom_keyword_value = ''
        },

        // enable transcription for all company users
        updateTranscriptionEnabledUsers(enabled) {
            const company_id = this.company_clone.id

            // loading state in one button at a time
            if (enabled) {
                this.loading_enable_transcription_for_users = true
            } else {
                this.loading_disable_transcription_for_users = true
            }

            const option = enabled ? 'enabled' : 'disabled'

            axios.post(`/api/v1/company/${company_id}/update-users-transcription`, {
                enabled,
            }).then((res) => {
                this.$notify({
                    offset: 95,
                    title: 'Account',
                    message: `Smart transcription is being ${option} for all users.`,
                    type: 'success',
                })
                this.loading_enable_transcription_for_users = false
                this.loading_disable_transcription_for_users = false
            }).catch((err) => {
                this.$root.handleErrors(err.response)
                this.loading_enable_transcription_for_users = false
                this.loading_disable_transcription_for_users = false
            })
        },

        displayUserTranscriptionManagementDialog() {
            this.loading_users_list = true
            // reload the users list before opening the modal
            this.$root.getUsers().then(() => {
                this.users_enablement.display_dialog = true
                this.loading_users_list = false
            })
        },

        closeUserTranscriptionManagementDialog() {
            this.users_enablement.display_dialog = false
        },
    },

    watch: {
        'transcription_settings.call_transcription_enabled'(value) {
            if (!value && this.transcription_settings.summarization_enabled) {
                // Disable summarization if transcription is disabled
                this.transcription_settings.summarization_enabled = false;
                this.updateSetting();
            }
        },
        $route({params, query, hash}) {
            // If somehow a user manage to click the Transcription Settings tab even when Smart Transcription is disabled.
            if (query.tab === 'transcription-settings' && !this.company_clone.transcription_enabled) {
                this.goBack()
            }
        }
    }
}
</script>
