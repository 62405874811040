<template>
    <el-popover
        ref="popover_notes"
        title="Note:"
        :placement="small ? 'top' : 'left'"
        :width="small ? 265 : 400"
        trigger="click"
        @show="showNote">

        <slot name="trigger"
              slot="reference">
            <el-button
                v-if="!template.note"
                type="text text-greyish opacity-4 hover-opacity text-md m-0 p-0">
                <i class="el-icon-document"></i>
            </el-button>

            <el-button
                v-else
                type="text text-dark-greenish hover-text-blackish text-md m-0 p-0">
                <i class="el-icon-document"></i>
            </el-button>
        </slot>

        <div class="row">
            <div class="col-12">
                <el-input
                    type="textarea"
                    placeholder="Write your call notes here..."
                    ref="chatbot_notes"
                    v-model="note"
                    :autosize="{ minRows: 3, maxRows: 5}"
                    autofocus>
                </el-input>

                <div class="mt-1 text-right h-24">
                    <span
                        class="text-muted"
                        v-show="loading_btn">
                        <i class="material-icons loader text-dark-greenish">&#xE863;</i>
                        saving
                    </span>
                    <span
                        class="text-muted"
                        v-show="!loading_btn && loading">
                        saved
                    </span>
                    <span class="text-deep-orange _600"
                          v-if="hasUnsavedChanges && !loading_btn">
                        unsaved changes
                    </span>
                </div>
            </div>
        </div>

        <div class="row mt-1">
            <div class="col-12">
                <el-button
                    class="pull-right"
                    type="success"
                    size="small"
                    :loading="loading_btn"
                    :disabled="loading_btn"
                    @click="saveNote">
                    Save
                </el-button>
            </div>
        </div>
    </el-popover>
</template>

<script>
    export default {
        props: {
            template: {
                required: true
            },
            small: {
                default: false,
                type: Boolean,
                required: false
            }
        },
        data() {
            return {
                loading: false,
                loading_btn: false,
                note: null,
            }
        },
        mounted() {
            this.note = this.template.note
        },
        computed: {
            /**
             * Checks if there are any unsaved changes in the current state of the component.
             * In this case, it checks whether the note has been modified compared to the
             * note in the template. If there's a difference, it returns true, indicating
             * that there are unsaved changes; otherwise, it returns false.
             *
             * @return {boolean} - True if there are unsaved changes, false otherwise.
             */
            hasUnsavedChanges() {
                if (this.template && this.note != this.template.note) {
                    return true
                }

                return false
            }
        },
        methods: {
            /**
             * Shows template note.
             *
             * @return {void}
             */
            showNote() {
                this.note = this.template.note
            },

            changeNote: _.debounce(function () {
                this.saveNote()
            }, 2000),

            /**
             * Save template note.
             *
             * @return {void}
             */
            saveNote() {
                this.loading_btn = true
                this.loading = true

                axios.post(`/api/v1/bots/builder/template/${this.template.id}`, {
                    note: this.note,
                }).then(res => {
                    this.template.note = this.note
                    this.loading_btn = false
                    this.loading = false
                    this.$notify.success({
                        offset: 95,
                        title: 'Chatbots',
                        message: 'Chatbot Template updated successfully.',
                    })
                    this.$emit('note-update')
                }).catch(err => {
                    this.$root.handleErrors(err.response)
                    this.loading = false
                    this.loading_btn = false
                })
            }
        },
        watch: {
            'template.note': function() {
                this.showNote()
            }
        }
    }
</script>