<template>
    <!-- NOTE: To be refactored after deployment -->
    <div>
    </div>
</template>

<script>
const START_NODE = 'start-node'
const END_NODE = 'end-node'
const MESSAGE_NODE = 'message-node'
const GET_INPUT_NODE = 'get-input-node'
const STORE_INPUT_NODE = 'store-input-node'
const ESCALATION_NODE = 'escalation-node'
const CUSTOM_NODE = 'custom-node'
export default {
    name: 'start-panel',
    props: { 
        transition: {
            default: null,
            required: false
        }
    },
    data() {
        return {
            START_NODE: START_NODE,
            MESSAGE_NODE: MESSAGE_NODE,
            GET_INPUT_NODE: GET_INPUT_NODE,
            STORE_INPUT_NODE: STORE_INPUT_NODE,
            ESCALATION_NODE: ESCALATION_NODE,
            CUSTOM_NODE: CUSTOM_NODE,
            options: [
                {
                    label: 'States',
                    options: [
                        {
                            value: MESSAGE_NODE,
                            label: 'Message'
                        },
                        {
                            value: 'Get Input',
                            label: 'Get Input'
                        },
                        {
                            value: 'Store Input',
                            label: 'Store Input'
                        },
                        {
                            value: 'Escalation',
                            label: 'Escalation'
                        },
                        {
                            value: 'Custom',
                            label: 'Custom'
                        },
                    ]
                }
            ],
            // We receive the transition as a props and make it a local state.
            local_transition: this.transition
        }
    },
    methods: {
        changeTransition() {
            // Emits the event waited by the parent component and returns the new value.
            return this.$emit('transition-changed', this.local_transition)
        }
    },
    computed: {
        // To mutate a prop value we must use a computed property like this:
        selected_transition: {
            get() {
                return this.local_transition
            },
            set(value) {
                this.local_transition = value
            }
        }
    }
}
</script>
