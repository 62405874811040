<template>
    <div class="chatbot-intents-selector">
        <div class="col-12">
            <div class="intent-list">
                <!-- Selected intents -->
                <div
                    v-for="intent in node_intents"
                    :key="intent.name"
                    class="mb-1 row px-2 py-2">
                    <span
                        class="mx-1"
                        :closable="node_intents.length > 1"
                        :disable-transitions="false">
                        {{ intent.name }}
                    </span>

                    <!-- Close icon -->
                    <span
                        class="ml-auto material-icons pt-1"
                        @click="handleIntentClose(intent)">
                        close
                    </span>
                </div>
            </div>
        </div>

        <div class="col-12 px-0">
            <!-- Remaining selectable intents -->
            <el-select
                v-model="selected_intent" 
                filterable 
                placeholder="Select a new intent" 
                clearable
                class="inline-input w-100 mt-2"
                @change="handleInputConfirm">

                <el-option
                    v-for="intent in selectableIntents"
                    :key="intent.id"
                    :label="intent.name"
                    :value="intent"/>

            </el-select>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        chatbot_intents: {
            required: false,
            default: () => []
        },
        node_intents: {
            required: false,
            default: () => []
        }
    },
    data () {
        return {
            selected_intent: null
        }
    },
    methods: {
        /**
         * Listens when new intent is added to current node.
         *
         * @return {void}
         */
        handleInputConfirm(intent) {
            // Adds the intent to the current stack.
            this.node_intents.push({ id: intent.id, name: intent.name })
            // Resets the selected intent.
            this.selected_intent = null
            // Add it to node data.
            this.$emit('intent-changed', { intents: this.node_intents })
        },

        /**
         * Listens when an intent is removed from the current node.
         *
         * @return {void}
         */
        handleIntentClose(intent){
            // Removes the intent from the current stack.
            this.node_intents = this.node_intents.filter(_intent => _intent.id != intent.id)
            // Remove it it to node data.
            this.$emit('intent-changed', { intents: this.node_intents })
        }
    },
    computed: {
        /**
         * Removes the selected intents from the 
         *
         * @return {object[]}
         */
        selectableIntents() {
            let selectedIds = this.node_intents.map(intent => intent.id)

            return this.chatbot_intents.filter(intent => {
                return !selectedIds.includes(intent.id)
            })
        }
    }
}
</script>