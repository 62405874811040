<template>
    <el-dialog
        class="dialog-blank"
        width="35%"
        append-to-body
        :show-close="false"
        :before-close="handleClose"
        :visible.sync="form_visible">

        <div class="container-listing rounded">
            <div class="container-header d-flex">
                <h5 class="listing-heading">Create Chatbot from Template</h5>
                <div class="ml-auto">
                    <el-button type="text"
                               class="btn-dialog-close"
                               size="large"
                               @click="handleClose">
                        <i class="fa fa-remove"></i>
                    </el-button>
                </div>
            </div>
            <div class="container-body">
                <el-form>
                    <el-form-item>
                        <div class="form-label">
                            <h5 class="text-dark">Template (*)</h5>
                            <small>
                                Select a template for this chatbot.
                            </small>
                        </div>
                        <el-select
                            class="w-100"
                            v-model="template.id"
                            filterable
                            placeholder="Template">
                            <el-option v-for="template in templates"
                                :key="template.id"
                                :label="`(${template.version}) ${template.workflow}`"
                                :value="template.id">
                                <span class="font-weight-bold">({{ template.version }}) </span>
                                <span>{{ template.workflow }}</span>
                            </el-option>
                        </el-select>
                    </el-form-item>

                    <el-form-item>
                        <div class="form-label">
                            <h5 class="text-dark">Name (*)</h5>
                            <small>
                                Specify a name for this chatbot.
                            </small>
                        </div>
                        <el-input v-model="chatbot.name"/>
                    </el-form-item>

                    <el-form-item>
                        <div class="form-label">
                            <h5 class="text-dark">Lines (*)</h5>
                            <small>
                                More than 1 line can be attached to this chatbot.
                            </small>
                        </div>
                        <el-select
                            class="w-100"
                            v-model="chatbot.campaign_ids"
                            multiple
                            filterable
                            placeholder="Select lines">

                            <el-option
                                v-for="line in lines"
                                :key="line.id"
                                :label="line.name"
                                :value="line.id"
                            />

                        </el-select>
                    </el-form-item>

                    <el-form-item>
                        <div class="form-label">
                            <h5 class="text-dark">Notes</h5>
                            <small>
                                Write any notes about this chatbot.
                            </small>
                        </div>
                        <el-input
                            type="textarea"
                            :rows="2"
                            v-model="chatbot.notes"/>
                    </el-form-item>

                    <el-form-item>
                        <div class="row">
                            <div class="col-12">
                                <div class="d-flex">
                                    <el-button
                                        class="ml-auto mb-3"
                                        type="success"
                                        @click="createBotFromTemplate()"
                                        :disabled="loading">
                                        <i v-if="loading" class="fa fa-spinner fa-spin"/>
                                        <strong v-if="loading"> Creating...</strong>
                                        <strong v-else> Create</strong>
                                    </el-button>
                                </div>
                            </div>
                        </div>
                    </el-form-item>
                </el-form>
            </div>
        </div>
    </el-dialog>
</template>

<script>
import _ from 'lodash'
export default {
    name: 'import-chatbot-form',
    props: {
        form_visible: {
            required: true,
            default: false
        },
        templates: {
            required: false,
            default: []
        },
        lines: {
            required: false,
            default: []
        }
    },
    data() {
        return {
            template: {
                id: null,
                workflow: null
            },
            chatbot: {
                name: null,
                campaign_ids: [],
                notes: null,
            },
            loading: false
        }
    },
    methods: {
        /**
         * Create a new bot from the selected template and the data filled.
         *
         * @return {void}
         */
        createBotFromTemplate() {
            // Sanity check - Check if a template ID is selected, and notify if not
            if (!this.template.id) {
                this.$notify.info({
                    offset: 95,
                    title: 'Chatbots',
                    message: 'You must select a template.'
                })

                return
            }

            // Get the template data using the selected template ID
            this.template = this.templates.filter(template => template.id == this.template.id)[0]

            // Sanity check - Validate that the required fields are filled before proceeding
            if (
                !this.template.id ||
                !this.template.workflow ||
                _.isEmpty(this.chatbot.campaign_ids) ||
                !this.chatbot.name
            ) {
                this.$notify.info({
                    offset: 95,
                    title: 'Chatbots',
                    message: 'You must fill the required (*) fields.'
                })

                return
            }

            // Start the loading animation
            this.startLoading()

            // Prepare the request data to create the chatbot using the selected template
            let req = {
                template_id: this.template.id,
                template_name: this.template.workflow,
                name: this.chatbot.name,
                notes: this.chatbot.notes,
                campaign_ids: this.chatbot.campaign_ids
            }

            axios.post(`/api/v1/bots/builder/template/import`, req).then(res => {
                this.$emit('bot-imported')
                this.stopLoading()
                this.$notify.success({
                    offset: 95,
                    title: 'Chatbots',
                    message: 'Your chatbot was imported successfully.'
                })
            }).catch(err => {
                this.stopLoading()
                this.$notify.error({
                    offset: 95,
                    title: 'Chatbots',
                    message: 'Something went wrong while importing your Chatbot.'
                })
                console.log(err)
            })
        },

        /**
         * Set all the components to loading state
         *
         * @return {void}
         */
        startLoading() {
            this.loading = true
        },

        /**
         * Stop all components loading state
         *
         * @return {void}
         */
        stopLoading() {
            this.loading = false
        },

        /**
         * Resets all the field used to import a chatbot.
         *
         * @return {void}
         */
        resetForm() {
            this.template = {
                id: null,
                workflow: null
            }
            this.template_id = null
            this.chatbot = {
                workflow: null,
                name: null,
                campaign_ids: null,
                notes: null,
            }
        },

        /**
         * Adds any additional logic before or after closing this dialog.
         *
         * @return {void}
         */
        handleClose() {
            // Show a confirmation dialog before closing the chatbot creation form
            this.$confirm('Are you sure you want to discard your new Chatbot?')
                .then(_ => {
                    this.$emit('bot-discarded')
                    this.resetForm()
                    // Close the dialog
                    done()
                })
                .catch(_ => {
                    // Do nothing if the user cancels the action
                })
        }
    }
}
</script>
