var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "pl-3 pr-3" }, [
    _c("div", { staticClass: "row mb-2" }, [
      _c("div", { staticClass: "col-6" }, [
        _c("h4", { staticClass: "m-a-0 text-lg mb-0" }, [
          _c("span", [
            _vm._v(
              _vm._s(_vm._f("numFormat")(_vm.summary.inbound_calls_count))
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("small", { staticClass: "text-muted text-mini" }, [
          _vm._v("Inbound Calls"),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-6" }, [
        _c("h4", { staticClass: "m-a-0 text-lg mb-0" }, [
          _c("span", [
            _vm._v(
              _vm._s(_vm._f("numFormat")(_vm.summary.outbound_calls_count))
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("small", { staticClass: "text-muted text-mini" }, [
          _vm._v("Outbound Calls"),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row mb-2" }, [
      _c("div", { staticClass: "col-6" }, [
        _c("h4", { staticClass: "m-a-0 text-lg mb-0" }, [
          _c("span", [
            _vm._v(
              _vm._s(_vm._f("numFormat")(_vm.summary.inbound_texts_count))
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("small", { staticClass: "text-muted text-mini" }, [
          _vm._v("Inbound Texts"),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-6" }, [
        _c("h4", { staticClass: "m-a-0 text-lg mb-0" }, [
          _c("span", [
            _vm._v(
              _vm._s(_vm._f("numFormat")(_vm.summary.outbound_texts_count))
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("small", { staticClass: "text-muted text-mini" }, [
          _vm._v("Outbound Texts"),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12" }, [
        _c("h4", { staticClass: "m-a-0 text-lg mb-0" }, [
          _c("span", [
            _vm._v(_vm._s(_vm._f("numFormat")(_vm.summary.total_count))),
          ]),
        ]),
        _vm._v(" "),
        _c("small", { staticClass: "text-muted text-mini" }, [
          _vm._v("All Communications"),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }