<template>
  <el-popover :ref="ref"
              :placement="placement"
              width="200"
              class="custom-popover"
              :trigger="triggers"
              :content="customMessage">
  </el-popover>
</template>

<script>
import { kyc_mixin } from '../../mixins'

export default {
  name: 'block-tooltip',

  mixins: [
    kyc_mixin
  ],

  props: {
    placement: {
      required: false,
      type: String,
      default: 'top'
    },
    triggers: {
      required: false,
      type: String,
      default: 'click'
    },
    ref: {
      required: false,
      type: String
    },
    task: {
      required: false,
      type: String
    }
  },
}
</script>

<style scoped>
  .custom-popover {
    background-color: #0067F4;
    color: #fff;
  }
</style>