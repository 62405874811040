var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm._m(0),
    _vm._v(" "),
    _c("div", { staticClass: "d-flex flex-column" }, [
      _c(
        "div",
        {
          staticClass: "flex-grow-1",
          staticStyle: {
            "overflow-y": "scroll",
            "overflow-x": "clip",
            "max-height": "600px",
            height: "400px",
          },
          attrs: { id: "messages" },
          on: { wheel: _vm.handleWheel },
        },
        _vm._l(_vm.communications, function (communication, id) {
          return _c("ChatBubble", {
            key: id,
            class: "mb-2",
            attrs: { communication: communication },
          })
        }),
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "p-1",
          staticStyle: {
            border: "1px solid rgb(221, 221, 221)",
            "border-radius": "5px",
          },
          attrs: { id: "input" },
        },
        [
          _c("div", { staticClass: "messenger-input" }, [
            _c("textarea", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.input,
                  expression: "input",
                },
              ],
              staticClass: "message-input w-100 p-1",
              attrs: {
                id: "message-input",
                name: "message",
                placeholder: "Write a message",
                maxlength: "1600",
                spellcheck: "false",
              },
              domProps: { value: _vm.input },
              on: {
                keydown: _vm.handleKeyDown,
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.input = $event.target.value
                },
              },
            }),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "container-fluid" }, [
            _c(
              "div",
              { staticClass: "row" },
              [
                _c(
                  "el-button",
                  {
                    staticClass: "ml-auto",
                    attrs: {
                      type: "success",
                      size: "small",
                      disabled: _vm.input == "",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.sendMessage()
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n                        Send\n                    "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "el-button",
                  {
                    attrs: {
                      type: "warning",
                      size: "small",
                      disabled: _vm.communications.length !== 0,
                      loading: _vm.start_loading,
                    },
                    on: { click: _vm.sendStartMessage },
                  },
                  [
                    _vm._v(
                      "\n                        Start\n                    "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "el-button",
                  {
                    attrs: {
                      type: "primary",
                      size: "small",
                      loading: _vm.reset_loading,
                    },
                    on: {
                      click: function ($event) {
                        return _vm.sendResetMessage(true)
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n                        Reset\n                    "
                    ),
                  ]
                ),
              ],
              1
            ),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "mt-2", attrs: { id: "contact" } },
        [
          _c("contact-select", {
            staticClass: "px-1",
            attrs: {
              id: "contact-select",
              label: "Search contact",
              contact_selected: _vm.selected_contact,
            },
            on: { "select-contacts": _vm.onSelectContact },
            model: {
              value: _vm.selected_contact,
              callback: function ($$v) {
                _vm.selected_contact = $$v
              },
              expression: "selected_contact",
            },
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row mb-1" }, [
      _c("div", { staticClass: "col-6" }, [
        _c("img", {
          attrs: {
            height: "30px",
            src: "/assets/images/app-icons/alohabot-chat-bot.svg",
          },
        }),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-6 text-right" }, [
        _c("img", {
          staticStyle: { "margin-right": "5px" },
          attrs: {
            height: "30px",
            src: "/assets/images/app-icons/alohabot-chat-contact.svg",
          },
        }),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }