<template>
    <div>
        <el-dialog class="dialog-padding auto conflicted_numbers_modal"
                   :title="getTitle"
                   data-testid="show-contact-phone-number-duplicates-dialog"
                   :visible.sync="dialogTableVisible"
                   :width="isLargeEnough ? '600px' : '100%'"
                   append-to-body>
            <el-table :data="[phone_number.contact]">
                <el-table-column label="Main Contact">
                    <template #default="scope">
                        <router-link :to="{ name: 'Contact', params: { contact_id: scope.row?.id }}" >{{ scope.row?.id }}</router-link>
                    </template>
                </el-table-column>
                <el-table-column property="first_name" label="First Name"/>
                <el-table-column property="last_name" label="Last Name"/>
            </el-table>
            <el-table class="pb-4 conflicted_contacts_table"
                      :data="phone_number.conflicted_contacts">
                <el-table-column label="Conflicted Contacts">
                    <template #default="scope">
                        <router-link :to="{ name: 'Contact', params: { contact_id: scope.row?.id }}" >{{ scope.row?.id }}</router-link>
                    </template>
                </el-table-column>
                <el-table-column property="first_name" label="First Name"/>
                <el-table-column property="last_name" label="Last Name"/>
            </el-table>
        </el-dialog>

        <slot name="trigger">
            <button slot="reference"
                    class="btn btn-sm btn-outline-danger has_conflict_color_outline w-30 d-flex justify-content-center align-items-center mr-1"
                    data-testid="show-contact-phone-number-duplicates-slot-button"
                    @click.prevent="dialogTableVisible = true">
                <i class="fa fa-exclamation"></i>
            </button>
        </slot>
    </div>
</template>
<script>

export default {
    props: {
        phone_number: {
            required: true
        }
    },
    data() {
        return {
            dialogTableVisible: false
        }
    },
    computed: {
        isLargeEnough() {
            return ['xxl', 'xl', 'lg', 'md'].includes(this.$mq);
        },
        getTitle() {
            return `Contacts conflicted by the same phone number ${this.phone_number.phone_number}`;
        }
    }
}
</script>
