var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "col-12 p-a-xs",
      attrs: { "data-testid": "contact-textual-filters" },
    },
    [
      _c(
        "span",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.contactsCount == null,
              expression: "contactsCount == null",
            },
          ],
          attrs: { "element-loading-custom-class": "medium-v-loading" },
        },
        [
          _c("span", [
            _c("strong", [
              _vm._v(_vm._s(_vm._f("numFormat")(_vm.filteredContactCount))),
            ]),
            _vm._v("\n            Contacts\n        "),
          ]),
        ]
      ),
      _vm._v(" "),
      _vm.notEmptyEnabledFilters
        ? _c("div", { staticClass: "py-2" }, [
            _c("small", [_vm._v("Active Filters:")]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-12 p-0" },
              _vm._l(_vm.enabled_filters_text, function (filter) {
                return (typeof filter.value !== "object" && filter.value) ||
                  (typeof filter.value === "object" &&
                    filter.value &&
                    filter.value.length) ||
                  (typeof filter.date_range === "object" &&
                    filter.date_range &&
                    filter.date_range.length)
                  ? _c(
                      "span",
                      {
                        staticClass:
                          "btn btn-xs indigo-A200 mr-1 mb-1 text-left white-space-normal",
                        staticStyle: { "max-width": "100%" },
                      },
                      [
                        _c("strong", [
                          _vm._v(_vm._s(_vm._f("ucwords")(filter.name))),
                        ]),
                        _vm._v(" "),
                        _vm._l(filter.value, function (value) {
                          return !_vm.flag_filters.includes(filter.name) &&
                            !filter.date_range &&
                            filter.value.length
                            ? _c(
                                "b",
                                {
                                  staticClass:
                                    "white-space-initial d-inline label label-sm rounded success m-l-xs",
                                },
                                [
                                  typeof value == "string"
                                    ? _c("span", [
                                        _vm._v(
                                          _vm._s(_vm._f("capitalize")(value))
                                        ),
                                      ])
                                    : _vm._e(),
                                ]
                              )
                            : _vm._e()
                        }),
                        _vm._v(" "),
                        !_vm.flag_filters.includes(filter.name) &&
                        filter.date_range
                          ? _c(
                              "span",
                              { staticClass: "white-space-initial d-inline" },
                              [
                                _c(
                                  "b",
                                  {
                                    staticClass: "label rounded success m-l-xs",
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(_vm._s(filter.date_range[0])),
                                    ]),
                                  ]
                                ),
                                _vm._v(" "),
                                _c("span", { staticClass: "m-l-xs" }, [
                                  _vm._v("to"),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "b",
                                  {
                                    staticClass: "label rounded success m-l-xs",
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(_vm._s(filter.date_range[1])),
                                    ]),
                                  ]
                                ),
                              ]
                            )
                          : _vm._e(),
                      ],
                      2
                    )
                  : _vm._e()
              }),
              0
            ),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }