var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "app-body" }, [
    _c(
      "div",
      {
        staticClass: "text-center pos-rlt p-y-md",
        staticStyle: { overflow: "hidden" },
      },
      [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-12" }, [
            _c("a", { staticClass: "mx-auto d-block" }, [
              !_vm.loading_whitelabel
                ? _c("img", {
                    attrs: {
                      src: _vm.statics.logo,
                      height: "70px",
                      alt: "logo",
                    },
                  })
                : _vm._e(),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row mt-4" }, [
          _c("div", { staticClass: "col-md-4" }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col-md-4" },
            [
              _c(
                "p",
                {
                  staticClass: "h5 mt-5 font-bold text-left",
                  staticStyle: { "line-height": "30px" },
                },
                [
                  _vm._v(
                    "Unfortunately, your subscription has been suspended due to a problem with your payment.\n                "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "p",
                {
                  staticClass: "h5 mb-5 mt-4 font-bold text-left",
                  staticStyle: { "line-height": "30px" },
                },
                [
                  _vm._v(
                    "\n                    Please update your payment information on your invoice, or contact us. Thank you!\n                "
                  ),
                ]
              ),
              _vm._v(" "),
              _vm.auth.user.profile &&
              (!_vm.auth.user.profile.created_by_reseller ||
                (_vm.auth.user.profile.created_by_reseller &&
                  _vm.auth.user.profile.independent_billing))
                ? [
                    !_vm.enabledToSkipTrialAndSubscribe()
                      ? _c(
                          "el-popover",
                          {
                            ref: "trial-skip-popover",
                            staticClass: "custom-popover",
                            attrs: {
                              placement: "top",
                              width: "200",
                              "popper-class": "btn-primary",
                              trigger: "hover",
                            },
                          },
                          [
                            _c("custom-message-display", {
                              attrs: {
                                config: _vm.customMessage("billing.subscribe"),
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.hasPermissionTo("see chargebee portal")
                      ? _c(
                          "button",
                          {
                            directives: [
                              {
                                name: "popover",
                                rawName: "v-popover:trial-skip-popover",
                                arg: "trial-skip-popover",
                              },
                            ],
                            staticClass: "btn btn-sm bluish pr-2 pl-2",
                            attrs: {
                              disabled:
                                _vm.isChargeBeeSessionOrSubscriptionDisabled,
                            },
                            on: { click: _vm.chargebeePaymentSources },
                          },
                          [
                            _c("i", { staticClass: "material-icons" }, [
                              _vm._v("credit_card_outline"),
                            ]),
                            _vm._v(" "),
                            !_vm.cardAdded
                              ? _c("span", [_vm._v("Add Payment Method")])
                              : _c("span", [_vm._v("Manage Payment Methods")]),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.hasPermissionTo("see chargebee portal")
                      ? _c(
                          "button",
                          {
                            staticClass: "btn btn-sm white pr-2 pl-2 ml-2",
                            attrs: { disabled: !_vm.hasChargeBeeSession },
                            on: { click: _vm.billingHistory },
                          },
                          [
                            _c("i", { staticClass: "material-icons" }, [
                              _vm._v("history"),
                            ]),
                            _vm._v(
                              "\n                        Payment History\n                    "
                            ),
                          ]
                        )
                      : _vm._e(),
                  ]
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "mt-3" }, [
                _vm.current_company && _vm.current_company.reseller_id != 357
                  ? _c(
                      "a",
                      {
                        staticClass: "btn greenish p-x-md",
                        attrs: {
                          href: "mailto:support@aloware.com?subject=Suspended Account - Failed Payment",
                        },
                      },
                      [
                        _vm._v(
                          "\n                        Send an Email\n                    "
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.current_company && _vm.current_company.reseller_id == 357
                  ? _c(
                      "a",
                      {
                        staticClass: "btn greenish p-x-md",
                        attrs: {
                          href: "mailto:support@simpsocial.com?subject=Suspended Account - Failed Payment",
                        },
                      },
                      [
                        _vm._v(
                          "\n                        Send an Email\n                    "
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.current_company && _vm.current_company.reseller_id != 357
                  ? _c(
                      "a",
                      {
                        staticClass: "btn new-blue p-x-md ml-2",
                        attrs: { href: "tel:+18552562001" },
                      },
                      [
                        _vm._v(
                          "\n                        Call us\n                    "
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.current_company && _vm.current_company.reseller_id == 357
                  ? _c(
                      "a",
                      {
                        staticClass: "btn new-blue p-x-md ml-2",
                        attrs: { href: "tel:+18888291110" },
                      },
                      [
                        _vm._v(
                          "\n                        Call us\n                    "
                        ),
                      ]
                    )
                  : _vm._e(),
              ]),
            ],
            2
          ),
          _vm._v(" "),
          _c("div", { staticClass: "col-md-4" }),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }