<template>
    <div>
        <div id="dialer"
            ref="dialer"
            class="webrtc-dialer box-shadow-z4"
            :class="[is_widget ? 'widget' : '']"
            v-visible="is_widget ? true : is_visible"
            v-if="showDialer()">

            <el-dialog :visible.sync="show_help"
                    title="Help"
                    top="10vh"
                    append-to-body>
                <ul class="list pl-3 pr-3 mb-0 text-md">
                    <li class="_600 mb-2">Closing the phone will not make you offline. Choose on break or busy from the
                        menu items to stop receiving calls.
                    </li>
                    <li class="_600 mb-2">If you refresh the page while on a call, the call will drop. Meanwhile you can
                        navigate with the application during a call.
                    </li>
                </ul>
            </el-dialog>

            <el-dialog
                :title="scriptDialogTitle"
                :visible.sync="showScriptDialog"
                :before-close="hideScript"
                append-to-body
                width="50%">
                <span v-if="selected_script"
                    v-html="selected_script.text">
                </span>
            </el-dialog>

            <div ref="dialerHeader"
                class="row no-gutters webrtc-top grabbable">
                <div class="col-12 p-2 d-flex align-items-center justify-content-between">
                    <div class="pull-left d-flex align-items-center justify-content-between w-100 pr-2">
                        <span class="_400 text-greyish text-xs no-select">
                            <el-tooltip
                                class="item"
                                effect="dark"
                                :content="phone_status"
                                :disabled="isPhoneStatusTooltipDisabled"
                                placement="top">
                                <span>
                                    {{ phone_status | truncate(20) }}

                                    <el-link
                                        v-if="last_error_code === 31208 && !$root.statics.whitelabel"
                                        href="https://support.aloware.com/en/articles/9037858-troubleshooting-tip-audio-issues-during-calls-here-s-how-to-fix-it"
                                        target="_blank"
                                        :underline="false"
                                        class="ml-2">
                                        See fix
                                    </el-link>
                                </span>
                            </el-tooltip>
                        </span>
                        <span class="text-muted text-xs ml-3 no-select"
                            v-text="call_timer"
                            v-if="call">
                        </span>
                    </div>

                    <div class="pull-right d-flex">
                        <el-popover placement="left-start"
                                    class="d-flex align-items-center p-0 mr-1"
                                    width="200"
                                    trigger="click"
                                    v-if="is_visible"
                                    @show="initializeSettings">
                            <el-form label-width="200px"
                                    label-position="top">
                                <el-form-item label="Input Device:"
                                            class="mb-0">
                                    <el-select v-model="input_device"
                                            placeholder="Select"
                                            size="mini"
                                            @change="setInputDevice">
                                        <el-option
                                            v-for="item in input_devices"
                                            :key="item.id"
                                            :label="item.label"
                                            :value="item.id">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item label="Output Device:"
                                            class="mb-0">
                                    <el-select v-model="output_device"
                                            placeholder="Select"
                                            size="mini"
                                            @change="setOutputDevice">
                                        <el-option
                                            v-for="item in output_devices"
                                            :key="item.id"
                                            :label="item.label"
                                            :value="item.id">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                                <el-button type="primary"
                                        class="d-block w-full mt-2"
                                        size="mini"
                                        @click.prevent="testOutputDevice">
                                    Speaker Test
                                </el-button>
                            </el-form>
                            <el-button slot="reference"
                                    type="text"
                                    class="p-0 ml-0 text-orange settings">
                                <i class="material-icons text-lg">settings</i>
                            </el-button>
                        </el-popover>
                        <el-button type="text"
                                class="p-0 ml-0 mr-1 help"
                                @click="toggleHelp"
                                v-if="is_visible && !is_widget">
                            <i class="material-icons text-lg">help</i>
                        </el-button>
                        <el-button type="text"
                                class="p-0 ml-0 text-danger minimize"
                                @click="closePhone"
                                v-if="is_visible && !is_widget">
                            <i class="material-icons text-lg">clear</i>
                        </el-button>
                    </div>
                </div>
            </div>
            <div class="row no-gutters webrtc-header"
                :class="getHeaderClass">
                <div class="col-12 pt-2 pr-2 pb-0 pl-2"
                    v-if="is_ready">
                    <el-form ref="form"
                            class="phone-form"
                            :rules="rules"
                            :model="form"
                            @submit.prevent.native="validateAndCall">
                        <router-link :to="{ name: 'Contact', params: { contact_id: communication.contact_id }}"
                                    v-if="call && communication && communication.contact && !is_widget">
                            <el-tooltip class="item"
                                        effect="dark"
                                        content="Go to contact's page"
                                        placement="left">
                                <span class="contact-name text-whitish opacity-8 text-ellipsis _400 text-md"
                                    :class="[!is_widget ? 'pull-left mr-1' : '']">
                                    {{ communication.contact.name | fixContactName }}
                                </span>
                            </el-tooltip>
                        </router-link>
                        <a href="#"
                        v-else-if="((call && communication && communication.contact && is_widget) || (on_wrap_up && communication && communication.contact && !is_widget))"
                        @click="goToContact">
                            <span class="contact-name text-whitish opacity-8 text-ellipsis _400 text-md"
                                :class="[!is_widget ? 'pull-left mr-1' : '']">
                                {{ communication.contact.name | fixContactName }}
                            </span>
                        </a>

                        <!-- contact edit -->
                        <contact-info-form :contact="(on_wrap_up ? communication.contact : contactBasic)"
                                        v-if="is_widget == false"
                                        @update="updateContactInCommunication">
                            <div class="no-select"
                                v-if="(call || on_wrap_up) && communication && communication.contact"
                                slot="ref_trigger">
                                <span class="text-md pointer no-select"><i class="material-icons">edit</i></span>
                            </div>
                        </contact-info-form>

                        <el-form-item prop="current_number"
                                    class="mb-0">
                            <input type="tel"
                                v-model="form.current_number"
                                ref="current_number"
                                class="pull-left _400 p-0"
                                placeholder="Click to type"
                                v-if="!call && !on_call && !on_wrap_up"
                                :class="call ? 'on-call' : ''"
                                :disabled="!is_ready"
                                @input="customPreValidateForm"
                                autofocus/>
                            <span class="_500 pull-left original-line-height"
                                v-if="call || on_call || on_wrap_up">
                                <template v-if="form.current_number">
                                    {{ form.current_number | fixPhone }}
                                </template>
                                <template v-else-if="communication">
                                    {{ communication.lead_number | fixPhone }}
                                </template>
                            </span>
                            <el-popover
                                placement="right"
                                title=""
                                width="50"
                                trigger="manual"
                                content="Copied!"
                                v-model="copied">
                                <el-button type="text"
                                        slot="reference"
                                        class="text-white ml-2 p-0 pull-left"
                                        v-if="((call && !['pending'].includes(call.status())) || on_wrap_up)"
                                        @click="copyPhoneNumber(on_wrap_up ? communication.lead_number : form.current_number)">
                                    <i class="fa fa-copy text-sm"></i>
                                </el-button>
                            </el-popover>
                        </el-form-item>
                        <router-link :to="{ name: 'Contact', params: { contact_id: contact.id }}"
                                    v-if="contact && !communication && !is_widget">
                            <el-tooltip class="item"
                                        effect="dark"
                                        content="Go to contact's page"
                                        placement="left">
                                <span class="text-whitish opacity-8 text-ellipsis _400">
                                    {{ contact.name | fixContactName }}
                                </span>
                            </el-tooltip>
                        </router-link>
                        <div v-if="contact_local_time || getCompanyName">
                            <span class="text-whitish opacity-8 _400 text-xs"
                                v-if="getCompanyName">
                                {{ getCompanyName }}
                            </span>
                            <span v-if="getCompanyName && contact_local_time">
                                -
                            </span>
                            <span class="text-whitish opacity-8 _400 text-xs"
                                v-if="contact_local_time">
                                {{ contact_local_time }}
                            </span>
                        </div>
                        <div v-if="is_held">
                            <span class="text-whitish opacity-8 text-ellipsis _400">On Hold</span>
                        </div>
                        <a href="#"
                        v-else-if="contact && !communication && is_widget"
                        @click="goToContact">
                            <span class="text-whitish opacity-8 text-ellipsis _400">
                                {{ contact.name | fixContactName }}
                            </span>
                        </a>

                        <template v-if="addedParty && show_added_party && (addingParty || partyAdded)">
                            <el-tooltip class="item"
                                        effect="dark"
                                        :content="addedParty"
                                        placement="left"
                                        v-if="true">
                                <div>
                                <span class="text-whitish text-ellipsis _400"
                                    v-if="addingParty">
                                    Adding: {{ addedParty | truncate(32) }}
                                </span>
                                    <span class="text-whitish text-ellipsis _400"
                                        style="font-size: 11px"
                                        v-if="partyAdded">
                                    Added: {{ addedParty | truncate(32) }}
                                </span>
                                    <b class="label blue webrtc-label-position-transfer"
                                    v-if="should_introduce">Introduce</b>
                                    <b class="label greenish webrtc-label-position-transfer"
                                    v-else-if="addedParty">Add</b>
                                </div>
                            </el-tooltip>
                        </template>

                    </el-form>

                    <add-contact class="pull-right pr-0 pb-0 pl-0"
                                v-if="!call && !on_wrap_up"
                                :small="is_widget"
                                @success="addContact">
                    </add-contact>

                    <div class="header-right"
                        v-if="call">
                        <ul id="signal-strength">
                            <li class="very-weak">
                                <div id="very-weak"
                                    class="active">
                                </div>
                            </li>
                            <li class="weak">
                                <div id="weak"
                                    :class="[ signalStrength >= 25 ? 'active' : '' ]">
                                </div>
                            </li>
                            <li class="strong">
                                <div id="strong"
                                    :class="[ signalStrength >= 50 ? 'active' : '' ]">
                                </div>
                            </li>
                            <li class="pretty-strong">
                                <div id="pretty-strong"
                                    :class="[ signalStrength >= 75 ? 'active' : '' ]">
                                </div>
                            </li>
                        </ul>
                    </div>

                    <div class="row no-gutters clear-both"
                        v-if="call === null && !on_call && parked_call">
                        <div class="col-12">
                            <button class="btn unhold btn-block btn-flat greenish"
                                    :disabled="!parked_call || loading_park"
                                    @click="unparkCall">
                                Unpark Call
                            </button>
                        </div>
                    </div>
                </div>
                <div class="el-table__empty-block no-min-height"
                    v-if="!is_ready && !loading_device">
                    <button class="btn solid bluish px-4"
                            @click="rebootPhone(true, true)">
                        Reconnect
                    </button>
                </div>
            </div>
            <div class="communication-info"
                v-if="on_wrap_up || (call && call.status() == 'closed')">
                <div class="scrollable pt-2">
                    <communication-info ref="communication_info"
                                        :communication="communication"
                                        :is_widget="is_widget"
                                        display_contact
                                        dialer_mode
                                        @callDisposed="callDisposed"
                                        @contactDisposed="contactDisposed"
                                        @contactNotDisposed="contactNotDisposed">
                    </communication-info>
                </div>
                <div class="row">
                    <div class="col-12">
                        <el-button class="pull-left ml-2"
                                size="small"
                                :disabled="disableBackButton || temporary_disable_back_button"
                                @click.prevent="backToQueue()">
                            <i class="fa fa-arrow-left"></i>
                            Back
                            <span v-if="wrapUpTimerEnabled && wrap_up_timer > 0">({{ wrap_up_timer }}s)</span>
                            <span v-if="!wrapUpTimerEnabled && temporary_disable_back_button && temporary_disable_timer_count > 0">({{ temporary_disable_timer_count }}s)</span>
                        </el-button>
                        <el-button class="pull-right mr-2"
                                type="success"
                                size="small"
                                v-if="communication"
                                :disabled="disableCallBackButton"
                                @click.prevent="callBack(communication.lead_number)">
                            <i class="fa fa-phone"></i>
                            Call back
                        </el-button>
                    </div>
                </div>
            </div>
            <div class="dial-menu"
                v-if="!show_transfer && !show_add && !show_notes && !show_dialpad && !show_dialer_info && call && call.status() != 'pending'">
                <div class="row no-gutters">
                    <div class="col-4">
                        <div class="dummy"></div>
                        <div class="in">
                            <template
                                v-if="communication && communication.legc_uuid && communication.legc_status == CommunicationStatus.STATUS_INPROGRESS_NEW && !communication.in_cold_transfer && call.call_sid != communication.legc_uuid && (!communication.legz_uuid || call.call_sid != communication.legz_uuid)">
                                <button class="btn btn-block btn-flat number-btn-wrapper"
                                        :disabled="agent_status === AgentStatus.AGENT_STATUS_SENTRY"
                                        @click.prevent="dropThirdParty">
                                    <span class="number-text text-danger">
                                        <i class="material-icons">call_end</i>
                                    </span>
                                    <span class="number-text-sub text-danger">Drop</span>
                                </button>
                            </template>
                            <template v-else>
                                <button class="btn btn-block btn-flat number-btn-wrapper"
                                        :disabled="!communication || communication.in_cold_transfer || communication.current_status2 !== CommunicationCurrentStatus.CURRENT_STATUS_INPROGRESS_NEW || (current_company && !current_company.conferencing_enabled) || (communication.legc_uuid && [CommunicationStatus.STATUS_INPROGRESS_NEW, CommunicationStatus.STATUS_RINGING_NEW].includes(communication.legc_status)) || (communication.legz_uuid && call.call_sid == communication.legz_uuid) || agent_status === AgentStatus.AGENT_STATUS_SENTRY"
                                        @click="toggleAdd">
                                    <span class="number-text">
                                        <i class="material-icons text-xl">add</i>
                                    </span>
                                    <span class="number-text-sub"
                                        v-if="communication && communication.legc_uuid && communication.legc_status && communication.legc_status == CommunicationStatus.STATUS_RINGING_NEW">
                                        Adding
                                        <span class="loader__dot">.</span><span class="loader__dot">.</span><span class="loader__dot">.</span>
                                    </span>
                                    <span class="number-text-sub"
                                        v-else>
                                        Add
                                    </span>
                                </button>
                            </template>
                        </div>
                    </div>
                    <div class="col-4">
                        <div class="dummy"></div>
                        <div class="in">
                            <button class="btn btn-block btn-flat number-btn-wrapper"
                                    :disabled="!communication || is_held || loading_hold || loading_unhold || loading_park || communication.current_status2 !== CommunicationCurrentStatus.CURRENT_STATUS_INPROGRESS_NEW || (current_company && !current_company.conferencing_enabled) || (communication.legc_uuid && [CommunicationStatus.STATUS_INPROGRESS_NEW, CommunicationStatus.STATUS_RINGING_NEW].includes(communication.legc_status)) || (communication.legz_uuid && call.call_sid == communication.legz_uuid) || agent_status === AgentStatus.AGENT_STATUS_SENTRY"
                                    @click="parkCall(true)">
                                <span class="number-text">
                                    <img src="/assets/images/app-icons/phone/park-call.png"/>
                                </span>
                                <span class="number-text-sub">Park</span>
                            </button>
                        </div>
                    </div>
                    <div class="col-4">
                        <div class="dummy"></div>
                        <div class="in">
                            <button class="btn btn-block btn-flat number-btn-wrapper"
                                    :disabled="!communication || (communication.legc_uuid && communication.legc_status == CommunicationStatus.STATUS_INPROGRESS_NEW) || communication.current_status2 !== CommunicationCurrentStatus.CURRENT_STATUS_INPROGRESS_NEW || (current_company && !current_company.conferencing_enabled) || (communication.legc_uuid && [CommunicationStatus.STATUS_INPROGRESS_NEW, CommunicationStatus.STATUS_RINGING_NEW].includes(communication.legc_status)) || (communication.legz_uuid && call.call_sid == communication.legz_uuid) || agent_status === AgentStatus.AGENT_STATUS_SENTRY"
                                    @click="toggleTransfer">
                                <span class="number-text">
                                    <i class="material-icons text-xl">call_split</i>
                                </span>
                                <span class="number-text-sub">
                                    Transfer
                                </span>
                            </button>
                        </div>
                    </div>
                    <div class="col-4">
                        <div class="dummy"></div>
                        <div class="in">
                            <button class="btn btn-block btn-flat number-btn-wrapper"
                                    @click="toggleNotes">
                                <span class="number-text">
                                    <i class="material-icons text-xl">note_add</i>
                                </span>
                                <span class="number-text-sub">Add Notes</span>
                            </button>
                        </div>
                    </div>
                    <div class="col-4">
                        <div class="dummy"></div>
                        <div class="in">
                            <communication-tags class="webrtc-communication-tags"
                                                :communication="communication"
                                                :small="is_widget"
                                                :show_tags="false"
                                                v-if="communication">
                                <template v-slot:trigger>
                                    <button class="btn btn-block btn-flat number-btn-wrapper">
                                        <span class="number-text">
                                            <i class="material-icons text-xl">label_outline</i>
                                        </span>
                                        <span class="number-text-sub">Add Tags</span>
                                    </button>
                                </template>
                            </communication-tags>
                            <button class="btn btn-block btn-flat number-btn-wrapper"
                                    disabled
                                    v-else>
                                <span class="number-text">
                                    <i class="material-icons text-xl">label_outline</i>
                                </span>
                                <span class="number-text-sub">Add Tags</span>
                            </button>
                        </div>
                    </div>
                    <div class="col-4">
                        <div class="dummy"></div>
                        <div class="in">
                            <button class="btn btn-block btn-flat number-btn-wrapper"
                                    :disabled="!communication"
                                    @click="toggleDialpad">
                                <span class="number-text">
                                    <i class="material-icons text-xl">dialpad</i>
                                </span>
                                <span class="number-text-sub">Dialpad</span>
                            </button>
                        </div>
                    </div>
                    <div class="col-4">
                        <div class="dummy"></div>
                        <div class="in">
                            <el-popover :placement="is_widget ? 'top' : 'left'"
                                        :width="is_widget ? 250 : 300"
                                        title="Drop Voicemail"
                                        trigger="click">
                                <template v-if="voicemail_drop_files && voicemail_drop_files.length > 5">
                                    <p>
                                        <el-select v-model="selected_user_vm_drop"
                                                class="w-full"
                                                placeholder="Select"
                                                @change="setVmDrop">
                                            <el-option v-for="user_vm in voicemail_drop_files"
                                                    :key="user_vm.id"
                                                    :label="user_vm.name"
                                                    :value="user_vm.id">
                                                {{ user_vm.name }}
                                            </el-option>
                                        </el-select>
                                    </p>
                                    <div class="m-0 text-right">
                                        <el-button type="success"
                                                size="mini"
                                                :disabled="!selected_user_vm_drop || loading_play_prerecorded_voicemail"
                                                @click="playPrerecordedVoicemailDrop">
                                            Drop
                                        </el-button>
                                    </div>
                                </template>
                                <template v-if="voicemail_drop_files && voicemail_drop_files.length <= 5">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <ul class="button-custom-list">
                                                <li v-for="user_vm in voicemail_drop_files"
                                                    :key="user_vm.id">
                                                    <button class="btn btn-sm btn-block btn-primary"
                                                            @click="playCustomPrerecordedVoicemailDrop(user_vm.id)">
                                                        <span class="number-text-sub">{{ user_vm.name }}</span>
                                                    </button>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </template>
                                <button class="btn btn-flat number-btn-wrapper"
                                        slot="reference"
                                        :disabled="!communication || communication.current_status2 !== CommunicationCurrentStatus.CURRENT_STATUS_INPROGRESS_NEW || loading_play_prerecorded_voicemail"
                                        @click="toggleVmDrop">
                                    <span class="number-text">
                                        <i class="material-icons text-xl">event_note</i>
                                    </span>
                                    <span class="number-text-sub">VM Drop</span>
                                </button>
                            </el-popover>
                        </div>
                    </div>
                    <div class="col-4"
                        v-if="!is_widget">
                        <div class="dummy"></div>
                        <div class="in">
                            <el-popover placement="left"
                                        title="CRM links"
                                        width="300"
                                        trigger="click">
                                <template v-if="contact">
                                    <contact-crm-links :contact="contact"/>
                                </template>
                                <button class="btn btn-block btn-flat number-btn-wrapper"
                                        slot="reference"
                                        :disabled="!contact || !current_company && (!current_company.pipedrive_integration_enabled || !current_company.hubspot_integration_enabled || !current_company.stripe_integration_enabled || !current_company.zoho_integration_enabled || !current_company.helpscout_integration_enabled)">
                                    <span class="number-text">
                                        <i class="material-icons text-xl">insert_link</i>
                                    </span>
                                    <span class="number-text-sub">CRM</span>
                                </button>
                            </el-popover>
                        </div>
                    </div>
                    <div class="col-4"
                        v-if="hasPermissionTo('list script') && !is_widget">
                        <div class="dummy"></div>
                        <div class="in">
                            <el-popover placement="left"
                                        title="Scripts"
                                        width="300"
                                        trigger="click">
                                <el-table v-loading="loading_script"
                                        :data="scripts">
                                    <el-table-column
                                        prop="title"
                                        label="Title">
                                    </el-table-column>
                                    <el-table-column>
                                        <template v-slot="scope">
                                            <el-button class="greenish"
                                                    @click="showScript(scope.row)"
                                                    size="mini">
                                                <i class="material-icons">launch</i>
                                            </el-button>
                                        </template>
                                    </el-table-column>
                                </el-table>

                                <button class="btn btn-flat number-btn-wrapper"
                                        slot="reference">
                                    <span class="number-text">
                                        <i class="material-icons text-xl">event_note</i>
                                    </span>
                                    <span class="number-text-sub">Scripts</span>
                                </button>
                            </el-popover>
                        </div>
                    </div>
                    <div class="col-4">
                        <div class="dummy"></div>
                        <div class="in">
                            <button class="btn btn-block btn-flat number-btn-wrapper"
                                    :disabled="loading_toggle_recording_status || !communication || communication.current_status2 !== CommunicationCurrentStatus.CURRENT_STATUS_INPROGRESS_NEW || agent_status === AgentStatus.AGENT_STATUS_SENTRY || communication.should_record === false"
                                    @click="toggleRecordingStatus(recording_status)">
                                <template
                                    v-if="recording_status === 'in-progress' && (!communication || communication.should_record == false)">
                                    <span class="number-text text-gray">
                                        <i class="material-icons text-xl">cancel</i>
                                    </span>
                                    <span class="number-text-sub text-gray">Paused Rec</span>
                                </template>
                                <template
                                    v-if="recording_status === 'in-progress' && communication && communication.should_record == true">
                                    <span class="number-text text-danger">
                                        <i class="material-icons text-xl">pause_circle_filled</i>
                                    </span>
                                    <span class="number-text-sub text-danger">Pause Rec</span>
                                </template>
                                <template v-if="recording_status === 'paused'">
                                    <span class="number-text">
                                        <i class="material-icons text-xl">lens</i>
                                    </span>
                                    <span class="number-text-sub">Start Rec</span>
                                </template>
                            </button>
                        </div>
                    </div>
                    <div class="col-4">
                        <div class="dummy"></div>
                        <div class="in">
                            <button class="btn btn-block btn-flat number-btn-wrapper"
                                    :disabled="!call || ['pending', 'closed'].includes(call.status())"
                                    @click="toggleMute">
                                <template v-if="muted">
                                    <span class="number-text text-danger">
                                        <i class="material-icons text-xl">mic_off</i>
                                    </span>
                                    <span class="number-text-sub text-danger">Unmute</span>
                                </template>
                                <template v-else>
                                    <span class="number-text">
                                        <i class="material-icons text-xl">mic</i>
                                    </span>
                                    <span class="number-text-sub">Mute</span>
                                </template>
                            </button>
                        </div>
                    </div>
                    <div class="col-4">
                        <div class="dummy"></div>
                        <div class="in">
                            <button class="btn btn-block btn-flat number-btn-wrapper"
                                    :disabled="!communication || !communication.contact"
                                    @click="goToContact">
                                <span class="number-text">
                                    <i class="material-icons text-xl">open_in_new</i>
                                </span>
                                <span class="number-text-sub">Contact</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="note-menu"
                v-if="show_notes">
                <div class="row note-wrapper pl-3 pr-3 pt-2">
                    <div class="col-12">
                        <div class="row">
                            <div class="col-12">
                                <el-form>
                                    <el-form-item label="Notes:"
                                                class="mb-0">
                                        <el-input type="textarea"
                                                placeholder="Write your call notes here..."
                                                ref="communication_notes"
                                                v-model="communication_notes"
                                                :autosize="{ minRows: 5, maxRows: 10}"
                                                :disabled="!hasPermissionTo('note communication') || loading_notes"
                                                autofocus>
                                        </el-input>
                                    </el-form-item>

                                    <div class="mt-1 text-right h-24">
                                        <span class="text-muted"
                                            v-show="loading_notes">
                                            <i class="material-icons loader text-dark-greenish">&#xE863;</i>
                                            saving
                                        </span>
                                        <span class="text-muted"
                                            v-show="!loading_notes && loading_text">
                                            saved
                                        </span>
                                        <span class="text-deep-orange _600"
                                            v-if="communication && communication_notes != communication.notes && !loading_notes">
                                            unsaved changes
                                        </span>
                                    </div>
                                </el-form>
                            </div>
                        </div>
                        <div class="row mt-1">
                            <div class="col-12">
                                <el-button class="pull-left"
                                        size="small"
                                        @click.prevent="toggleNotes">
                                    <i class="fa fa-arrow-left"></i>
                                    Back
                                </el-button>
                                <el-button class="pull-right"
                                        type="success"
                                        size="small"
                                        :loading="loading_notes"
                                        :disabled="loading_notes"
                                        @click="saveNotes">
                                    Save
                                </el-button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="transfer-menu"
                v-if="show_transfer">
                <div class="row transfer-wrapper pl-3 pr-3 pt-2">
                    <div class="col-12">
                        <el-form ref="transfer_form"
                                class="h-full"
                                label-position="top"
                                :rules="transfer_rules"
                                :model="transfer">
                            <el-form-item prop="transfer_mode">
                                <div class="row mb-4">
                                    <div class="col-12">
                                        <el-radio v-model="transfer.mode"
                                                label="user"
                                                @change="preValidateTransferForm">
                                            Transfer to a user
                                        </el-radio>

                                        <el-form-item prop="user">
                                            <div v-if="transfer.mode == 'user'">
                                                <el-select placeholder="Select User"
                                                        ref="transfer_user"
                                                        class="d-inline-flex"
                                                        style="width: 225px;"
                                                        filterable
                                                        v-model="transfer.user_id"
                                                        :disabled="loading_users"
                                                        @visible-change="resetFilteredText"
                                                        @change="preValidateTransferForm">
                                                    <el-option-group label="Available users"
                                                                    v-if="filteredAvailableUsers && filteredAvailableUsers.length > 0">
                                                        <el-option
                                                            v-for="user in filteredAvailableUsers"
                                                            :key="user.id"
                                                            :label="user.name"
                                                            :value="user.id">
                                                            <div class="media">
                                                                <div class="media-body">
                                                                    <label>{{ user.name }}</label>
                                                                    <small>{{ getLabel(user) }}</small>
                                                                </div>
                                                            </div>
                                                        </el-option>
                                                    </el-option-group>
                                                    <el-option-group label="Unavailable users">
                                                        <el-option
                                                            v-for="user in filteredUnavailableUsers"
                                                            :key="user.id"
                                                            :label="user.name"
                                                            :value="user.id">
                                                            <div class="media">
                                                                <div class="media-body">
                                                                    <label>{{ user.name }}</label>
                                                                    <small>{{ getLabel(user) }}</small>
                                                                </div>
                                                            </div>
                                                        </el-option>
                                                    </el-option-group>
                                                </el-select>
                                                <el-button type="primary"
                                                        icon="fa fa-refresh"
                                                        size="mini"
                                                        class="d-inline-flex ml-2"
                                                        :disabled="loading_users"
                                                        :loading="loading_users"
                                                        @click="getAvailableUsers"
                                                        circle>
                                                </el-button>
                                            </div>
                                        </el-form-item>
                                    </div>
                                </div>
                                <div class="row mb-4">
                                    <div class="col-12">
                                        <el-radio v-model="transfer.mode"
                                                label="ring_group"
                                                @change="preValidateTransferForm">
                                            Transfer to a ring group
                                        </el-radio>

                                        <el-form-item prop="ring_group">
                                            <div v-if="transfer.mode == 'ring_group'">
                                                <el-select placeholder="Select Ring Group"
                                                        ref="transfer_ring_group"
                                                        class="d-inline-flex"
                                                        style="width: 225px;"
                                                        filterable
                                                        clearable
                                                        v-model="transfer.ring_group_id"
                                                        @change="preValidateTransferForm">
                                                    <el-option
                                                        v-for="ring_group in visibleRingGroups"
                                                        :key="ring_group.id"
                                                        :label="ring_group.name"
                                                        :value="ring_group.id">
                                                        <i class="material-icons">group</i>
                                                        <span>{{ ring_group.name }}</span>
                                                    </el-option>
                                                </el-select>
                                            </div>
                                        </el-form-item>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12">
                                        <el-radio v-model="transfer.mode"
                                                label="phone"
                                                @change="preValidateTransferForm">
                                            Transfer to a phone number
                                        </el-radio>

                                        <el-form-item prop="phone_number">
                                            <div v-if="transfer.mode == 'phone'">
                                                <input type="tel"
                                                    class="el-input__inner _400"
                                                    v-model="transfer.phone_number"
                                                    ref="transfer_phone_number"
                                                    @input="preValidateTransferForm"/>
                                            </div>
                                        </el-form-item>
                                    </div>
                                </div>
                            </el-form-item>
                            <div class="row transfer-buttons mt-3">
                                <div class="col-12">
                                    <el-button class="pull-left"
                                            size="small"
                                            @click.prevent="toggleTransfer">
                                        <i class="fa fa-arrow-left"></i>
                                        Back
                                    </el-button>
                                    <el-button class="pull-right"
                                            type="success"
                                            size="small"
                                            :loading="loading_users || loading_transfer"
                                            :disabled="!transfer_validated"
                                            @click="transferCall">
                                        <i class="material-icons">call_split</i>
                                        Transfer
                                    </el-button>
                                </div>
                            </div>
                        </el-form>
                    </div>
                </div>
            </div>
            <div class="transfer-menu"
                v-if="show_add">
                <div class="row transfer-wrapper pl-3 pr-3 pt-2">
                    <div class="col-12">
                        <el-form ref="add_form"
                                class="h-full"
                                label-position="top"
                                :rules="add_rules"
                                :model="add">
                            <el-form-item prop="add_mode">
                                <div class="row mb-4">
                                    <div class="col-12">
                                        <el-radio v-model="add.mode"
                                                label="user"
                                                @change="preValidateAddForm">
                                            Add a user
                                        </el-radio>

                                        <el-form-item prop="user">
                                            <div v-if="add.mode == 'user'">
                                                <el-select placeholder="Select User"
                                                        ref="add_user"
                                                        class="d-inline-flex"
                                                        style="width: 225px;"
                                                        filterable
                                                        v-model="add.user_id"
                                                        :disabled="loading_users"
                                                        @visible-change="resetFilteredText"
                                                        @change="preValidateAddForm">
                                                    <el-option-group label="Available users"
                                                                    v-if="filteredAvailableUsers && filteredAvailableUsers.length > 0">
                                                        <el-option
                                                            v-for="user in filteredAvailableUsers"
                                                            :key="user.id"
                                                            :label="user.name"
                                                            :value="user.id">
                                                            <div class="media">
                                                                <div class="media-body">
                                                                    <label>{{ user.name }}</label>
                                                                    <small>{{ getLabel(user) }}</small>
                                                                </div>
                                                            </div>
                                                        </el-option>
                                                    </el-option-group>
                                                    <el-option-group label="Unavailable users">
                                                        <el-option
                                                            v-for="user in filteredUnavailableUsers"
                                                            :key="user.id"
                                                            :label="user.name"
                                                            :value="user.id">
                                                            <div class="media">
                                                                <div class="media-body">
                                                                    <label>{{ user.name }}</label>
                                                                    <small>{{ getLabel(user) }}</small>
                                                                </div>
                                                            </div>
                                                        </el-option>
                                                    </el-option-group>
                                                </el-select>
                                                <el-button type="primary"
                                                        icon="fa fa-refresh"
                                                        size="mini"
                                                        class="d-inline-flex ml-2"
                                                        :disabled="loading_users"
                                                        :loading="loading_users"
                                                        @click="getAvailableUsers(false)"
                                                        circle>
                                                </el-button>
                                            </div>
                                        </el-form-item>
                                    </div>
                                </div>
                                <div class="row mb-4">
                                    <div class="col-12">
                                        <el-radio v-model="add.mode"
                                                label="ring_group"
                                                @change="preValidateAddForm">
                                            Add a ring group
                                        </el-radio>

                                        <el-form-item prop="ring_group">
                                            <div v-if="add.mode == 'ring_group'">
                                                <el-select placeholder="Select Ring Group"
                                                        ref="add_ring_group"
                                                        class="d-inline-flex"
                                                        style="width: 225px;"
                                                        filterable
                                                        clearable
                                                        v-model="add.ring_group_id"
                                                        :disabled="loading_ring_groups"
                                                        @visible-change="resetFilteredText"
                                                        @change="preValidateAddForm">
                                                    <el-option
                                                        v-for="ring_group in filteredAvailableRingGroups"
                                                        :key="ring_group.id"
                                                        :label="ring_group.name"
                                                        :value="ring_group.id">
                                                        <div class="media">
                                                            <div class="media-body">
                                                                <label>{{ ring_group.name }}</label>
                                                            </div>
                                                        </div>
                                                    </el-option>
                                                </el-select>
                                                <el-button type="primary"
                                                        icon="fa fa-refresh"
                                                        size="mini"
                                                        class="d-inline-flex ml-2"
                                                        :disabled="loading_ring_groups"
                                                        :loading="loading_ring_groups"
                                                        @click="getAvailableRingGroups"
                                                        circle>
                                                </el-button>
                                            </div>
                                        </el-form-item>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12">
                                        <el-radio v-model="add.mode"
                                                label="phone"
                                                @change="preValidateAddForm">
                                            Add a phone number
                                        </el-radio>

                                        <el-form-item prop="phone_number">
                                            <div v-if="add.mode == 'phone'">
                                                <input type="tel"
                                                    class="el-input__inner _400"
                                                    v-model="add.phone_number"
                                                    ref="add_phone_number"
                                                    @input="preValidateAddForm"/>
                                            </div>
                                        </el-form-item>
                                    </div>
                                </div>
                            </el-form-item>
                            <div class="row transfer-buttons mt-3">
                                <div class="col-6">
                                    <el-button class="pull-left"
                                            size="small"
                                            @click.prevent="toggleAdd">
                                        <i class="fa fa-arrow-left"></i>
                                        Back
                                    </el-button>
                                </div>
                                <div class="col-6">
                                    <el-button class="pull-right"
                                            type="success"
                                            size="small"
                                            :loading="loading_users || loading_add"
                                            :disabled="!add_validated"
                                            @click="addCall(false)">
                                        <i class="fa fa-user-plus"></i>
                                        Add
                                    </el-button>
                                    <br>
                                    <el-button class="pull-right mt-2"
                                            type="primary"
                                            size="small"
                                            :loading="loading_users || loading_add"
                                            :disabled="!add_validated"
                                            @click="addCall(true)">
                                        <i class="fa fa-user-friends"></i>
                                        Introduce
                                    </el-button>
                                </div>
                            </div>
                        </el-form>
                    </div>
                </div>
            </div>
            <div class="dial-pad"
                v-if="!show_transfer && !show_add && !show_notes && show_dialpad && !show_dialer_info && (!call || (call && call.status() != 'pending')) && !(call && call.status() == 'closed' && on_wrap_up)">
                <div class="row no-gutters">
                    <div class="col-4">
                        <div class="dummy"></div>
                        <div class="in">
                            <button class="btn btn-block btn-flat number-btn-wrapper"
                                    :disabled="!is_ready"
                                    @click="sendDigit('1')">
                                <span class="number-text">1</span>
                            </button>
                        </div>
                    </div>
                    <div class="col-4">
                        <div class="dummy"></div>
                        <div class="in">
                            <button class="btn btn-block btn-flat number-btn-wrapper"
                                    :disabled="!is_ready"
                                    @click="sendDigit('2')">
                                <span class="number-text">2</span>
                                <span class="number-text-sub">A B C</span>
                            </button>
                        </div>
                    </div>
                    <div class="col-4">
                        <div class="dummy"></div>
                        <div class="in">
                            <button class="btn btn-block btn-flat number-btn-wrapper"
                                    :disabled="!is_ready"
                                    @click="sendDigit('3')">
                                <span class="number-text">3</span>
                                <span class="number-text-sub">D E F</span>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="row no-gutters">
                    <div class="col-4">
                        <div class="dummy"></div>
                        <div class="in">
                            <button class="btn btn-block btn-flat number-btn-wrapper"
                                    :disabled="!is_ready"
                                    @click="sendDigit('4')">
                                <span class="number-text">4</span>
                                <span class="number-text-sub">G H I</span>
                            </button>
                        </div>
                    </div>
                    <div class="col-4">
                        <div class="dummy"></div>
                        <div class="in">
                            <button class="btn btn-block btn-flat number-btn-wrapper"
                                    :disabled="!is_ready"
                                    @click="sendDigit('5')">
                                <span class="number-text">5</span>
                                <span class="number-text-sub">J K L</span>
                            </button>
                        </div>
                    </div>
                    <div class="col-4">
                        <div class="dummy"></div>
                        <div class="in">
                            <button class="btn btn-block btn-flat number-btn-wrapper"
                                    :disabled="!is_ready"
                                    @click="sendDigit('6')">
                                <span class="number-text">6</span>
                                <span class="number-text-sub">M N O</span>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="row no-gutters">
                    <div class="col-4">
                        <div class="dummy"></div>
                        <div class="in">
                            <button class="btn btn-block btn-flat number-btn-wrapper"
                                    :disabled="!is_ready"
                                    @click="sendDigit('7')">
                                <span class="number-text">7</span>
                                <span class="number-text-sub">P Q R S</span>
                            </button>
                        </div>
                    </div>
                    <div class="col-4">
                        <div class="dummy"></div>
                        <div class="in">
                            <button class="btn btn-block btn-flat number-btn-wrapper"
                                    :disabled="!is_ready"
                                    @click="sendDigit('8')">
                                <span class="number-text">8</span>
                                <span class="number-text-sub">T U V</span>
                            </button>
                        </div>
                    </div>
                    <div class="col-4">
                        <div class="dummy"></div>
                        <div class="in">
                            <button class="btn btn-block btn-flat number-btn-wrapper"
                                    :disabled="!is_ready"
                                    @click="sendDigit('9')">
                                <span class="number-text">9</span>
                                <span class="number-text-sub">W X Y Z</span>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="row no-gutters">
                    <div class="col-4">
                        <div class="dummy"></div>
                        <div class="in">
                            <button class="btn btn-block btn-flat number-btn-wrapper"
                                    :disabled="!is_ready"
                                    @click="sendDigit('*')">
                                <span class="number-text"
                                    style="font-size: 18px;">
                                    <i class="fas fa-star-of-life"></i>
                                </span>
                            </button>
                        </div>
                    </div>
                    <div class="col-4">
                        <div class="dummy"></div>
                        <div class="in">
                            <button class="btn btn-block btn-flat number-btn-wrapper"
                                    :disabled="!is_ready"
                                    @click="sendDigit('0')">
                                <span class="number-text">0</span>
                                <span class="number-text-sub text-md">+</span>
                            </button>
                        </div>
                    </div>
                    <div class="col-4">
                        <div class="dummy"></div>
                        <div class="in">
                            <button class="btn btn-block btn-flat number-btn-wrapper"
                                    :disabled="!is_ready"
                                    @click="sendDigit('#')">
                                <span class="number-text"
                                    style="font-size: 20px;">
                                    <i class="fas fa-hashtag"></i>
                                </span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row no-gutters p-2" v-if="!call && !on_wrap_up">
                <div class="col-4 offset-4 d-flex align-items-center justify-content-center">
                    <el-popover placement="top"
                                trigger="click"
                                popper-class="v-c dark-mode"
                                v-if="!outbound_campaign_id">
                        <button slot="reference"
                                ref="call_btn"
                                class="md-btn md-fab d-flex greenish align-items-center justify-content-center"
                                :disabled="form.current_number.length === 0 || !number_validated || !is_ready || isReceivingCall || on_call || loading_contact || !enabledToCallNumber(form.current_number)">
                            <i class="material-icons loader"
                            v-if="shouldWaitForContact && loading_contact">&#xE863;</i>
                            <i class="material-icons"
                            v-else>call</i>
                        </button>

                        <el-select v-model="initial_campaign_id"
                                placeholder="Select a line"
                                :loading="loading_contact"
                                :disabled="loading_contact"
                                filterable>
                            <el-option-group key="Agent Lines"
                                            label="Agent Lines">
                                <el-option
                                    v-for="campaign in agentCampaigns"
                                    :key="campaign.id"
                                    :label="campaign.name"
                                    :value="campaign.id">
                                </el-option>
                            </el-option-group>
                            <el-option-group key="All Lines"
                                            label="All Lines">
                                <el-option
                                    v-for="campaign in visibleCampaigns"
                                    :key="campaign.id"
                                    :label="campaign.name"
                                    :value="campaign.id">
                                </el-option>
                            </el-option-group>
                        </el-select>
                        <el-button type="success"
                                class="ml-1 pl-2 pr-2"
                                :disabled="!initial_campaign_id || !enabledToCallNumber(form.current_number)"
                                @click="callContact">
                            Call
                        </el-button>
                    </el-popover>

                    <button class="md-btn md-fab d-flex greenish align-items-center justify-content-center"
                            :disabled="form.current_number.length === 0 || !number_validated || !is_ready || isReceivingCall || on_call || is_answering || callDisabled || !enabledToCallNumber(form.current_number)"
                            @click="answer"
                            v-else>
                        <i class="material-icons">call</i>
                    </button>
                </div>
                <div class="col-4 d-flex align-items-center justify-content-center dropup">
                    <a class="nav-link d-flex align-items-center no-select"
                    :class="[ disableAgentStatusButtons ? 'disabled' : '']"
                    data-toggle="dropdown"
                    v-if="is_widget">
                        <span class="w-40 avatar agent-avatar grey-300"
                            v-bind:style="avatarStyle(auth.user.profile.name)"
                            v-if="auth.user && auth.user.profile && auth.user.profile.name">
                            <span>{{ auth.user.profile.name | initials }}</span>
                            <i class="b-white bottom"
                            :class="[ $options.filters.agentStatusClass(agent_status?? auth.user.profile.agent_status) ]">
                            </i>
                        </span>
                        <i class="material-icons">keyboard_arrow_up</i>
                    </a>
                    <agent-status-dropdown
                        id="avatar-agent-statuses"
                        class="dropdown-menu dropdown-menu-right dropdown-menu-overlay dropdown-menu-scale pull-right"
                        ref="avatar_agent_statuses"
                        :user_id="auth.user.profile.id"
                        :forced_disabled_ui="true">
                    </agent-status-dropdown>
                </div>
            </div>

            <!-- caller info -->
            <div class="row no-gutter p-2" v-if="call && call.status() == 'pending'">
                <div class="col-12">
                    <div class="call-communication-info">
                        <table class="mb-2 w-100"
                            v-loading="!communication">
                            <template v-if="communication">
                                <tr v-if="getSource(communication)">
                                    <td colspan="2">
                                        <strong>{{ getSource(communication) }}</strong>
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="2">
                                        <strong>Call Information</strong>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Line:</td>
                                    <td v-if="getCampaign(communication.campaign_id)">
                                        <strong>{{ getCampaign(communication.campaign_id).name }}</strong>
                                    </td>
                                </tr>
                                <tr>
                                    <td>From:</td>
                                    <td>
                                        <strong>{{ communication.incoming_number | fixPhone }}</strong>
                                    </td>
                                </tr>
                                <template v-if="communication.contact">
                                    <tr>
                                        <td colspan="2" class="pt-md-2">
                                            <strong>Contact Information</strong>
                                        </td>
                                    </tr>
                                    <tr v-if="communication.contact.company_name">
                                        <td>Company:</td>
                                        <td>
                                            <strong>{{ communication.contact.company_name }}</strong>
                                        </td>
                                    </tr>
                                    <tr v-if="communication.contact.website">
                                        <td>Website:</td>
                                        <td>
                                            <strong>{{ communication.contact.website }}</strong>
                                        </td>
                                    </tr>
                                    <tr v-if="communication.contact.cnam_city || communication.contact.cnam_state">
                                        <td>Location:</td>
                                        <td>
                                            <strong>
                                                <span v-if="communication.contact.cnam_city">{{ communication.contact.cnam_city }}, </span>
                                                <span v-if="communication.contact.cnam_state">{{ communication.contact.cnam_state }}</span>
                                            </strong>
                                        </td>
                                    </tr>
                                </template>
                            </template>
                        </table>
                        <div v-if="communication && communication.contact && hasCRMIntegration(communication)">
                            <el-collapse v-model="call_activename">
                                <el-collapse-item title="Integration Cards"
                                                name="integration-cards"
                                                v-if="hubspotEnabled">
                                    <integration-cards :contact="communication.contact"
                                                    :dialer_mode="true"
                                                    no_padding>
                                    </integration-cards>
                                </el-collapse-item>
                                <el-collapse-item title="Integrations"
                                                name="integrations">
                                    <contact-crm-links :contact="communication.contact"
                                                    no_padding>
                                    </contact-crm-links>
                                </el-collapse-item>
                            </el-collapse>
                        </div>
                    </div>
                </div>
            </div>

            <!-- The current accept/reject call button -->
            <div class="row no-gutters p-2"
                v-if="call && call.status() == 'pending'">
                <div class="col-3 offset-2 d-flex align-items-center justify-content-center">
                    <button class="md-btn md-fab d-flex danger align-items-center justify-content-center"
                            @click="reject">
                        <i class="material-icons">call_end</i>
                    </button>
                </div>
                <div class="col-3 offset-2 d-flex align-items-center justify-content-center">
                    <button class="md-btn md-fab d-flex greenish align-items-center justify-content-center"
                            :disabled="is_answering"
                            @click="answer">
                        <i class="material-icons">call</i>
                    </button>
                </div>
            </div>
            <div class="row no-gutters text-center p-2"
                v-if="call && ['ringing', 'open', 'connecting', 'reconnecting'].includes(call.status())">
                <div class="col-4 d-flex align-items-center justify-content-center">
                    <el-button type="text"
                            v-if="show_dialpad"
                            @click="toggleDialpad">
                        Hide
                    </el-button>
                    <el-tooltip class="item"
                                effect="dark"
                                content="Merge calls"
                                placement="top"
                                v-if="!show_dialpad && should_introduce && communication && communication.legc_uuid && communication.legc_status && communication.legc_status == CommunicationStatus.STATUS_INPROGRESS_NEW">
                        <button class="md-btn md-flat d-flex new-blue align-items-center justify-content-center"
                                @click="mergeCalls">
                            <template v-if="loading_merge">
                                <span class="loader__dot">.</span><span class="loader__dot">.</span><span class="loader__dot">.</span>
                            </template>
                            <i class="material-icons"
                            v-else>call_merge</i>
                        </button>
                    </el-tooltip>
                    <button class="btn btn-block btn-flat btn-white p-0"
                            :disabled="isHoldDisabled"
                            v-if="!show_dialpad && (!should_introduce || communication.legc_status !== CommunicationStatus.STATUS_INPROGRESS_NEW)"
                            @click="toggleHold">
                        <span class="number-text">
                            <img src="/assets/images/app-icons/phone/unhold-call.png"
                                v-show="is_held"/>
                            <img src="/assets/images/app-icons/phone/hold-call.png"
                                v-show="!is_held"/>
                        </span>
                        <span class="number-text-sub" :class="[ is_held ? 'text-blue-15' : '']">{{ is_held ? 'Unhold' : 'Hold' }}</span>
                    </button>
                </div>
                <div class="col-4 d-flex align-items-center justify-content-center">
                    <button class="md-btn md-fab d-flex danger align-items-center justify-content-center"
                            :disabled="!enable_hangup || is_answering"
                            @click="hangup">
                        <i class="material-icons">call_end</i>
                    </button>
                </div>
                <div class="col-4 d-flex align-items-center justify-content-center">
                    <el-tooltip class="item"
                                effect="dark"
                                content="Refresh"
                                placement="top"
                                v-if="agent_status !== AgentStatus.AGENT_STATUS_SENTRY">
                        <button class="md-btn md-flat d-flex success align-items-center justify-content-center"
                                @click="forceRefreshCommunication">
                            <i class="material-icons loader"
                            v-if="loading_communication">&#xE863;</i>
                            <i class="material-icons"
                            v-else>refresh</i>
                        </button>
                    </el-tooltip>
                </div>
            </div>
        </div>
        <div v-if="showAlertAgentOnCall()"
             class="p-3">
            <p><strong>Call in Progress on Another Device</strong></p>
            <hr>
            <p>You're currently engaged in another call on Aloware Talk. Please complete your current conversation before initiating a new call.</p>
        </div>
    </div>
</template>

<script>
import _ from 'lodash'
import auth from '../auth'
import {
    acl_mixin,
    avatar_mixin,
    communication_mixin,
    form_validation_mixin,
    user_info_mixin,
    validator_mixin,
    kyc_mixin
} from '../mixins'
import {mapActions, mapState} from 'vuex'
import TwilioDevice from './communication/twilio/device'
import ICountUp from 'vue-countup-v2'
import moment from 'moment'
import ContactCrmLinks from './contact-crm-links'
import ContactInfoForm from './contact-info-form'
import IntegrationCards from './integration-cards'
import AgentStatusDropdown from './misc/agent-status-dropdown'
import * as AgentStatus from '../constants/agent-status'
import * as Carriers from '../constants/carriers'
import * as WebrtcEvents from '../constants/webrtc-events'
import * as AnswerTypes from '../constants/answer-types'
import * as UserOutboundCallingModes from '../constants/user-outbound-calling-modes'
import * as CommunicationCurrentStatus from '../constants/communication-current-status'
import * as CommunicationStatus from '../constants/communication-status'
import * as CommunicationDirections from '../constants/communication-direction'
import * as CommunicationDispositionStatus from '../constants/communication-disposition-status'
import * as CommunicationTypes from '../constants/communication-types'
import * as CommunicationResolutions from "../constants/communication-resolution"
import * as CallbackStatus from "../constants/callback-status"
import {CALL_ACCEPT, CALL_CANCEL, CALL_DISCONNECT} from "../constants/webrtc-events";

export default {
    mixins: [
        acl_mixin,
        avatar_mixin,
        communication_mixin,
        user_info_mixin,
        validator_mixin,
        form_validation_mixin,
        kyc_mixin
    ],

    components: {
        AgentStatusDropdown,
        ICountUp,
        ContactCrmLinks,
        ContactInfoForm,
        IntegrationCards
    },

    props: {
        carrier_name: {
            required: true,
            type: String
        },

        is_widget: {
            default: false,
            type: Boolean,
            required: false
        }
    },

    data() {
        let easingFn = function (t, b, c, d) {
            let ts = (t /= d) * t
            let tc = ts * t
            return b + c * (tc + -3 * ts + 3 * t)
        }
        return {
            auth: auth,
            env: null,
            on_wrap_up: false,
            wrap_up_timer: 0,
            wrap_up_timer_interval: null,
            token: null,
            device: this.carrier_name === Carriers.TWILIO ? new TwilioDevice() : null,
            loading_device: true,
            is_ready: false,
            call_duration_timer_enabled: false,
            call_timer: '',
            call_duration: 0,
            phone_status: 'Logging into system',
            recording_status: 'in-progress',
            call_logs: [],
            scripts: [],
            call: null,
            muted: false,
            is_visible: false,
            show_dialer_info: false,
            show_dialpad: true,
            show_transfer: false,
            show_add: false,
            show_notes: false,
            show_added_party: false,
            loading: false,
            loading_contact: false,
            loading_communication: false,
            loading_park: false,
            loading_transfer: false,
            loading_add: false,
            loading_drop_third_party: false,
            loading_users: false,
            loading_ring_groups: false,
            loading_play_prerecorded_voicemail: false,
            loading_script: false,
            loading_toggle_recording_status: false,
            should_introduce: false,
            loading_merge: false,
            available_users: [],
            unavailable_users: [],
            available_ring_groups: [],
            transfer_validated: false,
            add_validated: false,
            added_party: null,
            contact_local_time: null,
            transfer: {
                mode: 'user',
                user_id: null,
                ring_group_id: null,
                phone_number: null
            },
            add: {
                mode: 'user',
                user_id: null,
                phone_number: null
            },
            transfer_rules: {
                resource: [
                    {
                        required: true,
                        message: 'Please select transfer mode',
                        trigger: 'change'
                    }
                ],
                user: [
                    {
                        validator: this.transferUserValidator,
                        trigger: 'change'
                    }
                ],
                ring_group: [
                    {
                        validator: this.transferRingGroupValidator,
                        trigger: 'change'
                    }
                ],
                phone_number: [
                    {
                        validator: this.transferPhoneValidator,
                        trigger: 'blur'
                    }
                ]
            },
            add_rules: {
                resource: [
                    {
                        required: true,
                        message: 'Please select add mode',
                        trigger: 'change'
                    }
                ],
                user: [
                    {
                        validator: this.addUserValidator,
                        trigger: 'change'
                    }
                ],
                ring_group: [
                    {
                        validator: this.addRingGroupValidator,
                        trigger: 'change'
                    }
                ],
                phone_number: [
                    {
                        validator: this.addPhoneValidator,
                        trigger: 'blur'
                    }
                ]
            },
            call_queue: [],
            form: {
                current_number: ''
            },
            number_validated: false,
            contact: null,
            communication: null,
            rules: {
                current_number: [
                    {
                        required: true,
                        validator: this.phoneValidator,
                        trigger: 'change'
                    }
                ]
            },
            agent_statuses: [{
                value: AgentStatus.AGENT_STATUS_OFFLINE,
                label: 'Offline'
            }, {
                value: AgentStatus.AGENT_STATUS_ACCEPTING_CALLS,
                label: 'Available'
            }, {
                value: AgentStatus.AGENT_STATUS_NOT_ACCEPTING_CALLS,
                label: 'Busy'
            }, {
                value: AgentStatus.AGENT_STATUS_ON_BREAK,
                label: 'On break'
            }, {
                value: AgentStatus.AGENT_STATUS_ON_WRAP_UP,
                label: 'Wrap-up',
                disabled: true
            }, {
                value: AgentStatus.AGENT_STATUS_ON_CALL,
                label: 'On call',
                disabled: true
            }, {
                value: AgentStatus.AGENT_STATUS_RINGING,
                label: 'Ringing',
                disabled: true
            }, {
                value: AgentStatus.AGENT_STATUS_SENTRY,
                label: 'Sentry',
                disabled: true
            }],
            agent_status: auth.user.profile.agent_status,
            old_agent_status: AgentStatus.AGENT_STATUS_OFFLINE,
            agent_status_before_on_call: null,
            agent_status_logs: [],
            outbound_campaign_id: null,
            show_help: false,
            enable_hangup: false,
            parked_call: null,
            input_devices: [],
            input_device: 'default',
            output_devices: [],
            output_device: 'default',
            selected_script: null,
            copied: false,
            pos1: 0,
            pos2: 0,
            pos3: 0,
            pos4: 0,
            initial_campaign_id: null,
            loading_vm_drop: false,
            voicemail_drop_files: [],
            selected_user_vm_drop: null,
            selected_user_vm_drop_data: null,
            is_answering: false,
            active_clicked: '',
            call_is_disposed: false,
            contact_is_disposed: false,
            call_activename: [],
            filtered_text: null,
            warnings: [],
            communication_notes: '',
            loading_notes: false,
            loading_text: false,
            padding: 8,
            rect: null,
            viewport: {
                bottom: 0,
                left: 0,
                right: 0,
                top: 0
            },
            loading_hold: false,
            loading_unhold: false,
            is_held: false,
            temporary_disable_back_button: false,
            temporary_disable_timer_count: 2,
            permanently_failed_to_login: false,
            last_error_code: null,
            is_power_dialer: false,
            AgentStatus,
            AnswerTypes,
            Carriers,
            UserOutboundCallingModes,
            CommunicationDispositionStatus,
            CommunicationCurrentStatus,
            CommunicationDirections,
            CommunicationStatus,
            CommunicationTypes,
            CommunicationResolutions,
        }
    },

    computed: {
        ...mapState(['campaigns', 'ring_groups', 'on_call']),
        ...mapState('cache', ['current_company']),

        addedParty() {
            // in case of ring group warm transfer
            if (this.communication && this.communication.added_user_id) {
                let user = this.getUser(this.communication.added_user_id)
                if (user) {
                    return user.name
                }
            }
            return this.added_party
        },

        addingParty() {
            return this.communication && this.communication.legc_uuid && this.communication.legc_status == CommunicationStatus.STATUS_RINGING_NEW
        },

        partyAdded() {
            return this.communication && this.communication.legc_uuid && this.communication.legc_status == CommunicationStatus.STATUS_INPROGRESS_NEW
        },

        isHoldDisabled() {
            return (!this.communication || this.loading_hold || this.loading_unhold || this.communication.current_status2 !== CommunicationCurrentStatus.CURRENT_STATUS_INPROGRESS_NEW || (this.current_company && !this.current_company.conferencing_enabled) || (this.communication.legc_uuid && [CommunicationStatus.STATUS_INPROGRESS_NEW, CommunicationStatus.STATUS_RINGING_NEW].includes(this.communication.legc_status)) || (this.communication.legz_uuid && this.call.call_sid === this.communication.legz_uuid))
        },

        disableBackButton() {
            return (
                this.current_company
                && this.current_company.force_call_disposition
                && !this.call_is_disposed
                && this.communication
                && !this.communication.call_disposition_id
            ) || (
                this.current_company
                && this.current_company.force_contact_disposition
                && !this.contact_is_disposed
                && this.communication
                && this.communication.contact
                && !this.communication.contact.call_disposition_id
            )
        },

        hubspotEnabled() {
            if (this.current_company &&
                this.current_company.hubspot_integration_enabled) {
                return true
            }

            return false
        },

        signalStrength() {
            return 100 - (this.warnings.length * 25)
        },

        getHeaderClass() {
            if (this.is_held) {
                return 'blue-15'
            }

            if (this.is_ready) {
                return 'new-blue'
            }

            if (!this.is_ready) {
                return 'grey'
            }

            return ''
        },

        agentCampaigns() {
            if (this.campaigns && this.users && this.auth.user && this.auth.user.profile) {
                let user = this.getUser(this.auth.user.profile.id)
                if (user && user.campaigns) {
                    return user.campaigns.filter(campaign => campaign.active == true)
                }
            }

            return []
        },

        visibleCampaigns() {
            if (this.campaigns) {
                return this.campaigns.filter(campaign => campaign.active == true)
            }

            return []
        },

        shouldWaitForContact() {
            return !this.outbound_campaign_id
        },

        isReceivingCall() {
            return this.activeCall !== undefined
        },

        activeCall() {
            return this.device.activeCall()
        },

        wrapUpTimerEnabled() {
            if (!this.current_company) {
                return false
            }

            let user_wrap_up_seconds = (this.auth && this.auth.user && this.auth.user.profile) ? this.auth.user.profile.wrap_up_seconds : 0
            let wrap_up_timer = this.current_company.force_wrap_up ? this.current_company.wrap_up_seconds : user_wrap_up_seconds

            return wrap_up_timer > 0
        },

        scriptDialogTitle() {
            if (this.selected_script) {
                return this.selected_script.title
            }

            return ''
        },

        showScriptDialog() {
            return this.selected_script != null
        },

        availableUsers() {
            if (!this.available_users) {
                return []
            }

            return this.available_users.sort((a, b) => {
                let textA = a.name.toUpperCase()
                let textB = b.name.toUpperCase()
                return (textA < textB) ? -1 : (textA > textB) ? 1 : 0
            })
        },

        filteredAvailableUsers() {
            if (this.filtered_text) {
                return this.availableUsers.filter((user) =>
                    (user.name && user.name.toLowerCase().includes(this.filtered_text.toLowerCase())) ||
                    (user.phone_number && user.phone_number.includes(this.filtered_text)) ||
                    (user.email && user.email.toLowerCase().includes(this.filtered_text.toLowerCase()))
                )
            }

            return this.availableUsers
        },

        unavailableUsers() {
            if (!this.unavailable_users) {
                return []
            }

            return this.unavailable_users.sort((a, b) => {
                let textA = a.name.toUpperCase()
                let textB = b.name.toUpperCase()
                return (textA < textB) ? -1 : (textA > textB) ? 1 : 0
            })
        },

        filteredUnavailableUsers() {
            if (this.filtered_text) {
                return this.unavailableUsers.filter((user) =>
                    (user.name && user.name.toLowerCase().includes(this.filtered_text.toLowerCase())) ||
                    (user.phone_number && user.phone_number.includes(this.filtered_text)) ||
                    (user.email && user.email.toLowerCase().includes(this.filtered_text.toLowerCase()))
                )
            }

            return this.unavailableUsers
        },

        visibleRingGroups() {
            if (this.ring_groups.length < 1) {
                return []
            }

            return this.ring_groups.filter((ring_group) => !ring_group.call_waiting)
        },

        availableRingGroups() {
            if (this.available_ring_groups.length < 1) {
                return []
            }

            // exclude call waiting ring groups
            return this.available_ring_groups.filter((ring_group) => !ring_group.call_waiting).sort((a, b) => {
                let textA = a.name.toUpperCase()
                let textB = b.name.toUpperCase()
                return (textA < textB) ? -1 : (textA > textB) ? 1 : 0
            })
        },

        filteredAvailableRingGroups() {
            if (this.filtered_text) {
                return this.availableRingGroups.filter((ring_group) =>
                    ring_group.name && ring_group.name.toLowerCase().includes(this.filtered_text.toLowerCase())
                )
            }

            return this.availableRingGroups
        },

        contactBasic() {
            if (this.contact) {
                return this.contact
            }

            return {
                first_name: '',
                last_name: ''
            }
        },

        getCompanyName() {
            if (this.communication && this.communication.contact && this.communication.contact.company_name) {
                return this.communication.contact.company_name
            }

            if (this.contact && this.contact.company_name) {
                return this.contact.company_name
            }

            return null
        },

        isOnCallOrRinging() {
            return (this.on_call ||
                    this.agent_status == AgentStatus.AGENT_STATUS_RINGING) &&
                !this.on_wrap_up
        },

        isOnWrapUpCall() {
            return this.on_call && this.on_wrap_up
        },

        isCallNotDisposed() {
            return this.current_company &&
                this.current_company.force_call_disposition &&
                !this.call_is_disposed
        },

        isContactNotDisposed() {
            return this.current_company &&
                this.current_company.force_contact_disposition &&
                !this.contact_is_disposed
        },

        isOnWrapUpCallWithCallAndContactNotDisposed() {
            return this.isOnWrapUpCall &&
                (this.isCallNotDisposed ||
                    this.isContactNotDisposed)
        },

        disableAgentStatusButtons() {
            return this.isOnCallOrRinging ||
                this.isOnWrapUpCallWithCallAndContactNotDisposed ||
                this.loading
        },

        disableCallBackButton() {
            return (this.current_company && this.current_company.force_call_disposition && !this.call_is_disposed)
                || (this.current_company && this.current_company.force_contact_disposition && !this.contact_is_disposed)
        },

        hasWrapUp() {
            if (this.current_company && this.auth && this.auth.user && this.auth.user.profile) {
                const user = this.auth.user.profile
                let wrap_up_seconds = this.current_company.force_wrap_up ? this.current_company.wrap_up_seconds : user.wrap_up_seconds
                return wrap_up_seconds >= 0
            }

            return false
        },

        isCompanyForcedAvailable() {
            return this.current_company?.force_users_always_available
        },

        isPhoneStatusTooltipDisabled() {
            return !this.phone_status || this.phone_status.length <= 20;
        },

        callDisabled () {
            return !this.enabledToCallNumber()
        }
    },

    created() {
        if (!Push.Permission.has() && Push.Permission.get() != Push.Permission.DENIED) {
            Push.Permission.request()
        }

        if (this.campaigns) {
            // find default outbound campaign
            this.findDefaultOutboundCampaign()
        }

        if ([AgentStatus.AGENT_STATUS_ON_CALL, AgentStatus.AGENT_STATUS_ON_WRAP_UP].includes(this.agent_status) && !this.on_call) {
            this.storeStatusBeforeOnCall(this.agent_status)
            this.setOnCall(true)
        }

        // initialize device if this is a widget instance
        if (this.is_widget) {
            this.openPhone()
            this.startPhone()
        }

        // fixes login/impersonation agent status from back-end
        if (this.auth.user.profile.agent_status) {
            this.changeOldAgentStatus(this.old_agent_status)
        }

        VueEvent.listen('hangup_call', () => {
            // hangup and end wrap-up
            this.hangup()
            setTimeout(() => {
                this.backToQueue()
            }, 1000)
        })

        // if it's Aloware internal phone
        if (!this.is_widget) {
            VueEvent.listen('make_new_call', (data) => {
                this.makeNewCall(data, true)
            })

            VueEvent.listen('unhold_call', (data) => {
                this.openPhone()
                this.makeCall("unhold:" + data.communication_id)
            })

            VueEvent.listen('hangup_and_answer_call', (data) => {
                // hangup and end wrap-up
                this.hangup()
                this.backToQueue()
                setTimeout(() => {
                    this.makeNewCall(data, true)
                }, 3000)
            })

            VueEvent.listen('park_and_answer_call', (data) => {
                // park and end wrap-up
                this.parkCall(true)
                setTimeout(() => {
                    this.makeNewCall(data, true)
                }, 3000)
            })
        }

        // if this is a widget in HubSpot
        if (this.is_widget) {
            VueEvent.listen('make_hs_new_call', (data) => {
                if (this.on_wrap_up || this.call) {
                    this.terminate(true)
                }

                setTimeout(() => {
                    this.makeNewCall(data, true, true)
                }, 1000)
            })
        }

        VueEvent.listen('toggle_webrtc', () => {
            if (!this.is_widget) {
                this.toggleVisible()
            }
        })

        VueEvent.listen('open_webrtc', (agent_status) => {
            if (!this.is_widget) {
                this.makeVisible(agent_status)
            }
        })

        VueEvent.listen('init_dialer', () => {
            if (!this.is_widget) {
                this.initDevice()
            }
        })

        VueEvent.listen('reload_dialer', () => {
            if (!this.is_widget) {
                // call api to end any user's active calls
                axios.post('/api/v1/profile/kill-live-calls').then(res => {
                    // end wrap up mode
                    this.backToQueue()
                    // reboot phone
                    this.rebootPhone(true, true)
                }).catch(err => {
                    this.$root.handleErrors(err.response)
                })
            }
        })

        VueEvent.listen('change_agent_status', ({agent_status, is_from_dropdown = false, signature}) => {
            this.agent_status = agent_status
            // if agent status is on AGENT_STATUS_OFFLINE, AGENT_STATUS_ACCEPTING_CALLS, AGENT_STATUS_NOT_ACCEPTING_CALLS, AGENT_STATUS_ON_BREAK, AGENT_STATUS_AUTO_DIAL, AGENT_STATUS_SENTRY
            if (this.agent_status !== undefined && ![AgentStatus.AGENT_STATUS_ON_WRAP_UP, AgentStatus.AGENT_STATUS_ON_CALL, AgentStatus.AGENT_STATUS_RINGING].includes(this.agent_status)) {
                this.changeOldAgentStatus(this.agent_status)
            }
            this.changeAgentStatus(this.agent_status, true, 1, is_from_dropdown, signature)
        })

        VueEvent.listen('user_updated', (user) => {
            if (this.auth && this.auth.user.profile && user.id == this.auth.user.profile.id && this.agent_status != user.agent_status) {
                this.agent_status = user.agent_status
                // if agent status is on AGENT_STATUS_OFFLINE, AGENT_STATUS_ACCEPTING_CALLS, AGENT_STATUS_NOT_ACCEPTING_CALLS, AGENT_STATUS_ON_BREAK, AGENT_STATUS_AUTO_DIAL, AGENT_STATUS_SENTRY
                if (this.agent_status !== undefined && ![AgentStatus.AGENT_STATUS_ON_WRAP_UP, AgentStatus.AGENT_STATUS_ON_CALL, AgentStatus.AGENT_STATUS_RINGING].includes(this.agent_status)) {
                    this.changeOldAgentStatus(this.agent_status)
                }
            }

            if (this.auth && this.auth.user.profile && user.id == this.auth.user.profile.id && this.auth.user.profile.outbound_calling_mode != user.outbound_calling_mode) {
                this.auth.user.profile.outbound_calling_mode = user.outbound_calling_mode
            }

            if (this.auth && this.auth.user.profile && user.id == this.auth.user.profile.id && this.auth.user.profile.default_outbound_campaign_id != user.default_outbound_campaign_id) {
                this.auth.user.profile.default_outbound_campaign_id = user.default_outbound_campaign_id
            }

            this.findDefaultOutboundCampaign()
        })

        VueEvent.listen('update_communication', this.updateCommunication)

        VueEvent.listen('webrtc_update_communication', this.updateCommunication)

        VueEvent.listen('webrtc_locked_communication', ({communication, user}) => {
            if (this.communication && this.communication.id == communication.id && this.auth && this.auth.user.profile && user.id == this.auth.user.profile.id) {
                this.backToQueue()
            }
        })

        VueEvent.listen('agent_status_updated', (event) => {
            if (this.auth?.user?.profile?.id == event.user_id) {
                this.auth.user.profile.agent_status = event.agent_status
                this.agent_status = event.agent_status

                this.showDialer()
                this.showAlertAgentOnCall()
            }
        })

        this.device.on(WebrtcEvents.REGISTERING, () => {
            this.changePhoneStatus('Registering phone ...')
        })

        this.device.on(WebrtcEvents.REGISTERED, (device) => {
            // Subscribe to the event for when the list of devices changes
            device.audio.on('deviceChange', () => this.getInputDevices())

            // Now it's time to Call getUserMedia to get the input device names.
            // This is needed to get the labels. Otherwise, we will only have device IDs.
            // It's also recommended to ensure we know which mic to use when the call comes in.
            // Furthermore, performing this action here, allows for capturing gUM errors early
            // before accepting/receiving a call and it's possible to create a much better user experience
            navigator.mediaDevices.getUserMedia({audio: true}).then((stream) => {
                this.getInputDevices()

                // Calling getUserMedia will start the media track selected.
                // This is not desired as the user may get the impression the mic is in use.
                // Therefore, we want to avoid having tracks started when they're not needed.
                // We only wanted to get the input device list so we stop the tracks immediately.
                stream.getTracks().forEach(track => track.stop())
            }).catch(error => {
                // Handle error. Tell the user there's a a mic issue. You could also tell
                // your backend or raise an alert for the system admin to resolve this issue.
                console.log(error)
            })

            // on ready
            this.changePhoneStatus('Phone ready')
            this.is_ready = true
            this.loading_device = false

            this.checkForcedStatus()
        })

        this.device.on(WebrtcEvents.UNREGISTERED, () => {
            if (this.is_ready && !this.is_widget) {
                this.$notify({
                    duration: 10000,
                    title: 'Phone',
                    message: 'Whoops! You have lost connection with the carrier. Check your internet connection and try again.',
                    type: 'error',
                    showClose: true,
                    position: 'top-right'
                })
            }
            this.is_ready = false
            this.changePhoneStatus('Lost connection to carrier')
        })

        this.device.on(WebrtcEvents.ERROR, (error, call) => {
            // if this error is a token error re-establish it
            if (error.tokenError() || error.code === 'Canceled') {
                this.handleError(error)
                if ([31009, 31204, 31205].includes(error.code)) {
                    this.rebootPhone(true, true)
                } else {
                    this.rebootPhone()
                }
            } else {
                // non token related errors (non trivial errors)
                this.changePhoneStatus('Error (' + error.code + ')')
                this.handleError(error)

                if ([31003, 31005, 31009, 31201, 31000].includes(error.code)) {
                    this.is_ready = false
                    this.changePhoneStatus('Lost connection to carrier')
                    this.setOnCall(false)
                }
            }
        })

        this.device.on(WebrtcEvents.INCOMING, (call) => {
            console.log('Incoming Call', call.call_sid)
            this.stopNotificationAudio()
            if (this.agent_status === AgentStatus.AGENT_STATUS_SENTRY) {
                call.accept()
                return
            }
            this.call_queue.push(call)
            this.getTheNextCall()
        })

        this.device.on(WebrtcEvents.TOKEN_WILL_EXPIRE, () => {
            console.log('Token will expire')
            this.rebootPhone(true, true)
        })
        // get parked call
        this.getParkedCall()

        // set loading to true
        this.loading = true
    },

    mounted() {
        if (!this.is_widget) {
            this.resizeHandler()
            this.dragElement()
        }
    },

    methods: {
        showDialer() {
            return this.agent_status !== AgentStatus.AGENT_STATUS_ON_CALL || (this.is_widget && this.on_call) || !this.is_widget
        },

        showAlertAgentOnCall() {
            return this.is_widget && this.agent_status === AgentStatus.AGENT_STATUS_ON_CALL
        },

        handleError(error) {
            this.last_error_code = error.code
            let err = new Error(error.message + " Code: " + error.code)
            err.code = error.code
            // Handled errors
            // 31000 => General Twilio Client error.
            // 31003 => Connection timeout.
            // 31005 => WebSocket connection to Twilio's signaling servers were unexpectedly ended. If this is happening consistently,
            // there may be an issue resolving the hostname provided. If a region is being specified in Device setup, ensure it's a valid region.
            // 31009 => No transport available to send or receive messages.
            // 31201 => Generic unknown error.
            // 31204 => Invalid JWT token.
            // 31205 => JWT token expired.
            // 9221 => Cannot connect to insights
            if (![31000, 31003, 31005, 31009, 31201, 31204, 31205, 9221].includes(err.code)) {
                Sentry.captureException(err)
            }
            console.log(error)
            this.is_ready = false
        },

        initCallEvents() {
            if (!this.call) {
                return
            }

            this.call.on(WebrtcEvents.CALL_WARNING, (warning_name, warning_data) => {
                console.log(WebrtcEvents.CALL_WARNING, warning_name, warning_data)
                // add warning to list
                if (this.warnings.indexOf(warning_name) === -1) {
                    this.warnings.push(warning_name)
                }
            })
            this.call.on(WebrtcEvents.CALL_WARNING_CLEARED, (warning_name) => {
                console.log(WebrtcEvents.CALL_WARNING_CLEARED, warning_name)
                // remove warning from list
                this.warnings = this.warnings.filter(value => value != warning_name)
            })
            this.call.on(WebrtcEvents.CALL_MUTE, (isMuted) => {
                this.muted = isMuted
            })
            this.call.on(WebrtcEvents.CALL_CANCEL, () => {
                // Note: this method gets triggered when an inbound call is accepted from another tab
                // and this changes the agent status from all other tabs that did not accept the call

                const call = this.call
                console.log('Call missed', call.call_sid)
                this.closeNotification(this.communication)
                this.removeTheCallFromQueue(call)
                this.changePhoneStatus('Call missed')
                this.backToQueue(true)
            })

            this.call.on(WebrtcEvents.CALL_ACCEPT, (call) => {
                // On accept call
                console.log('Call Connected', call.call_sid)
                // init call + events
                if (!this.call || call.call_sid !== this.call.call_sid) {
                    this.call = call
                }
                this.storeStatusBeforeOnCall(this.agent_status)
                this.getCommunication(call.call_sid, call.call_from)
                this.setOnCall(true)
                sessionStorage.setItem('on_call', 'true')
                this.closeNotification(this.communication)
                this.startCallTimer()
                this.changePhoneStatus('Connected')
                this.$emit('callConnected')
                this.enable_hangup = false
                this.show_dialer_info = false
                this.show_dialpad = false
                this.show_transfer = false
                this.show_add = false
                this.show_notes = false
                this.communication_notes = ''

                // mute the phone
                if (this.muted || sessionStorage.getItem('barge & whisper') === 'true') {
                    this.forceMute()
                }

                setTimeout(() => {
                    // get communication information one more time
                    this.getCommunication(call.call_sid, call.call_from, 1, true)
                    this.enable_hangup = true
                }, 3000)
            })

            this.call.on(WebrtcEvents.CALL_DISCONNECT, (call) => { // On hangup
                if (call && call.call_sid !== undefined) {
                    console.log('Call ended', call.call_sid)
                    // init call + events
                    if (!this.call || call.call_sid != this.call.call_sid) {
                        this.call = call
                    }
                    this.closeNotification(this.communication)
                    this.removeTheCallFromQueue(this.call)
                    this.muted = false
                    sessionStorage.removeItem('barge & whisper')
                    this.recording_status = 'in-progress'
                    this.loading_toggle_recording_status = false
                    this.changePhoneStatus('Call ended')
                    this.stopCallTimer()
                    this.$emit('callCompleted')

                    // only show wrap-up and wrap-up timer if we have a communication
                    if (this.communication) {
                        this.storeStatusBeforeOnCall(this.agent_status)
                        this.setOnCall(true)
                        sessionStorage.setItem('on_call', 'true')
                        this.show_dialer_info = false
                        this.show_dialpad = true
                        this.show_transfer = false
                        this.show_add = false
                        this.show_notes = false
                        this.startWrapUpTimer()
                    } else {
                        this.backToQueue()
                    }
                }

                if (!call || call.call_sid === undefined) {
                    this.$emit('callCompleted')
                    console.log('Call failed and connection is undefined or null', call)
                    try {
                        let stack = this.call_logs
                        if (stack && stack.length > 10) {
                            stack = stack.slice(-10)
                        }
                        throw new Error(stack.join(' => '))
                    } catch (err) {
                        Sentry.captureException(err)
                    }

                    this.backToQueue()
                }
            })
        },

        forceRefreshCommunication() {
            if (!this.call) {
                return
            }

            return this.getCommunication(this.call.call_sid, this.call.call_from, 1, true)
        },

        findDefaultOutboundCampaign() {
            // sanity check: in PD, skip assigning outbound campaign id if there's a selected one
            if (this.is_power_dialer && this.outbound_campaign_id) {
                return
            }

            this.outbound_campaign_id = null

            // 1. [Account Level] Default PD outbound line is set
            if (this.is_power_dialer && this.current_company && this.current_company.default_power_dialer_campaign_id) {
                this.outbound_campaign_id = this.current_company.default_power_dialer_campaign_id
                return
            }

            // 2. [Account level] force outbound line on all users
            if (this.current_company && this.current_company.force_outbound_line) {
                this.outbound_campaign_id = this.current_company.default_outbound_campaign_id
                return
            }

            // 3. [User level] Outbound line is set to follow account default
            if (this.current_company && this.auth.user.profile.outbound_calling_mode == UserOutboundCallingModes.OUTBOUND_CALLING_MODE_DEFAULT && !this.auth.user.profile.default_outbound_campaign_id) {
                this.outbound_campaign_id = this.current_company.default_outbound_campaign_id
                return
            }

            // 4. [User level] user has a default outbound line
            if (this.auth.user.profile.default_outbound_campaign_id && this.auth.user.profile.outbound_calling_mode == UserOutboundCallingModes.OUTBOUND_CALLING_MODE_DEFAULT) {
                this.outbound_campaign_id = this.auth.user.profile.default_outbound_campaign_id
            }

            // 4. We couldn't find anything
        },

        getScripts() {
            this.scripts = []
            this.loading_script = true
            axios.get('/api/v1/communication/' + this.communication.id + '/scripts').then((res) => {
                this.loading_script = false
                this.scripts = res.data
            }).catch((err) => {
                this.loading_script = false
                this.$root.handleErrors(err.response)
            })
        },

        getVmDropFiles() {
            this.voicemail_drop_files = []
            this.loading_vm_drop = true
            axios.get('/api/v1/voicemail-drop', {
                params: {
                    user_id: this.auth.user.profile.id
                }
            }).then((res) => {
                this.voicemail_drop_files = res.data
            }).catch((err) => {
                this.$root.handleErrors(err.response)
            }).then(_ => {
                this.loading_vm_drop = false
            })
        },

        updateCommunication(data) {
            if (this.parked_call && this.parked_call.id == data.id) {
                data = _.merge(this.parked_call, data)
                this.parked_call = data
                if (this.parked_call.disposition_status2 == CommunicationDispositionStatus.DISPOSITION_STATUS_COMPLETED_NEW || this.parked_call.current_status2 !== CommunicationCurrentStatus.CURRENT_STATUS_HOLD_NEW) {
                    this.getParkedCall()
                }
                return false
            }

            // checks if communication is the same with what we have
            if (!this.communication || (this.communication && data.id != this.communication.id)) {
                return false
            }

            if (!this.call) {
                return false
            }

            // update communication
            data = _.merge(this.communication, data)
            this.communication = data

            // hangup the communication after cold transfer
            if (this.current_company && this.current_company.conferencing_enabled && this.communication.in_cold_transfer && this.communication.current_status2 == CommunicationCurrentStatus.CURRENT_STATUS_INPROGRESS_NEW && this.communication.legc_uuid && this.communication.legc_status == CommunicationStatus.STATUS_INPROGRESS_NEW) {
                this.hangup()
            }
        },

        toggleRecordingStatus() {
            const new_status = (this.recording_status === 'in-progress') ? 'paused' : 'in-progress'
            this.loading_toggle_recording_status = true
            axios.post('/api/v1/communication/' + this.communication.id + '/toggle-recording-status', {
                status: new_status
            }).then((res) => {
                this.loading_toggle_recording_status = false
                if (res.data.result) {
                    this.recording_status = new_status
                    if (!this.is_widget) {
                        this.$notify({
                            duration: 2000,
                            title: 'Phone',
                            message: 'Recording status changed.',
                            type: 'success',
                            showClose: true,
                            position: 'bottom-right'
                        })
                    }
                } else {
                    if (!this.is_widget) {
                        this.$notify({
                            duration: 2000,
                            title: 'Phone',
                            message: 'Whoops! Failed to change recording status.',
                            type: 'error',
                            showClose: true,
                            position: 'bottom-right'
                        })
                    }
                }
            }).catch((err) => {
                this.loading_toggle_recording_status = false
                this.$root.handleErrors(err.response)
            })
        },

        toggleVmDrop() {
            if (this.selected_user_vm_drop || this.selected_user_vm_drop_data) {
                this.resetVmDrop()
            }
            this.showVmDropPopup()
        },

        async showVmDropPopup() {
            await this.getVmDropFiles()
        },

        resetVmDrop() {
            this.selected_user_vm_drop = null
            this.selected_user_vm_drop_data = null
        },

        showScript(script) {
            this.selected_script = script
        },

        hideScript() {
            this.selected_script = null
        },

        parkCall(end_wrap_up = false) {
            if (this.communication) {
                this.loading_park = true
                this.parked_call = this.communication
                let params = {
                    communication_id: this.communication.id,
                }

                axios.post('/api/v1/dialer/park', params).then(res => {
                    this.loading_park = false
                    this.$notify({
                        duration: 2000,
                        title: 'Phone',
                        message: 'Contact pushed to hold.',
                        type: 'success',
                        showClose: true,
                        position: 'bottom-right'
                    })

                    // end wrap up
                    if (end_wrap_up) {
                        this.backToQueue()
                    }
                }).catch(err => {
                    this.loading_park = false
                    this.getParkedCall()
                    this.$root.handleErrors(err.response)
                    console.log(err)
                })
            }
        },

        unparkCall() {
            if (this.parked_call) {
                this.loading_park = true
                this.makeCall("unhold:" + this.parked_call.id)
                this.loading_park = false
                this.recording_status = 'paused'
                this.$notify({
                    duration: 2000,
                    title: 'Phone',
                    message: 'Unparking call.',
                    type: 'success',
                    showClose: true,
                    position: 'bottom-right'
                })

                this.communication = this.parked_call
                this.getContactById(this.communication.contact_id)
                this.getParkedCall()
            }
        },

        toggleTransfer() {
            this.show_transfer = !this.show_transfer
            this.preValidateTransferForm()
        },

        toggleAdd() {
            this.show_add = !this.show_add
            this.preValidateAddForm()
        },

        toggleNotes() {
            this.show_notes = !this.show_notes
        },

        toggleDialerInfo() {
            this.show_dialer_info = !this.show_dialer_info
            this.toggleDialpad()
            this.setFocus()
        },

        toggleDialpad() {
            console.log('toggling dial pad')
            this.show_dialpad = !this.show_dialpad
        },

        toggleHelp() {
            this.show_help = !this.show_help
        },

        transferCall() {
            if (this.communication) {
                this.loading_transfer = true
                let params = {
                    communication_id: this.communication.id,
                    user_id: null,
                    ring_group_id: null,
                    phone_number: null,
                    type: 'cold'
                }

                if (this.transfer.mode == 'user') {
                    params.user_id = this.transfer.user_id
                }

                if (this.transfer.mode == 'ring_group') {
                    params.ring_group_id = this.transfer.ring_group_id
                }

                if (this.transfer.mode == 'phone') {
                    params.phone_number = this.$options.filters.fixPhone(this.transfer.phone_number, 'E164', true, true)
                }

                axios.post('/api/v1/dialer/conferencing-transfer', params).then(res => {
                    this.loading_transfer = false
                    this.resetTransfer()
                    this.show_transfer = false
                    this.$notify({
                        duration: 2000,
                        title: 'Phone',
                        message: 'Transfer is in progress.',
                        type: 'success',
                        showClose: true,
                        position: 'bottom-right'
                    })
                }).catch(err => {
                    this.loading_transfer = false
                    this.$root.handleErrors(err.response)
                    this.resetTransfer()
                    console.log(err)
                })
            }
        },

        resetTransfer(mode = 'user') {
            this.transfer.mode = mode
            this.transfer.phone_number = null
            this.transfer.ring_group_id = null
            this.transfer.user_id = null
            this.transfer_validated = false
        },

        goToContact() {
            if (!this.communication || !this.communication.contact) {
                return
            }

            if (this.is_widget) {
                let routeData = this.$router.resolve({
                    name: 'Contact',
                    params: {
                        contact_id: this.communication.contact_id
                    }
                })
                window.open(routeData.href, '_blank')
            } else {
                this.$router.push({
                    name: 'Contact',
                    params: {
                        contact_id: this.communication.contact_id
                    }
                }).catch(err => {
                })
            }
        },

        addCall(introduce = false) {
            if (this.communication) {
                this.loading_add = true
                let params = {
                    communication_id: this.communication.id,
                    introduce: introduce,
                    user_id: null,
                    phone_number: null,
                    type: 'warm'
                }

                switch (this.add.mode) {
                    case 'user':
                        params.user_id = this.add.user_id
                        let user = this.getUser(this.add.user_id)
                        if (user) {
                            this.added_party = this.$options.filters.fixName(user.name)
                        }
                        break
                    case 'ring_group':
                        params.ring_group_id = this.add.ring_group_id
                        let ring_group = this.getRingGroup(this.add.ring_group_id)
                        if (ring_group) {
                            this.added_party = ring_group.name + ' (Ring Group)'
                        }
                        break
                    case 'phone':
                        params.phone_number = this.$options.filters.fixPhone(this.add.phone_number, 'E164', true, true)
                        this.added_party = this.$options.filters.fixPhone(this.add.phone_number, 'NATIONAL', true, true)
                        break
                }

                axios.post('/api/v1/dialer/conferencing-transfer', params).then(res => {
                    this.loading_add = false
                    this.resetAdd()
                    this.show_add = false
                    this.show_added_party = true
                    this.should_introduce = introduce
                    this.$notify({
                        duration: 2000,
                        title: 'Phone',
                        message: (introduce) ? 'Introduce is in progress.' : 'Add is in progress.',
                        type: 'success',
                        showClose: true,
                        position: 'bottom-right'
                    })
                }).catch(err => {
                    this.show_added_party = false
                    this.loading_add = false
                    this.$root.handleErrors(err.response)
                    this.resetAdd()
                    console.log(err)
                })
            }
        },

        getRingGroup(id) {
            const ring_groups = _.get(this, 'ring_groups', null)

            if (!id || !ring_groups) {
                return null
            }

            let found = ring_groups.find(ring_group => ring_group.id === id)

            if (found) {
                return found
            }

            return null
        },

        resetAdd() {
            this.add.mode = 'user'
            this.add.phone_number = null
            this.add.user_id = null
            this.add.ring_group_id = null
            this.add_validated = false
        },

        mergeCalls() {
            if (this.communication) {
                this.loading_merge = true
                let params = {
                    communication_id: this.communication.id,
                }

                axios.post('/api/v1/dialer/merge-calls', params).then(res => {
                    this.loading_merge = false
                    this.should_introduce = false
                    this.$notify({
                        duration: 2000,
                        title: 'Phone',
                        message: 'Merge successful.',
                        type: 'success',
                        showClose: true,
                        position: 'bottom-right'
                    })
                }).catch(err => {
                    this.loading_merge = false
                    this.$root.handleErrors(err.response)
                    console.log(err)
                })
            }
        },

        getAvailableUsers(is_transfer = true) {
            this.loading_users = true
            if (is_transfer) {
                this.resetTransfer()
            } else {
                this.resetAdd()
            }
            axios.get('/api/v1/dialer/available-users', {
                params: {
                    communication_id: this.communication.id,
                }
            }).then((res) => {
                this.available_users = res.data.eligible_users.filter((user) => user.id != auth.user.profile.id)
                this.unavailable_users = res.data.ineligible_users.filter((user) => user.id != auth.user.profile.id)
                this.loading_users = false
            }).catch(err => {
                this.loading_users = false
                this.$root.handleErrors(err.response)
                console.log(err)
            })
        },

        getAvailableRingGroups(is_transfer = true) {
            this.loading_ring_groups = true
            if (is_transfer) {
                this.resetTransfer()
            } else {
                this.resetAdd()
            }
            axios.get('/api/v1/dialer/available-ring-groups', {
                params: {
                    communication_id: this.communication.id,
                }
            }).then((res) => {
                this.available_ring_groups = res.data
                this.loading_ring_groups = false
            }).catch(err => {
                this.loading_ring_groups = false
                this.$root.handleErrors(err.response)
                console.log(err)
            })
        },

        callBack(phone_number) {
            this.backToQueue()
            setTimeout(() => {
                this.form.current_number = this.$options.filters.fixPhone(phone_number)
                this.openPhone()
                this.customPreValidateForm()
                setTimeout(this.clickToCall, 50)
            }, 500)
        },

        backToQueue(cancel = false) {
            // save the notes after the wrap up if the user forgot to save it
            if (this.$refs.communication_info && this.$refs.communication_info.$refs.communication_notes && this.$refs.communication_info.$refs.communication_notes.hasUnsavedChanges) {
                this.$refs.communication_info.$refs.communication_notes.saveNote()
            }

            this.stopWrapUpTimer()
            this.on_wrap_up = false

            // prevent changing agent status during call acceptance/on live call from another tab
            if ((cancel && !this.on_call && sessionStorage.getItem('on_call') == 'false') || !cancel) {
                if (this.old_agent_status !== AgentStatus.AGENT_STATUS_RINGING && this.agent_status !== AgentStatus.AGENT_STATUS_RINGING) {
                    if (this.on_call || sessionStorage.getItem('on_call') == 'true') {
                        this.setOnCall(false)
                        sessionStorage.setItem('on_call', 'false')
                    }
                    this.backToPreviousStatus()
                }
                VueEvent.fire('call_ended')
            }

            // get parked call
            this.getParkedCall()

            // reboot phone
            this.rebootPhone()
        },

        backToPreviousStatus(signature) {
            let agent_status
            let agent_status_to_compare = this.old_agent_status
            if (
                this.agent_status_before_on_call !== null &&
                this.old_agent_status !== this.agent_status_before_on_call &&
                (this.agent_status !== 4 || this.agent_status !== 5)
            )
            {
                agent_status_to_compare = this.agent_status_before_on_call
            }
            switch (agent_status_to_compare) {
                case AgentStatus.AGENT_STATUS_OFFLINE:
                    agent_status = AgentStatus.AGENT_STATUS_OFFLINE
                    break
                case AgentStatus.AGENT_STATUS_ON_BREAK:
                    agent_status = AgentStatus.AGENT_STATUS_ON_BREAK
                    break
                case AgentStatus.AGENT_STATUS_NOT_ACCEPTING_CALLS:
                    agent_status = AgentStatus.AGENT_STATUS_NOT_ACCEPTING_CALLS
                    break
                default:
                    agent_status = AgentStatus.AGENT_STATUS_ACCEPTING_CALLS
                    break
            }

            this.agent_status_logs.push(agent_status)

            console.log('old agent status: ' + this.old_agent_status)
            console.log('current agent status: ' + this.agent_status)
            console.log('new agent status: ' + agent_status)
            console.log('agent status before on call: ' + this.agent_status_before_on_call)

            if (!this.on_call) this.agent_status_before_on_call = null
            if(this.current_company.id === 7 || this.current_company.id === 2287) {
                console.table(this.agent_status_logs)
                console.table(this.call_logs)
            }

            // check status
            this.changeAgentStatus(agent_status, false, 1, false, signature)
        },

        rebootPhone(login = false, reinitialize = false) {
            this.changePhoneStatus('Restarting ...')
            this.permanently_failed_to_login = false
            this.warnings = []
            this.contact_local_time = null
            this.communication = null
            this.contact = null
            this.call = null
            this.muted = false
            this.on_wrap_up = false
            this.form.current_number = ''
            this.show_dialer_info = false
            this.show_dialpad = true
            this.show_transfer = false
            this.show_add = false
            this.show_notes = false
            this.number_validated = false
            this.should_introduce = false
            this.added_party = null
            this.show_added_party = false
            this.communication_notes = ''
            this.is_held = false
            this.is_power_dialer = false

            // reinitialize if needed
            if (reinitialize) {
                this.is_ready = false
                this.device.destroy()
                this.setOnCall(false)
            }

            this.startPhone(login, reinitialize)
        },

        changeAgentStatus: _.debounce(function (val, manual, change_agent_status_try = 1, is_from_dropdown = false, signature = 'Unknown') {
            if (this.on_call && sessionStorage.getItem('on_call') == 'false' && !this.call) {
                return
            }

            // do not go to offline when we have either go_to_available_after_login or isCompanyForcedAvailable unless it's a manual effort
            if ((this.auth.user.profile.go_to_available_after_login || this.isCompanyForcedAvailable) && !manual && val === AgentStatus.AGENT_STATUS_OFFLINE) {
                console.log('prevented the app from changing agent status to offline')
                return
            }

            if (this.on_call && sessionStorage.getItem('on_call') == 'true' && ![AgentStatus.AGENT_STATUS_RINGING, AgentStatus.AGENT_STATUS_ON_CALL, AgentStatus.AGENT_STATUS_ON_WRAP_UP].includes(val)) {
                this.setOnCall(false)
                sessionStorage.setItem('on_call', 'false')
            }

            // if agent status is on AGENT_STATUS_OFFLINE, AGENT_STATUS_ACCEPTING_CALLS, AGENT_STATUS_NOT_ACCEPTING_CALLS, AGENT_STATUS_ON_BREAK, AGENT_STATUS_AUTO_DIAL, AGENT_STATUS_SENTRY
            if (![AgentStatus.AGENT_STATUS_ON_WRAP_UP, AgentStatus.AGENT_STATUS_ON_CALL, AgentStatus.AGENT_STATUS_RINGING].includes(val)) {
                this.changeOldAgentStatus(val)
            }

            console.log('Changing agent status: ' + val)

            // If the new agent status is on-call store the old agent status
            if (val === AgentStatus.AGENT_STATUS_ON_CALL) {
                this.storeStatusBeforeOnCall(this.agent_status)
            }

            // stop wrap up timer if the user changes agent status other than on wrap up
            if (this.agent_status !== AgentStatus.AGENT_STATUS_ON_WRAP_UP) {
                this.stopWrapUpTimer()
            }

            // make sure that the session is valid
            if (this.auth && this.auth.user && this.auth.user.profile && this.auth.user.profile.id) {
                this.loading = true
                axios.post('/api/v1/user/' + this.auth.user.profile.id + '/agent-status', {
                    agent_status: val
                }, {
                  headers: {
                    'Signature': signature,
                  }
                }).then(res => {
                    this.loading = false
                    VueEvent.fire('user_updated', res.data)
                    this.agent_status = res.data.agent_status
                    if (this.on_wrap_up === true && this.agent_status !== AgentStatus.AGENT_STATUS_ON_WRAP_UP) {
                        this.rebootPhone()
                    }
                }).catch(err => {
                    change_agent_status_try++
                    // check if we have found the communication after 3 retries
                    if (change_agent_status_try > 3) {
                        // error
                        console.log("An error occurred while changing agent status", err)
                        this.loading = false
                        this.$root.handleErrors(err.response)
                        if (this.on_wrap_up) {
                            this.rebootPhone()
                        }
                    } else {
                        if (err.response.status === 400) {
                            return
                        }

                        this.changeAgentStatus(val, manual, change_agent_status_try, is_from_dropdown, signature)
                    }
                })
            }
        }, 500),

        storeStatusBeforeOnCall(currentStatus) {
            if (
                currentStatus !== AgentStatus.AGENT_STATUS_ON_CALL ||
                currentStatus !== AgentStatus.AGENT_STATUS_ON_WRAP_UP ||
                currentStatus !== AgentStatus.AGENT_STATUS_RINGING ||
                currentStatus !== AgentStatus.AGENT_STATUS_SENTRY
            ) {
                console.log('Store status before call: ', currentStatus)
                this.agent_status_before_on_call = currentStatus
            }
        },

        getContactById(contact_id, get_contact_try = 1) {
            if (this.contact || !contact_id) {
                this.loading_contact = false
                return
            }
            this.loading_contact = true
            axios.get('/api/v1/contact/' + contact_id + '/info').then(res => {
                if (this.contact) {
                    return
                }
                this.contact = res.data
                this.loading_contact = false
            }).catch(err => {
                get_contact_try++
                // check if we have found the contact after 3 retries
                if (get_contact_try > 3) {
                    // error
                    console.log("An error occurred while getting the contact", err)
                    this.contact = null
                    this.loading_contact = false
                } else {
                    this.getContactById(contact_id, get_contact_try)
                }
            })
        },

        getCommunication(sid, from, get_communication_try = 1, force = false) {
            if (this.communication && !force) {
                this.loading_communication = false
                return
            }
            this.call_activename = []
            this.loading_communication = true
            axios.get('/api/v1/communication/info', {
                params: {
                    sid: sid,
                    phone_number: from,
                    live: true
                }
            }).then(res => {
                // don't run this when the connection is lost or when you already have the communication
                if (!this.call) {
                    this.loading_communication = false
                    return
                }

                this.communication = res.data
                this.getContactById(this.communication.contact_id)

                // vm_drop lists
                this.getVmDropFiles()

                // line scripts
                this.getScripts()
                // rest of comm data :)
                this.form.current_number = this.$options.filters.fixPhone(this.communication.lead_number)

                // update phone status
                if (this.agent_status !== AgentStatus.AGENT_STATUS_SENTRY) {
                    let campaign = this.getCampaign(this.communication.campaign_id)
                    let campaign_name = this.$options.filters.fixName(campaign?.name)
                    if (this.call_duration_timer_enabled) {
                        this.changePhoneStatus(campaign_name)
                    } else {
                        if (this.hasCRMIntegration(res.data)) {
                            this.call_activename.push('integrations')
                            this.call_activename.push('integration-cards')
                        }
                        if (this.communication.callback_status === CallbackStatus.CALLBACK_STATUS_INITIATED) {
                            this.changePhoneStatus('Callback Request')
                        } else {
                            this.changePhoneStatus('Incoming Call')
                        }
                    }
                } else {
                    this.changePhoneStatus('SENTRY MODE')
                }

                this.loading_communication = false
            }).catch(err => {
                // error
                console.log("An error occurred while getting the communication", err)

                // Fail if the call failed with a 4xx error
                if (err.response && err.response.status >= 400 && err.response.status < 500) {
                    this.communication = null
                    this.loading_communication = false
                    return
                }

                get_communication_try++
                // check if we have found the communication after 3 retries
                if (get_communication_try > 4) {
                    this.communication = null
                    this.loading_communication = false
                } else {
                    this.getCommunication(sid, from, get_communication_try, force)
                }
            })
        },

        getContactByPhoneNumber(phone_number, get_contact_try = 1) {
            if (this.contact || !phone_number) {
                this.loading_contact = false
                return
            }
            this.findDefaultOutboundCampaign()
            this.initial_campaign_id = null
            this.loading_contact = true
            axios.get('/api/v1/contact/phone-number', {
                params: {
                    phone_number: phone_number,
                    load_info: 0
                }
            }).then(res => {
                this.contact = res.data
                this.findContactInitialLine()
                this.loading_contact = false
            }).catch(err => {
                get_contact_try++
                // error
                console.log("An error occurred while getting the contact", err)
                // check if we have found the contact after 3 retries
                if (get_contact_try > 3) {
                    this.contact = null
                    this.loading_contact = false
                } else {
                    this.getContactByPhoneNumber(phone_number, get_contact_try)
                }
            })
        },

        searchContact: _.debounce(function () {
            this.contact = null
            if (this.$options.filters.fixPhone(this.form.current_number)) {
                this.getContactByPhoneNumber(this.form.current_number)
            }
        }, 100),

        startPhone(login = true, refreshToken = false, startTry = 1) {
            this.is_ready = false
            this.form.current_number = ''
            if (login) {
                this.loading = true
                this.loading_device = true
                this.changePhoneStatus('Logging in ...')
                // make sure that the session is valid
                if (this.auth && this.auth.user && this.auth.user.profile && this.auth.user.profile.id) {
                    axios.post('/api/v1/dialer/new-mobile-token', {
                        reset: refreshToken,
                        is_widget: this.is_widget
                    }).then(res => {
                        this.loading = false
                        this.token = res.data
                        console.log('Token: ' + this.token)
                        // setup twilio client

                        this.logHistory('Logged in')
                        this.changePhoneStatus('Initiating phone ...')

                        /**
                         * An ordered list of preferred codecs. Currently, 'pcmu' and 'opus' are supported.
                         * PCMU will remain default until the next breaking release,
                         * however we recommend testing and using Opus as it can provide better quality for lower bandwidth,
                         * particularly noticeable in poor network conditions.
                         */
                        this.device.initialize(this.token, {
                            edge: ['umatilla', 'ashburn', 'roaming'],
                            codecPreferences: ['opus', 'pcmu'],
                            enableIceRestart: true
                        })
                    }).catch(err => {
                        this.loading = false
                        this.changePhoneStatus('Failed to login ...')
                        Sentry.captureException(err)
                        console.log(err)
                    })
                } else {
                    let err = new Error('Failed to authenticate user. try: ' + startTry)
                    err.code = 2
                    console.log(err)

                    // try 3 times before giving up
                    if (startTry > 3) {
                        this.permanently_failed_to_login = true
                        Sentry.captureException(err)
                        this.changePhoneStatus('Failed to login ...')
                        return false
                    }

                    // check auth again and try to start phone after a successful check
                    auth.check().then(() => {
                        startTry += 1
                        this.startPhone(login, refreshToken, startTry)
                    }).catch((err) => {
                        this.$router.push({name: 'Login', query: {redirect: '/dashboard'}})
                    })
                }
            } else {
                this.changePhoneStatus('Phone ready')
                this.is_ready = true
            }

            this.setFocus()
            this.getTheNextCall()
        },

        getTheNextCall() {
            if (!this.call && this.call_queue && this.call_queue.length > 0) {
                // init call + events
                if (!this.call || this.call_queue[0].call_sid !== this.call.call_sid) {
                    this.call = this.call_queue[0]
                    this.initCallEvents()
                }
                console.log("Getting the next call", this.call_queue[0].call_sid)
                this.getCommunication(this.call_queue[0].call_sid, this.call_queue[0].call_from, 1, true)
                this.openPhone()
                // On inbound (simulates ringing)
                window.call_sid = this.call.call_sid
                // this.form.current_number = this.$options.filters.fixPhone(this.call.call_from)
                // the agent now has to take the call by pressing the button.
            }
        },

        removeTheCallFromQueue(call) {
            console.log("Removing call from queue", call.call_sid)
            this.call_queue = this.call_queue.filter(obj => {
                return obj.call_sid !== call.call_sid
            })
            if (this.call_queue && this.call_queue.length > 0) {
                console.log("Calls in queue", this.call_queue)
            }
        },

        countCallDuration() {
            this.call_duration += 1
            this.call_timer = this.secondsToHms(this.call_duration)
        },

        secondsToHms(d) {
            d = Number(d)
            let h = Math.floor(d / 3600)
            let m = Math.floor(d % 3600 / 60)
            let s = Math.floor(d % 3600 % 60)
            return ((h > 0 ? h + ':' + (m < 10 ? '0' : '') : '') + m + ':' + (s < 10 ? '0' : '') + s)
        },

        changePhoneStatus(status) {
            console.log('Phone status: ' + status)
            this.phone_status = status
            this.logHistory(status)
        },

        logHistory(history) {
            this.call_logs.push(history)
        },

        answer() {
            this.is_answering = true
            setTimeout(() => {
                this.is_answering = false
            }, 2000)
            this.changePhoneStatus('Call accepted')
            if (!this.isReceivingCall) {
                this.makeCall(this.form.current_number)
            } else {
                this.takeCall()
            }
        },

        hangup() {
            this.changePhoneStatus('Call ended')

            // terminate the phone
            this.terminate()
        },

        reject() {
            this.changePhoneStatus('Call rejected')
            this.closeNotification(this.communication)
            this.removeTheCallFromQueue(this.call)

            // terminate the phone
            this.terminate(true)
        },

        terminate(reject = false) {
            console.log((reject ? 'Rejecting' : 'Hanging up') + ' call.')
            if (this.activeCall && reject === true) {
                // rejecting an incoming call
                // reject it
                this.activeCall.reject()
            }

            if (this.call && reject === false) {
                // hangup an incoming call
                // hang it up
                this.call.hangup()
            }

            if (reject) {
                // set agent status to busy if it's an answer by browser/apps user
                // @custom for Cardone Capital: rejecting a call should still keep the agent on the previous status
                if (this.current_company && this.current_company.id !== 892 && !this.isCompanyForcedAvailable && !this.isCompanyForcedAvailable) {
                    this.old_agent_status = AgentStatus.AGENT_STATUS_NOT_ACCEPTING_CALLS
                }
                this.backToQueue()
            }
        },

        preValidateTransferForm() {
            this.customPreValidate('transfer_form', 'transfer_validated')
        },

        preValidateAddForm() {
            this.customPreValidate('add_form', 'add_validated')
        },

        customPreValidate: _.debounce(function (form_name, validated_name) {
            let form_element = _.get(this.$refs, form_name, null)

            if (!form_element) {
                return
            }

            let fields = form_element.fields
            if (fields.find((f) => f.validateState === 'validating')) {
                setTimeout(() => {
                    this.customPreValidate(form_name)
                }, 100)

                return
            }

            if (this.validateForm(form_name)) {
                this.validated = true
                this[validated_name] = this.validated
                return
            }

            this.validated = false
            this[validated_name] = this.validated
        }, 100),

        customPreValidateForm() {
            this.searchContact()
            this.number_validated = this.validateForm('form')
            return this.number_validated
        },

        validateAndCall() {
            this.customPreValidateForm()
            setTimeout(() => {
                if (this.number_validated) {
                    this.clickToCall()
                }
            }, 50)
        },

        async makeCall(phone_number) {
            // sanity check: check phone number is valid
            if (!this.$options.filters.fixPhone(phone_number)) {
                this.rebootPhone()
            }

            if (this.auth.user.profile.enabled_two_legged_outbound && this.contact) {
                let message = 'We will call your secondary phone'
                message += ' on ' + this.auth.user.profile.secondary_phone_number
                message += ` and connect you with ${this.contact.name}. Proceed?`
                return this.$confirm(message, 'Going old school?', {
                    confirmButtonText: 'OK',
                    cancelButtonText: 'Cancel',
                    customClass: 'width-500 fixed',
                    type: 'warning'
                }).then(() => {
                    this.makeTwoLeggedCall(phone_number)

                    this.changePhoneStatus('Phone ready')
                    this.is_ready = true
                    this.loading_device = false
                }).catch(() => {
                })

            }

            let params = {
                "To": phone_number,
                "CampaignId": this.outbound_campaign_id,
                "UserId": this.auth.user.profile.id
            }

            // init call
            try {
                this.call = await this.device.connect(params)

                // Make sure that phone number is string in this part before proceeding
                phone_number = phone_number.toString()
                // force mute
                if (phone_number.includes('barge') || phone_number.includes('whisper')) {
                    sessionStorage.setItem('barge & whisper', 'true')
                    this.forceMute()
                }

                this.initCallEvents()

                // find default outbound campaign
                this.findDefaultOutboundCampaign()

                // log it
                this.changePhoneStatus('Dialing out ...')

                // Store the agent status before on call
                this.storeStatusBeforeOnCall(this.agent_status)

                // set yourself on call
                this.setOnCall(true)
                sessionStorage.setItem('on_call', 'true')
            } catch (err) {
                Sentry.captureException(err)
                VueEvent.fire('new_call_failed', params)
            }
        },

        makeTwoLeggedCall(phone_number) {
            axios.post('/api/v1/contact/' + this.contact.id + '/make-two-legged-call', {
                phone_number: phone_number
            }).then(res => {
                this.$notify({
                    offset: 95,
                    title: 'Call Lead',
                    message: `We are calling your phone to connect you to ${this.contact.name}`,
                    type: 'success',
                    showClose: true
                })
            }).catch(err => {
                this.$root.handleErrors(err.response)
            })
        },

        takeCall() {
            if (this.activeCall) {
                // accept the incoming call and start two-way audio
                this.activeCall.accept()
            } else {
                this.rebootPhone()
            }
        },

        sendDigit(digit) {
            if (this.call) {
                this.call.sendDigits(digit)
            } else {
                if (this.on_call) {
                    return
                }
                if (digit == 'DEL' && this.form.current_number !== undefined && this.form.current_number.length > 0) {
                    this.form.current_number = this.form.current_number.slice(0, -1)
                }
                if (digit != 'DEL' && this.form.current_number !== undefined) {
                    this.form.current_number = this.form.current_number + digit
                }
            }

            this.customPreValidateForm()
            this.setFocus()
        },

        toggleMute() {
            this.muted = !this.muted
            this.call.mute(this.muted)
        },

        forceMute() {
            this.muted = true
            this.call.mute(true)
        },

        toggleHold() {
            if (this.is_held) {
                console.log('Unholding call')
                this.loading_unhold = true
                let params = {
                    communication_id: this.communication.id
                }
                axios.post('/api/v1/dialer/new-unhold', params).then(() => {
                    console.log('Call unheld')
                    this.is_held = false
                }).catch(err => {
                    console.log(err)
                }).finally(_ => {
                    this.loading_unhold = false
                })
            } else {
                console.log('Holding call')
                this.loading_hold = true
                let params = {
                    communication_id: this.communication.id
                }
                axios.post('/api/v1/dialer/new-hold', params).then(() => {
                    console.log('Call held')
                    this.is_held = true
                }).catch(err => {
                    console.log(err)
                }).finally(_ => {
                    this.loading_hold = false
                })
            }
        },

        toggleVisible() {
            if (this.on_call && sessionStorage.getItem('on_call') == 'false' && !this.call) {
                if (this.is_visible) {
                    console.log('[Toggle Visibility] We are closing phone since there is another phone instance open with a live call on it.')
                    this.closePhone()
                } else {
                    console.log('[Toggle Visibility] Phone is already closed and will stay closed since there is another phone instance open with a live call on it.')
                }
                return
            }

            if (!this.device._is_initialized) {
                this.startPhone()
            }

            if (this.is_visible) {
                return this.closePhone()
            }

            if (!this.is_visible) {
                return this.openPhone()
            }
        },

        openPhone() {
            this.is_visible = true
            this.setFocus()
        },

        closePhone() {
            if (!this.is_widget) {
                this.is_visible = false
            }
        },

        initDevice() {
            if (this.on_call && sessionStorage.getItem('on_call') == 'false' && !this.call) {
                return
            }

            if (!this.device._is_initialized) {
                this.startPhone()
            }
        },

        makeVisible(agent_status) {
            this.initDevice()
            if (agent_status) {
                // if agent status is on AGENT_STATUS_OFFLINE, AGENT_STATUS_ACCEPTING_CALLS, AGENT_STATUS_NOT_ACCEPTING_CALLS, AGENT_STATUS_ON_BREAK, AGENT_STATUS_AUTO_DIAL, AGENT_STATUS_SENTRY
                if (![AgentStatus.AGENT_STATUS_ON_WRAP_UP, AgentStatus.AGENT_STATUS_ON_CALL, AgentStatus.AGENT_STATUS_RINGING].includes(this.agent_status)) {
                    this.changeOldAgentStatus(this.agent_status)
                }

                this.agent_status = agent_status
                this.changeAgentStatus(this.agent_status, false, 1, false, 'Classic-MakeVisible')
            }
            this.openPhone()
        },

        setInputDevice() {
            this.device.setInputDevice(this.input_device).then(() => {
                console.info('Changed input device', this.input_device)
            }).catch(err => {
                console.info('Could not change the input device!', err)
            })
        },

        getInputDevices() {
            let input_devices = []
            if (!this.device) {
                return input_devices
            }

            let devices = this.device.availableInputDevices()
            if (devices) {
                devices.forEach(function (device, id) {
                    input_devices.push({
                        id: id,
                        label: device.label
                    })
                })
            }

            return input_devices
        },

        setOutputDevice() {
            this.device.setSpeakerDevice(this.output_device).then(() => {
                console.info('Changed output device', this.output_device)
            }).catch(err => {
                console.info('Could not change the output device!', err)
            })
        },

        getOutputDevices() {
            let output_devices = []
            if (!this.device) {
                return output_devices
            }

            let devices = this.device.availableOutputDevices()
            if (devices) {
                devices.forEach(function (device, id) {
                    output_devices.push({
                        id: id,
                        label: device.label
                    })
                })
            }

            return output_devices
        },

        testOutputDevice() {
            this.device.testSpeakerDevice().then(() => {
                console.info('Tested speaker device', this.output_device)
            }).catch(err => {
                console.info('Could not test the speaker device!', err)
            })
        },

        initializeSettings() {
            this.input_devices = this.getInputDevices()
            this.output_devices = this.getOutputDevices()
        },

        setFocus() {
            if (this.is_visible) {
                Vue.nextTick(() => {
                    if (this.$refs.current_number) {
                        this.$refs.current_number.focus()
                    }
                })
            }
        },

        closeNotification(communication) {
            if (communication) {
                Push.close('communication-notification-' + communication.id)
            }
        },

        startWrapUpTimer() {
            this.wrap_up_timer = this.current_company.force_wrap_up ? this.current_company.wrap_up_seconds : this.auth.user.profile.wrap_up_seconds

            // save notes when the call ends
            this.saveNotes()

            // if wrap up time is disabled account wide or if we are on sentry mode end wrap up
            if (this.wrap_up_timer < 0 || this.agent_status === AgentStatus.AGENT_STATUS_SENTRY) {
                this.backToQueue()
                return
            }
            this.on_wrap_up = true
            if (!this.wrapUpTimerEnabled) {
                return
            }
            if (!this.current_company || !this.auth.user || !this.auth.user.profile) {
                return
            }
            this.wrap_up_timer_interval = setInterval(() => {
                if (this.communication && this.current_company &&
                    ((this.current_company.force_call_disposition && !this.call_is_disposed && !this.communication.call_disposition_id) ||
                        (this.current_company.force_contact_disposition && !this.contact_is_disposed && this.communication.contact && !this.communication.contact.disposition_status_id))) {
                    return
                }
                this.wrap_up_timer--
                if (this.wrap_up_timer === 0) {
                    this.backToQueue()
                }
            }, 1000)
        },

        stopWrapUpTimer() {
            this.call_is_disposed = false
            this.contact_is_disposed = false
            if (!this.wrapUpTimerEnabled) {
                return
            }
            clearInterval(this.wrap_up_timer_interval)
        },

        clickToCall() {
            // find default outbound campaign
            this.findDefaultOutboundCampaign()

            if (!this.outbound_campaign_id) {
                const call_btn = this.$refs.call_btn
                if (call_btn) {
                    call_btn.click()
                }
            } else {
                this.answer()
            }
        },

        getCampaign(id) {
            if (!id) {
                return null
            }

            // reference the campaign object in communication if it has
            if (this.communication?.campaign) {
                return this.communication.campaign
            }

            let found = this.campaigns.find(campaign => campaign.id === id)
            if (found) {
                return found
            }

            return null
        },

        getLabel(user) {
            if (!user) {
                return
            }

            switch (user.answer_by) {
                case AnswerTypes.BY_PHONE_NUMBER:
                    return 'Phone Number (' + user.phone_number + ')'
                case AnswerTypes.BY_BROWSER:
                    return 'Browser / Apps'
                case AnswerTypes.BY_IP_PHONE:
                    return 'SIP (IP Phone)'
                case AnswerTypes.BY_NONE:
                    return 'Will Not Answer'
            }
        },

        filterOptions(filterValue) {
            this.filtered_text = filterValue
        },

        resetFilteredText() {
            this.filtered_text = null
        },

        getContactLocalTime() {
            if (this.contact && this.contact.timezone) {
                this.contact_local_time = moment.utc().tz(this.contact.timezone).format('h:mma')
            }
        },

        setVmDrop() {
            this.selected_user_vm_drop_data = this.voicemail_drop_files.find(vm => vm.id == this.selected_user_vm_drop)
        },

        playCustomPrerecordedVoicemailDrop(selected_user_vm_drop_id) {
            this.selected_user_vm_drop_data = this.voicemail_drop_files.find(vm => vm.id === selected_user_vm_drop_id)
            this.playPrerecordedVoicemailDrop()
        },

        playPrerecordedVoicemailDrop() {
            if (!this.selected_user_vm_drop_data) {
                return
            }

            let uuid = this.selected_user_vm_drop_data.uploaded_file.uuid
            let name = this.selected_user_vm_drop_data.name

            this.loading_play_prerecorded_voicemail = true
            axios.post('/api/v1/dialer/play-prerecorded-voicemail', {
                communication_id: this.communication.id,
                file_name: uuid,
                name: name
            }).then(res => {
                this.$notify({
                    duration: 2000,
                    title: 'Phone',
                    message: 'Your prerecorded voicemail has been played.',
                    type: 'success',
                    showClose: true,
                    position: 'bottom-right'
                })
            }).catch(err => {
                this.$root.handleErrors(err.response)
            }).then(_ => {
                this.resetVmDrop()
                this.loading_play_prerecorded_voicemail = false
            })
        },

        startCallTimer() {
            this.call_duration_timer_enabled = true
            this.$options.call_duration_interval = setInterval(this.countCallDuration, 1000)
        },

        stopCallTimer() {
            this.call_duration_timer_enabled = false
            this.call_duration = 0
            this.call_timer = ''
            clearInterval(this.$options.call_duration_interval)
        },

        dropThirdParty() {
            this.loading_drop_third_party = true
            axios.post('/api/v1/dialer/drop-third-party', {
                communication_id: this.communication.id
            }).then(res => {
                this.loading_drop_third_party = false
                this.should_introduce = false
                this.added_party = null
                this.show_added_party = false
                this.$notify({
                    duration: 2000,
                    title: 'Phone',
                    message: 'Third party has been dropped out of this call.',
                    type: 'success',
                    showClose: true,
                    position: 'bottom-right'
                })
            }).catch(err => {
                this.loading_drop_third_party = false
                this.$root.handleErrors(err.response)
            })
        },

        resizeHandler(e) {
            e = e || window.event
            this.$refs.dialer.style.top = 'auto'
            this.$refs.dialer.style.left = 'auto'
        },

        dragElement() {
            if (this.$refs.dialerHeader) {
                // if present, the header is where you move the DIV from:
                this.$refs.dialerHeader.onmousedown = this.dragMouseDown
            }
        },

        dragMouseDown(e) {
            e = e || window.event
            e.preventDefault()
            // get the mouse cursor position at startup:
            this.pos3 = e.clientX
            this.pos4 = e.clientY

            // store the current viewport and element dimensions when a drag starts
            this.rect = this.$refs.dialer.getBoundingClientRect()
            this.viewport.bottom = window.innerHeight
            this.viewport.left = this.padding
            this.viewport.right = window.innerWidth - this.padding
            this.viewport.top = this.padding

            document.onmouseup = this.closeDragElement
            // call a function whenever the cursor moves:
            document.onmousemove = this.elementDrag
        },

        elementDrag(e) {
            e = e || window.event
            e.preventDefault()
            // calculate the new cursor position:
            this.pos1 = this.pos3 - e.clientX
            this.pos2 = this.pos4 - e.clientY
            this.pos3 = e.clientX
            this.pos4 = e.clientY

            // check to make sure the element will be within our viewport boundary
            let newLeft = this.$refs.dialer.offsetLeft - this.pos1
            let newTop = this.$refs.dialer.offsetTop - this.pos2

            if (newLeft < this.viewport.left
                || newTop < this.viewport.top
                || newLeft + this.rect.width > this.viewport.right
                || newTop + this.rect.height > this.viewport.bottom
            ) {
                // the element will hit the boundary, do nothing...
            } else {
                // set the element's new position:
                this.$refs.dialer.style.top = (this.$refs.dialer.offsetTop - this.pos2) + "px"
                this.$refs.dialer.style.left = (this.$refs.dialer.offsetLeft - this.pos1) + "px"
            }
        },

        closeDragElement() {
            // stop moving when mouse button is released:
            document.onmouseup = null
            document.onmousemove = null
        },

        addContact(phone_number) {
            this.form.current_number = phone_number
            this.customPreValidateForm()
        },

        copyPhoneNumber(phone_number) {
            this.copyToClipboard(this.$options.filters.fixPhone(phone_number, 'NATIONAL'))
        },

        copyToClipboard(str) {
            const el = document.createElement('textarea')
            el.value = str
            el.setAttribute('readonly', '')
            el.style.position = 'absolute'
            el.style.left = '-9999px'
            document.body.appendChild(el)
            el.select()
            document.execCommand('copy')
            document.body.removeChild(el)
            this.copied = true
            setTimeout(() => {
                this.copied = false
            }, 1000)
        },

        findContactInitialLine() {
            let campaign = this.campaigns.find(campaign => campaign.id == this.contact.initial_campaign_id)
            if (!this.outbound_campaign_id) {
                this.initial_campaign_id = campaign.id
            }
        },

        callContact() {
            if (!this.outbound_campaign_id && this.initial_campaign_id) {
                this.outbound_campaign_id = this.initial_campaign_id
            }
            this.answer()
        },

        callDisposed() {
            this.call_is_disposed = true
        },

        contactDisposed() {
            this.contact_is_disposed = true
        },

        contactNotDisposed() {
            this.contact_is_disposed = false
        },

        hasCRMIntegration(communication) {
            if (!communication || !communication.contact) {
                return false
            }
            if (communication.contact.integration_data && Object.keys(communication.contact.integration_data).length) {
                return true
            }
            return false
        },

        updateContactInCommunication(contact) {
            if (this.communication && typeof this.communication.contact != 'undefined') {
                this.communication.contact.name = `${contact.first_name} ${contact.last_name}`
            }
        },

        saveNotes() {
            this.loading_notes = true
            axios.patch('/api/v1/communication/' + this.communication.id, {
                notes: this.communication_notes
            }).then(res => {
                this.loading_notes = false
                this.communication.notes = this.communication_notes
                this.loading_text = true
                setTimeout(() => {
                    this.loading_text = false
                }, 1000)
            }).catch(err => {
                this.$root.handleErrors(err.response)
                this.loading_notes = false
            }).then(() => {
                if (this.$refs.communication_notes) {
                    this.$refs.communication_notes.focus()
                }
            })
        },

        makeNewCall(data, start = true, from_hubspot = false) {
            let event_name = from_hubspot ? 'make_hs_new_call' : 'make_new_call'
            if (this.$root.loading) {
                setTimeout(() => {
                    VueEvent.fire(event_name, data)
                }, 2000)
                return
            }
            if (!this.campaigns || this.campaigns.length == 0) {
                if (!this.is_widget) {
                    this.$alert('Sorry, you need to add a line first to use the dialer.', 'Oops!', {
                        confirmButtonText: 'Ok',
                        center: true,
                        callback: cancel => {
                        }
                    })
                }
                return
            }
            if (!this.call && this.is_ready) {
                this.form.current_number = this.$options.filters.fixPhone(data.phone_number)
                this.openPhone()
                if (data.task && (data.task.includes('auto_dial_task') || data.task.includes('call') || data.task.includes('barge') || data.task.includes('whisper'))) {
                    this.is_power_dialer = data.task.includes('auto_dial_task');
                    this.outbound_campaign_id = data.campaign_id
                    this.customPreValidateForm()
                    setTimeout(this.makeCall(data.task), 100)
                } else {
                    this.findDefaultOutboundCampaign()
                    this.customPreValidateForm()
                    setTimeout(this.clickToCall, 100)
                }
            } else if (this.call && (!data.task || !(data.task.includes('auto_dial_task') || data.task.includes('call') || data.task.includes('barge') || data.task.includes('whisper')))) {
                this.$notify({
                    duration: 2000,
                    title: 'Phone',
                    message: 'Sorry, your phone looks busy. Please disconnect your ongoing call, or put yourself back to available.',
                    type: 'error',
                    showClose: true,
                    position: 'bottom-right'
                })
            } else if (this.call && this.is_ready) { //When agent is ready and the agent is receiving an incoming call
                // Reject incoming call and accept fishing call
                this.removeTheCallFromQueue(this.call)
                this.activeCall.reject()
                this.backToQueue()
                this.backToPreviousStatus()
                setTimeout(() => {
                    VueEvent.fire(event_name, data)
                }, 2000)
            } else if (!this.is_ready) {
                if (start) {
                    this.startPhone(true)
                    this.openPhone()
                    setTimeout(() => {
                        VueEvent.fire(event_name, data)
                    }, 2000)
                } else {
                    this.$notify({
                        duration: 2000,
                        title: 'Phone',
                        message: 'Whoops! Please open the phone first.',
                        type: 'error',
                        showClose: true,
                        position: 'bottom-right'
                    })
                }
            }
        },

        tempCountDownTimer() {
            if (this.temporary_disable_timer_count == 0) {
                this.temporary_disable_back_button = false
                this.temporary_disable_timer_count = 2
                return
            }

            if (this.temporary_disable_timer_count > 0) {
                setTimeout(() => {
                    this.temporary_disable_timer_count -= 1
                    this.tempCountDownTimer()
                }, 1000)
            }
        },

        getParkedCall() {
            this.parked_call = null
            axios.post('/api/v1/contact-center/parked-call').then(res => {
                if (!_.isEmpty(res.data)) {
                    this.parked_call = res.data
                }
            }).catch(err => {
                console.log(err)
            })
        },

        changeOldAgentStatus(agent_status) {
            // fixes go_to_available_after_login and isCompanyForcedAvailable
            if (this.auth.user.profile.go_to_available_after_login || this.isCompanyForcedAvailable) {
                this.old_agent_status = AgentStatus.AGENT_STATUS_ACCEPTING_CALLS
            } else {
                this.old_agent_status = agent_status
            }
            console.log('changed old agent status to: ' + this.old_agent_status)
        },

        checkForcedStatus () {
            if (!this.auth.user.profile.last_call) {
                return
            }
            const shouldForceContactDisposition = this.current_company.force_contact_disposition &&
                !this.auth.user.profile.last_call.contact.disposition_status_id
            const shouldForceCallDisposition = this.current_company.force_call_disposition &&
                !this.auth.user.profile.last_call.call_disposition_id
            if (shouldForceContactDisposition || shouldForceCallDisposition) {
                this.forceStartOnWrapUp()
            }
        },

        forceStartOnWrapUp () {
            this.communication = this.auth.user.profile.last_call
            this.contact = this.auth.user.profile.contact

            this.closeNotification(this.communication)
            this.muted = false
            sessionStorage.removeItem('barge & whisper')
            this.recording_status = 'in-progress'
            this.loading_toggle_recording_status = false
            this.changePhoneStatus('Call ended')
            this.stopCallTimer()
            this.$emit('callCompleted')
            this.show_dialpad = false
            this.startWrapUpTimer()
            this.toggleVisible()
        },

        ...mapActions(['setOnCall', 'setOnWrapUp', 'stopNotificationAudio']),
    },

    watch: {
        is_visible(newVal) {
            VueEvent.fire('webrtc_visibility', newVal)
        },

        is_ready(newVal) {
            VueEvent.fire('webrtc_ready', newVal)

            if (newVal) {
                this.setFocus()
            }
        },

        show_transfer() {
            if (this.show_transfer == true) {
                this.getAvailableUsers()
                this.getAvailableRingGroups()
            }
        },

        show_add() {
            if (this.show_add == true) {
                this.getAvailableUsers(false)
                this.getAvailableRingGroups(false)
            }
        },

        show_notes() {
            if (this.show_notes && this.communication) {
                this.communication_notes = this.communication.notes
            }
        },

        on_call() {
            if (this.is_ready && this.on_call) {
                this.changePhoneStatus('On Call')
                if (sessionStorage.getItem('on_call') == 'false' && this.is_visible) {
                    console.log('[On Call] We are closing phone since there is another phone instance open with a live call on it.')
                    this.closePhone()
                }
            } else if (this.phone_status == 'On Call' && this.is_ready && !this.on_call) {
                this.changePhoneStatus('Phone ready')
            }
        },

        current_company() {
            // find default outbound campaign
            this.findDefaultOutboundCampaign()
        },

        contact() {
            if (this.contact) {
                this.getContactLocalTime()
                this.$options.local_time_interval = setInterval(this.getContactLocalTime, 60 * 1000)
            } else {
                clearInterval(this.$options.local_time_interval)
                this.contact_local_time = null
            }
        },

        is_widget() {
            if (this.is_widget) {
                this.openPhone()
            }
        },

        on_wrap_up() {
            this.setOnWrapUp(this.on_wrap_up)

            // temporarily disable the back button for 2 seconds
            // ensuring that wrap up doesn't get bypassed
            if (this.hasWrapUp && this.on_wrap_up) {
                this.temporary_disable_back_button = true
                this.tempCountDownTimer()
            }
        },

        agent_status: function (newValue, oldValue) {
            // note: wrap up to available force change can be done by an admin from the wallboard (new).
            // this admin action can change agent's status but it does not stop/end the agent dialer's wrap up mode/state.
            // solution: make sure to reboot dialer when it is on wrap up mode and the agent status has already changed to anything but wrap up
            // this case is apparent on longer wrap up duration and/or indefinite wrap up setting
            if (this.on_wrap_up && !this.temporary_disable_back_button && this.agent_status != AgentStatus.AGENT_STATUS_ON_WRAP_UP) {
                this.backToQueue()
                return
            }

            // take people out of Sentry mode if the agent status has changed
            if (oldValue == AgentStatus.AGENT_STATUS_SENTRY) {
                VueEvent.fire('hangup_call')
                return
            }
        }
    },

    beforeCreate() {
        axios.get('/build-info.json').then(res => {
            this.env = res.data.env
        }).catch(err => {
            console.log(err)
        })
    },

    beforeDestroy() {
        clearInterval(this.wrap_up_timer_interval)
        clearInterval(this.$options.webrtc_interval)
        clearInterval(this.$options.local_time_interval)
        clearInterval(this.$options.call_duration_interval)
    }
}
</script>
