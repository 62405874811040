var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { ref: "new_summary_component" },
    [
      _c("communication-textual-filters"),
      _vm._v(" "),
      _c("div", { staticClass: "d-flex" }, [
        _c("h5", { staticClass: "text-dark mb-0 align-self-end" }, [
          _vm._v("Communication Metrics"),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "d-flex ml-auto" }, [
          _vm.hasRole("Company Admin")
            ? _c(
                "div",
                { staticClass: "row" },
                [
                  _vm.isTrial && _vm.isNotSimpSocial
                    ? _c("video-modal", {
                        staticClass: "pl-2",
                        attrs: {
                          "data-testid": "new-summary-report-video",
                          title:
                            "📈 Exploring Aloware Dashboard: A Hub for Comprehensive Communication Management",
                          "cookie-name": "dashboard",
                          notes:
                            "🚀 Navigate the future with <strong>Aloware Dashboard!</strong> <br><br> 🌟 Synthesize, analyze, and strategize. Embark on a data-driven journey that's leagues ahead. <br><br> Your control center evolution is here!",
                          "video-url":
                            "https://www.youtube.com/embed/SXe0RF8AjRQ?si=66cai6gq3MPMswM8",
                          "learn-more-link":
                            "https://support.aloware.com/en/articles/9033811-dashboard-communication-activities",
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "on-clickaway",
                          rawName: "v-on-clickaway",
                          value: _vm.closeAllMenus,
                          expression: "closeAllMenus",
                        },
                      ],
                      staticClass: "col",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "dropdown ml-2 pull-right dropdown-toggle-caret-none",
                        },
                        [
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-info dropdown-toggle",
                              attrs: { "data-testid": "export-summary-button" },
                              on: {
                                click: function ($event) {
                                  return _vm.toggleExportsMenu()
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n                            Export Summary "
                              ),
                              _c("i", { staticClass: "el-icon-arrow-down" }),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "dropdown-menu dropdown-menu-right export-summary-dropdown",
                              class: { show: _vm.exports_menu_visible },
                            },
                            [
                              _c(
                                "a",
                                {
                                  staticClass: "dropdown-item",
                                  attrs: {
                                    "data-testid":
                                      "export-summary-to-pdf-button",
                                  },
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      return _vm.exportSummaryReportTo("pdf")
                                    },
                                  },
                                },
                                [
                                  _c("i", { staticClass: "fa fa-file-pdf-o" }),
                                  _vm._v(
                                    "\n                                To PDF\n                            "
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]
                  ),
                ],
                1
              )
            : _vm._e(),
        ]),
      ]),
      _vm._v(" "),
      _vm.isAdminOrAgentOrSupervisor
        ? _c("div", [
            _vm.current_company && _vm.current_company.reseller_id != 357
              ? _c("div", { staticClass: "pb-0 pt-2" }, [
                  _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "loading",
                            rawName: "v-loading",
                            value: _vm.loading || _vm.contact_summary_loading,
                            expression: "loading || contact_summary_loading",
                          },
                        ],
                        staticClass: "col-4",
                      },
                      [
                        _vm.big_donut
                          ? _c("summary-report-big-donut-card", {
                              attrs: {
                                data: _vm.big_donut,
                                is_donut: true,
                                hide_labels: true,
                                show_slices: true,
                                loading:
                                  _vm.loading || _vm.contact_summary_loading,
                                card_index: 1,
                                extra_aggregate:
                                  _vm.aggregateData.contact_engaged,
                              },
                              on: { changeCardStatus: _vm.changeCardStatus },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-8" }, [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "loading",
                              rawName: "v-loading",
                              value: _vm.loading,
                              expression: "loading",
                            },
                          ],
                          staticClass: "w-100",
                        },
                        [
                          _vm.summary
                            ? _c("summary-report-card", {
                                attrs: {
                                  title: _vm.aggregateData.calls_inbound.title,
                                  data: _vm.aggregateData.calls_inbound.data,
                                  donut_value:
                                    _vm.aggregateData.calls_inbound.total,
                                  img_src:
                                    _vm.aggregateData.calls_inbound.img_src,
                                  is_donut: true,
                                  loading: _vm.loading,
                                  card_index: 2,
                                  campaign_id: _vm.campaign_id,
                                  workflow_id: _vm.workflow_id,
                                  ring_group_id: _vm.ring_group_id,
                                  user_id: _vm.user_id,
                                  extra_aggregates:
                                    _vm.inboundSectionExtraAggregates,
                                  "data-testid":
                                    "summary-report-card-change-status-1",
                                },
                                on: { changeCardStatus: _vm.changeCardStatus },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "loading",
                              rawName: "v-loading",
                              value: _vm.loading,
                              expression: "loading",
                            },
                          ],
                          staticClass: "mt-3 w-100",
                        },
                        [
                          _vm.summary
                            ? _c("summary-report-card", {
                                attrs: {
                                  title: _vm.aggregateData.calls_outbound.title,
                                  data: _vm.aggregateData.calls_outbound.data,
                                  donut_value:
                                    _vm.aggregateData.calls_outbound.total,
                                  img_src:
                                    _vm.aggregateData.calls_outbound.img_src,
                                  is_donut: true,
                                  loading: _vm.loading,
                                  card_index: 3,
                                  campaign_id: _vm.campaign_id,
                                  workflow_id: _vm.workflow_id,
                                  ring_group_id: _vm.ring_group_id,
                                  user_id: _vm.user_id,
                                  extra_aggregates: [
                                    _vm.aggregateData.sms_outbound,
                                  ],
                                  "data-testid":
                                    "summary-report-card-change-status-2",
                                },
                                on: { changeCardStatus: _vm.changeCardStatus },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _vm.showExtendedSummary
                    ? _c("div", { staticClass: "row mt-3" }, [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "loading",
                                rawName: "v-loading",
                                value:
                                  _vm.loading ||
                                  _vm.contact_audit_summary_loading,
                                expression:
                                  "loading || contact_audit_summary_loading",
                              },
                            ],
                            staticClass: "col-3",
                          },
                          [
                            _vm.summary
                              ? _c("summary-report-card", {
                                  attrs: {
                                    title: _vm.aggregateData.appointments.title,
                                    data: _vm.aggregateData.appointments.data,
                                    img_src:
                                      _vm.aggregateData.appointments.img_src,
                                    chart_type: null,
                                    is_donut: false,
                                    loading:
                                      _vm.loading ||
                                      _vm.contact_audit_summary_loading,
                                    card_index: 4,
                                    "data-testid":
                                      "summary-report-card-change-status-3",
                                  },
                                  on: {
                                    changeCardStatus: _vm.changeCardStatus,
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "col-3" },
                          [_c("broadcast-summary-widget")],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "loading",
                                rawName: "v-loading",
                                value: _vm.loading,
                                expression: "loading",
                              },
                            ],
                            staticClass: "col-3",
                          },
                          [
                            _vm.summary
                              ? _c("summary-report-card", {
                                  attrs: {
                                    title: _vm.aggregateData.sms.title,
                                    data: _vm.aggregateData.sms.data,
                                    img_src: _vm.aggregateData.sms.img_src,
                                    chart_type: null,
                                    is_donut: false,
                                    loading: _vm.loading,
                                    card_index: 5,
                                    "data-testid":
                                      "summary-report-card-change-status-4",
                                  },
                                  on: {
                                    changeCardStatus: _vm.changeCardStatus,
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "col-3" },
                          [_c("lead-source-widget")],
                          1
                        ),
                      ])
                    : _vm._e(),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.current_company && _vm.current_company.reseller_id == 357
              ? _c("div", { staticClass: "pb-0 pt-2" }, [
                  _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "loading",
                            rawName: "v-loading",
                            value: _vm.loading || _vm.contact_summary_loading,
                            expression: "loading || contact_summary_loading",
                          },
                        ],
                        staticClass: "col-4",
                      },
                      [
                        _vm.big_donut
                          ? _c("summary-report-big-donut-card", {
                              attrs: {
                                data: _vm.big_donut,
                                is_donut: true,
                                hide_labels: true,
                                show_slices: true,
                                loading:
                                  _vm.loading || _vm.contact_summary_loading,
                                card_index: 1,
                                extra_aggregate:
                                  _vm.aggregateData.contact_engaged,
                                "data-testid":
                                  "new-summary-report-big-donut-card",
                              },
                              on: { changeCardStatus: _vm.changeCardStatus },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-8" }, [
                      _c("div", { staticClass: "row" }, [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "loading",
                                rawName: "v-loading",
                                value: _vm.loading,
                                expression: "loading",
                              },
                            ],
                            staticClass: "col-6",
                          },
                          [
                            _vm.summary
                              ? _c("summary-report-card", {
                                  attrs: {
                                    title:
                                      _vm.aggregateData.calls_inbound.title,
                                    data: _vm.aggregateData.calls_inbound.data,
                                    donut_value:
                                      _vm.aggregateData.calls_inbound.total,
                                    img_src:
                                      _vm.aggregateData.calls_inbound.img_src,
                                    is_donut: true,
                                    loading: _vm.loading,
                                    card_index: 2,
                                    campaign_id: _vm.campaign_id,
                                    workflow_id: _vm.workflow_id,
                                    ring_group_id: _vm.ring_group_id,
                                    user_id: _vm.user_id,
                                    "data-testid":
                                      "summary-report-card-change-status-5",
                                  },
                                  on: {
                                    changeCardStatus: _vm.changeCardStatus,
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "loading",
                                rawName: "v-loading",
                                value: _vm.loading,
                                expression: "loading",
                              },
                            ],
                            staticClass: "col-6",
                          },
                          [
                            _vm.summary
                              ? _c("summary-report-card", {
                                  attrs: {
                                    title:
                                      _vm.aggregateData.calls_outbound.title,
                                    data: _vm.aggregateData.calls_outbound.data,
                                    donut_value:
                                      _vm.aggregateData.calls_outbound.total,
                                    img_src:
                                      _vm.aggregateData.calls_outbound.img_src,
                                    is_donut: true,
                                    loading: _vm.loading,
                                    card_index: 3,
                                    campaign_id: _vm.campaign_id,
                                    workflow_id: _vm.workflow_id,
                                    ring_group_id: _vm.ring_group_id,
                                    user_id: _vm.user_id,
                                    "data-testid":
                                      "summary-report-card-change-status-6",
                                  },
                                  on: {
                                    changeCardStatus: _vm.changeCardStatus,
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "row" }, [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "loading",
                                rawName: "v-loading",
                                value:
                                  _vm.loading ||
                                  _vm.contact_audit_summary_loading,
                                expression:
                                  "loading || contact_audit_summary_loading",
                              },
                            ],
                            staticClass: "mt-3 col-6",
                          },
                          [
                            _vm.summary
                              ? _c("summary-report-card", {
                                  attrs: {
                                    title: _vm.aggregateData.appointments.title,
                                    title_class: "pl-3",
                                    data: _vm.aggregateData.appointments.data,
                                    img_src:
                                      _vm.aggregateData.appointments.img_src,
                                    chart_type: null,
                                    is_donut: false,
                                    loading:
                                      _vm.loading ||
                                      _vm.contact_audit_summary_loading,
                                    card_index: 4,
                                    "data-testid":
                                      "summary-report-card-change-status-7",
                                  },
                                  on: {
                                    changeCardStatus: _vm.changeCardStatus,
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "mt-3 col-6" },
                          [
                            _c("broadcast-summary-widget", {
                              attrs: { title_class: "pl-3" },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "loading",
                                rawName: "v-loading",
                                value: _vm.loading,
                                expression: "loading",
                              },
                            ],
                            staticClass: "mt-3 col-6",
                          },
                          [
                            _vm.summary
                              ? _c("summary-report-card", {
                                  attrs: {
                                    title: _vm.aggregateData.sms.title,
                                    title_class: "pl-3",
                                    data: _vm.aggregateData.sms.data,
                                    img_src: _vm.aggregateData.sms.img_src,
                                    chart_type: null,
                                    is_donut: false,
                                    loading: _vm.loading,
                                    card_index: 5,
                                    "data-testid":
                                      "summary-report-card-change-status-8",
                                  },
                                  on: {
                                    changeCardStatus: _vm.changeCardStatus,
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "mt-3 col-6" },
                          [
                            _c("lead-source-widget", {
                              attrs: { title_class: "pl-3" },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ]),
                  ]),
                ])
              : _vm._e(),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }