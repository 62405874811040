var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.contact && _vm.contact.id
    ? _c(
        "div",
        {
          staticClass: "row no-gutter",
          class: [_vm.no_padding ? "" : "pl-3 pr-3"],
        },
        [
          _vm.guestyEnabled
            ? _c(
                "div",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading_integration_data,
                      expression: "loading_integration_data",
                    },
                  ],
                  staticClass: "col-12",
                  staticStyle: { "min-height": "60px" },
                },
                _vm._l(
                  _vm.reservations.reservations,
                  function (reservation, key) {
                    return !_vm.isEmpty(_vm.reservations)
                      ? _c(
                          "el-card",
                          {
                            key: key,
                            staticClass: "small-paddings",
                            staticStyle: { "margin-top": "10px" },
                            attrs: {
                              shadow: "never",
                              "data-testid": "guesty-card",
                            },
                          },
                          [
                            _c(
                              "a",
                              {
                                attrs: {
                                  href: reservation.reservation_link,
                                  "data-testid": "guesty-reservation-link",
                                  target: "_blank",
                                },
                              },
                              [
                                _c(
                                  "table",
                                  {
                                    staticClass: "mb-2 w-100",
                                    attrs: {
                                      "data-testid": "guesty-main-info-table",
                                    },
                                  },
                                  [
                                    _c(
                                      "tr",
                                      {
                                        attrs: {
                                          "data-testid":
                                            "guesty-confirmation-code-row",
                                        },
                                      },
                                      [
                                        _c(
                                          "td",
                                          {
                                            staticClass: "text-muted",
                                            attrs: {
                                              "data-testid":
                                                "guesty-confirmation-code-column",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "Confirmation Code:\n                        "
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "td",
                                          {
                                            staticClass: "text-right _400",
                                            attrs: {
                                              "data-testid":
                                                "guesty-confirmation-code-value",
                                            },
                                          },
                                          [
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  reservation.confirmation_code
                                                )
                                              ),
                                            ]),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "tr",
                                      {
                                        attrs: {
                                          "data-testid": "guesty-status-row",
                                        },
                                      },
                                      [
                                        _c(
                                          "td",
                                          {
                                            staticClass: "text-muted",
                                            attrs: {
                                              "data-testid":
                                                "guesty-status-column",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "Status:\n                        "
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "td",
                                          {
                                            staticClass: "text-right _400",
                                            attrs: {
                                              "data-testid":
                                                "guesty-status-value",
                                            },
                                          },
                                          [
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(reservation.status)
                                              ),
                                            ]),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "tr",
                                      {
                                        attrs: {
                                          "data-testid":
                                            "guesty-checking-date-row",
                                        },
                                      },
                                      [
                                        _c(
                                          "td",
                                          {
                                            staticClass: "text-muted",
                                            attrs: {
                                              "data-testid":
                                                "guesty-checking-date-column",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "Checkin Date:\n                        "
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "td",
                                          {
                                            staticClass: "text-right _400",
                                            attrs: {
                                              "data-testid":
                                                "guesty-checking-date-value",
                                            },
                                          },
                                          [
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(reservation.checkin_date)
                                              ),
                                            ]),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "tr",
                                      {
                                        attrs: {
                                          "data-testid":
                                            "guesty-checkout-date-row",
                                        },
                                      },
                                      [
                                        _c(
                                          "td",
                                          {
                                            staticClass: "text-muted",
                                            attrs: {
                                              "data-testid":
                                                "guesty-checkout-date-column",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "Checkout Date:\n                        "
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "td",
                                          {
                                            staticClass: "text-right _400",
                                            attrs: {
                                              "data-testid":
                                                "guesty-checkout-date-value",
                                            },
                                          },
                                          [
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  reservation.checkout_date
                                                )
                                              ),
                                            ]),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "tr",
                                      {
                                        attrs: {
                                          "data-testid":
                                            "guesty-listing-id-row",
                                        },
                                      },
                                      [
                                        _c(
                                          "td",
                                          {
                                            staticClass: "text-muted",
                                            attrs: {
                                              "data-testid":
                                                "guesty-listing-id-column",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "Listing ID:\n                        "
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "td",
                                          {
                                            staticClass: "text-right _400",
                                            attrs: {
                                              "data-testid":
                                                "guesty-listing-id-value",
                                            },
                                          },
                                          [
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(reservation.listing_id)
                                              ),
                                            ]),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "tr",
                                      {
                                        attrs: {
                                          "data-testid":
                                            "guesty-guest-count-row",
                                        },
                                      },
                                      [
                                        _c(
                                          "td",
                                          {
                                            staticClass: "text-muted",
                                            attrs: {
                                              "data-testid":
                                                "guesty-guest-count-column",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "Guests count:\n                        "
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "td",
                                          {
                                            staticClass: "text-right _400",
                                            attrs: {
                                              "data-testid":
                                                "guesty-guest-count-value",
                                            },
                                          },
                                          [
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(reservation.guests_count)
                                              ),
                                            ]),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "tr",
                                      {
                                        attrs: {
                                          "data-testid": "guesty-currency-row",
                                        },
                                      },
                                      [
                                        _c(
                                          "td",
                                          {
                                            staticClass: "text-muted",
                                            attrs: {
                                              "data-testid":
                                                "guesty-currency-column",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "Currency:\n                        "
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "td",
                                          {
                                            staticClass: "text-right _400",
                                            attrs: {
                                              "data-testid":
                                                "guesty-currency-value",
                                            },
                                          },
                                          [
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(reservation.currency)
                                              ),
                                            ]),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "tr",
                                      {
                                        attrs: {
                                          "data-testid":
                                            "guesty-total-income-row",
                                        },
                                      },
                                      [
                                        _c(
                                          "td",
                                          {
                                            staticClass: "text-muted",
                                            attrs: {
                                              "data-testid":
                                                "guesty-total-income-column",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "Total income:\n                        "
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "td",
                                          {
                                            staticClass: "text-right _400",
                                            attrs: {
                                              "data-testid":
                                                "guesty-total-income-value",
                                            },
                                          },
                                          [
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(reservation.total_income)
                                              ),
                                            ]),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        )
                      : _vm._e()
                  }
                ),
                1
              )
            : _vm._e(),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }