var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "app-body sold-report-container", attrs: { id: "view" } },
    [
      _vm.contact
        ? _c(
            "router-link",
            {
              staticClass: "mt-2 mb-1",
              attrs: {
                tag: "a",
                to: { name: "Contact", params: { contact_id: _vm.contact } },
              },
            },
            [
              _c("i", { staticClass: "fa fa-arrow-left" }),
              _vm._v("\n          Return to contact\n       "),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("simpsocial-email-i-frame", { attrs: { contact_id: _vm.contact } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }