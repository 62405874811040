<template>
    <el-dialog
        title="Delete Template"
        :visible.sync="form_visible"
        width="30%"
        :before-close="handleClose"
        center>

        <p class="font-weight-bold">Are you sure you want to delete this chatbot template?</p>

        <el-button
            round
            type="danger"
            :disabled="loading"
            @click="deleteTemplate()">
            <i v-if="loading" class="fa fa-spinner fa-spin"/>
            <i v-else class="fa fa-trash-o" aria-hidden="true"/>
            <strong> Delete</strong>
        </el-button>

    </el-dialog>
</template>

<script>
export default {
    name: 'delete-template',
    props: {
        form_visible: {
            required: true,
            default: false
        },
        template: null
    },
    data() {
        return {
            loading: false
        }
    },
    methods: {
        /**
         * Deletes a chatbot template
         *
         * @return {void}
         */
        deleteTemplate() {
            this.loading = true
            axios.delete(`/api/v1/bots/builder/template/${this.template.id}`).then(res => {
                this.$emit('submit')
                this.$notify.success({
                    offset: 95,
                    title: 'Chatbots',
                    message: 'Template deleted successfully.',
                })
                this.loading = false
            }).catch(err => {
                this.$root.handleErrors(err.response)
            })
        },

        /**
         * Closes this dialog
         *
         * @return {void}
         */
        handleClose() {
            this.$emit('discard')
        },

        /**
         * Fires submit event
         *
         * @return {void}
         */
        submit() {
            this.$emit('submit')
        }
    }
}
</script>