var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      key: _vm.sidebarKey,
      ref: "sidebar",
      staticClass: "app-aside modal nav-dropdown no-select",
      class: { "folded md show-text": _vm.sidebar_folded },
      style: { top: _vm.top_offset + "px" },
      attrs: { id: "aside" },
    },
    [
      _c(
        "div",
        {
          staticClass: "left navside text-blackish",
          attrs: { "data-layout": "column" },
        },
        [
          _c("div", { staticClass: "navbar no-radius" }, [
            _c("a", { staticClass: "navbar-brand mx-auto d-block" }, [
              !_vm.sidebar_folded && !_vm.loading_whitelabel
                ? _c("img", { attrs: { src: _vm.statics.logo, alt: "." } })
                : _vm._e(),
              _vm._v(" "),
              _vm.sidebar_folded && !_vm.loading_whitelabel
                ? _c("img", {
                    attrs: { src: _vm.statics.logo_square, alt: "." },
                  })
                : _vm._e(),
            ]),
          ]),
          _vm._v(" "),
          _vm.canCommunicate
            ? _c("div", { attrs: { "flex-no-shrink": "" } }, [
                _c("nav", { attrs: { "ui-nav": "" } }, [
                  _c("ul", { staticClass: "nav" }, [
                    _c("div", { staticClass: "p-2" }, [
                      _c(
                        "a",
                        {
                          ref: "alowareDialer",
                          staticClass: "btn btn-block pl-3 mt-1 text-center",
                          class: _vm.dialerButtonClass,
                          attrs: { "data-tour-step": "5" },
                          on: { click: _vm.toggleDialer },
                        },
                        [
                          !_vm.sidebar_folded
                            ? _c(
                                "i",
                                {
                                  staticClass:
                                    "material-icons dialer-icon pull-left",
                                },
                                [_vm._v("phone_iphone")]
                              )
                            : _c(
                                "i",
                                { staticClass: "material-icons dialer-icon" },
                                [_vm._v("phone_iphone")]
                              ),
                          _vm._v(" "),
                          !_vm.sidebar_folded
                            ? [
                                !_vm.webrtc_visible
                                  ? _c("span", [_vm._v("Open Phone")])
                                  : _c("span", [_vm._v("Close Phone")]),
                              ]
                            : _vm._e(),
                        ],
                        2
                      ),
                    ]),
                  ]),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "hide-scroll",
              class: [_vm.canCommunicate ? "" : "pt-2"],
              attrs: { "data-flex": "" },
            },
            [
              _c("nav", { staticClass: "scroll nav-border b-greenish h-v" }, [
                _c(
                  "ul",
                  { staticClass: "nav", attrs: { "ui-nav": "" } },
                  [
                    _vm.current_company &&
                    _vm.current_company.default_homepage ==
                      _vm.DefaultHomepage.DEFAULT_HOMEPAGE_CONTACTS &&
                    !_vm.isSupervisor
                      ? _c("app-sidebar-contact")
                      : _vm._e(),
                    _vm._v(" "),
                    !_vm.isSupervisor ? _c("app-sidebar-dashboard") : _vm._e(),
                    _vm._v(" "),
                    _vm.hasWallboardAccess && !_vm.isSupervisor
                      ? _c(
                          "router-link",
                          {
                            attrs: {
                              to: { name: "Wallboard" },
                              tag: "li",
                              "exact-active-class":
                                "active green-gradient rounded",
                              "active-class": "active green-gradient rounded",
                            },
                          },
                          [
                            _c("a", [
                              _c("span", { staticClass: "nav-icon" }, [
                                _c("img", {
                                  attrs: {
                                    src: "/assets/images/app-icons/icon-wallboard-grey.svg",
                                  },
                                }),
                              ]),
                              _vm._v(" "),
                              _c("span", { staticClass: "nav-icon active" }, [
                                _c("img", {
                                  attrs: {
                                    src: "/assets/images/app-icons/icon-wallboard-white.svg",
                                  },
                                }),
                              ]),
                              _vm._v(" "),
                              _c("span", { staticClass: "nav-text" }, [
                                _vm._v("Wallboard"),
                              ]),
                            ]),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.shouldShowLines
                      ? _c(
                          "router-link",
                          {
                            attrs: {
                              to: { name: "Lines" },
                              tag: "li",
                              "exact-active-class":
                                "active green-gradient rounded",
                              "active-class": "active green-gradient rounded",
                            },
                          },
                          [
                            _c("a", [
                              _c("span", { staticClass: "nav-icon" }, [
                                _c("img", {
                                  attrs: {
                                    src: "/assets/images/app-icons/icon-lines-grey.svg",
                                  },
                                }),
                              ]),
                              _vm._v(" "),
                              _c("span", { staticClass: "nav-icon active" }, [
                                _c("img", {
                                  attrs: {
                                    src: "/assets/images/app-icons/icon-lines-white.svg",
                                  },
                                }),
                              ]),
                              _vm._v(" "),
                              _c("span", { staticClass: "nav-text" }, [
                                _vm._v("Lines"),
                              ]),
                            ]),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.hasPermissionTo("list ring group") &&
                    !_vm.hasReporterAccess
                      ? _c(
                          "router-link",
                          {
                            attrs: {
                              to: { name: "Ring Groups" },
                              tag: "li",
                              "exact-active-class":
                                "active green-gradient rounded",
                              "active-class": "active green-gradient rounded",
                            },
                          },
                          [
                            _c("a", [
                              _c("span", { staticClass: "nav-icon" }, [
                                _c("img", {
                                  attrs: {
                                    src: "/assets/images/app-icons/icon-ring-groups-grey.svg",
                                  },
                                }),
                              ]),
                              _vm._v(" "),
                              _c("span", { staticClass: "nav-icon active" }, [
                                _c("img", {
                                  attrs: {
                                    src: "/assets/images/app-icons/icon-ring-groups-white.svg",
                                  },
                                }),
                              ]),
                              _vm._v(" "),
                              _c("span", { staticClass: "nav-text" }, [
                                _vm._v("Ring Groups"),
                              ]),
                            ]),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.isAdminOrSupervisor
                      ? _c(
                          "router-link",
                          {
                            attrs: {
                              to: { name: "Teams" },
                              "active-class": "active green-gradient rounded",
                              "exact-active-class":
                                "active green-gradient rounded",
                              tag: "li",
                              "data-testid": "teams-sidebar",
                            },
                          },
                          [
                            _c("a", [
                              _c("span", { staticClass: "nav-icon" }, [
                                _c("img", {
                                  attrs: {
                                    src: "/assets/images/app-icons/icon-teams.svg",
                                  },
                                }),
                              ]),
                              _vm._v(" "),
                              _c("span", { staticClass: "nav-icon active" }, [
                                _c("img", {
                                  attrs: {
                                    src: "/assets/images/app-icons/icon-teams-white.svg",
                                  },
                                }),
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex align-items-center justify-center",
                                  class: _vm.sidebar_folded
                                    ? "flex-column"
                                    : "",
                                },
                                [
                                  _c("span", { staticClass: "nav-text" }, [
                                    _vm._v("Teams"),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "el-tag",
                                    {
                                      staticClass: "ml-2",
                                      attrs: { size: "mini", type: "info" },
                                    },
                                    [
                                      _vm._v(
                                        "\n                                    Beta\n                                "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.shouldShowUsers
                      ? _c(
                          "router-link",
                          {
                            attrs: {
                              to: { name: "Users" },
                              tag: "li",
                              "exact-active-class":
                                "active green-gradient rounded",
                              "active-class": "active green-gradient rounded",
                              "data-testid": "users-sidebar",
                            },
                          },
                          [
                            _c("a", [
                              _c("span", { staticClass: "nav-icon" }, [
                                _c("img", {
                                  attrs: {
                                    src: "/assets/images/app-icons/icon-user-grey.svg",
                                  },
                                }),
                              ]),
                              _vm._v(" "),
                              _c("span", { staticClass: "nav-icon active" }, [
                                _c("img", {
                                  attrs: {
                                    src: "/assets/images/app-icons/icon-user-white.svg",
                                  },
                                }),
                              ]),
                              _vm._v(" "),
                              _c("span", { staticClass: "nav-text" }, [
                                _vm._v("Users"),
                              ]),
                            ]),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.current_company &&
                    _vm.current_company.default_homepage ==
                      _vm.DefaultHomepage.DEFAULT_HOMEPAGE_DASHBOARD &&
                    !_vm.isSupervisor
                      ? _c("app-sidebar-contact")
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.isAdminOrSupervisor
                      ? _c("app-sidebar-admin-list")
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.hasPermissionTo("list tag") && !_vm.isSupervisor
                      ? _c(
                          "router-link",
                          {
                            attrs: {
                              to: { name: "Tags" },
                              tag: "li",
                              "exact-active-class":
                                "active green-gradient rounded",
                              "active-class": "active green-gradient rounded",
                            },
                          },
                          [
                            _c("a", [
                              _c("span", { staticClass: "nav-icon" }, [
                                _c("img", {
                                  attrs: {
                                    src: "/assets/images/app-icons/icon-tags-grey.svg",
                                  },
                                }),
                              ]),
                              _vm._v(" "),
                              _c("span", { staticClass: "nav-icon active" }, [
                                _c("img", {
                                  attrs: {
                                    src: "/assets/images/app-icons/icon-tags-white.svg",
                                  },
                                }),
                              ]),
                              _vm._v(" "),
                              _c("span", { staticClass: "nav-text" }, [
                                _vm._v("Tags"),
                              ]),
                            ]),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.isSimpSocialSidebarItem && !_vm.forceTalk
                      ? _c(
                          "router-link",
                          {
                            attrs: {
                              tag: "li",
                              "exact-active-class":
                                "active green-gradient rounded",
                              "active-class": "active green-gradient rounded",
                              to: { name: "Messenger" },
                            },
                          },
                          [
                            _c("a", [
                              _c(
                                "span",
                                { staticClass: "nav-icon messenger" },
                                [
                                  _c("img", {
                                    attrs: {
                                      src: "/assets/images/messenger-line.png",
                                    },
                                  }),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "span",
                                { staticClass: "nav-icon active messenger" },
                                [
                                  _c("img", {
                                    attrs: {
                                      src: "/assets/images/messenger-line.png",
                                    },
                                  }),
                                ]
                              ),
                              _vm._v(" "),
                              _c("span", { staticClass: "nav-text" }, [
                                _vm._v("Messenger"),
                              ]),
                            ]),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.isSimpSocialSidebarItem && !_vm.forceTalk
                      ? _c(
                          "router-link",
                          {
                            attrs: {
                              tag: "li",
                              "exact-active-class":
                                "active green-gradient rounded",
                              "active-class": "active green-gradient rounded",
                              to: { name: "DMS Equity" },
                            },
                          },
                          [
                            _c("a", [
                              _c("span", { staticClass: "nav-icon" }, [
                                _c("img", {
                                  attrs: {
                                    src: "/assets/images/app-icons/icon-dms-grey.svg",
                                  },
                                }),
                              ]),
                              _vm._v(" "),
                              _c("span", { staticClass: "nav-icon active" }, [
                                _c("img", {
                                  attrs: {
                                    src: "/assets/images/app-icons/icon-dms-white.svg",
                                  },
                                }),
                              ]),
                              _vm._v(" "),
                              _c("span", { staticClass: "nav-text" }, [
                                _vm._v("DMS Equity"),
                              ]),
                            ]),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.isSimpSocialSidebarItem && !_vm.forceTalk
                      ? _c(
                          "router-link",
                          {
                            attrs: {
                              tag: "li",
                              "exact-active-class":
                                "active green-gradient rounded",
                              "active-class": "active green-gradient rounded",
                              to: { name: "Digital Lead War" },
                            },
                          },
                          [
                            _c("a", [
                              _c("span", { staticClass: "nav-icon" }, [
                                _c("i", {
                                  staticClass: "fa fa-fighter-jet text-grey",
                                }),
                              ]),
                              _vm._v(" "),
                              _c("span", { staticClass: "nav-icon active" }, [
                                _c("i", {
                                  staticClass: "fa fa-fighter-jet text-white1",
                                }),
                              ]),
                              _vm._v(" "),
                              _c("span", { staticClass: "nav-text" }, [
                                _vm._v("Digital Lead War"),
                              ]),
                            ]),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.isSimpSocialSidebarItem && !_vm.forceTalk
                      ? _c(
                          "router-link",
                          {
                            attrs: {
                              tag: "li",
                              "exact-active-class":
                                "active green-gradient rounded",
                              "active-class": "active green-gradient rounded",
                              to: { name: "Email Blast" },
                            },
                          },
                          [
                            _c("a", [
                              _c("span", { staticClass: "nav-icon" }, [
                                _c("i", {
                                  staticClass: "fa fa-envelope-o text-grey",
                                }),
                              ]),
                              _vm._v(" "),
                              _c("span", { staticClass: "nav-icon active" }, [
                                _c("i", {
                                  staticClass: "fa fa-envelope-o text-white1",
                                }),
                              ]),
                              _vm._v(" "),
                              _c("span", { staticClass: "nav-text" }, [
                                _vm._v("Email Blast"),
                              ]),
                            ]),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.shouldShowAloAiMenu
                      ? _c(
                          "router-link",
                          {
                            attrs: {
                              to: { name: "AloAi" },
                              tag: "li",
                              "exact-active-class":
                                "active green-gradient rounded",
                              "active-class": "active green-gradient rounded",
                            },
                          },
                          [
                            _c("a", [
                              _c(
                                "span",
                                { staticClass: "nav-label" },
                                [
                                  _c(
                                    "el-tooltip",
                                    {
                                      attrs: {
                                        content: "Pro",
                                        placement: "right",
                                      },
                                    },
                                    [
                                      _c("b", {
                                        staticClass:
                                          "fa fa-circle pro-label-text",
                                      }),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("span", { staticClass: "nav-icon" }, [
                                _c("img", {
                                  attrs: {
                                    src: "/assets/images/app-icons/alohabot-gray.svg",
                                  },
                                }),
                              ]),
                              _vm._v(" "),
                              _c("span", { staticClass: "nav-icon active" }, [
                                _c("img", {
                                  attrs: {
                                    src: "/assets/images/app-icons/alohabot-white.svg",
                                  },
                                }),
                              ]),
                              _vm._v(" "),
                              _c("span", { staticClass: "nav-text" }, [
                                _vm._v("AloAi Text Bot"),
                              ]),
                            ]),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.shouldShowAloAiMenu
                      ? _c(
                          "router-link",
                          {
                            attrs: {
                              to: { name: "AloAi Voice Bot" },
                              tag: "li",
                              "exact-active-class":
                                "active green-gradient rounded",
                              "active-class": "active green-gradient rounded",
                            },
                          },
                          [
                            _c("a", [
                              _c(
                                "span",
                                { staticClass: "nav-label" },
                                [
                                  _c(
                                    "el-tooltip",
                                    {
                                      attrs: {
                                        content: "Pro",
                                        placement: "right",
                                      },
                                    },
                                    [
                                      _c("b", {
                                        staticClass:
                                          "fa fa-circle pro-label-text",
                                      }),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("span", { staticClass: "nav-icon" }, [
                                _c("img", {
                                  attrs: {
                                    src: "/assets/images/app-icons/aloai-voice-bot-gray.svg",
                                  },
                                }),
                              ]),
                              _vm._v(" "),
                              _c("span", { staticClass: "nav-icon active" }, [
                                _c("img", {
                                  attrs: {
                                    src: "/assets/images/app-icons/aloai-voice-bot-white.svg",
                                  },
                                }),
                              ]),
                              _vm._v(" "),
                              _c("span", { staticClass: "nav-text" }, [
                                _vm._v("AloAi Voice Bot"),
                              ]),
                            ]),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.shouldShowAloVoiceAiMenu
                      ? _c(
                          "router-link",
                          {
                            attrs: {
                              to: { name: "Alo Voice AI" },
                              tag: "li",
                              "exact-active-class":
                                "active green-gradient rounded",
                              "active-class": "active green-gradient rounded",
                            },
                          },
                          [
                            _c("a", [
                              _c(
                                "span",
                                { staticClass: "nav-label" },
                                [
                                  _c(
                                    "el-tooltip",
                                    {
                                      attrs: {
                                        content: "Pro",
                                        placement: "right",
                                      },
                                    },
                                    [
                                      _c("b", {
                                        staticClass:
                                          "fa fa-circle pro-label-text",
                                      }),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("span", { staticClass: "nav-icon" }, [
                                _c("img", {
                                  attrs: {
                                    src: "/assets/images/app-icons/alo-voice-ai-grey.svg",
                                  },
                                }),
                              ]),
                              _vm._v(" "),
                              _c("span", { staticClass: "nav-icon active" }, [
                                _c("img", {
                                  attrs: {
                                    src: "/assets/images/app-icons/alo-voice-ai-white.svg",
                                  },
                                }),
                              ]),
                              _vm._v(" "),
                              _c("span", { staticClass: "nav-text" }, [
                                _vm._v("AloAi Voice Analytics"),
                              ]),
                            ]),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.shouldShowAlohabotMenu
                      ? _c(
                          "router-link",
                          {
                            attrs: {
                              to: { name: "Chatbots" },
                              tag: "li",
                              "exact-active-class":
                                "active green-gradient rounded",
                              "active-class": "active green-gradient rounded",
                            },
                          },
                          [
                            _c("a", [
                              _c("span", { staticClass: "nav-label" }, [
                                !_vm.sidebar_folded
                                  ? _c(
                                      "b",
                                      { staticClass: "label pro-label" },
                                      [
                                        _vm._v(
                                          "\n                                   Pro\n                                "
                                        ),
                                      ]
                                    )
                                  : _c("b", {
                                      staticClass:
                                        "fa fa-circle pro-label-text",
                                    }),
                              ]),
                              _vm._v(" "),
                              _c("span", { staticClass: "nav-icon" }, [
                                _c("img", {
                                  attrs: {
                                    src: "/assets/images/app-icons/alohabot-gray.svg",
                                  },
                                }),
                              ]),
                              _vm._v(" "),
                              _c("span", { staticClass: "nav-icon active" }, [
                                _c("img", {
                                  attrs: {
                                    src: "/assets/images/app-icons/alohabot-white.svg",
                                  },
                                }),
                              ]),
                              _vm._v(" "),
                              _c("span", { staticClass: "nav-text" }, [
                                _vm._v("AlohaBot"),
                              ]),
                            ]),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    !_vm.hasReporterAccess &&
                    !_vm.forceTalk &&
                    !_vm.isSupervisor
                      ? _c(
                          "router-link",
                          {
                            attrs: {
                              to: { name: "Calendar" },
                              tag: "li",
                              "exact-active-class":
                                "active green-gradient rounded",
                              "active-class": "active green-gradient rounded",
                            },
                          },
                          [
                            _c("a", [
                              _c("span", { staticClass: "nav-label" }, [
                                !_vm.sidebar_folded
                                  ? _c(
                                      "b",
                                      { staticClass: "label pro-label" },
                                      [_vm._v("Pro")]
                                    )
                                  : _c("b", {
                                      staticClass:
                                        "fa fa-circle pro-label-text",
                                    }),
                              ]),
                              _vm._v(" "),
                              _c("span", { staticClass: "nav-icon" }, [
                                _c("img", {
                                  attrs: {
                                    src: "/assets/images/app-icons/icon-calendar-grey.svg",
                                  },
                                }),
                              ]),
                              _vm._v(" "),
                              _c("span", { staticClass: "nav-icon active" }, [
                                _c("img", {
                                  attrs: {
                                    src: "/assets/images/app-icons/icon-calendar-white.svg",
                                  },
                                }),
                              ]),
                              _vm._v(" "),
                              _c("span", { staticClass: "nav-text" }, [
                                _vm._v("Calendar"),
                              ]),
                            ]),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    !_vm.hasReporterAccess &&
                    !_vm.forceTalk &&
                    !_vm.isSupervisor &&
                    _vm.shouldShowPowerDialerItemSimpsocial
                      ? _c(
                          "router-link",
                          {
                            attrs: {
                              to: { name: "PowerDialer" },
                              tag: "li",
                              "exact-active-class":
                                "active green-gradient rounded",
                              "active-class": "active green-gradient rounded",
                            },
                          },
                          [
                            _c("a", [
                              _c("span", { staticClass: "nav-label" }, [
                                !_vm.sidebar_folded
                                  ? _c(
                                      "b",
                                      { staticClass: "label pro-label" },
                                      [_vm._v("Pro")]
                                    )
                                  : _c("b", {
                                      staticClass:
                                        "fa fa-circle pro-label-text",
                                    }),
                              ]),
                              _vm._v(" "),
                              _c("span", { staticClass: "nav-icon" }, [
                                _c("img", {
                                  attrs: {
                                    src: "/assets/images/app-icons/icon-auto-dialer-grey.svg",
                                  },
                                }),
                              ]),
                              _vm._v(" "),
                              _c("span", { staticClass: "nav-icon active" }, [
                                _c("img", {
                                  attrs: {
                                    src: "/assets/images/app-icons/icon-auto-dialer-white.svg",
                                  },
                                }),
                              ]),
                              _vm._v(" "),
                              _c("span", { staticClass: "nav-text" }, [
                                _vm._v("PowerDialer"),
                              ]),
                            ]),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.shouldShowBroadcast
                      ? _c(
                          "router-link",
                          {
                            attrs: {
                              to: { name: "Broadcast" },
                              tag: "li",
                              "exact-active-class":
                                "active green-gradient rounded",
                              "active-class": "active green-gradient rounded",
                            },
                          },
                          [
                            _c("a", [
                              _c("span", { staticClass: "nav-label" }, [
                                !_vm.sidebar_folded
                                  ? _c(
                                      "b",
                                      { staticClass: "label pro-label" },
                                      [_vm._v("Pro")]
                                    )
                                  : _c("b", {
                                      staticClass:
                                        "fa fa-circle pro-label-text",
                                    }),
                              ]),
                              _vm._v(" "),
                              _c("span", { staticClass: "nav-icon" }, [
                                _c("img", {
                                  attrs: {
                                    src: "/assets/images/app-icons/icon-broadcast-grey.svg",
                                  },
                                }),
                              ]),
                              _vm._v(" "),
                              _c("span", { staticClass: "nav-icon active" }, [
                                _c("img", {
                                  attrs: {
                                    src: "/assets/images/app-icons/icon-broadcast-white.svg",
                                  },
                                }),
                              ]),
                              _vm._v(" "),
                              _c("span", { staticClass: "nav-text" }, [
                                _vm._v("Broadcast"),
                              ]),
                            ]),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.shouldSeeSequences
                      ? _c(
                          "router-link",
                          {
                            attrs: {
                              to: { name: "Sequences 2" },
                              tag: "li",
                              "exact-active-class":
                                "active green-gradient rounded",
                              "active-class": "active green-gradient rounded",
                            },
                          },
                          [
                            _c("a", [
                              _c("span", { staticClass: "nav-label" }, [
                                !_vm.sidebar_folded
                                  ? _c(
                                      "b",
                                      { staticClass: "label pro-label" },
                                      [_vm._v("Pro")]
                                    )
                                  : _c("b", {
                                      staticClass:
                                        "fa fa-circle pro-label-text",
                                    }),
                              ]),
                              _vm._v(" "),
                              _c("span", { staticClass: "nav-icon" }, [
                                _c("img", {
                                  attrs: {
                                    src: "/assets/images/app-icons/icon-workflow-grey.svg",
                                    width: "24",
                                  },
                                }),
                              ]),
                              _vm._v(" "),
                              _c("span", { staticClass: "nav-icon active" }, [
                                _c("img", {
                                  attrs: {
                                    src: "/assets/images/app-icons/icon-workflow-white.svg",
                                    width: "24",
                                  },
                                }),
                              ]),
                              _vm._v(" "),
                              _c("span", { staticClass: "nav-text" }, [
                                _vm._v("Sequences+"),
                              ]),
                            ]),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.hasPermissionTo("list report")
                      ? _c(
                          "router-link",
                          {
                            attrs: {
                              to: { name: "Reports" },
                              tag: "li",
                              "exact-active-class":
                                "active green-gradient rounded",
                              "active-class": "active green-gradient rounded",
                            },
                          },
                          [
                            _c("a", [
                              _c("span", { staticClass: "nav-icon" }, [
                                _c("img", {
                                  attrs: {
                                    src: "/assets/images/app-icons/icon-reports-grey.svg",
                                  },
                                }),
                              ]),
                              _vm._v(" "),
                              _c("span", { staticClass: "nav-icon active" }, [
                                _c("img", {
                                  attrs: {
                                    src: "/assets/images/app-icons/icon-reports-white.svg",
                                  },
                                }),
                              ]),
                              _vm._v(" "),
                              _c("span", { staticClass: "nav-text" }, [
                                _vm._v("Reports"),
                              ]),
                            ]),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.hasPermissionTo("list insights")
                      ? _c(
                          "router-link",
                          {
                            attrs: {
                              to: { name: "Insights" },
                              tag: "li",
                              "exact-active-class":
                                "active green-gradient rounded",
                              "active-class": "active green-gradient rounded",
                            },
                          },
                          [
                            _c("a", [
                              _c("span", { staticClass: "nav-icon" }, [
                                _c("img", {
                                  attrs: {
                                    src: "/assets/images/app-icons/icon-insights-grey.svg",
                                  },
                                }),
                              ]),
                              _vm._v(" "),
                              _c("span", { staticClass: "nav-icon active" }, [
                                _c("img", {
                                  attrs: {
                                    src: "/assets/images/app-icons/icon-insights-white.svg",
                                  },
                                }),
                              ]),
                              _vm._v(" "),
                              _c("span", { staticClass: "nav-text" }, [
                                _vm._v("Insights"),
                              ]),
                            ]),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.isSimpSocialSidebarItem && !_vm.forceTalk
                      ? _c("li", [_vm._m(0)])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.hasPermissionTo(
                      "change integration settings company"
                    ) &&
                    _vm.current_company &&
                    _vm.current_company.reseller_id !== 357
                      ? _c(
                          "router-link",
                          {
                            attrs: {
                              to: { name: "Integrations" },
                              tag: "li",
                              "exact-active-class":
                                "active green-gradient rounded",
                              "active-class": "active green-gradient rounded",
                            },
                          },
                          [
                            _c("a", [
                              _c("span", { staticClass: "nav-icon" }, [
                                _c("img", {
                                  attrs: {
                                    src: "/assets/images/app-icons/icon-integrations-grey.svg",
                                  },
                                }),
                              ]),
                              _vm._v(" "),
                              _c("span", { staticClass: "nav-icon active" }, [
                                _c("img", {
                                  attrs: {
                                    src: "/assets/images/app-icons/icon-integrations-white.svg",
                                  },
                                }),
                              ]),
                              _vm._v(" "),
                              _c("span", { staticClass: "nav-text" }, [
                                _vm._v("Integrations"),
                              ]),
                            ]),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.current_company &&
                    _vm.hasPermissionTo("update company") &&
                    _vm.auth.user.profile
                      ? _c(
                          "router-link",
                          {
                            attrs: {
                              to: { name: "Account" },
                              tag: "li",
                              "exact-active-class":
                                "active green-gradient rounded",
                              "active-class": "active green-gradient rounded",
                            },
                          },
                          [
                            _c("a", [
                              _c("span", { staticClass: "nav-icon" }, [
                                _c("img", {
                                  attrs: {
                                    src: "/assets/images/app-icons/icon-company-grey.svg",
                                  },
                                }),
                              ]),
                              _vm._v(" "),
                              _c("span", { staticClass: "nav-icon active" }, [
                                _c("img", {
                                  attrs: {
                                    src: "/assets/images/app-icons/icon-company-white.svg",
                                  },
                                }),
                              ]),
                              _vm._v(" "),
                              _c("span", { staticClass: "nav-text" }, [
                                _vm._v("Account"),
                              ]),
                            ]),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.isSimpSocialSidebarItem && !_vm.forceTalk
                      ? _c(
                          "router-link",
                          {
                            attrs: {
                              to: { name: "Sold Report" },
                              tag: "li",
                              "exact-active-class":
                                "active green-gradient rounded",
                              "active-class": "active green-gradient rounded",
                            },
                          },
                          [
                            _c("a", [
                              _c("span", { staticClass: "nav-icon" }, [
                                _c("img", {
                                  attrs: {
                                    src: "/assets/images/app-icons/icon-soldreport-grey.svg",
                                  },
                                }),
                              ]),
                              _vm._v(" "),
                              _c("span", { staticClass: "nav-icon active" }, [
                                _c("img", {
                                  attrs: {
                                    src: "/assets/images/app-icons/icon-soldreport-white.svg",
                                  },
                                }),
                              ]),
                              _vm._v(" "),
                              _c("span", { staticClass: "nav-text" }, [
                                _vm._v("Sold Report"),
                              ]),
                            ]),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.canSeeDealerProfile
                      ? _c(
                          "router-link",
                          {
                            attrs: {
                              to: { name: "Dealer Profile" },
                              tag: "li",
                              "exact-active-class":
                                "active green-gradient rounded",
                              "active-class": "active green-gradient rounded",
                            },
                          },
                          [
                            _c("a", [
                              _c("span", { staticClass: "nav-icon" }, [
                                _c("img", {
                                  attrs: {
                                    src: "/assets/images/app-icons/icon-car-grey.svg",
                                  },
                                }),
                              ]),
                              _vm._v(" "),
                              _c("span", { staticClass: "nav-icon active" }, [
                                _c("img", {
                                  attrs: {
                                    src: "/assets/images/app-icons/icon-car-white.svg",
                                  },
                                }),
                              ]),
                              _vm._v(" "),
                              _c("span", { staticClass: "nav-text" }, [
                                _vm._v("Dealer Profile"),
                              ]),
                            ]),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c("div", { staticClass: "h-2x my-4" }),
                  ],
                  1
                ),
              ]),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        [
          _c(
            "el-dialog",
            {
              attrs: {
                width: "20%",
                "custom-class": "lost-connection-dialog",
                title: "Dialer Error",
                visible: _vm.dialogDialerError,
                "append-to-body": true,
              },
              on: {
                close: function ($event) {
                  return _vm.closeDialerLostConnectionDialog()
                },
              },
            },
            [
              _c("div", { staticClass: "break-word" }, [
                _c("span", [_vm._v("One of the following may have occured:")]),
                _vm._v(" "),
                _c("ul", [
                  _c("li", [_vm._v("Poor connection to servers")]),
                  _vm._v(" "),
                  _c("li", [_vm._v("Poor connection to our carrier network")]),
                  _vm._v(" "),
                  _c("li", [
                    _vm._v(
                      "You have a second tab open with any other open instances of dialer on this browser"
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("hr"),
                _vm._v(" "),
                _c("span", [
                  _vm._v(
                    "Try refreshing this web app or close any other instances of dialer open on this browser"
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "span",
                {
                  staticClass: "dialog-footer text-center",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "btn-block",
                      attrs: { type: "primary" },
                      on: { click: _vm.reloadDialer },
                    },
                    [
                      _vm._v(
                        "\n                  Reload Dialer\n              "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "a",
      {
        staticClass: "is-link",
        attrs: { target: "_blank", href: "https://calendly.com/training-2652" },
      },
      [
        _c("span", { staticClass: "nav-icon" }, [
          _c("img", {
            attrs: { src: "/assets/images/app-icons/icon-training-grey.svg" },
          }),
        ]),
        _vm._v(" "),
        _c("span", { staticClass: "nav-icon active" }, [
          _c("img", {
            attrs: { src: "/assets/images/app-icons/icon-training-white.svg" },
          }),
        ]),
        _vm._v(" "),
        _c("span", { staticClass: "nav-text" }, [_vm._v("Training")]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }