<template>
    <el-form
        :model="report_setting"
        :rules="rules"
        class="form-aloware"
        ref="companySettingsForm"
        @submit.prevent.native="submitForm('companySettingsForm')">
        <div class="row">
            <div class="col-md-7">
                <el-form-item
                    prop="default_report_period">
                    <span
                        class="page-anchor"
                        id="default_report_period">
                    </span>
                    <div class="form-label">
                        <h5>Default Report Period</h5>
                        <small>Configure how the default timeframe for the dashboard and its main graph.</small>
                    </div>
                    <el-select
                        class="w-full"
                        @change="updateParent('default_report_period')"
                        v-model="report_setting.default_report_period">
                        <el-option value="day"/>
                        <el-option value="week"/>
                        <el-option value="month"/>
                    </el-select>
                </el-form-item>

                <el-form-item>
                    <span class="page-anchor"
                          id="daily_activity_reports_enabled">
                    </span>
                    <div class="form-label">
                        <h5>Daily Activity Reports</h5>
                        <small>
                            Send daily reports of calls and texts, new lead dispositions,
                            and agents activities by email. This report is emailed to
                            account admins and includes unreturned statistics. </br>
                            An unreturned conversation is any contact that has an unread communication or is without a response.</br>
                            <a class="is-link"
                               href="https://support.aloware.com/en/articles/9034116-aloware-s-unreturned-conversations-your-key-to-unmissed-opportunities" 
                               target="_blank">
                               <i class="material-icons text-lg">help</i>
                               Click here to learn more
                            </a>
                        </small>
                    </div>
                    <div class="switch-label">
                        <el-switch
                            active-color="#00a344"
                            v-model="report_setting.daily_activity_reports_enabled"
                            @change="updateParent('daily_activity_reports_enabled')">
                        </el-switch>
                        <label>Enable daily activity reports</label>
                    </div>
                    <div class="form-label mt-2">
                        <small>
                            By selecting this box, all phone extension metrics will be included in the report.
                        </small>
                    </div>
                    <div>
                        <el-checkbox v-show="report_setting.daily_activity_reports_enabled"
                                     class="mr-2 mt-2"
                                     border
                                     v-model="report_setting.send_daily_activity_reports_with_user_extensions"
                                     @change="updateParent('send_daily_activity_reports_with_user_extensions')">
                            <strong>Include extensions</strong>
                        </el-checkbox>
                    </div>
                    <div class="form-label mt-2">
                        <small>
                            By selecting this box, only users with activity will be included in the report.
                        </small>
                    </div>
                    <div>
                        <el-checkbox v-show="report_setting.daily_activity_reports_enabled"
                                     class="mr-2 mt-2"
                                     border
                                     v-model="report_setting.send_daily_activity_reports_hiding_users_without_activity"
                                     @change="updateParent('send_daily_activity_reports_hiding_users_without_activity')">
                            <strong>Hide Users Without Activity</strong>
                        </el-checkbox>
                    </div>
                </el-form-item>

                <el-form-item
                    prop="send_daily_activity_reports_on"
                    v-show="report_setting.daily_activity_reports_enabled">
                    <span
                        class="page-anchor"
                        id="send_daily_activity_reports_on">
                    </span>
                    <div class="form-label">
                        <h5>Send Activity Reports On</h5>
                        <small>When to send the report, in your company timezone.</small>
                    </div>
                    <el-select
                        @change="updateParent('send_daily_activity_reports_on')"
                        filterable
                        placeholder="Select time"
                        class="w-full"
                        v-model="report_setting.send_daily_activity_reports_on">
                        <el-option
                            v-for="time in everyThirtyMinutes"
                            :key="time.value"
                            :label="time.label"
                            :value="time.value">
                        </el-option>
                    </el-select>
                </el-form-item>

                <el-form-item v-show="report_setting.daily_activity_reports_enabled">
                    <span
                        class="page-anchor"
                        id="send_daily_activity_reports_for">
                    </span>
                    <div class="form-label">
                        <h5>Send Activity Reports For</h5>
                        <small>Receive report from the beginning of the day (00:00 am) or the previous cut off.</small>
                    </div>
                    <el-radio-group
                        @change="updateParent('send_daily_activity_reports_for')"
                        v-model="report_setting.send_daily_activity_reports_for">
                        <el-radio-button :label="1">Today</el-radio-button>
                        <el-radio-button :label="2">Last 24 hours</el-radio-button>
                    </el-radio-group>
                    <div>
                        <el-button
                            class="bg-deep-orange mt-2"
                            type="warning"
                            size="small"
                            :loading="test_activity_report.loading"
                            @click="sendActivityReports">
                            Test daily activity reports
                        </el-button>
                    </div>
                </el-form-item>

                <el-form-item prop="daily_sms_report">
                    <span
                        class="page-anchor"
                        id="daily_sms_report">
                    </span>
                    <div class="form-label">
                        <h5>Daily Inbound SMS Report</h5>
                        <small>
                            Receive a daily report of your inbound SMS messages.
                            Useful for accounts with heavy SMS marketing.
                        </small>
                    </div>
                    <el-select
                        @change="updateParent('daily_sms_report')"
                        filterable
                        placeholder="Pick an option"
                        class="w-full"
                        v-model="report_setting.daily_sms_report">
                        <el-option
                            v-for="time in dailySmsReportPeriods"
                            :key="time.value"
                            :label="time.label"
                            :value="time.value">
                            <div class="media">
                                <div class="media-body">
                                    <label>{{ time.label }}</label>
                                    <small>{{ time.description }}</small>
                                </div>
                            </div>
                        </el-option>
                    </el-select>
                </el-form-item>

                <el-form-item prop="daily_outbound_sms_report">
                    <span class="page-anchor"
                          id="daily_outbound_sms_report">
                    </span>
                    <div class="form-label">
                        <h5>Daily Outbound SMS Report</h5>
                        <small>
                            Receive a daily report of your outbound SMS messages.
                            Useful for accounts with heavy SMS marketing.
                        </small>
                    </div>
                    <el-select
                        @change="updateParent('daily_outbound_sms_report')"
                        filterable
                        placeholder="Pick an option"
                        class="w-full"
                        v-model="report_setting.daily_outbound_sms_report">
                        <el-option
                            v-for="time in dailySmsReportPeriods"
                            class="p-0"
                            :key="time.value"
                            :label="time.label"
                            :value="time.value">
                            <div class="media">
                                <div class="media-body">
                                    <label>{{ time.label }}</label>
                                    <small>{{ time.description }}</small>
                                </div>
                            </div>
                        </el-option>
                    </el-select>
                </el-form-item>

                <el-form-item
                    v-if="report_setting && !report_setting.is_high_volume && (!report_setting.reseller_id || report_setting.reseller_id != 357)"
                    prop="daily_spend_report">
                    <span
                        class="page-anchor"
                        id="daily_spend_report">
                    </span>
                    <div class="form-label">
                        <h5>Daily Spending Report</h5>
                        <small>Receive a detailed report of your spending on our platform.</small>
                    </div>
                    <el-select
                        @change="updateParent('daily_spend_report')"
                        filterable
                        placeholder="Pick an option"
                        class="w-full"
                        v-model="report_setting.daily_spend_report">
                        <el-option
                            v-for="time in dailySpendingReportPeriods"
                            :key="time.value"
                            :label="time.label"
                            :value="time.value">
                        </el-option>
                    </el-select>
                </el-form-item>

                <el-form-item
                    prop="daily_dnc_report_enabled"
                    class="border-0 pb-0">
                    <span
                        class="page-anchor"
                        id="daily_dnc_report_enabled">
                    </span>
                    <div class="form-label">
                        <h5>Daily DNC Report</h5>
                        <small>
                            Receive a daily report of contacts set to DNC.
                        </small>
                    </div>
                    <div class="switch-label">
                        <el-switch
                            active-color="#00a344"
                            v-model="report_setting.daily_dnc_report_enabled"
                            @change="updateParent('daily_dnc_report_enabled')">
                        </el-switch>
                        <label>Enable daily DNC report</label>
                    </div>
                </el-form-item>

                <el-form-item class="no-border pb-3">
                    <el-popover placement="right"
                                width="320"
                                trigger="hover">
                        <span>Go to User Settings > Notification Settings > Account Level Notifications > Account Reports</span>
                        <span class="border-bottom-dashed cursor-pointer"
                              slot="reference">
                            To enable these settings for a specific User
                        </span>
                    </el-popover>
                </el-form-item>
            </div>
            <div class="col-md-2 d-none d-md-block ml-auto">
                <div
                    class="list-group list-group-alo list-group-flush sticky-top stick-top-with-header top-70">
                    <h6 class="list-group-item">
                        On This Page
                    </h6>
                    <a href="#default_report_period"
                       class="list-group-item list-group-item-action"
                       @click="shine('default_report_period')">
                        Default Report Period
                    </a>
                    <a href="#daily_activity_reports_enabled"
                       class="list-group-item list-group-item-action"
                       @click="shine('daily_activity_reports_enabled')">
                        Daily Activity Reports
                    </a>
                    <a href="#send_daily_activity_reports_on"
                       v-show="report_setting.daily_activity_reports_enabled"
                       class="list-group-item list-group-item-action"
                       @click="shine('send_daily_activity_reports_on')">
                        Send Activity Reports On
                    </a>
                    <a v-show="report_setting.daily_activity_reports_enabled"
                       href="#send_daily_activity_reports_for"
                       class="list-group-item list-group-item-action"
                       @click="shine('send_daily_activity_reports_for')">
                        Send Activity Reports For
                    </a>
                    <a href="#daily_sms_report"
                       class="list-group-item list-group-item-action"
                       @click="shine('daily_sms_report')">
                        Daily Inbound SMS Report
                    </a>
                    <a href="#daily_outbound_sms_report"
                       class="list-group-item list-group-item-action"
                       @click="shine('daily_outbound_sms_report')">
                        Daily Outbound SMS Report
                    </a>
                    <a v-if="report_setting && !report_setting.is_high_volume && (!report_setting.reseller_id || report_setting.reseller_id != 357)"
                       href="#daily_spend_report"
                       class="list-group-item list-group-item-action"
                       @click="shine('daily_spend_report')">
                        Daily Spending Report
                    </a>
                    <a href="#daily_dnc_report_enabled"
                       class="list-group-item list-group-item-action"
                       @click="shine('daily_dnc_report_enabled')">
                        Daily DNC Report
                    </a>
                </div>
            </div>
        </div>
    </el-form>
</template>

<script>
    import {form_handler, validator_mixin} from '../../mixins'
    export default {
        name: "account-report-settings",
        mixins: [form_handler, validator_mixin],
        props: {
            company_clone: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                report_setting: {},
                dailySpendingReportPeriods: [
                    {label: 'No Report', value: 0},
                    {label: 'Every night', value: 1},
                ],
                dailySmsReportPeriods: [
                    {label: 'No Report', value: 0, description: 'No report will be sent.'},
                    {label: 'Once (06:00 PM)', value: 1, description: '06:00 PM of the previous day to 06:00 PM of the current day.'},
                    {label: 'Twice (12:00 PM | 06:00 PM)', value: 2, description: 'First, 06:00 PM of the previous day to 12:00 PM of the current day. Second, 12:00 PM => 06:00 PM'},
                    {label: 'Thrice (10:00 AM | 02:00 PM | 06:00 PM)', value: 3, description: 'First, 06:00 PM of the previous day to 10:00 AM of the current day. Second, 10:00 AM => 02:00 PM. Third, 02:00 PM => 06:00 PM'},
                ],
                rules: {
                    send_daily_activity_reports_on: [
                        {validator: this.sendDailyActivityReportsOnValidator}
                    ],
                },
                test_activity_report: {
                    loading: false
                },
            }
        },

        created() {
            // sets the needed property of the component
            if (this.company_clone && this.company_clone.id) {
                this.initializeProperties()
            }
        },

        methods: {
            initializeProperties() {
                // updates the properties from parent object
                this.report_setting = {
                    default_report_period: this.company_clone.default_report_period,
                    daily_activity_reports_enabled: this.company_clone.daily_activity_reports_enabled,
                    send_daily_activity_reports_on: this.company_clone.send_daily_activity_reports_on,
                    send_daily_activity_reports_for: this.company_clone.send_daily_activity_reports_for,
                    daily_sms_report: this.company_clone.daily_sms_report,
                    daily_outbound_sms_report: this.company_clone.daily_outbound_sms_report,
                    is_high_volume: this.company_clone.is_high_volume,
                    reseller_id: this.company_clone.reseller_id,
                    daily_spend_report: this.company_clone.daily_spend_report,
                    daily_dnc_report_enabled: this.company_clone.daily_dnc_report_enabled,
                    send_daily_activity_reports_with_user_extensions: this.company_clone.send_daily_activity_reports_with_user_extensions,
                    send_daily_activity_reports_hiding_users_without_activity: this.company_clone.send_daily_activity_reports_hiding_users_without_activity,
                }
            },

            updateParent(prop) {
                if (this.$refs.companySettingsForm) {
                    this.$refs.companySettingsForm.validate(valid => {
                        if (valid) {
                            this.$emit('update-parent', this.report_setting)
                        }
                    })
                }
            },

            sendActivityReports() {
                this.test_activity_report.loading = true;

                axios.post('api/v1/reports/activity-reports/email')
                    .then(res => {
                        this.test_activity_report.loading = false;
                        switch (res.status) {
                            case 200:
                                this.$notify({
                                    offset: 95,
                                    title: 'Activity Reports',
                                    message: 'Test activity report is sent.',
                                    type: 'success',
                                    showClose: true,
                                })
                                break;
                            default:
                                this.$notify({
                                    offset: 95,
                                    title: 'Activity Reports',
                                    message: res.error,
                                    type: 'error',
                                    showClose: true,
                                })
                        }
                    })
            },
        },

        computed: {
            everyThirtyMinutes() {
                const times = []

                for (let hour = 0; hour < 24; hour++) {
                    times.push({
                        label: moment({hour}).format('h:mm A'),
                        value: moment({hour}).format('HH:mm')
                    })
                    times.push({
                            label: moment({hour, minute: 30}).format('h:mm A'),
                            value: moment({hour, minute: 30}).format('HH:mm')
                        }
                    )
                }

                return times
            }
        },
    }
</script>
