<template>
    <node-template :id="nodeId">
        <template v-slot:header>
            <span>Get Input</span>
        </template>

        <Intents :data="data"/>

        <Fallbacks
            class="mt-1"
            :fallbacks="data.fallback"
            :start_id="startId" />

    </node-template>
</template>

<script>
import { alohabot_node_mixin } from '../../../mixins'
import Intents from './intents.vue'
import Fallbacks from './fallback-component.vue'

export default {
    name: 'get-input-node',
    mixins: [
        alohabot_node_mixin
    ],
    components: {
        Intents,
        Fallbacks
    },
    computed: {
        /**
         * The fallback start id must begin after our intents, since the fallback itself acts as an intent.
         *
         * @return {int}
         */
        startId() {
            if (this.data.intents) {
                return this.data.intents.length + 1
            }

            return 1
        }
    }
}
</script>
