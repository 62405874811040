<template>
    <div>
        <!-- If 'single_button' is true, let's show this component with a single icon button. -->
        <el-tooltip v-if="single_button"
                    class="item"
                    effect="dark"
                    content="Show Transcription"
                    data-testid="communication-transcription-tooltip"
                    placement="bottom">
                    <!--
                        The Smart Transcription modal will be shown on click.
                        @event click
                    -->
            <button class="btn btn-sm blue btn-icon opaq"
                    data-testid="communication-transcription-button"
                    @click="fetchSmartTranscriptionData">
                <i class="material-icons">chat</i>
            </button>
        </el-tooltip>

        <!--
            If 'single_button' is false, let's show this component with a clickable icon + button_text.
            The Smart Transcription modal will be shown on click.
            @event click
        -->
        <span v-else class="text text-dark-greenish m-0 p-0 pointer"
              style="font-size: 14px!important;"
              data-test="communication-smart-transcription-button"
              @click="fetchSmartTranscriptionData">
            <i class="fa fa-file-text" style="margin-left: 1px"/>
            <span style="margin-left: 5px">{{ this.button_text }}</span>
        </span>

        <!-- Smart Transcription modal. -->
        <el-dialog :top="'5vh'"
                   :width="'95%'"
                   :visible="show_form"
                   :append-to-body="true"
                   data-testid="communication-transcription-dialog"
                   :before-close="handleClose">
            <div slot="title" class="mb-0 pb-0">
                <h4 class="text-dark mb-0">Smart Transcription</h4>
            </div>
            <!-- After fetching transcription, show_form will be true. -->
            <el-row v-if="show_form"
                    class="mb-3">
                <el-col :lg="24" >
                    <!-- After fetching the recording, remote_url will be set and Call Recording will be shown. -->
                    <div v-if="remote_url"
                         class="p-1 border">
                        <waveform data-test="communication-transcription-waveform" :remote_url="remote_url"/>
                    </div>
                </el-col>
            </el-row>

            <!-- Show loading text while fetching transcription. -->
            <strong v-if="loading"
                    data-test="communication-transcription-loading-text"
                    class="text-center">
                Loading...
            </strong>

            <el-row v-else
                    data-test="communication-transcription-row-table"
                    :gutter="15">
                <el-col :lg="12"
                        :md="24"
                        :sm="24"
                        data-test="communication-transcription-col-table">
                    <!-- Categories section. -->
                    <h5 class="mb-1 text-dark">Categories</h5>
                    <hr class="my-1">
                    <!-- Sanity check. -->
                    <table v-if="!isEmpty(iab_categories)"
                            style="width: 100%"
                            data-test="communication-transcription-categories-table"
                            class="my-4">
                        <!-- Let's iterate over iab categories to extract each category summary. -->
                        <tr data-test="communication-transcription-catgories-row-table" v-for="(category_summary, summary_index) in iab_categories"
                            :key="summary_index">
                            <td data-test="communication-transcription-categories-column-table">
                                <el-breadcrumb data-test="communication-transcription-categories-breadcrumb" separator-class="el-icon-arrow-right">
                                    <!--
                                        A single category summary contains:
                                            1. categories: an array of individual detected categories.
                                            2. relevance:  relevance of the category expressed as percentage float value.
                                    -->
                                    <el-breadcrumb-item data-testid="communication-transcription-categories-breadcrum-item" 
                                                        v-for="(category, category_index) in category_summary.categories"
                                                        :key="category_index">
                                        <span>{{ category }}</span>
                                    </el-breadcrumb-item>
                                </el-breadcrumb>
                            </td>
                            <td class="text-right">
                                <el-tooltip class="item"
                                            effect="dark"
                                            content="Relevance between the conversation and this category"
                                            data-testid="relevance-conversation-category-tooltip"
                                            placement="left">
                                    <span style="font-size: 16px">
                                        {{ category_summary.relevance }}%
                                    </span>
                                </el-tooltip>
                            </td>
                        </tr>
                    </table>

                    <!-- If no categories were detected. -->
                    <div v-else>
                        <span>
                            We couldn't find any categories in this call. For more information please check
                            <a data-testid="no-categories-this-articles-link" 
                               style="color: blue" href="https://support.aloware.com/en/articles/9037887-frequently-asked-questions-smart-transcription">
                               this article
                            </a>.
                        </span>
                    </div>

                    <!-- Highlights section. -->
                    <h5 class="mt-3 mb-1 text-dark">Highlights</h5>
                    <hr class="my-1">
                    <!-- Sanity check. -->
                    <div v-if="!isEmpty(highlights)">
                        <!-- To get the highlights by speaker, first we iterate over speakers array. -->
                        <div v-for="(speaker, speaker_index) of speakers"
                            :key="speaker_index"
                            class="mt-2">
                            <b>{{ speaker }}</b>
                            <br>
                            <!-- The highlight[speaker] contains the array of highlights. -->
                            <div v-for="(highlight, highlight_index) in highlights[speaker]"
                                 :key="highlight_index"
                                 style="display:inline;">
                                <el-tag class="mr-1 mt-1"
                                        style="background: #67def3!important;color: black;font-size: 15px"
                                        type="info"
                                        effect="dark">
                                    {{ highlight }}
                                </el-tag>
                            </div>
                        </div>
                    </div>

                    <!-- If no highlights were detected. -->
                    <div v-else>
                        <span class="mt-3">
                            We couldn't find any highlights in this call. For more information please check
                            <a data-testid="no-highlights-this-articles-link" 
                               style="color: blue" href="https://support.aloware.com/en/articles/9037887-frequently-asked-questions-smart-transcription">
                               this article
                            </a>.
                        </span>
                    </div>

                    <!-- Entities section. -->
                    <h5 class="mt-3 mb-1 text-dark">Entities</h5>
                    <hr class="my-1">
                    <!-- Sanity check. -->
                    <div v-if="!isEmpty(entities)">
                        <!-- We want to show the entities by speaker -->
                        <div v-for="(speaker, speaker_index) in speakers"
                            :key="speaker_index">
                            <!-- Check if an entity was the detected the current speaker -->
                            <div v-if="entities[speaker]"
                                 class="mt-2">
                                <b>{{ speaker }}</b>
                                <!--
                                    We grouped the entities by its type and speaker.
                                    We need to loop through entity types (if we reached here, we have at least one of these).
                                -->
                                <table v-for="(type, type_index) in entity_types"
                                    data-test="comm-transcription-entities-table"
                                    :key="type_index">
                                    <!-- Check if current type was detected for current speaker. -->
                                    <tr data-test="comm-transcription-entities-row" v-if="entities[speaker][type]">
                                        <td data-test="comm-transcription-entities-column">{{ type | ucfirst }}</td>
                                        <td data-test="comm-transcription-entities-column">
                                            <!-- Let's iterate over each entity that belongs to the current speaker and type. -->
                                            <div v-for="(entity, entity_index) in entities[speaker][type]"
                                                :key="entity_index"
                                                class="d-inline">
                                                <el-tag class="mx-1 mt-1"
                                                        type="success"
                                                        data-test="comm-transcription-entities-tag-table"
                                                        style="background: #87FBB9!important; color: black!important;font-size: 15px"
                                                        effect="dark">
                                                    {{ entity }}
                                                </el-tag>
                                            </div>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </div>

                    <!-- If no entities were detected. -->
                    <div v-else>
                        <span class="mt-3">
                            We couldn't find any specific entities in this call. For more information please check
                            <a data-testid="no-entities-this-articles-link" 
                               style="color: blue" href="https://support.aloware.com/en/articles/9037887-frequently-asked-questions-smart-transcription">
                               this article
                            </a>.
                        </span>
                    </div>

                    <!-- Custom Keywords section. -->
                    <h5 class="mt-3 mb-1 text-dark">Custom Keywords Frequency</h5>
                    <hr class="my-1">
                    <!-- Sanity check. -->
                    <div v-if="!isEmpty(custom_keywords)">
                        <!-- We want to show the custom keywords by speaker. -->
                        <div v-for="(speaker, speaker_index) in speakers"
                            :key="speaker_index">
                            <!-- Check if the current speaker said any custom keyword -->
                            <div v-if="custom_keywords[speaker]"
                                 class="mt-2">
                                <b>{{ speaker }}</b>
                                <br>
                                <!-- custom_keywords[speaker] contains an object in which the keys represent the custom keywords. -->
                                <div v-for="(keyword, idx) in Object.keys(custom_keywords[speaker])"
                                    :key="idx"
                                    style="display: inline!important;">
                                    <el-tag class="mr-1 mt-1"
                                            type="warning"
                                            data-test="custom-keywords-tag"
                                            style="background: #ffd57e!important; color: black!important;font-size: 15px"
                                            effect="dark">
                                        {{ keyword | ucfirst }}<span style="color:gray!important;">{{ ` x ${custom_keywords[speaker][keyword]}` }}</span>
                                    </el-tag>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- If no custom keywords were detected. -->
                    <div v-else>
                        <span>
                            We couldn't find any custom keywords in this call. For more information please check
                            <a data-testid="no-custom-key-this-articles-link" 
                               style="color: blue" 
                               href="https://support.aloware.com/en/articles/9037887-frequently-asked-questions-smart-transcription">
                               this article
                            </a>.
                        </span>
                    </div>
                </el-col>

                <el-col :lg="12"
                        :md="24"
                        :sm="24">
                    <div>
                        <!-- Overall Sentiment Analysis section. -->
                        <div class="mb-1">
                            <strong>Overall Sentiment:</strong>
                            <div style="display: inline!important;">
                                <!-- Sanity check. -->
                                <div v-if="!isEmpty(sentiment_analysis)"
                                     style="display: inline!important;">
                                    <!--
                                        Sentiment analysis object contains:
                                            1. speaker.
                                            2. overall: speaker's overall sentiment.
                                    -->
                                    <div v-for="(sentiment_summary, sentiment_index) in sentiment_analysis"
                                        :key="sentiment_index"
                                        style="display: inline!important;"
                                        class="mx-1">
                                        {{ sentiment_summary.speaker }} :
                                        <el-tooltip class="item"
                                                    effect="dark"
                                                    style="display: inline!important;"
                                                    :content="calculateOverAllSentimentBySpeaker(sentiment_summary)"
                                                    data-test="overall-sentiment-tooltip"
                                                    placement="top">
                                            <span style="padding:0.2em 0.2em;border-radius: 5px; color: black;font-size: 13px!important;"
                                                :style="{ background: sentimentColors[sentiment_summary.overall]}">
                                                {{ sentiment_summary.overall }}
                                            </span>
                                        </el-tooltip>
                                    </div>
                                </div>
                            </div>

                            <!-- Sanity check. -->
                            <div v-if="!isEmpty(talk_time_analysis)"
                                 style="display: inline!important;margin-left: 15px">
                                <strong>Talk Time:</strong>
                                <div style="display: inline!important;">
                                    <!--
                                        Talk time analysis contains:
                                            [key]: speaker as a string value.
                                            [value]: talk time ratio float.
                                        Let's iterate over each sentiment summary.
                                    -->
                                    <div v-for="(speaker, speaker_index) in speakers"
                                        :key="speaker_index"
                                        style="display: inline!important;"
                                        class="mx-1">
                                        {{ speaker }}:
                                        <b style="padding:0.2em 0.2em;border-radius: 5px; color: black;font-size: 13px!important;">
                                            {{ talk_time_analysis[speaker] }}%
                                        </b>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- Conversation section. -->
                        <div>
                            <section ref="chatArea"
                                     class="chat-area">
                                <!-- Sanity check. -->
                                <div v-if="!isEmpty(messages)">
                                    <div v-for="(message, message_index) in messages"
                                         :key="message_index">
                                         <!--
                                            Any agent message will prompt on the left side of conversation.
                                            Any customer message will prompt on the right side of conversation.
                                            Also, we need to change the background color based on the message sentiment.
                                        -->
                                        <p class="message break-word"
                                           :class="{ 'message-out': inArray(message.speaker, ['AGENT', 'A']), 'message-in': !inArray(message.speaker, ['AGENT', 'A']) }"
                                           :style="{ background: sentimentColors[message.sentiment]}">
                                            <strong>Speaker: {{ message.speaker }}</strong>
                                            <br>

                                            <!-- Circle the existing highlights inside the message text. -->
                                            <span v-html="circleText(message.speaker, message.text)"
                                                  style="line-height: 1.6"/>
                                        </p>

                                        <!-- Show the speaker's sentiment below each message. -->
                                        <span class="sentiment"
                                              :class="{ 'sentiment-out': inArray(message.speaker, ['AGENT', 'A']), 'sentiment-in': !inArray(message.speaker, ['AGENT', 'A']) }">
                                            <!-- The ⬤ color depends on the message sentiment. -->
                                            <span :style="{ color: sentimentColors[message.sentiment]}">⬤</span>
                                            {{ message.sentiment_possibility }}% {{ message.sentiment }}
                                        </span>
                                    </div>
                                </div>

                                <!-- If no conversation was detected. -->
                                <div v-else
                                     class="text-center">
                                    <span>No Conversation</span>
                                </div>
                            </section>
                        </div>
                    </div>
                </el-col>
            </el-row>
        </el-dialog>
    </div>
</template>

<script>
import {mapActions} from 'vuex'
import Waveform from './waveform'
import * as UploadedFileTypes from '../constants/uploaded-file-types'
import _ from "lodash"

export default {
    name: "communication-transcription-modal",

    components: {Waveform},

    props: {
        communication: {
            required: true
        },
        button_text: {
            type: String,
            default: "Show more"
        },
        single_button: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            loading: false,
            show_form: false,
            remote_url: null,
            iab_categories: [],
            highlights: [],
            entities: [],
            entity_types: [],
            custom_keywords: [],
            sentiment_analysis: [],
            talk_time_analysis: [],
            messages: [],
            sentiments: [
                'POSITIVE',
                'NEUTRAL',
                'NEGATIVE'
            ],
            sentimentColors: {
                'POSITIVE': "#87FBB9",
                'NEUTRAL': "lightgrey",
                'NEGATIVE': "#E83B1E"
            },
            UploadedFileTypes
        }
    },

    methods: {
        /**
         * Fetches Transcription's data along with communication remote url.
         * @public
         */
        fetchSmartTranscriptionData() {
            this.loading = true
            // Once the button is clicked, let's show the form.
            this.show_form = true
            // Remote url is reset to reload <waveform> component.
            this.remote_url = null
            this.setSidebarFolded(true)

            // Fetch communication transcription.
            axios.get(`/api/v1/transcription/communication/${this.communication.id}`)
                .then(res => {
                    this.setSmartTranscriptionData(res.data)
                    this.loading = false
                }).catch(err => {
                    console.log("Couldn't fetch transcription information.", err)
                    this.loading = false
                })

            // Fetch communication recording url.
            let options = {
                params: {
                    type: this.UploadedFileTypes.TYPE_CALL_RECORDING
                }
            }
            axios.get(`/api/v1/communication/${this.communication.id}/file-url`, options)
                .then(res => {
                    this.remote_url = res.data.url
                }).catch(err => {
                    console.log("Couldn't fetch call recording.", err)
                })
        },

        /**
         * Sets Smart Transcription panel data.
         * @public
         *
         * @param {Object} data
         */
        setSmartTranscriptionData(data)
        {
            // Sort the speakers to always get AGENT first.
            this.speakers = data.speakers.sort()
            this.iab_categories = data.iab_categories
            this.highlights = data.highlights
            this.highlights_summary = data.auto_highlights_summary
            this.entities = data.entities
            this.entity_types = data.entity_types
            this.custom_keywords = data.custom_keywords
            this.messages = data.messages
            this.sentiment_analysis = data.sentiment_analysis_summary
            this.talk_time_analysis = data.talk_time_analysis
        },

        /**
         * Adds border to highlights in a message text.
         * @public
         *
         * @param {string} message_text
         *
         * @returns {string}
         */
        circleText(speaker, message_text) {
            this.highlights_summary.forEach(function (highlight_summary) {
                if(speaker === highlight_summary.speaker) {
                    /** @var {string} message_text Needed to revert doubled highlighted words. */
                    let backup_message_text = message_text

                    /** @var {string} highlight_text Get highlight text to circle. */
                    let highlight_text = highlight_summary.text

                    // Circle the highlight if it exists in the message text.
                    message_text = message_text.replace(
                        highlight_text, `<span style="padding:0.0em 0.1em;border-radius: 5px; color: black;border-style: solid">${highlight_text}</span>`
                    )

                    /**
                     * Imagine we have 2 highlights: 'call' and 'outbound call'.
                     * At this point 'call' will be highlighted twice.
                     *
                     * If we find a span tag directly connected to another one,
                     * it means a word has already been highlighted twice.
                     *
                     * Check for existing tag before and after a span.
                     */
                    if(message_text.includes('><span') || message_text.includes("</span><")) {
                        // Revert last highlighted word to prevent double highlighting.
                        message_text = backup_message_text
                    }
                }
            })

            return message_text
        },

        /**
         * Closes Smart Transcription modal.
         * @public
         */
        handleClose() {
            this.show_form = false
        },

        /**
         * Encapsulates lodash _.isEmpty() function.
         * @public
         *
         * @param {Object} data
         *
         * @returns {boolean}
         */
        isEmpty(data) {
            return _.isEmpty(data)
        },

        /**
         * Checks if a value exists in an array.
         * @public
         *
         * @param {any} needle The searched value.
         * @param {Array} haystack The array.
         *
         * @returns {boolean}
         */
        inArray(needle, haystack) {
            let length = haystack.length
            for(let i = 0; i < length; i++) {
                if(haystack[i] == needle) {
                  return true
                }
            }

            return false
        },

        /**
         * Create a string with each speaker sentiments' percentages.
         * @public
         *
         * @param {Object} sentiment_summary
         *
         * @returns {string} Ex: POSITIVE: 0%; NEUTRAL: 100%; NEGATIVE: 0%;
         */
        calculateOverAllSentimentBySpeaker(sentiment_summary) {
            /** @var {number} positive_sum */
            const positive_sum = sentiment_summary.positive
            /** @var {number} neutral_sum */
            const neutral_sum = sentiment_summary.neutral
            /** @var {number} negative_sum */
            const negative_sum = sentiment_summary.negative

            /** @var {number} whole_sentiments_sum */
            const whole_sentiments_sum = positive_sum + neutral_sum + negative_sum

            /**  @var {string} sentiment_percentages The actual string shown in the Overall Sentiment Tooltip. */
            let sentiment_percentages = ''

            // If we had no sentiments, don't do anything.
            if(whole_sentiments_sum === 0) {
                return sentiment_percentages
            }

            // Let's build each sentiment percentage.
            for (const sentiment of this.sentiments) {
                /** @var {number} percentage Current sentiment percentage. */
                let percentage = 0

                /** @var {number} current_sentiment_sum */
                let current_sentiment_sum = 0

                // Match each sentiment with its count
                switch(sentiment) {
                    case 'POSITIVE':
                        current_sentiment_sum = positive_sum
                        break;
                    case 'NEUTRAL':
                        current_sentiment_sum = neutral_sum
                        break;
                    case 'NEGATIVE':
                        current_sentiment_sum = negative_sum
                        break;
                }

                // Calculate current sentiment percentage
                percentage = (current_sentiment_sum / whole_sentiments_sum) * 100

                // Add current sentiment percentage with 2 decimals to final string.
                sentiment_percentages += `${sentiment}: ${(percentage).toFixed(2)}%; `
            }

            return sentiment_percentages;
        },

        ...mapActions('cache', ['setSidebarFolded', 'resetCache']),
    }
}
</script>

<style scoped>
.chat-area {
    background: whitesmoke;
    height: 75vh;
    padding: 1em;
    overflow: auto;
    margin: 0 auto 2em auto;
    border-radius: 10px;
}

.sentiment {
    width: 45%;
}

.sentiment-out {
    margin-left: 0%;
}

.sentiment-in {
    margin-left: 53%;
}

.message {
    width: 45%;
    border-radius: 10px;
    padding: .5em;
    font-size: 14px;
}

.message-out {
    color: black;
}

.message-in {
    color: black;
    margin-left: 53%;
}
</style>
