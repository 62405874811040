<template>
    <div class="app-body"
         id="view">
        <template v-if="shouldShowSequences">
            <div class="container-listing min-height-screen">
                <div class="container-header d-flex">
                    <h3 class="listing-heading">Sequences</h3>
                    <div class="ml-auto">
                        <div class="d-flex">
                            <el-popover v-if="!canCreateSequence"
                                        ref="new-sequence-popover"
                                        placement="top"
                                        width="200"
                                        class="custom-popover"
                                        popper-class="btn-primary"
                                        trigger="hover">
                                <custom-message-display :config="customMessage('sequences')" />
                            </el-popover>
                            <el-button type="success"
                                       round
                                       class="mr-3"
                                       size="medium"
                                       v-popover:new-sequence-popover
                                       :disabled="!canCreateSequence"
                                       @click="$refs.workflow_creator.addWorkflow()">
                                <i class="fa fa-plus"></i>
                                <strong>New Sequence</strong>
                            </el-button>

                            <video-modal class="pl-2"
                                         title="Mastering Aloware Automation Sequences 🚀"
                                         cookie-name="workflows"
                                         notes="🤖 Dive into the world of Aloware's Automation Sequences: from managing leads, powering SMS drip campaigns to sending timely reminders. </br></br> Customize, trigger, and automate for a seamless experience! 🚀 #MasterAutomationWithAloware"
                                         video-url="https://www.youtube.com/embed/ABDa6fgL5F4?si=F9eEcDPylwxAmBDY"
                                         learn-more-link="https://support.aloware.com/en/articles/9037395-aloware-sequence-explained-how-it-works-and-how-to-get-started"
                                         activator-size="small"
                                         v-if="isTrial && isNotSimpSocial"/>
                        </div>
                    </div>
                </div>

                <div class="container-body">
                    <div class="container-listing-tools d-flex flex-wrap mb-1">
                        <div class="d-flex flex-wrap mr-auto">
                            <div class="d-flex mr-2 search-toolbar">
                                <el-input
                                    ref="sequenceSearch"
                                    id="search-input"
                                    placeholder="Search sequences..."
                                    v-model="filters.search"
                                    clearable
                                    autofocus
                                    @keyup.native="searchWorkflows"
                                    @change="searchWorkflows">
                                    <i slot="prefix" class="el-input__icon el-icon-search"></i>
                                </el-input>
                            </div>
                            <div class="d-flex mt-2 mt-sm-0 mr-2 mr-xl-0">
                                <el-radio-group v-model="filters.active"
                                                size="medium"
                                                @change="fetchWorkflows(1)">
                                    <el-radio-button label="-3">
                                        All
                                    </el-radio-button>
                                    <el-radio-button label="1">
                                        Active
                                    </el-radio-button>
                                    <el-radio-button label="0">
                                        Paused
                                    </el-radio-button>
                                </el-radio-group>
                            </div>
                        </div>
                        <div class="d-flex mt-2 mt-xl-0 flex-wrap flex-lg-nowrap">
                            <div class="mr-2 align-self-center">
                                <span class="text-grey">Total Sequences:</span> <strong>{{ pagination.total }}</strong>
                            </div>
                            <el-pagination
                                class="align-self-center"
                                layout="prev, pager, next"
                                :current-page.sync="filters.page"
                                :page-size.sync="filters.size"
                                :total="pagination.total"
                                hide-on-single-page
                                :pager-count="5"
                                @size-change="fetchWorkflows(1)"
                                @current-change="fetchWorkflows">
                            </el-pagination>
                        </div>
                    </div>

                    <div class="table-responsive"
                         v-loading="workflows_loading">
                        <table v-if="pagination.data.length > 0" class="table">
                            <thead>
                            <tr>
                                <th>Sequence</th>
                                <th>Steps</th>
                                <th>Process On</th>
                                <th>Line Used</th>
                                <th>Contacts</th>
                                <th>Remaining Tasks</th>
                                <th>Status</th>
                                <th></th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="workflow in pagination.data">
                                <td>
                                    <div class="d-flex">
                                        <el-switch
                                            class="mr-4 align-self-center"
                                            v-model="workflow.active"
                                            active-color="#00BF50"
                                            :disabled="workflow.real_status === Workflow.CURRENT_STATUS_INVALID && !workflow.active"
                                            @change="changeActiveStatus(workflow)">
                                        </el-switch>
                                        <div>
                                            <router-link :to="{name: 'Sequence Manager', params: {workflow_id: workflow.id}}">
                                                <div class="hover-lower-opacity">
                                                    <h6 class="mb-0">{{ workflow.name }}</h6>
                                                    <span>#{{ workflow.id }}</span>
                                                    <span v-if="workflow.created_at"
                                                          class="d-block b-t pt-1 text-xs">
                                                    {{ workflow.created_at | fixDateTime }}
                                                </span>
                                                </div>
                                            </router-link>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <router-link :to="{name: 'Sequence Manager', params: {workflow_id: workflow.id}, query: {tab: 'steps'}}">
                                        <div v-if="workflow.sequences_count"
                                             class="hover-lower-opacity">
                                            {{ workflow.sequences_count }} step{{ workflow.sequences_count > 1 ? 's' : '' }}
                                        </div>
                                        <div v-else class="text-grey">No steps</div>
                                    </router-link>
                                </td>
                                <td>
                                    <span v-html="workflow.process_condition"></span>
                                </td>
                                <td>
                                    <div v-if="workflow.campaign">
                                        <h6 class="mb-0">{{ workflow.campaign.name }}</h6>
                                        <span>#{{ workflow.campaign.id }}</span>
                                    </div>
                                    <div v-else>-</div>
                                </td>
                                <td>
                                    <router-link :to="{name: 'Sequence Manager', params: {workflow_id: workflow.id}, query: {tab: 'contacts'}}">
                                        <div v-if="workflow.contacts_count"
                                             class="hover-lower-opacity">
                                            {{ workflow.contacts_count | numFormat }} contacts
                                        </div>
                                        <div v-else
                                             class="text-grey">
                                            None enrolled
                                        </div>
                                    </router-link>
                                </td>
                                <td>
                                    <div v-if="workflow.remaining_tasks"
                                         class="hover-lower-opacity">
                                        {{ workflow.remaining_tasks | numFormat }} <span v-if="workflow.total_tasks > 0">/ {{ workflow.total_tasks | numFormat }}</span>
                                    </div>
                                    <div v-else
                                         class="text-grey">
                                        0
                                    </div>
                                </td>
                                <td>
                                    <workflow-status :workflow="workflow"/>
                                </td>
                                <td class="text-right">
                                    <el-dropdown trigger="click">
                                        <el-button
                                            size="medium"
                                            class="px-3 py-2">
                                            <i class="fa fa-ellipsis-h"></i>
                                        </el-button>
                                        <template v-slot:dropdown>
                                            <el-dropdown-menu>
                                                <router-link :to="{name: 'Sequence Manager', params: {workflow_id: workflow.id}}">
                                                    <el-dropdown-item>Settings</el-dropdown-item>
                                                </router-link>
                                                <router-link :to="{name: 'Sequence Manager', params: {workflow_id: workflow.id}, query: {tab: 'steps'}}">
                                                    <el-dropdown-item>Steps</el-dropdown-item>
                                                </router-link>
                                                <router-link :to="{name: 'Sequence Manager', params: {workflow_id: workflow.id}, query: {tab: 'triggers'}}">
                                                    <el-dropdown-item>Triggers</el-dropdown-item>
                                                </router-link>
                                                <router-link :to="{name: 'Sequence Manager', params: {workflow_id: workflow.id}, query: {tab: 'contacts'}}">
                                                    <el-dropdown-item>Contacts</el-dropdown-item>
                                                </router-link>
                                                <router-link :to="{name: 'Sequence Manager', params: {workflow_id: workflow.id}, query: {tab: 'history'}}">
                                                    <el-dropdown-item>History</el-dropdown-item>
                                                </router-link>
                                                <el-dropdown-item
                                                    divided
                                                    @click.native="copyWorkflow(workflow)">
                                                    <i class="fa fa-copy"></i> Create Copy
                                                </el-dropdown-item>
                                                <el-dropdown-item
                                                    class="text-danger"
                                                    v-if="hasPermissionTo('delete workflow')"
                                                    @click.native="deleteWorkflow(workflow)">
                                                    <i class="fa fa-trash-o"></i> Delete
                                                </el-dropdown-item>
                                            </el-dropdown-menu>
                                        </template>
                                    </el-dropdown>
                                </td>
                            </tr>
                            </tbody>
                        </table>

                        <div v-if="pagination.data.length <= 0"
                             class="text-center mx-auto w-50 py-5">
                            <div class="w-75 mx-auto">
                                <img-empty></img-empty>
                            </div>
                            <p class="lead">
                                We can't seem to find sequences
                                <span v-if="filters.search.length > 0">that matches "{{ filters.search }}"</span>
                            </p>
                            <el-button type="success"
                                       round
                                       class="mr-3"
                                       size="medium"
                                       @click="$refs.workflow_creator.addWorkflow()">
                                <i class="fa fa-plus"></i>
                                <strong>New Sequence</strong>
                            </el-button>
                        </div>
                    </div>
                </div>

                <div class="container-footer d-flex">
                    <div class="ml-auto">
                        <el-pagination
                            layout="sizes, prev, pager, next"
                            :current-page.sync="filters.page"
                            :page-sizes="[10, 20, 50, 100]"
                            :page-size.sync="filters.size"
                            :hide-on-single-page="true"
                            :total="pagination.total"
                            @size-change="fetchWorkflows(1)"
                            @current-change="fetchWorkflows">
                        </el-pagination>
                    </div>
                </div>
            </div>
            <workflow-creator ref="workflow_creator"/>
            <form-height-indicator :bottom="20"/>
        </template>
        <upgrade-now-page image-link="/assets/images/Sequences.svg"
                          text="Nurture your contacts through drip campaigns and automate many actions based on predefined steps with Sequences+"
                          extra-text="Upgrade today to unlock this feature"
                          title-text="Sequences+"
                          kb-link="https://support.aloware.com/en/articles/9031607-a-guide-to-aloware-admin-broadcast"
                          class="mt-5"
                          v-if="!shouldShowSequences && shouldShowUpgradeNow">
        </upgrade-now-page>
    </div>
</template>

<script>
import {acl_mixin, kyc_mixin} from "../../mixins"
import { mapGetters } from "vuex"
import auth from "../../auth"
import * as Workflow from "../../constants/workflow"
import ImgEmpty from "../misc/img-empty"
import FormHeightIndicator from "../form-height-indicator"
import WorkflowCreator from "./workflow-creator"
import WorkflowStatus from "./workflow-status"
import UpgradeNowPage from "../../components/upgrade-now-page.vue"
import VideoModal from '../../components/video-modal.vue'
import CustomMessageDisplay from '../kyc/custom-message-display'

export default {
    name: "workflow-list",

    components: {
        UpgradeNowPage,
        WorkflowStatus,
        WorkflowCreator,
        FormHeightIndicator,
        ImgEmpty,
        VideoModal,
        CustomMessageDisplay
    },

    mixins: [acl_mixin, kyc_mixin],

    data() {
        return {
            auth: auth,
            Workflow,
            workflows_loading: true,
            pagination: {
                total: 0,
                data: []
            },
            filters: {
                page: 1,
                size: 10,
                search: '',
                active: -3
            }
        }
    },

    computed: {
        ...mapGetters('cache', ['isTrial', 'isNotSimpSocial']),

        canCreateSequence() {
            return this.enabledToAddSequences()
        }
    },

    created() {
        if (!this.shouldSeeSequences) {
            this.goBack()
        }

        this.getStatics()
        this.fetchWorkflows()
    },

    mounted() {
        // focus sequence search input on page visit
        if (this.$refs.sequenceSearch) {
            this.$refs.sequenceSearch.focus()
        }
    },

    methods: {
        searchWorkflows: _.debounce(function () {
            this.fetchWorkflows()
        }, 500),

        fetchWorkflows(page = 1) {
            this.workflows_loading = true
            this.filters.page = page

            axios.get('/api/v1/automations/workflows', {
                params: this.filters
            }).then(res => {
                this.pagination = res.data
                this.workflows_loading = false
            }).catch(err => {
                if (!this.isTrialKYC) {
                    this.$root.handleErrors(err.response)
                }
                this.workflows_loading = false
            })
        },

        copyWorkflow(workflow) {
            this.$confirm(`Are you sure you want to create a copy of this sequence?`, 'Warning', {
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                customClass: 'width-500 fixed',
                confirmButtonClass: 'el-button--success',
                type: 'warning'
            }).then(() => {
                axios.post(`/api/v1/automations/workflows/${workflow.id}/copy`, {}).then(res => {
                    this.$notify({
                        offset: 75,
                        title: 'Sequence',
                        message: res.data.message,
                        type: 'success',
                        showClose: true,
                        duration: 3000
                    })

                    // redirect to sequence manager
                    this.$router.push({
                        name: 'Sequence Manager',
                        params: {workflow_id: res.data.workflow_copy.id}
                    })
                }).catch(err => {
                    this.$notify({
                        offset: 75,
                        title: 'Sequence',
                        message: 'Failed to copy sequence',
                        type: 'error',
                        showClose: true,
                        duration: 3000
                    })
                })
            }).catch(() => {

            });
        },

        changeActiveStatus(workflow) {
            let status_action = !workflow.active ? 'pause' : 'activate';

            this.$confirm(`Are you sure you want to ${status_action} this sequence?`, 'Warning', {
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                customClass: 'width-500 fixed',
                confirmButtonClass: 'el-button--success',
                type: 'warning'
            }).then(() => {
                axios.put(`/api/v1/automations/workflows/${workflow.id}/active-status`, {
                    active: workflow.active
                }).then(res => {
                    workflow.active = res.data.workflow.active
                })
            }).catch(() => {
                workflow.active = !workflow.active
            });
        },

        deleteWorkflow(workflow) {
            this.$confirm(`Are you sure you want to delete this sequence?`, 'Warning', {
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                customClass: 'w-50 fixed',
                confirmButtonClass: 'el-button--success',
                type: 'warning'
            }).then(() => {
                axios.delete(`/api/v1/automations/workflows/${workflow.id}`).then(res => {
                    this.pagination.data = _.remove(this.pagination.data, (wf) => {
                        return wf.id != workflow.id
                    })

                    this.$notify({
                        offset: 75,
                        title: 'Sequence',
                        message: res.data.message,
                        type: 'success',
                        showClose: true,
                        duration: 3000
                    })
                })
            }).catch(() => {

            });
        },

        getStatics() {
            this.loading_whitelabel = true
            axios.get('/get-statics').then(res => {
                this.statics = res.data
                this.setPageTitle('Sequences - ' + this.statics.name)
                this.loading_whitelabel = false
            }).catch(err => {
                this.setPageTitle('Sequences - Aloware')
                console.log(err)
                this.$root.handleErrors(err.response)
                this.loading_whitelabel = false
            })
        },
    },

    beforeRouteEnter(to, from, next) {
        auth.check().then((res) => {
            if (res.data.user.is_reseller) {
                // redirect to account management portal if the company is a reseller
                next({name: 'Account Management Portal'})
            } else {
                next()
            }
        }).catch((err) => {
            next({name: 'Login', query: {redirect: to.fullPath}})
        })
    },

    beforeCreate() {
        axios.get('/build-info.json').then(res => {
            this.env = res.data.env
        }).catch(err => {
            console.log(err)
        })
    }
}
</script>
