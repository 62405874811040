var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "Delete Chatbot",
        visible: _vm.form_visible,
        width: "30%",
        "before-close": _vm.handleClose,
        center: "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.form_visible = $event
        },
      },
    },
    [
      _c("p", { staticClass: "font-weight-bold" }, [
        _vm._v("Are you sure you want to delete this bot?"),
      ]),
      _vm._v(" "),
      _c(
        "el-button",
        {
          attrs: { round: "", type: "danger", disabled: _vm.loading },
          on: {
            click: function ($event) {
              return _vm.deleteChatbot()
            },
          },
        },
        [
          _vm.loading
            ? _c("i", { staticClass: "fa fa-spinner fa-spin" })
            : _c("i", {
                staticClass: "fa fa-trash-o",
                attrs: { "aria-hidden": "true" },
              }),
          _vm._v(" "),
          _c("strong", [_vm._v(" Delete")]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }