<template>
    <div class="app-body"
         id="view"
         v-if="ring_group">
        <div class="fixed-header padding pt-0 pb-0" :style="{top: (total_offset+50) + 'px'}">
            <div class="row mb-0 mt-0">
                <div class="col-12 p-2">
                    <div class="d-flex">
                        <div class="d-flex flex-fill">
                            <comm-advanced-filters-wrapper
                                :ring_group_id="ring_group_id"></comm-advanced-filters-wrapper>
                        </div>
                        <div class="ml-auto">
                            <router-link
                                :to="{ name: 'Ring Group Dialog', params: {ring_group_id: ring_group_id}}"
                                v-if="hasPermissionTo('update ring group')">
                                <button class="btn btn-md greenish ml-2"
                                        style="height: 40px;">
                                    <i class="fa fa-cogs"></i>
                                    Settings
                                </button>
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-show="section === 'Activities'">
            <div class="padding pt-3"
                 v-if="hasPermissionTo('list report')">
                <div class="row">
                    <div class="col-12 p-0 mt-5">
                        <new-summary-report :ring_group_id="ring_group_id"></new-summary-report>
                    </div>
                </div>
            </div>

            <div class="padding"
                 v-if="hasPermissionTo('list report') && show_graph">
                <div class="row box">
                    <div class="col-12">
                        <div class="no-effect">
                            <div class="box-header pb-0">
                                <activity-graph base="ring_group"
                                                :ring_group_id="ring_group_id"
                                                :is_first_load="reports_first_load">
                                </activity-graph>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="padding"
                 v-if="hasPermissionTo('list communication')">
                <div class="row box">
                    <div class="col-12">
                        <div class="no-effect">
                            <div class="box-body">
                                <comm-log-table :ring_group_id="ring_group_id"></comm-log-table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="section === 'Transcriptions' && shouldDisplayTranscriptionSections">
            <div class="padding mt-5 pt-3">
                <transcription-reporting-panel :ring_group_id="ring_group_id"/>
            </div>
        </div>

        <el-backtop target="html"
                    ref="backtop"
                    :bottom="20"
                    :right="20">
        </el-backtop>
    </div>
</template>

<script>
import {mapGetters, mapState} from 'vuex'
import auth from '../../auth'
import {
    acl_mixin,
    chargebee_mixin,
    filter_mixin,
    scroll_mixin,
    styling_mixin,
    announce_kit_mixin
} from '../../mixins'
import TranscriptionReportingPanel from '../../components/transcription-reporting-panel'

export default {
    components: {
        'transcription-reporting-panel': TranscriptionReportingPanel
    },

    mixins: [
        styling_mixin,
        chargebee_mixin,
        scroll_mixin,
        filter_mixin,
        acl_mixin,
        announce_kit_mixin
    ],

    props: {
        ring_group_id: {
            required: true
        }
    },

    data() {
        return {
            auth: auth,
            ring_group: null,
            reports_first_load: true
        }
    },

    computed: {
        ...mapState({
            current_company: state => state.cache.current_company,
            show_graph: state => state.cache.show_graph
        }),

        ...mapGetters({
            section: 'getSection'
        }),

        isLargeEnough() {
            let valid_sizes = ['xxl', 'xl', 'lg', 'md']
            if (valid_sizes.includes(this.$mq)) {
                return true
            }
            return false
        }
    },

    mounted() {
        this.setTitle()
        if (this.hasPermissionTo('view ring group')) {
            this.getRingGroup(this.ring_group_id).then(() => {
                VueEvent.fire('page_title_updated', this.ring_group.name)
            })
        }
    },

    methods: {
        setTitle() {
            axios.get('/get-statics')
                .then(res => {
                    let name = res.data.name
                    this.setPageTitle('Ring Group Activity - ' + name)
                })
                .catch(err => {
                    this.setPageTitle('Ring Group Activity - Aloware')
                    console.log(err)
                    this.$root.handleErrors(err.response)
                })
        },

        getRingGroup: function (ring_group_id) {
            return axios.get('/api/v1/ring-group/' + ring_group_id, {
                mode: 'no-cors',
            }).then(res => {
                this.ring_group = res.data
                return Promise.resolve(res)
            }).catch(err => {
                console.log(err)
                this.$root.handleErrors(err.response)
                return Promise.reject(err)
            })
        },

        editRingGroup: function (ring_group_id) {
            this.$router.push({name: 'Ring Group Dialog', params: {ring_group_id: ring_group_id}})
        },

        reset: function () {
            this.ring_group = null
        },

        handleCommand(command) {
            if (command == "edit") {
                this.editRingGroup(this.ring_group.id)
            }
        }
    },

    watch: {
        $route({params, query}) {
            this.reset()
            if (this.hasPermissionTo('view ring group')) {
                this.getRingGroup(this.ring_group_id).then(() => {
                    VueEvent.fire('page_title_updated', this.ring_group.name)
                })
            }
        },
        'show_graph': function () {
            this.reports_first_load = false
        }
    },

    beforeRouteEnter(to, from, next) {
        auth.check()
            .then((res) => {
                if (res.data.user.is_reseller) {
                    // redirect to account management portal if the company is a reseller
                    next({name: 'Account Management Portal'})
                } else {
                    next()
                }
            })
            .catch((err) => {
                next({name: 'Login', query: {redirect: to.fullPath}})
            })
    }
}
</script>
