var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row", attrs: { id: "flow-builder" } }, [
    _c(
      "div",
      {
        staticClass: "col-12 pr-0 alo-bot-flowchart",
        staticStyle: { height: "calc(100vh - 13.5rem)" },
      },
      [
        _c(
          "div",
          {
            staticStyle: { "box-shadow": "0px 0px 6px 5px rgb(0 0 0 / 9%)" },
            attrs: { id: "drawflow" },
            on: {
              drop: _vm.onDrop,
              dragover: function ($event) {
                $event.preventDefault()
              },
            },
          },
          [
            _c("div", { staticClass: "footer m-4" }, [
              _c(
                "div",
                {
                  staticClass: "row",
                  staticStyle: {
                    "box-shadow": "1px 1px 5px #aaaaaa",
                    "border-radius": "5px",
                    background: "white",
                  },
                },
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "mr-2",
                      attrs: { size: "mini" },
                      on: { click: _vm.zoomOut },
                      nativeOn: {
                        mousedown: function ($event) {
                          return _vm.zoomOutRepeat.apply(null, arguments)
                        },
                        mouseup: function ($event) {
                          return _vm.zoomOutStopRepeat.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _c("strong", { staticStyle: { "font-size": "large" } }, [
                        _vm._v("-"),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      staticClass: "my-2 text-center no-select",
                      staticStyle: { width: "43px!important" },
                    },
                    [_vm._v(_vm._s(_vm.adjustedZoom) + "%")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      staticClass: "ml-2",
                      attrs: { size: "mini" },
                      on: { click: _vm.zoomIn },
                      nativeOn: {
                        mousedown: function ($event) {
                          return _vm.zoomInRepeat.apply(null, arguments)
                        },
                        mouseup: function ($event) {
                          return _vm.zoomInStopRepeat.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _c("strong", { staticStyle: { "font-size": "large" } }, [
                        _vm._v("+"),
                      ]),
                    ]
                  ),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _vm.shouldDisplaySidePanel && _vm.chatbot
              ? _c(
                  "div",
                  {
                    staticClass: "col-4 py-3 px-0",
                    staticStyle: { "max-height": "calc(100% - 6.7rem)" },
                    attrs: { id: "side-panel" },
                  },
                  [
                    _c("SidePanel", {
                      attrs: {
                        chatbot: _vm.chatbot,
                        intents: _vm.intents,
                        node_id: _vm.selected_node_id,
                        state: _vm.selected_state,
                        editor: _vm.editor,
                        states: _vm.states,
                        panel: _vm.selected_panel,
                        appointment_services: _vm.appointment_services,
                      },
                      on: {
                        "export-data": _vm.exportData,
                        "node-panel-closed": _vm.onNodePanelClosed,
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "ml-2", attrs: { id: "elements" } }, [
              _c(
                "div",
                { staticClass: "wrapper" },
                _vm._l(_vm.draggableNodes, function (NODE_DATA, dx) {
                  return _c(
                    "el-tooltip",
                    {
                      key: dx,
                      staticClass: "box-item",
                      attrs: {
                        effect: "dark",
                        content: NODE_DATA.label,
                        placement: "right-start",
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "drag-drawflow px-2 py-0 m-1",
                          attrs: {
                            draggable: "true",
                            "data-node": NODE_DATA.node,
                          },
                          on: { dragstart: _vm.onDrag },
                        },
                        [
                          _c("i", { staticClass: "material-icons" }, [
                            _vm._v(_vm._s(NODE_DATA.icon)),
                          ]),
                        ]
                      ),
                    ]
                  )
                }),
                1
              ),
            ]),
          ]
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }