<template>
    <el-tooltip
            class="item"
            effect="dark"
            :content="data.message"
            :disabled="!data.message || shouldHideTooltip"
            placement="bottom">
        <node-template :id="nodeId">
            <template v-slot:header>
                <span>Store Input</span>
            </template>

            <div class="row mt-2">
                <div class="col-12 text-center">
                    <p>{{ data.message }}</p>
                </div>
            </div>

            <hr>

            <div class="row-inline mt-2">
                <div class="col-12 text-center">
                    <span><b>Name:</b> {{ data.name }}</span>
                </div>
            </div>

            <div class="row-inline mt-2">
                <div class="col-12 text-center">
                    <span><b>Type:</b> {{ data.type }}</span>
                </div>
            </div>

            <div class="row-inline mt-2">
                <div class="col-12 text-center">
                    <span><b>Custom Property:</b> {{ data.aloware_name }}</span>
                </div>
            </div>
            <hr>

            <Fallbacks
                    class="mt-1"
                    :fallbacks="data.fallback"
                    :showSuccess="true"/>
        </node-template>
    </el-tooltip>
</template>

<script>
import {alohabot_node_mixin} from '../../../mixins'
import Fallbacks from './fallback-component.vue'

export default {
    name: 'store-input-node',
    mixins: [
        alohabot_node_mixin
    ],
    components: {
        Fallbacks
    },
    data() {
        return {
            entity_types: {
                'projects/-/locations/-/agents/-/entityTypes/sys.date': 'date',
                'projects/-/locations/-/agents/-/entityTypes/sys.time': 'time',
                'projects/-/locations/-/agents/-/entityTypes/sys.any': 'any',
                'projects/-/locations/-/agents/-/entityTypes/sys.time': 'number',
            }
        }
    }
}
</script>

<style scoped>
.el-tooltip {
    max-width: 300px;
}

.el-tooltip div p {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    line-clamp: 2;
    -webkit-box-orient: vertical;
}
</style>
<style>
.el-tooltip__popper {
    max-width: 250px;
}
</style>
