export const TRIGGER_OPERATION_APPLY_TAG_TO_COMMUNICATION = {
    id: 1,
    name: 'Apply tags to communication',
    value: 'tag'
}

export const TRIGGER_OPERATION_APPLY_TAG_TO_CONTACT = {
    id: 2,
    name: 'Apply tags to contact',
    value: 'tag_contact'
}

export const TRIGGER_OPERATION_ENROLL_IN_SEQUENCE = {
    id: 3,
    name: 'Enroll in sequence',
    value: 'enroll'
}

export const TRIGGER_OPERATION_DISPOSE_CONTACT = {
    id: 4,
    name: 'Dispose contact',
    value: 'dispose'
}

export const TRIGGER_OPERATIONS = [
    TRIGGER_OPERATION_APPLY_TAG_TO_COMMUNICATION,
    TRIGGER_OPERATION_APPLY_TAG_TO_CONTACT,
    TRIGGER_OPERATION_ENROLL_IN_SEQUENCE,
    TRIGGER_OPERATION_DISPOSE_CONTACT
]
