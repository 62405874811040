<template>
    <div class="row no-gutter"
         :class="[ no_padding ? '' : 'pl-3 pr-3' ]"
         v-if="contact && contact.id">

        <div class="col-12"
             v-loading="loading_integration_data"
             style="min-height: 60px;"
             v-if="guestyEnabled">
            <el-card shadow="never"
                     class="small-paddings"
                     data-testid="guesty-card"
                     v-if="!isEmpty(reservations)"
                     v-for="(reservation, key) in reservations.reservations"
                     :key="key"
                     style="margin-top: 10px;">
                <a :href="reservation.reservation_link" 
                    data-testid="guesty-reservation-link" 
                    target="_blank">
                    <!-- Start main info -->
                    <table class="mb-2 w-100" 
                           data-testid="guesty-main-info-table">
                        <tr data-testid="guesty-confirmation-code-row">
                            <td data-testid="guesty-confirmation-code-column" 
                                class="text-muted">Confirmation Code:
                            </td>
                            <td data-testid="guesty-confirmation-code-value" 
                                class="text-right _400">
                                <span>{{ reservation.confirmation_code }}</span>
                            </td>
                        </tr>
                        <tr data-testid="guesty-status-row">
                            <td data-testid="guesty-status-column" 
                                class="text-muted">Status:
                            </td>
                            <td data-testid="guesty-status-value" 
                                class="text-right _400">
                                <span>{{ reservation.status }}</span>
                            </td>
                        </tr>
                        <tr data-testid="guesty-checking-date-row">
                            <td data-testid="guesty-checking-date-column" 
                                class="text-muted">Checkin Date:
                            </td>
                            <td data-testid="guesty-checking-date-value" 
                                class="text-right _400">
                                <span>{{ reservation.checkin_date }}</span>
                            </td>
                        </tr>
                        <tr data-testid="guesty-checkout-date-row">
                            <td data-testid="guesty-checkout-date-column" 
                                class="text-muted">Checkout Date:
                            </td>
                            <td data-testid="guesty-checkout-date-value" 
                                class="text-right _400">
                                <span>{{ reservation.checkout_date }}</span>
                            </td>
                        </tr>
                        <tr data-testid="guesty-listing-id-row">
                            <td data-testid="guesty-listing-id-column" 
                                class="text-muted">Listing ID:
                            </td>
                            <td data-testid="guesty-listing-id-value" 
                                class="text-right _400">
                                <span>{{ reservation.listing_id }}</span>
                            </td>
                        </tr>
                        <tr data-testid="guesty-guest-count-row">
                            <td data-testid="guesty-guest-count-column" 
                                class="text-muted">Guests count:
                            </td>
                            <td data-testid="guesty-guest-count-value" 
                                class="text-right _400">
                                <span>{{ reservation.guests_count }}</span>
                            </td>
                        </tr>
                        <tr data-testid="guesty-currency-row">
                            <td data-testid="guesty-currency-column" 
                                class="text-muted">Currency:
                            </td>
                            <td data-testid="guesty-currency-value"
                                class="text-right _400">
                                <span>{{ reservation.currency }}</span>
                            </td>
                        </tr>
                        <tr data-testid="guesty-total-income-row">
                            <td data-testid="guesty-total-income-column" 
                                class="text-muted">Total income:
                            </td>
                            <td data-testid="guesty-total-income-value" 
                                class="text-right _400">
                                <span>{{ reservation.total_income }}</span>
                            </td>
                        </tr>
                    </table>
                    <!-- End main info -->
                </a>
            </el-card>
        </div>
    </div>
</template>

<script>

import {mapState} from 'vuex'

export default {
    props: {
        contact: {
            required: true
        },

        no_padding: {
            type: Boolean,
            required: false,
            default: false
        },
    },

    data() {
        return {
            loading_integration_data: false,
            reservations: [],
        }
    },

    computed: {
        ...mapState('cache', ['current_company']),

        guestyEnabled() {
            if (this.current_company &&
                this.current_company.guesty_integration_enabled) {
                return true
            }

            return false
        }
    },

    created() {
        this.fetchReservationsData()
    },

    methods: {
        fetchContactReservations(contact_id) {
            this.loading_integration_data = true
            axios.get('/api/v1/contact/' + contact_id + '/reservations')
                .then(res => {
                    this.loading_integration_data = false
                    this.reservations = res.data
                })
                .catch(err => {
                    this.loading_integration_data = false
                })
        },

        isEmpty(obj) {
            return Object.keys(obj).length === 0 && obj.constructor === Object
        },

        fetchReservationsData() {
            if (this.contact && this.contact.id) {
                if (this.guestyEnabled) {
                    this.fetchContactReservations(this.contact.id)
                }
            }
        },
    },

    watch: {
        contact() {
            if (this.contact) {
                this.fetchReservationsData()
            }
        }
    }
}
</script>
