<template>
    <div>
        <div class="col-12 mb-3">
            <p class="font-weight-bold side-panel-title">Get Input Panel</p>
        </div>

        <div class="col-12 mt-2">
            <p class="font-weight-bold">Intents:</p>
            <IntentsSelector
                :chatbot_intents="intents" 
                :node_intents="nodeData.intents" 
                @intent-changed="intentChangedListener"/>
        </div>

        <div class="col-12 mt-4">
            <Fallback
                ref="fallback"
                :fallbacks="fallbacks"
                @change="updateFallback"/>
        </div>
    </div>
</template>

<script>
import { alohabot_side_panel_mixin } from '../../../mixins'
import { mapState } from 'vuex'

import IntentsSelector from '../chatbot-intents-selector.vue'
import Fallback from './panel-fallback-component.vue'

export default {
    name: 'get-input-panel',
    mixins: [
        alohabot_side_panel_mixin
    ],
    components: {
        IntentsSelector,
        Fallback
    },
    props: {
        intents: {
            required: false,
            default: []
        }
    },
    data() {
        return {
            inputValue: null,
            selectedValue: null
        }
    },
    methods: {
        /**
         * Listens whenever an intent is added or removed from the node.
         *
         * @param {object} payload
         *
         * @return {void}
         */
        intentChangedListener(payload) {
            let { intents } = payload
            this.nodeData.intents = intents
        },

        /**
         * Handles saving the panel.
         *
         * @param {object} payload
         *
         * @return {void}
         */
        handleSaveState(payload) {
            this.$refs['fallback'].handleSaveState()
        }
    },
    computed: {
        ...mapState(['chatbots']),
    },
    watch: {
        fallbacks(value) {
            let copy = JSON.parse(JSON.stringify(this.nodeData))
            copy.fallback = value
            this.nodeData = copy
        }
    }
}
</script>