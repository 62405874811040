var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "row-row whitish text-blackish contacts-list" },
    [
      _c("div", { staticClass: "row-body hover b-a" }, [
        _c(
          "div",
          { staticClass: "row-inner" },
          [
            _c(
              "div",
              { staticClass: "list inset" },
              _vm._l(_vm.uniqueContacts, function (contact) {
                return _c(
                  "div",
                  { key: contact.id, staticClass: "pointer b-b" },
                  [
                    _c(
                      "router-link",
                      {
                        staticClass: "list-item",
                        attrs: {
                          to: {
                            name: "Contact",
                            params: { contact_id: contact.id },
                          },
                          "data-testid": "go-to-contact-router-link",
                        },
                      },
                      [
                        _vm.isLargeEnough
                          ? _c(
                              "div",
                              { staticClass: "row d-flex align-items-center" },
                              [
                                _c("div", { staticClass: "col-md-2" }, [
                                  _c(
                                    "div",
                                    { staticClass: "d-flex flex-row" },
                                    [
                                      _c(
                                        "el-tooltip",
                                        {
                                          staticClass: "item",
                                          attrs: {
                                            effect: "dark",
                                            disabled:
                                              !_vm.avatarTooltip(contact),
                                            content: _vm.avatarTooltip(contact),
                                            placement: "top",
                                          },
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "w-40 avatar grey-300 d-flex align-items-center justify-content-center flex-shrink-0",
                                              style: _vm.avatarStyle(contact),
                                            },
                                            [
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm._f("initials")(
                                                      _vm._f("fixContactName")(
                                                        contact.name
                                                      )
                                                    )
                                                  )
                                                ),
                                              ]),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "ml-2 d-flex flex-column flex-grow-1 text-ellipsis",
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "d-block font-weight-bold text-ellipsis",
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm._f("fixContactName")(
                                                    contact.name
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "span",
                                            { staticClass: "d-block" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm._f("fixPhone")(
                                                    contact.phone_number
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          contact.is_dnc
                                            ? _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "label p-1 text-xxs danger width-fit-content",
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                                DNC\n                                            "
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "col-md-2" }, [
                                  _c(
                                    "div",
                                    { staticClass: "list-item pl-0 pr-0" },
                                    [
                                      contact.user_id
                                        ? _c(
                                            "span",
                                            {
                                              staticClass:
                                                "d-block text-bluish",
                                            },
                                            [
                                              _c(
                                                "i",
                                                {
                                                  staticClass: "material-icons",
                                                },
                                                [_vm._v("person")]
                                              ),
                                              _vm._v(
                                                "\n                                            " +
                                                  _vm._s(
                                                    _vm.getUserName(
                                                      contact.user_id
                                                    )
                                                  ) +
                                                  "\n                                        "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      contact.disposition_status_id
                                        ? _c(
                                            "span",
                                            {
                                              staticClass:
                                                "d-block text-red-500",
                                            },
                                            [
                                              _c(
                                                "i",
                                                {
                                                  staticClass: "material-icons",
                                                },
                                                [_vm._v("folder")]
                                              ),
                                              _vm._v(
                                                "\n                                            " +
                                                  _vm._s(
                                                    _vm.getDispositionStatusName(
                                                      contact.disposition_status_id
                                                    )
                                                  ) +
                                                  "\n                                        "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.getCampaign(
                                        contact.initial_campaign_id
                                      )
                                        ? _c(
                                            "span",
                                            {
                                              staticClass:
                                                "d-block text-purple-500",
                                            },
                                            [
                                              _c(
                                                "i",
                                                {
                                                  staticClass: "material-icons",
                                                },
                                                [_vm._v("power")]
                                              ),
                                              _vm._v(
                                                "\n                                            " +
                                                  _vm._s(
                                                    _vm.getCampaign(
                                                      contact.initial_campaign_id
                                                    ).name
                                                  ) +
                                                  "\n                                        "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      contact.company_name
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "d-block text-pink-500",
                                            },
                                            [
                                              _c(
                                                "i",
                                                {
                                                  staticClass: "material-icons",
                                                },
                                                [_vm._v("business_center")]
                                              ),
                                              _vm._v(
                                                "\n                                            " +
                                                  _vm._s(contact.company_name) +
                                                  "\n                                        "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      contact.cnam_city || contact.cnam_state
                                        ? _c(
                                            "span",
                                            {
                                              staticClass:
                                                "d-block text-new-purple",
                                            },
                                            [
                                              _c(
                                                "i",
                                                {
                                                  staticClass: "material-icons",
                                                },
                                                [_vm._v("location_on")]
                                              ),
                                              _vm._v(" "),
                                              contact.cnam_city
                                                ? _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        contact.cnam_city
                                                      ) + ", "
                                                    ),
                                                  ])
                                                : _vm._e(),
                                              _vm._v(" "),
                                              contact.cnam_state
                                                ? _c("span", [
                                                    _vm._v(
                                                      _vm._s(contact.cnam_state)
                                                    ),
                                                  ])
                                                : _vm._e(),
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      contact.lead_source
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "d-block text-dark-greenish",
                                            },
                                            [
                                              _c("i", {
                                                staticClass: "fa fa-filter",
                                              }),
                                              _vm._v(
                                                "\n                                            " +
                                                  _vm._s(contact.lead_source) +
                                                  "\n                                        "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      contact.timezone
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "d-block text-deep-orange",
                                            },
                                            [
                                              _c(
                                                "i",
                                                {
                                                  staticClass: "material-icons",
                                                },
                                                [_vm._v("public")]
                                              ),
                                              _vm._v(
                                                "\n                                            " +
                                                  _vm._s(
                                                    _vm._f("fixTimezone")(
                                                      contact.timezone
                                                    )
                                                  ) +
                                                  "\n                                        "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "col-md-2" }, [
                                  contact.last_engagement_at ||
                                  contact.last_engagement_text
                                    ? _c("div", { staticClass: "pl-2 pr-2" }, [
                                        contact.last_engagement_text
                                          ? _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "d-block last-message",
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                            " +
                                                    _vm._s(
                                                      _vm._f("truncate")(
                                                        contact.last_engagement_text,
                                                        100
                                                      )
                                                    ) +
                                                    "\n                                        "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        contact.last_engagement_at
                                          ? _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "d-block last-message b-t",
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                            " +
                                                    _vm._s(
                                                      _vm._f("fixDateTime")(
                                                        contact.last_engagement_at
                                                      )
                                                    ) +
                                                    "\n                                        "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ])
                                    : _vm._e(),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "col-md-2" },
                                  [
                                    _c("contact-tags", {
                                      attrs: {
                                        show_only: true,
                                        summary: true,
                                        contact: contact,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "col-md-1 text-center" },
                                  [
                                    contact.unread_missed_call_count !== 0
                                      ? _c(
                                          "span",
                                          {
                                            staticClass:
                                              "label full-font-size danger mt-2 pointer mr-2",
                                            on: {
                                              click: function ($event) {
                                                return _vm.markAllAsRead(
                                                  $event,
                                                  contact.id,
                                                  _vm.ContactUnreadCountTypes
                                                    .UNREAD_TYPE_MISSED_CALLS
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _vm.loading_unread_count ===
                                            contact.id
                                              ? _c("i", {
                                                  staticClass:
                                                    "fa fa-spinner fa-spin",
                                                })
                                              : [
                                                  _vm._v(
                                                    "\n                                              " +
                                                      _vm._s(
                                                        contact.unread_missed_call_count
                                                      ) +
                                                      "\n                                          "
                                                  ),
                                                ],
                                          ],
                                          2
                                        )
                                      : _vm._e(),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "text-center col-md-1" },
                                  [
                                    contact.unread_count !== 0
                                      ? _c(
                                          "span",
                                          {
                                            staticClass:
                                              "label full-font-size danger mt-2 pointer",
                                            on: {
                                              click: function ($event) {
                                                return _vm.markAllAsRead(
                                                  $event,
                                                  contact.id,
                                                  _vm.ContactUnreadCountTypes
                                                    .UNREAD_TYPE_TEXTS
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _vm.loading_unread_count ===
                                            contact.id
                                              ? _c("i", {
                                                  staticClass:
                                                    "fa fa-spinner fa-spin",
                                                })
                                              : [
                                                  _vm._v(
                                                    "\n                                            " +
                                                      _vm._s(
                                                        contact.unread_count
                                                      ) +
                                                      "\n                                        "
                                                  ),
                                                ],
                                          ],
                                          2
                                        )
                                      : _vm._e(),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "col-md-1 text-center" },
                                  [
                                    contact.unread_voicemail_count !== 0
                                      ? _c(
                                          "span",
                                          {
                                            staticClass:
                                              "label full-font-size danger mt-2 pointer",
                                            on: {
                                              click: function ($event) {
                                                return _vm.markAllAsRead(
                                                  $event,
                                                  contact.id,
                                                  _vm.ContactUnreadCountTypes
                                                    .UNREAD_TYPE_VOICEMAILS
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _vm.loading_unread_count ===
                                            contact.id
                                              ? _c("i", {
                                                  staticClass:
                                                    "fa fa-spinner fa-spin",
                                                })
                                              : [
                                                  _vm._v(
                                                    "\n                                            " +
                                                      _vm._s(
                                                        contact.unread_voicemail_count
                                                      ) +
                                                      "\n                                        "
                                                  ),
                                                ],
                                          ],
                                          2
                                        )
                                      : _vm._e(),
                                  ]
                                ),
                                _vm._v(" "),
                                _vm.hasPermissionTo("update contact")
                                  ? _c(
                                      "div",
                                      { staticClass: "col-md-1 column" },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "row justify-content-center mb-1",
                                          },
                                          [
                                            !_vm.enabledToCallNumber()
                                              ? _c(
                                                  "el-popover",
                                                  {
                                                    ref:
                                                      "call-popover-" +
                                                      contact.id,
                                                    refInFor: true,
                                                    staticClass:
                                                      "custom-popover",
                                                    attrs: {
                                                      placement: "left",
                                                      width: "200",
                                                      "popper-class":
                                                        "btn-primary",
                                                      trigger: "hover",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "custom-message-display",
                                                      {
                                                        attrs: {
                                                          config:
                                                            _vm.customMessage(
                                                              "call"
                                                            ),
                                                        },
                                                      }
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "button",
                                                      {
                                                        staticClass:
                                                          "btn btn-sm btn-icon btn-outline-success",
                                                        attrs: {
                                                          slot: "reference",
                                                          "data-testid":
                                                            "disabled-call-button",
                                                          disabled: "",
                                                        },
                                                        slot: "reference",
                                                      },
                                                      [
                                                        _vm.call_loading ===
                                                        contact.id
                                                          ? _c("i", {
                                                              staticClass:
                                                                "fa fa-spinner fa-spin",
                                                            })
                                                          : _vm._e(),
                                                        _vm._v(" "),
                                                        !_vm.call_loading ||
                                                        _vm.call_loading !==
                                                          contact.id
                                                          ? _c("i", {
                                                              staticClass:
                                                                "fa fa-phone",
                                                            })
                                                          : _vm._e(),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                            _vm._v(" "),
                                            _vm.enabledToCallNumber()
                                              ? _c(
                                                  "button",
                                                  {
                                                    staticClass:
                                                      "btn btn-sm btn-icon btn-outline-success",
                                                    attrs: {
                                                      "data-popover": "call",
                                                      "data-testid":
                                                        "enabled-call-button",
                                                      disabled:
                                                        _vm.call_loading ===
                                                        contact.id,
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        $event.preventDefault()
                                                        return _vm.callContact(
                                                          contact
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _vm.call_loading ===
                                                    contact.id
                                                      ? _c("i", {
                                                          staticClass:
                                                            "fa fa-spinner fa-spin",
                                                        })
                                                      : _vm._e(),
                                                    _vm._v(" "),
                                                    !_vm.call_loading ||
                                                    _vm.call_loading !==
                                                      contact.id
                                                      ? _c("i", {
                                                          staticClass:
                                                            "fa fa-phone",
                                                        })
                                                      : _vm._e(),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "row justify-content-center mb-1",
                                          },
                                          [
                                            !_vm.enabledToTextNumber()
                                              ? _c(
                                                  "el-popover",
                                                  {
                                                    ref:
                                                      "text-popover-" +
                                                      contact.id,
                                                    refInFor: true,
                                                    staticClass:
                                                      "custom-popover",
                                                    attrs: {
                                                      placement: "left",
                                                      width: "200",
                                                      "popper-class":
                                                        "btn-primary",
                                                      trigger: "hover",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "custom-message-display",
                                                      {
                                                        attrs: {
                                                          config:
                                                            _vm.customMessage(
                                                              "text"
                                                            ),
                                                        },
                                                      }
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "button",
                                                      {
                                                        staticClass:
                                                          "btn btn-sm btn-icon btn-outline-info",
                                                        attrs: {
                                                          slot: "reference",
                                                          "data-testid":
                                                            "disabled-text-button",
                                                          disabled: "",
                                                        },
                                                        slot: "reference",
                                                      },
                                                      [
                                                        _c("i", {
                                                          staticClass:
                                                            "fa fa-comment",
                                                        }),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                            _vm._v(" "),
                                            _vm.enabledToTextNumber()
                                              ? _c(
                                                  "button",
                                                  {
                                                    staticClass:
                                                      "btn btn-sm btn-icon btn-outline-info",
                                                    attrs: {
                                                      "data-popover": "text",
                                                      "data-testid":
                                                        "enabled-text-button",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        $event.preventDefault()
                                                        return _vm.gotoMessages(
                                                          contact.id
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("i", {
                                                      staticClass:
                                                        "fa fa-comment",
                                                    }),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "row justify-content-center",
                                          },
                                          [
                                            _vm.hasPermissionTo(
                                              "archive contact"
                                            ) &&
                                            _vm.current_company &&
                                            _vm.current_company.reseller_id !==
                                              357
                                              ? _c(
                                                  "button",
                                                  {
                                                    staticClass:
                                                      "btn btn-sm btn-icon btn-outline-danger",
                                                    attrs: {
                                                      "data-testid":
                                                        "delete-contact-list-item",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        $event.preventDefault()
                                                        return _vm.deleteContactById(
                                                          contact.id
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _vm.loading_delete &&
                                                    _vm.loading_delete ==
                                                      contact.id
                                                      ? _c("i", {
                                                          staticClass:
                                                            "fa fa-spinner fa-spin",
                                                        })
                                                      : _c("i", {
                                                          staticClass:
                                                            "fa fa-trash",
                                                        }),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ]
                            )
                          : _c("div", { staticClass: "row-col" }, [
                              _c(
                                "span",
                                {
                                  staticClass: "w-40 avatar grey-300 pull-left",
                                  class: [
                                    _vm.getCampaign(contact.initial_campaign_id)
                                      ? "mt-2"
                                      : "",
                                  ],
                                  style: _vm.avatarStyle(contact),
                                },
                                [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("initials")(
                                          _vm._f("fixContactName")(contact.name)
                                        )
                                      )
                                    ),
                                  ]),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "ml-2 pull-left width-120" },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "d-block font-weight-bold" },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("fixContactName")(contact.name)
                                        )
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("span", { staticClass: "d-block" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("fixPhone")(contact.phone_number)
                                      )
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _vm.getCampaign(contact.initial_campaign_id)
                                    ? _c(
                                        "span",
                                        {
                                          staticClass:
                                            "d-block text-dark-greenish font-weight-bold",
                                        },
                                        [
                                          _vm._v(
                                            "\n                                        " +
                                              _vm._s(
                                                _vm.getCampaign(
                                                  contact.initial_campaign_id
                                                ).name
                                              ) +
                                              "\n                                    "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]
                              ),
                              _vm._v(" "),
                              contact.unread_count !== 0
                                ? _c(
                                    "span",
                                    {
                                      staticClass:
                                        "label full-font-size danger mt-2 pointer pull-right",
                                      on: {
                                        click: function ($event) {
                                          return _vm.markAllAsRead(
                                            $event,
                                            contact.id
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _vm.loading_unread_count === contact.id
                                        ? _c("i", {
                                            staticClass:
                                              "fa fa-spinner fa-spin",
                                          })
                                        : [
                                            _vm._v(
                                              "\n                                        " +
                                                _vm._s(contact.unread_count) +
                                                "\n                                    "
                                            ),
                                          ],
                                    ],
                                    2
                                  )
                                : _vm._e(),
                            ]),
                      ]
                    ),
                  ],
                  1
                )
              }),
              0
            ),
            _vm._v(" "),
            _vm.contacts.length == 0 && !_vm.loading
              ? _c(
                  "div",
                  {
                    staticClass: "text-center pos-abt w-full",
                    staticStyle: { top: "50%" },
                  },
                  [
                    _vm._v(
                      "\n                    No Contacts\n                "
                    ),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.contacts.length == 0 && _vm.loading
              ? _c(
                  "div",
                  {
                    staticClass: "text-center pos-abt w-full",
                    staticStyle: { top: "50%" },
                  },
                  [
                    _vm._v(
                      "\n                    Loading Contacts\n                "
                    ),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.contacts.length > 0
              ? _c(
                  "infinite-loading",
                  {
                    attrs: { spinner: "waveDots" },
                    on: { infinite: _vm.loadMore },
                  },
                  [
                    _c("span", { attrs: { slot: "no-more" }, slot: "no-more" }),
                    _vm._v(" "),
                    _c("span", {
                      attrs: { slot: "no-results" },
                      slot: "no-results",
                    }),
                  ]
                )
              : _vm._e(),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }