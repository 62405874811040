var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.contact
    ? _c(
        "div",
        [
          _vm.hasPermissionTo("list user")
            ? _c(
                "div",
                { staticClass: "md-form-group pb-2" },
                [
                  _c("user-selector", {
                    ref: "agent_selector",
                    attrs: {
                      disabled:
                        !_vm.hasPermissionTo("change contact ownership") ||
                        _vm.loading_field["user_id"],
                      "hide-extensions": "",
                    },
                    on: { change: _vm.changeOwner },
                    model: {
                      value: _vm.contact.user_id,
                      callback: function ($$v) {
                        _vm.$set(_vm.contact, "user_id", $$v)
                      },
                      expression: "contact.user_id",
                    },
                  }),
                  _vm._v(" "),
                  _c("label", [_vm._v("Owner")]),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.hasPermissionTo("list disposition status")
            ? _c(
                "div",
                { staticClass: "md-form-group pb-2" },
                [
                  _c("contact-disposition-selector", {
                    key: "contact-page-" + _vm.contact.id,
                    attrs: {
                      placeholder: "Select contact disposition",
                      contact: _vm.contact,
                      is_contact_page: true,
                      disabled: !_vm.hasPermissionTo("dispose contact"),
                    },
                  }),
                  _vm._v(" "),
                  _c("label", [_vm._v("Contact Disposition")]),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "md-form-group pb-2" }, [
            _c("label", [_vm._v("Tags")]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "mt-2" },
              [
                _c("contact-tags", {
                  attrs: { contact: _vm.contact, "no-collapse": true },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _vm.contact.text_authorized_at
            ? _c("div", { staticClass: "md-form-group pb-2" }, [
                _c("input", {
                  staticClass: "md-input",
                  attrs: {
                    "data-testid": "contacto-details-tcpa-approved-at-input",
                    disabled: true,
                  },
                  domProps: {
                    value: _vm._f("fixDateTime")(
                      _vm.contact.text_authorized_at
                    ),
                  },
                }),
                _vm._v(" "),
                _c("label", [_vm._v("TCPA Approved At")]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "md-form-group pb-2" }, [
            !_vm.edit.overall && !_vm.edit.email
              ? _c("input", {
                  directives: [{ name: "focus", rawName: "v-focus" }],
                  staticClass: "md-input",
                  attrs: {
                    "data-testid": "contact-details-email-input",
                    disabled: true,
                  },
                  domProps: { value: _vm._f("fixEmail")(_vm.contact.email) },
                })
              : _c("input", {
                  directives: [{ name: "focus", rawName: "v-focus" }],
                  ref: "email",
                  staticClass: "md-input",
                  attrs: { "data-testid": "contact-details-edit-email-input" },
                  domProps: { value: _vm.contact.email },
                  on: {
                    blur: function ($event) {
                      _vm.edit.email = false
                    },
                    keyup: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      )
                        return null
                      return _vm.fastUpdateField("email", $event)
                    },
                  },
                }),
            _vm._v(" "),
            _vm.hasPermissionTo("update contact")
              ? _c(
                  "div",
                  {
                    staticClass: "edit-field-button",
                    class:
                      _vm.edit.email || _vm.loading_field["email"]
                        ? "active"
                        : "",
                    attrs: { "data-testid": "edit-email-icon" },
                    on: {
                      click: function ($event) {
                        _vm.edit.email = true
                      },
                    },
                  },
                  [
                    _c(
                      "span",
                      {
                        staticClass: "text-md text-greenish pointer no-select",
                      },
                      [
                        !_vm.edit.email && !_vm.loading_field["email"]
                          ? _c("i", { staticClass: "material-icons" }, [
                              _vm._v("edit"),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.loading_field["email"]
                          ? _c("i", { staticClass: "material-icons loader" }, [
                              _vm._v(""),
                            ])
                          : _vm._e(),
                      ]
                    ),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _c("label", [_vm._v("Email")]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "md-form-group pb-2" }, [
            !_vm.edit.overall && !_vm.edit.address
              ? _c("input", {
                  directives: [{ name: "focus", rawName: "v-focus" }],
                  staticClass: "md-input",
                  attrs: {
                    disabled: true,
                    "data-testid": "contact-details-address-input",
                  },
                  domProps: { value: _vm.contact.address },
                })
              : _c("input", {
                  directives: [{ name: "focus", rawName: "v-focus" }],
                  ref: "address",
                  staticClass: "md-input",
                  attrs: {
                    "data-testid": "contact-details-edit-address-input",
                  },
                  domProps: { value: _vm.contact.address },
                  on: {
                    blur: function ($event) {
                      _vm.edit.address = false
                    },
                    keyup: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      )
                        return null
                      return _vm.fastUpdateField("address", $event)
                    },
                  },
                }),
            _vm._v(" "),
            _vm.hasPermissionTo("update contact")
              ? _c(
                  "div",
                  {
                    staticClass: "edit-field-button",
                    class:
                      _vm.edit.address || _vm.loading_field["address"]
                        ? "active"
                        : "",
                    attrs: { "data-testid": "edit-address-icon" },
                    on: {
                      click: function ($event) {
                        _vm.edit.address = true
                      },
                    },
                  },
                  [
                    _c(
                      "span",
                      {
                        staticClass: "text-md text-greenish pointer no-select",
                      },
                      [
                        !_vm.edit.address && !_vm.loading_field["address"]
                          ? _c("i", { staticClass: "material-icons" }, [
                              _vm._v("edit"),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.loading_field["address"]
                          ? _c("i", { staticClass: "material-icons loader" }, [
                              _vm._v(""),
                            ])
                          : _vm._e(),
                      ]
                    ),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _c("label", [_vm._v("Address")]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "md-form-group pb-2" }, [
            !_vm.edit.overall && !_vm.edit.website && _vm.contact.website
              ? _c(
                  "a",
                  {
                    staticClass: "md-input-style",
                    attrs: {
                      src: _vm.contact.website,
                      "data-testid": "contact-details-link-to-website",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.openInNewTab(_vm.contact.website)
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.contact.website) +
                        "\n        "
                    ),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            !_vm.edit.overall && !_vm.edit.website && !_vm.contact.website
              ? _c("input", {
                  directives: [{ name: "focus", rawName: "v-focus" }],
                  staticClass: "md-input",
                  attrs: {
                    disabled: true,
                    "data-testid": "contact-details-website-input",
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.edit.overall || _vm.edit.website
              ? _c("input", {
                  directives: [{ name: "focus", rawName: "v-focus" }],
                  ref: "website",
                  staticClass: "md-input",
                  attrs: {
                    "data-testid": "contact-details-edit-website-input",
                  },
                  domProps: { value: _vm.contact.website },
                  on: {
                    blur: function ($event) {
                      _vm.edit.website = false
                    },
                    keyup: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      )
                        return null
                      return _vm.fastUpdateField("website", $event)
                    },
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.hasPermissionTo("update contact")
              ? _c(
                  "div",
                  {
                    staticClass: "edit-field-button",
                    class:
                      _vm.edit.website || _vm.loading_field["website"]
                        ? "active"
                        : "",
                    attrs: { "data-testid": "edit-website-icon" },
                    on: {
                      click: function ($event) {
                        _vm.edit.website = true
                      },
                    },
                  },
                  [
                    _c(
                      "span",
                      {
                        staticClass: "text-md text-greenish pointer no-select",
                      },
                      [
                        !_vm.edit.website && !_vm.loading_field["website"]
                          ? _c("i", { staticClass: "material-icons" }, [
                              _vm._v("edit"),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.loading_field["website"]
                          ? _c("i", { staticClass: "material-icons loader" }, [
                              _vm._v(""),
                            ])
                          : _vm._e(),
                      ]
                    ),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _c("label", [_vm._v("Website")]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "md-form-group pb-2" }, [
            !_vm.edit.overall && !_vm.edit.company_name
              ? _c("input", {
                  directives: [{ name: "focus", rawName: "v-focus" }],
                  staticClass: "md-input",
                  attrs: {
                    disabled: true,
                    "data-testid": "contact-details-company-name-input",
                  },
                  domProps: {
                    value: _vm._f("fixCompanyName")(_vm.contact.company_name),
                  },
                })
              : _c("input", {
                  directives: [{ name: "focus", rawName: "v-focus" }],
                  ref: "company_name",
                  staticClass: "md-input",
                  attrs: {
                    "data-testid": "contact-details-edit-company-name-input",
                  },
                  domProps: { value: _vm.contact.company_name },
                  on: {
                    blur: function ($event) {
                      _vm.edit.company_name = false
                    },
                    keyup: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      )
                        return null
                      return _vm.fastUpdateField("company_name", $event)
                    },
                  },
                }),
            _vm._v(" "),
            _vm.hasPermissionTo("update contact")
              ? _c(
                  "div",
                  {
                    staticClass: "edit-field-button",
                    class:
                      _vm.edit.company_name || _vm.loading_field["company_name"]
                        ? "active"
                        : "",
                    attrs: { "data-testid": "edit-company-name-icon" },
                    on: {
                      click: function ($event) {
                        _vm.edit.company_name = true
                      },
                    },
                  },
                  [
                    _c(
                      "span",
                      {
                        staticClass: "text-md text-greenish pointer no-select",
                      },
                      [
                        !_vm.edit.company_name &&
                        !_vm.loading_field["company_name"]
                          ? _c("i", { staticClass: "material-icons" }, [
                              _vm._v("edit"),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.loading_field["company_name"]
                          ? _c("i", { staticClass: "material-icons loader" }, [
                              _vm._v(""),
                            ])
                          : _vm._e(),
                      ]
                    ),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _c("label", [_vm._v("Company Name")]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-6" }, [
              _c(
                "div",
                { staticClass: "md-form-group pb-2" },
                [
                  !_vm.edit.overall && !_vm.edit.cnam_country
                    ? _c("input", {
                        directives: [{ name: "focus", rawName: "v-focus" }],
                        staticClass: "md-input",
                        attrs: {
                          "data-testid": "contact-details-country-input",
                          disabled: true,
                        },
                        domProps: {
                          value: _vm._f("fixCountry")(_vm.contact.cnam_country),
                        },
                      })
                    : [
                        _c(
                          "el-select",
                          {
                            staticClass: "w-100",
                            attrs: {
                              placeholder: "Select Country",
                              size: "mini",
                              autocomplete: "off",
                              "data-testid": "contact-details-country-select",
                              "default-first-option": "",
                              filterable: "",
                              clearable: "",
                            },
                            on: {
                              change: function ($event) {
                                return _vm.updateField(
                                  "cnam_country",
                                  "",
                                  _vm.contact.cnam_country
                                )
                              },
                            },
                            model: {
                              value: _vm.contact.cnam_country,
                              callback: function ($$v) {
                                _vm.$set(_vm.contact, "cnam_country", $$v)
                              },
                              expression: "contact.cnam_country",
                            },
                          },
                          _vm._l(_vm.countries, function (country, index) {
                            return _c(
                              "el-option",
                              {
                                key: index,
                                attrs: {
                                  label: country.name,
                                  value: country.code,
                                },
                              },
                              [_c("span", [_vm._v(_vm._s(country.name))])]
                            )
                          }),
                          1
                        ),
                      ],
                  _vm._v(" "),
                  !_vm.edit.cnam_country &&
                  _vm.hasPermissionTo("update contact")
                    ? _c(
                        "div",
                        {
                          staticClass: "edit-field-button",
                          class: _vm.edit.cnam_country ? "active" : "",
                          attrs: {
                            "data-testid": "contact-details-edit-country",
                          },
                          on: {
                            click: function ($event) {
                              _vm.edit.cnam_country = true
                            },
                          },
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass:
                                "text-md text-greenish pointer no-select",
                            },
                            [
                              !_vm.edit.cnam_country
                                ? _c("i", { staticClass: "material-icons" }, [
                                    _vm._v("edit"),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.loading_field["cnam_country"]
                                ? _c(
                                    "i",
                                    { staticClass: "material-icons loader" },
                                    [_vm._v("")]
                                  )
                                : _vm._e(),
                            ]
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c("label", [_vm._v("Country")]),
                ],
                2
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-6" }, [
              _c("div", { staticClass: "md-form-group pb-2" }, [
                !_vm.edit.overall && !_vm.edit.cnam_zipcode
                  ? _c("input", {
                      directives: [{ name: "focus", rawName: "v-focus" }],
                      staticClass: "md-input",
                      attrs: {
                        disabled: true,
                        "data-testid": "contact-details-zip-code-input",
                      },
                      domProps: {
                        value: _vm._f("fixZipcode")(_vm.contact.cnam_zipcode),
                      },
                    })
                  : _c("input", {
                      directives: [{ name: "focus", rawName: "v-focus" }],
                      ref: "cnam_zipcode",
                      staticClass: "md-input",
                      attrs: {
                        "data-testid": "contact-details-edit-zip-code-input",
                      },
                      domProps: { value: _vm.contact.cnam_zipcode },
                      on: {
                        blur: function ($event) {
                          _vm.edit.cnam_zipcode = false
                        },
                        keyup: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          )
                            return null
                          return _vm.fastUpdateField("cnam_zipcode", $event)
                        },
                      },
                    }),
                _vm._v(" "),
                _vm.hasPermissionTo("update contact")
                  ? _c(
                      "div",
                      {
                        staticClass: "edit-field-button",
                        class:
                          _vm.edit.cnam_zipcode ||
                          _vm.loading_field["cnam_zipcode"]
                            ? "active"
                            : "",
                        attrs: { "data-testid": "edit-zip-code-icon" },
                        on: {
                          click: function ($event) {
                            _vm.edit.cnam_zipcode = true
                          },
                        },
                      },
                      [
                        _c(
                          "span",
                          {
                            staticClass:
                              "text-md text-greenish pointer no-select",
                          },
                          [
                            !_vm.edit.cnam_zipcode &&
                            !_vm.loading_field["cnam_zipcode"]
                              ? _c("i", { staticClass: "material-icons" }, [
                                  _vm._v("edit"),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.loading_field["cnam_zipcode"]
                              ? _c(
                                  "i",
                                  { staticClass: "material-icons loader" },
                                  [_vm._v("")]
                                )
                              : _vm._e(),
                          ]
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c("label", [_vm._v("Zip Code")]),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-6" }, [
              _c("div", { staticClass: "md-form-group pb-2" }, [
                !_vm.edit.overall && !_vm.edit.cnam_city
                  ? _c("input", {
                      directives: [{ name: "focus", rawName: "v-focus" }],
                      staticClass: "md-input",
                      attrs: {
                        disabled: true,
                        "data-testid": "contact-details-city-input",
                      },
                      domProps: {
                        value: _vm._f("fixCity")(_vm.contact.cnam_city),
                      },
                    })
                  : _c("input", {
                      directives: [{ name: "focus", rawName: "v-focus" }],
                      ref: "cnam_city",
                      staticClass: "md-input",
                      attrs: {
                        "data-testid": "contact-details-edit-city-input",
                      },
                      domProps: { value: _vm.contact.cnam_city },
                      on: {
                        blur: function ($event) {
                          _vm.edit.cnam_city = false
                        },
                        keyup: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          )
                            return null
                          return _vm.fastUpdateField("cnam_city", $event)
                        },
                      },
                    }),
                _vm._v(" "),
                _vm.hasPermissionTo("update contact")
                  ? _c(
                      "div",
                      {
                        staticClass: "edit-field-button",
                        class:
                          _vm.edit.cnam_city || _vm.loading_field["cnam_city"]
                            ? "active"
                            : "",
                        attrs: { "data-testid": "edit-city-icon" },
                        on: {
                          click: function ($event) {
                            _vm.edit.cnam_city = true
                          },
                        },
                      },
                      [
                        _c(
                          "span",
                          {
                            staticClass:
                              "text-md text-greenish pointer no-select",
                          },
                          [
                            !_vm.edit.cnam_city && !_vm.loading_field["csf1"]
                              ? _c("i", { staticClass: "material-icons" }, [
                                  _vm._v("edit"),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.loading_field["cnam_city"]
                              ? _c(
                                  "i",
                                  { staticClass: "material-icons loader" },
                                  [_vm._v("")]
                                )
                              : _vm._e(),
                          ]
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c("label", [_vm._v("City")]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-6" }, [
              _vm.contact.cnam_country &&
              ["US", "CA"].includes(_vm.contact.cnam_country)
                ? _c(
                    "div",
                    { staticClass: "md-form-group pb-2" },
                    [
                      !_vm.edit.overall && !_vm.edit.cnam_state
                        ? _c("input", {
                            directives: [{ name: "focus", rawName: "v-focus" }],
                            staticClass: "md-input",
                            attrs: {
                              disabled: true,
                              "data-testid": "contact-details-state-input",
                            },
                            domProps: {
                              value: _vm._f("fixState")(_vm.contact.cnam_state),
                            },
                          })
                        : [
                            _vm.contact.cnam_country == "US"
                              ? _c(
                                  "el-select",
                                  {
                                    staticClass: "w-100",
                                    attrs: {
                                      placeholder: "Select State",
                                      size: "mini",
                                      autocomplete: "off",
                                      "data-testid":
                                        "contact-details-state-select",
                                      "default-first-option": "",
                                      filterable: "",
                                      clearable: "",
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.updateField(
                                          "cnam_state",
                                          "",
                                          _vm.contact.cnam_state
                                        )
                                      },
                                    },
                                    model: {
                                      value: _vm.contact.cnam_state,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.contact, "cnam_state", $$v)
                                      },
                                      expression: "contact.cnam_state",
                                    },
                                  },
                                  _vm._l(
                                    _vm.country_states.US,
                                    function (state, index) {
                                      return _c(
                                        "el-option",
                                        {
                                          key: index,
                                          attrs: { label: state, value: state },
                                        },
                                        [_c("span", [_vm._v(_vm._s(state))])]
                                      )
                                    }
                                  ),
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.contact.cnam_country == "CA"
                              ? _c(
                                  "el-select",
                                  {
                                    staticClass: "w-100",
                                    attrs: {
                                      placeholder: "Select Province",
                                      size: "mini",
                                      autocomplete: "off",
                                      "data-testid":
                                        "contact-details-province-select",
                                      "default-first-option": "",
                                      filterable: "",
                                      clearable: "",
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.updateField(
                                          "cnam_state",
                                          "",
                                          _vm.contact.cnam_state
                                        )
                                      },
                                    },
                                    model: {
                                      value: _vm.contact.cnam_state,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.contact, "cnam_state", $$v)
                                      },
                                      expression: "contact.cnam_state",
                                    },
                                  },
                                  _vm._l(
                                    _vm.country_states.CA,
                                    function (state, index) {
                                      return _c(
                                        "el-option",
                                        {
                                          key: index,
                                          attrs: { label: state, value: state },
                                        },
                                        [_c("span", [_vm._v(_vm._s(state))])]
                                      )
                                    }
                                  ),
                                  1
                                )
                              : _vm._e(),
                          ],
                      _vm._v(" "),
                      !_vm.edit.cnam_state &&
                      _vm.hasPermissionTo("update contact")
                        ? _c(
                            "div",
                            {
                              staticClass: "edit-field-button",
                              class: _vm.edit.cnam_state ? "active" : "",
                              attrs: { "data-testid": "edit-state-icon" },
                              on: {
                                click: function ($event) {
                                  _vm.edit.cnam_state = true
                                },
                              },
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "text-md text-greenish pointer no-select",
                                },
                                [
                                  !_vm.edit.cnam_state
                                    ? _c(
                                        "i",
                                        { staticClass: "material-icons" },
                                        [_vm._v("edit")]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.loading_field["cnam_state"]
                                    ? _c(
                                        "i",
                                        {
                                          staticClass: "material-icons loader",
                                        },
                                        [_vm._v("")]
                                      )
                                    : _vm._e(),
                                ]
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c("label", [_vm._v("State")]),
                    ],
                    2
                  )
                : _vm._e(),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "md-form-group pb-2" },
            [
              !_vm.edit.overall && !_vm.edit.timezone
                ? _c("input", {
                    directives: [{ name: "focus", rawName: "v-focus" }],
                    staticClass: "md-input",
                    attrs: {
                      disabled: true,
                      "data-testid": "contact-details-time-zone-input",
                    },
                    domProps: {
                      value: _vm._f("fixTimezone")(_vm.contact.timezone),
                    },
                  })
                : [
                    _c(
                      "el-select",
                      {
                        staticClass: "w-100",
                        attrs: {
                          placeholder: "Select Time Zone",
                          size: "mini",
                          autocomplete: "off",
                          "data-testid": "contact-details-time-zone-select",
                          "default-first-option": "",
                          filterable: "",
                          clearable: "",
                        },
                        on: {
                          change: function ($event) {
                            return _vm.updateField(
                              "timezone",
                              "",
                              _vm.contact.timezone
                            )
                          },
                        },
                        model: {
                          value: _vm.contact.timezone,
                          callback: function ($$v) {
                            _vm.$set(_vm.contact, "timezone", $$v)
                          },
                          expression: "contact.timezone",
                        },
                      },
                      _vm._l(_vm.timezones, function (timezone, index) {
                        return _c(
                          "el-option",
                          {
                            key: index,
                            attrs: { label: timezone, value: timezone },
                          },
                          [_c("span", [_vm._v(_vm._s(timezone))])]
                        )
                      }),
                      1
                    ),
                  ],
              _vm._v(" "),
              !_vm.edit.timezone && _vm.isAdmin
                ? _c(
                    "div",
                    {
                      staticClass: "edit-field-button",
                      class: _vm.edit.timezone ? "active" : "",
                      attrs: { "data-testid": "edit-time-zone-icon" },
                      on: {
                        click: function ($event) {
                          _vm.edit.timezone = true
                        },
                      },
                    },
                    [
                      _c(
                        "span",
                        {
                          staticClass:
                            "text-md text-greenish pointer no-select",
                        },
                        [
                          !_vm.edit.timezone
                            ? _c("i", { staticClass: "material-icons" }, [
                                _vm._v("edit"),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.loading_field["timezone"]
                            ? _c(
                                "i",
                                { staticClass: "material-icons loader" },
                                [_vm._v("")]
                              )
                            : _vm._e(),
                        ]
                      ),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("label", [_vm._v("Time Zone")]),
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "md-form-group pb-2" },
            [
              !_vm.edit.overall && !_vm.edit.date_of_birth
                ? _c("input", {
                    directives: [{ name: "focus", rawName: "v-focus" }],
                    staticClass: "md-input",
                    attrs: {
                      disabled: true,
                      "data-testid": "contact-details-date-of-birth-input",
                    },
                    domProps: {
                      value: _vm._f("fixDateOfBirth")(
                        _vm.contact.date_of_birth
                      ),
                    },
                  })
                : [
                    _vm.auth.user.profile.country == "US"
                      ? _c("el-date-picker", {
                          staticClass: "w-100",
                          attrs: {
                            size: "mini",
                            type: "date",
                            format: "MM/dd/yyyy",
                            "value-format": "yyyy-MM-dd",
                            placeholder: "Date Of Birth",
                            "data-testid":
                              "contact-details-date-of-birth-picker-us",
                          },
                          on: {
                            change: function ($event) {
                              return _vm.updateField(
                                "date_of_birth",
                                "",
                                _vm.contact.date_of_birth
                              )
                            },
                          },
                          model: {
                            value: _vm.contact.date_of_birth,
                            callback: function ($$v) {
                              _vm.$set(_vm.contact, "date_of_birth", $$v)
                            },
                            expression: "contact.date_of_birth",
                          },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.auth.user.profile.country == "CA"
                      ? _c("el-date-picker", {
                          staticClass: "w-100",
                          attrs: {
                            size: "mini",
                            type: "date",
                            format: "dd/MM/yyyy",
                            "value-format": "yyyy-MM-dd",
                            placeholder: "Date Of Birth",
                            "data-testid":
                              "contact-details-date-of-birth-picker-ca",
                          },
                          on: {
                            change: function ($event) {
                              return _vm.updateField(
                                "date_of_birth",
                                "",
                                _vm.contact.date_of_birth
                              )
                            },
                          },
                          model: {
                            value: _vm.contact.date_of_birth,
                            callback: function ($$v) {
                              _vm.$set(_vm.contact, "date_of_birth", $$v)
                            },
                            expression: "contact.date_of_birth",
                          },
                        })
                      : _vm._e(),
                  ],
              _vm._v(" "),
              !_vm.edit.date_of_birth && _vm.hasPermissionTo("update contact")
                ? _c(
                    "div",
                    {
                      staticClass: "edit-field-button",
                      class: _vm.edit.date_of_birth ? "active" : "",
                      attrs: { "data-testid": "edit-date-of-birth-icon" },
                      on: {
                        click: function ($event) {
                          _vm.edit.date_of_birth = true
                        },
                      },
                    },
                    [
                      _c(
                        "span",
                        {
                          staticClass:
                            "text-md text-greenish pointer no-select",
                        },
                        [
                          !_vm.edit.date_of_birth
                            ? _c("i", { staticClass: "material-icons" }, [
                                _vm._v("edit"),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.loading_field["date_of_birth"]
                            ? _c(
                                "i",
                                { staticClass: "material-icons loader" },
                                [_vm._v("")]
                              )
                            : _vm._e(),
                        ]
                      ),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("label", [_vm._v("Date Of Birth")]),
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "md-form-group pb-2" },
            [
              !_vm.edit.overall && !_vm.edit.lead_source
                ? _c("input", {
                    directives: [{ name: "focus", rawName: "v-focus" }],
                    staticClass: "md-input",
                    attrs: {
                      disabled: true,
                      "data-testid": "contact-details-lead-source-input",
                    },
                    domProps: {
                      value: _vm._f("fixCustomField")(_vm.contact.lead_source),
                    },
                  })
                : [
                    _c("lead-source-selector", {
                      on: { change: _vm.changeLeadSource },
                      model: {
                        value: _vm.contact.lead_source_id,
                        callback: function ($$v) {
                          _vm.$set(_vm.contact, "lead_source_id", $$v)
                        },
                        expression: "contact.lead_source_id",
                      },
                    }),
                  ],
              _vm._v(" "),
              !_vm.edit.lead_source && _vm.hasPermissionTo("update contact")
                ? _c(
                    "div",
                    {
                      staticClass: "edit-field-button",
                      class:
                        _vm.edit.lead_source || _vm.loading_field["lead_source"]
                          ? "active"
                          : "",
                      attrs: { "data-testid": "edit-lead-source-icon" },
                      on: {
                        click: function ($event) {
                          _vm.edit.lead_source = true
                        },
                      },
                    },
                    [
                      _c(
                        "span",
                        {
                          staticClass:
                            "text-md text-greenish pointer no-select",
                        },
                        [
                          !_vm.edit.lead_source &&
                          !_vm.loading_field["lead_source"]
                            ? _c("i", { staticClass: "material-icons" }, [
                                _vm._v("edit"),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.loading_field["lead_source"]
                            ? _c(
                                "i",
                                { staticClass: "material-icons loader" },
                                [_vm._v("")]
                              )
                            : _vm._e(),
                        ]
                      ),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("label", [_vm._v("Lead Source")]),
            ],
            2
          ),
          _vm._v(" "),
          _c("div", { staticClass: "md-form-group pb-2" }, [
            _c("label", [_vm._v("Notes")]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "mt-2" },
              [_c("contact-note", { attrs: { contact: _vm.contact } })],
              1
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "md-form-group pb-2" }, [
            !_vm.edit.overall && !_vm.edit.csf1
              ? _c("input", {
                  directives: [{ name: "focus", rawName: "v-focus" }],
                  staticClass: "md-input",
                  attrs: {
                    disabled: true,
                    "data-testid": "contact-details-custom-field-1-input",
                  },
                  domProps: {
                    value: _vm._f("fixCustomField")(_vm.contact.csf1),
                  },
                })
              : _c("input", {
                  directives: [{ name: "focus", rawName: "v-focus" }],
                  ref: "csf1",
                  staticClass: "md-input",
                  attrs: {
                    "data-testid": "contact-details-edit-custom-field-1-input",
                  },
                  domProps: { value: _vm.contact.csf1 },
                  on: {
                    blur: function ($event) {
                      _vm.edit.csf1 = false
                    },
                    keyup: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      )
                        return null
                      return _vm.fastUpdateField("csf1", $event)
                    },
                  },
                }),
            _vm._v(" "),
            _vm.hasPermissionTo("update contact")
              ? _c(
                  "div",
                  {
                    staticClass: "edit-field-button",
                    class:
                      _vm.edit.csf1 || _vm.loading_field["csf1"]
                        ? "active"
                        : "",
                    attrs: { "data-testid": "edit-custom-field-1-icon" },
                    on: {
                      click: function ($event) {
                        _vm.edit.csf1 = true
                      },
                    },
                  },
                  [
                    _c(
                      "span",
                      {
                        staticClass: "text-md text-greenish pointer no-select",
                      },
                      [
                        !_vm.edit.csf1 && !_vm.loading_field["csf1"]
                          ? _c("i", { staticClass: "material-icons" }, [
                              _vm._v("edit"),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.loading_field["csf1"]
                          ? _c("i", { staticClass: "material-icons loader" }, [
                              _vm._v(""),
                            ])
                          : _vm._e(),
                      ]
                    ),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _c("label", [_vm._v("Custom Field 1")]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "md-form-group pb-2" }, [
            !_vm.edit.overall && !_vm.edit.csf2
              ? _c("input", {
                  directives: [{ name: "focus", rawName: "v-focus" }],
                  staticClass: "md-input",
                  attrs: {
                    disabled: true,
                    "data-testid": "contact-details-custom-field-2-input",
                  },
                  domProps: {
                    value: _vm._f("fixCustomField")(_vm.contact.csf2),
                  },
                })
              : _c("input", {
                  directives: [{ name: "focus", rawName: "v-focus" }],
                  ref: "csf2",
                  staticClass: "md-input",
                  attrs: {
                    "data-testid": "contact-details-edit-custom-field-2-input",
                  },
                  domProps: { value: _vm.contact.csf2 },
                  on: {
                    blur: function ($event) {
                      _vm.edit.csf2 = false
                    },
                    keyup: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      )
                        return null
                      return _vm.fastUpdateField("csf2", $event)
                    },
                  },
                }),
            _vm._v(" "),
            _vm.hasPermissionTo("update contact")
              ? _c(
                  "div",
                  {
                    staticClass: "edit-field-button",
                    class:
                      _vm.edit.csf2 || _vm.loading_field["csf2"]
                        ? "active"
                        : "",
                    attrs: { "data-testid": "edit-custom-field-2-icon" },
                    on: {
                      click: function ($event) {
                        _vm.edit.csf2 = true
                      },
                    },
                  },
                  [
                    _c(
                      "span",
                      {
                        staticClass: "text-md text-greenish pointer no-select",
                      },
                      [
                        !_vm.edit.csf2 && !_vm.loading_field["csf2"]
                          ? _c("i", { staticClass: "material-icons" }, [
                              _vm._v("edit"),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.loading_field["csf2"]
                          ? _c("i", { staticClass: "material-icons loader" }, [
                              _vm._v(""),
                            ])
                          : _vm._e(),
                      ]
                    ),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _c("label", [_vm._v("Custom Field 2")]),
          ]),
          _vm._v(" "),
          _c("contact-attributes", { attrs: { contact: _vm.contact } }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "md-form-group pb-2" },
            [
              !_vm.edit.overall && !_vm.edit.initial_campaign_id
                ? _c("input", {
                    directives: [{ name: "focus", rawName: "v-focus" }],
                    staticClass: "md-input",
                    attrs: {
                      disabled: true,
                      "data-testid": "contact-details-initial-line-input",
                    },
                    domProps: { value: _vm._f("fixName")(_vm.lineName) },
                  })
                : [
                    _c("campaign-selector", {
                      ref: "campaign_selector",
                      attrs: {
                        value: _vm.contact.initial_campaign_id,
                        show_paused: false,
                      },
                      on: { change: _vm.changeInitialCampaign },
                      model: {
                        value: _vm.contact.initial_campaign_id,
                        callback: function ($$v) {
                          _vm.$set(_vm.contact, "initial_campaign_id", $$v)
                        },
                        expression: "contact.initial_campaign_id",
                      },
                    }),
                  ],
              _vm._v(" "),
              _vm.hasPermissionTo("update contact")
                ? _c(
                    "div",
                    {
                      staticClass: "edit-field-button",
                      class:
                        _vm.edit.initial_campaign_id ||
                        _vm.loading_field["initial_campaign_id"]
                          ? "active"
                          : "",
                      attrs: { "data-testid": "edit-initial-line-icon" },
                      on: {
                        click: function ($event) {
                          _vm.edit.initial_campaign_id = true
                        },
                      },
                    },
                    [
                      _c(
                        "span",
                        {
                          staticClass:
                            "text-md text-greenish pointer no-select",
                        },
                        [
                          !_vm.edit.initial_campaign_id &&
                          !_vm.loading_field["initial_campaign_id"]
                            ? _c("i", { staticClass: "material-icons" }, [
                                _vm._v("edit"),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.loading_field["initial_campaign_id"]
                            ? _c(
                                "i",
                                { staticClass: "material-icons loader" },
                                [_vm._v("")]
                              )
                            : _vm._e(),
                        ]
                      ),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("label", [_vm._v("Initial Line")]),
            ],
            2
          ),
          _vm._v(" "),
          _c("div", { staticClass: "md-form-group pb-2" }, [
            _c("input", {
              staticClass: "md-input",
              attrs: {
                disabled: true,
                "data-testid": "contact-details-tcpa-approved-input",
              },
              domProps: {
                value: _vm._f("fixBooleanType")(_vm.contact.text_authorized),
              },
            }),
            _vm._v(" "),
            _c("label", [_vm._v("TCPA Approved")]),
          ]),
          _vm._v(" "),
          _vm.contact.created_at
            ? _c("div", { staticClass: "md-form-group pb-2" }, [
                _c("input", {
                  staticClass: "md-input",
                  attrs: {
                    disabled: true,
                    "data-testid": "contact-details-created-at-input",
                  },
                  domProps: {
                    value: _vm._f("fixFullDateUTCRelative")(
                      _vm.contact.created_at
                    ),
                  },
                }),
                _vm._v(" "),
                _c("label", [_vm._v("Created At")]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.first_outbound_call
            ? _c("div", { staticClass: "md-form-group pb-2" }, [
                _c("input", {
                  staticClass: "md-input",
                  attrs: {
                    disabled: true,
                    "data-testid": "contact-details-first-outbound-call-input",
                  },
                  domProps: { value: _vm.timeOfFirstOutboundCall },
                }),
                _vm._v(" "),
                _c("label", [_vm._v("Time of First Outbound Call")]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.first_outbound_call
            ? _c("div", { staticClass: "md-form-group pb-2" }, [
                _c("input", {
                  staticClass: "md-input",
                  attrs: {
                    disabled: true,
                    "data-testid":
                      "contact-details-time-to-first-outbound-call-input",
                  },
                  domProps: { value: _vm.timeToFirstOutboundCall },
                }),
                _vm._v(" "),
                _c("label", [_vm._v("Time to First Outbound Call")]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "md-form-group pb-2" }, [
            _c("input", {
              staticClass: "md-input",
              attrs: {
                disabled: true,
                "data-testid": "contact-details-intake-source-input",
              },
              domProps: {
                value: _vm._f("toUpperCase")(_vm.contact.intake_source),
              },
            }),
            _vm._v(" "),
            _c("label", [_vm._v("Intake Source")]),
          ]),
          _vm._v(" "),
          _vm.contact.external_data && _vm.contact.external_data.source
            ? _c("div", { staticClass: "md-form-group pb-2" }, [
                _c("input", {
                  staticClass: "md-input",
                  attrs: {
                    disabled: true,
                    "data-testid": "contact-details-external-source-input",
                  },
                  domProps: {
                    value: _vm._f("fixName")(_vm.contact.external_data.source),
                  },
                }),
                _vm._v(" "),
                _c("label", [_vm._v("External Source")]),
              ])
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }