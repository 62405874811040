var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading_campaign,
          expression: "loading_campaign",
        },
      ],
      staticClass: "app-body page-campaign-settings",
      style: { "min-height": "100vh" },
      attrs: { id: "view" },
    },
    [
      _vm.campaign && _vm.hasPermissionTo("view campaign")
        ? _c("div", [
            _c(
              "div",
              {
                staticClass: "fixed-header padding pt-0 pb-0",
                style: { top: _vm.total_offset + 50 + "px" },
              },
              [
                _c("div", { staticClass: "row mb-0 mt-0" }, [
                  _c(
                    "div",
                    { staticClass: "col-12 p-2" },
                    [
                      _c(
                        "router-link",
                        {
                          attrs: {
                            to: {
                              name: "Line Activity",
                              params: { campaign_id: _vm.campaign_id },
                            },
                          },
                        },
                        [
                          _c(
                            "button",
                            { staticClass: "btn purplish pull-left" },
                            [
                              _c("i", { staticClass: "fa fa-signal" }),
                              _vm._v(
                                "\n                            Activity\n                        "
                              ),
                            ]
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      !_vm.statics.whitelabel
                        ? _c(
                            "a",
                            {
                              attrs: {
                                href: "https://support.aloware.com/en/articles/9020245-managing-lines-settings-in-aloware-admin",
                                target: "_blank",
                              },
                            },
                            [_vm._m(0)]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ]),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "container-fluid pt-5 pb-3 d-relative" },
              [
                _c("div", { staticClass: "mb-4" }),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "card p-2" },
                  [
                    _c(
                      "el-form",
                      {
                        directives: [
                          {
                            name: "loading",
                            rawName: "v-loading",
                            value: _vm.loading,
                            expression: "loading",
                          },
                        ],
                        ref: "campaign_settings",
                        staticClass: "form-aloware",
                        attrs: {
                          "label-position": "top",
                          model: _vm.campaign,
                          rules: _vm.rules,
                        },
                        nativeOn: {
                          submit: function ($event) {
                            $event.preventDefault()
                            return _vm.submitForm("campaign_settings")
                          },
                        },
                      },
                      [
                        [
                          _c(
                            "el-tabs",
                            {
                              on: { "tab-click": _vm.tabClicked },
                              model: {
                                value: _vm.active_tab_name,
                                callback: function ($$v) {
                                  _vm.active_tab_name = $$v
                                },
                                expression: "active_tab_name",
                              },
                            },
                            [
                              _c(
                                "el-tab-pane",
                                {
                                  staticClass: "p-3",
                                  attrs: {
                                    name: "general-settings",
                                    "data-tab-error": "general_settings",
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    { attrs: { slot: "label" }, slot: "label" },
                                    [
                                      _vm._v("General"),
                                      _c("span", {
                                        domProps: {
                                          innerHTML: _vm._s(
                                            _vm.tabLabelErrorIcon(
                                              _vm.tab_errors.general_settings
                                            )
                                          ),
                                        },
                                      }),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "row" }, [
                                    _c(
                                      "div",
                                      { staticClass: "col-md-10" },
                                      [
                                        _c(
                                          "el-form-item",
                                          { attrs: { prop: "name" } },
                                          [
                                            _c("span", {
                                              staticClass: "page-anchor",
                                              attrs: { id: "name" },
                                            }),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              { staticClass: "form-label" },
                                              [_c("h5", [_vm._v("Name")])]
                                            ),
                                            _vm._v(" "),
                                            _c("el-input", {
                                              on: {
                                                input: function ($event) {
                                                  return _vm.preValidateForm(
                                                    "campaign_settings"
                                                  )
                                                },
                                              },
                                              model: {
                                                value: _vm.campaign.name,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.campaign,
                                                    "name",
                                                    $$v
                                                  )
                                                },
                                                expression: "campaign.name",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c("el-form-item", [
                                          _c(
                                            "div",
                                            { staticClass: "form-label" },
                                            [
                                              _c("h5", [_vm._v("Type")]),
                                              _vm._v(" "),
                                              _c(
                                                "b",
                                                {
                                                  staticClass: "mt-2 label",
                                                  class: _vm.campaign.is_fax
                                                    ? "purple-500"
                                                    : "bg-black opacity",
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                                    " +
                                                      _vm._s(
                                                        _vm.campaign.is_fax
                                                          ? "Fax Only"
                                                          : "Standard"
                                                      ) +
                                                      "\n                                                "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _c(
                                          "el-form-item",
                                          { attrs: { prop: "description" } },
                                          [
                                            _c("span", {
                                              staticClass: "page-anchor",
                                              attrs: { id: "description" },
                                            }),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              { staticClass: "form-label" },
                                              [
                                                _c("h5", [
                                                  _vm._v("Description"),
                                                ]),
                                                _vm._v(" "),
                                                _c("small", [
                                                  _vm._v(
                                                    "Describe the purpose of this line."
                                                  ),
                                                ]),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c("el-input", {
                                              attrs: {
                                                type: "textarea",
                                                maxlength: "190",
                                                autosize: {
                                                  minRows: 3,
                                                  maxRows: 3,
                                                },
                                                "show-word-limit": "",
                                              },
                                              on: {
                                                input: function ($event) {
                                                  return _vm.preValidateForm(
                                                    "campaign_settings"
                                                  )
                                                },
                                              },
                                              model: {
                                                value: _vm.campaign.description,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.campaign,
                                                    "description",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "campaign.description",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _vm.impersonated
                                          ? _c(
                                              "el-form-item",
                                              {
                                                attrs: { prop: "calling_only" },
                                              },
                                              [
                                                _c("span", {
                                                  staticClass: "page-anchor",
                                                  attrs: { id: "calling_only" },
                                                }),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  {
                                                    class: {
                                                      "cursor-not-allowed width-fit-content":
                                                        _vm.current_company
                                                          .calling_only,
                                                    },
                                                  },
                                                  [
                                                    _vm.current_company
                                                      .calling_only
                                                      ? _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "badge badge-warning py-1 mb-2",
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                                                    Set as Calling Only\n                                                "
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      {
                                                        class: {
                                                          "p-2 px-3 border border-warning":
                                                            _vm.current_company
                                                              .calling_only,
                                                        },
                                                      },
                                                      [
                                                        _c("div", [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "form-label",
                                                            },
                                                            [
                                                              _c("h5", [
                                                                _vm._v(
                                                                  "Calling Only"
                                                                ),
                                                              ]),
                                                              _vm._v(" "),
                                                              _c("small", [
                                                                _vm._v(
                                                                  "When this is active, the whole line will be flagged as calling only, including all the numbers in it."
                                                                ),
                                                              ]),
                                                              _vm._v(" "),
                                                              _c("small", [
                                                                _vm.hasRole([
                                                                  "Company Admin",
                                                                ]) &&
                                                                _vm
                                                                  .current_company
                                                                  .calling_only
                                                                  ? _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "mbt-2 mb-3",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "a",
                                                                          {
                                                                            staticClass:
                                                                              "text-blue-14",
                                                                            attrs:
                                                                              {
                                                                                href:
                                                                                  "/admin/company/" +
                                                                                  _vm
                                                                                    .current_company
                                                                                    .id,
                                                                                target:
                                                                                  "_blank",
                                                                              },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "u",
                                                                              [
                                                                                _vm._v(
                                                                                  "Modify"
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ]
                                                                    )
                                                                  : _vm._e(),
                                                              ]),
                                                            ]
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "switch-label",
                                                            },
                                                            [
                                                              _c("el-switch", {
                                                                attrs: {
                                                                  "active-color":
                                                                    "#00a344",
                                                                  disabled:
                                                                    _vm
                                                                      .current_company
                                                                      .calling_only,
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm.campaign
                                                                      .calling_only,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.campaign,
                                                                        "calling_only",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "campaign.calling_only",
                                                                },
                                                              }),
                                                              _vm._v(" "),
                                                              _c("label", [
                                                                _vm._v(
                                                                  "Enable Calling Only"
                                                                ),
                                                              ]),
                                                            ],
                                                            1
                                                          ),
                                                        ]),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              prop: "send_long_messages_as_mms_toggle",
                                            },
                                          },
                                          [
                                            _c("span", {
                                              staticClass: "page-anchor",
                                              attrs: {
                                                id: "send_long_messages_as_mms_toggle",
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                class: {
                                                  "cursor-not-allowed width-fit-content":
                                                    _vm.current_company
                                                      .send_long_messages_as_mms,
                                                },
                                              },
                                              [
                                                _vm.current_company
                                                  .send_long_messages_as_mms
                                                  ? _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "badge badge-warning py-1 mb-2",
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                                                    Set by Admin in Account Settings\n                                                "
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  {
                                                    class: {
                                                      "p-2 px-3 border border-warning":
                                                        _vm.current_company
                                                          .send_long_messages_as_mms,
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "el-tooltip",
                                                      {
                                                        attrs: {
                                                          disabled:
                                                            !_vm.current_company
                                                              .send_long_messages_as_mms,
                                                          placement: "top",
                                                          effect: "dark",
                                                          content:
                                                            "Requires Admin to change in Account Settings",
                                                        },
                                                      },
                                                      [
                                                        _c("div", [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "form-label",
                                                            },
                                                            [
                                                              _c("h5", [
                                                                _vm._v(
                                                                  "Send long text messages as MMS"
                                                                ),
                                                              ]),
                                                              _vm._v(" "),
                                                              _c("small", [
                                                                _vm._v(
                                                                  "Messages longer than 160 characters will automatically send as an MMS rather than multiple SMS segments."
                                                                ),
                                                              ]),
                                                              _vm._v(" "),
                                                              _c(
                                                                "small",
                                                                [
                                                                  _vm._v(
                                                                    "This setting can also be applied globally in\n                                                                    "
                                                                  ),
                                                                  _c(
                                                                    "router-link",
                                                                    {
                                                                      staticClass:
                                                                        "text-green",
                                                                      attrs: {
                                                                        to: {
                                                                          name: "Account",
                                                                        },
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "Account Settings"
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ]
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "switch-label",
                                                            },
                                                            [
                                                              _c("el-switch", {
                                                                attrs: {
                                                                  "active-color":
                                                                    "#00a344",
                                                                  disabled:
                                                                    _vm
                                                                      .current_company
                                                                      .send_long_messages_as_mms,
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm.campaign
                                                                      .send_long_messages_as_mms,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.campaign,
                                                                        "send_long_messages_as_mms",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "campaign.send_long_messages_as_mms",
                                                                },
                                                              }),
                                                              _vm._v(" "),
                                                              _c("label", [
                                                                _vm._v(
                                                                  "Enable Send long text messages as MMS"
                                                                ),
                                                              ]),
                                                            ],
                                                            1
                                                          ),
                                                        ]),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _vm._v(" "),
                                                _vm.hasRole([
                                                  "Company Admin",
                                                ]) &&
                                                _vm.current_company
                                                  .send_long_messages_as_mms
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "mbt-2 mb-3",
                                                      },
                                                      [
                                                        _c(
                                                          "a",
                                                          {
                                                            staticClass:
                                                              "text-blue-14",
                                                            attrs: {
                                                              href: "/account?tab=settings#send_long_messages_as_mms_toggle",
                                                              target: "_blank",
                                                            },
                                                          },
                                                          [
                                                            _c("u", [
                                                              _vm._v("Modify"),
                                                            ]),
                                                          ]
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _vm.lineHasMessagingService
                                          ? _c("el-form-item", [
                                              _c("span", {
                                                staticClass: "page-anchor",
                                                attrs: {
                                                  id: "messaging_service",
                                                },
                                              }),
                                              _vm._v(" "),
                                              _c("div", [
                                                _c(
                                                  "div",
                                                  { staticClass: "form-label" },
                                                  [
                                                    _c("h5", [
                                                      _vm._v("A2P Campaign"),
                                                    ]),
                                                    _vm._v(" "),
                                                    _c("small", [
                                                      _vm._v(
                                                        "This line is attached to the following A2P Campaign:"
                                                      ),
                                                    ]),
                                                    _vm._v(" "),
                                                    _c(
                                                      "ul",
                                                      { staticClass: "mt-3" },
                                                      _vm._l(
                                                        _vm.campaign
                                                          .messaging_services,
                                                        function (
                                                          messaging_service
                                                        ) {
                                                          return _c(
                                                            "li",
                                                            {
                                                              key: messaging_service.sid,
                                                            },
                                                            [
                                                              _c(
                                                                "router-link",
                                                                {
                                                                  attrs: {
                                                                    to: {
                                                                      name: "Account",
                                                                      query: {
                                                                        tab: "compliance",
                                                                      },
                                                                    },
                                                                    "data-testid":
                                                                      "word-analytics-router-link",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "span",
                                                                    {
                                                                      staticClass:
                                                                        "label py-1 mx-1 blue",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "\n                                                                    #" +
                                                                          _vm._s(
                                                                            messaging_service.id
                                                                          ) +
                                                                          " " +
                                                                          _vm._s(
                                                                            _vm.messagingServiceFormattedUseCase(
                                                                              messaging_service
                                                                                ?.a2p_use_case
                                                                                ?.use_case
                                                                            )
                                                                          ) +
                                                                          "\n                                                                "
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          )
                                                        }
                                                      ),
                                                      0
                                                    ),
                                                  ]
                                                ),
                                              ]),
                                            ])
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _c(
                                          "el-form-item",
                                          { staticClass: "no-border" },
                                          [
                                            _c("span", {
                                              staticClass: "page-anchor",
                                              attrs: { id: "phone_numbers" },
                                            }),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "d-flex w-full align-content-center justify-content-between",
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "form-label" },
                                                  [
                                                    _c("h5", [
                                                      _vm._v("Phone Numbers"),
                                                    ]),
                                                    _vm._v(" "),
                                                    _vm.shouldShowLocalPresenceText
                                                      ? _c("small", [
                                                          _vm._v(
                                                            "\n                                                        These are Local Presence phone numbers that your customers call or\n                                                        send messages to. Matching the outbound Caller ID with the prospect's\n                                                        phone number can increase pickups and connection rates in outbound\n                                                        calling campaigns. Learn more about Local Presence\n                                                        "
                                                          ),
                                                          _c(
                                                            "a",
                                                            {
                                                              staticClass:
                                                                "text-primary",
                                                              attrs: {
                                                                href: "https://support.aloware.com/en/articles/9020267-step-by-step-guide-setting-up-local-presence-with-aloware",
                                                                target:
                                                                  "_blank",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                                                            here.\n                                                        "
                                                              ),
                                                            ]
                                                          ),
                                                        ])
                                                      : _vm._e(),
                                                    _vm._v(" "),
                                                    _vm.isOfflineOrOnlineExternal &&
                                                    !_vm.campaign
                                                      .has_local_presence
                                                      ? _c("small", [
                                                          _vm._v(
                                                            "\n                                                        These are the phone numbers that your customers call or\n                                                        send message to.\n                                                    "
                                                          ),
                                                        ])
                                                      : _vm._e(),
                                                    _vm._v(" "),
                                                    _vm.campaign.type == 1 &&
                                                    _vm.campaign.subtype == 1
                                                      ? _c("small", [
                                                          _vm._v(
                                                            "\n                                                        Each new visitor will be assigned a unique phone number\n                                                        from this pool of numbers for 30 minutes.\n                                                        "
                                                          ),
                                                          _c("br"),
                                                          _vm._v(
                                                            "\n                                                        When they call in, they will be uniquely identified and tagged.\n                                                    "
                                                          ),
                                                        ])
                                                      : _vm._e(),
                                                    _vm._v(" "),
                                                    _vm.campaign.type == 1 &&
                                                    _vm.campaign.subtype == 2
                                                      ? _c("small", [
                                                          _vm._v(
                                                            "\n                                                        When a user gets to your website from this source, we\n                                                        will replace all the phone numbers on your website to\n                                                        this number.\n                                                    "
                                                          ),
                                                        ])
                                                      : _vm._e(),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "d-flex w-full-lg flex-wrap align-items-center justify-content-end",
                                                  },
                                                  [
                                                    _c(
                                                      "el-button",
                                                      {
                                                        staticClass: "m-2",
                                                        attrs: {
                                                          type: "primary",
                                                          plain: "",
                                                          disabled:
                                                            _vm.campaign.is_fax,
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            _vm.dialogCallerIdVisible = true
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                                                        Add Verified Caller ID\n                                                    "
                                                        ),
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    !_vm.loading &&
                                                    !_vm.isEnabledToAddNumber
                                                      ? _c(
                                                          "el-popover",
                                                          {
                                                            ref: "dialog-restricted-popover",
                                                            attrs: {
                                                              placement: "top",
                                                              width: "200",
                                                              "popper-class":
                                                                "btn-primary",
                                                              trigger: "hover",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "custom-message-display",
                                                              {
                                                                attrs: {
                                                                  config:
                                                                    _vm.customMessage(
                                                                      "line.add_number"
                                                                    ),
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                    _vm._v(" "),
                                                    !_vm.loading &&
                                                    !_vm.isEnabledToAddNumber
                                                      ? _c(
                                                          "el-button",
                                                          {
                                                            directives: [
                                                              {
                                                                name: "popover",
                                                                rawName:
                                                                  "v-popover:dialog-restricted-popover",
                                                                arg: "dialog-restricted-popover",
                                                              },
                                                            ],
                                                            staticClass: "m-2",
                                                            attrs: {
                                                              type: "success",
                                                              plain: "",
                                                              disabled: "",
                                                            },
                                                          },
                                                          [
                                                            _c("i", {
                                                              staticClass:
                                                                "fa fa-plus mr-2",
                                                            }),
                                                            _vm._v(
                                                              "\n                                                        Add Number To Line\n                                                    "
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                    _vm._v(" "),
                                                    !_vm.loading &&
                                                    _vm.isEnabledToAddNumber &&
                                                    _vm.hasPermissionTo(
                                                      "create campaign"
                                                    ) &&
                                                    (_vm.campaign
                                                      .is_hosted_sms ||
                                                      !_vm.campaign
                                                        .has_messaging_service) &&
                                                    !_vm.campaign.is_fax &&
                                                    !_vm.campaign
                                                      .has_local_presence
                                                      ? _c(
                                                          "el-button",
                                                          {
                                                            staticClass: "m-2",
                                                            attrs: {
                                                              type: "success",
                                                              plain: "",
                                                            },
                                                            on: {
                                                              click:
                                                                _vm.openModal,
                                                            },
                                                          },
                                                          [
                                                            _c("i", {
                                                              staticClass:
                                                                "fa fa-plus mr-2",
                                                            }),
                                                            _vm._v(
                                                              "\n                                                        Add Number To Line\n                                                    "
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                    _vm._v(" "),
                                                    _vm.campaign.is_fax
                                                      ? _c(
                                                          "el-tooltip",
                                                          {
                                                            attrs: {
                                                              effect: "dark",
                                                              content:
                                                                "To add another number, convert this line to a Standard type (currently a Fax Only type)",
                                                              placement: "top",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "el-button",
                                                              {
                                                                staticClass:
                                                                  "m-2 is-disabled",
                                                                attrs: {
                                                                  type: "success",
                                                                  plain: "",
                                                                },
                                                              },
                                                              [
                                                                _c("i", {
                                                                  staticClass:
                                                                    "fa fa-plus mr-2",
                                                                }),
                                                                _vm._v(
                                                                  "\n                                                            Add Number To Line\n                                                        "
                                                                ),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              [
                                                _c(
                                                  "el-dialog",
                                                  {
                                                    attrs: {
                                                      top: "10vh",
                                                      title:
                                                        "Unrent Phone Number",
                                                      width: "500px",
                                                      visible:
                                                        _vm.dialogUnrentNumber,
                                                      "append-to-body": "",
                                                    },
                                                    on: {
                                                      "update:visible":
                                                        function ($event) {
                                                          _vm.dialogUnrentNumber =
                                                            $event
                                                        },
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "alert alert-danger alert-aloware",
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                                                        This process is irreversible and the phone number will be lost forever.\n                                                    "
                                                        ),
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _vm.lineHasMessagingService
                                                      ? _c(
                                                          "p",
                                                          {
                                                            staticClass:
                                                              "text-black mb-2",
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                                                        This number will be removed from this A2P Campaign:\n                                                        "
                                                            ),
                                                            _c("br"),
                                                            _vm._v(" "),
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "font-weight-bold",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "#" +
                                                                    _vm._s(
                                                                      _vm
                                                                        .to_unrent
                                                                        ?.messaging_service
                                                                        ?.id
                                                                    ) +
                                                                    " " +
                                                                    _vm._s(
                                                                      _vm.messagingServiceFormattedUseCase(
                                                                        _vm
                                                                          .to_unrent
                                                                          ?.messaging_service
                                                                          ?.a2p_use_case
                                                                          ?.use_case
                                                                      )
                                                                    )
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                    _vm._v(" "),
                                                    _c(
                                                      "p",
                                                      {
                                                        staticClass:
                                                          "text-black mb-1",
                                                        staticStyle: {
                                                          "word-break":
                                                            "break-word",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                                                        Do you really want to unrent the phone number?\n                                                        "
                                                        ),
                                                        _c("br"),
                                                        _vm._v(" "),
                                                        _c("br"),
                                                        _vm._v(
                                                          "\n                                                        Please enter the phone number in the box below and click on unrent:\n                                                    "
                                                        ),
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c("el-input", {
                                                      attrs: {
                                                        placeholder:
                                                          "Enter Phone Number",
                                                        maxlength: "15",
                                                        keyup:
                                                          _vm.verifyUnrentNumber(),
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.verify_unrent,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.verify_unrent =
                                                            $$v
                                                        },
                                                        expression:
                                                          "verify_unrent",
                                                      },
                                                    }),
                                                    _vm._v(" "),
                                                    _c(
                                                      "small",
                                                      {
                                                        staticClass:
                                                          "text-muted",
                                                      },
                                                      [
                                                        _vm._v(
                                                          "e.g. " +
                                                            _vm._s(
                                                              _vm._f(
                                                                "fixPhone"
                                                              )(
                                                                _vm.to_unrent
                                                                  .phone_number
                                                              )
                                                            )
                                                        ),
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "dialog-footer",
                                                        attrs: {
                                                          slot: "footer",
                                                        },
                                                        slot: "footer",
                                                      },
                                                      [
                                                        _c(
                                                          "button",
                                                          {
                                                            staticClass: "btn",
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                $event.preventDefault()
                                                                _vm.dialogUnrentNumber = false
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                                                            Cancel\n                                                        "
                                                            ),
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "button",
                                                          {
                                                            staticClass:
                                                              "btn danger ml-2",
                                                            attrs: {
                                                              disabled:
                                                                _vm.confirm_unrent,
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                $event.preventDefault()
                                                                return _vm.unrentNumber(
                                                                  _vm.to_unrent
                                                                    .id,
                                                                  _vm.to_unrent
                                                                    .phone_number
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                                                            Unrent\n                                                        "
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "el-dialog",
                                                  {
                                                    attrs: {
                                                      top: "10vh",
                                                      title:
                                                        "Delete Phone Number",
                                                      width: "500px",
                                                      visible:
                                                        _vm.dialogDeleteNumber,
                                                      "append-to-body": "",
                                                    },
                                                    on: {
                                                      "update:visible":
                                                        function ($event) {
                                                          _vm.dialogDeleteNumber =
                                                            $event
                                                        },
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "alert alert-warning alert-aloware",
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                                                        If in the future you want to use this number, you will have to validate it again.\n                                                    "
                                                        ),
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "p",
                                                      {
                                                        staticClass:
                                                          "text-black mb-1",
                                                        staticStyle: {
                                                          "word-break":
                                                            "break-word",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                                                        Do you really want to delete the phone number?\n                                                        "
                                                        ),
                                                        _c("br"),
                                                        _vm._v(" "),
                                                        _c("br"),
                                                        _vm._v(
                                                          "\n                                                        Please enter the phone number in the box below and click on delete:\n                                                    "
                                                        ),
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c("el-input", {
                                                      attrs: {
                                                        placeholder:
                                                          "Enter Phone Number",
                                                        maxlength: "15",
                                                        keyup:
                                                          _vm.verifyDeleteNumber(),
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.verify_delete,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.verify_delete =
                                                            $$v
                                                        },
                                                        expression:
                                                          "verify_delete",
                                                      },
                                                    }),
                                                    _vm._v(" "),
                                                    _c(
                                                      "small",
                                                      {
                                                        staticClass:
                                                          "text-muted",
                                                      },
                                                      [
                                                        _vm._v(
                                                          "e.g. " +
                                                            _vm._s(
                                                              _vm._f(
                                                                "fixPhone"
                                                              )(
                                                                _vm.to_delete
                                                                  .phone_number
                                                              )
                                                            )
                                                        ),
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "dialog-footer",
                                                        attrs: {
                                                          slot: "footer",
                                                        },
                                                        slot: "footer",
                                                      },
                                                      [
                                                        _c(
                                                          "button",
                                                          {
                                                            staticClass: "btn",
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                $event.preventDefault()
                                                                _vm.dialogDeleteNumber = false
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                                                            Cancel\n                                                        "
                                                            ),
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "button",
                                                          {
                                                            staticClass:
                                                              "btn danger ml-2",
                                                            attrs: {
                                                              disabled:
                                                                _vm.confirm_delete,
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                $event.preventDefault()
                                                                return _vm.deleteNumber(
                                                                  _vm.to_delete
                                                                    .id,
                                                                  _vm.to_delete
                                                                    .phone_number
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                                                            Delete\n                                                        "
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "el-table",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "loading",
                                                        rawName: "v-loading",
                                                        value:
                                                          _vm.incoming_numbers_loading,
                                                        expression:
                                                          "incoming_numbers_loading",
                                                      },
                                                    ],
                                                    key: _vm.incoming_numbers_key,
                                                    staticClass: "w-full",
                                                    attrs: {
                                                      data: _vm.campaign
                                                        .incoming_numbers,
                                                      fit: "",
                                                      stripe: "",
                                                    },
                                                  },
                                                  [
                                                    _c("el-table-column", {
                                                      attrs: {
                                                        label: "Phone Number",
                                                      },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "default",
                                                            fn: function (
                                                              scope
                                                            ) {
                                                              return [
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "text-greyish",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm._f(
                                                                          "fixPhone"
                                                                        )(
                                                                          scope
                                                                            .row
                                                                            .phone_number
                                                                        )
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                                _vm._v(" "),
                                                                _c("div", [
                                                                  scope.row
                                                                    .do_not_use
                                                                    ? _c(
                                                                        "span",
                                                                        {
                                                                          staticClass:
                                                                            "mr-2 d-inline-block label red",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "\n                                                                    Do Not Use\n                                                                "
                                                                          ),
                                                                        ]
                                                                      )
                                                                    : _vm._e(),
                                                                  _vm._v(" "),
                                                                  scope.row
                                                                    .phone_number ===
                                                                    _vm.campaign
                                                                      .incoming_number &&
                                                                  _vm.campaign
                                                                    .incoming_numbers
                                                                    .length ===
                                                                    1
                                                                    ? _c(
                                                                        "span",
                                                                        {
                                                                          staticClass:
                                                                            "mr-2 d-inline-block label blue",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "\n                                                                    Primary\n                                                                "
                                                                          ),
                                                                        ]
                                                                      )
                                                                    : _vm._e(),
                                                                  _vm._v(" "),
                                                                  scope.row
                                                                    .default_callerid ===
                                                                  true
                                                                    ? _c(
                                                                        "span",
                                                                        {
                                                                          staticClass:
                                                                            "mr-2 d-inline-block label greenish",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "\n                                                                    Default Call Mask\n                                                                "
                                                                          ),
                                                                        ]
                                                                      )
                                                                    : _vm._e(),
                                                                  _vm._v(" "),
                                                                  scope.row
                                                                    .is_fax
                                                                    ? _c(
                                                                        "span",
                                                                        {
                                                                          staticClass:
                                                                            "mr-2 d-inline-block label purple",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "\n                                                                    Fax\n                                                                "
                                                                          ),
                                                                        ]
                                                                      )
                                                                    : _vm._e(),
                                                                  _vm._v(" "),
                                                                  scope.row
                                                                    .is_verified_caller_id
                                                                    ? _c(
                                                                        "span",
                                                                        {
                                                                          staticClass:
                                                                            "mr-2 d-inline-block label teal",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "\n                                                                    Verified Caller ID\n                                                                "
                                                                          ),
                                                                        ]
                                                                      )
                                                                    : _vm._e(),
                                                                ]),
                                                              ]
                                                            },
                                                          },
                                                        ],
                                                        null,
                                                        false,
                                                        1631530757
                                                      ),
                                                    }),
                                                    _vm._v(" "),
                                                    _vm.impersonated
                                                      ? _c("el-table-column", {
                                                          attrs: {
                                                            label: "Carrier",
                                                          },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key: "default",
                                                                fn: function (
                                                                  scope
                                                                ) {
                                                                  return [
                                                                    !scope.row
                                                                      .is_verified_caller_id
                                                                      ? _c(
                                                                          "div",
                                                                          [
                                                                            _c(
                                                                              "span",
                                                                              {
                                                                                staticClass:
                                                                                  "d-inline-block label text-capitalize",
                                                                                class:
                                                                                  _vm.carrierBadgeClass(
                                                                                    scope
                                                                                      .row
                                                                                      .carrier_name
                                                                                  ),
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  "\n                                                            " +
                                                                                    _vm._s(
                                                                                      scope
                                                                                        .row
                                                                                        .carrier_name
                                                                                    ) +
                                                                                    "\n                                                          "
                                                                                ),
                                                                              ]
                                                                            ),
                                                                            _vm._v(
                                                                              " "
                                                                            ),
                                                                            scope
                                                                              .row
                                                                              .hosted_carrier_name
                                                                              ? _c(
                                                                                  "span",
                                                                                  {
                                                                                    staticClass:
                                                                                      "d-inline-block label hosted-on-badge",
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      "\n                                                           Hosted on "
                                                                                    ),
                                                                                    _c(
                                                                                      "span",
                                                                                      {
                                                                                        staticClass:
                                                                                          "text-capitalize",
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          _vm._s(
                                                                                            scope
                                                                                              .row
                                                                                              .hosted_carrier_name
                                                                                          )
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                  ]
                                                                                )
                                                                              : _vm._e(),
                                                                          ]
                                                                        )
                                                                      : _vm._e(),
                                                                  ]
                                                                },
                                                              },
                                                            ],
                                                            null,
                                                            false,
                                                            505356070
                                                          ),
                                                        })
                                                      : _vm._e(),
                                                    _vm._v(" "),
                                                    _c("el-table-column", {
                                                      attrs: { label: "Type" },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "default",
                                                            fn: function (
                                                              scope
                                                            ) {
                                                              return [
                                                                !_vm.isUkNumber(
                                                                  scope.row
                                                                    .phone_number
                                                                )
                                                                  ? _c(
                                                                      "div",
                                                                      [
                                                                        _vm._l(
                                                                          _vm.phoneNumberTypes(
                                                                            scope.row
                                                                          ),
                                                                          function (
                                                                            number_type
                                                                          ) {
                                                                            return _c(
                                                                              "span",
                                                                              {
                                                                                key: number_type.order,
                                                                                class: `d-inline-block label ${number_type.class}`,
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  "\n                                                                    " +
                                                                                    _vm._s(
                                                                                      number_type.label
                                                                                    ) +
                                                                                    "\n                                                                "
                                                                                ),
                                                                              ]
                                                                            )
                                                                          }
                                                                        ),
                                                                        _vm._v(
                                                                          " "
                                                                        ),
                                                                        _c(
                                                                          "br"
                                                                        ),
                                                                      ],
                                                                      2
                                                                    )
                                                                  : _c(
                                                                      "span",
                                                                      {
                                                                        staticClass:
                                                                          "mr-2 d-inline-block label purple",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "\n                                                                " +
                                                                            _vm._s(
                                                                              _vm.ukNumberTag(
                                                                                scope.row
                                                                              )
                                                                            ) +
                                                                            "\n                                                            "
                                                                        ),
                                                                      ]
                                                                    ),
                                                                _vm._v(" "),
                                                                scope.row
                                                                  .calling_only
                                                                  ? _c(
                                                                      "span",
                                                                      {
                                                                        staticClass:
                                                                          "d-inline-block label black",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "\n                                                                Call Only\n                                                            "
                                                                        ),
                                                                      ]
                                                                    )
                                                                  : _vm._e(),
                                                                _vm._v(" "),
                                                                _vm.isFakeNumber(
                                                                  scope.row
                                                                )
                                                                  ? _c(
                                                                      "span",
                                                                      {
                                                                        staticClass:
                                                                          "d-inline-block label black",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "\n                                                                Fake\n                                                            "
                                                                        ),
                                                                      ]
                                                                    )
                                                                  : _vm._e(),
                                                                _vm._v(" "),
                                                                _vm.lineIsGrayPoolHosted(
                                                                  scope.row
                                                                ) &&
                                                                _vm.impersonated
                                                                  ? _c(
                                                                      "el-tooltip",
                                                                      {
                                                                        staticClass:
                                                                          "item mr-2",
                                                                        attrs: {
                                                                          effect:
                                                                            "dark",
                                                                          content:
                                                                            "This information is only visible via Impersonation in Alo Super Admin.",
                                                                          placement:
                                                                            "top",
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "span",
                                                                          {
                                                                            staticClass:
                                                                              "d-inline-block label gray",
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "\n                                                                            Using "
                                                                            ),
                                                                            _c(
                                                                              "span",
                                                                              {
                                                                                staticClass:
                                                                                  "text-capitalize",
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    scope
                                                                                      .row
                                                                                      .hosted_carrier_name
                                                                                  )
                                                                                ),
                                                                              ]
                                                                            ),
                                                                            _vm._v(
                                                                              " Bridge\n                                                                        "
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ]
                                                                    )
                                                                  : _vm._e(),
                                                              ]
                                                            },
                                                          },
                                                        ],
                                                        null,
                                                        false,
                                                        2383781861
                                                      ),
                                                    }),
                                                    _vm._v(" "),
                                                    _c("el-table-column", {
                                                      attrs: {
                                                        label: "Location",
                                                      },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "default",
                                                            fn: function (
                                                              scope
                                                            ) {
                                                              return [
                                                                !scope.row
                                                                  .is_tollfree
                                                                  ? [
                                                                      scope.row
                                                                        .city &&
                                                                      scope.row
                                                                        .state
                                                                        ? _c(
                                                                            "span",
                                                                            [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  scope
                                                                                    .row
                                                                                    .city
                                                                                ) +
                                                                                  ", " +
                                                                                  _vm._s(
                                                                                    scope
                                                                                      .row
                                                                                      .state
                                                                                  )
                                                                              ),
                                                                            ]
                                                                          )
                                                                        : scope
                                                                            .row
                                                                            .city
                                                                        ? _c(
                                                                            "span",
                                                                            [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  scope
                                                                                    .row
                                                                                    .city
                                                                                )
                                                                              ),
                                                                            ]
                                                                          )
                                                                        : scope
                                                                            .row
                                                                            .state
                                                                        ? _c(
                                                                            "span",
                                                                            [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  scope
                                                                                    .row
                                                                                    .state
                                                                                )
                                                                              ),
                                                                            ]
                                                                          )
                                                                        : _c(
                                                                            "span",
                                                                            [
                                                                              _vm._v(
                                                                                "-"
                                                                              ),
                                                                            ]
                                                                          ),
                                                                    ]
                                                                  : [
                                                                      _c(
                                                                        "span",
                                                                        [
                                                                          _vm._v(
                                                                            "-"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ],
                                                              ]
                                                            },
                                                          },
                                                        ],
                                                        null,
                                                        false,
                                                        2717137964
                                                      ),
                                                    }),
                                                    _vm._v(" "),
                                                    _c("el-table-column", {
                                                      attrs: {
                                                        label: "Compliance Met",
                                                      },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "default",
                                                            fn: function (
                                                              scope
                                                            ) {
                                                              return [
                                                                scope.row
                                                                  .registered_stir_shaken
                                                                  ? _c(
                                                                      "span",
                                                                      {
                                                                        staticClass:
                                                                          "label text-xxs mx-1 green",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "STIR/SHAKEN"
                                                                        ),
                                                                      ]
                                                                    )
                                                                  : _vm._e(),
                                                                _vm._v(" "),
                                                                scope.row
                                                                  .registered_cnam
                                                                  ? _c(
                                                                      "span",
                                                                      {
                                                                        staticClass:
                                                                          "label text-xxs mx-1",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "CNAM"
                                                                        ),
                                                                      ]
                                                                    )
                                                                  : _vm._e(),
                                                              ]
                                                            },
                                                          },
                                                        ],
                                                        null,
                                                        false,
                                                        2748585627
                                                      ),
                                                    }),
                                                    _vm._v(" "),
                                                    _c("el-table-column", {
                                                      attrs: {
                                                        label: "A2P Campaign",
                                                      },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "default",
                                                            fn: function (
                                                              scope
                                                            ) {
                                                              return [
                                                                scope.row
                                                                  .messaging_service
                                                                  ? _c("span", [
                                                                      _c(
                                                                        "span",
                                                                        {
                                                                          staticClass:
                                                                            "d-inline-block label text-xxs mx-1 blue",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "\n                                                                    #" +
                                                                              _vm._s(
                                                                                scope
                                                                                  .row
                                                                                  .messaging_service
                                                                                  .id
                                                                              ) +
                                                                              " " +
                                                                              _vm._s(
                                                                                _vm.messagingServiceFormattedUseCase(
                                                                                  scope
                                                                                    .row
                                                                                    .messaging_service
                                                                                    ?.a2p_use_case
                                                                                    ?.use_case
                                                                                )
                                                                              ) +
                                                                              "\n                                                                "
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ])
                                                                  : _c("span", [
                                                                      _vm._v(
                                                                        "-"
                                                                      ),
                                                                    ]),
                                                              ]
                                                            },
                                                          },
                                                        ],
                                                        null,
                                                        false,
                                                        1980051705
                                                      ),
                                                    }),
                                                    _vm._v(" "),
                                                    _c("el-table-column", {
                                                      attrs: {
                                                        label:
                                                          "Line Capabilities",
                                                      },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "default",
                                                            fn: function (
                                                              scope
                                                            ) {
                                                              return [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "clear mt-2",
                                                                  },
                                                                  [
                                                                    _vm
                                                                      .line_capability_icon_colors[
                                                                      scope.row
                                                                        .id
                                                                    ]
                                                                      ? _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "row",
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "div",
                                                                              {
                                                                                staticClass:
                                                                                  "col",
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "el-tooltip",
                                                                                  {
                                                                                    staticClass:
                                                                                      "item mr-2",
                                                                                    attrs:
                                                                                      {
                                                                                        effect:
                                                                                          "dark",
                                                                                        content:
                                                                                          _vm._f(
                                                                                            "incomingNumberVoiceCapabilityContent"
                                                                                          )(
                                                                                            scope
                                                                                              .row
                                                                                              .id,
                                                                                            _vm.campaign
                                                                                          ),
                                                                                        placement:
                                                                                          "top",
                                                                                      },
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "span",
                                                                                      {
                                                                                        staticClass:
                                                                                          "cursor-default",
                                                                                        class:
                                                                                          _vm
                                                                                            .line_capability_icon_colors[
                                                                                            scope
                                                                                              .row
                                                                                              .id
                                                                                          ][
                                                                                            "voice"
                                                                                          ],
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "i",
                                                                                          {
                                                                                            staticClass:
                                                                                              "material-icons",
                                                                                          },
                                                                                          [
                                                                                            _vm._v(
                                                                                              "call"
                                                                                            ),
                                                                                          ]
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                                _vm._v(
                                                                                  " "
                                                                                ),
                                                                                _c(
                                                                                  "el-tooltip",
                                                                                  {
                                                                                    staticClass:
                                                                                      "item mr-2",
                                                                                    attrs:
                                                                                      {
                                                                                        effect:
                                                                                          "dark",
                                                                                        content:
                                                                                          _vm._f(
                                                                                            "incomingNumberFaxCapabilityContent"
                                                                                          )(
                                                                                            scope
                                                                                              .row
                                                                                              .id,
                                                                                            _vm.campaign
                                                                                          ),
                                                                                        placement:
                                                                                          "top",
                                                                                      },
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "span",
                                                                                      {
                                                                                        staticClass:
                                                                                          "cursor-default",
                                                                                        class:
                                                                                          _vm
                                                                                            .line_capability_icon_colors[
                                                                                            scope
                                                                                              .row
                                                                                              .id
                                                                                          ][
                                                                                            "fax"
                                                                                          ],
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "i",
                                                                                          {
                                                                                            staticClass:
                                                                                              "fa fa-fax",
                                                                                          }
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                                _vm._v(
                                                                                  " "
                                                                                ),
                                                                                _c(
                                                                                  "el-tooltip",
                                                                                  {
                                                                                    staticClass:
                                                                                      "item mr-2",
                                                                                    attrs:
                                                                                      {
                                                                                        effect:
                                                                                          "dark",
                                                                                        content:
                                                                                          _vm._f(
                                                                                            "incomingNumberSMSCapabilityContent"
                                                                                          )(
                                                                                            scope
                                                                                              .row
                                                                                              .id,
                                                                                            _vm.campaign
                                                                                          ),
                                                                                        placement:
                                                                                          "top",
                                                                                      },
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "span",
                                                                                      {
                                                                                        staticClass:
                                                                                          "cursor-default",
                                                                                        class:
                                                                                          _vm
                                                                                            .line_capability_icon_colors[
                                                                                            scope
                                                                                              .row
                                                                                              .id
                                                                                          ][
                                                                                            "sms"
                                                                                          ],
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "i",
                                                                                          {
                                                                                            staticClass:
                                                                                              "material-icons",
                                                                                          },
                                                                                          [
                                                                                            _vm._v(
                                                                                              "forum"
                                                                                            ),
                                                                                          ]
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                                _vm._v(
                                                                                  " "
                                                                                ),
                                                                                _c(
                                                                                  "el-tooltip",
                                                                                  {
                                                                                    staticClass:
                                                                                      "item",
                                                                                    attrs:
                                                                                      {
                                                                                        effect:
                                                                                          "dark",
                                                                                        content:
                                                                                          _vm._f(
                                                                                            "incomingNumberMMSCapabilityContent"
                                                                                          )(
                                                                                            scope
                                                                                              .row
                                                                                              .id,
                                                                                            _vm.campaign
                                                                                          ),
                                                                                        placement:
                                                                                          "top",
                                                                                      },
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "span",
                                                                                      {
                                                                                        staticClass:
                                                                                          "cursor-default",
                                                                                        class:
                                                                                          _vm
                                                                                            .line_capability_icon_colors[
                                                                                            scope
                                                                                              .row
                                                                                              .id
                                                                                          ][
                                                                                            "mms"
                                                                                          ],
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "i",
                                                                                          {
                                                                                            staticClass:
                                                                                              "material-icons",
                                                                                          },
                                                                                          [
                                                                                            _vm._v(
                                                                                              "insert_photo"
                                                                                            ),
                                                                                          ]
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                              ],
                                                                              1
                                                                            ),
                                                                          ]
                                                                        )
                                                                      : _vm._e(),
                                                                  ]
                                                                ),
                                                              ]
                                                            },
                                                          },
                                                        ],
                                                        null,
                                                        false,
                                                        2272292425
                                                      ),
                                                    }),
                                                    _vm._v(" "),
                                                    _c("el-table-column", {
                                                      attrs: {
                                                        align: "center",
                                                        width: "215",
                                                        fixed: "right",
                                                      },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "header",
                                                            fn: function () {
                                                              return [
                                                                _c("el-input", {
                                                                  attrs: {
                                                                    clearable:
                                                                      "",
                                                                    size: "medium",
                                                                    placeholder:
                                                                      "Type to search",
                                                                  },
                                                                  on: {
                                                                    input:
                                                                      _vm.searchIncomingNumbers,
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm.incoming_numbers_search,
                                                                    callback:
                                                                      function (
                                                                        $$v
                                                                      ) {
                                                                        _vm.incoming_numbers_search =
                                                                          $$v
                                                                      },
                                                                    expression:
                                                                      "incoming_numbers_search",
                                                                  },
                                                                }),
                                                              ]
                                                            },
                                                            proxy: true,
                                                          },
                                                          {
                                                            key: "default",
                                                            fn: function (
                                                              scope
                                                            ) {
                                                              return [
                                                                _c("div", [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "row",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "col-12",
                                                                        },
                                                                        [
                                                                          (scope
                                                                            .row
                                                                            .default_callerid ===
                                                                            false &&
                                                                            _vm
                                                                              .usableNumbers
                                                                              .length >
                                                                              1) ||
                                                                          (_vm.show_mask_button &&
                                                                            scope
                                                                              .row
                                                                              .default_callerid ===
                                                                              false)
                                                                            ? _c(
                                                                                "button",
                                                                                {
                                                                                  staticClass:
                                                                                    "btn btn-sm btn-block greenish",
                                                                                  attrs:
                                                                                    {
                                                                                      disabled:
                                                                                        scope
                                                                                          .row
                                                                                          .do_not_use ===
                                                                                        true,
                                                                                    },
                                                                                  on: {
                                                                                    click:
                                                                                      function (
                                                                                        $event
                                                                                      ) {
                                                                                        $event.preventDefault()
                                                                                        return _vm.defaultCallerIdNumber(
                                                                                          scope
                                                                                            .row
                                                                                            .id,
                                                                                          scope
                                                                                            .row
                                                                                            .phone_number,
                                                                                          true
                                                                                        )
                                                                                      },
                                                                                  },
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "i",
                                                                                    {
                                                                                      staticClass:
                                                                                        "fa fa-mobile-alt pull-left",
                                                                                    }
                                                                                  ),
                                                                                  _vm._v(
                                                                                    "\n                                                                            Default Call Mask\n                                                                        "
                                                                                  ),
                                                                                ]
                                                                              )
                                                                            : _vm._e(),
                                                                          _vm._v(
                                                                            " "
                                                                          ),
                                                                          scope
                                                                            .row
                                                                            .default_callerid ===
                                                                          true
                                                                            ? _c(
                                                                                "button",
                                                                                {
                                                                                  staticClass:
                                                                                    "btn btn-sm btn-block purple-500",
                                                                                  on: {
                                                                                    click:
                                                                                      function (
                                                                                        $event
                                                                                      ) {
                                                                                        $event.preventDefault()
                                                                                        return _vm.defaultCallerIdNumber(
                                                                                          scope
                                                                                            .row
                                                                                            .id,
                                                                                          scope
                                                                                            .row
                                                                                            .phone_number,
                                                                                          false
                                                                                        )
                                                                                      },
                                                                                  },
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "i",
                                                                                    {
                                                                                      staticClass:
                                                                                        "fa fa-mobile-alt pull-left",
                                                                                    }
                                                                                  ),
                                                                                  _vm._v(
                                                                                    "\n                                                                            Unset Default Call Mask\n                                                                        "
                                                                                  ),
                                                                                ]
                                                                              )
                                                                            : _vm._e(),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _vm._v(" "),
                                                                  !_vm.campaign
                                                                    .is_fax
                                                                    ? _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "row mt-2",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "col-12",
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "transfer-phone-number-popover",
                                                                                {
                                                                                  attrs:
                                                                                    {
                                                                                      current_campaign:
                                                                                        _vm.campaign,
                                                                                      campaigns:
                                                                                        _vm.campaigns,
                                                                                      incoming_number:
                                                                                        scope.row,
                                                                                    },
                                                                                }
                                                                              ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                        ]
                                                                      )
                                                                    : _vm._e(),
                                                                  _vm._v(" "),
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "row mt-2",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "col-12",
                                                                        },
                                                                        [
                                                                          !scope
                                                                            .row
                                                                            .do_not_use
                                                                            ? _c(
                                                                                "button",
                                                                                {
                                                                                  staticClass:
                                                                                    "btn btn-sm btn-block deep-orange-500",
                                                                                  on: {
                                                                                    click:
                                                                                      function (
                                                                                        $event
                                                                                      ) {
                                                                                        $event.preventDefault()
                                                                                        return _vm.doNotUseOrActivateNumber(
                                                                                          scope.row,
                                                                                          true
                                                                                        )
                                                                                      },
                                                                                  },
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "i",
                                                                                    {
                                                                                      staticClass:
                                                                                        "fa fa-phone-slash pull-left",
                                                                                    }
                                                                                  ),
                                                                                  _vm._v(
                                                                                    "\n                                                                            Do Not Use\n                                                                        "
                                                                                  ),
                                                                                ]
                                                                              )
                                                                            : _c(
                                                                                "button",
                                                                                {
                                                                                  staticClass:
                                                                                    "btn btn-sm btn-block deep-orange-500",
                                                                                  on: {
                                                                                    click:
                                                                                      function (
                                                                                        $event
                                                                                      ) {
                                                                                        $event.preventDefault()
                                                                                        return _vm.doNotUseOrActivateNumber(
                                                                                          scope.row,
                                                                                          false
                                                                                        )
                                                                                      },
                                                                                  },
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "i",
                                                                                    {
                                                                                      staticClass:
                                                                                        "fa fa-play pull-left",
                                                                                    }
                                                                                  ),
                                                                                  _vm._v(
                                                                                    "\n                                                                            Activate\n                                                                        "
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _vm._v(" "),
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "row mt-2",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "col-12",
                                                                        },
                                                                        [
                                                                          scope
                                                                            .row
                                                                            .is_verified_caller_id
                                                                            ? _c(
                                                                                "button",
                                                                                {
                                                                                  staticClass:
                                                                                    "btn btn-sm btn-block danger",
                                                                                  on: {
                                                                                    click:
                                                                                      function (
                                                                                        $event
                                                                                      ) {
                                                                                        $event.preventDefault()
                                                                                        return _vm.openDeleteDialog(
                                                                                          scope.row
                                                                                        )
                                                                                      },
                                                                                  },
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "i",
                                                                                    {
                                                                                      staticClass:
                                                                                        "fa fa-trash pull-left",
                                                                                    }
                                                                                  ),
                                                                                  _vm._v(
                                                                                    "\n                                                                            Delete\n                                                                        "
                                                                                  ),
                                                                                ]
                                                                              )
                                                                            : _vm._e(),
                                                                          _vm._v(
                                                                            " "
                                                                          ),
                                                                          _vm
                                                                            .campaign
                                                                            .has_local_presence
                                                                            ? [
                                                                                _c(
                                                                                  "el-tooltip",
                                                                                  {
                                                                                    attrs:
                                                                                      {
                                                                                        content:
                                                                                          "Unable to unrent because this is part of the Local Presence package",
                                                                                        placement:
                                                                                          "left-start",
                                                                                      },
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "span",
                                                                                      [
                                                                                        _c(
                                                                                          "button",
                                                                                          {
                                                                                            staticClass:
                                                                                              "btn btn-sm btn-block danger",
                                                                                            attrs:
                                                                                              {
                                                                                                disabled: true,
                                                                                              },
                                                                                            on: {
                                                                                              click:
                                                                                                function (
                                                                                                  $event
                                                                                                ) {
                                                                                                  $event.preventDefault()
                                                                                                  return _vm.openUnrentDialog(
                                                                                                    scope.row
                                                                                                  )
                                                                                                },
                                                                                            },
                                                                                          },
                                                                                          [
                                                                                            _c(
                                                                                              "i",
                                                                                              {
                                                                                                staticClass:
                                                                                                  "fa fa-power-off pull-left",
                                                                                              }
                                                                                            ),
                                                                                            _vm._v(
                                                                                              "\n                                                                                        Unrent\n                                                                                    "
                                                                                            ),
                                                                                          ]
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                              ]
                                                                            : [
                                                                                _c(
                                                                                  "button",
                                                                                  {
                                                                                    staticClass:
                                                                                      "btn btn-sm btn-block danger",
                                                                                    on: {
                                                                                      click:
                                                                                        function (
                                                                                          $event
                                                                                        ) {
                                                                                          $event.preventDefault()
                                                                                          return _vm.openUnrentDialog(
                                                                                            scope.row
                                                                                          )
                                                                                        },
                                                                                    },
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "i",
                                                                                      {
                                                                                        staticClass:
                                                                                          "fa fa-power-off pull-left",
                                                                                      }
                                                                                    ),
                                                                                    _vm._v(
                                                                                      "\n                                                                                Unrent\n                                                                            "
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                              ],
                                                                        ],
                                                                        2
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]),
                                                              ]
                                                            },
                                                          },
                                                        ],
                                                        null,
                                                        false,
                                                        1669753713
                                                      ),
                                                    }),
                                                  ],
                                                  1
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "py-2 text-center",
                                                  },
                                                  [
                                                    _c("el-pagination", {
                                                      attrs: {
                                                        background: "",
                                                        layout:
                                                          "prev, pager, next",
                                                        "current-page":
                                                          _vm
                                                            .incoming_numbers_pagination
                                                            .current_page,
                                                        "page-count":
                                                          _vm
                                                            .incoming_numbers_pagination
                                                            .last_page,
                                                        "page-size": parseInt(
                                                          _vm
                                                            .incoming_numbers_pagination
                                                            .per_page
                                                        ),
                                                        total:
                                                          _vm
                                                            .incoming_numbers_pagination
                                                            .total,
                                                        "hide-on-single-page": true,
                                                      },
                                                      on: {
                                                        "update:currentPage":
                                                          function ($event) {
                                                            return _vm.$set(
                                                              _vm.incoming_numbers_pagination,
                                                              "current_page",
                                                              $event
                                                            )
                                                          },
                                                        "update:current-page":
                                                          function ($event) {
                                                            return _vm.$set(
                                                              _vm.incoming_numbers_pagination,
                                                              "current_page",
                                                              $event
                                                            )
                                                          },
                                                        "current-change":
                                                          _vm.fetchIncomingNumbers,
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                                _vm._v(" "),
                                                _vm.hasPermissionTo(
                                                  "create campaign"
                                                )
                                                  ? _c(
                                                      "el-dialog",
                                                      {
                                                        attrs: {
                                                          top: "10vh",
                                                          "custom-class":
                                                            "wizard-dialog dialog-no-border",
                                                          visible:
                                                            _vm.dialogVisible,
                                                          "before-close":
                                                            _vm.beforeCloseModal,
                                                          "append-to-body": "",
                                                        },
                                                        on: {
                                                          "update:visible":
                                                            function ($event) {
                                                              _vm.dialogVisible =
                                                                $event
                                                            },
                                                          close: _vm.closeModal,
                                                        },
                                                      },
                                                      [
                                                        _c("line-add-number", {
                                                          key: _vm.prop_counter,
                                                          attrs: {
                                                            campaign:
                                                              _vm.campaign,
                                                          },
                                                          on: {
                                                            add: _vm.addedIncomingNumberToCampaign,
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                _c(
                                                  "el-dialog",
                                                  {
                                                    attrs: {
                                                      top: "10vh",
                                                      "custom-class":
                                                        "wizard-dialog dialog-no-border",
                                                      visible:
                                                        _vm.dialogCallerIdVisible,
                                                      "before-close":
                                                        _vm.beforeCloseCallerIdModal,
                                                      "append-to-body": "",
                                                    },
                                                    on: {
                                                      "update:visible":
                                                        function ($event) {
                                                          _vm.dialogCallerIdVisible =
                                                            $event
                                                        },
                                                      close:
                                                        _vm.closeCallerIdModal,
                                                    },
                                                  },
                                                  [
                                                    _c("line-add-caller-id", {
                                                      key: _vm.prop_counter,
                                                      attrs: {
                                                        campaign: _vm.campaign,
                                                      },
                                                      on: {
                                                        close:
                                                          _vm.closeCallerIdModal,
                                                        moved:
                                                          _vm.newCallerIdAddedToCampaign,
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "col-md-2 d-none d-md-block ml-auto",
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "list-group list-group-alo list-group-flush sticky-top stick-top-with-header",
                                          },
                                          [
                                            _c(
                                              "h6",
                                              {
                                                staticClass: "list-group-item",
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                                On This Page\n                                            "
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "a",
                                              {
                                                staticClass:
                                                  "list-group-item list-group-item-action",
                                                attrs: { href: "#name" },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.shine("name")
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                                Name\n                                            "
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "a",
                                              {
                                                staticClass:
                                                  "list-group-item list-group-item-action",
                                                attrs: { href: "#description" },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.shine(
                                                      "description"
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                                Description\n                                            "
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "a",
                                              {
                                                staticClass:
                                                  "list-group-item list-group-item-action",
                                                attrs: {
                                                  href: "#send_long_messages_as_mms_toggle",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.shine(
                                                      "send_long_messages_as_mms_toggle"
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                                Send long text messages as MMS\n                                            "
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "a",
                                              {
                                                staticClass:
                                                  "list-group-item list-group-item-action",
                                                attrs: {
                                                  href: "#phone_numbers",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.shine(
                                                      "phone_numbers"
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                                Phone Numbers\n                                            "
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _vm.lineHasMessagingService
                                              ? _c(
                                                  "a",
                                                  {
                                                    staticClass:
                                                      "list-group-item list-group-item-action",
                                                    attrs: {
                                                      href: "#messaging_service",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.shine(
                                                          "messaging_service"
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                                                A2P Campaigns\n                                            "
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]),
                                ]
                              ),
                              _vm._v(" "),
                              !_vm.campaign.is_fax
                                ? _c(
                                    "el-tab-pane",
                                    {
                                      staticClass: "p-3 line-greet-record",
                                      attrs: {
                                        name: "ivr-settings",
                                        "data-tab-error": "ivr_settings",
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          attrs: { slot: "label" },
                                          slot: "label",
                                        },
                                        [
                                          _vm._v("Greet & Record"),
                                          _c("span", {
                                            domProps: {
                                              innerHTML: _vm._s(
                                                _vm.tabLabelErrorIcon(
                                                  _vm.tab_errors.ivr_settings
                                                )
                                              ),
                                            },
                                          }),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "row" }, [
                                        _c(
                                          "div",
                                          { staticClass: "col-8" },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "text-center mb-4",
                                              },
                                              [
                                                _c("i", {
                                                  staticClass:
                                                    "fas fa-phone mb-2 color-999",
                                                  staticStyle: {
                                                    "font-size": "40px",
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _c("p", [
                                                  _vm._v("Call starts"),
                                                ]),
                                                _vm._v(" "),
                                                _c("i", {
                                                  staticClass:
                                                    "fas fa-arrow-down",
                                                  staticStyle: {
                                                    "font-size": "30px",
                                                    color: "#acacac",
                                                  },
                                                }),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "el-form-item",
                                              { staticClass: "card pb-0" },
                                              [
                                                _c("span", {
                                                  staticClass: "page-anchor",
                                                  attrs: { id: "greet_caller" },
                                                }),
                                                _vm._v(" "),
                                                _c(
                                                  "el-popover",
                                                  {
                                                    ref: "popover_record_info",
                                                    attrs: {
                                                      placement: "top-start",
                                                      width: "600",
                                                      trigger: "hover",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "p",
                                                      { staticClass: "mb-0" },
                                                      [
                                                        _vm._v(
                                                          "\n                                                    Record a greeting or welcome message to callers.\n                                                "
                                                        ),
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "card-header",
                                                        attrs: {
                                                          slot: "reference",
                                                        },
                                                        slot: "reference",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "switch-label",
                                                          },
                                                          [
                                                            _c("el-switch", {
                                                              attrs: {
                                                                "active-color":
                                                                  "#00a344",
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.campaign
                                                                    .should_greet,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.campaign,
                                                                      "should_greet",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "campaign.should_greet",
                                                              },
                                                            }),
                                                            _vm._v(" "),
                                                            _c("label", [
                                                              _vm._v(
                                                                "Greet / Welcome Callers?"
                                                              ),
                                                            ]),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "transition",
                                                  {
                                                    attrs: {
                                                      name: "el-fade-in-linear",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        directives: [
                                                          {
                                                            name: "show",
                                                            rawName: "v-show",
                                                            value:
                                                              _vm.campaign
                                                                .should_greet,
                                                            expression:
                                                              "campaign.should_greet",
                                                          },
                                                        ],
                                                        staticClass:
                                                          "card-body",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass: "row",
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "col-12",
                                                              },
                                                              [
                                                                _c("p", [
                                                                  _vm._v(
                                                                    "Welcome the caller to this Line using text-to-speech or pre-recorded audio."
                                                                  ),
                                                                ]),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "b-b nav-active-greenish",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "ul",
                                                                      {
                                                                        staticClass:
                                                                          "nav nav-tabs",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "li",
                                                                          {
                                                                            staticClass:
                                                                              "nav-item",
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "a",
                                                                              {
                                                                                staticClass:
                                                                                  "border border-right-0 rounded-top-left",
                                                                                class:
                                                                                  _vm
                                                                                    .greetingNavClass
                                                                                    .read,
                                                                                attrs:
                                                                                  {
                                                                                    href: "",
                                                                                    "data-toggle":
                                                                                      "tab",
                                                                                    "data-target":
                                                                                      "#greet-tts",
                                                                                    "aria-expanded":
                                                                                      "true",
                                                                                  },
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "i",
                                                                                  {
                                                                                    staticClass:
                                                                                      "fa fa-robot",
                                                                                  }
                                                                                ),
                                                                                _vm._v(
                                                                                  "\n                                                                            Text to Speech\n                                                                        "
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _vm._v(
                                                                          " "
                                                                        ),
                                                                        _vm.hasPermissionTo(
                                                                          "upload file campaign"
                                                                        )
                                                                          ? _c(
                                                                              "li",
                                                                              {
                                                                                staticClass:
                                                                                  "nav-item",
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "a",
                                                                                  {
                                                                                    staticClass:
                                                                                      "border rounded-top-right",
                                                                                    class:
                                                                                      _vm
                                                                                        .greetingNavClass
                                                                                        .play,
                                                                                    attrs:
                                                                                      {
                                                                                        href: "",
                                                                                        "data-toggle":
                                                                                          "tab",
                                                                                        "data-target":
                                                                                          "#greet-file",
                                                                                        "aria-expanded":
                                                                                          "false",
                                                                                      },
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "i",
                                                                                      {
                                                                                        staticClass:
                                                                                          "fa fa-play text-md",
                                                                                      }
                                                                                    ),
                                                                                    _vm._v(
                                                                                      "\n                                                                            Play Recording\n                                                                        "
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                              ]
                                                                            )
                                                                          : _vm._e(),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "tab-content p-a m-b-md border border-top-0",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        class:
                                                                          _vm
                                                                            .greetingContainerClass
                                                                            .read,
                                                                        attrs: {
                                                                          id: "greet-tts",
                                                                          "aria-expanded":
                                                                            "true",
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "el-form-item",
                                                                          {
                                                                            staticClass:
                                                                              "no-border mb-4",
                                                                            attrs:
                                                                              {
                                                                                label:
                                                                                  "Greeting Message",
                                                                                prop: "should_greet",
                                                                              },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "div",
                                                                              {
                                                                                staticClass:
                                                                                  "row",
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "div",
                                                                                  {
                                                                                    staticClass:
                                                                                      "col-12",
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "div",
                                                                                      {
                                                                                        staticClass:
                                                                                          "input-group",
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "input",
                                                                                          {
                                                                                            directives:
                                                                                              [
                                                                                                {
                                                                                                  name: "model",
                                                                                                  rawName:
                                                                                                    "v-model",
                                                                                                  value:
                                                                                                    _vm
                                                                                                      .campaign
                                                                                                      .greeting_tts,
                                                                                                  expression:
                                                                                                    "campaign.greeting_tts",
                                                                                                },
                                                                                              ],
                                                                                            staticClass:
                                                                                              "form-control pt-2 pb-2",
                                                                                            attrs:
                                                                                              {
                                                                                                type: "text",
                                                                                              },
                                                                                            domProps:
                                                                                              {
                                                                                                value:
                                                                                                  _vm
                                                                                                    .campaign
                                                                                                    .greeting_tts,
                                                                                              },
                                                                                            on: {
                                                                                              input:
                                                                                                [
                                                                                                  function (
                                                                                                    $event
                                                                                                  ) {
                                                                                                    if (
                                                                                                      $event
                                                                                                        .target
                                                                                                        .composing
                                                                                                    )
                                                                                                      return
                                                                                                    _vm.$set(
                                                                                                      _vm.campaign,
                                                                                                      "greeting_tts",
                                                                                                      $event
                                                                                                        .target
                                                                                                        .value
                                                                                                    )
                                                                                                  },
                                                                                                  function (
                                                                                                    $event
                                                                                                  ) {
                                                                                                    return _vm.preValidateForm(
                                                                                                      "campaign_settings"
                                                                                                    )
                                                                                                  },
                                                                                                ],
                                                                                            },
                                                                                          }
                                                                                        ),
                                                                                        _vm._v(
                                                                                          " "
                                                                                        ),
                                                                                        _c(
                                                                                          "variable-dialog"
                                                                                        ),
                                                                                      ],
                                                                                      1
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                    _vm._v(" "),
                                                                    _vm.hasPermissionTo(
                                                                      "upload file campaign"
                                                                    )
                                                                      ? _c(
                                                                          "div",
                                                                          {
                                                                            class:
                                                                              _vm
                                                                                .greetingContainerClass
                                                                                .play,
                                                                            attrs:
                                                                              {
                                                                                id: "greet-file",
                                                                                "aria-expanded":
                                                                                  "false",
                                                                              },
                                                                          },
                                                                          [
                                                                            _vm
                                                                              .campaign
                                                                              .greeting_file ===
                                                                            null
                                                                              ? _c(
                                                                                  "audio-recorder",
                                                                                  {
                                                                                    staticClass:
                                                                                      "audio-recorder-wrapper",
                                                                                    attrs:
                                                                                      {
                                                                                        action:
                                                                                          "/api/v1/campaign/" +
                                                                                          _vm
                                                                                            .campaign
                                                                                            .id +
                                                                                          "/upload-ivr/greetings",
                                                                                        upload_type:
                                                                                          "greeting_file",
                                                                                      },
                                                                                    on: {
                                                                                      applyUploadedAudio:
                                                                                        _vm.applyUploadedAudio,
                                                                                    },
                                                                                  }
                                                                                )
                                                                              : _vm._e(),
                                                                            _vm._v(
                                                                              " "
                                                                            ),
                                                                            _c(
                                                                              "div",
                                                                              {
                                                                                staticClass:
                                                                                  "audio-uploader-wrapper",
                                                                              },
                                                                              [
                                                                                _vm
                                                                                  .campaign
                                                                                  .greeting_file ===
                                                                                null
                                                                                  ? _c(
                                                                                      "el-upload",
                                                                                      {
                                                                                        ref: "greetingAudio",
                                                                                        attrs:
                                                                                          {
                                                                                            drag: "",
                                                                                            action:
                                                                                              "/api/v1/campaign/" +
                                                                                              _vm
                                                                                                .campaign
                                                                                                .id +
                                                                                              "/upload-ivr/greetings",
                                                                                            headers:
                                                                                              _vm.headers,
                                                                                            "on-success":
                                                                                              _vm.onSuccessGreeting,
                                                                                            "on-error":
                                                                                              _vm.onFailedGreeting,
                                                                                            "on-progress":
                                                                                              _vm.progressUploadGreeting,
                                                                                            "before-upload":
                                                                                              _vm.beforeUploadGreeting,
                                                                                            "file-list":
                                                                                              _vm
                                                                                                .uploadFileList
                                                                                                .greeting,
                                                                                          },
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "i",
                                                                                          {
                                                                                            staticClass:
                                                                                              "el-icon-upload",
                                                                                          }
                                                                                        ),
                                                                                        _vm._v(
                                                                                          " "
                                                                                        ),
                                                                                        _c(
                                                                                          "div",
                                                                                          {
                                                                                            staticClass:
                                                                                              "el-upload__text",
                                                                                          },
                                                                                          [
                                                                                            _vm._v(
                                                                                              "\n                                                                                Drop file here or "
                                                                                            ),
                                                                                            _c(
                                                                                              "em",
                                                                                              [
                                                                                                _vm._v(
                                                                                                  "click to\n                                                                                upload"
                                                                                                ),
                                                                                              ]
                                                                                            ),
                                                                                          ]
                                                                                        ),
                                                                                        _vm._v(
                                                                                          " "
                                                                                        ),
                                                                                        _c(
                                                                                          "div",
                                                                                          {
                                                                                            staticClass:
                                                                                              "el-upload__tip",
                                                                                            attrs:
                                                                                              {
                                                                                                slot: "tip",
                                                                                              },
                                                                                            slot: "tip",
                                                                                          },
                                                                                          [
                                                                                            _vm._v(
                                                                                              "\n                                                                                MP3/WAV file (less than 8MB)\n                                                                            "
                                                                                            ),
                                                                                          ]
                                                                                        ),
                                                                                      ]
                                                                                    )
                                                                                  : _vm._e(),
                                                                                _vm._v(
                                                                                  " "
                                                                                ),
                                                                                _vm
                                                                                  .campaign
                                                                                  .greeting_file ===
                                                                                null
                                                                                  ? _c(
                                                                                      "el-progress",
                                                                                      {
                                                                                        attrs:
                                                                                          {
                                                                                            "text-inside": true,
                                                                                            "stroke-width": 18,
                                                                                            percentage:
                                                                                              _vm
                                                                                                .uploadPercentage
                                                                                                .greeting,
                                                                                            status:
                                                                                              _vm
                                                                                                .uploadStatus
                                                                                                .greeting,
                                                                                          },
                                                                                      }
                                                                                    )
                                                                                  : _vm._e(),
                                                                                _vm._v(
                                                                                  " "
                                                                                ),
                                                                                _vm
                                                                                  .campaign
                                                                                  .greeting_file !==
                                                                                null
                                                                                  ? _c(
                                                                                      "audio",
                                                                                      {
                                                                                        attrs:
                                                                                          {
                                                                                            controls:
                                                                                              "",
                                                                                          },
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "source",
                                                                                          {
                                                                                            attrs:
                                                                                              {
                                                                                                src:
                                                                                                  "/static/uploaded_file/" +
                                                                                                  _vm
                                                                                                    .campaign
                                                                                                    .greeting_file,
                                                                                              },
                                                                                          }
                                                                                        ),
                                                                                        _vm._v(
                                                                                          "\n                                                                            Your browser does not support the\n                                                                            audio\n                                                                            element.\n                                                                        "
                                                                                        ),
                                                                                      ]
                                                                                    )
                                                                                  : _vm._e(),
                                                                                _vm._v(
                                                                                  " "
                                                                                ),
                                                                                _vm
                                                                                  .campaign
                                                                                  .greeting_file !==
                                                                                  null &&
                                                                                _vm.hasPermissionTo(
                                                                                  "delete file campaign"
                                                                                )
                                                                                  ? _c(
                                                                                      "button",
                                                                                      {
                                                                                        staticClass:
                                                                                          "btn btn-sm btn-danger pull-right",
                                                                                        on: {
                                                                                          click:
                                                                                            function (
                                                                                              $event
                                                                                            ) {
                                                                                              $event.preventDefault()
                                                                                              return _vm.deleteFile(
                                                                                                "greetings"
                                                                                              )
                                                                                            },
                                                                                        },
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "i",
                                                                                          {
                                                                                            directives:
                                                                                              [
                                                                                                {
                                                                                                  name: "show",
                                                                                                  rawName:
                                                                                                    "v-show",
                                                                                                  value:
                                                                                                    _vm.loading_greeting,
                                                                                                  expression:
                                                                                                    "loading_greeting",
                                                                                                },
                                                                                              ],
                                                                                            staticClass:
                                                                                              "material-icons loader pull-left",
                                                                                          },
                                                                                          [
                                                                                            _vm._v(
                                                                                              ""
                                                                                            ),
                                                                                          ]
                                                                                        ),
                                                                                        _vm._v(
                                                                                          " "
                                                                                        ),
                                                                                        _c(
                                                                                          "i",
                                                                                          {
                                                                                            directives:
                                                                                              [
                                                                                                {
                                                                                                  name: "show",
                                                                                                  rawName:
                                                                                                    "v-show",
                                                                                                  value:
                                                                                                    !_vm.loading_greeting,
                                                                                                  expression:
                                                                                                    "!loading_greeting",
                                                                                                },
                                                                                              ],
                                                                                            staticClass:
                                                                                              "material-icons pull-left",
                                                                                          },
                                                                                          [
                                                                                            _vm._v(
                                                                                              ""
                                                                                            ),
                                                                                          ]
                                                                                        ),
                                                                                        _vm._v(
                                                                                          " "
                                                                                        ),
                                                                                        _c(
                                                                                          "span",
                                                                                          [
                                                                                            _vm._v(
                                                                                              "Remove file"
                                                                                            ),
                                                                                          ]
                                                                                        ),
                                                                                      ]
                                                                                    )
                                                                                  : _vm._e(),
                                                                              ],
                                                                              1
                                                                            ),
                                                                          ],
                                                                          1
                                                                        )
                                                                      : _vm._e(),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass: "text-center my-4",
                                              },
                                              [
                                                _c("i", {
                                                  staticClass:
                                                    "fas fa-arrow-down",
                                                  staticStyle: {
                                                    "font-size": "30px",
                                                    color: "#acacac",
                                                  },
                                                }),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "el-form-item",
                                              { staticClass: "card pb-0" },
                                              [
                                                _c("span", {
                                                  staticClass: "page-anchor",
                                                  attrs: {
                                                    id: "call_recording",
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _c(
                                                  "el-popover",
                                                  {
                                                    ref: "popover_record_info",
                                                    attrs: {
                                                      placement: "top-start",
                                                      width: "600",
                                                      trigger: "hover",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "p",
                                                      { staticClass: "mb-0" },
                                                      [
                                                        _vm._v(
                                                          "\n                                                    This will enable call recordings on this line,\n                                                    you can modify the inbound/outbound recording behavior globally in account settings or\n                                                    you can customize it on the user level by changing the user settings.\n                                                "
                                                        ),
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "card-header",
                                                        attrs: {
                                                          slot: "reference",
                                                        },
                                                        slot: "reference",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "switch-label",
                                                          },
                                                          [
                                                            _c("el-switch", {
                                                              attrs: {
                                                                "active-color":
                                                                  "#00a344",
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.campaign
                                                                    .should_record,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.campaign,
                                                                      "should_record",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "campaign.should_record",
                                                              },
                                                            }),
                                                            _vm._v(" "),
                                                            _c("label", [
                                                              _vm._v(
                                                                "Should Record Calls?"
                                                              ),
                                                            ]),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "transition",
                                                  {
                                                    attrs: {
                                                      name: "el-fade-in-linear",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        directives: [
                                                          {
                                                            name: "show",
                                                            rawName: "v-show",
                                                            value:
                                                              _vm.campaign
                                                                .should_record,
                                                            expression:
                                                              "campaign.should_record",
                                                          },
                                                        ],
                                                        staticClass:
                                                          "card-body",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "alert alert-warning alert-aloware p-2 d-inline-block",
                                                          },
                                                          [
                                                            _c("i", {
                                                              staticClass:
                                                                "fa fa-info-circle",
                                                            }),
                                                            _vm._v(
                                                              "\n                                                        In the US, you must let the caller know\n                                                        that their conversation is being recorded.\n                                                    "
                                                            ),
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass: "row",
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "col-12",
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "b-b nav-active-greenish",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "ul",
                                                                      {
                                                                        staticClass:
                                                                          "nav nav-tabs",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "li",
                                                                          {
                                                                            staticClass:
                                                                              "nav-item",
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "a",
                                                                              {
                                                                                staticClass:
                                                                                  "border border-right-0 rounded-top-left",
                                                                                class:
                                                                                  _vm
                                                                                    .recordNavClass
                                                                                    .read,
                                                                                attrs:
                                                                                  {
                                                                                    href: "",
                                                                                    "data-toggle":
                                                                                      "tab",
                                                                                    "data-target":
                                                                                      "#record-tts",
                                                                                    "aria-expanded":
                                                                                      "true",
                                                                                  },
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "i",
                                                                                  {
                                                                                    staticClass:
                                                                                      "fa fa-robot",
                                                                                  }
                                                                                ),
                                                                                _vm._v(
                                                                                  "\n                                                                            Text to Speech\n                                                                        "
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _vm._v(
                                                                          " "
                                                                        ),
                                                                        _vm.hasPermissionTo(
                                                                          "upload file campaign"
                                                                        )
                                                                          ? _c(
                                                                              "li",
                                                                              {
                                                                                staticClass:
                                                                                  "nav-item",
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "a",
                                                                                  {
                                                                                    staticClass:
                                                                                      "border rounded-top-right",
                                                                                    class:
                                                                                      _vm
                                                                                        .recordNavClass
                                                                                        .play,
                                                                                    attrs:
                                                                                      {
                                                                                        href: "",
                                                                                        "data-toggle":
                                                                                          "tab",
                                                                                        "data-target":
                                                                                          "#record-file",
                                                                                        "aria-expanded":
                                                                                          "false",
                                                                                      },
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "i",
                                                                                      {
                                                                                        staticClass:
                                                                                          "fa fa-play text-md",
                                                                                      }
                                                                                    ),
                                                                                    _vm._v(
                                                                                      "\n                                                                            Play Recording\n                                                                        "
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                              ]
                                                                            )
                                                                          : _vm._e(),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "tab-content p-a m-b-md border border-top-0",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        class:
                                                                          _vm
                                                                            .recordContainerClass
                                                                            .read,
                                                                        attrs: {
                                                                          id: "record-tts",
                                                                          "aria-expanded":
                                                                            "true",
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "el-form-item",
                                                                          {
                                                                            staticClass:
                                                                              "no-border mb-4",
                                                                            attrs:
                                                                              {
                                                                                label:
                                                                                  "Recording Message",
                                                                                prop: "should_record",
                                                                              },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "div",
                                                                              {
                                                                                staticClass:
                                                                                  "row",
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "div",
                                                                                  {
                                                                                    staticClass:
                                                                                      "col-12",
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "div",
                                                                                      {
                                                                                        staticClass:
                                                                                          "input-group",
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "input",
                                                                                          {
                                                                                            directives:
                                                                                              [
                                                                                                {
                                                                                                  name: "model",
                                                                                                  rawName:
                                                                                                    "v-model",
                                                                                                  value:
                                                                                                    _vm
                                                                                                      .campaign
                                                                                                      .record_tts,
                                                                                                  expression:
                                                                                                    "campaign.record_tts",
                                                                                                },
                                                                                              ],
                                                                                            staticClass:
                                                                                              "form-control pt-2 pb-2",
                                                                                            attrs:
                                                                                              {
                                                                                                type: "text",
                                                                                              },
                                                                                            domProps:
                                                                                              {
                                                                                                value:
                                                                                                  _vm
                                                                                                    .campaign
                                                                                                    .record_tts,
                                                                                              },
                                                                                            on: {
                                                                                              input:
                                                                                                [
                                                                                                  function (
                                                                                                    $event
                                                                                                  ) {
                                                                                                    if (
                                                                                                      $event
                                                                                                        .target
                                                                                                        .composing
                                                                                                    )
                                                                                                      return
                                                                                                    _vm.$set(
                                                                                                      _vm.campaign,
                                                                                                      "record_tts",
                                                                                                      $event
                                                                                                        .target
                                                                                                        .value
                                                                                                    )
                                                                                                  },
                                                                                                  function (
                                                                                                    $event
                                                                                                  ) {
                                                                                                    return _vm.preValidateForm(
                                                                                                      "campaign_settings"
                                                                                                    )
                                                                                                  },
                                                                                                ],
                                                                                            },
                                                                                          }
                                                                                        ),
                                                                                        _vm._v(
                                                                                          " "
                                                                                        ),
                                                                                        _c(
                                                                                          "variable-dialog"
                                                                                        ),
                                                                                      ],
                                                                                      1
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                    _vm._v(" "),
                                                                    _vm.hasPermissionTo(
                                                                      "upload file campaign"
                                                                    )
                                                                      ? _c(
                                                                          "div",
                                                                          {
                                                                            class:
                                                                              _vm
                                                                                .recordContainerClass
                                                                                .play,
                                                                            attrs:
                                                                              {
                                                                                id: "record-file",
                                                                                "aria-expanded":
                                                                                  "false",
                                                                              },
                                                                          },
                                                                          [
                                                                            _vm
                                                                              .campaign
                                                                              .record_file ===
                                                                            null
                                                                              ? _c(
                                                                                  "audio-recorder",
                                                                                  {
                                                                                    staticClass:
                                                                                      "audio-recorder-wrapper",
                                                                                    attrs:
                                                                                      {
                                                                                        action:
                                                                                          "/api/v1/campaign/" +
                                                                                          _vm
                                                                                            .campaign
                                                                                            .id +
                                                                                          "/upload-ivr/recording_notices",
                                                                                        upload_type:
                                                                                          "record_file",
                                                                                      },
                                                                                    on: {
                                                                                      applyUploadedAudio:
                                                                                        _vm.applyUploadedAudio,
                                                                                    },
                                                                                  }
                                                                                )
                                                                              : _vm._e(),
                                                                            _vm._v(
                                                                              " "
                                                                            ),
                                                                            _c(
                                                                              "div",
                                                                              {
                                                                                staticClass:
                                                                                  "audio-uploader-wrapper",
                                                                              },
                                                                              [
                                                                                _vm
                                                                                  .campaign
                                                                                  .record_file ===
                                                                                null
                                                                                  ? _c(
                                                                                      "el-upload",
                                                                                      {
                                                                                        attrs:
                                                                                          {
                                                                                            drag: "",
                                                                                            action:
                                                                                              "/api/v1/campaign/" +
                                                                                              _vm
                                                                                                .campaign
                                                                                                .id +
                                                                                              "/upload-ivr/recording_notices",
                                                                                            headers:
                                                                                              _vm.headers,
                                                                                            "on-success":
                                                                                              _vm.onSuccessRecord,
                                                                                            "on-error":
                                                                                              _vm.onFailedRecord,
                                                                                            "on-progress":
                                                                                              _vm.progressUploadRecord,
                                                                                            "before-upload":
                                                                                              _vm.beforeUploadRecord,
                                                                                            "file-list":
                                                                                              _vm
                                                                                                .uploadFileList
                                                                                                .record,
                                                                                          },
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "i",
                                                                                          {
                                                                                            staticClass:
                                                                                              "el-icon-upload",
                                                                                          }
                                                                                        ),
                                                                                        _vm._v(
                                                                                          " "
                                                                                        ),
                                                                                        _c(
                                                                                          "div",
                                                                                          {
                                                                                            staticClass:
                                                                                              "el-upload__text",
                                                                                          },
                                                                                          [
                                                                                            _vm._v(
                                                                                              "\n                                                                                Drop file here or "
                                                                                            ),
                                                                                            _c(
                                                                                              "em",
                                                                                              [
                                                                                                _vm._v(
                                                                                                  "click to\n                                                                                upload"
                                                                                                ),
                                                                                              ]
                                                                                            ),
                                                                                          ]
                                                                                        ),
                                                                                        _vm._v(
                                                                                          " "
                                                                                        ),
                                                                                        _c(
                                                                                          "div",
                                                                                          {
                                                                                            staticClass:
                                                                                              "el-upload__tip",
                                                                                            attrs:
                                                                                              {
                                                                                                slot: "tip",
                                                                                              },
                                                                                            slot: "tip",
                                                                                          },
                                                                                          [
                                                                                            _vm._v(
                                                                                              "\n                                                                                MP3/WAV file (less than 8MB)\n                                                                            "
                                                                                            ),
                                                                                          ]
                                                                                        ),
                                                                                      ]
                                                                                    )
                                                                                  : _vm._e(),
                                                                                _vm._v(
                                                                                  " "
                                                                                ),
                                                                                _vm
                                                                                  .campaign
                                                                                  .record_file ===
                                                                                null
                                                                                  ? _c(
                                                                                      "el-progress",
                                                                                      {
                                                                                        attrs:
                                                                                          {
                                                                                            "text-inside": true,
                                                                                            "stroke-width": 18,
                                                                                            percentage:
                                                                                              _vm
                                                                                                .uploadPercentage
                                                                                                .record,
                                                                                            status:
                                                                                              _vm
                                                                                                .uploadStatus
                                                                                                .record,
                                                                                          },
                                                                                      }
                                                                                    )
                                                                                  : _vm._e(),
                                                                                _vm._v(
                                                                                  " "
                                                                                ),
                                                                                _vm
                                                                                  .campaign
                                                                                  .record_file !==
                                                                                null
                                                                                  ? _c(
                                                                                      "audio",
                                                                                      {
                                                                                        attrs:
                                                                                          {
                                                                                            controls:
                                                                                              "",
                                                                                          },
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "source",
                                                                                          {
                                                                                            attrs:
                                                                                              {
                                                                                                src:
                                                                                                  "/static/uploaded_file/" +
                                                                                                  _vm
                                                                                                    .campaign
                                                                                                    .record_file,
                                                                                              },
                                                                                          }
                                                                                        ),
                                                                                        _vm._v(
                                                                                          "\n                                                                            Your browser does not support the\n                                                                            audio\n                                                                            element.\n                                                                        "
                                                                                        ),
                                                                                      ]
                                                                                    )
                                                                                  : _vm._e(),
                                                                                _vm._v(
                                                                                  " "
                                                                                ),
                                                                                _vm
                                                                                  .campaign
                                                                                  .record_file !==
                                                                                  null &&
                                                                                _vm.hasPermissionTo(
                                                                                  "delete file campaign"
                                                                                )
                                                                                  ? _c(
                                                                                      "button",
                                                                                      {
                                                                                        staticClass:
                                                                                          "btn btn-sm btn-danger pull-right",
                                                                                        on: {
                                                                                          click:
                                                                                            function (
                                                                                              $event
                                                                                            ) {
                                                                                              $event.preventDefault()
                                                                                              return _vm.deleteFile(
                                                                                                "recording_notices"
                                                                                              )
                                                                                            },
                                                                                        },
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "i",
                                                                                          {
                                                                                            directives:
                                                                                              [
                                                                                                {
                                                                                                  name: "show",
                                                                                                  rawName:
                                                                                                    "v-show",
                                                                                                  value:
                                                                                                    _vm.loading_recording,
                                                                                                  expression:
                                                                                                    "loading_recording",
                                                                                                },
                                                                                              ],
                                                                                            staticClass:
                                                                                              "material-icons loader pull-left",
                                                                                          },
                                                                                          [
                                                                                            _vm._v(
                                                                                              ""
                                                                                            ),
                                                                                          ]
                                                                                        ),
                                                                                        _vm._v(
                                                                                          " "
                                                                                        ),
                                                                                        _c(
                                                                                          "i",
                                                                                          {
                                                                                            directives:
                                                                                              [
                                                                                                {
                                                                                                  name: "show",
                                                                                                  rawName:
                                                                                                    "v-show",
                                                                                                  value:
                                                                                                    !_vm.loading_recording,
                                                                                                  expression:
                                                                                                    "!loading_recording",
                                                                                                },
                                                                                              ],
                                                                                            staticClass:
                                                                                              "material-icons pull-left",
                                                                                          },
                                                                                          [
                                                                                            _vm._v(
                                                                                              ""
                                                                                            ),
                                                                                          ]
                                                                                        ),
                                                                                        _vm._v(
                                                                                          " "
                                                                                        ),
                                                                                        _c(
                                                                                          "span",
                                                                                          [
                                                                                            _vm._v(
                                                                                              "Remove file"
                                                                                            ),
                                                                                          ]
                                                                                        ),
                                                                                      ]
                                                                                    )
                                                                                  : _vm._e(),
                                                                              ],
                                                                              1
                                                                            ),
                                                                          ],
                                                                          1
                                                                        )
                                                                      : _vm._e(),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "el-form-item",
                                                          {
                                                            staticClass:
                                                              "pl-3 border-left",
                                                            attrs: {
                                                              prop: "call_recording_retention",
                                                            },
                                                          },
                                                          [
                                                            _c("span", {
                                                              staticClass:
                                                                "page-anchor",
                                                              attrs: {
                                                                id: "call_recording_retention",
                                                              },
                                                            }),
                                                            _vm._v(" "),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "form-label",
                                                              },
                                                              [
                                                                _c("h5", [
                                                                  _vm._v(
                                                                    "Call records retention duration"
                                                                  ),
                                                                ]),
                                                              ]
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "retention-duration-feature-overview"
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "el-tooltip",
                                                              {
                                                                attrs: {
                                                                  placement:
                                                                    "top",
                                                                  effect:
                                                                    "dark",
                                                                  disabled:
                                                                    _vm.hasAccessToEditRetentionDuration,
                                                                },
                                                                scopedSlots:
                                                                  _vm._u(
                                                                    [
                                                                      {
                                                                        key: "content",
                                                                        fn: function () {
                                                                          return [
                                                                            _c(
                                                                              "div",
                                                                              {
                                                                                domProps:
                                                                                  {
                                                                                    innerHTML:
                                                                                      _vm._s(
                                                                                        _vm.getRetentionDurationTooltipText
                                                                                      ),
                                                                                  },
                                                                              }
                                                                            ),
                                                                          ]
                                                                        },
                                                                        proxy: true,
                                                                      },
                                                                    ],
                                                                    null,
                                                                    false,
                                                                    3948873284
                                                                  ),
                                                              },
                                                              [
                                                                _vm._v(" "),
                                                                _c(
                                                                  "el-select",
                                                                  {
                                                                    staticClass:
                                                                      "width-300",
                                                                    attrs: {
                                                                      disabled:
                                                                        !_vm.hasAccessToEditRetentionDuration,
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        _vm
                                                                          .campaign
                                                                          .call_recording_retention,
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            _vm.campaign,
                                                                            "call_recording_retention",
                                                                            $$v
                                                                          )
                                                                        },
                                                                      expression:
                                                                        "campaign.call_recording_retention",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "el-option",
                                                                      {
                                                                        attrs: {
                                                                          value:
                                                                            _vm
                                                                              .CallRecordsRetentionDurations
                                                                              .CALL_RECORDS_RETENTION_DURATIONS_INDEFINITE,
                                                                          label:
                                                                            "Indefinite",
                                                                        },
                                                                      }
                                                                    ),
                                                                    _vm._v(" "),
                                                                    _c(
                                                                      "el-option",
                                                                      {
                                                                        attrs: {
                                                                          value:
                                                                            _vm
                                                                              .CallRecordsRetentionDurations
                                                                              .CALL_RECORDS_RETENTION_DURATIONS_10_DAYS,
                                                                          label:
                                                                            "10 days",
                                                                        },
                                                                      }
                                                                    ),
                                                                    _vm._v(" "),
                                                                    _c(
                                                                      "el-option",
                                                                      {
                                                                        attrs: {
                                                                          value:
                                                                            _vm
                                                                              .CallRecordsRetentionDurations
                                                                              .CALL_RECORDS_RETENTION_DURATIONS_30_DAYS,
                                                                          label:
                                                                            "30 days",
                                                                        },
                                                                      }
                                                                    ),
                                                                    _vm._v(" "),
                                                                    _c(
                                                                      "el-option",
                                                                      {
                                                                        attrs: {
                                                                          value:
                                                                            _vm
                                                                              .CallRecordsRetentionDurations
                                                                              .CALL_RECORDS_RETENTION_DURATIONS_60_DAYS,
                                                                          label:
                                                                            "60 days",
                                                                        },
                                                                      }
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _vm.recordingSetInAccountSettings
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "alert alert-danger alert-aloware p-2 d-block",
                                                  },
                                                  [
                                                    _c("i", {
                                                      staticClass:
                                                        "fa fa-warning",
                                                    }),
                                                    _vm._v(
                                                      "\n                                            This setting is being forced at the account level, please go to Account > Calling Settings to change it.\n                                        "
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass: "text-center my-4",
                                              },
                                              [
                                                _c("i", {
                                                  staticClass:
                                                    "fas fa-arrow-down",
                                                  staticStyle: {
                                                    "font-size": "30px",
                                                    color: "#acacac",
                                                  },
                                                }),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "el-form-item",
                                              { staticClass: "card pb-0" },
                                              [
                                                _c("span", {
                                                  staticClass: "page-anchor",
                                                  attrs: {
                                                    id: "check_operating_hours",
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _c(
                                                  "el-popover",
                                                  {
                                                    ref: "popover_record_info",
                                                    attrs: {
                                                      placement: "top-start",
                                                      width: "600",
                                                      trigger: "hover",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "p",
                                                      { staticClass: "mb-0" },
                                                      [
                                                        _vm._v(
                                                          "\n                                                    Set what this line should do on calls outside your business' operating hours.\n                                                "
                                                        ),
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "card-header",
                                                        attrs: {
                                                          slot: "reference",
                                                        },
                                                        slot: "reference",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "switch-label",
                                                          },
                                                          [
                                                            _c("el-switch", {
                                                              attrs: {
                                                                "active-color":
                                                                  "#00a344",
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.show_operating_hours,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.show_operating_hours =
                                                                      $$v
                                                                  },
                                                                expression:
                                                                  "show_operating_hours",
                                                              },
                                                            }),
                                                            _vm._v(" "),
                                                            _c("label", [
                                                              _vm._v(
                                                                "Check Operating Hours?"
                                                              ),
                                                            ]),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "transition",
                                                  {
                                                    attrs: {
                                                      name: "el-fade-in-linear",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        directives: [
                                                          {
                                                            name: "show",
                                                            rawName: "v-show",
                                                            value:
                                                              _vm.show_operating_hours,
                                                            expression:
                                                              "show_operating_hours",
                                                          },
                                                        ],
                                                        staticClass:
                                                          "card-body",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass: "row",
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "col-12",
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "form-label",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "label",
                                                                      {
                                                                        staticClass:
                                                                          "lh-1",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "\n                                                                    Here you can define the open/close hours of your business.\n                                                                    If a call comes in during closed hours, it will follow your 'missed call' logic defined on the next page.\n                                                                "
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "alert alert-success alert-aloware p-2 d-inline-block",
                                                                  },
                                                                  [
                                                                    _c("i", {
                                                                      staticClass:
                                                                        "fa fa-info-circle",
                                                                    }),
                                                                    _vm._v(
                                                                      "\n                                                                The times below are local to your account's timezone, currently set to " +
                                                                        _vm._s(
                                                                          _vm
                                                                            .current_company
                                                                            .timezone
                                                                        ) +
                                                                        "\n                                                            "
                                                                    ),
                                                                  ]
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "form-group",
                                                                  },
                                                                  [
                                                                    !_vm.loading_operating_hours &&
                                                                    _vm.campaign
                                                                      .operating_hours
                                                                      ? _c(
                                                                          "business-hours",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                days: _vm
                                                                                  .campaign
                                                                                  .operating_hours,
                                                                                "time-increment":
                                                                                  _vm.timeIncrement,
                                                                                type: "select",
                                                                                color:
                                                                                  "#00BF50",
                                                                              },
                                                                          }
                                                                        )
                                                                      : _vm._e(),
                                                                  ],
                                                                  1
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "row mt-3",
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "col-12",
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "form-group",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "el-checkbox",
                                                                      {
                                                                        on: {
                                                                          change:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.preValidateForm(
                                                                                "campaign_settings"
                                                                              )
                                                                            },
                                                                        },
                                                                        model: {
                                                                          value:
                                                                            _vm
                                                                              .campaign
                                                                              .closed_hours_voice_prompt
                                                                              .enabled,
                                                                          callback:
                                                                            function (
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                _vm
                                                                                  .campaign
                                                                                  .closed_hours_voice_prompt,
                                                                                "enabled",
                                                                                $$v
                                                                              )
                                                                            },
                                                                          expression:
                                                                            "campaign.closed_hours_voice_prompt.enabled",
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "\n                                                                    Closed Hours Voice Prompt\n                                                                "
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _vm._v(" "),
                                                                    _c(
                                                                      "label",
                                                                      {
                                                                        staticClass:
                                                                          "help-text",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "\n                                                                    This message will be played when a call\n                                                                    comes in during business closure hours.\n                                                                "
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                                _vm._v(" "),
                                                                _vm.campaign
                                                                  .closed_hours_voice_prompt
                                                                  .enabled
                                                                  ? _c("div", [
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "b-b nav-active-greenish",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "ul",
                                                                            {
                                                                              staticClass:
                                                                                "nav nav-tabs",
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "li",
                                                                                {
                                                                                  staticClass:
                                                                                    "nav-item",
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "a",
                                                                                    {
                                                                                      staticClass:
                                                                                        "border border-right-0 rounded-top-left",
                                                                                      class:
                                                                                        _vm
                                                                                          .closedHoursMissedCallVoicemailNavClass
                                                                                          .read,
                                                                                      attrs:
                                                                                        {
                                                                                          href: "",
                                                                                          "data-toggle":
                                                                                            "tab",
                                                                                          "data-target":
                                                                                            "#closed-hours-voice-prompt-tts",
                                                                                          "aria-expanded":
                                                                                            "true",
                                                                                        },
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "i",
                                                                                        {
                                                                                          staticClass:
                                                                                            "fa fa-robot",
                                                                                        }
                                                                                      ),
                                                                                      _vm._v(
                                                                                        "\n                                                                                Text to Speech\n                                                                            "
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _vm.hasPermissionTo(
                                                                                "upload file campaign"
                                                                              )
                                                                                ? _c(
                                                                                    "li",
                                                                                    {
                                                                                      staticClass:
                                                                                        "nav-item",
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "a",
                                                                                        {
                                                                                          staticClass:
                                                                                            "border rounded-top-right",
                                                                                          class:
                                                                                            _vm
                                                                                              .closedHoursMissedCallVoicemailNavClass
                                                                                              .play,
                                                                                          attrs:
                                                                                            {
                                                                                              href: "",
                                                                                              "data-toggle":
                                                                                                "tab",
                                                                                              "data-target":
                                                                                                "#closed-hours-voice-prompt-file",
                                                                                              "aria-expanded":
                                                                                                "false",
                                                                                            },
                                                                                        },
                                                                                        [
                                                                                          _c(
                                                                                            "i",
                                                                                            {
                                                                                              staticClass:
                                                                                                "fa fa-play text-md",
                                                                                            }
                                                                                          ),
                                                                                          _vm._v(
                                                                                            "\n                                                                                Play Recording\n                                                                            "
                                                                                          ),
                                                                                        ]
                                                                                      ),
                                                                                    ]
                                                                                  )
                                                                                : _vm._e(),
                                                                            ]
                                                                          ),
                                                                        ]
                                                                      ),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "tab-content p-a m-b-md border border-top-0",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              class:
                                                                                _vm
                                                                                  .closedHoursMissedCallVoicemailContainerClass
                                                                                  .read,
                                                                              attrs:
                                                                                {
                                                                                  id: "closed-hours-voice-prompt-tts",
                                                                                  "aria-expanded":
                                                                                    "true",
                                                                                },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "el-form-item",
                                                                                {
                                                                                  staticClass:
                                                                                    "no-border mb-4",
                                                                                  attrs:
                                                                                    {
                                                                                      label:
                                                                                        "Voice Prompt",
                                                                                      prop: "closed_hours_voice_prompt",
                                                                                    },
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "div",
                                                                                    {
                                                                                      staticClass:
                                                                                        "row",
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "div",
                                                                                        {
                                                                                          staticClass:
                                                                                            "col-12",
                                                                                        },
                                                                                        [
                                                                                          _c(
                                                                                            "div",
                                                                                            {
                                                                                              staticClass:
                                                                                                "input-group",
                                                                                            },
                                                                                            [
                                                                                              _c(
                                                                                                "input",
                                                                                                {
                                                                                                  directives:
                                                                                                    [
                                                                                                      {
                                                                                                        name: "model",
                                                                                                        rawName:
                                                                                                          "v-model",
                                                                                                        value:
                                                                                                          _vm
                                                                                                            .campaign
                                                                                                            .closed_hours_voice_prompt
                                                                                                            .tts,
                                                                                                        expression:
                                                                                                          "campaign.closed_hours_voice_prompt.tts",
                                                                                                      },
                                                                                                    ],
                                                                                                  staticClass:
                                                                                                    "form-control pt-2 pb-2",
                                                                                                  attrs:
                                                                                                    {
                                                                                                      type: "text",
                                                                                                    },
                                                                                                  domProps:
                                                                                                    {
                                                                                                      value:
                                                                                                        _vm
                                                                                                          .campaign
                                                                                                          .closed_hours_voice_prompt
                                                                                                          .tts,
                                                                                                    },
                                                                                                  on: {
                                                                                                    input:
                                                                                                      [
                                                                                                        function (
                                                                                                          $event
                                                                                                        ) {
                                                                                                          if (
                                                                                                            $event
                                                                                                              .target
                                                                                                              .composing
                                                                                                          )
                                                                                                            return
                                                                                                          _vm.$set(
                                                                                                            _vm
                                                                                                              .campaign
                                                                                                              .closed_hours_voice_prompt,
                                                                                                            "tts",
                                                                                                            $event
                                                                                                              .target
                                                                                                              .value
                                                                                                          )
                                                                                                        },
                                                                                                        function (
                                                                                                          $event
                                                                                                        ) {
                                                                                                          return _vm.preValidateForm(
                                                                                                            "campaign_settings"
                                                                                                          )
                                                                                                        },
                                                                                                      ],
                                                                                                  },
                                                                                                }
                                                                                              ),
                                                                                              _vm._v(
                                                                                                " "
                                                                                              ),
                                                                                              _c(
                                                                                                "variable-dialog"
                                                                                              ),
                                                                                            ],
                                                                                            1
                                                                                          ),
                                                                                        ]
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                          _vm._v(
                                                                            " "
                                                                          ),
                                                                          _vm.hasPermissionTo(
                                                                            "upload file campaign"
                                                                          )
                                                                            ? _c(
                                                                                "div",
                                                                                {
                                                                                  class:
                                                                                    _vm
                                                                                      .closedHoursMissedCallVoicemailContainerClass
                                                                                      .play,
                                                                                  attrs:
                                                                                    {
                                                                                      id: "closed-hours-voice-prompt-file",
                                                                                      "aria-expanded":
                                                                                        "false",
                                                                                    },
                                                                                },
                                                                                [
                                                                                  _vm
                                                                                    .campaign
                                                                                    .closed_hours_voice_prompt
                                                                                    .file ===
                                                                                  null
                                                                                    ? _c(
                                                                                        "audio-recorder",
                                                                                        {
                                                                                          staticClass:
                                                                                            "audio-recorder-wrapper",
                                                                                          attrs:
                                                                                            {
                                                                                              action:
                                                                                                "/api/v1/campaign/" +
                                                                                                _vm
                                                                                                  .campaign
                                                                                                  .id +
                                                                                                "/upload-ivr/closed_hours_voice_prompts",
                                                                                              upload_type:
                                                                                                "closed_hours_voice_prompt_file",
                                                                                            },
                                                                                          on: {
                                                                                            applyUploadedAudio:
                                                                                              _vm.applyUploadedAudio,
                                                                                          },
                                                                                        }
                                                                                      )
                                                                                    : _vm._e(),
                                                                                  _vm._v(
                                                                                    " "
                                                                                  ),
                                                                                  _c(
                                                                                    "div",
                                                                                    {
                                                                                      staticClass:
                                                                                        "audio-uploader-wrapper",
                                                                                    },
                                                                                    [
                                                                                      _vm
                                                                                        .campaign
                                                                                        .closed_hours_voice_prompt
                                                                                        .file ===
                                                                                      null
                                                                                        ? _c(
                                                                                            "el-upload",
                                                                                            {
                                                                                              attrs:
                                                                                                {
                                                                                                  drag: "",
                                                                                                  action:
                                                                                                    "/api/v1/campaign/" +
                                                                                                    _vm
                                                                                                      .campaign
                                                                                                      .id +
                                                                                                    "/upload-ivr/closed_hours_voice_prompts",
                                                                                                  headers:
                                                                                                    _vm.headers,
                                                                                                  "on-success":
                                                                                                    _vm.onSuccessClosedHoursMissedCallVoicemail,
                                                                                                  "on-error":
                                                                                                    _vm.onFailedClosedHoursMissedCallVoicemail,
                                                                                                  "on-progress":
                                                                                                    _vm.progressUploadClosedHoursMissedCallVoicemail,
                                                                                                  "before-upload":
                                                                                                    _vm.beforeUploadClosedHoursMissedCallVoicemail,
                                                                                                  "file-list":
                                                                                                    _vm
                                                                                                      .uploadFileList
                                                                                                      .closed_hours_voice_prompt,
                                                                                                },
                                                                                            },
                                                                                            [
                                                                                              _c(
                                                                                                "i",
                                                                                                {
                                                                                                  staticClass:
                                                                                                    "el-icon-upload",
                                                                                                }
                                                                                              ),
                                                                                              _vm._v(
                                                                                                " "
                                                                                              ),
                                                                                              _c(
                                                                                                "div",
                                                                                                {
                                                                                                  staticClass:
                                                                                                    "el-upload__text",
                                                                                                },
                                                                                                [
                                                                                                  _vm._v(
                                                                                                    "\n                                                                                    Drop file here or "
                                                                                                  ),
                                                                                                  _c(
                                                                                                    "em",
                                                                                                    [
                                                                                                      _vm._v(
                                                                                                        "click\n                                                                                    to\n                                                                                    upload"
                                                                                                      ),
                                                                                                    ]
                                                                                                  ),
                                                                                                ]
                                                                                              ),
                                                                                              _vm._v(
                                                                                                " "
                                                                                              ),
                                                                                              _c(
                                                                                                "div",
                                                                                                {
                                                                                                  staticClass:
                                                                                                    "el-upload__tip",
                                                                                                  attrs:
                                                                                                    {
                                                                                                      slot: "tip",
                                                                                                    },
                                                                                                  slot: "tip",
                                                                                                },
                                                                                                [
                                                                                                  _vm._v(
                                                                                                    "\n                                                                                    MP3/WAV file (less than 8MB)\n                                                                                "
                                                                                                  ),
                                                                                                ]
                                                                                              ),
                                                                                            ]
                                                                                          )
                                                                                        : _vm._e(),
                                                                                      _vm._v(
                                                                                        " "
                                                                                      ),
                                                                                      _vm
                                                                                        .campaign
                                                                                        .closed_hours_voice_prompt
                                                                                        .file ===
                                                                                      null
                                                                                        ? _c(
                                                                                            "el-progress",
                                                                                            {
                                                                                              attrs:
                                                                                                {
                                                                                                  "text-inside": true,
                                                                                                  "stroke-width": 18,
                                                                                                  percentage:
                                                                                                    _vm
                                                                                                      .uploadPercentage
                                                                                                      .closed_hours_voice_prompt,
                                                                                                  status:
                                                                                                    _vm
                                                                                                      .uploadStatus
                                                                                                      .closed_hours_voice_prompt,
                                                                                                },
                                                                                            }
                                                                                          )
                                                                                        : _vm._e(),
                                                                                      _vm._v(
                                                                                        " "
                                                                                      ),
                                                                                      _vm
                                                                                        .campaign
                                                                                        .closed_hours_voice_prompt
                                                                                        .file !==
                                                                                      null
                                                                                        ? _c(
                                                                                            "audio",
                                                                                            {
                                                                                              attrs:
                                                                                                {
                                                                                                  controls:
                                                                                                    "",
                                                                                                },
                                                                                            },
                                                                                            [
                                                                                              _c(
                                                                                                "source",
                                                                                                {
                                                                                                  attrs:
                                                                                                    {
                                                                                                      src:
                                                                                                        "/static/uploaded_file/" +
                                                                                                        _vm
                                                                                                          .campaign
                                                                                                          .closed_hours_voice_prompt
                                                                                                          .file,
                                                                                                    },
                                                                                                }
                                                                                              ),
                                                                                              _vm._v(
                                                                                                "\n                                                                                Your browser does not support\n                                                                                the\n                                                                                audio element.\n                                                                            "
                                                                                              ),
                                                                                            ]
                                                                                          )
                                                                                        : _vm._e(),
                                                                                      _vm._v(
                                                                                        " "
                                                                                      ),
                                                                                      _vm
                                                                                        .campaign
                                                                                        .closed_hours_voice_prompt
                                                                                        .file !==
                                                                                        null &&
                                                                                      _vm.hasPermissionTo(
                                                                                        "delete file campaign"
                                                                                      )
                                                                                        ? _c(
                                                                                            "button",
                                                                                            {
                                                                                              staticClass:
                                                                                                "btn btn-sm btn-danger pull-right",
                                                                                              on: {
                                                                                                click:
                                                                                                  function (
                                                                                                    $event
                                                                                                  ) {
                                                                                                    $event.preventDefault()
                                                                                                    return _vm.deleteFile(
                                                                                                      "closed_hours_voice_prompts"
                                                                                                    )
                                                                                                  },
                                                                                              },
                                                                                            },
                                                                                            [
                                                                                              _c(
                                                                                                "i",
                                                                                                {
                                                                                                  directives:
                                                                                                    [
                                                                                                      {
                                                                                                        name: "show",
                                                                                                        rawName:
                                                                                                          "v-show",
                                                                                                        value:
                                                                                                          _vm.loading_closed_hours_voice_prompt,
                                                                                                        expression:
                                                                                                          "loading_closed_hours_voice_prompt",
                                                                                                      },
                                                                                                    ],
                                                                                                  staticClass:
                                                                                                    "material-icons loader pull-left",
                                                                                                },
                                                                                                [
                                                                                                  _vm._v(
                                                                                                    ""
                                                                                                  ),
                                                                                                ]
                                                                                              ),
                                                                                              _vm._v(
                                                                                                " "
                                                                                              ),
                                                                                              _c(
                                                                                                "i",
                                                                                                {
                                                                                                  directives:
                                                                                                    [
                                                                                                      {
                                                                                                        name: "show",
                                                                                                        rawName:
                                                                                                          "v-show",
                                                                                                        value:
                                                                                                          !_vm.loading_closed_hours_voice_prompt,
                                                                                                        expression:
                                                                                                          "!loading_closed_hours_voice_prompt",
                                                                                                      },
                                                                                                    ],
                                                                                                  staticClass:
                                                                                                    "material-icons pull-left",
                                                                                                },
                                                                                                [
                                                                                                  _vm._v(
                                                                                                    ""
                                                                                                  ),
                                                                                                ]
                                                                                              ),
                                                                                              _vm._v(
                                                                                                " "
                                                                                              ),
                                                                                              _c(
                                                                                                "span",
                                                                                                [
                                                                                                  _vm._v(
                                                                                                    "Remove file"
                                                                                                  ),
                                                                                                ]
                                                                                              ),
                                                                                            ]
                                                                                          )
                                                                                        : _vm._e(),
                                                                                    ],
                                                                                    1
                                                                                  ),
                                                                                ],
                                                                                1
                                                                              )
                                                                            : _vm._e(),
                                                                        ]
                                                                      ),
                                                                    ])
                                                                  : _vm._e(),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "row mt-3",
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "col-12",
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "form-group",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "el-checkbox",
                                                                      {
                                                                        on: {
                                                                          change:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.preValidateForm(
                                                                                "campaign_settings"
                                                                              )
                                                                            },
                                                                        },
                                                                        model: {
                                                                          value:
                                                                            _vm
                                                                              .campaign
                                                                              .closed_hours_auto_reply_text
                                                                              .enabled,
                                                                          callback:
                                                                            function (
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                _vm
                                                                                  .campaign
                                                                                  .closed_hours_auto_reply_text,
                                                                                "enabled",
                                                                                $$v
                                                                              )
                                                                            },
                                                                          expression:
                                                                            "campaign.closed_hours_auto_reply_text.enabled",
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "\n                                                                    Closed Hours Auto Reply Text\n                                                                "
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _vm._v(" "),
                                                                    _c(
                                                                      "label",
                                                                      {
                                                                        staticClass:
                                                                          "help-text",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "\n                                                                    Auto reply all incoming messages with a\n                                                                    canned response during business closure\n                                                                    hours.\n                                                                "
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                                _vm._v(" "),
                                                                _vm.campaign
                                                                  .closed_hours_auto_reply_text
                                                                  .enabled
                                                                  ? _c(
                                                                      "div",
                                                                      [
                                                                        _c(
                                                                          "el-form-item",
                                                                          {
                                                                            staticClass:
                                                                              "no-border",
                                                                            attrs:
                                                                              {
                                                                                label:
                                                                                  "Auto Reply Text",
                                                                                prop: "closed_hours_auto_reply_text",
                                                                              },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "div",
                                                                              {
                                                                                staticClass:
                                                                                  "row",
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "div",
                                                                                  {
                                                                                    staticClass:
                                                                                      "col-12",
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "div",
                                                                                      {
                                                                                        staticClass:
                                                                                          "input-group",
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "textarea",
                                                                                          {
                                                                                            directives:
                                                                                              [
                                                                                                {
                                                                                                  name: "model",
                                                                                                  rawName:
                                                                                                    "v-model",
                                                                                                  value:
                                                                                                    _vm
                                                                                                      .campaign
                                                                                                      .closed_hours_auto_reply_text
                                                                                                      .message,
                                                                                                  expression:
                                                                                                    "campaign.closed_hours_auto_reply_text.message",
                                                                                                },
                                                                                              ],
                                                                                            staticClass:
                                                                                              "form-control pt-2 pb-2",
                                                                                            attrs:
                                                                                              {
                                                                                                type: "text",
                                                                                                rows: "2",
                                                                                              },
                                                                                            domProps:
                                                                                              {
                                                                                                value:
                                                                                                  _vm
                                                                                                    .campaign
                                                                                                    .closed_hours_auto_reply_text
                                                                                                    .message,
                                                                                              },
                                                                                            on: {
                                                                                              input:
                                                                                                [
                                                                                                  function (
                                                                                                    $event
                                                                                                  ) {
                                                                                                    if (
                                                                                                      $event
                                                                                                        .target
                                                                                                        .composing
                                                                                                    )
                                                                                                      return
                                                                                                    _vm.$set(
                                                                                                      _vm
                                                                                                        .campaign
                                                                                                        .closed_hours_auto_reply_text,
                                                                                                      "message",
                                                                                                      $event
                                                                                                        .target
                                                                                                        .value
                                                                                                    )
                                                                                                  },
                                                                                                  function (
                                                                                                    $event
                                                                                                  ) {
                                                                                                    return _vm.preValidateForm(
                                                                                                      "campaign_settings"
                                                                                                    )
                                                                                                  },
                                                                                                ],
                                                                                            },
                                                                                          }
                                                                                        ),
                                                                                        _vm._v(
                                                                                          " "
                                                                                        ),
                                                                                        _c(
                                                                                          "variable-dialog"
                                                                                        ),
                                                                                      ],
                                                                                      1
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ],
                                                                      1
                                                                    )
                                                                  : _vm._e(),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass: "text-center my-4",
                                              },
                                              [
                                                _c("i", {
                                                  staticClass:
                                                    "fas fa-arrow-down",
                                                  staticStyle: {
                                                    "font-size": "30px",
                                                    color: "#acacac",
                                                  },
                                                }),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "el-form-item",
                                              { staticClass: "card pb-0" },
                                              [
                                                _c("span", {
                                                  staticClass: "page-anchor",
                                                  attrs: { id: "tcpa" },
                                                }),
                                                _vm._v(" "),
                                                _c(
                                                  "el-popover",
                                                  {
                                                    ref: "popover_record_info",
                                                    attrs: {
                                                      placement: "top-start",
                                                      width: "600",
                                                      trigger: "hover",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "p",
                                                      { staticClass: "mb-0" },
                                                      [
                                                        _vm._v(
                                                          "\n                                                    Prompt the caller to press one and explicitly authorize receiving a text message from you.\n                                                    Although not mandatory, this feature satisfies most TCPA compliance requirements.\n                                                    Prompt the caller to press ONE.\n                                                "
                                                        ),
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "card-header",
                                                        attrs: {
                                                          slot: "reference",
                                                        },
                                                        slot: "reference",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "switch-label",
                                                          },
                                                          [
                                                            _c("el-switch", {
                                                              attrs: {
                                                                "active-color":
                                                                  "#00a344",
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.campaign
                                                                    .should_ask_for_text_authorization,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.campaign,
                                                                      "should_ask_for_text_authorization",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "campaign.should_ask_for_text_authorization",
                                                              },
                                                            }),
                                                            _vm._v(" "),
                                                            _c("label", [
                                                              _vm._v(
                                                                "TCPA: Ask For Text Authorization?"
                                                              ),
                                                            ]),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "transition",
                                                  {
                                                    attrs: {
                                                      name: "el-fade-in-linear",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        directives: [
                                                          {
                                                            name: "show",
                                                            rawName: "v-show",
                                                            value:
                                                              _vm.campaign
                                                                .should_ask_for_text_authorization,
                                                            expression:
                                                              "campaign.should_ask_for_text_authorization",
                                                          },
                                                        ],
                                                        staticClass:
                                                          "card-body",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass: "row",
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "col-12",
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "b-b nav-active-greenish",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "ul",
                                                                      {
                                                                        staticClass:
                                                                          "nav nav-tabs",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "li",
                                                                          {
                                                                            staticClass:
                                                                              "nav-item",
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "a",
                                                                              {
                                                                                staticClass:
                                                                                  "border border-right-0 rounded-top-left",
                                                                                class:
                                                                                  _vm
                                                                                    .textAuthorizationNavClass
                                                                                    .read,
                                                                                attrs:
                                                                                  {
                                                                                    href: "",
                                                                                    "data-toggle":
                                                                                      "tab",
                                                                                    "data-target":
                                                                                      "#ask-for-text-auth-tts",
                                                                                    "aria-expanded":
                                                                                      "true",
                                                                                  },
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "i",
                                                                                  {
                                                                                    staticClass:
                                                                                      "fa fa-robot",
                                                                                  }
                                                                                ),
                                                                                _vm._v(
                                                                                  "\n                                                                            Text to Speech\n                                                                        "
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _vm._v(
                                                                          " "
                                                                        ),
                                                                        _vm.hasPermissionTo(
                                                                          [
                                                                            "update ring group",
                                                                            "create ring group",
                                                                            "view ring group",
                                                                          ]
                                                                        )
                                                                          ? _c(
                                                                              "li",
                                                                              {
                                                                                staticClass:
                                                                                  "nav-item",
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "a",
                                                                                  {
                                                                                    staticClass:
                                                                                      "border rounded-top-right",
                                                                                    class:
                                                                                      _vm
                                                                                        .textAuthorizationNavClass
                                                                                        .play,
                                                                                    attrs:
                                                                                      {
                                                                                        href: "",
                                                                                        "data-toggle":
                                                                                          "tab",
                                                                                        "data-target":
                                                                                          "#ask-for-text-auth-file",
                                                                                        "aria-expanded":
                                                                                          "false",
                                                                                      },
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "i",
                                                                                      {
                                                                                        staticClass:
                                                                                          "fa fa-play text-md",
                                                                                      }
                                                                                    ),
                                                                                    _vm._v(
                                                                                      "\n                                                                            Play Recording\n                                                                        "
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                              ]
                                                                            )
                                                                          : _vm._e(),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "tab-content p-a m-b-md border border-top-0",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        class:
                                                                          _vm
                                                                            .textAuthorizationContainerClass
                                                                            .read,
                                                                        attrs: {
                                                                          id: "ask-for-text-auth-tts",
                                                                          "aria-expanded":
                                                                            "true",
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "el-form-item",
                                                                          {
                                                                            staticClass:
                                                                              "no-border mb-4",
                                                                            attrs:
                                                                              {
                                                                                label:
                                                                                  "Prompt",
                                                                                prop: "should_ask_for_text_authorization",
                                                                              },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "div",
                                                                              {
                                                                                staticClass:
                                                                                  "row",
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "div",
                                                                                  {
                                                                                    staticClass:
                                                                                      "col-12",
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "div",
                                                                                      {
                                                                                        staticClass:
                                                                                          "input-group",
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "input",
                                                                                          {
                                                                                            directives:
                                                                                              [
                                                                                                {
                                                                                                  name: "model",
                                                                                                  rawName:
                                                                                                    "v-model",
                                                                                                  value:
                                                                                                    _vm
                                                                                                      .campaign
                                                                                                      .ask_for_text_authorization_tts,
                                                                                                  expression:
                                                                                                    "campaign.ask_for_text_authorization_tts",
                                                                                                },
                                                                                              ],
                                                                                            staticClass:
                                                                                              "form-control pt-2 pb-2",
                                                                                            attrs:
                                                                                              {
                                                                                                type: "text",
                                                                                              },
                                                                                            domProps:
                                                                                              {
                                                                                                value:
                                                                                                  _vm
                                                                                                    .campaign
                                                                                                    .ask_for_text_authorization_tts,
                                                                                              },
                                                                                            on: {
                                                                                              input:
                                                                                                [
                                                                                                  function (
                                                                                                    $event
                                                                                                  ) {
                                                                                                    if (
                                                                                                      $event
                                                                                                        .target
                                                                                                        .composing
                                                                                                    )
                                                                                                      return
                                                                                                    _vm.$set(
                                                                                                      _vm.campaign,
                                                                                                      "ask_for_text_authorization_tts",
                                                                                                      $event
                                                                                                        .target
                                                                                                        .value
                                                                                                    )
                                                                                                  },
                                                                                                  function (
                                                                                                    $event
                                                                                                  ) {
                                                                                                    return _vm.preValidateForm(
                                                                                                      "campaign_settings"
                                                                                                    )
                                                                                                  },
                                                                                                ],
                                                                                            },
                                                                                          }
                                                                                        ),
                                                                                        _vm._v(
                                                                                          " "
                                                                                        ),
                                                                                        _c(
                                                                                          "variable-dialog"
                                                                                        ),
                                                                                      ],
                                                                                      1
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                    _vm._v(" "),
                                                                    _vm.hasPermissionTo(
                                                                      [
                                                                        "update ring group",
                                                                        "create ring group",
                                                                        "view ring group",
                                                                      ]
                                                                    )
                                                                      ? _c(
                                                                          "div",
                                                                          {
                                                                            class:
                                                                              _vm
                                                                                .textAuthorizationContainerClass
                                                                                .play,
                                                                            attrs:
                                                                              {
                                                                                id: "ask-for-text-auth-file",
                                                                                "aria-expanded":
                                                                                  "false",
                                                                              },
                                                                          },
                                                                          [
                                                                            _vm
                                                                              .campaign
                                                                              .ask_for_text_authorization_file ===
                                                                            null
                                                                              ? _c(
                                                                                  "audio-recorder",
                                                                                  {
                                                                                    staticClass:
                                                                                      "audio-recorder-wrapper",
                                                                                    attrs:
                                                                                      {
                                                                                        action:
                                                                                          "/api/v1/campaign/" +
                                                                                          _vm
                                                                                            .campaign
                                                                                            .id +
                                                                                          "/upload-ivr/text_authorizations",
                                                                                        upload_type:
                                                                                          "ask_for_text_authorization_file",
                                                                                      },
                                                                                    on: {
                                                                                      applyUploadedAudio:
                                                                                        _vm.applyUploadedAudio,
                                                                                    },
                                                                                  }
                                                                                )
                                                                              : _vm._e(),
                                                                            _vm._v(
                                                                              " "
                                                                            ),
                                                                            _c(
                                                                              "div",
                                                                              {
                                                                                staticClass:
                                                                                  "audio-uploader-wrapper",
                                                                              },
                                                                              [
                                                                                _vm
                                                                                  .campaign
                                                                                  .ask_for_text_authorization_file ===
                                                                                null
                                                                                  ? _c(
                                                                                      "el-upload",
                                                                                      {
                                                                                        attrs:
                                                                                          {
                                                                                            drag: "",
                                                                                            action:
                                                                                              "/api/v1/campaign/" +
                                                                                              _vm
                                                                                                .campaign
                                                                                                .id +
                                                                                              "/upload-ivr/text_authorizations",
                                                                                            headers:
                                                                                              _vm.headers,
                                                                                            "on-success":
                                                                                              _vm.onSuccessTextAuthorization,
                                                                                            "on-error":
                                                                                              _vm.onFailedTextAuthorization,
                                                                                            "on-progress":
                                                                                              _vm.progressUploadTextAuthorization,
                                                                                            "before-upload":
                                                                                              _vm.beforeUploadTextAuthorization,
                                                                                            "file-list":
                                                                                              _vm
                                                                                                .uploadFileList
                                                                                                .askForTextAuthorization,
                                                                                          },
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "i",
                                                                                          {
                                                                                            staticClass:
                                                                                              "el-icon-upload",
                                                                                          }
                                                                                        ),
                                                                                        _vm._v(
                                                                                          " "
                                                                                        ),
                                                                                        _c(
                                                                                          "div",
                                                                                          {
                                                                                            staticClass:
                                                                                              "el-upload__text",
                                                                                          },
                                                                                          [
                                                                                            _vm._v(
                                                                                              "\n                                                                                Drop file here or "
                                                                                            ),
                                                                                            _c(
                                                                                              "em",
                                                                                              [
                                                                                                _vm._v(
                                                                                                  "click to\n                                                                                upload"
                                                                                                ),
                                                                                              ]
                                                                                            ),
                                                                                          ]
                                                                                        ),
                                                                                        _vm._v(
                                                                                          " "
                                                                                        ),
                                                                                        _c(
                                                                                          "div",
                                                                                          {
                                                                                            staticClass:
                                                                                              "el-upload__tip",
                                                                                            attrs:
                                                                                              {
                                                                                                slot: "tip",
                                                                                              },
                                                                                            slot: "tip",
                                                                                          },
                                                                                          [
                                                                                            _vm._v(
                                                                                              "\n                                                                                MP3/WAV file (less than 8MB)\n                                                                            "
                                                                                            ),
                                                                                          ]
                                                                                        ),
                                                                                      ]
                                                                                    )
                                                                                  : _vm._e(),
                                                                                _vm._v(
                                                                                  " "
                                                                                ),
                                                                                _vm
                                                                                  .campaign
                                                                                  .ask_for_text_authorization_file ===
                                                                                null
                                                                                  ? _c(
                                                                                      "el-progress",
                                                                                      {
                                                                                        attrs:
                                                                                          {
                                                                                            "text-inside": true,
                                                                                            "stroke-width": 18,
                                                                                            percentage:
                                                                                              _vm
                                                                                                .uploadPercentage
                                                                                                .askForTextAuthorization,
                                                                                            status:
                                                                                              _vm
                                                                                                .uploadStatus
                                                                                                .askForTextAuthorization,
                                                                                          },
                                                                                      }
                                                                                    )
                                                                                  : _vm._e(),
                                                                                _vm._v(
                                                                                  " "
                                                                                ),
                                                                                _vm
                                                                                  .campaign
                                                                                  .ask_for_text_authorization_file !==
                                                                                null
                                                                                  ? _c(
                                                                                      "audio",
                                                                                      {
                                                                                        attrs:
                                                                                          {
                                                                                            controls:
                                                                                              "",
                                                                                          },
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "source",
                                                                                          {
                                                                                            attrs:
                                                                                              {
                                                                                                src:
                                                                                                  "/static/uploaded_file/" +
                                                                                                  _vm
                                                                                                    .campaign
                                                                                                    .ask_for_text_authorization_file,
                                                                                              },
                                                                                          }
                                                                                        ),
                                                                                        _vm._v(
                                                                                          "\n                                                                            Your browser does not support the\n                                                                            audio\n                                                                            element.\n                                                                        "
                                                                                        ),
                                                                                      ]
                                                                                    )
                                                                                  : _vm._e(),
                                                                                _vm._v(
                                                                                  " "
                                                                                ),
                                                                                _vm
                                                                                  .campaign
                                                                                  .ask_for_text_authorization_file !==
                                                                                  null &&
                                                                                _vm.hasPermissionTo(
                                                                                  "delete ring group"
                                                                                )
                                                                                  ? _c(
                                                                                      "button",
                                                                                      {
                                                                                        staticClass:
                                                                                          "btn btn-sm btn-danger pull-right",
                                                                                        on: {
                                                                                          click:
                                                                                            function (
                                                                                              $event
                                                                                            ) {
                                                                                              $event.preventDefault()
                                                                                              return _vm.deleteFile(
                                                                                                "text_authorizations"
                                                                                              )
                                                                                            },
                                                                                        },
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "i",
                                                                                          {
                                                                                            directives:
                                                                                              [
                                                                                                {
                                                                                                  name: "show",
                                                                                                  rawName:
                                                                                                    "v-show",
                                                                                                  value:
                                                                                                    _vm.loading_ask_for_text_authorization,
                                                                                                  expression:
                                                                                                    "loading_ask_for_text_authorization",
                                                                                                },
                                                                                              ],
                                                                                            staticClass:
                                                                                              "material-icons loader pull-left",
                                                                                          },
                                                                                          [
                                                                                            _vm._v(
                                                                                              ""
                                                                                            ),
                                                                                          ]
                                                                                        ),
                                                                                        _vm._v(
                                                                                          " "
                                                                                        ),
                                                                                        _c(
                                                                                          "i",
                                                                                          {
                                                                                            directives:
                                                                                              [
                                                                                                {
                                                                                                  name: "show",
                                                                                                  rawName:
                                                                                                    "v-show",
                                                                                                  value:
                                                                                                    !_vm.loading_ask_for_text_authorization,
                                                                                                  expression:
                                                                                                    "!loading_ask_for_text_authorization",
                                                                                                },
                                                                                              ],
                                                                                            staticClass:
                                                                                              "material-icons pull-left",
                                                                                          },
                                                                                          [
                                                                                            _vm._v(
                                                                                              ""
                                                                                            ),
                                                                                          ]
                                                                                        ),
                                                                                        _vm._v(
                                                                                          " "
                                                                                        ),
                                                                                        _c(
                                                                                          "span",
                                                                                          [
                                                                                            _vm._v(
                                                                                              "Remove file"
                                                                                            ),
                                                                                          ]
                                                                                        ),
                                                                                      ]
                                                                                    )
                                                                                  : _vm._e(),
                                                                              ],
                                                                              1
                                                                            ),
                                                                          ],
                                                                          1
                                                                        )
                                                                      : _vm._e(),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass: "text-center my-4",
                                              },
                                              [
                                                _c("i", {
                                                  staticClass:
                                                    "fas fa-arrow-down",
                                                  staticStyle: {
                                                    "font-size": "30px",
                                                    color: "#acacac",
                                                  },
                                                }),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "text-center form-label",
                                              },
                                              [
                                                _c("i", {
                                                  staticClass:
                                                    "fas fa-network-wired",
                                                  staticStyle: {
                                                    "font-size": "40px",
                                                    color: "#acacac",
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _c(
                                                  "h5",
                                                  { staticClass: "mt-2" },
                                                  [
                                                    _c("i", {
                                                      staticClass:
                                                        "fa fa-check-circle-o text-success",
                                                    }),
                                                    _vm._v(
                                                      "\n                                                Ready to route\n                                            "
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c("small", {}, [
                                                  _vm._v(
                                                    "\n                                                Proceed to\n                                                "
                                                  ),
                                                  _c(
                                                    "a",
                                                    {
                                                      staticClass:
                                                        "text-success",
                                                      on: {
                                                        click:
                                                          _vm.loadRoutingIvr,
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                                                    Routing and IVR\n                                                "
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(
                                                    "\n                                                settings\n                                            "
                                                  ),
                                                ]),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "col-md-2 d-none d-md-block ml-auto",
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "list-group list-group-alo list-group-flush sticky-top stick-top-with-header",
                                              },
                                              [
                                                _c(
                                                  "h6",
                                                  {
                                                    staticClass:
                                                      "list-group-item",
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                                                On This Page\n                                            "
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "a",
                                                  {
                                                    staticClass:
                                                      "list-group-item list-group-item-action",
                                                    attrs: {
                                                      href: "#greet_caller",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.shine(
                                                          "greet_caller"
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                                                Greet/Welcome Callers\n                                            "
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "a",
                                                  {
                                                    staticClass:
                                                      "list-group-item list-group-item-action",
                                                    attrs: {
                                                      href: "#call_recording",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.shine(
                                                          "call_recording"
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                                                Call Recording\n                                            "
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "a",
                                                  {
                                                    staticClass:
                                                      "list-group-item list-group-item-action",
                                                    attrs: {
                                                      href: "#check_operating_hours",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.shine(
                                                          "check_operating_hours"
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                                                Check Operating Hours\n                                            "
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "a",
                                                  {
                                                    staticClass:
                                                      "list-group-item list-group-item-action",
                                                    attrs: { href: "#tcpa" },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.shine("tcpa")
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                                                TCPA: Text Authorization\n                                            "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]),
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              !_vm.campaign.is_fax
                                ? _c(
                                    "el-tab-pane",
                                    {
                                      staticClass: "p-3",
                                      attrs: {
                                        name: "routing-settings",
                                        "data-tab-error": "routing_settings",
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          attrs: { slot: "label" },
                                          slot: "label",
                                        },
                                        [
                                          _vm._v("Routing & IVR"),
                                          _c("span", {
                                            domProps: {
                                              innerHTML: _vm._s(
                                                _vm.tabLabelErrorIcon(
                                                  _vm.tab_errors
                                                    .routing_settings
                                                )
                                              ),
                                            },
                                          }),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "row" }, [
                                        _c(
                                          "div",
                                          { staticClass: "col-md-9" },
                                          [
                                            _c(
                                              "el-form-item",
                                              [
                                                _c("span", {
                                                  staticClass: "page-anchor",
                                                  attrs: { id: "answer" },
                                                }),
                                                _vm._v(" "),
                                                _c(
                                                  "el-timeline",
                                                  {
                                                    staticClass: "ivr-timeline",
                                                  },
                                                  [
                                                    _c(
                                                      "el-timeline-item",
                                                      {
                                                        attrs: {
                                                          placement: "top",
                                                          size: "large",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "template",
                                                          { slot: "dot" },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "timeline-dot",
                                                              },
                                                              [
                                                                _c("div", {
                                                                  staticClass:
                                                                    "el-timeline-item__node el-timeline-item__node--xlarge success",
                                                                }),
                                                              ]
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "label",
                                                              {
                                                                staticClass:
                                                                  "el-form-item__label ml-2",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                                                            Who should answer this call?\n                                                        "
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      2
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "el-timeline-item",
                                                      {
                                                        attrs: {
                                                          placement: "top",
                                                          type: "primary",
                                                          size: "large",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "template",
                                                          { slot: "dot" },
                                                          [
                                                            _c("el-switch", {
                                                              attrs: {
                                                                "active-color":
                                                                  "#00a344",
                                                                disabled:
                                                                  _vm.to_ring_group,
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.to_ring_group,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.to_ring_group =
                                                                      $$v
                                                                  },
                                                                expression:
                                                                  "to_ring_group",
                                                              },
                                                            }),
                                                            _vm._v(" "),
                                                            _c(
                                                              "label",
                                                              {
                                                                staticClass:
                                                                  "el-form-item__label ml-2",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                                                            A Ring Group\n                                                        "
                                                                ),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "label",
                                                          {
                                                            staticClass:
                                                              "ml-1 help-text",
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                                                        Send the call to a group of agents. Queues are defined\n                                                        per ring group.\n                                                        "
                                                            ),
                                                            _c("el-popover", {
                                                              ref: "popover_ring_group",
                                                              staticClass:
                                                                "ml-2",
                                                              attrs: {
                                                                placement:
                                                                  "right",
                                                                title:
                                                                  "Ring Group",
                                                                width: "400",
                                                                trigger:
                                                                  "hover",
                                                                content:
                                                                  "A ring group is an ordered list of agents\n                                                                    that receive and process calls. This feature\n                                                                    is often used to efficiently distribute\n                                                                    calls to specific departments such as\n                                                                    Sales, Customer Support or others.\n                                                                    You can set up smart queues and callback\n                                                                    options within your ring groups.",
                                                              },
                                                            }),
                                                            _vm._v(" "),
                                                            _c(
                                                              "span",
                                                              {
                                                                directives: [
                                                                  {
                                                                    name: "popover",
                                                                    rawName:
                                                                      "v-popover:popover_ring_group",
                                                                    arg: "popover_ring_group",
                                                                  },
                                                                ],
                                                                staticClass:
                                                                  "form-hover-help text-blue",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                                                                More Info\n                                                        "
                                                                ),
                                                              ]
                                                            ),
                                                            _vm._v(" "),
                                                            _c("br"),
                                                          ],
                                                          1
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "el-card",
                                                          {
                                                            directives: [
                                                              {
                                                                name: "show",
                                                                rawName:
                                                                  "v-show",
                                                                value:
                                                                  _vm.to_ring_group,
                                                                expression:
                                                                  "to_ring_group",
                                                              },
                                                            ],
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "row",
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "col-12",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "el-form-item",
                                                                      {
                                                                        staticClass:
                                                                          "no-border",
                                                                        attrs: {
                                                                          prop: "ring_group_id",
                                                                          label:
                                                                            "Select Ring Group:",
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "ring-group-selector",
                                                                          {
                                                                            staticClass:
                                                                              "pull-left",
                                                                            staticStyle:
                                                                              {
                                                                                width:
                                                                                  "300px",
                                                                              },
                                                                            on: {
                                                                              change:
                                                                                _vm.changeCallRingGroup,
                                                                            },
                                                                            model:
                                                                              {
                                                                                value:
                                                                                  _vm
                                                                                    .campaign
                                                                                    .ring_group_id,
                                                                                callback:
                                                                                  function (
                                                                                    $$v
                                                                                  ) {
                                                                                    _vm.$set(
                                                                                      _vm.campaign,
                                                                                      "ring_group_id",
                                                                                      $$v
                                                                                    )
                                                                                  },
                                                                                expression:
                                                                                  "campaign.ring_group_id",
                                                                              },
                                                                          }
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      2
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "el-timeline-item",
                                                      {
                                                        attrs: {
                                                          placement: "top",
                                                          type: "primary",
                                                          size: "large",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "template",
                                                          { slot: "dot" },
                                                          [
                                                            _c("el-switch", {
                                                              attrs: {
                                                                "active-color":
                                                                  "#00a344",
                                                                disabled:
                                                                  _vm.to_user,
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.to_user,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.to_user =
                                                                      $$v
                                                                  },
                                                                expression:
                                                                  "to_user",
                                                              },
                                                            }),
                                                            _vm._v(" "),
                                                            _c(
                                                              "label",
                                                              {
                                                                staticClass:
                                                                  "el-form-item__label ml-2",
                                                              },
                                                              [_vm._v("A User")]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "label",
                                                          {
                                                            staticClass:
                                                              "ml-1 help-text",
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                                                        Send the call (and assign texts) to this user.\n                                                    "
                                                            ),
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "el-card",
                                                          {
                                                            directives: [
                                                              {
                                                                name: "show",
                                                                rawName:
                                                                  "v-show",
                                                                value:
                                                                  _vm.to_user,
                                                                expression:
                                                                  "to_user",
                                                              },
                                                            ],
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "row",
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "col-12",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "el-form-item",
                                                                      {
                                                                        attrs: {
                                                                          prop: "user_id",
                                                                          label:
                                                                            "Select User:",
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "user-selector",
                                                                          {
                                                                            staticClass:
                                                                              "pull-left",
                                                                            staticStyle:
                                                                              {
                                                                                width:
                                                                                  "300px",
                                                                              },
                                                                            attrs:
                                                                              {
                                                                                ignore_focus_mode: true,
                                                                              },
                                                                            on: {
                                                                              change:
                                                                                _vm.changeUser,
                                                                            },
                                                                            model:
                                                                              {
                                                                                value:
                                                                                  _vm
                                                                                    .campaign
                                                                                    .user_id,
                                                                                callback:
                                                                                  function (
                                                                                    $$v
                                                                                  ) {
                                                                                    _vm.$set(
                                                                                      _vm.campaign,
                                                                                      "user_id",
                                                                                      $$v
                                                                                    )
                                                                                  },
                                                                                expression:
                                                                                  "campaign.user_id",
                                                                              },
                                                                          }
                                                                        ),
                                                                        _vm._v(
                                                                          " "
                                                                        ),
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "mt-1",
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "el-popover",
                                                                              {
                                                                                ref: "popover_call_waiting",
                                                                                attrs:
                                                                                  {
                                                                                    placement:
                                                                                      "top",
                                                                                    title:
                                                                                      "Call Waiting",
                                                                                    width:
                                                                                      "200",
                                                                                    trigger:
                                                                                      "hover",
                                                                                    content:
                                                                                      "Place new calls on hold while on a call",
                                                                                  },
                                                                              }
                                                                            ),
                                                                            _vm._v(
                                                                              " "
                                                                            ),
                                                                            _c(
                                                                              "el-switch",
                                                                              {
                                                                                staticClass:
                                                                                  "ml-2",
                                                                                attrs:
                                                                                  {
                                                                                    "active-color":
                                                                                      "#00a344",
                                                                                  },
                                                                                model:
                                                                                  {
                                                                                    value:
                                                                                      _vm.has_call_waiting,
                                                                                    callback:
                                                                                      function (
                                                                                        $$v
                                                                                      ) {
                                                                                        _vm.has_call_waiting =
                                                                                          $$v
                                                                                      },
                                                                                    expression:
                                                                                      "has_call_waiting",
                                                                                  },
                                                                              }
                                                                            ),
                                                                            _vm._v(
                                                                              " "
                                                                            ),
                                                                            _c(
                                                                              "span",
                                                                              {
                                                                                directives:
                                                                                  [
                                                                                    {
                                                                                      name: "popover",
                                                                                      rawName:
                                                                                        "v-popover:popover_call_waiting",
                                                                                      arg: "popover_call_waiting",
                                                                                    },
                                                                                  ],
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  "Call Waiting"
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ],
                                                                          1
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      2
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "el-timeline-item",
                                                      {
                                                        attrs: {
                                                          placement: "top",
                                                          type: "primary",
                                                          size: "large",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "template",
                                                          { slot: "dot" },
                                                          [
                                                            _c("el-switch", {
                                                              attrs: {
                                                                "active-color":
                                                                  "#00a344",
                                                                disabled:
                                                                  _vm.to_ivr,
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.to_ivr,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.to_ivr =
                                                                      $$v
                                                                  },
                                                                expression:
                                                                  "to_ivr",
                                                              },
                                                            }),
                                                            _vm._v(" "),
                                                            _c(
                                                              "label",
                                                              {
                                                                staticClass:
                                                                  "el-form-item__label ml-2",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                                                            An IVR\n                                                        "
                                                                ),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "label",
                                                          {
                                                            staticClass:
                                                              "ml-1 help-text",
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                                                        An IVR, or phone tree, routes your calls based on caller's\n                                                        choice.\n                                                        "
                                                            ),
                                                            _c("el-popover", {
                                                              ref: "popover_ivr",
                                                              staticClass:
                                                                "ml-2",
                                                              attrs: {
                                                                placement:
                                                                  "right",
                                                                title: "IVR",
                                                                width: "400",
                                                                trigger:
                                                                  "hover",
                                                                content:
                                                                  "Interactive Voice Response (IVR) is a telephony\n                                                                    menu system. Using an IVR\n                                                                    allows your customers to navigate your\n                                                                    phone tree and get connected to the\n                                                                    extension (user) or department (ring group)\n                                                                    that they want.",
                                                              },
                                                            }),
                                                            _vm._v(" "),
                                                            _c(
                                                              "span",
                                                              {
                                                                directives: [
                                                                  {
                                                                    name: "popover",
                                                                    rawName:
                                                                      "v-popover:popover_ivr",
                                                                    arg: "popover_ivr",
                                                                  },
                                                                ],
                                                                staticClass:
                                                                  "form-hover-help text-blue",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                                                                More Info\n                                                        "
                                                                ),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "transition",
                                                          {
                                                            attrs: {
                                                              name: "el-fade-in-linear",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "el-card",
                                                              {
                                                                directives: [
                                                                  {
                                                                    name: "show",
                                                                    rawName:
                                                                      "v-show",
                                                                    value:
                                                                      _vm.to_ivr,
                                                                    expression:
                                                                      "to_ivr",
                                                                  },
                                                                ],
                                                              },
                                                              [
                                                                _c(
                                                                  "ivr-selector",
                                                                  {
                                                                    staticClass:
                                                                      "pull-left",
                                                                    staticStyle:
                                                                      {
                                                                        width:
                                                                          "300px",
                                                                      },
                                                                    attrs: {
                                                                      ignore_focus_mode: true,
                                                                    },
                                                                    on: {
                                                                      change:
                                                                        _vm.changeIvr,
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        _vm
                                                                          .campaign
                                                                          .ivr_id,
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            _vm.campaign,
                                                                            "ivr_id",
                                                                            $$v
                                                                          )
                                                                        },
                                                                      expression:
                                                                        "campaign.ivr_id",
                                                                    },
                                                                  }
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "el-button",
                                                                  {
                                                                    staticClass:
                                                                      "ml-2",
                                                                    attrs: {
                                                                      type: "primary",
                                                                      disabled:
                                                                        !_vm.selected_ivr_id,
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.showIvrModal(
                                                                            false
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "\n                                                                Edit\n                                                            "
                                                                    ),
                                                                  ]
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "el-button",
                                                                  {
                                                                    attrs: {
                                                                      type: "success",
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        _vm.showIvrModal,
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "\n                                                                Create\n                                                            "
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      2
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "el-dialog",
                                                      {
                                                        attrs: {
                                                          top: "5vh",
                                                          width: "70%",
                                                          visible:
                                                            _vm.show_ivr_modal,
                                                          "append-to-body": true,
                                                          "before-close":
                                                            _vm.beforeCloseIvrModal,
                                                        },
                                                      },
                                                      [
                                                        _c("el-card", [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "row",
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "col-12",
                                                                },
                                                                [
                                                                  _c(
                                                                    "el-form-item",
                                                                    {
                                                                      staticClass:
                                                                        "text-md _600",
                                                                      attrs: {
                                                                        label:
                                                                          "IVR name",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "el-input",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              placeholder:
                                                                                "Name of IVR",
                                                                            },
                                                                          model:
                                                                            {
                                                                              value:
                                                                                _vm
                                                                                  .ivr
                                                                                  .name,
                                                                              callback:
                                                                                function (
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    _vm.ivr,
                                                                                    "name",
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                              expression:
                                                                                "ivr.name",
                                                                            },
                                                                        }
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ]
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "row",
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "col-12",
                                                                },
                                                                [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "b-b nav-active-greenish",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "ul",
                                                                        {
                                                                          staticClass:
                                                                            "nav nav-tabs",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "li",
                                                                            {
                                                                              staticClass:
                                                                                "nav-item",
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "a",
                                                                                {
                                                                                  staticClass:
                                                                                    "border border-right-0 rounded-top-left",
                                                                                  class:
                                                                                    _vm
                                                                                      .ivrPromptNavClass
                                                                                      .read,
                                                                                  attrs:
                                                                                    {
                                                                                      href: "",
                                                                                      "data-toggle":
                                                                                        "tab",
                                                                                      "data-target":
                                                                                        "#ivr-prompt-tts",
                                                                                      "aria-expanded":
                                                                                        "true",
                                                                                    },
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "i",
                                                                                    {
                                                                                      staticClass:
                                                                                        "fa fa-robot",
                                                                                    }
                                                                                  ),
                                                                                  _vm._v(
                                                                                    "\n                                                                                Text to Speech\n                                                                            "
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          _vm._v(
                                                                            " "
                                                                          ),
                                                                          _vm.hasPermissionTo(
                                                                            "upload file campaign"
                                                                          )
                                                                            ? _c(
                                                                                "li",
                                                                                {
                                                                                  staticClass:
                                                                                    "nav-item",
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "a",
                                                                                    {
                                                                                      staticClass:
                                                                                        "border rounded-top-right",
                                                                                      class:
                                                                                        _vm
                                                                                          .ivrPromptNavClass
                                                                                          .play,
                                                                                      attrs:
                                                                                        {
                                                                                          href: "",
                                                                                          "data-toggle":
                                                                                            "tab",
                                                                                          "data-target":
                                                                                            "#ivr-prompt-file",
                                                                                          "aria-expanded":
                                                                                            "false",
                                                                                        },
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "i",
                                                                                        {
                                                                                          staticClass:
                                                                                            "fa fa-play text-md",
                                                                                        }
                                                                                      ),
                                                                                      _vm._v(
                                                                                        "\n                                                                                Play Recording\n                                                                            "
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                ]
                                                                              )
                                                                            : _vm._e(),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _vm._v(" "),
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "tab-content p-a m-b-md border border-top-0",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          class:
                                                                            _vm
                                                                              .ivrPromptContainerClass
                                                                              .read,
                                                                          attrs:
                                                                            {
                                                                              id: "ivr-prompt-tts",
                                                                              "aria-expanded":
                                                                                "true",
                                                                            },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "el-form-item",
                                                                            {
                                                                              staticClass:
                                                                                "mb-4",
                                                                              attrs:
                                                                                {
                                                                                  prop: "ivr_prompt_tts",
                                                                                },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "div",
                                                                                [
                                                                                  _c(
                                                                                    "label",
                                                                                    {
                                                                                      staticClass:
                                                                                        "el-form-item__label",
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        "\n                                                                                    IVR Prompt\n                                                                                "
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "div",
                                                                                {
                                                                                  staticClass:
                                                                                    "row",
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "div",
                                                                                    {
                                                                                      staticClass:
                                                                                        "col-12",
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "div",
                                                                                        {
                                                                                          staticClass:
                                                                                            "input-group",
                                                                                        },
                                                                                        [
                                                                                          _c(
                                                                                            "input",
                                                                                            {
                                                                                              directives:
                                                                                                [
                                                                                                  {
                                                                                                    name: "model",
                                                                                                    rawName:
                                                                                                      "v-model",
                                                                                                    value:
                                                                                                      _vm
                                                                                                        .ivr
                                                                                                        .prompt_tts,
                                                                                                    expression:
                                                                                                      "ivr.prompt_tts",
                                                                                                  },
                                                                                                ],
                                                                                              staticClass:
                                                                                                "form-control pt-2 pb-2",
                                                                                              attrs:
                                                                                                {
                                                                                                  type: "text",
                                                                                                  placeholder:
                                                                                                    "Press 1 for Sales, Press 2 for Support, ...",
                                                                                                },
                                                                                              domProps:
                                                                                                {
                                                                                                  value:
                                                                                                    _vm
                                                                                                      .ivr
                                                                                                      .prompt_tts,
                                                                                                },
                                                                                              on: {
                                                                                                input:
                                                                                                  [
                                                                                                    function (
                                                                                                      $event
                                                                                                    ) {
                                                                                                      if (
                                                                                                        $event
                                                                                                          .target
                                                                                                          .composing
                                                                                                      )
                                                                                                        return
                                                                                                      _vm.$set(
                                                                                                        _vm.ivr,
                                                                                                        "prompt_tts",
                                                                                                        $event
                                                                                                          .target
                                                                                                          .value
                                                                                                      )
                                                                                                    },
                                                                                                    function (
                                                                                                      $event
                                                                                                    ) {
                                                                                                      return _vm.preValidateForm(
                                                                                                        "campaign_settings"
                                                                                                      )
                                                                                                    },
                                                                                                  ],
                                                                                              },
                                                                                            }
                                                                                          ),
                                                                                          _vm._v(
                                                                                            " "
                                                                                          ),
                                                                                          _c(
                                                                                            "variable-dialog"
                                                                                          ),
                                                                                        ],
                                                                                        1
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      _vm.hasPermissionTo(
                                                                        "upload file campaign"
                                                                      )
                                                                        ? _c(
                                                                            "div",
                                                                            {
                                                                              class:
                                                                                _vm
                                                                                  .ivrPromptContainerClass
                                                                                  .play,
                                                                              attrs:
                                                                                {
                                                                                  id: "ivr-prompt-file",
                                                                                  "aria-expanded":
                                                                                    "false",
                                                                                },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "audio-recorder",
                                                                                {
                                                                                  staticClass:
                                                                                    "audio-recorder-wrapper",
                                                                                  attrs:
                                                                                    {
                                                                                      action:
                                                                                        "/api/v1/campaign/" +
                                                                                        _vm
                                                                                          .campaign
                                                                                          .id +
                                                                                        "/upload-ivr/ivr_prompts",
                                                                                      upload_type:
                                                                                        "ivr_prompt_file",
                                                                                    },
                                                                                  on: {
                                                                                    applyUploadedAudio:
                                                                                      _vm.applyUploadedAudio,
                                                                                  },
                                                                                }
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "div",
                                                                                {
                                                                                  staticClass:
                                                                                    "audio-uploader-wrapper",
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "el-upload",
                                                                                    {
                                                                                      attrs:
                                                                                        {
                                                                                          drag: "",
                                                                                          action:
                                                                                            "/api/v1/campaign/" +
                                                                                            _vm
                                                                                              .campaign
                                                                                              .id +
                                                                                            "/upload-ivr/ivr_prompts",
                                                                                          headers:
                                                                                            _vm.headers,
                                                                                          "on-success":
                                                                                            _vm.onSuccessIvrPrompt,
                                                                                          "on-error":
                                                                                            _vm.onFailedIvrPrompt,
                                                                                          "on-progress":
                                                                                            _vm.progressUploadIvrPrompt,
                                                                                          "before-upload":
                                                                                            _vm.beforeUploadIvrPrompt,
                                                                                          "file-list":
                                                                                            _vm
                                                                                              .uploadFileList
                                                                                              .ivr_prompt,
                                                                                        },
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "i",
                                                                                        {
                                                                                          staticClass:
                                                                                            "el-icon-upload",
                                                                                        }
                                                                                      ),
                                                                                      _vm._v(
                                                                                        " "
                                                                                      ),
                                                                                      _c(
                                                                                        "div",
                                                                                        {
                                                                                          staticClass:
                                                                                            "el-upload__text",
                                                                                        },
                                                                                        [
                                                                                          _vm._v(
                                                                                            "\n                                                                                    Drop file here or "
                                                                                          ),
                                                                                          _c(
                                                                                            "em",
                                                                                            [
                                                                                              _vm._v(
                                                                                                "click to\n                                                                                    upload"
                                                                                              ),
                                                                                            ]
                                                                                          ),
                                                                                        ]
                                                                                      ),
                                                                                      _vm._v(
                                                                                        " "
                                                                                      ),
                                                                                      _c(
                                                                                        "div",
                                                                                        {
                                                                                          staticClass:
                                                                                            "el-upload__tip",
                                                                                          attrs:
                                                                                            {
                                                                                              slot: "tip",
                                                                                            },
                                                                                          slot: "tip",
                                                                                        },
                                                                                        [
                                                                                          _vm._v(
                                                                                            "\n                                                                                    MP3/WAV file (less than 8MB)\n                                                                                "
                                                                                          ),
                                                                                        ]
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    " "
                                                                                  ),
                                                                                  _vm
                                                                                    .uploadPercentage
                                                                                    .ivr_prompt >
                                                                                  0
                                                                                    ? _c(
                                                                                        "el-progress",
                                                                                        {
                                                                                          attrs:
                                                                                            {
                                                                                              "text-inside": true,
                                                                                              "stroke-width": 18,
                                                                                              percentage:
                                                                                                _vm
                                                                                                  .uploadPercentage
                                                                                                  .ivr_prompt,
                                                                                              status:
                                                                                                _vm
                                                                                                  .uploadStatus
                                                                                                  .ivr_prompt,
                                                                                            },
                                                                                        }
                                                                                      )
                                                                                    : _vm._e(),
                                                                                ],
                                                                                1
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _vm.ivrPromptFile !==
                                                                              null
                                                                                ? _c(
                                                                                    "div",
                                                                                    {
                                                                                      staticClass:
                                                                                        "mt-2",
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "span",
                                                                                        {
                                                                                          staticClass:
                                                                                            "text-md _600",
                                                                                        },
                                                                                        [
                                                                                          _vm._v(
                                                                                            "Current file:"
                                                                                          ),
                                                                                        ]
                                                                                      ),
                                                                                      _vm._v(
                                                                                        " "
                                                                                      ),
                                                                                      _c(
                                                                                        "el-form",
                                                                                        {
                                                                                          attrs:
                                                                                            {
                                                                                              model:
                                                                                                _vm.ivrPromptFile,
                                                                                              inline:
                                                                                                "",
                                                                                            },
                                                                                        },
                                                                                        [
                                                                                          _c(
                                                                                            "el-form-item",
                                                                                            [
                                                                                              _c(
                                                                                                "audio",
                                                                                                {
                                                                                                  attrs:
                                                                                                    {
                                                                                                      controls:
                                                                                                        "",
                                                                                                    },
                                                                                                },
                                                                                                [
                                                                                                  _c(
                                                                                                    "source",
                                                                                                    {
                                                                                                      attrs:
                                                                                                        {
                                                                                                          src:
                                                                                                            "/static/uploaded_file/" +
                                                                                                            _vm
                                                                                                              .ivrPromptFile
                                                                                                              .uuid,
                                                                                                        },
                                                                                                    }
                                                                                                  ),
                                                                                                  _vm._v(
                                                                                                    "\n                                                                                        Your browser does not support the\n                                                                                        audio\n                                                                                        element.\n                                                                                    "
                                                                                                  ),
                                                                                                ]
                                                                                              ),
                                                                                            ]
                                                                                          ),
                                                                                          _vm._v(
                                                                                            " "
                                                                                          ),
                                                                                          _c(
                                                                                            "el-form-item",
                                                                                            [
                                                                                              _c(
                                                                                                "el-input",
                                                                                                {
                                                                                                  staticStyle:
                                                                                                    {
                                                                                                      width:
                                                                                                        "500px",
                                                                                                    },
                                                                                                  attrs:
                                                                                                    {
                                                                                                      disabled:
                                                                                                        _vm.ivr_prompt_file_rename ===
                                                                                                        _vm
                                                                                                          .ivrPromptFile
                                                                                                          .id,
                                                                                                      placeholder:
                                                                                                        "Unnamed File",
                                                                                                      size: "small",
                                                                                                    },
                                                                                                  model:
                                                                                                    {
                                                                                                      value:
                                                                                                        _vm
                                                                                                          .ivrPromptFile
                                                                                                          .name,
                                                                                                      callback:
                                                                                                        function (
                                                                                                          $$v
                                                                                                        ) {
                                                                                                          _vm.$set(
                                                                                                            _vm.ivrPromptFile,
                                                                                                            "name",
                                                                                                            $$v
                                                                                                          )
                                                                                                        },
                                                                                                      expression:
                                                                                                        "ivrPromptFile.name",
                                                                                                    },
                                                                                                },
                                                                                                [
                                                                                                  _c(
                                                                                                    "template",
                                                                                                    {
                                                                                                      slot: "append",
                                                                                                    },
                                                                                                    [
                                                                                                      _c(
                                                                                                        "el-button",
                                                                                                        {
                                                                                                          attrs:
                                                                                                            {
                                                                                                              type: "primary",
                                                                                                              disabled:
                                                                                                                _vm.ivr_prompt_file_rename ===
                                                                                                                  _vm
                                                                                                                    .ivrPromptFile
                                                                                                                    .id ||
                                                                                                                !_vm
                                                                                                                  .ivrPromptFile
                                                                                                                  .name,
                                                                                                              loading:
                                                                                                                _vm.ivr_prompt_file_rename ===
                                                                                                                _vm
                                                                                                                  .ivrPromptFile
                                                                                                                  .id,
                                                                                                              icon: "el-icon-check",
                                                                                                            },
                                                                                                          on: {
                                                                                                            click:
                                                                                                              function (
                                                                                                                $event
                                                                                                              ) {
                                                                                                                return _vm.renameIvrPromptFile(
                                                                                                                  _vm.ivrPromptFile
                                                                                                                )
                                                                                                              },
                                                                                                          },
                                                                                                        }
                                                                                                      ),
                                                                                                    ],
                                                                                                    1
                                                                                                  ),
                                                                                                ],
                                                                                                2
                                                                                              ),
                                                                                            ],
                                                                                            1
                                                                                          ),
                                                                                          _vm._v(
                                                                                            " "
                                                                                          ),
                                                                                          _c(
                                                                                            "el-form-item",
                                                                                            [
                                                                                              _vm.hasPermissionTo(
                                                                                                "delete file campaign"
                                                                                              )
                                                                                                ? _c(
                                                                                                    "button",
                                                                                                    {
                                                                                                      staticClass:
                                                                                                        "btn btn-sm btn-danger pull-right",
                                                                                                      on: {
                                                                                                        click:
                                                                                                          function (
                                                                                                            $event
                                                                                                          ) {
                                                                                                            $event.preventDefault()
                                                                                                            return _vm.deleteFile(
                                                                                                              "ivr_prompts"
                                                                                                            )
                                                                                                          },
                                                                                                      },
                                                                                                    },
                                                                                                    [
                                                                                                      _c(
                                                                                                        "i",
                                                                                                        {
                                                                                                          directives:
                                                                                                            [
                                                                                                              {
                                                                                                                name: "show",
                                                                                                                rawName:
                                                                                                                  "v-show",
                                                                                                                value:
                                                                                                                  _vm.loading_ivr_prompt,
                                                                                                                expression:
                                                                                                                  "loading_ivr_prompt",
                                                                                                              },
                                                                                                            ],
                                                                                                          staticClass:
                                                                                                            "material-icons loader pull-left",
                                                                                                        },
                                                                                                        [
                                                                                                          _vm._v(
                                                                                                            ""
                                                                                                          ),
                                                                                                        ]
                                                                                                      ),
                                                                                                      _vm._v(
                                                                                                        " "
                                                                                                      ),
                                                                                                      _c(
                                                                                                        "i",
                                                                                                        {
                                                                                                          directives:
                                                                                                            [
                                                                                                              {
                                                                                                                name: "show",
                                                                                                                rawName:
                                                                                                                  "v-show",
                                                                                                                value:
                                                                                                                  !_vm.loading_ivr_prompt,
                                                                                                                expression:
                                                                                                                  "!loading_ivr_prompt",
                                                                                                              },
                                                                                                            ],
                                                                                                          staticClass:
                                                                                                            "material-icons pull-left",
                                                                                                        },
                                                                                                        [
                                                                                                          _vm._v(
                                                                                                            ""
                                                                                                          ),
                                                                                                        ]
                                                                                                      ),
                                                                                                      _vm._v(
                                                                                                        " "
                                                                                                      ),
                                                                                                      _c(
                                                                                                        "span",
                                                                                                        [
                                                                                                          _vm._v(
                                                                                                            " Remove file"
                                                                                                          ),
                                                                                                        ]
                                                                                                      ),
                                                                                                    ]
                                                                                                  )
                                                                                                : _vm._e(),
                                                                                            ]
                                                                                          ),
                                                                                        ],
                                                                                        1
                                                                                      ),
                                                                                    ],
                                                                                    1
                                                                                  )
                                                                                : _vm._e(),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _vm
                                                                                .filteredIvrPromptFiles
                                                                                .length >
                                                                                0 &&
                                                                              _vm.show_ivr_prompt_files
                                                                                ? _c(
                                                                                    "div",
                                                                                    {
                                                                                      staticClass:
                                                                                        "mt-2 scrollable height-300",
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "span",
                                                                                        {
                                                                                          staticClass:
                                                                                            "text-md _600",
                                                                                        },
                                                                                        [
                                                                                          _vm._v(
                                                                                            "All files:"
                                                                                          ),
                                                                                        ]
                                                                                      ),
                                                                                      _vm._v(
                                                                                        " "
                                                                                      ),
                                                                                      _vm._l(
                                                                                        _vm.filteredIvrPromptFiles,
                                                                                        function (
                                                                                          promptFile
                                                                                        ) {
                                                                                          return _c(
                                                                                            "el-form",
                                                                                            {
                                                                                              key: promptFile.id,
                                                                                              attrs:
                                                                                                {
                                                                                                  model:
                                                                                                    promptFile,
                                                                                                  inline:
                                                                                                    "",
                                                                                                },
                                                                                            },
                                                                                            [
                                                                                              _c(
                                                                                                "el-form-item",
                                                                                                [
                                                                                                  _c(
                                                                                                    "audio",
                                                                                                    {
                                                                                                      attrs:
                                                                                                        {
                                                                                                          controls:
                                                                                                            "",
                                                                                                        },
                                                                                                    },
                                                                                                    [
                                                                                                      _c(
                                                                                                        "source",
                                                                                                        {
                                                                                                          attrs:
                                                                                                            {
                                                                                                              src:
                                                                                                                "/static/uploaded_file/" +
                                                                                                                promptFile.uuid,
                                                                                                            },
                                                                                                        }
                                                                                                      ),
                                                                                                      _vm._v(
                                                                                                        "\n                                                                                        Your browser does not support the\n                                                                                        audio\n                                                                                        element.\n                                                                                    "
                                                                                                      ),
                                                                                                    ]
                                                                                                  ),
                                                                                                ]
                                                                                              ),
                                                                                              _vm._v(
                                                                                                " "
                                                                                              ),
                                                                                              _c(
                                                                                                "el-form-item",
                                                                                                [
                                                                                                  _c(
                                                                                                    "el-input",
                                                                                                    {
                                                                                                      staticStyle:
                                                                                                        {
                                                                                                          width:
                                                                                                            "500px",
                                                                                                        },
                                                                                                      attrs:
                                                                                                        {
                                                                                                          disabled:
                                                                                                            _vm.ivr_prompt_file_rename ===
                                                                                                            promptFile.id,
                                                                                                          placeholder:
                                                                                                            "Unnamed File",
                                                                                                          size: "small",
                                                                                                        },
                                                                                                      model:
                                                                                                        {
                                                                                                          value:
                                                                                                            promptFile.name,
                                                                                                          callback:
                                                                                                            function (
                                                                                                              $$v
                                                                                                            ) {
                                                                                                              _vm.$set(
                                                                                                                promptFile,
                                                                                                                "name",
                                                                                                                $$v
                                                                                                              )
                                                                                                            },
                                                                                                          expression:
                                                                                                            "promptFile.name",
                                                                                                        },
                                                                                                    },
                                                                                                    [
                                                                                                      _c(
                                                                                                        "template",
                                                                                                        {
                                                                                                          slot: "append",
                                                                                                        },
                                                                                                        [
                                                                                                          _c(
                                                                                                            "el-button",
                                                                                                            {
                                                                                                              attrs:
                                                                                                                {
                                                                                                                  type: "primary",
                                                                                                                  icon: "el-icon-check",
                                                                                                                  disabled:
                                                                                                                    _vm.ivr_prompt_file_rename ===
                                                                                                                      promptFile.id ||
                                                                                                                    !promptFile.name,
                                                                                                                  loading:
                                                                                                                    _vm.ivr_prompt_file_rename ===
                                                                                                                    promptFile.id,
                                                                                                                },
                                                                                                              on: {
                                                                                                                click:
                                                                                                                  function (
                                                                                                                    $event
                                                                                                                  ) {
                                                                                                                    return _vm.renameIvrPromptFile(
                                                                                                                      promptFile
                                                                                                                    )
                                                                                                                  },
                                                                                                              },
                                                                                                            }
                                                                                                          ),
                                                                                                        ],
                                                                                                        1
                                                                                                      ),
                                                                                                    ],
                                                                                                    2
                                                                                                  ),
                                                                                                ],
                                                                                                1
                                                                                              ),
                                                                                              _vm._v(
                                                                                                " "
                                                                                              ),
                                                                                              _c(
                                                                                                "el-form-item",
                                                                                                [
                                                                                                  _c(
                                                                                                    "button",
                                                                                                    {
                                                                                                      staticClass:
                                                                                                        "btn btn-sm btn-info pull-right",
                                                                                                      on: {
                                                                                                        click:
                                                                                                          function (
                                                                                                            $event
                                                                                                          ) {
                                                                                                            $event.preventDefault()
                                                                                                            return _vm.useIvrPromptFile(
                                                                                                              promptFile
                                                                                                            )
                                                                                                          },
                                                                                                      },
                                                                                                    },
                                                                                                    [
                                                                                                      _c(
                                                                                                        "i",
                                                                                                        {
                                                                                                          directives:
                                                                                                            [
                                                                                                              {
                                                                                                                name: "show",
                                                                                                                rawName:
                                                                                                                  "v-show",
                                                                                                                value:
                                                                                                                  _vm.loading_ivr_prompt,
                                                                                                                expression:
                                                                                                                  "loading_ivr_prompt",
                                                                                                              },
                                                                                                            ],
                                                                                                          staticClass:
                                                                                                            "material-icons loader pull-left",
                                                                                                        },
                                                                                                        [
                                                                                                          _vm._v(
                                                                                                            ""
                                                                                                          ),
                                                                                                        ]
                                                                                                      ),
                                                                                                      _vm._v(
                                                                                                        " "
                                                                                                      ),
                                                                                                      _c(
                                                                                                        "i",
                                                                                                        {
                                                                                                          directives:
                                                                                                            [
                                                                                                              {
                                                                                                                name: "show",
                                                                                                                rawName:
                                                                                                                  "v-show",
                                                                                                                value:
                                                                                                                  !_vm.loading_ivr_prompt,
                                                                                                                expression:
                                                                                                                  "!loading_ivr_prompt",
                                                                                                              },
                                                                                                            ],
                                                                                                          staticClass:
                                                                                                            "material-icons pull-left",
                                                                                                        },
                                                                                                        [
                                                                                                          _vm._v(
                                                                                                            "add_box"
                                                                                                          ),
                                                                                                        ]
                                                                                                      ),
                                                                                                      _vm._v(
                                                                                                        " "
                                                                                                      ),
                                                                                                      _c(
                                                                                                        "span",
                                                                                                        [
                                                                                                          _vm._v(
                                                                                                            " Select"
                                                                                                          ),
                                                                                                        ]
                                                                                                      ),
                                                                                                    ]
                                                                                                  ),
                                                                                                ]
                                                                                              ),
                                                                                            ],
                                                                                            1
                                                                                          )
                                                                                        }
                                                                                      ),
                                                                                    ],
                                                                                    2
                                                                                  )
                                                                                : _vm._e(),
                                                                            ],
                                                                            1
                                                                          )
                                                                        : _vm._e(),
                                                                    ]
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "row mt-3",
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "col-12",
                                                                },
                                                                [
                                                                  _c(
                                                                    "p",
                                                                    {
                                                                      staticClass:
                                                                        "d-none",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "a",
                                                                        {
                                                                          staticClass:
                                                                            "help",
                                                                          attrs:
                                                                            {
                                                                              href: "#",
                                                                              title:
                                                                                "help",
                                                                              target:
                                                                                "_blank",
                                                                            },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "el-button",
                                                                            {
                                                                              staticClass:
                                                                                "p-0",
                                                                              attrs:
                                                                                {
                                                                                  type: "text",
                                                                                },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "i",
                                                                                {
                                                                                  staticClass:
                                                                                    "material-icons text-lg",
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    "help"
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "span",
                                                                                {
                                                                                  staticClass:
                                                                                    "text-md",
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    "Read our tutorial"
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _vm._v(" "),
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "el-form--label-top",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "label",
                                                                        {
                                                                          staticClass:
                                                                            "el-form-item__label",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "\n                                                                        Text Messaging Ring Group\n                                                                    "
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _vm._v(" "),
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "form-group",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "el-form-item",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              prop: "ivr_text_ring_group_id",
                                                                            },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "ring-group-selector",
                                                                            {
                                                                              staticClass:
                                                                                "pull-left",
                                                                              staticStyle:
                                                                                {
                                                                                  width:
                                                                                    "300px",
                                                                                },
                                                                              on: {
                                                                                change:
                                                                                  _vm.changeTextRingGroup,
                                                                              },
                                                                              model:
                                                                                {
                                                                                  value:
                                                                                    _vm
                                                                                      .ivr
                                                                                      .text_ring_group_id,
                                                                                  callback:
                                                                                    function (
                                                                                      $$v
                                                                                    ) {
                                                                                      _vm.$set(
                                                                                        _vm.ivr,
                                                                                        "text_ring_group_id",
                                                                                        $$v
                                                                                      )
                                                                                    },
                                                                                  expression:
                                                                                    "ivr.text_ring_group_id",
                                                                                },
                                                                            }
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                  _vm._v(" "),
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "el-form--label-top",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "label",
                                                                        {
                                                                          staticClass:
                                                                            "el-form-item__label",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "\n                                                                        IVR Menu Options\n                                                                    "
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _vm._v(" "),
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "form-group",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "el-form-item",
                                                                        {
                                                                          staticClass:
                                                                            "mb-0",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "row",
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "div",
                                                                                {
                                                                                  staticClass:
                                                                                    "col-12",
                                                                                },
                                                                                [
                                                                                  _vm.show_ivr_modal
                                                                                    ? _c(
                                                                                        "campaign-ivr-steps",
                                                                                        {
                                                                                          attrs:
                                                                                            {
                                                                                              ivr_steps:
                                                                                                _vm
                                                                                                  .ivr
                                                                                                  .steps,
                                                                                              campaign:
                                                                                                _vm.campaign,
                                                                                            },
                                                                                          on: {
                                                                                            updateIvrSteps:
                                                                                              _vm.updateIvrSteps,
                                                                                          },
                                                                                        }
                                                                                      )
                                                                                    : _vm._e(),
                                                                                ],
                                                                                1
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                        ]),
                                                        _vm._v(" "),
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "dialog-footer",
                                                            attrs: {
                                                              slot: "footer",
                                                            },
                                                            slot: "footer",
                                                          },
                                                          [
                                                            _c(
                                                              "el-button",
                                                              {
                                                                attrs: {
                                                                  type: "danger",
                                                                },
                                                                on: {
                                                                  click:
                                                                    _vm.beforeCloseIvrModal,
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                                                                Cancel\n                                                            "
                                                                ),
                                                              ]
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "el-button",
                                                              {
                                                                attrs: {
                                                                  type: "primary",
                                                                  loading:
                                                                    _vm.ivr_button_loading,
                                                                },
                                                                on: {
                                                                  click:
                                                                    _vm.submitIvr,
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                                                                " +
                                                                    _vm._s(
                                                                      this
                                                                        .should_create_ivr
                                                                        ? "Create"
                                                                        : "Update"
                                                                    ) +
                                                                    "\n                                                            "
                                                                ),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "el-timeline-item",
                                                      {
                                                        attrs: {
                                                          placement: "top",
                                                          type: "primary",
                                                          size: "large",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "template",
                                                          { slot: "dot" },
                                                          [
                                                            _c("el-switch", {
                                                              attrs: {
                                                                "active-color":
                                                                  "#00a344",
                                                                disabled:
                                                                  _vm.to_dead_end,
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.to_dead_end,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.to_dead_end =
                                                                      $$v
                                                                  },
                                                                expression:
                                                                  "to_dead_end",
                                                              },
                                                            }),
                                                            _vm._v(" "),
                                                            _c("div", [
                                                              _c(
                                                                "label",
                                                                {
                                                                  staticClass:
                                                                    "el-form-item__label ml-2",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n                                                                Dead End\n                                                            "
                                                                  ),
                                                                ]
                                                              ),
                                                            ]),
                                                          ],
                                                          1
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "label",
                                                          {
                                                            staticClass:
                                                              "ml-1 help-text",
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                                                        Terminate all calls without connecting to any\n                                                        agents.\n                                                        "
                                                            ),
                                                            _c("el-popover", {
                                                              ref: "popover_deadend",
                                                              staticClass:
                                                                "ml-2",
                                                              attrs: {
                                                                placement:
                                                                  "right",
                                                                title:
                                                                  "Dead End",
                                                                width: "400",
                                                                trigger:
                                                                  "hover",
                                                                content:
                                                                  "Using this option will prevent your callers\n                                                                    from reaching your agents. A dead end line\n                                                                    is only useful when you are running a sequence,\n                                                                    or an SMS campaign, and don't want callbacks.",
                                                              },
                                                            }),
                                                            _vm._v(" "),
                                                            _c(
                                                              "span",
                                                              {
                                                                directives: [
                                                                  {
                                                                    name: "popover",
                                                                    rawName:
                                                                      "v-popover:popover_deadend",
                                                                    arg: "popover_deadend",
                                                                  },
                                                                ],
                                                                staticClass:
                                                                  "form-hover-help text-blue",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                                                                More Info\n                                                        "
                                                                ),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      2
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c("el-form-item", [
                                              _c("span", {
                                                staticClass: "page-anchor",
                                                attrs: { id: "csat_survey" },
                                              }),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                { staticClass: "form-label" },
                                                [
                                                  _c("h5", [
                                                    _vm._v("CSAT Survey"),
                                                  ]),
                                                  _vm._v(" "),
                                                  _c("small", [
                                                    _vm._v(
                                                      "\n                                                    Enable CSAT collection for all calls to this line.\n                                                    CSAT (Customer Satisfaction) is a measure of how happy or pleased customers are with a product or service they received.\n                                                "
                                                    ),
                                                  ]),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "switch-label mb-3",
                                                },
                                                [
                                                  _c("el-switch", {
                                                    attrs: {
                                                      "active-color": "#00a344",
                                                    },
                                                    model: {
                                                      value: _vm.has_csat,
                                                      callback: function ($$v) {
                                                        _vm.has_csat = $$v
                                                      },
                                                      expression: "has_csat",
                                                    },
                                                  }),
                                                  _vm._v(" "),
                                                  _c("label", [
                                                    _vm._v("Enable CSAT"),
                                                  ]),
                                                ],
                                                1
                                              ),
                                            ]),
                                            _vm._v(" "),
                                            _c(
                                              "el-form-item",
                                              {
                                                attrs: {
                                                  prop: "missed_call_handling_mode",
                                                },
                                              },
                                              [
                                                _c("span", {
                                                  staticClass: "page-anchor",
                                                  attrs: {
                                                    id: "missed_call_handling_mode",
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  { staticClass: "form-label" },
                                                  [
                                                    _c("h5", [
                                                      _vm._v("Missed Calls"),
                                                    ]),
                                                    _vm._v(" "),
                                                    _c("small", [
                                                      _vm._v(
                                                        "\n                                                    If your business is closed, or your routing setting is set\n                                                    to Dead End, the call will be missed. Here you can configure\n                                                    what happens to missed calls.\n                                                "
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "el-radio-group",
                                                  {
                                                    staticClass: "w-full mt-2",
                                                    on: {
                                                      change:
                                                        _vm.changedMissedCallHandlingMode,
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.campaign
                                                          .missed_calls_settings
                                                          .missed_call_handling_mode,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.campaign
                                                            .missed_calls_settings,
                                                          "missed_call_handling_mode",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "campaign.missed_calls_settings.missed_call_handling_mode",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      { staticClass: "row" },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "col-12",
                                                          },
                                                          [
                                                            _c(
                                                              "el-radio",
                                                              {
                                                                staticStyle: {
                                                                  width: "100%",
                                                                },
                                                                attrs: {
                                                                  label:
                                                                    _vm
                                                                      .MissedCallBehaviors
                                                                      .MISSED_CALL_BEHAVIOR_VOICEMAIL,
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                                                            Take a voicemail\n                                                            "
                                                                ),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    directives:
                                                                      [
                                                                        {
                                                                          name: "show",
                                                                          rawName:
                                                                            "v-show",
                                                                          value:
                                                                            _vm
                                                                              .campaign
                                                                              .missed_calls_settings
                                                                              .missed_call_handling_mode ===
                                                                            _vm
                                                                              .MissedCallBehaviors
                                                                              .MISSED_CALL_BEHAVIOR_VOICEMAIL,
                                                                          expression:
                                                                            "campaign.missed_calls_settings.missed_call_handling_mode === MissedCallBehaviors.MISSED_CALL_BEHAVIOR_VOICEMAIL",
                                                                        },
                                                                      ],
                                                                    staticClass:
                                                                      "m-t-md ml-1",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "p",
                                                                      {
                                                                        staticClass:
                                                                          "_600",
                                                                        staticStyle:
                                                                          {
                                                                            "margin-left":
                                                                              "-3px",
                                                                          },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "Create a Voicemail Message"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _vm._v(" "),
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "mt-1 ml-3",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            directives:
                                                                              [
                                                                                {
                                                                                  name: "show",
                                                                                  rawName:
                                                                                    "v-show",
                                                                                  value:
                                                                                    _vm
                                                                                      .campaign
                                                                                      .missed_calls_settings
                                                                                      .missed_call_handling_mode ===
                                                                                    _vm
                                                                                      .MissedCallBehaviors
                                                                                      .MISSED_CALL_BEHAVIOR_VOICEMAIL,
                                                                                  expression:
                                                                                    "campaign.missed_calls_settings.missed_call_handling_mode === MissedCallBehaviors.MISSED_CALL_BEHAVIOR_VOICEMAIL",
                                                                                },
                                                                              ],
                                                                            staticClass:
                                                                              "b-b nav-active-greenish",
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "ul",
                                                                              {
                                                                                staticClass:
                                                                                  "nav nav-tabs",
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "li",
                                                                                  {
                                                                                    staticClass:
                                                                                      "nav-item",
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "a",
                                                                                      {
                                                                                        staticClass:
                                                                                          "border border-right-0 rounded-top-left",
                                                                                        class:
                                                                                          _vm
                                                                                            .voicemailNavClass
                                                                                            .read,
                                                                                        attrs:
                                                                                          {
                                                                                            href: "",
                                                                                            "data-toggle":
                                                                                              "tab",
                                                                                            "data-target":
                                                                                              "#voicemail-tts",
                                                                                            "aria-expanded":
                                                                                              "true",
                                                                                          },
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "i",
                                                                                          {
                                                                                            staticClass:
                                                                                              "fa fa-robot",
                                                                                          }
                                                                                        ),
                                                                                        _vm._v(
                                                                                          "\n                                                                                    Text to Speech\n                                                                                "
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                                _vm._v(
                                                                                  " "
                                                                                ),
                                                                                _vm.hasPermissionTo(
                                                                                  "upload file campaign"
                                                                                )
                                                                                  ? _c(
                                                                                      "li",
                                                                                      {
                                                                                        staticClass:
                                                                                          "nav-item",
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "a",
                                                                                          {
                                                                                            staticClass:
                                                                                              "border rounded-top-right",
                                                                                            class:
                                                                                              _vm
                                                                                                .voicemailNavClass
                                                                                                .play,
                                                                                            attrs:
                                                                                              {
                                                                                                href: "",
                                                                                                "data-toggle":
                                                                                                  "tab",
                                                                                                "data-target":
                                                                                                  "#voicemail-file",
                                                                                                "aria-expanded":
                                                                                                  "false",
                                                                                              },
                                                                                          },
                                                                                          [
                                                                                            _c(
                                                                                              "i",
                                                                                              {
                                                                                                staticClass:
                                                                                                  "fa fa-play text-md",
                                                                                              }
                                                                                            ),
                                                                                            _vm._v(
                                                                                              "\n                                                                                    Play Recording\n                                                                                "
                                                                                            ),
                                                                                          ]
                                                                                        ),
                                                                                      ]
                                                                                    )
                                                                                  : _vm._e(),
                                                                                _vm._v(
                                                                                  " "
                                                                                ),
                                                                                _vm
                                                                                  .campaign
                                                                                  .missed_calls_settings
                                                                                  .voicemail_file
                                                                                  ? _c(
                                                                                      "li",
                                                                                      {
                                                                                        staticClass:
                                                                                          "nav-item",
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "a",
                                                                                          {
                                                                                            staticClass:
                                                                                              "nav-link",
                                                                                            on: {
                                                                                              click:
                                                                                                function (
                                                                                                  $event
                                                                                                ) {
                                                                                                  $event.preventDefault()
                                                                                                  return _vm.deleteFile(
                                                                                                    "voicemail"
                                                                                                  )
                                                                                                },
                                                                                            },
                                                                                          }
                                                                                        ),
                                                                                      ]
                                                                                    )
                                                                                  : _vm._e(),
                                                                              ]
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _vm._v(
                                                                          " "
                                                                        ),
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            directives:
                                                                              [
                                                                                {
                                                                                  name: "show",
                                                                                  rawName:
                                                                                    "v-show",
                                                                                  value:
                                                                                    _vm
                                                                                      .campaign
                                                                                      .missed_calls_settings
                                                                                      .missed_call_handling_mode ===
                                                                                    _vm
                                                                                      .MissedCallBehaviors
                                                                                      .MISSED_CALL_BEHAVIOR_VOICEMAIL,
                                                                                  expression:
                                                                                    "campaign.missed_calls_settings.missed_call_handling_mode === MissedCallBehaviors.MISSED_CALL_BEHAVIOR_VOICEMAIL",
                                                                                },
                                                                              ],
                                                                            staticClass:
                                                                              "tab-content p-a m-b-md border border-top-0",
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "div",
                                                                              {
                                                                                class:
                                                                                  _vm
                                                                                    .voicemailContainerClass
                                                                                    .read,
                                                                                attrs:
                                                                                  {
                                                                                    id: "voicemail-tts",
                                                                                    "aria-expanded":
                                                                                      "true",
                                                                                  },
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "el-form-item",
                                                                                  {
                                                                                    staticClass:
                                                                                      "mb-4",
                                                                                    attrs:
                                                                                      {
                                                                                        label:
                                                                                          "Voicemail Message",
                                                                                        prop: "voicemail",
                                                                                      },
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "el-popover",
                                                                                      {
                                                                                        ref: "popover_voicemail",
                                                                                        attrs:
                                                                                          {
                                                                                            placement:
                                                                                              "bottom-start",
                                                                                            title:
                                                                                              "Voicemail",
                                                                                            width:
                                                                                              "200",
                                                                                            trigger:
                                                                                              "hover",
                                                                                            content:
                                                                                              "We are sorry, but no agents\n                                                                                                            are available to take this call.\n                                                                                                            Please leave your message after the beep.",
                                                                                          },
                                                                                      }
                                                                                    ),
                                                                                    _vm._v(
                                                                                      " "
                                                                                    ),
                                                                                    _c(
                                                                                      "div",
                                                                                      {
                                                                                        staticClass:
                                                                                          "row",
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "div",
                                                                                          {
                                                                                            staticClass:
                                                                                              "col-12",
                                                                                          },
                                                                                          [
                                                                                            _c(
                                                                                              "div",
                                                                                              {
                                                                                                staticClass:
                                                                                                  "input-group",
                                                                                              },
                                                                                              [
                                                                                                _c(
                                                                                                  "input",
                                                                                                  {
                                                                                                    directives:
                                                                                                      [
                                                                                                        {
                                                                                                          name: "model",
                                                                                                          rawName:
                                                                                                            "v-model",
                                                                                                          value:
                                                                                                            _vm
                                                                                                              .campaign
                                                                                                              .missed_calls_settings
                                                                                                              .voicemail_tts,
                                                                                                          expression:
                                                                                                            "campaign.missed_calls_settings.voicemail_tts",
                                                                                                        },
                                                                                                      ],
                                                                                                    staticClass:
                                                                                                      "form-control pt-2 pb-2",
                                                                                                    attrs:
                                                                                                      {
                                                                                                        type: "text",
                                                                                                      },
                                                                                                    domProps:
                                                                                                      {
                                                                                                        value:
                                                                                                          _vm
                                                                                                            .campaign
                                                                                                            .missed_calls_settings
                                                                                                            .voicemail_tts,
                                                                                                      },
                                                                                                    on: {
                                                                                                      input:
                                                                                                        [
                                                                                                          function (
                                                                                                            $event
                                                                                                          ) {
                                                                                                            if (
                                                                                                              $event
                                                                                                                .target
                                                                                                                .composing
                                                                                                            )
                                                                                                              return
                                                                                                            _vm.$set(
                                                                                                              _vm
                                                                                                                .campaign
                                                                                                                .missed_calls_settings,
                                                                                                              "voicemail_tts",
                                                                                                              $event
                                                                                                                .target
                                                                                                                .value
                                                                                                            )
                                                                                                          },
                                                                                                          function (
                                                                                                            $event
                                                                                                          ) {
                                                                                                            return _vm.preValidateForm(
                                                                                                              "campaign_settings"
                                                                                                            )
                                                                                                          },
                                                                                                        ],
                                                                                                    },
                                                                                                  }
                                                                                                ),
                                                                                                _vm._v(
                                                                                                  " "
                                                                                                ),
                                                                                                _c(
                                                                                                  "variable-dialog"
                                                                                                ),
                                                                                              ],
                                                                                              1
                                                                                            ),
                                                                                          ]
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                  ],
                                                                                  1
                                                                                ),
                                                                              ],
                                                                              1
                                                                            ),
                                                                            _vm._v(
                                                                              " "
                                                                            ),
                                                                            _vm.hasPermissionTo(
                                                                              "upload file campaign"
                                                                            )
                                                                              ? _c(
                                                                                  "div",
                                                                                  {
                                                                                    class:
                                                                                      _vm
                                                                                        .voicemailContainerClass
                                                                                        .play,
                                                                                    attrs:
                                                                                      {
                                                                                        id: "voicemail-file",
                                                                                        "aria-expanded":
                                                                                          "false",
                                                                                      },
                                                                                  },
                                                                                  [
                                                                                    _vm
                                                                                      .campaign
                                                                                      .missed_calls_settings
                                                                                      .voicemail_file ===
                                                                                    null
                                                                                      ? _c(
                                                                                          "audio-recorder",
                                                                                          {
                                                                                            staticClass:
                                                                                              "audio-recorder-wrapper",
                                                                                            attrs:
                                                                                              {
                                                                                                action:
                                                                                                  "/api/v1/campaign/" +
                                                                                                  _vm
                                                                                                    .campaign
                                                                                                    .id +
                                                                                                  "/upload-ivr/voicemails",
                                                                                                upload_type:
                                                                                                  "voicemail_file",
                                                                                              },
                                                                                            on: {
                                                                                              applyUploadedAudio:
                                                                                                _vm.applyUploadedAudio,
                                                                                            },
                                                                                          }
                                                                                        )
                                                                                      : _vm._e(),
                                                                                    _vm._v(
                                                                                      " "
                                                                                    ),
                                                                                    _c(
                                                                                      "div",
                                                                                      {
                                                                                        staticClass:
                                                                                          "audio-uploader-wrapper",
                                                                                      },
                                                                                      [
                                                                                        _vm
                                                                                          .campaign
                                                                                          .missed_calls_settings
                                                                                          .voicemail_file ===
                                                                                        null
                                                                                          ? _c(
                                                                                              "el-upload",
                                                                                              {
                                                                                                attrs:
                                                                                                  {
                                                                                                    drag: "",
                                                                                                    action:
                                                                                                      "/api/v1/campaign/" +
                                                                                                      _vm
                                                                                                        .campaign
                                                                                                        .id +
                                                                                                      "/upload-ivr/voicemails",
                                                                                                    headers:
                                                                                                      _vm.headers,
                                                                                                    "on-success":
                                                                                                      _vm.onSuccessVoicemail,
                                                                                                    "on-error":
                                                                                                      _vm.onFailedVoicemail,
                                                                                                    "on-progress":
                                                                                                      _vm.progressUploadVoicemail,
                                                                                                    "before-upload":
                                                                                                      _vm.beforeUploadVoicemail,
                                                                                                    "file-list":
                                                                                                      _vm
                                                                                                        .uploadFileList
                                                                                                        .voicemail,
                                                                                                  },
                                                                                              },
                                                                                              [
                                                                                                _c(
                                                                                                  "i",
                                                                                                  {
                                                                                                    staticClass:
                                                                                                      "el-icon-upload",
                                                                                                  }
                                                                                                ),
                                                                                                _vm._v(
                                                                                                  " "
                                                                                                ),
                                                                                                _c(
                                                                                                  "div",
                                                                                                  {
                                                                                                    staticClass:
                                                                                                      "el-upload__text",
                                                                                                  },
                                                                                                  [
                                                                                                    _vm._v(
                                                                                                      "\n                                                                                        Drop file here or "
                                                                                                    ),
                                                                                                    _c(
                                                                                                      "em",
                                                                                                      [
                                                                                                        _vm._v(
                                                                                                          "click to\n                                                                                        upload"
                                                                                                        ),
                                                                                                      ]
                                                                                                    ),
                                                                                                  ]
                                                                                                ),
                                                                                                _vm._v(
                                                                                                  " "
                                                                                                ),
                                                                                                _c(
                                                                                                  "div",
                                                                                                  {
                                                                                                    staticClass:
                                                                                                      "el-upload__tip",
                                                                                                    attrs:
                                                                                                      {
                                                                                                        slot: "tip",
                                                                                                      },
                                                                                                    slot: "tip",
                                                                                                  },
                                                                                                  [
                                                                                                    _vm._v(
                                                                                                      "\n                                                                                        MP3/WAV file (less than 8MB)\n                                                                                    "
                                                                                                    ),
                                                                                                  ]
                                                                                                ),
                                                                                              ]
                                                                                            )
                                                                                          : _vm._e(),
                                                                                        _vm._v(
                                                                                          " "
                                                                                        ),
                                                                                        _vm
                                                                                          .campaign
                                                                                          .missed_calls_settings
                                                                                          .voicemail_file ===
                                                                                        null
                                                                                          ? _c(
                                                                                              "el-progress",
                                                                                              {
                                                                                                attrs:
                                                                                                  {
                                                                                                    "text-inside": true,
                                                                                                    "stroke-width": 18,
                                                                                                    percentage:
                                                                                                      _vm
                                                                                                        .uploadPercentage
                                                                                                        .voicemail,
                                                                                                    status:
                                                                                                      _vm
                                                                                                        .uploadStatus
                                                                                                        .voicemail,
                                                                                                  },
                                                                                              }
                                                                                            )
                                                                                          : _vm._e(),
                                                                                        _vm._v(
                                                                                          " "
                                                                                        ),
                                                                                        _vm
                                                                                          .campaign
                                                                                          .missed_calls_settings
                                                                                          .voicemail_file !==
                                                                                        null
                                                                                          ? _c(
                                                                                              "audio",
                                                                                              {
                                                                                                attrs:
                                                                                                  {
                                                                                                    controls:
                                                                                                      "",
                                                                                                  },
                                                                                              },
                                                                                              [
                                                                                                _c(
                                                                                                  "source",
                                                                                                  {
                                                                                                    attrs:
                                                                                                      {
                                                                                                        src:
                                                                                                          "/static/uploaded_file/" +
                                                                                                          _vm
                                                                                                            .campaign
                                                                                                            .missed_calls_settings
                                                                                                            .voicemail_file,
                                                                                                      },
                                                                                                  }
                                                                                                ),
                                                                                                _vm._v(
                                                                                                  "\n                                                                                    Your browser does not support the audio\n                                                                                    element.\n                                                                                "
                                                                                                ),
                                                                                              ]
                                                                                            )
                                                                                          : _vm._e(),
                                                                                        _vm._v(
                                                                                          " "
                                                                                        ),
                                                                                        _vm
                                                                                          .campaign
                                                                                          .missed_calls_settings
                                                                                          .voicemail_file !==
                                                                                          null &&
                                                                                        _vm.hasPermissionTo(
                                                                                          "delete file campaign"
                                                                                        )
                                                                                          ? _c(
                                                                                              "button",
                                                                                              {
                                                                                                staticClass:
                                                                                                  "btn btn-sm btn-danger pull-right",
                                                                                                on: {
                                                                                                  click:
                                                                                                    function (
                                                                                                      $event
                                                                                                    ) {
                                                                                                      $event.preventDefault()
                                                                                                      return _vm.deleteFile(
                                                                                                        "voicemails"
                                                                                                      )
                                                                                                    },
                                                                                                },
                                                                                              },
                                                                                              [
                                                                                                _c(
                                                                                                  "i",
                                                                                                  {
                                                                                                    directives:
                                                                                                      [
                                                                                                        {
                                                                                                          name: "show",
                                                                                                          rawName:
                                                                                                            "v-show",
                                                                                                          value:
                                                                                                            _vm.loading_voicemail,
                                                                                                          expression:
                                                                                                            "loading_voicemail",
                                                                                                        },
                                                                                                      ],
                                                                                                    staticClass:
                                                                                                      "material-icons loader pull-left",
                                                                                                  },
                                                                                                  [
                                                                                                    _vm._v(
                                                                                                      ""
                                                                                                    ),
                                                                                                  ]
                                                                                                ),
                                                                                                _vm._v(
                                                                                                  " "
                                                                                                ),
                                                                                                _c(
                                                                                                  "i",
                                                                                                  {
                                                                                                    directives:
                                                                                                      [
                                                                                                        {
                                                                                                          name: "show",
                                                                                                          rawName:
                                                                                                            "v-show",
                                                                                                          value:
                                                                                                            !_vm.loading_voicemail,
                                                                                                          expression:
                                                                                                            "!loading_voicemail",
                                                                                                        },
                                                                                                      ],
                                                                                                    staticClass:
                                                                                                      "material-icons pull-left",
                                                                                                  },
                                                                                                  [
                                                                                                    _vm._v(
                                                                                                      ""
                                                                                                    ),
                                                                                                  ]
                                                                                                ),
                                                                                                _vm._v(
                                                                                                  " "
                                                                                                ),
                                                                                                _c(
                                                                                                  "span",
                                                                                                  [
                                                                                                    _vm._v(
                                                                                                      "Remove file"
                                                                                                    ),
                                                                                                  ]
                                                                                                ),
                                                                                              ]
                                                                                            )
                                                                                          : _vm._e(),
                                                                                      ],
                                                                                      1
                                                                                    ),
                                                                                  ],
                                                                                  1
                                                                                )
                                                                              : _vm._e(),
                                                                          ]
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _vm._v(" "),
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "mt-1",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "p",
                                                                          {
                                                                            staticClass:
                                                                              "_600",
                                                                            staticStyle:
                                                                              {
                                                                                "margin-left":
                                                                                  "-3px",
                                                                              },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "\n                                                                        Require contact to press 1 to leave a voicemail\n                                                                        "
                                                                            ),
                                                                            _c(
                                                                              "el-popover",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    placement:
                                                                                      "right",
                                                                                    trigger:
                                                                                      "hover",
                                                                                    content:
                                                                                      "Use this if you find your contacts are accidentally leaving voicemails",
                                                                                  },
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "i",
                                                                                  {
                                                                                    staticClass:
                                                                                      "fa fa-info-circle text-primary ml-2",
                                                                                    attrs:
                                                                                      {
                                                                                        slot: "reference",
                                                                                      },
                                                                                    slot: "reference",
                                                                                  }
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ],
                                                                          1
                                                                        ),
                                                                        _vm._v(
                                                                          " "
                                                                        ),
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "ml-3",
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "el-radio-group",
                                                                              {
                                                                                staticClass:
                                                                                  "w-full mt-2",
                                                                                model:
                                                                                  {
                                                                                    value:
                                                                                      _vm
                                                                                        .campaign
                                                                                        .missed_calls_settings
                                                                                        .voicemail_confirmation,
                                                                                    callback:
                                                                                      function (
                                                                                        $$v
                                                                                      ) {
                                                                                        _vm.$set(
                                                                                          _vm
                                                                                            .campaign
                                                                                            .missed_calls_settings,
                                                                                          "voicemail_confirmation",
                                                                                          $$v
                                                                                        )
                                                                                      },
                                                                                    expression:
                                                                                      "campaign.missed_calls_settings.voicemail_confirmation",
                                                                                  },
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "el-radio",
                                                                                  {
                                                                                    staticStyle:
                                                                                      {
                                                                                        width:
                                                                                          "100%",
                                                                                      },
                                                                                    attrs:
                                                                                      {
                                                                                        label: false,
                                                                                      },
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      "No"
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                                _vm._v(
                                                                                  " "
                                                                                ),
                                                                                _c(
                                                                                  "el-radio",
                                                                                  {
                                                                                    staticClass:
                                                                                      "mt-2",
                                                                                    staticStyle:
                                                                                      {
                                                                                        width:
                                                                                          "100%",
                                                                                      },
                                                                                    attrs:
                                                                                      {
                                                                                        label: true,
                                                                                      },
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      "Yes"
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                              ],
                                                                              1
                                                                            ),
                                                                            _vm._v(
                                                                              " "
                                                                            ),
                                                                            _vm
                                                                              .campaign
                                                                              .missed_calls_settings
                                                                              .voicemail_confirmation
                                                                              ? _c(
                                                                                  "div",
                                                                                  {
                                                                                    staticClass:
                                                                                      "voicemail-attention-box mb-4",
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "p",
                                                                                      {
                                                                                        staticClass:
                                                                                          "note",
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          "Note - Platform waits 5 seconds for contact to press 1 to leave a voicemail."
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                    _vm._v(
                                                                                      " "
                                                                                    ),
                                                                                    _c(
                                                                                      "div",
                                                                                      {
                                                                                        staticStyle:
                                                                                          {
                                                                                            width:
                                                                                              "fit-content",
                                                                                          },
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "div",
                                                                                          {
                                                                                            staticClass:
                                                                                              "contain",
                                                                                          },
                                                                                          [
                                                                                            _c(
                                                                                              "img",
                                                                                              {
                                                                                                staticClass:
                                                                                                  "icon",
                                                                                                attrs:
                                                                                                  {
                                                                                                    src: "/assets/images/app-icons/warning-triangle.svg",
                                                                                                  },
                                                                                              }
                                                                                            ),
                                                                                            _vm._v(
                                                                                              " "
                                                                                            ),
                                                                                            _c(
                                                                                              "div",
                                                                                              {
                                                                                                staticClass:
                                                                                                  "voicemail-confirmation-attention",
                                                                                              },
                                                                                              [
                                                                                                _vm._v(
                                                                                                  "Include this in Voicemail Message"
                                                                                                ),
                                                                                              ]
                                                                                            ),
                                                                                          ]
                                                                                        ),
                                                                                        _vm._v(
                                                                                          " "
                                                                                        ),
                                                                                        _c(
                                                                                          "div",
                                                                                          {
                                                                                            staticClass:
                                                                                              "message",
                                                                                          },
                                                                                          [
                                                                                            _c(
                                                                                              "p",
                                                                                              {
                                                                                                staticClass:
                                                                                                  "m-2 _400",
                                                                                              },
                                                                                              [
                                                                                                _vm._v(
                                                                                                  "Your "
                                                                                                ),
                                                                                                _c(
                                                                                                  "span",
                                                                                                  {
                                                                                                    staticClass:
                                                                                                      "_600",
                                                                                                  },
                                                                                                  [
                                                                                                    _vm._v(
                                                                                                      "Voicemail Message"
                                                                                                    ),
                                                                                                  ]
                                                                                                ),
                                                                                                _vm._v(
                                                                                                  " above should explain to contacts they\n                                                                                        should press 1 if they want to leave a voicemail."
                                                                                                ),
                                                                                              ]
                                                                                            ),
                                                                                            _vm._v(
                                                                                              " "
                                                                                            ),
                                                                                            _c(
                                                                                              "p",
                                                                                              {
                                                                                                staticClass:
                                                                                                  "m-2 mt-4 _600",
                                                                                              },
                                                                                              [
                                                                                                _vm._v(
                                                                                                  "Voicemail Message Example:"
                                                                                                ),
                                                                                              ]
                                                                                            ),
                                                                                            _vm._v(
                                                                                              " "
                                                                                            ),
                                                                                            _c(
                                                                                              "p",
                                                                                              {
                                                                                                staticClass:
                                                                                                  "ml-2 mr-2 _400",
                                                                                              },
                                                                                              [
                                                                                                _vm._v(
                                                                                                  "“Sorry, we don’t have any agents available to take your call. Press 1 to leave a\n                                                                                        voicemail and we’ll get back to you.”"
                                                                                                ),
                                                                                              ]
                                                                                            ),
                                                                                          ]
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                  ]
                                                                                )
                                                                              : _vm._e(),
                                                                          ],
                                                                          1
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      { staticClass: "row" },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "col-12 pb-2",
                                                            class:
                                                              _vm.campaign
                                                                .missed_calls_settings
                                                                .missed_call_handling_mode ===
                                                              _vm
                                                                .MissedCallBehaviors
                                                                .MISSED_CALL_BEHAVIOR_REROUTE
                                                                ? "mb-1"
                                                                : "",
                                                          },
                                                          [
                                                            _c(
                                                              "el-form-item",
                                                              {
                                                                staticClass:
                                                                  "no-border",
                                                                class:
                                                                  _vm.campaign
                                                                    .missed_calls_settings
                                                                    .missed_call_handling_mode ===
                                                                  _vm
                                                                    .MissedCallBehaviors
                                                                    .MISSED_CALL_BEHAVIOR_REROUTE
                                                                    ? "mb-3"
                                                                    : "",
                                                                attrs: {
                                                                  prop: "missed_call_handling_mode_router_other_line",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "el-radio",
                                                                  {
                                                                    attrs: {
                                                                      label:
                                                                        _vm
                                                                          .MissedCallBehaviors
                                                                          .MISSED_CALL_BEHAVIOR_REROUTE,
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "\n                                                                Route to other line\n                                                                "
                                                                    ),
                                                                    _vm.campaign
                                                                      .missed_calls_settings
                                                                      .missed_call_handling_mode ===
                                                                    _vm
                                                                      .MissedCallBehaviors
                                                                      .MISSED_CALL_BEHAVIOR_REROUTE
                                                                      ? _c(
                                                                          "campaign-selector",
                                                                          {
                                                                            ref: "campaign_selector",
                                                                            staticClass:
                                                                              "m-2",
                                                                            staticStyle:
                                                                              {
                                                                                "margin-left":
                                                                                  "27px !important",
                                                                              },
                                                                            attrs:
                                                                              {
                                                                                exclude:
                                                                                  _vm
                                                                                    .campaign
                                                                                    .id,
                                                                                value:
                                                                                  _vm
                                                                                    .campaign
                                                                                    .missed_calls_settings
                                                                                    .reroute_to_campaign_id,
                                                                              },
                                                                            on: {
                                                                              change:
                                                                                _vm.changeRerouteToCampaign,
                                                                            },
                                                                            model:
                                                                              {
                                                                                value:
                                                                                  _vm
                                                                                    .campaign
                                                                                    .missed_calls_settings
                                                                                    .reroute_to_campaign_id,
                                                                                callback:
                                                                                  function (
                                                                                    $$v
                                                                                  ) {
                                                                                    _vm.$set(
                                                                                      _vm
                                                                                        .campaign
                                                                                        .missed_calls_settings,
                                                                                      "reroute_to_campaign_id",
                                                                                      $$v
                                                                                    )
                                                                                  },
                                                                                expression:
                                                                                  "campaign.missed_calls_settings.reroute_to_campaign_id",
                                                                              },
                                                                          }
                                                                        )
                                                                      : _vm._e(),
                                                                  ],
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      { staticClass: "row" },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "col-12 pb-2",
                                                            class:
                                                              _vm.campaign
                                                                .missed_calls_settings
                                                                .missed_call_handling_mode ===
                                                              _vm
                                                                .MissedCallBehaviors
                                                                .MISSED_CALL_BEHAVIOR_FORWARD
                                                                ? "mb-1"
                                                                : "",
                                                          },
                                                          [
                                                            _c(
                                                              "el-form-item",
                                                              {
                                                                staticClass:
                                                                  "no-border",
                                                                class:
                                                                  _vm.campaign
                                                                    .missed_calls_settings
                                                                    .missed_call_handling_mode ===
                                                                  _vm
                                                                    .MissedCallBehaviors
                                                                    .MISSED_CALL_BEHAVIOR_FORWARD
                                                                    ? "mb-3"
                                                                    : "",
                                                                attrs: {
                                                                  prop: "missed_call_handling_mode_forward_number",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "el-radio",
                                                                  {
                                                                    attrs: {
                                                                      label:
                                                                        _vm
                                                                          .MissedCallBehaviors
                                                                          .MISSED_CALL_BEHAVIOR_FORWARD,
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "\n                                                                Forward externally\n                                                                "
                                                                    ),
                                                                    _vm.campaign
                                                                      .missed_calls_settings
                                                                      .missed_call_handling_mode ===
                                                                    _vm
                                                                      .MissedCallBehaviors
                                                                      .MISSED_CALL_BEHAVIOR_FORWARD
                                                                      ? _c(
                                                                          "el-form-item",
                                                                          {
                                                                            staticClass:
                                                                              "m-2 no-border",
                                                                            attrs:
                                                                              {
                                                                                prop: "missed_call_handling_options.forward_to",
                                                                              },
                                                                          },
                                                                          [
                                                                            _vm.isBlockedFrom(
                                                                              "line external forwarding"
                                                                            )
                                                                              ? _c(
                                                                                  "div",
                                                                                  [
                                                                                    _vm.shouldShowUpgradeNow
                                                                                      ? _c(
                                                                                          "upgrade-now-lite",
                                                                                          {
                                                                                            attrs:
                                                                                              {
                                                                                                "kb-link":
                                                                                                  "https://support.aloware.com/en/articles/9031270-lines-menu-general-settings",
                                                                                                text: "External forwarding is not included in your current plan. To use it, please contact us to upgrade today!",
                                                                                                "default-click": true,
                                                                                              },
                                                                                          }
                                                                                        )
                                                                                      : _vm._e(),
                                                                                  ],
                                                                                  1
                                                                                )
                                                                              : [
                                                                                  _c(
                                                                                    "el-input",
                                                                                    {
                                                                                      staticStyle:
                                                                                        {
                                                                                          width:
                                                                                            "200px",
                                                                                          "margin-left":
                                                                                            "27px !important",
                                                                                        },
                                                                                      attrs:
                                                                                        {
                                                                                          placeholder:
                                                                                            "Forward Number",
                                                                                        },
                                                                                      on: {
                                                                                        input:
                                                                                          function (
                                                                                            $event
                                                                                          ) {
                                                                                            return _vm.preValidateForm(
                                                                                              "campaign_settings"
                                                                                            )
                                                                                          },
                                                                                      },
                                                                                      model:
                                                                                        {
                                                                                          value:
                                                                                            _vm
                                                                                              .campaign
                                                                                              .missed_calls_settings
                                                                                              .forward_to,
                                                                                          callback:
                                                                                            function (
                                                                                              $$v
                                                                                            ) {
                                                                                              _vm.$set(
                                                                                                _vm
                                                                                                  .campaign
                                                                                                  .missed_calls_settings,
                                                                                                "forward_to",
                                                                                                $$v
                                                                                              )
                                                                                            },
                                                                                          expression:
                                                                                            "campaign.missed_calls_settings.forward_to",
                                                                                        },
                                                                                    }
                                                                                  ),
                                                                                ],
                                                                          ],
                                                                          2
                                                                        )
                                                                      : _vm._e(),
                                                                  ],
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      { staticClass: "row" },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "col-12 pt-2",
                                                          },
                                                          [
                                                            _c(
                                                              "el-radio",
                                                              {
                                                                attrs: {
                                                                  label:
                                                                    _vm
                                                                      .MissedCallBehaviors
                                                                      .MISSED_CALL_BEHAVIOR_NOTHING,
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                                                            Do nothing and hang up\n                                                        "
                                                                ),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "el-form-item",
                                              {
                                                attrs: {
                                                  prop: "missed_calls_extra_notifications",
                                                },
                                              },
                                              [
                                                _c("span", {
                                                  staticClass: "page-anchor",
                                                  attrs: {
                                                    id: "missed_calls_extra_notifications",
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  { staticClass: "form-label" },
                                                  [
                                                    _c("h5", [
                                                      _vm._v(
                                                        "Missed Calls Extra Notifications"
                                                      ),
                                                    ]),
                                                    _vm._v(" "),
                                                    _c("small", [
                                                      _vm._v(
                                                        "\n                                                    Select who receives a notification when your business has a missed call\n                                                "
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "el-checkbox",
                                                  {
                                                    on: {
                                                      change: function (
                                                        $event
                                                      ) {
                                                        return _vm.preValidateForm(
                                                          "campaign_settings"
                                                        )
                                                      },
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.campaign
                                                          .last_called_agent_missed_call_notification,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.campaign,
                                                          "last_called_agent_missed_call_notification",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "campaign.last_called_agent_missed_call_notification",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                                                Notify the last agent who called the contact\n                                            "
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c("br"),
                                                _vm._v(" "),
                                                _c(
                                                  "el-checkbox",
                                                  {
                                                    on: {
                                                      change: function (
                                                        $event
                                                      ) {
                                                        return _vm.preValidateForm(
                                                          "campaign_settings"
                                                        )
                                                      },
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.campaign
                                                          .contact_owner_missed_call_notification,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.campaign,
                                                          "contact_owner_missed_call_notification",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "campaign.contact_owner_missed_call_notification",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                                                Notify the contact owner\n                                            "
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "el-form-item",
                                              { staticClass: "no-border" },
                                              [
                                                _c("span", {
                                                  staticClass: "page-anchor",
                                                  attrs: {
                                                    id: "should_message_if_missed",
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  { staticClass: "form-label" },
                                                  [
                                                    _c("h5", [
                                                      _vm._v(
                                                        "Text message after missed call"
                                                      ),
                                                    ]),
                                                    _vm._v(" "),
                                                    _c("small", [
                                                      _vm._v(
                                                        "\n                                                    If the call is abandoned or missed, send a text message to the\n                                                    caller.\n                                                "
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "switch-label mb-3",
                                                  },
                                                  [
                                                    _c("el-switch", {
                                                      attrs: {
                                                        "active-color":
                                                          "#00a344",
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.campaign
                                                            .should_message_if_missed,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.campaign,
                                                            "should_message_if_missed",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "campaign.should_message_if_missed",
                                                      },
                                                    }),
                                                    _vm._v(" "),
                                                    _c("label", [
                                                      _vm._v(
                                                        "Enable text message after missed call"
                                                      ),
                                                    ]),
                                                  ],
                                                  1
                                                ),
                                                _vm._v(" "),
                                                _vm.campaign
                                                  .should_message_if_missed
                                                  ? _c(
                                                      "el-form-item",
                                                      {
                                                        staticClass:
                                                          "no-border",
                                                        attrs: {
                                                          prop: "missed_call_message",
                                                        },
                                                      },
                                                      [
                                                        _c("el-input", {
                                                          attrs: {
                                                            type: "textarea",
                                                            placeholder:
                                                              "Follow up text",
                                                            rows: 2,
                                                          },
                                                          on: {
                                                            input: function (
                                                              $event
                                                            ) {
                                                              return _vm.preValidateForm(
                                                                "campaign_settings"
                                                              )
                                                            },
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.campaign
                                                                .missed_call_message,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.campaign,
                                                                "missed_call_message",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "campaign.missed_call_message",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "col-md-2 d-none d-md-block ml-auto",
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "list-group list-group-alo list-group-flush sticky-top stick-top-with-header",
                                              },
                                              [
                                                _c(
                                                  "h6",
                                                  {
                                                    staticClass:
                                                      "list-group-item",
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                                                On This Page\n                                            "
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "a",
                                                  {
                                                    staticClass:
                                                      "list-group-item list-group-item-action",
                                                    attrs: { href: "#answer" },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.shine(
                                                          "answer"
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                                                Call Assignment\n                                            "
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "a",
                                                  {
                                                    staticClass:
                                                      "list-group-item list-group-item-action",
                                                    attrs: {
                                                      href: "#csat_survey",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.shine(
                                                          "csat_survey"
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                                                CSAT Survey\n                                            "
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "a",
                                                  {
                                                    staticClass:
                                                      "list-group-item list-group-item-action",
                                                    attrs: {
                                                      href: "#missed_call_handling_mode",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.shine(
                                                          "missed_call_handling_mode"
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                                                Missed Calls\n                                            "
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "a",
                                                  {
                                                    staticClass:
                                                      "list-group-item list-group-item-action",
                                                    attrs: {
                                                      href: "#missed_calls_extra_notifications",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.shine(
                                                          "missed_calls_extra_notifications"
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                                                Missed Calls Extra Notifications\n                                            "
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "a",
                                                  {
                                                    staticClass:
                                                      "list-group-item list-group-item-action",
                                                    attrs: {
                                                      href: "#should_message_if_missed",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.shine(
                                                          "should_message_if_missed"
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                                                Text After Missed Calls\n                                            "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]),
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              !_vm.campaign.is_fax
                                ? _c(
                                    "el-tab-pane",
                                    {
                                      staticClass: "p-3",
                                      attrs: {
                                        name: "advanced-settings",
                                        "data-tab-error": "advanced_settings",
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          attrs: { slot: "label" },
                                          slot: "label",
                                        },
                                        [
                                          _vm._v("Advanced Settings"),
                                          _c("span", {
                                            domProps: {
                                              innerHTML: _vm._s(
                                                _vm.tabLabelErrorIcon(
                                                  _vm.tab_errors
                                                    .advanced_settings
                                                )
                                              ),
                                            },
                                          }),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "row" },
                                        [
                                          _vm.isBlockedFrom(
                                            "line advanced settings"
                                          )
                                            ? _c(
                                                "div",
                                                { staticClass: "col-md-12" },
                                                [
                                                  _vm.shouldShowUpgradeNow
                                                    ? _c("upgrade-now", {
                                                        attrs: {
                                                          "image-link":
                                                            "/assets/images/fomo/LineAdvancedSettings.png",
                                                          "kb-link":
                                                            "https://support.aloware.com/en/articles/9031270-lines-menu-general-settings",
                                                          text: "Advanced Settings are not included in your current plan. To use it, please contact us to upgrade today! ",
                                                          "title-text":
                                                            "Advanced Settings",
                                                          "default-click": true,
                                                        },
                                                      })
                                                    : _vm._e(),
                                                ],
                                                1
                                              )
                                            : [
                                                _c(
                                                  "div",
                                                  { staticClass: "col-md-9" },
                                                  [
                                                    _c("el-form-item", [
                                                      _c("span", {
                                                        staticClass:
                                                          "page-anchor",
                                                        attrs: {
                                                          id: "should_whisper",
                                                        },
                                                      }),
                                                      _vm._v(" "),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "form-label",
                                                        },
                                                        [
                                                          _c("h5", [
                                                            _vm._v("Whisper"),
                                                          ]),
                                                          _vm._v(" "),
                                                          _c("small", [
                                                            _vm._v(
                                                              "\n                                                        Whisper something to the agent before connecting the call. The\n                                                        caller does not hear the message.\n                                                    "
                                                            ),
                                                          ]),
                                                          _vm._v(" "),
                                                          _c("el-popover", {
                                                            ref: "popover_whisper",
                                                            staticClass: "ml-2",
                                                            attrs: {
                                                              placement:
                                                                "right",
                                                              title: "Whisper",
                                                              width: "400",
                                                              trigger: "hover",
                                                              content:
                                                                "Useful when your agents are taking calls from\n                                                                    different campaigns. Your can whisper someone's name,\n                                                                    marketing source or other data into their ears.",
                                                            },
                                                          }),
                                                          _vm._v(" "),
                                                          _c(
                                                            "span",
                                                            {
                                                              directives: [
                                                                {
                                                                  name: "popover",
                                                                  rawName:
                                                                    "v-popover:popover_whisper",
                                                                  arg: "popover_whisper",
                                                                },
                                                              ],
                                                              staticClass:
                                                                "form-hover-help text-blue",
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                                                            More Info\n                                                    "
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _vm._v(" "),
                                                      _c("div", [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "switch-label mb-3",
                                                          },
                                                          [
                                                            _c("el-switch", {
                                                              attrs: {
                                                                "active-color":
                                                                  "#00a344",
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.campaign
                                                                    .should_whisper,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.campaign,
                                                                      "should_whisper",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "campaign.should_whisper",
                                                              },
                                                            }),
                                                            _vm._v(" "),
                                                            _c("label", [
                                                              _vm._v(
                                                                "\n                                                            Enable whisper\n                                                        "
                                                              ),
                                                            ]),
                                                          ],
                                                          1
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "div",
                                                          {
                                                            directives: [
                                                              {
                                                                name: "show",
                                                                rawName:
                                                                  "v-show",
                                                                value:
                                                                  _vm.campaign
                                                                    .should_whisper,
                                                                expression:
                                                                  "campaign.should_whisper",
                                                              },
                                                            ],
                                                            staticClass:
                                                              "b-b nav-active-greenish",
                                                          },
                                                          [
                                                            _c(
                                                              "ul",
                                                              {
                                                                staticClass:
                                                                  "nav nav-tabs",
                                                              },
                                                              [
                                                                _c(
                                                                  "li",
                                                                  {
                                                                    staticClass:
                                                                      "nav-item",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "a",
                                                                      {
                                                                        staticClass:
                                                                          "border border-right-0 rounded-top-left",
                                                                        class:
                                                                          _vm
                                                                            .whisperNavClass
                                                                            .read,
                                                                        attrs: {
                                                                          href: "",
                                                                          "data-toggle":
                                                                            "tab",
                                                                          "data-target":
                                                                            "#whisper-tts",
                                                                          "aria-expanded":
                                                                            "true",
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "i",
                                                                          {
                                                                            staticClass:
                                                                              "fa fa-robot",
                                                                          }
                                                                        ),
                                                                        _vm._v(
                                                                          "\n                                                                    Text to Speech\n                                                                "
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                ),
                                                                _vm._v(" "),
                                                                _vm.hasPermissionTo(
                                                                  "upload file campaign"
                                                                )
                                                                  ? _c(
                                                                      "li",
                                                                      {
                                                                        staticClass:
                                                                          "nav-item",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "a",
                                                                          {
                                                                            staticClass:
                                                                              "border rounded-top-right",
                                                                            class:
                                                                              _vm
                                                                                .whisperNavClass
                                                                                .play,
                                                                            attrs:
                                                                              {
                                                                                href: "",
                                                                                "data-toggle":
                                                                                  "tab",
                                                                                "data-target":
                                                                                  "#whisper-file",
                                                                                "aria-expanded":
                                                                                  "false",
                                                                              },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "i",
                                                                              {
                                                                                staticClass:
                                                                                  "fa fa-play text-md",
                                                                              }
                                                                            ),
                                                                            _vm._v(
                                                                              "\n                                                                    Play Recording\n                                                                "
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ]
                                                                    )
                                                                  : _vm._e(),
                                                                _vm._v(" "),
                                                                _vm.campaign
                                                                  .whisper_file
                                                                  ? _c(
                                                                      "li",
                                                                      {
                                                                        staticClass:
                                                                          "nav-item",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "a",
                                                                          {
                                                                            staticClass:
                                                                              "nav-link",
                                                                            on: {
                                                                              click:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  $event.preventDefault()
                                                                                  return _vm.deleteFile(
                                                                                    "whisper"
                                                                                  )
                                                                                },
                                                                            },
                                                                          }
                                                                        ),
                                                                      ]
                                                                    )
                                                                  : _vm._e(),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "div",
                                                          {
                                                            directives: [
                                                              {
                                                                name: "show",
                                                                rawName:
                                                                  "v-show",
                                                                value:
                                                                  _vm.campaign
                                                                    .should_whisper,
                                                                expression:
                                                                  "campaign.should_whisper",
                                                              },
                                                            ],
                                                            staticClass:
                                                              "tab-content p-a m-b-md border border-top-0",
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "break-word",
                                                                class:
                                                                  _vm
                                                                    .whisperContainerClass
                                                                    .read,
                                                                attrs: {
                                                                  id: "whisper-tts",
                                                                  "aria-expanded":
                                                                    "true",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "el-form-item",
                                                                  {
                                                                    staticClass:
                                                                      "no-border mb-4",
                                                                    attrs: {
                                                                      label:
                                                                        "Whisper Message",
                                                                      prop: "should_whisper",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "row",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "col-12",
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "div",
                                                                              {
                                                                                staticClass:
                                                                                  "input-group",
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "input",
                                                                                  {
                                                                                    directives:
                                                                                      [
                                                                                        {
                                                                                          name: "model",
                                                                                          rawName:
                                                                                            "v-model",
                                                                                          value:
                                                                                            _vm
                                                                                              .campaign
                                                                                              .whisper_tts,
                                                                                          expression:
                                                                                            "campaign.whisper_tts",
                                                                                        },
                                                                                      ],
                                                                                    staticClass:
                                                                                      "form-control pt-2 pb-2",
                                                                                    attrs:
                                                                                      {
                                                                                        type: "text",
                                                                                      },
                                                                                    domProps:
                                                                                      {
                                                                                        value:
                                                                                          _vm
                                                                                            .campaign
                                                                                            .whisper_tts,
                                                                                      },
                                                                                    on: {
                                                                                      input:
                                                                                        [
                                                                                          function (
                                                                                            $event
                                                                                          ) {
                                                                                            if (
                                                                                              $event
                                                                                                .target
                                                                                                .composing
                                                                                            )
                                                                                              return
                                                                                            _vm.$set(
                                                                                              _vm.campaign,
                                                                                              "whisper_tts",
                                                                                              $event
                                                                                                .target
                                                                                                .value
                                                                                            )
                                                                                          },
                                                                                          function (
                                                                                            $event
                                                                                          ) {
                                                                                            return _vm.preValidateForm(
                                                                                              "campaign_settings"
                                                                                            )
                                                                                          },
                                                                                        ],
                                                                                    },
                                                                                  }
                                                                                ),
                                                                                _vm._v(
                                                                                  " "
                                                                                ),
                                                                                _c(
                                                                                  "variable-dialog",
                                                                                  {
                                                                                    attrs:
                                                                                      {
                                                                                        has_agent: true,
                                                                                      },
                                                                                  }
                                                                                ),
                                                                              ],
                                                                              1
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                ),
                                                                _vm._v(" "),
                                                                _c("p", [
                                                                  _vm._v(
                                                                    "\n                                                                This text-to-speech prompt automatically plays immediately as soon as the call gets connected. It’s important to make sure that the\n                                                                forwarding number does not have any prompts as the text-to-speech will play while the prompt is also playing causing the agent to\n                                                                miss\n                                                                the text-to-speech prompt.\n                                                            "
                                                                  ),
                                                                ]),
                                                              ],
                                                              1
                                                            ),
                                                            _vm._v(" "),
                                                            _vm.hasPermissionTo(
                                                              "upload file campaign"
                                                            )
                                                              ? _c(
                                                                  "div",
                                                                  {
                                                                    class:
                                                                      _vm
                                                                        .whisperContainerClass
                                                                        .play,
                                                                    attrs: {
                                                                      id: "whisper-file",
                                                                      "aria-expanded":
                                                                        "false",
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm.campaign
                                                                      .whisper_file ===
                                                                    null
                                                                      ? _c(
                                                                          "audio-recorder",
                                                                          {
                                                                            staticClass:
                                                                              "audio-recorder-wrapper",
                                                                            attrs:
                                                                              {
                                                                                action:
                                                                                  "/api/v1/campaign/" +
                                                                                  _vm
                                                                                    .campaign
                                                                                    .id +
                                                                                  "/upload-ivr/whispers",
                                                                                upload_type:
                                                                                  "whisper_file",
                                                                              },
                                                                            on: {
                                                                              applyUploadedAudio:
                                                                                _vm.applyUploadedAudio,
                                                                            },
                                                                          }
                                                                        )
                                                                      : _vm._e(),
                                                                    _vm._v(" "),
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "audio-uploader-wrapper",
                                                                      },
                                                                      [
                                                                        _vm
                                                                          .campaign
                                                                          .whisper_file ===
                                                                        null
                                                                          ? _c(
                                                                              "el-upload",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    drag: "",
                                                                                    action:
                                                                                      "/api/v1/campaign/" +
                                                                                      _vm
                                                                                        .campaign
                                                                                        .id +
                                                                                      "/upload-ivr/whispers",
                                                                                    headers:
                                                                                      _vm.headers,
                                                                                    "on-success":
                                                                                      _vm.onSuccessWhisper,
                                                                                    "on-error":
                                                                                      _vm.onFailedWhisper,
                                                                                    "on-progress":
                                                                                      _vm.progressUploadWhisper,
                                                                                    "before-upload":
                                                                                      _vm.beforeUploadWhisper,
                                                                                    "file-list":
                                                                                      _vm
                                                                                        .uploadFileList
                                                                                        .whisper,
                                                                                  },
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "i",
                                                                                  {
                                                                                    staticClass:
                                                                                      "el-icon-upload",
                                                                                  }
                                                                                ),
                                                                                _vm._v(
                                                                                  " "
                                                                                ),
                                                                                _c(
                                                                                  "div",
                                                                                  {
                                                                                    staticClass:
                                                                                      "el-upload__text",
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      "\n                                                                        Drop file here or "
                                                                                    ),
                                                                                    _c(
                                                                                      "em",
                                                                                      [
                                                                                        _vm._v(
                                                                                          "click to\n                                                                        upload"
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                                _vm._v(
                                                                                  " "
                                                                                ),
                                                                                _c(
                                                                                  "div",
                                                                                  {
                                                                                    staticClass:
                                                                                      "el-upload__tip",
                                                                                    attrs:
                                                                                      {
                                                                                        slot: "tip",
                                                                                      },
                                                                                    slot: "tip",
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      "\n                                                                        MP3/WAV file (less than 8MB)\n                                                                    "
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                              ]
                                                                            )
                                                                          : _vm._e(),
                                                                        _vm._v(
                                                                          " "
                                                                        ),
                                                                        _vm
                                                                          .campaign
                                                                          .whisper_file ===
                                                                        null
                                                                          ? _c(
                                                                              "el-progress",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    "text-inside": true,
                                                                                    "stroke-width": 18,
                                                                                    percentage:
                                                                                      _vm
                                                                                        .uploadPercentage
                                                                                        .whisper,
                                                                                    status:
                                                                                      _vm
                                                                                        .uploadStatus
                                                                                        .whisper,
                                                                                  },
                                                                              }
                                                                            )
                                                                          : _vm._e(),
                                                                        _vm._v(
                                                                          " "
                                                                        ),
                                                                        _vm
                                                                          .campaign
                                                                          .whisper_file !==
                                                                        null
                                                                          ? _c(
                                                                              "audio",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    controls:
                                                                                      "",
                                                                                  },
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "source",
                                                                                  {
                                                                                    attrs:
                                                                                      {
                                                                                        src:
                                                                                          "/static/uploaded_file/" +
                                                                                          _vm
                                                                                            .campaign
                                                                                            .whisper_file,
                                                                                      },
                                                                                  }
                                                                                ),
                                                                                _vm._v(
                                                                                  "\n                                                                    Your browser does not support the audio\n                                                                    element.\n                                                                "
                                                                                ),
                                                                              ]
                                                                            )
                                                                          : _vm._e(),
                                                                        _vm._v(
                                                                          " "
                                                                        ),
                                                                        _vm
                                                                          .campaign
                                                                          .whisper_file !==
                                                                          null &&
                                                                        _vm.hasPermissionTo(
                                                                          "delete file campaign"
                                                                        )
                                                                          ? _c(
                                                                              "button",
                                                                              {
                                                                                staticClass:
                                                                                  "btn btn-sm btn-danger pull-right",
                                                                                on: {
                                                                                  click:
                                                                                    function (
                                                                                      $event
                                                                                    ) {
                                                                                      $event.preventDefault()
                                                                                      return _vm.deleteFile(
                                                                                        "whispers"
                                                                                      )
                                                                                    },
                                                                                },
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "i",
                                                                                  {
                                                                                    directives:
                                                                                      [
                                                                                        {
                                                                                          name: "show",
                                                                                          rawName:
                                                                                            "v-show",
                                                                                          value:
                                                                                            _vm.loading_whisper,
                                                                                          expression:
                                                                                            "loading_whisper",
                                                                                        },
                                                                                      ],
                                                                                    staticClass:
                                                                                      "material-icons loader pull-left",
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      ""
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                                _vm._v(
                                                                                  " "
                                                                                ),
                                                                                _c(
                                                                                  "i",
                                                                                  {
                                                                                    directives:
                                                                                      [
                                                                                        {
                                                                                          name: "show",
                                                                                          rawName:
                                                                                            "v-show",
                                                                                          value:
                                                                                            !_vm.loading_whisper,
                                                                                          expression:
                                                                                            "!loading_whisper",
                                                                                        },
                                                                                      ],
                                                                                    staticClass:
                                                                                      "material-icons pull-left",
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      ""
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                                _vm._v(
                                                                                  " "
                                                                                ),
                                                                                _c(
                                                                                  "span",
                                                                                  [
                                                                                    _vm._v(
                                                                                      "Remove file"
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                              ]
                                                                            )
                                                                          : _vm._e(),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ],
                                                                  1
                                                                )
                                                              : _vm._e(),
                                                          ]
                                                        ),
                                                      ]),
                                                    ]),
                                                    _vm._v(" "),
                                                    _c("el-form-item", [
                                                      _c("span", {
                                                        staticClass:
                                                          "page-anchor",
                                                        attrs: {
                                                          id: "caller_id_option",
                                                        },
                                                      }),
                                                      _vm._v(" "),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "form-label",
                                                        },
                                                        [
                                                          _c("h5", [
                                                            _vm._v(
                                                              "Caller ID Suppression"
                                                            ),
                                                          ]),
                                                          _vm._v(" "),
                                                          _c("small", [
                                                            _vm._v(
                                                              "\n                                                        Redact caller's phone number when transferring calls.\n                                                        Only useful if you are selling calls\n                                                        to third parties.\n                                                    "
                                                            ),
                                                          ]),
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "switch-label",
                                                        },
                                                        [
                                                          _c("el-switch", {
                                                            attrs: {
                                                              "active-color":
                                                                "#00a344",
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.campaign
                                                                  .caller_id_option,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    _vm.campaign,
                                                                    "caller_id_option",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "campaign.caller_id_option",
                                                            },
                                                          }),
                                                          _vm._v(" "),
                                                          _c("label", [
                                                            _vm._v(
                                                              "\n                                                        Enable caller ID suppression\n                                                    "
                                                            ),
                                                          ]),
                                                        ],
                                                        1
                                                      ),
                                                    ]),
                                                    _vm._v(" "),
                                                    _c("el-form-item", [
                                                      _c("span", {
                                                        staticClass:
                                                          "page-anchor",
                                                        attrs: {
                                                          id: "should_block_spam",
                                                        },
                                                      }),
                                                      _vm._v(" "),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "form-label",
                                                        },
                                                        [
                                                          _c("h5", [
                                                            _vm._v(
                                                              "Spam Detection (Beta)"
                                                            ),
                                                          ]),
                                                          _vm._v(" "),
                                                          _c("small", [
                                                            _vm._v(
                                                              "\n                                                        This feature blocks calls from known spammers and robocallers.\n                                                        All calls will be rejected without ringing you.\n                                                    "
                                                            ),
                                                          ]),
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "switch-label",
                                                        },
                                                        [
                                                          _c("el-switch", {
                                                            attrs: {
                                                              "active-color":
                                                                "#00a344",
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.campaign
                                                                  .should_block_spam,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    _vm.campaign,
                                                                    "should_block_spam",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "campaign.should_block_spam",
                                                            },
                                                          }),
                                                          _vm._v(" "),
                                                          _c("label", [
                                                            _vm._v(
                                                              "\n                                                        Enable spam detection\n                                                    "
                                                            ),
                                                          ]),
                                                        ],
                                                        1
                                                      ),
                                                    ]),
                                                  ],
                                                  1
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "col-md-2 d-none d-md-block ml-auto",
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "list-group list-group-alo list-group-flush sticky-top stick-top-with-header",
                                                      },
                                                      [
                                                        _c(
                                                          "h6",
                                                          {
                                                            staticClass:
                                                              "list-group-item",
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                                                    On This Page\n                                                "
                                                            ),
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "a",
                                                          {
                                                            staticClass:
                                                              "list-group-item list-group-item-action",
                                                            attrs: {
                                                              href: "#should_whisper",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.shine(
                                                                  "should_whisper"
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                                                    Whisper\n                                                "
                                                            ),
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "a",
                                                          {
                                                            staticClass:
                                                              "list-group-item list-group-item-action",
                                                            attrs: {
                                                              href: "#caller_id_option",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.shine(
                                                                  "caller_id_option"
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                                                    Call Mask Suppression\n                                                "
                                                            ),
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "a",
                                                          {
                                                            staticClass:
                                                              "list-group-item list-group-item-action",
                                                            attrs: {
                                                              href: "#should_block_spam",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.shine(
                                                                  "should_block_spam"
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                                                    Spam Detection\n                                                "
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ],
                                        ],
                                        2
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              !_vm.campaign.is_fax
                                ? _c(
                                    "el-tab-pane",
                                    {
                                      staticClass: "p-3",
                                      attrs: {
                                        name: "triggers",
                                        "data-tab-error": "triggers",
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          attrs: { slot: "label" },
                                          slot: "label",
                                        },
                                        [
                                          _vm._v("Triggers"),
                                          _c("span", {
                                            domProps: {
                                              innerHTML: _vm._s(
                                                _vm.tabLabelErrorIcon(
                                                  _vm.tab_errors.triggers
                                                )
                                              ),
                                            },
                                          }),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "row" },
                                        [
                                          _vm.isBlockedFrom("line triggers")
                                            ? _c(
                                                "div",
                                                { staticClass: "col-md-12" },
                                                [
                                                  _vm.shouldShowUpgradeNow
                                                    ? _c("upgrade-now", {
                                                        attrs: {
                                                          "image-link":
                                                            "/assets/images/fomo/LineTriggers.png",
                                                          "kb-link":
                                                            "https://support.aloware.com/en/articles/9031270-lines-menu-general-settings",
                                                          text: "Triggers are not included in your current plan. To use it, please contact us to upgrade today!",
                                                          "title-text":
                                                            "Triggers",
                                                          "default-click": true,
                                                        },
                                                      })
                                                    : _vm._e(),
                                                ],
                                                1
                                              )
                                            : [
                                                _c(
                                                  "div",
                                                  { staticClass: "col-md-12" },
                                                  [
                                                    _c(
                                                      "el-form-item",
                                                      {
                                                        staticClass:
                                                          "no-border",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "form-label mb-3",
                                                          },
                                                          [
                                                            _c("h5", [
                                                              _vm._v(
                                                                "Triggers"
                                                              ),
                                                            ]),
                                                            _vm._v(" "),
                                                            _c(
                                                              "small",
                                                              {
                                                                staticClass:
                                                                  "w-75 d-block mb-2",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                                                        Here you can define triggers to be applied to\n                                                        your calls, messages, and contacts based on their\n                                                        disposition (how they finished) and duration, status or content.\n                                                        All triggers will be applied independently without order or precedence. One trigger will not modify another trigger.\n                                                    "
                                                                ),
                                                              ]
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "a",
                                                              {
                                                                staticClass:
                                                                  "help",
                                                                attrs: {
                                                                  href: "https://support.aloware.com/en/articles/9031270-lines-menu-general-settings",
                                                                  title: "help",
                                                                  target:
                                                                    "_blank",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "el-button",
                                                                  {
                                                                    attrs: {
                                                                      type: "primary",
                                                                      plain: "",
                                                                      size: "small",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c("i", {
                                                                      staticClass:
                                                                        "fa fa-question-circle",
                                                                    }),
                                                                    _vm._v(
                                                                      "\n                                                            Read Tutorial\n                                                        "
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c("div", [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "row",
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "col-12",
                                                                },
                                                                [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "form-group",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "el-form-item",
                                                                        {
                                                                          staticClass:
                                                                            "mb-0 no-border",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "row",
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "div",
                                                                                {
                                                                                  staticClass:
                                                                                    "col-12",
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "triggers",
                                                                                    {
                                                                                      attrs:
                                                                                        {
                                                                                          triggers:
                                                                                            _vm
                                                                                              .campaign
                                                                                              .triggers,
                                                                                          intake_route_list:
                                                                                            _vm.intake_route_list,
                                                                                        },
                                                                                      on: {
                                                                                        validateTrigger:
                                                                                          _vm.updateValidation,
                                                                                      },
                                                                                    }
                                                                                  ),
                                                                                ],
                                                                                1
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                        ]),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                        ],
                                        2
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.current_company &&
                              _vm.campaign &&
                              _vm.hasRole("Company Admin") &&
                              !_vm.campaign.is_fax
                                ? _c(
                                    "el-tab-pane",
                                    {
                                      staticClass: "p-3",
                                      attrs: { name: "cnam" },
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          attrs: { slot: "label" },
                                          slot: "label",
                                        },
                                        [_vm._v("CNAM")]
                                      ),
                                      _vm._v(" "),
                                      _c("cnam-tab", {
                                        attrs: {
                                          campaign_id: _vm.campaign.id,
                                          caller_id:
                                            _vm.campaign.cnam_caller_id,
                                        },
                                        on: {
                                          "update:caller_id": function (
                                            $event
                                          ) {
                                            return _vm.$set(
                                              _vm.campaign,
                                              "cnam_caller_id",
                                              $event
                                            )
                                          },
                                          "CNAM-deleted": _vm.removeCNAM,
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.canSeeEmailConversations
                                ? _c(
                                    "el-tab-pane",
                                    {
                                      staticClass: "p-3",
                                      attrs: {
                                        name: "email_conversations",
                                        "data-tab-error": "email_conversations",
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          attrs: { slot: "label" },
                                          slot: "label",
                                        },
                                        [
                                          _vm._v("Email Conversations"),
                                          _c("span", {
                                            domProps: {
                                              innerHTML: _vm._s(
                                                _vm.tabLabelErrorIcon(
                                                  _vm.tab_errors
                                                    .email_conversations
                                                )
                                              ),
                                            },
                                          }),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-form-item",
                                        { staticClass: "no-border" },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "form-label" },
                                            [
                                              _c("h5", [
                                                _vm._v("Email Conversations"),
                                              ]),
                                              _vm._v(" "),
                                              _c("small", [
                                                _vm._v(
                                                  "Here you can define an email route to have two-way email conversations with your customers"
                                                ),
                                              ]),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "a",
                                            {
                                              attrs: {
                                                href: "https://support.aloware.com/en/articles/9037643-sending-emails-with-aloware-talk",
                                                title: "help",
                                                target: "_blank",
                                              },
                                            },
                                            [
                                              _c(
                                                "el-button",
                                                {
                                                  attrs: {
                                                    type: "primary",
                                                    plain: "",
                                                    size: "small",
                                                  },
                                                },
                                                [
                                                  _c("i", {
                                                    staticClass:
                                                      "fa fa-question-circle",
                                                  }),
                                                  _vm._v(
                                                    "\n                                            Read Tutorial\n                                        "
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            { staticClass: "mt-3" },
                                            [
                                              _c(
                                                "el-form",
                                                {
                                                  ref: "intake_email_form",
                                                  attrs: {
                                                    autocomplete: "off",
                                                    "label-position": "top",
                                                    model: _vm.intake_data,
                                                  },
                                                  nativeOn: {
                                                    submit: function ($event) {
                                                      $event.preventDefault()
                                                      return _vm.saveIntakeRoute.apply(
                                                        null,
                                                        arguments
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "el-form-item",
                                                    {
                                                      staticClass: "no-border",
                                                      attrs: { label: "Email" },
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        { staticClass: "row" },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "col-md-4",
                                                            },
                                                            [
                                                              _c(
                                                                "el-input",
                                                                {
                                                                  staticClass:
                                                                    "btn-input-group",
                                                                  style: {
                                                                    "min-width":
                                                                      "370px",
                                                                  },
                                                                  attrs: {
                                                                    keyup:
                                                                      _vm.checkSpecialCharacter(),
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm.intake_username,
                                                                    callback:
                                                                      function (
                                                                        $$v
                                                                      ) {
                                                                        _vm.intake_username =
                                                                          $$v
                                                                      },
                                                                    expression:
                                                                      "intake_username",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "template",
                                                                    {
                                                                      slot: "append",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "@" +
                                                                          _vm._s(
                                                                            _vm.conversation_domain
                                                                          )
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                2
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.campaign && _vm.hasRole("Company Admin")
                                ? _c(
                                    "el-tab-pane",
                                    {
                                      staticClass: "p-3",
                                      attrs: { name: "audit" },
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          attrs: { slot: "label" },
                                          slot: "label",
                                        },
                                        [_vm._v("Audit Log")]
                                      ),
                                      _vm._v(" "),
                                      _c("AuditTab", {
                                        ref: "auditTab",
                                        attrs: { campaign_id: _vm.campaign.id },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                      ],
                      2
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("form-height-indicator"),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "fixed-footer padding pb-0" }, [
              _c("div", { staticClass: "form-group row mb-0 mt-0" }, [
                _vm.disableOperationButtons
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "col-12 d-none d-md-block p-2 edit-operations",
                      },
                      [
                        _vm.canGoBack
                          ? _c(
                              "el-button",
                              {
                                staticClass: "pull-left",
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.goBack.apply(null, arguments)
                                  },
                                },
                              },
                              [
                                _c("i", { staticClass: "fa fa-arrow-left" }),
                                _vm._v(
                                  "\n                        Back\n                    "
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.canUpdateCampaign
                          ? _c(
                              "el-button",
                              {
                                staticClass: "pull-right ml-0",
                                attrs: { type: "success" },
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.submitForm("campaign_settings")
                                  },
                                },
                              },
                              [
                                _c(
                                  "i",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.loading,
                                        expression: "loading",
                                      },
                                    ],
                                    staticClass:
                                      "material-icons loader pull-left",
                                  },
                                  [_vm._v("")]
                                ),
                                _vm._v(
                                  "\n                        Save\n                    "
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.hasPermissionTo("toggle active status campaign")
                          ? _c(
                              "button",
                              {
                                staticClass: "btn el-btn pull-right mr-2",
                                class:
                                  _vm.campaign.active == true
                                    ? "deep-orange-500"
                                    : "light-blue-500",
                                attrs: { disabled: _vm.loading_btn },
                                on: { click: _vm.toggleActive },
                              },
                              [
                                _vm.loading_btn
                                  ? _c("i", {
                                      staticClass: "fa fa-spin fa-spinner",
                                    })
                                  : [
                                      _vm.campaign.active == true
                                        ? _c("i", {
                                            staticClass: "fa fa-pause",
                                          })
                                        : _c("i", {
                                            staticClass: "fa fa-play",
                                          }),
                                    ],
                                _vm._v(
                                  "\n                        " +
                                    _vm._s(
                                      _vm.campaign.active == true
                                        ? "Pause"
                                        : "Activate"
                                    ) +
                                    "\n                    "
                                ),
                              ],
                              2
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.hasPermissionTo("archive campaign")
                          ? _c(
                              "button",
                              {
                                staticClass:
                                  "btn el-btn pull-right danger mr-2",
                                attrs: { disabled: _vm.loading_delete },
                                on: {
                                  click: function ($event) {
                                    return _vm.deleteCampaignRemote()
                                  },
                                },
                              },
                              [
                                _c(
                                  "i",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.loading_delete,
                                        expression: "loading_delete",
                                      },
                                    ],
                                    staticClass:
                                      "material-icons loader pull-left",
                                  },
                                  [_vm._v("")]
                                ),
                                _vm._v(" "),
                                _c("i", {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: !_vm.loading_delete,
                                      expression: "!loading_delete",
                                    },
                                  ],
                                  staticClass: "fa fa-trash",
                                }),
                                _vm._v(
                                  "\n                        Delete\n                    "
                                ),
                              ]
                            )
                          : _vm._e(),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.disableOperationButtons
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "col-12 d-sm-block d-md-none p-2 edit-operations",
                      },
                      [
                        _vm.canGoBack
                          ? _c(
                              "el-button",
                              {
                                staticClass: "pull-left",
                                attrs: { size: "mini" },
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.goBack.apply(null, arguments)
                                  },
                                },
                              },
                              [_c("i", { staticClass: "fa fa-arrow-left" })]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.canUpdateCampaign
                          ? _c(
                              "el-button",
                              {
                                staticClass: "pull-right",
                                attrs: {
                                  size: "mini",
                                  type: "success",
                                  disabled: _vm.loading || !_vm.validated,
                                },
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.submitForm("campaign_settings")
                                  },
                                },
                              },
                              [
                                _c(
                                  "i",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.loading,
                                        expression: "loading",
                                      },
                                    ],
                                    staticClass:
                                      "material-icons loader pull-left",
                                  },
                                  [_vm._v("")]
                                ),
                                _vm._v(
                                  "\n                        Save\n                    "
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "el-popover",
                          {
                            attrs: {
                              placement: "top",
                              width: "100%",
                              trigger: "click",
                            },
                          },
                          [
                            _vm.hasPermissionTo("toggle active status campaign")
                              ? _c(
                                  "el-button",
                                  {
                                    directives: [
                                      {
                                        name: "popover",
                                        rawName:
                                          "v-popover:dialog-pause-popover",
                                        arg: "dialog-pause-popover",
                                      },
                                    ],
                                    staticClass: "pull-right ml-2",
                                    attrs: {
                                      size: "mini",
                                      type:
                                        _vm.campaign.active == true
                                          ? "deep-orange-500"
                                          : "light-blue-500",
                                      disabled: _vm.loading_btn,
                                    },
                                    on: { click: _vm.toggleActive },
                                  },
                                  [
                                    _vm.loading_btn
                                      ? _c(
                                          "i",
                                          {
                                            staticClass:
                                              "material-icons loader pull-left",
                                          },
                                          [_vm._v("")]
                                        )
                                      : [
                                          _vm.campaign.active == true
                                            ? _c(
                                                "i",
                                                {
                                                  staticClass:
                                                    "material-icons pull-left",
                                                },
                                                [_vm._v("pause")]
                                              )
                                            : _c(
                                                "i",
                                                {
                                                  staticClass:
                                                    "material-icons pull-left",
                                                },
                                                [_vm._v("autorenew")]
                                              ),
                                        ],
                                    _vm._v(
                                      "\n                            " +
                                        _vm._s(
                                          _vm.campaign.active == true
                                            ? "Pause"
                                            : "Activate"
                                        ) +
                                        "\n                        "
                                    ),
                                  ],
                                  2
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.hasPermissionTo("archive campaign")
                              ? _c(
                                  "el-button",
                                  {
                                    staticClass: "pull-right ml-0",
                                    attrs: {
                                      type: "danger",
                                      size: "mini",
                                      disabled: _vm.loading_delete,
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.deleteCampaignRemote()
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "i",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: _vm.loading_delete,
                                            expression: "loading_delete",
                                          },
                                        ],
                                        staticClass:
                                          "material-icons loader pull-left",
                                      },
                                      [_vm._v("")]
                                    ),
                                    _vm._v(" "),
                                    _c("i", {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: !_vm.loading_delete,
                                          expression: "!loading_delete",
                                        },
                                      ],
                                      staticClass: "fa fa-trash",
                                    }),
                                    _vm._v(
                                      "\n                            Delete\n                        "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c("el-button", {
                              staticClass: "pull-right",
                              attrs: {
                                slot: "reference",
                                size: "mini",
                                type: "primary",
                                icon: "el-icon-more-outline",
                                plain: "",
                              },
                              slot: "reference",
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ]),
            ]),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "button",
      { staticClass: "btn btn-md pull-left ml-2 text-bluish" },
      [
        _c("i", { staticClass: "material-icons text-lg" }, [_vm._v("help")]),
        _vm._v(
          "\n                            Tutorial\n                        "
        ),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }