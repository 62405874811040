var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm._m(0),
    _vm._v(" "),
    _c("div", { staticClass: "col-12 mt-2" }, [
      _c("p", { staticClass: "mb-0 font-weight-bold" }, [_vm._v("Type:")]),
      _vm._v(" "),
      _c(
        "div",
        [
          _c(
            "el-radio-group",
            {
              model: {
                value: _vm.nodeData.type,
                callback: function ($$v) {
                  _vm.$set(_vm.nodeData, "type", $$v)
                },
                expression: "nodeData.type",
              },
            },
            [
              _c(
                "el-radio-button",
                { attrs: { label: _vm.ESCALATION_TYPE_SEQUENCE } },
                [_vm._v("Sequence")]
              ),
              _vm._v(" "),
              _c(
                "el-radio-button",
                { attrs: { label: _vm.ESCALATION_TYPE_SMS } },
                [_vm._v("SMS")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]),
    _vm._v(" "),
    _vm.nodeData.type === _vm.ESCALATION_TYPE_SEQUENCE
      ? _c(
          "div",
          { staticClass: "col-12 mt-4" },
          [
            _c("p", { staticClass: "mb-0 font-weight-bold" }, [
              _vm._v("Select a Workflow:"),
            ]),
            _vm._v(" "),
            _c(
              "el-select",
              {
                attrs: { filterable: "", placeholder: "Sequence" },
                model: {
                  value: _vm.nodeData.id,
                  callback: function ($$v) {
                    _vm.$set(_vm.nodeData, "id", $$v)
                  },
                  expression: "nodeData.id",
                },
              },
              _vm._l(_vm.workflows, function (workflow) {
                return _c("el-option", {
                  key: workflow.id,
                  attrs: { label: workflow.name, value: workflow.id },
                })
              }),
              1
            ),
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.nodeData.type === _vm.ESCALATION_TYPE_SMS
      ? _c(
          "div",
          { staticClass: "col-12 mt-4" },
          [
            _c("p", { staticClass: "mb-0 font-weight-bold" }, [
              _vm._v("Numbers:"),
            ]),
            _vm._v(" "),
            _vm._l(_vm.nodeData.numbers, function (number) {
              return _c(
                "el-tag",
                {
                  key: `${number}`,
                  staticClass: "font-weight-bold",
                  attrs: { closable: "", "disable-transitions": false },
                  on: {
                    close: function ($event) {
                      return _vm.handleClose(number)
                    },
                  },
                },
                [_vm._v("\n            " + _vm._s(number) + "\n        ")]
              )
            }),
            _vm._v(" "),
            _vm.inputVisible
              ? _c("el-input", {
                  ref: "saveTagInput",
                  staticClass: "input-new-tag",
                  attrs: { size: "mini" },
                  on: { blur: _vm.handleInputConfirm },
                  nativeOn: {
                    keyup: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      )
                        return null
                      return _vm.handleInputConfirm.apply(null, arguments)
                    },
                  },
                  model: {
                    value: _vm.inputValue,
                    callback: function ($$v) {
                      _vm.inputValue = $$v
                    },
                    expression: "inputValue",
                  },
                })
              : _c(
                  "el-button",
                  {
                    staticClass: "button-new-tag",
                    attrs: { size: "small" },
                    on: { click: _vm.showInput },
                  },
                  [_vm._v("\n            + Add Number\n        ")]
                ),
          ],
          2
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.nodeData.type === _vm.ESCALATION_TYPE_SMS
      ? _c(
          "div",
          { staticClass: "col-12 mt-4" },
          [
            _c("p", { staticClass: "mb-0 font-weight-bold" }, [
              _vm._v("Message:"),
            ]),
            _vm._v(" "),
            _c("el-input", {
              attrs: {
                type: "textarea",
                placeholder: "Type the escalation message here.",
                rows: 3,
              },
              model: {
                value: _vm.nodeData.message,
                callback: function ($$v) {
                  _vm.$set(_vm.nodeData, "message", $$v)
                },
                expression: "nodeData.message",
              },
            }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-12 text-right" },
              [
                _c(
                  "el-tooltip",
                  { attrs: { content: "Add variable", placement: "top" } },
                  [
                    _c("Variables", {
                      on: { "variable-selected": _vm.variableSelected },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-12" }, [
      _c("p", { staticClass: "font-weight-bold side-panel-title" }, [
        _vm._v("Escalation Panel"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }