var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-popover",
    { staticClass: "h-100", attrs: { placement: "top-start", width: "520" } },
    [
      _c(
        "div",
        { staticStyle: { height: "450px" } },
        [
          _vm.show_search_giphy
            ? _c("search-giphy", { on: { selected: _vm.gifSelected } })
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-button",
        {
          staticClass: "border-0 bg-transparent",
          staticStyle: { padding: "3px 5px" },
          attrs: {
            slot: "reference",
            id: "gif-popover-trigger",
            size: "mini",
            circle: "",
            disabled: _vm.disabled,
          },
          on: { click: _vm.mountSearchGiphy },
          slot: "reference",
        },
        [
          _c(
            "i",
            {
              staticClass: "material-icons",
              staticStyle: { "font-size": "2rem" },
            },
            [_vm._v("gif")]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }