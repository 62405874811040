<template>
    <el-popover placement="top-start"
                width="400"
                trigger="click"
                class="p-0"
                data-testid="attachment-popover"
                popper-class="variable-popover">
        <el-upload ref="attachment_upload"
                   data-testid="attachment-upload"
                   :action="'/api/v1/uploaded-files/' + campaign_id"
                   :headers="headers"
                   :on-success="fileUploaded"
                   :on-error="uploadFailed"
                   :file-list="media.files"
                   :show-file-list="false"
                   :before-upload="beforeFileUpload"
                   multiple
                   drag>
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">
                Drop file here or <em>click to upload</em>
            </div>
            <div class="el-upload__tip p-3 break-word"
                 slot="tip">
                We support various media file types.
                The file size limit for images is 5MB, and for the other file types, it should be below 600KB for carriers to accept it.
            </div>
        </el-upload>
        <el-button id="attachment-popover-trigger"
                   data-testid="attachment-popover-trigger"
                   class="border-0 bg-transparent"
                   slot="reference"
                   size="mini"
                   :disabled="disabled"
                   circle>
            <i class="fa fa-paperclip"></i>
        </el-button>
    </el-popover>
</template>

<script>
import _ from "lodash"
import {contact_activities_mixin} from '../../mixins'

export default {
    name: "attachments",

    props: {
        campaign_id: {
            required: true
        },

        disabled: {
            required: false,
            default: false
        }
    },

    data() {
        return {
            files: [],
            max_size_in_kb: 1600
        }
    },

    mixins: [
        contact_activities_mixin
    ],

    methods: {
        fileUploaded(res) {
            this.$emit('file-uploaded', res.uploaded_file)
            this.files = this.files.filter(name => name !== res.uploaded_file.original_file)
            this.checkFiles()
        },

        uploadFailed(err, file) {
            this.$root.handleUploadErrors(err.message)
            this.files = this.files.filter(name => name !== file.name)
            this.checkFiles()
        },

        checkFiles() {
            if (this.files.length === 0) {
                this.closeUploader()
            }
        },

        closeUploader() {
            this.files = []

            // Check reference
            if (this.$refs.attachment_upload) {
                this.$refs.attachment_upload.clearFiles()
            }

            this.$el.querySelector('#attachment-popover-trigger').click()
        },

        beforeFileUpload(file) {
            const max_size_in_bytes = this.max_size_in_kb * 1024;

            if (file.size > max_size_in_bytes) {
                this.$notify.error({
                    title: 'Error',
                    message: `- The file you are trying to upload is too large. Please upload files smaller than ${this.max_size_in_kb} KB.`,
                });

                return false
            }

            return true
        }
    }
}
</script>
