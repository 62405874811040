var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "app-body max-height no-footer" }, [
    _c("div", { staticClass: "h-v no-header" }, [
      _c(
        "div",
        {
          staticClass: "box row-col no-shadow",
          staticStyle: { "min-height": "100%" },
        },
        [
          _vm.hasPermissionTo("list campaign") && _vm.selected_contact
            ? _c("div", { staticClass: "row p-2" }, [
                _c("div", { staticClass: "col-12" }, [
                  _c("div", { staticClass: "pull-left no-select" }, [
                    _vm.selected_contact.unread_count !== 0
                      ? _c(
                          "button",
                          {
                            staticClass: "btn btn-xs blue text-xs",
                            attrs: { disabled: _vm.loading_mark_as_read },
                            on: { click: _vm.markAllAsRead },
                          },
                          [
                            _c(
                              "i",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.loading_mark_as_read,
                                    expression: "loading_mark_as_read",
                                  },
                                ],
                                staticClass: "material-icons loader",
                              },
                              [
                                _vm._v(
                                  "\n                                \n                            "
                                ),
                              ]
                            ),
                            _vm._v(
                              "\n                            Mark All As Read\n                        "
                            ),
                          ]
                        )
                      : _vm._e(),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "pull-right no-select" },
                    [
                      _c("strong", { staticClass: "text-xs" }, [
                        _vm._v("Phone Number:"),
                      ]),
                      _vm._v(" "),
                      _c(
                        "el-select",
                        {
                          staticClass: "no-select",
                          staticStyle: { width: "150px" },
                          attrs: {
                            placeholder: "Select a phone number",
                            size: "mini",
                          },
                          on: { change: _vm.changeSelectedPhoneNumber },
                          model: {
                            value: _vm.selected_phone_number,
                            callback: function ($$v) {
                              _vm.selected_phone_number = $$v
                            },
                            expression: "selected_phone_number",
                          },
                        },
                        _vm._l(
                          _vm.contact_phone_numbers,
                          function (phone_number) {
                            return _c(
                              "el-option",
                              {
                                key: phone_number.id,
                                attrs: {
                                  label: phone_number.phone_number,
                                  value: phone_number.phone_number,
                                },
                              },
                              [
                                _c("span", { staticClass: "pull-left" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("fixPhone")(
                                        phone_number.phone_number
                                      )
                                    )
                                  ),
                                ]),
                              ]
                            )
                          }
                        ),
                        1
                      ),
                    ],
                    1
                  ),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "row-row" }, [
            _c("div", { staticClass: "row-body" }, [
              _vm.selected_contact && !_vm.loading_contact_communications
                ? _c(
                    "div",
                    {
                      ref: "activitiesWrap",
                      staticClass: "row-inner pl-2 pr-2",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "p-a-sm" },
                        [
                          _c(
                            "ul",
                            { staticClass: "timeline timeline-center" },
                            [
                              _vm.has_more_communications
                                ? _c(
                                    "li",
                                    { staticClass: "tl-header mb-3" },
                                    [
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            type: "primary",
                                            size: "medium",
                                            loading:
                                              _vm.is_loading_previous_activities,
                                            plain: "",
                                          },
                                          on: {
                                            click:
                                              _vm.loadMorePreviousActivities,
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                                        Previous Activities\n                                    "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ]
                          ),
                          _vm._v(" "),
                          _vm._l(
                            _vm.filteredCommunications,
                            function (communication, index) {
                              return _c("activity", {
                                key: communication.id + "-comm-" + index,
                                ref:
                                  (communication.type !== undefined
                                    ? "communication-"
                                    : "contact-audit-") + communication.id,
                                refInFor: true,
                                attrs: {
                                  communication: communication,
                                  contact: _vm.selected_contact,
                                  is_widget: true,
                                },
                                on: { unread: _vm.increaseUnreadCount },
                              })
                            }
                          ),
                          _vm._v(" "),
                          _vm.filteredCommunications.length == 0
                            ? _c(
                                "div",
                                { staticClass: "el-table__empty-block" },
                                [
                                  _vm.selected_contact
                                    ? _c(
                                        "span",
                                        {
                                          staticClass: "el-table__empty-text",
                                          staticStyle: { color: "#606266" },
                                        },
                                        [
                                          _vm._v(
                                            "\n                                    No Data\n                                "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]
                              )
                            : _vm._e(),
                        ],
                        2
                      ),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.loading_contact || _vm.loading_contact_communications
                ? _c(
                    "div",
                    {
                      staticClass:
                        "d-flex justify-content-center align-items-center p-a-sm h-100",
                    },
                    [_vm._m(0)]
                  )
                : _vm._e(),
            ]),
          ]),
          _vm._v(" "),
          _vm.contact_loaded
            ? _c("message-composer", {
                ref: "message_composer",
                attrs: {
                  phone_numbers: _vm.contact_phone_numbers,
                  api_key: _vm.api_key,
                },
                on: {
                  "send-message": _vm.sendNewMessage,
                  "create-scheduled-message": _vm.createScheduledMessage,
                },
                model: {
                  value: _vm.message_object,
                  callback: function ($$v) {
                    _vm.message_object = $$v
                  },
                  expression: "message_object",
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "el-table__empty-block" }, [
      _c(
        "span",
        {
          staticClass: "el-table__empty-text",
          staticStyle: { color: "#606266" },
        },
        [
          _vm._v("\n                                Loading "),
          _c("span", { staticClass: "loader__dot" }, [_vm._v(".")]),
          _c("span", { staticClass: "loader__dot" }, [_vm._v(".")]),
          _c("span", { staticClass: "loader__dot" }, [_vm._v(".")]),
        ]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }