var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          staticClass: "dialog-padding auto",
          attrs: {
            title: "Send an Email",
            width: _vm.isLargeEnough ? "500px" : "100%",
            visible: _vm.hide_add,
            "before-close": _vm.beforeCloseModal,
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.hide_add = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "send_email",
              staticClass: "p-0",
              attrs: {
                "label-position": "top",
                rules: _vm.rules_send_email,
                model: _vm.send_email,
                "data-testid": "send-email-form",
              },
              nativeOn: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.sendEmail(_vm.contact_id)
                },
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { prop: "subject", label: "Subject" } },
                [
                  _c("el-input", {
                    ref: "subject",
                    attrs: { "data-testid": "subject-email-input" },
                    on: {
                      input: function ($event) {
                        return _vm.preValidateForm("send_email")
                      },
                    },
                    model: {
                      value: _vm.send_email.subject,
                      callback: function ($$v) {
                        _vm.$set(_vm.send_email, "subject", $$v)
                      },
                      expression: "send_email.subject",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { prop: "message", label: "Message Body" } },
                [
                  _c("el-input", {
                    attrs: {
                      type: "textarea",
                      rows: "4",
                      "data-testid": "message-email-input",
                    },
                    on: {
                      input: function ($event) {
                        return _vm.preValidateForm("send_email")
                      },
                    },
                    model: {
                      value: _vm.send_email.message,
                      callback: function ($$v) {
                        _vm.$set(_vm.send_email, "message", $$v)
                      },
                      expression: "send_email.message",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "row pb-3" }, [
            _c("div", { staticClass: "col-8" }, [
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col-6" },
                  [_c("variable-dialog", { attrs: { in_input_group: false } })],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-6" },
                  [_c("template-list-dialog")],
                  1
                ),
              ]),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-4" },
              [
                _c(
                  "el-button",
                  {
                    staticClass: "pull-right ml-2",
                    attrs: {
                      type: "success",
                      disabled: _vm.loading_btn || !_vm.validated,
                      "data-testid": "send-email-button",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.sendEmail(_vm.contact_id)
                      },
                    },
                  },
                  [
                    _c(
                      "i",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.loading_btn,
                            expression: "loading_btn",
                          },
                        ],
                        staticClass: "material-icons loader",
                      },
                      [_vm._v("")]
                    ),
                    _vm._v(" "),
                    _c(
                      "i",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.loading_btn,
                            expression: "!loading_btn",
                          },
                        ],
                        staticClass: "material-icons",
                      },
                      [
                        _vm._v(
                          "\n                        send\n                    "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("span", [_vm._v("Send")]),
                  ]
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }