<template>
    <div class="app-body" id="view">
        <Chatbots />
    </div>
</template>

<script>
import Chatbots from '../../components/chatbots/chatbots.vue'
import auth from '../../auth.js'

export default {
    components: {
        Chatbots
    },
    beforeRouteEnter(to, from, next) {
        // This logic help us wrap the Chatbots section with the Aloware App components.
        if (to.query.api_token) {
            return next({
                name: 'Login',
                query: {
                    api_token: to.query.api_token
                }
            })
        }

        auth.check().then((res) => {
            if (res.data.user.is_reseller) {
                // redirect to account management portal if the company is a reseller
                next({name: 'Account Management Portal'})
            } else {
                next()
            }
        }).catch((err) => {
            next({
                name: 'Login',
                query: {
                    redirect: to.fullPath
                }
            })
        })
    }
}
</script>
