var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("el-popover", {
    ref: _vm.ref,
    staticClass: "custom-popover",
    attrs: {
      placement: _vm.placement,
      width: "200",
      trigger: _vm.triggers,
      content: _vm.customMessage,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }