<template>
    <div class="message">
        <div
            class="d-flex flex-column"
            :class="[ communication.direction === CommunicationDirection.INBOUND ? 'align-items-start' : 'align-items-end text-right' ]">

            <el-badge
                class="item px-2 d-flex flex-column"
                :class="[communication.direction == CommunicationDirection.INBOUND ? 'align-items-start' : 'align-items-end']">
                <div
                    v-if="communication.body"    
                    class="inline r-2x effect7 mt-1"
                    :class="getCommunicationClass">

                    <span class="arrow pull-top"
                            :class="[ communication.direction === CommunicationDirection.INBOUND ? 'arrow-dker left' : 'arrow-dker right' ]">
                    </span>

                    <div class="p-a p-y-sm handle-whitespace">
                        <span v-linkified:options="{ target: '_blank' }">{{ communication.body }}</span>
                    </div>

                </div>
            </el-badge>
        </div>
    </div>
</template>

<script>
import * as CommunicationDirection from '../../../constants/communication-direction'
export default {
    props: {
        communication: {
            type: Object,
            required: true
        }
    },
    data () {
        return {
            CommunicationDirection
        }
    },
    computed: {
        /**
         * Styles the chat bubble depending on the communication direction.
         * 
         * @return {string}
         */
        getCommunicationClass() {
            if (this.communication.direction === CommunicationDirection.INBOUND) {
                return 'dker'
            }

            if (this.communication.direction === CommunicationDirection.OUTBOUND) {
                return 'blue-800 text-left'
            }
        }
    }
}
</script>
