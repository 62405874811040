import Vue from 'vue'
import store from './store'
import numeral from 'numeral'
import numFormat from 'vue-filter-number-format'
import googlePhone from 'google-libphonenumber'
import * as LrnTypes from './constants/lrn-types'
import * as CampaignCallRouterBehavior from './constants/campaign-call-router-behaviors'
import * as CommunicationCurrentStatus from './constants/communication-current-status'
import * as CommunicationResolution from './constants/communication-resolution'
import * as CommunicationDispositionStatus from './constants/communication-disposition-status'
import * as RingGroupTextAssignModes from './constants/ring-group-text-assign-mode'
import * as AgentStatus from './constants/agent-status'
import * as WorkflowCurrentStatus from './constants/workflow-current-status'
import * as SequenceTypes from './constants/sequence-types'
import * as CommunicationTransferTypes from './constants/communication-transfer-types'
import * as CallbackStatus from './constants/callback-status'
import * as TagCategory from './constants/tag-categories'
import * as CreatorTypes from './constants/creator-types'
import * as CommunicationQueueResolution from './constants/communication-queue-resolution'
import moment from 'moment'

// date / time / duration related
Vue.filter('fixCommunicationDateTime', (dt, duration = 0) => {
    if (!dt) {
        return '-'
    }

    if (window.timezone) {
        if (window.timezone === 'Asia/Manila') {
            return moment.utc(dt).tz(window.timezone).add(duration, 'seconds').format('MM/DD h:mm:ss a') + ' MNL'
        }

        return moment.utc(dt).tz(window.timezone).add(duration, 'seconds').format('MM/DD h:mm:ss a z')
    } else {
        return moment.utc(dt).local().add(duration, 'seconds').format('MM/DD h:mm:ss a z')
    }
})
Vue.filter('fixDateTime', (dt, duration = 0) => {
    if (!dt) {
        return '-'
    }

    if (store.state.current_timezone === 'Asia/Manila') {
        return `${moment.utc(dt).tz(store.state.current_timezone).format('MMM D, YYYY h:mm:ss a')} MNL`
    }

    return moment.utc(dt).tz(store.state.current_timezone).format('MMM D, YYYY h:mm:ss a z')
})
Vue.filter('fixDateTimeFull', (dt) => {
    if (!dt) {
        return '-'
    }

    if (window.timezone) {
        if (window.timezone === 'Asia/Manila') {
            return moment.utc(dt).tz(window.timezone).format('MMM D, YYYY h:mm:ss a') + ' MNL'
        }

        return moment.utc(dt).tz(window.timezone).format('MMM D, YYYY h:mm:ss a z')
    } else {
        return moment.utc(dt).local().format('MMM D, YYYY h:mm:ss a z')
    }
})
Vue.filter('fixDateTimeCompanyTimeZone', (dt) => {
    if (!dt) {
        return '-'
    }

    if (store.state.current_timezone === 'Asia/Manila') {
        return `${moment.utc(dt).tz(store.state.current_timezone, true).format('MMM D, YYYY h:mm:ss a')} MNL`
    }

    return moment.utc(dt).tz(store.state.current_timezone, true).format('MMM D, YYYY h:mm:ss a z')
})
Vue.filter('fixTime', (dt, format = 'h:mma') => {
    if (!dt) {
        return '-'
    }

    if (window.timezone) {
        return moment.utc(dt, 'HH:mm:ss').tz(window.timezone).format(format)
    } else {
        return moment.utc(dt, 'HH:mm:ss').local().format(format)
    }
})
Vue.filter('fixTimeLocal', (dt, format = 'h:mma') => {
    if (!dt) {
        return '-'
    }

    if (window.timezone) {
        return moment(dt, 'HH:mm:ss').format(format)
    } else {
        return moment(dt, 'HH:mm:ss').format(format)
    }
})
Vue.filter('fixDuration', (duration) => {
    if (duration !== undefined) {
        return moment.duration(duration, 'seconds').format('m:ss', {
            trim: false
        })
    } else {
        return '-'
    }
})
Vue.filter('fixLongDuration', (duration) => {
    if (duration !== undefined) {
        if (moment.duration(duration, 'seconds').hours() >= 1) {
            return moment.duration(duration, 'seconds').format('HH:mm:ss', {
                trim: false
            })
        } else {
            return moment.duration(duration, 'seconds').format('mm:ss', {
                trim: false
            })
        }
    } else {
        return '-'
    }
})
Vue.filter('fixFullLongDuration', (duration) => {
    if (duration !== undefined) {
        return moment.duration(duration, 'seconds').format('HH:mm:ss', {
            trim: false
        })
    } else {
        return '-'
    }
})
Vue.filter('fixFullDuration', (duration, should_display_seconds = true) => {
    if (duration !== undefined) {
        let seconds = duration
        let hour = Math.floor(seconds / 3600)
        let min = Math.floor(seconds / 60 % 60)
        let sec = Math.floor(seconds % 60)

        if (hour) {
            if (should_display_seconds) {
                return `${hour}h ${min}m ${sec}s`
            }
            return `${hour}h ${min}m`
        }

        if (!hour && min) {
            if (should_display_seconds) {
                return `${min}m ${sec}s`
            }
            return `${min}m`
        }

        return `${sec}s`
    } else {
        return '-'
    }
})
Vue.filter('fixFullDateUTC', (dt) => {
    if (dt) {
        if (window.timezone) {
            if (window.timezone === 'Asia/Manila') {
                return moment.utc(dt).tz(window.timezone).format('MMM D, YYYY') + ' MNL'
            }

            return moment.utc(dt).tz(window.timezone).format('MMM D, YYYY z')
        } else {
            return moment.utc(dt).local().format('MMM D, YYYY z')
        }
    } else {
        return '-'
    }
})
Vue.filter('fixFullDateLocal', (dt) => {
    if (dt && store.state.cache.current_company) {
        let timezone = store.state.cache.current_company.timezone
        if (timezone) {
            return moment.tz(dt, timezone).format('MMM D, YYYY z')
        }
        return moment(dt).utc().local().format('MMM D, YYYY z')
    }
    return '-'
})
Vue.filter('fixFullDateTimeLocal', (dt) => {
    if (!dt) {
        return '-'
    }

    if (window.timezone) {
        return moment(dt).utc().tz(window.timezone).format('MMM D, YYYY h:mma z')
    }

    return moment(dt).utc().local().format('MMM D, YYYY h:mma z')
})
Vue.filter('fixFullDateUTCRelative', (dt) => {
    if (dt && store.state.current_timezone) {
        let now = moment.utc()
        let datetime = moment.utc(dt)

        if (now.diff(datetime) < 24 * 60 * 60 * 1000) {
                return datetime.tz(store.state.current_timezone).fromNow()
        } else {
            if (store.state.current_timezone === 'Asia/Manila') {
                return `${moment.utc(dt).tz(store.state.current_timezone).format('MMM D, YYYY h:mma')} MNL`
            }

            return datetime.tz(store.state.current_timezone).format('MMM D, YYYY h:mma z')
        }
    } else {
        return ''
    }
})
Vue.filter('fixShortDateUTCRelative', (dt) => {
    if (dt) {
        let now = moment.utc()
        let datetime = moment.utc(dt)

        if (now.diff(datetime) < 24 * 60 * 60 * 1000) {
            if (window.timezone) {
                return datetime.tz(window.timezone).fromNow()
            } else {
                return datetime.local().fromNow()
            }
        } else {
            if (window.timezone) {
                if (window.timezone === 'Asia/Manila') {
                    return moment.utc(dt).tz(window.timezone).format('MM/DD/YYYY h:mma') + ' MNL'
                }

                return datetime.tz(window.timezone).format('MM/DD/YYYY h:mma z')
            } else {
                return datetime.local().format('MM/DD/YYYY h:mma z')
            }
        }
    } else {
        return ''
    }
})
Vue.filter('fixDurationUTCRelative', (dt) => {
    if (dt) {
        let now = moment.utc()
        let datetime = moment.utc(dt)
        let duration = now.diff(datetime, 'seconds')

        if (moment.duration(duration, 'seconds').hours() >= 1) {
            return moment.duration(duration, 'seconds').format('HH:mm:ss', {
                trim: false
            })
        } else {
            return moment.duration(duration, 'seconds').format('mm:ss', {
                trim: false
            })
        }
    } else {
        return ''
    }
})
Vue.filter('fixFullDayDateUTC', (dt) => {
    // Sunday Jan 3, 2022
    if (dt) {
        if (window.timezone) {
            return moment.utc(dt).tz(window.timezone).format('dddd MMMM Do, YYYY')
        } else {
            return moment.utc(dt).local().format('dddd MMMM Do, YYYY z')
        }
    } else {
        return ''
    }
})
Vue.filter('fixUnit', (type) => {
    switch (type) {
        case 'local_min':
            return 'minute(s)'
        case 'tollfree_min':
            return 'minute(s)'
        case 'intl_call':
            return 'minute(s)'
        case 'transcription':
            return 'minute(s)'
        default:
            return ''
    }
})
Vue.filter('fixWorkflowCurrentStatus', (mode) => {
    switch (mode) {
        case WorkflowCurrentStatus.CURRENT_STATUS_PENDING:
            return 'Pending'
        case WorkflowCurrentStatus.CURRENT_STATUS_POPULATING:
            return 'Populating'
        case WorkflowCurrentStatus.CURRENT_STATUS_RUNNING:
            return 'Running'
        case WorkflowCurrentStatus.CURRENT_STATUS_COMPLETED:
            return 'Completed'
    }
    return '-'
})

Vue.filter('fixCreditInfo', (credit) => {
    if (credit !== undefined) {
        if (credit == 0) {
            return credit + ' (debited from plan)'
        } else {
            return credit
        }
    } else {
        return '-'
    }
})
Vue.filter('fixRounding', (amount, precision = 3, resource = null) => {
    if (amount !== undefined) {
        //in transcription resource because of the price usually needs 6 digit precision
        if (resource && resource == 'Transcription'){
            return parseFloat(amount).toFixed(6)
        }
        return parseFloat(amount).toFixed(precision)
    } else {
        return '-'
    }
})
Vue.filter('toCurrency', (amount) => {
    if ((!!amount && amount !== undefined) || amount === 0) {
        if (amount < 0) {
            return '-$' + Math.abs(amount).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
        }
        return '$' + amount.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    } else {
        return '-'
    }
})
Vue.filter('removeSign', (amount) => {
    if (amount !== undefined) {
        return Math.abs(amount)
    } else {
        return '-'
    }
})

// communication related
Vue.filter('fixPhone', (phoneNumber, format = 'E164', force = false, include_suffix = false) => {
    if (phoneNumber) {
        phoneNumber = phoneNumber.toString()
        phoneNumber = phoneNumber.replace(/\s+/g, '')
        phoneNumber = phoneNumber.replace('#', '')
        phoneNumber = phoneNumber.replace('.', '')

        if (phoneNumber.toLowerCase() === 'restricted') {
            return phoneNumber
        }

        if (phoneNumber.toLowerCase() === 'anonymous') {
            return phoneNumber
        }

        if (phoneNumber.toLowerCase() === 'unknown') {
            return phoneNumber
        }

        // @todo: please remove this when there is a new update on identifying this number
        if (phoneNumber === '+61483904553') {
            return phoneNumber
        }

        if (phoneNumber === '+266696687') {
            return phoneNumber
        }

        if (phoneNumber === '8656696') {
            return phoneNumber
        }

        if (phoneNumber.includes('unhold:')) {
            return phoneNumber
        }

        if (phoneNumber.includes('auto_dial_task:')) {
            return phoneNumber
        }

        if (phoneNumber.includes('call:')) {
            return phoneNumber
        }

        if (phoneNumber.includes('barge:')) {
            return phoneNumber
        }

        if (phoneNumber.includes('whisper:')) {
            return phoneNumber
        }

        if (phoneNumber.includes('hs:')) {
            return phoneNumber
        }

        // sip uri used instead of phone number
        if (phoneNumber.indexOf('@') > -1) {
            return phoneNumber
        }

        // Use substring() and indexOf() functions to remove
        // portion of string after certain character (w => wait)
        let pos = phoneNumber.indexOf('w')
        let suffix = ''
        if (pos !== -1) {
            suffix = phoneNumber.substring(pos, phoneNumber.length - 1).trim()
            phoneNumber = phoneNumber.substring(0, pos).trim()
        }

        if ([5, 6].includes(phoneNumber.toString().length)) {
            // Get an instance of ShortNumberInfo
            const shortInfo = googlePhone.ShortNumberInfo.getInstance()

            try {
                // Parse number with US country code and keep raw input
                const number = window.phoneUtil.parseAndKeepRawInput(phoneNumber.toString(), 'US')

                if (shortInfo.isValidShortNumberForRegion(number, 'US')) {
                    return phoneNumber.toString()
                }
            } catch (err) {
                // Ignore and move on
            }
        }

        let locale = window.guessLocale(phoneNumber)

        if (!locale) {
            return false
        }

        let tel = window.phoneUtil.parse(phoneNumber, locale)

        if (['US', 'CA'].includes(locale) && !force) {
            format = 'NATIONAL'
        }

        let formatted_phone_number

        if (format == 'INTERNATIONAL') {
            formatted_phone_number = phoneUtil.format(tel, PNF.INTERNATIONAL).toString()
        } else if (format == 'E164') {
            formatted_phone_number = phoneUtil.format(tel, PNF.E164).toString()
        } else {
            formatted_phone_number = phoneUtil.format(tel, PNF.NATIONAL).toString()
        }

        // if we have to include suffix
        if (include_suffix) {
            formatted_phone_number = formatted_phone_number + suffix
        }

        return formatted_phone_number
    } else {
        return ''
    }
})
Vue.filter('getCountryCode', (phoneNumber) => {
    if (phoneNumber) {
        phoneNumber = phoneNumber.replace(/\s+/g, '')
        phoneNumber = phoneNumber.replace('#', '')
        phoneNumber = phoneNumber.replace('.', '')

        if (phoneNumber.toLowerCase() === 'restricted') {
            return false
        }

        if (phoneNumber.toLowerCase() === 'anonymous') {
            return false
        }

        if (phoneNumber.toLowerCase() === 'unknown') {
            return false
        }

        if (phoneNumber === '+266696687') {
            return false
        }

        if (phoneNumber === '8656696') {
            return false
        }

        // sip uri used instead of phone number
        if (phoneNumber.indexOf('@') > -1) {
            return false
        }

        // Use substring() and indexOf() functions to remove
        // portion of string after certain character (w => wait)
        let pos = phoneNumber.indexOf('w')
        if (pos !== -1) {
            phoneNumber = phoneNumber.substring(0, pos).trim()
        }

        if (phoneNumber.toString().length <= 9) {
            return false
        }

        let locale = window.guessLocale(phoneNumber)

        if (!locale) {
            return false
        }

        let tel = window.phoneUtil.parse(phoneNumber, locale)
        return tel.getCountryCode()
    } else {
        return ''
    }
})
Vue.filter('fixCommDirection', (direction) => {
    switch (direction) {
        case 1:
            return 'Inbound'
        case 2:
            return 'Outbound'
        default:
            return 'N/A'
    }
})
Vue.filter('fixCommType', (type) => {
    switch (type) {
        case 1:
            return 'Call'
        case 2:
            return 'SMS'
        case 3:
            return 'Conference'
        case 4:
            return 'RVM'
        case 5:
            return 'Email'
        case 8:
            return 'Fax'
        case 10:
            return 'Note'
        case 11:
            return 'Sys Note'
        case 12:
            return 'Appointment'
        case 13:
            return 'Reminder'
        default:
            return 'N/A'
    }
})
Vue.filter('areaCode', (phoneNumber) => {
    let areaCode = /\d{3}/.exec(Vue.options.filters.fixPhone(phoneNumber))
    if (areaCode && areaCode.length > 0) {
        return areaCode[0]
    }

    return null
})
Vue.filter('translateCreatorTypeText', (status) => {
    switch (status) {
        case CreatorTypes.CREATOR_TYPE_MANUAL:
            return 'Manual'
        case CreatorTypes.CREATOR_TYPE_API:
            return 'API'
        case CreatorTypes.CREATOR_TYPE_WORKFLOW:
            return 'Sequence'
        case CreatorTypes.CREATOR_TYPE_BROADCAST:
            return 'Broadcast'
        case CreatorTypes.CREATOR_TYPE_POWER_DIALER:
            return 'Power Dialer'
        case CreatorTypes.CREATOR_TYPE_SMS_REMINDER:
            return 'SMS Reminder'
        case CreatorTypes.CREATOR_TYPE_APP_NOTIFICATIONS:
            return 'App Notification'
        case CreatorTypes.CREATOR_TYPE_HUBSPOT:
            return 'HubSpot Workflow'
        case CreatorTypes.CREATOR_TYPE_ZAPIER:
            return 'Zapier'
        case CreatorTypes.CREATOR_TYPE_ALOHABOT:
            return 'AlohaBot'
        case CreatorTypes.CREATOR_TYPE_GOHIGHLEVEL:
            return 'GoHighLevel'
        case CreatorTypes.CREATOR_TYPE_ALOAI:
            return 'AloAi'
        default:
            return 'N/A'
    }
})

Vue.filter('translateCurrentStatusText', (status) => {
    switch (status) {
        // The call is currently ringing (relative to us)
        case CommunicationCurrentStatus.CURRENT_STATUS_RINGING_NEW:
            return CommunicationCurrentStatus.CURRENT_STATUS_RINGING
        // Caller is hearing the greeting message
        case CommunicationCurrentStatus.CURRENT_STATUS_GREETING_NEW:
            return CommunicationCurrentStatus.CURRENT_STATUS_GREETING
        // the call is in our internal queue.
        case CommunicationCurrentStatus.CURRENT_STATUS_QUEUED_NEW:
            return CommunicationCurrentStatus.CURRENT_STATUS_QUEUED
        // We are attempting to find an agent for this call via conferencing
        case CommunicationCurrentStatus.CURRENT_STATUS_RINGALL_NEW:
            return CommunicationCurrentStatus.CURRENT_STATUS_RINGALL
        // We are dialing the destination
        case CommunicationCurrentStatus.CURRENT_STATUS_TRANSFERRING_NEW:
            return CommunicationCurrentStatus.CURRENT_STATUS_TRANSFERRING
        // the call hit our voicemail
        case CommunicationCurrentStatus.CURRENT_STATUS_VOICEMAIL_NEW:
            return CommunicationCurrentStatus.CURRENT_STATUS_VOICEMAIL
        // Callee picked up and is hearing the whisper message.
        case CommunicationCurrentStatus.CURRENT_STATUS_WHISPERING_NEW:
            return CommunicationCurrentStatus.CURRENT_STATUS_WHISPERING
        // The call was answered and is currently in progress.
        case CommunicationCurrentStatus.CURRENT_STATUS_INPROGRESS_NEW:
            return CommunicationCurrentStatus.CURRENT_STATUS_INPROGRESS
        // The call was answered and has ended normally.
        case CommunicationCurrentStatus.CURRENT_STATUS_COMPLETED_NEW:
            return CommunicationCurrentStatus.CURRENT_STATUS_COMPLETED
        // call held (parked) and caller hearing piano music
        case CommunicationCurrentStatus.CURRENT_STATUS_HOLD_NEW:
            return CommunicationCurrentStatus.CURRENT_STATUS_HOLD
        // A SMS is received by our system.
        case CommunicationCurrentStatus.CURRENT_STATUS_SMS_RECEIVED_NEW:
            return CommunicationCurrentStatus.CURRENT_STATUS_SMS_RECEIVED
        // A SMS is sent to the system.
        case CommunicationCurrentStatus.CURRENT_STATUS_SMS_SENT_NEW:
            return CommunicationCurrentStatus.CURRENT_STATUS_SMS_SENT
        // A SMS is delivered.
        case CommunicationCurrentStatus.CURRENT_STATUS_SMS_DELIVERED_NEW:
            return CommunicationCurrentStatus.CURRENT_STATUS_SMS_DELIVERED
        // A SMS is accepted.
        case CommunicationCurrentStatus.CURRENT_STATUS_SMS_ACCEPTED_NEW:
            return CommunicationCurrentStatus.CURRENT_STATUS_SMS_ACCEPTED
        // A SMS is queued.
        case CommunicationCurrentStatus.CURRENT_STATUS_SMS_QUEUED_NEW:
            return CommunicationCurrentStatus.CURRENT_STATUS_SMS_QUEUED
        // A SMS is sending.
        case CommunicationCurrentStatus.CURRENT_STATUS_SMS_SENDING_NEW:
            return CommunicationCurrentStatus.CURRENT_STATUS_SMS_SENDING
        // A SMS is receiving.
        case CommunicationCurrentStatus.CURRENT_STATUS_SMS_RECEIVING_NEW:
            return CommunicationCurrentStatus.CURRENT_STATUS_SMS_RECEIVING
        // A SMS is undelivered.
        case CommunicationCurrentStatus.CURRENT_STATUS_SMS_UNDELIVERED_NEW:
            return CommunicationCurrentStatus.CURRENT_STATUS_SMS_UNDELIVERED
        // A SMS is failed.
        case CommunicationCurrentStatus.CURRENT_STATUS_SMS_FAILED_NEW:
            return CommunicationCurrentStatus.CURRENT_STATUS_SMS_FAILED
        // A FAX is queued.
        case CommunicationCurrentStatus.CURRENT_STATUS_FAX_QUEUED_NEW:
            return CommunicationCurrentStatus.CURRENT_STATUS_FAX_QUEUED
        // A FAX is processing.
        case CommunicationCurrentStatus.CURRENT_STATUS_FAX_PROCESSING_NEW:
            return CommunicationCurrentStatus.CURRENT_STATUS_FAX_PROCESSING
        // A FAX is sending.
        case CommunicationCurrentStatus.CURRENT_STATUS_FAX_SENDING_NEW:
            return CommunicationCurrentStatus.CURRENT_STATUS_FAX_SENDING
        // A FAX is delivered.
        case CommunicationCurrentStatus.CURRENT_STATUS_FAX_DELIVERED_NEW:
            return CommunicationCurrentStatus.CURRENT_STATUS_FAX_DELIVERED
        // A FAX is receiving.
        case CommunicationCurrentStatus.CURRENT_STATUS_FAX_RECEIVING_NEW:
            return CommunicationCurrentStatus.CURRENT_STATUS_FAX_RECEIVING
        // A FAX is received.
        case CommunicationCurrentStatus.CURRENT_STATUS_FAX_RECEIVED_NEW:
            return CommunicationCurrentStatus.CURRENT_STATUS_FAX_RECEIVED
        // A FAX is no-answer.
        case CommunicationCurrentStatus.CURRENT_STATUS_FAX_NO_ANSWER_NEW:
            return CommunicationCurrentStatus.CURRENT_STATUS_FAX_NO_ANSWER
        // A FAX is busy.
        case CommunicationCurrentStatus.CURRENT_STATUS_FAX_BUSY_NEW:
            return CommunicationCurrentStatus.CURRENT_STATUS_FAX_BUSY
        // A FAX is failed.
        case CommunicationCurrentStatus.CURRENT_STATUS_FAX_FAILED_NEW:
            return CommunicationCurrentStatus.CURRENT_STATUS_FAX_FAILED
        // A FAX is canceled.
        case CommunicationCurrentStatus.CURRENT_STATUS_FAX_CANCELED_NEW:
            return CommunicationCurrentStatus.CURRENT_STATUS_FAX_CANCELED

        default:
            return 'N/A'
    }
})
Vue.filter('translateResolutionText', (status) => {
    switch (status) {
        // Normal call
        case CommunicationResolution.RESOLUTION_NORMAL_NEW:
            return CommunicationResolution.RESOLUTION_NORMAL
        // call that went to the queue and had to wait.
        case CommunicationResolution.RESOLUTION_QUEUE_NEW:
            return CommunicationResolution.RESOLUTION_QUEUE
        // When caller left a voicemail.
        case CommunicationResolution.RESOLUTION_VOICEMAIL_NEW:
            return CommunicationResolution.RESOLUTION_VOICEMAIL
        // When call goes to forwarded phone number
        case CommunicationResolution.RESOLUTION_FORWARD_NEW:
            return CommunicationResolution.RESOLUTION_FORWARD
        // When caller requested a callback
        case CommunicationResolution.RESOLUTION_CALLBACK_REQUESTED_NEW:
            return CommunicationResolution.RESOLUTION_CALLBACK_REQUESTED
        // When we are processing a callback
        case CommunicationResolution.RESOLUTION_CALLBACK_INITIATED_NEW:
            return CommunicationResolution.RESOLUTION_CALLBACK_INITIATED
        // ?
        case CommunicationResolution.RESOLUTION_HUNT_GROUP_NEW:
            return CommunicationResolution.RESOLUTION_HUNT_GROUP
        case CommunicationResolution.RESOLUTION_VOICEMAIL_DROP_NEW:
            return CommunicationResolution.RESOLUTION_VOICEMAIL_DROP
        case CommunicationResolution.RESOLUTION_MAX_WAIT_NEW:
            return CommunicationResolution.RESOLUTION_MAX_WAIT
        case CommunicationResolution.RESOLUTION_MACHINE_DETECTED_NEW:
            return CommunicationResolution.RESOLUTION_MACHINE_DETECTED
        default:
            return 'N/A'
    }
})

Vue.filter('translateQueueResolutionText', (status) => {
    switch (status) {
        // handle by text.
        case CommunicationQueueResolution.QUEUE_RESOLUTION_CALLBACK_REQUESTED:
            return CommunicationQueueResolution.QUEUE_RESOLUTION_TXT_CALLBACK_REQUESTED
        // call that went to the queue and had to wait.
        case CommunicationQueueResolution.QUEUE_RESOLUTION_HANDLE_BY_TEXT:
            return CommunicationQueueResolution.QUEUE_RESOLUTION_TXT_HANDLE_BY_TEXT
        // When caller left a voicemail.
        case CommunicationQueueResolution.QUEUE_RESOLUTION_VOICEMAIL:
            return CommunicationQueueResolution.QUEUE_RESOLUTION_TXT_VOICEMAIL
        default:
            return 'N/A'
    }
})

Vue.filter('translateDispositionStatusText', (status) => {
    switch (status) {
        // Call is still going on.
        case CommunicationDispositionStatus.DISPOSITION_STATUS_INPROGRESS_NEW:
            return CommunicationDispositionStatus.DISPOSITION_STATUS_INPROGRESS
        // call is abandoned without ringing target.
        case CommunicationDispositionStatus.DISPOSITION_STATUS_ABANDONED_NEW:
            return CommunicationDispositionStatus.DISPOSITION_STATUS_ABANDONED
        // target party did not answer the call.
        case CommunicationDispositionStatus.DISPOSITION_STATUS_MISSED_NEW:
            return CommunicationDispositionStatus.DISPOSITION_STATUS_MISSED
        // A successful call (parties talked)
        case CommunicationDispositionStatus.DISPOSITION_STATUS_COMPLETED_NEW:
            return CommunicationDispositionStatus.DISPOSITION_STATUS_COMPLETED
        // The call failed for some reason.
        case CommunicationDispositionStatus.DISPOSITION_STATUS_FAILED_NEW:
            return CommunicationDispositionStatus.DISPOSITION_STATUS_FAILED
        // The call hit a dead end.
        case CommunicationDispositionStatus.DISPOSITION_STATUS_DEADEND_NEW:
            return CommunicationDispositionStatus.DISPOSITION_STATUS_DEADEND
        // communication was rejected from carrier before sending
        case CommunicationDispositionStatus.DISPOSITION_STATUS_INVALID_NEW:
            return CommunicationDispositionStatus.DISPOSITION_STATUS_INVALID
        // virtual status for triggers
        case CommunicationDispositionStatus.DISPOSITION_STATUS_PLACED_NEW:
            return CommunicationDispositionStatus.DISPOSITION_STATUS_PLACED
        // voicemail status
        case CommunicationDispositionStatus.DISPOSITION_STATUS_VOICEMAIL_NEW:
            return CommunicationDispositionStatus.DISPOSITION_STATUS_VOICEMAIL
        // missed + voicemail virtual status
        case CommunicationDispositionStatus.DISPOSITION_STATUS_MISSED_AND_VOICEMAIL_NEW:
            return 'Missed (Including Voicemail)'
        default:
            return 'N/A'
    }
})

Vue.filter('translateTransferTypeText', (transfer_types) => {
    switch (transfer_types) {
        case CommunicationTransferTypes.TRANSFER_TYPE_COLD:
            return "Cold Transfer"
        case CommunicationTransferTypes.TRANSFER_TYPE_WARM:
            return "Warm Transfer"
        case CommunicationTransferTypes.TRANSFER_TYPE_CONF:
            return "Conference"
    }
})

Vue.filter('translateCallbackStatusText', (callback_status) => {
    switch (callback_status) {
        case CallbackStatus.CALLBACK_STATUS_INITIATED:
            return "Callback Initiated"
        case CallbackStatus.CALLBACK_STATUS_REQUESTED:
            return "Callback Pending"
    }
    return '-'
})

Vue.filter('translateTagCategory', (category) => {
    switch (category) {
        case TagCategory.CAT_COMMUNICATIONS:
            return "Communication"
        case TagCategory.CAT_CONTACTS:
            return "Contact"
    }
    return '-'
})

// contact related
Vue.filter('fixDateOfBirth', (date_of_birth) => {
    if (date_of_birth) {
        return date_of_birth
    }

    return ''
})
Vue.filter('fixContactName', (name) => {
    if (name) {
        return name.capitalize()
    }

    return 'No Name'
})
Vue.filter('fixName', (name) => {
    if (name) {
        return name.capitalize()
    }

    return ''
})
Vue.filter('fixCountry', (country) => {
    if (country) {
        return country.capitalize()
    }

    return ''
})
Vue.filter('fixState', (state) => {
    if (state) {
        return state.capitalize()
    }

    return ''
})
Vue.filter('fixCity', (city) => {
    if (city) {
        return city.capitalize()
    }

    return ''
})
Vue.filter('fixZipcode', (zipcode) => {
    if (zipcode) {
        return zipcode
    }

    return ''
})
Vue.filter('fixEmail', (email) => {
    if (email) {
        return email
    }

    return ''
})
Vue.filter('fixTimezone', (timezone) => {
    if (timezone) {
        return timezone
    }

    return ''
})
Vue.filter('fixCompanyName', (companyName) => {
    if (companyName) {
        return companyName.capitalize()
    }

    return ''
})
Vue.filter('fixCustomField', (value) => {
    if (value) {
        return value
    }

    return ''
})
Vue.filter('fixLrnType', (lrn_type) => {
    lrn_type = parseInt(lrn_type)
    // LRN_TYPE_LANDLINE = 0 so it couldn't pass the if statement because 0 != true.
    if (lrn_type !== null) {
        switch (lrn_type) {
            case LrnTypes.LRN_TYPE_LANDLINE:
                return 'Landline'
            case LrnTypes.LRN_TYPE_WIRELESS:
                return 'Wireless'
            case LrnTypes.LRN_TYPE_VOIP:
                return 'Voip'
            case LrnTypes.LRN_TYPE_OTHER:
                return 'Other'
            default:
                return 'Unknown'
        }
    }

    return 'Unknown'
})
Vue.filter('fixSequenceType', (sequence_type) => {
    sequence_type = parseInt(sequence_type)
    if (sequence_type !== null) {
        switch (sequence_type) {
            case SequenceTypes.TYPE_CALL_LEAD:
                return 'Call Lead'
            case SequenceTypes.TYPE_SMS_LEAD:
                return 'Text Lead'
            case SequenceTypes.TYPE_CONFERENCE:
                return 'Conference'
            case SequenceTypes.TYPE_RVM:
                return 'Ringless Voicemail'
            case SequenceTypes.TYPE_MMS:
                return 'MMS'
            case SequenceTypes.TYPE_CALL_RING_GROUP:
                return 'Call Ring Group'
            case SequenceTypes.TYPE_SMS_RING_GROUP:
                return 'Text Ring Group'
            case SequenceTypes.TYPE_WEBHOOK:
                return 'Webhook'
            case SequenceTypes.TYPE_AUTO_DIAL:
                return 'Auto Dial with AMD'
            case SequenceTypes.TYPE_BIRTHDAY:
                return 'Birthday'
            case SequenceTypes.TYPE_ADD_TAG_CONTACT:
                return 'Tag Contact'
            case SequenceTypes.TYPE_CHANGE_CONTACT_OWNER:
                return 'Change Contact Owner'
            case SequenceTypes.TYPE_EMAIL_LEAD:
                return 'Email Lead'
            case SequenceTypes.TYPE_EMAIL_RING_GROUP:
                return 'Email Ring Group'
            case SequenceTypes.TYPE_ADD_TO_POWER_DIALER:
                return 'Add to PowerDialer'
            case SequenceTypes.TYPE_PREDICTIVE_DIAL:
                return 'Predictive Dialer'
            case SequenceTypes.TYPE_REMOVE_TAG_CONTACT:
                return 'Remove Contact Tag'
            case SequenceTypes.TYPE_DISPOSE_CONTACT:
                return 'Dispose Contact'
            case SequenceTypes.TYPE_DNC_CONTACT:
                return 'DNC Contact'
            case SequenceTypes.TYPE_REMOVE_FROM_POWER_DIALER:
                return 'Remove from PowerDialer'
            case SequenceTypes.TYPE_ENROLL_TO_OTHER_WORKFLOW:
                return 'Enroll to other Sequence'
            case SequenceTypes.TYPE_START_ALOAI_CONVERSATION:
                return 'Start AloAi bot Conversation'
        }
    }

    return 'N/A'
})
Vue.filter('fixAgentStatus', (agent_status) => {
    agent_status = parseInt(agent_status)
    if (agent_status !== null) {
        switch (agent_status) {
            case AgentStatus.AGENT_STATUS_OFFLINE:
                return 'Offline'
            case AgentStatus.AGENT_STATUS_ACCEPTING_CALLS:
                return 'Available'
            case AgentStatus.AGENT_STATUS_NOT_ACCEPTING_CALLS:
                return 'Busy'
            case AgentStatus.AGENT_STATUS_ON_BREAK:
                return 'On Break'
            case AgentStatus.AGENT_STATUS_ON_CALL:
                return 'On Call'
            case AgentStatus.AGENT_STATUS_ON_WRAP_UP:
                return 'Wrap-up'
            case AgentStatus.AGENT_STATUS_RINGING:
                return 'Ringing'
            case AgentStatus.AGENT_STATUS_AUTO_DIAL:
                return 'Auto Dial'
            case AgentStatus.AGENT_STATUS_SENTRY:
                return 'Sentry mode'
        }
    }

    return 'N/A'
})
Vue.filter('agentStatusClass', (agent_status) => {
    agent_status = parseInt(agent_status)
    if (agent_status !== null) {
        switch (agent_status) {
            case AgentStatus.AGENT_STATUS_OFFLINE:
                return 'bg-status-offline'
            case AgentStatus.AGENT_STATUS_ACCEPTING_CALLS:
                return 'bg-status-online'
            case AgentStatus.AGENT_STATUS_NOT_ACCEPTING_CALLS:
                return 'bg-status-busy'
            case AgentStatus.AGENT_STATUS_ON_BREAK:
                return 'bg-status-on-break'
            case AgentStatus.AGENT_STATUS_ON_CALL:
                return 'bg-status-on-call'
            case AgentStatus.AGENT_STATUS_ON_WRAP_UP:
                return 'bg-status-wrap-up'
            case AgentStatus.AGENT_STATUS_RINGING:
                return 'bg-status-ringing'
            case AgentStatus.AGENT_STATUS_AUTO_DIAL:
                return 'bg-grey-5'
            case AgentStatus.AGENT_STATUS_SENTRY:
                return 'bg-dark'
            default:
                return 'bg-grey-6'
        }
    }

    return 'bg-grey-6'
})
Vue.filter('fixBooleanType', (val) => {
    return val ? 'Yes' : 'No'
})
// general
Vue.filter('toUpperCase', (text) => {
    return text ? text.toUpperCase() : ''
})
Vue.filter('toLowerCase', (text) => {
    return text ? text.toLowerCase() : ''
})
Vue.filter('capitalize', (text) => {
    return text ? text.capitalize() : ''
})
Vue.filter('initials', (name) => {
    let initials = name.match(/\b\w/g) || []
    initials = ((initials.shift() || '') + (initials.pop() || '')).toUpperCase()

    return initials
})
Vue.filter('humanReadableBool', (boolValue) => {
    if (boolValue) {
        return 'On'
    } else {
        return 'Off'
    }
})
Vue.filter('humanReadableDialMode', (mode) => {
    switch (mode) {
        case 0:
            return 'Simultaneous'
        case 1:
            return 'Random'
        case 2:
            return 'Round-robin'
        case 3:
            return 'Longest Available'
    }
    return '-'
})
Vue.filter('humanReadableTextAssignMode', (mode) => {
    switch (mode) {
        case RingGroupTextAssignModes.TEXT_ASSIGN_MODE_TO_CONTACT_OWNER:
            return 'Assign incoming text messages to the contact owner (default)'
        case RingGroupTextAssignModes.TEXT_ASSIGN_MODE_RANDOM_TO_AGENTS:
            return 'Assign incoming text messages randomly between agents (if contact is not owned)'
        case RingGroupTextAssignModes.TEXT_ASSIGN_MODE_DO_NOTHING:
            return 'Do not assign incoming text messages to agents'
        case RingGroupTextAssignModes.TEXT_ASSIGN_MODE_TO_LAST_CALLED_AGENT:
            return 'Assign incoming text messages to the last agent who called the contact (if contact is not owned)'
        case RingGroupTextAssignModes.TEXT_ASSIGN_MODE_TO_LAST_CALLED_AGENT_REGARDLESS_OWNERSHIP:
            return 'Assign incoming text messages to the last agent who called or messaged the contact, regardless of ownership.'
    }
    return '-'
})
Vue.filter('humanReadableCallRouterBehavior', (mode) => {
    switch (mode) {
        case CampaignCallRouterBehavior.CALL_ROUTER_BEHAVIOR_MODE_FAST_FORWARD:
            return 'Connected to:'
        case CampaignCallRouterBehavior.CALL_ROUTER_BEHAVIOR_MODE_DEAD_END:
            return 'Dead End'
        case CampaignCallRouterBehavior.CALL_ROUTER_BEHAVIOR_MODE_IVR:
            return 'IVR'
        case CampaignCallRouterBehavior.CALL_ROUTER_BEHAVIOR_MODE_FAST_FORWARD_RING_GROUP:
            return 'Connect to Ring Group'
        case CampaignCallRouterBehavior.CALL_ROUTER_BEHAVIOR_MODE_FAST_FORWARD_USER:
            return 'Connect to User'
    }
    return '-'
})
Vue.filter('campaignName', (campaign_id, campaigns) => {
    // find campaign
    const campaign = campaigns.find((campaign) => campaign.id == campaign_id)

    // return name if found
    if (campaign) {
        return campaign.name
    }

    return '-'
})
Vue.filter('incomingNumberVoiceCapabilityContent', (incoming_number_id, campaign) => {
    // find incoming number
    const incoming_number = campaign.incoming_numbers.find((incoming_number) => incoming_number.id == incoming_number_id)

    // return proper value
    if (campaign.is_fax) {
        return 'Fax lines don’t support this capability. Create a new ‘Line’ if you want to use this feature'
    }
    if (incoming_number.is_voice_capable) {
        return 'Send And Receive Call'
    }

    return 'No Call Capability'
})
Vue.filter('incomingNumberSMSCapabilityContent', (incoming_number_id, campaign) => {
    // find incoming number
    const incoming_number = campaign.incoming_numbers.find((incoming_number) => incoming_number.id == incoming_number_id)

    // return proper value
    if (campaign.is_fax) {
        return 'Fax lines don’t support this capability. Create a new ‘Line’ if you want to use this feature'
    }
    if (incoming_number.calling_only) {
        return 'This number is not allowed to Send Text Messages because it’s flagged as Calling Only'
    }
    if (incoming_number.is_sms_capable) {
        return 'Send And Receive SMS'
    }

    return 'No SMS Capability'
})
Vue.filter('incomingNumberMMSCapabilityContent', (incoming_number_id, campaign) => {
    // find incoming number
    const incoming_number = campaign.incoming_numbers.find((incoming_number) => incoming_number.id == incoming_number_id)

    // return proper value
    if (campaign.is_fax) {
        return 'Fax lines don’t support this capability. Create a new ‘Line’ if you want to use this feature'
    }
    if (incoming_number.is_mms_capable) {
        return 'Send And Receive MMS'
    }

    return 'No MMS Capability'
})
Vue.filter('incomingNumberFaxCapabilityContent', (incoming_number_id, campaign) => {
    // find incoming number
    const incoming_number = campaign.incoming_numbers.find((incoming_number) => incoming_number.id == incoming_number_id)

    // return proper value
    if (!campaign.is_fax) {
        return 'Standard lines don’t support Fax capabilities. Create a new ‘Fax Line’ if you want to use fax'
    }
    if (incoming_number.is_fax_capable) {
        return 'Send And Receive Fax'
    }

    return 'No Fax Capability'
})
Vue.filter('dispositionStatusName', (disposition_status_id) => {
    // find dispositionStatus
    const dispositionStatus = store.state.disposition_statuses.find((dispositionStatus) => dispositionStatus.id == disposition_status_id)

    // return name if found
    if (dispositionStatus) {
        return dispositionStatus.name
    }

    return '-'
})
Vue.filter('dispositionStatusColor', (disposition_status_id) => {
    // find dispositionStatus
    const dispositionStatus = store.state.disposition_statuses.find((dispositionStatus) => dispositionStatus.id == disposition_status_id)

    // return name if found
    if (dispositionStatus) {
        return dispositionStatus.color
    }

    return '#D3D3D3'
})
Vue.filter('callDispositionName', (call_disposition_id) => {
    // find callDisposition
    const callDisposition = store.state.call_dispositions.find((callDisposition) => callDisposition.id == call_disposition_id)

    // return name if found
    if (callDisposition) {
        return callDisposition.name.capitalize()
    }

    return '-'
})
Vue.filter('callDispositionColor', (call_disposition_id) => {
    // find callDisposition
    const callDisposition = store.state.call_dispositions.find((callDisposition) => callDisposition.id == call_disposition_id)

    // return name if found
    if (callDisposition) {
        return callDisposition.color
    }

    return '#D3D3D3'
})
Vue.filter('lastTags', (tags, count) => {
    if (!tags || tags.length == 0) {
        return
    }

    if (count === undefined) {
        count = tags.length
    }

    if (tags.length < count) {
        return tags.slice().reverse()
    }

    return tags.slice(-1 * count)
})
Vue.filter('ucfirst', (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1)
})
Vue.filter('checkIfEmpty', (value) => {
    if (!value) {
        return '-'
    } else {
        return value
    }
})
Vue.filter('checkIfTrue', (value) => {
    if (!value) {
        return '<i class="material-icons">&#xE876;</i>'
    } else {
        return '<i class="material-icons">&#xE14C;</i>'
    }
})
Vue.filter('prettifyCamelCase', (value) => {
    if (value === 'SMTP') {
        return value
    }

    let output = ''
    let len = value.length
    let char

    for (let i = 0; i < len; i++) {
        char = value.charAt(i)

        if (i == 0) {
            output += char.toUpperCase()
        } else if (char !== char.toLowerCase() && char === char.toUpperCase()) {
            output += ' ' + char
        } else if (char == '-' || char == '_') {
            output += ' '
        } else {
            output += char
        }
    }

    return output
})
Vue.filter('ucwords', (value) => {
    if (value) {
        return (value + '')
            .replace(/^(.)|\s+(.)/g, function ($1) {
                return $1.toUpperCase()
            })
    } else {
        return '-'
    }
})
Vue.filter('filterDomain', (email) => {
    if (email) {
        return email.replace(/.*@/, '')
    } else {
        return '-'
    }
})
Vue.filter('fixCompanyRole', (label) => {
    if (label) {
        return label.replace('Company ', '')
    } else {
        return '-'
    }
})
Vue.filter('truncate', function (text, stop, clamp) {
    return text.slice(0, stop) + (stop < text.length ? clamp || '...' : '')
})
Vue.filter('fixHtml', function (html) {
    let div = document.createElement('div');
    div.innerHTML = html
    return (div.innerHTML);
})
Vue.filter('nl2br', (value) => {
    if (!value) {
        return '-'
    } else {
        let breakTag = '<br />';
        return (value + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2');
    }
})
Vue.filter('toInt', (amount) => {
    if (amount !== undefined) {
        return parseInt(amount)
    } else {
        return '-'
    }
})
Vue.filter('fixOrder', (order) => {
    if (order !== '' && order !== undefined) {
        return parseInt(order) + 1
    } else {
        return '-'
    }
})
Vue.filter('pretty', (value) => {
    if (value) {
        return JSON.stringify(value, null, 3)
    } else {
        return ''
    }
})
Vue.filter('removePlus', (value) => {
    if (value) {
        return value.replace('+', '')
    } else {
        return ''
    }
})
Vue.filter('getFirstName', (name) => {
    if (name) {
        return name.split(' ').slice(0, -1).join(' ')
    } else {
        return ''
    }
})
Vue.filter('fixIntegrationName', (name) => {
    if (name) {
        if (name == 'Helpscout') {
            return 'Help Scout'
        }

        if (name == 'Hubspot') {
            return 'HubSpot'
        }

        if (name == 'Gohighlevel') {
            return 'GoHighLevel'
        }

        return name
    } else {
        return ''
    }
})
Vue.filter('replaceDash', (text) => {
    if (text) {
        return text.replace('-', ' ')
    } else {
        return ''
    }
})
Vue.filter('fixDomain', (text) => {
    if (text) {
        if (text.startsWith('https')) {
            text = text.replace('https://', '')
            return `https://${text}`
        } else {
            text = text.replace('http://', '')
            return `http://${text}`
        }
    }

    return ''
})

Vue.filter('momentFormat', (datetime, format, toUserTimezone = false) => {
    if (toUserTimezone) {
        return moment.utc(datetime).tz(window.timezone).format(format)
    }
    return moment(datetime).format(format)
})

Vue.filter('strLimit', (string, limit, appendEllipsis = true) => {
    if (string) {
        return string.slice(0, limit) + (appendEllipsis && string.length > limit ? '...' : '')
    }
    return string;
})

Vue.filter('implodeRingGroups', (ringGroup) => {
    if (ringGroup.length == 0) {
        return '';
    }
    return ringGroup.map(e => e.name).join(",")
})

Vue.filter('numFormat', numFormat(numeral))

Vue.filter('fixDurationHumanize', (datetime) => {
    if (datetime === undefined) {
        return '-'
    }

    let now = window.timezone ? moment.utc(new Date()).tz(window.timezone) : moment.utc(new Date())
    let end = window.timezone ? moment.utc(datetime).tz(window.timezone) : moment.utc(datetime)
    let duration = moment.duration(now.diff(end))
    let as_seconds = duration.asSeconds()

    return moment.duration(as_seconds, "seconds").humanize()
})

Vue.filter('snakeToTitle', (str) => {
    return str.split('_').map((item) => {
        return item.charAt(0).toUpperCase() + item.substring(1)
    }).join(' ')
})

Vue.filter('contactFullName', (object) => {
    if (object.contact_first_name || object.contact_last_name) {
        let result = ''

        if (object.contact_first_name) {
            result += object.contact_first_name + ' '
        }

        if (object.contact_last_name) {
            result += object.contact_last_name + ' '
        }

        return result.trim()
    }

    return 'No Name'
})

Vue.filter('queryString', (params) => {
    var queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&')

    return queryString
})

const COUNTRY_DICT = {
    'ca': 'CA',
    'us': 'US',
    'gb': 'GB'
}

Vue.filter('fixCountryLetters', (string) => {
    let split = string.split(' ')

    return split.map(word => {
        if (COUNTRY_DICT[word]) {
            return COUNTRY_DICT[word]
        }

        return word
    })
    .reduce((previous, current) => previous + ' ' + current, '')
    .trim()
})

Vue.filter('truncateText', (string, maxLength = 40, truncateAt = 37) => {
    if (string.length > maxLength) {
        return `${string.substring(0, truncateAt)}...`
    }

    return string
})
