var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      staticClass: "dialog-blank alo-default-app-selector-dialog",
      attrs: { width: "700px", visible: _vm.visible, "show-close": true },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "alo-default-app-selector-body" },
        [
          _c("div", { staticClass: "break-word" }, [
            _c("p", [_vm._v("Hello there!")]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "We have news to share. As we move closer to the launch of our new Aloware Talk product (yay!), we will be sunsetting this Power Dialer feature in " +
                  _vm._s(_vm.alowareClassic) +
                  " shortly, and we will transition all of your existing contacts to the new Power Dialer when we make the switch."
              ),
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "In the meantime, we are glad to let you know that Aloware Talk's Power Dialer offers a step up for users with a more intuitive, seamless experience."
              ),
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "Go ahead and go check it out for yourself! Just click the button below. Happy talking!"
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("el-divider"),
          _vm._v(" "),
          _c(
            "a",
            { attrs: { href: _vm.talk2PowerDialerUrl } },
            [
              _c(
                "el-button",
                {
                  staticClass:
                    "w-100 d-flex justify-content-center align-items-center",
                  attrs: { size: "large", type: "primary" },
                },
                [
                  _vm._v(
                    "\n                Try Aloware Talk Power Dialer\n            "
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }