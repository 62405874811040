<template>
    <div
        v-if="shouldShowAloAis"
        id="view"
        class="app-body page-chatbot">
        <ChatbotBuilder :chatbot_id="chatbot_id"/>
    </div>
</template>

<script>
import auth from '../../auth.js'
import ChatbotBuilder from '../../components/chatbots/chatbot-builder.vue'
import {acl_mixin} from '../../mixins'

export default {
    components: {
        ChatbotBuilder
    },
    mixins: [acl_mixin],
    data() {
        return {
            chatbot_id: null
        }
    },
    created() {
        this.chatbot_id = this.$route.params.bot_id
    },
    beforeRouteEnter(to, from, next) {
        // This logic help us wrap the Chatbots section with the Aloware App components.
        if (to.query.api_token) {
            return next({
                name: 'Login',
                query: {
                    api_token: to.query.api_token
                }
            })
        }

        auth.check().then((res) => {
            if (res.data.user.is_reseller) {
                // redirect to account management portal if the company is a reseller
                next({name: 'Account Management Portal'})
            } else {
                next()
            }
        }).catch((err) => {
            next({
                name: 'Login',
                query: {
                    redirect: to.fullPath
                }
            })
        })
    },
    mounted() {
        if (!this.shouldShowAlohabots) {
            this.gotoChatbots()
        }
    },
    methods: {
        /**
         * Re-routes to the chatbots page
         *
         * @return {void}
         */
        gotoChatbots() {
            this.$router.push({name: 'Chatbots'})
        }
    }
}
</script>
