<template>
    <div v-if="hasPermissionTo('list team')">
        <label class="p-0 m-0 _500"
               v-if="label">
            {{ label }}
        </label>
        <el-tooltip effect="dark"
                    placement="top"
                    :content="disabled_tooltip_content"
                    :disabled="!disabled">
            <el-select class="w-full" 
                       autocomplete="off"
                       clearable
                       filterable
                       :default-first-option="default_first"
                       :disabled="disabled"
                       :multiple="multiple"
                       :placeholder="placeholder"
                       :size="size"
                       :collapse-tags="collapsable"
                       data-testid="team-selector"
                       v-model="team_id"
                       @clear="teamChange"
                       @change="teamChange">
                <el-option :key="team.id"
                           :label="team.name"
                           :value="team.id"
                           v-for="team in team_list">
                    <span>{{ team.name }}</span>
                </el-option>
            </el-select>
        </el-tooltip>
    </div>
</template>

<script>
import {mapState} from 'vuex'
import auth from '../auth'
import {acl_mixin} from '../mixins'

export default {
    name: 'team-selector',

    mixins: [acl_mixin],

    props: {
        value: {
            type: [String, Number, Boolean, Array],
            required: false,
        },

        multiple: {
            type: Boolean,
            required: false,
            default: false
        },

        default_first: {
            type: Boolean,
            required: false,
            default: false
        },

        first_value: {
            type: String,
            required: false,
            default: null
        },

        label: {
            type: String,
            required: false,
            default: null
        },

        size: {
            type: String,
            default: '',
            required: false
        },

        collapsable: {
            type: Boolean,
            required: false,
            default: false
        },

        disabled: {
            type: Boolean,
            required: false,
            default: false
        },

        disabled_tooltip_content: {
            type: String,
            required: false,
            default: null
        }
    },

    data() {
        return {
            auth: auth,
            team_id: this.value,
            team_list: []
        }
    },

    computed: {
        ...mapState({
            current_company: state => state.cache.current_company,
            teams: state => state.teams
        }),

        placeholder() {
            if (this.multiple) {
                return 'Select Teams'
            }

            return 'Select Team'
        },
    },

    mounted() {
        this.updateTeamsList()
    },

    watch: {
        value() {
            this.team_id = this.value
        },

        teams: {
            deep: true,
            handler: function() {
                this.updateTeamsList()
            }
        }
    },

    methods: {
        teamChange(team_id) {
            let selectedTeams = []

            if (team_id) {
                selectedTeams = this.team_list.filter(team => this.multiple ? team_id.includes(team.id) : team.id === team_id)
            } 

            if (!team_id && this.first_value) {
                team_id = 'all'
                this.team_id = 'all'
                selectedTeams = [...this.team_list]
            }

            this.$emit('change', team_id, selectedTeams)
        },

        updateTeamsList() {
            if (this.first_value) {
                const all = [{name: this.first_value.capitalize(), id: this.first_value, user_ids: []}]
                this.team_list = _.concat(all, [...this.teams])
                return
            }
            this.team_list = [...this.teams]
        },
    },
}
</script>
