var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.hasPermissionTo("list contact") && !_vm.forceTalk
    ? _c(
        "router-link",
        {
          attrs: {
            to: { name: "Contacts" },
            "active-class": "active green-gradient rounded",
            "exact-active-class": "active green-gradient rounded",
            tag: "li",
            "data-testid": "contacts-sidebar",
          },
        },
        [
          _c("a", [
            _c("span", { staticClass: "nav-icon" }, [
              _c("img", {
                attrs: {
                  src: "/assets/images/app-icons/icon-contacts-grey.svg",
                  "data-testid": "icon-contacts-grey-img",
                },
              }),
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "nav-icon active" }, [
              _c("img", {
                attrs: {
                  src: "/assets/images/app-icons/icon-contacts-white.svg",
                  "data-testid": "icon-contacts-white-img",
                },
              }),
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "nav-text" }, [_vm._v("Contacts")]),
          ]),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }