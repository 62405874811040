<template>
    <el-dialog
        class="dialog-blank"
        width="60vw"
        top="5vh"
        append-to-body
        :before-close="closeDialog"
        :visible="visible">
        <div class="container-listing rounded pt-3 pb-2">
            <h4
                class="listing-heading"
                slot="title">
                Import Logs
            </h4>

            <div
                v-if="import_contact"
                class="row mt-3 mb-3">
                <div class="col-md-3">
                    <div class="d-flex">
                        <div class="white-space-no-wrap">File: </div>
                        <a
                            :href="import_contact.download_url"
                            target="_blank"
                            class="ml-1 min-w-0">
                            <div class="text-success overflow-hidden text-ellipsis white-space-no-wrap">
                                <strong>
                                    <i class="fas fa-file-csv mr-1"></i>
                                    {{ import_contact.uploaded_file.original_file }}
                                </strong>
                            </div>
                        </a>
                    </div>
                    <div>
                        <div class="d-flex">
                            <span class="white-space-no-wrap">Imported At: </span>
                            <span class="overflow-hidden text-ellipsis white-space-no-wrap"><b>{{ import_contact.created_at | fixDateTime }}</b></span>
                        </div>
                    </div>
                </div>
                <div class="col-md-2 text-center">
                    <div><b>{{ (import_contact.rows > 0 ? import_contact.rows + 1 : 0) | numFormat }}</b></div>
                    <div>Rows </div>
                </div>
                <div class="col-md-2 text-center">
                    <div><b>{{ import_contact.imported | numFormat }}</b></div>
                    <div>Imported </div>
                </div>
                <div class="col-md-2 text-center text-danger">
                    <div><b>{{ import_contact.failed | numFormat }}</b></div>
                    <div>Failed </div>
                </div>
                <div class="col-md-2 text-center text-warning">
                    <div><b>{{ import_contact.ignored | numFormat }}</b></div>
                    <div>Skipped </div>
                </div>
            </div>

            <el-tabs
                ref="tabs"
                id="import-logging"
                v-model="active_view">
                <el-tab-pane
                    label="Logs"
                    class="px-3 py-2"
                    name="logs"
                    lazy>
                    <div>
                        <div class="d-flex mt-2 mt-sm-0 mr-2 mr-xl-0 container-listing-tools">
                            <el-radio-group
                                v-model="filters.log_type"
                                size="small"
                                @change="getImportLogs(1)"
                                class="alo-custom-radio-buttons">
                                <el-radio-button :label="ImportLogFilterTypes.FILTER_ALL">
                                    All
                                </el-radio-button>
                                <el-radio-button
                                    :label="ImportLogFilterTypes.FILTER_FAILED"
                                    class="alo-radio-button-danger">
                                    Failed
                                </el-radio-button>
                                <el-radio-button
                                    :label="ImportLogFilterTypes.FILTER_SKIPPED"
                                    class="alo-radio-button-warning">
                                    Skipped
                                </el-radio-button>
                            </el-radio-group>

                            <div class="ml-auto">
                                <el-button
                                    @click="exportLogs"
                                    :disabled="disableButtons"
                                    style="padding: 8.5px 15px"
                                    size="small"
                                    type="success">
                                    <i class="fas fa-file-export"></i> Export
                                </el-button>
                            </div>
                        </div>

                        <el-table
                            v-if="pagination.data && pagination.data.length"
                            v-loading="loading"
                            size="small"
                            fit
                            :data="pagination.data"
                            max-height="60vh"
                            class="min-height-40-vh scroll-y">
                            <el-table-column
                                v-for="column in columns"
                                :key="column.prop"
                                :property="column.prop"
                                :label="column.label"
                                :show-overflow-tooltip="column.show_overflow_tooltip"
                                :min-width="column.min_width"
                                :width="column.width ? column.width : ''">
                                <template v-slot="scope">
                                    <template v-if="column.prop === 'row'">
                                        {{ scope.row[column.prop] | numFormat }}
                                    </template>
                                    <template v-else>
                                        {{ scope.row[column.prop] }}
                                    </template>
                                </template>
                            </el-table-column>
                        </el-table>

                        <div
                            v-else
                            class="el-table__empty-block">
                            <div
                                class="text-center el-table__empty-text import-log-empty-text">
                                <img-empty class="mx-auto w-100"/>
                                <div>No import logs for this import.</div>
                                <small class="text-italic import-log-note">Import logging starts at Aug 26, 2021. Previous imports with failures will not show any logs.</small>
                            </div>
                        </div>
                    </div>
                </el-tab-pane>

                <el-tab-pane
                    label="Timeline"
                    class="px-3 py-2"
                    name="timeline"
                    lazy>
                    <import-timestamps
                        :import_contact="import_contact"
                        ref="importTimestamps">
                    </import-timestamps>
                </el-tab-pane>
            </el-tabs>

            <div class="container-footer d-flex mb-0 mt-3">
                <el-button
                    size="small"
                    @click="closeDialog">
                    Close
                </el-button>

                <div
                    v-if="active_view === 'logs'"
                    class="ml-auto">
                    <el-pagination
                        layout="sizes, prev, pager, next"
                        :current-page.sync="filters.page"
                        :page-sizes="[10, 20, 50, 100]"
                        :page-size.sync="filters.size"
                        :total="pagination.total"
                        @size-change="getImportLogs(1)"
                        @current-change="getImportLogs">
                    </el-pagination>
                </div>
            </div>
        </div>
    </el-dialog>
</template>

<script>
    import {mapState} from 'vuex'
    import ImgEmpty from "../../misc/img-empty"
    import ImportTimestamps from "./import-timestamps"
    import * as ImportLogTypes from '../../../constants/import-log-type'
    import * as ImportLogFilterTypes from '../../../constants/import-log-filter-types'

    export default {
        name: "ImportLogs",

        components: {
            ImgEmpty,
            ImportTimestamps
        },

        props: {
            import_contact: {
                required: true
            }
        },

        data() {
            return {
                active_view: 'logs',
                loading: false,
                visible: false,
                pagination: {
                    total: 0,
                    data: []
                },
                filters: {
                    page: 1,
                    size: 50,
                    log_type: -1
                },
                columns: [
                    {label: 'Row Number', prop: 'row', show_overflow_tooltip: true, width: '120px'},
                    {label: 'Status', prop: 'status_name', show_overflow_tooltip: true, width: '90px'},
                    {label: 'Phone Number(s)', prop: 'phone_number', show_overflow_tooltip: false, min_width: '120px'},
                    {label: 'Reason', prop: 'message', show_overflow_tooltip: false},
                ],
                ImportLogTypes,
                import_logs: [],
                ImportLogFilterTypes
            }
        },

        computed: {
            ...mapState('cache', ['current_company']),

            uploadedFileName() {
                return this.import_contact && this.import_contact.uploaded_file ? this.import_contact.uploaded_file.original_file : ''
            },

            disableButtons() {
                return !(this.pagination && this.pagination.data.length > 0)
            }
        },

        methods: {
            getImportLogs(page) {
                // do not load if dialog is not open
                if (!this.visible) {
                    return
                }

                this.filters.page = page

                this.loading = true

                axios.get(`/api/v1/imports/${this.import_contact.id}/import-logs`, {
                    params: this.filters
                })
                .then(res => {
                    this.loading = false

                    if (res.data) {
                        this.pagination.total = res.data.total
                        this.pagination.data = res.data.data
                    }
                })
                .catch(err => {
                    this.loading = false
                })
            },

            exportLogs() {
                this.loading = true

                axios.get(`/api/v1/imports/${this.import_contact.id}/export-import-logs`)
                .then(res => {
                    this.$notify({
                        title: 'Import Log',
                        message: res.data.message,
                        type: 'success'
                    })

                    this.loading = false
                })
                .catch(err => {
                    this.loading = false
                })
            },

            closeDialog() {
                this.resetFilter()

                this.visible = false

                this.$emit('close-dialog')
            },

            resetFilter() {
                this.filters = {
                    page: 1,
                    size: 50,
                    log_type: -1
                }
            }
        },

        watch: {
            'import_contact.id'() {
                if (this.import_contact && this.import_contact.id) {
                    this.getImportLogs(1)
                }
            }
        }
    }
</script>
