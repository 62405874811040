// the endpoint's base url
let base_path = `/api/v2/contacts-list`

export default {
    /**
     * APIv2 endpoint for fetching contact lists.
     * filters parameter is used to limit the amount of
     * data that will be fetch
     *
     * @param filters
     *
     * @handler Api/V2/Contacts/ContactListController@index
     *
     * @return Promise
     */
    getContactsList(filters) {
        return axios.get(`${base_path}`, {params: filters})
    },

    /**
     * APIv2 endpoint to update a contact list
     *
     * @param post_data
     * @param id
     *
     * @handler Api/V2/Contacts/ContactListController@update
     *
     * @return Promise
     */
    updateContactList(post_data, id) {
        return axios.put(`${base_path}/${id}`, post_data)
    },

    /**
     * APIv2 endpoint that will delete a contact list
     *
     * @param id
     * @param params
     *
     * @handler Api/V2/Contacts/ContactListController@destroy
     *
     * @return Promise
     */
    deleteContactList(id, params) {
        return axios.delete(`${base_path}/${id}`, {params})
    },

    /**
     * Assign contacts of a contact list to either user or ring group
     *
     * @param contact_list_id
     * @param post_data
     *
     * @handler Api/V2/Contacts/ContactListController@assign
     *
     * @returns Promise
     */
    assignContacts(contact_list_id, post_data) {
        return axios.post(`${base_path}/${contact_list_id}/assign`, post_data)
    },

    /**
     * APIv2 endpoint that will import certain hubspot list from hubspot
     *
     * @param list_id
     *
     * @handler Api/V2/Contacts/ContactListController@importHubspotList
     *
     * @returns Promise
     */
    importFromHubspotList(list_id) {
        return axios.post(`${base_path}/import-hubspot-list/${list_id}`)
    },

    /**
     * APIv2 endpoint that checks if a contact list already exist based on the provided id from HS
     *
     * @param list_id
     *
     * @handler Api/V2/Contacts/ContactListController@checkHubspotList
     *
     * @returns Promise
     */
    checkListNameIfExist(list_id) {
        return axios.get(`${base_path}/hubspot-list-exists/${list_id}`)
    }
}
