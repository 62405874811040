var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "app-body", attrs: { id: "view" } },
    [
      _vm.selected_campaign && _vm.hasPermissionTo("view campaign")
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: _vm.selected_campaign.name,
                visible: _vm.detailDialogVisible,
                width: "50%",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.detailDialogVisible = $event
                },
              },
            },
            [
              _c("campaign-details", {
                attrs: { campaign: _vm.selected_campaign },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "What are lines?",
            top: "10vh",
            visible: _vm.show_help,
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.show_help = $event
            },
          },
        },
        [
          _c("p", { staticClass: "text-md _600 break-word" }, [
            _vm._v(
              "Lines are the core of your phone system: managing Calls and SMS intake.\n            Here you can\n            buy phone numbers, define greeting prompts, record calls, or set up IVR phone trees."
            ),
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "text-md _600 break-word" }, [
            _vm._v(
              "Each Line has an activity page showing its calls and messages. Use\n            multiple lines\n            for call tracking and attribution, or set up a dedicated phone number for a department."
            ),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "fixed-header padding pt-0 pb-0",
          style: { top: _vm.total_offset + 50 + "px" },
        },
        [
          _c("div", { staticClass: "row mb-0 mt-0" }, [
            _c("div", { staticClass: "d-flex flex-fill flex-wrap p-2" }, [
              _c(
                "div",
                {
                  staticClass:
                    "d-flex flex-wrap flex-xl-nowrap mr-auto pr-xl-2",
                },
                [
                  _c(
                    "div",
                    { staticClass: "mr-sm-2 flex-fill search-toolbar" },
                    [
                      _c("el-input", {
                        ref: "lineSearch",
                        attrs: {
                          placeholder: "Search",
                          "prefix-icon": "el-icon-search",
                          clearable: "",
                          autofocus: "",
                        },
                        model: {
                          value: _vm.search_text,
                          callback: function ($$v) {
                            _vm.search_text = $$v
                          },
                          expression: "search_text",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "no-select mt-2 mt-md-0 mr-2 w-m" },
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "no-select",
                          attrs: {
                            placeholder: "Select",
                            "default-first-option": "",
                            filterable: "",
                          },
                          on: { change: _vm.changedFilter },
                          model: {
                            value: _vm.type_filter,
                            callback: function ($$v) {
                              _vm.type_filter = $$v
                            },
                            expression: "type_filter",
                          },
                        },
                        [
                          _c("el-option", {
                            key: 1,
                            attrs: { label: "All", value: "All" },
                          }),
                          _vm._v(" "),
                          _c("el-option", {
                            key: 2,
                            attrs: {
                              label: "Personal Lines",
                              value: "Personal Lines",
                            },
                          }),
                          _vm._v(" "),
                          _c("el-option", {
                            key: 3,
                            attrs: { label: "IVRs", value: "IVRs" },
                          }),
                          _vm._v(" "),
                          _c("el-option", {
                            key: 4,
                            attrs: {
                              label: "Ring Groups",
                              value: "Ring Groups",
                            },
                          }),
                          _vm._v(" "),
                          _c("el-option", {
                            key: 5,
                            attrs: { label: "Dead ends", value: "Dead ends" },
                          }),
                          _vm._v(" "),
                          _c("el-option", {
                            key: 6,
                            attrs: {
                              label: "Local Presence",
                              value: "Local Presence",
                            },
                          }),
                          _vm._v(" "),
                          _c("el-option", {
                            key: 7,
                            attrs: { label: "Toll-Free", value: "Toll-Free" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "no-select mt-2 mt-md-0" },
                    [
                      !_vm.isMobileSize
                        ? _c(
                            "el-radio-group",
                            {
                              staticClass: "no-select",
                              on: { change: _vm.changedFilter },
                              model: {
                                value: _vm.campaign_filter,
                                callback: function ($$v) {
                                  _vm.campaign_filter = $$v
                                },
                                expression: "campaign_filter",
                              },
                            },
                            [
                              _c(
                                "el-radio-button",
                                { attrs: { label: "active" } },
                                [
                                  _vm._v(
                                    "\n                                Active "
                                  ),
                                  _vm.activeCount
                                    ? _c("span", [
                                        _vm._v(
                                          "(" + _vm._s(_vm.activeCount) + ")"
                                        ),
                                      ])
                                    : _vm._e(),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-radio-button",
                                {
                                  class: [
                                    _vm.campaign_filter == "paused"
                                      ? "pause-deep-orange"
                                      : "",
                                  ],
                                  attrs: { label: "paused" },
                                },
                                [
                                  _vm._v(
                                    "\n                                Paused "
                                  ),
                                  _vm.pausedCount
                                    ? _c("span", [
                                        _vm._v(
                                          "(" + _vm._s(_vm.pausedCount) + ")"
                                        ),
                                      ])
                                    : _vm._e(),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-radio-button",
                                { attrs: { label: "all" } },
                                [
                                  _vm._v(
                                    "\n                                All "
                                  ),
                                  _vm.allCount
                                    ? _c("span", [
                                        _vm._v(
                                          "(" + _vm._s(_vm.allCount) + ")"
                                        ),
                                      ])
                                    : _vm._e(),
                                ]
                              ),
                            ],
                            1
                          )
                        : _c(
                            "el-select",
                            {
                              staticClass: "no-select",
                              attrs: {
                                placeholder: "Select",
                                "default-first-option": "",
                                filterable: "",
                              },
                              on: { change: _vm.changedFilter },
                              model: {
                                value: _vm.campaign_filter,
                                callback: function ($$v) {
                                  _vm.campaign_filter = $$v
                                },
                                expression: "campaign_filter",
                              },
                            },
                            [
                              _c("el-option", {
                                key: 1,
                                attrs: {
                                  label:
                                    "Active " +
                                    (_vm.activeCount
                                      ? "(" + _vm.activeCount + ")"
                                      : ""),
                                  value: "active",
                                },
                              }),
                              _vm._v(" "),
                              _c("el-option", {
                                key: 2,
                                attrs: {
                                  label:
                                    "Paused " +
                                    (_vm.pausedCount
                                      ? "(" + _vm.pausedCount + ")"
                                      : ""),
                                  value: "paused",
                                },
                              }),
                              _vm._v(" "),
                              _c("el-option", {
                                key: 3,
                                attrs: {
                                  label:
                                    "All " +
                                    (_vm.allCount
                                      ? "(" + _vm.allCount + ")"
                                      : ""),
                                  value: "all",
                                },
                              }),
                            ],
                            1
                          ),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "d-flex flex-wrap flex-xl-nowrap" },
                [
                  _vm.canCreateFaxAndNewLine
                    ? _c("line-creator", {
                        staticClass: "mr-2 mt-2 mt-xl-0",
                        attrs: { is_fax: true },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.canCreateFaxAndNewLine
                    ? _c("line-creator", { staticClass: "mr-2 mt-2 mt-xl-0" })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.shouldShowLPPurchaseButton
                    ? _c("div", { staticClass: "mt-2 mt-xl-0 mr-2" }, [
                        _c(
                          "a",
                          {
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return (($event) =>
                                  _vm.openLocalPresenceWizard()).apply(
                                  null,
                                  arguments
                                )
                              },
                            },
                          },
                          [_vm._m(0)]
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.isCompanyKYC
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "mt-2 mt-xl-0 mr-2 mr-xl-0 d-flex flex-column justify-content-center text-blue",
                        },
                        [
                          _c(
                            "i",
                            {
                              staticClass:
                                "material-icons text-lg cursor-pointer",
                              on: { click: _vm.toggleHelp },
                            },
                            [
                              _vm._v(
                                "\n                            info_outline\n                        "
                              ),
                            ]
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.isTrial && _vm.isNotSimpSocial
                    ? _c("video-modal-activator", { attrs: { size: "small" } })
                    : _vm._e(),
                ],
                1
              ),
            ]),
          ]),
        ]
      ),
      _vm._v(" "),
      _vm.hasPermissionTo("list report") && !_vm.search_text
        ? _c("div", { staticClass: "padding mt-5 pt-3" }, [
            _c("div", { staticClass: "row box" }, [
              _c("div", { staticClass: "col-12" }, [
                _c("div", { staticClass: "no-effect" }, [
                  _c(
                    "div",
                    { staticClass: "box-header pb-0" },
                    [
                      _c("activity-graph", {
                        attrs: {
                          base: "campaign",
                          default_date_range: 7,
                          is_first_load: _vm.reports_first_load,
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "padding", attrs: { id: "table" } }, [
        _c("div", { staticClass: "row box" }, [
          _c("div", { staticClass: "col-12" }, [
            _c("div", { staticClass: "no-effect" }, [
              _c("div", { staticClass: "box-header" }, [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-12" }, [
                    _vm.filteredVisibleCampaigns
                      ? _c("span", { staticClass: "pull-left font-120" }, [
                          _c("strong", [
                            _vm._v(
                              _vm._s(_vm.filteredVisibleCampaigns.length || 0)
                            ),
                          ]),
                          _vm._v(
                            "\n                                    " +
                              _vm._s(
                                _vm.filteredVisibleCampaigns > 1
                                  ? "lines"
                                  : "line"
                              ) +
                              "\n                                "
                          ),
                        ])
                      : _vm._e(),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "box-body" }, [
                _c(
                  "div",
                  { on: { mouseleave: _vm.tableLeave } },
                  [
                    _c(
                      "el-table",
                      {
                        directives: [
                          {
                            name: "loading",
                            rawName: "v-loading",
                            value: _vm.loading,
                            expression: "loading",
                          },
                        ],
                        staticClass: "w-full",
                        attrs: {
                          data: _vm.paginatedData,
                          "default-sort": { prop: "id", order: "ascending" },
                          fit: "",
                          stripe: "",
                        },
                        on: { "cell-mouse-enter": _vm.cellEnter },
                      },
                      [
                        _c("el-table-column", {
                          attrs: { prop: "active", width: "35" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("span", { staticClass: "status" }, [
                                    _c("i", {
                                      staticClass: "b-white",
                                      class: [
                                        scope.row.active
                                          ? "greenish"
                                          : "danger",
                                      ],
                                    }),
                                  ]),
                                ]
                              },
                            },
                          ]),
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: {
                            prop: "id",
                            label: "ID",
                            width: "100",
                            sortable: "",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("span", { staticClass: "text-greyish" }, [
                                    _vm._v(_vm._s(scope.row.id)),
                                  ]),
                                ]
                              },
                            },
                          ]),
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: {
                            prop: "name",
                            label: "Name",
                            "min-width": 200,
                            sortable: "",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c(
                                    "router-link",
                                    {
                                      attrs: {
                                        to: {
                                          name: "Line Settings",
                                          params: { campaign_id: scope.row.id },
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "text-dark-greenish text-ellipsis",
                                          attrs: { title: scope.row.name },
                                        },
                                        [
                                          _vm._v(
                                            "\n                                                " +
                                              _vm._s(scope.row.name) +
                                              "\n                                            "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      scope.row.has_local_presence
                                        ? _c(
                                            "span",
                                            {
                                              staticClass:
                                                "label p-1 text-xxs blue",
                                            },
                                            [
                                              _vm._v(
                                                "\n                                                Has Local Presence\n                                            "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        { staticClass: "text-greyish" },
                                        [
                                          _vm._v(
                                            _vm._s(scope.row.description || "")
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              },
                            },
                          ]),
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: {
                            prop: "cnam_caller_id",
                            label: "Caller ID (CNAM)",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  scope.row.cnam_caller_id
                                    ? _c(
                                        "span",
                                        { staticClass: "text-greyish" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              scope.row.cnam_caller_id
                                                .display_name
                                            )
                                          ),
                                        ]
                                      )
                                    : _c(
                                        "span",
                                        { staticClass: "text-greyish" },
                                        [_vm._v(" - ")]
                                      ),
                                ]
                              },
                            },
                          ]),
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: {
                            prop: "type",
                            label: "Type",
                            align: "center",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _vm.lineHasLocal(scope.row)
                                    ? _c(
                                        "span",
                                        {
                                          staticClass:
                                            "ml-2 d-inline-block label deep-orange",
                                        },
                                        [
                                          _vm._v(
                                            "\n                                            Local\n                                        "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.lineHasUkNumbers(scope.row)
                                    ? _c(
                                        "span",
                                        {
                                          staticClass:
                                            "ml-2 d-inline-block label purple",
                                        },
                                        [
                                          _vm._v(
                                            "\n                                            " +
                                              _vm._s(_vm.ukLineTag(scope.row)) +
                                              "\n                                        "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.lineHasTollFree(scope.row)
                                    ? _c(
                                        "span",
                                        {
                                          staticClass:
                                            "ml-2 d-inline-block label blue",
                                        },
                                        [
                                          _vm._v(
                                            "\n                                            Toll-free\n                                        "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.hasShortCode(scope.row)
                                    ? _c(
                                        "span",
                                        {
                                          staticClass:
                                            "ml-2 d-inline-block label light-blue",
                                        },
                                        [
                                          _vm._v(
                                            "\n                                            Short Code\n                                        "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.lineHasInternationalMobile(scope.row)
                                    ? _c(
                                        "span",
                                        {
                                          staticClass:
                                            "ml-2 d-inline-block label amber text-dark",
                                        },
                                        [
                                          _vm._v(
                                            "\n                                            International Mobile\n                                        "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.lineHasInternationalLocal(scope.row)
                                    ? _c(
                                        "span",
                                        {
                                          staticClass:
                                            "ml-2 d-inline-block label gray text-dark",
                                        },
                                        [
                                          _vm._v(
                                            "\n                                            International Local\n                                        "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.lineHasFax(scope.row)
                                    ? _c(
                                        "span",
                                        {
                                          staticClass:
                                            "ml-2 d-inline-block label light-green text-white",
                                        },
                                        [
                                          _vm._v(
                                            "\n                                            Fax\n                                        "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.lineHasFake(scope.row)
                                    ? _c(
                                        "span",
                                        {
                                          staticClass:
                                            "ml-2 d-inline-block label dark",
                                        },
                                        [
                                          _vm._v(
                                            "\n                                            Fake\n                                        "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.lineIsEmpty(scope.row)
                                    ? _c(
                                        "span",
                                        {
                                          staticClass:
                                            "ml-2 d-inline-block label gray",
                                        },
                                        [
                                          _vm._v(
                                            "\n                                            Empty Line\n                                        "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.lineIsHosted(scope.row)
                                    ? _c(
                                        "span",
                                        {
                                          staticClass:
                                            "ml-2 d-inline-block label bluish",
                                        },
                                        [
                                          _vm._v(
                                            "\n                                            Gray Pool\n                                        "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.lineIsCallingOnly(scope.row)
                                    ? _c(
                                        "span",
                                        {
                                          staticClass:
                                            "ml-2 d-inline-block label black",
                                        },
                                        [
                                          _vm._v(
                                            "\n                                            Calling Only\n                                        "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]
                              },
                            },
                          ]),
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: {
                            prop: "incoming_number",
                            label: "Numbers",
                            width: "180",
                            align: "left",
                            sortable: "",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  scope.row.incoming_numbers
                                    ? _c(
                                        "el-tooltip",
                                        {
                                          staticClass: "item",
                                          attrs: {
                                            effect: "dark",
                                            content: "View Details",
                                            placement: "bottom",
                                          },
                                        },
                                        [
                                          _vm.hasPermissionTo("update campaign")
                                            ? _c(
                                                "a",
                                                {
                                                  on: {
                                                    click: function ($event) {
                                                      $event.preventDefault()
                                                      return _vm.viewDetails(
                                                        scope.row
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  scope.row.incoming_numbers
                                                    .length > 1
                                                    ? _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "text-dark-greenish",
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                                                    " +
                                                              _vm._s(
                                                                scope.row
                                                                  .incoming_numbers
                                                                  .length
                                                              ) +
                                                              " Numbers\n                                                "
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  scope.row.incoming_numbers
                                                    .length == 1
                                                    ? _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "text-dark-greenish",
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                                                    " +
                                                              _vm._s(
                                                                _vm._f(
                                                                  "fixPhone"
                                                                )(
                                                                  scope.row
                                                                    .incoming_numbers[0]
                                                                    .phone_number
                                                                )
                                                              ) +
                                                              "\n                                                "
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                ]
                                              )
                                            : _c(
                                                "router-link",
                                                {
                                                  attrs: {
                                                    to: {
                                                      name: "Line Activity",
                                                      params: {
                                                        campaign_id:
                                                          scope.row.id,
                                                      },
                                                    },
                                                  },
                                                },
                                                [
                                                  scope.row.incoming_numbers
                                                    .length > 1
                                                    ? _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "text-dark-greenish",
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                                                    " +
                                                              _vm._s(
                                                                scope.row
                                                                  .incoming_numbers
                                                                  .length
                                                              ) +
                                                              " Numbers\n                                                "
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  scope.row.incoming_numbers
                                                    .length == 1
                                                    ? _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "text-dark-greenish",
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                                                    " +
                                                              _vm._s(
                                                                _vm._f(
                                                                  "fixPhone"
                                                                )(
                                                                  scope.row
                                                                    .incoming_numbers[0]
                                                                    .phone_number
                                                                )
                                                              ) +
                                                              "\n                                                "
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                ]
                                              ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ]
                              },
                            },
                          ]),
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: {
                            label: "Routing",
                            width: "160",
                            align: "left",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  !scope.row.is_fax
                                    ? [
                                        _c(
                                          "span",
                                          { staticClass: "text-greyish" },
                                          [
                                            _vm._v(
                                              "\n                                                " +
                                                _vm._s(
                                                  _vm._f(
                                                    "humanReadableCallRouterBehavior"
                                                  )(
                                                    scope.row
                                                      .call_router_behavior
                                                  )
                                                ) +
                                                "\n                                            "
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c("br"),
                                        _vm._v(" "),
                                        scope.row.ring_group_id &&
                                        _vm.getRingGroup(
                                          scope.row.ring_group_id
                                        )
                                          ? _c(
                                              "router-link",
                                              {
                                                attrs: {
                                                  to: {
                                                    name: "Ring Group Dialog",
                                                    params: {
                                                      ring_group_id:
                                                        scope.row.ring_group_id,
                                                    },
                                                  },
                                                },
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "text-dark-greenish",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.getRingGroup(
                                                          scope.row
                                                            .ring_group_id
                                                        ).name
                                                      )
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            )
                                          : scope.row.user_id &&
                                            _vm.getUser(scope.row.user_id)
                                          ? _c(
                                              "router-link",
                                              {
                                                attrs: {
                                                  to: {
                                                    name: "User Dialog",
                                                    params: {
                                                      user_id:
                                                        scope.row.user_id,
                                                    },
                                                  },
                                                },
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "text-dark-greenish",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.getUserName(
                                                          _vm.getUser(
                                                            scope.row.user_id
                                                          )
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]
                                    : [
                                        _c(
                                          "span",
                                          { staticClass: "text-greyish" },
                                          [
                                            _vm._v(
                                              "\n                                                Fax\n                                            "
                                            ),
                                          ]
                                        ),
                                      ],
                                ]
                              },
                            },
                          ]),
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: {
                            prop: "active",
                            label: "Status",
                            width: "120",
                            align: "left",
                            sortable: "",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  scope.row.active == true
                                    ? _c("span", [_vm._v("Active")])
                                    : _c("span", [_vm._v("Paused")]),
                                ]
                              },
                            },
                          ]),
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: {
                            label: "Operations",
                            align: "center",
                            width: "120",
                            fixed: "right",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c(
                                    "div",
                                    { style: _vm.getStyle(scope.row.id) },
                                    [
                                      _vm.hasPermissionTo([
                                        "view campaign",
                                        "list communication",
                                        "view communication",
                                      ])
                                        ? _c("div", { staticClass: "row" }, [
                                            _c(
                                              "div",
                                              { staticClass: "col-12" },
                                              [
                                                _c(
                                                  "router-link",
                                                  {
                                                    attrs: {
                                                      to: {
                                                        name: "Line Activity",
                                                        params: {
                                                          campaign_id:
                                                            scope.row.id,
                                                        },
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "button",
                                                      {
                                                        staticClass:
                                                          "btn btn-sm btn-block purplish",
                                                      },
                                                      [
                                                        _c("i", {
                                                          staticClass:
                                                            "fa fa-signal pull-left",
                                                        }),
                                                        _vm._v(
                                                          "\n                                                                Activity\n                                                            "
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ])
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.hasPermissionTo("update campaign")
                                        ? _c(
                                            "div",
                                            { staticClass: "row mt-2" },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "col-12" },
                                                [
                                                  _c(
                                                    "router-link",
                                                    {
                                                      attrs: {
                                                        to: {
                                                          name: "Line Settings",
                                                          params: {
                                                            campaign_id:
                                                              scope.row.id,
                                                          },
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "button",
                                                        {
                                                          staticClass:
                                                            "btn btn-sm btn-block info",
                                                        },
                                                        [
                                                          _c("i", {
                                                            staticClass:
                                                              "fa fa-cogs pull-left",
                                                          }),
                                                          _vm._v(
                                                            "\n                                                                Settings\n                                                            "
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.hasPermissionTo(
                                        "toggle active status campaign"
                                      )
                                        ? _c(
                                            "div",
                                            { staticClass: "row mt-2" },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "col-12" },
                                                [
                                                  _c(
                                                    "button",
                                                    {
                                                      staticClass:
                                                        "btn btn-sm btn-block",
                                                      class:
                                                        scope.row.active == true
                                                          ? "deep-orange-500"
                                                          : "light-blue-500",
                                                      attrs: {
                                                        disabled:
                                                          _vm.loading_btn_id ==
                                                          scope.row.id,
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.toggleActive(
                                                            scope.row.id
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm.loading_btn_id ==
                                                      scope.row.id
                                                        ? _c("i", {
                                                            staticClass:
                                                              "fa fa-spin fa-spinner pull-left",
                                                          })
                                                        : [
                                                            scope.row.active ==
                                                            true
                                                              ? _c("i", {
                                                                  staticClass:
                                                                    "fa fa-pause pull-left",
                                                                })
                                                              : _c("i", {
                                                                  staticClass:
                                                                    "fa fa-play pull-left",
                                                                }),
                                                          ],
                                                      _vm._v(
                                                        "\n                                                            " +
                                                          _vm._s(
                                                            scope.row.active ==
                                                              true
                                                              ? "Pause"
                                                              : "Activate"
                                                          ) +
                                                          "\n                                                        "
                                                      ),
                                                    ],
                                                    2
                                                  ),
                                                ]
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.hasPermissionTo("create campaign") &&
                                      !scope.row.deleted_at
                                        ? _c(
                                            "div",
                                            { staticClass: "row mt-2" },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "col-12" },
                                                [
                                                  _c(
                                                    "button",
                                                    {
                                                      staticClass:
                                                        "btn btn-sm btn-block blue",
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.duplicateCampaign(
                                                            scope.row.id
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm.loading_duplicate_btn_id ===
                                                      scope.row.id
                                                        ? _c("i", {
                                                            staticClass:
                                                              "fa fa-spin fa-spinner pull-left",
                                                          })
                                                        : _c("i", {
                                                            staticClass:
                                                              "fa fa-copy pull-left",
                                                          }),
                                                      _vm._v(
                                                        "\n                                                            Duplicate\n                                                        "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.hasPermissionTo("archive campaign")
                                        ? _c(
                                            "div",
                                            { staticClass: "row mt-2" },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "col-12" },
                                                [
                                                  _c(
                                                    "button",
                                                    {
                                                      staticClass:
                                                        "btn btn-sm btn-block danger",
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.deleteCampaignRemote(
                                                            scope.row.id,
                                                            scope.row
                                                              .has_local_presence
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c("i", {
                                                        staticClass:
                                                          "fa fa-trash pull-left",
                                                      }),
                                                      _vm._v(
                                                        "\n                                                            Delete\n                                                        "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]
                                  ),
                                ]
                              },
                            },
                          ]),
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.pageCount >= 1
                  ? _c(
                      "div",
                      { staticClass: "mt-3" },
                      [
                        _c("el-pagination", {
                          staticClass: "pull-right",
                          attrs: {
                            background: "",
                            layout: "sizes, prev, pager, next",
                            "page-sizes": [10, 20, 50, 100, 250],
                            "page-size": 20,
                            "current-page": _vm.pagination.current_page,
                            total: _vm.dataCount,
                          },
                          on: {
                            "update:currentPage": function ($event) {
                              return _vm.$set(
                                _vm.pagination,
                                "current_page",
                                $event
                              )
                            },
                            "update:current-page": function ($event) {
                              return _vm.$set(
                                _vm.pagination,
                                "current_page",
                                $event
                              )
                            },
                            "current-change": _vm.changePage,
                            "size-change": _vm.handleSizeChange,
                          },
                        }),
                        _vm._v(" "),
                        _c("div", { staticClass: "clearfix" }),
                      ],
                      1
                    )
                  : _vm._e(),
              ]),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      !_vm.isSimpSocial
        ? _c("local-presence-wizard", { ref: "localPresenceWizard" })
        : _vm._e(),
      _vm._v(" "),
      _c("el-backtop", {
        ref: "backtop",
        attrs: { target: "html", bottom: 20, right: 20 },
      }),
      _vm._v(" "),
      _vm.isTrial && _vm.isNotSimpSocial
        ? _c("video-modal", {
            staticClass: "pl-2",
            attrs: {
              title: "Adding Phone Lines to Your Account 📞",
              "cookie-name": "lines",
              notes:
                "🔥 Supercharge your phone system with <strong>Lines</strong>! </br></br>🚀 Handle calls, SMS, snag cool numbers, drop epic greetings, record, and unleash powerful IVR trees. Dive into each Line's buzzing activity.</br></br>Multi-line call tracking? Easy. A dedicated number for a department? Done. Elevate NOW! 🌟📞🔥",
              "video-url":
                "https://www.youtube.com/embed/-hk1p0j0feI?si=a8BC36dxCjCz4yIc",
              "learn-more-link":
                "https://support.aloware.com/en/articles/9031270-lines-menu-general-settings",
              "has-activator-button": true,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("button", { staticClass: "btn btn-md btn-primary" }, [
      _c("i", { staticClass: "fa fa-thumb-tack" }),
      _vm._v(
        "\n                                Add Local Presence\n                            "
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }