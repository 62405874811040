<template>
    <div class="app-body conversync max-height no-footer"
         :class="getClass"
         id="view"
         :style="{ height: 'calc(100% - ' + (top_offset + 1) + 'px) !important' }">
        <!-- giphy media dialog -->
        <el-dialog title="Send GIF"
                   v-if="hasPermissionTo('update contact')"
                   top="10vh"
                   append-to-body
                   custom-class="wizard-dialog dialog-no-border gif-dialog"
                   :visible.sync="giphyMediaDialogVisible">
            <search-giphy v-if="giphyMediaDialogVisible"
                          @selected="sendGifMessage">
            </search-giphy>
        </el-dialog>

        <!--send media dialog-->
        <el-dialog title="Send Media"
                   v-if="hasPermissionTo('update contact')"
                   :visible.sync="sendMediaDialogVisible"
                   @close="closeSendMediaModal"
                   top="10vh"
                   append-to-body
                   custom-class="wizard-dialog dialog-no-border"
                   :before-close="beforeCloseSendMediaModal">
            <el-form ref="media"
                     label-width="200px"
                     label-position="top"
                     :model="media"
                     :rules="rules_media"
                     @submit.prevent.native="sendMedia">
                <div class="input-group mb-3">
                    <variable-dialog :is_append="false"
                                     :short="false">
                    </variable-dialog>
                    <textarea class="form-control r-2x"
                              v-model="media.body"
                              rows="1"
                              :placeholder="getPlaceholder"/>
                </div>
                <p class="form-head-message text-left mb-2">Upload the media file:</p>
                <div class="no-bottom-margin">
                    <el-form-item prop="file_name">
                        <el-upload drag
                                   :action="'/api/v1/campaign/' + selected_campaign_id + '/upload/media'"
                                   :headers="headers"
                                   :on-success="onSuccessSendMedia"
                                   :on-error="onFailedSendMedia"
                                   :on-progress="progressUploadSendMedia"
                                   :before-upload="beforeUploadSendMedia"
                                   :file-list="media.files"
                                   :on-change="onChangeFileList"
                                   :on-remove="onChangeFileList"
                                   multiple>
                            <i class="el-icon-upload"></i>
                            <div class="el-upload__text">
                                Drop file here or <em>click to upload</em>
                            </div>
                            <div class="el-upload__tip p-3 break-word"
                                 slot="tip">
                                We support various media file types.
                                The file size limit for images is 5MB, and for the other file types, it should be below 600KB for carriers to accept it.
                            </div>
                        </el-upload>
                        <el-progress :text-inside="true"
                                     :stroke-width="18"
                                     :percentage="uploadPercentage.upload"
                                     :status="uploadStatus.upload"
                                     v-if="media.file_name === null">
                        </el-progress>
                    </el-form-item>
                </div>
            </el-form>

            <span slot="footer"
                  class="dialog-footer">
                <el-button @click="sendMediaDialogVisible = false">Cancel</el-button>
                <el-button type="success"
                           @click="sendMedia()"
                           :loading="loading_send_media_btn"
                           :disabled="!validated">
                    Send
                </el-button>
            </span>
        </el-dialog>

        <!--send Fax dialog-->
        <el-dialog title="Send Fax"
                   v-if="hasPermissionTo('update contact')"
                   :visible.sync="sendFaxDialogVisible"
                   @close="closeSendFaxModal"
                   top="10vh"
                   append-to-body
                   custom-class="wizard-dialog dialog-no-border"
                   :before-close="beforeCloseSendFaxModal">
            <el-form ref="fax"
                     label-width="200px"
                     label-position="top"
                     :model="fax"
                     :rules="rules_fax"
                     @submit.prevent.native="sendFax">
                <p class="form-head-message text-left mb-2">Upload a PDF file:</p>
                <div class="no-bottom-margin">
                    <el-form-item prop="file_name">
                        <el-upload drag
                                   :action="'/api/v1/campaign/' + selected_campaign_id + '/upload/fax'"
                                   :headers="headers"
                                   :on-success="onSuccessSendFax"
                                   :on-error="onFailedSendFax"
                                   :on-progress="progressUploadSendFax"
                                   :before-upload="beforeUploadSendFax"
                                   :file-list="uploadFileList.upload"
                                   :multiple="false">
                            <i class="el-icon-upload"></i>
                            <div class="el-upload__text">
                                Drop file here or <em>click to upload</em>
                            </div>
                            <div class="el-upload__tip"
                                 slot="tip">
                                PDF file (up to 32MB)
                            </div>
                        </el-upload>
                        <el-progress :text-inside="true"
                                     :stroke-width="18"
                                     :percentage="uploadPercentage.upload"
                                     :status="uploadStatus.upload"
                                     v-if="fax.file_name === null">
                        </el-progress>
                    </el-form-item>
                </div>
            </el-form>

            <span slot="footer"
                  class="dialog-footer">
                <el-button @click="sendFaxDialogVisible = false">Cancel</el-button>
                <el-button type="success"
                           @click="sendFax"
                           :loading="loading_send_fax_btn"
                           :disabled="!validated">
                    Send
                </el-button>
            </span>
        </el-dialog>

        <new-email ref="newEmailMessage"
                   v-if="hasPermissionTo('update contact')"
                   :contact_id="contact_id"
                   :selected_campaign_id="selected_campaign_id">
        </new-email>

        <new-car ref="newCarMessage"
                 v-if="hasPermissionTo('update contact')"
                 :key="prop_counter"
                 :contact_id="contact_id"
                 :selected_campaign_id="selected_campaign_id"
                 @hide="newCarFormClosed">
        </new-car>

        <div class="pt-3 pl-3 pr-3 full-height">
            <!--contact page (mobile screen)-->
            <div v-if="!isLargeEnough && contact_id && !$root.loading && hasPermissionTo('view contact')"
                 v-loading="loading_contact"
                 class="full-height">
                <div v-if="!statics.whitelabel">
                    <div class="p-a notif-card el-card">
                        <div class="row">
                            <div class="col-md-12">
                                <a href="https://aloware.com/apps"
                                   data-testid="aloware-app-link"
                                   class="text-md text-bluish">
                                    Please download Aloware app on your smartphone 📱
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!--contact page (big screen)-->
            <div v-if="isLargeEnough && contact_id && hasPermissionTo('view contact')"
                 v-loading="loading_contact"
                 class="full-height test">
                <div class="row flex-nowrap full-height">
                    <div class="col sidebar-full-height scroll-y pl-2 pr-2">
                        <div class="box b-a">
                            <div class="d-flex justify-content-between mt-3">
                                <el-button class="no-radius"
                                           type="info"
                                           size="small"
                                           v-if="!embedded"
                                           plain
                                           data-testid="back-to-contacts-button"
                                           @click="backToContacts">
                                    <i class="el-icon-arrow-left"></i>
                                    Contacts
                                </el-button>

                                <div class="d-flex"
                                     v-if="selected_contact && !embedded">
                                    <el-button-group>
                                        <el-button class="no-radius ml-0"
                                                   type="info"
                                                   size="small"
                                                   icon="el-icon-arrow-left"
                                                   ref="previousContact"
                                                   :disabled="contact_list_pagination_loading"
                                                   data-testid="previous-contact-button"
                                                   @click="goToPreviousContact"
                                                   plain>
                                            Prev
                                            <i v-if="contact_list_pagination_loading"
                                               class="fa fa-spinner fa-spin"></i>
                                        </el-button>
                                        <el-button class="no-radius ml-0"
                                                   type="info"
                                                   size="small"
                                                   ref="nextContact"
                                                   :disabled="contact_list_pagination_loading"
                                                   @click="goToNextContact"
                                                   data-testid="next-contact-button"
                                                   plain>
                                            Next
                                            <i v-if="contact_list_pagination_loading"
                                               class="fa fa-spinner fa-spin"></i>
                                            <i v-else
                                               class="el-icon-arrow-right"></i>
                                        </el-button>
                                    </el-button-group>
                                </div>
                            </div>

                            <div class="d-flex flex-row pt-3 pb-3 pl-3 pr-3 b-b"
                                 v-if="selected_contact">
                                <div class="d-flex flex-column align-items-start w-100">
                                    <div class="d-flex flex-row align-items-start justify-content-between w-100">
                                        <div class="d-flex">
                                            <span class="_600 text-md fixed d-flex align-self-start mr-2"
                                                  style="max-width: 116px;">
                                                {{ selected_contact.name | fixContactName }}
                                            </span>
                                            <div class="pos-rlt"
                                                 v-if="hasPermissionTo('update contact')"
                                                 style="">
                                                <!-- contact edit -->
                                                <contact-info-form :contact="contactBasic"
                                                                   :position="'right-start'"
                                                                   data-testid="contact-info-form"
                                                                   @update="updateContactInCommunication">
                                                    <div class="no-select"
                                                         slot="ref_trigger">
                                                        <span class="text-md text-greenish pointer no-select">
                                                            <i class="material-icons">edit</i>
                                                        </span>
                                                    </div>
                                                </contact-info-form>
                                            </div>
                                        </div>
                                        <digital-clock class="d-flex ml-3"
                                                       :timezone="getTimeZone(selected_contact.timezone)">
                                        </digital-clock>
                                    </div>
                                    <span class="text-md">{{ selected_contact.phone_number | fixPhone }}</span>
                                    <div class="d-flex flex-row mt-2 align-items-center">
                                        <span class="label p-1 text-xxs success"
                                            v-if="selected_contact.phone_number">
                                            Primary
                                        </span>
                                        <span class="label p-1 text-xxs light-blue ml-2"
                                              v-if="selected_contact.lrn_type != null">
                                            {{ selected_contact.lrn_type | fixLrnType }}
                                        </span>
                                        <span class="label p-1 text-xxs danger ml-2"
                                              v-if="selected_contact.is_dnc">
                                            DNC
                                        </span>

                                        <span
                                            v-if="primaryNumber && primaryNumber.is_wrong_number"
                                            class="label p-1 text-xxs red-A400 ml-2">
                                            Wrong Number
                                        </span>

                                        <span
                                            v-if="primaryNumber && primaryNumber.is_invalid"
                                            class="label p-1 text-xxs red-A400 ml-2">
                                            Invalid Number
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div class="text-center contact-info"
                                 v-if="hasPermissionTo('update contact')">
                                <contact-buttons
                                    :contact="selected_contact"
                                    :contact_phone_numbers="contact_phone_numbers"
                                    :selected_phone_number="selected_phone_number"
                                    :contact_id="contact_id"
                                    :campaign_id="selected_campaign_id"
                                    :mention_items="mention_items"
                                    :disable_send_fax_button.sync="disableSendFaxButton"
                                    @changeSelectedPhoneNumber="changeSelectedPhoneNumber"
                                    @add-appointment="addAppointment">
                                </contact-buttons>
                            </div>

                            <div
                                class="row no-gutter b-t mt-2"
                                v-if="selected_contact && !selected_contact.is_dnc && hasPermissionTo('update contact')">
                                <contact-sequence
                                    v-model="selected_contact"
                                    :contact_phone_numbers="contact_phone_numbers"
                                />
                            </div>

                            <div class="contact-details">
                                <el-collapse v-model="activeNames">
                                    <el-collapse-item title="Integration Cards"
                                                      name="integration-cards"
                                                      data-testid="integration-card-contact-item"
                                                      v-if="hubspotEnabled || pipedriveEnabled || goHighLevelEnabled">
                                        <integration-cards :contact="selected_contact"></integration-cards>
                                    </el-collapse-item>

                                    <el-collapse-item title="Guesty Reservations"
                                                      name="guesty-reservations"
                                                      data-testid="guesty-reservation-contact-item"
                                                      v-if="guestyEnabled && showGuestyReservations">
                                        <guesty-reservations :contact="selected_contact"></guesty-reservations>
                                    </el-collapse-item>

                                    <el-collapse-item title="Guesty Messages"
                                                      name="guesty-messages"
                                                      data-testid="guesty-messages-contact-item"
                                                      v-if="guestyEnabled && showGuestyReservations">
                                        <guesty-messages :contact="selected_contact"></guesty-messages>
                                    </el-collapse-item>

                                    <el-collapse-item title="Integrations"
                                                      name="integrations"
                                                      data-testid="integrations-contact-item">
                                        <contact-crm-links :contact="selected_contact"></contact-crm-links>
                                        <div class="pl-3 pr-3"
                                             v-if="integrationsDisabled">
                                            <strong class="text-muted">
                                                Enable your favorite CRM integration and push contacts from the
                                                integration settings page to see links to your CRM contacts.
                                            </strong>
                                        </div>
                                    </el-collapse-item>

                                    <el-collapse-item title="Scheduled Messages"
                                                      name="scheduled-messages"
                                                      data-testid="scheduled-messages-item">
                                        <div class="container text-center my-2 px-4">
                                            <el-button plain
                                                       size="small"
                                                       data-testid="open-schedule-messages-dialog"
                                                       @click="openScheduledMessagesDialog">
                                                <i class="fa fa-list"></i> Show Scheduled Messages
                                            </el-button>
                                        </div>
                                    </el-collapse-item>

                                    <el-collapse-item title="Push Contact to CRM"
                                                      name="push-to-crm"
                                                      data-testid="push-contact-to-crm-item"
                                                      v-if="isPushContactToCrmEnabled">
                                        <contact-push-to-crm :contact="selected_contact"></contact-push-to-crm>
                                    </el-collapse-item>

                                    <el-collapse-item title="Phone Numbers"
                                                      name="phone_numbers"
                                                      data-testid="phone-number-item">
                                        <contact-phone-numbers
                                            ref="contactPhoneNumbers"
                                            :contact="selected_contact"
                                            @message="changeSelectedPhoneNumber"
                                            @updated="setContactPhoneNumbers"
                                            @primary-updated="updateContactPhoneNumber">
                                        </contact-phone-numbers>
                                    </el-collapse-item>

                                    <el-collapse-item title="About this contact"
                                                      name="about"
                                                      data-testid="about-this-contact-item">
                                        <div class="pl-3 pr-3">
                                            <contact-details :contact="selected_contact"
                                                             :first_outbound_call="communications_summary.first_outbound_call"
                                                             @contactUpdated="selectedContactUpdate">
                                            </contact-details>
                                        </div>
                                    </el-collapse-item>

                                    <el-collapse-item
                                        title="Communications Summary"
                                        name="communications"
                                        data-testid="communitactions-summary-item">
                                        <contact-communications-summary
                                            :summary="communications_summary.summaries">
                                        </contact-communications-summary>
                                    </el-collapse-item>

                                    <el-collapse-item title="Contact Lines"
                                                      name="lines"
                                                      data-testid="contact-lines-item">
                                        <div class="pl-3 pr-3">
                                            <contact-lines :contact="selected_contact"></contact-lines>
                                        </div>
                                    </el-collapse-item>

                                    <el-collapse-item title="Contact Ring Groups"
                                                      name="ring-groups"
                                                      data-testid="contact-ring-groups">
                                        <div class="pl-3 pr-3">
                                            <contact-ring-groups :contact="selected_contact"></contact-ring-groups>
                                        </div>
                                    </el-collapse-item>

                                    <el-collapse-item title="Broadcasts"
                                                      name="broadcasts"
                                                      data-testid="broadcast-item">
                                        <div class="pl-3 pr-3">
                                            <contact-broadcasts :contact="selected_contact"></contact-broadcasts>
                                        </div>
                                    </el-collapse-item>
                                </el-collapse>
                            </div>
                        </div>
                    </div>
                    <div class="col pl-2 pr-2"
                         v-loading="loading_contact_communications"
                         v-if="selected_contact">
                        <el-tabs v-model="activeTab"
                                 class="full-height no-header"
                                 data-testid="contact-communication-card"
                                 type="card">
                            <el-tab-pane name="activities"
                                         class="full-height bg-white b-a"
                                         data-testid="contact-communication-pane"
                                         v-if="hasPermissionTo('list communication')">
                                <span slot="label">
                                    <el-badge class="tab-badge"
                                              :value="totalUnreadCount"
                                              :max="99"
                                              data-testid="contact-activities-badge"
                                              :hidden="totalUnreadCount === 0">
                                        <span>Activities</span>
                                    </el-badge>
                                </span>

                                <div class="box row-col no-shadow"
                                     style="min-height:100%">
                                    <div class="row p-2"
                                         v-if="hasPermissionTo('list campaign')">
                                        <div class="col-12">
                                            <div class="pull-left no-select">
                                                <button class="btn btn-sm blue text-sm"
                                                        v-if="totalUnreadCount !== 0"
                                                        :disabled="loading_mark_as_read"
                                                        data-testid="communication-mark-all-as-read-button"
                                                        @click="markAllAsRead">
                                                    <i v-show="loading_mark_as_read"
                                                       class="material-icons loader">
                                                        &#xE863;
                                                    </i>
                                                    Mark All As Read
                                                </button>
                                            </div>

                                            <div class="pull-right no-select">
                                                <strong class="text-xs">Phone Number:</strong>
                                                <el-select v-model="selected_phone_number"
                                                           placeholder="Select a phone number"
                                                           size="mini"
                                                           style="width: 150px"
                                                           class="no-select"
                                                           data-testid="communication-phone-number-select"
                                                           @change="changeSelectedPhoneNumber">
                                                    <el-option
                                                        v-for="phone_number in contact_phone_numbers"
                                                        :key="phone_number.id"
                                                        :label="phone_number.phone_number"
                                                        :value="phone_number.phone_number">
                                                        <span class="pull-left">{{ phone_number.phone_number | fixPhone }}</span>
                                                    </el-option>
                                                </el-select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row-row">
                                        <div class="row-body">
                                            <div class="row-inner"
                                                 ref="activitiesWrap">
                                                <div class="p-a-sm">
                                                    <ul class="timeline timeline-center">
                                                        <li class="tl-header mb-3"
                                                            v-if="has_more_communications">
                                                            <el-button type="primary"
                                                                       size="medium"
                                                                       :loading="is_loading_previous_activities"
                                                                       plain
                                                                       data-testid="load-more-previous-activities-button"
                                                                       @click="loadMorePreviousActivities">
                                                                Previous Activities
                                                            </el-button>
                                                        </li>
                                                    </ul>
                                                    <activity v-for="(communication, index) in filteredCommunications"
                                                              :key="communication.id + '-comm-' + index"
                                                              :ref="(communication.type !== undefined ? 'communication-' : 'contact-audit-') + communication.id"
                                                              :communication="communication"
                                                              :contact="selected_contact"
                                                              :campaign_id="selected_campaign_id">
                                                    </activity>

                                                    <template v-if="is_typing">
                                                        <div class="m-b message">
                                                            <span class="w-40 avatar grey-300 pull-right"
                                                                  v-bind:style="avatarStyle(selected_contact)">
                                                                <span v-if="current_company">{{ current_company.name | fixContactName | initials }}</span>
                                                            </span>

                                                            <div class="clear d-flex flex-column align-items-end text-right">
                                                                <div class="inline r-2x message-body effect7 mt-1 greenish text-left">
                                                                    <span class="arrow pull-top arrow-greenish right"></span>

                                                                    <div class="p-a p-y-sm handle-whitespace">
                                                                        <span>{{ is_typing.message }}</span>
                                                                    </div>
                                                                </div>
                                                                <div class="text-xs m-t-xs width-500 mr-2">
                                                                    <span class="text-muted">
                                                                        from {{ is_typing.campaign_name }}
                                                                    </span>
                                                                    <span class="text-muted">
                                                                        to {{ is_typing.phone_number | fixPhone }}
                                                                    </span>
                                                                    <span class="text-muted">
                                                                        {{ is_typing.user_name }} is typing ...
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </template>

                                                    <div class="el-table__empty-block"
                                                         v-if="filteredCommunications.length == 0">
                                                        <span class="el-table__empty-text"
                                                              style="color: #606266;">
                                                            No Data
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <message-composer ref="message_composer"
                                                      class="border-top"
                                                      v-model="message_object"
                                                      :phone_numbers="contact_phone_numbers"
                                                      :force-disabled="hasRole('Company Reporter Access')"
                                                      v-if="contact_loaded"
                                                      @open-car-form="openCarForm"
                                                      @send-credit-application-link="sendCreditApplicationLink"
                                                      @send-message="sendNewMessage"
                                                      @create-scheduled-message="createScheduledMessage"
                                                      @changed-selected-campaign="changeSelectedCampaign">
                                    </message-composer>
                                </div>
                            </el-tab-pane>
                        </el-tabs>
                    </div>
                </div>
            </div>
        </div>

        <appointment-reminder-manager ref="manager"
                                      called_from="contact"
                                      data-testid="contact-appointment-reminder-manager"
                                      :contact="selected_contact">
        </appointment-reminder-manager>

        <contact-scheduled-messages-list :contact="contact"
                                         data-testid="contact-scheduled-messages-list"
                                         :contact_id="contact_id">
        </contact-scheduled-messages-list>

        <contact-merge-dialog
            data-testid="contact-merge-dialog"
            :contact="selected_contact">
        </contact-merge-dialog>
    </div>
</template>

<script>
import auth from '../../auth'
import {mapActions, mapGetters, mapState} from 'vuex'
import {
    acl_mixin,
    avatar_mixin,
    contact_activities_mixin,
    contact_phone_number_mixin,
    form_validation_mixin,
    styling_mixin,
    validator_mixin,
    supervisor_mixin
} from '../../mixins'
import cloneDeep from "lodash/cloneDeep"
import * as Carriers from '../../constants/carriers'
import VariableDialog from '../../components/variable-dialog'
import TemplateListDialog from "../../components/template-list-dialog"
import ContactCrmLinks from '../../components/contact-crm-links'
import IntegrationCards from '../../components/integration-cards'
import ContactPushToCrm from '../../components/contact-push-to-crm'
import ContactRingGroups from '../../components/contact-ring-groups'
import ContactLines from '../../components/contact-lines'
import ContactPhoneNumbers from '../../components/contact-phone-numbers'
import DigitalClock from '../../components/digital-clock'
import SearchGiphy from '../../components/search-giphy'
import NewEmail from '../../components/new-email'
import NewCar from '../../components/new-car'
import ContactInfoForm from '../../components/contact-info-form'
import ContactBroadcasts from '../../components/contact-broadcasts'
import ContactCommunicationsSummary from '../../components/contact-communications-summary'
import AppointmentReminderManager from '../../components/calendar/appointment-reminder-manager'
import MessageComposer from "../../components/messenger/message-composer"
import ContactScheduledMessagesList from "../../components/messages/contact-scheduled-messages-list"
import WorkflowBulkEnroller from "../../components/automations/workflow-bulk-enroller"
import ContactMergeDialog from "../../components/contact-merge-dialog"
import ContactSequence from "../../components/contact/contact-sequence"
import {CALL, SMS} from "../../constants/communication-types"
import {INBOUND, OUTBOUND} from "../../constants/communication-direction"
import GuestyReservations from "../../components/guesty-reservations"
import GuestyMessages from "../../components/guesty-messages"

export default {
    mixins: [
        styling_mixin,
        validator_mixin,
        acl_mixin,
        avatar_mixin,
        contact_activities_mixin,
        form_validation_mixin,
        contact_phone_number_mixin,
        supervisor_mixin
    ],

    components: {
        ContactSequence,
        WorkflowBulkEnroller,
        ContactScheduledMessagesList,
        MessageComposer,
        ContactBroadcasts,
        ContactCommunicationsSummary,
        ContactCrmLinks,
        ContactPushToCrm,
        IntegrationCards,
        ContactPhoneNumbers,
        TemplateListDialog,
        DigitalClock,
        VariableDialog,
        SearchGiphy,
        NewEmail,
        NewCar,
        ContactRingGroups,
        ContactLines,
        ContactInfoForm,
        AppointmentReminderManager,
        ContactMergeDialog,
        GuestyReservations,
        GuestyMessages,
    },

    props: {
        contact_id: {
            required: false
        },

        embedded: {
            type: Boolean,
            default: false,
            required: false
        },

        force_full_height: {
            type: Boolean,
            default: false,
            required: false
        }
    },

    data() {
        return {
            env: null,
            statics: {
                logo: null,
                logo_inverse: null,
                logo_square: null,
                logo_square_inverse: null,
                host: null,
                referer: null,
                name: '',
                domain: null,
                whitelabel: false,
                path: null
            },
            loading_whitelabel: true,
            activeTab: 'activities',
            activeNames: ['phone_numbers', 'about', 'lines', 'ring-groups', 'scheduled-messages', 'communications'],
            disposition_status_id: null,
            loading_enroll_contact: false,
            loading_disenroll_contact: false,
            disable_enroll_contact: true,
            enroll_popover_visibility: false,
            edit: {
                name: false
            },
            is_center: true,
            sendFaxDialogVisible: false,
            loading_send_fax_btn: false,
            rules_fax: {
                file_name: [
                    {
                        required: true,
                        message: 'Please upload your PDF file',
                        trigger: 'change'
                    }
                ]
            },
            rules_enroll: {
                workflow_id: [
                    {
                        required: true,
                        message: 'Please select a sequence',
                        trigger: 'change'
                    }
                ]
            },
            rules_edit_contact: {
                first_name: [
                    {
                        validator: this.firstNameRequiredValidator,
                        trigger: 'blur'
                    }
                ],
                last_name: [
                    {
                        validator: this.firstNameRequiredValidator,
                        trigger: 'blur'
                    }
                ]
            },
            fax: {
                file_name: null
            },
            enroll: {
                workflow_id: null
            },
            prop_counter: 0,
            contact: {
                first_name: '',
                last_name: ''
            },
            contact_save_button_disabled: true,
            contact_list_pagination_loading: false,
            contacts: [],
            source: null,
            is_typing: null,
            is_loading_previous_activities: false,
            previous_route: null,
            disable_send_fax_button: false,
            mention_items: [],
            Carriers,
            // push to CRM feature exclusive for ss, just in case new company is added, then just add the reseller id here
            reseller_id_to_push_contact_to_crm: [357],
            message_object: {
                phone_number: null
            },
            contact_loaded: false
        }
    },

    computed: {
        totalUnreadCount() {
            if (!this.selected_contact) {
                this.setPageTitleInfoText('');
                return 0
            }

            let total = this.selected_contact.unread_count + this.selected_contact.unread_voicemail_count + this.selected_contact.unread_missed_call_count;

            if (total > 0) {
                this.setPageTitleInfoText('(' + total + ')')
            } else {
                this.setPageTitleInfoText('')
            }

            return total
        },

        hubspotEnabled() {
            return this.current_company && this.current_company.hubspot_integration_enabled
        },

        pipedriveEnabled() {
            return this.current_company && this.current_company.pipedrive_integration_enabled
        },

        goHighLevelEnabled() {
            return this.current_company && this.current_company.gohighlevel_integration_enabled
        },

        guestyEnabled() {
            return this.current_company && this.current_company.guesty_integration_enabled
        },

        integrationsDisabled() {
            if (!this.current_company) {
                return true
            }

            if (this.current_company.id == 460) {
                return false
            }

            return !this.current_company.pipedrive_integration_enabled && !this.current_company.hubspot_integration_enabled && !this.current_company.stripe_integration_enabled && !this.current_company.zoho_integration_enabled && !this.current_company.helpscout_integration_enabled && !this.current_company.guesty_integration_enabled && !this.current_company.gohighlevel_integration_enabled
        },

        isLargeEnough() {
            let valid_sizes = ['xxl', 'xl', 'lg', 'md']
            if (valid_sizes.includes(this.$mq)) {
                return true
            }
            return false
        },

        uniqueContacts() {
            if (this.contacts.length) {
                return _.uniqBy(this.contacts, 'id')
            }
            return this.contacts
        },

        getPlaceholder() {
            if (this.is_typing) {
                return this.is_typing.user_name + ' is typing ...'
            }

            return 'Message ...'
        },

        contactBasic() {
            if (this.selected_contact.id) {
                return this.selected_contact
            }

            return {
                first_name: '',
                last_name: ''
            }
        },

        getClass() {
            if (this.isLargeEnough && !this.embedded && !this.force_full_height) {
                return 'big-screen'
            }

            if (this.isLargeEnough && this.embedded && !this.force_full_height) {
                return 'embedded-screen'
            }

            if (this.isLargeEnough && this.force_full_height) {
                return 'big-screen force-full-height'
            }

            return ''
        },

        isPushContactToCrmEnabled() {
            if (!this.current_company || !this.current_company.reseller_id) {
                return false
            }

            if (!this.reseller_id_to_push_contact_to_crm.includes(this.current_company.reseller_id)) {
                return false
            }

            return true
        },

        primaryNumber() {
            let numbers = this.contact_phone_numbers

            // search the phone number data from the collection of contact's phone number based on the phone number
            if (numbers && numbers.length) {
                return numbers.find(num => num.is_primary)
            }

            return null
        },

        disableSendFaxButton() {
            return this.selectedCampaign ? !this.selectedCampaign.is_fax : true
        },

        showGuestyReservations() {
            return this.current_company.is_multi_guesty
        },

        ...mapGetters({
            filter: 'getFilter',
            contact_list_contacts: 'getContacts',
            pagination: 'getContactsPagination'
        }),

        ...mapState('cache', ['live_update_contacts']),
        ...mapState(['workflows']),
    },

    async created() {
        this.source = axios.CancelToken.source()
        if (this.$route.query.disposition_status_id) {
            this.disposition_status_id = parseInt(this.$route.query.disposition_status_id)
        }

        if (this.live_update_contacts) {
            VueEvent.listen('typing', (data) => {
                // make sure we have all the data
                if (!this.selected_contact || !this.auth || !this.auth.user || !this.auth.user.profile || !this.contact_id || !data.contact_id || !data.user_id) {
                    return false
                }

                // checks if contact is the same
                if (this.selected_contact.id != data.contact_id) {
                    return false
                }

                // checks if user is not the same
                if (this.auth?.user?.profile?.id == data.user_id) {
                    return false
                }

                this.is_typing = data
                this.scrollMessages()


                setTimeout(_ => {
                    this.is_typing = null
                }, 15000)
            })
        }

        this.getStatics()

        this.message_object = {
            body: this.reply_text
        }
    },

    async mounted() {
        // fill out authorization header
        auth.check().then((res) => {
            this.headers.Authorization = 'Bearer ' + this.auth.user.profile.api_token
        })

        this.initType()

        if (!this.checkSize()) {
            this.is_center = false
        }

        if (this.contact_id) {
            if (this.selected_contact.id !== this.contact_id) {
                if (!this.hasPermissionTo('view contact')) {
                    this.resetSelectedContact()
                    this.goBack()
                } else {
                    this.loading_contact = true
                    this.processFetchContactInfo()
                    this.getCommunicationsSummary(this.contact_id)
                }
            }
        }

        // contact list was not created/initialized
        // we have to fetch the contacts using the default filter
        if (localStorage.getItem('PREVIOUS_ROUTE_NAME') === 'null') {
            this.getContacts()
                .catch(err => {
                    console.log(err)
                })
        } else {
            // get the contact list's contacts
            this.contacts = this.contact_list_contacts
        }

        if (this.hasPermissionTo('update contact')) {
            await this.fetchUsersWithAccessToContact()
        }

        VueEvent.listen('open-send-email-dialog', () => {
            this.openEmailForm()
        })

        VueEvent.listen('open-send-fax-dialog', () => {
            this.openSendFaxModal()
        })

        // listens to newly communication added
        // used in communications count summary
        VueEvent.listen('new_communication', communication => {
            // checks if the new comm is not from the current contact, then return
            if (communication.contact_id != this.contact_id) {
                return
            }

            this.communications_summary.summaries.total_count++
            let type = communication.type
            let direction = communication.direction

            if (type === CALL && direction === INBOUND) {
                this.communications_summary.summaries.inbound_calls_count++
            } else if (type === CALL && direction === OUTBOUND) {
                this.communications_summary.summaries.outbound_calls_count++

                // if no outbound call and the new com. is an outbound call, assign.
                if (!this.communications_summary.first_outbound_call) {
                    this.communications_summary.first_outbound_call = communication
                }
            } else if (type === SMS && direction === INBOUND) {
                this.communications_summary.summaries.inbound_texts_count++
            } else if (type === SMS && direction === OUTBOUND) {
                this.communications_summary.summaries.outbound_texts_count++
            }
        })

        VueEvent.listen('contact_phone_number_flagged_as_invalid', data => {
            let contact_phone_number = data.contact_phone_number

            if (contact_phone_number && contact_phone_number.contact_id == this.contact_id) {
                this.updatePhoneNumbers(contact_phone_number)

                this.$notify({
                    offset: 95,
                    title: 'Contact',
                    message: 'Invalid phone number.',
                    type: 'error',
                    showClose: true,
                })
            }
        })

        this.message_object.phone_number = this.selected_phone_number
    },

    methods: {
        updatePhoneNumbers(contact_phone_number) {
            let phone_numbers = cloneDeep(this.contact_phone_numbers)
            let index = phone_numbers.findIndex(pn => pn.id === contact_phone_number.id)

            if (index > -1) {
                phone_numbers.splice(index, 1, contact_phone_number)

                if (this.$refs.contactPhoneNumbers) {
                    this.$refs.contactPhoneNumbers.setPhoneNumbers(phone_numbers)
                }
            }
        },

        openScheduledMessagesDialog() {
            VueEvent.fire('load_scheduled_messages_list')
        },

        async fetchUsersWithAccessToContact() {
            try {
                const {data: users} = await axios.get(`/api/v1/user/get-users-with-access-to-contact?contact_id=${this.contact_id}`)
                this.mention_items = users
            } catch (error) {
                console.error(error.message)
                this.$notify({
                    offset: 95,
                    title: 'Contact',
                    message: 'Something went wrong. Please refresh the page and try again.',
                    type: 'error',
                    showClose: true,
                })
            }
        },

        addAppointment() {
            this.$refs.manager.addSchedule()
        },

        processFetchContactInfo() {
            this.loadingContactInProgress()
            this.fetchContactInfo().then(res => {
                this.processFetchedContactInfo(res.data)
            }).catch(() => {
                this.loadingContactsFailed()
            })
        },

        loadingContactInProgress() {
            if (!this.integrationsDisabled) {
                this.activeNames.push('integrations')
                this.activeNames.push('integration-cards')
            } else {
                this.activeNames = this.activeNames.filter(name => name != 'integrations' && name != 'integration-cards')
            }

            if (this.isPushContactToCrmEnabled) {
                this.activeNames.push('push-to-crm')
            } else {
                this.activeNames = this.activeNames.filter(name => name != 'push-to-crm')
            }

            if (this.guestyEnabled) {
                this.activeNames.push('guesty-reservations')
                this.activeNames.push('guesty-messages')
            } else {
                this.activeNames = this.activeNames.filter(name => name != 'guesty-reservations')
                this.activeNames = this.activeNames.filter(name => name != 'guesty-messages')
            }

            this.resetSelectedContact()
        },

        processFetchedContactInfo(selected_contact) {
            this.message_object.contact = selected_contact
            this.fetchedContactInfo(selected_contact)
            this.contact.first_name = selected_contact.first_name
            this.contact.last_name = selected_contact.last_name
            this.updateBreadcrumbContactName(this.contact)
            this.contact_phone_numbers = []
            VueEvent.fire('contact_selected', this.contact_id)
            if (localStorage.getItem('PREVIOUS_ROUTE_NAME') !== 'Contacts') {
                this.loading_contact = false
            }
            this.contact_loaded = true
        },

        loadingContactsFailed() {
            if (localStorage.getItem('PREVIOUS_ROUTE_NAME') === 'Contact') {
                this.goToNextContact()
                return
            }
            this.backToContacts(true)
        },

        loadMoreContacts() {
            if (this.pagination && this.pagination.to && this.filter.page <= this.pagination.to) {
                this.filter.page += 1
                this.getContacts()
                    .then(res => {
                        this.loading_contact = false
                        this.$router.push({
                            name: 'Contact',
                            params: {contact_id: res.data.data[0].id}
                        }).catch(err => {
                        })
                    })
                    .catch(err => {
                        this.loading_contact = false
                    })
            } else {
                this.loading_contact = false
            }
        },

        backToContacts(is_normal_route = false) {
            // if not called on page refresh, just go back to contacts page
            if (is_normal_route) {
                this.$router.push({name: 'Contacts'})
                    .catch(err => {
                        console.log(err)
                    })
                return
            }

            const previous_route_path = _.get(this.previous_route, 'path', null)

            // use route history to go back if we have
            // previous route info
            if (previous_route_path && previous_route_path.includes('contacts')) {
                this.goBack()
                return
            }

            // else, just go to contacts page
            this.$router.push({name: 'Contacts'})
                .catch(err => {
                    console.log(err)
                })
        },

        goToNextContact($event = null) {
            if (this.uniqueContacts.length === 0) {
                return
            }
            if ($event) {
                $event.target.blur()
                if (this.$refs.nextContact) {
                    this.$refs.nextContact.$el.blur()
                }
            }
            // previous route name is null or route started from a null previous route name
            if (localStorage.getItem('PREVIOUS_ROUTE_NAME') === 'null' || (localStorage.getItem('PREVIOUS_ROUTE_NAME') !== 'null' && typeof this.$route.meta.prev !== 'undefined')) {
                // this will use the component's contact list
                // since the contact list was not initialized/created
                let current_contact_index = this.uniqueContacts.findIndex(con => con.id === parseInt(this.contact_id))
                // if contact's index was not found, fallback to first contact
                if (current_contact_index === -1) {
                    let contact_array_keys = Object.keys(this.uniqueContacts)
                    current_contact_index = parseInt(contact_array_keys[0])
                } else if (this.uniqueContacts[(current_contact_index + 1)]) {
                    // if incremented index is found, then it's safe to use it
                    current_contact_index += 1
                }

                let next_contact = this.uniqueContacts[current_contact_index]
                // don't continue if next contact id is the same as the current contact id
                if (!next_contact || (next_contact && this.contact_id == next_contact.id)) {
                    return
                }

                let new_meta = this.$route.meta
                new_meta.prev = ''
                this.$router.push({
                    name: 'Contact',
                    params: {contact_id: next_contact.id},
                    meta: new_meta
                }).catch(err => {
                    // if goToNextContact is triggered from loadingContactsFailed,
                    // go back to contacts normally
                    this.backToContacts((!$event ? true : false))
                })
            } else {
                if (this.contacts.length < this.contact_list_contacts.length) {
                    this.contacts = this.contact_list_contacts
                }
                if (this.uniqueContacts.length === 0) {
                    return
                }

                let current_contact_index = this.uniqueContacts.findIndex(con => con.id === parseInt(this.contact_id))
                if (current_contact_index === (this.contacts.length - 1)) {
                    this.loading_contact = true
                    this.loadMoreContacts()
                } else {
                    // contacts' updated value doesn't reflect in contacts list
                    // if contact's index was not found, fallback to first contact
                    if (current_contact_index === -1) {
                        let contact_array_keys = Object.keys(this.uniqueContacts)
                        current_contact_index = parseInt(contact_array_keys[0])
                    } else if (this.uniqueContacts[(current_contact_index + 1)]) {
                        // if incremented index is found, then it's safe to use it
                        current_contact_index += 1
                    }

                    const next_contact = this.uniqueContacts[current_contact_index]
                    // don't continue if next contact id is the same as the current contact id
                    if (!next_contact || (next_contact && this.contact_id == next_contact.id)) {
                        return
                    }

                    // we have to stop the loop after 3 rounds (if this happens)
                    if (this.$route.meta.contact_loop_count && this.$route.meta.contact_loop_count >= 3) {
                        this.$notify({
                            offset: 95,
                            title: 'Contact',
                            dangerouslyUseHTMLString: true,
                            message: 'Prevented a contact route loop.',
                            type: 'error',
                            showClose: true,
                        })
                        // if goToNextContact is triggered from loadingContactsFailed,
                        // go back to contacts normally
                        this.backToContacts((!$event ? true : false))
                        return
                    }

                    let is_same_contact = this.$route.meta.contact_id && this.$route.meta.contact_id === this.contact_id ? true : false
                    this.$route.meta.contact_id = !this.$route.meta.contact_id || !is_same_contact ? this.contact_id : this.$route.meta.contact_id
                    this.$route.meta.contact_loop_count = is_same_contact ? (this.$route.meta.contact_loop_count + 1) : 0
                    this.$router.push({
                        name: 'Contact',
                        params: {contact_id: next_contact.id, meta: this.$route.meta}
                    }).catch(err => {
                        // if goToNextContact is triggered from loadingContactsFailed,
                        // go back to contacts normally
                        this.backToContacts((!$event ? true : false))
                    })
                }
            }
        },

        goToPreviousContact($event = null) {
            if (this.uniqueContacts.length === 0) {
                return
            }
            $event.target.blur()
            if (this.$refs.previousContact) {
                this.$refs.previousContact.$el.blur()
            }
            // previous route name is null or route started from a null previous route name
            if (localStorage.getItem('PREVIOUS_ROUTE_NAME') === 'null' || (localStorage.getItem('PREVIOUS_ROUTE_NAME') !== 'null' && typeof this.$route.meta.prev !== 'undefined')) {
                // this will use the component's contact list
                // since the contact list was not initialized/created
                const current_contact = this.uniqueContacts.find((contact) => contact.id === parseInt(this.contact_id))
                let current_contact_index = this.uniqueContacts.indexOf(current_contact)
                // if contact's index was not found, fallback to first contact
                if (current_contact_index === -1) {
                    let contact_array_keys = Object.keys(this.uniqueContacts)
                    current_contact_index = parseInt(contact_array_keys[0])
                } else if (this.uniqueContacts[(current_contact_index - 1)]) {
                    // if decremented index is found, then it's safe to use it
                    current_contact_index -= 1
                }

                const previous_contact = this.uniqueContacts[current_contact_index]
                // don't continue if previous contact id is the same as the current contact id
                if (!previous_contact || (previous_contact && this.contact_id == previous_contact.id)) {
                    return
                }

                let new_meta = this.$route.meta
                new_meta.prev = ''
                this.$router.push({
                    name: 'Contact',
                    params: {contact_id: previous_contact.id},
                    meta: new_meta
                }).catch(err => {
                    this.backToContacts()
                })
            } else {
                if (this.contacts.length < this.contact_list_contacts.length) {
                    this.contacts = this.contact_list_contacts
                }
                if (this.uniqueContacts.length === 0) {
                    return
                }
                // contacts' updated value doesn't reflect in contacts list
                const current_contact = this.uniqueContacts.find((contact) => contact.id === this.contact_id)
                let current_contact_index = this.uniqueContacts.indexOf(current_contact)
                // if contact's index was not found, fallback to first contact
                if (current_contact_index === -1) {
                    let contact_array_keys = Object.keys(this.uniqueContacts)
                    current_contact_index = parseInt(contact_array_keys[0])
                } else if ((current_contact_index - 1) >= 0) {
                    // if decremented index is found, then it's safe to use it
                    current_contact_index -= 1
                }

                const previous_contact = this.uniqueContacts[current_contact_index]
                // don't continue if previous contact id is the same as the current contact id
                if (!previous_contact || (previous_contact && this.contact_id == previous_contact.id)) {
                    return
                }

                this.$router.push({
                    name: 'Contact',
                    params: {
                        contact_id: previous_contact.id
                    }
                }).catch(err => {
                    this.backToContacts()
                })
            }
        },

        getContacts: function () {
            this.contact_list_pagination_loading = true
            let params = this.filter
            params.per_page = 20
            return axios.get('/api/v1/contact', {
                params: params,
                cancelToken: this.source.token
            }).then(res => {
                this.setContacts(_.union(this.contacts, res.data.data))
                this.setContactsPagination(res.data)
                this.contacts = this.contact_list_contacts
                this.contact_list_pagination_loading = false
                return Promise.resolve(res)
            }).catch(err => {
                this.$root.handleErrors(err.response)
                this.contact_list_pagination_loading = false
                if (axios.isCancel(err)) {
                    console.log('Request canceled', err.message)
                    // return Promise.reject(err)
                } else {
                    console.log(err)
                    return Promise.reject(err)
                }
            })
        },

        initType() {
            if (this.$route.query.activity_type) {
                this.type = parseInt(this.$route.query.activity_type)
            }
        },

        getStatics() {
            this.loading_whitelabel = true
            axios.get('/get-statics').then(res => {
                this.statics = res.data
                if (!this.embedded) {
                    this.selected_contact
                        ? this.setPageTitle(this.$options.filters.fixContactName(this.selected_contact.name) + ' - ' + this.statics.name)
                        : this.setPageTitle('Contacts - ' + this.statics.name)
                }
                this.loading_whitelabel = false
            }).catch(err => {
                if (!this.embedded) {
                    this.selected_contact
                        ? this.setPageTitle(this.$options.filters.fixContactName(this.selected_contact.name) + ' - ' + this.statics.name)
                        : this.setPageTitle('Contacts - Aloware')
                }
                console.log(err)
                this.$root.handleErrors(err.response)
                this.loading_whitelabel = false
            })
        },

        getTimeZone(timezone) {
            return timezone || window.timezone
        },

        blur(event) {
            event.target.blur()
        },

        checkSize() {
            return this.isLargeEnough
        },

        updateContactInCommunication(contact) {
            if (this.communication && typeof this.communication.contact != 'undefined') {
                this.communication.contact.name = `${contact.first_name} ${contact.last_name}`
            }
        },

        openSendFaxModal() {
            this.sendFaxDialogVisible = true
            this.resetSendMediaContactsForm('fax')
        },

        closeSendFaxModal() {
            this.sendFaxDialogVisible = false
            this.resetSendMediaContactsForm('fax')
        },

        beforeCloseSendFaxModal(done) {
            this.$confirm('Are you sure you want to leave? You have not faxed anything yet', 'Warning', {
                confirmButtonText: 'Yes, Leave',
                cancelButtonText: 'No, Stay',
                customClass: 'width-500 fixed',
                type: 'warning'
            }).then(res => {
                done()
            }).catch(err => {
                console.log(err)
            })
        },

        onSuccessSendFax(res) {
            this.$notify({
                offset: 95,
                title: 'PDF Upload',
                message: 'PDF file has been uploaded successfully.',
                type: 'success',
                showClose: true,
            })
            this.$set(this.fax, 'file_name', res.file_name)
            this.uploadStatus.upload = 'success'
            this.preValidateForm('fax')
        },

        onFailedSendFax(err) {
            this.$root.handleUploadErrors(err.message)
            this.uploadStatus.upload = 'exception'
            this.uploadPercentage.upload = 0
            this.preValidateForm('fax')
        },

        beforeUploadSendFax() {
            this.uploadStatus.upload = 'success'
            this.uploadPercentage.upload = 0
        },

        progressUploadSendFax(event) {
            this.uploadPercentage.upload = parseInt(event.percent)
        },

        sendFax() {
            if (this.selectedCampaign && this.validateForm('fax') == true) {
                this.loading_send_fax_btn = true
                this.fax.phone_number = this.selected_phone_number
                axios.post('/api/v1/campaign/send-fax/' + this.selectedCampaign.id + '/' + this.selected_contact.id, this.fax)
                    .then(res => {
                        this.loading_send_fax_btn = false
                        this.resetSendMediaContactsForm('fax')
                        this.sendFaxDialogVisible = false
                        this.uploadPercentage.upload = 0
                        this.uploadStatus.upload = 'success'
                        this.uploadFileList.upload = []
                    })
                    .catch(err => {
                        console.log(err)
                        this.$root.handleErrors(err.response)
                        this.resetSendMediaContactsForm('fax')
                        this.loading_send_fax_btn = false
                    })
            } else {
                return false
            }
        },

        openEmailForm() {
            if (this.$refs.newEmailMessage) {
                this.$refs.newEmailMessage.hide_add = true
                this.$refs.newEmailMessage.send_email.campaign_id = this.selected_campaign_id

                // autofocus subject field when the modal is open
                this.$nextTick(_ => {
                    this.$refs.newEmailMessage.$refs.subject ? this.$refs.newEmailMessage.$refs.subject.focus() : null
                })
            }
        },

        openCarForm() {
            this.$refs.newCarMessage.startInventory(this.selected_campaign_id)
        },

        sendCreditApplicationLink() {
            axios.post(`/integrations/simpsocial/credit-application-to-contact/${this.selected_contact.id}`, {
                campaign_id: this.selected_campaign_id
            }).then(res => {
                console.log(res.data)
            }).catch(err => {
                console.log(err)
            })
        },

        newCarFormClosed() {
            this.prop_counter += 1
        },

        changeEnrollingWorkflow(val) {
            this.enroll.workflow_id = val
            this.disable_enroll_contact = !this.validateForm('enroll_contact')
        },

        resetContactEnroll() {
            this.enroll.workflow_id = null
            this.resetForm('enroll_contact')
            this.disable_enroll_contact = true
        },

        enrollContact() {
            if (this.validateForm('enroll_contact') == true) {
                this.loading_enroll_contact = true
                axios.post('/api/v1/contact/' + this.selected_contact.id + '/workflow/' + this.enroll.workflow_id + '/enroll')
                    .then((res) => {
                        this.loading_enroll_contact = false
                        this.enroll_popover_visibility = false
                        this.resetContactEnroll()
                        this.$notify({
                            offset: 95,
                            title: 'Sequence',
                            message: res.data.message,
                            type: 'success',
                            showClose: true,
                        })
                    })
                    .catch((err) => {
                        this.loading_enroll_contact = false
                        this.resetContactEnroll()
                        this.$root.handleErrors(err.response)
                    })
            } else {
                return false
            }
        },

        setContactPhoneNumbers(phone_numbers) {
            this.contact_phone_numbers = phone_numbers

            if (this.$refs.message_composer) {
                this.$refs.message_composer.contact_phone_numbers = cloneDeep(phone_numbers)
            }
        },

        updateContactPhoneNumber(phone_number) {
            this.selected_contact.phone_number = cloneDeep(phone_number.phone_number)
        },

        selectedContactUpdate(contact) {
            this.updateSelectedContact(contact)
            this.updateBreadcrumbContactName(contact)
        },

        preventSubmit() {
            return false
        },

        updateBreadcrumbContactName(contact) {
            if (!this.loading_contact) {
                let full_name = contact && contact.first_name ? contact.first_name + ' ' : ''
                full_name += contact && contact.last_name ? contact.last_name : ''
                full_name = full_name ? full_name : 'No Name'
                VueEvent.fire('contact_name_breadcrumb', this.$options.filters.ucwords(full_name))
            }
        },

        isTyping: _.debounce(function () {
            if (!this.selected_contact || !this.selected_contact.id || !this.auth.user || !this.auth.user.profile || this.reply_text.length == 0) {
                return
            }

            let channel = Echo.private('company-' + this.auth.user.profile.company_id)
            channel.whisper('typing', {
                contact_id: this.selected_contact.id,
                user_id: this.auth.user.profile.id,
                user_name: this.auth.user.profile.name,
                campaign_name: this.selectedCampaign ? this.selectedCampaign.name : '',
                phone_number: this.selected_phone_number,
                message: this.reply_text,
                typing: true
            })
        }, 1000),

        processResetSelectedContact() {
            this.resetSelectedContact()
            this.is_typing = null
        },

        sendNewMessage(message_object) {
            if (this.$refs.message_composer) {
                this.$refs.message_composer.isLoading()
                axios.post('/api/v1/messages', message_object)
                    .then(res => {
                        this.message_object = {
                            phone_number: null
                        }
                        this.$refs.message_composer.isLoading(false)
                        this.$refs.message_composer.resetStaticFields()
                    })
                    .catch(err => {
                        if (this.$refs.message_composer) {
                            this.$refs.message_composer.isLoading(false)
                        }
                        if (err.response) {
                            if (err.response.status === 422) {
                                this.$root.handleSingleValidationError(err.response.data.errors, 'Messaging Error')
                            } else if (err.response.status === 403) {
                                this.$root.handleErrors(err.response, 'Permission Error')
                            }
                        }
                    })
            }
        },

        createScheduledMessage(message_object) {
            if (this.$refs.message_composer) {
                this.$refs.message_composer.isLoading()
                axios.post('/api/v1/scheduled-messages', message_object)
                    .then(res => {
                        this.message_object = {
                            phone_number: null
                        }
                        this.$refs.message_composer.isLoading(false)
                        this.$refs.message_composer.resetStaticFields()
                        this.$notify({
                            offset: 95,
                            title: 'Contact',
                            message: 'Message is scheduled to be sent',
                            type: 'success',
                            showClose: true,
                        })
                    })
                    .catch(err => {
                        if (this.$refs.message_composer) {
                            this.$refs.message_composer.isLoading(false)
                        }

                        if (err.response && err.response.status === 422) {
                            this.$notify.error({
                                title: 'Error',
                                message: err.response.data.message
                            })
                        }
                    })
            }
        },

        changeSelectedCampaign(campaign_id) {
            this.selected_campaign_id = campaign_id
        },
        ...mapActions(['setContacts', 'setContactsPagination'])
    },

    watch: {
        'contact.first_name': function () {
            this.preValidateForm('edit_contact')
            if (this.contact_save_button_disabled && this.contact.first_name !== this.selected_contact.first_name) {
                this.contact_save_button_disabled = false
            } else if (!this.contact_save_button_disabled && this.contact.first_name === this.selected_contact.first_name && this.contact.last_name === this.selected_contact.last_name) {
                this.contact_save_button_disabled = true
            }
        },

        'contact.last_name': function () {
            this.preValidateForm('edit_contact')
            if (this.contact_save_button_disabled && this.contact.last_name !== this.selected_contact.last_name) {
                this.contact_save_button_disabled = false
            } else if (!this.contact_save_button_disabled && this.contact.first_name === this.selected_contact.first_name && this.contact.last_name === this.selected_contact.last_name) {
                this.contact_save_button_disabled = true
            }
        },

        'filteredCommunications': function () {
            if (this.filteredCommunications.length > 0 && localStorage.getItem('PREVIOUS_ROUTE_NAME') === 'Contacts') {
                this.loading_contact = false
            }
        },

        $route({params, query}) {
            if (this.$route.query.activity_type) {
                this.type = this.$route.query.activity_type
            }

            if (this.contact_id) {
                if (this.selected_contact && this.selected_contact.id != this.contact_id) {
                    if (!this.hasPermissionTo('view contact')) {
                        this.processResetSelectedContact()
                        this.goBack()
                    } else {
                        this.loading_contact = true
                        this.processFetchContactInfo()
                    }
                } else if (this.$route.meta.contact_id) {
                    this.loading_contact = true
                    this.processFetchContactInfo()
                }
            } else {
                this.processResetSelectedContact()
            }
        },

        selected_phone_number() {
            if (this.$refs.message_composer) {
                this.$refs.message_composer.setPhoneNumber(this.selected_phone_number)
            }
        },

        selected_contact(val) {
            if (val) {
                if (!this.embedded) {
                    this.setPageTitle(this.$options.filters.fixContactName(val.name) + ' - ' + this.statics.name)
                }
            }
        },
    },

    beforeRouteEnter(to, from, next) {
        auth.check().then((res) => {
            if (res.data.user.is_reseller) {
                // redirect to account management portal if the company is a reseller
                next({name: 'Account Management Portal'})
            } else {
                next(vm => {
                    vm.previous_route = from
                })
            }
        }).catch((err) => {
            next({name: 'Login', query: {redirect: to.fullPath}})
        })
    },

    beforeCreate() {
        axios.get('/build-info.json').then(res => {
            this.env = res.data.env
        }).catch(err => {
            console.log(err)
        })
    },

    beforeDestroy() {
        // destroy listeners
        VueEvent.unlisten('contact_phone_number_flagged_as_invalid')
    },

    destroyed() {
        VueEvent.unlisten('typing')
    }
}
</script>
