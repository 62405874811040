var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "container-listing" }, [
    _c("div", { staticClass: "container-header d-flex mb-0" }, [
      _c("div", { staticClass: "mr-auto" }, [
        _c(
          "div",
          { staticClass: "d-flex" },
          [
            _c(
              "el-button",
              {
                attrs: { type: "info", round: "", size: "medium" },
                on: {
                  click: function ($event) {
                    return _vm.$router.push({ name: "Chatbots" })
                  },
                },
              },
              [
                _c("i", {
                  staticClass: "fa fa-chevron-left mr-1",
                  attrs: { "aria-hidden": "true" },
                }),
                _vm._v(" "),
                _c("strong", [_vm._v("Back")]),
              ]
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _vm.chatbot
        ? _c(
            "div",
            { staticClass: "ml-auto vertical-center mr-3" },
            [
              _vm.chatbot.build_status === _vm.BUILD_IN_PROGRESS
                ? _c(
                    "el-tooltip",
                    {
                      attrs: {
                        effect: "dark",
                        content: "Saving changes...",
                        placement: "top",
                      },
                    },
                    [
                      _c(
                        "i",
                        {
                          staticClass:
                            "material-icons hover-lower-opacity-primary text-primary",
                        },
                        [
                          _vm._v(
                            "\n                    cloud_upload\n                "
                          ),
                        ]
                      ),
                    ]
                  )
                : _vm.chatbot.build_status !== _vm.BUILD_SUCCESS ||
                  _vm.edit_icon_visible
                ? _c("div", [
                    _c("small", [_vm._v("Unsaved changes")]),
                    _vm._v(" "),
                    _c("i", { staticClass: "material-icons" }, [
                      _vm._v(
                        "\n                    edit_square\n                "
                      ),
                    ]),
                  ])
                : _c(
                    "el-tooltip",
                    {
                      attrs: {
                        effect: "dark",
                        content: "Your changes are saved",
                        placement: "top",
                      },
                    },
                    [
                      _c(
                        "i",
                        {
                          staticClass:
                            "material-icons hover-lower-opacity text-success",
                        },
                        [
                          _vm._v(
                            "\n                    cloud_done\n                "
                          ),
                        ]
                      ),
                    ]
                  ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.chatbot
        ? _c(
            "div",
            { staticClass: "vertical-center mr-3" },
            [
              !_vm.chatbot.enabled
                ? _c(
                    "el-tooltip",
                    {
                      attrs: {
                        effect: "dark",
                        content: "This chatbot is paused",
                        placement: "top",
                      },
                    },
                    [
                      _c(
                        "i",
                        {
                          staticClass:
                            "material-icons hover-lower-opacity-warning text-warning",
                        },
                        [
                          _vm._v(
                            "\n                    pause_circle_filled\n                "
                          ),
                        ]
                      ),
                    ]
                  )
                : _c(
                    "el-tooltip",
                    {
                      attrs: {
                        effect: "dark",
                        content: "This chabot is active and running",
                        placement: "top",
                      },
                    },
                    [
                      _c(
                        "i",
                        {
                          staticClass:
                            "material-icons hover-lower-opacity text-success",
                        },
                        [
                          _vm._v(
                            "\n                    play_circle_filled\n                "
                          ),
                        ]
                      ),
                    ]
                  ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.chatbot
        ? _c(
            "div",
            { staticClass: "vertical-center mr-3" },
            [
              _vm.chatbot.build_status === _vm.BUILD_SUCCESS
                ? _c(
                    "el-tooltip",
                    {
                      attrs: {
                        effect: "dark",
                        content: "Successfully Built",
                        placement: "top",
                      },
                    },
                    [
                      _c(
                        "i",
                        {
                          staticClass:
                            "material-icons hover-lower-opacity text-success",
                        },
                        [
                          _vm._v(
                            "\n                    check_box\n                "
                          ),
                        ]
                      ),
                    ]
                  )
                : _vm.chatbot.build_status === _vm.BUILD_IN_PROGRESS
                ? _c(
                    "el-tooltip",
                    {
                      attrs: {
                        effect: "dark",
                        content: "Building...",
                        placement: "top",
                      },
                    },
                    [
                      _c(
                        "i",
                        {
                          staticClass:
                            "material-icons fa-spin hover-lower-opacity-primary text-primary",
                        },
                        [_vm._v("\n                    sync\n                ")]
                      ),
                    ]
                  )
                : _vm.chatbot.build_status === _vm.BUILD_FAILED
                ? _c(
                    "el-tooltip",
                    {
                      attrs: {
                        effect: "dark",
                        content: "Failed to Build",
                        placement: "top",
                      },
                    },
                    [
                      _c(
                        "i",
                        {
                          staticClass:
                            "material-icons hover-lower-opacity-danger text-danger",
                        },
                        [
                          _vm._v(
                            "\n                    report\n                "
                          ),
                        ]
                      ),
                    ]
                  )
                : _c(
                    "el-tooltip",
                    {
                      attrs: {
                        effect: "dark",
                        content: "Never Built",
                        placement: "top",
                      },
                    },
                    [
                      _c("i", { staticClass: "material-icons text-dark" }, [
                        _vm._v(
                          "\n                    more_horiz\n                "
                        ),
                      ]),
                    ]
                  ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.chatbot
        ? _c(
            "div",
            { staticClass: "vertical-center" },
            [
              _c(
                "el-button",
                {
                  staticClass: "mr-3",
                  attrs: {
                    type: "primary",
                    disabled: _vm.shouldDisableBuild,
                    round: "",
                    size: "medium",
                  },
                  on: { click: _vm.onClickBuild },
                },
                [
                  _c("i", {
                    staticClass: "fa fa-wrench mr-1",
                    attrs: { "aria-hidden": "true" },
                  }),
                  _vm._v(" "),
                  _c("strong", [_vm._v("Build")]),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "d-flex" }, [
      _vm.chatbot
        ? _c("div", { staticClass: "ml-auto mr-3" }, [
            _vm.chatbot.build_status === _vm.NOT_BUILD
              ? _c("p", { staticClass: "mb-0" }, [
                  _c("b", [_vm._v(_vm._s(_vm.chatbot.name))]),
                  _vm._v(" has "),
                  _c("b", [_vm._v("never")]),
                  _vm._v(" been built\n                "),
                ])
              : _c("p", { staticClass: "mb-0" }, [
                  _c("b", [_vm._v(_vm._s(_vm.chatbot.name))]),
                  _vm._v(" "),
                  _c("b", { staticClass: "text-success" }, [
                    _vm._v("successfully"),
                  ]),
                  _vm._v(" built on "),
                  _c("b", [
                    _vm._v(
                      _vm._s(_vm._f("fixDateTime")(this.chatbot.updated_at))
                    ),
                  ]),
                  _vm._v(" by "),
                  _c("b", [_vm._v(_vm._s(_vm.chatbot.builder_user?.name))]),
                ]),
          ])
        : _vm._e(),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "h-foot", attrs: { id: "chatbot-builder" } },
      [
        _c(
          "el-tabs",
          {
            staticClass: "pt-2 h-100",
            staticStyle: { "max-height": "calc(100% - 4rem)" },
            on: { "tab-click": _vm.onTabClick },
            model: {
              value: _vm.active_tab,
              callback: function ($$v) {
                _vm.active_tab = $$v
              },
              expression: "active_tab",
            },
          },
          [
            _vm.chatbot
              ? _c(
                  "el-tab-pane",
                  { attrs: { name: "settings", label: "Chatbot Settings" } },
                  [
                    _c("ChatbotSettings", {
                      attrs: { chatbot: _vm.chatbot, lines: _vm.campaigns },
                      on: { "bot-update": _vm.fetchChatbot },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.chatbot
              ? _c(
                  "el-tab-pane",
                  {
                    staticClass: "w-100 h-100",
                    attrs: { name: "builder", label: "Flow Builder" },
                  },
                  [
                    _c("FlowBuilder", {
                      attrs: {
                        chatbot: _vm.chatbot,
                        intents: _vm.intents,
                        active_tab: _vm.active_tab,
                      },
                      on: {
                        "ready-states": _vm.onChatbotStatesReady,
                        "set-save-icon": _vm.onSetSaveIcon,
                        "set-edit-icon": _vm.onSetEditIcon,
                        "enable-build-button": _vm.onEnableBuildButton,
                        "disable-build-button": _vm.onDisableBuildButton,
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.chatbot
              ? _c(
                  "el-tab-pane",
                  { attrs: { name: "intent-editor", label: "Intent Manager" } },
                  [
                    _c("IntentManager", {
                      attrs: { chatbot: _vm.chatbot, intents: _vm.intents },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }