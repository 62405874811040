<template>
    <div id="flow-builder" class="row">
        <div class="col-12 pr-0 alo-bot-flowchart"
             style="height: calc(100vh - 13.5rem);">

            <div id="drawflow"
                 style="box-shadow: 0px 0px 6px 5px rgb(0 0 0 / 9%)"
                 @drop="onDrop"
                 @dragover.prevent="">

                <!-- Zoom component -->
                <div class="footer m-4">
                    <div style="box-shadow: 1px 1px 5px #aaaaaa; border-radius: 5px; background: white" class="row">
                        <el-button
                            class="mr-2"
                            size="mini"
                            @click="zoomOut"
                            @mousedown.native="zoomOutRepeat"
                            @mouseup.native="zoomOutStopRepeat">
                            <strong style="font-size: large;">-</strong>
                        </el-button>
                        <span class="my-2 text-center no-select" style="width: 43px!important;">{{ adjustedZoom }}%</span>
                        <el-button
                            class="ml-2"
                            size="mini"
                            @click="zoomIn"
                            @mousedown.native="zoomInRepeat"
                            @mouseup.native="zoomInStopRepeat">
                            <strong style="font-size: large;">+</strong>
                        </el-button>
                    </div>
                </div>

                <!-- Side Panel -->
                <div id="side-panel"
                     v-if="shouldDisplaySidePanel && chatbot"
                     class="col-4 py-3 px-0"
                     style="max-height: calc(100% - 6.7rem);">
                    <SidePanel
                        :chatbot="chatbot"
                        :intents="intents"
                        :node_id="selected_node_id"
                        :state="selected_state"
                        :editor="editor"
                        :states="states"
                        :panel="selected_panel"
                        :appointment_services="appointment_services"
                        @export-data="exportData"
                        @node-panel-closed="onNodePanelClosed"/>
                </div>

                <!-- Draggable items -->
                <div
                    id="elements"
                    class="ml-2">

                    <div class="wrapper">
                        <el-tooltip
                            v-for="(NODE_DATA, dx) in draggableNodes"
                            v-bind:key="dx"
                            class="box-item"
                            effect="dark"
                            :content="NODE_DATA.label"
                            placement="right-start">

                            <div class="drag-drawflow px-2 py-0 m-1"
                                 draggable="true"
                                 @dragstart="onDrag"
                                 :data-node="NODE_DATA.node">
                                <i class="material-icons">{{ NODE_DATA.icon }}</i>
                            </div>
                        </el-tooltip>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Vue from 'vue'
import _ from 'lodash'
import {mapState} from 'vuex'
import Drawflow from 'drawflow'
import SidePanel from './side-panel.vue'
// Needed to load Drawflow nodes properly.
import styleDrawflow from 'drawflow/dist/drawflow.min.css'

// Custom Nodes
import StartNode from './nodes/start-node.vue'
import EndNode from './nodes/end-node.vue'
import MessageNode from './nodes/message-node.vue'
import GetInputNode from './nodes/get-input-node.vue'
import StoreInputNode from './nodes/store-input-node.vue'
import EscalationNode from './nodes/escalation-node.vue'
import AppointmentNode from './nodes/appointment-node.vue'
import CustomNode from './nodes/custom-node.vue'
import ConditionNode from './nodes/condition-node.vue'
import DisengagementNode from './nodes/disengagement-node'

/**
 * @var {Integer}
 */
const START_TYPE = 1
/**
 * @var {Integer}
 */
const END_TYPE = 2
/**
 * @var {Integer}
 */
const MESSAGE_TYPE = 3
/**
 * @var {Integer}
 */
const GET_INPUT_TYPE = 4
/**
 * @var {Integer}
 */
const STORE_INPUT_TYPE = 5
/**
 * @var {Integer}
 */
const ESCALATION_TYPE = 6
/**
 * @var {Integer}
 */
const CUSTOM_TYPE = 7
/**
 * @var {Integer}
 */
const APPOINTMENT_TYPE = 8
/**
 * @var {Integer}
 */
const CONDITION_TYPE = 10
/**
 * @var {Integer}
 */
const DISENGAGEMENT_TYPE = 11
/**
 * @var {String}
 */
const START_NODE = 'start-node'
/**
 * @var {String}
 */
const END_NODE = 'end-node'
/**
 * @var {String}
 */
const MESSAGE_NODE = 'message-node'
/**
 * @var {String}
 */
const GET_INPUT_NODE = 'get-input-node'
/**
 * @var {String}
 */
const STORE_INPUT_NODE = 'store-input-node'
/**
 * @var {String}
 */
const ESCALATION_NODE = 'escalation-node'
/**
 * @var {String}
 */
const CUSTOM_NODE = 'custom-node'
/**
 * @var {String}
 */
const APPOINTMENT_NODE = 'appointment-node'
/**
 * @var {String}
 */
const CONDITION_NODE = 'condition-node'
/**
 * @var {String}
 */
const DISENGAGEMENT_NODE = 'disengagement-node'
/**
 * @var {String}
 */
const MODE = 'vue'
/**
 * @var {String}
 */
const GENERAL_PANEL = 'general_panel'
/**
 * @var {String}
 */
const START_PANEL = 'start_panel'
/**
 * @var {String}
 */
const MESSAGE_PANEL = 'message_panel'
/**
 * @var {String}
 */
const GET_INPUT_PANEL = 'get_input_panel'
/**
 * @var {String}
 */
const STORE_INPUT_PANEL = 'store_input_panel'
/**
 * @var {String}
 */
const ESCALATION_PANEL = 'escalation_panel'
/**
 * @var {String}
 */
const CUSTOM_PANEL = 'custom_panel'
/**
 * @var {String}
 */
const APPOINTMENT_PANEL = 'appointment_panel'
/**
 * @var {String}
 */
const CONDITION_PANEL = 'condition_panel'
/**
 * @var {Integer}
 */
const BUILD_IN_PROGRESS = 3

const NODES_DATA = {
    1: {
        node: START_NODE,
        panel: GENERAL_PANEL,
        component: StartNode,
        label: 'Start',
        icon: 'play_arrow',
        name: 'Start'
    },
    2: {
        node: END_NODE,
        panel: GENERAL_PANEL,
        component: EndNode,
        label: 'End',
        icon: 'stop_circle',
        name: 'End'
    },
    3: {
        node: MESSAGE_NODE,
        panel: MESSAGE_PANEL,
        component: MessageNode,
        label: 'Message',
        icon: 'chat',
        name: 'Message'
    },
    4: {
        node: GET_INPUT_NODE,
        panel: GET_INPUT_PANEL,
        component: GetInputNode,
        label: 'Get Input',
        icon: 'reply',
        name: 'Digest'
    },
    5: {
        node: STORE_INPUT_NODE,
        panel: STORE_INPUT_PANEL,
        component: StoreInputNode,
        label: 'Store Input',
        icon: 'save_as',
        name: 'Store'
    },
    6: {
        node: ESCALATION_NODE,
        panel: ESCALATION_PANEL,
        component: EscalationNode,
        label: 'Escalation',
        icon: 'person_pin',
        name: 'Escalation'
    },
    7: {
        node: CUSTOM_NODE,
        panel: CUSTOM_PANEL,
        component: CustomNode,
        label: 'Custom Field',
        icon: 'label',
        name: 'Annotate'
    },
    8: {
        node: APPOINTMENT_NODE,
        panel: APPOINTMENT_PANEL,
        component: AppointmentNode,
        label: 'Appointment',
        icon: 'event',
        name: 'Appointment'
    },
    10: {
        node: CONDITION_NODE,
        panel: CONDITION_PANEL,
        component: ConditionNode,
        label: 'Condition',
        icon: 'device_hub',
        name: 'Condition'
    },
    11: {
        node: DISENGAGEMENT_NODE,
        panel: GENERAL_PANEL,
        component: DisengagementNode,
        label: 'Disengage',
        icon: 'block',
        name: 'Disengagement'
    },
}

/**
 * @var {Integer}
 */
const LOGICAL_TRIGGER_TYPE = 1
/**
 * @var {Integer}
 */
const INTENT_TRIGGER_TYPE = 2
/**
 * @var {Integer}
 */
const CX_TYPE = 3
/**
 * @var {Integer}
 */
const CX_FALLBACK_TYPE = 4

/**
 * @var {Integer}
 */
const NO_CONDITION_TRIGGER = 1
/**
 * @var {Integer}
 */
const FULFILLED_TRIGGER = 2
/**
 * @var {Integer}
 */
const NO_MATCH_TRIGGER = 3
/**
 * @var {Integer}
 */
const FALLBACK_TRIGGER = 4

// Condition module
/**
 * @var {Integer}
 */
const CONDITION_VALUE_TRUE = 1
/**
 * @var {Integer}
 */
const CONDITION_VALUE_FALSE = 0
/**
 * @var {Integer}
 */
const FALLBACK_TRIGGER_CONDITION = 2

const fallbackEnabledNodeTypes = [GET_INPUT_TYPE, STORE_INPUT_TYPE, APPOINTMENT_TYPE]

export default {
    ref: 'drawflow',
    components: {
        SidePanel
    },
    props: {
        chatbot: {
            required: true,
            default: null
        },
        intents: {
            required: false,
            default: []
        },
        active_tab: {
            required: true,
            default: ''
        },
    },
    data() {
        return {
            editor: null,
            states: [],
            updateBuffer: {
                modified: [],
                removed: []
            },
            default_node_params: {
                name: '',
                inputs: 1,
                outputs: 1,
                pos_x: 50,
                pos_y: 250,
                css_classes: 'aloware-node',
                data: {},
                node: '',
                component: null,
                props: {},
                options: {},
            },
            drawflowInitialized: false,
            selected_panel: GENERAL_PANEL,
            selected_state: null,
            selected_node_id: null,
            zoom: 100,
            NODES_DATA: NODES_DATA,
            GENERAL_PANEL,
            isMounted: false,
            appointment_services: [],
            intervalId: null
        }
    },
    mounted() {
        this.fetchAppointmentServices()

        if (this.chatbot) {
            this.isMounted = true
        }
    },
    methods: {
        fetchBotStates() {
            axios.get(`/api/v1/bots/builder/${this.$route.params.bot_id}/states`)
                .then(res => {
                    /** @var {object[]} this.states */
                    this.states = res.data

                    this.convertBotStatesIntoDrawflowNodes()

                    this.connectNodes()

                    this.drawflowInitialized = true
                })
                .catch(err => {
                    console.error(err)
                })
        },

        /**
         * Fetches the Appointment Services used for the Appointment nodes.
         *
         * @return {void}
         */
        fetchAppointmentServices() {
            axios.get('/api/v1/calendar/webhook/service').then(res => {
                // Delete pagination
                delete res.data.pagination
                this.appointment_services = res.data
            }).catch(err => {
                console.log(err)
            })
        },

        /**
         * Stores State in Database whenever needed.
         *
         * @param {object[]} state
         *
         * @return {void}
         */
        storeStateInDB(state) {
            axios.post(`/api/v1/bots/builder/${this.chatbot.id}/states`, state).then(({data}) => {
                state = data
                // Push to current states
                this.states.push(state)
                // Push to current states
                this.createNodeFromState(state, this.states.length - 1)
            }).catch(err => {
                console.log(err)
            })
        },

        /**
         * Initializes Drawflow stuff.
         *
         * @return {void}
         */
        initDrawflow() {
            /** @var {HTMLElement} drawflow_element */
            const drawflow_element = document.getElementById('drawflow')

            /** @var {Drawflow} this.$df */
            Vue.prototype.$df = new Drawflow(drawflow_element, Vue, this)

            this.$df.zoom = 1.0
            this.$df.zoom_max = 1.60
            this.$df.zoom_min = 0.40
            this.$df.zoom_value = 0.050

            // Initialize listeners
            this.initializeListeners()
            this.$df.reroute = true
            this.$df.curvature = 0.5
            this.$df.reroute_curvature_start_end = 0.5
            this.$df.reroute_curvature = 0.5

            this.$df.createCurvature = function (
                start_pos_x,
                start_pos_y,
                end_pos_x,
                end_pos_y
            ) {
                var center_y = (end_pos_y - start_pos_y) / 2 + start_pos_y
                let curv = (
                    ' M ' +
                    start_pos_x +
                    ' ' +
                    start_pos_y +
                    ' L ' +
                    (start_pos_x + 30) +
                    ' ' +
                    start_pos_y +
                    ' L ' +
                    (start_pos_x + 30) +
                    ' ' +
                    center_y +
                    ' L ' +
                    (end_pos_x - 30) +
                    ' ' +
                    center_y +
                    ' L ' +
                    (end_pos_x - 30) +
                    ' ' +
                    end_pos_y +
                    ' L ' +
                    end_pos_x +
                    ' ' +
                    end_pos_y +
                    ' L ' +
                    // Arrow
                    (end_pos_x - 10) +
                    ' ' +
                    (end_pos_y + 10) +
                    ' M ' +
                    end_pos_x +
                    ' ' +
                    end_pos_y +
                    ' L ' +
                    (end_pos_x - 10) +
                    ' ' +
                    (end_pos_y - 10)
                )

                return curv
            }

            // Allow self connection
            this.$df.dragEnd = function (e) {
                // Get the position of the mouse or touch event.
                if (e.type === 'touchend') {
                    var e_pos_x = this.mouse_x
                    var e_pos_y = this.mouse_y
                    var ele_last = document.elementFromPoint(e_pos_x, e_pos_y)
                } else {
                    var e_pos_x = e.clientX
                    var e_pos_y = e.clientY
                    var ele_last = e.target
                }

                // If a node was dragged, dispatch a 'nodeMoved' event.
                if (this.drag) {
                    if (this.pos_x_start != e_pos_x || this.pos_y_start != e_pos_y) {
                        this.dispatch('nodeMoved', this.ele_selected.id.slice(5))
                    }
                }

                // If a reroute point was dragged, dispatch a 'rerouteMoved' event.
                if (this.drag_point) {
                    this.ele_selected.classList.remove('selected')
                    if (this.pos_x_start != e_pos_x || this.pos_y_start != e_pos_y) {
                        this.dispatch('rerouteMoved', this.ele_selected.parentElement.classList[2].slice(14))
                    }
                }

                // Update the editor's position if it was dragged.
                if (this.editor_selected) {
                    this.canvas_x = this.canvas_x + (-(this.pos_x - e_pos_x))
                    this.canvas_y = this.canvas_y + (-(this.pos_y - e_pos_y))
                    this.editor_selected = false
                }

                // Check if a connection was being created or modified.
                if (this.connection === true) {
                    // Check if the last element was an input or if the force_first_input option is enabled.
                    if (ele_last.classList[0] === 'input' || (this.force_first_input && (ele_last.closest('.drawflow_content_node') != null || ele_last.classList[0] === 'drawflow-node'))) {

                        // If force_first_input is enabled and the last element is a drawflow-node or a drawflow_content_node.
                        if (this.force_first_input && (ele_last.closest('.drawflow_content_node') != null || ele_last.classList[0] === 'drawflow-node')) {
                            if (ele_last.closest('.drawflow_content_node') != null) {
                                var input_id = ele_last.closest('.drawflow_content_node').parentElement.id
                            } else {
                                var input_id = ele_last.id
                            }
                            // Check if there are no inputs in the node.
                            if (Object.keys(this.getNodeFromId(input_id.slice(5)).inputs).length === 0) {
                                var input_class = false
                            } else {
                                var input_class = 'input_1'
                            }
                        } else {
                            // Fix connection: get the input_id and input_class from the last element.
                            var input_id = ele_last.parentElement.parentElement.id
                            var input_class = ele_last.classList[1]
                        }
                        // Get the output_id and output_class from the selected element.
                        var output_id = this.ele_selected.parentElement.parentElement.id
                        var output_class = this.ele_selected.classList[1]

                        // Check if the input_class is not false.
                        if (input_class !== false) {
                            // Check if a connection between the input and output nodes does not already exist.
                            if (this.container.querySelectorAll('.connection.node_in_' + input_id + '.node_out_' + output_id + '.' + output_class + '.' + input_class).length === 0) {
                                // Connection does not exist, save the connection.

                                this.connection_ele.classList.add('node_in_' + input_id)
                                this.connection_ele.classList.add('node_out_' + output_id)
                                this.connection_ele.classList.add(output_class)
                                this.connection_ele.classList.add(input_class)
                                var id_input = input_id.slice(5)
                                var id_output = output_id.slice(5)

                                // Update the connections data in the drawflow object.
                                this.drawflow.drawflow[this.module].data[id_output].outputs[output_class].connections.push({ 'node': id_input, 'output': input_class })
                                this.drawflow.drawflow[this.module].data[id_input].inputs[input_class].connections.push({ 'node': id_output, 'input': output_class })
                                this.updateConnectionNodes('node-' + id_output)
                                this.updateConnectionNodes('node-' + id_input)

                                // Dispatch a connectionCreated event.
                                this.dispatch('connectionCreated', { output_id: id_output, input_id: id_input, output_class: output_class, input_class: input_class })

                            } else {
                                // Connection already exists, cancel the connection and remove the connection element.
                                this.dispatch('connectionCancel', true)
                                this.connection_ele.remove()
                            }
                            this.connection_ele = null
                        } else {
                            // Connection exists, remove the connection.
                            this.dispatch('connectionCancel', true)
                            this.connection_ele.remove()
                            this.connection_ele = null
                        }

                    } else {
                        // Remove Connection
                        this.dispatch('connectionCancel', true)
                        this.connection_ele.remove()
                        this.connection_ele = null
                    }
                }

                // Reset the variables at the end of the event.
                this.drag = false
                this.drag_point = false
                this.connection = false
                this.ele_selected = null
                this.editor_selected = false
            }

            // Make the editor appear in the UI.
            this.$df.start()

            this.lockFlowBuilder()

            if (this.chatbot.build_status !== BUILD_IN_PROGRESS) {
                this.unlockFlowBuilder()
            }
        },

        /**
         * Convert Aloware States into Drawflow nodes.
         *
         * Creates nodes from states and attaches a autogenerated node id.
         * This id can be customizable, but messing with the core is needed, so it's not recommended.
         * This node id is autoincrementable, so it's kind of easy to track.
         *
         * @param {object[]} states
         *
         * @return {object[]}
         */
        convertBotStatesIntoDrawflowNodes() {
            for (const [index, state] of this.states.entries()) {
                this.createNodeFromState(state, index)
            }
        },

        /**
         * Converts an Aloware State into a Drawflow Node.
         *
         * @param {object} state State to convert
         * @param {integer} index Refers to the autoincrement Drawflow Node Id.
         *
         * @return {void}
         */
        createNodeFromState(state, index = null) {
            // If node id is already set.
            if (index) {
                this.states[index].node_id = index + 1
            }

            // Let's count the intents if they exists.
            let intentLen = _.get(state, 'properties.intents', []).length

            // Our nodes will only have 1 input dot.
            let inputs = 1

            // The first outputs are the intents, if no intents, outputs defaults to 1.
            let outputs = intentLen > 0 ? intentLen : 1

            // Count the fallbacks.
            let fallbacksLen = _.get(state, 'properties.fallback', []).length

            // Add the fallbacks as outputs.
            if (fallbacksLen > 0) {
                outputs += fallbacksLen
            }

            if (state.type == CONDITION_TYPE) {
                outputs += 1
            }

            // Prepare node data
            let data = {
                ...this.states[index].properties,
                db_id: this.states[index].id
            }

            // Prepare node params
            let params = {
                pos_x: _.get(state, 'metadata.pos_x', null),
                pos_y: _.get(state, 'metadata.pos_y', null),
                node: this.NODES_DATA[state.type].node,
                component: this.NODES_DATA[state.type].component,
                inputs,
                outputs,
                data
            }

            switch (state.type) {
                case START_TYPE:
                    // Overwrite node params
                    params = {
                        ...params,
                        name: 'start123',
                        inputs: 0,
                    }
                    this.createNode(params)
                    break
                case END_TYPE:
                    // We don't use it right now.
                    break
                case MESSAGE_TYPE:
                    // Overwrite node params
                    params = {
                        ...params,
                        name: 'message',
                    }
                    this.createNode(params)
                    break
                case GET_INPUT_TYPE:
                    // Overwrite node params
                    params = {
                        ...params,
                        state: state,
                    }
                    this.createNode(params)
                    break
                case STORE_INPUT_TYPE:
                    // Overwrite node params
                    params = {
                        ...params,
                        name: state.properties.name,
                        type: state.properties.type,
                        message: state.properties.message,
                        aloware_name: state.properties.aloware_name
                    }
                    this.createNode(params)
                    break
                case ESCALATION_TYPE:
                    this.createNode(params)
                    break
                case CUSTOM_TYPE:
                    this.createNode(params)
                    break
                case APPOINTMENT_TYPE:
                    // Overwrite node params
                    params = {
                        ...params,
                        name: 'appointment',
                        props: {
                            appointment_services: this.appointment_services
                        }
                    }
                    this.createNode(params)
                    break
                case CONDITION_TYPE:
                    // Overwrite node params
                    params = {
                        ...params,
                        name: 'condition',
                    }
                    this.createNode(params)
                    break
                case DISENGAGEMENT_TYPE:
                    // Overwrite node params
                    params = {
                        ...params,
                        name: 'disengagement',
                    }
                    this.createNode(params)
                    break
                default:
                    console.warn(`Node type ${this.states[index].type} not found.`)
                    break
            }
        },

        /**
         * Registers a node. This is needed before adding any node.
         *
         * @param {String} node Node name.
         * @param {Component} component Vue component.
         * @param {Object} props Vue component props.
         * @param {Object} options Node options.
         *
         * @return {Void}
         */
        registerNode(name, component, props = {}, options = {}) {
            this.$df.registerNode(
                name,
                component,
                props,
                options
            )
        },

        /**
         * Adds a node to the editor.
         *
         * @param {Component} component compoennt.
         * @param {string} node Node name.
         * @param {string} state_name Name of the State.
         * @param {integer} inputs Number of inputs.
         * @param {integer} outputs Number of outputs.
         * @param {integer} pos_x Initial node position in horizontal axis.
         * @param {integer} pos_y Initial node position in vertial axis.
         * @param {string} css_classes Additional node CSS classes.
         * @param {object} data Node additional data.
         *
         * @return {string} The uuid or numeric ID of the node.
         */
        addNode(component, state_name, inputs = 1, outputs = 1, pos_x = 150, pos_y = 300, css_classes = '', data = {}) {
            if (css_classes.length != 0) {
                css_classes = `aloware-node ${css_classes}`
            } else {
                css_classes = 'aloware-node'
            }

            // Add node to editor.
            return this.$df.addNode(
                state_name,
                inputs,
                outputs,
                pos_x,
                pos_y,
                css_classes,
                data,
                component,
                MODE
            )
        },

        /**
         * Combines registerNode and addNode functions to add nodes in Vue Js.
         *
         * @param {object[]} params
         */
        createNode(params = {}) {
            // Overwrite default node params.
            params = {
                ...this.default_node_params,
                ...params,
            }

            let {node, component, props, options, name, inputs, outputs, pos_x, pos_y, css_classes, data} = params

            // Register node.
            this.registerNode(
                node,
                component,
                props,
                options
            )
            // Add node.
            return this.addNode(
                node,
                name,
                inputs,
                outputs,
                pos_x,
                pos_y,
                css_classes,
                data
            )
        },

        /**
         * Removes node from Drawflow
         *
         * @param {integer} id
         *
         * @return {void}
         */
        removeNode(id) {
            this.unselectNode()
            this.$df.removeNodeId(`node-${id}`)
        },

        /**
         * Selects a chatbot state.
         *
         * @param {object} state
         *
         * @return {void}
         */
        openSidePanel(state) {
            switch (state?.type) {
                default:
                    console.error('Unknown state type')
                    this.unselectNode()
                    break
                case START_TYPE:
                case DISENGAGEMENT_TYPE:
                    this.hideSidePanel()
                    break
                case MESSAGE_TYPE:
                    this.selected_panel = MESSAGE_PANEL
                    break
                case GET_INPUT_TYPE:
                    this.selected_panel = GET_INPUT_PANEL
                    break
                case STORE_INPUT_TYPE:
                    this.selected_panel = STORE_INPUT_PANEL
                    break
                case ESCALATION_TYPE:
                    this.selected_panel = ESCALATION_PANEL
                    break
                case CUSTOM_TYPE:
                    this.selected_panel = CUSTOM_PANEL
                    break
                case APPOINTMENT_TYPE:
                    this.selected_panel = APPOINTMENT_PANEL
                    break
                case CONDITION_TYPE:
                    this.selected_panel = CONDITION_PANEL
                    break
            }
        },

        /**
         * Unselects a node.
         *
         * @param {object} state
         *
         * @return {void}
         */
        unselectNode() {
            this.selected_node_id = null
            this.selected_state = null
            this.hideSidePanel()
        },

        /**
         * Connects all state nodes.
         *
         * @return {void}
         */
        connectNodes() {
            for (const [state_idx, state] of this.states.entries()) {
                this.connectSingleNode(state)
            }
        },

        /**
         * Connects a single node.
         *
         * @param {object} state
         *
         * @return {void}
         */
        connectSingleNode(state) {
            // Check if the state has transitions
            if (!state.transitions) {
                return
            }

            for (let [transition_idx, transition] of state.transitions.entries()) {
                // Sanity check - skip if the next state ID is not set
                if (!transition.next_state_id) {
                    continue
                }

                // Get the source and target nodes from their database IDs
                let source_node = this.getNodeFromDbId(state.id)
                let target_node = this.getNodeFromDbId(transition.next_state_id)

                let input = `input_1`
                let output = `output_${transition_idx + 1}`

                let outputId = 1
                let fallbackId = 0

                // Get the index of the fallback type if it exists in state properties
                if (state.properties.fallback) {
                    fallbackId = state.properties.fallback.map(fallback => fallback.type).indexOf(transition.trigger_value)
                }

                let intentId = 0
                // Get the index of the intent if it exists in state properties
                if (state.properties.intents) {
                    intentId = state.properties.intents.map(intent => intent.id).indexOf(parseInt(transition.trigger_value))
                }

                // Handle different state types for setting the output connections
                switch (state.type) {
                    case GET_INPUT_TYPE:
                        // If it's a fallback, use the index of the fallback type
                        if (transition.trigger_type == FALLBACK_TRIGGER) {
                            if (fallbackId < 0) {
                                continue
                            }
                            outputId = state.properties.intents.length + fallbackId + 1
                        } else {
                            // If it's an intent, use the intent index
                            outputId += intentId
                        }

                        output = `output_${outputId}`
                        break
                    case STORE_INPUT_TYPE:
                    case APPOINTMENT_TYPE:
                        // Output ID 1 is for success
                        // Use the fallback type index if it's in the list. Add one because it's indexed to 0.
                        if (transition.trigger_type == FALLBACK_TRIGGER) {
                            if (fallbackId < 0) {
                                continue
                            }
                            outputId += fallbackId + 1
                        }
                        output = `output_${outputId}`
                        break
                    case CONDITION_TYPE:
                        // Handle different trigger values for the condition type
                        switch (transition.trigger_value) {
                            case '1':
                                output = `output_1`
                                break
                            case '0':
                                output = `output_2`
                                break
                        }
                        break
                }

                // Set the properties of the transition object
                transition.next_node_id = target_node.id
                transition.input_class = input
                transition.output_class = output

                // Add the connection if the target node ID is set
                if (target_node.id) {
                    try {
                        this.$df.addConnection(source_node.id, target_node.id, output, input)
                    } catch (ex) {
                        // We are still debugging this:
                        // console.log({ ex, source_node_id: source_node.id, target_node_id: target_node.id, output, input })
                    }
                }
            }
        },

        /**
         * We use this function to tell Drawflow to point this node's external outputs to internal outputs.
         * Links any external output with any item that has 'link' class sinside the specific node.
         *
         * @param {integer} id Node id
         */
        createInternalNodeConnection(id) {
            /**
             * @var {HTMLElement[]} links Select any item that has .link class insde any node.
             * This elements will be linked to an external node.
             */
            const links = document.querySelectorAll(`#node-${id} .drawflow_content_node .link`)

            links.forEach((item) => {

                /** @var {String} output_class Source node output point */
                let output_class = item.classList[1]

                /** @var {HTMLElement} target This node's targets through its output class. */
                let target = document.querySelector(`#node-${id} .outputs .${output_class}`)

                target.style.top = '0'
                target.style.left = '0'

                target = document.querySelector(`#node-${id} .outputs .${output_class}`)

                // Sanity check.
                if (target === null) {
                    return
                }

                /**
                 * @var {DOMRect} nested_item_position Position of the internal node.
                 * Provides information about the size of an element and its position relative to the viewport.
                 * @see https://developer.mozilla.org/en-US/docs/Web/API/Element/getBoundingClientRect
                 */
                let nested_item_position = item.getBoundingClientRect() // link

                /** @var {DOMRect} targetPos Target position. */
                let target_node_position = target.getBoundingClientRect() // circle

                // Fix an offset related to the size of the dot
                const CORRECTION_NODE_POSITION = 3
                let top = nested_item_position.top - target_node_position.top - CORRECTION_NODE_POSITION
                let left = nested_item_position.left - target_node_position.left - CORRECTION_NODE_POSITION

                // Calculate the difference in one hundred percent
                let calculateOneHundredPercent = (number, percentage) => number * 1 / percentage

                target.style.top = `${calculateOneHundredPercent(top, this.$df.zoom)}px`
                target.style.left = `${calculateOneHundredPercent(left, this.$df.zoom)}px`
            })
        },

        /**
         * cleans up fallback in case there's any without messages
         * @param {object} state
         */
        removeFallbackByMessageLength(state) {
            const requiredIndex = state.properties.fallback.findIndex(fallback => {
                return fallback.messages.length === 0
            })
            if (requiredIndex === -1) {
                return false
            }
            return !!state.properties.fallback.splice(requiredIndex, 1)
        },

        /**
         * General helper to lock the flow builder when needed.
         *
         * @return {void}
         */
        lockFlowBuilder() {
            this.$df.editor_mode = 'view'
        },

        /**
         * General helper to unlock the flow builder when needed.
         *
         * @return {void}
         */
        unlockFlowBuilder() {
            this.$df.editor_mode = 'edit'
        },

        /**
         * This solves an issue of when the page opens on other tab, the connections do not show up
         *
         * @return {void}
         */
        onFlowBuilderVisible() {
            this.lockFlowBuilder()
            this.unlockFlowBuilder()
        },

        /**
         * Hides the side panel by loading a general panel.
         *
         * @return {void}
         */
        hideSidePanel() {
            this.selected_panel = GENERAL_PANEL
        },

        /**
         * Listens on drag node event
         *
         * @param {object} event
         *
         * @return {void}
         */
        onDrag(event) {
            // For mobile (ignored)
            if (event.type === "touchstart") {
                return
            }
            event.dataTransfer.setData("node", event.target.getAttribute('data-node'))
        },

        /**
         * Listens on drop node event.
         * - Creates a Node when Dropping draggable elements.
         *
         * @param {object} event
         *
         * @return {void}
         */
        onDrop(event) {
            // For mobile (ignored)
            if (event.type === "touchend") {
                return
            }
            event.preventDefault()

            var node_type = event.dataTransfer.getData("node")

            // Current X and Y positions
            let pos_x = event.clientX
            let pos_y = event.clientY

            // New x & y positions
            pos_x = pos_x * (this.$df.precanvas.clientWidth / (this.$df.precanvas.clientWidth * this.$df.zoom)) - (this.$df.precanvas.getBoundingClientRect().x * (this.$df.precanvas.clientWidth / (this.$df.precanvas.clientWidth * this.$df.zoom)))
            pos_y = pos_y * (this.$df.precanvas.clientHeight / (this.$df.precanvas.clientHeight * this.$df.zoom)) - (this.$df.precanvas.getBoundingClientRect().y * (this.$df.precanvas.clientHeight / (this.$df.precanvas.clientHeight * this.$df.zoom)))

            // Get State type from Node
            let type = 0

            // Converts node type to integer.
            Object.entries(this.NODES_DATA).filter(([id, data]) => {
                if (data.node == node_type) {
                    type = parseInt(id)
                    return true
                }
            })[0]

            // State template
            let state = {
                type,
                metadata: {
                    pos_x,
                    pos_y
                },
                properties: {},
                transitions: []
            }

            // Let's add specific state properties based on type
            switch (type) {
                case STORE_INPUT_TYPE:
                    state.properties.name = `example_name_${Math.ceil(Math.random() * 10000)}`
                    state.properties.aloware_name = `example_name_${Math.ceil(Math.random() * 10000)}`
                    state.properties.type = 'any'
                case MESSAGE_TYPE:
                    state.properties.message = 'Example message'
                    break
                case GET_INPUT_TYPE:
                    let _intents = this.intents

                    // TODO: is this validation still necessary?
                    if (_intents.length === 0) {
                        this.$notify({
                            type: 'error',
                            title: 'Create node',
                            message: 'It\'s not possible to create a get input node without intents. Please create one.',
                            showClose: true,
                        })
                    }

                    state.properties.intents = _intents.slice(-1).map(intent => intent.id)
                default:
                    break
            }

            this.storeStateInDB(state)
        },

        /**
         * Zooms in when needed.
         *
         * @return {void}
         */
        zoomIn() {
            // Do not overpass 160% of zoom.
            if (this.zoom < 160) {
                this.zoom += 10
                this.$df.zoom_in()
            }
        },

        /**
         * Zooms in when holding click.
         *
         * @return {void}
         */
        zoomInRepeat() {
            // Call the zoomIn method once when the mouse button is pressed
            this.zoomIn()
            this.intervalId = setInterval(() => {
                // Call the zoomIn method repeatedly while the mouse button is held down
                this.zoomIn()
                // Change this interval to adjust the speed of the repeat
            }, 100)
        },

        /**
         * Stops the repeating zoom in loop
         *
         * @return {void}
         */
        zoomInStopRepeat() {
            // Stop the repeat loop when the mouse button is released
            clearInterval(this.intervalId)
        },

        /**
         * Zooms out when needed.
         *
         * @return {void}
         */
        zoomOut() {
            // Do not go below 40% of zoom.
            if (this.zoom > 40) {
                this.zoom -= 10
                this.$df.zoom_out()
            }
        },

        /**
         * Zooms out when holding click.
         *
         * @return {void}
         */
        zoomOutRepeat() {
            // Call the zoomOut method once when the mouse button is pressed
            this.zoomOut()
            this.intervalId = setInterval(() => {
                // Call the zoomOut method repeatedly while the mouse button is held down
                this.zoomOut()
                // Change this interval to adjust the speed of the repeat
            }, 100)
        },

        /**
         * Stops the repeating zoom out loop
         *
         * @return {void}
         */
        zoomOutStopRepeat() {
            // Stop the repeat loop when the mouse button is released
            clearInterval(this.intervalId)
        },

        /**
         * Retrieves a state by id.
         *
         * @param {integer} id
         *
         * @return {object}
         */
        getStateFromId(id) {
            return this.states.filter(state => {
                return state.id === id
            })[0]
        },

        /**
         * Retrieves a chatbot state by its node id.
         *
         * @param {integer} node_id
         *
         * @return {object}
         */
        getStateFromNodeId(node_id) {
            let node = this.getNodeFromId(node_id)
            return this.getStateFromId(node.data.db_id)
        },

        /**
         * Retrieves a drawflow node by its id.
         *
         * @param {integer} node_id
         *
         * @return {object}
         */
        getNodeFromId(node_id) {
            return this.$df.drawflow.drawflow.Home.data[node_id]
        },

        /**
         * Retrieves a drawflow node by its db_id.
         *
         * @param {integer} db_id State id
         *
         * @return {object}
         */
        getNodeFromDbId(db_id) {
            let nodes = this.$df.drawflow.drawflow.Home.data
            let node = Object.keys(nodes).filter(key => nodes[key].data.db_id == db_id)[0]
            return nodes[node]
        },

        /**
         * Updates node when panel closes.
         *
         * @param {object} payload
         *
         * @return {void}
         */
        onNodePanelClosed(payload) {
            let {id, data} = payload

            // Get a deep copy of the old state using the state's database ID
            let oldState = JSON.parse(JSON.stringify(this.getStateFromId(data.db_id)))

            // Get the node using its ID and overwrite its data
            let node = this.$df.getNodeFromId(id)
            node.data = {...data}

            // Update the node data in Drawflow using its ID
            this.$df.updateNodeDataFromId(id, node.data)
            this.$emit('flowStateUpdated')

            // Get the updated state and the fallback length
            let state = this.getStateFromId(data.db_id)

            let fallback_length = 0

            if (fallbackEnabledNodeTypes.includes(state.type)) {
                let currentIntentIds = 0
                let stateCopy = JSON.parse(JSON.stringify(this.getStateFromNodeId(node.id)))

                let isGetInputType = state.type == GET_INPUT_TYPE

                if (isGetInputType) {
                    let oldIntentIds = oldState.properties.intents.map(({id}) => id)
                    currentIntentIds = data.intents.map(({id}) => id)
                    let intentsAdded = currentIntentIds.filter(id => !oldIntentIds.includes(id))
                    let intentsRemoved = oldIntentIds.filter(id => !currentIntentIds.includes(id))

                    // Copy and remove delete intents
                    stateCopy.properties.intents = currentIntentIds.map(intentId => this.intents.filter(intent => intent.id == intentId)[0])
                    stateCopy.transitions = state.transitions.filter(transition => {
                        return !intentsRemoved.includes(parseInt(transition.trigger_value))
                    })
                }


                // Remove all outputs
                for (let id = Object.keys(node.outputs).length; id > 0; id--) {
                    this.$df.removeNodeOutput(node.id, `output_${id}`)
                }

                // Get how inputs are needed because of fallback
                // If there are no messages, remove fallback

                if (data.fallback) {
                    fallback_length = data.fallback.reduce((prev, curr) => {
                        if (curr.messages.length == 0) {
                            return prev
                        }

                        if (curr.messages.length == 1) {
                            return curr.messages[0] != '' ? prev + 1 : prev
                        }

                        return prev + 1
                    }, 0)
                }

                // This code is adding the dot connection to the missing fallback
                // APPOINTMENT will always have two fallbacks
                if (oldState.type === APPOINTMENT_TYPE && fallback_length == 1) {
                    // Make 2 connections appear (otherwise, if only time fallback exists, it will look like it's coming from the date)
                    fallback_length = 2
                }

                // In case we don't have fallback, remove transitions
                if (fallback_length === 0) {
                    stateCopy.transitions = oldState.transitions.filter(transition => {
                        return !['state', 'date', 'time'].includes(transition.trigger_value)
                    })
                }

                // Add correct number of outputs again
                let outputAmount = fallback_length + (isGetInputType ? currentIntentIds.length : 1)
                for (let id = 0; id < outputAmount; id++) {
                    this.$df.addNodeOutput(node.id)
                }

                // Connect other nodes
                this.connectSingleNode(stateCopy)

                setTimeout(() => {
                    this.createInternalNodeConnection(node.id)
                }, 400)

                // update state intents
                this.states = this.states.map(_state => _state.id == node.data.db_id ? stateCopy : _state)
            }

            this.states.map(item => {
                if (item.id === node.data.db_id) {
                    item.properties = {...data}
                    return item
                }
            })

            let selected_state = this.getStateFromNodeId(this.selected_node_id)

            if (!selected_state.properties) {
                selected_state.properties = null
            }

            switch (this.selected_panel) {
                case START_PANEL:
                    break
                case MESSAGE_PANEL:
                    selected_state.properties.message = data.message
                    break
                case GET_INPUT_PANEL:
                    // Modify the get input state here
                    break
                case STORE_INPUT_PANEL:
                    selected_state.properties.type = data.type
                    selected_state.properties.name = data.name
                    selected_state.properties.aloware_name = data.aloware_name
                    selected_state.properties.message = data.message
                    break
                case ESCALATION_PANEL:
                    if (data.type === 1) {
                        selected_state.properties.id = data.id
                        delete selected_state.properties.message
                        delete selected_state.properties.numbers
                    }
                    if (data.type === 2) {
                        selected_state.properties.message = data.message
                        selected_state.properties.numbers = data.numbers
                        delete selected_state.properties.id
                    }
                    selected_state.properties.type = data.type
                    break
                case CUSTOM_PANEL:
                    selected_state.properties.key = data.key
                    selected_state.properties.value = data.value
                    break
                case APPOINTMENT_PANEL:
                    selected_state.properties.date_message = data.date_message
                    selected_state.properties.time_message = data.time_message
                    selected_state.properties.note = data.note
                    selected_state.properties.service_id = data.service_id
                    selected_state.properties.date_suggestion_number = data.date_suggestion_number
                    selected_state.properties.time_suggestion_number = data.time_suggestion_number
                    selected_state.properties.reminder_enabled = data.reminder_enabled
                    selected_state.properties.reminder_message = data.reminder_message
                    selected_state.properties.reminder_time = data.reminder_time
                    selected_state.properties.reminder_workflow_id = data.reminder_workflow_id
                    break
                case CONDITION_PANEL:
                    selected_state.properties.operator_type = data.operator_type
                    selected_state.properties.parameter = data.parameter
                    selected_state.properties.value = data.value
                    break
            }

            this.states = this.states.map(_state => selected_state.id == _state.id ? selected_state : _state)

            // In case we don't have fallback, remove empty key
            if (fallback_length === 0) {
                delete selected_state.properties.fallback
            }

            //Add to backend data
            this.saveToBuffer('modified', selected_state)

            this.hideSidePanel()
        },

        /**
         * Save information to buffer. This will be sent to the backend later
         *
         * @param {string} type
         * @param {object} state
         *
         * @return {void}
         */
        saveToBuffer(type, state) {
            if (!this.drawflowInitialized) {
                return
            }

            let bufferIds = {}
            for (let [_type, _items] of Object.entries(this.updateBuffer)) {
                bufferIds[_type] = new Set(_items.map(item => typeof item != 'object' ? item : item.id))
            }

            switch (type) {
                case 'removed':
                    // For the removed ones, only the id is enough
                    if (!bufferIds[type].has(state.id)) {
                        this.updateBuffer[type].push(state.id)
                    }

                    // Remove from other buffers if found (why doing anything else if it's deleted huh? 😅)
                    for (let [_type, _ids] of Object.entries(bufferIds)) {
                        if (_type == type || _ids.size == 0 || !_ids.has(state.id)) {
                            continue
                        }

                        let index = Array.from(_ids).indexOf(state.id)
                        this.updateBuffer[_type].splice(index, 1)
                    }
                    break
                default:
                    // Push state if it's new. Replace state if it's existant
                    if (bufferIds[type].has(state.id)) {
                        let index = Array.from(bufferIds[type]).indexOf(state.id)
                        this.updateBuffer[type].splice(index, 1, state)
                    } else {
                        this.updateBuffer[type].push(state)
                    }
                    break
            }
        },

        /**
         * Clears up the buffer of changes (modified and deleted nodes and connections)
         *
         * @return {void}
         */
        clearBuffer() {
            this.updateBuffer.modified = []
            this.updateBuffer.removed = []
        },

        /**
         * We use this function to extend each of the events Drawflow provides.
         *
         * @return {void}
         */
        initializeListeners() {
            // Node listeners
            this.$df.on('nodeCreated', (id) => this.nodeCreatedListener(id))
            this.$df.on('nodeSelected', (id) => this.nodeSelectedListener(id))
            this.$df.on('nodeUnselected', (is_unselected) => this.nodeUnselectedListener())
            this.$df.on('nodeRemoved', (id) => this.nodeRemovedListener(id))

            this.$df.on('nodeMoved', (id) => this.nodeMovedListener(id))

            // Connection listeners
            this.$df.on('connectionCreated', (event) => this.connectionCreatedListener(event))
            this.$df.on('connectionRemoved', (event) => this.connectionRemovedListener(event))

            this.$df.on('zoom', (event) => this.zoomListener(event))

            // When Build button is clicked.
            this.$root.$on('prepare-chatbot-states', () => {
                this.lockFlowBuilder()
                this.unselectNode()
                this.prepareChatbotStatesToBuild()
            })

            // When Building process ends
            this.$root.$on('chatbot-build-finished', () => {
                this.unlockFlowBuilder()
            })

            // When Building process succeeds, we clean up the buffer in order to prevent previous changes for being sent.
            this.$root.$on('cleanup-states-buffer', () => {
                this.clearBuffer()
            })

            // When a side panel is openning
            this.$root.$on('open-side-panel', (node_id) => {
                let state = this.getStateFromNodeId(node_id)
                this.openSidePanel(state)
            })
        },

        /**
         * Listens when a node is moved
         *
         * @return {void}
         */
        nodeMovedListener(id) {
            let node = this.getNodeFromId(id)
            let state = this.getStateFromNodeId(id)

            let {pos_x, pos_y} = node

            if (!state.metadata) {
                state.metadata = {}
            }

            state.metadata.pos_x = pos_x
            state.metadata.pos_y = pos_y

            this.saveToBuffer('modified', state)
            this.states = this.states.map(_state => state.id == _state.id ? state : _state)
        },

        /**
         * Listens when a node is created
         *
         * @param {integer} id
         *
         * @return {void}
         */
        nodeCreatedListener(id) {
            /** @var {Object} state Bot state of the node. */
            let state = this.getStateFromNodeId(id)

            if (!state) {
                return
            }

            switch (state.type) {
                case GET_INPUT_TYPE:
                case STORE_INPUT_TYPE:
                case APPOINTMENT_TYPE:
                case CONDITION_TYPE:
                    setTimeout(() => {
                        this.createInternalNodeConnection(id)
                    }, 400)
                    break
                default:
                    break
            }
        },

        /**
         * Listens when a node is removed
         *
         * @param {integer} id
         *
         * @return {void}
         */
        nodeRemovedListener(id) {
            let _state = this.states.filter(state => state.node_id == id)[0]

            this.saveToBuffer('removed', _state)

            this.states = this.states.map(state => {
                state.transitions = state.transitions.filter(transition => transition.next_state_id != _state.id)
                return state
            }).filter(state => _state.id != state.id)
        },

        /**
         * Listens when a node is selected
         *
         * @param {integer} id
         *
         * @return {void}
         */
        nodeSelectedListener(id) {
            this.selected_node_id = id
            this.$root.$emit('disable-node-tooltips')

            /** @var {Object} state Bot state of the node. */
            let state = this.getStateFromNodeId(id)

            if (!state) {
                return
            }

            this.selected_state = state
        },

        /**
         * Listens when zoom has changed.
         *
         * @param {integer} raw_zoom
         *
         * @return {void}
         */
        zoomListener(raw_zoom) {
            if (typeof raw_zoom == 'number') {
                let new_zoom = raw_zoom
                // Fix cases like 0.89999 to 0.90
                new_zoom = parseFloat(new_zoom.toFixed(2))
                // Make it percentage
                new_zoom = new_zoom * 100
                // Make it integer to avoid cases like 90.0 to 90
                new_zoom = parseInt(new_zoom)
                // Set zoom
                this.zoom = new_zoom
            }
        },

        /**
         * Listens when a node connection is created.
         *
         * @param {object} event
         *
         * @return {void}
         */
        connectionCreatedListener(event) {
            let {
                output_id,
                input_id,
                output_class,
                input_class
            } = event

            /** @var {object} source_node */
            let source_node = this.getNodeFromId(output_id)
            /** @var {integer} source_state_id */
            let source_state_id = _.get(source_node, 'data.db_id', null)
            /** @var {object} source_state */
            let source_state = this.getStateFromId(source_state_id)
            /** @var {string} source_class */
            let source_class = output_class

            /** @var {object} target_node */
            let target_node = this.getNodeFromId(input_id)
            /** @var {integer} target_state_id */
            let target_state_id = _.get(target_node, 'data.db_id', null)
            /** @var {string} target_class */
            let target_class = input_class

            /** @var {integer} output_number The number of output */
            let output_number = Number.parseInt(source_class.split('_')[1])

            // Transition stuff

            /** @var {object} transition */
            let _transition = {
                state_id: source_state_id,
                next_state_id: target_state_id,
                properties: null,
            }

            /** @var {integer} trigger_condition */
            let trigger_condition = NO_CONDITION_TRIGGER
            /** @var {integer} trigger_type */
            let trigger_type = CX_TYPE
            /** @var {any} trigger_value */
            let trigger_value = null

            /** @var {boolean} output_occupied */
            let is_fallback_type = fallbackEnabledNodeTypes.includes(source_state.type)
            /** @var {boolean} output_occupied For nodes with one output */
            let output_occupied = source_state.transitions.length > 0 && !is_fallback_type

            /** @var {HTMLElement[]} outputTags All the internal outputs */
            let outputTags = document.getElementById(`node-${output_id}`)
                .getElementsByClassName(output_class)

            /** @var {string[]} classes All the internal outputs classes */
            let classes = Array.prototype.slice.call(outputTags)
                .map(item => Array.prototype.slice.call(item.classList))
                .reduce((prev, classes) => [...prev, ...classes], [])

            if (is_fallback_type) {
                /** @var {integer|null} fallbackType */
                let fallbackType = null

                // Checks the output classes.
                for (let className of classes) {
                    // Break the whole loop when the element has the .success class
                    if (className.includes('success')) {
                        break
                    }

                    // 'fallback_' diferentiates from the other class that only taggs it's a fallback, but without the type
                    if (className.includes('fallback_')) {
                        fallbackType = className.split('_')[2]

                        break
                    }
                }

                trigger_condition = fallbackType ? FALLBACK_TRIGGER_CONDITION : FULFILLED_TRIGGER
                trigger_type = fallbackType ? FALLBACK_TRIGGER : trigger_type
                trigger_value = fallbackType ? fallbackType : trigger_value

                switch (source_state.type) {
                    case GET_INPUT_TYPE:
                        /** @var {object} intent */
                        let intent = _.get(source_state, 'properties.intents', [])[output_number - 1]

                        // Trigger value is the same as fallbackType unless there's an intent.
                        trigger_value = intent ? intent.id : trigger_value

                        output_occupied = source_state.transitions.filter(transition => {
                            // It's occupied if the fallbackType is the same as transititon trigger value
                            if (fallbackType) {
                                return fallbackType == transition.trigger_value
                            }

                            // It's occupied if the trigger value is the same as the intent.
                            return parseInt(transition.trigger_value) == (intent ? intent.id : null)
                        }).length > 0
                        break
                    case STORE_INPUT_TYPE:
                    case APPOINTMENT_TYPE:
                        output_occupied = source_state.transitions.filter(transition => {
                            if (fallbackType) {
                                return fallbackType == transition.trigger_value
                            }

                            // Trigger value is null to regular transitions
                            return transition.trigger_value == null
                        }).length > 0

                        break
                }
            } else {
                switch (source_state.type) {
                    case CONDITION_TYPE:
                        // Set proper trigger type
                        trigger_type = LOGICAL_TRIGGER_TYPE
                        // Depending on the node classes, we know if the transitions if for True or for False values.
                        trigger_value = classes.includes('condition_true') ? CONDITION_VALUE_TRUE : CONDITION_VALUE_FALSE
                        // Check existing transitions to see if it already exists.
                        output_occupied = source_state.transitions.filter(transition => {
                            if(trigger_value == CONDITION_VALUE_TRUE) {
                                // If we already have a transition True as trigger value, counts as occupied
                                return transition.trigger_value == CONDITION_VALUE_TRUE
                            }

                            if(trigger_value == CONDITION_VALUE_FALSE) {
                                // If we already have a transition False as trigger value, counts as occupied
                                return transition.trigger_value == CONDITION_VALUE_FALSE
                            }
                        }).length > 0
                        break
                }
            }

            _transition = {
                ..._transition,
                trigger_type,
                trigger_condition,
                trigger_value,
            }

            // If output is already used, do not allow it to be connected to something else
            if (output_occupied && this.drawflowInitialized) {
                this.$notify.info({
                    offset: 95,
                    title: 'Chatbots',
                    message: "You can't create more than one connection per output.",
                })
                this.$df.removeSingleConnection(output_id, input_id, output_class, input_class)
                return
            }

            try {
                // Loop through the states to update the one with the matching source_state_id.
                this.states.map(state => {
                    if (state.id == source_state_id) {

                        // Check if the transition already exists in the state's transitions.
                        let exists = state.transitions.filter(transition => {
                            return transition.next_state_id == _transition.next_state_id && transition.trigger_value == _transition.trigger_value
                        }).length > 0

                        // If the transition does not exist yet.
                        if (!exists) {
                            // Handle special cases for APPOINTMENT_TYPE and STORE_INPUT_TYPE.
                            if ([APPOINTMENT_TYPE, STORE_INPUT_TYPE].includes(state.type)) {
                                // Check if there's already a default transition (with a null trigger_value).
                                let has_default_transition = state.transitions.filter(transition => {
                                    return transition.trigger_value === null
                                }).length > 0

                                // Prevent adding a "ghost transition" that already exists.
                                // Allow adding the transition if there's no default transition or if the new transition has a non-null trigger_value.
                                if (!has_default_transition || (has_default_transition && _transition.trigger_value !== null)) {
                                    state.transitions.push(_transition)
                                    this.saveToBuffer('modified', state)
                                }
                            } else {
                                // Handle other cases, avoiding "ghost transitions" forever.
                                let avoid_ghost_transition = state.type != CONDITION_TYPE || _transition.trigger_value != null

                                // If the ghost transition should be avoided, add the new transition and save the modified state to the buffer.
                                if (avoid_ghost_transition) {
                                    state.transitions.push(_transition)
                                    this.saveToBuffer('modified', state)
                                }
                            }
                        } else {
                            // If the transition already exists, save the modified state to the buffer.
                            this.saveToBuffer('modified', state)
                        }
                    }
                    return state
                })
            } catch (ex) {
                console.log(ex)
                return
            }
        },

        /**
         * Listens when a node connection is removed.
         *
         * @param {object} event - The event object containing information about the removed connection.
         *
         * @return {void}
         */
        connectionRemovedListener(event) {
            // Destructure the event object to get output_id, input_id, and output_class.
            let {output_id, input_id, output_class: source_class} = event

            // Retrieve the source and target nodes using the output_id and input_id.
            let source_node = this.getNodeFromId(output_id)
            let target_node = this.getNodeFromId(input_id)

            // Get the source and target state IDs and the source state object.
            let source_state_id = _.get(source_node, 'data.db_id', null)
            let target_state_id = _.get(target_node, 'data.db_id', null)
            let source_state = this.getStateFromId(source_state_id)

            // Calculate the output number and retrieve the corresponding intent and fallback.
            let output_number = Number.parseInt(source_class.split('_')[1])
            let intents = _.get(source_state, 'properties.intents', [])
            let intent = intents[output_number - 1]
            let fallbacks = _.get(source_state, 'properties.fallback', [])
            let fallback = fallbacks[output_number - (intents.length == 0 ? 2 : intents.length + 1)] // -2 because of indexation of fallbacks and outputs

            // Filter the transitions array of the source state to remove the transition corresponding to the removed connection.
            source_state.transitions = source_state.transitions.filter(transition => {
                // Handle different source_state types.
                switch (source_state.type) {
                    case GET_INPUT_TYPE:
                        // If the output_number is greater than the number of intents,
                        // remove the transition with a trigger_value equal to the fallback type.
                        if (output_number > intents.length) {
                            return transition.trigger_value != fallback.type
                        }
                        // Otherwise, remove the transition that has a next_state_id equal to the target_state_id
                        // and a trigger_value equal to the intent ID (or null if there is no intent).
                        return !(transition.next_state_id == target_state_id && parseInt(transition.trigger_value) == (intent ? intent.id : null))
                    case STORE_INPUT_TYPE:
                    case APPOINTMENT_TYPE:
                        // If the output_number is 1, remove the transition with a non-null trigger_value
                        // and a next_state_id equal to the target_state_id.
                        if (output_number == 1) {
                            return transition.trigger_value != null && transition.next_state_id != target_state_id
                        }
                        // Otherwise, remove the transition with a trigger_value equal to the fallback type.
                        return transition.trigger_value != fallback.type
                }
                // If the source_state type is not one of the cases above,
                // remove the transition with a next_state_id equal to the target_state_id.
                return transition.next_state_id != target_state_id
            })

            // Update the states list, marking the source state as modified and saving it to a buffer.
            this.states.map(state => {
                if (state.id == source_state_id) {
                    this.saveToBuffer('modified', source_state)
                    return source_state
                } else return state
            })
        },

        /**
         * Listens when a node is unselected.
         *
         * @param {object} event
         *
         * @return {void}
         */
        nodeUnselectedListener() {
            this.unselectNode()
            this.$root.$emit('enable-node-tooltips')
        },

        /**
         * @todo to be implemented.
         */
        exportData() {
            alert(JSON.stringify(this.$df.export()))
        },

        /**
         * When Building, we need to convert the modified and removed nodes
         * into something the backend can understand.
         *
         * @return {void}
         */
        prepareChatbotStatesToBuild() {
            let states_to_send = {}

            states_to_send['modified'] = this.prepareStatesToBeSent(this.updateBuffer.modified)
            states_to_send['removed'] = this.updateBuffer.removed

            // If there are no nodes to modify or remove.
            if (!states_to_send.modified && !states_to_send.removed) {
                this.unlockFlowBuilder()
                return
            }

            this.$emit('ready-states', states_to_send)
        },

        /**
         * Performs a General & Specific State cleanup to be sent to the backend.
         *
         * @param {object[]} states Modified states only.
         *
         * @return {object[]}
         */
        prepareStatesToBeSent(states) {
            let states_to_send = []

            // General state cleaning
            states.map(state => {
                if (_.isEmpty(state.properties)) {
                    state.properties = null
                }

                // Specific state cleaning
                switch (state.type) {
                    case GET_INPUT_TYPE:
                        /// remove ghost transitions :)
                        state.transitions = state.transitions.filter(transition => {
                            return transition.trigger_value
                        })
                        state.properties.intents = this.prepareIntentsToBeSent(state.properties.intents)
                        break
                    default:
                        break
                }

                states_to_send.push(state)
            })

            return states_to_send
        },

        /**
         * General cleanup for transitions.
         * Removes ghost transitions :)
         *
         * @param {object[]} transitions
         *
         * @return {object[]}
         */
        removeGhostTransitions(transitions) {
            cleaned_transitions = transitions.filter(transition => {
                return transition.trigger_value
            })

            return cleaned_transitions
        },

        /**
         * Performs a General Intent cleanup.
         *
         * @param {array} intents Can be objects or integers.
         *
         * @return {array}
         */
        prepareIntentsToBeSent(intents = []) {
            let intents_to_send = intents.map(intent => {
                // If we already have an intent id, we don't need to send the whole object.
                if (typeof intent == 'object') {
                    return intent.id
                }

                return intent
            })

            return intents_to_send
        },
    },
    computed: {
        ...mapState(['chatbots']),

        /**
         * Filter the nodes needed to be draggable.
         *
         * @return {object[]}
         */
        draggableNodes() {
            // Skips start (1) & end nodes (2)
            return Object.entries(this.NODES_DATA).filter(([key, data]) => parseInt(key) != 2 && parseInt(key) != 1).map(([key, data]) => data)
        },

        /**
         * Helper to hide/show the node side panel.
         *
         * @return {boolean}
         */
        shouldDisplaySidePanel() {
            // The GENERAL_PANEL is an empty panel used to make it 'disppear'
            return this.selected_panel !== GENERAL_PANEL
        },

        /**
         * This function converts the range 40% -> 160% into 0% -> 100%
         *
         * @return {int}
         */
        preadjustedZoom() {
            return Math.round((this.zoom - 30) / (160 - 30) * 100)
        },

        /**
         * The steps each click should add to the zoom are tricky to calculate,
         * since we are shrinking a 120 range (40 - 160) into a 100 range (0 - 100)
         *
         * @return {int}
         */
        adjustedZoom() {
            // For the last low step (when we get to 40% of raw zoom), we end up with a preadjusted zoom of 8%.
            // We caught this and transform it into a 0.
            if (this.preadjustedZoom == 8) {
                return 0
            }

            return this.preadjustedZoom
        }
    },
    watch: {
        '$route.query.tab'(query_tab) {
            if (query_tab === 'builder' && this.drawflowInitialized) {
                this.onFlowBuilderVisible()
            }
        },
        active_tab: {
            immediate: true, // ensures that the handler function is executed immediately after the watcher is created.
            handler(current_tab) {
                if (current_tab == 'builder' && !this.drawflowInitialized) {
                    // Fetch the bot states.
                    this.fetchBotStates()

                    // If the flow-builder component is mounted, initialize drawflow.
                    if (this.isMounted) {
                        this.initDrawflow()
                    } else {
                        // If the component is not mounted yet, watch for the 'isMounted' property
                        // and initialize drawflow when the component is mounted.
                        this.$watch('isMounted', (isMounted) => {
                            if (isMounted) {
                                this.initDrawflow()
                            }
                        })
                    }
                }

                // If drawflow is initialized, ensure that the node dots are in the right place.
                if (this.drawflowInitialized) {
                    // Wait 400ms delay.
                    setTimeout(() => {
                        // Loop through all the states.
                        for (let state of this.states) {
                            // If the state type is one of the specified types, create an internal node connection.
                            if ([GET_INPUT_TYPE, STORE_INPUT_TYPE, APPOINTMENT_TYPE, CONDITION_TYPE].includes(state.type)) {
                                this.createInternalNodeConnection(state.node_id)
                            }
                        }
                    }, 400)
                }
            }
        },
        updateBuffer: {
            deep: true, // ensures execution when nested properties change.
            handler() {
                // If both the 'modified' and 'removed' properties of the 'updateBuffer' object are empty, disable the build button and set the save icon.
                if (_.isEmpty(this.updateBuffer.modified) && _.isEmpty(this.updateBuffer.removed)) {
                    this.$emit('disable-build-button')
                    this.$emit('set-save-icon')
                    return
                }
                // If there are changes, enable the build button and set the edit icon.
                this.$emit('enable-build-button')
                this.$emit('set-edit-icon')
            }
        }
    }
}
</script>
